

/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useContext, useCallback } from "react";
import "./QuoteInfoCardMobile.css";
import SecureLS from "secure-ls";
import { useHistory } from "react-router-dom";
import DialogPop from "../../../components/DialogPop";
import { PuffLoader } from "react-spinners";
import Swal from "sweetalert2";
import axios from "axios";
import editiconn from "../../../assets/editicon.svg";
import trash from "../../../assets/trash.svg";
import note from "../../../assets/note.svg";
import trackingtrack from "../../../assets/trackingtrack.svg";
import cardposagent from "../../../assets/cardposagent.svg";
import { ConnContext } from "../../../context/ConnContext";
import CountDown from "../../../components/CountDown";
import moment from "moment";
import Cookies from "js-cookie";

import GlobalizationLanguage, { GlobalizationLanguagewithCOntryCode } from "../../../components/GlobalizationLanguage";
import Currency from "../../../utils/Currency";
import MakePayment from "../../../components/MakePayment";
export default function QuoteInfoCardMobile({
  quote_amount,
  onClick,
  image,
  payer,
  best_quote,
  paymentStatus,
  payment_time,
  itemDesc,
  deliveryStatus,
  data,
  itemdetailss,
  promoDetails,
  onWaitClick,
  onAcceptExistClick,
  items,
  statusmemory,
  wallet_bln,
  setwallet_bln,
  modeofpayment

}) {
  const history = useHistory();
  const { visitingCountryCode } = useContext(ConnContext);
  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()
  const [country,setCountry]=useState(countryvalue);
  const [validrandom, setValidrandom] = useState();
  const [isStart, setIsStart] = useState(false);

  const RandomNumber = () => {
    return setValidrandom(Math.random().toString().substr(2, 6));
  };

  useEffect(() => {
      setCountry(Cookies.get('i18next'));
    },[Cookies.get('i18next')])
    
  var editquote = GlobalizationLanguage("/editquote")
  const sendersettings=GlobalizationLanguage("/sendersettings")
  var sendermessage = GlobalizationLanguage("/sendermessage")
  var viewQuote = GlobalizationLanguage("/viewQuote")
  var viewQuote_quote = GlobalizationLanguage(
    "/viewQuote/quotes"
  );
  var viewQuote_request_delivery = GlobalizationLanguage("/viewQuote/request-a-delivery")
  // var activedeliveries = GlobalizationLanguage(`/activedeliveries/viewDelivery/${itemdetailss.delivery_id}`)
  var activedeliveries = GlobalizationLanguagewithCOntryCode(`/activedeliveries/viewDelivery/${itemdetailss?.vcc}/${itemdetailss?.delivery_id}`, country, visitingCountryCode)
  var timeout = GlobalizationLanguage("/timeout")
  var Tracking = GlobalizationLanguage(`/tracking/${itemdetailss.delivery_id}`)

  const [showpayment_method, setshowPayment_method] = useState(false);

  const numberWithCommas = new Intl.NumberFormat("en-GB");

  const [showupdate, setShowupdate] = useState(false);
  const [showrelist, setshowrelist] = useState(false);
  const [itemdescUpdate, setitemdescUpdate] = useState("");
  const [isloading, setisloading] = useState(false);
  const [paymentloading, setpaymentloading] = useState(false);
  const [paymentresponse, setpaymentresponse] = useState('')

  const [subTotal, setsubTotal] = useState(0);
  const [total, settotal] = useState(0);
  const [show, setshow] = useState(false);
  const [itemToDelete, setitemToDelete] = useState([]);
  const [showDeleting, setshowDeleting] = useState(false);
  const [deleting, setdeleting] = useState(false);
  const [clicked, setclicked] = useState("");



  const [showtransferdetails, setshowtransferdetails] = useState(false);
  const [showsent, setshowsent] = useState(false);
  // const [modeofpayment, setModeOfPayment] = useState("");


  const [time, setTime] = useState("");
  const [waiting, setWaiting] = useState(false);


  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });


  const authAxios3 = axios.create({
    baseURL: ``,
    headers: {
      Authorization: ls.get("_utk"),
    },

    response: true, 
  });


  const ListData = {
    Users: [
      {
        type: "awaiting",
        name: "Awaiting",
      },
      {
        type: "received",
        name: "Received",
      },
      {
        type: "acknowledge",
        name: "Acknowledgement",
      },
      {
        type: "agents",
        name: "Agent assignment",
      },
      {
        type: "pickup",
        name: "Pick up",
      },
      {
        type: "intransit",
        name: "In transit",
      },
      {
        type: "outfordelivery",
        name: "Out for delivery",
      },
    ],
  };

  const DropDown = () => {
    return (
      <div className="parent-dropdown">
        {ListData.Users.map((x, i) => {
          return (
            <div key={i} className="each">
              <span
                style={{
                  marginLeft: "10px",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                {x.name}
              </span>
            </div>
          );
        })}
      </div>
    );
  };


  const updateItemDesc = (e) => {
    e.preventDefault();

    setisloading(true);
    axios
      .post(`${process.env.REACT_APP_API}m-dillivry-listing`, {
        action_type: "update",
        delivery_id: itemdetailss?.delivery_id,
        item_desc: itemdescUpdate,
        sender_email: ls.get("eid"),
      })
      .then((res) => {
        setisloading(false);
        setShowupdate(false);
        setitemdescUpdate("");
        Swal.fire({
          icon: "success",
          text: "Updated Successfully",
          timer: 3000,
          showConfirmButton: false,
        }).then((result)=>{
          if (result.isConfirmed) {
            window.location.reload()
          }
        });
        // setshould_reload("should_reload")
      })
      .catch((err) => {
        console.log(err.response);
        setisloading(false);
        setShowupdate(false);
        setitemdescUpdate("");
        Swal.fire({
          icon: "error",
          text: `${err?.response?.data?.error_message}`,
          timer: 3000,
          showConfirmButton: true,
        });
        setisloading(false);
      });
  };

  const deleteItem = (e) => {
    e.preventDefault();

    setdeleting(true);

    authAxios
      .post(
        `m-dillivry-listing?delivery_location=${itemToDelete.vcc}`,
        {
          action_type: "delete",
          delivery_id: itemToDelete.delivery_id,
          sender_email: ls.get("eid"),
          // vcc: visitingCountryCode,
        },
        { timeout: 240000 }
      )
      .then((res) => {
        setshowDeleting(false);
        setdeleting(false);
        // setsendingMsg(false)
        Swal.fire({
          title: "Deleted",
          text: "Item deleted!",
          icon: "success",
          confirmButtonColor: "var(--main)",
          showConfirmButton: false,
          timer: 2000,
        });
        history.go(0);
      })
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          console.log("internal-server-error-timeout");
          history.push(timeout);
          // window.location.reload()
        }
        setshowDeleting(false);
        setdeleting(false);
        Swal.fire({
          icon: "error",
          text: "error!",
          timer: 2000,
          showConfirmButton: false,
        });
        if (err.code === "ECONNABORTED") {
          console.log("internal-server-error-timeout");
          history.push(timeout);
          // window.location.reload()
        }
      });
  };

  
  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });


  useEffect(() => {
    const thirty_min_after =  moment(new Date(items?.ack_req?.ack_req_time_stamp)).add(30, 'm')

    const d = new Date(thirty_min_after);
    
    let date = d.getDate();
    let month = d.getMonth();
    let year = d.getFullYear();
    let hour = d.getHours();
    let min = d.getMinutes();

    const waitingTimeInMinute = (moment([year, month, date, hour, min])).diff(moment())/1000
    
    setTime(Math.floor(waitingTimeInMinute))

  }, [statusmemory])


  return (
    <div style={{ position: "relative", zIndex: 0 }}>
      <div className="imageanditemdesc">
        <div className="upper-one">
          <div
            onClick={onClick}
            className="quote-info-image-main"
            style={{ backgroundImage: `url(${image})` }}
          >
          </div>

          <div className="itemupper">
            <span onClick={onClick} data-tip data-for="global3">
              {itemDesc?.charAt(0)?.toUpperCase() +
                itemDesc?.slice(1)?.toLowerCase()?.replace(/_/g, " ")}
            </span>{" "}
          </div>
        </div>

        <div className="lowwer-two">
          <div className="loowerone"></div>

          <div className="lowwertwo">
            <div className="lowertwoone">
              {paymentStatus ? (
                <span>
                  Payment status:{" "}
                  <span className="item-value">
                    {paymentStatus === "success" ? "Paid" : "Not paid"}{" "}
                  </span>
                </span>
              ) : (
                <></>
              )}

              <div>
                <span>
                  Delivery status:{" "}
                  <span
                    style={{
                      color:
                        deliveryStatus === "awaiting_quote" ||
                        deliveryStatus === "awaiting_provider_acknowledgment"
                          ? "#004DC1"
                          : deliveryStatus === "delivered"
                          ? "#50B14A"
                          : deliveryStatus === "missed_delivery" ||
                            deliveryStatus === "expired"
                          ? "#F40101"
                          : "#B77E02",
                    }}
                  >
                    {deliveryStatus === "delivered"
                      ? "Completed"
                      : itemdetailss?.total_quote_received >= 1 &&
                        deliveryStatus === "awaiting_quote"
                      ? "Received quote"
                      : deliveryStatus === "awaiting_provider_acknowledgment"
                      ? "Awaiting acknowledgement"
                      : deliveryStatus?.charAt(0)?.toUpperCase() +
                        deliveryStatus?.slice(1).replace(/_/g, " ")}
                  </span>
                </span>
              </div>

              <div>
                <span>
                  Payer :{" "}
                  <span className="item-value">
                    {payer?.charAt(0)?.toUpperCase() + payer?.slice(1)}
                  </span>
                </span>
              </div>

              <div>

                {best_quote ? (
                  <span>
                    Best quote:{" "}
                    <span style={{ color: "blue" }}>
                      {" "}
                      <Currency location={data.vcc} />{numberWithCommas?.format(best_quote)}
                    </span>{" "}
                  </span>
                ) : (
                  <></>
                )}
                {quote_amount ? (
                  <span>
                    Quote amount:{" "}
                    <span style={{ color: "blue" }}>
                    <Currency location={data.vcc} />{numberWithCommas?.format(quote_amount)}
                    </span>{" "}
                  </span>
                ) : (
                  <></>
                )}
              </div>

              {payment_time ? (
                <div>
                  <span>
                    Payment type:{" "}
                    <span className="item-value">
                      {payment_time === "on_delivery"
                        ? "Pay on delivery"
                        : "Pay on pickup"}
                    </span>
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="lowertwotwo">
              <div>
                {deliveryStatus === "awaiting_quote" &&
                itemdetailss?.total_quote_received <= 0 ? (
                  <div>
                    <div
                      onClick={() =>
                        history.push({
                          pathname: editquote,
                          state: itemdetailss,
                        })
                      }
                    >
                      <img src={editiconn} alt="edit_icon" />
                    </div>

                    <div
                      onClick={() => {
                        setitemToDelete(itemdetailss);
                        setshowDeleting(true);
                      }}
                    >
                      <img src={trash} alt="trash_icon" />
                    </div>
                  </div>
                ) : itemdetailss?.total_quote_received >= 1 &&
                  itemdetailss?.delivery_status === "awaiting_quote" ? (
                  <div style={{ right: "50px" }}>
                    <div>
                      <div
                        onClick={() =>
                          history.push(
                            activedeliveries
                          )
                        }
                        className="top-cart"
                      >
                        <div className="top-cart-icon">
                          {itemdetailss?.total_quote_received}
                        </div>
                        <img src={note} alt="edit_icon" />
                      </div>
                    </div>

                    <div
                      onClick={
                        
                      ()=>  itemdetailss?.total_quote_received >= 1 &&
                        itemdetailss?.delivery_status === "awaiting_quote" ?
                              
                        setshowrelist(true) :
                        () =>
                        history.push({
                          pathname: editquote,
                          state: itemdetailss,
                        })
                      }
                    >
                      <img src={editiconn} alt="trash_icon" />
                    </div>

                    <div
                      onClick={() => {
                        setitemToDelete(itemdetailss);
                        setshowDeleting(true);
                      }}
                    >
                      <img src={trash} alt="trash_icon" />
                    </div>
                  </div>
                ) : deliveryStatus === "awaiting_agent_assignment" ? (
                  <div style={{ right: "50px" }}>
                    {itemdetailss?.payment_status === "success" ? (
                      <button
                        style={{
                          border: "none",
                        }}
                        disabled
                        className="top-awaiting"
                      >
                        <img src={cardposagent} alt="edit_icon" />
                      </button>
                    ) : (
                      <div
                        onClick={() => {
                          setshowPayment_method(true);
                          settotal(quote_amount);
                          setsubTotal(quote_amount);
                          console.log(modeofpayment, "mode");
                          // firstRender();
                          setshowtransferdetails(false); 
                          // setshowsent(!showsent);
                        }}
                        className="top-awaiting"
                      >
                        <img src={cardposagent} alt="edit_icon" />
                      </div>
                    )}

                  </div>
                ) : deliveryStatus === "awaiting_pickup" ? (
                  <div style={{ right: "50px" }}>
                   
                    {itemdetailss?.payment_status === "success" ? (
                      <button
                        style={{
                          border: "none",
                        }}
                        disabled
                        className="top-awaiting"
                      >
                        <img src={cardposagent} alt="edit_icon" />
                      </button>
                    ) : (
                      <div
                        onClick={() => {
                          setshowPayment_method(true);
                          settotal(quote_amount);
                          setsubTotal(quote_amount);
                          console.log(modeofpayment, "mode")
                        }}
                        className="top-awaiting"
                      >
                        <img src={cardposagent} alt="edit_icon" />
                      </div>
                    )}

                  </div>
                ) : deliveryStatus === "in_transit" ? (
                  <div style={{ right: "50px" }}>

                    {itemdetailss?.payment_status === "success" ? (
                      <button
                        style={{
                          border: "none",
                        }}
                        disabled
                        className="top-awaiting"
                      >
                        <img src={cardposagent} alt="edit_icon" />
                      </button>
                    ) : (
                      <div
                        onClick={() => {
                          setshowPayment_method(true);
                          settotal(quote_amount);
                          setsubTotal(quote_amount);
                          console.log(modeofpayment, "mode")
                        }}
                        className="top-awaiting"
                      >
                        <img src={cardposagent} alt="edit_icon" />
                      </div>
                    )}

                    <div
                      onClick={() =>
                        history.push(Tracking)
                      }
                      className="bottom-awaiting"
                    >
                      <img src={trackingtrack} alt="trash_icon" />
                    </div>
                  </div>
                ) : deliveryStatus === "out_for_delivery" ? (
                  <div style={{ right: "50px" }}>

                    {itemdetailss?.payment_status === "success" ? (
                      <button
                        style={{
                          border: "none",
                        }}
                        disabled
                        className="top-awaiting"
                      >
                        <img src={cardposagent} alt="edit_icon" />
                      </button>
                    ) : (
                      <div
                        onClick={() => {
                          setshowPayment_method(true);
                          settotal(quote_amount);
                          setsubTotal(quote_amount);
                          console.log(modeofpayment, "mode")
                        }}
                        className="top-awaiting"
                      >
                        <img src={cardposagent} alt="edit_icon" />
                      </div>
                    )}

                    <div className="bottom-awaiting"   onClick={() =>
                        history.push(Tracking)
                      }>
                      <img src={trackingtrack} alt="trash_icon" />
                    </div>
                  </div>
                ) : deliveryStatus === "delivered" ? (
                  <div style={{ right: "50px" }}>

                    {itemdetailss?.payment_status === "success" ? (
                      <button
                        style={{
                          border: "none",
                        }}
                        disabled
                        className="top-awaiting"
                      >
                        <img src={cardposagent} alt="edit_icon" />
                      </button>
                    ) : (
                      <div
                        onClick={() => {
                          return (
                            setshowPayment_method(true) ,
                            console.log(modeofpayment, "modeee")
                          )
                        }
                      }
                        className="top-awaiting"
                      >
                        <img src={cardposagent} alt="edit_icon" />
                      </div>
                    )}

                  <div className="bottom-awaiting"   onClick={() =>
                        history.push(Tracking)
                      }>
                      <img src={trackingtrack} alt="trash_icon" />
                    </div>
                  </div>
                ) : deliveryStatus === "missed_delivery" ? (
                  <div style={{ right: "50px" }}>
                  
                    {itemdetailss?.payment_status === "success" ? (
                      <button
                        style={{
                          border: "none",
                        }}
                        disabled
                        className="top-awaiting"
                      >
                        <img src={cardposagent} alt="edit_icon" />
                      </button>
                    ) : (
                      <div
                        onClick={() => {
                          setshowPayment_method(true);
                          settotal(quote_amount);
                          setsubTotal(quote_amount);
                          console.log(modeofpayment, "modeee")
                        }}
                        className="top-awaiting"
                      >
                        <img src={cardposagent} alt="edit_icon" />
                      </div>
                    )}

                    <div
                      onClick={() =>
                        history.push(Tracking)
                      }
                      className="bottom-awaiting"
                    >
                      <img src={trackingtrack} alt="trash_icon" />
                    </div>
                  </div>
                ) : deliveryStatus === "expired" ? (
                  <div style={{ right: "50px" }}>
                   
                    <div className="bottom-awaiting">
                      <img src={trash} alt="trash_icon" />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {itemdetailss?.delivery_status ===
          "awaiting_provider_acknowledgment" ? (
            <div className="ack-con">
              {time < 0 ? (
                <>
                  <div>
                    <span>
                      <span> Acknowledgement time: 00:00 </span>
                    </span>
                  </div>
                  <div>
                    <p className="ack-text">
                      You can now accept from existing quotes or wait for more
                      quotes 
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <span>
                      <span>
                        {" "}
                        Acknowledgement time: {Math.floor(time / 60)}:{" "}
                        {time % 60}{" "}
                      </span>
                    </span>
                  </div>
                  <div>
                    <CountDown setTime={setTime} time={time} />

                    <p className="ack-text">
                      After 30 minitues you can accept from existing quotes or
                      wait for more quotes.
                    </p>
                  </div>
                </>
              )}
              {time > 0 ? (
                <div className="ack-butn-con">
                  <button className="wait-btn"> Wait for more quotes  </button>
                  <button className="accept-btn">Accept existing quotes</button>
                </div>
              ) : (
                <div className="ack-butn-con">
                  <button className="wait" onClick={onWaitClick}>
                    {" "}
                    {waiting && clicked === itemdetailss.delivery_id
                      ? "Please wait"
                      : "Wait for more quotes "}
                  </button>
                  <button className="accpt" onClick={onAcceptExistClick}>
                    Accept existing quotes
                  </button>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>

      <DialogPop
        openPopup={showupdate}
        setopenPopup={setShowupdate}
        head="Update Item Description "
      >
        <div className="update_itemdesc_con">
          <form className="update_itemdesc_form">
            <textarea
              type="text"
              placeholder="Update item desc.."
              onChange={(e) => {
                setitemdescUpdate(e.target.value);
              }}
              value={itemdescUpdate}
              className="update_itemdesc_text"
            />
            <div className="update_itemdesc_btncon">
              {itemdescUpdate === "" || itemdescUpdate === undefined ? (
                <button disabled>Update</button>
              ) : (
                <button
                  onClick={updateItemDesc}
                  className="update_itemdesc_btn"
                >
                  {isloading ? (
                    <PuffLoader
                      color="white"
                      loading={isloading}
                      speedMultiplier={1}
                      size={34}
                    />
                  ) : (
                    <span onClick={updateItemDesc}>
                      {" "}
                      {isloading ? "Loading..." : "Update"}{" "}
                    </span>
                  )}
                </button>
              )}
            </div>
          </form>
        </div>
      </DialogPop>

      <DialogPop
        openPopup={showrelist}
        setopenPopup={setshowrelist}
        head="Update Item Description"
      >
        <div className="item-desc-details">
          <p className="item-para">
            Sorry item description edit is forbidden.You have already received a
            quote from a provider.To change the description you need to re-list
            the request. Click the Re-list button to proceed.
          </p>

          <div className="relist-btn">
            <button
              onClick={() =>
                history.push({
                  pathname: viewQuote_request_delivery,
                  state: { data: itemdetailss, Schedule: "schedule" },
                  // state: `schedule`
                })
              }
            >
              Re-list
            </button>
          </div>
        </div>
      </DialogPop>

      <DialogPop
        openPopup={showDeleting}
        setopenPopup={setshowDeleting}
        // head="Delete Item"
      >
        <div className="quote_div">
          <p style={{ fontSize: "15px" }}>
            {" "}
            Are you sure you want to delete this item?
          </p>
          {
            <form style={{ display: "flex", width: "100%" }}>
              <div className="dialogdiv">
                <button
                  onClick={() => setshowDeleting(false)}
                  className="dialodivbt"
                  type="button"
                  style={{
                    cursor: "pointer",
                    color: "#FFFFFF",
                    backgroundColor: "#50B14A",
                    border: "1px solid #50B14A",
                  }}
                >
                  {" "}
                  No
                </button>{" "}
                &nbsp; &nbsp;
                {deleting ? (
                  <button
                    className="dialodivbt"
                    type="button"
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "grey",
                      border: "1px solid grey",
                    }}
                  >
                    <PuffLoader
                      color="white"
                      loading={deleting}
                      speedMultiplier={1}
                      size={16}
                    />
                  </button>
                ) : (
                  <button
                    className="dialodivbt"
                    type="button"
                    onClick={deleteItem}
                    style={{
                      cursor: "pointer",
                      color: "#FFFFFF",
                      backgroundColor: "grey",
                      border: "1px solid grey",
                    }}
                  >
                    Yes
                  </button>
                )}
              </div>
            </form>
          }
        </div>
      </DialogPop>




      <DialogPop
        openPopup={showDeleting}
        setopenPopup={setshowDeleting}
        // head="Delete Item"
      >
        <div className="quote_div">
          <p style={{ fontSize: "15px" }}>
            {" "}
            Are you sure you want to delete this item?
          </p>
          {
            <form style={{ display: "flex", width: "100%" }}>
              <div className="dialogdiv">
                <button
                  onClick={() => setshowDeleting(false)}
                  className="dialodivbt"
                  type="button"
                  style={{
                    cursor: "pointer",
                    color: "#FFFFFF",
                    backgroundColor: "#50B14A",
                    border: "1px solid #50B14A",
                  }}
                >
                  {" "}
                  No
                </button>{" "}
                &nbsp; &nbsp;
                {deleting ? (
                  <button
                    className="dialodivbt"
                    type="button"
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "grey",
                      border: "1px solid grey",
                    }}
                  >
                    <PuffLoader
                      color="white"
                      loading={deleting}
                      speedMultiplier={1}
                      size={16}
                    />
                  </button>
                ) : (
                  <button
                    className="dialodivbt"
                    type="button"
                    onClick={deleteItem}
                    style={{
                      cursor: "pointer",
                      color: "#FFFFFF",
                      backgroundColor: "grey",
                      border: "1px solid grey",
                    }}
                  >
                    Yes
                  </button>
                )}
              </div>
            </form>
          }
        </div>
      </DialogPop>

      <MakePayment
          openPopup={showpayment_method}
          setopenPopup={setshowPayment_method}
          deliveries={items}
          walletbalance={wallet_bln}
          setwalletbalance={setwallet_bln}

          promoDetails={promoDetails}
        />


      {show ? <DropDown /> : null}

    </div>
  );
}
