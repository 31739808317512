import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BiChevronDown } from 'react-icons/bi';

export default function DashboardFilter({
    filterAll,
    filterAck,
    filterAccepted,
    filterCompleted,
    filterCurrent,
    filterMissed,
    filterOut,
    filterOutbid,
    filterPickup,
    filterRejected,
    filterWatchList
}) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div >
      <div
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{textDecoration: "none",
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "19px",
            color: "white",
            display: 'flex',
            padding: '7px 17px',
            borderRadius:'5px',
            backgroundColor:'var(--main)',
            gap: "5px",
            cursor: 'pointer'
        }}>
        <span>Filter by delivery status</span>
        <span><BiChevronDown /></span>
      </div>
      <Menu
        
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div onClick={handleClose}>
       <div onClick={filterAll}>
            <MenuItem style={{padding:'7px 10px', width:'100%', justifyContent:'flex-start'}}>All</MenuItem>
        </div> 
        <div onClick={filterCurrent}>
            <MenuItem style={{padding:'7px 10px', width:'100%', justifyContent:'flex-start'}}>Current Quote</MenuItem>
        </div> 
        <div onClick={filterAck}>
            <MenuItem style={{padding:'7px 10px', width:'100%', justifyContent:'flex-start'}}>Await acknowledgement</MenuItem>
        </div> 
        <div onClick={filterAccepted}>
            <MenuItem style={{padding:'7px 10px', width:'100%', justifyContent:'flex-start'}}>Accepted</MenuItem>
        </div> 
        <div onClick={filterPickup}>
            <MenuItem style={{padding:'7px 10px', width:'100%', justifyContent:'flex-start'}}>Picked up</MenuItem>
        </div> 
        <div onClick={filterOut}>
            <MenuItem style={{padding:'7px 10px', width:'100%', justifyContent:'flex-start'}}>Out for delivery</MenuItem>
        </div> 
        <div onClick={filterOutbid}>
            <MenuItem style={{padding:'7px 10px', width:'100%', justifyContent:'flex-start'}}>Outbids</MenuItem>
        </div> 
        <div onClick={filterMissed}>
            <MenuItem style={{padding:'7px 10px', width:'100%', justifyContent:'flex-start'}}>Missed</MenuItem>
        </div> 
        <div onClick={filterRejected}>
            <MenuItem style={{padding:'7px 10px', width:'100%', justifyContent:'flex-start'}}>Rejected</MenuItem>
        </div>
        <div onClick={filterCompleted}>
            <MenuItem style={{padding:'7px 10px', width:'100%', justifyContent:'flex-start'}}>Completed</MenuItem>
        </div>
        {/* <div onClick={filterWatchList}>
            <MenuItem style={{padding:'7px 10px', width:'100%', justifyContent:'flex-start'}}>Watchlist</MenuItem>
        </div> */}
        </div>
      </Menu>
    </div>
  );
}