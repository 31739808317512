import React from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#50B14A',
    },
  },
});

const useStyles = makeStyles(() => ({
   
    formLabel: {
      fontWeight:'600',
      fontSize:'14px',
    },
    itemGrid:{
      marginBottom:'3px'
    },
    input:{
      borderColor: 'red',
      marginTop:'5px',
    }
  }));

function RegInput({name, id, txt,onKeyUp,InputProps,  maxlength, minlength, disabled,  htmlFor,autoFocus,type, placeholder, size, value, onChange, min, max, error, maxDate}) {
  const classes = useStyles();

    return (
        <div>
          <ThemeProvider theme={theme}>
            <label htmlFor={htmlFor} className={classes.formLabel}> {txt}</label>
            <TextField
              inputProps={InputProps}
              
                className ={classes.input}
                name={name}
                minlength={minlength}
                maxlength={maxlength}
                onKeyUp={onKeyUp}
                variant="outlined"
                required
                fullWidth
                id={id}
                placeholder={placeholder}
                autoFocus={autoFocus}
                size={size ? size : 'small'}
                type={type}
                value={value}
                onChange={onChange}
                error={error}
                max={maxDate}
                disabled={disabled}
               text
               
              />
            </ThemeProvider>
        </div>
    )
}

export default RegInput
