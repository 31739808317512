
import TitleText from '../../components/Reg_components/TitleText';
import TopNavigation from '../../components/Reg_components/TopNavigation';
import Playstore from '../../assets/Playstore.png'
import Appstore from '../../assets/Appstore.png'
import LoginTopMenu from '../../components/topNav/LoginTopMenu';

export default function RegLinkToApp() {

  return (
    <div className="registration">
      <div className="topDesktop">
        <LoginTopMenu/>
      </div>

      
      <div className="pageTitle">
        <div className="pageTag">
            Download Dillivry App on
        </div>
        <div className="pageSubtage">
            Playstore for android
        </div>
        <div className="pageSubtage">
            Appstore for IOS
        </div>
      </div>
      <div className="formSide">

        <div className="regCont">
          <TopNavigation help='.'/>
          <TitleText head='Download Dillivry App on' 
              subHead='Playstore for android' 
              subHead2="Appstore for IOS"
              margin_base="40px" />
            <div style={{display:'flex', gap:'20px', width:'100%', justifyContent:'center', alignItems:'center', paddingBottom:'30px'}}>
              <img src={Playstore} alt="playstore" className="storeLogo"/>
              <img src={Appstore} alt="appstore"  className="storeLogo"/>
            </div>
         
        </div>
      </div>
    </div>
  );
}


