import React, { useEffect, useContext, useState } from "react";
import "./Message.css";
import { MdNavigateNext } from "react-icons/md";
import SmallBtn from "../../../components/SmallBtn";
import { TiArrowBack } from "react-icons/ti";
import axios from "axios";
import Swal from "sweetalert2";
import PuffLoader from "react-spinners/PuffLoader";
import moment from "moment";

import warngreen from "../../../assets/warngreen.svg";
import warnrred from "../../../assets/warnrred.svg";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthContext } from "../../../context/AuthContext";
import { useHistory } from "react-router-dom";
import SecureLS from "secure-ls";
import { ConnContext } from "../../../context/ConnContext";
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
function MessageSender({ msg, from, handleChange, data }) {
  var timeout = GlobalizationLanguage("/timeout")

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const history = useHistory();

  const schema = yup.object().shape({
    message: yup
      .string()
      .required("This is required")
      .min(1, "Can't be lesser than 2 digits")
      .max(2500, "Can't exceed 20 digits"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const date = Date();

  const [d, setd] = useState("");

  const newdatem = d;

  const [viewMsg, setviewMsg] = useState("default");
  const [enquiryMsg, setEnquiryMsg] = useState([]);
  const [reply, setReply] = useState("");
  const [conversation, setconversation] = useState([]);
  const [subject, setsubject] = useState("");
  const [delivery_id, setdelivery_id] = useState("");
  const [fulfiller_id, setfulfiller_id] = useState("");
  const [sendingMsg, setsendingMsg] = useState(false);
  const [pickup, setpickup] = useState("");
  const [destination, setdestination] = useState("");
  const [image, setimage] = useState([]);
  const [msgLength, setmsgLength] = useState(0);
  const [sender_name, setSender_name] = useState("");
  const [paginateActivePage, setPaginateActivePage] = useState(1);

  const [viewData, setviewData] = useState([]);
  const [isRequired, setisRequired] = useState(false);

  const auth = useContext(AuthContext);

  useEffect(() => {
    setviewData([...viewData, data]);
  }, []);

  useEffect(() => {
    setmsgLength(msg.length);
    setEnquiryMsg(msg.slice(0, n));
  }, []);

  function reverseArr(input) {
    var ret = new Array();
    for (var i = input.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  const n = 10;

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });
  const insert = (arr, index, ...newItems) => [
    ...arr.slice(0, index),
    ...newItems,
    ...arr.slice(index),
  ];

  const {visitingCountryCode} = useContext(ConnContext)
  
  const replyMessage = () => {
    if (reply === "") {
      setisRequired(true);
    } else {
      setisRequired(false);
      setsendingMsg(true);
      authAxios
        .post(`m-dillivry-quote-manager`, {
          action_type: "quote_msg",
          delivery_id: delivery_id,
          fulfiller_id: fulfiller_id,
          msg: reply,
          vcc: visitingCountryCode
        }, {timeout: 240000})
        .then((res) => {
          setsendingMsg(false);
          setReply("");
          let new_msg = {
            user_id: ls.get("eid"),
            msg: reply,
            time_stamp: new Date(),
          };
          let new_conversation = insert(conversation, 0, new_msg);
          setconversation(new_conversation);

          setReply("");
          if (res.status === 200) {
          }
        })

        .catch((err) => {
          if (err.code === "ECONNABORTED") {
            console.log('internal-server-error-timeout')
            history.push(timeout)
          }
          setReply("");
          setsendingMsg(false);
          if (err?.response?.status === 403) {
            Swal.fire({
              icon: "warning",
              title: "Session!",
              text: "Session expired login again!",
              allowOutsideClick: false,
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              setsendingMsg(false);
              if (result.value) {
                auth.logout();
                history.push(`/login`);
              }
            });
          } else if (err?.response?.status === 401) {
            setReply("");
            setsendingMsg(false);
            Swal.fire({
              icon: "info",
              text: "item has been delivered, message not sent",
              timer: 4000,
              showConfirmButton: false,
            });
          } else {
            setsendingMsg(false);
            setReply("");
            Swal.fire({
              icon: "info",
              text: "Ops! Message not sent, try again later",
              timer: 4000,
              showConfirmButton: false,
            });
          }
        });
    }
  };

  return (
    <div className="msgHouseSender">
      <div className="context" style={{ padding: "20px 0 0 0 " }}>
        {viewMsg === "view" ? (
          <div className="msgflexb">
            <div className="padding4Mobile" style={{ width: "100%" }}>
              <div
                style={{ width: "115px" }}
                onClick={() => setviewMsg("default")}
              >
                <SmallBtn
                  padding="0"
                  label="Back to inbox"
                  icon={<TiArrowBack />}
                />
              </div>

              <div className="msgView" style={{ marginTop: "15px" }}>
                <div className="msgViewContainer">
                  <div className="msgViewContainerTop">
                    <div
                      className="msgViewContainerTopImage"
                      style={{ backgroundImage: `url(${image})` }}
                    ></div>
                    <div>
                      <p>
                        {" "}
                        {subject.charAt(0)?.toUpperCase() + subject.slice(1)} (
                        {delivery_id})
                      </p>

                      <p>
                        {" "}
                        <img src={warngreen} alt="msgpi_icon" />
                        &ensp;{pickup}
                      </p>

                      <p>
                        {" "}
                        <img src={warnrred} alt="msgpi_icon" />
                        &ensp;{destination}
                      </p>
                    </div>
                  </div>
                  {reverseArr(conversation).map((converse, index, deatils) => {
                    return (
                      <div key={index}>
                        {converse.fulfiller_id === undefined ? (
                          <div
                            className="msgCard"
                            style={{ backgroundColor: "#E6F4E5" }}
                          >
                            <div
                              className="msgCardPics"
                              style={{ backgroundColor: "#c4c4c4" }}
                            ></div>
                            <div className="msgCardChat">
                              <h4>
                                {sender_name.charAt(0)?.toUpperCase() +
                                  sender_name.slice(1)}
                              </h4>

                              <span>
                                {converse.msg.charAt(0)?.toUpperCase() +
                                  converse.msg.slice(1)}
                              </span>
                            </div>
                            <span
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                              }}
                            >
                              <div
                                className="timediplaydesk"
                                style={{ fontSize: "10px" }}
                              >
                                {moment(converse.time_stamp).format(
                                  "MMM Do YYYY, h:mm a "
                                )}
                                ({moment(converse.time_stamp).fromNow()})
                              </div>
                              <div
                                className="timediplaymobile"
                                style={{ fontSize: "7px" }}
                              >
                                {moment(converse.time_stamp).format(
                                  "MMM Do YYYY, h:mm a "
                                )}
                                ({moment(converse.time_stamp).fromNow()})
                              </div>
                            </span>
                          </div>
                        ) : (
                          <div className="msgCard">
                            <div className="msgCardPics"></div>
                            <div className="msgCardChat">
                              <h4>{converse.fulfiller_id}</h4>

                              <span>
                                {converse.msg.charAt(0)?.toUpperCase() +
                                  converse.msg.slice(1)}
                              </span>
                            </div>
                            <span
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                fontSize: "10px",
                              }}
                            >
                              <div
                                className="timediplaydesk"
                                style={{ fontSize: "10px" }}
                              >
                                {moment(converse.time_stamp).format(
                                  "MMM Do YYYY, h:mm a "
                                )}
                                ({moment(converse.time_stamp).fromNow()})
                              </div>
                              <div
                                className="timediplaymobile"
                                style={{ fontSize: "7px" }}
                              >
                                {moment(converse.time_stamp).format(
                                  "MMM Do YYYY, h:mm a "
                                )}
                                ({moment(converse.time_stamp).fromNow()})
                              </div>
                            </span>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div
                style={{
                  padding: "20px",
                  border: "1px solid #c4c4c4",
                  borderTop: "none",
                }}
              >
                <textarea
                  required
                  type="text"
                  className="textarea"
                  value={reply}
                  onChange={(e) => {
                    setReply(e.target.value);
                    setisRequired(false);
                  }}
                  placeholder="Type your message..."
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    border: isRequired ? "1px solid red" : "1px solid #C4C4C4",
                    color: "black",
                  }}
                  rows="4"
                />
                <p>{errors.message?.message}</p>
                <div
                  className="acceptBTN"
                  type="submit"
                  value="submit"
                  style={{ width: "130px" }}
                  onClick={replyMessage}
                >
                  {sendingMsg ? (
                    <PuffLoader
                      color="white"
                      loading={sendingMsg}
                      speedMultiplier={1}
                      size={16}
                    />
                  ) : (
                    <>
                      <span>Send message </span>{" "}
                      <div>
                        <MdNavigateNext />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="msgDivCont">
            {from === "items" ? (
              <div style={{ width: "200px", marginBottom: "15px" }}>
                <SmallBtn
                  label="Back"
                  icon={<TiArrowBack />}
                  onClick={handleChange}
                />
              </div>
            ) : null}
            <div className>
              <div className="topPaginate noshow" style={{ marginBottom: "0" }}>
                <p style={{ color: "var(--main)" }}>
                  {" "}
                  Result: {paginateActivePage * n - (n - 1)} of{" "}
                  {paginateActivePage * n}
                </p>
                <div>
                  <div
                    className="topPaginate noshow"
                    style={{ marginBottom: "0", border: "none" }}
                  >
                    <div style={{ border: "none" }}>
                      <span
                        className="paginateButtons"
                        style={{
                          color: paginateActivePage === 1 ? "white" : "",
                          backgroundColor:
                            paginateActivePage === 1 ? "var(--main)" : "",
                          cursor:
                            paginateActivePage === 1
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() => {
                          setEnquiryMsg(msg.slice(0, n));
                          setPaginateActivePage(1);
                        }}
                      >
                        First
                      </span>
                      {paginateActivePage === 1 ? (
                        <span
                          className="paginateButtons"
                          style={{ color: "#989898", cursor: "not-allowed" }}
                        >
                          Previous
                        </span>
                      ) : (
                        <span
                          className="paginateButtons"
                          onClick={() => {
                            setEnquiryMsg(
                              msg.slice(
                                (paginateActivePage - 2) * n,
                                (paginateActivePage - 1) * n
                              )
                            );
                            setPaginateActivePage(paginateActivePage - 1);
                          }}
                        >
                          Previous
                        </span>
                      )}

                      {[...Array(Math.ceil(msgLength / n))].map((e, i) => (
                        <span
                          className="paginateButtons"
                          style={{
                            backgroundColor:
                              paginateActivePage === i + 1 ? "var(--main)" : "",
                            color: paginateActivePage === i + 1 ? "white" : "",
                          }}
                          key={i}
                          onClick={() => {
                            setEnquiryMsg(msg.slice(n * i, n * i + n));
                            setPaginateActivePage(i + 1);
                          }}
                        >
                          {i + 1}
                        </span>
                      ))}
                      {paginateActivePage === Math.ceil(msgLength / n) ? (
                        <span
                          className="paginateButtons"
                          style={{ color: "#989898", cursor: "not-allowed" }}
                        >
                          Next
                        </span>
                      ) : (
                        <span
                          className="paginateButtons"
                          onClick={() => {
                            setEnquiryMsg(
                              msg.slice(
                                n * paginateActivePage,
                                n * (paginateActivePage + 1)
                              )
                            );
                            setPaginateActivePage(paginateActivePage + 1);
                          }}
                        >
                          Next
                        </span>
                      )}
                      <span
                        className="paginateButtons"
                        style={{
                          backgroundColor:
                            paginateActivePage === Math.ceil(msgLength / n)
                              ? "var(--main)"
                              : "",
                          color:
                            paginateActivePage === Math.ceil(msgLength / n)
                              ? "white"
                              : "",
                        }}
                        onClick={() => {
                          setEnquiryMsg(
                            msg.slice(Math.floor(msgLength / n) * n, msgLength)
                          );
                          setPaginateActivePage(Math.ceil(msgLength / n));
                        }}
                      >
                        Last
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" paginationmobile">
                <div>
                  <div className=""></div>
                </div>
              </div>

              <div className="messagess noshow">
                <div
                  className="messageFrom"
                  style={{ color: "black", fontSize: "12px", width: "20%" }}
                >
                  {" "}
                  <input
                    className="ff"
                    type="checkbox"
                    id="vehicle1"
                    name="vehicle1"
                    value="Bike"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; From{" "}
                </div>
                <div
                  className="messageSubj"
                  style={{ color: "black", fontSize: "12px", width: "20%" }}
                >
                  {" "}
                  Subject
                </div>
                <div
                  className="messageSubj"
                  style={{
                    color: "black",
                    fontSize: "12px",
                    width: "50%",
                    paddingLeft: "30px",
                  }}
                >
                  {" "}
                  Message
                </div>
                {/* <div className="messageTime" style={{ color: 'black', fontSize: '12px' }}>Recieved </div> */}
              </div>

              <div className="mobileMsgHead">
                <div
                  className="messageFrom"
                  style={{ color: "black", fontSize: "12px", width: "20%" }}
                >
                  Subject{" "}
                </div>
                {/* <div className="messageSubj" style={{ color: 'black', fontSize: '16px', width: '20%' }}> Subject</div> */}
                <div
                  className="messageSubj"
                  style={{
                    color: "black",
                    fontSize: "12px",
                    width: "80%",
                    paddingLeft: "30px",
                  }}
                >
                  {" "}
                  Message
                </div>
                <div
                  className="messageTimemobile"
                  style={{ color: "black", fontSize: "12px" }}
                >
                  {" "}
                </div>
              </div>

              {enquiryMsg.map((msg, index) => {
                return (
                  <div
                    key={index}
                    className="messagess"
                    onClick={() => {
                      setviewMsg("view");
                      setconversation(msg.conversation);
                      setsubject(msg["subject:quote"]);
                      setpickup(msg.pickup_address);
                      setdestination(msg.delivery_address);
                      setSender_name(msg.sender_name);
                      setimage(msg.image_urls[0]);
                      setdelivery_id(msg["delivery_id"]);
                      setfulfiller_id(msg["fulfiller_id"]);
                    }}
                  >
                    {enquiryMsg.length === 0 ? (
                      <div className="tablerowwrapper">
                        You dont have message yet
                      </div>
                    ) : (
                      <>
                        <div
                          className="messageFrom noshow"
                          style={{
                            width: "20%",
                            cursor: "pointer",
                            fontSize: "12px",
                            color: "black",
                          }}
                        >
                          {msg.conversation[0]?.fulfiller_id === undefined ? (
                            <div className="tableRowfrom">
                              <input
                                className="ff"
                                type="checkbox"
                                id="vehicle1"
                                name="vehicle1"
                                value="Bike"
                              />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                              {msg.fulfiller_id}
                            </div>
                          ) : (
                            <div className="tableRowfrom">
                              {" "}
                              <span>
                                <span>
                                  {" "}
                                  <input
                                    className="ff"
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                  />{" "}
                                </span>
                              </span>{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                              <span> {msg.conversation[0]?.fulfiller_id}</span>
                            </div>
                          )}
                        </div>
                        <div
                          className="messageSubj"
                          style={{
                            width: "20%",
                            cursor: "pointer",
                            fontSize: "12px",
                            color: "black",
                          }}
                        >
                          <p onClick={() => setviewMsg("view")}>
                            {" "}
                            {msg["subject:quote"]}{" "}
                          </p>
                          {/* <span style={{ color:"#000000"}}>  {msg.conversation[0]?.msg}</span>  */}
                        </div>
                        <div
                          className="messageSubj"
                          style={{
                            width: "50%",
                            paddingLeft: "30px",
                            fontSize: "12px",
                          }}
                        >
                          {/* <p onClick={() => setviewMsg('view')}> {msg['subject:quote']} </p> */}
                          <span style={{ color: "#000000" }}>
                            {" "}
                            {msg.conversation[0]?.msg}
                          </span>
                        </div>
                        <div
                          style={{ fontSize: "10px" }}
                          className="messageTime"
                        >
                          {/* {moment(msg.msg_time_stamp).format("MMM DD, YY  hh:mma")} */}

                          {/* {moment(msg.msg_time_stamp).format("MMM DD, YY  hh:mma")} */}

                          {moment().diff(msg.msg_time_stamp, "days") === 0 ? (
                            <>{moment(msg.msg_time_stamp).format("hh:mm A")}</>
                          ) : (
                            <>{moment(msg.msg_time_stamp).format("MMM D")}</>
                          )}
                        </div>
                        {/* : */}
                        <>
                          {/* <ViewFulfiller details={fulfillerToShow} handleChange={()=>setshowQuoteType('quote')} /> */}
                          {/* <ViewFulfiler2 details={fulfillerToShow} handleChange={() => setshowQuoteType('quote')} /> */}
                        </>{" "}
                      </>
                    )}
                  </div>
                );
              })}

              <div className="mobileMsgfooter">
                {/* <p style={{ color: 'var(--main)' }}> Result:  {((paginateActivePage * n) - (n - 1))} of {paginateActivePage * n}</p> */}
                <div style={{ border: "none", width: "60%" }}>
                  <span
                    className="paginateButtons"
                    style={{
                      color: paginateActivePage === 1 ? "white" : "",
                      backgroundColor:
                        paginateActivePage === 1 ? "var(--main)" : "",
                      cursor:
                        paginateActivePage === 1 ? "not-allowed" : "pointer",
                    }}
                    onClick={() => {
                      setEnquiryMsg(msg.slice(0, n));
                      setPaginateActivePage(1);
                    }}
                  >
                    First
                  </span>
                  {paginateActivePage === 1 ? (
                    <span
                      className="paginateButtons"
                      style={{ color: "#989898", cursor: "not-allowed" }}
                    >
                      Previous
                    </span>
                  ) : (
                    <span
                      className="paginateButtons"
                      onClick={() => {
                        setEnquiryMsg(
                          msg.slice(
                            (paginateActivePage - 2) * n,
                            (paginateActivePage - 1) * n
                          )
                        );
                        setPaginateActivePage(paginateActivePage - 1);
                      }}
                    >
                      Previous
                    </span>
                  )}

                  {[...Array(Math.ceil(msgLength / n))].map((e, i) => (
                    <span
                      className="paginateButtons"
                      style={{
                        backgroundColor:
                          paginateActivePage === i + 1 ? "var(--main)" : "",
                        color: paginateActivePage === i + 1 ? "white" : "",
                      }}
                      key={i}
                      onClick={() => {
                        setEnquiryMsg(msg.slice(n * i, n * i + n));
                        setPaginateActivePage(i + 1);
                      }}
                    >
                      {i + 1}
                    </span>
                  ))}
                  {paginateActivePage === Math.ceil(msgLength / n) ? (
                    <span
                      className="paginateButtons"
                      style={{ color: "#989898", cursor: "not-allowed" }}
                    >
                      Next
                    </span>
                  ) : (
                    <span
                      className="paginateButtons"
                      onClick={() => {
                        setEnquiryMsg(
                          msg.slice(
                            n * paginateActivePage,
                            n * (paginateActivePage + 1)
                          )
                        );
                        setPaginateActivePage(paginateActivePage + 1);
                      }}
                    >
                      Next
                    </span>
                  )}
                  <span
                    className="paginateButtons"
                    style={{
                      backgroundColor:
                        paginateActivePage === Math.ceil(msgLength / n)
                          ? "var(--main)"
                          : "",
                      color:
                        paginateActivePage === Math.ceil(msgLength / n)
                          ? "white"
                          : "",
                    }}
                    onClick={() => {
                      setEnquiryMsg(
                        msg.slice(Math.floor(msgLength / n) * n, msgLength)
                      );
                      setPaginateActivePage(Math.ceil(msgLength / n));
                    }}
                  >
                    Last
                  </span>
                </div>
              </div>
              <div className="DesktopMsgfooter">
                <p style={{ color: "var(--main)" }}>
                  {" "}
                  Result: {paginateActivePage * n - (n - 1)} of{" "}
                  {paginateActivePage * n}
                </p>
                {/* <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike"/>
                                                                <label for="vehicle1"> I have a bike</label> 
                                                                <input type="checkbox" class="checkbox-round" /> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default MessageSender;
