import React, {useEffect, useState, useRef, useContext } from "react";
import Nav from "../../components/Nav/Nav";
import "./Tracking.css";
import IndividualSidebar from "../../components/Sidebar/IndividualSidebar";
import imagelogo from "../../assets/Dillivry.png";
import { useHistory, useParams } from "react-router-dom";
import itemdescr_ from "../../assets/itemdescr_.svg";
import deliveryid from '../../assets/deliveryid.svg'
import Deliverytype from '../../assets/Deliverytype.svg'
import Deliverytimeframe from '../../assets/Deliverytimeframe.svg'
import Deliverystatus from '../../assets/Deliverystatus.svg'
import Timetodestination from '../../assets/Timetodestination.svg'
import SecureLS from 'secure-ls';
import GoogleMapWidget from "../../components/Map/GoogleMapWidget";
import GoogleMapWidget2 from "../../components/Map/GoogleMapWidget2";

export default function OutforDelivery({data}) {
 
  const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
 

const [origin, setorigin] = useState([]);
const [destination, setdestination] = useState([]);
const [showlogin, setshowlogin] = useState(false)

useEffect(() => {
  console.log( console.log(data, "data")
  )
  if (data?.length !== 0) {
    console.log(data, 'data')
    const startLat = parseFloat(data?.agent_location?.lat);
    const startlng = parseFloat(data?.agent_location?.lng);
   const endLat = parseFloat(data?.end_location?.lat);
   const endLng = parseFloat(data?.end_location?.lng);

   setorigin([
    {
      id: "pickup",
      title: "Pickup",
      coordinates: {
        lat: startLat,
        lon: startlng,
      },
    },
  ]);
  setdestination([
    {
      id: "destination",
      title: "Destination",
      coordinates: {
        lat: endLat,
        lon: endLng,
      },
    },
  ]);
  }
}, [])

return (
    <div className="tracking-body">
      <div className="tracking-content">
        <div className="traction-section-one">
          <section className="setion-one-left">
            <h1 className="section-one-left-title">Out for delivery</h1>
            <p className="section-one-left-heading">
              Your package will arrive in {data?.time_to_destination}
            </p>
          </section>

          <section className="section-one-right">
            <img
              src={data?.image_urls[0]}
              alt="Chairsample"
              className="section-one-right-img"
            />
          </section>
        </div>

        <div className="tracting-section-two">
          <div className="tracting-code-paragraph-div">
            <p className="tracking-code-paragraph-p">
              {" "}
              Your delivery code is {data?.delivery_code}. To validate you as the receiver, the
              agent will need this code to confirm delivery.
            </p>
          </div>

          <div>
          <div className="view_Map">
        <GoogleMapWidget2
          origins={destination}
          destinations={origin}
        />
      </div>
          </div>

        </div>

        <div className="tracking-section-three">
          <section className="assigned-agent">
            <p className="agent-title-header">Assigned agent</p>
            <div className="section-after-assigned-agent">
              <section className="agent-img">
                {" "}
                <img
                  src={data?.assigned_agent_picture === undefined ? data?.d_agent_pics : data?.assigned_agent_picture }
                  alt="AgentImage"
                  className="Agent-image-"
                />{" "}
              </section>

              <section className="agent-name-and-id">
                <p className="agent-name">{data?.assigned_agent_name}</p>
                <p className="agent-id">{data?.delivery_agent_id}</p>
              </section>
            </div>
          </section>

          <section className="Delivery-information-section">
          <div className="tracting-code-paragraph-div">
            <p className="tracking-code-paragraph-p">
              {" "}
              Delivery Information
            </p>
          </div>
            <div className="cnt-body"   >
            <div className="delivery-info-inner-content">
              <div className="inner">
                <img src={itemdescr_} alt="itemdesc" className="itemdesc" />
                <p className="desc-name-title">Description</p>
              </div>

              <div className="right-desc-detail">
                <p className="main-desc">{data?.item_desc}</p>
              </div>
            </div>

            <div className="delivery-info-inner-content">
              <div className="inner">
                <img src={deliveryid} alt="itemdesc" className="itemdesc" />
                <p className="desc-name-title"    >Delivery ID:</p>
              </div>



              <div className="right-desc-detail">
                <p className="main-desc">{data?.delivery_id}</p>
              </div>
            </div>




            <div className="delivery-info-inner-content">
              <div className="inner">
                <img src={Deliverytype} alt="itemdesc" className="itemdesc" />
                <p className="desc-name-title"   >Delivery Type:</p>
              </div>



              <div className="right-desc-detail">
                <p className="main-desc">{data?.delivery_type?.charAt(0)
                                          ?.toUpperCase()
                                          ?.replace(/_/g, "") + data?.delivery_type?.slice(1)}</p>
              </div>
            </div>




            <div className="delivery-info-inner-content">
              <div className="inner">
                <img src={Deliverytimeframe} alt="itemdesc" className="itemdesc" />
                <p className="desc-name-title"   >Delivery time frame:</p>
              </div>



              <div className="right-desc-detail">
                <p className="main-desc">{data?.delivery_time_frame}</p>
              </div>
            </div>




            <div className="delivery-info-inner-content">
              <div className="inner">
                <img src={Deliverystatus} alt="itemdesc" className="itemdesc" />
                <p className="desc-name-title"   >Delivery status:</p>
              </div>



              <div className="right-desc-detail">
                <p className="main-desc">{data?.delivery_status  === 'out_for_delivery' ? 'Out for delivery' : null}</p>
              </div>
            </div>



            <div className="delivery-info-inner-content">
              <div className="inner">
                <img src={Timetodestination} alt="itemdesc" className="itemdesc" />
                <p className="desc-name-title"   >Time to destination:</p>
              </div>



              <div className="right-desc-detail">
                <p className="main-desc">{data?.time_to_destination} to destination</p>
              </div>
            </div>
            </div>
          </section>

          <section className="empty-section-to-keep-space">

          </section>




               


               
        </div>
      </div>


      {/* <Footer/> */}
    </div>
  );
}
