import {IoMdArrowRoundBack} from 'react-icons/io'
import Dillivry from '../../assets/Dillivry.png'
import './TopNavigation.css'
import {useHistory} from 'react-router-dom'

function TopNavigation({help, onBackClick, openHelp}) {
    const history = useHistory();

    return (
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:'30px'}}>
            <div onClick={onBackClick} style={{cursor:'pointer', zIndex:'100'}}><IoMdArrowRoundBack size='30px'/></div>
            
            <div className="dLogo" style={{cursor:'pointer'}}>
                <img src={Dillivry} alt="dillivry logo" style={{width:'90px'}} onClick={()=>history.push('/')}/>
            </div>
            <div style={{cursor:'pointer', zIndex:'100'}} onClick={openHelp}>{help}</div>
        </div>
    )
}

export default TopNavigation
