import React, { useState, useEffect } from 'react'
import { usePaystackPayment} from 'react-paystack';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import Swal from 'sweetalert2'
import { ClassNames } from '@emotion/react';

const PaystackWidgetReceiver = ({amount, email, delivery_id, fulfiller_id, amount_paid, vcc, sender_id }) => {
  const history = useHistory();
  const [TransDate, setTransDate] = useState('')

    useEffect(() => {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      setTransDate(date);
    }, [])

    const endpoint = `${process.env.REACT_APP_API}receiver-payment`
    
    const config = {
      reference: (new Date()).getTime(),
      email: email,
      amount: amount,
      // currency: 
      channels: ['card', 'ussd'],
      // channels: ['card', 'bank', 'ussd', 'qr', 'mobile_money'],
      publicKey: process.env.REACT_APP_ENVIRONMENT === "PROD" || process.env.REACT_APP_ENVIRONMENT === "TEST" ? process.env.REACT_APP_PAYSTACK_KEY : process.env.REACT_APP_PAYSTACK_KEY_DEMO, 

  };

  // you can call this function anything
  const onSuccess = (reference) => {

    // console.log("reference: ", reference)

    // if(reference.status === 'success'){
      axios.post(`${endpoint}`,{
        action_type: "make_payment",
        delivery_id: delivery_id,
        amount_paid: amount_paid,
        payment_date: TransDate,
        quoted_amount: amount_paid,
        payment_status: 'success',
        StatusCode: 200,
        reference_id: reference.reference,
        message: reference.message,
        transaction: reference.transaction,
        user_id: sender_id,
        fulfiller_id: fulfiller_id,
        vcc: vcc,
        payment_gateway: "paystack",
      }, {timeout: 240000})
      .then((response)=>{
        // console.log(response, "response")
          Swal.fire({
            icon: 'success',
            text: "Payment made successfully" ,
            confirmButtonColor:'var(--main)',
            ClassName:"swalpaymentpop"
          })
          .then((result)=>{
            if(result.value){
              history.push('/')
            }
          })
      })
      .catch((err)=>{
        console.log(err, 'error')
      })
    // }
    // Implementation for whatever you want to do with reference and after success call.
    
  };

  // you can call this function anything
  const onClose = () => {
  }

    const initializePayment = usePaystackPayment(config);
    return (
          <div style={{padding:'8px 10px'}}
              onClick={() => {
                // closePopup();
                initializePayment(onSuccess, onClose);
               
              }}
              >
              <span style={{cursor:"pointer",borderRadius:"0px"}}> Make Payment </span>
               <div>
                 {/* <MdNavigateNext /> */}
               </div>
          </div>
        
    );
};

export default PaystackWidgetReceiver