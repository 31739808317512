import { FaShuttleVan } from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md';
import { IoMdOpen, IoMdMailOpen } from 'react-icons/io'
import { useContext, useEffect, useState } from 'react'
import DialogOutClick from '../../../../components/DialogOutClick'
import SubmitQuote from './SubmitQuote';
import ReactTooltip from 'react-tooltip';
import { IconContext } from "react-icons";
import { useHistory } from 'react-router-dom';
import { ConnContext } from '../../../../context/ConnContext';
import GlobalizationLanguage , {GlobalizationLanguagewithCOntryCode} from '../../../../components/GlobalizationLanguage';
import Cookies from 'js-cookie';
import Currency from '../../../../utils/Currency';


function OpenRequestCard({data}) {

    const history = useHistory();

    var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

    useEffect(() => {
        setCountry(Cookies.get('i18next'));
      },[Cookies.get('i18next')])

    const [country,setCountry]=useState(countryvalue);

    const showMe = (delivery_id) => {
        var link = GlobalizationLanguagewithCOntryCode(`/open-delivery/${delivery_id}`, country, visitingCountryCode)
        history.push(link)
    }
    
    const [openQuote, setopenQuote] = useState(false)
    const [itemToQuote, setitemToQuote] = useState([])
    const handleClickOpen = () => {
        setopenQuote(true);
    };

    const handleClose = () => {
        setopenQuote(false);
    };
    const { visitingCountryCode } = useContext(ConnContext)

    const numberWithCommas = new Intl.NumberFormat("en-GB")

    return (
        <>
      <h3 style={{paddingLeft:'15px'}}>Open requests</h3>
            {
                data.map((item, index) => {
                    return <div style={{ width: '100%'}} key={index}>
                    <div className="quoteCard">
                        <div className="quoteBody">
                            <div className="quoteBodyDetails">
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <div className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${item.image_urls[0]})` }}
                                        onClick={() => { showMe(item.delivery_id) }}>
                                    </div>
                                    <div className="quoteBodyDetailsLeft">
                                        <div className="quoteBody_Desc">
                                            <span onClick={() => showMe(item.delivery_id)}>{item?.item_desc?.charAt(0)?.toUpperCase() + item.item_desc?.slice(1)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', width: '80px', justifyContent: 'flex-end', }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                       {
                                            item?.best_quote !== undefined ? 
                                            <div>
                                                <div style={{ fontSize: '14px' }}><Currency location={Cookies.get('i18next')}/>{numberWithCommas.format(item?.best_quote)}</div>
                                                <div style={{ fontSize: '9px', color: 'var(--main)' }}>Best quote</div>
                                            </div>
                                            :
                                            null
                                       }
                                        {
                                            item?.quote_amount !== undefined ? 
                                            <div>
                                                <div style={{ fontSize: '14px' }}><Currency location={Cookies.get('i18next')}/>{numberWithCommas.format(item.quote_amount)}</div>
                                                <div style={{ fontSize: '9px', color: item.quote_amount == item?.best_quote ? 'var(--main)' : 'red' }}>Your quote</div>
                                            </div>
                                            :
                                            null
                                       }
                                    </div>
                                </div>
                            </div>

                            <div className="quoteBodyBtn">
                                <div>
                                    <div className="quoteBody_items">
                                        <span data-tip
                                            data-for="globalMode">

                                            <IconContext.Provider
                                                value={{
                                                    color: "#2e2e2e",

                                                }}
                                            >
                                                <FaShuttleVan color="green" size='12px' />
                                            </IconContext.Provider>
                                            <ReactTooltip
                                                id="globalMode"
                                                aria-haspopup="true"
                                                role="example"
                                                type="light"
                                            >
                                                <p>Mode of Delivery</p>
                                            </ReactTooltip>
                                        </span>
                                        <div>{item?.mode_of_delivery?.charAt(0)?.toUpperCase() + item?.mode_of_delivery?.toLowerCase().slice(1)}</div>
                                    </div>
                                    <div className="quoteBody_items">
                                        <span data-tip
                                            data-for="globaldelibery">

                                            <IconContext.Provider
                                                value={{
                                                    color: "#2e2e2e",

                                                }}
                                            >
                                                <MdLocationOn color="green" size='12px' />
                                            </IconContext.Provider>
                                            <ReactTooltip
                                                id="globaldelibery"
                                                aria-haspopup="true"
                                                role="example"
                                                type="light"
                                            >
                                                <p>PickUp Address</p>
                                            </ReactTooltip>
                                        </span>
                                        <div>{item?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                    </div>
                                    <div className="quoteBody_items">
                                        <span data-tip
                                            data-for="globaldistance">
                                            <IconContext.Provider
                                                value={{
                                                    color: "#2e2e2e",

                                                }}
                                            >
                                                <MdLocationOn color="red" size='12px' />
                                            </IconContext.Provider>
                                            <ReactTooltip
                                                id="globaldistance"
                                                aria-haspopup="true"
                                                role="example"
                                                type="light"
                                            >
                                                <p>Delivery Address </p>
                                            </ReactTooltip>
                                        </span>
                                        <div>{item?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                    </div>
                                    <div className="quoteBody_items statusCheck" > Status: <span style={{color:'#004DC1'}}>Awaiting quote</span> </div>
                                    <div className="quoteBody_items statusCheck" > Mode of payment: <span style={{color:'#004DC1'}}>
                                                
                                                {
                                                    typeof( item?.mode_of_payment) === "string"  ?
                                                    <>
                                                    {
                                                    item?.mode_of_payment === "pay_by_cash" ?
                                                    <span>Cash</span>:
                                                    item?.mode_of_payment === "pay_by_transfer" ?
                                                    <span>Transfer</span>:
                                                    item?.mode_of_payment === "pay_by_ussd" ?
                                                    <span>USSD</span>:
                                                    item?.mode_of_payment === "pay_by_wallet" ?
                                                    <span>Online</span>:
                                                    item?.mode_of_payment === "pay_by_card" ?
                                                    <span>Card</span>:
                                                    <span>{item?.mode_of_payment}</span>
                                                    }
                                                    
                                                    </>
                        
                                                    :
                                                    <span>N/A</span>


                                                }
                                 
                                </span>
                                 
                            </div>
                                </div>
                                <div style={{ display: 'flex', gap: '15px', alignItems:'center', justifyContent: "center" }}>
                                    <span onClick={() => { setitemToQuote(item); handleClickOpen() }}>
                                        <IoMdOpen size='20px'  color='#484848' />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                })
            }
          
            <DialogOutClick
                open={openQuote}
                handleClose={handleClose}
            >
                <SubmitQuote item={itemToQuote} closePopup={handleClose} />
            </DialogOutClick>
        </>
    )
}

export default OpenRequestCard
