import { useState, useEffect,useRef, useContext } from "react";
import Layout from "../../../Layout";
import "./QuotesStatus.css";
import { useHistory, useLocation } from "react-router-dom";
import { SenderContext } from "../../../../../../context/SenderDashboard/SenderDashboardContext";
import axios from "axios";
import SecureLS from "secure-ls";
import { ConnContext } from "../../../../../../context/ConnContext";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@material-ui/core";
import SenderBottomNav from "../../../../components/SenderBottomNav";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import Wunmi_small from '../../../../../../assets/wunmi_small.png'

import GlobalizationLanguage, {
  GlobalizationLanguagewithCOntryCode,
} from "../../../../../../components/GlobalizationLanguage";
import moment from "moment";
import SenderTopBarBackArrow from "../../../../components/SenderTopBarBackArrow";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useOutsideClick } from "../../../../../../hooks/useOutsideClick";
import { ClickAwayListener, IconButton, Tooltip } from "@mui/material";

export default function QuotesItemStatus() {
 
  const location = useLocation();

  const { delivery_id } = useParams()

  const [showClickMessage, setshowClickMessage] = useState(false)

  const endpoint = process.env.REACT_APP_API;
  var countryvalue =
    Cookies.get("i18next") || visitingCountryCode.toLowerCase();
  const [country, setCountry] = useState(countryvalue);

  const convertDate = (value) =>{
    // converting 20-06-2022 20:00:52 +01:00 to 2022-06-20 20:00:52
    let x = value.split(" ")
    let changeFormat = x[0].split('-').reverse().join("-")
    return changeFormat + " " + x[1]
  }

  useEffect(() => {
    setCountry(Cookies.get("i18next"));
    console.log()
  }, [Cookies.get("i18next")]);

  const history = useHistory();
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const [deliveryInfo, setdeliveryInfo] = useState([])

  const {
    initialstate,
    UpdateData,
    UpdateAlldata,
  } = useContext(SenderContext);

  const { visitingCountryCode } = useContext(ConnContext);

  const [deliveries, setDeliveries] = useState([]);
  const [loading, setloading] = useState(false);
  const [show, setshow] = useState(false);
  const [searchclick, setsearchclick] = useState(false);

  const [pageViewData, setpageViewData] = useState([])

  useEffect(()=>{
    if (location?.state === "fromAccept") {
      axios
      .get(`${endpoint}m-dillivry-sender-api`, {
        headers: {
          Authorization: ls.get("_utk"),
        },
      })
      .then((res) => {
        if (res.status === 200) {
          // update all deliveries response data to context
          UpdateData(res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());
          // update UI data with response data to context
          UpdatePage(res.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());
        }
        // update all response data to context
        UpdateAlldata(res);

      })
      .catch((err) => {
        setloading(false);
        console.log(err);

      });
    }
    history.replace({ state: {} });
  },[])

  useEffect(() => {
    setloading(true);
    setshow(false);
    if (initialstate !== undefined) {
      UpdatePage(initialstate);
    } else {
      axios
        .get(`${endpoint}m-dillivry-sender-api`, {
          headers: {
            Authorization: ls.get("_utk"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            // update all deliveries response data to context
            UpdateData(res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());

            // update UI data with response data to context
            UpdatePage(res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());
          }
          // update all response data to context
          UpdateAlldata(res);

        })
        .catch((err) => {
          setloading(false);
          console.log(err);

        });
    }
  }, []);

  const return_quote_date = (quote) =>{
    for (var key in quote) {
      if (quote.hasOwnProperty(key)) {
        // console.log(key, "key"); 
        return quote[key].time_stamp; 
      }
    }  
  }

  const UpdatePage = (datum) => {
    setshow(false);
    if (datum !== undefined) {
      const awaiting_quotes = datum?.map((x) => x?.awaiting_quote)?.flat()
      const awaiting_ack = datum?.map((x) => x?.awaiting_acknowledgment)?.flat()
      const awaiting_agent_assmt = datum?.map((x) => x?.awaiting_agent_assignment)?.flat()
      const pick_up = datum?.map((x) => x?.awaiting_pickup)?.flat()
      const intransit_data = datum?.map((x) => x?.in_transit)?.flat()
      const out_for_delivery_data = datum?.map((x) => x?.out_for_delivery)?.flat()
      const completed_data = datum?.map((x) => x?.past_deliveries)?.flat();
      // const missed_data = datum?.map((x) => x?.missed_delivery)?.flat();
      // const expired_data = datum?.map((x) => x?.expired_deliveries)?.flat();

      const data = [
        ...awaiting_quotes,
        ...awaiting_ack,
        ...awaiting_agent_assmt,
        ...pick_up,
        ...intransit_data,
        ...out_for_delivery_data,
        ...completed_data,
        // ...missed_data,
        // ...expired_data,
      ];
      setDeliveries(data.filter(x => x.delivery_id === delivery_id));
      const deliveryInfo = data.filter(x => x.delivery_id === delivery_id);

      setdeliveryInfo(deliveryInfo)
      // console.log(deliveryInfo, "ghdgshdg")
      
      if (deliveryInfo[0].delivery_status === "awaiting_quote" && deliveryInfo[0].total_quote_received === 0) {
        setpageViewData([{
          listing: {
            title: "Delivery request successful",
            date: deliveryInfo[0].date_listed,
            message: "Your delivery request has been placed",
            status: 'completed',
          },
          awaiting: {
            title: "Awaiting quote",
            date: deliveryInfo[0].date_listed,
            message: "We are awaiting providers to place quote",
            status: 'active', clickable: 'yes',
            popup_message: <span>Please wait while providers drop their prices.</span>,
            delivery_id: deliveryInfo[0].delivery_id,
            vcc: deliveryInfo[0].vcc,
          },
          recieved: {
            title: "Received quote",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          awaiting_ack: {
            title: "Awaiting acknowledgment",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          awaiting_agent: {
            title: "Awaiting agent assignment",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          awaiting_pickup: {
            title: "Awaiting pickup",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          in_transit: {
            title: "In-transit",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          out_for_delivery: {
            title: "Out for delivery",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          completed: {
            title: "Completed",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          }

        }])
      }
      else if (deliveryInfo[0].delivery_status === "awaiting_quote" && deliveryInfo[0].total_quote_received > 0) {
        setpageViewData([{
          listing: {
            title: "Delivery request successful",
            date: deliveryInfo[0].date_listed,
            message: "Your delivery request has been placed",
            status: 'completed',
          },
          awaiting: {
            title: "Awaiting quote",
            date: deliveryInfo[0].date_listed,
            message: "We are awaiting providers to place quote",
            status: 'completed'
          },
          recieved: {
            title: "Received quote",
            date: `${return_quote_date(deliveryInfo[0]?.quote)}`,
            message: `You have received ${deliveryInfo[0].total_quote_received} ${deliveryInfo[0].total_quote_received === 1 ? "quote" : "quotes"}`,
            status: 'active', clickable: 'yes',
            popup_message: <span>Your request has received <span className="span_color"> {deliveryInfo[0].total_quote_received} {deliveryInfo[0].total_quote_received === 1 ? "quote" : "quotes"} </span> from different {deliveryInfo[0].total_quote_received === 1 ? "provider" : "providers"}. Click on the <span className="span_color"> {deliveryInfo[0].total_quote_received === 1 ? 'Received quote' : 'Received quotes'} </span> to review and accept a suitable price.</span>,
            delivery_id: deliveryInfo[0].delivery_id,
            vcc: deliveryInfo[0].vcc,

          },
          awaiting_ack: {
            title: "Awaiting acknowledgment",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          awaiting_agent: {
            title: "Awaiting agent assignment",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          awaiting_pickup: {
            title: "Awaiting pickup",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          in_transit: {
            title: "In-transit",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          out_for_delivery: {
            title: "Out for delivery",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          completed: {
            title: "Completed",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          }

        }])
      }
      else if (deliveryInfo[0].delivery_status === "awaiting_provider_acknowledgment") {
        setpageViewData([{
          listing: {
            title: "Delivery request successful",
            date: deliveryInfo[0].date_listed,
            message: "Your delivery request has been placed",
            status: 'completed',
          },
          awaiting: {
            title: "Awaiting quote",
            date: deliveryInfo[0].date_listed,
            message: "We are awaiting providers to place quote",
            status: 'completed'
          },
          recieved: {
            title: "Received quote",
            date: `${return_quote_date(deliveryInfo[0]?.quote)}`,
            message: `You have received ${deliveryInfo[0].total_quote_received} ${deliveryInfo[0].total_quote_received === 1 ? "quote" : "quotes"}`,
            status: 'completed',
          },
          awaiting_ack: {
            title: "Awaiting acknowledgment",
            date: deliveryInfo[0]?.ack_req?.ack_req_time_stamp,
            message: `Your request is awaiting provider’s availability`,
            status: 'active', clickable: 'no',
            popup_message: <span>Your delivery is currently <span className="span_color">Awaiting acknowledgement from {deliveryInfo[0].fulfiller_id.toUpperCase()} 
              {/* (phone_number)  */}
              </span></span>,
            vcc: deliveryInfo[0].vcc,
            delivery_id: deliveryInfo[0].delivery_id,
          },  
          awaiting_agent: {
            title: "Awaiting agent assignment",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          awaiting_pickup: {
            title: "Awaiting pickup",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          in_transit: {
            title: "In-transit",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          out_for_delivery: {
            title: "Out for delivery",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          completed: {
            title: "Completed",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          }

        }])
      }
      else if (deliveryInfo[0].delivery_status === "awaiting_agent_assignment") {
        setpageViewData([{
          listing: {
            title: "Delivery request successful",
            date: deliveryInfo[0].date_listed,
            message: "Your delivery request has been placed",
            status: 'completed',
          },
          awaiting: {
            title: "Awaiting quote",
            date: deliveryInfo[0].date_listed,
            message: "We are awaiting providers to place quote",
            status: 'completed'
          },
          recieved: {
            title: "Received quote",
            date: `${return_quote_date(deliveryInfo[0]?.quote)}`,
            message: `You have received ${deliveryInfo[0].total_quote_received} ${deliveryInfo[0].total_quote_received === 1 ? "quote" : "quotes"}`,
            status: 'completed',
          },
          awaiting_ack: {
            title: "Awaiting acknowledgment",
            date: deliveryInfo[0]?.ack_req?.ack_req_time_stamp,
            message: `Your request is awaiting provider’s availability`,
            status: 'completed'
          },  
          awaiting_agent: {
            title: "Awaiting agent assignment",
            date: deliveryInfo[0]?.ack_req?.ack_res_time_stamp,
            message: "Your item is awaiting agent assignment",
            status: 'active', clickable: 'yes',
            popup_message: <span>Your delivery is currently <span className="span_color">Awaiting agent assignment from {deliveryInfo[0].fulfiller_id.toUpperCase()} 
            {/* (phone_number)  */}
            </span></span>,
            vcc: deliveryInfo[0].vcc,
            delivery_id: deliveryInfo[0].delivery_id,
          },
          awaiting_pickup: {
            title: "Awaiting pickup",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          in_transit: {
            title: "In-transit",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          out_for_delivery: {
            title: "Out for delivery",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          completed: {
            title: "Completed",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          }

        }])
      }
      else if (deliveryInfo[0].delivery_status === "awaiting_pickup") {
        setpageViewData([{
          listing: {
            title: "Delivery request successful",
            date: deliveryInfo[0].date_listed,
            message: "Your delivery request has been placed",
            status: 'completed',
          },
          awaiting: {
            title: "Awaiting quote",
            date: deliveryInfo[0].date_listed,
            message: "We are awaiting providers to place quote",
            status: 'completed'
          },
          recieved: {
            title: "Received quote",
            date: `${return_quote_date(deliveryInfo[0]?.quote)}`,
            message: `You have received ${deliveryInfo[0].total_quote_received} ${deliveryInfo[0].total_quote_received === 1 ? "quote" : "quotes"}`,
            status: 'completed',
          },
          awaiting_ack: {
            title: "Awaiting acknowledgment",
            date: deliveryInfo[0]?.ack_req?.ack_req_time_stamp,
            message: `Your request is awaiting provider’s availability`,
            status: 'completed'
          },  
          awaiting_agent: {
            title: "Awaiting agent assignment",
            date: deliveryInfo[0]?.ack_req?.ack_res_time_stamp,
            message: "Your item is awaiting agent assignment",
            status: 'completed',
            
          },
          awaiting_pickup: {
            title: "Awaiting pickup",
            date: deliveryInfo[0]?.assigned_time_stamp,
            message: "Agent is on his way to pickup location",
            status: 'active', clickable: 'yes',
            popup_message: <span>The agent is on his way to the <span className="span_color">pickup address</span></span>,
            vcc: deliveryInfo[0].vcc,
            delivery_id: deliveryInfo[0].delivery_id,
          },
          in_transit: {
            title: "In-transit",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          out_for_delivery: {
            title: "Out for delivery",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          completed: {
            title: "Completed",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          }

        }])
      }
      else if (deliveryInfo[0].delivery_status === "in_transit") {
        setpageViewData([{
          listing: {
            title: "Delivery request successful",
            date: deliveryInfo[0].date_listed,
            message: "Your delivery request has been placed",
            status: 'completed',
          },
          awaiting: {
            title: "Awaiting quote",
            date: deliveryInfo[0].date_listed,
            message: "We are awaiting providers to place quote",
            status: 'completed'
          },
          recieved: {
            title: "Received quote",
            date: `${return_quote_date(deliveryInfo[0]?.quote)}`,
            message: `You have received ${deliveryInfo[0].total_quote_received} ${deliveryInfo[0].total_quote_received === 1 ? "quote" : "quotes"}`,
            status: 'completed',
          },
          awaiting_ack: {
            title: "Awaiting acknowledgment",
            date: deliveryInfo[0]?.ack_req?.ack_req_time_stamp,
            message: `Your request is awaiting provider’s availability`,
            status: 'completed'
          },  
          awaiting_agent: {
            title: "Awaiting agent assignment",
            date: deliveryInfo[0]?.ack_req?.ack_res_time_stamp,
            message: "Your item is awaiting agent assignment",
            status: 'completed',
            
          },
          awaiting_pickup: {
            title: "Awaiting pickup",
            date: deliveryInfo[0]?.assigned_time_stamp,
            message: "Agent is on his way to pickup location",
            status: 'completed',
            
          },
          in_transit: {
            title: "In-transit",
            date: deliveryInfo[0]?.pickup_timestamp,
            message: "Agent has picked up your item",
            status: 'active', clickable: 'yes',
            popup_message: <span>The agent is on his way to deliver your item</span>,
            vcc: deliveryInfo[0].vcc,
            delivery_id: deliveryInfo[0].delivery_id,
          },
          out_for_delivery: {
            title: "Out for delivery",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          },
          completed: {
            title: "Completed",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          }

        }])
      }
      else if (deliveryInfo[0].delivery_status === "out_for_delivery") {
        setpageViewData([{
          listing: {
            title: "Delivery request successful",
            date: deliveryInfo[0].date_listed,
            message: "Your delivery request has been placed",
            status: 'completed',
          },
          awaiting: {
            title: "Awaiting quote",
            date: deliveryInfo[0].date_listed,
            message: "We are awaiting providers to place quote",
            status: 'completed'
          },
          recieved: {
            title: "Received quote",
            date: `${return_quote_date(deliveryInfo[0]?.quote)}`,
            message: `You have received ${deliveryInfo[0].total_quote_received} ${deliveryInfo[0].total_quote_received === 1 ? "quote" : "quotes"}`,
            status: 'completed',
          },
          awaiting_ack: {
            title: "Awaiting acknowledgment",
            date: deliveryInfo[0]?.ack_req?.ack_req_time_stamp,
            message: `Your request is awaiting provider’s availability`,
            status: 'completed'
          },  
          awaiting_agent: {
            title: "Awaiting agent assignment",
            date: deliveryInfo[0]?.ack_req?.ack_res_time_stamp,
            message: "Your item is awaiting agent assignment",
            status: 'completed',
            
          },
          awaiting_pickup: {
            title: "Awaiting pickup",
            date: deliveryInfo[0]?.assigned_time_stamp,
            message: "Agent is on his way to pickup location",
            status: 'completed',
            
          },
          in_transit: {
            title: "In-transit",
            date: deliveryInfo[0]?.pickup_timestamp,
            // date: convertDate(deliveryInfo[0]?.pickup_timestamp),
            message: "Agent has picked up your item",
            status: 'completed',
          },
          out_for_delivery: {
            title: "Out for delivery",
            date: "out time",
            message: "Agent is on his way to delivery location",
            status: 'active', clickable: 'yes',
            popup_message: <span>Your item is on the way to be delivered by <span className="span_color">{deliveryInfo[0].fulfiller_id.toUpperCase()}</span>. You can track your item.</span>,
            vcc: deliveryInfo[0].vcc,
            delivery_id: deliveryInfo[0].delivery_id,
          },
          completed: {
            title: "Completed",
            date: "",
            message: "",
            status: 'inactive', clickable: 'no',
          }

        }])
      }
      else if (deliveryInfo[0].delivery_status === "delivered") {
        setpageViewData([{
          listing: {
            title: "Delivery request successful",
            date: deliveryInfo[0].date_listed,
            message: "Your delivery request has been placed",
            status: 'completed',
          },
          awaiting: {
            title: "Awaiting quote",
            date: deliveryInfo[0].date_listed,
            message: "We are awaiting providers to place quote",
            status: 'completed'
          },
          recieved: {
            title: "Received quote",
            date: `${return_quote_date(deliveryInfo[0]?.quote)}`,
            message: `You have received ${deliveryInfo[0].total_quote_received} ${deliveryInfo[0].total_quote_received === 1 ? "quote" : "quotes"}`,
            status: 'completed',
          },
          awaiting_ack: {
            title: "Awaiting acknowledgment",
            date: deliveryInfo[0]?.ack_req?.ack_req_time_stamp,
            message: `Your request is awaiting provider’s availability`,
            status: 'completed'
          },  
          awaiting_agent: {
            title: "Awaiting agent assignment",
            date: deliveryInfo[0]?.ack_req?.ack_res_time_stamp,
            message: "Your item is awaiting agent assignment",
            status: 'completed',
            
          },
          awaiting_pickup: {
            title: "Awaiting pickup",
            date: deliveryInfo[0]?.assigned_time_stamp,
            message: "Agent is on his way to pickup location",
            status: 'completed',
            
          },
          in_transit: {
            title: "In-transit",
            date: deliveryInfo[0]?.pickup_timestamp,
            message: "Agent has picked up your item",
            status: 'completed',
          },
          out_for_delivery: {
            title: "Out for delivery",
            date: "out time",
            message: "Agent is on his way to delivery location",
            status: 'completed',
            
          },
          completed: {
            title: "Completed",
            date: deliveryInfo[0].delivery_timestamp,
            message: "Your delivery has been completed",
            status: 'active', clickable: 'yes',
            popup_message: <span>Your delivery is now <span className="span_color">completed</span></span>,
            vcc: deliveryInfo[0].vcc,
            delivery_id: deliveryInfo[0].delivery_id
          }

        }])
      }

      setloading(false);
    }
  }

  function EachStatus ({ item, hide }){
    return(
    <div className="each_status">
      <div className="each_line">
        <div className={item.status === "inactive" ? "each_circle_pending" : "each_circle"}>
        </div>
        {
          hide ?
            <></>
            :
            <div className={item.status === "completed" ? "each_line_thin_complete" : item.status === "active" ? "each_line_thin" : "each_line_thin_pending"}>
            </div>
        }
      </div>
      <div onClick={()=>{
        item.status === "active" && item.clickable === "yes" ?
          history.push(
            GlobalizationLanguagewithCOntryCode(
              `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
              country,
              visitingCountryCode
            )
          )
          :
          console.log('')
        }} 
        className={item.status === "active" ? "each_line_info_active" : "each_line_info"}>
        <h3>{item?.title}</h3>
        {
          item?.date === "" ? "" :
          <span>{moment(item?.date).format("DD-MM-YYYY hh:mm a")}</span>
        }
        <h5>{item?.message}</h5>
      </div>

      {item.status === "active" ?
        <div className="hi_avatar" >
          <ClickAwayListener onClickAway={()=>setshowClickMessage(false)}>
            <div className="hi_image_container">
              <img onClick={()=>{setshowClickMessage(!showClickMessage)}} 
                className="hi_image" 
                src={Wunmi_small} alt="avatar" 
              />
              <span onClick={()=>{setshowClickMessage(!showClickMessage)}}>Hi, I'm Wunmi, click </span>
              <span onClick={()=>{setshowClickMessage(!showClickMessage)}}>me for help</span>
            
              {
                showClickMessage ? 
                <div 
                  onClick={()=>{
                    item.status === "active" && item.clickable === "yes" ?
                      history.push(
                        GlobalizationLanguagewithCOntryCode(
                          `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                          country,
                          visitingCountryCode
                        )
                      )
                      :
                      console.log('')
                    }} 
                  className="mesgeCard">
                  {item?.popup_message}
                </div>
                : 
                ""
              }
            </div>
          </ClickAwayListener>
        </div>
        :
        null
      }
    </div>
    )
  }
  

  // const SearchBar = () => {
  //   return (
  //     <div className="search">
  //       <div className="search-input-navbar">
  //         <input
  //           type="text"
  //           value={search}
  //           autoFocus
  //           placeholder="search..."
  //           onChange={filterData}
  //         />
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div>
      {/* <div className="main-father">
        <SearchBar />
      </div> */}

      <div className="dsktopscreen">
        <Layout>

          {loading ? (
            <div
              style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "none",
                zIndex: "300",
                position: "fixed",
                top: "70px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <LinearProgress color="success" />
              </Box>
            </div>
          ) : (
            <div className="quoteCont">
              {deliveries?.length === 0 ? (
                <div className="columnBody1">
                  - currently none to display -</div>
              ) : (
                <div className="cont_items_status">
                  <>
                  <div style={{marginBottom:'20px', display:'flex', gap:'20px', alignItems:'center'}}>
                    <Tooltip title='Go back'>
                      <IconButton onClick={() => history.goBack()}>
                        <IoMdArrowRoundBack size={28}/>
                      </IconButton>
                    </Tooltip>
                  <h5 style={{margin:'0'}}>{deliveryInfo[0].item_desc.charAt(0).toUpperCase() + deliveryInfo[0].item_desc.slice(1)}</h5>

                  </div>
                  
                      <EachStatus 
                        item={pageViewData[0].listing} 
                      />
                      <EachStatus 
                        item={pageViewData[0].awaiting} 
                      />
                      <EachStatus 
                        item={pageViewData[0].recieved} 
                      />
                      <EachStatus 
                        item={pageViewData[0].awaiting_ack} 
                      />
                      <EachStatus 
                        item={pageViewData[0].awaiting_agent} 
                      />
                      <EachStatus 
                        item={pageViewData[0].awaiting_pickup} 
                      />
                      <EachStatus 
                        item={pageViewData[0].in_transit} 
                      />
                      <EachStatus 
                        item={pageViewData[0].out_for_delivery} 
                      />
                      <EachStatus 
                        item={pageViewData[0].completed} 
                        hide 
                      />
                    </>
                </div>
              )}
            </div>
          )}
        </Layout>
      </div>

      <div className="mobilescreen">
        <div>
          <div className="mobile-boby">
            <SenderTopBarBackArrow
              pageTitle="Delivery status"
            />
            <div className="mobile-dash-body">
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    backgroundColor: "none",
                    zIndex: "300",
                    position: "fixed",
                    top: "70px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress color="success" />
                  </Box>
                </div>
              ) : (
                <div className="quoteCont">
                  {deliveries?.length === 0 ? (
                    <div className="columnBody1">
                      - currently none to display -
                    </div>
                  ) : (
                    <>
                      <h5 style={{marginBottom:'15px'}}>{deliveryInfo[0].item_desc.charAt(0).toUpperCase() + deliveryInfo[0].item_desc.slice(1)}</h5>

                      <EachStatus 
                        item={pageViewData[0].listing} 
                      />
                      <EachStatus 
                        item={pageViewData[0].awaiting} 
                      />
                      <EachStatus 
                        item={pageViewData[0].recieved} 
                      />
                      <EachStatus 
                        item={pageViewData[0].awaiting_ack} 
                      />
                      <EachStatus 
                        item={pageViewData[0].awaiting_agent} 
                      />
                      <EachStatus 
                        item={pageViewData[0].awaiting_pickup} 
                      />
                      <EachStatus 
                        item={pageViewData[0].in_transit} 
                      />
                      <EachStatus 
                        item={pageViewData[0].out_for_delivery} 
                      />
                      <EachStatus 
                        item={pageViewData[0].completed} 
                        hide 
                      />
                    </>
                  )}
                </div>
              )}
            </div>
            <SenderBottomNav state="status" />
          </div>
        </div>
      </div>
    </div>
  );
}
