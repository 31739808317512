/* eslint-disable react/jsx-pascal-case */
import { useState, useContext } from "react";
import TitleText from "../../components/Reg_components/TitleText";
import RegStages from "../../components/Reg_components/RegStages";
import TopNavigationHambuger from "../../components/Reg_components/TopNavigationHambuger";
import Web_capture from "../../components/Reg_components/Web_capture";
import Web_capture_preview from "../../components/Reg_components/Web_capture_preview";
import ThanksNext from "../../components/Reg_components/ThanksNext";
import RegBtn from "../../components/Reg_components/RegBtn";
import { ImFilePicture } from "react-icons/im";
import RegInput from "../../components/Reg_components/RegInput";
import {
  
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  withStyles,
} from "@material-ui/core";
import { Select,InputLabel,FormControl,} from "@mui/material";
import RegBtnDouble from "../../components/Reg_components/RegBtnDouble";
import Image_capture from "../../components/Reg_components/Image_capture";
import Image_capture_preview from "../../components/Reg_components/Image_capture_preview";
import { green } from "@material-ui/core/colors";
import Policy from "../../assets/Policy.png";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import HomeHelp from "../../components/HomeHelp";
import SecureLS from "secure-ls";
import { ConnContext } from "../../context/ConnContext";
import Cookies from 'js-cookie';
// import {Select,MenuItem, FormControl, InputLabel,Grid} from '@material-ui/core';

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

function UpdateReg({
  bankVerified,
  proofVerified,
  profileVerified,
  goodsVerified,
  bankList
}) {

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  // const {visitingCountryCode} = useContext(ConnContext)
  // console.log("Lgd",ls.get("lGd"))
  const lGd = ls.get("lGd")
  const registered_countries = lGd.registered_countries.toLowerCase();
  const history = useHistory();

  const [registeredStatus, setRegisteredStatus] = useState("otp_confirmed");

  const [profilePhotoSlide, setProfilePhotoSlide] = useState("pre_take_photo");
  const [formOfIdSlide, setFormOfIdSlide] = useState("pre_form_id");
  const [selectedProof, setselectedProof] = useState("nil");
  const [InsuranceSlide, setInsuranceSlide] = useState("pre_insurance_policy");
  const [HaveInsurance, setHaveInsurance] = useState(false);
  const [BankDetailSlide, setBankDetailSlide] = useState("pre_bank_detail");
  const [uploading, setuploading] = useState(false);

  const [showHelp, setshowHelp] = useState(false);
  const [goingToNext, setgoingToNext] = useState(false);

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: ls.get("_nodi"),
    },
  });

  const schema = yup.object().shape({
    acct_name: yup
      .string()
      .required("Account name is required")
      .min(3, "Can't be lesser than 3 digits")
      .matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
    acct_number: yup
      .string()
      .required("Account number is required")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits")
      .matches(/^[0-9]*$/, "Account number can't contain alphabet"),
    // bvn: yup.string().required("BVN is required").min(11, 'Must be exactly 11 digits').max(11, 'Must be exactly 11 digits').matches(/^[0-9]*$/,  "BVN can't contain alphabet"),
    // bvn_address: yup.string().required("BVN Address is required").min(3, "Can't be lesser than 3 digits"),
    bank_name: yup
      .string()
      .required("Bank name is required")
      .min(3, "Can't be lesser than 3 digits"),
      // bank_code: yup
      //   .string()
      //   .required("Sort Code is required")
      //   .min(3, "Can't be lesser than 3 digits"),
  });
  const schemaGB = yup.object().shape({
    acct_name: yup
      .string()
      .required("Account name is required")
      .min(3, "Can't be lesser than 3 digits")
      .matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
    acct_number: yup
      .string()
      .required("Account number is required")
      .min(10, "Must be exactly 10 digits")
      .max(10, "Must be exactly 10 digits")
      .matches(/^[0-9]*$/, "Account number can't contain alphabet"),
    // bvn: yup.string().required("BVN is required").min(11, 'Must be exactly 11 digits').max(11, 'Must be exactly 11 digits').matches(/^[0-9]*$/,  "BVN can't contain alphabet"),
    // bvn_address: yup.string().required("BVN Address is required").min(3, "Can't be lesser than 3 digits"),
    bank_name: yup
      .string()
      .required("Bank name is required")
      .min(3, "Can't be lesser than 3 digits"),
    sort_code: yup
      .string()
      .required("Sort Code is required")
      .min(3, "Can't be lesser than 3 digits"),
  });

  const schema2 = yup.object().shape({
    ins_prov_name: yup.string().min(3, "Can't be lesser than 3 digits"),
    // ins_pol_num: yup.string().matches(
    //     /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/,
    //     "Must contain alphabet and numbers"
    //   ).max(12, "Can't be more than 12 digits"),
    ins_pol_num: yup.string().max(25, "Can't be more than 25 digits"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(registered_countries!=="gb"?schema:schemaGB),
  });

  const {
    control: control2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema2),
  });


  const {
    control: control3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const schema1 = yup.object().shape({
    //   password: yup
    //   .string()
    //   .required('Please Enter your password')
    //   .matches(
    //     /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    //   ),
    international_passport: yup
      .string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/,
        "Must contain alphabet and numbers"
      )
      .min(9, "Must be exactly 9 digits")
      .max(9, "Must be exactly 9 digits"),
    //   start: yup.string().required('Required'),
    //   to: yup.string().required('Required')
  });

  const schemaDrive = yup.object().shape({
    driver_license: yup
      .string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/,
        "Must contain alphabet and numbers"
      )
      .max(24, "Can't be more than 24 digit"),
        // start: yup.string().required(),
        // to: yup.string().required()

  });

  const schemaNIN = yup.object().shape({
    national_id_card: yup
      .string()
      .min(11, "Can't be lesser than 11 digits")
      .max(11, "Can't be more than 11 digits")
      .matches(/^$|^[0-9]*$/, "Can't contain letter or special character"),
  });

  const {
    control: control1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema1),
  });

  const {
    control: controlDrive,
    handleSubmit: handleSubmitDrive,
    formState: { errors: errorsDrive },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schemaDrive),
  });

  const {
    control: controlNIN,
    handleSubmit: handleSubmitNIN,
    formState: { errors: errorsNIN },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schemaNIN),
  });

  const goToDashboard = () => {
    setuploading(true);
    window.location.reload();
  };


  const gotoNext = (from) => {
    setgoingToNext(true);
    authAxios
      .get(`m-dillivry-dashboard-manager`, {
        headers: {
          Authorization: ls.get("_nodi"),
        },
      })
      .then((response) => {
        setgoingToNext(false);
        

        if (response.status === 201) {
          if (from === "id_photo") {
            if (response.data.profile_photo === "not_yet_provided") {
             setRegisteredStatus("upload_profile_photo");
           } 
           else if (
             response.data.good_in_transit_insurance === "not_yet_provided"
           ) {
             setRegisteredStatus("upload_insurance_policy");
           } else if (response.data.bank_detail === "not_yet_provided") {
             setRegisteredStatus("upload_bank_details");
           } else {
             window.location.reload();
           }
         }
          else if (from === "photo") {
            if (response.data.proof_of_identity === "not_yet_provided") {
              setRegisteredStatus("upload_form_of_id");
            } else if (
              response.data.good_in_transit_insurance === "not_yet_provided"
            ) {
              setRegisteredStatus("upload_insurance_policy");
            } else if (response.data.bank_detail === "not_yet_provided") {
              setRegisteredStatus("upload_bank_details");
            } else {
              window.location.reload();
            }
          } 
           else if (from === "insurance") {
            if (response.data.bank_detail === "not_yet_provided") {
              setRegisteredStatus("upload_bank_details");
            }
            else if (response.data.proof_of_identity === "not_yet_provided") {
              setRegisteredStatus("upload_form_of_id");
            }
             else if (response.data.profile_photo === "not_yet_provided") {
              setRegisteredStatus("upload_profile_photo");
            }  else {
              window.location.reload();
            }
          } else if (from === "bank") {
            if (response.data.proof_of_identity === "not_yet_provided") {
              setRegisteredStatus("upload_form_of_id");
            } 
            else if (response.data.profile_photo === "not_yet_provided") {
              setRegisteredStatus("upload_profile_photo");
            } else if (
              response.data.good_in_transit_insurance === "not_yet_provided"
            ) {
              setRegisteredStatus("upload_insurance_policy");
            } else {
              window.location.reload();
            }
          }
        } else {
        }
      })

      .catch((error) => {
        console.log(error);
        setgoingToNext(true);
        if (
          error.response.status !== undefined &&
          error.response.status === 404
        ) {
          window.location.reload();
        } else {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              history.push(`/login`);
            }
          });
        }
        console.log(error);
      });
  };

  const submitPolicyDetails = async (data) => {
    ls.set(
      "insu_d",
      JSON.stringify({
        provider_name: data.ins_prov_name,
        insurance_num: data.ins_pol_num,
        start: data.valid_from,
        end: data.valid_to,
      })
    );
    setInsuranceSlide("take_insurance_photo");
  };

  const submitForm = async (data) => {
    data.action_type = "bank_detail";
    // data.bank_code = "004400"
    // data.account_name = ls.get("_tyl_");
    // data.bvn_address = `Not required as at: ${new Date()}`;
    // data.bvn = `xxxxxxxxxxxxx`;
    // data.country_code = visitingCountryCode;
    setuploading(true);

    authAxios
      .post(`m-fulfiller-document-upload?registered_countries=${registered_countries}`, data)
      .then(() => {
        setuploading(false);
        setBankDetailSlide("bank_details_uploaded");
      })
      .catch((error) => {
        setuploading(false);
      });
  };

  const updatePhoto = () => {
    setuploading(true);
    authAxios
      .post(`m-fulfiller-document-upload?registered_countries=${registered_countries}`, {
        action_type: "profile_photo",
        profile_photo: ls.get("_ig_m"),
      })
      .then((response) => {
        setuploading(false);
        setProfilePhotoSlide("photo_uploaded");
        ls.remove("_prv_");
        ls.remove("_ig_m");
      })
      .catch((error) => {
        console.log(error, "error response")

        setuploading(false);
       
      });
  };
  const updatePreFormOfID = async (data) => {
    ls.set("n_id",
    JSON.stringify({
    //    drivers_number: data.ins_prov_name,
    international_passport: data.international_passport,
        start: data.valid_from,
        end: data.valid_to,
      })
    );

    setFormOfIdSlide("take_id_photo");
  };


  const updatePreDrive = async (data) => {
    // ls.set("n_id", data.driver_license);
    ls.set("n_id",
    JSON.stringify({
    //    drivers_number: data.ins_prov_name,
        driver_license_num: data.driver_license,
        start: data.valid_from,
        end: data.valid_to,
      })
    );
    setFormOfIdSlide("take_id_photo");
  };


  const updatePreNIN = async (data) => {

    ls.set("n_id", data.national_id_card);
    setFormOfIdSlide("take_id_photo");

    ls.set("n_id",
    JSON.stringify({
        national_id_card: data.national_id_card
    })
    )
  };


  const updateFormOfID = () => {
    setuploading(true);
    var formofIDDetails = JSON.parse(ls.get("n_id"));
    authAxios
      .post(`m-fulfiller-document-upload?registered_countries=${registered_countries}`, {
        action_type: "proof_of_identity",
        form_of_id_sn: formofIDDetails.driver_license_num ? formofIDDetails.driver_license_num :  formofIDDetails.international_passport ? formofIDDetails.international_passport : formofIDDetails.national_id_card,
        proof_of_id: ls.get("_ig_m"),
        start_date: formofIDDetails.start,
        end_date: formofIDDetails.end,
      })
      .then(() => {
        setuploading(false);
        setFormOfIdSlide("id_photo_uploaded");
        ls.remove("_prv_");
        ls.remove("_ig_m");
        ls.remove("n_id");
      })
      .catch((error) => {
        setuploading(false);
      });
  };

  const updatePolicy = () => {
    setuploading(true);
    var policyDetails = JSON.parse(ls.get("insu_d"));
    authAxios
      .post(`m-fulfiller-document-upload?registered_countries=${registered_countries}`, {
        action_type: "good_in_transit_insurance",
        provider_name: policyDetails.provider_name,
        policy_number: policyDetails.insurance_num,
        start_date: policyDetails.start,
        end_date: policyDetails.end,
        policy_image: ls.get("_ig_m"),
      })
      .then(() => {
        setuploading(false);
        setInsuranceSlide("insurance_uploaded");
        ls.remove("_prv_");
        ls.remove("_ig_m");
        ls.remove("n_id");
        ls.remove("insu_id");
      })
      .catch((error) => {
        setuploading(false);
      })
  };

  const updateNoPolicy = () => {
    setuploading(true);

    authAxios
      .post(`m-fulfiller-document-upload?registered_countries=${registered_countries}`, {
        action_type: "good_in_transit_insurance",
        provider_name: "",
        policy_number: "",
        start_date: "",
        end_date: "",
        policy_image: "",
      })
      .then(() => {
        setuploading(false);
        setInsuranceSlide("insurance_uploaded");
        ls.remove("_prv_");
        ls.remove("_ig_m");
        ls.remove("n_id");
        ls.remove("insu_id");
      })
      .catch((error) => {
        console.log(error);
        setuploading(false);
      });
  };
  return (
    <div className="regCont">
      
      {registeredStatus === "otp_confirmed" ? (
        <>
          {/* welcome page start here */}
          <TopNavigationHambuger
            help="Help"
            openHelp={() => setshowHelp(!showHelp)}
          />

          <TitleText
            head={"Welcome, " + ls.get("nful")}
            subHead2="Required steps"
            subHead3="Here's what you need to do to setup your account."
            margin_base="30px"
          />

          <h5 style={{ paddingBottom: "10px" }}>
            Upload your details and await validation from the Dillivry team. You
            might need to refresh this page at intervals to check the status of
            the validation
          </h5>

          <div>
          {proofVerified === "not_yet_provided" ||
            proofVerified === "not_valid" ? (
              <RegStages
                status={proofVerified}
                onClick={() => {
                  setRegisteredStatus("upload_form_of_id");
                }}
                title="Form of identification"
              />
            ) : null}
            {profileVerified === "not_yet_provided" ||
            profileVerified === "not_valid" ? (
              <RegStages
                disableState = {proofVerified}
                status={proofVerified&&profileVerified}
                onClick={() => setRegisteredStatus("upload_profile_photo")}
                title="Profile photo"
              />
            ) : null}
            {goodsVerified === "not_yet_provided" ||
            goodsVerified === "not_valid" ? (
              <RegStages
                disableState = {profileVerified}
                status={goodsVerified}
                onClick={() => setRegisteredStatus("upload_insurance_policy")}
                title="Insurance policy"
              />
            ) : null}
            {bankVerified === "not_yet_provided" ||
            bankVerified === "not_valid" ? (
              <RegStages
                disableState = {goodsVerified}
                status={bankVerified}
                onClick={() => setRegisteredStatus("upload_bank_details")}
                title="Bank details"
              />
            ) : null}
          </div>
          <div style={{ marginTop: "70px" }}>
            {proofVerified === "verified" ||
            profileVerified === "verified" ||
            goodsVerified === "verified" ||
            bankVerified === "verified" ||
            proofVerified === "not_verified" ||
            profileVerified === "not_verified" ||
            goodsVerified === "not_verified" ||
            bankVerified === "not_verified" ? (
              <div
                style={{
                  fontSize: "17px",
                  fontWeight: "700",
                  marginBottom: "20px",
                }}
              >
                Submitted
              </div>
            ) : null}
            {proofVerified === "verified" ||
            proofVerified === "not_verified" ? (
              <RegStages
                status={proofVerified}
                onClick={() => setRegisteredStatus("upload_form_of_id")}
                title="Form of identification"
              />
            ) : null}
            {profileVerified === "verified" ||
            profileVerified === "not_verified" ? (
              <RegStages
                status={profileVerified}
                onClick={() => setRegisteredStatus("upload_profile_photo")}
                title="Profile photo"
              />
            ) : null}
            
            {goodsVerified === "verified" ||
            goodsVerified === "not_verified" ? (
              <RegStages
                status={goodsVerified}
                onClick={() => setRegisteredStatus("upload_insurance_policy")}
                title="Insurance policy"
              />
            ) : null}
            {bankVerified === "verified" || bankVerified === "not_verified" ? (
              <RegStages
                status={bankVerified}
                onClick={() => setRegisteredStatus("upload_bank_details")}
                title="Bank details"
              />
            ) : null}
          </div>
        </>
      ) : 
      registeredStatus === "upload_profile_photo" ? (
        <>
          {/* profile picture upload start here */}

          {profilePhotoSlide === "pre_take_photo" ? (
            <>
              {/* take your photo page start here */}
              <TopNavigationHambuger
                help="Help"
                openHelp={() => setshowHelp(!showHelp)}
              />
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  marginBottom: "20px",
                }}
              >
                Take your profile photo
              </div>
              <div
                style={{
                  fontSize: "11px",
                  fontWeight: "400",
                  marginBottom: "10px",
                }}
              >
                Your profile photo helps people recoginse you. Please note that
                once you submitted your profile photo. It cannot be changed.
              </div>
              <div style={{ fontSize: "9px", marginBottom: "5px" }}>
                1. Face the camera and make sure your eyes and mouth are clearly
                visilbe.
              </div>
              <div style={{ fontSize: "9px", marginBottom: "5px" }}>
                2. Make sure the photo is well fit, free of glare and in focus.
              </div>
              <div style={{ fontSize: "9px", marginBottom: "5px" }}>
                3. No photos of a photo, filters or alterations.
              </div>
              <div
                style={{
                  width: "100%",
                  height: "300px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ImFilePicture size="130" color="#c4c4c4" />
              </div>
              <div className="btnDeskto">
                <RegBtn
                  txt="Take Photo"
                  onClick={() => setProfilePhotoSlide("take_photo")}
                />
              </div>
              <div className="btnMobil2">
                <RegBtn
                  txt="Take Photo"
                  onClick={() => setProfilePhotoSlide("take_photo")}
                />
              </div>
            </>
          ) : profilePhotoSlide === "take_photo" ? (
            <>
              <Web_capture
                goToPreview={() => setProfilePhotoSlide("preview_photo")}
                goBack={() => setProfilePhotoSlide("pre_take_photo")}
              />
            </>
          ) : profilePhotoSlide === "preview_photo" ? (
            <>
              <Web_capture_preview
                uploading={uploading}
                leftClick={() => setProfilePhotoSlide("take_photo")}
                rightClick={updatePhoto}
              />
            </>
          ) : profilePhotoSlide === "photo_uploaded" ? (
            <>
              <ThanksNext
                txt="Thanks for providing your profile photo"
                toNext={() => {
                  gotoNext("photo");
                }}
                // toStatus={()=>setRegisteredStatus('otp_confirmed')}
                toStatus={goToDashboard}
                loading={uploading}
              />
            </>
          ) : null}
        </>
      ) 
      : registeredStatus === "upload_form_of_id" ? (
        <>
          {/* upload form of id start here */}
          {formOfIdSlide === "pre_form_id" ? (
            <>
              <TopNavigationHambuger
                help="Help"
                openHelp={() => setshowHelp(!showHelp)}
              />
              <TitleText
                head="Upload your form of identification"
                margin_base="0px"
              />

              <h3 className="chartweb" style={{ marginBottom: "30px" }}>
                Upload your form of identification
              </h3>

              <FormControl
                variant="outlined"
                className="formInput"
                sx={{
                  '& legend':{
                    float:"left !important"
                  }
                }}
                style={{ marginBottom: "10px" }}
              >
                <InputLabel></InputLabel>
                <Select
                  value={selectedProof}
                  onChange={(e) => setselectedProof(e.target.value)}
                >
                  <MenuItem value="nil">
                    Please select your Identification
                  </MenuItem>
                  <MenuItem value="driver_license">Driver License</MenuItem>
                  <MenuItem value="international_passport">
                    International Passport
                  </MenuItem>
                  <MenuItem value="national_id_card">National ID Card</MenuItem>
                </Select>
              </FormControl>
              {selectedProof === "driver_license" ? (
                <>
                  <form
                    style={{ width: "100%" }}
                    onSubmit={handleSubmitDrive(updatePreDrive)}
                    noValidate
                  >
                    <Grid container spacing={1}>
                      <Grid item style={{ marginBottom: "10px" }} xs={12}>
                        <Controller
                          name="driver_license"
                          control={controlDrive}
                          defaultValue=""
                          render={({ field }) => (
                            <RegInput
                              {...field}
                              htmlFor="driver_license"
                              name="driver_license"
                              id="driver_license"
                              txt="Drivers license number"
                              placeholder="Drivers license number"
                              type="text"
                              size="small"
                              error={errorsDrive.driver_license}
                            />
                          )}
                        />
                        <div className="err">
                          {errorsDrive.driver_license &&
                            errorsDrive.driver_license?.message}
                        </div>
                      </Grid>
                      <Grid item xs={6} sm={6} style={{ marginBottom: "10px" }}>
                        <Controller
                          name="valid_from"
                         control={controlDrive}
                          defaultValue=""
                          render={({ field }) => (
                            <RegInput
                              {...field}
                              htmlFor="valid_from"
                              name="valid_from"
                              id="valid_from"
                              txt="Valid from"
                              placeholder="Valid from"
                              type="date"
                              size="small"
                              error={errorsDrive.start}
                            />
                          )}
                        />
                        {/* <div className="err">
                          {errors2.valid_from && errors2.valid_from?.message}
                        </div> */}
                      </Grid>

                      <Grid item xs={6} sm={6} style={{ marginBottom: "10px" }}>
                        <Controller
                          name="valid_to"
                          control={controlDrive}
                          defaultValue=""
                          render={({ field }) => (
                            <RegInput
                              {...field}
                              htmlFor="valid_to"
                              name="valid_to"
                              id="valid_to"
                              txt="Valid to"
                              placeholder="Valid to"
                              type="date"
                              size="small"
                              error={errorsDrive.to}
                            />
                          )}
                        />
                        {/* <div className="err">
                          {errors2.valid_to && errors2.valid_to?.message}
                        </div> */}
                      </Grid>

                      {/* <div className="err">{errorsDrive.driver_license && errorsDrive.driver_license?.message}</div>  */}
                      <div className="btnMobil2">
                        <RegBtnDouble
                          type="submit"
                          leftText="Go to account status"
                          rightText="Take photo"
                          toNext={() => setRegisteredStatus("otp_confirmed")}
                        />
                      </div>
                      <div
                        className="btnDeskto"
                        style={{ marginBottom: "20px" }}
                      >
                        <RegBtnDouble
                          type="submit"
                          leftText="Go to account status"
                          rightText="Take photo"
                          toNext={() => setRegisteredStatus("otp_confirmed")}
                        />
                      </div>
                    </Grid>
                  </form>
                </>
              ) : selectedProof === "international_passport" ? (
                <>
                  <form
                    style={{ width: "100%" }}
                    onSubmit={handleSubmit1(updatePreFormOfID)}
                    noValidate
                  >
                       <Grid container spacing={1}>
                       <Grid item style={{ marginBottom: "10px" }} xs={12}>
                    <Controller
                      name="international_passport"
                      control={control1}
                      defaultValue=""
                      render={({ field }) => (
                        <RegInput
                          {...field}
                          htmlFor="international_passport"
                          name="international_passport"
                          id="international_passport"
                          txt="Internation passport number"
                          placeholder="Internation passport number"
                          type="text"
                          size="small"
                          error={errors1.international_passport}
                        />
                      )}
                    />
                    <div className="err">
                      {errors1.international_passport &&
                        errors1.international_passport?.message}
                    </div>

                    </Grid>



                    <Grid item xs={6} sm={6} style={{ marginBottom: "10px" }}>
                        <Controller
                          name="valid_from"
                          control={control1}
                          defaultValue=""
                          render={({ field }) => (
                            <RegInput
                              {...field}
                              htmlFor="valid_from"
                              name="valid_from"
                              id="valid_from"
                              txt="Valid from"
                              placeholder="Valid from"
                              type="date"
                              size="small"
                              error={errors1.start}
                            />
                          )}
                        />
                        <div className="err">
                          {errors2.valid_from && errors2.valid_from?.message}
                        </div>
                      </Grid>
                      <Grid item xs={6} sm={6} style={{ marginBottom: "10px" }}>
                        <Controller
                          name="valid_to"
                          control={control1}
                          defaultValue=""
                          render={({ field }) => (
                            <RegInput
                              {...field}
                              htmlFor="valid_to"
                              name="valid_to"
                              id="valid_to"
                              txt="Valid to"
                              placeholder="Valid to"
                              type="date"
                              size="small"
                              error={errors1.to}
                            />
                          )}
                        />
                        <div className="err">
                          {errors2.valid_to && errors2.valid_to?.message}
                        </div>
                      </Grid>

                    <div className="btnMobil2">
                      <RegBtnDouble
                        type="submit"
                        leftText="Go to account status"
                        rightText="Take photo"
                        toNext={() => setRegisteredStatus("otp_confirmed")}
                      />
                    </div>
                    <div className="btnDeskto" style={{ marginBottom: "20px" }}>
                      <RegBtnDouble
                        type="submit"
                        leftText="Go to account status"
                        rightText="Take photo"
                        toNext={() => setRegisteredStatus("otp_confirmed")}
                      />
                    </div>
                    </Grid>
                  </form>
                </>
              ) : selectedProof === "national_id_card" ? (
                <>
                  <form
                    style={{ width: "100%" }}
                    onSubmit={handleSubmitNIN(updatePreNIN)}
                    noValidate
                  >
                    <Controller
                      name="national_id_card"
                      control={controlNIN}
                      defaultValue=""
                      render={({ field }) => (
                        <RegInput
                          {...field}
                          htmlFor="national_id_card"
                          name="national_id_card"
                          id="national_id_card"
                          txt="National ID card number"
                          placeholder="National ID card number"
                          type="text"
                          size="small"
                          error={errorsNIN.national_id_card}
                        />
                      )}
                    />
                    <div className="err">
                      {errorsNIN.national_id_card &&
                        errorsNIN.national_id_card?.message}
                    </div>
                    <div className="btnMobil2">
                      <RegBtnDouble
                        type="submit"
                        leftText="Go to account status"
                        rightText="Take photo"
                        toNext={() => setRegisteredStatus("otp_confirmed")}
                      />
                    </div>
                    <div className="btnDeskto" style={{ marginBottom: "20px" }}>
                      <RegBtnDouble
                        type="submit"
                        leftText="Go to account status"
                        rightText="Take photo"
                        toNext={() => setRegisteredStatus("otp_confirmed")}
                      />
                    </div>
                  </form>
                </>
              ) : (
                <>
                  <div className="btnMobil2">
                    <RegBtnDouble
                      type="submit"
                      leftText="Go to account status"
                      rightText="Take photo"
                      disabled
                      toNext={() => setRegisteredStatus("otp_confirmed")}
                    />
                  </div>
                  <div className="btnDeskto" style={{ marginBottom: "20px" }}>
                    <RegBtnDouble
                      type="submit"
                      disabled
                      leftText="Go to account status"
                      rightText="Take photo"
                      toNext={() => setRegisteredStatus("otp_confirmed")}
                    />
                  </div>
                </>
              )}
            </>
          ) : formOfIdSlide === "take_id_photo" ? (
            <>
              <Image_capture
                goToPreview={() => setFormOfIdSlide("preview_form_id")}
                goToBack={() => setFormOfIdSlide("pre_form_id")}
                videoWidth={250}
                videoHeight={350}
                // camWidth = {300}
                // camHeight = {200}
                // shotWidth = {300}
                // shotHeight = {200}
              />
            </>
          ) : formOfIdSlide === "preview_form_id" ? (
            <>
              <Image_capture_preview
                uploading={uploading}
                imageWidth={240}
                imageHeight={160}
                title="Use this Form of Identifcation?"
                subtitle="By tapping save, you agree that Dillivry or a trusted vendor can collect and process your photos with technology that allows us to verify your identity."
                leftClick={() => setFormOfIdSlide("take_id_photo")}
                rightClick={updateFormOfID}
              />
            </>
          ) : formOfIdSlide === "id_photo_uploaded" ? (
            <>
              <ThanksNext
                txt="Thanks for providing your form of identification"
                toNext={() => gotoNext("id_photo")}
                // toNext={()=>console.log('next')}
                toStatus={goToDashboard}
                loading={uploading}
              />
            </>
          ) : null}
        </>
      ) 
      : registeredStatus === "upload_insurance_policy" ? (
        <>
          {/* upload insurance policy start here */}
          {InsuranceSlide === "pre_insurance_policy" ? (
            <>
              <TopNavigationHambuger
                help="Help"
                openHelp={() => setshowHelp(!showHelp)}
              />
              <TitleText
                head="Upload your insurance"
                subHead="Do you have goods in transit insurance?"
                margin_base="20px"
              />
              <h3 className="chartweb" style={{ marginBottom: "20px" }}>
                Upload your insurance
              </h3>
              <h4 className="chartweb">
                Do you have goods in transit insurance?
              </h4>
              <div style={{ marginBottom: "30px" }}>
                <FormControl component="fieldset">
                  <RadioGroup row aria-label="" name="row-radio-buttons-group">
                    <FormControlLabel
                      value="yes"
                      onClick={(e) => setHaveInsurance(true)}
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      onClick={(e) => setHaveInsurance(false)}
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {HaveInsurance ? (
                <>
                  <form
                    style={{ width: "100%" }}
                    noValidate
                    onSubmit={handleSubmit2(submitPolicyDetails)}
                  >
                    <Grid container spacing={1}>
                      <Grid item style={{ marginBottom: "10px" }} xs={12}>
                        <Controller
                          name="ins_prov_name"
                          control={control2}
                          defaultValue=""
                          render={({ field }) => (
                            <RegInput
                              {...field}
                              htmlFor="ins_prov_name"
                              name="ins_prov_name"
                              txt="Insurance provider name"
                              id="ins_prov_name"
                              placeholder="Insurance provider name"
                              type="text"
                              size="small"
                              error={errors2.ins_prov_name}
                            />
                          )}
                        />
                        <div className="err">
                          {errors2.ins_prov_name &&
                            errors2.ins_prov_name?.message}
                        </div>
                      </Grid>
                      <Grid item style={{ marginBottom: "10px" }} xs={12}>
                        <Controller
                          name="ins_pol_num"
                          control={control2}
                          defaultValue=""
                          render={({ field }) => (
                            <RegInput
                              {...field}
                              htmlFor="ins_pol_num"
                              name="ins_pol_num"
                              txt="Insurance policy number"
                              id="ins_pol_num"
                              placeholder="Insurance policy number"
                              type="text"
                              size="small"
                              error={errors2.ins_pol_num}
                            />
                          )}
                        />
                        <div className="err">
                          {errors2.ins_pol_num && errors2.ins_pol_num?.message}
                        </div>
                      </Grid>
                      <Grid item xs={6} sm={6} style={{ marginBottom: "10px" }}>
                        <Controller
                          name="valid_from"
                          control={control2}
                          defaultValue=""
                          render={({ field }) => (
                            <RegInput
                              {...field}
                              htmlFor="valid_from"
                              name="valid_from"
                              id="valid_from"
                              txt="Valid from"
                              placeholder="Valid from"
                              type="date"
                              size="small"
                              error={errors2.valid_from}
                            />
                          )}
                        />
                        <div className="err">
                          {errors2.valid_from && errors2.valid_from?.message}
                        </div>
                      </Grid>
                      <Grid item xs={6} sm={6} style={{ marginBottom: "10px" }}>
                        <Controller
                          name="valid_to"
                          control={control2}
                          defaultValue=""
                          render={({ field }) => (
                            <RegInput
                              {...field}
                              htmlFor="valid_to"
                              name="valid_to"
                              id="valid_to"
                              txt="Valid to"
                              placeholder="Valid to"
                              type="date"
                              size="small"
                              error={errors2.valid_to}
                            />
                          )}
                        />
                        <div className="err">
                          {errors2.valid_to && errors2.valid_to?.message}
                        </div>
                      </Grid>
                      <Grid item style={{ marginBottom: "10px" }} xs={12}>
                    <RegBtnDouble
                          type="submit"
                          leftText="Go to account status"
                          rightText="Take photo"
                          //     toStatus={()=>{
                          //         ls.set('insu_d', JSON.stringify({'provider_name': provider_name , 'insurance_num': policy_number, 'start': start_date, 'end': end_date }))
                          //         setInsuranceSlide('take_insurance_photo')
                          // }}
                          toNext={() => setRegisteredStatus("otp_confirmed")}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </>
              ) : (
                <>
                  <div
                    style={{
                      width: "100%",
                      height: "300px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={Policy}
                      alt="policy"
                      style={{ maxHeight: "150px" }}
                    />
                  </div>
                  <div className="btnMobil2">
                    <RegBtnDouble
                      loading={uploading}
                      type="button"
                      leftText="Go to account status"
                      rightText="Next"
                      toStatus={updateNoPolicy}
                      toNext={goToDashboard}
                    />
                  </div>
                  <div className="btnDeskto" style={{ marginBottom: "20px" }}>
                    <RegBtnDouble
                      loading={uploading}
                      type="button"
                      leftText="Go to account status"
                      rightText="Next"
                      toStatus={updateNoPolicy}
                      toNext={goToDashboard}
                    />
                  </div>
                </>
              )}
            </>
          ) : InsuranceSlide === "take_insurance_photo" ? (
            <>
              <Image_capture
                goToPreview={() => setInsuranceSlide("preview_insurance_photo")}
                goToBack={() => setInsuranceSlide("pre_insurance_policy")}
                videoWidth={370}
                videoHeight={270}
                // camWidth = {200}
                // camHeight = {400}
                // shotWidth = {200}
                // shotHeight = {400}
              />
            </>
          ) : InsuranceSlide === "preview_insurance_photo" ? (
            <>
              <Image_capture_preview
                uploading={uploading}
                imageWidth={180}
                imageHeight={300}
                title="Use this Insurance policy?"
                subtitle="By tapping save, you agree that Dillivry or a trusted vendor can collect and process your photos with technology that allows us to verify your identity."
                leftClick={() => setInsuranceSlide("take_insurance_photo")}
                rightClick={updatePolicy}
              />
            </>
          ) : InsuranceSlide === "insurance_uploaded" ? (
            <>
              <ThanksNext
                txt="Thanks for providing your Insurance policy"
                toNext={() => gotoNext("insurance")}
                toStatus={goToDashboard}
                loading={uploading}
              />
            </>
          ) : null}
        </>
      ) : registeredStatus === "upload_bank_details" ? (
        <>
          {/* upload bank details start here */}
          {BankDetailSlide === "pre_bank_detail" ? (
            <>
              <TopNavigationHambuger
                help="Help"
                openHelp={() => setshowHelp(!showHelp)}
              />
              <TitleText head="Provide your bank details" margin_base="20px" />
              <h3 className="chartweb" style={{ marginBottom: "30px" }}>
                Provide your bank details
              </h3>

              <form
                style={{ width: "100%", paddingBottom: "30px" }}
                onSubmit={handleSubmit(submitForm)}
                noValidate
              >
                <Grid container spacing={1}>
                  {/* <Grid item style={{marginBottom:'10px'}} xs={12}>
                                        <Controller
                                            name="bank_name"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => <RegInput
                                                {...field}
                                                htmlFor = "bank_name"
                                                name = "bank_name"
                                                txt= 'Bank name'
                                                id='bank_name'
                                                placeholder="Bank name"
                                                type='text'
                                                size='small'
                                                error={errors.bank_name}

                                            />}
                                        />
                                        <div className="err">{errors.bank_name && errors.bank_name?.message}</div> 
                                    </Grid> */}

                  <Grid item style={{ marginBottom: "10px" }} xs={12}>
                    <Controller
                      name="bank_name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        // visitingCountryCode!=="gb"?
                        <FormControl
                          variant="outlined"
                          style={{ minWidth: "100%" }}
                          size = 'small'
                        >
                          <InputLabel id="select"> Bank name</InputLabel>
                          <Select
                            {...field}
                            labelId="select"
                            error={errors.bank_name}
                            label="Bank name"
                          >
                            {bankList?.map((bank, index) => {
                              return (
                                <MenuItem key={index} value={bank}>
                                  {bank}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      //   :
                      //   <RegInput
                      //   {...field}
                      //   htmlFor="bank_name"
                      //   name="bank_name"
                      //   txt="Bank name"
                      //   id="bank_name"
                      //   placeholder="Bank name"
                      //   type="text"
                      //   size="small"
                      //   error={errors.bank_name}
                      // />
                      )}
                    />
                    <div className="err">
                      {errors.bank_name && errors.bank_name?.message}
                    </div>
                  </Grid>

                  <Grid item style={{ marginBottom: "10px" }} xs={12}>
                    <Controller
                      name="acct_name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <RegInput
                          {...field}
                          htmlFor="acct_name"
                          name="acct_name"
                          txt="Account name"
                          id="acct_name"
                          placeholder="Account name"
                          type="text"
                          size="small"
                          error={errors.acct_name}
                        />
                      )}
                    />
                    <div className="err">
                      {errors.acct_name && errors.acct_name?.message}
                    </div>
                  </Grid>
                  <Grid item style={{ marginBottom: "10px" }} xs={12}>
                    <Controller
                      name="acct_number"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <RegInput
                          {...field}
                          htmlFor="acct_number"
                          name="acct_number"
                          txt="Account number"
                          id="acct_number"
                          placeholder="Account number"
                          type="text"
                          size="small"
                          error={errors.acct_number}
                        />
                      )}
                    />
                    <div className="err">
                      {errors.acct_number && errors.acct_number?.message}
                    </div>
                  </Grid>
                  {registered_countries==="gb"?
                  <Grid item style={{ marginBottom: "10px" }} xs={12}>
                    <Controller
                      name="sort_code"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <RegInput
                          {...field}
                          htmlFor="sort_code"
                          name="sort_code"
                          txt="Sort Code"
                          id="sort_code"
                          placeholder="Sort Code"
                          type="text"
                          size="small"
                          error={errors.sort_code}
                        />
                      )}
                    />
                    <div className="err">
                      {errors.sort_code && errors.sort_code?.message}
                    </div>
                  </Grid>:null}
                  {/* {visitingCountryCode==="ng"?
                  <Grid item style={{ marginBottom: "10px" }} xs={12}>
                    <Controller
                      name="bank_code"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <RegInput
                          {...field}
                          htmlFor="bank_code"
                          name="bank_code"
                          txt="Bank Code"
                          id="bank_code"
                          placeholder="Bank Code"
                          type="text"
                          size="small"
                          error={errors.bank_code}
                        />
                      )}
                    />
                    <div className="err">
                      {errors.bank_code && errors.bank_code?.message}
                    </div>
                  </Grid>:null} */}
                  {/* <Grid item style={{marginBottom:'10px'}} xs={12}>
                                    <Controller
                                        name="bvn"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => <RegInput
                                            {...field}
                                            htmlFor = "bvn"
                                            name = "bvn"
                                            txt= 'Bank Verification Number'
                                            id='bvn'
                                            placeholder="Bank Verification Number"
                                            type='text'
                                            size='small'
                                            error={errors.bvn}

                                        />}
                                    />
                                    <div className="err">{errors.bvn && errors.bvn?.message}</div> 

                                </Grid> */}
                  {/* <Grid item style={{marginBottom:'10px'}} xs={12}>
                                    <Controller
                                        name="bvn_address"
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => <RegInput
                                            {...field}
                                            htmlFor = "bvn_address"
                                            name = "bvn_address"
                                            txt= 'BVN Registration Address'
                                            id='bvn_address'
                                            placeholder="BVN Registration Address"
                                            type='text'
                                            size='small'
                                            error={errors.bvn_address}

                                        />}
                                    />
                                    <div className="err">{errors.bvn_address && errors.bvn_address?.message}</div> 
                                </Grid> */}
                  <Grid xs={12} style={{ padding: "5px" }}>
                    <RegBtnDouble
                      loading={uploading}
                      leftText="Go to account status"
                      rightText="Submit"
                      type="submit"
                      // toStatus={}
                      toNext={() => window.location.reload()}
                    />
                  </Grid>
                </Grid>
              </form>
            </>
          ) : BankDetailSlide === "bank_details_uploaded" ? (
            <>
              <ThanksNext
                txt="Thanks for providing your Bank details"
                toNext={() => gotoNext("bank")}
                toStatus={goToDashboard}
                loading={uploading}
              />
            </>
          ) : null}
        </>
      ) : null}
      {showHelp ? (
        <HomeHelp showDialog={showHelp} onClose={() => setshowHelp(false)} />
      ) : null}
    </div>
  );
}
export default UpdateReg;
