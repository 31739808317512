import React, { useEffect, useContext, useState } from "react";
import Layout from "../../Layout";
import "./Message.css";
import userAvatar from "../../../../../assets/userAvatar.jpeg";
import send from "../../../../../assets/Stroke.svg";
import horiz from "../../../../../assets/horiz.svg";
import SecureLS from "secure-ls";
import moment from "moment";
import { AuthContext } from "../../../../../context/AuthContext";

import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory, useParams, useLocation } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import { ConnContext } from "../../../../../context/ConnContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import warngreen from "../../../../../assets/warngreen.svg";
import warnrred from "../../../../../assets/warnrred.svg";
import { SenderContext } from "../../../../../context/SenderDashboard/SenderDashboardContext";
import { BsCheckAll } from "react-icons/bs";
import { MdKeyboardBackspace } from "react-icons/md";
import Cookies from "js-cookie";
import GlobalizationLanguage, {
  GlobalizationLanguagewithCOntryCode,
} from "../../../../../../src/components/GlobalizationLanguage";
import TopBar from "../../../../../components/TopBar/TopBar";

export default function Messagefromreceivedquote() {
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const [delivery_location, setdelivery_location] = useState(undefined);
  const [ful_id, setful_id] = useState("");
  const [loading, setloading] = useState(false);

  const { id } = useParams();
  const [reply, setReply] = useState("");
  const [DMconversation, setDMconversation] = useState([]);
  const [todisplay, setTodisplay] = useState();
  const [sendingMsg, setsendingMsg] = useState(false);
  const history = useHistory();
  const [softRefresh, setsoftRefresh] = useState(false);
  const auth = useContext(AuthContext);

  const location = useLocation();
  const [datainfo, setdatainfo] = useState()
  const sendersettlement=GlobalizationLanguage("/sendersettlement")
  const senderapi=GlobalizationLanguage("/senderapi")
  const team=GlobalizationLanguage("/team")
  var sendermessage = GlobalizationLanguage("/sendermessage")


  const schema = yup.object().shape({
    message: yup
      .string()
      .required("This is required")
      .min(1, "Can't be lesser than 2 digits")
      .max(2500, "Can't exceed 20 digits"),
  });

  const {
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const [isloading, setIsloading] = useState(false);
  const [isRequired, setisRequired] = useState(false);
  const { visitingCountryCode } = useContext(ConnContext);
  var countryvalue =
    Cookies.get("i18next") || visitingCountryCode.toLowerCase();

  const [country, setCountry] = useState(countryvalue);

  const endpoint = process.env.REACT_APP_API;
  const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });


  useEffect(() => {
    // replynputRef.current.focus()
    console.log(location, "location")

    setloading(true)
    if (history?.location?.default !== undefined) {
      setdatainfo(history?.location?.default?.deliveries)
      setloading(false)
    }else{
      setloading(true)
      axios
        .get(`${endpoint}m-dillivry-sender-api`, {
          headers: {
            Authorization: ls.get("_utk"),
          },
        }).then((res)=>{
  
          const datum = res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat()
          const awaiting_quotes = datum
          ?.map((x) => x?.awaiting_quote)
          ?.flat()
          ?.filter(function (x) {
            return x?.total_quote_received === 0;
          });

          const received_quotes = datum
          ?.map((x) => x?.awaiting_quote)
          ?.flat()
          .filter(function (x) {
            return x?.total_quote_received > 0;
          });

        const awaiting_ack = datum
          ?.map((x) => x?.awaiting_acknowledgment)
          ?.flat()
          ?.filter(function (x) {
            return x?.total_quote_received > 0;
          });
        const awaiting_agent_assmt = datum
          ?.map((x) => x?.awaiting_agent_assignment)
          ?.flat()
          ?.filter(function (x) {
            return x?.total_quote_received > 0;
          });
        const pick_up = datum
          ?.map((x) => x?.awaiting_pickup)
          ?.flat()
          ?.filter(function (x) {
            return x?.total_quote_received > 0;
          });
        const intransit_data = datum
          ?.map((x) => x?.in_transit)
          ?.flat()
          ?.filter(function (x) {
            return x?.total_quote_received > 0;
          });
        const out_for_delivery_data = datum
          ?.map((x) => x?.out_for_delivery)
          ?.flat()
          ?.filter(function (x) {
            return x?.total_quote_received > 0;
          });
        const completed_data = datum?.map((x) => x?.past_deliveries)?.flat();
        const missed_data = datum?.map((x) => x?.missed_delivery)?.flat();
        const expired_data = datum?.map((x) => x?.expired_deliveries)?.flat();

        const data = [
          ...awaiting_quotes,
          ...received_quotes,
          ...awaiting_ack,
          ...awaiting_agent_assmt,
          ...pick_up,
          ...intransit_data,
          ...out_for_delivery_data,
          ...completed_data,
          ...missed_data,
          ...expired_data,
        ];
setdatainfo(data.filter(x => x.delivery_id === id)[0])
setloading(false)
        }).catch((err)=>{
          console.log(err, "err");
        })
    }

    setloading(true)
    axios
    .get(
      `${process.env.REACT_APP_API}m-dillivry-sender-api?attribute=get_message_list`,
      {
        headers: {
          Authorization: ls.get("_utk"),
        },
      }
    )
    .then((response) => {

      setTodisplay(
        response?.data?.data
        ?.map((x) => x?.ng ?? x?.gb)
        ?.flat()
        .map((x) => x.quote_messages)
        .flat()?.filter((x) => x?.delivery_id === id)?.map(
          (x) => x?.fulfiller_conversation
        )[0]
      );
      setdelivery_location(
        response?.data?.data
        ?.map((x) => x?.ng ?? x?.gb)
        ?.flat()
        .map((x) => x.quote_messages)
        .flat()?.filter((x) => x?.delivery_id === id)?.map(
          (x) => x?.delivery_location
        )[0]
      )

    
      setIsloading(false);
      setloading(false)
      setsendingMsg(false);

      setReply("");

      DMconversation.conversation = response?.data?.data
        ?.map((x) => x?.ng ?? x?.gb)
        ?.flat()
        .map((x) => x.quote_messages)
        .flat()?.filter(
        (x) => x?.delivery_id === id
      ).map((x) => x?.fulfiller_conversation)[0];
      setloading(false)
    }).catch((err)=>{
      console.log(err)
      setloading(false)
    })

  }, []);

  const Initiatemssgfromdrilldown = () => {

    if (reply === "") {
      setisRequired(true);
    } else {
      setisRequired(false);
      setsendingMsg(true);
      authAxios
        .post(
          `m-dillivry-quote-manager?delivery_location=${datainfo?.vcc}`,
          {
            action_type: "quote_msg",
            delivery_id: datainfo.delivery_id,
            fulfiller_id: location?.state,
            msg: reply,
          },
          { timeout: 240000 }
        )
        .then((res) => {
          setReply("");
          if (res.status === 200) {
            axios
              .get(
                `${process.env.REACT_APP_API}m-dillivry-sender-api?attribute=get_message_list`,
                {
                  headers: {
                    Authorization: ls.get("_utk"),
                  },
                }
              )
              .then((response) => {
                let Tofilter = response?.data?.data
                  ?.map((x) => x?.ng ?? x?.gb)
                  ?.flat()
                  .map((x) => x.quote_messages)
                  .flat();

                setTodisplay(
                  Tofilter?.filter((x) => x?.delivery_id === id)?.map(
                    (x) => x?.fulfiller_conversation
                  )[0]
                );

                setIsloading(false);
                setsendingMsg(false);
                setReply(" ");
                DMconversation.conversation = Tofilter?.filter(
                  (x) => x?.delivery_id === id
                ).map((x) => x?.fulfiller_conversation)[0];
              });
          }
        })
        .catch((err) => {
          console.log(err, "errrrr");
          setReply("");
          setsendingMsg(false);
          if (err?.response?.status === 403) {
            Swal.fire({
              icon: "warning",
              title: "Session!",
              text: "Session expired login again!",
              allowOutsideClick: false,
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              setsendingMsg(false);
              if (result.value) {
                auth.logout();
                history.push(
                  GlobalizationLanguagewithCOntryCode(
                    `/login`,
                    country,
                    visitingCountryCode
                  )
                );
              }
            });
          } else if (err?.response?.status === 401) {
            setReply("");
            setsendingMsg(false);
            Swal.fire({
              icon: "info",
              text: "item has been delivered, message not sent",
              timer: 4000,
              showConfirmButton: false,
            });
          } else {
            setsendingMsg(false);
            setReply("");
            Swal.fire({
              icon: "info",
              text: "Ops! Message not sent, try again later",
              timer: 4000,
              showConfirmButton: false,
            });
            if (err.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push(
                GlobalizationLanguagewithCOntryCode(
                  `/timeout`,
                  country,
                  visitingCountryCode
                )
              );
            }
            setsendingMsg(false);
          }
        });
    }
  };

  const capitalize = (x) => {
    return x;
  };

  function reverseArr(input) {
    var ret = new Array();
    for (var i = input?.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  return (
    <div>
                 {/* <SenderSideBar pageTitle="Message" links={links}/> */}

{loading === true ? (
            <div
              style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "none",
                zIndex: "300",
                position: "fixed",
                // top: "70px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <LinearProgress color="success" />
              </Box>
            </div>
        
          ) : (

           <div className="allbody">
<div >
  <TopBar/>
</div>




<div  className="second-msg">
             
          
             <>
               <div className="chat-con" style={{paddingTop: "70px"}}>
               <MdKeyboardBackspace
                   color="#50b14a"
                   fontSize="25px"
                   onClick={() => history.goBack()}
                 />
                 <div className="msgViewContainerTop2" style={{marginTop:'10px'}}>
                   <div
                     className="msgViewContainerTopImage"
                     style={{
                       backgroundImage: `url(${datainfo?.image_urls[0]})`,
                     }}
                   ></div>
             
                   <div className="msgViewTopText">
                     <p>
                       {" "}
                       {datainfo?.item_desc
                         .charAt(0)
                         ?.toUpperCase() +
                         datainfo?.item_desc?.slice(
                           1
                         ) +
                         " (" +
                         datainfo?.delivery_id +
                         ")"}
                     </p>
                     <p>
                       {" "}
                       <img src={warngreen} alt="msgpi_icon" />
                       &ensp;
                       {datainfo?.from}
                     </p>
                     <p>
                       {" "}
                       <img src={warnrred} alt="msgpi_icon" />
                       &ensp;{datainfo?.to}
                     </p>
                   </div>
                 </div>
             
                 <div>
                   <div
                     style={{
                       padding: "10px",
                       border: "1px solid #c4c4c4",
                       marginTop: "3px",
                       marginBottom: "3px",
                       borderTopRightRadius: "5px",
                       borderTopLeftRadius: "5px",
                       cursor: "pointer",
                       userSelect: "none",
                       display: "flex",
                       justifyContent: "space-between",
                       alignItems: "center",
                     }}
                   >
                     <div
                       style={{
                         fontSize: "14px",
                         fontWeight: "500",
                         color: "var(--main)",
                       }}
                     >
                      
                       {location?.default?.data?.fulfiller_id === undefined
                         ? ful_id?.state
                         : location?.default?.data?.fulfiller_id}
                     </div>
                   </div>
                 </div>
             
                 {todisplay !== undefined
                   ? reverseArr(todisplay?.map((x) => x?.conversation).flat())?.map(
                       (mssg, index) => {
                         return (
                           <>
                          
                             {mssg?.fulfiller_id === undefined ? (
                               <>
                                 <div className="right-wrapper">
                                   <div class="message-right">
                                     <div className="main-msg">
                                       <p> {capitalize(mssg?.msg)}</p>{" "}
                                     </div>
                                     <div className="message-timeStamp">
                                       <div className="all-time">
                                         <div
                                           className="timediplaydesk"
                                           style={{
                                             fontSize: "10px",
                                           }}
                                         >
                                           {moment(mssg?.time_stamp).format(
                                             "MMM Do YYYY, h:mm a "
                                           )}
                                           ({moment(mssg?.time_stamp).fromNow()})
                                         </div>
                                         <div
                                           className="timediplaymobile"
                                           style={{
                                             fontSize: "7px",
                                           }}
                                         >
                                           {moment(mssg?.time_stamp).format(
                                             "MMM Do YYYY, h:mm a "
                                           )}
                                           ({moment(mssg?.time_stamp)?.fromNow()})
                                         </div>
                                       </div>
                                     </div>
                                     <div className="message-timeStamp">
                                       <div className="all-time">
                                         <BsCheckAll color="white" size="18px" />
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </>
                             ) : (
                               <>
                                 <div className="left-wrapper">
                                   <span class="imageView-cropper">
                                     <img
                                       src={userAvatar}
                                       class="rounded"
                                       alt="userAvatar"
                                     />
                                   </span>
                                   <div class="message-left">
                                     <div>
                                       <strong>
                                         {capitalize(mssg?.fulfiller_id)}
                                       </strong>
                                     </div>
                                     <div>
                                       <p className="text-sty">
                                         {capitalize(mssg?.msg)}{" "}
                                       </p>
                                     </div>
                                     <div className="message-timeStamp">
                                       <div className="all-time">
                                         <div
                                           className="timediplaydesk"
                                           style={{
                                             fontSize: "10px",
                                           }}
                                         >
                                           {moment(mssg?.time_stamp).format(
                                             "MMM Do YYYY, h:mm a "
                                           )}
                                           ({moment(mssg?.time_stamp).fromNow()})
                                         </div>
                                         <div
                                           className="timediplaymobile"
                                           style={{
                                             fontSize: "7px",
                                           }}
                                         >
                                           {moment(mssg?.time_stamp).format(
                                             "MMM Do YYYY, h:mm a "
                                           )}
                                           ({moment(mssg?.time_stamp).fromNow()})
                                         </div>
                                       </div>
                                     </div>
                                     <div className="message-timeStamp">
                                       <div className="all-time">
                                         <BsCheckAll
                                           color="var(--main)"
                                           size="18px"
                                         />
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </>
                             )}
                           </>
                         );
                       }
                     )
                   : null}
             
                 <div className="reply-message-wrap">
                   <div className="reply-message-con">
                     <p>{errors.message?.message}</p>
                     <div className="send-con">
                       <div className="send-icons">
                         <span>
                           <textarea type="text"  autoFocus onChange={(e) => {
                               setReply(e.target.value);
                               setisRequired(false);
                             }}
                               placeholder="Reply..." className="reply-input"  />
                          </span>
                       </div>
                       <div className="send-icons">
                         <span type="submit" value="submit" className="send-icon">
                           {sendingMsg ? (
                             <>
                               <span className="sending">
                                 <PuffLoader
                                   color="var(--main)"
                                   loading={sendingMsg}
                                   speedMultiplier={1}
                                   size={25}
                                 />
                               </span>
                             </>
                           ) : (
                             <span>
                               <img
                                 src={send}
                                 alt=""
                                 style={{ width: "20px" }}
                                 onClick={Initiatemssgfromdrilldown}
                               />
                             </span>
                           )}
                         </span>
                         <span>
                           {" "}
                           <img
                             src={horiz}
                             alt=""
                             style={{ marginLeft: "20px" }}
                           />{" "}
                         </span>
                       </div>
                     </div>
                   </div>
                   <div>
                     <br></br>
                   </div>
                 </div>
               </div>
             </>
             </div>
 
           </div>
          )}
    </div>
  );
}
