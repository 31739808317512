import {useState,useContext,useEffect} from 'react'
import {useHistory} from 'react-router-dom';
import {BiLogOut, BiTimer, BiTransfer, BiWallet} from 'react-icons/bi'
import MUTable from '../../../../components/MUTable'
import { AuthContext } from '../../../../context/AuthContext';
import { TrackContext } from '../../../../context/TrackingContext';
import {MdDelete, MdPayment} from 'react-icons/md'
import {FaUsersCog} from 'react-icons/fa'
import {RiAccountPinCircleLine} from 'react-icons/ri'
import {GoChevronRight} from 'react-icons/go'
import Settlement from '../Settlement';
import UserSetting from '../UserSetting';
import AccountSetting from '../AccountSetting';
import {FiSettings} from 'react-icons/fi';
import MobileTransaction from '../MobileTransaction';
import DialogOutClick from '../../../../components/DialogOutClick';
import { PuffLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import axios from 'axios';
import SecureLS from 'secure-ls';
import{DashboardContext}  from '../../../../context/Dashboard/DashboardProvider/dashboard.provider'
import Nav from '../../../../components/Nav/Nav';
import { AiTwotoneShop } from 'react-icons/ai';
import { TiMessages } from 'react-icons/ti';
import { ConnContext } from '../../../../context/ConnContext';
import Box from "@mui/material/Box";
import '../Fulfiller_account.css'
import LinearProgress from "@mui/material/LinearProgress";
import TopBar from '../../../../components/TopBar/TopBar';
import GlobalizationLanguage from "../../../../components/GlobalizationLanguage";

function FulfillerAccount({booked, settlement, users}) {
    var login=GlobalizationLanguage("/login")
  var wallet = GlobalizationLanguage("/wallet")
  var accountsettings = GlobalizationLanguage("/account/settings")
  var accountusers = GlobalizationLanguage("/account/users")
  var accountsettlement = GlobalizationLanguage("/account/settlement")
  var deliveries_history = GlobalizationLanguage("/deliveries_history")
  var messages = GlobalizationLanguage("/messages")
  const Dashboard = GlobalizationLanguage("/Dashboard")

    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

    const auth = useContext(AuthContext);
    const [loadingData, setloadingData] = useState(false)

    const history = useHistory();
    const [active, setactive] = useState('transaction')
    const [showLogout, setShowLogout] = useState(false)
    const [showDeteleAcct, setShowDeteleAcct] = useState(false)
    const [loading, setloading] = useState(false)
    const [deleteInput, setdeleteInput] = useState('')
    
    const [account, setaccount] = useState([])
    const [transaction, setTransaction] = useState([])
    const [userProfile, setuserProfile] = useState([])

    const authAxios = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers:{
           Authorization:  ls.get('_nodi')
        }
    })

    useEffect(() => {
        if (dashboards !== undefined) {
            setaccount(dashboards.data.my_account_info.my_account_info)
            setTransaction(dashboards.data.transaction_details.transaction_details)
            setuserProfile(dashboards.data.account_profile.account_profile[0])
        }
        else{
            setloadingData(true)
            authAxios.get(`m-dillivry-dashboard-manager`)
            .then((response) => {
                ls.set(
                    "means_of_delivery",
                    JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                  );
                setloadingData(false)
                updateDashboard(response);
                setaccount(response.data.my_account_info.my_account_info)
                setTransaction(response.data.transaction_details.transaction_details)
                setuserProfile(response.data.account_profile.account_profile[0])
            })
            .catch((err)=>{
                setloadingData(false)
                if (err?.response?.status === 403) {
                    Swal.fire({
                      icon: "warning",
                      title: "Session!",
                      text: "Session expired login again!",
                      allowOutsideClick: false,
                      confirmButtonColor: "var(--main)",
                    }).then((result) => {
                      if (result.value) {
                        auth.logout();
                        history.push(login);
                      }
                    });
                } 
            })
        }
    }, [])

    const logMeOut = ()=>{
       auth.logout();
        history.push('/') 
    }
    const {dashboards, updateDashboard} = useContext(DashboardContext)
   
    const endpoint = process.env.REACT_APP_API
    const {visitingCountryCode} = useContext(ConnContext)

    const disableAccount = () =>{
        axios.delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
            headers:{
                Authorization:  ls.get('_nodi')
             },
             data : {
                "action_type": "disable_account",
                "vcc": visitingCountryCode
            }
          })
        .then((res)=>{
            setShowDeteleAcct(false)
            Swal.fire({
                title: 'Account disabled',
                text: 'contact the dillivry team to enable you account back',
                allowOutsideClick: false,
                confirmButtonColor:'var(--main)',
            })
            .then((result)=>{
                if (result.value) {
                    auth.logout();
                    history.push('/') 
                }
            });
        })
        .catch((err)=>{
            if(err?.response?.status === 403){
                Swal.fire({
                    icon: 'warning',
                    title: 'Session!',
                    text: 'Session expired login again!',
                    allowOutsideClick: false,
                    confirmButtonColor: 'var(--main)'
                })
                .then((result) => {
                    if (result.value) {
                        auth.logout();
                        history.push(login) 
                    }
                });
            }
            else{
                Swal.fire({
                    icon: 'info',
                    text: 'An error occur try again',
                    timer: 3000,
                    showConfirmButton: false,
                })
            }
        })
    }
   
    const deleteMyAccount = () =>{
        setloading(true);
        axios.delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
            headers:{
                Authorization:  ls.get('_nodi')
             },
             data : {
                "action_type": "delete_account",
                "vcc": visitingCountryCode
            }
          })
        .then((res)=>{
            setShowDeteleAcct(false)

            Swal.fire({
                title: 'Account deleted',
                text: 'We are very sorry to see you go',
                allowOutsideClick: false,
                confirmButtonColor:'var(--main)',
            })
            .then((result)=>{
                if (result.value) {
                    auth.logout();
                    history.push('/') 
                }
            });
        })
        .catch((err)=>{
            if(err?.response?.status === 403){
                Swal.fire({
                    icon: 'warning',
                    title: 'Session!',
                    text: 'Session expired login again!',
                    allowOutsideClick: false,
                    confirmButtonColor: 'var(--main)'
                })
                    .then((result) => {
                        if (result.value) {
                            auth.logout();
                            history.push(login) 
                        }
                    });
            }
            else{
                Swal.fire({
                    icon: 'info',
                    text: 'An error occur try again',
                    timer: 3000,
                    showConfirmButton: false,
                })
            }
    })
}


    const numberWithCommas = new Intl.NumberFormat("en-GB")
    return (
        <>
        {loadingData ? (
            <div
            style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "none",
                zIndex: "300",
                position: "fixed",
                top: "0",
                paddingTop: "5px",
            }}
            >
            <Box sx={{ width: "100%" }}>
                <LinearProgress color="success" />
            </Box>
            </div>
        ) : null}
        <div className="dashboardDesktop">
            <div className="topmenu">
               <TopBar/>
            </div>
            <div className="content-view_menu">
                <div className = "tab"  onClick={()=>history.push(Dashboard)}><AiTwotoneShop /> &nbsp; Dillivry Activities</div>
                <div className="tab"  onClick={() => history.push(wallet) }  >
                    <BiWallet /> &nbsp; Wallet
                  </div>
                {/* <div className = "tab" onClick={()=>history.push('/BookDelivery')}><TiMessages /> &nbsp; Create delivery</div> */}
                <div className = "tab" onClick={()=>history.push(messages)}><TiMessages /> &nbsp; Messages</div>
                <div className= "tab" onClick={()=>history.push(deliveries_history)}><BiTimer /> &nbsp;  Quote history</div>
                <div className= "activeTabs"><RiAccountPinCircleLine /> &nbsp;  Account</div>
            </div>
        </div>
        <div className="Fulfiller_account">
             <div className="_account">
                {
                account.map((acct, index)=>{return <div style={{display:'flex',paddingTop:'20px', flexDirection:'column', gap:'20px'}} key={index}>
                    <div style={{display:'flex',flexDirection:'column', gap:'10px'}}>
                    
                        <div className="activeTapps">
                            <div><BiTransfer/> Transactions</div><GoChevronRight/>
                        </div>
                        
                    {
                        account[0]?.role === 'dillivry-corp-sys-admin' ? 
                        <> 
                            <div className= "tapps"
                                onClick={()=>history.push(accountsettlement)}
                            >
                                <div><MdPayment/> Settlements</div><GoChevronRight/>
                            </div>
                            
                            <div className="tapps" onClick={()=>history.push(accountusers)}>
                                <div><FaUsersCog/> User Manager</div><GoChevronRight/>
                            </div>
                        </>
                        :
                        null
                    }

                        <div className="tapps"
                            onClick={()=>history.push(accountsettings)}>
                            <div><FiSettings/>Settings</div><GoChevronRight/>
                        </div>

                    {
                        account[0]?.role === 'dillivry-corp-sys-admin' ? 
                        <div className= "tapps" onClick={() => {
                            setShowDeteleAcct(true)
                        }}>
                            <div><MdDelete/>Delete my account</div><GoChevronRight/>
                        </div>
                        :
                        null
                    }

                        <div className="tapps" onClick={() => {
                            setShowLogout(true)
                        }}>
                            <div><BiLogOut/>Logout</div><GoChevronRight/>
                        </div>
                    </div> 

                    <div className="profileInfo">
                        <div className="profile">
                            <div className="profileimg" style={{ backgroundImage:`url(${userProfile?.cop_logo})`}}>
                            </div>
                            <div className="profilename">{acct.account_name.charAt(0)?.toUpperCase() + acct.account_name?.slice(1)}</div>
                            <div className="profilelocation">{acct.account_id}</div>
                            <div className="profilenum">{acct.user_id}</div>
                            <div className="profilenum">{account[0]?.role === 'dillivry-corp-sys-admin' ? "Admin(Corperate)" : account[0]?.role === 'dillivry-corp-bid-ops' ? "Bidder(Business)" : account[0]?.role === 'dillivry-sole-trader-ops' ? "" : account[0]?.role === 'dillivry-corp-last-mile-ops' ? "Fulfilling Agent(Business)" : null }</div>
                            {acct?.account_status?.state === "active" || acct?.account_status?.state === "CONFIRMED" ?
                                null
                                :
                                <div className="profilenum">{acct?.account_status?.state}</div>
                            }
                        </div>
                    </div>
                </div>})
                }

                {
                    active === 'transaction' ? 

                    <div className="fulfillers">
                        <div className='btnAssigns'>
                <div>
                </div>
                <div className="btnAssign" 
                    onClick={() => {
                        auth.logout();
                        history.push('/')
                    }}>
                    <BiLogOut size='18px'/> Logout
                </div>
            </div> 
            <div className="usersTable">
            <MUTable
                style={{width:'100%', fontSize:'8px'}}
                title="Transactions"
                columns={[
                    { title: 'Delivery id', 
                        field: 'delivery_id',
                    },
                    { title: 'Reference id', 
                        field: 'reference_id', 
                    },
                    { title: 'Amount due (NGN)', 
                        field: 'amount_due_to_fulfiller',
                          render: rowData => <div>{numberWithCommas.format(rowData.amount_due_to_fulfiller)} </div>
                    },
                    { title: 'Charges (NGN)',
                        field: 'amount_due_to_platform',
                        render: rowData => <div>{numberWithCommas.format(rowData.amount_due_to_platform)} </div>   
                    },
                    { title: 'Gross Amount (NGN)', field: 'gross_amount',
                        render: rowData => <div>{numberWithCommas.format(rowData.gross_amount)} </div>  
                    },
                    { title: 'Payment Date', field: 'payment_date' },
                    ]}
                data={transaction}
                options={{
                    pageSize:10, 
                }}
            />
            </div>
                </div>
                :
                active === 'settlement' ?
                <>
                <Settlement quoted = {settlement} bookedDelivery = {booked} />

                </>
                :
                active === 'user' ?
                <UserSetting goBack={()=>setactive('active')}  users={users}/>
                :
                <AccountSetting userProfile={account}/>
                }  
            </div>
            

            {/* mobile view start here */}
            <div className="_accountMobile">
                <MobileTransaction/>
            </div>
            <DialogOutClick
                open={showLogout}
                handleClose ={()=>setShowLogout(false)}
                > 
                <div style={{width:'100%',padding:"30px 30px 20px 30px"}}>
                    <div style={{width:'100%',marginBottom:"30px", fontSize:'13px', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <span>Are you sure you want to logout?</span>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
                        <div className="disBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer'}}
                            onClick={()=>setShowLogout(false)} >
                            No
                        </div>
                        <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'white', backgroundColor:'#808080', border:'1px solid #808080' }}
                            onClick={logMeOut}>
                            Yes
                        </div>
                    </div>
                </div>
            </DialogOutClick>
            <DialogOutClick
                open={showDeteleAcct}
                handleClose ={()=>setShowDeteleAcct(false)}
                > 
                <div style={{width:'100%',padding:"30px 30px 20px 30px"}}>
                    <div style={{width:'100%',marginBottom:"30px", fontSize:'14px', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <span>Are you sure you want to delete this account? Type in <b>permanently delete</b> in the input below to confirm deletion</span>
                    </div>
                    <div style={{marginBottom:'30px'}}>
                        <input 
                            type="text" 
                             placeholder="permanently delete"
                             value={deleteInput}
                             onChange={(e)=>setdeleteInput(e.target.value)}
                             style={{border:'1px solid var(--main)', padding:'7px 10px', outline:'none', borderRadius:'5px', fontSize:'13px'}}
                        />
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
                        <div className="disBtn" style={{fontSize:'13px', fontWeight:'bold', cursor:'pointer',border:'#0080FF solid 1px', backgroundColor:'#0080FF', color:'white'}}
                            onClick={disableAccount} >
                            Disable instead
                        </div>
                        <div className="disBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer'}}
                            onClick={()=>setShowDeteleAcct(false)} >
                            Cancel
                        </div>
                        {
                            deleteInput === "permanently delete" ?
                            <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'white', backgroundColor:'red', border:'1px solid red' }}
                                onClick={deleteMyAccount}>
                                {
                                    loading ? 
                                    <PuffLoader color='var(--main)' loading={loading}  speedMultiplier={1} size={15}/> 
                                    :
                                    'Yes'
                                }
                            </div>
                            :
                            <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'not-allowed', color:'white', backgroundColor:'#B86566', border:'1px solid #B86566' }}>
                                Yes
                            </div>

                        }
                        
                    </div>
                </div>
            </DialogOutClick>
        </div>
    </>
       
    )
}

export default FulfillerAccount
