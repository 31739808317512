import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import dillivry from "../../../assets/Dillivry.png";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { IconContext } from "react-icons";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Carousel from "react-material-ui-carousel";
import GoogleMapWidget from "../../../components/Map/GoogleMapWidget";
import { AiFillFileText } from "react-icons/ai";
import { MdDashboard, MdBlock, MdLocationOn, MdPayment } from "react-icons/md";
import { FaClipboardCheck } from "react-icons/fa";
import { RiMiniProgramFill } from "react-icons/ri";
import { BsCreditCard, BsInfoCircle } from "react-icons/bs";
import { BiArrowBack, BiPhoneCall ,BiTimer } from "react-icons/bi";
import { IoMdMailOpen } from "react-icons/io";
import { Button } from "@material-ui/core";
import {Grid} from '@mui/material'
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DialogPop from "../../../components/DialogPop";
import { PuffLoader } from "react-spinners";
import MessageFulfiller from "../../SendQuote/ViewQuote/MessageFulfiller";
import SecureLS from "secure-ls";
import { DashboardContext } from "../../../context/Dashboard/DashboardProvider/dashboard.provider";
import { AuthContext } from "../../../context/AuthContext";
import { useHistory } from "react-router-dom";
import DashDrillDownCurrent from "./DashDrillDownCurrent";
import jwt_decode from "jwt-decode";
import DialogOutClick from "../../../components/DialogOutClick";
import { TiUserAddOutline } from "react-icons/ti";
import LoginCorporate from "../../../pages/Login/LoginCorporate";
import { ConnContext } from "../../../context/ConnContext";
import moment from 'moment';
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
import Cookies from 'js-cookie';
import Currency from "../../../utils/Currency";

function DashDrillDown({ dp }) {
  const { visitingCountryCode } = useContext(ConnContext);
  const history = useHistory();
  const [dataRefresh, setdataRefresh] = useState(false);
  var myDillivry = GlobalizationLanguage('/MyDillivry')

  const {
    dashboards,
    updateDashboard,
    assignAgent,
    re_assignAgent,
    re_assign_pickedup,
    re_assign_out,
    re_assign_missed,
    setpage,
     page
  } = useContext(DashboardContext);

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const { type, id } = useParams();

  const [ShowLogin, setShowLogin] = useState(false);
  const [viewData, setviewData] = useState([]);
  const [showAgents, setShowAgents] = useState(false);
  const [origin, setorigin] = useState([]);
  const [destination, setdestination] = useState([]);
  const [assignedAgent, setAssignedAgent] = useState([]);
  const [availableAgent, setAvailableAgent] = useState([]);

  const [itemToReject, setItemToReject] = useState([]);
  const [deli_locations, setDeli_locations] = useState("")
  const [rejectPopup, setrejectPopup] = useState(false);
  const [rejecting, setrejecting] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loadingData, setloadingData] = useState(false);
  const [error, seterror] = useState("");
  const [isError, setIsError] = useState(false);

  const [fulfiller_id_to_assign, setfulfiller_id_to_assign] = useState("");
  const [selectedLastmile, setSelectedLastmile] = useState("");
  const [assigning, setassigning] = useState(false);
  const [AssignType, setAssignType] = useState("");

  const assignNow = () => {
    if (
      selectedLastmile.account_status.device_status === undefined ||
      selectedLastmile.account_status.device_status === "awaiting_verification"
    ) {
      setShowAgents(!showAgents);
      Swal.fire({
        icon: "warning",
        text: `You can't assign this agent now, as he/she is yet to register his/her device`,
        timer: 3000,
        showConfirmButton: false,
      });
      setfulfiller_id_to_assign("");
    } else {
      setassigning(true);
      authAxios
        .post(
          `m-dillivry-bid-manager?delivery_location=${viewData[0].delivery_location}`,
          {
            action_type: AssignType === "assign" ? "assign_agent" : "re_assign",
            user_id: fulfiller_id_to_assign,
            delivery_id: viewData[0].delivery_id,
            delivery_status: viewData[0].delivery_status,
            vcc: visitingCountryCode,
          },
          { timeout: 24000 }
        )
        .then(() => {
          setShowAgents(!showAgents);
          setassigning(false);

          Swal.fire({
            icon: "success",
            text: "Agent Assigned",
            timer: 2500,
            showConfirmButton: false,
          });

          if (
            viewData[0].delivery_status === "awaiting_agent_assignment" &&
            AssignType === "assign"
          ) {
            assignAgent({
              agentid: fulfiller_id_to_assign,
              delivery_id: viewData[0].delivery_id,
              agentName: `${selectedLastmile.first_name} ${selectedLastmile.last_name}`,
            });

            history.push({
              pathname: "/MyDillivry",
              state: type === "unassign" || type === "assign" ? "accept" : type,
            });
          } else if (
            viewData[0].delivery_status === "awaiting_pickup" &&
            AssignType === "reassign"
          ) {
            re_assignAgent({
              agentid: fulfiller_id_to_assign,
              delivery_id: viewData[0].delivery_id,
              agentName: `${selectedLastmile.first_name} ${selectedLastmile.last_name}`,
            });
            setdataRefresh(!dataRefresh);
          } else if (
            viewData[0].delivery_status === "picked_up" &&
            viewData[0].quote_status !== "missed_delivery" &&
            AssignType === "reassign"
          ) {
            re_assign_pickedup({
              agentid: fulfiller_id_to_assign,
              delivery_id: viewData[0].delivery_id,
              agentName: `${selectedLastmile.first_name} ${selectedLastmile.last_name}`,
            });
            setdataRefresh(!dataRefresh);
          } else if (
            viewData[0].delivery_status === "out_for_delivery" &&
            AssignType === "reassign"
          ) {
            re_assign_out({
              agentid: fulfiller_id_to_assign,
              delivery_id: viewData[0].delivery_id,
              agentName: `${selectedLastmile.first_name} ${selectedLastmile.last_name}`,
            });
            setdataRefresh(!dataRefresh);
          } else if (
            viewData[0].quote_status === "missed_delivery" &&
            AssignType === "reassign"
          ) {
            re_assign_missed({
              agentid: fulfiller_id_to_assign,
              delivery_id: viewData[0].delivery_id,
              agentName: `${selectedLastmile.first_name} ${selectedLastmile.last_name}`,
            });
            setdataRefresh(!dataRefresh);
          } else {
            console.log("delivery status not captured");
          }
        })
        .catch((err) => {
          setShowAgents(!showAgents);
          setassigning(false);
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "An error occured!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              auth.logout();
              setShowLogin(true);
            }
          });
          if (err.code === "ECONNABORTED") {
            console.log("internal-server-error-timeout");
            history.push("/timeout");
          }
          console.log(err);
        });
    }
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day, month, year].join("-");
  }

  const Authorization = ls.get("_nodi");

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: Authorization,
    },
  });

  const auth = useContext(AuthContext);

  const schema = yup.object().shape({
    comment: yup
      .string()
      .required("State your reason")
      .min(10, "Can't be lesser than 10 digits")
      .max(80, "Can't be lesser than 80 digits"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const rejectQuote = async (data) => {
    setrejecting(true);
    authAxios
      .post(
        `m-dillivry-bid-manager?delivery_location=${viewData[0].delivery_location}`,
        {
          action_type: "reject_bid",
          delivery_id: itemToReject,
          comment: data.comment,
          vcc: visitingCountryCode,
        },
        { timeout: 240000 }
      )
      .then((response) => {
        setrejectPopup(false);
        setrejecting(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "info",
            text: "Quote rejected!",
            timer: 2500,
            showConfirmButton: false,
          });
        }
      })
      .catch((err) => {
        setrejectPopup(false);
        setrejecting(false);
        Swal.fire({
          icon: "error",
          text: "error!",
          timer: 2000,
          showConfirmButton: false,
        });
        console.log(err);
        if (err.code === "ECONNABORTED") {
          console.log("internal-server-error-timeout");
          history.push("/timeout");
          // window.location.reload()
        }
      });
  };

  const getDeliveryData = (res) =>{

    let data = [];

      if (
        res.data.current_quotes.filter((item) => item.delivery_id === id)
          .length === 1
      ) {
        data = res.data.current_quotes.filter(
          (item) => item.delivery_id === id
        );
      }
      if (
        res.data.accepted_quotes.assigned.filter(
          (item) => item.delivery_id === id
        ).length === 1
      ) {
        data = res.data.accepted_quotes.assigned.filter(
          (item) => item.delivery_id === id
        );
        const agents = res.data.all_users_list.all_users_list.filter(
          function (lastmile) {
            return lastmile.role === "dillivry-corp-last-mile-ops";
          }
        );
        setAssignedAgent(
          agents.filter(
            (agent) =>
              agent.user_id === data[0].assigned_agent_details.pickup_agent_id
          )
        );
        setAvailableAgent(
          agents.filter(
            (agent) =>
              agent.user_id !==
                data[0].assigned_agent_details.pickup_agent_id &&
              agent.current_status === "active"
          )
        );
      }
      if (
        res.data.accepted_quotes.unassigned.filter(
          (item) => item.delivery_id === id
        ).length === 1
      ) {
        data = res.data.accepted_quotes.unassigned.filter(
          (item) => item.delivery_id === id
        );
        const agents = res.data.all_users_list.all_users_list.filter(
          function (lastmile) {
            return (
              lastmile.role === "dillivry-corp-last-mile-ops" &&
              lastmile.current_status === "active"
            );
          }
        );
        setAvailableAgent(agents);
      }
      if (
        res.data.picked_up.filter((item) => item.delivery_id === id)
          .length === 1
      ) {
        data = res.data.picked_up.filter(
          (item) => item.delivery_id === id
        );
        const agents = res.data.all_users_list.all_users_list.filter(
          function (lastmile) {
            return lastmile.role === "dillivry-corp-last-mile-ops";
          }
        );
        setAssignedAgent(
          agents.filter(
            (agent) =>
              agent.user_id === data[0].assigned_agent_details.pickup_agent_id
          )
        );
        setAvailableAgent(
          agents.filter(
            (agent) =>
              agent.user_id !==
                data[0].assigned_agent_details.pickup_agent_id &&
              agent.current_status === "active"
          )
        );
      }
      if (
        res.data.out_for_delivery.filter(
          (item) => item.delivery_id === id
        ).length === 1
      ) {
        data = res.data.out_for_delivery.filter(
          (item) => item.delivery_id === id
        );
        const agents = res.data.all_users_list.all_users_list.filter(
          function (lastmile) {
            return lastmile.role === "dillivry-corp-last-mile-ops";
          }
        );
        setAssignedAgent(
          agents.filter(
            (agent) =>
              agent.user_id === data[0].assigned_agent_details.pickup_agent_id
          )
        );
        setAvailableAgent(
          agents.filter(
            (agent) =>
              agent.user_id !==
                data[0].assigned_agent_details.pickup_agent_id &&
              agent.current_status === "active"
          )
        );
      }
      if (
        res.data.missed_delivery.filter(
          (item) => item.delivery_id === id
        ).length === 1
      ) {
        data = res.data.missed_delivery.filter(
          (item) => item.delivery_id === id
        );
        const agents = res.data.all_users_list.all_users_list.filter(
          function (lastmile) {
            return lastmile.role === "dillivry-corp-last-mile-ops";
          }
        );
        setAssignedAgent(
          agents.filter(
            (agent) =>
              agent.user_id === data[0].assigned_agent_details.pickup_agent_id
          )
        );
        setAvailableAgent(
          agents.filter(
            (agent) =>
              agent.user_id !==
                data[0].assigned_agent_details.pickup_agent_id &&
              agent.current_status === "active"
          )
        );
      }
      if (
        res.data.unsuccessful_quotes.filter(
          (item) => item.delivery_id === id
        ).length === 1
      ) {
        data = res.data.unsuccessful_quotes.filter(
          (item) => item.delivery_id === id
        );
      }
      if (
        res.data.outbid_auction.filter(
          (item) => item.delivery_id === id
        ).length === 1
      ) {
        data = res.data.outbid_auction.filter(
          (item) => item.delivery_id === id
        );
      }
      if (
        res.data.completed_quotes.filter(
          (item) => item.delivery_id === id
        ).length === 1
      ) {
        data = res.data.completed_quotes.filter(
          (item) => item.delivery_id === id
        );
        const agents = res.data.all_users_list.all_users_list.filter(
          function (lastmile) {
            return lastmile.role === "dillivry-corp-last-mile-ops";
          }
        );
        setAssignedAgent(
          agents.filter(
            (agent) =>
              agent.user_id === data[0].delivery_agent_details.pickup_agent_id
          )
        );
        setAvailableAgent(
          agents.filter(
            (agent) =>
              agent.user_id !==
                data[0].delivery_agent_details.pickup_agent_id &&
              agent.current_status === "active"
          )
        );
      }

      setviewData(data);
      const startLat = parseFloat(data[0]?.start_location?.lat);
      const startlng = parseFloat(data[0]?.start_location?.lng);
      const endLat = parseFloat(data[0]?.end_location?.lat);
      const endLng = parseFloat(data[0]?.end_location?.lng);

      setorigin([
        {
          id: "pickup",
          title: "Pickup",
          coordinates: {
            lat: startLat,
            lon: startlng,
          },
        },
      ]);
      setdestination([
        {
          id: "destination",
          title: "Destination",
          coordinates: {
            lat: endLat,
            lon: endLng,
          },
        },
      ]);
  }
  useEffect(() => {
    if (dashboards !== undefined) {
      getDeliveryData(dashboards)
    } else {
      if (ls.get("_nodi") === "") {
        setShowLogin(true);
        auth.logout();
      } else {
        const decoded = jwt_decode(ls.get("_nodi"));
        const expired = decoded.exp;

        if (expired < Date.now() / 1000) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              ls.removeAll();
              setShowLogin(true);
            }
          });
        } else {
          if (
            type !== "current" &&
            type !== "assign" &&
            type !== "unassign" &&
            type !== "out_for_delivery" &&
            type !== "picked_up" &&
            type !== "complete" &&
            type !== "missed" &&
            type !== "decline"
          ) {
            setIsError(true);
            seterror("invalid");
          } else {
            setIsError(false);

            setloadingData(true);
            axios
              .get(`${process.env.REACT_APP_API}m-dillivry-dashboard-manager`, {
                headers: {
                  Authorization: ls.get("_nodi"),
                },
              })
              .then((response) => {
                setloadingData(false);

                if (response.status === 200) {
                  updateDashboard(response);
                  getDeliveryData(response)

                }
              })
              .catch((err) => {
                setloadingData(false);
                if (err?.response?.status === 403) {
                  Swal.fire({
                    icon: "warning",
                    title: "Session!",
                    text: "Session expired login again!",
                    allowOutsideClick: false,
                    confirmButtonColor: "var(--main)",
                  }).then((result) => {
                    if (result.value) {
                      auth.logout();
                      setShowLogin(true);
                    }
                  });
                } else {
                  setIsError(true);
                  seterror("not_foung");
                }
              });
          }
        }
      }
    }
  }, [dataRefresh]);

  const numberWithCommas = new Intl.NumberFormat("en-GB");

  return (
    <>
      {type === "current" || type === "decline" ? (
        <DashDrillDownCurrent />
      ) : ShowLogin ? (
       history.push('/login')
      ) : (
        <>
          {isError ? (
            error === "invalid" ? (
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "fixed",
                  top: "80px",
                  zIndex: "10",
                  flexDirection: "column",
                }}
              >
                <span>
                  <BsInfoCircle size={60} color="var(--main)" />
                </span>
                <span
                  style={{
                    fontSize: "30px",
                    textAlign: "center",
                    padding: "20px",
                  }}
                >
                  Invalid address url, page not found
                </span>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "fixed",
                  top: "80px",
                  zIndex: "10",
                  flexDirection: "column",
                }}
              >
                <span>
                  <BsInfoCircle size={60} color="var(--main)" />
                </span>
                <span
                  style={{
                    fontSize: "30px",
                    textAlign: "center",
                    padding: "20px",
                  }}
                >
                  Item not found
                </span>
              </div>
            )
          ) : null}
          <div className="userDesktop">
            <div className="viewSearch">
              {loadingData ? (
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    backgroundColor: "none",
                    zIndex: "300",
                    position: "fixed",
                    top: "0",
                    paddingTop: "5px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress color="success" />
                  </Box>
                  <header className="item-header-content-header">
                    <div className="item-header-content">
                      <div className="item-logo-content">
                        <Link to="/">
                          {" "}
                          <img
                            src={dillivry}
                            alt=""
                            className="item-content-logo"
                          />
                        </Link>
                      </div>
                    </div>
                  </header>
                </div>
              ) : null}
              {showMessage ? (
                <div style={{ padding: "30px 100px" }}>
                  <div
                    style={{
                      padding: "7px 15px",
                      backgroundColor: "var(--main)",
                      color: "white",
                      cursor: "pointer",
                      userSelect: "none",
                      display: "inline-block",
                      borderRadius: "5px",
                    }}
                    onClick={() => setShowMessage(false)}
                  >
                    Go back
                  </div>
                  <MessageFulfiller
                    hide="yes"
                    msg={messages}
                    from="items"
                    myDP={dp}
                  />
                </div>
              ) : (
                viewData.map((view) => {
                  return (
                    <div className="Desktop-screen-div">
                      <header className="item-header-content-header">
                        <div className="item-header-content">
                          <div className="item-logo-content">
                            <Link to="/">
                              {" "}
                              <img
                                src={dillivry}
                                alt=""
                                className="item-content-logo"
                              />
                            </Link>
                          </div>
                        </div>
                      </header>

                      <div key={view.delivery_id} className="view_Container">
                        <div className="view_Top_mobile">
                          <div className="topItem">
                            <div className="topItem-first-div">
                              <button
                                className="topItem-first-div-btn"
                                onClick={()=>history.goBack()}
                              >
                                <span>Back</span>
                              </button>
                              <span
                                className="topItem-first-div-category"
                                style={{ fontWeight: "600" }}
                              >
                                {" "}
                                Category:{" "}
                              </span>
                              <span className="topItem-first-div-item-category">
                                {view.item_category?.charAt(0)?.toUpperCase() +
                                  view.item_category
                                    .slice(1)
                                    .replace(/_/g, " ")}
                              </span>
                            </div>
                            <div className="topItem-second-div">
                              <span
                                className="topItem-second-div-Id"
                                style={{ fontWeight: "600" }}
                              >
                                ID:{" "}
                              </span>
                              <span className="topItem-second-div-item-category">
                                {view.delivery_id}
                              </span>
                              <button
                                className="topItem-second-div-report"
                                onClick={() => history.push("/complaints")}
                              >
                                <span>Report</span>
                              </button>
                            </div>
                          </div>
                          <div></div>
                        </div>

                        <div className="view_Map">
                          <GoogleMapWidget
                            origins={destination}
                            destinations={origin}
                          />
                        </div>
                        <div className="bodyItem" style={{ marginTop: "20px" }}>
                          <Grid container spacing={4}>
                            <Grid item md={3} xs={12}>
                              <div className="imgage-content">
                                <Carousel
                                  navButtonsAlwaysInvisible={true}
                                  indicators={false}
                                  animation={"slide"}
                                  timeout={100}
                                  autoPlay={true}
                                >
                                  {view.image_urls?.map((img_url, i) => (
                                    <img
                                      key={i}
                                      src={img_url}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                      }}
                                      alt="Item_image"
                                      className="carousel_image-"
                                    />
                                  ))}
                                </Carousel>
                              </div>
                            </Grid>

                            <Grid item md={5} xs={12}>
                              <>
                                <div className="content-iconn">
                                  <div className="topTitle">
                                    Delivery information
                                  </div>
                                  <div className="itemDescs">
                                    <div className="itemDesc">
                                      <span>
                                        {" "}
                                        <span data-tip data-for="globa1">
                                          <IconContext.Provider
                                            value={
                                              {
                                                // color: "#2e2e2e",
                                                // className: "global-class-name-icon",
                                              }
                                            }
                                          >
                                            {" "}
                                            <AiFillFileText />
                                          </IconContext.Provider>

                                          <ReactTooltip
                                            id="globa1"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Item description: </span>
                                          </ReactTooltip>
                                        </span>
                                      </span>
                                      <span className="icon-desc">
                                        <span>Item description: </span>
                                        {view.item_desc
                                          .charAt(0)
                                          ?.toUpperCase() +
                                          view.item_desc.toLowerCase().slice(1)}
                                      </span>
                                    </div>

                                    <div className="itemDesc">
                                      <span>
                                        {" "}
                                        <span data-tip data-for="globalmode2">
                                          <IconContext.Provider
                                            value={
                                              {
                                                // color: "#2e2e2e",
                                                // className: "global-class-name-icon",
                                              }
                                            }
                                          >
                                            {" "}
                                            <MdDashboard />
                                          </IconContext.Provider>

                                          <ReactTooltip
                                            id="globalmode2"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Item category </span>
                                          </ReactTooltip>
                                        </span>
                                      </span>
                                      <span className="icon-desc">
                                        <span>Item category: </span>
                                        {view.item_category
                                          .charAt(0)
                                          ?.toUpperCase() +
                                          view.item_category
                                            .slice(1)
                                            .replace(/_/g, " ")}
                                      </span>
                                    </div>

                                    <div className="itemDesc">
                                      <span>
                                        {" "}
                                        <span data-tip data-for="globalmode">
                                          <IconContext.Provider
                                            value={
                                              {
                                                // color: "#2e2e2e",
                                                // className: "global-class-name-icon",
                                              }
                                            }
                                          >
                                            {" "}
                                            <AiFillFileText />
                                          </IconContext.Provider>

                                          <ReactTooltip
                                            id="globalmode"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Delivery ID </span>
                                          </ReactTooltip>
                                        </span>
                                      </span>
                                      <span className="icon-desc">
                                        <span>Delivery ID: </span>
                                        {view.delivery_id}
                                      </span>
                                    </div>

                                    <div className="itemDesc">
                                      <span>
                                        {" "}
                                        <span data-tip data-for="globalmdel">
                                          <IconContext.Provider
                                            value={
                                              {
                                                // color: "#2e2e2e",
                                                // className: "global-class-name-icon",
                                              }
                                            }
                                          >
                                            {" "}
                                            <AiFillFileText />
                                          </IconContext.Provider>

                                          <ReactTooltip
                                            id="globalmdel"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Delivery type </span>
                                          </ReactTooltip>
                                        </span>
                                      </span>
                                      <span className="icon-desc">
                                        <span>Delivery type: </span>
                                        {view.delivery_type === "bid"
                                          ? "Quoted"
                                          : "Booked"}
                                      </span>
                                    </div>

                                    <div className="itemDesc">
                                      <span>
                                        <span data-tip data-for="globalsta">
                                          <IconContext.Provider
                                            value={
                                              {
                                                // color: "#2e2e2e",
                                                // className: "global-class-name-icon",
                                              }
                                            }
                                          >
                                            {" "}
                                            <RiMiniProgramFill />
                                          </IconContext.Provider>

                                          <ReactTooltip
                                            id="globalsta"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Delivery status </span>
                                          </ReactTooltip>
                                        </span>
                                      </span>
                                      <span className="icon-desc">
                                        <span>Delivery status: </span>

                                        {view?.delivery_status
                                          ?.charAt(0)
                                          ?.toUpperCase() +
                                          view?.delivery_status
                                            .slice(1)
                                            .replace(/_/g, " ")}
                                      </span>
                                    </div>
                                   
                                    {
                                     view.quote_status === "out_for_delivery"  || view.quote_status === "in_transit" || view.quote_status === "completed" || view.quote_status === "missed_delivery" || view.assigned_agent_details ?
                                      
                                    <div className="itemDesc">
                                      <span>
                                        <span data-tip data-for="globalasn">
                                          <IconContext.Provider
                                            value={
                                              {
                                                // color: "#2e2e2e",
                                                // className: "global-class-name-icon",
                                              }
                                            }
                                          >
                                            {" "}
                                            <BiTimer />
                                          </IconContext.Provider>

                                          <ReactTooltip
                                            id="globalasgn"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Assign time </span>
                                          </ReactTooltip>
                                        </span>
                                      </span>
                                      <span className="icon-desc">
                                        <span>Assign time: </span>

                                        { moment(view?.assigned_agent_details.assigned_time_stamp).format("DD-MM-YYYY HH:MM:SS")}
                                      </span>
                                    </div>
                                    :
                                    null
                                     }
                                    <div className="itemDesc">
                                      <span>
                                        {" "}
                                        <span data-tip data-for="globaPick">
                                          <IconContext.Provider
                                            value={
                                              {
                                                // color: "#2e2e2e",
                                                // // className: "global-class-name-icon",
                                              }
                                            }
                                          >
                                            {" "}
                                            <MdLocationOn color="red" />
                                          </IconContext.Provider>

                                          <ReactTooltip
                                            id="globaPick"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Pickup address </span>
                                          </ReactTooltip>
                                        </span>
                                      </span>
                                      <span className="icon-desc">
                                        <span>Pickup address: </span>

                                        {view?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                                      </span>
                                    </div>
                                    <div className="itemDesc">
                                      <span>
                                        {" "}
                                        <span data-tip data-for="globaPickup">
                                          <IconContext.Provider
                                            value={
                                              {
                                                // color: "#2e2e2e",
                                                // className: "global-class-name-icon",
                                              }
                                            }
                                          >
                                            {" "}
                                            <BiPhoneCall />
                                          </IconContext.Provider>

                                          <ReactTooltip
                                            id="globaPickup"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Pickup contact</span>
                                          </ReactTooltip>
                                        </span>
                                      </span>
                                      <span className="icon-desc">
                                        <span>Pickup contact: </span>

                                        {view.pickup_phone_no}
                                      </span>
                                    </div>
                                    <div className="itemDesc">
                                      <span>
                                        {" "}
                                        <span data-tip data-for="globaPaddr">
                                          <IconContext.Provider
                                            value={
                                              {
                                                // color: "#2e2e2e",
                                                // className: "global-class-name-icon",
                                              }
                                            }
                                          >
                                            {" "}
                                            <MdLocationOn color="var(--main)" />
                                          </IconContext.Provider>

                                          <ReactTooltip
                                            id="globaPaddr"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Delivery address</span>
                                          </ReactTooltip>
                                        </span>
                                      </span>
                                      <span className="icon-desc">
                                        <span>Delivery address: </span>

                                        {view?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                                      </span>
                                    </div>

                                    <div className="itemDesc">
                                      <span>
                                        {" "}
                                        <span data-tip data-for="globaPickup2">
                                          <IconContext.Provider
                                            value={
                                              {
                                                // color: "#2e2e2e",
                                                // className: "global-class-name-icon",
                                              }
                                            }
                                          >
                                            {" "}
                                            <BiPhoneCall />
                                          </IconContext.Provider>

                                          <ReactTooltip
                                            id="globaPickup3"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Payer:</span>
                                          </ReactTooltip>


                                          <ReactTooltip
                                            id="globaPickup2"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Delivery contact</span>
                                          </ReactTooltip>
                                        </span>
                                      </span>
                                      <span className="icon-desc">
                                        <span>Delivery contact: </span>

                                        {view.delivery_phone_no}
                                      </span>
                                    </div>

                                    <div className="itemDesc">
                                      <span>
                                        {" "}
                                        <span  data-tip data-for="globaPickup3">
                                          <IconContext.Provider
                                            value={
                                              {
                                                // color: "#2e2e2e",
                                                // className: "global-class-name-icon",
                                              }
                                            }
                                          >
                                            {" "}
                                            <MdPayment />
                                          </IconContext.Provider>

                                          <ReactTooltip
                                            id="globa31"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Payer</span>
                                          </ReactTooltip>
                                        </span>
                                      </span>
                                      <span className="icon-desc">
                                        <span>Payer: </span>
                                        {view?.payer.charAt(0)?.toUpperCase() +
                                          view?.payer?.slice(1)}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div
                                  className="content-iconn"
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="topTitle">
                                    Payment information
                                  </div>
                                  <div className="itemDescs">
                                    <div className="itemDesc">
                                      <span>
                                        {" "}
                                        <span
                                          data-tip
                                          data-for="globaPickuppay"
                                        >
                                          <IconContext.Provider
                                            value={
                                              {
                                                // color: "#2e2e2e",
                                                // className: "global-class-name-icon",
                                              }
                                            }
                                          >
                                            {" "}
                                            <BsCreditCard />
                                          </IconContext.Provider>

                                          <ReactTooltip
                                            id="globaPickuppay"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Payment status</span>
                                          </ReactTooltip>
                                        </span>
                                      </span>
                                      <span className="icon-desc">
                                        <span>Payment status: </span>
                                        {view.payment_status ===
                                        "pay_on_delivery"
                                          ? "Pay on delivery"
                                          : view.payment_status === "success"
                                          ? "Paid"
                                          : view.payment_status === "NA"
                                          ? "NA"
                                          : "Not paid"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            </Grid>

                            <Grid item md={4} xs={12}>
                              <>
                                <div
                                  className="itemDescs"
                                  style={{ border: "none" }}
                                >
                                  <div className="topTitle">
                                    Quote information
                                  </div>

                                  <div className="itemDesc">
                                    {" "}
                                    <span>
                                      {" "}
                                      <span data-tip data-for="globaPQuote">
                                        <IconContext.Provider
                                          value={
                                            {
                                              // color: "#2e2e2e",
                                              // className: "global-class-name-icon",
                                            }
                                          }
                                        >
                                          {" "}
                                          <MdDashboard />
                                        </IconContext.Provider>

                                        <ReactTooltip
                                          id="globaPQuote"
                                          aria-haspopup="true"
                                          role="example"
                                          type="light"
                                        >
                                          <span> Quote status</span>
                                        </ReactTooltip>
                                      </span>
                                    </span>
                                    <span className="icon-desc">
                                      <span> Quote status: </span>
                                      {view.quote_status === "completed"
                                        ? "Delivered"
                                        : view.quote_status
                                            .charAt(0)
                                            ?.toUpperCase() +
                                          view.quote_status
                                            .slice(1)
                                            .replace(/_/g, " ")}
                                    </span>
                                  </div>
                                  
                                  <div className="itemDesc">
                                    {" "}
                                    <span>
                                      {" "}
                                      <span data-tip data-for="globaPQuotepay">
                                        <IconContext.Provider
                                          value={
                                            {
                                              // color: "#2e2e2e",
                                              // className: "global-class-name-icon",
                                            }
                                          }
                                        >
                                          {" "}
                                          <BsCreditCard />
                                        </IconContext.Provider>

                                        <ReactTooltip
                                          id="globaPQuotepay"
                                          aria-haspopup="true"
                                          role="example"
                                          type="light"
                                        >
                                          <span> Mode of payment </span>
                                        </ReactTooltip>
                                      </span>
                                    </span>
                                    <span className="icon-desc">
                                      <span> Mode of payment: </span>
                                      {
                                    typeof( view?.mode_of_payment) === "string"  ?
                                    <>
                                    {
                                      view?.mode_of_payment === "pay_by_cash" ?
                                      <div>Cash</div>:
                                      view?.mode_of_payment === "pay_by_transfer" ?
                                      <div>Transfer</div>:
                                      view?.mode_of_payment === "pay_by_ussd" ?
                                      <div>USSD</div>:
                                      view?.mode_of_payment === "pay_by_wallet" ?
                                      <div>Online</div>:
                                      view?.mode_of_payment === "pay_by_card" ?
                                      <div>Card</div>:
                                      <div>{view?.mode_of_payment}</div>

                                    }
                                    
                                    </>
        
                                    :
                                    <div>N/A</div>
                                    
                                  }
                                    </span>
                                  </div>
                                  

                                  <div className="itemDesc">
                                    {" "}
                                    <span>
                                      {" "}
                                      <span data-tip data-for="globaPQuote2">
                                        <IconContext.Provider
                                          value={
                                            {
                                              // color: "#2e2e2e",
                                              // className: "global-class-name-icon",
                                            }
                                          }
                                        >
                                          {" "}
                                          <MdDashboard />
                                        </IconContext.Provider>

                                        <ReactTooltip
                                          id="globaPQuote2"
                                          aria-haspopup="true"
                                          role="example"
                                          type="light"
                                        >
                                          <span>Pickup date</span>
                                        </ReactTooltip>
                                      </span>
                                    </span>
                                    <span className="icon-desc">
                                      <span> Pickup date: </span>
                                      {view.pickup_date}
                                    </span>
                                  </div>
                                  { 
                                     view.quote_status === "out_for_delivery"|| view.quote_status === "missed_delivery"  || view.quote_status === "completed" ?
                                  <div className="itemDesc">
                                    {" "}
                                    <span>
                                      {" "}
                                      <span data-tip data-for="globaPQuote23">
                                        <IconContext.Provider
                                          value={
                                            {
                                              // color: "#2e2e2e",
                                              // className: "global-class-name-icon",
                                            }
                                          }
                                        >
                                          {" "}
                                          <BiTimer />
                                        </IconContext.Provider>

                                        <ReactTooltip
                                          id="globaPQuote23"
                                          aria-haspopup="true"
                                          role="example"
                                          type="light"
                                        >
                                          <span>Pickup time</span>
                                        </ReactTooltip>
                                      </span>
                                    </span>
                                    <span className="icon-desc">
                                      <span> Pickup time: </span>
                                      {moment(view?.assigned_agent_details?.pickup_timestamp).format("YYYY-MM-DD HH:MM:SS")}
                                    </span>
                                  </div>
                                    :
                                    null}
                                 
                                  <div className="itemDesc">
                                    {" "}
                                    <span>
                                      {" "}
                                      <span data-tip data-for="globaPQuote21">
                                        <IconContext.Provider
                                          value={
                                            {
                                              // color: "#2e2e2e",
                                              // className: "global-class-name-icon",
                                            }
                                          }
                                        >
                                          {" "}
                                          <MdDashboard />
                                        </IconContext.Provider>

                                        <ReactTooltip
                                          id="globaPQuote21"
                                          aria-haspopup="true"
                                          role="example"
                                          type="light"
                                        >
                                          <span>Delivery time frame:</span>
                                        </ReactTooltip>
                                      </span>
                                    </span>
                                    <span className="icon-desc">
                                      <span>Delivery time frame: </span>
                                      {view.delivery_time_frame}
                                    </span>
                                  </div>

                                  { 
                                     type == "complete" || view.quote_status === "completed" ?
                                  <div className="itemDesc">
                                    {" "}
                                    <span>
                                      {" "}
                                      <span data-tip data-for="globaPQuote24">
                                        <IconContext.Provider
                                          value={
                                            {
                                              // color: "#2e2e2e",
                                              // className: "global-class-name-icon",
                                            }
                                          }
                                        >
                                          {" "}
                                          <BiTimer />
                                        </IconContext.Provider>

                                        <ReactTooltip
                                          id="globaPQuote24"
                                          aria-haspopup="true"
                                          role="example"
                                          type="light"
                                        >
                                          <span>Delivery time</span>
                                        </ReactTooltip>
                                      </span>
                                    </span>
                                    <span className="icon-desc">
                                      <span> Delivery time: </span>
                                      { moment( view?.delivery_agent_details?.delivery_timestamp).format('DD-MM-YYYY hh:mm:ss ')}
                                    </span>
                                  </div>
                                    :
                                    null}


                                  <div className="itemDesc">
                                    <span>
                                      {" "}
                                      <span data-tip data-for="globaPQuote22">
                                        <IconContext.Provider
                                          value={
                                            {
                                              // color: "#2e2e2e",
                                              // className: "global-class-name-icon",
                                            }
                                          }
                                        >
                                          {" "}
                                          <FaClipboardCheck />
                                        </IconContext.Provider>

                                        <ReactTooltip
                                          id="globaPQuote22"
                                          aria-haspopup="true"
                                          role="example"
                                          type="light"
                                        >
                                          <span>Accepted quote</span>
                                        </ReactTooltip>
                                      </span>
                                      {/* <FaClipboardCheck/> */}
                                    </span>
                                    <span className="icon-desc">
                                      <span>Accepted quote: </span>
                                      <span>
                                        <Currency location={`${viewData[0].delivery_location}`}/> {" "}
                                        {numberWithCommas.format(view.quote_amount)}
                                      </span>
                                    </span>
                                  </div>
                                  <div className="itemDesc">
                                    {type === "out_for_delivery" ||
                                    type === "in_transit" ? (
                                      <>
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "space-between",
                                            margin: "5px 0",
                                          }}
                                        >
                                          <Button
                                            size="small"
                                            variant="contained"
                                            style={{
                                              fontSize: "12px",
                                              border: "1px solid var(--main)",
                                              backgroundColor: "white",
                                              color: "var(--main)",
                                              textTransform: "none",
                                            }}
                                            onClick={() =>
                                              history.push(
                                                `/message/${type}/${view.delivery_id}`
                                              )
                                            }
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <span>
                                                <IoMdMailOpen size="12px" />
                                              </span>
                                              <span>Message</span>
                                            </div>
                                          </Button>
                                        </div>
                                      </>
                                    ) : type === "assign" ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          justifyContent: "space-between",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <Button
                                          size="small"
                                          variant="contained"
                                          style={{
                                            fontSize: "12px",
                                            border: "1px solid red",
                                            backgroundColor: "#e2a5a5",
                                            color: "red",
                                            textTransform: "none",
                                          }}
                                          onClick={() => {
                                            setrejectPopup(true);
                                            setItemToReject(view.delivery_id);
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "5px",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <span>
                                              <MdBlock size="12px" />
                                            </span>
                                            <span>Reject</span>
                                          </div>
                                        </Button>

                                        <Button
                                          size="small"
                                          variant="contained"
                                          style={{
                                            fontSize: "12px",
                                            border: "1px solid var(--main)",
                                            backgroundColor: "white",
                                            color: "var(--main)",
                                            textTransform: "none",
                                          }}
                                          onClick={() =>
                                            history.push(
                                              `/message/${type}/${view.delivery_id}`
                                            )
                                          }
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "5px",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <span>
                                              <IoMdMailOpen size="12px" />
                                            </span>
                                            <span>Message</span>
                                          </div>
                                        </Button>
                                      </div>
                                    ) : type === "unassign" ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          justifyContent: "space-between",
                                          marginTop: "20px",
                                        }}
                                      >
                                        <Button
                                          size="small"
                                          variant="contained"
                                          style={{
                                            fontSize: "12px",
                                            border: "1px solid red",
                                            backgroundColor: "#e2a5a5",
                                            color: "red",
                                            textTransform: "none",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "5px",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            onClick={() => {
                                              setrejectPopup(true);
                                              setItemToReject(view.delivery_id);
                                            }}
                                          >
                                            <span>
                                              <MdBlock size="12px" />
                                            </span>
                                            <span>Reject</span>
                                          </div>
                                        </Button>

                                        <Button
                                          size="small"
                                          variant="contained"
                                          style={{
                                            fontSize: "12px",
                                            border: "1px solid var(--main)",
                                            backgroundColor: "white",
                                            color: "var(--main)",
                                            textTransform: "none",
                                          }}
                                          onClick={() => {
                                            setAssignType("assign");
                                            setShowAgents(true);
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "5px",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <span>
                                              <TiUserAddOutline size="12px" />
                                            </span>
                                            <span>Assign</span>
                                          </div>
                                        </Button>

                                        <Button
                                          size="small"
                                          variant="contained"
                                          style={{
                                            fontSize: "12px",
                                            border: "1px solid var(--main)",
                                            backgroundColor: "var(--main)",
                                            color: "white",
                                            textTransform: "none",
                                          }}
                                          onClick={() =>
                                            history.push(
                                              `/message/${type}/${view.delivery_id}`
                                            )
                                          }
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "5px",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <span>
                                              <IoMdMailOpen size="12px" />
                                            </span>
                                            <span>Message</span>
                                          </div>
                                        </Button>
                                      </div>
                                    ) : type === "complete" ? null : type ===
                                      "missed" ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          justifyContent: "space-between",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <Button
                                          size="small"
                                          variant="contained"
                                          style={{
                                            fontSize: "12px",
                                            border: "1px solid var(--main)",
                                            backgroundColor: "var(--main)",
                                            color: "white",
                                            textTransform: "none",
                                          }}
                                          onClick={() =>
                                            history.push(
                                              `/message/${type}/${view.delivery_id}`
                                            )
                                          }
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "5px",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <span>
                                              <IoMdMailOpen size="12px" />
                                            </span>
                                            <span>Message</span>
                                          </div>
                                        </Button>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          justifyContent: "space-between",
                                          marginTop: "10px",
                                        }}
                                      >
                                        <Button
                                          size="small"
                                          variant="contained"
                                          style={{
                                            fontSize: "12px",
                                            border: "1px solid red",
                                            backgroundColor: "#e2a5a5",
                                            color: "red",
                                            textTransform: "none",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "5px",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <span>
                                              <MdBlock size="12px" />
                                            </span>
                                            <span>Reject</span>
                                          </div>
                                        </Button>
                                        <Button
                                          size="small"
                                          variant="contained"
                                          style={{
                                            fontSize: "12px",
                                            border: "1px solid var(--main)",
                                            backgroundColor: "var(--main)",
                                            color: "white",
                                            textTransform: "none",
                                          }}
                                          onClick={() =>
                                            history.push(
                                              `/message/${type}/${view.delivery_id}`
                                            )
                                          }
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "5px",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <span>
                                              <IoMdMailOpen size="12px" />
                                            </span>
                                            <span>Message</span>
                                          </div>
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <div
                                  className="itemDescs"
                                  style={{ marginTop: "20px" }}
                                >
                                  {/* <div className="itemDesc"> */}
                                  <span
                                    className="icon-desc"
                                    style={{
                                      flexDirection: "column",
                                    }}
                                  >
                                    {type === "out_for_delivery" ||
                                    type === "picked_up" ? (
                                      <>
                                        <div className="topTitle">
                                          Assigned Agent
                                        </div>
                                        <div
                                          style={{
                                            width: "100%",
                                            border: "1px solid #E5E5E5",
                                            borderTop: "none",
                                            borderRadius: "7px",
                                            borderTopRightRadius: "0",
                                            borderTopLeftRadius: "0",
                                            padding: "15px",
                                          }}
                                        >
                                          <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                              <div
                                                className="quoteBodyDetailsRight"
                                                style={{
                                                  backgroundImage: `url(${assignedAgent[0]?.profile_photo?.profile_photo})`,
                                                }}
                                              ></div>
                                            </Grid>
                                            <Grid item xs={9}>
                                              <Grid container spacing={1}>
                                                <Grid
                                                  item
                                                  xs={9}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  {assignedAgent[0]
                                                    ?.first_name +
                                                    " " +
                                                    assignedAgent[0]
                                                      ?.last_name}{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item xs={3}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                </Grid>
                                                <Grid
                                                  item xs={9}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  {
                                                    assignedAgent[0]
                                                      ?.fulfilling_agent_id
                                                  }{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={3}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={9}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  Out for deliveries
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={3}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  (
                                                  {
                                                    assignedAgent[0]
                                                      ?.num_intransit_deliveries
                                                  }
                                                  ){" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={9}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  Assign deliveries{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={3}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  (
                                                  {
                                                    assignedAgent[0]
                                                      ?.num_assgn_deliveries
                                                  }
                                                  ){" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={9}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  Completed deliveries{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={3}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  (
                                                  {
                                                    assignedAgent[0]
                                                      ?.num_completed_deliveries
                                                  }
                                                  ){" "}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "space-between",
                                            marginTop: "10px",
                                          }}
                                        >
                                          {/* <Button
                                                      size="small"
                                                      variant="contained"
                                                      style={{
                                                        fontSize: "12px",
                                                        border:
                                                          "1px solid var(--main)",
                                                        backgroundColor:
                                                          "var(--main)",
                                                        color: "white",
                                                        textTransform: "none",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          gap: "5px",
                                                          alignItems: "center",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                      >
                                                        <span>
                                                          <RiPinDistanceFill size="12px" />
                                                        </span>
                                                        <span>Track agent</span>
                                                      </div>
                                                    </Button> */}
                                          <Button
                                            size="small"
                                            variant="contained"
                                            style={{
                                              fontSize: "12px",
                                              border: "1px solid var(--main)",
                                              backgroundColor: "white",
                                              color: "var(--main)",
                                              textTransform: "none",
                                            }}
                                            onClick={() => {
                                              setAssignType("reassign");
                                              setShowAgents(true);
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <span>
                                                <TiUserAddOutline size="12px" />
                                              </span>
                                              <span>Reassign</span>
                                            </div>
                                          </Button>
                                        </div>
                                      </>
                                    ) : type === "assign" ? (
                                      <>
                                        <div className="topTitle">
                                          Assigned Agent
                                        </div>
                                        <div
                                          style={{
                                            width: "100%",
                                            border: "1px solid #E5E5E5",
                                            borderTop: "none",
                                            borderRadius: "7px",
                                            borderTopRightRadius: "0",
                                            borderTopLeftRadius: "0",
                                            padding: "15px",
                                          }}
                                        >
                                          <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                              <div
                                                className="quoteBodyDetailsRight"
                                                style={{
                                                  backgroundImage: `url(${assignedAgent[0]?.profile_photo?.profile_photo})`,
                                                }}
                                              ></div>
                                            </Grid>
                                            <Grid item xs={9}>
                                              <Grid container spacing={1}>
                                                <Grid
                                                  item
                                                  xs={9}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  {assignedAgent[0]
                                                    ?.first_name +
                                                    " " +
                                                    assignedAgent[0]
                                                      ?.last_name}{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={3}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={9}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  {
                                                    assignedAgent[0]
                                                      ?.fulfilling_agent_id
                                                  }{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={3}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={9}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  Out for deliveries
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={3}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  (
                                                  {
                                                    assignedAgent[0]
                                                      ?.num_intransit_deliveries
                                                  }
                                                  ){" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={9}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  Assign deliveries{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={3}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  (
                                                  {
                                                    assignedAgent[0]
                                                      ?.num_assgn_deliveries
                                                  }
                                                  ){" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={9}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  Completed deliveries{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={3}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  (
                                                  {
                                                    assignedAgent[0]
                                                      ?.num_completed_deliveries
                                                  }
                                                  ){" "}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </div>
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "space-between",
                                            marginTop: "10px",
                                          }}
                                        >
                                          {/* <Button
                                                      size="small"
                                                      variant="contained"
                                                      style={{
                                                        fontSize: "12px",
                                                        border:
                                                          "1px solid var(--main)",
                                                        backgroundColor:
                                                          "var(--main)",
                                                        color: "white",
                                                        textTransform: "none",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          gap: "5px",
                                                          alignItems: "center",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                      >
                                                        <span>
                                                          <RiPinDistanceFill size="12px" />
                                                        </span>
                                                        <span>Track agent</span>
                                                      </div>
                                                    </Button> */}
                                          <Button
                                            size="small"
                                            variant="contained"
                                            style={{
                                              fontSize: "12px",
                                              border: "1px solid var(--main)",
                                              backgroundColor: "white",
                                              color: "var(--main)",
                                              textTransform: "none",
                                            }}
                                            onClick={() => {
                                              setAssignType("reassign");
                                              setShowAgents(true);
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <span>
                                                <TiUserAddOutline size="12px" />
                                              </span>
                                              <span>Reassign</span>
                                            </div>
                                          </Button>
                                        </div>
                                      </>
                                    ) : type === "complete" ? (
                                      // noShowAgent ?
                                      // null
                                      // :
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          marginTop: "10px",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <>
                                          <div className="topTitle">
                                            Delivery Agent
                                          </div>
                                          <div
                                            style={{
                                              width: "100%",
                                              border: "1px solid #E5E5E5",
                                              borderTop: "none",
                                              borderRadius: "7px",
                                              borderTopRightRadius: "0",
                                              borderTopLeftRadius: "0",
                                              padding: "15px",
                                            }}
                                          >
                                            <Grid container spacing={1}>
                                              <Grid item xs={3}>
                                                <div
                                                  className="quoteBodyDetailsRight"
                                                  style={{
                                                    backgroundImage: `url(${assignedAgent[0]?.profile_photo?.profile_photo})`,
                                                  }}
                                                ></div>
                                              </Grid>
                                              <Grid item xs={9}>
                                                <Grid container spacing={1}>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {assignedAgent[0]
                                                      ?.first_name +
                                                      " " +
                                                      assignedAgent[0]
                                                        ?.last_name}{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {
                                                      assignedAgent[0]
                                                        ?.fulfilling_agent_id
                                                    }{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Out for deliveries
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_intransit_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Assign deliveries{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_assgn_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Completed deliveries{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_completed_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </>
                                      </div>
                                    ) : type === "missed" ? (
                                      // noShowAgent ?
                                      //   null
                                      //   :
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          marginTop: "10px",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <>
                                          <div className="topTitle">
                                            Assigned Agent
                                          </div>
                                          <div
                                            style={{
                                              width: "100%",
                                              border: "1px solid #E5E5E5",
                                              borderTop: "none",
                                              borderRadius: "7px",
                                              borderTopRightRadius: "0",
                                              borderTopLeftRadius: "0",
                                              padding: "15px",
                                            }}
                                          >
                                            <Grid container spacing={1}>
                                              <Grid item xs={3}>
                                                <div
                                                  className="quoteBodyDetailsRight"
                                                  style={{
                                                    backgroundImage: `url(${assignedAgent[0]?.profile_photo?.profile_photo})`,
                                                  }}
                                                ></div>
                                              </Grid>
                                              <Grid item xs={9}>
                                                <Grid container spacing={1}>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {assignedAgent[0]
                                                      ?.first_name +
                                                      " " +
                                                      assignedAgent[0]
                                                        ?.last_name}{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {
                                                      assignedAgent[0]
                                                        ?.fulfilling_agent_id
                                                    }{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Out for deliveries
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_intransit_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Assign deliveries{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_assgn_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Completed deliveries{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_completed_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                              marginTop: "10px",
                                            }}
                                          >
                                            {/* <Button
                                                        size="small"
                                                        variant="contained"
                                                        style={{
                                                          fontSize: "12px",
                                                          border:
                                                            "1px solid var(--main)",
                                                          backgroundColor:
                                                            "var(--main)",
                                                          color: "white",
                                                          textTransform: "none",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            gap: "5px",
                                                            alignItems:
                                                              "center",
                                                            justifyContent:
                                                              "center",
                                                          }}
                                                        >
                                                          <span>
                                                            <RiPinDistanceFill size="12px" />
                                                          </span>
                                                          <span>
                                                            Track agent
                                                          </span>
                                                        </div>
                                                      </Button> */}
                                            <Button
                                              size="small"
                                              variant="contained"
                                              style={{
                                                fontSize: "12px",
                                                border: "1px solid var(--main)",
                                                backgroundColor: "white",
                                                color: "var(--main)",
                                                textTransform: "none",
                                              }}
                                              onClick={() => {
                                                setAssignType("reassign");
                                                setShowAgents(true);
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "5px",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <span>
                                                  <TiUserAddOutline size="12px" />
                                                </span>
                                                <span>Reassign</span>
                                              </div>
                                            </Button>
                                          </div>
                                        </>
                                      </div>
                                    ) : null}
                                  </span>
                                </div>
                              </>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          <div className="userMobile">
            <div className="viewSearch">
              {loadingData ? (
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    backgroundColor: "none",
                    zIndex: "300",
                    position: "fixed",
                    top: "0",
                    paddingTop: "5px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress color="success" />
                  </Box>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "200px",
                    }}
                  >
                    <PuffLoader
                      color="#50b14a"
                      loading={loadingData}
                      speedMultiplier={1}
                      size={100}
                    />
                  </div>
                </div>
              ) : null}
              {
                <>
                  <div className="fulfillerViewTop">
                    <div
                      onClick={()=>history.goBack()}
                      // onClick={()=>history.push(myDillivry)}
                    >
                      <BiArrowBack size="25px" />
                    </div>
                    <div className="viewLogo">
                      <img src={dillivry} alt="logo" />
                    </div>
                    <div className="topCircle"></div>
                  </div>
                  {viewData.map((view) => {
                    return (
                      <div key={view.delivery_id} className="view_Container">
                        <div className="view_Top_mobile">
                          <div
                            className="topItem"
                            style={{
                              backgroundColor: "var(--main)",
                              justifyContent: "center",
                              padding: "7px",
                              fontSize: "13px",
                              color: "white",
                            }}
                          >
                            {type === "complete"
                              ? "Completed : " +
                                formatDate(
                                  view.delivery_agent_details.delivery_timestamp
                                )
                              : "Due date : " + view.delivery_time_frame}
                          </div>
                        </div>

                        <div className="view_Map_mobile">
                          <GoogleMapWidget
                            origins={destination}
                            destinations={origin}
                          />
                        </div>

                        <div className="submitQuot">
                          <div
                            className="bodyItem"
                            style={{ marginTop: "20px" }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <div className="imgage-content">
                                  <Carousel
                                    navButtonsAlwaysInvisible={true}
                                    indicators={false}
                                    animation={"slide"}
                                    timeout={100}
                                    autoPlay={true}
                                  >
                                    {view.image_urls?.map((img_url, i) => (
                                      <img
                                        key={i}
                                        src={img_url}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        alt="Item_image"
                                        className="carousel_image-"
                                      />
                                    ))}
                                  </Carousel>
                                </div>
                              </Grid>

                              <Grid item xs={12}>
                                <>
                                  <div className="content-iconn">
                                    <div className="topTitle">
                                      Delivery information
                                    </div>
                                    <div className="itemDescs">
                                      <div className="itemDesc">
                                        <span>
                                          {" "}
                                          <AiFillFileText />
                                        </span>
                                        <span className="icon-desc">
                                          <span>Item description: </span>
                                          {view.item_desc
                                            .charAt(0)
                                            ?.toUpperCase() +
                                            view.item_desc
                                              .toLowerCase()
                                              .slice(1)}
                                        </span>
                                      </div>

                                      <div className="itemDesc">
                                        <span>
                                          {" "}
                                          <MdDashboard />
                                        </span>
                                        <span className="icon-desc">
                                          <span>Item category: </span>
                                          {view.item_category
                                            .charAt(0)
                                            ?.toUpperCase() +
                                            view.item_category
                                              .slice(1)
                                              .replace(/_/g, " ")}
                                        </span>
                                      </div>

                                      <div className="itemDesc">
                                        <span>
                                          {" "}
                                          <AiFillFileText />
                                        </span>
                                        <span className="icon-desc">
                                          <span>Delivery ID: </span>
                                          {view.delivery_id}
                                        </span>
                                      </div>

                                      <div className="itemDesc">
                                        <span>
                                          {" "}
                                          <AiFillFileText />
                                        </span>
                                        <span className="icon-desc">
                                          <span>Delivery type: </span>
                                          {view.delivery_type === "bid"
                                            ? "Quoted"
                                            : "Booked"}
                                        </span>
                                      </div>

                                      <div className="itemDesc">
                                        <span>
                                          <RiMiniProgramFill />
                                        </span>
                                        <span className="icon-desc">
                                          <span>Delivery status: </span>
                                          {/* {type === 'assign' ? 'Awaiting Pickup' 
                                                          : 
                                                          type === 'unassign' ? "Awaiting agent assignment" 
                                                          : 
                                                          type === 'out_for_delivery' ? 'Picked up' 
                                                          :
                                                          type === 'in_transit' ? 'Out for delivery' 
                                                          : 
                                                          type === 'outbid' ? 'Outbidded' 
                                                          :
                                                          type === 'complete' ? 'Completed' 
                                                          :
                                                          type === 'missed' ? 'Missed' 
                                                          :
                                                          null
                                                      } */}
                                          {view?.delivery_status
                                            ?.charAt(0)
                                            ?.toUpperCase() +
                                            view?.delivery_status
                                              .slice(1)
                                              .replace(/_/g, " ")}
                                        </span>
                                      </div>
                                      {
                                        view.quote_status === "out_for_delivery" || view.quote_status === "in_transit" || view.quote_status === "completed"  || view.quote_status === "missed_delivery" || view.assigned_agent_details ?
                                        <div className="itemDesc">
                                        <span>
                                          {" "}
                                          <BiTimer />
                                        </span>
                                        <span className="icon-desc">
                                          <span>Assign time: </span>

                                          { moment(view?.assigned_agent_details.assigned_time_stamp).format("DD-MM-YYYY HH:MM:SS")}
                                        </span>
                                      </div>
                                      :
                                      null

                                        
                                      }
                                      <div className="itemDesc">
                                        <span>
                                          {" "}
                                          <MdLocationOn color="red" />
                                        </span>
                                        <span className="icon-desc">
                                          <span>Pickup address: </span>

                                          {view?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                                        </span>
                                      </div>
                                      <div className="itemDesc">
                                        <span>
                                          {" "}
                                          <BiPhoneCall />
                                        </span>
                                        <span className="icon-desc">
                                          <span>Pickup contact: </span>

                                          {view.pickup_phone_no}
                                        </span>
                                      </div>
                                      <div className="itemDesc">
                                        <span>
                                          {" "}
                                          <MdLocationOn color="var(--main)" />
                                        </span>
                                        <span className="icon-desc">
                                          <span>Delivery address: </span>

                                          {view?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                                        </span>
                                      </div>
                                      <div className="itemDesc">
                                        <span>
                                          {" "}
                                          <BiPhoneCall />
                                        </span>
                                        <span className="icon-desc">
                                          <span>Delivery contact : </span>

                                          {view.delivery_phone_no}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="itemDesc">
                                      <span>
                                        {" "}
                                        <span data-tip data-for="globaPickup3">
                                          <IconContext.Provider
                                            value={
                                              {
                                                // color: "#2e2e2e",
                                                // className: "global-class-name-icon",
                                              }
                                            }
                                          >
                                            {" "}
                                            <MdPayment />
                                          </IconContext.Provider>

                                          <ReactTooltip
                                            id="globa30"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Payer</span>
                                          </ReactTooltip>

                                          <ReactTooltip
                                            id="globa31"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Payer</span>
                                          </ReactTooltip>
                                        </span>
                                      </span>
                                      <span className="icon-desc">
                                        <span>Payer: </span>
                                        {/* {console.log(view, "viewwww")} */}

                                        {view?.payer.charAt(0)?.toUpperCase() +
                                          view?.payer?.slice(1)}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    className="content-iconn"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <div className="topTitle">
                                      Payment information
                                    </div>
                                    <div className="itemDescs">
                                      <div className="itemDesc">
                                        <span>
                                          {" "}
                                          <BsCreditCard />
                                        </span>
                                        <span className="icon-desc">
                                          <span>Payment status: </span>
                                          {view.payment_status ===
                                          "pay_on_delivery"
                                            ? "Pay on delivery"
                                            : view.payment_status === "success"
                                            ? "Paid"
                                            : view.payment_status === "NA"
                                            ? "NA"
                                            : "Not paid"}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              </Grid>

                              <Grid item xs={12}>
                                <>
                                  <div
                                    className="itemDescs"
                                    style={{ border: "none" }}
                                  >
                                    <div className="topTitle">
                                      Quote information
                                    </div>
                                    <div className="itemDesc">
                                      {" "}
                                      <span>
                                        {" "}
                                        <MdDashboard />
                                      </span>
                                      <span className="icon-desc">
                                        <span> Quote status: </span>
                                        {view.quote_status === "completed"
                                          ? "Delivered"
                                          : view.quote_status
                                              .charAt(0)
                                              ?.toUpperCase() +
                                            view.quote_status
                                              .slice(1)
                                              .replace(/_/g, " ")}
                                      </span>
                                    </div>

                                    <div className="itemDesc">
                                      {" "}
                                      <span>
                                        {" "}
                                        <BsCreditCard />
                                      </span>
                                      <span className="icon-desc">
                                        <span> Mode of payment: </span>
                                        {
                                    typeof( view?.mode_of_payment) === "string"  ?
                                    <>
                                    {
                                      view?.mode_of_payment === "pay_by_cash" ?
                                      <div>Cash</div>:
                                      view?.mode_of_payment === "pay_by_transfer" ?
                                      <div>Transfer</div>:
                                      view?.mode_of_payment === "pay_by_ussd" ?
                                      <div>USSD</div>:
                                      view?.mode_of_payment === "pay_by_wallet" ?
                                      <div>Online</div>:
                                      view?.mode_of_payment === "pay_by_card" ?
                                      <div>Card</div>:
                                      <div>{view?.mode_of_payment}</div>

                                    }
                                    
                                    </>
        
                                    :
                                    <div>N/A</div>


                                  }
                                     
                                      </span>
                                    </div>

                                    <div className="itemDesc">
                                      {" "}
                                      <span>
                                        {" "}
                                        <MdDashboard />
                                      </span>
                                      <span className="icon-desc">
                                        <span> Pickup date: </span>
                                        {view.pickup_date}
                                      </span>
                                    </div>
                                    { 
                                       view.quote_status === "out_for_delivery"|| view.quote_status === "missed_delivery"  || view.quote_status === "completed"  ?
                                    <div className="itemDesc">
                                      {" "}
                                      <span>
                                        {" "}
                                        <BiTimer />
                                      </span>
                                      <span className="icon-desc">
                                        <span> Pick up time </span>
                                        {moment(view?.assigned_agent_details?.pickup_timestamp).format("DD-MM-YYYY HH:MM:SS")}
                                      </span>
                                    </div>
                                     :
                                     null}
                                  

                                     




                                    <div className="itemDesc">
                                      {" "}
                                      <span>
                                        {" "}
                                        <MdDashboard />
                                      </span>
                                      <span className="icon-desc">
                                        <span>Delivery time frame: </span>
                                        {view.delivery_time_frame}
                                      </span>
                                    </div>
                                    { 
                                      type == "complete" ?
                                    <div className="itemDesc">
                                      {" "}
                                      <span>
                                        {" "}
                                        <BiTimer />
                                      </span>
                                      <span className="icon-desc">
                                        <span> Delivery time: </span>
                                        {
                                                 moment( view?.delivery_agent_details?.delivery_timestamp).format('DD-MM-YYYY hh:mm:ss ')
                                                }
                                      </span>
                                    </div>
                                     :
                                     null}

                                    <div className="itemDesc">
                                      <span>
                                        {" "}
                                        <FaClipboardCheck />
                                      </span>
                                      <span className="icon-desc">
                                        <span>Accepted quote: </span>
                                        <span>
                                          <Currency location={`${viewData[0].delivery_location}`}/> {" "}
                                          {numberWithCommas.format(view.quote_amount)}
                                        </span>
                                      </span>
                                    </div>
                                    <div className="itemDesc">
                                      {type === "out_for_delivery" ||
                                      type === "in_transit" ? (
                                        <>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                              margin: "5px 0",
                                            }}
                                          >
                                            <Button
                                              size="small"
                                              variant="contained"
                                              style={{
                                                fontSize: "12px",
                                                border: "1px solid var(--main)",
                                                backgroundColor: "white",
                                                color: "var(--main)",
                                                textTransform: "none",
                                              }}
                                              onClick={() =>
                                                history.push(
                                                  `/message/${type}/${view.delivery_id}`
                                                )
                                              }
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "5px",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <span>
                                                  <IoMdMailOpen size="12px" />
                                                </span>
                                                <span>Message</span>
                                              </div>
                                            </Button>
                                          </div>
                                        </>
                                      ) : type === "assign" ? (
                                        <>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                              marginTop: "20px",
                                            }}
                                          >
                                            <Button
                                              size="small"
                                              variant="contained"
                                              style={{
                                                fontSize: "12px",
                                                border: "1px solid red",
                                                backgroundColor: "#e2a5a5",
                                                color: "red",
                                                textTransform: "none",
                                              }}
                                              onClick={() => {
                                                setrejectPopup(true);
                                                setItemToReject(
                                                  view.delivery_id
                                                );
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "5px",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <span>
                                                  <MdBlock size="12px" />
                                                </span>
                                                <span>Reject</span>
                                              </div>
                                            </Button>
                                            <Button
                                              size="small"
                                              variant="contained"
                                              style={{
                                                fontSize: "12px",
                                                border: "1px solid var(--main)",
                                                backgroundColor: "white",
                                                color: "var(--main)",
                                                textTransform: "none",
                                              }}
                                              onClick={() =>
                                                history.push(
                                                  `/message/${type}/${view.delivery_id}`
                                                )
                                              }
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "5px",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <span>
                                                  <IoMdMailOpen size="12px" />
                                                </span>
                                                <span>Message</span>
                                              </div>
                                            </Button>
                                          </div>
                                        </>
                                      ) : type === "unassign" ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "space-between",
                                            marginTop: "20px",
                                          }}
                                        >
                                          <Button
                                            size="small"
                                            variant="contained"
                                            style={{
                                              fontSize: "12px",
                                              border: "1px solid red",
                                              backgroundColor: "#e2a5a5",
                                              color: "red",
                                              textTransform: "none",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                              onClick={() => {
                                                setrejectPopup(true);
                                                setItemToReject(
                                                  view.delivery_id
                                                );
                                              }}
                                            >
                                              <span>
                                                <MdBlock size="12px" />
                                              </span>
                                              <span>Reject</span>
                                            </div>
                                          </Button>
                                          <Button
                                            size="small"
                                            variant="contained"
                                            style={{
                                              fontSize: "12px",
                                              border: "1px solid var(--main)",
                                              backgroundColor: "white",
                                              color: "var(--main)",
                                              textTransform: "none",
                                            }}
                                            onClick={() => {
                                              setAssignType("assign");
                                              setShowAgents(true);
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <span>
                                                <TiUserAddOutline size="12px" />
                                              </span>
                                              <span>Assign</span>
                                            </div>
                                          </Button>
                                          <Button
                                            size="small"
                                            variant="contained"
                                            style={{
                                              fontSize: "12px",
                                              border: "1px solid var(--main)",
                                              backgroundColor: "var(--main)",
                                              color: "white",
                                              textTransform: "none",
                                            }}
                                            onClick={() =>
                                              history.push(
                                                `/message/${type}/${view.delivery_id}`
                                              )
                                            }
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <span>
                                                <IoMdMailOpen size="12px" />
                                              </span>
                                              <span>Message</span>
                                            </div>
                                          </Button>
                                        </div>
                                      ) : type === "complete" ? null : type ===
                                        "missed" ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "space-between",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <Button
                                            size="small"
                                            variant="contained"
                                            style={{
                                              fontSize: "12px",
                                              border: "1px solid var(--main)",
                                              backgroundColor: "var(--main)",
                                              color: "white",
                                              textTransform: "none",
                                            }}
                                            onClick={() =>
                                              history.push(
                                                `/message/${type}/${view.delivery_id}`
                                              )
                                            }
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <span>
                                                <IoMdMailOpen size="12px" />
                                              </span>
                                              <span>Message</span>
                                            </div>
                                          </Button>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            width: "100%",
                                            justifyContent: "space-between",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <Button
                                            size="small"
                                            variant="contained"
                                            style={{
                                              fontSize: "12px",
                                              border: "1px solid red",
                                              backgroundColor: "#e2a5a5",
                                              color: "red",
                                              textTransform: "none",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <span>
                                                <MdBlock size="12px" />
                                              </span>
                                              <span>Reject</span>
                                            </div>
                                          </Button>
                                          <Button
                                            size="small"
                                            variant="contained"
                                            style={{
                                              fontSize: "12px",
                                              border: "1px solid var(--main)",
                                              backgroundColor: "var(--main)",
                                              color: "white",
                                              textTransform: "none",
                                            }}
                                            onClick={() =>
                                              history.push(
                                                `/message/${type}/${view.delivery_id}`
                                              )
                                            }
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                gap: "5px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <span>
                                                <IoMdMailOpen size="12px" />
                                              </span>
                                              <span>Message</span>
                                            </div>
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div
                                    className="itemDescs"
                                    style={{ marginTop: "20px" }}
                                  >
                                    <span
                                      className="icon-desc"
                                      style={{ flexDirection: "column" }}
                                    >
                                      {type === "out_for_delivery" ||
                                      type === "picked_up" ? (
                                        <>
                                          <div className="topTitle">
                                            Assigned Agent
                                          </div>
                                          <div
                                            style={{
                                              width: "100%",
                                              border: "1px solid #E5E5E5",
                                              borderTop: "none",
                                              borderRadius: "7px",
                                              borderTopRightRadius: "0",
                                              borderTopLeftRadius: "0",
                                              padding: "15px",
                                            }}
                                          >
                                            <Grid container spacing={1}>
                                              <Grid item xs={3}>
                                                <div
                                                  className="quoteBodyDetailsRight"
                                                  style={{
                                                    backgroundImage: `url(${assignedAgent[0]?.profile_photo?.profile_photo})`,
                                                  }}
                                                ></div>
                                              </Grid>
                                              <Grid item xs={9}>
                                                <Grid container spacing={1}>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {assignedAgent[0]
                                                      ?.first_name +
                                                      " " +
                                                      assignedAgent[0]
                                                        ?.last_name}{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    {" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {
                                                      assignedAgent[0]
                                                        ?.fulfilling_agent_id
                                                    }{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    {" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Out for deliveries
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_intransit_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Assign deliveries{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_assgn_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Completed deliveries{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_completed_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                              marginTop: "10px",
                                            }}
                                          >
                                            {/* <Button 
                                                                  size="small" 
                                                                  variant="contained" 
                                                                  style={{fontSize:'12px', border:'1px solid var(--main)', backgroundColor:'var(--main)', color:'white', textTransform:'none'}}
                                                              > 
                                                                <div style={{display:'flex', gap:'5px', alignItems:'center', justifyContent:'center'}}>
                                                                    <span><RiPinDistanceFill size='12px'/></span>
                                                                    <span>Track agent</span>
                                                                </div>
                                                              </Button> */}
                                            <Button
                                              size="small"
                                              variant="contained"
                                              style={{
                                                fontSize: "12px",
                                                border: "1px solid var(--main)",
                                                backgroundColor: "white",
                                                color: "var(--main)",
                                                textTransform: "none",
                                              }}
                                              onClick={() => {
                                                setAssignType("reassign");
                                                setShowAgents(true);
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "5px",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <span>
                                                  <TiUserAddOutline size="12px" />
                                                </span>
                                                <span>Reassign</span>
                                              </div>
                                            </Button>
                                          </div>
                                        </>
                                      ) : type === "assign" ? (
                                        <>
                                          <div className="topTitle">
                                            Assigned Agent
                                          </div>
                                          <div
                                            style={{
                                              width: "100%",
                                              border: "1px solid #E5E5E5",
                                              borderTop: "none",
                                              borderRadius: "7px",
                                              borderTopRightRadius: "0",
                                              borderTopLeftRadius: "0",
                                              padding: "15px",
                                            }}
                                          >
                                            <Grid container spacing={1}>
                                              <Grid item xs={3}>
                                                <div
                                                  className="quoteBodyDetailsRight"
                                                  style={{
                                                    backgroundImage: `url(${assignedAgent[0]?.profile_photo.profile_photo})`,
                                                  }}
                                                ></div>
                                              </Grid>
                                              <Grid item xs={9}>
                                                <Grid container spacing={1}>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {assignedAgent[0]
                                                      ?.first_name +
                                                      " " +
                                                      assignedAgent[0]
                                                        ?.last_name}{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    {" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {
                                                      assignedAgent[0]
                                                        ?.fulfilling_agent_id
                                                    }{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    {" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Out for deliveries
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_intransit_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Assign deliveries{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_assgn_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Completed deliveries{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_completed_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                              marginTop: "10px",
                                            }}
                                          >
                                            {/* <Button 
                                                                      size="small" 
                                                                      variant="contained" 
                                                                      style={{fontSize:'12px', border:'1px solid var(--main)', backgroundColor:'var(--main)', color:'white', textTransform:'none'}}
                                                                  >
                                                                      <div style={{display:'flex', gap:'5px', alignItems:'center', justifyContent:'center'}}>
                                                                          <span><RiPinDistanceFill size='12px'/></span>
                                                                          <span>Track agent</span>
                                                                      </div>
                                                                  </Button> */}
                                            <Button
                                              size="small"
                                              variant="contained"
                                              style={{
                                                fontSize: "12px",
                                                border: "1px solid var(--main)",
                                                backgroundColor: "white",
                                                color: "var(--main)",
                                                textTransform: "none",
                                              }}
                                              onClick={() => {
                                                setAssignType("reassign");
                                                setShowAgents(true);
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "5px",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <span>
                                                  <TiUserAddOutline size="12px" />
                                                </span>
                                                <span>Reassign</span>
                                              </div>
                                            </Button>
                                          </div>
                                        </>
                                      ) : type === "complete" ? (
                                        // noShowAgent ?
                                        //   null
                                        //   :
                                        <>
                                          <div className="topTitle">
                                            Delivery Agent
                                          </div>
                                          <div
                                            style={{
                                              width: "100%",
                                              border: "1px solid #E5E5E5",
                                              borderTop: "none",
                                              borderRadius: "7px",
                                              borderTopRightRadius: "0",
                                              borderTopLeftRadius: "0",
                                              padding: "15px",
                                            }}
                                          >
                                            <Grid container spacing={1}>
                                              <Grid item xs={3}>
                                                <div
                                                  className="quoteBodyDetailsRight"
                                                  style={{
                                                    backgroundImage: `url(${assignedAgent[0]?.profile_photo?.profile_photo})`,
                                                  }}
                                                ></div>
                                              </Grid>
                                              <Grid item xs={9}>
                                                <Grid container spacing={1}>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {assignedAgent[0]
                                                      ?.first_name +
                                                      " " +
                                                      assignedAgent[0]
                                                        ?.last_name}{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    {" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {
                                                      assignedAgent[0]
                                                        ?.fulfilling_agent_id
                                                    }{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    {" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Out for deliveries
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_intransit_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Assign deliveries{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_assgn_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Completed deliveries{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_completed_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </div>
                                        </>
                                      ) : type === "missed" ? (
                                        // noShowAgent ?
                                        //   null
                                        //   :
                                        <span
                                          className="icon-desc"
                                          style={{ flexDirection: "column" }}
                                        >
                                          <div className="topTitle">
                                            Assigned Agent
                                          </div>
                                          <div
                                            style={{
                                              width: "100%",
                                              border: "1px solid #E5E5E5",
                                              borderTop: "none",
                                              borderRadius: "7px",
                                              borderTopRightRadius: "0",
                                              borderTopLeftRadius: "0",
                                              padding: "15px",
                                            }}
                                          >
                                            <Grid container spacing={1}>
                                              <Grid item xs={3}>
                                                <div
                                                  className="quoteBodyDetailsRight"
                                                  style={{
                                                    backgroundImage: `url(${assignedAgent[0]?.profile_photo.profile_photo})`,
                                                  }}
                                                ></div>
                                              </Grid>
                                              <Grid item xs={9}>
                                                <Grid container spacing={1}>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {assignedAgent[0]
                                                      ?.first_name +
                                                      " " +
                                                      assignedAgent[0]
                                                        ?.last_name}{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    {" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {
                                                      assignedAgent[0]
                                                        ?.fulfilling_agent_id
                                                    }{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    {" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Out for deliveries
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_intransit_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Assign deliveries{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_assgn_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={9}
                                                    style={{
                                                      fontWeight: "500",
                                                      fontSize: "13px",
                                                    }}
                                                  >
                                                    {" "}
                                                    Completed deliveries{" "}
                                                  </Grid>{" "}
                                                  <Grid
                                                    item
                                                    xs={3}
                                                    style={{ fontSize: "13px" }}
                                                  >
                                                    (
                                                    {
                                                      assignedAgent[0]
                                                        ?.num_completed_deliveries
                                                    }
                                                    ){" "}
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                              marginTop: "10px",
                                            }}
                                          >
                                            {/* <Button 
                                                                      size="small" 
                                                                      variant="contained" 
                                                                      style={{fontSize:'12px', border:'1px solid var(--main)', backgroundColor:'var(--main)', color:'white', textTransform:'none'}}
                                                                  >
                                                                      <div style={{display:'flex', gap:'5px', alignItems:'center', justifyContent:'center'}}>
                                                                          <span><RiPinDistanceFill size='12px'/></span>
                                                                          <span>Track agent</span>
                                                                      </div>
                                                                  </Button> */}
                                            <Button
                                              size="small"
                                              variant="contained"
                                              style={{
                                                fontSize: "12px",
                                                border: "1px solid var(--main)",
                                                backgroundColor: "white",
                                                color: "var(--main)",
                                                textTransform: "none",
                                              }}
                                              onClick={() => {
                                                setAssignType("reassign");
                                                setShowAgents(true);
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "5px",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <span>
                                                  <TiUserAddOutline size="12px" />
                                                </span>
                                                <span>Reassign</span>
                                              </div>
                                            </Button>
                                          </div>
                                        </span>
                                      ) : null}
                                    </span>
                                  </div>
                                </>
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              }
            </div>
          </div>

          <DialogPop
            openPopup={rejectPopup}
            setopenPopup={setrejectPopup}
            head="Reason for rejection"
          >
            <div className="quote_div">
              {
                <form
                  style={{ display: "flex", width: "100%" }}
                  onSubmit={handleSubmit(rejectQuote)}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <input
                        {...register("comment")}
                        type="text"
                        style={{
                          outline: "none",
                          padding: "5px 10px",
                          borderRadius: "7px",
                          width: "100%",
                          border: "1px solid #EAEAEA",
                          height: "30px",
                        }}
                      />
                      <div className="err">{errors.comment?.message}</div>
                    </Grid>
                  </Grid>
                  <Button
                    size="smaill"
                    variant="contained"
                    type="submit"
                    style={{
                      backgroundColor: "var(--main)",
                      width: "150px",
                      color: "white",
                      borderRadius: "10px",
                      textTransform: "none",
                      marginBottom: "20px",
                    }}
                  >
                    {rejecting ? (
                      <PuffLoader
                        color="white"
                        loading={rejecting}
                        speedMultiplier={1}
                        size={20}
                      />
                    ) : (
                      <span className="btnlabel"> Reject </span>
                    )}
                  </Button>
                </form>
              }
            </div>
          </DialogPop>

          <DialogOutClick
            open={showAgents}
            handleClose={() => setShowAgents(false)}
          >
            <div className="quote_di">
              <div style={{ padding: "25px", fontWeight: "600" }}>
                Assign an agent
              </div>
              {availableAgent.length === 0 ? (
                <div style={{ padding: "20px 60px" }}>
                  No other agent available to assign to
                </div>
              ) : (
                availableAgent.map((fulfillers, index) => {
                  return (
                    <div
                      className={
                        fulfillers.user_id === fulfiller_id_to_assign
                          ? "assignFulfillerSelected"
                          : "assignFulfiller"
                      }
                      key={index}
                      onClick={() => {
                        setfulfiller_id_to_assign(fulfillers.user_id);
                        setSelectedLastmile(fulfillers);
                      }}
                    >
                      <div style={{ display: "flex", gap: "10px" }}>
                        <div
                          className="radiusCard"
                          style={{
                            backgroundImage: `url(${fulfillers?.profile_photo?.profile_photo})`,
                          }}
                        ></div>
                        <div style={{ width: "calc(100% - 45px)" }}>
                          <Grid container spacing={1}>
                            <Grid item xs={9}>
                              <span
                                style={{ fontSize: "13px", fontWeight: "600" }}
                              >
                                {fulfillers?.first_name +
                                  " " +
                                  fulfillers?.last_name}{" "}
                              </span>
                            </Grid>
                            <Grid item xs={3}>
                              <span style={{ fontSize: "13px" }}>
                                {fulfillers?.fulfilling_agent_id}
                              </span>
                            </Grid>
                            <Grid item xs={9}>
                              <span style={{ fontSize: "13px" }}>
                                Assigned deliveries:
                              </span>
                            </Grid>
                            <Grid item xs={3}>
                              <span style={{ fontSize: "13px" }}>
                                ({fulfillers?.num_assgn_deliveries})
                              </span>
                            </Grid>
                            <Grid item xs={9}>
                              <span style={{ fontSize: "13px" }}>
                                In-transit deliveries:
                              </span>
                            </Grid>
                            <Grid item xs={3}>
                              <span style={{ fontSize: "13px" }}>
                                ({fulfillers?.num_intransit_deliveries})
                              </span>
                            </Grid>
                            <Grid item xs={9}>
                              <span style={{ fontSize: "13px" }}>
                                Completed deliveries:
                              </span>
                            </Grid>
                            <Grid item xs={3}>
                              <span style={{ fontSize: "13px" }}>
                                ({fulfillers?.num_completed_deliveries})
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                      {/* {fulfillers.first_name +' '+ fulfillers.last_name } */}
                    </div>
                  );
                })
              )}
              {assigning ? (
                <div className="assignNow">
                  <span className="btnlabe">
                    <PuffLoader
                      color="#ffffff"
                      loading={assigning}
                      speedMultiplier={1}
                      size={24}
                    />
                  </span>
                </div>
              ) : selectedLastmile === "" ? (
                <div
                  className="assignNow"
                  style={{
                    backgroundColor: "var(--msgBorderline)",
                    cursor: "text",
                  }}
                >
                  Assign
                </div>
              ) : (
                <div className="assignNow" onClick={assignNow}>
                  Assign
                </div>
              )}
            </div>
          </DialogOutClick>
        </>
      )}
    </>
  );
}

export default DashDrillDown;