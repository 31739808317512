import React from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PuffLoader from 'react-spinners/PuffLoader';

const useStyles = makeStyles((theme) => ({
  right: {
    backgroundColor:'var(--main)',
    color:'white',
    fontWeight:'600',
  },
  left: {
    backgroundColor:'white',
    color:'var(--main)',
    border:'1px solid var(--main)',
    fontWeight:'600',
  }
  }));

function RegBtn({rightText, leftText, toNext,disabled, toStatus,type, loading}) {
    const classes = useStyles();
    return (
      <div style={{width:'100%', display:'flex', flexDirection:'column', gap:'15px'}}>
          <Button
              onClick={toStatus}
              type={loading ? 'button' : type }
              fullWidth
              variant="contained"
              className={classes.right}
              disabled={disabled}
              >
              <div style={{width:'100%', display:'flex', justifyContent:'center', textTransform:'none'}}>
              {
                  loading ?
                  <PuffLoader color='white' loading={loading}  speedMultiplier={1} size={24}/>
                  :
                  rightText
              }
              </div>
          </Button>
          <Button
              onClick={toNext}
              type="button"
              fullWidth
              variant="contained"
              className={classes.left}
              >
              <div style={{width:'100%', display:'flex', justifyContent:'center', textTransform:'none'}}>
                  {leftText}
              </div>
          </Button>
      </div>
        
    )
}

export default RegBtn
