/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from "react";
// import './ViewFulfiller2.css'
import dillivry from "../../../assets/Dillivry.png";
import { BiArrowBack } from "react-icons/bi";
import { Grid } from "@material-ui/core";
import { IoCheckbox } from "react-icons/io5";
import "./ViewFulfiler2.css";
import "./ViewFulfiller.css";
import "./ViewDelivry2.css";
import feedback_logo from "../../../assets/feedback_logo.svg";
import { Button } from "@material-ui/core";
import Footer from "../../../components/Footer/Footer";
import Reviews from "../../../assets/Reviews.svg";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { useHistory, useLocation} from "react-router-dom";
import Nav from "../../../components/Nav/Nav";
import IndividualSidebar from "../../../components/Sidebar/IndividualSidebar";
import imagelogo from "../../../assets/Dillivry.png";
import {FcCancel} from 'react-icons/fc'
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
export default function ViewFulfiler2({ details, handleChange, backtosearch }) {
  var deliver_with_us = GlobalizationLanguage("/create-account")
 

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [lastshowvalue, setLastShowValue] = useState(4);
  const [PER_PAGE, setPERPAGE] = useState(lastshowvalue);
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(details.length / PER_PAGE);
  const handleLastShowValue = (e) => {
    let { name, value } = e.target;
    console.log(Number(value));
    console.log(setPERPAGE(Number(value)));
  };
  const [componenttoshow, setComponenttoshow] = useState(false);
  const [datatoshow, setDatatoShow] = useState([]);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    if(location.state === 'back'){
      executeScrollbacktotop()
    }
}, [])


const scrollToRef = (ref) => window.scrollTo({
  top: 0,
  behavior:"smooth"
})


const backToTop = useRef(null)
const executeScrollbacktotop = () => scrollToRef(backToTop)






  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    console.log(
      "Showing",
      PER_PAGE * selectedPage + 1,
      "to",
      (selectedPage + 1) * PER_PAGE,
      "of",
      data.length,
      "entries"
    );
  };

  return (
    <div>
      <div className="header-body-">
        {/* <header className="item-header-content-header">
              <div className="item-header-content">
                <div className="item-logo-content">
                 <Link to="/"> <img src={dillivry} alt="" className="item-content-logo" /></Link>
                </div>
              </div>

              <div className="right-header-view">
                <input
                  type="text"
                  className="right-header-input"
                  placeholder="Search"
                />
                <button className="right-header-button">
                  <span className="right-header-button-span">Search</span>
                </button>
              </div>
            </header> */}
        <Nav />


        <div className="ind-nav">
              <IndividualSidebar />
              <div className="logocont">
                <img
                  src={imagelogo}
                  alt="dillivry"
                  onClick={() => history.push("/")}
                />
              </div>
            </div>
      </div>

      <div className="button-back-to-search">
        {" "}
        <button className="back-to-search" onClick={handleChange}>
          {" "}
          <span className="back-tosearch-content"  style={{cursor: "pointer"}}   onClick={()=>executeScrollbacktotop()}  >Back to delivery</span>
        </button>
      </div>

      <div className="htnlmk">
        <div className="fulfilername-body">
          <p>
            {details?.account_name}({details.completed_jobs})
          </p>
        </div>
      </div>

      <div className="fulfiler-main">
        <div className="top-flex">
          <div className="fulfiler-inner-content">
            <div className="image-feedback-details">
              <div className="image-provider">
                <img
                  src={details?.cop_logo}
                  alt="provider-profile"
                />
              </div>
              <div className="checkbox-under-image">
                <div className="verified">
                  <IoCheckbox color="var(--main)" />{" "}
                  <span style={{ fontSize: "13px", fontWeight: "600" }}>
                    Verified
                  </span>
                </div>
                <div>
                {
                  details?.good_in_transit_insurance?.insurance_coverage?.length > 4  ?
                  <div className="verified">
                      <IoCheckbox color='var(--main)'/> <span style={{fontSize:'13px', fontWeight:'600'}}>Insurance</span>
                  </div>
                  :
                  <div className="verified">
                      <FcCancel/> <span style={{fontSize:'13px', fontWeight:'600'}}>Insurance</span>
                  </div>
                }
                </div>
              </div>
            </div>

            <div className="feedback-percentage">
              <div className="fxflex">
                <div className="iner-flex">
                  <div>
                    <p className="paragraph-feedback">Positive feedback: </p>
                  </div>
                  <div>
                    <span className="span-content">
                      {details.Positive_feedback}
                    </span>
                  </div>
                </div>

                <div className="iner-flex">
                  <div>
                    <p className="paragraph-feedback">Completed Jobs: </p>
                  </div>
                  <div>
                    <span className="span-content">
                      ({details.completed_jobs})
                    </span>
                  </div>
                </div>

                <div className="iner-flex">
                  <div>
                    <p className="paragraph-feedback">Rejected Jobs: </p>
                  </div>
                  <div>
                    <span className="span-content">
                      ({details.rejected_jobs})
                    </span>
                  </div>
                </div>

                <div className="iner-flex">
                  <div>
                    <p className="paragraph-feedback">Registered Since:</p>
                  </div>
                  <div>
                    <span className="span-content">
                      {details.account_status.joined_date?.substr(8, 2)}/
                      {details.account_status.joined_date?.substr(5, 2)}/
                      {details.account_status.joined_date?.substr(0, 4)}
                    </span>
                  </div>
                </div>

                <div className="iner-flex">
                  <div>
                    <p className="paragraph-feedback">Provider type:</p>
                  </div>
                  <div>
                    <span className="span-content">
                      {/* {details?.account_type === "business"
                        ? "Corporateeee"
                        : "Corporate"} */}
                         {details?.role === "dillivry-corp-sys-admin"
                        ? "Corporate"
                        : details?.role === "dillivry-sole-trader-ops"  ? "Individual" : null  }
                    </span>
                  </div>
                </div>

                <div className="iner-flex">
                  <div>
                    <p className="paragraph-feedback">Insurance coverage:</p>
                  </div>
                  <div>
                    <span className="span-content">
                      {/* {details.good_in_transit_insurance?.provider_name} */}
                      {details?.good_in_transit_insurance?.insurance_coverage ?   details?.good_in_transit_insurance?.insurance_coverage : 'None' }
                    </span>
                  </div>
                </div>
              </div>
              {/* 
              {console.log(
                details.good_in_transit_insurance_validity,
                "insurance-policy"
              )} */}
            </div>
          </div>

          <div className="feedback-table">
            <div
              className="logo-feedback"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <img src={feedback_logo} alt="feedback_logo" />
              <p className="feedback-logo">Feedback</p>
            </div>

            <div className="feedbacktable-div">
              <table className="feedbacks-table">
                <tr className="feedback-trow">
                  <th></th>
                  <th>1 month</th>
                  <th>6 month</th>
                  <th>12 month</th>
                </tr>

                <tr className="positive-feedback">
                  <td>Positive</td>
                  <td>{details.Feedback.positive.one_month}</td>
                  <td>{details.Feedback.positive.six_month}</td>
                  <td>{details.Feedback.positive.telv_month}</td>
                </tr>
                <tr>
                  <td>Negative</td>
                  <td>{details.Feedback.negative.one_month}</td>
                  <td>{details.Feedback.negative.six_month}</td>
                  <td>{details.Feedback.negative.telv_month}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div className="provider-history-div" style={{marginTop :'0px'}}>
          <h2 className="provider-history">
            {" "}
            <img src={Reviews} alt="Reviews-icons" /> Reviews
          </h2>

          <table className="table_  table-tr-th   table-fulfiler">
            <thead>
            <tr>
              <th>Rating</th>
              <th>Item</th>
              <th>Left by</th>
              <th>Comments</th>
              <th>Date</th>
            </tr>
            </thead>

            {/* <td>
                    <img src={Reviews} alt="Reviews-icons" /> 
                    </td>
                    <td><p>Chairs</p></td>
                    <td><p>Musa  Chikanma (90)</p></td>
                    <td><span  style={{width: '20px'}} >Food Preparation and Serving Related Occupations, Mozilla/5.0 (Windows NT 10.0;</span></td>
                      <td><span>12/04/2021 </span></td> */}
            {details.reviews.map((i, d) => {
              return (
                <tr>
                  <td>
                    {i.rating === "notGreat" ? (
                      <FaThumbsDown color="red" />
                    ) : (
                      <FaThumbsUp color="var(--main)" />
                    )}
                  </td>

                  <td>{i.item_desc ? i.item_desc?.charAt(0)
                                          ?.toUpperCase() + i.item_desc?.slice(1)  : null}</td>
                  <td>
                    {i.sender_name} ({i.count}){" "}
                  </td>
                  <td>{i.additional_comments}</td>
                  <td>
                    {details.account_status?.joined_date.substr(8, 2)}/
                    {details.account_status?.joined_date.substr(5, 2)}/
                    {details.account_status?.joined_date.substr(0, 4)}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>

        {
          <div className="bottom-search-viewfulfiler">
            <div>
              <div className="bottom-search">
                <div className="left-entries">
                  <p className="showing-entries">
                    Showing {PER_PAGE * currentPage + 1} to{" "}
                    {(currentPage + 1) * PER_PAGE > details.length
                      ? details.length
                      : (currentPage + 1) * PER_PAGE}{" "}
                    of &nbsp;
                    {details.length} entries
                  </p>
                </div>
                <div className="right-search">
                  <div className="right-inner">
                    <div className="right-inner-search">
                      <p className="search-value">Search:</p>
                    </div>
                    <div className="right-input">
                      <input type="text" className="right-input-field" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="pagination">
                <div className="left-pagination">
                  <div className="left-pagination-show">
                    <p className="pagination-show">Show</p>
                  </div>
                  <div className="left-pagination-input">
                    <select
                      name=""
                      id=""
                      className="pagination-input"
                      onChange={handleLastShowValue}
                      value="15"
                    >
                      <option value="5">5</option>
                      <option value="15">20</option>
                      <option value="20">15</option>
                    </select>
                  </div>
                  <div className="left-pagination-entries">
                    <p className="pagination-entries">entries</p>
                  </div>
                </div>

                <div className="">
                  <div className="">
                    <ReactPaginate
                      previousLabel={"First"}
                      nextLabel={"Last"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={4}
                      pageRangeDisplayed={6}
                      onPageChange={handlePageClick}
                      containerClassName={"new-pagination"}
                      pageClassName={"pagination-one"}
                      pageLinkClassName={""}
                      previousClassName={"first-pagination"}
                      nextClassName={"last-pagination"}
                      nextLinkClassName={""}
                      breakClassName={"pagination-one--"}
                      breakLinkClassName={"pagination-one--"}
                      activeClassName={"active-"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="new-pagination"></div>
            {/* <div className="Backto-top">
              <div></div>
              <div className="main-back">
                <span style={{ marginTop: "20px" }}>Back to top</span>
              </div>
              <div></div>
            </div> */}
          </div>
        }
      </div>

      <div className="button-back-to-search">
        {" "}
        <button className="back-to-search" onClick={handleChange}>
          {" "}
          <span className="back-tosearch-content">Back to delivery</span>
        </button>
      </div>

      <div className="a-fulfuler">
        <div className="a1"></div>
        <div className="a2">
          <h2 className="logicstics-content">Are you a logistics provider?</h2>
        </div>
        {/* <div className="a2"><h2 className="logicstics-content">Are you a logistics provider?</h2></div>  */}

        <div className="a3">
          <button
            onClick={() => history.push(deliver_with_us)}
            className="signup-btn"
          >
            <span className="signup-btn-">Signup</span>
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
}
