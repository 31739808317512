import React, { useContext, useState, useEffect } from 'react'
import "./SenderSideBar.css"
import { useHistory } from 'react-router-dom'
import { SenderContext } from "../../../context/SenderDashboard/SenderDashboardContext";
import DialogOutClick from '../../../components/DialogOutClick';
import { AuthContext } from '../../../context/AuthContext';
import shopping from '../../../assets/shopping.svg'
import GlobalizationLanguage from '../../../components/GlobalizationLanguage';
import { IoArrowBack } from 'react-icons/io5';
import axios from 'axios';
import SecureLS from 'secure-ls';

export default function SenderTopBarBackArrow({  pageTitle }) {

  const auth = useContext(AuthContext);

  const { alldata, UpdateAlldata } = useContext(SenderContext)
  const [accountInfo, setAccountInfo] = useState()
  const [Cart, SetCart] = useState()

  const endpoint = process.env.REACT_APP_API;
  const ls = new SecureLS({ isCompression: true, encodingType: "rabbit", encryptionSecret: "dm_dillivry", });

  useEffect(() => {
    if (alldata !== undefined) {
      if(alldata.status === 200){
      const CheckLength =  alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length
      const InfoData = alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info) : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)
      CheckLength === 1 ? setAccountInfo(InfoData[0]) : setAccountInfo(InfoData[1])
      SetCart(alldata?.data?.data?.deliveries ?   alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map((x) => Object.values(x?.account))?.flat()?.map(x => x?.cart_history?.deliveries)?.flat() : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map((x) => Object.values(x?.account))?.flat()?.map(x => x?.cart_history?.deliveries)?.flat()) }
      
    }
    else{
      axios
        .get(`${endpoint}m-dillivry-sender-api`, {
          headers: {
            Authorization: ls.get("_utk"),
          },
        })
        .then((res) => {
          UpdateAlldata(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } 
}, [alldata])

  const logMeOut = () => {
    auth.logout();
    history.push("/");
  };
  var viewQuote_myCart = GlobalizationLanguage("/viewQuote/myCart")

  const history = useHistory();
  return (
    <div>
      <header className="header">

        <IoArrowBack size={24} onClick={() => history.goBack()} />

        <div className="titl">
          <p className="tltletag">{pageTitle}</p>
        </div>

        <div className="search-cart">
          <div className="cart-icon" onClick={() => history.push(viewQuote_myCart)}>
            {
              accountInfo?.cart_history?.deliveries.length ?
                <div className="cart-icon-badge">
                  {/* {accountInfo?.cart_history?.deliveries.length} */}
                  {Cart?.length}
                </div>
                :
                null
            }
            <img src={shopping} alt="shooping" className="carticon" />
          </div>
          <div>
          </div>
        </div>
      </header>
    </div>
  )
}
