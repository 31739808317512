import React, { useState,useCallback } from 'react';
import Sidebar from './Sidebar';

import ArticleIcon from '@mui/icons-material/Article';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { MenuItem } from '@mui/material';
// import CssTextField from '../../components/CssTextFeild';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { TextField,Button } from '@mui/material';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {useDropzone} from 'react-dropzone'
import profileLogistic from "../../assets/profileLogistic.svg"
import CheckedGreen from "../../assets/CheckedGreen.svg"
import policy from "../../assets/Policy.png"
import CustomizedRadios from '../Sender/Registration/Checkbox';

var FormStep = [
  {
    id: 1,
    Primary: "Form of identification",
    secondary: "Ready to begin",
  },
  {
    id: 2,
    Primary: "Profile photo",
    secondary: "Recommended next step",
  },
  {
    id: 3,
    Primary: "Insurance policy",
    secondary: "Ready to begin",
  },
  {
    id: 4,
    Primary: "Bank details",
    secondary: "Ready to begin",
  },
];
function Forms(step,setSwitchRoute){
switch (step) {
  case 1:
    return <FormofIdentifcation setSwitchRoute={setSwitchRoute}/>;
    break;
  case 2:
    return <Profilephoto setSwitchRoute={setSwitchRoute}/>;
    break;
  case 3:
    return <Insurancepolicy setSwitchRoute={setSwitchRoute}/>;
    break;
  case 4:
    break;

  default:
    break;
}
}
function Dashboard(props) {
  var [switchRoute,setSwitchRoute]=useState(0)
 
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Sidebar>
        
        <div className='container d-flex justify-content-center mt-5 h-100'>
        {switchRoute===0?
            <div className='d-flex justify-content-center flex-column'>
            <h3>Welcome, Jane</h3>
            <h6>Required steps</h6>
            <span>Here's what you need to do to setup your account.</span>
            <div className='mt-5 '></div>
            {FormStep.map((item,key)=>{
                return (
                  <Button
                    onClick={()=>setSwitchRoute(item.id)}
                    className="my-3 py-3 d-flex justify-content-around"
                    variant="outlined"
                    // sx={{
                    //     "&.MuiButton-outlinedPrimary": { color: "#000000 !important" },
                    //       border: "1px #B0AFB0 solid"
                    //     }}
                  >
                    <div>
                      <ArticleIcon />
                    </div>
                    <div className="d-flex flex-column align-items-start px-3">
                      <h6 className="m-0">{item.Primary}</h6>
                      <span>{item.secondary}</span>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                      <ChevronRightIcon />
                    </div>
                  </Button>
                );
            })}
            
            

            </div>:Forms(switchRoute,setSwitchRoute)}
        </div>
      </Sidebar>
      </LocalizationProvider>
    );
}
function Profilephoto(props){
  const [screen,setScreen]=useState(0);
  const [successScreen,setsuccessScreen]=useState(false);
  const [files, setFiles] = useState([]);
  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  }, [])
  const thumbs = files.map(file => (
    <div  key={file.name}>
      <div >
        <img
          style={{width:"100%",maxHeight:"300px",minHeight:"300px",borderRadius:"150px"}}
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));
  const {getRootProps, getInputProps, isDragActive} = useDropzone({ accept: {
    'image/*': []
  },onDrop})
return (
  <div
    className="d-flex justify-content-center flex-column h-100"
    style={{ maxWidth: "400px" }}
  >
    {
     successScreen?<div
      className="d-flex justify-content-center flex-column text-center h-100"
      style={{ minWidth: "400px" }}
    >
      <img src={CheckedGreen} style={{maxHeight:"200px"}} />

      <h4 className='pt-4'>Thanks for providing your profile photo</h4>
      <Button
              variant="outlined"
              onClick={()=>props.setSwitchRoute(0)}
              className="py-3 mt-auto w-100"
              style={{ borderRadius: "20px", fontWeight: "600" }}
            >
              Go to next step
            </Button>
            <div style={{width:"50px"}}></div>
            <Button
              onClick={()=>props.setSwitchRoute(0)}

              variant="contained"
              className="py-3 my-3 w-100"
              style={{ borderRadius: "20px", fontWeight: "600",color:"#fff" }}
            >
              Go to account status
            </Button>
    </div>:screen===0?
    <>
    <h6>Take your Profile photo</h6>
    <span style={{ fontSize: "14px", maxWidth: "400px" }}>
      Your profile photo helps people recoginse you. Please note that once you
      submitted your profile photo. It cannot be changed.
    </span>
    <ul className='ulDecimal ' style={{fontSize:"12px"}}>
      <li>Face the camera and make sure your eyes and mouth are clearly visilbe.</li>
      <li>Make sure the photo is well fit, free of glare and in focus.</li>
      <li>No photos of a photo, filters or alterations.</li>
    </ul>
    <div className='d-flex justify-content-center pt-5'>
    <img src={profileLogistic} width="180"/>
    </div>

    <button
    onClick={()=>setScreen(1)}
          className="mt-auto mb-4 py-3"
          style={{
            width: "100%",
            textAlign: "center",
            userSelect: "none",
            cursor: "pointer",
            backgroundColor: "var(--main)",
            color: "white",
            fontWeight: "600",
            fontSize: "15px",
            borderRadius: "20px",
            border: 0,
          }}
        >
          Upload photo
        </button>
        </>: <div
      className="d-flex justify-content-center flex-column h-100"
      style={{ minWidth: "400px" }}
    >
      <div
        {...getRootProps()}
        className="mb-5 mx-auto "
        style={{ background: "#E7E7E7",width:"300px", height: "300px",borderRadius:"150px" }}
      >
        {/* <PersonIcon/> */}
        {thumbs}

        <input {...getInputProps()} />
      </div>
      {files.length !== 0 ? (
        <>
          <h6>Use this Form of Identifcation?</h6>
          <span style={{ maxWidth: "400px" }}>
            By tapping save, you agree that Dillivry or a trusted vendor can
            collect and process your photos with technology that allows us to
            verify your identity?
          </span>
          <div className="d-flex mt-auto">
            <Button
              {...getRootProps()}
              variant="outlined"
              className="py-3 w-100"
              style={{ borderRadius: "20px", fontWeight: "600" }}
            >
              Change
            </Button>
            <div style={{width:"50px"}}></div>
            <Button
              onClick={()=>setsuccessScreen(true)}
              variant="contained"
              className="py-3 w-100"
              style={{ borderRadius: "20px", fontWeight: "600",color:"#fff" }}
            >
              save
            </Button>
          </div>
        </>
      ) : (
        <button
          {...getRootProps()}
          className="mt-auto mb-4 py-3"
          style={{
            width: "100%",
            textAlign: "center",
            userSelect: "none",
            cursor: "pointer",
            backgroundColor: "var(--main)",
            color: "white",
            fontWeight: "600",
            fontSize: "15px",
            borderRadius: "20px",
            border: 0,
          }}
        >
          Upload photo
        </button>
      )}
    </div>}
  </div>
);
}
function Insurancepolicy(props){
  const [value2, setValue2] = React.useState(null);
  const [value3, setValue3] = React.useState(null);
  const [successScreen,setsuccessScreen]=useState(false);
  const [uploadPhoto,SetUploadPhoto]=useState(false);
  const [files, setFiles] = useState([]);
  const [isEightPlus, setisEightPlus] = useState("false")

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  }, [])
  const thumbs = files.map(file => (
    <div  key={file.name}>
      <div >
        <img
          style={{width:"100%",maxHeight:"275px"}}
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));
  const {getRootProps, getInputProps, isDragActive} = useDropzone({ accept: {
    'image/*': []
  },onDrop})
  const schema = yup.object().shape({
      Insuranceprovidername: yup
      .string()
      .required("ID number is required")
      .min(3, "Can't be lesser than 3 digits"),
      Insurancepolicynumber: yup
      .string()
      .required("ID number is required")
      .min(3, "Can't be lesser than 3 digits"),
      validForm: yup
      .string()
      .required("Valid Form is required"),
      validTill: yup
      .string()
      .required("Valid Till is required")
  });
  const { setValue, control,handleSubmit, formState: { errors } } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
});
  return successScreen ? (
    <div
      className="d-flex justify-content-center flex-column text-center h-100"
      style={{ maxWidth: "400px" }}
    >
      <img src={CheckedGreen} style={{ maxHeight: "200px" }} />

      <h4 className="pt-4">Thanks for providing your Form of Identifcation</h4>
      <Button
        variant="outlined"
        onClick={() => props.setSwitchRoute(0)}
        className="py-3 mt-auto w-100"
        style={{ borderRadius: "20px", fontWeight: "600" }}
      >
        Go to next step
      </Button>
      <div style={{ width: "50px" }}></div>
      <Button
        onClick={() => props.setSwitchRoute(0)}
        variant="contained"
        className="py-3 my-3 w-100"
        style={{ borderRadius: "20px", fontWeight: "600", color: "#fff" }}
      >
        Go to account status
      </Button>
    </div>
  ) : !uploadPhoto ? (
    <div
      className="d-flex justify-content-center flex-column"
      style={{ maxWidth: "400px" }}
    >
      <span className='my-auto' style={{ color: "#484848", fontWeight: "700" }}>
      Upload your Insurance policy
      </span>
      <span className='my-auto'style={{ color: "#484848", fontWeight: "400" }}>
      Do you have goods in transit insurance?
      </span>
      <CustomizedRadios isEightPlus={isEightPlus}
                    setisEightPlus={setisEightPlus}/>

                    {isEightPlus==="Yes"?<>
      <span className='mt-auto' style={{ color: "#484848", fontWeight: "700" }}>Insurance provider name</span>
      <div className="py-2">
        <Controller
          name="Insuranceprovidername"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              id="Insuranceprovidername"
              variant="outlined"
              // value={accout_type}
              // onChange={(e) => setaccout_type(e.target.value)}
              label="Insurance provider name"
            />
          )}
        />
      </div>
      <span style={{ color: "#484848", fontWeight: "700" }}>Insurance policy number</span>
      <div className="py-2">
        <Controller
          name="Insurancepolicynumber"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              id="Insurancepolicynumber"
              variant="outlined"
              // value={accout_type}
              // onChange={(e) => setaccout_type(e.target.value)}
              label="Insurance policy number"
            />
          )}
        />
      </div>
      <div className=" d-flex py-5">
        <Controller
          name="validForm"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <DatePicker
              label="Valid from"
              value={value2}
              onChange={(newValue) => {
                setValue2(newValue);
              }}
              views={["month", "year"]}
              inputFormat="MM/yyyy"
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ marginRight: "0.5rem !important" }}
                  {...field}
                />
              )}
            />
          )}
        />
        <div style={{ width: "50px" }}></div>
        <Controller
          name="validTill"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <DatePicker
              label="Valid till"
              value={value3}
              onChange={(newValue) => {
                setValue3(newValue);
              }}
              views={["month", "year"]}
              inputFormat="MM/yyyy"
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ marginLeft: "0.5rem !important" }}
                  {...field}
                />
              )}
            />
          )}
        />
      </div>
      <button
        className="my-auto mb-4 py-3"
        onClick={() => SetUploadPhoto(true)}
        style={{
          width: "100%",
          textAlign: "center",
          userSelect: "none",
          cursor: "pointer",
          backgroundColor: "var(--main)",
          color: "white",
          fontWeight: "600",
          fontSize: "15px",
          borderRadius: "20px",
          border: 0,
        }}
      >
        Upload photo
      </button>
      </>:
      <img className='m-auto' src={policy} width="80%"/>
      }
      <Button
        variant="outlined"
        className="py-3 my-auto"
        style={{ borderRadius: "20px", fontWeight: "600" }}
      >
        Go to account status
      </Button>
    </div>
  ) : (
    <div
      className="d-flex justify-content-center flex-column h-100"
      style={{ minWidth: "400px" }}
    >
      <div
        {...getRootProps()}
        className="mb-5"
        style={{ background: "#E7E7E7", height: " 275px" }}
      >
        {thumbs}

        <input {...getInputProps()} />
      </div>
      {files.length !== 0 ? (
        <>
          <h6>Use this Form of Identifcation?</h6>
          <span style={{ maxWidth: "400px" }}>
            By tapping save, you agree that Dillivry or a trusted vendor can
            collect and process your photos with technology that allows us to
            verify your identity?
          </span>
          <div className="d-flex mt-auto">
            <Button
              {...getRootProps()}
              variant="outlined"
              className="py-3 w-100"
              style={{ borderRadius: "20px", fontWeight: "600" }}
            >
              Change
            </Button>
            <div style={{ width: "50px" }}></div>
            <Button
              variant="contained"
              onClick={()=>setsuccessScreen(true)}
              className="py-3 w-100"
              style={{ borderRadius: "20px", fontWeight: "600", color: "#fff" }}
            >
              save
            </Button>
            {/* <button
              style={{
                textAlign: "center",
                userSelect: "none",
                cursor: "pointer",
                backgroundColor: "var(--main)",
                color: "white",
                fontWeight: "600",
                fontSize: "15px",
                borderRadius: "20px",
                border: 0,
              }}
            >
              Save
            </button> */}
          </div>
        </>
      ) : (
        <button
          {...getRootProps()}
          className="mt-auto mb-4 py-3"
          style={{
            width: "100%",
            textAlign: "center",
            userSelect: "none",
            cursor: "pointer",
            backgroundColor: "var(--main)",
            color: "white",
            fontWeight: "600",
            fontSize: "15px",
            borderRadius: "20px",
            border: 0,
          }}
        >
          Upload photo
        </button>
      )}
    </div>
  );
}
function FormofIdentifcation(props){
  const [value2, setValue2] = React.useState(null);
  const [value3, setValue3] = React.useState(null);
  const [successScreen,setsuccessScreen]=useState(false);
  const [uploadPhoto,SetUploadPhoto]=useState(false);
  const [files, setFiles] = useState([]);
  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  }, [])
  const thumbs = files.map(file => (
    <div  key={file.name}>
      <div >
        <img
          style={{width:"100%",maxHeight:"275px"}}
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));
  const {getRootProps, getInputProps, isDragActive} = useDropzone({ accept: {
    'image/*': []
  },onDrop})
  const schema = yup.object().shape({
    idNumber: yup
      .string()
      .required("ID number is required")
      .min(3, "Can't be lesser than 3 digits")
      .matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
      validForm: yup
      .string()
      .required("Valid Form is required"),
      validTill: yup
      .string()
      .required("Valid Till is required")
  });
  const { setValue, control,handleSubmit, formState: { errors } } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
});
  return successScreen ? (
    <div
      className="d-flex justify-content-center flex-column text-center h-100"
      style={{ minWidth: "400px" }}
    >
      <img src={CheckedGreen} style={{ maxHeight: "200px" }} />

      <h4 className="pt-4">Thanks for providing your Form of Identifcation</h4>
      <Button
        variant="outlined"
        onClick={() => props.setSwitchRoute(0)}
        className="py-3 mt-auto w-100"
        style={{ borderRadius: "20px", fontWeight: "600" }}
      >
        Go to next step
      </Button>
      <div style={{ width: "50px" }}></div>
      <Button
        onClick={() => props.setSwitchRoute(0)}
        variant="contained"
        className="py-3 my-3 w-100"
        style={{ borderRadius: "20px", fontWeight: "600", color: "#fff" }}
      >
        Go to account status
      </Button>
    </div>
  ) : !uploadPhoto ? (
    <div
      className="d-flex justify-content-center flex-column"
      style={{ minWidth: "300px" }}
    >
      <span style={{ color: "#484848", fontWeight: "400" }}>
        Upload your Form of Identifcation
      </span>
      <div className="pt-4 pb-3">
        <Controller
          className="pt-5"
          name="identification"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              select
              {...field}
              fullWidth
              id="identification"
              variant="outlined"
              // value={accout_type}
              // onChange={(e) => setaccout_type(e.target.value)}
              label="Please select your Identification"
            >
              <MenuItem
                className="py-3 "
                divider={true}
                value="Drivers License"
              >
                Drivers License{" "}
              </MenuItem>
              <MenuItem
                className="py-3 "
                divider={true}
                value="International Passport"
              >
                International Passport{" "}
              </MenuItem>
              <MenuItem
                className="py-3 "
                divider={true}
                value="Biometric resident permit (BPR)"
              >
                Biometric resident permit (BPR)
              </MenuItem>
            </TextField>
          )}
        />
      </div>
      <span style={{ color: "#484848", fontWeight: "700" }}>ID number</span>
      <div className="py-2">
        <Controller
          name="idNumber"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              id="idNumber"
              variant="outlined"
              // value={accout_type}
              // onChange={(e) => setaccout_type(e.target.value)}
              label="Enter ID number"
            />
          )}
        />
      </div>
      <div className=" d-flex pt-5">
        <Controller
          name="validForm"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <DatePicker
              label="Valid from"
              value={value2}
              onChange={(newValue) => {
                setValue2(newValue);
              }}
              views={["month", "year"]}
              inputFormat="MM/yyyy"
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ marginRight: "0.5rem !important" }}
                  {...field}
                />
              )}
            />
          )}
        />
        <div style={{ width: "50px" }}></div>
        <Controller
          name="validTill"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <DatePicker
              label="Valid till"
              value={value3}
              onChange={(newValue) => {
                setValue3(newValue);
              }}
              views={["month", "year"]}
              inputFormat="MM/yyyy"
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ marginLeft: "0.5rem !important" }}
                  {...field}
                />
              )}
            />
          )}
        />
      </div>
      <button
        className="mt-auto mb-4 py-3"
        onClick={() => SetUploadPhoto(true)}
        style={{
          width: "100%",
          textAlign: "center",
          userSelect: "none",
          cursor: "pointer",
          backgroundColor: "#B0AFB0",
          color: "white",
          fontWeight: "600",
          fontSize: "15px",
          borderRadius: "20px",
          border: 0,
        }}
      >
        Upload photo
      </button>
      <Button
        variant="outlined"
        className="py-3 "
        style={{ borderRadius: "20px", fontWeight: "600" }}
      >
        Go to account status
      </Button>
    </div>
  ) : (
    <div
      className="d-flex justify-content-center flex-column h-100"
      style={{ minWidth: "400px" }}
    >
      <div
        {...getRootProps()}
        className="mb-5"
        style={{ background: "#E7E7E7", height: " 275px" }}
      >
        {thumbs}

        <input {...getInputProps()} />
      </div>
      {files.length !== 0 ? (
        <>
          <h6>Use this Form of Identifcation?</h6>
          <span style={{ maxWidth: "400px" }}>
            By tapping save, you agree that Dillivry or a trusted vendor can
            collect and process your photos with technology that allows us to
            verify your identity?
          </span>
          <div className="d-flex mt-auto">
            <Button
              {...getRootProps()}
              variant="outlined"
              className="py-3 w-100"
              style={{ borderRadius: "20px", fontWeight: "600" }}
            >
              Change
            </Button>
            <div style={{ width: "50px" }}></div>
            <Button
              variant="contained"
              onClick={()=>setsuccessScreen(true)}
              className="py-3 w-100"
              style={{ borderRadius: "20px", fontWeight: "600", color: "#fff" }}
            >
              save
            </Button>
            {/* <button
              style={{
                textAlign: "center",
                userSelect: "none",
                cursor: "pointer",
                backgroundColor: "var(--main)",
                color: "white",
                fontWeight: "600",
                fontSize: "15px",
                borderRadius: "20px",
                border: 0,
              }}
            >
              Save
            </button> */}
          </div>
        </>
      ) : (
        <button
          {...getRootProps()}
          className="mt-auto mb-4 py-3"
          style={{
            width: "100%",
            textAlign: "center",
            userSelect: "none",
            cursor: "pointer",
            backgroundColor: "var(--main)",
            color: "white",
            fontWeight: "600",
            fontSize: "15px",
            borderRadius: "20px",
            border: 0,
          }}
        >
          Upload photo
        </button>
      )}
    </div>
  );
}

export default Dashboard;