import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TopBar from '../../components/TopBar/TopBar';
// import TopBarHome from '../../components/TopBar/TopBarHome';
import { withStyles } from '@mui/styles';
const drawerWidth = 300;
const ListItem = withStyles({
    root: {
        '& .MuiListItemButton-root': {
            padding: "0 28px",
          },
          '& .MuiListItemIcon-root': {
            minWidth: 0,
            marginRight: 12,
          },
        "& .MuiListItemButton-root:hover":{
            backgroundColor: "transparent",
        },
        "& .MuiListItemText-root ":{
            padding:"10px 25px",
            marginRight:"25px",
        },
      "&$selected": {
        backgroundColor: "transparent",
        color: "white",
        "& .MuiListItemIcon-root": {
          color: "#50B14A"
        },
        "& .MuiListItemText-root ":{
            backgroundColor: "#50B14A",
            padding:"10px 25px",
            marginRight:"25px",
            color:"white",
            borderTopRightRadius:"26px",
            borderBottomLeftRadius:"26px"
        }

      },
    //   "&$selected:hover": {
    //     backgroundColor: "purple",
    //     color: "white",
    //     "& .MuiListItemIcon-root": {
    //       color: "white"
    //     }
    //   },
      "&:hover": {
        backgroundColor: "transparent",
        color: "white",
        "& .MuiListItemIcon-root": {
          color: "#50B14A"
        },
        "& .MuiListItemText-root ":{
            backgroundColor: "#50B14A",
            padding:"10px 25px",
            marginRight:"25px",
            color:"white",
            borderTopRightRadius:"26px",
            borderBottomLeftRadius:"26px"
        }
      }
    },
    selected: {
        
    }
  })(MuiListItem);
function Sidebar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div >
      <Toolbar sx={{height:"85px"}}/>
      <Divider />
      <List>
        {['Dashboard', 'My dillivry', 'Open deliveries', 'Create delivery','Wallet  ',   'Referrals ', 'Messages','Account','How it works','Settings','Help'].map((text, index) => (
          <ListItem key={text} disablePadding selected={index===0}>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
        </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* <TopBar/> */}
      <TopBar/>
      {/* <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Responsive drawer
          </Typography>
        </Toolbar>
      </AppBar> */}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth,background:"#E0FCE7" },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth,background:"#E0FCE7" },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        {props.children}
        
      </Box>
    </Box>
  );
}

Sidebar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Sidebar;
