import React, { useState, useContext, useEffect } from "react";
import InputField from "../../components/InputField";
import { HiOutlineMinusCircle } from "react-icons/hi";
import { FaTextHeight } from "react-icons/fa";
import PuffLoader from "react-spinners/PuffLoader";
import { FaCloudUploadAlt } from "react-icons/fa";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  CssBaseline,
  Grid,
} from "@material-ui/core";
import { Select, FormControl, MenuItem, InputLabel } from "@mui/material";
import DialogBox from "../../components/topNav/DialogBox";
import DateRanger from "../../components/DateRanger";
import ButtonCard from "../../components/ButtonCard";
import Calender from "../../components/Calender";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneNumber from "../../components/PhoneNumber";
import axios from "axios";
import Swal from "sweetalert2";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { AuthContext } from "../../context/AuthContext";
import imageCompression from "browser-image-compression";
import "./../SendQuote/SendQuote.css";
import { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import LoginComponent from "../../components/LoginComponent";
import { Link } from "react-router-dom";
import Popup from "../../components/Popup";
import SecureLS from "secure-ls";
import PrivacyDialog from "../../components/PrivacyDialog";
import { ConnContext } from "../../context/ConnContext";
import Dialogsummary from "../../components/Dialogsummary";
import edit from "../Sender/SenderDashboard/Assets/edit.png";
import GlobalizationLanguage from "../../components/GlobalizationLanguage";
import Cookies from "js-cookie";
import ReactSelect from "react-select";
import { BiCalendarAlt } from "react-icons/bi";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles(() => ({
  formControl: {
    width: `100%`,
  },
}));

function SendGeneral({
  main_category,
  itemCategory,
  onClick,
  previous,
  page,

  delivery_location,
  pickup_address,
  delivery_address,
  pickupIsError,
  deliveryIsError,
  setpickupIsError,
  setdeliveryIsError,
}) {
  const { visitingCountryCode } = useContext(ConnContext);
  var countryvalue =
    Cookies.get("i18next") || visitingCountryCode.toLowerCase();
  const [country, setCountry] = useState(countryvalue);

  const [modeIsValid, setmodeIsValid] = useState(false);

  const options = [
    { value: "pay_by_transfer", label: "Transfer" },
    { value: "pay_by_cash", label: "Cash" },
    { value: "pay_by_wallet", label: "Wallet" },
    { value: "pay_by_card", label: "Card" },
    { value: "pay_by_ussd", label: "USSD" },
  ];

  useEffect(() => {
    setCountry(Cookies.get("i18next"));
  }, [Cookies.get("i18next")]);

  var viewQuote = GlobalizationLanguage("/viewQuote");
  var timeout = GlobalizationLanguage("/timeout");
  var ThankYou = GlobalizationLanguage("/viewQuote/thank-you")

  var hasNumber = /\d/;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: state.isSelected ? "black" : "black",
      backgroundColor: state.isSelected ? "white" : "white",
    }),
  };

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const [mode_of_delivery_list, setmode_of_delivery_list] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (ls.get("_utk") === "") {
      setauthorize("");
    } else {
      setauthorize(ls.get("_utk"));

      const IdToken = ls.get("_utk");
      const token = IdToken.split(".")[1];
      const encodedToken = atob(token);
      const tokenJSON = JSON.parse(encodedToken);

      setdisableField(true);
      setValue("sender_email", tokenJSON?.email);
      setValue("sender_name", tokenJSON?.given_name);
      setSender_phone_no(tokenJSON?.phone_number);

      // console.log(tokenJSON, "tokenJSON");
    }
  }, []);

  useEffect(() => {
    if (delivery_location === "ng") {
      if (
        itemCategory === "Cake & Confectionery" ||
        itemCategory === "Document & Books" ||
        itemCategory === "Medicine" ||
        itemCategory === "Herbal products" ||
        itemCategory === "Beauty & Skincare" ||
        itemCategory === "Beverages" ||
        itemCategory === "Fruits" ||
        itemCategory === "Provisions" ||
        itemCategory === "Wine & Drinks" ||
        itemCategory === "Shoe" ||
        itemCategory === "Clothing" ||
        itemCategory === "Computer & Accessories" ||
        itemCategory === "Home appliances" ||
        itemCategory === "Phones & Accessories" ||
        itemCategory === "Frozen food" ||
        itemCategory === "Printing press"
      ) {
        setmode_of_delivery_list(["Bike", "Car", "Van"]);
      } else if (itemCategory === "Furniture" || itemCategory === "Livestock") {
        setmode_of_delivery_list(["Truck", "Van"]);
      } else if (itemCategory === "Food stuffs") {
        setmode_of_delivery_list(["Bike", "Car"]);
      } else if (
        itemCategory === "Vehicle parts" ||
        itemCategory === "Others" ||
        itemCategory === "General items"
      ) {
        setmode_of_delivery_list(["Bike", "Car", "Truck", "Van"]);
      } else if (itemCategory === "Perishables") {
        setmode_of_delivery_list(["Car", "Truck", "Van"]);
      } else if (
        itemCategory === "Jewellery & Accessories" ||
        itemCategory === "Fast food" ||
        itemCategory === "Snacks"
      ) {
        setmode_of_delivery_list(["Bike", "Car", "Truck", "Van"]);
      } else setmode_of_delivery_list([]);
    } else {
      if (
        itemCategory === "Beverages" ||
        itemCategory === "Provisions" ||
        itemCategory === "Wine & Drinks" ||
        itemCategory === "General items" ||
        itemCategory === "Boxes" ||
        itemCategory === "Medicine" ||
        itemCategory === "Dry food" ||
        itemCategory === "Fast food" ||
        itemCategory === "Food stuffs" ||
        itemCategory === "Frozen food"
      ) {
        setmode_of_delivery_list(["Car", "Van", "Truck"]);
      } else if (
        itemCategory === "Furniture" ||
        itemCategory === "Livestock" ||
        itemCategory === "Pallets" ||
        itemCategory === "Removal"
      ) {
        setmode_of_delivery_list(["Truck", "Van"]);
      } else if (
        itemCategory === "Computer & Accessories" ||
        itemCategory === "Home appliances" ||
        itemCategory === "Phones & Accessories"
      ) {
        setmode_of_delivery_list(["Car", "Truck", "Van"]);
      } else if (itemCategory === "Pets") {
        setmode_of_delivery_list(["Car", "Van"]);
      } else if (itemCategory === "Vehicle parts") {
        setmode_of_delivery_list(["Car", "Van"]);
      } else setmode_of_delivery_list([]);
    }
  }, [itemCategory, delivery_location]);

  const endPoint = process.env.REACT_APP_API;

  const history = useHistory();
  const [policyShow, setPolicyShow] = useState(false);
  const [pageToShow, setpageToShow] = useState("");
  const [showdrop, setShowdrop] = useState(false);
  const [showcate, setShowcate] = useState(false);
  const [payer, setPayer] = useState("sender");

  const handleClick = () => {
    setShowdrop((showdrop) => !showdrop);
    if (showcate) {
      setShowcate(false);
    }
  };

  const [isloading, setisloading] = useState(false);
  const [dimensionUnit, setDimensionUnit] = useState("centimeters");
  const [dimensionUnitWie, setDimensionUnitWie] = useState("grammes");
  const [showImageSpace, setShowImageSpace] = useState(false);
  const [image_files, setImage_files] = useState([]);
  const [sendImage, setSendImage] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [delivery_time_frame, setDelivery_time_frame] = useState("");
  const [delivery_time_frameduplicate, setDelivery_time_frameduplicate] =
    useState("");
  const [deliveryTFIsValid, setdeliveryTFIsValid] = useState(false);
  const [pickup_phone_no, setPickup_phone_no] = useState("");
  const [sender_phone_no, setSender_phone_no] = useState("");
  const [delivery_phone_no, setDelivery_phone_no] = useState("");

  const [showsummary, setshowsummary] = useState(false);
  const [weightUnit, setweightUnit] = useState("");
  const [mode_of_delivery_data, setmode_of_delivery_data] = useState("");
  const [previewdata, setpreviewdata] = useState([]);

  const [disableField, setdisableField] = useState(false);
  const [ShowLogin, setShowLogin] = useState(false);

  const [authorize, setauthorize] = useState("");

  const [isReciever, setIsReciever] = useState("false");
  const [isPickup, setIsPickup] = useState("false");
  const [range, setRanges] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
      color: "#f99804",
    },
  ]);

  const setDialog = async () => {
    const result = await trigger(["pickup_date"]);
    if (result) {
      setOpenDialog(!openDialog);
      setdeliveryTFIsValid(false);
    }
  };
  const setRange = async () => {
    setOpenDateRange(!openDateRange);
  };
  const handleOpenDate = () => {
    setOpenDate(!openDate);
  };
  const auth = useContext(AuthContext);


  const handleChange = (e) => {
    setmode_of_delivery_data(e.target.value);
    setmodeIsValid(false);
  };

  async function imageHandler(e) {
    const file = e.target.files[0];
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 512,
    };
    const output = await imageCompression(file, options);

    const dataa = await imageCompression.getDataUrlFromFile(output);

    setImage_files([...image_files, dataa]);

    const img = dataa.split(",");

    setSendImage([...sendImage, img[1]]);

    setShowImageSpace(true);
  }

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const classes = useStyles();

  const schema = yup.object().shape({
    mode_of_payment: yup.object().shape({
      label: yup.string().required("payment option is required (from label)"),
      value: yup.string().required("payment option is required"),
    }),
    item_desc: yup.string().required("Brief description is required"),
    // mode_of_delivery: yup.string().required("Mode of delivery is required"),
    pickup_email: yup.string().email("Valid email is required"),
    sender_email: yup
      .string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
        "Valid email is required"
      )
      .required("Sender email is required"),
    delivery_email: yup.string().email("Valid email is required"),
    pickup_name: yup
      .string()
      .required("Pickup name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    delivery_name: yup
      .string()
      .required("Receiver's name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    sender_name: yup
      .string()
      .required("Your name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    item_weight: yup.string().matches(/^[0-9]*$/, "Weight has to be number"),
    item_dim_len: yup.string().matches(/^[0-9]*$/, "Length has to be number"),
    item_dim_wid: yup.string().matches(/^[0-9]*$/, "Width has to be number"),
    item_dim_hei: yup.string().matches(/^[0-9]*$/, "Height has to be number"),
    pickup_date: yup.string().required("Pickup date is required"),
  });
  const {
    control,
    getValues,
    setValue,
    trigger,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const closemodalandbacktopageone = () => {
    setshowsummary(!showsummary);
  };

  const submitForm = async (data) => {
    setshowsummary(false);
    if (
      sender_phone_no === undefined ||
      delivery_phone_no === undefined ||
      pickup_phone_no === undefined
    ) {
      Swal.fire({
        icon: "info",
        text: "Phones number is required",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    } else if (
      !isValidPhoneNumber(sender_phone_no) ||
      !isValidPhoneNumber(delivery_phone_no) ||
      !isValidPhoneNumber(pickup_phone_no) ||
      (!sender_phone_no?.length === 14 && visitingCountryCode == "NG") ||
      (!sender_phone_no?.length === 15 && visitingCountryCode == "NG") ||
      (sender_phone_no?.length === 13 && visitingCountryCode == "GB")
    ) {
      Swal.fire({
        icon: "info",
        text: "Invalid phone number",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
      setshowsummary(false);
    } else {
      if (data.image_files === undefined) {
        data.image_files = "";
      }
      if (data.item_dim_hei === undefined) {
        data.item_dim_hei = "";
      }
      if (data.item_dim_len === undefined) {
        data.item_dim_len = "";
      }
      if (data.item_dim_wid === undefined) {
        data.item_dim_wid = "";
      }
      if (data.item_weight === undefined) {
        data.item_weight = "";
      }
      data.pickup_address = pickup_address;
      data.delivery_address = delivery_address;
      data.delivery_time_frame = delivery_time_frameduplicate.replace(
        /\b(\d)\b/g,
        "0$1"
      );
      data.image_files = sendImage;
      data.pickup_phone_no = parsePhoneNumber(pickup_phone_no).number;
      data.sender_phone_no = parsePhoneNumber(sender_phone_no).number;
      data.delivery_phone_no = parsePhoneNumber(delivery_phone_no).number;
      data.sender_email = data.sender_email.toLowerCase();
      data.item_dim_hei = data.item_dim_hei + " " + dimensionUnit;
      data.item_dim_len = data.item_dim_len + " " + dimensionUnit;
      data.item_dim_wid = data.item_dim_wid + " " + dimensionUnit;
      data.item_weight = data.item_weight + " " + dimensionUnitWie;
      data.item_category = itemCategory.toLowerCase().split(" ").join("_");
      data.category = main_category.toLowerCase().split(" ").join("_");
      data.action_type = "listing";
      data.delivery_type = "bid";
      data.payer = payer;
      data.vcc = visitingCountryCode;
      data.mode_of_delivery = mode_of_delivery_data.toLowerCase();
      data.mode_of_payment = data?.mode_of_payment?.value;

      // console.log(data, "data")
      const authAxios = axios.create({
        baseURL: `${endPoint}`,
        headers: {
          Authorization: ls.get("_utk") !== null ? ls.get("_utk") : "",
        },
      });

      setisloading(true);
      const Api =
        delivery_location !== undefined
          ? `m-dillivry-listing?delivery_location=${delivery_location}`
          : `m-dillivry-listing?delivery_location=ng`;

      // const Api = Cookies?.get('i18next')?.toLowerCase() === visitingCountryCode.toLowerCase() ? `m-dillivry-listing` : `m-dillivry-listing?vcc=${country}`
      await authAxios
        .post(Api, data, { timeout: 60000 })
        .then((res) => {
          setshowsummary(false);

          // ls.set("eid", data.sender_email);
          ls.remove("auth_status");
          Swal.fire({
            icon: "success",
            title: "Done",
            html:
              ls.get("_utk") !== "" && ls.get("eid") !== ""
                ? '<h6 style={{fontSize:"14px"}}>Your requested has now been listed successfully.</h6>' +
                  "<h6>Sit back and relax as the quotes starts to roll in.</h6>" +
                  "<h6>For instant quote alert and tracking, download the app</h6>"
                : '<h6 style={{fontSize:"14px"}}>Your requestly has now been listed successfully.</h6>' +
                  "<h6>Sit back and relax as the quotes starts to roll in.</h6>" +
                  "<h6>An account has been created for you and your login details has been sent to your email address.</h6>" +
                  "<h6>Check your email and login to your dashboard to manage your account.</h6>" +
                  "<h6>For instant quote alert and tracking, download the app</h6>",

            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              history.push({
                pathname: ThankYou,
                state: "from_requestDelivery",
              });
            }
          });
          setisloading(false);
        })

        .catch((error) => {
          setisloading(false);
          // console.log(error?.response?.data?.msg_subject, "error?.response?.data?.msg_subject")

          if (
            error.response?.data?.msg === "not yet in the specifcied country"
          ) {
            Swal.fire({
              icon: "warning",
              text: `Not yet in the specified country, check your pickup addresses and try again`,
              confirmButtonColor: "var(--main)",
            }).then(()=>{
              setisloading(false)
            });
          } else if (error.code === "ECONNABORTED") {
            console.log("internal-server-error-timeout");
            history.push(timeout);
            // window.location.reload()
          } else if (
            error?.response?.data?.msg_subject !== undefined &&
            error?.response?.data?.msg_subject === "user name already exists"
          ) {
            Swal.fire({
              icon: "warning",
              text: `${error?.response?.data?.msg_subject}`,
              confirmButtonColor: "var(--main)",
              confirmButtonText: "Login to continue",
            }).then((result) => {
              if (result.value) {
                setshowsummary(false)
                setShowLogin(true);
              }
            });

            setisloading(false);
          } else {
            Swal.fire({
              icon: "warning",
              text: `${error?.response?.data?.msg_subject}`,
              confirmButtonColor: "var(--main)",
            });
            setisloading(false);
          }
        })
        .finally(()=>{
          setisloading(false)
        })
    }
  };

  const requestDelivery = async (data, e) => {

    // console.log(data, "data")


    e.preventDefault();
    setshowsummary(!showsummary);
    if (
      sender_phone_no === undefined ||
      delivery_phone_no === undefined ||
      pickup_phone_no === undefined
    ) {
      Swal.fire({
        icon: "info",
        text: "Phones number is required",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    } else if (
      !isValidPhoneNumber(sender_phone_no) ||
      !isValidPhoneNumber(delivery_phone_no) ||
      !isValidPhoneNumber(pickup_phone_no)
    ) {
      Swal.fire({
        icon: "info",
        text: "Invalid phone number",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    } else {
      if (data.image_files === undefined) {
        data.image_files = "";
      }
      if (data.item_dim_hei === undefined) {
        data.item_dim_hei = "";
      }
      if (data.item_dim_len === undefined) {
        data.item_dim_len = "";
      }
      if (data.item_dim_wid === undefined) {
        data.item_dim_wid = "";
      }
      if (data.item_weight === undefined) {
        data.item_weight = "";
      }
      data.pickup_address = pickup_address;
      data.delivery_address = delivery_address;

      data.delivery_time_frame = delivery_time_frameduplicate
      data.image_files = sendImage;
      data.pickup_phone_no =
        isPickup === "true"
          ? sender_phone_no
          : parsePhoneNumber(pickup_phone_no).number;
      data.sender_phone_no = sender_phone_no;
      data.delivery_phone_no =
        isReciever === "true"
          ? sender_phone_no
          : parsePhoneNumber(delivery_phone_no).number;
      data.item_dim_hei = data.item_dim_hei + " " + dimensionUnit;
      data.item_dim_len = data.item_dim_len + " " + dimensionUnit;
      data.item_dim_wid = data.item_dim_wid + " " + dimensionUnit;
      data.item_weight = data.item_weight + " " + weightUnit;
      data.mode_of_delivery = mode_of_delivery_data.toLowerCase();
      data.mode_of_payment = data?.mode_of_payment?.value;
      data.item_category = itemCategory.toLowerCase().split(" ").join("_");
      data.category = main_category.toLowerCase().split(" ").join("_");
      data.sender_email = data.sender_email.toLowerCase();
      data.sender_name = data.sender_name;
      data.action_type = "listing";
      data.delivery_type = "bid";
      data.payer = payer;
      // data.vcc = visitingCountryCode;

      setpreviewdata([data]);

      const authAxios = axios.create({
        baseURL: `${endPoint}`,
        headers: {
          Authorization: ls.get("_utk") !== null ? ls.get("_utk") : "",
        },
      });

      setisloading(true);
      setisloading(false);
    }
  };
  

  const submitafterPreview = async (data, e) => {
    // console.log(data, "data after preview")
    // console.log(data.mode_of_payment, "mode_of_payment")
   e.preventDefault();
   setisloading(true);
    if (
      sender_phone_no === undefined ||
      delivery_phone_no === undefined ||
      pickup_phone_no === undefined
    ) {
      Swal.fire({
        icon: "info",
        text: "Phones number is required",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    } else if (
      !isValidPhoneNumber(sender_phone_no) ||
      !isValidPhoneNumber(delivery_phone_no) ||
      !isValidPhoneNumber(pickup_phone_no)
    ) {
      Swal.fire({
        icon: "info",
        text: "Invalid phone number",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    } else {
      if (data.image_files === undefined) {
        data.image_files = "";
      }
      if (data.item_dim_hei === undefined) {
        data.item_dim_hei = "";
      }
      if (data.item_dim_len === undefined) {
        data.item_dim_len = "";
      }
      if (data.item_dim_wid === undefined) {
        data.item_dim_wid = "";
      }
      if (data.item_weight === undefined) {
        data.item_weight = "";
      }
      data.pickup_address = pickup_address;
      data.delivery_address = delivery_address;

      data.delivery_time_frame = delivery_time_frameduplicate
      // data.delivery_time_frame = delivery_time_frameduplicate.replace(/\b(\d)\b/g, "0$1")

      data.image_files = sendImage;
      data.pickup_phone_no =
        isPickup === "true"
          ? sender_phone_no
          : parsePhoneNumber(pickup_phone_no).number;
      data.sender_phone_no = sender_phone_no;
      data.delivery_phone_no =
        isReciever === "true"
          ? sender_phone_no
          : parsePhoneNumber(delivery_phone_no).number;
      data.item_dim_hei = data.item_dim_hei + " " + dimensionUnit;
      data.item_dim_len = data.item_dim_len + " " + dimensionUnit;
      data.item_dim_wid = data.item_dim_wid + " " + dimensionUnit;
      data.item_weight = data.item_weight + " " + weightUnit;
      data.mode_of_delivery = mode_of_delivery_data.toLowerCase();
      data.mode_of_payment = data?.mode_of_payment?.value;
      data.item_category = itemCategory.toLowerCase().split(" ").join("_");
      data.category = main_category.toLowerCase().split(" ").join("_");
      data.sender_email = data.sender_email.toLowerCase();
      data.sender_name = data.sender_name;
      data.action_type = "listing";
      data.delivery_type = "bid";
      data.payer = payer;
   

      setpreviewdata([data]);
      setisloading(false)

      const authAxios = axios.create({
        baseURL: `${endPoint}`,
        headers: {
          Authorization: ls.get("_utk") !== null ? ls.get("_utk") : "",
        },
      });

      setisloading(true);

      // console.log(delivery_location,"delivery_location")

      // const Api = `m-dillivry-listing?delivery_location=${delivery_location}`
      const Api = delivery_location !== undefined ? `m-dillivry-listing?delivery_location=${delivery_location}` : `m-dillivry-listing?delivery_location=ng`

      await authAxios
        .post(Api, data, { timeout: 60000 })
        .then((res) => {
          if (res.status === 200) {
            // ls.set("eid", data.sender_email);
            ls.remove("auth_status");
            setshowsummary(!showsummary);
            setisloading(false);
            Swal.fire({
              icon: "success",
              title: "Done",
              html:"<h6>Your request has now been listed successfully. Sit back and relax as the quotes start to roll in.</h6>",
              allowOutsideClick: false,
              confirmButtonColor: "var(--main)",
              showConfirmButton: true
            }).then((result) => {
              if (result.value) {
                history.push({
                  pathname: ThankYou,
                  state: "from_requestDelivery",
                });
              }
            });
            setisloading(false);
          }
        })
        .catch((error) => {
          setisloading(false);
          // console.log(error?.response?.data?.msg_subject, "error?.response?.data?.msg_subject")

          if (
            error.response?.data?.msg === "not yet in the specifcied country"
          ) {
            Swal.fire({
              icon: "warning",
              text: `Not yet in the specified country, check your pickup addresses and try again`,
              confirmButtonColor: "var(--main)",
            });
          } else if (error.code === "ECONNABORTED") {
            // console.log("internal-server-error-timeout");
            history.push(timeout);
            // window.location.reload()
          } else if (
            error?.response?.data?.msg_subject !== undefined &&
            error?.response?.data?.msg_subject === "user name already exists"
          ) {
            Swal.fire({
              icon: "warning",
              text: `${error?.response?.data?.msg_subject}`,
              confirmButtonColor: "var(--main)",
              confirmButtonText: "Login to continue",
            }).then((result) => {
              if (result.value) {
                setShowLogin(true);
                setshowsummary(false)
              }
            });

            setisloading(false);
          } else {
            Swal.fire({
              icon: "warning",
              text: `${error?.response?.data?.msg_subject}`,
              confirmButtonColor: "var(--main)",
            });
            setisloading(false);
          }
        })
        .finally(()=>{
          setisloading(false)
        })
        
    }
  };

  return (
    <>
      <CssBaseline />
      <form onSubmit={handleSubmit(requestDelivery)} style={{ width: "100%" }}>
        {page === 1 ? (
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={12} sm={12}>
              <Controller
                name="item_desc"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputField
                      label="Brief Description"
                      {...field}
                      error={errors?.item_desc}
                      id="item_desc"
                      type="text"
                      labelWidth={125}
                      icon={
                        <FaTextHeight
                          style={{
                            marginRight: "5px",
                            color: "var(--main)",
                          }}
                        />
                      }
                    />
                    <div className="err">{errors.item_desc?.message}</div>
                  </FormControl>
                )}
              />
            </Grid>




            <Grid item xs={12}>
              <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
                <InputLabel id="select">Mode of delivery</InputLabel>
                <Select
                  labelId="select"
                  error={modeIsValid}
                  value={mode_of_delivery_data}
                  onChange={handleChange}
                  label="Mode of delivery"
                >
                  {mode_of_delivery_list?.map((mode, i) => (
                    <MenuItem key={i} value={mode}>
                      {mode?.charAt(0)?.toUpperCase() + mode?.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>


            {/* <Grid item xs={12} sm={12}>
              <Controller
                name="mode_of_delivery"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl
                    className={classes?.formControl}
                    variant="outlined"
                  >
                    <InputLabel id="mode_of_delivery">
                      Mode of Delivery
                    </InputLabel>

                    <Select
                      {...field}
                      error={errors?.mode_of_delivery}
                      labelId="mode_of_delivery"
                      id="mode_of_delivery"
                      label="Mode of Delivery"
                      size="small"
                    >
                      {mode_of_delivery_list
                        ? mode_of_delivery_list?.map((mode, i) => (
                            <MenuItem key={i} value={mode}>
                              {mode?.charAt(0)?.toUpperCase() + mode?.slice(1)}
                            </MenuItem>
                          ))
                        : null}
                    </Select>

                    <div className="err">
                      {errors?.mode_of_delivery?.message}
                    </div>
                  </FormControl>
                )}
              />
            </Grid> */}








            <Grid item xs={12} sm={6}>
              <Controller
                name="pickup_date"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <label>Pickup Date</label>
                    <InputField
                      label="."
                      {...field}
                      error={errors.pickup_date}
                      id="pickup_date"
                      name="pickup_date"
                      type="date"
                      inputProps={{ min: disablePastDate() }}
                      InputLabelProps={{ shrink: false }}
                      icon={
                        <FaTextHeight
                          style={{
                            marginRight: "5px",
                            color: "var(--main)",
                          }}
                        />
                      }
                    />
                    <div className="err">{errors.pickup_date?.message}</div>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="delivery_time_frame"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    style={{ position: "relative" }}
                  >
                    <label>Delivery time frame </label>

                    <InputField
                      InputLabelProps={{ shrink: false }}
                      label="."
                      error={deliveryTFIsValid}
                      {...field}
                      name="delivery_time_frame"
                      icon={
                        <FaTextHeight
                          style={{
                            marginRight: "5px",
                            color: "var(--main)",
                          }}
                        />
                      }
                      type="text"
                      value={delivery_time_frame}
                      onChange={setDialog}
                      endIcon={<BiCalendarAlt style={{ fontSize: "20px" }} />}
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                      }}
                      onClick={setDialog}
                    />
                    <div className="err">
                      {errors.delivery_time_frame?.message}
                    </div>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} style={{ cursor: "pointer", fontSize: "15px" }}>
              <input
                type="checkbox"
                name="vehicle1"
                checked={showdrop}
                value="none"
              ></input>
              &nbsp;
              <label
                style={{
                  paddingLeft: "30px",
                  marginLeft: "-30px",
                }}
                for="vehicle1"
                onClick={handleClick}
              >
                {" "}
                <b> Add optional item dimensions & photos </b>
              </label>
            </Grid>
            {showdrop ? (
              <>
                <Grid item xs={12} sm={12}>
                  <Grid container xs={12} sm={12}>
                    <Grid
                      container
                      xs={12}
                      sm={3}
                      spacing={1}
                      style={{ marginRight: "8px" }}
                    >
                      <Grid item xs={12} sm={12}>
                        Unit
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <FormControl
                          className={classes.formControl}
                          variant="outlined"
                        >
                          <InputLabel id="unit">Unit</InputLabel>
                          <Select
                            labelId="unit"
                            value={dimensionUnit}
                            onChange={(e) => setDimensionUnit(e.target.value)}
                            label="Unit"
                          >
                            <MenuItem value="centimeters">Centimeter</MenuItem>
                            <MenuItem value="meters">Meters</MenuItem>
                            <MenuItem value="inches">Inches</MenuItem>
                            <MenuItem value="feet">Feet</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} xs={12} sm={9}>
                      <Grid item xs={12}>
                        Dimension
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Controller
                          name="item_dim_len"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              className={classes.formControl}
                              variant="outlined"
                            >
                              <InputField
                                label="Length"
                                {...field}
                                name="item_dim_len"
                                id="item_dim_len"
                                type="text"
                                error={errors.item_dim_len}
                                labelWidth={90}
                                icon={
                                  <FaTextHeight
                                    style={{
                                      color: "var(--main)",
                                    }}
                                  />
                                }
                              />
                              <div className="err">
                                {errors.item_dim_len?.message}
                              </div>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Controller
                          name="item_dim_wid"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              className={classes.formControl}
                              variant="outlined"
                            >
                              <InputField
                                label="Width"
                                {...field}
                                name="item_dim_wid"
                                id="item_dim_wid"
                                type="text"
                                error={errors.item_dim_wid}
                                labelWidth={90}
                                icon={
                                  <FaTextHeight
                                    style={{
                                      marginRight: "5px",
                                      color: "var(--main)",
                                    }}
                                  />
                                }
                              />
                              <div className="err">
                                {errors.item_dim_wid?.message}
                              </div>
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <Grid item xs={4} sm={4}>
                        <Controller
                          name="item_dim_hei"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              className={classes.formControl}
                              variant="outlined"
                            >
                              <InputField
                                label="Height"
                                {...field}
                                name="item_dim_hei"
                                id="item_dim_hei"
                                type="text"
                                error={errors.item_dim_hei}
                                labelWidth={90}
                                icon={
                                  <FaTextHeight
                                    style={{
                                      marginRight: "5px",
                                      color: "var(--main)",
                                    }}
                                  />
                                }
                              />
                              <div className="err">
                                {errors.item_dim_hei?.message}
                              </div>
                            </FormControl>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <InputLabel id="unit">Weight Unit</InputLabel>
                    <Select
                      labelId="unit"
                      value={dimensionUnitWie}
                      onChange={(e) => setDimensionUnitWie(e.target.value)}
                      label="Unit"
                    >
                      <MenuItem value="grammes">Gram</MenuItem>
                      <MenuItem value="kilogrammes">Kilogram</MenuItem>
                      <MenuItem value="tonnes">Tonnes</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Controller
                    name="item_weight"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputField
                          label="Weight"
                          {...field}
                          name="item_weight"
                          id="item_weight"
                          type="text"
                          error={errors.item_weight}
                          labelWidth={90}
                          icon={
                            <FaTextHeight
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                          }
                        />
                        <div className="err">{errors.item_weight?.message}</div>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div className="title">Upload photo</div>
                  <div className="photo">
                    <input
                      style={{ display: "none" }}
                      id="image_files"
                      name="image_files"
                      type="file"
                      accept="image/x-png,image/gif,image/jpeg"
                      {...register("image_files")}
                      onChange={imageHandler}
                    />
                    <div style={{ color: "#989898" }}>
                      Browse to choose photos
                    </div>
                    {image_files.length !== 3 ? (
                      <label htmlFor="image_files" className="upload">
                        Upload Picture &nbsp;{" "}
                        <FaCloudUploadAlt style={{ fontSize: "15px" }} />
                      </label>
                    ) : (
                      <label htmlFor="image_file" className="uploaded">
                        Upload Picture &nbsp;{" "}
                        <FaCloudUploadAlt style={{ fontSize: "15px" }} />
                      </label>
                    )}
                  </div>
                </Grid>
              </>
            ) : null}

            <Grid item xs={12} style={{ padding: "0" }}>
              <Grid container spacing={2}>
                {showImageSpace ? (
                  <>
                    {image_files.map((images, index) => {
                      return (
                        <Grid item xs={4}>
                          <div key={index} className="forPhoto">
                            <div
                              className="border"
                              style={{ backgroundImage: `url(${images})` }}
                            >
                              <span className="icon">
                                <HiOutlineMinusCircle
                                  onClick={() => {
                                    const image = Object.assign(
                                      [],
                                      image_files
                                    );
                                    image.splice(index, 1);
                                    setImage_files(image);

                                    const tosend = Object.assign([], sendImage);
                                    tosend.splice(index, 1);
                                    setSendImage(tosend);
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                  </>
                ) : null}
              </Grid>
            </Grid>
            <Button
              size="medium"
              variant="contained"
              type="button"
              style={{
                backgroundColor: "var(--main)",
                width: "100%",
                marginTop: "0px",
                color: "white",
                borderRadius: "10px",
                textTransform: "none",
              }}
              onClick={async () => {
                const result = await trigger([
                  "item_desc",
                  // "mode_of_delivery",
                  "pickup_date",
                ]);
                if (
                  result === true &&
                  pickup_address !== undefined &&
                  mode_of_delivery_data !== "" &&
                  delivery_address !== undefined &&
                  itemCategory !== "" &&
                  !pickupIsError &&
                  !deliveryIsError &&
                  delivery_time_frame !== ""
                ) {
                  onClick();
                  // console.log(result, "resulttt");
                } else {
                  if (pickup_address === "" || pickup_address === undefined) {
                    setpickupIsError(true);
                  }
                  if (mode_of_delivery_data === "") {
                    setmodeIsValid(true);
                  }
                  if (
                    delivery_address === "" ||
                    delivery_address === undefined
                  ) {
                    setdeliveryIsError(true);
                  }

                  if (itemCategory === "") {
                    Swal.fire({
                      icon: "info",
                      title: "Required",
                      text: "Select a category",
                      timer: 3000,
                      showConfirmButton: false,
                    });
                  }
                  if (delivery_time_frame === "") {
                    setdeliveryTFIsValid(true);
                  }
                }
              }}
            >
              Continue
            </Button>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div
                className="txt"
                style={{
                  color: "var(--main)",
                  fontWeight: "1000",
                  fontSize: "20px",
                  letterSpacing: "1px",
                }}
              >
                Contact Info
              </div>

              <div className="txt">My Information</div>

              <Controller
                name="sender_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <InputField
                      label="Sender's Name"
                      {...field}
                      error={errors.sender_name}
                      name="sender_name"
                      id="sender_name"
                      type="text"
                      labelWidth={150}
                      icon={
                        <FaTextHeight
                          style={{
                            marginRight: "5px",
                            color: "var(--main)",
                          }}
                        />
                      }
                      disabled={disableField}
                    />
                    <div className="err">{errors.sender_name?.message}</div>
                  </FormControl>
                )}
              />
            </Grid>



            <Grid item xs={12}>
              <Controller
                name="sender_email"
                error={errors.sender_email}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <InputField
                      name="sender_email"
                      label="Sender's Email"
                      {...field}
                      error={errors.sender_email}
                      id="sender_email"
                      type="email"
                      labelWidth={150}
                      icon={
                        <FaTextHeight
                          style={{
                            marginRight: "5px",
                            color: "var(--main)",
                          }}
                        />
                      }
                      disabled={disableField}
                    />
                    <div className="err">
                      {errors.sender_email && errors.sender_email?.message}
                    </div>
                  </FormControl>
                )}
              />
            </Grid>


            

            <Grid item xs={12}>
              <div className="phoneTopic">Sender Phone Number</div>
              <PhoneNumber
                value={sender_phone_no}
                setValue={setSender_phone_no}
                disabled={disableField}
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <div style={{ fontSize: "18px", fontWeight: "500" }}>
                Are you the pickup contact?
              </div>
              <div style={{ fontSize: "12px" }}>
                Is the item being collected from you, or someone else?
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="gender"
                  defaultValue={isPickup}
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="true"
                    onClick={() => {
                      setIsPickup("true");
                      const data = getValues(["sender_email", "sender_name"]);

                      // console.log(data, "dataaaaa");

                      setValue("pickup_email", data[0], {
                        shouldValidate: true,
                        shouldDirty: true,
                      });

                      setValue("pickup_name", data[1], {
                        shouldValidate: true,
                        shouldDirty: true,
                      });

                      setPickup_phone_no(sender_phone_no);
                    }}
                    control={<GreenRadio />}
                    label="Yes"
                  />

                  <FormControlLabel
                    value="false"
                    onClick={() => {
                      setIsPickup("false");

                      // const data = getValues(['sender_email', 'sender_name']);

                      setValue("pickup_email", "", {
                        shouldValidate: true,
                        shouldDirty: true,
                      });

                      setValue("pickup_name", "", {
                        shouldValidate: true,
                        shouldDirty: true,
                      });

                      setPickup_phone_no("");
                    }}
                    control={<GreenRadio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {isPickup === "true" ? null : (
              <>
                <Grid item xs={12}>
                  <div className="txt">Pickup Contact</div>
                  <Controller
                    name="pickup_name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputField
                          label="Pickup's Name"
                          {...field}
                          error={errors.pickup_name}
                          name="pickup_name"
                          id="pickup_name"
                          type="text"
                          labelWidth={150}
                          icon={
                            <FaTextHeight
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                          }
                        />
                        <div className="err">{errors.pickup_name?.message}</div>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="pickup_email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputField
                          label="Pickup's Email(optional)"
                          {...field}
                          error={errors.pickup_email}
                          name="pickup_email"
                          id="pickup_email"
                          type="email"
                          labelWidth={150}
                          icon={
                            <FaTextHeight
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                          }
                        />
                        <div className="err">
                          {errors.pickup_email?.message}
                        </div>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <div className="phoneTopic">Pickup Phone</div>
                  <PhoneNumber
                    value={pickup_phone_no}
                    setValue={setPickup_phone_no}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={8}>
              <div style={{ fontSize: "18px", fontWeight: "500" }}>
                Are you the receiver?
              </div>
              <div style={{ fontSize: "12px" }}>
                Are you the receiver of the item?
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl component="fieldset">
                <RadioGroup
                  defaultValue={isReciever}
                  row
                  aria-label="gender"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="true"
                    onClick={() => {
                      setIsReciever("true");
                      const data = getValues(["sender_email", "sender_name"]);

                      setValue("delivery_email", data[0], {
                        shouldValidate: true,
                        shouldDirty: true,
                      });

                      setValue("delivery_name", data[1], {
                        shouldValidate: true,
                        shouldDirty: true,
                      });

                      setDelivery_phone_no(sender_phone_no);
                    }}
                    control={<GreenRadio />}
                    label="Yes"
                  />

                  <FormControlLabel
                    value="false"
                    onClick={() => {
                      setIsReciever("false");
                      setValue("delivery_email", "", {
                        shouldValidate: true,
                        shouldDirty: true,
                      });

                      setValue("delivery_name", "", {
                        shouldValidate: true,
                        shouldDirty: true,
                      });

                      setDelivery_phone_no("");
                    }}
                    control={<GreenRadio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {isReciever === "true" ? null : (
              <>
                <Grid item xs={12}>
                  <div className="txt">Receiver's Contact</div>

                  <Controller
                    name="delivery_name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputField
                          label="Receiver's Name"
                          {...field}
                          name="delivery_name"
                          id="delivery_name"
                          error={errors.delivery_name}
                          type="text"
                          labelWidth={150}
                          icon={
                            <FaTextHeight
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                          }
                        />
                        <div className="err">
                          {errors.delivery_name?.message}
                        </div>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="delivery_email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputField
                          label="Receiver's Email(optional)"
                          {...field}
                          name="delivery_email"
                          id="delivery_email"
                          error={errors.delivery_email}
                          type="email"
                          labelWidth={150}
                          icon={
                            <FaTextHeight
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                          }
                        />
                        <div className="err">
                          {errors.delivery_email?.message}
                        </div>
                      </FormControl>
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <div className="phoneTopic">Receiver's Phone Number</div>
                  <PhoneNumber
                    value={delivery_phone_no}
                    setValue={setDelivery_phone_no}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={6}>
              <div style={{ fontSize: "18px", fontWeight: "500" }}>
                Who is paying for this delivery?
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset">
                <RadioGroup
                  defaultValue={payer}
                  row
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="sender"
                    onClick={() => {
                      setPayer("sender");
                    }}
                    control={<GreenRadio />}
                    label="Sender"
                  />

                  <FormControlLabel
                    value="receiver"
                    onClick={() => {
                      setPayer("receiver");
                    }}
                    control={<GreenRadio />}
                    label="Reciever"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="txt">Payment option</div>

                <Controller
                  name="mode_of_payment"
                  control={control}
                  render={({ field }) => (
                    <ReactSelect
                      {...field}
                      isSearchable={false}
                      className="react-dropdown"
                      classNamePrefix="dropdown"
                      options={options}
                      placeholder="Select Payment Option"
                      styles={customStyles}
                    />
                  )}
                />

                <div className="err">
                  {errors?.mode_of_payment?.value?.message}
                </div>
              </Grid>
            </Grid>

            <Grid container>
              <div
                style={{
                  paddingTop: "10px",
                  textAlign: "justify",
                  fontSize: "13px",
                }}
              >
                By proceeding I agree to Dillivry{" "}
                <Link
                  onClick={() => {
                    setPolicyShow(true);
                    setpageToShow("terms");
                  }}
                  style={{ color: "var(--main)" }}
                >
                  Terms of Use
                </Link>{" "}
                and acknowledge that I have read the{" "}
                <Link
                  onClick={() => {
                    setPolicyShow(true);
                    setpageToShow("privacy");
                  }}
                  style={{ color: "var(--main)" }}
                >
                  Privacy Policy
                </Link>
                .
              </div>
            </Grid>
            <Grid container>
              <div
                style={{
                  paddingTop: "10px",
                  textAlign: "justify",
                  fontSize: "13px",
                }}
              >
                I also agree that Dillivry or its representative may contact me
                by email, phone or SMS (including by automated means) at the
                email address or number i provide, including for marketing
                purposes.
              </div>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} style={{ marginTop: "20px" }}>
                <Button
                  size="small"
                  variant="contained"
                  type="button"
                  style={{
                    backgroundColor: "#f5f7f6",
                    width: "100%",
                    marginTop: "0px",
                    color: "var(--main)",
                    borderRadius: "5px",
                    padding: "10px",
                    border: "1px solid var(--main)",
                    textTransform: "none",
                  }}
                  onClick={previous}
                >
                  <span className="btnlabel">Previous </span>
                </Button>
              </Grid>
              <Grid item xs={6} style={{ marginTop: "20px" }}>
                {isloading ? (
                  <Button
                    size="small"
                    variant="contained"
                    type="button"
                    disabled
                    style={{
                      backgroundColor: "var(--main)",
                      width: "100%",
                      marginTop: "0px",
                      color: "white",
                      borderRadius: "5px",
                      padding: "10px",
                      textTransform: "none",
                    }}
                  >
                    <PuffLoader
                      color="white"
                      loading={isloading}
                      speedMultiplier={1}
                      size={24}
                    />
                  </Button>
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    style={{
                      backgroundColor: "var(--main)",
                      width: "100%",
                      color: "white",
                      borderRadius: "5px",
                      padding: "10px",
                      textTransform: "none",
                    }}
                  >
                    <span className="btnlabel">Request a delivery</span>
                  </Button>
                )}
              </Grid>
{/* 
                    {
                      console.log(errors, "errors")
                    } */}

            </Grid>
          </Grid>
        )}
      </form>

      <Dialogsummary openPopup={showsummary} setopenPopup={setshowsummary}>
        {previewdata.map((item, index) => {
          return (
            <div key={index}>
              <div className="heading">
                <div className="heading__title">
                  <h1 className="heading__title__tag">Request summary</h1>
                </div>

                <div className="heading__sub__title">
                  <p className="heading__subtitle__tag">
                    Review your request details before submission
                  </p>
                </div>
              </div>

              <div className="summary__content">
                <Grid item xs={4}>
                  <div key={index}>
                    {item.image_files.length === 0 ? (
                      <></>
                    ) : (
                      <div className="forPhoto">
                        <div
                          className="border"
                          style={{
                            backgroundImage: `url(data:image/png;base64,${item.image_files[0]})`,
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                </Grid>

                <div className="summary__content__main">
                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Category</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.category?.charAt(0)?.toUpperCase() +
                          item?.category?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Pickup address</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.pickup_address?.charAt(0)?.toUpperCase() +
                          item?.pickup_address?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Delivery address</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.delivery_address?.charAt(0)?.toUpperCase() +
                          item?.delivery_address
                            ?.replaceAll("_", " ")
                            ?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Item description</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.item_desc?.charAt(0)?.toUpperCase() +
                          item?.item_desc?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Mode of delivery</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.mode_of_delivery?.charAt(0)?.toUpperCase() +
                          item?.mode_of_delivery
                            ?.replaceAll("_", " ")
                            ?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  {hasNumber.test(item?.item_dim_hei) && (
                    <div className="summary_category">
                      <div className="sum_category">
                        <h1 className="sum_cat_main"> Height</h1>
                      </div>

                      <div className="sum__cate__content">
                        <h1 className="sum_cat_main_sub">
                          {item?.item_dim_hei === " "
                            ? "NA"
                            : item?.item_dim_hei?.charAt(0)?.toUpperCase() +
                              item?.item_dim_hei
                                ?.replaceAll("_", " ")
                                ?.slice(1)}
                        </h1>
                      </div>
                    </div>
                  )}

                  {hasNumber.test(item.item_dim_len) && (
                    <div className="summary_category">
                      <div className="sum_category">
                        <h1 className="sum_cat_main">Length</h1>
                      </div>

                      <div className="sum__cate__content">
                        <h1 className="sum_cat_main_sub">
                          {item?.item_dim_len === " "
                            ? "NA"
                            : item?.item_dim_len?.charAt(0)?.toUpperCase() +
                              item?.item_dim_len
                                ?.replaceAll("_", " ")
                                ?.slice(1)}
                        </h1>
                      </div>
                    </div>
                  )}

                  {hasNumber.test(item?.item_dim_wid) && (
                    <div className="summary_category">
                      <div className="sum_category">
                        <h1 className="sum_cat_main"> Width</h1>
                      </div>

                      <div className="sum__cate__content">
                        <h1 className="sum_cat_main_sub">
                          {item?.item_dim_wid === " "
                            ? "NA"
                            : item?.item_dim_wid?.charAt(0)?.toUpperCase() +
                              item?.item_dim_wid
                                ?.replaceAll("_", " ")
                                ?.slice(1)}
                        </h1>
                      </div>
                    </div>
                  )}

                  {hasNumber.test(item?.item_weight) && (
                    <div className="summary_category">
                      <div className="sum_category">
                        <h1 className="sum_cat_main">Weight</h1>
                      </div>

                      <div className="sum__cate__content">
                        <h1 className="sum_cat_main_sub">
                          {item?.item_weight === " "
                            ? "NA"
                            : item?.item_weight?.charAt(0)?.toUpperCase() +
                              item?.item_weight?.replaceAll("_", " ")?.slice(1)}
                        </h1>
                      </div>
                    </div>
                  )}

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Are you the receiver?</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {isReciever === "true" ? "Yes" : "No"}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Receiver’s full name</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.delivery_name}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Receiver’s phone number</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.delivery_phone_no}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">
                        Are you the pickup contact?
                      </h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {isPickup === "true" ? "Yes" : "No"}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Who is paying?</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.payer?.charAt(0)?.toUpperCase() +
                          item?.payer?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="submit__and__edit">
                <div>
                  {" "}
                  <button
                    className="edit-icon-btn"
                    onClick={() => {
                      previous();
                      closemodalandbacktopageone();
                    }}
                  >
                    {" "}
                    <img src={edit} alt="edit-icon" />{" "}
                  </button>
                </div>

                <div>
                  {" "}
                  <button
                    className="submit-btn"
                    onClick={handleSubmit(submitafterPreview)}
                    disabled={isloading}
                  >
                    {" "}
                    {isloading ? "Submiting..." : "Submit"}{" "}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </Dialogsummary>

      <DialogBox
        openPopup={openDialog}
        setopenPopup={setDialog}
        head="State delivery time frame"
      >
        <ButtonCard
          onClick={() => {
            setDelivery_time_frame("Within a week");
            setDelivery_time_frameduplicate("Within a week");
            setOpenDialog(false);
          }}
          text="Within a week"
        />
        <ButtonCard
          onClick={() => {
            setDelivery_time_frame("Within 2 week");
            setDelivery_time_frameduplicate("Within 2 week");
            setOpenDialog(false);
          }}
          text="Within 2 weeks"
        />
        <ButtonCard onClick={handleOpenDate} text="On a specific date" />
        <ButtonCard onClick={setRange} text="Within a specific dates " />
        <ButtonCard
          onClick={() => {
            setDelivery_time_frame("Don't have a date yet");
            setDelivery_time_frameduplicate("Don't have a date yet");
            setOpenDialog(false);
          }}
          text="Don't have a date yet"
        />
      </DialogBox>

      <DialogBox openPopup={openDateRange} setopenPopup={setRange}>
        <DateRanger
          onChange={(item) => setRanges([item.selection])}
          ranges={range}
          minDate={getValues("pickup_date")}
        />
        <ButtonCard
          onClick={() => {
            if (
              `${range[0].startDate.getFullYear()}-${range[0].startDate.getMonth()}-${range[0].startDate.getDate()}` ===
              `${range[0].endDate.getFullYear()}-${range[0].endDate.getMonth()}-${range[0].endDate.getDate()}`
            ) {
              setDelivery_time_frame(
                `${range[0].startDate.getDate()}/${(
                  range[0].startDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}/${range[0].startDate.getFullYear()}`
              );

              setDelivery_time_frameduplicate(
                `${range[0].startDate.getFullYear()}-${(
                  range[0].startDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${range[0].startDate.getDate()}`
              );
              setRange();
              setOpenDialog(false);
            } else {
              if (range[0].endDate === null) {
                setDelivery_time_frame("");
                setDelivery_time_frameduplicate("");
                setOpenDialog(false);
              } else {
                setDelivery_time_frame(
                  `${range[0].startDate.getDate()}/${(
                    range[0].startDate.getMonth() + 1
                  )
                    .toString()
                    .padStart(
                      2,
                      "0"
                    )}/${range[0].startDate.getFullYear()} -  ${range[0].endDate.getDate()}/${(
                    range[0].endDate.getMonth() + 1
                  )
                    .toString()
                    .padStart(2, "0")}/${range[0].endDate.getFullYear()}`
                );

                setDelivery_time_frameduplicate(
                  `${range[0].startDate.getFullYear()}-${
                    range[0].startDate.getMonth() + 1
                  }-${range[0].startDate.getDate()} - ${range[0].endDate.getFullYear()}-${
                    range[0].endDate.getMonth() + 1
                  }-${range[0].endDate.getDate()}`
                );
                setRange();
                setOpenDialog(false);
              }
            }
          }}
          text="Ok"
        />
      </DialogBox>
      <DialogBox openPopup={openDate} setopenPopup={handleOpenDate}>
        <Calender
          minDate={getValues("pickup_date")}
          onChange={(item) => {
            setDelivery_time_frame(
              `${item.getDate()}/${(item.getMonth() + 1)
                .toString()
                .padStart(2, "0")}/${item.getFullYear()}`
            );
            setDelivery_time_frameduplicate(
              `${item.getFullYear()}-${item.getMonth() + 1}-${item.getDate()}`
            );
            setOpenDate(false);
            setOpenDialog(false);
          }}
        />
      </DialogBox>

      <Popup trigger={ShowLogin} setTrigger={setShowLogin}>
        <LoginComponent onFinish={() => setShowLogin(false)} />
      </Popup>

      <PrivacyDialog
        open={policyShow}
        onClick={() => setPolicyShow(false)}
        pageToShow={pageToShow}
        title={
          pageToShow === "terms" ? "Terms and condition" : "Privacy policy"
        }
      ></PrivacyDialog>
    </>
  );
}

export default SendGeneral;
