import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function CustomizedSnackbars({ setHandleOpen, open }) {
    const classes = useStyles();
    // const [open, setOpen] = React.useState(false);

    // const handleClick = () => {
    //     setOpen(true);
    // };

    // const handleClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }

    //     setOpen(false);
    // };

    return (
        <div className={classes.root}>
            <Snackbar open={open} autoHideDuration={1000} onClose={() => setHandleOpen(false)}>
                <Alert onClose={() => setHandleOpen(false)} severity="success">
                    This is a success message!
                </Alert>
            </Snackbar>
        </div>
    )
}
