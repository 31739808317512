import React, { lazy, Suspense } from 'react'

import "./HomeV2.css"
import "./Home.css"
import '../../pages/SendQuote/ViewQuote/AcceptQuote.css'
import '../../pages/Fulfiller/Dashboard/Dashboard.css'
import '../../components/Nav/nav.css'
import '../../components/Quote/quote.css'
import '../../pages/SearchDilivry2/Table.css'
import '../../pages/API_Page/Apipage.css'
import '../../pages/SendQuote/ViewQuote/Message.css'
import '../../pages/Fulfiller/Dashboard/ViewDeli.css'
import '../../pages/Fulfiller/Fulfiller.css'
import '../../pages/SearchDeliveries/NewSearch.css'
import '../../pages/SearchDilivry2/newdilivry.css'
import '../../pages/Sender/SenderDashboard/Pages/Quote/EditnewQuote.css'
import '../../pages/Fulfiller/Dashboard/Fulfiller_account.css'
import '../../pages/SendQuote/SendQuote.css';
import '../../pages/Fulfiller/Dashboard/ViewDelivry2.css'
import '../../pages/Fulfiller/Dashboard/ViewFulfiller.css'
import '../../pages/Fulfiller/Dashboard/ViewFulfiler2.css'
import '../../pages/Appdownload/appdownload.css'
import '../../pages/Sender/SenderDashboard/Pages/Quote/EditnewQuote.css'
import '../../pages/Sender/SenderDashboard/Pages/Quote/Quotes.css'
import '../../pages/Sender/SenderDashboard/Pages/Quote/QuoteDrillDown.css'
import '../../pages/Sender/SenderDashboard/Pages/Dashboard/SenderDashboard.css'
import '../../pages/Login/Login.css'
import '../../pages/Registration/Registration.css'
import '../../pages/Sender/SenderDashboard/Checkout.css'
import '../../pages/Sender/SenderDashboard/QuoteAcknowledgmentcard.css'
import '../../pages/Sender/SenderDashboard/AcknowledgeMobileCard.css'

import TopBarHome from '../../components/TopBar/TopBarHome'
import GetStartedHero from './HomeComponents/GetStartedHero'
import EarnExtra from './HomeComponents/EarnExtra'
import MoonLoader from 'react-spinners/MoonLoader';
import CookieConsent, {Cookies,resetCookieConsentValue } from "react-cookie-consent";
import { Link } from "react-router-dom";
// import ScrollAnimation from 'react-animate-on-scroll';
// import "animate.css/animate.min.css";


const Footer = lazy(() => import("../../components/Footer/Footer"));
const CarouselHero = lazy(() => import("./HomeComponents/CarouselHero"));
const DownloadHero = lazy(() => import("./HomeComponents/DownloadHero"));
const ProviderHero = lazy(() => import("./HomeComponents/ProviderHero"));
const SenderHero = lazy(() => import("./HomeComponents/SenderHero"));
// const StatHero = lazy(() => import("./HomeComponents/StatHero"));
const CustomerHero = lazy(() => import("./HomeComponents/CustomerHero"));

export default function HomeV2() {
  function deleteAllCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}
  return (
    
    <div className="homeV2">
      <TopBarHome />
      <GetStartedHero />

      <EarnExtra />
      <Suspense fallback={<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <MoonLoader color='var(--main)' loading={true} speedMultiplier={1} size={24} /> </div>}>
        <SenderHero />
      </Suspense>
      <Suspense fallback={<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <MoonLoader color='var(--main)' loading={true} speedMultiplier={1} size={24} /> </div>}>
        <ProviderHero bg="var(--lighest)" title="Logistics Providers" />
        {/* <StatHero/> */}
      </Suspense>
      <Suspense fallback={<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <MoonLoader color='var(--main)' loading={true} speedMultiplier={1} size={24} /> </div>}>
        <CustomerHero />
      </Suspense>
      <Suspense fallback={<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <MoonLoader color='var(--main)' loading={true} speedMultiplier={1} size={24} /> </div>}>
        <DownloadHero color="white" bg='linear-gradient(88.15deg, #32A071 -13.41%, #15CF74 67.33%, #2DA771 114.58%)' />
      </Suspense>
      <Suspense fallback={<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <MoonLoader color='var(--main)' loading={true} speedMultiplier={1} size={24} /> </div>}>
        <CarouselHero />
      </Suspense>
      <Suspense fallback={<div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <MoonLoader color='var(--main)' loading={true} speedMultiplier={1} size={24} /> </div>}>
        <Footer />
      </Suspense>
      <CookieConsent
      buttonWrapperClasses="cookieButtonCon"
      onDecline={()=>{deleteAllCookies();}}
      location="bottom"
      buttonText="I understand "
      declineButtonText="Decline"
      cookieName="Dillivry"
      containerClasses="cookieCon"
      enableDeclineButton="true"
      declineButtonStyle={{ border:"1px solid white",color: "#ffff", background: "#2B373B",fontSize: "15px" , textAlign:"center ", marginRight:"0px"  , borderRadius:"4px", padding:"8px 20px"}}
      
      style={{ background: "#2B373B", color:"#ffff" }}
      buttonStyle={{ border:"1px solid var(--main)" ,color: "var(--main)", fontSize: "15px" , backgroundColor:"#2B373B" , textAlign:"center ", marginRight:"20px"  , borderRadius:"4px", padding:"8px 20px"}}
      expires={365}
    >
     
     <Link to="/" style={{cursor:"pointer", color: "var(--main"}}>  Dillivry</Link>  uses cookies to help give you the best experience on our site. Your privacy is also important to us. By continuing, you consent to our use of cookies.   <Link style={{cursor:"pointer", color: "var(--main"}} to="/CookiePolicy"> Learn more</Link>  {" "} 
      
    </CookieConsent>

    </div>
  )
}
