import React, { useState, useEffect, useContext } from "react";
import { usePaystackPayment } from "react-paystack";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SecureLS from "secure-ls";
import { ConnContext } from "../context/ConnContext";
import DialogOutClick from './DialogOutClick';
import LoginCompV2 from './LoginCompV2';
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

const Paystack_sender_single_payment = ({
  amount,
  email,
  delivery_id,
  delivery_location,
  fulfiller_id,
  amount_paid,
  closePopup,
  promoCode,
  quote_amount,
  discount,
  sender
}) => {
  const { visitingCountryCode } = useContext(ConnContext);
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });


  // const { visitingCountryCode } = useContext(ConnContext);
  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

    const [country,setCountry]=useState(countryvalue);

    useEffect(() => {
      
        setCountry(Cookies.get('i18next'));
      },[Cookies.get('i18next')])

  const [TransDate, setTransDate] = useState('')

  const [waiting, setwaiting] = useState(false);
  const [ShowLogin, setShowLogin] = useState(false)

  const refresh = () =>{
        window.location.reload();
      }

    useEffect(() => {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      setTransDate(date);
    }, [])

    const endpoint = process.env.REACT_APP_API
    
    const config = {
      reference: (new Date()).getTime(),
      email: email,
      amount: amount,
      channels: ['card',  'ussd'],
      publicKey: process.env.REACT_APP_ENVIRONMENT === "PROD" || process.env.REACT_APP_ENVIRONMENT === "TEST" ? process.env.REACT_APP_PAYSTACK_KEY : process.env.REACT_APP_PAYSTACK_KEY_DEMO, 
  };

  const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });

  const onSuccess = (reference) => {
    const data = promoCode !== "" ? 
    {
      action_type: "make_payment",
      delivery_id: delivery_id,
      fulfiller_id: fulfiller_id,
      amount_paid: `${amount_paid}`,
      payment_date: TransDate,
      quoted_amount:   quote_amount,
      promo_discount: discount,
      payment_status: "success",
      StatusCode: 200,
      reference_id: reference.reference,
      message: reference.message,
      transaction: reference.transaction,
      vcc: visitingCountryCode,
      payment_gateway: "paystack",
      promocode: promoCode
    }
    :
    {
      action_type: "make_payment",
      delivery_id: delivery_id,
      fulfiller_id: fulfiller_id,
      amount_paid: `${amount_paid}`,
      payment_date: TransDate,
      quoted_amount:   quote_amount,
      payment_status: "success",
      StatusCode: 200,
      reference_id: reference.reference,
      message: reference.message,
      transaction: reference.transaction,
      vcc: visitingCountryCode,
      payment_gateway: "paystack",
    }

    setwaiting(true)
    
    // if (reference.status === "success") {
      authAxios
        .post(
          `/m-dillivry-quote-manager?delivery_location=${delivery_location}`, data,
          { timeout: 240000 }
        )
        .then((response) => {
          Swal.fire({
            icon: "success",
            text: "Payment made successfully",
            confirmButtonColor: "var(--main)",
          })
          .then(()=>{
            refresh();
          })
          ls.remove("item");
          ls.remove("delivery_id");
          
        })
        .catch((err) => {
          console.log(err, 'errrerer')
          if (err?.response?.status === 403) {
            Swal.fire({
              icon: "warning",
              title: "Session!",
              text: "Session expired login again!",
              allowOutsideClick: false,
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                ls.removeAll();
                history.push(`/login`);
              }
            });
          }
        });
    // }
  };

  const history = useHistory();
  // you can call this function anything
  const onClose = () => {
    
  };

  const initializePayment = usePaystackPayment(config);

  const tokenCheck = () =>{
    const decoded = jwt_decode(
      ls.get("_utk")
    );
    const expired = decoded.exp;
    if(expired <= Math.floor(Date.now() / 1000)){
        setShowLogin(true)
      }
    else{
      closePopup(); 
      initializePayment(onSuccess, onClose);
      }
  }

  return (
    <>
     
      <div className="paystack_btn" onClick={tokenCheck}>
        Proceed
     </div>
    
      
    <DialogOutClick
        open={ShowLogin}
        handleClose ={()=>{setShowLogin(false);  }}
        >
        <LoginCompV2 onFinish={() => {setShowLogin(false); closePopup(); initializePayment(onSuccess, onClose);}} />
      </DialogOutClick>
    </>
  );
};

export default Paystack_sender_single_payment;
