import  { useState } from 'react'
import HomeHelp from '../../../components/HomeHelp'
import OTP from '../../../components/Reg_components/OTP'
import RegWelcome from '../../../components/Reg_components/RegWelcome'
import TitleText from '../../../components/Reg_components/TitleText'
import TopNavigation from '../../../components/Reg_components/TopNavigation'
import RegFormCompany from '../RegFormCompany'

function Corp_Ful_Reg({compDetails, countryCode,handleGoBack}) {
    const [registeredStatus, setRegisteredStatus] = useState('admin_registered')
  const [showHelp, setshowHelp] = useState(false)
    return (
        <div className="regContV2">
            {
                registeredStatus === 'admin_registered' ?
                <>
                    {/* registration form here */}
                    <RegFormCompany compDetails={compDetails}  countryCode={countryCode} handleGoBack={handleGoBack}
                        handleBtnClick={()=>{
                            setRegisteredStatus('otp_confirm_reg')
                        }}
                    />
                </>
                :
                registeredStatus === 'otp_confirm_reg' ?
                <>
                    {/* OTP confirmation page start here */}
                    <TopNavigation onBackClick={handleGoBack} help='Help' openHelp={()=>setshowHelp(!showHelp)} />
                    <TitleText head="Security check" subHead="As a security measure, a one-off code was sent to you via Email and SMS." subHead2="Please enter the one-off code below." margin_base="70px"  />
                    <h3 className="chartweb" style={{marginBottom:'20px'}}>Security check</h3> 
                    <h4 className="chartweb" style={{marginBottom:'20px'}}>As a security measure, a one-off code was sent to you via Email and SMS.</h4>
                    <h5 className="chartweb" style={{marginBottom:'40px'}}>Please enter the one-off code below.</h5>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', width:'100%'}}>
                        <OTP 
                            confirmed={()=>setRegisteredStatus('otp_confirmed')}
                            num={4}
                        />
                    </div>
                </>
                :
                registeredStatus === 'otp_confirmed' ?
                <>
                    <RegWelcome/>
                </>
                :
                null
            }
            {
                showHelp ?
                <HomeHelp showDialog={showHelp} onClose={() => setshowHelp(false)} />
                :
                null
            }
        </div>
    )
}

export default Corp_Ful_Reg
