import { useEffect, useState, useRef, useContext } from "react";
import Carousel from "react-material-ui-carousel";
import GoogleMapWidget from "../../../components/Map/GoogleMapWidget";

import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import to from "../../../assets/to.svg";
import From from "../../../assets/From.svg";
import itemdescr_ from "../../../assets/itemdescr_.svg";
import Distance from "../../../assets/Distance.svg";
import Duration from "../../../assets/Duration.svg";
import Listed from "../../../assets/Listed.svg";
import Fastdelivery from "../../../assets/Fastdelivery.svg";
import Footer from "../../../components/Footer/Footer";
import DialogOutClick from "../../../components/DialogOutClick";
import QuoteSignInForm from "./QuoteSignInForm";
import HomeHelp from "../../../components/HomeHelp";
import Nav from "../../../components/Nav/Nav";
import "../../../pages/SearchDilivry2/Table.css";
import "./ViewDeli.css";
import "./ViewDelivery.css"
import "./ViewDelivry2.css"
import "./ViewFulfiller.css"
import moment from "moment";
import {
  AiFillCloseCircle,
  AiOutlineUserAdd,
  AiTwotoneStar,
} from "react-icons/ai";
import { IconContext } from "react-icons";
import { useLocation, useParams } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import { AuthContext } from "../../../context/AuthContext";
import SecureLS from "secure-ls";
import jwt_decode from "jwt-decode";
import ReactTooltip from "react-tooltip";
import dimensions from "../../../assets/dimensions.png";
import weighingmachine from "../../../assets/weighingmachine.png";
import { SearchContext } from "../../../context/SearchProvider/search.provider";
import erros from "../../../assets/erros.png";
import CancelIcon from '../../../assets/CancelIcon.png'
import { BiNotificationOff } from "react-icons/bi";
import { PuffLoader } from "react-spinners";
import { Button } from "@material-ui/core";
import { ConnContext } from "../../../context/ConnContext";
import TopBar from "../../../components/TopBar/TopBar";
import { DashboardContext } from "../../../context/Dashboard/DashboardProvider/dashboard.provider";
import GlobalizationLanguage, {GlobalizationLanguagewithCOntryCode} from "../../../components/GlobalizationLanguage";
import Cookies from 'js-cookie';
import Currency from "../../../utils/Currency";


export default function ViewDeliverNew() {
  const Dashboard = GlobalizationLanguage("/Dashboard")
  var timeout = GlobalizationLanguage("/timeout")
  var deliver_with_us = GlobalizationLanguage("/create-account")

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  var login=GlobalizationLanguage("/login")
  var open_deliveries=GlobalizationLanguage("/open-deliveries")

  let { delivery_id } = useParams();

  const [showCanQuote, setshowCanQuote] = useState(false);
  const [showValidity, setshowValidity] = useState(false);
  const [showAMsender, setshowAMsender] = useState(false);

  const { searches, updateSearch, addTowatch, removeFromWatch } =
    useContext(SearchContext);

  const history = useHistory();
  const [showpopup, setShowpopup] = useState(false);
  const [showpopup2, setShowpopup2] = useState(false);

  const [showAccountStatus, setshowAccountStatus] = useState(false);
  const { visitingCountryCode } = useContext(ConnContext);
  const [componenttoshow, setComponenttoshow] = useState(false);
  const [componenttoshow2, setComponenttoshow2] = useState(false);
  const [submiting, setsubmiting] = useState(false);
  const [showHelp, setshowHelp] = useState(false);
  const [showSubmitQuote, setShowSubmitQuote] = useState(false);
  const [watched_items, setwatched_items] = useState([]);
  const [allselectedids, setllselectedids] = useState([]);
  const location = useLocation();
  const auth = useContext(AuthContext);
  const [newState, setnewState] = useState("");

  const {dashboards} = useContext(DashboardContext);

  var countryvalue =
  Cookies.get("i18next") || visitingCountryCode.toLowerCase();
const [country, setCountry] = useState(countryvalue);

useEffect(() => {
  setCountry(Cookies.get("i18next"));
}, [Cookies.get("i18next")]);

  const childRef = useRef();
  const triggerNavRefresh = () => childRef.current.refreshMe();

  const Authorization = ls.get("_nodi") === "" ? "" : ls.get("_nodi");

  const CheckWathlistValidity = (timeframe) => {
    // console.log(timeframe, "timeframe")
    // console.log(timeframe?.delivery_time_frame, "length of timeframe");

    // Todays date
    var today = new Date();
    // console.log(today, "today");
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    // Add Zero if it number is between 0-9
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    var today = yyyy + "" + mm + "" + dd;

    // console.log(today, 'todayyyyy')

    // ----------------------------------------------------//
    // Day of event

    if (timeframe?.delivery_time_frame.length === 23) {
      var endDay = parseInt(timeframe?.delivery_time_frame.substring(21, 23)); // day 15

      var endMonth = parseInt(timeframe?.delivery_time_frame.substring(18, 20)); // month

      var endYear = timeframe?.delivery_time_frame.substring(13, 17); // year
    } else if (timeframe?.delivery_time_frame.length <= 12) {
      var endDay = parseInt(timeframe?.delivery_time_frame.substring(8, 10)); // day 15

      var endMonth = parseInt(timeframe?.delivery_time_frame.substring(5, 7)); // month

      var endYear = timeframe?.delivery_time_frame.substring(0, 4); // year
    } else {
      var today_ = new Date();
      var endDay = today_.getDate(); // day 15

      // console.log(endDay, "end-day");
      var endMonth = today_.getMonth() + 1; // month

      var endYear = today_.getFullYear();
    }

    // Add Zero if it number is between 0-9
    if (endDay < 10) {
      endDay = "0" + endDay;
    }
    if (endMonth < 10) {
      endMonth = "0" + endMonth;
    }

    // eventDay - date of the event
    var eventDay = endYear + "" + endMonth + "" + endDay;
    // ----------------------------------------------------//

    // console.log(eventDay, "eventDay");
    // ----------------------------------------------------//
    // check if eventDay has been or not
    if (eventDay >= today) {
      // console.log('Can Submit quote');
      // return true;
      // setshowValidity(!showValidity)
      // console.log()
      // history.push(`/open-delivery/` + timeframe?.delivery_id);
       WatchNow(timeframe.delivery_id);
      // console.log("blockeddd")
    } else {
      // return false;
      // console.log('Can not submit quote');
      Swal.fire({
        icon: "error",
        text: `Delivery timeframe has passed!
        You can no longer watch this item. `,
        showConfirmButton: "true",
        confirmButtonColor: "#8E8E8E",
      });
      // .then((result) => {
      //   if (result.value) {
      //     window.location.reload();
      //   }
      // });
    }
  };

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: Authorization,
    },
  });

  const [viewData, setViewData] = useState([]);
  const [enqmsg, setenqmsg] = useState([]);

  useEffect(() => {
     <TopBar/>
  }, [setComponenttoshow]);

  const schema = yup.object().shape({
    QAmount: yup
      .string()
      .required("Amount required")
      .matches(/^[0-9]*$/, "Amount has to be number"),
  });

  const schemamobile = yup.object().shape({
    QAmountmobile: yup
      .string()
      .required("Amount required")
      .matches(/^[0-9]*$/, "Amount has to be number"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schemamobile),
  });

  const CheckTimeframeValidity = (timeframe) => {
    // Todays date
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    // Add Zero if it number is between 0-9
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    var today = yyyy + "" + mm + "" + dd;


    // ----------------------------------------------------//
    // Day of event

    if (timeframe.length === 23) {
      var endDay = parseInt(timeframe.substring(21, 23)); // day 15

      var endMonth = parseInt(timeframe.substring(18, 20)); // month

      var endYear = timeframe.substring(13, 17); // year
    } else if (timeframe.length <= 12) {
      var endDay = parseInt(timeframe.substring(8, 10)); // day 15

      var endMonth = parseInt(timeframe.substring(5, 7)); // month

      var endYear = timeframe.substring(0, 4); // year
    } else {
      var today_ = new Date();
      var endDay = today_.getDate(); // day 15

      // console.log(endDay, "end-day");
      var endMonth = today_.getMonth() + 1; // month

      var endYear = today_.getFullYear();
    }

    // Add Zero if it number is between 0-9
    if (endDay < 10) {
      endDay = "0" + endDay;
    }
    if (endMonth < 10) {
      endMonth = "0" + endMonth;
    }

    // eventDay - date of the event
    var eventDay = endYear + "" + endMonth + "" + endDay;
    // ----------------------------------------------------//

    // ----------------------------------------------------//
    // check if eventDay has been or not
    if (eventDay >= today) {
      // console.log('Can Submit quote');
      return true;
    } else {
      return false;
      // console.log('Can not submit quote');
    }
  };




  const CheckEnquiryValidity = (timeframe) => {
  
    // Todays date
    var today = new Date();
    console.log(today, "today");
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
  
    // Add Zero if it number is between 0-9
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
  
    var today = yyyy + "" + mm + "" + dd;
  
    // console.log(today, 'todayyyyy')
  
    // ----------------------------------------------------//
    // Day of event
  
    if (timeframe?.length === 23) {
      var endDay = parseInt(timeframe?.substring(21, 23)); // day 15
  
      var endMonth = parseInt(timeframe?.substring(18, 20)); // month
  
      var endYear = timeframe?.substring(13, 17); // year
    } else if (timeframe?.length <= 12) {
      var endDay = parseInt(timeframe?.substring(8, 10)); // day 15
  
      var endMonth = parseInt(timeframe?.substring(5, 7)); // month
  
      var endYear = timeframe?.substring(0, 4); // year
    } else {
      var today_ = new Date();
      var endDay = today_.getDate(); // day 15
  
      // console.log(endDay, "end-day");
      var endMonth = today_.getMonth() + 1; // month
  
      var endYear = today_.getFullYear();
    }
  
    // Add Zero if it number is between 0-9
    if (endDay < 10) {
      endDay = "0" + endDay;
    }
    if (endMonth < 10) {
      endMonth = "0" + endMonth;
    }
  
    // eventDay - date of the event
    var eventDay = endYear + "" + endMonth + "" + endDay;
    // ----------------------------------------------------//
  
    // ----------------------------------------------------//
    // check if eventDay has been or not
    if (eventDay >= today) {
      if (ls.get("_utk") !== "") {
        // check if login as sender
        setshowAMsender(true);
      } else {
        setShowpopup(!showpopup);
        setShowpopup2(!showpopup2);
        setshow("hide");
        if (ls.get("_nodi") === "") {
          setComponenttoshow(true);
          setComponenttoshow2(false);
        } else if (
          ls.get("aCs_") !== "active"
        ) {
          setshowAccountStatus(
            !showAccountStatus
          );
          setComponenttoshow(false);
          setComponenttoshow2(false);
          setShowpopup2(false);
          setShowpopup(false);
        } else {
          const decoded = jwt_decode(
            ls.get("_nodi")
          );
          const expired = decoded.exp;
  
          if (
            expired <
            Date.now() / 1000
          ) {
            Swal.fire({
              icon: "warning",
              title: "Session!",
              text: "Session expired login again!",
              allowOutsideClick: false,
              confirmButtonColor:
                "var(--main)",
            }).then((result) => {
              if (result.value) {
                auth.logout();
                setShowpopup(true);
                setComponenttoshow(true);
              }
            });
          } else {
            setShowpopup(!showpopup);
            setShowpopup2(!showpopup2);
            setComponenttoshow(false);
            setComponenttoshow2(true);
          }
  
          // if (ls.get("_nodi") === "") {
          //   setComponenttoshow2(false);
          // }
        }
      }
    } else {
      // return false;
      // console.log('Can not submit quote');
      Swal.fire({
        icon: "error",
        text: `Delivery timeframe has passed!
        You can no longer make an enquiry for this job. `,
        showConfirmButton: "true",
        confirmButtonColor: "#8E8E8E",
      });
      // .then((result) => {
      //   if (result.value) {
      //     window.location.reload();
      //   }
      // });
    }
  };
  




  const check_mode = () => {
    if (
      (ls.get("means_of_delivery").includes("Car") &&
        viewData[0].mode_of_delivery === "vehicle") ||
      (ls.get("means_of_delivery").includes("car") &&
        viewData[0].mode_of_delivery === "vehicle") ||
      (ls.get("means_of_delivery").includes("Car") &&
        viewData[0].mode_of_delivery === "Car") ||
      (ls.get("means_of_delivery").includes("Car") &&
        viewData[0].mode_of_delivery === "car") ||
      (ls.get("means_of_delivery").includes("car") &&
        viewData[0].mode_of_delivery === "Car") ||
      (ls.get("means_of_delivery").includes("car") &&
        viewData[0].mode_of_delivery === "car") ||
      (ls.get("means_of_delivery").includes("Bike") &&
        viewData[0].mode_of_delivery === "Bike") ||
      (ls.get("means_of_delivery").includes("Bike") &&
        viewData[0].mode_of_delivery === "bike") ||
      (ls.get("means_of_delivery").includes("bike") &&
        viewData[0].mode_of_delivery === "Bike") ||
      (ls.get("means_of_delivery").includes("bike") &&
        viewData[0].mode_of_delivery === "bike") ||
      // (ls.get("means_of_delivery").includes("Tricycle") &&
      //   viewData[0].mode_of_delivery === "Tricycle") ||
      // (ls.get("means_of_delivery").includes("Tricycle") &&
      //   viewData[0].mode_of_delivery === "tricycle") ||
      // (ls.get("means_of_delivery").includes("tricycle") &&
      //   viewData[0].mode_of_delivery === "Tricycle") ||
      // (ls.get("means_of_delivery").includes("tricycle") &&
      //   viewData[0].mode_of_delivery === "tricycle") ||
      (ls.get("means_of_delivery").includes("Van") &&
        viewData[0].mode_of_delivery === "van") ||
      (ls.get("means_of_delivery").includes("Van") &&
        viewData[0].mode_of_delivery === "Van") ||
      (ls.get("means_of_delivery").includes("Van") &&
        viewData[0].mode_of_delivery === "moving_home") ||
      (ls.get("means_of_delivery").includes("Van") &&
        viewData[0].mode_of_delivery === "haulage") ||
      (ls.get("means_of_delivery").includes("van") &&
        viewData[0].mode_of_delivery === "van") ||
      (ls.get("means_of_delivery").includes("van") &&
        viewData[0].mode_of_delivery === "Van") ||
      (ls.get("means_of_delivery").includes("van") &&
        viewData[0].mode_of_delivery === "moving_home") ||
      (ls.get("means_of_delivery").includes("van") &&
        viewData[0].mode_of_delivery === "haulage") ||
      (ls.get("means_of_delivery").includes("Truck") &&
        viewData[0].mode_of_delivery === "truck") ||
      (ls.get("means_of_delivery").includes("Truck") &&
        viewData[0].mode_of_delivery === "Truck") ||
      (ls.get("means_of_delivery").includes("Truck") &&
        viewData[0].mode_of_delivery === "moving_home") ||
      (ls.get("means_of_delivery").includes("Truck") &&
        viewData[0].mode_of_delivery === "haulage") ||
      (ls.get("means_of_delivery").includes("truck") &&
        viewData[0].mode_of_delivery === "truck") ||
      (ls.get("means_of_delivery").includes("truck") &&
        viewData[0].mode_of_delivery === "Truck") ||
      (ls.get("means_of_delivery").includes("truck") &&
        viewData[0].mode_of_delivery === "moving_home") ||
      (ls.get("means_of_delivery").includes("truck") &&
        viewData[0].mode_of_delivery === "haulage")
    ) {
      return true;
    }
    return false;
  };

  // const quoteNow = (formData)=>{


  //   if( viewData[0]?.mode_of_payment === "pay_by_cash"){
  //     if((+ls.get("btwl")) < (+formData.QAmount.replaceAll(",", "")))
  //     console.log("hello")

  //   }
  //   console.log(viewData[0]?.mode_of_payment)
  //   console.log(ls.get("btwl"))
  //   console.log(+formData.QAmount.replaceAll(",", ""))

  // }

  const quoteNow = (formData) => {


         
    if (formData.msg === "" || formData.msg === undefined) {
      if (
        ls.get("_nodi") !== "" &&
        ls.get("aCs_") === "active" &&
        check_mode() && CheckTimeframeValidity(viewData[0]?.delivery_time_frame)
      ) {
        setsubmiting(true);
        authAxios
          .post(
            `m-dillivry-bid-manager?delivery_location=${Cookies.get("i18next")}`,
            {
              action_type: "quote",
              delivery_id: viewData[0].delivery_id,
              quote_amount: formData.QAmount.replaceAll(",", ""),
             
            },
            { timeout: 240000 }
          )
          .then((response) => {
            console.log(response,"rxx")
            setShowSubmitQuote(false);
            if (response.status === 200) {
              Swal.fire({
                icon: "success",
                text: "Quote placed successfully!",
                // timer: 2500,
                showConfirmButton: true,
                confirmButtonColor: "var(--main)",
              }).then(() => {
                reset({
                  Qamount: " "
                });
                window.location.reload()
              });
              reset({});
              setsubmiting(false);
            }
            setsubmiting(false);
          })
          .catch((err) => {
            console.log(err.response,"sxx")
            if (err.code === "ECONNABORTED") {
              history.push(timeout);
            }
            setShowSubmitQuote(false);

            if (err?.response?.status === 403) {
              Swal.fire({
                icon: "warning",
                text: "Session expired, login again!",
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  auth.logout();
                  history.push(login);
                }
              });
            }
            else if(err?.response?.status === 400){
              if( err?.response?.data?.message === "You can not bid from the selected conutry, please register for the  specifcied country"){
                Swal.fire({
                  // icon: "warning",
                  title:" Oops forbidden!",
                  text: `${err?.response?.data?.message}`,
                  // text: "oops! Forbidden, you can only bid for item in the country where you registered. To bid for this item, you need to register as a provider in this country.",
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  // confirmButtonText :"Process",
                  // button: "Process",
                  customClass: {
                    title:"redBg"
                  },
                  
                  // timer: 2500,
                } )
                

              }
              else if( err?.response?.data?.message === "You are owing dillivry please fund your or accept instant payment method to balance your account"){
                Swal.fire({
                  title:" Oops forbidden!",
                  text:`${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  confirmButtonText :"Process",
                  customClass: {
                    title:"redBg"
                  },
                  
                })
                .then(()=>{
                  history.push(GlobalizationLanguagewithCOntryCode("/corpwallet", country,visitingCountryCode))
          
                })
                

              }
              else if( err?.response?.data?.message.replace(/[0-9]/g, '') === "You have  item need to be completed before you can bid again"){
                Swal.fire({
                  title:" Oops forbidden!",
                  text:`${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  confirmButtonText :"Process",
                  customClass: {
                    title:"redBg"
                  },
                  

                })
                .then(()=>{
                  history.push(GlobalizationLanguagewithCOntryCode("/MyDillivry", country,visitingCountryCode))

                })
              }
              else{
                Swal.fire({
                  title:" Oops forbidden!",
                  text: `${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  // confirmButtonText :"Process",
                  customClass: {
                    title:"redBg"
                  },
                  

                })
                
                  
              } 
              setsubmiting(false);


            }
            
            else {
              Swal.fire({
                icon: "warning",
                text: "An error occured, be sure you are connected to internet",
                timer: 2500,
                showConfirmButton: false,
              });
              setsubmiting(false);
            }
          });
      } else if (
        ls.get("_nodi") !== "" &&
        ls.get("aCs_") === "active" &&
        !check_mode()
      ) {
        setshowCanQuote(true);
      }
      else if (
        ls.get("_nodi") !== "" &&
        ls.get("aCs_") === "active" &&
       !CheckTimeframeValidity(viewData[0]?.delivery_time_frame)
      ) {
        setshowValidity(true);
      }
    } else {
      if (
        ls.get("_nodi") !== "" &&
        ls.get("aCs_") === "active" &&
     check_mode() && CheckTimeframeValidity(viewData[0]?.delivery_time_frame)
      ) {
        setsubmiting(true);
        authAxios
          .post(
            `m-dillivry-bid-manager?delivery_location=${country}`,
            {
              action_type: "quote",
              delivery_id: viewData[0].delivery_id,
              quote_amount: formData.QAmount.replaceAll(",", ""),
              msg: formData.msg,
              vcc: country,
            },
            { timeout: 240000 }
          )
          .then((response) => {
            setShowSubmitQuote(false);
            setsubmiting(false);
            if (response.status === 200) {
              Swal.fire({
                icon: "success",
                text: "Quote placed successfully!",
                // timer: 2500,
                showConfirmButton: true,
                confirmButtonColor: "var(--main)",
              }).then(() => {
                reset({
                  Qamount: " "
                });
                window.location.reload()
              });
              reset({});
              setsubmiting(false);
            }
          })
          .catch((err) => {
            setShowSubmitQuote(false);
            if (err.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push(timeout);
            }
            if (err?.response?.status === 403) {
              Swal.fire({
                icon: "warning",
                text: "Session expired, login again!",
                //  timer: 2500,
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  auth.logout();
                  history.push(login);
                }
                setsubmiting(false);
              });
            }
            else if(err?.response?.status === 400){
              if( err?.response?.data?.message === "You can not bid from the selected conutry, please register for the  specifcied country"){
                Swal.fire({
                  // icon: "warning",
                  title:" Oops forbidden!",
                  text: `${err?.response?.data?.message}`,
                  // text: "oops! Forbidden, you can only bid for item in the country where you registered. To bid for this item, you need to register as a provider in this country.",
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  // confirmButtonText :"Process",
                  // button: "Process",
                  customClass: {
                    title:"redBg"
                  },
                  
                  // timer: 2500,
                } ).then(()=>{
                  reset({
                    Qamount: " "
                  });
                  reset({
                 
                  });
                })
                reset({
                 
                });

              }
              else if( err?.response?.data?.message === "You are owing dillivry please fund your or accept instant payment method to balance your account"){
                Swal.fire({
                  title:" Oops forbidden!",
                  text:`${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  confirmButtonText :"Process",
                  customClass: {
                    title:"redBg"
                  },
                  
                })
                .then(()=>{
                  history.push(GlobalizationLanguagewithCOntryCode("/corpwallet", country,visitingCountryCode))
          
                })
                

              }
              else if( err?.response?.data?.message.replace(/[0-9]/g, '') === "You have  item need to be completed before you can bid again"){
                Swal.fire({
                  title:" Oops forbidden!",
                  text:`${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  confirmButtonText :"Process",
                  customClass: {
                    title:"redBg"
                  },
                  

                })
                .then(()=>{
                  history.push(GlobalizationLanguagewithCOntryCode("/MyDillivry", country,visitingCountryCode))

                })
             

               
              
                

              }
              else{
                Swal.fire({
                  title:" Oops forbidden!",
                  text: `${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  // confirmButtonText :"Process",
                  customClass: {
                    title:"redBg"
                  },
                  

                })
                
                  
              } 
              setsubmiting(false);


            }
            
            
            
            else {
              Swal.fire({
                icon: "warning",
                text: "Error occured try again!",
                timer: 2500,
                showConfirmButton: false,
              });
              setsubmiting(false);
            }
          });
      } else if (
        ls.get("_nodi") !== "" &&
        ls.get("aCs_") === "active" &&
        !check_mode()
      ) {
        setshowCanQuote(true);
      }
      else if (
        ls.get("_nodi") !== "" &&
        ls.get("aCs_") === "active" &&
       !CheckTimeframeValidity(viewData[0]?.delivery_time_frame)
      ) {
        setshowValidity(true);
      }
    }

    
   

  };

  const quoteNowMobile = (formData) => {
  
    if (formData.msg === "" || formData.msg === undefined) {
      if (
        ls.get("_nodi") !== "" &&
        ls.get("aCs_") === "active" &&
     check_mode() && CheckTimeframeValidity(viewData[0]?.delivery_time_frame)
      ) {
        setsubmiting(true);
        authAxios
          .post(
            `m-dillivry-bid-manager?delivery_location=${country}`,
            {
              action_type: "quote",
              delivery_id: viewData[0].delivery_id,
              quote_amount: formData.QAmountmobile.replaceAll(",", ""),
              vcc: country,
            },
            { timeout: 240000 }
          )
          .then((response) => {
            setShowSubmitQuote(false);
            if (response.status === 200) {
              Swal.fire({
                icon: "success",
                text: "Quote placed successfully!",
                // timer: 2500,
                showConfirmButton: true,
                confirmButtonColor: "var(--main)",
              }).then(() => {
                reset({
                  Qamount: " "
                });
                window.location.reload()
              });
              reset({});
              setsubmiting(false);
            }
            setsubmiting(false);
          })
          .catch((err) => {
            if (err.code === "ECONNABORTED") {
              history.push(timeout);
            }
            setShowSubmitQuote(false);

            if (err?.response?.status === 403) {
              Swal.fire({
                icon: "warning",
                text: "Session expired, login again!",
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  auth.logout();
                  history.push(login);
                }
              });
            } 
            else if(err?.response?.status === 400){
              if( err?.response?.data?.message === "You can not bid from the selected conutry, please register for the  specifcied country"){
                Swal.fire({
                  // icon: "warning",
                  title:" Oops forbidden!",
                  text: `${err?.response?.data?.message}`,
                  // text: "oops! Forbidden, you can only bid for item in the country where you registered. To bid for this item, you need to register as a provider in this country.",
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  // confirmButtonText :"Process",
                  // button: "Process",
                  customClass: {
                    title:"redBg"
                  },
                  
                  // timer: 2500,
                } )
                

              }
              else if( err?.response?.data?.message === "You are owing dillivry please fund your or accept instant payment method to balance your account"){
                Swal.fire({
                  title:" Oops forbidden!",
                  text: `${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  confirmButtonText :"Process",
                  customClass: {
                    title:"redBg"
                  },
                })
                .then(()=>{
                  history.push(GlobalizationLanguagewithCOntryCode("/corpwallet", country,visitingCountryCode))
                })
              }
              else if( err?.response?.data?.message.replace(/[0-9]/g, '') === "You have  item need to be completed before you can bid again"){
                Swal.fire({
                  title:" Oops forbidden!",
                  text:`${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  confirmButtonText :"Process",
                  customClass: {
                    title:"redBg"
                  },
                })
                .then(()=>{
                  history.push(GlobalizationLanguagewithCOntryCode("/MyDillivry", country,visitingCountryCode))
                })
              }
              else{
                Swal.fire({
                  title:" Oops forbidden!",
                  text: `${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  customClass: {
                    title:"redBg"
                  },
                })
              }
              setsubmiting(false);
            }
            
            else {
              Swal.fire({
                icon: "warning",
                text: `${err?.response?.data?.message}`,
                timer: 2500,
                showConfirmButton: false,
              });
            }
          });
      } else if (
        ls.get("_nodi") !== "" &&
        ls.get("aCs_") === "active" &&
        !check_mode()
      ) {
        setshowCanQuote(true);
      }
      else if (
        ls.get("_nodi") !== "" &&
        ls.get("aCs_") === "active" &&
       !CheckTimeframeValidity(viewData[0]?.delivery_time_frame)
      ) {
        setshowValidity(true);
      }
    } else {
      if (
        ls.get("_nodi") !== "" &&
        ls.get("aCs_") === "active" &&
        check_mode() && CheckTimeframeValidity(viewData[0]?.delivery_time_frame)
      ) {
        setsubmiting(true);
        authAxios
          .post(
            `m-dillivry-bid-manager?delivery_location=${country}`,
            {
              action_type: "quote",
              delivery_id: viewData[0].delivery_id,
              quote_amount: formData.QAmountmobile.replaceAll(",", ""),
              msg: formData.msg,
              vcc: country,
            },
            { timeout: 240000 }
          )
          .then((response) => {
            setShowSubmitQuote(false);
            setsubmiting(false);
            if (response.status === 200) {
              Swal.fire({
                icon: "success",
                text: "Quote placed successfully!",
                // timer: 2500,
                showConfirmButton: true,
                confirmButtonColor: "var(--main)",
              }).then(() => {
                reset({
                  Qamount: " "
                });
                window.location.reload()
              });
              reset({});
              setsubmiting(false);
              
            }
          })
          .catch((err) => {
            setShowSubmitQuote(false);
            if (err.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push(timeout);
            }
            if (err?.response?.status === 403) {
              Swal.fire({
                icon: "warning",
                text: "Session expired, login again!",
                //  timer: 2500,
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  auth.logout();
                  history.push(login);
                }
                setsubmiting(false);
              });
            }
            else if(err?.response?.status === 400){
              if( err?.response?.data?.message === "You can not bid from the selected conutry, please register for the  specifcied country"){
                Swal.fire({
                  // icon: "warning",
                  title:" Oops forbidden!",
                  text: `${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  // confirmButtonText :"Process",
                  // button: "Process",
                  customClass: {
                    title:"redBg"
                  },
                  // timer: 2500,
                } )
                

              }
              else if( err?.response?.data?.message === "You are owing dillivry please fund your or accept instant payment method to balance your account"){
                Swal.fire({
                  title:" Oops forbidden!",
                  text:`${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  confirmButtonText :"Process",
                  customClass: {
                    title:"redBg"
                  },
                  
                })
                .then(()=>{
                  history.push(GlobalizationLanguagewithCOntryCode("/corpwallet", country,visitingCountryCode))
          
                })
                

              }
              else if( err?.response?.data?.message.replace(/[0-9]/g, '') === "You have  item need to be completed before you can bid again"){
                Swal.fire({
                  title:" Oops forbidden!",
                  text: `${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  confirmButtonText :"Process",
                  customClass: {
                    title:"redBg"
                  },
                })
                .then(()=>{
                  history.push(GlobalizationLanguagewithCOntryCode("/MyDillivry", country,visitingCountryCode))
                })
              }
              else{
                Swal.fire({
                  title:" Oops forbidden!",
                  text: `${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  // confirmButtonText :"Process",
                  customClass: {
                    title:"redBg"
                  },
                })
              } 
              setsubmiting(false);
            }
            
            else {
              Swal.fire({
                icon: "warning",
                text: "Error occured try again!",
                timer: 2500,
                showConfirmButton: false,
              });
              setsubmiting(false);
            }
          });
      } else if (
        ls.get("_nodi") !== "" &&
        ls.get("aCs_") === "active" &&
        !check_mode()
      ) {
        setshowCanQuote(true);
      }
      else if (
        ls.get("_nodi") !== "" &&
        ls.get("aCs_") === "active" &&
       !CheckTimeframeValidity(viewData[0]?.delivery_time_frame)
      ) {
        setshowValidity(true);
      }
    }

  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (location.state === "back") {
      executeScrollbacktotop();
    }
  }, []);

  const scrollToRef = (ref) =>
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  const backToTop = useRef(null);
  const executeScrollbacktotop = () => scrollToRef(backToTop);
  const [newdilId, setnewdilId] = useState([]);
  const [removedilId, setremovedilId] = useState([]);
  // const [newdilId, setnewdilId] = useState([]);

  const [show, setshow] = useState("hide");
  const [selected, setselected] = useState("hide");

  const handlerRadioChange = (e) => {
    if (e.target.value === "show") {
      setshow("show");
      setselected(e.target.value);
    } else {
      setshow("hide");
      setselected(e.target.value);
    }
  };

  const [origin, setorigin] = useState([]);
  const [destination, setdestination] = useState([]);
  const [loading, setloading] = useState(false);

  const formatString = (string) => {
    if (string !== null) {
      let trimStr = string.trim();
      let trimStr2 = trimStr.split(" ");
      trimStr2 = trimStr2.filter((e) => e.length !== 0);
      return trimStr2;
    } else {
      return string;
    }
  };

  function reverseArr(input) {
    var ret = new Array();
    for (var i = input?.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  const joiner = (data) => {
    const newarrrr = data.split("-");
    const merge = newarrrr[0].concat(newarrrr[1], newarrrr[2]);
    const newarrr = merge.split(" ");
    const merged = newarrr[0].concat(newarrr[1]);
    const newarrrf = merged.split(":");
    const mergedd = newarrrf[0].concat(newarrrf[1], newarrrf[2]);
    return mergedd;
  };

  useEffect(() => {
    if (dashboards !== undefined) {
      ls.set(
        "means_of_delivery",
        JSON.stringify(dashboards?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
      );
    }
      setloading(true);
      if(Cookies.get('i18next')  !== "" ||Cookies.get('i18next')  !== undefined){
        authAxios
        .get(`${process.env.REACT_APP_API}m-dillivry-search?vcc=${Cookies.get('i18next')}&attribute=query_delivery&delivery_id=${delivery_id}`,
            { timeout: 24000 }
          )
          .then((response) => {

            setloading(false);
            
            setViewData([response.data.data])
  
            const searchData = response.data.data
  
            if (searchData.length !== 0) {
              const startLat = parseFloat(searchData.start_location.lat);
              const startlng = parseFloat(searchData.start_location.lng);
              const endLat = parseFloat(searchData.end_location.lat);
              const endLng = parseFloat(searchData.end_location.lng);
  
              setorigin([
                {
                  id: "pickup",
                  title: "Pickup",
                  coordinates: {
                    lat: startLat,
                    lon: startlng,
                  },
                },
              ]);
              setdestination([
                {
                  id: "destination",
                  title: "Destination",
                  coordinates: {
                    lat: endLat,
                    lon: endLng,
                  },
                },
              ]);
            }
          })
          .catch((err) => {
            if (err.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push("/timeout");
              // window.location.reload()
            }
          });

      }
      else if(Cookies.get('i18next')  === "" || Cookies.get('i18next')  === undefined){
        authAxios
        .get(`${process.env.REACT_APP_API}m-dillivry-search?vcc=${Cookies.get('i18next').toLowerCase()}&attribute=query_delivery&delivery_id=${delivery_id}`,
        
            { timeout: 24000 }
          )
          .then((response) => {
            setloading(false);
           
            setViewData(
              [response.data.data]
            );
  
  
            const searchData = response.data.data
  
            if (searchData.length !== 0) {
              const startLat = parseFloat(searchData.start_location.lat);
              const startlng = parseFloat(searchData.start_location.lng);
              const endLat = parseFloat(searchData.end_location.lat);
              const endLng = parseFloat(searchData.end_location.lng);
  
              setorigin([
                {
                  id: "pickup",
                  title: "Pickup",
                  coordinates: {
                    lat: startLat,
                    lon: startlng,
                  },
                },
              ]);
              setdestination([
                {
                  id: "destination",
                  title: "Destination",
                  coordinates: {
                    lat: endLat,
                    lon: endLng,
                  },
                },
              ]);
            }
          })
          .catch((err) => {
            if (err.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push("/timeout");
            }
          });

      }
    
  }, []);

  const numberWithCommas = new Intl.NumberFormat("en-GB");
  // console.log(datatoshow, "data-to-show");

  const WatchNow = (delivery_id_) => {
    if (ls.get("aCs_") !== "active") {
      setshowAccountStatus(!showAccountStatus);
      setComponenttoshow(false);
      setComponenttoshow2(false);
      setShowpopup2(false);
      setShowpopup(false);
    } else {
      const decoded = jwt_decode(ls.get("_nodi"));
      const expired = decoded.exp;
      if (expired < Date.now() / 1000) {
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            auth.logout();
            setShowpopup(true);
            setComponenttoshow(true);
          }
        });
      } else {
        authAxios
          .post(
            `m-dillivry-listing?delivery_location=${Cookies.get('i18next')}`,
            {
              action_type: "watchlist",
              ops_type: "watch",
              delivery_id: [delivery_id_],
              fulfiller_id: ls.get("_iyD") ? ls.get("_iyD") : null,
              vcc: Cookies.get('i18next'),
            },
            { timeout: 240000 }
          )
          .then((response) => {
            setllselectedids([...allselectedids, delivery_id_]);
            if (response.status === 200) {
              viewData[0].watched = "yes";
            } else {
              alert("Error occured!");
            }
          })
          .catch((err) => {
            if (err.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push(timeout);
              // window.location.reload()
            }
            console.log(err);
          });
      }
    }
  };

  const RemoveNow = (delivery_id_) => {
    authAxios
      .post(`m-dillivry-listing?delivery_location=${Cookies.get('i18next')}`, {
        action_type: "watchlist",
        ops_type: "remove",
        delivery_id: [delivery_id_],
        fulfiller_id: ls.get("_iyD") ? ls.get("_iyD") : null,
        vcc: Cookies.get('i18next'),
      })
      .then((response) => {
        delete viewData[0].watched
        setllselectedids(
          allselectedids.filter(function (n) {
            return n !== delivery_id_;
          })
        );

        setwatched_items(
          watched_items.filter(function (n) {
            return n !== delivery_id_;
          })
        );

        if (response.status === 200) {
          
        } else {
          alert("Error occured!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "none",
            zIndex: "300",
            position: "fixed",
            top: "0",
            paddingTop: "5px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="success" />
          </Box>
        </div>
      ) : null}

      <div className="deskShow">
        <div className="viewSearch">
          <ReactTooltip
            id="global"
            aria-haspopup="true"
            type="light"
            role="example"
          >
            <p>Destination Address</p>
          </ReactTooltip>
          <div className="Desktop-screen-div">
            <div className="header-body-">
              <TopBar/>
            </div>
            {/* {console.log(viewData,"sde")} */}
            {viewData.length !== 0 ? (
              viewData.map((view) => {
                return (
                  <>
                  
                    <div key={view.delivery_id} className="view_Container">
                      <div className="view_Top_mobile">
                        <div className="topItem">
                          <div className="topItem-first-div">
                            <button
                              className="topItem-first-div-btn"
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => history.goBack()}
                              >
                                Back 
                              </span>
                            </button>
                            <span
                              className="topItem-first-div-category"
                              style={{ fontWeight: "600" }}
                            >
                              {" "}
                              Category:{" "}
                            </span>
                            <span className="topItem-first-div-item-category">
                              {view.item_category.charAt(0)?.toUpperCase() +
                                view.item_category?.slice(1).replace(/_/g, " ")}
                            </span>
                          </div>
                          <div className="topItem-second-div">
                            <span
                              className="topItem-second-div-Id"
                              style={{ fontWeight: "600" }}
                            >
                              ID:{" "}
                            </span>
                            <span className="topItem-second-div-item-category">
                              {view.delivery_id}
                            </span>

                            {watched_items.includes(view.delivery_id) ||
                            allselectedids.includes(view.delivery_id) ||
                            view?.watched === "yes" ? (
                              <button
                                className="watch-btn-star viewfulfiler-btn"
                                onClick={(e) => {}}
                              >
                                <IconContext.Provider
                                  value={{
                                    color: "#50b14a",
                                    className: "global-class-name-watch-icon",
                                  }}
                                >
                                  <div>
                                    <AiTwotoneStar />
                                  </div>
                                </IconContext.Provider>
                                <span
                                  onClick={() => {
                                    if (ls.get("_utk") !== "") {
                                      // check if login as sender
                                      setshowAMsender(true);
                                    } else {
                                      setShowpopup(!showpopup);
                                      if (ls.get("_nodi") === "") {
                                        setComponenttoshow(true);
                                      } else {
                                        setComponenttoshow(false);
                                        setShowpopup(showpopup);

                                        RemoveNow(view.delivery_id);
                                      }
                                    }
                                  }}
                                >
                                  Remove
                                </span>
                              </button>
                            ) : (
                              <button className="watch-btn-star viewfulfiler-btn">
                                <IconContext.Provider
                                  value={{
                                    color: "#50b14a",
                                    className: "global-class-name-watch-icon",
                                  }}
                                >
                                  <div>
                                    <AiTwotoneStar />
                                  </div>
                                </IconContext.Provider>
                                <span
                                  onClick={(e) => {
                                    if (ls.get("_utk") !== "") {
                                      // check if login as sender
                                      setshowAMsender(true);
                                    } else {
                                      setShowpopup(!showpopup);
                                      if (ls.get("_nodi") === "") {
                                        setComponenttoshow(true);
                                      } else {
                                        setComponenttoshow(false);
                                        setShowpopup(showpopup);
                                         CheckWathlistValidity(view);
                                        // console.log(view)
                                        
                                       //  WatchNow(view.delivery_id);
                                      }
                                    }
                                  }}
                                >
                                  Watch
                                </span>
                              </button>
                            )}

                            <button
                              className="topItem-second-div-report"
                              onClick={() => history.push("/complaints")}
                            >
                              <span>Report</span>
                            </button>
                          </div>
                        </div>
                        <div></div>
                      </div>

                      <ReactTooltip
                        id="global1"
                        aria-haspopup="true"
                        type="light"
                        role="example"
                      >
                        <p>Pick up Address</p>
                      </ReactTooltip>

                      <ReactTooltip
                        id="global"
                        aria-haspopup="true"
                        type="light"
                        role="example"
                      >
                        <p>Destination Address</p>
                      </ReactTooltip>

                      <div className="view_Map">
                        <GoogleMapWidget
                          origins={destination}
                          destinations={origin}
                        />
                      </div>

                      <div className="main-fulfiler-page">
                        <div className="imgage-content">
                          <Carousel
                            navButtonsAlwaysInvisible={true}
                            indicators={true}
                            animation={"slide"}
                            timeout={100}
                            autoPlay={true}
                          >
                            {view.image_urls?.map((img_url, i) => (
                              <img
                                key={i}
                                src={img_url}
                                style={{ width: "100%" }}
                                alt="Item_image"
                                className="carousel_image-"
                              />
                            ))}
                          </Carousel>
                        </div>

                        <ReactTooltip
                          id="global3"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Item Description</p>
                        </ReactTooltip>

                        <ReactTooltip
                          id="global4"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Distance</p>
                        </ReactTooltip>

                        <ReactTooltip
                          id="global5"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Duration In Traffic</p>
                        </ReactTooltip>

                        <ReactTooltip
                          id="global6"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Date Listed</p>
                        </ReactTooltip>

                        <ReactTooltip
                          id="global7"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Mode of Delivery</p>
                        </ReactTooltip>

                        <ReactTooltip
                          id="global18"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Mode of Movement</p>
                        </ReactTooltip>

                        <ReactTooltip
                          id="dimension"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Dimension</p>
                        </ReactTooltip>

                        <ReactTooltip
                          id="Weight"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Weight</p>
                        </ReactTooltip>

                        <div className="dillivry-info">
                          <div className="right-content-information---">
                            <div className="submit-quote-content">
                              <div className="quote-div">
                                {" "}
                                <h1>Delivery information</h1>
                              </div>
                              <div className="current-quote-body  dilivry-one   ">
                                <div>
                                  <div className="">
                                    <div className="itemDescs-desc">
                                      <div
                                        className="itemDescs- quote-info--- "
                                        data-tip
                                        data-for="global3"
                                      >
                                        <div data-tip data-for="global3">
                                          {" "}
                                          <img
                                            src={itemdescr_}
                                            alt=""
                                            className="icn-one-"
                                          />{" "}
                                        </div>

                                        {/* {
                                          console.log(view, 'viewwwww')
                                        } */}
                                        <div data-tip data-for="global3">
                                          {" "}
                                          <span className="icon-desc">
                                            {view.item_desc
                                              .charAt(0)
                                              ?.toUpperCase() +
                                              view.item_desc
                                                .toLowerCase()
                                                ?.slice(1)}
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="itemDescs-"
                                        data-tip
                                        data-for="global1"
                                      >
                                        <div>
                                          {" "}
                                          <img
                                            src={to}
                                            alt=""
                                            className="icn-one-"
                                          />{" "}
                                        </div>
                                        <div>
                                          {" "}
                                          <span className="icon-desc">
                                            {view.from}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        className="itemDescs-"
                                        data-tip
                                        data-for="global"
                                      >
                                        <div>
                                          {" "}
                                          <img
                                            src={From}
                                            alt=""
                                            className="icn-two-"
                                          />
                                        </div>

                                        <div>
                                          {" "}
                                          <span className="icon-desc">
                                            {view.to}
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="itemDescs-"
                                        data-tip
                                        data-for="global4"
                                      >
                                        <div>
                                          {" "}
                                          <img
                                            src={Distance}
                                            alt=""
                                            className="icn3-"
                                          />
                                        </div>

                                        <div>
                                          <span className="icon-desc">
                                            {view.distance}
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="itemDescs-"
                                        data-tip
                                        data-for="global5"
                                      >
                                        <div>
                                          <img
                                            src={Duration}
                                            alt=""
                                            className="icn4-"
                                          />
                                        </div>

                                        <div>
                                          <span className="icon-desc">
                                            {view.duration}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        className="itemDescs-"
                                        data-tip
                                        data-for="global6"
                                      >
                                        <div>
                                          <img
                                            src={Listed}
                                            alt=""
                                            className="icn5-"
                                          />
                                        </div>

                                        <div>
                                          <span className="icon-desc">
                                            {view.date_listed}
                                          </span>
                                        </div>
                                      </div>

                                      {/* {view.mode_of_delivery === "haulage" ||
                                      view.mode_of_delivery ===
                                        "moving_home" ? (
                                        <div>
                                          <div
                                            className="itemDescs-"
                                            data-tip
                                            data-for="global18"
                                          >
                                            <div>
                                              <img
                                                src={Fastdelivery}
                                                alt=""
                                                className="icn6-"
                                              />
                                            </div>

                                            <div>
                                              <span className="icon-desc">
                                                {view.mode_of_delivery
                                                  .charAt(0)
                                                  ?.toUpperCase() +
                                                  view.mode_of_delivery
                                                    .toLowerCase()
                                                    .slice(1)
                                                    .replaceAll("_", " ")}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : ( */}
                                      <div
                                        className="itemDescs-"
                                        data-tip
                                        data-for="global7"
                                      >
                                        <div>
                                          <img
                                            src={Fastdelivery}
                                            alt=""
                                            className="icn6-"
                                          />
                                        </div>

                                        <div>
                                          <span className="icon-desc">
                                            {view?.mode_of_delivery ===
                                            "vehicle"
                                              ? "Car"
                                              : view?.mode_of_delivery
                                                  ?.charAt(0)
                                                  ?.toUpperCase() +
                                                view?.mode_of_delivery
                                                  ?.replaceAll("_", " ")
                                                  ?.slice(1)}
                                          </span>
                                        </div>
                                      </div>
                                      {/* )} */}

                                      {view.item_category === "general_items" ||
                                      view.item_category === "furniture" ||
                                      view.item_category === "vehicle_parts" ? (
                                        <div
                                          className="itemDescs-"
                                          data-tip
                                          data-for="dimension"
                                        >
                                          <div data-tip data-for="">
                                            <img
                                              className="icn6-"
                                              src={dimensions}
                                              alt=""
                                              style={{ height: "20px" }}
                                            />
                                          </div>

                                          <div>
                                            <span
                                              className="icon-desc"
                                              data-tip
                                              data-for=""
                                            >
                                              {view?.item_dim?.length ===
                                                "centimeters" &&
                                              view?.item_dim?.width ===
                                                "centimeters" &&
                                              view?.item_dim?.height ===
                                                "centimeters" ? (
                                                "Not provided by sender"
                                              ) : (
                                                <>
                                                  {
                                                    formatString(
                                                      view?.item_dim?.length
                                                    )[0]
                                                  }
                                                  {formatString(
                                                    view?.item_dim?.length
                                                  )[1] === "centimeters"
                                                    ? "cm"
                                                    : formatString(
                                                        view?.item_dim?.length
                                                      )[1] === "meters"
                                                    ? "m"
                                                    : formatString(
                                                        view?.item_dim?.length
                                                      )[1] === "inches"
                                                    ? "inches"
                                                    : "ft"}
                                                  &nbsp; x &nbsp;
                                                  {
                                                    formatString(
                                                      view?.item_dim?.width
                                                    )[0]
                                                  }
                                                  {formatString(
                                                    view?.item_dim?.width
                                                  )[1] === "centimeters"
                                                    ? "cm"
                                                    : formatString(
                                                        view?.item_dim?.width
                                                      )[1] === "meters"
                                                    ? "m"
                                                    : formatString(
                                                        view?.item_dim?.width
                                                      )[1] === "inches"
                                                    ? "inches"
                                                    : "ft"}
                                                  &nbsp; x &nbsp;
                                                  {
                                                    formatString(
                                                      view?.item_dim?.height
                                                    )[0]
                                                  }
                                                  {formatString(
                                                    view?.item_dim?.height
                                                  )[1] === "centimeters"
                                                    ? "cm"
                                                    : formatString(
                                                        view?.item_dim?.height
                                                      )[1] === "meters"
                                                    ? "m"
                                                    : formatString(
                                                        view?.item_dim?.height
                                                      )[1] === "inches"
                                                    ? "inches"
                                                    : "ft"}{" "}
                                                  &nbsp; (L x W x H)
                                                </>
                                              )}
                                            </span>
                                            <div className="tobeflexmain"></div>
                                          </div>
                                        </div>
                                      ) : null}

                                      {view.item_category === "general_items" ||
                                      view.item_category === "furniture" ||
                                      view.item_category === "vehicle_parts" ? (
                                        <div
                                          className="itemDescs-"
                                          data-tip
                                          data-for="Weight"
                                        >
                                          <div data-tip data-for="">
                                            <img
                                              className="icn6-"
                                              src={weighingmachine}
                                              alt=""
                                              style={{ height: "20px" }}
                                            />
                                          </div>

                                          <div>
                                            <span
                                              className="icon-desc"
                                              data-tip
                                              data-for=""
                                            >
                                              {view?.item_dim?.weight ===
                                              " grammes" ? (
                                                "Not provided by sender"
                                              ) : (
                                                <>
                                                  {
                                                    formatString(
                                                      view?.item_dim?.weight
                                                    )[0]
                                                  }
                                                  {formatString(
                                                    view?.item_dim?.weight
                                                  )[1] === "gramme"
                                                    ? "gr"
                                                    : formatString(
                                                        view?.item_dim?.weight
                                                      )[1] === "kilogrammes"
                                                    ? "kg"
                                                    : "tonnes"}
                                                </>
                                              )}
                                            </span>
                                            <div className="tobeflexmain"></div>
                                          </div>
                                        </div>
                                      ) : view.item_category === "haulage" ? (
                                        <div
                                          className="itemDescs-"
                                          data-tip
                                          data-for="Weight"
                                        >
                                          <div>
                                            <img
                                              className="icn6-"
                                              src={weighingmachine}
                                              alt=""
                                              style={{ height: "20px" }}
                                            />
                                          </div>

                                          <div>
                                            <span className="icon-desc">
                                              {view?.item_dim === "" ? (
                                                "Not provided by sender"
                                              ) : (
                                                <>
                                                  {formatString(view?.item_dim)}{" "}
                                                  &nbsp;
                                                </>
                                              )}
                                            </span>
                                            <div className="tobeflexmain"></div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className=" quote-info ß  submit-quote-content">
                          <div className="quote-div">
                            <h1>Quote Information</h1>
                          </div>
                          <div className="itemDescs-desc current-quote-body ">
                            <div>
                              <div>
                                <div className="quote-info--">
                                  <div>
                                    {" "}
                                    <span className="Quote-Title">
                                      Quote status:{" "}
                                    </span>{" "}
                                  </div>
                                  <div>
                                    <span className="quote-amount">
                                      Awaiting Quote Acceptance
                                    </span>
                                  </div>
                                </div>

                              </div>
                             

                              <div>
                                <div className="quote-info--">
                                  <div>
                                    {" "}
                                    <span className="Quote-Title">
                                      Mode of payment: {" "}
                                    </span>{" "}
                                  </div>
                                    <div>
                                    {
                                      typeof( view?.mode_of_payment) === "string"  ?
                                      <>
                                      {
                                        view?.mode_of_payment === "pay_by_cash" ?
                                        <span  className="quote-amount">Cash</span>:
                                        view?.mode_of_payment === "pay_by_transfer" ?
                                        <span  className="quote-amount">Transfer</span>:
                                        view?.mode_of_payment === "pay_by_ussd" ?
                                        <span  className="quote-amount">USSD</span>:
                                        view?.mode_of_payment === "pay_by_wallet" ?
                                        <span  className="quote-amount">Online</span>:
                                        view?.mode_of_payment === "pay_by_card" ?
                                        <span  className="quote-amount">Card</span>:
                                        <span  className="quote-amount">{view?.mode_of_payment}</span>




                                        

                                      }
                                      
                                      </>
          
                                      :
                                      <span  className="quote-amount">N/A</span>


                                    }
                                                          {/* rowData?.mode_of_payment?.charAt(0)?.toUpperCase() + rowData?.mode_of_payment?.slice(1) */}
                                  
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="quote-info--">
                                  <div>
                                    {" "}
                                    <span className="Quote-Title">
                                      Pick up date:{" "}
                                    </span>{" "}
                                  </div>
                                  <div>
                                    <span className="quote-amount">
                                    {moment(view?.pickup_date)?.format("DD-MM-YYYY")}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="quote-info--">
                                  <div>
                                    {" "}
                                    <span className="Quote-Title">
                                      Delivery time frame:{" "}
                                    </span>{" "}
                                  </div>
                                  <div>
                                  {
                                    view?.delivery_time_frame.length === 9 ||
                                    view?.delivery_time_frame.length === 10 ||
                                    view?.delivery_time_frame.length === 22 || 
                                    view?.delivery_time_frame.length === 23 || 
                                    view?.delivery_time_frame.length === 24 ?
                                    <span className="quote-amount">
                             
                                        {  moment((view?.delivery_time_frame.split(' '))[0]).format('DD-MM-YYYY')
                                      
                                      }
                                      {" "}
                                      { (view?.delivery_time_frame.split(' '))[1]
                                      
                                    }
                                      {" "}
                                      { 
                                          ( view?.delivery_time_frame.split(' '))[2] ?
                                      
                                      moment((view?.delivery_time_frame.split(' '))[2]).format('DD-MM-YYYY'):
                                      null
                                      
                                    }
                                    </span>
                                   :
                                   view?.delivery_time_frame === "Within 02 week" ?
                  
                                      <span className="quote-amount">
                                            {"Within 2 weeks"}
                  
                                      </span>
                                    :
                                    
                                    <span className="quote-amount">
                             
                                    {view?.delivery_time_frame}
                                  </span>
                                }

                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="quote-info--">
                                  <div>
                                    {" "}
                                    <span className="Quote-Title">
                                      Best Quote:{" "}
                                    </span>{" "}
                                  </div>
                                  <div>
                                    <span className="quote-amount">
                                      {
                                        view?.best_quote ?
                                        <>
                                        <Currency location={`${Cookies.get('i18next')}`}/>
                                      {numberWithCommas.format(view?.best_quote)}
                                        </>
                                        :
                                        "None"
                                      }
                                      
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <form onSubmit={handleSubmit(quoteNow)}>
                                  <div className="quote-info--">
                                    <div>
                                      {" "}
                                      <span className="Quote-Title">
                                        Your Quote:{" "}
                                      </span>{" "}
                                    </div>
                                    <div>
                                      <span className="quote-amount">
                                      <Currency location={`${Cookies.get('i18next')}`}/>{" "}
                                        <input
                                          {...register("QAmount")}
                                          type="text"
                                          className="quoteinputcontent"
                                        />
                                        <br />{" "}
                                        <small>{errors.QAmount?.message}</small>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="quote-info--">
                                    <div>
                                      {" "}
                                      <div
                                        className=""
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>
                                          {" "}
                                          <span className="Quote-Title-message   ques-">
                                            {" "}
                                            Would you like to add message?
                                          </span>{" "}
                                        </div>

                                        <div style={{ display: "flex" }}>
                                          <div style={{ marginRight: "30px" }}>
                                            <input
                                              type="radio"
                                              value="show"
                                              className="message_yes_response"
                                              name="content"
                                              onClick={handlerRadioChange}
                                              checked={selected === "show"}
                                            />
                                            <label htmlFor="Yes">Yes</label>
                                          </div>

                                          <div>
                                            <input
                                              type="radio"
                                              value="hide"
                                              className="message_yes_response"
                                              name="content"
                                              onClick={handlerRadioChange}
                                              checked={selected === "hide"}
                                            />
                                            <label htmlFor="No_">No</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div></div>
                                  </div>

                                  {show === "show" ? (
                                    <div className="">
                                      <div className="">
                                        <textarea
                                          {...register("msg")}
                                          rows="3"
                                          cols="20"
                                          className="quoteinputcontent-"
                                          required
                                        ></textarea>
                                      </div>
                                    </div>
                                  ) : null}

                                  <div className="enquiry-submit">
                                    {view.delivery_status === undefined ? (
                                      <div className=" enquiry-mssg">
                                        {/* { console.log(view, 'view')} */}
                                        <p
                                          onClick={ () => {
                                           
                                            CheckEnquiryValidity(view.delivery_time_frame)
                                           
                                          }}
                                        >
                                          Make Enquiry 
                                        </p>
                                      </div>
                                    ) : null}

                                    <div className="">
                                      <div className="parent-submit-quote-enquiry-msg">
                                        <div>
                                          <button
                                            disabled={submiting}
                                            type="submit"
                                            className="btn-quote-form"
                                            onClick={(e) => {
                                              // reset({
                                              //   QAmount: ""
                                               
                                              // })
                                              // reset({
                                            
                                              // })
                                              if (ls.get("_utk") !== "") {
                                                // check if login as sender
                                                setshowAMsender(true);
                                              } else {
                                                // // console.log(view, 'view')
                                                //  CheckTimeframeValidity(view?.delivery_time_frame, 'timeframe')
                                                setShowpopup(!showpopup);
                                                setshow("hide");
                                                if (ls.get("_nodi") === "") {
                                                  setComponenttoshow(true);
                                                  setComponenttoshow2(false);
                                                } else if (
                                                  ls.get("aCs_") !== "active"
                                                ) {
                                                  setshowAccountStatus(
                                                    !showAccountStatus
                                                  );
                                                  setComponenttoshow(false);
                                                  setComponenttoshow2(false);
                                                  setShowpopup2(false);
                                                  setShowpopup(false);
                                                } else {
                                                  const decoded = jwt_decode(
                                                    ls.get("_nodi")
                                                  );
                                                  const expired = decoded.exp;

                                                  if (
                                                    expired <
                                                    Date.now() / 1000
                                                  ) {
                                                    console.log(
                                                      "session expired"
                                                    );
                                                    Swal.fire({
                                                      icon: "warning",
                                                      title: "Session!",
                                                      text: "Session expired login again!",
                                                      allowOutsideClick: false,
                                                      confirmButtonColor:
                                                        "var(--main)",
                                                    }).then((result) => {
                                                      if (result.value) {
                                                        auth.logout();
                                                        setShowpopup(true);
                                                        setComponenttoshow(
                                                          true
                                                        );
                                                      }
                                                    });
                                                  } else {
                                                    setShowpopup(!showpopup);
                                                    setComponenttoshow(false);
                                                    setComponenttoshow2(false);
                                                    setShowpopup2(false);
                                                  }
                                                  // reset({
                                                  //   QAmount: ""
                                                   
                                                  // })
                                                  // reset({
                                                
                                                  // })

                                                  // Prevent Providers from submitting quote if timeframe has elapsed
                                                }
                                              }
                                            }}
                                          >
                                            {submiting
                                              ? "Submitting..."
                                              : "Submit quote"}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        view?.quote_history?.length === 0 ? 
                        <></>
                        :
                        <div
                          className="table-body"
                          style={{ margin: "0", marginTop: "20px" }}
                        >
                          <div className="main-parent">
                            <div
                              className="main-father"
                              style={{ position: "relative" }}>
                              <header className="qoute-header">
                                <h4>Provider History</h4>
                              </header>
                            </div>

                          <div>
                            
                            </div>
                          </div>

                          <table className="table__  table-tr-th  tabledelivry  ">
                            <tr>
                              <th>Quote amount</th>
                              <th>Logistic provider</th>
                              <th>Time</th>
                              <th>Quote status</th>
                              <th className="third-tr">Providers info </th>
                            </tr>

                            {view.quote_history?.map((detail, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {" "}
                                    <Currency location={`${Cookies.get('i18next')}`}/>
                                    {numberWithCommas.format(detail.quote_amount)}
                                  </td>
                                  <td>{detail.fulfiller_id}</td>
                                  <td>
                                    {detail?.time_stamp}
                                  </td>
                                  <td>
                                    {
                                    detail?.quote_status === "rejected," ?
                                    "Rejected"
                                    :
                                    (detail?.quote_status
                                      ?.replaceAll("_", " ")
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                      detail?.quote_status
                                        .replaceAll("_", " ")
                                        ?.slice(1)).split(",").join(", ")}
                                  </td>

                                  <td>
                                    <button
                                      onClick={() => {
                                        history.push(
                                          `/open-delivery/` +
                                            viewData[0].delivery_id +
                                            "/" +
                                            detail.fulfiller_id
                                        );
                                        history.push(
                                          GlobalizationLanguagewithCOntryCode(
                                            `/open-delivery/` +
                                            viewData[0].delivery_id +
                                            "/" +
                                            detail.fulfiller_id ,
                                            country,
                                            visitingCountryCode
                                          )
                                        );
                                      }}
                                      className="profile-btn"
                                    >
                                      <span>View Profile</span>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      }
                      {
                        view?.inquiry_details.length === 0 ? 
                        <></>
                        :
                        <div
                          className="main-father"
                          style={{ position: "relative", marginTop: "30px" }}
                        >
                          <header className="qoute-header enquiry-header">
                            <h4>Enquires from providers</h4>
                          </header>
                        </div>
                      }
                      {view?.inquiry_details?.map((msg, i) => {
                        return (
                          <div className="Enquiry-div-message">
                            {msg.message.map((x) => (
                              <div>
                                <p>
                                  {x?.fulfiller_id && (
                                    <b>
                                      Provider:{" "}
                                      {moment(
                                        x?.fulfiller_id && x?.time_stamp
                                      ).format("L, h:mm a ")}
                                      {moment(x?.time_stamp).fromNow()}{" "}
                                    </b>
                                  )}
                                </p>
                                <p>{x?.fulfiller_id && x?.msg}</p>

                                <p>
                                  {x?.user_id && (
                                    <b>
                                      Sender:
                                      {moment(
                                        x?.user_id && x?.time_stamp
                                      ).format("L, h:mm a ")}
                                      {moment(x?.time_stamp).fromNow()}{" "}
                                    </b>
                                  )}
                                </p>
                                <p>{x?.user_id && x?.msg}</p>
                              </div>
                            ))}
                          </div>
                        );
                      })}

                      <div className="footerover-ride"></div>
                    </div>

                    {showHelp ? (
                      <HomeHelp
                        showDialog={showHelp}
                        onClose={() => setshowHelp(false)}
                      />
                    ) : null}

                    {componenttoshow2 ? (
                      <DialogOutClick
                        open={showpopup2}
                        handleClose={() => setShowpopup2(!showpopup2)}
                      >
                        <EnquiryForm
                          item={view}
                          onFinish={(state) => {
                            if (state === "inactive") {
                              // setshowAccountStatus(true)
                              // setShowpopup2(showpopup2);
                              setComponenttoshow2(false);
                            } else {
                              setShowpopup2(!showpopup2);
                            }
                          }}
                          closePopup={() => setShowpopup2(!showpopup2)}
                        />
                      </DialogOutClick>
                    ) : null}

                    <DialogOutClick
                      open={showAccountStatus}
                      handleClose={() =>
                        setshowAccountStatus(!showAccountStatus)
                      }
                    >
                      <div
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          padding: "20px",
                          paddingTop: "40px",
                        }}
                      >
                        <AiFillCloseCircle
                          onClick={() =>
                            setshowAccountStatus(!showAccountStatus)
                          }
                          size="28px"
                          color="red"
                          style={{
                            position: "absolute",
                            right: "15px",
                            top: "15px",
                          }}
                        />
                        <img
                          src={erros}
                          alt="dillivry"
                          style={{ width: "60px" }}
                        />
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                            fontWeight: "500",
                            width: "100%",
                          }}
                        >
                          {ls.get("_iRl") === "dillivry-corp-sys-admin" ? (
                            <>
                              <div>
                                Your account is currently pending validation
                              </div>
                              <div>Click Ok to see your account status.</div>
                              <br></br>
                              <span
                                style={{
                                  padding: "7px 12px",
                                  backgroundColor: "var(--main)",
                                  color: "white",
                                  cursor: "pointer",
                                  borderRadius: "5px",
                                }}
                                onClick={() => history.push(Dashboard)}
                              >
                                Ok{" "}
                              </span>
                            </>
                          ) : ls.get("_iRl") === "dillivry-sole-trader-ops" ? (
                            <>
                              <div>
                                Your account is currently pending validation
                              </div>
                              <div>
                                Login to your Dillivry App to see the status of
                                your registration
                              </div>
                              <br></br>
                              <span
                                style={{
                                  padding: "7px 12px",
                                  backgroundColor: "var(--main)",
                                  color: "white",
                                  cursor: "pointer",
                                  borderRadius: "5px",
                                }}
                                onClick={() => history.push(open_deliveries)}
                              >
                                Ok{" "}
                              </span>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </DialogOutClick>



                    {/* {

                      showValidity  && 
                      
                      <> 
                      {
                       Swal.fire({
                        icon: "error",
                        text: `Delivery timeframe has passed!
                        You can no longer quote for this job. `,
                        showConfirmButton: "true",
                        confirmButtonColor: "grey",
                      }).then((result) => {
                        if (result.value) {
                          window.location.reload();
                        }
                      })
                      }
                      
                      </>


                    } */}


                     <DialogOutClick open={ showValidity }  handleClose={()=> setshowValidity(!showValidity)}  >

                    <div
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          padding: "20px",
                          paddingTop: "40px",
                        }}
                      >
                        {/* <AiFillCloseCircle
                          onClick={() => setshowValidity(!showValidity)}
                          size="28px"
                          color="red"
                          style={{
                            position: "absolute",
                            right: "15px",
                            top: "15px",
                          }}
                        /> */}
                        <img
                          src={CancelIcon}
                          alt="dillivry"
                          style={{ width: "60px" }}
                        />
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                            fontWeight: "500",
                            width: "100%",
                          }}
                        >
                          <>
                            <div>
                            Delivery timeframe has passed!
                            You can no longer quote for this job. 
                            </div>
                            <br></br>
                            <span
                              style={{
                                padding: "7px 12px",
                                backgroundColor: "#8E8E8E",
                                color: "white",
                                cursor: "pointer",
                                borderRadius: "5px",
                              }}
                              onClick={() => setshowValidity(!showValidity)}
                            >
                              Ok
                            </span>
                            {/* {
                               Swal.fire({
                                icon: "error",
                                text: `  Delivery timeframe has passed!
                                You can no longer quote for this job. `,
                                showConfirmButton: "true",
                                confirmButtonColor: "grey",
                              }).then((result) => {
                                if (result.value) {
                                  window.location.reload();
                                }
                              })
                            } */}
                          </>
                        </div>
                      </div>




                    </DialogOutClick> 


                    <DialogOutClick
                      open={showCanQuote}
                      handleClose={() => setshowCanQuote(!showCanQuote)}
                    >
                      <div
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          gap: "20px",
                          padding: "20px",
                          paddingTop: "40px",
                        }}
                      >
                        <AiFillCloseCircle
                          onClick={() => setshowCanQuote(!showCanQuote)}
                          size="28px"
                          color="red"
                          style={{
                            position: "absolute",
                            right: "15px",
                            top: "15px",
                          }}
                        />
                        <img
                          src={erros}
                          alt="dillivry"
                          style={{ width: "60px" }}
                        />
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                            fontWeight: "500",
                            width: "100%",
                          }}
                        >
                          <>
                            <div>
                              Quoting on this item is denied. You do not have
                              the required mode of delivery.
                            </div>
                            <br></br>
                            <span
                              style={{
                                padding: "7px 12px",
                                backgroundColor: "var(--main)",
                                color: "white",
                                cursor: "pointer",
                                borderRadius: "5px",
                              }}
                              onClick={() => setshowCanQuote(!showCanQuote)}
                            >
                              Ok{" "}
                            </span>
                          </>
                        </div>
                      </div>
                    </DialogOutClick>
                  </>
                );
              })
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "50px 20px",
                  fontSize: "28px",
                  fontWeight: "600",
                  color: "var(--main)",
                  flexDirection: "column",
                }}
              >
                {loading ? (
                  <PuffLoader
                    color="#50b14a"
                    loading={loading}
                    speedMultiplier={1}
                    size={100}
                  />
                ) : (
                  <div style={{ padding: "0 100px" }}>
                    <span>
                      {" "}
                      <BiNotificationOff size="50px" />
                    </span>
                    <span>
                      Item no longer in open delivery page. sender might have
                      accepted a quote from a provider or the delivery is no
                      longer required. Search{" "}
                      <span
                        style={{
                          color: "var(--lighty)",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => history.push(open_deliveries)}
                      >
                        open deliveries
                      </span>{" "}
                      for other opportunities
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
            <Footer />
        </div>
      </div>

      {/* MOBILE SCREEEN */}
      {/* MOBILE SCREEEN */}
      {/* MOBILE SCREEEN */}
      {/* MOBILE SCREEEN */}

      <div className="mobileShow">
        <div className="viewSearch">
          <ReactTooltip
            id="global"
            aria-haspopup="true"
            type="light"
            role="example"
          >
            <p>Destination Address</p>
          </ReactTooltip>
          <div className="Desktop-screen-div">
            <div className="header-body-">
              <TopBar/>
            </div>
            {viewData.length !== 0 ? (
              viewData.map((view) => {
                return (
                  <>
                    {/* {console.log(view, "view view")} */}
                    <div key={view.delivery_id} className="view_Container">
                      <div className="view_Top_mobile">
                        <div className="topItem">
                          <div className="topItem-first-div">
                            <button
                              className="topItem-first-div-btn"
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => history.goBack()}
                              >
                                Back
                              </span>
                            </button>
                            <span>{" "}</span>
                            <span className="topItem-first-div-item-category">
                              {view.item_category?.charAt(0)?.toUpperCase() +
                                view.item_category?.slice(1).replace(/_/g, " ")}
                            </span>
                          </div>
                          <div className="topItem-second-div">
                            <span>{" "} </span>
                            <span className="topItem-second-div-item-category">
                              {view.delivery_id}
                            </span>

                            {watched_items.includes(view.delivery_id) ||
                            allselectedids.includes(view.delivery_id) ||
                            view?.watched === "yes" ? (
                              <button
                                className="watch-btn-star viewfulfiler-btn"
                                onClick={(e) => {}}
                              >
                                <IconContext.Provider
                                  value={{
                                    color: "#50b14a",
                                    className: "global-class-name-watch-icon",
                                  }}
                                >
                                  <div>
                                    <AiTwotoneStar />
                                  </div>
                                </IconContext.Provider>
                                <span
                                  onClick={() => {
                                    if (ls.get("_utk") !== "") {
                                      // check if login as sender
                                      setshowAMsender(true);
                                    } else {
                                      setShowpopup(!showpopup);
                                      if (ls.get("_nodi") === "") {
                                        setComponenttoshow(true);
                                      } else {
                                        setComponenttoshow(false);
                                        setShowpopup(showpopup);

                                        RemoveNow(view.delivery_id);
                                      }
                                    }
                                  }}
                                >
                                  Remove
                                </span>
                              </button>
                            ) : (
                              <button className="watch-btn-star viewfulfiler-btn">
                                <IconContext.Provider
                                  value={{
                                    color: "#50b14a",
                                    className: "global-class-name-watch-icon",
                                  }}
                                >
                                  <div>
                                    <AiTwotoneStar />
                                  </div>
                                </IconContext.Provider>
                                <span
                                  onClick={(e) => {
                                    if (ls.get("_utk") !== "") {
                                      // check if login as sender
                                      setshowAMsender(true);
                                    } else {
                                      setShowpopup(!showpopup);
                                      if (ls.get("_nodi") === "") {
                                        setComponenttoshow(true);
                                      } else {
                                        setComponenttoshow(false);
                                        setShowpopup(showpopup);

                                        WatchNow(view.delivery_id);
                                      }
                                    }
                                  }}
                                >
                                  Watch
                                </span>
                              </button>
                            )}

                            <button
                              className="topItem-second-div-report"
                              onClick={() => history.push("/complaints")}
                            >
                              <span>Report</span>
                            </button>
                          </div>
                        </div>
                        <div></div>
                      </div>

                      <ReactTooltip
                        id="global1"
                        aria-haspopup="true"
                        type="light"
                        role="example"
                      >
                        <p>Pick up Address</p>
                      </ReactTooltip>

                      <ReactTooltip
                        id="global"
                        aria-haspopup="true"
                        type="light"
                        role="example"
                      >
                        <p>Destination Address</p>
                      </ReactTooltip>

                      <div className="view_Map">
                        <GoogleMapWidget
                          origins={destination}
                          destinations={origin}
                        />
                      </div>
                      
                      <div className="new_flex">
                        <span>
                          {view.item_category?.charAt(0)?.toUpperCase() +
                            view.item_category
                              .slice(1)
                              .replace(/_/g, " ")}
                        </span>
                        <span >
                          {view.delivery_id}
                        </span>
                      </div>

                      <div className="main-fulfiler-page">
                        <div className="imgage-content">
                          <Carousel
                            navButtonsAlwaysInvisible={true}
                            indicators={true}
                            animation={"slide"}
                            timeout={100}
                            autoPlay={true}
                          >
                            {view.image_urls?.map((img_url, i) => (
                              <img
                                key={i}
                                src={img_url}
                                style={{ width: "100%" }}
                                alt="Item_image"
                                className="carousel_image-"
                              />
                            ))}
                          </Carousel>
                        </div>

                        <ReactTooltip
                          id="global10"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Weight</p>
                        </ReactTooltip>

                        <ReactTooltip
                          id="global3"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Item Description</p>
                        </ReactTooltip>

                        <ReactTooltip
                          id="global4"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Distance</p>
                        </ReactTooltip>

                        <ReactTooltip
                          id="global5"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Duration In Traffic</p>
                        </ReactTooltip>

                        <ReactTooltip
                          id="global6"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Date Listed</p>
                        </ReactTooltip>

                        <ReactTooltip
                          id="global7"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Mode of Delivery</p>
                        </ReactTooltip>

                        <ReactTooltip
                          id="dimension"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Dimension</p>
                        </ReactTooltip>

                        <ReactTooltip
                          id="Weight"
                          aria-haspopup="true"
                          type="light"
                          role="example"
                        >
                          <p>Weight</p>
                        </ReactTooltip>

                        <div className="dillivry-info">
                          <div className="right-content-information---">
                            <div className="submit-quote-content">
                              <div className="quote-div">
                                {" "}
                                <h1>Delivery information</h1>
                              </div>
                              <div className="current-quote-body  dilivry-one   ">
                                <div>
                                  <div className="">
                                    <div className="itemDescs-desc">
                                      <div
                                        className="itemDescs- quote-info--- "
                                        data-tip
                                        data-for="global3"
                                      >
                                        <div data-tip data-for="global3">
                                          {" "}
                                          <img
                                            src={itemdescr_}
                                            alt=""
                                            className="icn-one-"
                                          />{" "}
                                        </div>
                                        <div data-tip data-for="global3">
                                          {" "}
                                          <span className="icon-desc">
                                            {view.item_desc
                                              ?.charAt(0)
                                              ?.toUpperCase() +
                                              view.item_desc
                                                .toLowerCase()
                                                ?.slice(1)}
                                          </span>
                                        </div>
                                      </div>
                                      
                                      <div
                                        className="itemDescs-"
                                        data-tip
                                        data-for="global1"
                                      >
                                        <div>
                                          {" "}
                                          <img
                                            src={to}
                                            alt=""
                                            className="icn-one-"
                                          />{" "}
                                        </div>
                                        <div>
                                          {" "}
                                          <span className="icon-desc">
                                            {view.from}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        className="itemDescs-"
                                        data-tip
                                        data-for="global"
                                      >
                                        <div>
                                          {" "}
                                          <img
                                            src={From}
                                            alt=""
                                            className="icn-two-"
                                          />
                                        </div>

                                        <div>
                                          {" "}
                                          <span className="icon-desc">
                                            {view.to}
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="itemDescs-"
                                        data-tip
                                        data-for="global4"
                                      >
                                        <div>
                                          {" "}
                                          <img
                                            src={Distance}
                                            alt=""
                                            className="icn3-"
                                          />
                                        </div>

                                        <div>
                                          <span className="icon-desc">
                                            {view.distance}
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className="itemDescs-"
                                        data-tip
                                        data-for="global5"
                                      >
                                        <div>
                                          {" "}
                                          <img
                                            src={Duration}
                                            alt=""
                                            className="icn4-"
                                          />
                                        </div>

                                        <div>
                                          {" "}
                                          <span className="icon-desc">
                                            {view.duration}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        className="itemDescs-"
                                        data-tip
                                        data-for="global6"
                                      >
                                        <div>
                                          <img
                                            src={Listed}
                                            alt=""
                                            className="icn5-"
                                          />
                                        </div>

                                        <div>
                                          {" "}
                                          <span className="icon-desc">
                                            {view.date_listed}
                                          </span>
                                        </div>
                                      </div>

                                      {view.mode_of_delivery === "haulage" ||
                                      view.mode_of_delivery ===
                                        "moving_home" ? (
                                        <div>
                                          <div
                                            className="itemDescs-"
                                            data-tip
                                            data-for="global18"
                                          >
                                            <div>
                                              <img
                                                src={Fastdelivery}
                                                alt=""
                                                className="icn6-"
                                              />
                                            </div>

                                            <div>
                                              <span className="icon-desc">
                                                {view.mode_of_delivery
                                                  .charAt(0)
                                                  ?.toUpperCase() +
                                                  view.mode_of_delivery
                                                    .toLowerCase()
                                                    .slice(1)
                                                    .replaceAll("_", " ")}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div
                                          className="itemDescs-"
                                          data-tip
                                          data-for="global7"
                                        >
                                          <div>
                                            <img
                                              src={Fastdelivery}
                                              alt=""
                                              className="icn6-"
                                            />
                                          </div>

                                          <div>
                                            <span className="icon-desc">
                                              {view?.mode_of_delivery
                                                ?.charAt(0)
                                                ?.toUpperCase() +
                                                view?.mode_of_delivery
                                                  ?.toLowerCase()
                                                  ?.slice(1)
                                                  ?.replaceAll("_", " ")}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                      {view.item_category === "general_items" ||
                                      view.item_category === "furniture" ||
                                      view.item_category === "vehicle_parts" ? (
                                        <div
                                          className="itemDescs-"
                                          data-tip
                                          data-for="dimension"
                                        >
                                          <div>
                                            <img
                                              className="icn6-"
                                              src={dimensions}
                                              alt=""
                                              style={{ height: "20px" }}
                                            />
                                          </div>

                                          <div>
                                            <span className="icon-desc">
                                              {view?.item_dim?.length ===
                                                " centimeters" &&
                                              view?.item_dim?.width ===
                                                " centimeters" &&
                                              view?.item_dim?.height ===
                                                " centimeters" ? (
                                                "Not provided by sender"
                                              ) : (
                                                <>
                                                  {
                                                    formatString(
                                                      view?.item_dim?.length
                                                    )[0]
                                                  }
                                                  {formatString(
                                                    view?.item_dim?.length
                                                  )[1] === "centimeters"
                                                    ? "cm"
                                                    : formatString(
                                                        view?.item_dim?.length
                                                      )[1] === "meters"
                                                    ? "m"
                                                    : formatString(
                                                        view?.item_dim?.length
                                                      )[1] === "inches"
                                                    ? "inches"
                                                    : "ft"}
                                                  &nbsp; x &nbsp;
                                                  {
                                                    formatString(
                                                      view?.item_dim?.width
                                                    )[0]
                                                  }
                                                  {formatString(
                                                    view?.item_dim?.width
                                                  )[1] === "centimeters"
                                                    ? "cm"
                                                    : formatString(
                                                        view?.item_dim?.width
                                                      )[1] === "meters"
                                                    ? "m"
                                                    : formatString(
                                                        view?.item_dim?.width
                                                      )[1] === "inches"
                                                    ? "inches"
                                                    : "ft"}
                                                  &nbsp; x &nbsp;
                                                  {
                                                    formatString(
                                                      view?.item_dim?.height
                                                    )[0]
                                                  }
                                                  {formatString(
                                                    view?.item_dim?.height
                                                  )[1] === "centimeters"
                                                    ? "cm"
                                                    : formatString(
                                                        view?.item_dim?.height
                                                      )[1] === "meters"
                                                    ? "m"
                                                    : formatString(
                                                        view?.item_dim?.height
                                                      )[1] === "inches"
                                                    ? "inches"
                                                    : "ft"}{" "}
                                                  &nbsp; &nbsp; &nbsp; (L x W x
                                                  H)
                                                </>
                                              )}
                                            </span>
                                            <div className="tobeflexmain"></div>
                                          </div>
                                        </div>
                                      ) : null}

                                      {view.item_category === "general_items" ||
                                      view.item_category === "furniture" ||
                                      view.item_category === "vehicle_parts" ? (
                                        <div
                                          className="itemDescs-"
                                          data-tip
                                          data-for="Weight"
                                        >
                                          <div>
                                            <img
                                              className="icn6-"
                                              src={weighingmachine}
                                              alt=""
                                              style={{ height: "20px" }}
                                            />
                                          </div>

                                          <div>
                                            <span className="icon-desc">
                                              {view?.item_dim?.weight ===
                                              " grammes" ? (
                                                "Not provided by sender"
                                              ) : (
                                                <>
                                                  {
                                                    formatString(
                                                      view?.item_dim?.weight
                                                    )[0]
                                                  }{" "}
                                                  &nbsp; &nbsp;
                                                  {formatString(
                                                    view?.item_dim?.weight
                                                  )[1] === "gramme"
                                                    ? "gr"
                                                    : formatString(
                                                        view?.item_dim?.weight
                                                      )[1] === "kilogrammes"
                                                    ? "kg"
                                                    : "tonnes"}
                                                </>
                                              )}
                                            </span>
                                            <div className="tobeflexmain"></div>
                                          </div>
                                        </div>
                                      ) : view.item_category === "haulage" ? (
                                        <div className="itemDescs-">
                                          <div>
                                            <img
                                              className="icn6-"
                                              src={weighingmachine}
                                              alt=""
                                              style={{ height: "20px" }}
                                            />
                                          </div>
                                          <div>
                                            <span className="icon-desc">
                                              {view?.item_dim === "" ? (
                                                "Not provided by sender"
                                              ) : (
                                                <>
                                                  {formatString(view?.item_dim)}
                                                </>
                                              )}
                                            </span>
                                            <div className="tobeflexmain"></div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className=" quote-info ß  submit-quote-content">
                          <div className="quote-div">
                            <h1>Quote Information</h1>
                          </div>
                          <div className="itemDescs-desc current-quote-body ">
                            <div>
                              <div className="quote-info--">
                                <div>
                                  {" "}
                                  <span className="Quote-Title">
                                    Quote status:{" "}
                                  </span>{" "}
                                </div>
                                <div>
                                  <span className="quote-amount">
                                    Awaiting Quote Acceptance
                                  </span>
                                </div>
                              </div>

                              <div>
                                <div className="quote-info--">
                                  <div>
                                    {" "}
                                    <span className="Quote-Title">
                                      Pick up date:{" "}
                                    </span>{" "}
                                  </div>
                                  <div>
                                    <span className="quote-amount">
                                    {moment(view?.pickup_date)?.format("DD-MM-YYYY")}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="quote-info--">
                                  <div>
                                    {" "}
                                    <span className="Quote-Title">
                                      Delivery time frame:{" "}
                                    </span>{" "}
                                  </div>
                                  <div>
                                         {
                                    view?.delivery_time_frame.length === 9 ||
                                    view?.delivery_time_frame.length === 10 ||
                                    view?.delivery_time_frame.length === 22 || 
                                    view?.delivery_time_frame.length === 23 || 
                                    view?.delivery_time_frame.length === 24 ?
                                    <span className="quote-amount">
                             
                                        {  moment((view?.delivery_time_frame.split(' '))[0]).format('DD-MM-YYYY')
                                      
                                      }
                                      {" "}
                                      { (view?.delivery_time_frame.split(' '))[1]
                                      
                                    }
                                      {" "}
                                      { 
                                          ( view?.delivery_time_frame.split(' '))[2] ?
                                      
                                      moment((view?.delivery_time_frame.split(' '))[2]).format('DD-MM-YYYY'):
                                      null
                                      
                                    }
                                    </span>
                                   :
                                   view?.delivery_time_frame === "Within 02 week" ?
                  
                                      <span className="quote-amount">
                                            {"Within 2 weeks"}
                  
                                      </span>
                                    :
                                    
                                    <span className="quote-amount">
                             
                                    {view?.delivery_time_frame}
                                  </span>
                                }
                                  </div>
                                </div>
                              </div>

                              <div>
                                <div className="quote-info--">
                                  <div>
                                    {" "}
                                    <span className="Quote-Title">
                                      Best Quote:{" "}
                                    </span>{" "}
                                  </div>
                                  <div>
                                    <span className="quote-amount">
                                      {
                                        view?.best_quote ? 
                                        <>
                                          <Currency location={`${Cookies.get('i18next')}`}/> {numberWithCommas.format(view.best_quote)}
                                        </>
                                        :
                                        "None"
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div>
                                <form onSubmit={handleSubmit1(quoteNowMobile)}>
                                  <div className="quote-info--">
                                    <div>
                                      {" "}
                                      <span className="Quote-Title">
                                        Your Quote:{" "}
                                      </span>{" "}
                                    </div>
                                    <div>
                                      <span className="quote-amount">
                                      <Currency location={`${Cookies.get('i18next')}`}/>{" "}
                                        <input
                                          {...register1("QAmountmobile")}
                                          type="text"
                                          className="quoteinputcontent"
                                        />
                                        <br />{" "}
                                        <small>
                                          {errors1.QAmountmobile?.message}
                                        </small>
                                      </span>
                                    </div>
                                  </div>

                                  <div className="quote-info--">
                                    <div>
                                      {" "}
                                      <div
                                        className=""
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>
                                          {" "}
                                          <span className="Quote-Title-message   ques-">
                                            {" "}
                                            Would you like to add message?
                                          </span>{" "}
                                        </div>

                                        <div style={{ display: "flex" }}>
                                          <div style={{ marginRight: "30px" }}>
                                            <input
                                              type="radio"
                                              value="show"
                                              className="message_yes_response"
                                              name="content"
                                              onClick={handlerRadioChange}
                                              checked={selected === "show"}
                                            />
                                            <label htmlFor="Yes">Yes</label>
                                          </div>

                                          <div>
                                            <input
                                              type="radio"
                                              value="hide"
                                              className="message_yes_response"
                                              name="content"
                                              onClick={handlerRadioChange}
                                              checked={selected === "hide"}
                                            />
                                            <label htmlFor="No_">No</label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div></div>
                                  </div>

                                  {show === "show" ? (
                                    <div className="">
                                      <div className="">
                                        <textarea
                                          {...register1("msg")}
                                          rows="3"
                                          cols="20"
                                          className="quoteinputcontent-"
                                          required
                                        ></textarea>
                                      </div>
                                    </div>
                                  ) : null}

                                  <div className="enquiry-submit">
                                    {view.delivery_status === undefined ? (
                                      <div className=" enquiry-mssg">
                                        {" "}
                                        <p
                                          onClick={(e) => {
                                            CheckEnquiryValidity(view.delivery_time_frame)
                                            // if (ls.get("_utk") !== "") {
                                            //   // check if login as sender
                                            //   setshowAMsender(true);
                                            // } else {
                                            //   setShowpopup(!showpopup);
                                            //   setShowpopup2(!showpopup2);
                                            //   setshow("hide");
                                            //   if (
                                            //     ls.get("_nodi") === "" // logedincheck
                                            //   ) {
                                            //     setComponenttoshow(true);
                                            //     setComponenttoshow2(false);
                                            //   } else if (
                                            //     ls.get("aCs_") !== "active"
                                            //   ) {
                                            //     setshowAccountStatus(
                                            //       !showAccountStatus
                                            //     );
                                            //     setComponenttoshow(false);
                                            //     setComponenttoshow2(false);
                                            //     setShowpopup2(false);
                                            //     setShowpopup(false);
                                            //   } else {
                                            //     setShowpopup(!showpopup);
                                            //     setShowpopup2(!showpopup2);
                                            //     setComponenttoshow(false);
                                            //     setComponenttoshow2(true);
                                            //   }
                                            //   if (ls.get("_nodi") === "") {
                                            //     setComponenttoshow2(false);
                                            //   }
                                            // }
                                          }}
                                        >
                                          Make Enquiry
                                        </p>
                                      </div>
                                    ) : null}

                                    <div className="">
                                      {submiting ? (
                                        <p
                                          style={{
                                            textAlign: "center",
                                            color: "green",
                                          }}
                                        >
                                          Submitting...
                                        </p>
                                      ) : (
                                        <div className="parent-submit-quote-enquiry-msg">
                                          <div>
                                            <button
                                              type="submit"
                                              disabled={submiting}
                                              className="btn-quote-form"

                                              onClick={(e) => {
                                                if (ls.get("_utk") !== "") {
                                                  // check if login as sender
                                                  setshowAMsender(true);
                                                } else {
                                                  setShowpopup(!showpopup);

                                                  setshow("hide");

                                                  if (ls.get("_nodi") === "") {
                                                    setComponenttoshow(true);
                                                    setComponenttoshow2(false);
                                                  } else if (
                                                    ls.get("aCs_") !== "active"
                                                  ) {
                                                    setshowAccountStatus(
                                                      !showAccountStatus
                                                    );
                                                    setComponenttoshow(false);
                                                    setComponenttoshow2(false);
                                                    setShowpopup2(false);
                                                    setShowpopup(false);
                                                  } else {
                                                    setShowpopup(!showpopup);
                                                    setComponenttoshow(false);
                                                    setComponenttoshow2(false);
                                                    setShowpopup2(false);
                                                  }

                                                  if (ls.get("_nodi" !== "")) {
                                                    const decoded = jwt_decode(
                                                      ls.get("_nodi")
                                                    );
                                                    const exp = decoded.exp;

                                                    if (
                                                      exp <
                                                      Date.now() / 1000
                                                    ) {
                                                      setComponenttoshow(true);
                                                    } else {
                                                      setComponenttoshow(false);
                                                    }
                                                  }

                                                  if (newState === "inactive") {
                                                    setshowAccountStatus(true);
                                                    setShowpopup2(false);
                                                    setComponenttoshow2(false);
                                                  }
                                                }
                                              }}
                                            >
                                              {submiting
                                                ? "Submitting..."
                                                : "Submit quote "}
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="provider-history-div">
                      {
                        view?.quote_history.length === 0 ?
                          <></>
                          :
                          <div
                            className="main-father"
                            style={{ position: "relative", marginTop: "30px" }}
                          >
                            <header className="qoute-header enquiry-header">
                              <h4>Quote History</h4>
                            </header>
                          </div>
                        }
                        {
                          view?.quote_history?.map((detail, index)=>{
                            return (

                              <div className="quote-content-div"  >
                                <p><b>Quote amount:</b>  <Currency location={`${Cookies.get('i18next')}`}/> {numberWithCommas.format(detail.quote_amount)} </p>
                                <p><b>Logistic provider:</b> {detail.fulfiller_id}</p>
                              <p><b>Time:</b> 
                                {/* {moment(
                                    view?.inquiry_details[index]?.message[0]
                                      ?.time_stamp
                                  ).format("L, h:mm a ")} */}
                                {detail?.time_stamp}
                              </p>

                              <p>
                                <b>Quote Status:</b>{" "}
                                {view?.quote_details[index]?.quote_status ===
                                "rejected,"
                                  ? "Rejected"
                                  : view?.quote_details[index]?.quote_status
                                      ?.replaceAll("_", " ")
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                    view?.quote_details[index]?.quote_status
                                      .replaceAll("_", " ")
                                      ?.slice(1)}
                              </p>
                              <div className="flex-btn">
                                <div></div>
                                <div>
                                  <button
                                    onClick={() => {
                                      // history.push(
                                      //   `/open-delivery/` +
                                      //     viewData[0].delivery_id +
                                      //     "/" +
                                      //     detail.fulfiller_id
                                      // );
                                      history.push(
                                        GlobalizationLanguagewithCOntryCode(
                                          `/open-delivery/` +
                                          viewData[0].delivery_id +
                                          "/" +
                                          detail.fulfiller_id ,
                                          country,
                                          visitingCountryCode
                                        )
                                      );
                                      // setFulfillerDetailToShow(detail.provider_info);
                                      // viewFulfiller(detail.provider_info);
                                    }}
                                    className="profile-btn"
                                  >
                                    <span>View Profile</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div className="footerover-ride">{/* <Footer /> */}</div>
                      {
                        view?.inquiry_details?.length === 0 ? 
                        <></>
                        :
                        <div
                          className="main-father"
                          style={{ position: "relative", marginTop: "30px" }}
                        >
                          <header className="qoute-header enquiry-header">
                            <h4>Enquires from providers</h4>
                          </header>
                        </div>
                      }
                      
                      {view?.inquiry_details?.map((msg, i) => {
                        return (
                          <div className="Enquiry-div-message">
                            {msg.message.map((x) => (
                              <div>
                                <p>
                                  {x?.fulfiller_id && (
                                    <b>
                                      Provider:{" "}
                                      {moment(
                                        x?.fulfiller_id && x?.time_stamp
                                      ).format("L, h:mm a ")}
                                      {moment(x?.time_stamp).fromNow()}{" "}
                                    </b>
                                  )}
                                </p>
                                <p>{x?.fulfiller_id && x?.msg}</p>

                                <p>
                                  {x?.user_id && (
                                    <b>
                                      Sender:
                                      {moment(
                                        x?.user_id && x?.time_stamp
                                      ).format("L, h:mm a ")}
                                      {moment(x?.time_stamp).fromNow()}{" "}
                                    </b>
                                  )}
                                </p>

                                <p>{x?.user_id && x?.msg}</p>
                              </div>
                            ))}
                          </div>
                        );
                      })}
                    </div>

                    {showHelp ? (
                      <HomeHelp
                        showDialog={showHelp}
                        onClose={() => setshowHelp(false)}
                      />
                    ) : null}

                    {componenttoshow ? (
                      <DialogOutClick
                        open={showpopup}
                        handleClose={() => setShowpopup(!showpopup)}
                      >
                        <QuoteSignInForm
                          onFinish={(state) => {
                            setShowpopup(showpopup);
                            triggerNavRefresh();
                            setComponenttoshow2(true);
                            setComponenttoshow(false);
                            if (state === "inactive") {
                              setshowAccountStatus(true);
                              setComponenttoshow2(false);
                            }
                          }}
                          onCloseCorp={() => {
                            setShowpopup(!showpopup);
                            setComponenttoshow2(true);
                            setComponenttoshow(false);
                          }}
                          onClose={() => {
                            setShowpopup(!showpopup);
                          }}
                          handleClose={() => setShowpopup(!showpopup)}
                        />
                      </DialogOutClick>
                    ) : null}

                    {componenttoshow2 ? (
                      <DialogOutClick
                        open={showpopup2}
                        handleClose={() => setShowpopup2(!showpopup2)}
                      >
                        <EnquiryForm
                          item={view}
                          onFinish={(state) => {
                            setnewState(state);
                            if (state === "inactive") {
                              setshowAccountStatus(true);
                              setShowpopup2(false);
                              setComponenttoshow2(false);
                            } else {
                              setShowpopup2(!showpopup2);
                            }
                          }}
                          closePopup={() => setShowpopup2(false)}
                        />
                      </DialogOutClick>
                    ) : null}
                  </>
                );
              })
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "50px 20px",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "var(--main)",
                  flexDirection: "column",
                }}
              >
                {loading ? (
                  <PuffLoader
                    color="#50b14a"
                    loading={loading}
                    speedMultiplier={1}
                    size={100}
                  />
                ) : (
                  <>
                    <span>
                      {" "}
                      <BiNotificationOff size="50px" />
                    </span>
                    <span>
                      Item no longer in open delivery page. sender might have
                      accepted a quote from a provider or the delivery is no
                      longer required. Search{" "}
                      <span
                        style={{
                          color: "var(--lighty)",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => history.push(open_deliveries)}
                      >
                        open deliveries
                      </span>{" "}
                      for other opportunities
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
            <Footer />
        </div>

        <DialogOutClick
          open={showAMsender}
          handleClose={() => setshowAMsender(false)}
        >
          <>
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                padding: "20px",
              }}
            >
              <img src={erros} alt="dillivry" style={{ width: "60px" }} />
              <div
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: "500",
                  width: "100%",
                }}
              >
                <div>You cannot perform this action as a sender</div>
                <div>Login as a provider or create account to continue</div>
              </div>

              <Button
                size="small"
                onClick={() => {
                  history.push(deliver_with_us);
                }}
                variant="contained"
                style={{
                  fontSize: "12px",
                  border: "1px solid var(--main)",
                  backgroundColor: "white",
                  color: "var(--main)",
                  textTransform: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span>
                    <AiOutlineUserAdd size="12px" />
                  </span>
                  <span>Create account</span>
                </div>
              </Button>
            </div>
          </>
        </DialogOutClick>
      </div>
    </>
  );
}
