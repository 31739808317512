import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import dillivrylogo from "../../../Sender/SenderDashboard/Assets/dillivrylogo.svg";
import sett1 from "../../../../assets/sett1.svg";
import sett2 from "../../../../assets/sett2.svg";
import userAvatar from "../../../../assets/userAvatar.jpeg";
import user1 from "../../../../assets/user1.svg";
import user2 from "../../../../assets/user2.svg";
import feed1 from "../../../../assets/feed1.svg";
import tran1 from "../../../../assets/tran1.svg";
import tran2 from "../../../../assets/tran2.svg";
import delete1 from "../../../../assets/delete1.svg";
import DialogOutClick from "../../../../components/DialogOutClick";
import Swal from "sweetalert2";
import { AuthContext } from "../../../../context/AuthContext";
import { PuffLoader } from "react-spinners";
import axios from "axios";
import SecureLS from "secure-ls";
import { ConnContext } from "../../../../context/ConnContext";
import GlobalizationLanguage from "../../../../components/GlobalizationLanguage";
import { DashboardContext } from "../../../../context/Dashboard/DashboardProvider/dashboard.provider";

function AccountSidebar({ accountInfo }) {
  const location = useLocation();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [showDeteleAcct, setShowDeteleAcct] = useState(false);
  const [deleteInput, setdeleteInput] = useState("");
  const [loading, setloading] = useState(false);
  const endpoint = process.env.REACT_APP_API;
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const { visitingCountryCode } = useContext(ConnContext);
  const [registered_country, setregistered_country] = useState([])

  const {
    dashboards
  } = useContext(DashboardContext);

useEffect(() => {
  if (dashboards !== undefined) {
  setregistered_country(dashboards?.data.account_profile?.account_profile[0]?.registered_countries)
  }
}, [dashboards])

  const QuoteData = [
    {
      path: GlobalizationLanguage("/account"),
      name: "Transaction",
      icon: tran1,
      iconclicked: tran2,
      click: false,
    },
    {
      // path: "/activedilivry/awaitingquote",
      path: GlobalizationLanguage("/settlements"),
      name: "Settlements",
      icon: sett1,
      iconclicked: sett2,
      click: false,
    },
    {
      path: GlobalizationLanguage("/userManager"),
      name: "User Manager",
      icon: user1,
      iconclicked: user2,
      click: false,
    },
    {
      path: GlobalizationLanguage("/6994622167893963"),
      name: "Feedback",
      icon: feed1,
      iconclicked: feed1,
      click: false,
    },
  ];
  const disableAccount = () => {
    axios
      .delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
        headers: {
          Authorization: ls.get("_nodi"),
        },
        data: {
          action_type: "disable_account",
          vcc: registered_country[0],
        },
      })
      .then((res) => {
        setShowDeteleAcct(false);
        Swal.fire({
          title: "Account disabled",
          text: "contact the dillivry team to enable you account back",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            auth.logout();
            history.push("/");
          }
        });
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              auth.logout();
              history.push("/login");
            }
          });
        } else {
          Swal.fire({
            icon: "info",
            text: "An error occur try again",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      });
  };

  const deleteMyAccount = () => {
    setloading(true);
    axios
      .delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
        headers: {
          Authorization: ls.get("_nodi"),
        },
        data: {
          action_type: "delete_account",
          vcc: registered_country[0],
        },
      })
      .then((res) => {
        setShowDeteleAcct(false);

        Swal.fire({
          title: "Account deleted",
          text: "We are very sorry to see you go",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            auth.logout();
            history.push("/");
          }
        });
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              auth.logout();
              history.push("/login");
            }
          });
        } else {
          Swal.fire({
            icon: "info",
            text: "An error occur try again",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      });
  };

  return (
    <>
      <div className="sidemenudetails accountside">
        <div>
          <div
            className="corpnav-business-details"
            style={{ padding:'20px' }}
          >
            <div
              className="nav-img"
              style={{
                backgroundImage:
                  accountInfo?.cop_logo !== null
                    ? `url(${accountInfo?.cop_logo})`
                    : accountInfo?.profile_photo !== ""
                    ? `url(${accountInfo?.profile_photo})`
                    : `url(${userAvatar})`,
              }}
            ></div>

            <div className="nav-business-name">
              <div>
                <p className="nav-title" style={{ marginTop: "20px" }}>
                  {accountInfo?.account_name ? (
                    <div>
                      {accountInfo?.role === "dillivry-corp-sys-admin" ? (
                        <div style={{ display: "flex", cursor: "pointer" }}>
                          <p className="nav-title">
                            {accountInfo?.account_name
                              ?.charAt(0)
                              .toUpperCase() +
                              accountInfo?.account_name?.slice(1)}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </p>
              </div>
              <span className="nav-title">
                {accountInfo?.account_holder ? (
                  <span className="nav-title">
                    {accountInfo.account_holder.first_name
                      .charAt(0)
                      .toUpperCase() +
                      accountInfo.account_holder.first_name.slice(1) +
                      " " +
                      accountInfo?.account_holder?.last_name}
                  </span>
                ) : null}
              </span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {QuoteData.map((x, i) => {
              return (
                <div
                  style={{
                    marginTop: "0px",
                    cursor: "pointer",
                    userSelect: "none",
                  }}
                  key={i}
                >
                  <div
                    onClick={() => {
                      history.push(`${x.path}`);
                      setShowDeteleAcct(x.click);
                    }}
                    className="image-contentx"
                  >
                    {location.pathname.includes(x.path) ? (
                      <img
                        src={x.iconclicked}
                        alt="iconimage"
                        width="25px"
                        className="side-img"
                      />
                    ) : (
                      <img
                        src={x.icon}
                        width="25px"
                        alt="iconimage"
                        className="side-img"
                      />
                    )}
                    <p
                      className={
                        location.pathname.includes(x.path)
                          ? "active4 "
                          : "inactive3"
                      }
                    >
                      {x.name}
                    </p>
                  </div>
                </div>
              );
            })}
            <div
              style={{
                marginTop: "0px",
                cursor: "pointer",
                userSelect: "none",
              }}
            >
              <div
                onClick={() => setShowDeteleAcct(true)}
                className="image-contentx"
              >
                {showDeteleAcct ? (
                  <img
                    src={delete1}
                    alt="iconimage"
                    width="25px"
                    className="side-img"
                  />
                ) : (
                  <img
                    src={delete1}
                    width="25px"
                    alt="iconimage"
                    className="side-img"
                  />
                )}
                <p>Delete account</p>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-logo" onClick={() => history.push("/")}>
          <img
            src={dillivrylogo}
            alt="dillivrylogo"
            className="bottom-logo-img"
          />
        </div>
      </div>

      <DialogOutClick
        open={showDeteleAcct}
        handleClose={() => setShowDeteleAcct(false)}
      >
        <div style={{ width: "100%", padding: "30px 30px 20px 30px" }}>
          <div
            style={{
              width: "100%",
              marginBottom: "30px",
              fontSize: "14px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>
              Are you sure you want to delete this account? Type in{" "}
              <b>permanently delete</b> in the input below to confirm deletion
            </span>
          </div>
          <div style={{ marginBottom: "30px" }}>
            <input
              type="text"
              placeholder="permanently delete"
              value={deleteInput}
              onChange={(e) => setdeleteInput(e.target.value)}
              style={{
                border: "1px solid var(--main)",
                padding: "7px 10px",
                outline: "none",
                borderRadius: "5px",
                fontSize: "13px",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="disBtn"
              style={{
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
                border: "#0080FF solid 1px",
                backgroundColor: "#0080FF",
                color: "white",
              }}
              onClick={disableAccount}
            >
              Disable instead
            </div>
            <div
              className="disBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => setShowDeteleAcct(false)}
            >
              Cancel
            </div>
            {deleteInput === "permanently delete" ? (
              <div
                className="ediBtn"
                style={{
                  width: "50px",
                  fontSize: "13px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "red",
                  border: "1px solid red",
                }}
                onClick={deleteMyAccount}
              >
                {loading ? (
                  <PuffLoader
                    color="var(--main)"
                    loading={loading}
                    speedMultiplier={1}
                    size={15}
                  />
                ) : (
                  "Yes"
                )}
              </div>
            ) : (
              <div
                className="ediBtn"
                style={{
                  width: "50px",
                  fontSize: "13px",
                  fontWeight: "bold",
                  cursor: "not-allowed",
                  color: "white",
                  backgroundColor: "#B86566",
                  border: "1px solid #B86566",
                }}
              >
                Yes
              </div>
            )}
          </div>
        </div>
      </DialogOutClick>
    </>
  );
}
export default AccountSidebar;
