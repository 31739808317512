import React, { useEffect,useContext, useState } from 'react'
import './Message.css';
import {MdNavigateNext} from 'react-icons/md'
import SmallBtn from '../../../components/SmallBtn';
import {TiArrowBack} from 'react-icons/ti'
import axios from 'axios';
import Swal from 'sweetalert2'
import PuffLoader from 'react-spinners/PuffLoader';

import warngreen from "../../../assets/warngreen.svg"
import warnrred from "../../../assets/warnrred.svg"
import { AiFillBackward, AiFillForward } from 'react-icons/ai';
import { IoCaretBack, IoCaretForward } from 'react-icons/io5';
import moment from 'moment'
import { BsCheckAll } from 'react-icons/bs';
import {useHistory} from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import SecureLS from 'secure-ls';

import userAvatar from '../../../assets/userAvatar.jpeg'
import { ConnContext } from '../../../context/ConnContext';
import GlobalizationLanguage from '../../../components/GlobalizationLanguage';
function MessageFulfiller({hide, myDP, msg, from, handleChange}) {
  var timeout = GlobalizationLanguage("/timeout")

    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
    
    const [viewMsg, setviewMsg] = useState('default')
    const [enquiryMsg, setEnquiryMsg] = useState([])
    const [reply, setReply] = useState('');
    const [dmReply, setDmReply] = useState('');
    const [conversation, setconversation] = useState([])
    const [DMconversation, setDMconversation] = useState([])
    const [subject, setsubject] = useState('')
    const [delivery_id, setdelivery_id] = useState('')
    const [fulfiller_id, setfulfiller_id] = useState('')
    const [sendingMsg, setsendingMsg] = useState(false)
    const [pickup, setpickup] = useState('')
    const [destination, setdestination] = useState('')
    const [image, setimage] = useState([]);
    const [msgLength, setmsgLength] = useState(0)
    const [paginateActivePage, setPaginateActivePage] = useState(1)
    const [error, seterror] = useState(false);
    const [sender, setsender] = useState('')
    const [DMsender, setDMsender] = useState('')
    
    const history = useHistory()

    const auth = useContext(AuthContext);


    useEffect(() => {
        window.scrollTo(0,0);
      
        console.log(
            msg
        )
        setmsgLength(msg.length);
        setEnquiryMsg(msg.slice(0, n))
        setDMconversation(msg[0]?.conversation);
        setDMsender(msg[0]?.sender_name);
    }, [])
    function reverseArr(input) {
        var ret = new Array;
        for(var i = input?.length-1; i >= 0; i--) {
            ret.push(input[i]);
        }
        return ret;
    }

    const n = 10;

    const endpoint = process.env.REACT_APP_API
    const {visitingCountryCode} = useContext(ConnContext)
    const authAxios = axios.create({
        baseURL: `${endpoint}`,
        headers:{
           Authorization:  ls.get('_nodi')
        }
    })

    const insert = (arr, index, ...newItems) => [
        ...arr.slice(0, index),
        ...newItems,
        ...arr.slice(index)
    ]

   const replyMessageDM = ()=>{
    if(dmReply === '') {
        seterror(true)
    }
    else{
        seterror(false)
        setsendingMsg(true)
        authAxios.post(`m-dillivry-bid-manager`,{
            action_type:"quote",
            delivery_id: msg[0]?.delivery_id,
            msg: dmReply,
            vcc: visitingCountryCode
        }, {timeout: 240000})
        .then((res)=>{
        setsendingMsg(false)
        setDmReply('');
        let new_msg = {"fulfiller_id": msg[0]?.fulfiller_id, "msg":dmReply, "time_stamp": new Date()}
        let new_conversation = insert(DMconversation,0, new_msg);
        setDMconversation(new_conversation)
        
            // if(res.status === 200){
            //     Swal.fire({
            //         icon: 'success',
            //         text: 'Message sent',
            //         timer: 2500,
            //         showConfirmButton: false,
            //     })
            // }
        })
        .catch((err)=>{
            if (err.code === "ECONNABORTED") {
                console.log('internal-server-error-timeout')
                history.push(timeout)
                // window.location.reload()
              }
            setsendingMsg(false)

            Swal.fire({
                icon: 'info',
                text: 'item has been delivered, Message not sent',
                timer: 3000,
                showConfirmButton: false,
            })
        })
    }
   }
    const replyMessage = () =>{
        if(reply === '') {
            seterror(true)
        }
        else{
            seterror(false)
            setsendingMsg(true)
            authAxios.post(`m-dillivry-bid-manager`,{
                action_type:"quote",
                delivery_id: delivery_id,
                msg: reply,
                vcc: visitingCountryCode
            }, {timeout: 240000})
            .then((res)=>{
                setReply('');

                let new_msg = {"fulfiller_id":fulfiller_id, "msg":reply,"time_stamp": new Date()}
                let new_conversation = insert(conversation,0, new_msg);
                setconversation(new_conversation);
                setsendingMsg(false)

            })
            .catch((err)=>{
                if (err.code === "ECONNABORTED") {
                    console.log('internal-server-error-timeout')
                    history.push(timeout)
                    // window.location.reload()
                  }
                setsendingMsg(false)

                if(err?.response?.status === 403){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Session!',
                        text: 'Session expired login again!',
                        allowOutsideClick: false,
                        confirmButtonColor: 'var(--main)'
                    })
                        .then((result) => {
                            if (result.value) {
                                auth.logout();
                                history.push(`/login`);
                            }
                        });
                }
                else if(err?.response?.status === 500){
                    Swal.fire({
                        icon: 'info',
                        text: 'item has been delivered, message not sent',
                        timer: 4000,
                        showConfirmButton: false,
                    })
                }
                else{
                    console.log(err?.response?.status)
                    Swal.fire({
                        icon: 'info',
                        text: 'Ops! Message not sent, try again later',
                        timer: 4000,
                        showConfirmButton: false,
                    })
                }
            })
        }

        
    }
    return (  
        <div className="context" style={{padding:'20px 0 0 0', backgroundColor:'white'}}>
            {
                from === "items" ?
                <div className="padding4Mobile">
                    {
                        hide === 'yes' ?
                        null
                        :
                        <div style={{width:'200px', marginBottom:"15px"}}>
                            <SmallBtn 
                                label='Back'
                                icon={<TiArrowBack/>}
                                onClick={handleChange}
                            /> 
                        </div> 
                    }
                    <div className="msgView" style={{marginTop:"15px"}}>
                        <div className="msgViewContainer">
                            <div className="msgViewContainerTop">
                                <div className="msgViewContainerTopImage" style={{ backgroundImage:`url(${msg[0]?.image_urls})`}}>   
                                </div>
                                <div className="msgViewTopText">
                                    <p> {msg[0]?.quote_subject.charAt(0)?.toUpperCase() + msg[0]?.quote_subject.slice(1) +" ("+msg[0]?.delivery_id + ")" }</p>
                                    <p> <img src={warngreen} alt="msgpi_icon"/>&ensp;{msg[0]?.pickup_address}</p>
                                    <p> <img src={warnrred} alt="msgpi_icon"/>&ensp;{msg[0]?.delivery_address}</p>
                                </div>
                            </div>
                            {
                                
                        reverseArr(DMconversation).map((converse, index)=>{ return <div key={index} >
                        {
                            converse.fulfiller_id === undefined ? 
                                 <div className="msgCard" style={{backgroundColor:'#E6F4E5'}}>
                                        <div className="msgCardPics" style={{backgroundImage: `url(${userAvatar})`}}>
                                        </div>
                                        <div className="msgCardChat">
                                            <h4>{DMsender}</h4>
                                            <span> 
                                                {converse.msg}
                                            </span>
                                        </div>
                                        <span style={{position:'absolute', top:'10px', right:'10px'}}>
                                            <div className="timediplaydesk" style={{fontSize:"10px"}}>
                                                {moment(converse.time_stamp).format("MMM Do YYYY, h:mm a ")}
                                                ({moment(converse.time_stamp).fromNow()})
                                            </div>
                                            <div className="timediplaymobile" style={{fontSize:"7px"}}>
                                                {moment(converse.time_stamp).format("MMM Do YYYY, h:mm a ")}
                                                ({moment(converse.time_stamp).fromNow()})
                                            </div>
                                        </span>
                                        <span style={{position:'absolute', bottom:'10px', right:'10px'}}>
                                            <BsCheckAll color="var(--main)" size='18px'/>
                                        </span>
                                    </div>
                                        :
                                        <div className="msgCard">
                                            <div className="msgCardPics" style={{ backgroundImage:`url(${myDP})`}}>
                                            </div>
                                            <div className="msgCardChat">
                                                <h4>{converse.fulfiller_id}</h4>
                                                <span>
                                                    {converse.msg}
                                                </span>
                                            </div>
                                            <span style={{position:'absolute', top:'10px', right:'10px'}}>
                                                <div className="timediplaydesk" style={{fontSize:"10px"}}>
                                                    {moment(converse.time_stamp).format("MMM Do YYYY, h:mm a ")}
                                                    ({moment(converse.time_stamp).fromNow()})
                                                </div>
                                                <div className="timediplaymobile" style={{fontSize:"7px"}}>
                                                    {moment(converse.time_stamp).format("MMM Do YYYY, h:mm a ")}
                                                    ({moment(converse.time_stamp).fromNow()})

                                                </div>
                                            </span>
                                            <span style={{position:'absolute', bottom:'10px', right:'10px'}}>
                                                <BsCheckAll color="var(--main)" size='18px'/>
                                            </span>
                                        </div>
                                    }
                            </div>
                            })}
                        </div>
                    </div>
                    
                    <div style={{padding:'20px', border:'1px solid #c4c4c4', borderTop:'none'}}>
                        <textarea className='textarea' value={dmReply} onChange={(e)=>{setDmReply(e.target.value);seterror(false)}} placeholder='Type your message...' style={{ width: '100%', backgroundColor: "white", border: error ? "1px solid red" : "1px solid #C4C4C4", color:'black', }}  rows="4"/>   
                        {/* <textarea className='textarea' value={reply} onChange={(e)=>{setReply(e.target.value);seterror(false)}} placeholder='Type your message...' style={{ width: '100%', backgroundColor: "white", border: error ? "1px solid red" : "1px solid #C4C4C4", color:'black', }}  rows="4"/>      */}

                        <div className="acceptBTN" style={{width:'200px'}} onClick={replyMessageDM}>
                            {
                                sendingMsg ?
                                <PuffLoader color='white' loading={sendingMsg}  speedMultiplier={1} size={16}/>
                                :
                                <>
                                    <span>Send message </span> <div><MdNavigateNext/></div>
                                </>
                            }
                        </div>
                    </div>

                        
                </div>
                :

                viewMsg === 'view'? 
                <div className="padding4Mobile">
                    <div style={{width:'150px'}} onClick={()=>setviewMsg('default')}>
                        <SmallBtn 
                            label='Back to inbox'
                            icon={<TiArrowBack/>}
                        /> 
                    </div>
                    
                    <div className="msgView" style={{marginTop:"15px"}}>
                        <div className="msgViewContainer">
                            <div className="msgViewContainerTop">
                                <div className="msgViewContainerTopImage" style={{ backgroundImage:`url(${image})`}}>   
                                </div>
                                <div className="msgViewTopText">
                                    <p> {subject.charAt(0)?.toUpperCase() + subject.slice(1) +" ("+ delivery_id + ")" }</p>

                                    <p> <img src={warngreen} alt="msgpi_icon"/>&ensp;{pickup}</p>
                                    <p> <img src={warnrred} alt="msgpi_icon"/>&ensp;{destination}</p>
                                </div>
                            </div>
                            {
                        reverseArr(conversation).map((converse, index)=>{ return <div key={index} >
                        {
                            converse.fulfiller_id === undefined ? 
                                converse.user_id === ls.get('eid') ? 
                                    <div className="msgCard">
                                        <div className="msgCardPics" style={{ backgroundImage:`url(${myDP})`}}>
                                        </div>
                                        <div className="msgCardChat">
                                            <h4>Me</h4>
                                            <span> 
                                                {converse.msg}
                                            </span>
                                        </div>
                                        <span style={{position:'absolute', top:'10px', right:'10px'}}>
                                            <div className="timediplaydesk" style={{fontSize:"10px"}}>
                                                {moment(converse.time_stamp).format("MMM Do YYYY, h:mm a ")}
                                                ({moment(converse.time_stamp).fromNow()})
                                            </div>
                                            <div className="timediplaymobile" style={{fontSize:"7px"}}>
                                                {moment(converse.time_stamp).format("MMM Do YYYY, h:mm a ")}
                                                ({moment(converse.time_stamp).fromNow()})
                                            </div>
                                        </span>
                                        <span style={{position:'absolute', bottom:'10px', right:'10px'}}>
                                            <BsCheckAll color="var(--main)" size='18px'/>
                                        </span>
                                    </div>
                                    :
                                    <div className="msgCard" style={{backgroundColor:'#E6F4E5'}}>
                                        <div className="msgCardPics" style={{backgroundImage: `url(${userAvatar})`}}>
                                        </div>
                                        <div className="msgCardChat">
                                            <h4>{sender}</h4>
                                            <span> 
                                                {converse.msg}
                                            </span>
                                        </div>
                                        <span style={{position:'absolute', top:'10px', right:'10px'}}>
                                            <div className="timediplaydesk" style={{fontSize:"10px"}}>
                                                {moment(converse.time_stamp).format("MMM Do YYYY, h:mm a ")}
                                                ({moment(converse.time_stamp).fromNow()})
                                            </div>
                                            <div className="timediplaymobile" style={{fontSize:"7px"}}>
                                                {moment(converse.time_stamp).format("MMM Do YYYY, h:mm a ")}
                                                ({moment(converse.time_stamp).fromNow()})

                                            </div>
                                        </span>
                                        <span style={{position:'absolute', bottom:'10px', right:'10px'}}>
                                            <BsCheckAll color="var(--main)" size='18px'/>
                                        </span>
                                        
                                    </div>
                                        :
                                        <div className="msgCard">
                                            <div className="msgCardPics" style={{ backgroundImage:`url(${myDP})`}}>
                                            </div>
                                            <div className="msgCardChat">
                                                <h4>{converse.fulfiller_id}</h4>
                                                <span> 
                                                    {converse.msg}
                                                </span>
                                            </div>
                                            <span style={{position:'absolute', top:'10px', right:'10px'}}>
                                                <div className="timediplaydesk" style={{fontSize:"10px"}}>
                                                    {moment(converse.time_stamp).format("MMM Do YYYY, h:mm a ")}
                                                    ({moment(converse.time_stamp).fromNow()})
                                                </div>
                                                <div className="timediplaymobile" style={{fontSize:"7px"}}>
                                                    {moment(converse.time_stamp).format("MMM Do YYYY, h:mm a ")}
                                                    ({moment(converse.time_stamp).fromNow()})

                                                </div>
                                            </span>
                                            <span style={{position:'absolute', bottom:'10px', right:'10px'}}>
                                                <BsCheckAll color="var(--main)" size='14px'/>
                                            </span>
                                        </div>
                                    }
                            </div>
                            })}
                        </div>
                    </div>
                    <div style={{padding:'20px', border:'1px solid #c4c4c4', borderTop:'none'}}>
                        <textarea className='textarea' value={reply} onChange={(e)=>{setReply(e.target.value);seterror(false)}} placeholder='Type your message...' style={{ width: '100%', backgroundColor: "white", border: error ? "1px solid red" : "1px solid #C4C4C4", color:'black', }}  rows="4"/>     
                        <div className="acceptBTN" style={{width:'200px'}} onClick={replyMessage}>
                            {
                                sendingMsg ?
                                <PuffLoader color='white' loading={sendingMsg}  speedMultiplier={1} size={16}/>
                                :
                                <>
                                    <span>Send message </span> <div><MdNavigateNext/></div>
                                </>
                            }
                        </div>
                    </div>
                        
                </div>
               
                :

                <div className="msgDivCont">
                    
                    <div>
                        <div className="topPaginate noshow" style={{marginBottom:'0'}}>
                                <p style={{color:'var(--main)'}}> Result:  {((paginateActivePage * n) - (n - 1))} of {paginateActivePage * n}</p>
                            <div>
                                <span className="paginateButtons" style={{color: paginateActivePage === 1 ? 'white' : '',backgroundColor: paginateActivePage === 1 ? 'var(--main)' : '', cursor: paginateActivePage === 1 ? 'not-allowed' :  'pointer'}} onClick={()=>{
                                    setEnquiryMsg(msg.slice(0, n));
                                    setPaginateActivePage(1)
                                }}>First</span> 
                                {
                                    paginateActivePage === 1 ?
                                    <span className="paginateButtons" style={{color:'#989898', cursor:'not-allowed'}} >Previous</span>
                                    :
                                    <span className="paginateButtons" onClick={()=>{
                                        setEnquiryMsg(msg.slice(((paginateActivePage-2)* n),((paginateActivePage - 1) * n)));
                                        setPaginateActivePage(paginateActivePage - 1);
                                    }}>Previous</span>
                                }
                                 
                                { 
                                    [...Array(Math.ceil(msgLength/n))].map((e, i) => <span className='paginateButtons' style={{backgroundColor: paginateActivePage === i + 1 ? 'var(--main)' : '', color:paginateActivePage === i + 1 ? 'white' : ''}}  key={i} onClick={()=>{
                                        setEnquiryMsg(msg.slice((n*i), (n*i)+n));
                                        setPaginateActivePage(i+1)
                                    }}
                                     >{i + 1}</span>) 
                                }
                                {
                                    paginateActivePage === Math.ceil(msgLength/n) ? 
                                    <span className="paginateButtons" style={{color:'#989898', cursor:'not-allowed'}}>Next</span>
                                    :
                                    <span className="paginateButtons" onClick={()=>{
                                        setEnquiryMsg(msg.slice(n * paginateActivePage, n * (paginateActivePage + 1)));
                                        setPaginateActivePage(paginateActivePage + 1);
                                    }}>Next</span>
                                }
                                <span className="paginateButtons" style={{backgroundColor: paginateActivePage === Math.ceil(msgLength/n) ? 'var(--main)' : '', color: paginateActivePage === Math.ceil(msgLength/n) ? 'white':''}} onClick={()=>{
                                        setEnquiryMsg(msg.slice((Math.floor(msgLength/n)*n), msgLength))
                                        setPaginateActivePage(Math.ceil(msgLength/n));
                                    }}>Last</span> 
                            </div>
                        </div>
                        {/* <div className="messageCheck">
                            <select className="messageType" onChange={(e)=>setMessageType(e.target.value)}>
                                <option value="allMessages">All messages</option>
                                <option value="unreadMessages">Unread messages</option>
                                <option value="readMessages">Read messages</option>
                            </select>
                        </div> */}
                            <div className='messagess noshow'>
                                <div className="messageFrom" style={{color:'black', fontSize:'16px'}}>From </div>
                                <div className="messageSubj" style={{color:'black', fontSize:'16px'}}> Subject</div>
                                <div className="messageText" style={{color:'black', fontSize:'16px', fontWeight:'500'}}> Message</div>
                                <div className="messageTime" style={{color:'black', fontSize:'16px'}}>Recieved </div>
                            </div>

                            <div className="mobileMsgHead">
                                Messages
                            </div>

                            {enquiryMsg.map((msg, index)=>{return <div key={index} className='messagess' onClick={()=>{
                                setviewMsg('view');
                                setconversation(msg.conversation)
                                setsubject(msg.quote_subject)
                                setpickup(msg.pickup_address)
                                setdestination(msg.delivery_address)
                                setimage(msg.image_urls[0])
                                setdelivery_id(msg['delivery_id'])
                                setsender(msg?.sender_name)
                                // setsender(msg['sender_name'])
                                setfulfiller_id(msg['fulfiller_id'])}}> 
                            {
                                enquiryMsg.length === 0 ? 
                                <div className="tablerowwrapper">
                                    You dont have message yet
                                </div>
                                :
                                <>
                                    <div className="messageFrom noshow"> 
                                        {
                                            msg.conversation[0]?.fulfiller_id === undefined ?
                                                msg.conversation[0]?.user_id === ls.get('eid') ? 
                                                    <div className="tableRowfrom">{msg?.sender_name}</div>
                                                    :
                                                    <div className="tableRowfrom">{msg?.sender_name}</div>
                                            :
                                            <div className="tableRowfrom">{msg?.sender_name}</div>
                                        }
                                    </div>
                                    <div className="messageSubj"> 
                                        <p onClick={() => {setviewMsg('view'); setsender(msg?.sender_name)}}> 
                                            { 
                                                msg?.quote_subject.charAt(0)?.toUpperCase() + msg?.quote_subject.slice(1) 
                                                
                                            }
                                        </p>
                                        
                                        <span className='showNow'>{msg.conversation[0]?.msg}</span>
                                    </div>
                                    <div className="messageText">
                                        {msg.conversation[0]?.msg}
                                    </div>
                                    <div className="messageTime" style={{fontSize:"10px"}}>
                                        { moment().diff(msg.msg_time_stamp, 'days') === 0?
                                            <> 
                                                {moment(msg.msg_time_stamp).format("hh:mm A")}
                                            </>
                                            :
                                            <> 
                                                {moment(msg.msg_time_stamp).format("MMM D") }
                                            </>              
                                        } 
                                    </div>
                                    
                                </>
                                }
                            </div>})}
                            <div className="mobileMsgfooter">
                                <div>
                                    <span className="paginateButtons" style={{color: paginateActivePage === 1 ? 'white' : '',backgroundColor: paginateActivePage === 1 ? 'var(--main)' : '', cursor: paginateActivePage === 1 ? 'not-allowed' :  'pointer'}} onClick={()=>{
                                        setEnquiryMsg(msg.slice(0, n));
                                        setPaginateActivePage(1)
                                    }}><AiFillBackward/></span> 
                                    {
                                        paginateActivePage === 1 ?
                                        <span className="paginateButtons" style={{color:'#989898', cursor:'not-allowed'}} ><IoCaretBack/></span>
                                        :
                                        <span className="paginateButtons" onClick={()=>{
                                            setEnquiryMsg(msg.slice(((paginateActivePage-2)* n),((paginateActivePage - 1) * n)));
                                            setPaginateActivePage(paginateActivePage - 1);
                                        }}><IoCaretBack/></span>
                                    }
                                    
                                    { 
                                        [...Array(Math.ceil(msgLength/n))].map((e, i) => <span className='paginateButtons' style={{backgroundColor: paginateActivePage === i + 1 ? 'var(--main)' : '', color:paginateActivePage === i + 1 ? 'white' : ''}}  key={i} onClick={()=>{
                                            setEnquiryMsg(msg.slice((n*i), (n*i)+n));
                                            setPaginateActivePage(i+1)
                                        }}
                                        >{i + 1}</span>) 
                                    }
                                    {
                                        paginateActivePage === Math.ceil(msgLength/n) ? 
                                        <span className="paginateButtons" style={{color:'#989898', cursor:'not-allowed'}}><IoCaretForward/></span>
                                        :
                                        <span className="paginateButtons" 
                                        onClick={()=>{
                                            setEnquiryMsg(msg.slice(n * paginateActivePage, n * (paginateActivePage + 1)));
                                            setPaginateActivePage(paginateActivePage + 1);
                                        }}><IoCaretForward/></span>
                                    }
                                    <span className="paginateButtons" style={{backgroundColor: paginateActivePage === Math.ceil(msgLength/n) ? 'var(--main)' : '', color: paginateActivePage === Math.ceil(msgLength/n) ? 'white':''}} onClick={()=>{
                                            setEnquiryMsg(msg.slice((Math.floor(msgLength/n)*n), msgLength))
                                            setPaginateActivePage(Math.ceil(msgLength/n));
                                        }}><AiFillForward/></span> 
                                </div>
                            </div>
                            <div className="DesktopMsgfooter">
                            <p> Result:  {((paginateActivePage * n) - (n - 1))} of {paginateActivePage * n}</p>
                            </div>
                    </div>

                    
                </div>

            }
        </div>
    )
}
export default MessageFulfiller
