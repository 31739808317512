import { useEffect, useContext, useState } from "react";
import Layout from "../../Layout";
import "./Message.css";
import userAvatar from "../../../../../assets/userAvatar.jpeg";
import send from "../../../../../assets/Stroke.svg";
import horiz from "../../../../../assets/horiz.svg";
import SecureLS from "secure-ls";
import moment from "moment";
import { AuthContext } from "../../../../../context/AuthContext";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory, useParams, useLocation } from "react-router-dom";
import PuffLoader from "react-spinners/PuffLoader";
import { ConnContext } from "../../../../../context/ConnContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import warngreen from "../../../../../assets/warngreen.svg";
import warnrred from "../../../../../assets/warnrred.svg";
import { SenderContext } from "../../../../../context/SenderDashboard/SenderDashboardContext";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { BsCheckAll } from "react-icons/bs";
import MessageDrilldownMobile from "./MessageDrilldownMobile";
import { MdKeyboardBackspace } from "react-icons/md";
import jwt_decode from "jwt-decode";
import DialogOutClick from "../../../../../components/DialogOutClick";
import LoginCompV2 from "../../../../../components/LoginCompV2";
import Cookies from "js-cookie";
import GlobalizationLanguage, {
  GlobalizationLanguagewithCOntryCode,
} from "../../../../../../src/components/GlobalizationLanguage";
import { IconButton, Tooltip } from "@mui/material";

export default function MessageDrilldown() {
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const { id } = useParams();

  const [idnew, setidnew] = useState(undefined);
  const [ful_id, setful_id] = useState("");
  const [idtouse, setidtouse] = useState();
  const [msgIndex, setMsgIndex] = useState(0);
  const [show, setshow] = useState(true);
  const [fulfiller_id, setfulfiller_id] = useState("");
  const [reply, setReply] = useState("");
  const [DMconversation, setDMconversation] = useState([]);
  const [todisplay, setTodisplay] = useState();
  const [sendingMsg, setsendingMsg] = useState(false);
  const [DMsender, setDMsender] = useState("");
  const history = useHistory();
  const [msg, setmsg] = useState([]);
  const [softRefresh, setsoftRefresh] = useState(false);
  const auth = useContext(AuthContext);
  const [no, setno] = useState(false);
  const [newMessage, setnewMessage] = useState([]);
  const [deliveryID, setdeliveryID] = useState("");
  const [delivery_location, setdelivery_location] = useState('')
  const [freshmsg, setfreshmsg] = useState([]);
  const [enquiryMsg, setEnquiryMsg] = useState([]);
  const [msgLength, setmsgLength] = useState(0);
  // const [paginateActivePage, setPaginateActivePage] = useState(1);
  const [viewData, setviewData] = useState([]);

  const [ShowLogin, setShowLogin] = useState(false);

  const location = useLocation();

  const refreshsoft = () => {
    window.location.reload();
  };

  const schema = yup.object().shape({
    message: yup
      .string()
      .required("This is required")
      .min(1, "Can't be lesser than 2 digits")
      .max(2500, "Can't exceed 20 digits"),
  });

  const {
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const {
    msgdrildown,
    createSenderMsg,
  } = useContext(SenderContext);
  const [isloading, setIsloading] = useState(false);
  const [isRequired, setisRequired] = useState(false);
  const [torefresh, settorefresh] = useState(false);
  const [requiredIndex, setRequiredIndex] = useState();
  const [replying, setReplying] = useState();
  const [tomap, settomap] = useState();
  const { visitingCountryCode } = useContext(ConnContext);
  var countryvalue =
    Cookies.get("i18next") || visitingCountryCode.toLowerCase();

  const [country, setCountry] = useState(countryvalue);

  useEffect(() => {
    setCountry(Cookies.get("i18next"));
  }, [Cookies.get("i18next")]);

  var sendermessage = GlobalizationLanguage("/sendermessage");

  const endpoint = process.env.REACT_APP_API;
  const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });

  const capitalize = (x) => {
    return x;
    //  x?.charAt(0).toUpperCase() + x.slice(1)
  };

  const messageHead = [
    {
      thead: "Subject",
    },
    {
      thead: "Message",
    },
    {
      thead: "Date & Time",
    },
  ];

  useEffect(() => {
    setIsloading(true);
    if (ls.get("eid") === "") {
      setno(true);
    } else {
      if (msgdrildown !== undefined) {
        setIsloading(false);
        if (location?.state === undefined) {
          msgFromMsgSection(msgdrildown);
        } else {
          msgFromQuoteDrilldown(msgdrildown);
        }
      } else {
        setIsloading(true);
        axios
          .get(
            `${process.env.REACT_APP_API}m-dillivry-sender-api?attribute=get_message_list`,
            {
              headers: {
                Authorization: ls.get("_utk"),
              },
            }
          )
          .then((response) => {
            console.log(response, "response i")
            if (response.status === 200) {
              setIsloading(false);
              if (location?.state === undefined) {
                msgFromMsgSection(
                  response?.data?.data
                    ?.map((x) => x?.ng ?? x?.gb)
                    ?.flat()
                    .map((x) => x?.quote_messages)
                    .flat()
                );
              } else {
                msgFromQuoteDrilldown(
                  response?.data?.data
                    ?.map((x) => x?.ng ?? x?.gb)
                    ?.flat()
                    .map((x) => x?.quote_messages)
                    .flat()
                );
              }
            }
          })
          .catch((error) => {
            if (error.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push(
                GlobalizationLanguagewithCOntryCode(
                  `/timeout`,
                  country,
                  visitingCountryCode
                )
              );
            }
          });
      }
    }
  }, [softRefresh, idnew,]);

  useEffect(() => {
    setIsloading(true);
    if (msgdrildown !== undefined) {
      let Data = [];
      Data = msgdrildown;
      setTodisplay(
        msgdrildown
          ?.filter((x) => x?.delivery_id === idtouse)
          ?.map((x) => x?.fulfiller_conversation)[0]
          ?.map((x) => x?.conversation)[0]
      );
      setviewData(Data);
      setmsgLength(Data.length);
      setEnquiryMsg(Data);
      setIsloading(false);
    } else {
      setIsloading(true);
      axios
        .get(
          `${process.env.REACT_APP_API}m-dillivry-sender-api?attribute=get_message_list`,
          {
            headers: {
              Authorization: ls.get("_utk"),
            },
          }
        )

        .then((response) => {
          console.log(response, "response iI")

          if (response.status === 200) {
            setIsloading(false);
            ls.set("fo_in", JSON.stringify(response.data.account));
            let Data = [];
            Data = response?.data?.data
              ?.map((x) => x?.ng ?? x?.gb)
              ?.flat()
              .map((x) => x?.quote_messages)
              .flat();
            setviewData([...Data]);
            setmsgLength(Data.length);
            setEnquiryMsg(Data);
            setTodisplay(
              Data?.filter((x) => x?.delivery_id === id)
                .map((x) => x?.fulfiller_conversation)[0]
                ?.map((x) => x?.conversation)[0]
            );
            setReply(" ");
            setIsloading(false);
          }
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            console.log("internal-server-error-timeout");
            history.push(
              GlobalizationLanguagewithCOntryCode(
                `/timeout`,
                country,
                visitingCountryCode
              )
            );
          }
        });
    }
  }, [idnew]);

  useEffect(() => {
    settomap(msgdrildown);
  }, [torefresh, msgdrildown]);

  function reverseArr(input) {
    var ret = new Array();
    for (var i = input?.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  const insert = (arr, index, ...newItems) => [
    ...arr.slice(0, index),
    ...newItems,
    ...arr.slice(index),
  ];

  const tokenCheck = (index) => {
    const decoded = jwt_decode(ls.get("_utk"));
    const expired = decoded.exp;
    if (expired <= Math.floor(Date.now() / 1000)) {
      setShowLogin(true);
    } else {
      // setShowLogin(true)
      replyMessage(index);
      // setReply(" ")
    }
  };

  const replyMessage = (index) => {
    if (reply === "") {
      setRequiredIndex(index);
      setisRequired(true);
    } else {
      setisRequired(false);
      setsendingMsg(true);
      authAxios
        .post(
          `m-dillivry-quote-manager?delivery_location=${msg[0]?.delivery_location}`,
          {
            action_type: "quote_msg",
            delivery_id: msg[0]?.delivery_id,
            fulfiller_id: fulfiller_id,
            msg: reply,
            // vcc: visitingCountryCode,
          },
          { timeout: 240000 }
        )
        .then((res) => {
          if (res.status === 200) {
            setsendingMsg(false);
            setReply(" ");
            let new_msg = {
              user_id: ls.get("eid"),
              msg: reply,
              time_stamp: new Date(),
            };
            const converseFulfiller = DMconversation.filter(
              (x, i) => x.fulfiller_id === fulfiller_id
            );
            const index = DMconversation.findIndex(
              (x) => x.fulfiller_id === converseFulfiller[0].fulfiller_id
            );
            let new_conversation = insert(
              converseFulfiller[0].conversation,
              0,
              new_msg
            );
            DMconversation[index].conversation = new_conversation;
            setsoftRefresh(!softRefresh);
          }
        })
        .catch((err) => {
          console.log(err, "errrrr");
          setReply("");
          setsendingMsg(false);
          if (err?.response?.status === 403) {
            Swal.fire({
              icon: "warning",
              title: "Session!",
              text: "Session expired login again!",
              allowOutsideClick: false,
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              setsendingMsg(false);
              if (result.value) {
                auth.logout();
                history.push(
                  GlobalizationLanguagewithCOntryCode(
                    `/login`,
                    country,
                    visitingCountryCode
                  )
                );
              }
            });
          } else if (err?.response?.status === 401) {
            setReply("");
            setsendingMsg(false);
            Swal.fire({
              icon: "info",
              text: "item has been delivered, message not sent",
              timer: 4000,
              showConfirmButton: false,
            });
          } else {
            setsendingMsg(false);
            setReply("");
            Swal.fire({
              icon: "info",
              text: "Ops! Message not sent, try again later",
              timer: 4000,
              showConfirmButton: false,
            });
            if (err.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push(
                GlobalizationLanguagewithCOntryCode(
                  `/timeout`,
                  country,
                  visitingCountryCode
                )
              );
            }
            setsendingMsg(false);
          }
        });
    }
  };

  const initiateMessage = () => {
    if (reply === "") {
      setisRequired(true);
    } else {
      setsendingMsg(true);
      authAxios
        .post(
          `m-dillivry-quote-manager?delivery_location=${delivery_location}`,
          {
            action_type: "quote_msg",
            // delivery_id: msg[0]?.delivery_id,
            delivery_id: deliveryID,
            fulfiller_id: fulfiller_id,
            msg: reply,
            // vcc: visitingCountryCode,
          },
          { timeout: 240000 }
        )
        .then((res) => {
          setsendingMsg(false);
          if (res.status === 200) {
            setReply("");
            let new_msg = {
              user_id: ls.get("eid"),
              msg: reply,
              time_stamp: new Date(),
            };
            let converse = [];
            let updateNewmessage = [];

            converse = freshmsg;
            updateNewmessage = insert(converse, 0, new_msg);

            setfreshmsg(updateNewmessage);
            createSenderMsg({
              new_message: new_msg,
              id: id,
              fulfillerID: fulfiller_id,
            });
            setsoftRefresh(!softRefresh);
          }
        })

        .catch((err) => {
          setReply("");
          setsendingMsg(false);
          if (err?.response?.status === 403) {
            Swal.fire({
              icon: "warning",
              title: "Session!",
              text: "Session expired login again!",
              allowOutsideClick: false,
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              setsendingMsg(false);
              if (result.value) {
                auth.logout();
                history.push(
                  GlobalizationLanguagewithCOntryCode(
                    `/login`,
                    country,
                    visitingCountryCode
                  )
                );
              }
            });
          } else if (err?.response?.status === 401) {
            setReply("");
            setsendingMsg(false);
            Swal.fire({
              icon: "info",
              text: "item has been delivered, message not sent",
              timer: 4000,
              showConfirmButton: false,
            });
          } else {
            setsendingMsg(false);
            setReply("");
            Swal.fire({
              icon: "info",
              text: "Ops! Message not sent, try again later",
              timer: 4000,
              showConfirmButton: false,
            });
            if (err.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push(
                GlobalizationLanguagewithCOntryCode(
                  `/timeout`,
                  country,
                  visitingCountryCode
                )
              );
            }
            setsendingMsg(false);
          }
        });
    }
  };

  const Initiatemssgfromdrilldown = () => {
    //  console.log(location);
    if (reply === "") {
      // setRequiredIndex(index);
      setisRequired(true);
    } else {
      setisRequired(false);
      setsendingMsg(true);
      authAxios
        .post(
          `m-dillivry-quote-manager`,
          {
            action_type: "quote_msg",
            delivery_id:
              location?.default?.delivery_id === undefined
                ? idtouse
                : location?.default?.delivery_id,
            // delivery_id: deliveryID,
            fulfiller_id:
              location?.default?.data?.fulfiller_id === undefined
                ? ful_id?.state
                : location?.default?.data?.fulfiller_id,
            msg: reply,
            // vcc: visitingCountryCode,
          },
          { timeout: 240000 }
        )
        .then((res) => {
          //  console.log(res, "resssss");
          if (res.status === 200) {
            axios
              .get(
                `${process.env.REACT_APP_API}m-dillivry-sender-api?attribute=get_message_list`,
                {
                  headers: {
                    Authorization: ls.get("_utk"),
                  },
                }
              )
              .then((response) => {
                let Tofilter = response?.data?.data
                  ?.map((x) => x?.ng ?? x?.gb)
                  ?.flat()
                  .map((x) => x.quote_messages)
                  .flat();
                setTodisplay(
                  Tofilter?.filter((x) => x?.delivery_id === id).map(
                    (x) => x?.fulfiller_conversation
                  )[0]
                );
                setIsloading(false);
                setsendingMsg(false);
                setReply(" ");
                DMconversation.conversation = Tofilter?.filter(
                  (x) => x?.delivery_id === id
                ).map((x) => x?.fulfiller_conversation)[0];
              });

            settorefresh(!torefresh);
            // setIsloading(true)
            let new_msg = {
              user_id: ls.get("eid"),
              msg: reply,
              time_stamp: new Date(),
            };

            // window.location.reload()
            // setIsloading(false)

            // const converseFulfiller = DMconversation.filter(
            //   (x, i) => x.fulfiller_id === fulfiller_id
            // );
            // // console.log( DMconversation.filter((x,i)=>(x.fulfiller_id === fulfiller_id)),"conful")

            // const index = DMconversation.findIndex(
            //   (x) => x.fulfiller_id === converseFulfiller[0].fulfiller_id
            // );
            // // console.log(DMconversation.findIndex(x => x.fulfiller_id === converseFulfiller[0].fulfiller_id),"index")

            // let new_conversation = insert(
            //   converseFulfiller[0].conversation,
            //   0,
            //   new_msg
            // );
            // // console.log(insert(converseFulfiller[0].conversation, 0, new_msg),"new convo")

            // DMconversation[index].conversation = new_conversation;

            // UpdateSenderMsg({new_message: DMconversation, id: id })

            setsoftRefresh(!softRefresh);
          }
        })
        .catch((err) => {
          console.log(err, "errrrr");
          setReply("");
          setsendingMsg(false);
          if (err?.response?.status === 403) {
            Swal.fire({
              icon: "warning",
              title: "Session!",
              text: "Session expired login again!",
              allowOutsideClick: false,
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              setsendingMsg(false);
              if (result.value) {
                auth.logout();
                history.push(
                  GlobalizationLanguagewithCOntryCode(
                    `/login`,
                    country,
                    visitingCountryCode
                  )
                );
              }
            });
          } else if (err?.response?.status === 401) {
            setReply("");
            setsendingMsg(false);
            Swal.fire({
              icon: "info",
              text: "item has been delivered, message not sent",
              timer: 4000,
              showConfirmButton: false,
            });
          } else {
            setsendingMsg(false);
            setReply("");
            Swal.fire({
              icon: "info",
              text: "Ops! Message not sent, try again later",
              timer: 4000,
              showConfirmButton: false,
            });
            if (err.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push(
                GlobalizationLanguagewithCOntryCode(
                  `/timeout`,
                  country,
                  visitingCountryCode
                )
              );
            }
            setsendingMsg(false);
          }
        });
    }
  };

  useEffect(() => {
    //  console.log(location, "locationnnn")
    setful_id(location);
    // console.log(id)
    setidtouse(id);
  }, [msg, id, location]);

  const msgFromQuoteDrilldown = (incomingData) => {
    // console.log(incomingData);
    // if(incomingData.length !== 0){
    //   let iddata = ""
    //   idnew !== undefined ? iddata = idnew : iddata = id
    //     const checkquoteMessage = incomingData?.filter(
    //       (item) => item.delivery_id === iddata
    //     );
    //     let data = [];
    //     data = [
    //       {
    //         sender_name: checkquoteMessage[0]?.sender_name,
    //         conversation: checkquoteMessage[0]?.fulfiller_conversation,
    //         delivery_id: checkquoteMessage[0]?.delivery_id,
    //         fulfiller_id: checkquoteMessage[0]?.fulfiller_id,
    //         quote_subject: checkquoteMessage[0]?.["subject:quote"],
    //         delivery_address: checkquoteMessage[0]?.delivery_address,
    //         pickup_address: checkquoteMessage[0]?.pickup_address,
    //         image_urls: checkquoteMessage[0]?.image_urls[0],
    //       },
    //     ];
    //     const checkconv = data[0]?.conversation?.filter(
    //       (item)=>item.fulfiller_id === location.state  )
    //   if(checkquoteMessage.length === 0 || checkconv.length === 0 ){
    //     let messag = [];
    //     let messagData = [];
    //     messag = incomingData.data?.awaiting_quote?.filter((x)=>x.delivery_id === id)
    //     messagData = messag[0].quote_detail?.filter(
    //       (item)=>item.fulfiller_id === location.state  )
    //     setfreshmsg(messagData[0]?.message)
    //     setnewMessage(incomingData.data.awaiting_quote.filter((x)=>x.delivery_id === id))
    //     setfulfiller_id(location.state)
    //     setdeliveryID(id)
    //   }
    //   else{
    //     let data = [];
    //     let iddata = ""
    //     idnew !== undefined ? iddata = idnew : iddata = id
    //     const deliveryData = incomingData.data.quote_messages.filter(
    //       (item) => item.delivery_id === iddata
    //     );
    //     data = [
    //       {
    //         sender_name: deliveryData[0].sender_name,
    //         conversation: deliveryData[0].fulfiller_conversation,
    //         delivery_id: deliveryData[0].delivery_id,
    //         fulfiller_id: deliveryData[0].fulfiller_id,
    //         quote_subject: deliveryData[0]["subject:quote"],
    //         delivery_address: deliveryData[0].delivery_address,
    //         pickup_address: deliveryData[0].pickup_address,
    //         image_urls: deliveryData[0].image_urls[0],
    //       },
    //     ];
    //     setmsg(data);
    //     setDMconversation([...data[0]?.conversation]?.filter(
    //       (item) => item?.fulfiller_id === location?.state));
    //     setfulfiller_id(data[0]?.conversation[data[0]?.conversation.length - 1].fulfiller_id);
    //     setDMsender(data[0]?.sender_name);
    //     setIsloading(false);
    //     setdeliveryID(data[0]?.delivery_id)
    //   }
    // }
    // else{
    //   let messag = [];
    //   let messagData = [];
    //   let iddata = ""
    //     idnew !== undefined ? iddata = idnew : iddata = id
    //   messag = incomingData.data?.awaiting_quote?.filter((x)=>x.delivery_id === iddata)
    //   messagData  = messag[0].quote_detail?.filter(
    //     (item)=>item.fulfiller_id === location.state  )
    //   setfreshmsg(messagData[0]?.message)
    //   setnewMessage(incomingData.data.awaiting_quote.filter((x)=>x.delivery_id === id))
    //   setfulfiller_id(location.state)
    //   setdeliveryID(id)
    // }
  };

  const msgFromMsgSection = (incomingData) => {
    let data = [];

    let iddata = "";

    idnew !== undefined ? (iddata = idnew) : (iddata = id);

    const deliveryData = incomingData.filter(
      (item) => item.delivery_id === iddata
    );

    data = [
      {
        sender_name: deliveryData[0]?.sender_name,
        conversation: deliveryData[0]?.fulfiller_conversation,
        delivery_id: deliveryData[0]?.delivery_id,
        delivery_location: deliveryData[0]?.delivery_location,
        fulfiller_id: deliveryData[0]?.fulfiller_conversation.map(
          (x) => x?.fulfiller_id
        )[0],
        quote_subject: deliveryData[0]["subject:quote"],
        delivery_address: deliveryData[0]?.delivery_address,
        pickup_address: deliveryData[0]?.pickup_address,
        image_urls: deliveryData[0]?.image_urls[0],
      },
    ];
    // console.log(data[0], "from section");
    setmsg(data);
    setDMconversation(data[0]?.conversation);
    setfulfiller_id(
      data[0]?.conversation[data[0]?.conversation.length - 1].fulfiller_id
    );
    setDMsender(data[0]?.sender_name);
    setIsloading(false);
    setdeliveryID(data[0]?.delivery_id);
    setdelivery_location(data[0]?.delivery_location);
  };

  const [clicked, setclicked] = useState(false);

  return (
    <div>
      {location?.state === undefined ? (
        <div className="dsktopscreen">
          <Layout>
            {isloading ? (
              <>
                <Box
                  sx={{ width: "100%", marginBottom: "10px", marginTop: "4px" }}
                >
                  <LinearProgress color="success" />
                </Box>
              </>
            ) : null}

            <div className="msg-body">
              {newMessage?.length === 0 ? (
                <div className="first-msg">
                  <table className="msg-table">
                    {messageHead?.map((msg) => (
                      <th className="msg-thead">{msg.thead}</th>
                    ))}

                    {[...enquiryMsg]?.reverse()?.length === 0 ? (
                      <tbody>
                        <tr className="msg-trow">
                          <td className="msg-tdata" colSpan={3}>
                            {" "}
                            -- You don't have message(s) yet --{" "}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      [...enquiryMsg]?.reverse()?.sort(function (left, right) {
                        return moment.utc(left.msg_time_stamp).diff(moment.utc(right.msg_time_stamp))
                      })?.reverse()?.map((msg, index) => (
                        <tbody>
                          {/* {
        msg?.fulfiller_conversation[0].conversation.fulfiller_id ? */}
                          <tr
                            className={
                              msg.delivery_id === id
                                ? "msg-trow-active"
                                : "msg-trow"
                            }
                            key={index}
                            onClick={() => {
                              setclicked(true);
                              setidnew(msg.delivery_id);
                            }}
                          >
                            <td className="msg-tdata">
                              {" "}
                              {capitalize(msg["subject:quote"])}
                            </td>
                            <td className="msg-tdata">
                              {" "}
                              {capitalize(
                                msg?.fulfiller_conversation[0]?.conversation[0]
                                  ?.msg
                              )}
                            </td>
                            <td className="msg-tdata">
                              {moment().diff(msg?.msg_time_stamp, "days") ===
                                0 ? (
                                <>
                                  {moment(msg?.msg_time_stamp).format(
                                    "MMM Do YYYY, h:mm a "
                                  )}
                                </>
                              ) : (
                                <>
                                  {/* {moment(msg.msg_time_stamp).format("MMM D") } */}
                                  {moment(msg?.msg_time_stamp).format(
                                    "MMM Do YYYY, h:mm a "
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                          {/* :
 null
} */}
                        </tbody>
                      ))
                    )}
                  </table>
                </div>
              ) : null}
              <div className="second-msg">
                {/* {console.log(msg, 'msg')} */}
                {/* {
                  console.log(msg[0], "msg[0]")
                } */}

                {msg.length !== 0 ? (
                  <div className="chat-con">
                    <div>
                      <Tooltip title='Go back'>
                        <IconButton onClick={() => history.goBack()}>
                          <MdKeyboardBackspace
                            color="#50b14a"
                            fontSize="25px"

                          />
                        </IconButton>
                      </Tooltip>
                    </div>
                    {msg[0] === undefined ? (
                      <></>
                    ) : (
                      <div className="msgViewContainerTop">
                        <div
                          className="msgViewContainerTopImage"
                          style={{
                            backgroundImage: `url(${msg[0]?.image_urls})`,
                          }}
                        ></div>

                        <div className="msgViewTopText">
                          <p>
                            {" "}
                            {msg[0]?.quote_subject.charAt(0)?.toUpperCase() +
                              msg[0]?.quote_subject.slice(1) +
                              " (" +
                              msg[0]?.delivery_id +
                              ")"}
                            {/* {msg[0].quote_subject} */}
                          </p>
                          <p>
                            {" "}
                            <img src={warngreen} alt="msgpi_icon" />
                            &ensp;{msg[0]?.pickup_address}
                          </p>
                          <p>
                            {" "}
                            <img src={warnrred} alt="msgpi_icon" />
                            &ensp;{msg[0]?.delivery_address}
                          </p>
                        </div>
                      </div>
                    )}

                    {reverseArr(DMconversation)?.map((converse, index) => {
                      return (
                        <div key={index}>
                          {converse?.fulfiller_id ? (
                            <>
                              <div
                                style={{
                                  padding: "10px",
                                  border: "1px solid #c4c4c4",
                                  marginTop: "3px",
                                  marginBottom: "3px",
                                  borderTopRightRadius: "5px",
                                  borderTopLeftRadius: "5px",
                                  cursor: "pointer",
                                  userSelect: "none",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  setMsgIndex(index);
                                  setshow(!show);
                                  setfulfiller_id(converse?.fulfiller_id);
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "var(--main)",
                                  }}
                                >
                                  {capitalize(converse?.fulfiller_id)}
                                </div>
                                {msgIndex === index && show ? (
                                  <FaChevronUp color="var(--main)" />
                                ) : (
                                  <FaChevronDown color="var(--main)" />
                                )}
                              </div>

                              {msgIndex === index && show
                                ? reverseArr(converse?.conversation).map(
                                  (mssg, index) => (
                                    <>
                                      {mssg.fulfiller_id === undefined ? (
                                        <>
                                          <div className="right-wrapper">
                                            <div class="message-right">
                                              <div className="main-msg">
                                                <p>
                                                  {" "}
                                                  {capitalize(mssg?.msg)}
                                                </p>{" "}
                                              </div>
                                              <div className="message-timeStamp">
                                                <div className="all-time">
                                                  <div
                                                    className="timediplaydesk"
                                                    style={{
                                                      fontSize: "10px",
                                                    }}
                                                  >
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).format(
                                                      "MMM Do YYYY, h:mm a "
                                                    )}
                                                    (
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).fromNow()}
                                                    )
                                                  </div>
                                                  <div
                                                    className="timediplaymobile"
                                                    style={{
                                                      fontSize: "7px",
                                                    }}
                                                  >
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).format(
                                                      "MMM Do YYYY, h:mm a "
                                                    )}
                                                    (
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).fromNow()}
                                                    )
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="message-timeStamp">
                                                <div className="all-time">
                                                  <BsCheckAll
                                                    color="white"
                                                    size="18px"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="left-wrapper">
                                            <span class="imageView-cropper">
                                              <img
                                                src={userAvatar}
                                                class="rounded"
                                                alt="userAvatar"
                                              />
                                            </span>
                                            <div class="message-left">
                                              <div>
                                                {/* <strong>{mssg.fulfiller_id}</strong> */}
                                                <strong>
                                                  {capitalize(
                                                    mssg?.fulfiller_id
                                                  )}
                                                </strong>
                                              </div>
                                              <div>
                                                <p className="text-sty">
                                                  {capitalize(mssg?.msg)}{" "}
                                                </p>
                                              </div>
                                              <div className="message-timeStamp">
                                                <div className="all-time">
                                                  <div
                                                    className="timediplaydesk"
                                                    style={{
                                                      fontSize: "10px",
                                                    }}
                                                  >
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).format(
                                                      "MMM Do YYYY, h:mm a "
                                                    )}
                                                    (
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).fromNow()}
                                                    )
                                                  </div>
                                                  <div
                                                    className="timediplaymobile"
                                                    style={{
                                                      fontSize: "7px",
                                                    }}
                                                  >
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).format(
                                                      "MMM Do YYYY, h:mm a "
                                                    )}
                                                    (
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).fromNow()}
                                                    )
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="message-timeStamp">
                                                <div className="all-time">
                                                  <BsCheckAll
                                                    color="var(--main)"
                                                    size="18px"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )
                                )
                                : null}
                              {msgIndex === index && show ? (
                                <>
                                  <div className="reply-message-wrap">
                                    <div className="reply-message-con">
                                      <div className="send-con">
                                        <div className="send-icons">
                                          <span>
                                            <textarea
                                              autoFocus
                                              requiredtype="text"
                                              className="reply-input"
                                              value={
                                                replying === index
                                                  ? reply
                                                  : null
                                              }
                                              onChange={(e) => {
                                                setReply(e.target.value);
                                                setisRequired(false);
                                                setReplying(index);
                                              }}
                                              type="text"
                                              placeholder="Reply..."
                                            />
                                            <p>{errors.message?.message}</p>
                                          </span>
                                        </div>
                                        <div className="send-icons">
                                          <span
                                            type="submit"
                                            value="submit"
                                            className="send-icon"
                                          >
                                            {sendingMsg &&
                                              replying === index ? (
                                              <>
                                                <span className="sending">
                                                  <PuffLoader
                                                    color="var(--main)"
                                                    loading={sendingMsg}
                                                    speedMultiplier={1}
                                                    size={25}
                                                  />
                                                </span>
                                              </>
                                            ) : (
                                              <span>
                                                <img
                                                  src={send}
                                                  alt=""
                                                  onClick={() =>
                                                    tokenCheck(index)
                                                  }
                                                  style={{ width: "20px" }}
                                                />
                                              </span>
                                            )}
                                          </span>
                                          <span>
                                            {" "}
                                            <img
                                              src={horiz}
                                              alt=""
                                              style={{ marginLeft: "20px" }}
                                            />{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <br></br>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    {newMessage.length !== 0 ? (
                      <div className="chat-con">
                        <div>
                          <Tooltip title='Go back'>
                            <IconButton onClick={() => history.goBack()}>
                              <MdKeyboardBackspace
                                color="#50b14a"
                                fontSize="25px"

                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div className="msgViewContainerTop">
                          <div
                            className="msgViewContainerTopImage"
                            style={{
                              backgroundImage: `url(${newMessage[0]?.image_urls[0]})`,
                            }}
                          ></div>

                          <div className="msgViewTopText">
                            <p>
                              {" "}
                              {newMessage[0]?.item_desc
                                .charAt(0)
                                ?.toUpperCase() +
                                newMessage[0]?.item_desc.slice(1) +
                                " (" +
                                newMessage[0]?.delivery_id +
                                ")"}
                              {/* {msg[0].quote_subject} */}
                            </p>
                            <p>
                              {" "}
                              <img src={warngreen} alt="msgpi_icon" />
                              &ensp;{newMessage[0]?.from}
                            </p>
                            <p>
                              {" "}
                              <img src={warnrred} alt="msgpi_icon" />
                              &ensp;{newMessage[0]?.to}
                            </p>
                          </div>
                        </div>

                        <div>
                          <div
                            style={{
                              padding: "10px",
                              border: "1px solid #c4c4c4",
                              marginTop: "3px",
                              marginBottom: "3px",
                              borderTopRightRadius: "5px",
                              borderTopLeftRadius: "5px",
                              cursor: "pointer",
                              userSelect: "none",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "var(--main)",
                              }}
                            >
                              {location?.state}
                            </div>
                          </div>
                        </div>

                        {freshmsg !== undefined
                          ? reverseArr(freshmsg).map((mssg, index) => {
                            return (
                              <div key={index}>
                                <div className="right-wrapper">
                                  <div class="message-right">
                                    <div className="main-msg">
                                      <p> {mssg.msg}</p>{" "}
                                    </div>
                                    <div className="message-timeStamp">
                                      <div className="all-time">
                                        <div
                                          className="timediplaydesk"
                                          style={{ fontSize: "10px" }}
                                        >
                                          {moment(mssg.time_stamp).format(
                                            "MMM Do YYYY, h:mm a "
                                          )}
                                          ({moment(mssg.time_stamp).fromNow()}
                                          )
                                        </div>
                                        <div
                                          className="timediplaymobile"
                                          style={{ fontSize: "7px" }}
                                        >
                                          {moment(mssg.time_stamp).format(
                                            "MMM Do YYYY, h:mm a "
                                          )}
                                          ({moment(mssg.time_stamp).fromNow()}
                                          )
                                        </div>
                                      </div>
                                    </div>
                                    <div className="message-timeStamp">
                                      <div className="all-time">
                                        <BsCheckAll
                                          color="white"
                                          size="18px"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : null}

                        <div className="reply-message-wrap">
                          <div className="reply-message-con">
                            <p>{errors.message?.message}</p>
                            <div className="send-con">
                              <div className="send-icons">
                                <span>
                                  <textarea
                                    requiredtype="text"
                                    autoFocus
                                    className="reply-input"
                                    type="text"
                                    value={reply}
                                    onChange={(e) => {
                                      setReply(e.target.value);
                                      setisRequired(false);
                                    }}
                                    placeholder="Reply..."
                                  />
                                </span>
                              </div>
                              <div className="send-icons">
                                <span
                                  type="submit"
                                  value="submit"
                                  className="send-icon"
                                >
                                  {sendingMsg ? (
                                    <>
                                      <span className="sending">
                                        <PuffLoader
                                          color="var(--main)"
                                          loading={sendingMsg}
                                          speedMultiplier={1}
                                          size={25}
                                        />
                                      </span>
                                    </>
                                  ) : (
                                    <span>
                                      <img
                                        src={send}
                                        alt=""
                                        style={{ width: "20px" }}
                                        onClick={initiateMessage}
                                      />
                                    </span>
                                  )}
                                </span>
                                <span>
                                  {" "}
                                  <img
                                    src={horiz}
                                    alt=""
                                    style={{ marginLeft: "20px" }}
                                  />{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <br></br>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="chat-con">
                        <div>
                          <Tooltip title='Go back'>
                            <IconButton onClick={() => history.goBack()}>
                              <MdKeyboardBackspace
                                color="#50b14a"
                                fontSize="25px"

                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                        {msg[0] === undefined ? (
                          <></>
                        ) : (
                          <div className="msgViewContainerTop">
                            <div
                              className="msgViewContainerTopImage"
                              style={{
                                backgroundImage: `url(${msg[0]?.image_urls})`,
                              }}
                            ></div>

                            <div className="msgViewTopText">
                              <p>
                                {" "}
                                {msg[0]?.quote_subject
                                  .charAt(0)
                                  ?.toUpperCase() +
                                  msg[0]?.quote_subject.slice(1) +
                                  " (" +
                                  msg[0]?.delivery_id +
                                  ")"}
                                {/* {msg[0].quote_subject} */}
                              </p>
                              <p>
                                {" "}
                                <img src={warngreen} alt="msgpi_icon" />
                                &ensp;{msg[0]?.pickup_address}
                              </p>
                              <p>
                                {" "}
                                <img src={warnrred} alt="msgpi_icon" />
                                &ensp;{msg[0]?.delivery_address}
                              </p>
                            </div>
                          </div>
                        )}

                        {DMconversation.map((converse, index) => {
                          return (
                            <div key={index}>
                              <div
                                style={{
                                  padding: "10px",
                                  border: "1px solid #c4c4c4",
                                  marginTop: "3px",
                                  marginBottom: "3px",
                                  borderTopRightRadius: "5px",
                                  borderTopLeftRadius: "5px",
                                  cursor: "pointer",
                                  userSelect: "none",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  setMsgIndex(index);
                                  setshow(!show);
                                  setfulfiller_id(converse?.fulfiller_id);
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "var(--main)",
                                  }}
                                >
                                  {capitalize(converse?.fulfiller_id)}
                                </div>
                                {msgIndex === index && show ? (
                                  <FaChevronUp color="var(--main)" />
                                ) : (
                                  <FaChevronDown color="var(--main)" />
                                )}
                              </div>

                              {msgIndex === index && show
                                ? reverseArr(converse?.conversation).map(
                                  (mssg, index) => (
                                    <>
                                      {mssg.fulfiller_id === undefined ? (
                                        <>
                                          <div className="right-wrapper">
                                            <div class="message-right">
                                              <div className="main-msg">
                                                <p>
                                                  {" "}
                                                  {capitalize(mssg?.msg)}
                                                </p>{" "}
                                              </div>
                                              <div className="message-timeStamp">
                                                <div className="all-time">
                                                  <div
                                                    className="timediplaydesk"
                                                    style={{
                                                      fontSize: "10px",
                                                    }}
                                                  >
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).format(
                                                      "MMM Do YYYY, h:mm a "
                                                    )}
                                                    (
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).fromNow()}
                                                    )
                                                  </div>
                                                  <div
                                                    className="timediplaymobile"
                                                    style={{
                                                      fontSize: "7px",
                                                    }}
                                                  >
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).format(
                                                      "MMM Do YYYY, h:mm a "
                                                    )}
                                                    (
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).fromNow()}
                                                    )
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="message-timeStamp">
                                                <div className="all-time">
                                                  <BsCheckAll
                                                    color="white"
                                                    size="18px"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="left-wrapper">
                                            <span class="imageView-cropper">
                                              <img
                                                src={userAvatar}
                                                class="rounded"
                                              />
                                            </span>
                                            <div class="message-left">
                                              <div>
                                                {/* <strong>{mssg.fulfiller_id}</strong> */}
                                                <strong>
                                                  {capitalize(
                                                    mssg?.fulfiller_id
                                                  )}
                                                </strong>
                                              </div>
                                              <div>
                                                <p className="text-sty">
                                                  {capitalize(mssg?.msg)}{" "}
                                                </p>
                                              </div>
                                              <div className="message-timeStamp">
                                                <div className="all-time">
                                                  <div
                                                    className="timediplaydesk"
                                                    style={{
                                                      fontSize: "10px",
                                                    }}
                                                  >
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).format(
                                                      "MMM Do YYYY, h:mm a "
                                                    )}
                                                    (
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).fromNow()}
                                                    )
                                                  </div>
                                                  <div
                                                    className="timediplaymobile"
                                                    style={{
                                                      fontSize: "7px",
                                                    }}
                                                  >
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).format(
                                                      "MMM Do YYYY, h:mm a "
                                                    )}
                                                    (
                                                    {moment(
                                                      mssg.time_stamp
                                                    ).fromNow()}
                                                    )
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="message-timeStamp">
                                                <div className="all-time">
                                                  <BsCheckAll
                                                    color="var(--main)"
                                                    size="18px"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  )
                                )
                                : null}
                              {msgIndex === index && show ? (
                                <>
                                  <div className="reply-message-wrap">
                                    <div className="reply-message-con">
                                      <div className="send-con">
                                        <div className="send-icons">
                                          <span>
                                            <textarea
                                              requiredtype="text"
                                              autoFocus
                                              className="reply-input"
                                              value={
                                                replying === index
                                                  ? reply
                                                  : null
                                              }
                                              onChange={(e) => {
                                                setReply(e.target.value);
                                                setisRequired(false);
                                                setReplying(index);
                                              }}
                                              type="text"
                                              placeholder="Reply..."
                                            />
                                            <p>{errors.message?.message}</p>
                                          </span>
                                        </div>
                                        <div className="send-icons">
                                          <span
                                            type="submit"
                                            value="submit"
                                            className="send-icon"
                                          >
                                            {sendingMsg &&
                                              replying === index ? (
                                              <>
                                                <span className="sending">
                                                  <PuffLoader
                                                    color="var(--main)"
                                                    loading={sendingMsg}
                                                    speedMultiplier={1}
                                                    size={25}
                                                  />
                                                </span>
                                              </>
                                            ) : (
                                              <span>
                                                <img
                                                  src={send}
                                                  alt=""
                                                  onClick={() =>
                                                    tokenCheck(index)
                                                  }
                                                  style={{ width: "20px" }}
                                                />
                                              </span>
                                            )}
                                          </span>
                                          <span>
                                            {" "}
                                            <img
                                              src={horiz}
                                              alt=""
                                              style={{ marginLeft: "20px" }}
                                            />{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <br></br>
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Layout>
        </div>
      ) : (
        //  <h2>hhg</h2>
        <div className="second-msg">
          {msg.length !== 0 ? (
            <div className="chat-con">
              <div>
                <Tooltip title='Go back'>
                  <IconButton onClick={() => history.goBack()}>
                    <MdKeyboardBackspace
                      color="#50b14a"
                      fontSize="25px"

                    />
                  </IconButton>
                </Tooltip>
              </div>
              {
                // msg[0] === undefined ?
                // <></>
                // :

                <div className="msgViewContainerTop">
                  <div
                    className="msgViewContainerTopImage"
                    style={{ backgroundImage: `url(${msg[0]?.image_urls})` }}
                  ></div>

                  <div className="msgViewTopText">
                    <p>
                      {" "}
                      {msg[0]?.quote_subject.charAt(0)?.toUpperCase() +
                        msg[0]?.quote_subject.slice(1) +
                        " (" +
                        msg[0]?.delivery_id +
                        ")"}
                      {/* {msg[0].quote_subject} */}
                    </p>
                    <p>
                      {" "}
                      <img src={warngreen} alt="msgpi_icon" />
                      &ensp;{msg[0]?.pickup_address}
                    </p>
                    <p>
                      {" "}
                      <img src={warnrred} alt="msgpi_icon" />
                      &ensp;{msg[0]?.delivery_address}
                    </p>
                  </div>
                </div>
              }

              {reverseArr(DMconversation).map((converse, index) => {
                return (
                  <div key={index}>
                    {converse?.fulfiller_id ? (
                      <>
                        <div
                          style={{
                            padding: "10px",
                            border: "1px solid #c4c4c4",
                            marginTop: "3px",
                            marginBottom: "3px",
                            borderTopRightRadius: "5px",
                            borderTopLeftRadius: "5px",
                            cursor: "pointer",
                            userSelect: "none",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            setMsgIndex(index);
                            setshow(!show);
                            setfulfiller_id(converse?.fulfiller_id);
                          }}
                        >
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "var(--main)",
                            }}
                          >
                            {capitalize(converse?.fulfiller_id)}
                          </div>
                          {msgIndex === index && show ? (
                            <FaChevronUp color="var(--main)" />
                          ) : (
                            <FaChevronDown color="var(--main)" />
                          )}
                        </div>

                        {msgIndex === index && show
                          ? reverseArr(converse?.conversation).map(
                            (mssg, index) => (
                              <>
                                {mssg.fulfiller_id === undefined ? (
                                  <>
                                    <div className="right-wrapper">
                                      <div class="message-right">
                                        <div className="main-msg">
                                          <p> {capitalize(mssg?.msg)}</p>{" "}
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <div
                                              className="timediplaydesk"
                                              style={{ fontSize: "10px" }}
                                            >
                                              {moment(mssg.time_stamp).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                            <div
                                              className="timediplaymobile"
                                              style={{ fontSize: "7px" }}
                                            >
                                              {moment(mssg.time_stamp).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                          </div>
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <BsCheckAll
                                              color="white"
                                              size="18px"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="left-wrapper">
                                      <span class="imageView-cropper">
                                        <img
                                          src={userAvatar}
                                          class="rounded"
                                          alt="rounded"
                                        />
                                      </span>
                                      <div class="message-left">
                                        <div>
                                          {/* <strong>{mssg.fulfiller_id}</strong> */}
                                          <strong>
                                            {capitalize(mssg?.fulfiller_id)}
                                          </strong>
                                        </div>
                                        <div>
                                          <p className="text-sty">
                                            {capitalize(mssg?.msg)}{" "}
                                          </p>
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <div
                                              className="timediplaydesk"
                                              style={{ fontSize: "10px" }}
                                            >
                                              {moment(mssg.time_stamp).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                            <div
                                              className="timediplaymobile"
                                              style={{ fontSize: "7px" }}
                                            >
                                              {moment(mssg.time_stamp).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                          </div>
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <BsCheckAll
                                              color="var(--main)"
                                              size="18px"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )
                          )
                          : null}
                        {msgIndex === index && show ? (
                          <>
                            <div className="reply-message-wrap">
                              <div className="reply-message-con">
                                <div className="send-con">
                                  <div className="send-icons">
                                    <span>
                                      <textarea
                                        autoFocus
                                        requiredtype="text"
                                        className="reply-input"
                                        value={
                                          replying === index ? reply : null
                                        }
                                        onChange={(e) => {
                                          setReply(e.target.value);
                                          setisRequired(false);
                                          setReplying(index);
                                        }}
                                        type="text"
                                        placeholder="Reply..."
                                      />
                                      <p>{errors.message?.message}</p>
                                    </span>
                                  </div>
                                  <div className="send-icons">
                                    <span
                                      type="submit"
                                      value="submit"
                                      className="send-icon"
                                    >
                                      {sendingMsg && replying === index ? (
                                        <>
                                          <span className="sending">
                                            <PuffLoader
                                              color="var(--main)"
                                              loading={sendingMsg}
                                              speedMultiplier={1}
                                              size={25}
                                            />
                                          </span>
                                        </>
                                      ) : (
                                        <span>
                                          <img
                                            src={send}
                                            alt=""
                                            onClick={() => tokenCheck(index)}
                                            style={{ width: "20px" }}
                                          />
                                        </span>
                                      )}
                                    </span>
                                    <span>
                                      {" "}
                                      <img
                                        src={horiz}
                                        alt=""
                                        style={{ marginLeft: "20px" }}
                                      />{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <br></br>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                );
              })}
            </div>
          ) : (
            <>

              {newMessage.length !== 0 ? (
                <div className="chat-con">
                  <div>
                    <Tooltip title='Go back'>
                      <IconButton onClick={() => history.goBack()}>
                        <MdKeyboardBackspace
                          color="#50b14a"
                          fontSize="25px"

                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="msgViewContainerTop">
                    <div
                      className="msgViewContainerTopImage"
                      style={{
                        backgroundImage: `url(${newMessage[0]?.image_urls[0]})`,
                      }}
                    ></div>

                    <div className="msgViewTopText">
                      <p>
                        {" "}
                        {newMessage[0]?.item_desc.charAt(0)?.toUpperCase() +
                          newMessage[0]?.item_desc.slice(1) +
                          " (" +
                          newMessage[0]?.delivery_id +
                          ")"}
                        {/* {msg[0].quote_subject} */}
                      </p>
                      <p>
                        {" "}
                        <img src={warngreen} alt="msgpi_icon" />
                        &ensp;{newMessage[0]?.from}
                      </p>
                      <p>
                        {" "}
                        <img src={warnrred} alt="msgpi_icon" />
                        &ensp;{newMessage[0]?.to}
                      </p>
                    </div>
                  </div>

                  <div>
                    <div
                      style={{
                        padding: "10px",
                        border: "1px solid #c4c4c4",
                        marginTop: "3px",
                        marginBottom: "3px",
                        borderTopRightRadius: "5px",
                        borderTopLeftRadius: "5px",
                        cursor: "pointer",
                        userSelect: "none",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "var(--main)",
                        }}
                      >
                        {location?.state}
                      </div>
                    </div>
                  </div>

                  {freshmsg !== undefined
                    ? reverseArr(freshmsg).map((mssg, index) => {
                      return (
                        <div key={index}>
                          <div className="right-wrapper">
                            <div class="message-right">
                              <div className="main-msg">
                                <p> {mssg.msg}</p>{" "}
                              </div>
                              <div className="message-timeStamp">
                                <div className="all-time">
                                  <div
                                    className="timediplaydesk"
                                    style={{ fontSize: "10px" }}
                                  >
                                    {moment(mssg.time_stamp).format(
                                      "MMM Do YYYY, h:mm a "
                                    )}
                                    ({moment(mssg.time_stamp).fromNow()})
                                  </div>
                                  <div
                                    className="timediplaymobile"
                                    style={{ fontSize: "7px" }}
                                  >
                                    {moment(mssg.time_stamp).format(
                                      "MMM Do YYYY, h:mm a "
                                    )}
                                    ({moment(mssg.time_stamp).fromNow()})
                                  </div>
                                </div>
                              </div>
                              <div className="message-timeStamp">
                                <div className="all-time">
                                  <BsCheckAll color="white" size="18px" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                    : null}
                  <div className="reply-message-wrap">
                    <div className="reply-message-con">
                      <p>{errors.message?.message}</p>
                      <div className="send-con">
                        <div className="send-icons">
                          <span>
                            <textarea
                              requiredtype="text"
                              autoFocus
                              className="reply-input"
                              type="text"
                              value={reply}
                              onChange={(e) => {
                                setReply(e.target.value);
                                setisRequired(false);
                              }}
                              placeholder="Reply..."
                            />
                          </span>
                        </div>
                        <div className="send-icons">
                          <span
                            type="submit"
                            value="submit"
                            className="send-icon"
                          >
                            {sendingMsg ? (
                              <>
                                <span className="sending">
                                  <PuffLoader
                                    color="var(--main)"
                                    loading={sendingMsg}
                                    speedMultiplier={1}
                                    size={25}
                                  />
                                </span>
                              </>
                            ) : (
                              <span>
                                <img
                                  src={send}
                                  alt=""
                                  style={{ width: "20px" }}
                                  onClick={initiateMessage}
                                />
                              </span>
                            )}
                          </span>
                          <span>
                            {" "}
                            <img
                              src={horiz}
                              alt=""
                              style={{ marginLeft: "20px" }}
                            />{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <br></br>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="second-msg">
                    <>
                      <div className="chat-con">
                        <div>
                          <Tooltip title='Go back'>
                            <IconButton onClick={() => history.goBack()}>
                              <MdKeyboardBackspace
                                color="#50b14a"
                                fontSize="25px"

                              />
                            </IconButton>
                          </Tooltip>
                        </div>

                        <div className="msgViewContainerTop">
                          <div
                            className="msgViewContainerTopImage"
                            style={{
                              backgroundImage: `url(${history?.location?.default?.deliveries?.image_urls[0]})`,
                            }}
                          ></div>

                          <div className="msgViewTopText">
                            <p>
                              {" "}
                              {history?.location?.default?.deliveries?.item_desc
                                .charAt(0)
                                ?.toUpperCase() +
                                history?.location?.default?.deliveries?.item_desc?.slice(
                                  1
                                ) +
                                " (" +
                                history?.location?.default?.deliveries
                                  ?.delivery_id +
                                ")"}
                              {/* {msg[0].quote_subject} */}
                            </p>
                            <p>
                              {" "}
                              <img src={warngreen} alt="msgpi_icon" />
                              &ensp;
                              {history?.location?.default?.deliveries?.from}
                            </p>
                            <p>
                              {" "}
                              <img src={warnrred} alt="msgpi_icon" />
                              &ensp;{history?.location?.default?.deliveries?.to}
                            </p>
                          </div>
                        </div>

                        <div>
                          <div
                            style={{
                              padding: "10px",
                              border: "1px solid #c4c4c4",
                              marginTop: "3px",
                              marginBottom: "3px",
                              borderTopRightRadius: "5px",
                              borderTopLeftRadius: "5px",
                              cursor: "pointer",
                              userSelect: "none",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "var(--main)",
                              }}
                            >
                              {/* {
                      console.log(ful_id, id, idtouse)
                    } */}
                              {location?.default?.data?.fulfiller_id ===
                                undefined
                                ? ful_id?.state
                                : location?.default?.data?.fulfiller_id}
                            </div>
                          </div>
                        </div>

                        {/* {
                      console.log(msgdrildown)
                    } */}
                        {/* {
                console.log(msgdrildown?.filter(x => x?.delivery_id === location?.default?.delivery_id), "here")
              } */}
                        {/* {
                console.log(todisplay?.map(x => x?.conversation)[0],"todisplay")
              } */}



                        {todisplay !== undefined
                          ? reverseArr(
                            todisplay?.map((x) => x?.conversation)[0]
                          )?.map((mssg, index) => {
                            return (
                              <>
                                {mssg?.fulfiller_id === undefined ? (
                                  <>
                                    <div className="right-wrapper">
                                      <div class="message-right">
                                        <div className="main-msg">
                                          <p> {capitalize(mssg?.msg)}</p>{" "}
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <div
                                              className="timediplaydesk"
                                              style={{
                                                fontSize: "10px",
                                              }}
                                            >
                                              {moment(
                                                mssg?.time_stamp
                                              ).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg?.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                            <div
                                              className="timediplaymobile"
                                              style={{
                                                fontSize: "7px",
                                              }}
                                            >
                                              {moment(
                                                mssg?.time_stamp
                                              ).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg?.time_stamp
                                              )?.fromNow()}
                                              )
                                            </div>
                                          </div>
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <BsCheckAll
                                              color="white"
                                              size="18px"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="left-wrapper">
                                      <span class="imageView-cropper">
                                        <img
                                          src={userAvatar}
                                          class="rounded"
                                          alt="userAvatar"
                                        />
                                      </span>
                                      <div class="message-left">
                                        <div>
                                          {/* <strong>{mssg.fulfiller_id}</strong> */}
                                          <strong>
                                            {capitalize(mssg?.fulfiller_id)}
                                          </strong>
                                        </div>
                                        <div>
                                          <p className="text-sty">
                                            {capitalize(mssg?.msg)}{" "}
                                          </p>
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <div
                                              className="timediplaydesk"
                                              style={{
                                                fontSize: "10px",
                                              }}
                                            >
                                              {moment(
                                                mssg?.time_stamp
                                              ).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg?.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                            <div
                                              className="timediplaymobile"
                                              style={{
                                                fontSize: "7px",
                                              }}
                                            >
                                              {moment(
                                                mssg?.time_stamp
                                              ).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg?.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                          </div>
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <BsCheckAll
                                              color="var(--main)"
                                              size="18px"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            );
                          })
                          : null}

                        <div className="reply-message-wrap">
                          <div className="reply-message-con">
                            <p>{errors.message?.message}</p>
                            <div className="send-con">
                              <div className="send-icons">
                                <span>
                                  <textarea
                                    requiredtype="text"
                                    autoFocus
                                    className="reply-input"
                                    type="text"
                                    value={reply}
                                    onChange={(e) => {
                                      setReply(e.target.value);
                                      setisRequired(false);
                                    }}
                                    placeholder="Reply..."
                                  />
                                </span>
                              </div>
                              <div className="send-icons">
                                <span
                                  type="submit"
                                  value="submit"
                                  className="send-icon"
                                >
                                  {sendingMsg ? (
                                    <>
                                      <span className="sending">
                                        <PuffLoader
                                          color="var(--main)"
                                          loading={sendingMsg}
                                          speedMultiplier={1}
                                          size={25}
                                        />
                                      </span>
                                    </>
                                  ) : (
                                    <span>
                                      <img
                                        src={send}
                                        alt=""
                                        style={{ width: "20px" }}
                                        onClick={Initiatemssgfromdrilldown}
                                      />
                                    </span>
                                  )}
                                </span>
                                <span>
                                  {" "}
                                  <img
                                    src={horiz}
                                    alt=""
                                    style={{ marginLeft: "20px" }}
                                  />{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <br></br>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}

      <div className="mobilescreen">
        <MessageDrilldownMobile
          isloading={isloading}
          msg={msg}
          msgIndex={msgIndex}
          DMconversation={DMconversation}
          setReply={setReply}
          setisRequired={setisRequired}
          setReplying={setReplying}
          replying={replying}
          setMsgIndex={setMsgIndex}
          setshow={setshow}
          setfulfiller_id={setfulfiller_id}
          show={show}
          reverseArr={reverseArr}
          sendingMsg={sendingMsg}
          reply={reply}
          errors={errors}
          replyMessage={tokenCheck}
          newMessage={newMessage}
          freshmsg={freshmsg}
          initiateMessage={initiateMessage}
          location={location}
        />
      </div>

      <DialogOutClick
        open={ShowLogin}
        handleClose={() => {
          setShowLogin(false);
        }}
      >
        <LoginCompV2
          onFinish={() => {
            setShowLogin(false);
          }}
        />
      </DialogOutClick>
    </div>
  );
}
