import React from 'react'
import GetStartedForm from './GetStartedForm'
import './GetStartedHero.css'
import GetStartedTextSwitch from './GetStartedTextSwitch'
import { useTranslation } from "react-i18next";


export default function GetStartedHero() {
  const {t}=useTranslation()
  return (
    <div className="getstartedCont">
        <div className="home_max_width">
            <div className="getStartedBody">
                <GetStartedTextSwitch/>
                <GetStartedForm/>
            </div>
        </div>
        
    </div>
  )
}
