import React, { useState, useEffect, useContext } from "react";
import { usePaystackPayment } from "react-paystack";
import { MdNavigateNext } from "react-icons/md";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SecureLS from "secure-ls";
import { ConnContext } from "../context/ConnContext";
import GlobalizationLanguage from "./GlobalizationLanguage";
import Cookies from "js-cookie";

const PaystackWidget = ({
  amount,
  email,
  delivery_id,
  fulfiller_id,
  amount_paid,
  closePopup,
}) => {
  const { visitingCountryCode } = useContext(ConnContext);
  var viewQuote = GlobalizationLanguage("/viewQuote")
  var timeout = GlobalizationLanguage("/timeout")

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const history = useHistory();
  const [TransDate, setTransDate] = useState('')
  const refresh = () =>{
        window.location.reload();}

    useEffect(() => {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      setTransDate(date);
    }, [])

    const endpoint = process.env.REACT_APP_API

    // const { visitingCountryCode } = useContext(ConnContext);
    var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()
  
      const [country,setCountry]=useState(countryvalue);
  
      useEffect(() => {
        
          setCountry(Cookies.get('i18next'));
        },[Cookies.get('i18next')])
    
    const config = {
      reference: (new Date()).getTime(),
      email: email,
      amount: amount,
      channels: ['card', 'ussd'],
      publicKey: process.env.REACT_APP_ENVIRONMENT === "PROD" || process.env.REACT_APP_ENVIRONMENT === "TEST" ? process.env.REACT_APP_PAYSTACK_KEY : process.env.REACT_APP_PAYSTACK_KEY_DEMO, 
  };

  const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });

  const onSuccess = (reference) => {
    
    // if (reference.status === "success") {
      authAxios
        .post(
          `/m-dillivry-quote-manager?delivery_location=${country}`,
          {
            action_type: "accept",
            payment_type:"instant",
            delivery_id: delivery_id,
            fulfiller_id: fulfiller_id,
            amount_paid: amount_paid,
            payment_date: TransDate,
            quoted_amount: amount_paid,
            payment_status: "success",
            StatusCode: 200,
            reference_id: reference.reference,
            message: reference.message,
            transaction: reference.transaction,
            vcc: visitingCountryCode,
            payment_gateway: "paystack",
          },
          { timeout: 240000 }
        )
        .then((response) => {
          Swal.fire({
            icon: "success",
            text: "Payment made successfully, refresh your page to reflect",
            timer: 5000,
            confirmButtonColor: "var(--main)",
          });
          ls.remove("item");
          ls.remove("delivery_id");
          history.push(viewQuote);
          refresh();
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            Swal.fire({
              icon: "warning",
              title: "Session!",
              text: "Session expired login again!",
              allowOutsideClick: false,
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                ls.removeAll();
                history.push(`/login`);
              }
            });
          }
          if (err.code === "ECONNABORTED") {
            console.log("internal-server-error-timeout");
            history.push(timeout);
            // window.location.reload()
          }
          history.push("login");
        });
    // }
  };

  // you can call this function anything
  const onClose = () => {};

  const initializePayment = usePaystackPayment(config);

  return (
    <div
      className="acceptBTN"
      style={{ width: "120px", height: "21px", marginLeft: "40px" }}
      onClick={() => {
        closePopup();
        initializePayment(onSuccess, onClose);
      }}
    >
      <span style={{ cursor: "pointer", borderRadius: "0px" }}>
        {" "}
        Make Payment{" "}
      </span>
      <div>{/* <MdNavigateNext /> */}</div>
    </div>
  );
};

export default PaystackWidget;
