
import SenderBottomNav from "../../../components/SenderBottomNav"
import SenderSideBar from "../../../components/SenderSideBar"
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import directMsg from "../../../../../assets/plusIcon.svg"
import moment from "moment";
import { useHistory} from "react-router-dom";
import GlobalizationLanguage, { GlobalizationLanguagewithCOntryCode } from "../../../../../components/GlobalizationLanguage";
import { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { ConnContext } from "../../../../../context/ConnContext";

export default function MessageMobile({isloading, enquiryMsg}) {
    const history = useHistory();

    const {visitingCountryCode} = useContext(ConnContext)
    var timeout = GlobalizationLanguage("/timeout")  // const [freshData, setfreshData] = useState({});
    const sendersettings=GlobalizationLanguage("/sendersettings")
    const sendersettlement=GlobalizationLanguage("/sendersettlement")
    const senderapi=GlobalizationLanguage("/senderapi")
    const team=GlobalizationLanguage("/team")
    var sendermessage = GlobalizationLanguage("/sendermessage")
    var refPage = GlobalizationLanguage("/referrals_sender")

    var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

    const [country,setCountry]=useState(countryvalue);
  
    useEffect(() => {
        setCountry(Cookies.get('i18next'));
      },[Cookies.get('i18next')])
      
  
    
    const links =  [
   
    //   {
    //     path: team,
    //     name: "Team",
    //     state:'inactive'
    //   },
  
    //   {
    //     path: senderapi,
    //     name: "API",
    //     state:'inactive'
    // },  
    //   {
    //     path: sendersettlement,
    //     name: "Settlement",
    //     state:'inactive'
    //   },
      // {
      //   path: sendermessage,
      //   name: "Help",
      //   state:'inactive'
      // },
      
      {
        path: refPage,
        name: "Referral",
        icon: "devices",
        state:'inactive'
      },
      {
        path: sendersettings,
        name: "Settings",
        icon: "settings",
        state:'inactive'
      },
    ]
  
      const capitalize =(x)=>{
        return(
          x
          //  x.charAt(0).toUpperCase() + x.slice(1)
        ) 
      }
    return(
        <div>
           <SenderSideBar pageTitle="Message" links={links}/>
            <div className="moblie-msg-wrap">
                {isloading ? (
                        <>
                        <Box
                            sx={{ width: "100%", marginBottom: "10px", marginTop: "4px" }}
                        >
                            <LinearProgress color="success" />
                        </Box>
                        </>
                    ) : (
                        <></>
                    )
                }

                <table className="mobile-msg-table">
                    {
                        [...enquiryMsg]?.reverse()?.length === 0 ?
                        <tbody className="moblie-table">
                        <tr className='mobile-msg-row'>
                            <td className='msg-tdata' colSpan={3}> -- You don't have message(s) yet -- </td>
                        </tr>
                        </tbody>
                        :
                        [...enquiryMsg]?.reverse()?.sort(function (left, right) {
                          return moment.utc(left.msg_time_stamp).diff(moment.utc(right.msg_time_stamp))
                      })?.reverse()?.map((msg,index) =>

                        <tbody className="moblie-table">
                            <tr className="mobile-msg-row" key={index} 
                            // onClick={() => {history.push( `/mssg/${msg.delivery_id}`) }}
                            onClick={()=> history.push(GlobalizationLanguagewithCOntryCode(`/mssg/${msg?.delivery_id}`, country, visitingCountryCode))}
                            
                            >
                                <td className="mobile-msg-tdata">
                                    <div className="mob-msg-img-con">
                                        <div className="mob-msg-img">
                                            <h3>{capitalize(msg?.fulfiller_conversation[0]?.fulfiller_id?.charAt(0))}</h3>
                                            

                                        </div>
                                    </div>
            
                                </td>
                                <td  className="mobile-msg-tdata">
                                    <h4>{capitalize(msg['subject:quote']) }</h4>
                                    <p>{capitalize(msg?.fulfiller_conversation[0]?.conversation[0]?.msg)}</p>
                                </td>
                                <td className="mobile-msg-tdata">
                                { moment().diff(msg.msg_time_stamp, 'days') === 0?
                                      <> 
                                    
                                     
                                      {moment(msg.msg_time_stamp).format(
                                      " h:mm a ")}
                                      </>
                                      :
                                      <> 
                                      {/* { console.log(msg.msg_time_stamp,"ve")} */}
                                      {/* {moment(msg.msg_time_stamp).format("MMM D") } */}
                                      {moment(msg.msg_time_stamp).format(
                                      "MMM Do YYYY ")}
                                      </>              
                                    } 
                                </td>

                            </tr>
                            
                        </tbody>
                        )
                    }
                </table>
                {/* <div  > <img src={directMsg} alt="" className='mobile-direct-msg-icon' /></div> */}
            </div>
           <SenderBottomNav state="message"/>
        </div>
    )
}