import React,{useContext, useEffect, useState} from 'react'
import './TopMenu.css'
import {HiMenu} from 'react-icons/hi'
import {AiOutlineCaretDown, AiOutlineClose} from 'react-icons/ai'
import dillivry from '../../assets/Dillivry.png'
import {animateScroll as scroll} from 'react-scroll'
// import Drawal from './Drawal'
import {Link} from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import SecureLS from 'secure-ls';
import { useHistory } from 'react-router-dom';

import GlobalizationLanguage from '../GlobalizationLanguage'
function TopMenu() {
    const login = GlobalizationLanguage("/login")
    const Dashboard = GlobalizationLanguage("/Dashboard")
  var viewQuote = GlobalizationLanguage("/viewQuote")

    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

    const authContext = useContext(AuthContext)

    const [loggedIn, setLogggedIn] = useState(false);
    const [type, setType] = useState('')
    const history = useHistory();
    useEffect(() => {
        if(ls.get('eid') === ""){
            setLogggedIn(false)
        }
        else{
            const acc_type = ls.get('_iRl');
            if(acc_type === 'individual'){
                setType('individual')
            }
            else{setType('business')}
            setLogggedIn(true)
        }
    }, [])
    return (
        <div>
            <div className="wrapper">
                <nav>
                {/* <input type="checkbox" id="show-search"/> */}
                <input type="checkbox" id="show-menu"/> 
                <label htmlFor="show-menu" className="menu-icon"><HiMenu style={{fontSize:'30px', display:'flex', alignItems:'center', justifyContent:'center'}}/></label>
                {
                    loggedIn ?
                        <div className="content">
                            <Link to='/'>
                                <div className="logo">
                                    <img src={dillivry} alt="" />
                                </div>
                            </Link> 
                        
                            {/* <Drawal/> */}
                                <ul className="links">
                                <li>
                                    <input type="checkbox" id="show-menu"/> 
                                        <label htmlFor="show-menu" className="menu-icon"><AiOutlineClose style={{fontSize:'30px', display:'flex', alignItems:'center', justifyContent:'center'}}/></label>
                                    </li>   
                                    <li>
                                        <a href="#" className="desktop-link" >Company <AiOutlineCaretDown style={{color:'black', fontSize:'15px'}}/> </a>
                                        <input type="checkbox" id="show-company"/>
                                        <label htmlFor="show-company">Company<AiOutlineCaretDown style={{color:'black', fontSize:'15px'}}/></label>
                                        <ul>
                                            <li><a href="#">About us</a></li>
                                            <li><a href="#">Blog</a></li>
                                            <li><a href="#">Careers</a></li>
                                            <li><a href="#">Investors Relations</a></li>
                                            <li><a href="#">Newsroom</a></li>
                                        </ul>
                                    </li>
                                    <li><a className='gapRight' href="#">Help</a></li>
                                    
                                    <li><Link to='/'><span onClick={()=> scroll.scrollTo(1300)}>Why Dillivry</span></Link></li>
                                {/* <li><Link to='/'><span onClick={()=> scroll.scrollTo(1800)}>How it works</span></Link></li> */}
                                    {/* <li><a href="#">API</a></li> */}
                                    <li><Link to={GlobalizationLanguage(`/open-deliveries`)}>Open deliveries</Link></li>
                                    <li><Link to={GlobalizationLanguage(`/request-a-delivery`)} className='signup'>Request a delivery</Link></li>
                                    <li>
                                        <Link to={type === 'individual' ?  viewQuote : authContext.isAuth ? Dashboard : login} className='signup'>
                                            My Dillivry
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            :
                            <div className="content">
                        <Link to={GlobalizationLanguage('/')}>
                            <div className="logo">
                                <img src={dillivry} alt="" />
                            </div>
                        </Link> 
                    
                        {/* <Drawal/> */}
                            <ul className="links">
                            <li>
                                <input type="checkbox" id="show-menu"/> 
                                    <label htmlFor="show-menu" className="menu-icon"><AiOutlineClose style={{fontSize:'30px', display:'flex', alignItems:'center', justifyContent:'center'}}/></label>
                                </li>  
                                <li>
                                    <a href="#" className="desktop-link" >Company <AiOutlineCaretDown style={{color:'black', fontSize:'15px'}}/> </a>
                                    <input type="checkbox" id="show-company"/>
                                    <label htmlFor="show-company">Company<AiOutlineCaretDown style={{color:'black', fontSize:'15px'}}/></label>
                                    <ul>
                                        <li><a href="#">About us</a></li>
                                        <li><a href="#">Blog</a></li>
                                        <li><a href="#">Careers</a></li>
                                        <li><a href="#">Investors Relations</a></li>
                                        <li><a href="#">Newsroom</a></li>
                                    </ul>
                                </li>
                                <li><a className='gapRight' href="#">Help</a></li>
                                
                                {/* <li><span onClick={()=> scroll.scrollTo(1300)}><a href="#" >Why Dillivry</a></span></li> */}
                                <li><Link to={GlobalizationLanguage('/')}><span onClick={()=> scroll.scrollTo(1300)}>Why Dillivry</span></Link></li>
                                {/* <li><Link to='/'><span onClick={()=> scroll.scrollTo(1800)}>How it works</span></Link></li> */}


                                {/* <li><a href="#">API</a></li> */}
                                <li><Link to={GlobalizationLanguage(`/open-deliveries`)}>Open deliveries</Link></li>
                                <li><Link to={GlobalizationLanguage('/login')}>Login</Link></li>
                                <li><Link to={GlobalizationLanguage(`/request-a-delivery`)} className='signup'>Request a delivery</Link></li>
                                {/* <li><Link to='/logistics-companies' className='signup'>Sign up</Link></li> */}


                            </ul>
                        </div>
                        
                }
                
              
                </nav>
            </div>
        </div>
    )
}

export default TopMenu