import React from "react";

import "./Settings.css"
import userAvatar from "../../../../assets/userAvatar.jpeg"

import SenderSideBar from "../../components/SenderSideBar";
import SenderBottomNav from "../../components/SenderBottomNav";
import { PuffLoader } from "react-spinners";
import GlobalizationLanguage from "../../../../components/GlobalizationLanguage";

export default function SettingsMobile({
  accountType,
  image_file,
  userPassport,
  imageHandler,
  personaName,
  email,
  passclick,
  updatingPassport,
  history


}) {
  const sendersettings_business=GlobalizationLanguage("/sendersettings/business")
  var timeout = GlobalizationLanguage("/timeout")  // const [freshData, setfreshData] = useState({});
  const sendersettings=GlobalizationLanguage("/sendersettings")
  const sendersettlement=GlobalizationLanguage("/sendersettlement")
  const senderapi=GlobalizationLanguage("/senderapi")
  const team=GlobalizationLanguage("/team")
  var sendermessage = GlobalizationLanguage("/sendermessage")
  var viewQuote = GlobalizationLanguage("/viewQuote")
  var viewQuote_quote = GlobalizationLanguage(
    "/viewQuote/quotes"
  );
  var walletsender = GlobalizationLanguage("/wallet_sender")
  var viewQuote_request_delivery = GlobalizationLanguage("/viewQuote/request-a-delivery")
  const forgetpass=GlobalizationLanguage("/forgetpass")
  var RefPage = GlobalizationLanguage("/referrals_sender")

  const businessLink =  [
    {
      path: sendersettings,
      name: "Personal",
      state: "active"
    },
    {
      path: sendersettings_business,
      name: "Business",
      state: "inactive"
    },
    // {
    //   path: sendersettings,
    //   name: "Security",
    //   state: "inactive"
    // }
  ]

  const individualLink =  [
    {
      path: sendersettings,
      name: "Personal",
      state: "active"
    },
    // {
    //   path: sendersettings,
    //   name: "Security",
    //   state: "inactive"
    // },
    
  ]

  return(
    <div className='sender-settings-wrap'>
      {
        accountType === "business_admin" ?
        <SenderSideBar pageTitle="Settings" links={businessLink}/>
        :
        <SenderSideBar pageTitle="Settings" links={individualLink}/>
      }
      <div className="personal-settings">

        <div className="personal-image">
        {
          updatingPassport ?
          <PuffLoader color='var(--main)' loading={updatingPassport} speedMultiplier={1} size={100} />
            :
            <>
              <img src={image_file !== "" ? image_file : userPassport !== "" ? userPassport : userAvatar} />
              <div className="change-image">
                <label htmlFor="image_files" style={{ color: 'var(--main)', textDecoration: 'underline', cursor: 'pointer' }}>Change</label>
                <input
                  style={{ display: "none" }}
                  id="image_files"
                  name="image_files"
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  onChange={imageHandler}
                />
              </div>
            </>
          }
        </div>
        
        <div className="personal-info" >
            <div className="personal-input-field-wrap">
              <div className="personal-input-field">
                <input type="text" value={personaName} />
                <input type="text" value={email} />
              </div>
            </div>

            <div className="change-password">
              <div className={passclick ? "passnone":"changebox" }>
                  <p onClick={()=>history.push(forgetpass)}> Change password</p>
                
              </div>
              
              <input type="password"  className={passclick ? "password-change" :"passnone" }/>
            </div>
        
            <div className="update-info-btn">
              <button className="cancel-update">Cancel</button>
              <button className="save-update">Save</button>
            </div>
        </div>
      </div>
        <SenderBottomNav/>
    </div>
  );
}
