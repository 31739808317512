import { useState, useEffect,useContext } from 'react';
import dillivrylogo from "../../assets/FooterLogo.png";
import facebook1 from "../../assets/WhiteFB.png";
import twitter1 from "../../assets/WhiteTwitter.png";
import LinkedIn from "../../assets/WhiteLinkedln.png";
import IG1 from "../../assets/WhiteInsta.png";
import "../Footer/Footer.css";
import { Link } from "react-router-dom";
import "../Scrolltotop/scroll.css";
import HomeHelp from '../HomeHelp';
import { ConnContext } from "../../context/ConnContext"
import { useHistory } from 'react-router-dom';

import $ from 'jquery'
import GlobalizationLanguage from '../GlobalizationLanguage';
import Cookies from 'js-cookie';
export default function Footer() {
    const [showHelp, setshowHelp] = useState(false);
    const [showScroll, setShowScroll] = useState(false);
    const history = useHistory();
    const addToFooter = () =>{
        if(process.env.REACT_APP_ENVIRONMENT === 'PROD'){    
          $('body').append(`
          <script>
            var x =0;
            var timer = setInterval(function(){
                if(document.querySelectorAll('.swal2-success').length>0){
                if(x==0){
                    gtag('event', 'conversion', {'send_to': 'AW-10820955880/mJ1sCLLx25YDEOjd6qco'});
                    x=1;
                }
                clearInterval(timer);
                }
            })
            </script>
            
            <script>
            if(window.location.href.indexOf('/viewQuote')!=-1)
            {
            gtag('event', 'conversion', {'send_to': 'AW-10820955880/PEm5CIGmidEDEOjd6qco'});
            }
            </script>
          `)
        }
      }

    useEffect(() => {
        addToFooter();
    }, [Cookies.get('i18next')])
    // const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false)
        }
    };

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    window.addEventListener('scroll', checkScrollTop)

    return (
        <>
            <div className="footercontainer">
                <div className='home_max_width'>
                    <div className="container pt-5">
                        <div className="row">
                            <div className="col-lg-3 mb-5">
                                <img className="dillivrycontainerLogo" src={dillivrylogo} alt="" onClick={scrollTop} style={{ cursor: 'pointer' }} />

                                <div className="streettext">
                                    {/* <div> */}
                                    {/* <p>7 Adefowope Street, Ilupeju, Lagos, Nigeria.</p> */}
                                    {/* </div> */}
                                    {/* <div> */}
                                    {/* <p>Epworth House, 25 City Rd, Shoreditch,</p><p> London EC1Y 1AA</p> */}
                                    {/* </div> */}
                                    <div style={{ marginTop:'10px', display: 'block' }} className="linkdiv">
                                        <p>Connect with us :<Link to="" onClick={() => setshowHelp(!showHelp)} style={{ color: "white" }} className="link"> care@dillivry.com</Link></p>
                                    </div>

                                </div>
                                <div className="iconscontainer">
                                    <a href="https://www.facebook.com/Dillivry-110591434740495" target="_blank" rel="noopener noreferrer"><img src={facebook1} alt="dillivry" style={{ cursor: 'pointer' }} /></a>
                                    <a href="https://twitter.com/dillivry" target="_blank" rel="noopener noreferrer"><img src={twitter1} alt="dillivry" style={{ cursor: 'pointer' }} /></a>
                                    <a href="https://www.instagram.com/dillivry_1/" target="_blank" rel="noopener noreferrer"><img src={IG1} alt="dillivry" style={{ cursor: 'pointer' }} /></a>
                                    <a href="https://www.linkedin.com/company/dillivry" target="_blank" rel="noopener noreferrer"><img src={LinkedIn} alt="dillivry" style={{ cursor: 'pointer', width: '17px', height: '17px' }} /></a>

                                </div>
                            </div>

                            <div className="row col-lg-7 offset-lg-2">
                                <div className="col-6 col-sm-4  mb-5">
                                    <h4>Company</h4>
                                    <Link onClick={scrollTop} to={GlobalizationLanguage("/")}><p>About Dillivry</p></Link>
                                    <Link to="#"><p>Career</p></Link>
                                    <Link to={GlobalizationLanguage(`/CookiePolicy`)}><p className="p1">Cookie policy</p></Link>
                                    <Link to="#"><p>Press and media</p></Link>
                                    <Link to={GlobalizationLanguage(`/Privacy`)} ><p className="p1">Privacy policy</p></Link>
                                    <Link to={GlobalizationLanguage(`/Terms`)}><p className="p1">Terms</p></Link>
                                </div>
                                <div className="col-6 col-sm-4  mb-5">
                                    <h4>Services</h4>
                                    <Link to={GlobalizationLanguage(`/request-a-delivery`)}><p>Request delivery</p></Link>
                                    <Link to={GlobalizationLanguage(`/open-deliveries`)}><p>Open deliveries</p></Link>
                                    <Link to={GlobalizationLanguage(`/riders-and-drivers`)}><p>Riders</p></Link>
                                    <Link to={GlobalizationLanguage(`/riders-and-drivers`)}><p>Drivers</p></Link>
                                    <Link to={GlobalizationLanguage(`/logistics-companies`)}><p>Logistics companies</p></Link>
                                </div>
                                <div className="col-6 col-sm-4  mb-5">
                                    <h4>Support</h4>
                                    <Link to={GlobalizationLanguage(`/faq`)}><p>FAQ</p></Link>
                                    <Link ><p onClick={() => setshowHelp(!showHelp)}>Help</p></Link>
                                    <Link to={GlobalizationLanguage(`/contact-us`)}><p>Contact Us</p></Link>
                                </div>
                            </div>

                        </div>

                        {
                            showHelp ?
                                <HomeHelp showDialog={showHelp} onClose={() => setshowHelp(false)} />
                                :
                                null
                        }

                        <div className="footerflex">
                            <div className="copyrightcontainer">
                                <p>&copy; {new Date().getFullYear()} Dillivry.</p>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <ScrollArrow/> */}
            </div>
        </>
    )
}