import React from 'react';
// import { OutlinedInput, InputLabel } from '@material-ui/core';
// import './InputField.css'
// import { createTheme } from '@material-ui/core/styles';
// import { ThemeProvider } from '@material-ui/styles';
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';



export default function InputField(props, value) {

  return (
      <TextField
      variant='outlined'
      size="small"
      InputProps={{
        endAdornment: props.endIcon,
        classes: {
            notchedOutline: {
              borderColor: '#FFFFFF',
              borderWidth: 1,
              '&:hover': {
                  borderColor: '#ff0000',
                  borderWidth: 2
              },
            }
        },
        // value: {value}
     
    }}
       {...props}
     
      />
  );
}