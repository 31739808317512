import React, { useEffect } from 'react'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import { useState } from 'react';

function DateRanger({onChange, ranges, minDate}) {
    const [Today, setToday] = useState('')
    
    useEffect(() => {
        if(minDate !== undefined){
            setToday(new Date(minDate)) 
        }
        else{
            setToday(new Date()) 
        }
    }, [])

    return (
        <DateRange
            editableDateInputs={true}
            onChange={onChange}
            moveRangeOnFirstSelection={false}
            minDate = {Today}
            ranges={ranges}
            color='blue'
            startDatePlaceholder="From"
            endDatePlaceholder="To"
        />
    )
}

export default DateRanger
