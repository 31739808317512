import './RegStages.css'
import {IoDocumentText} from 'react-icons/io5'
import { FiChevronRight } from 'react-icons/fi'
import {AiOutlineFileDone} from 'react-icons/ai'
import { BiErrorAlt } from 'react-icons/bi'
import { FaBusinessTime } from 'react-icons/fa'

function RegStages({title, chevron, onClick, status, disableState}) {

    const amDisabled = () => console.log('disabled')
    return (
        <div className={ status === 'verified' ? 'regStagesVerified' : status === 'not_yet_provided' ? 'regStagesNoProvided' : status === 'not_verified' ? 'regStages' : 'regStagesNoProvided'} 
            onClick={disableState === "not_yet_provided" ? amDisabled : status === 'not_yet_provided' ? onClick : status === 'not_valid' ? onClick : null }
            >
            <div className="stageIcon">
                {
                    status === 'not_yet_provided'  ?
                        <IoDocumentText size="28" color= 'var(--dark)'/>
                    :
                    status === 'not_verified' ?
                        <FaBusinessTime size="28" color= 'var(--dark)'/>
                    :
                    status === 'verified' ?
                        <AiOutlineFileDone size="28" color= "var(--main)"/>
                    :
                    status === 'not_valid' ?
                        <BiErrorAlt size="28" color="red"/>
                    :
                    null
                }
            </div>
            <div className='stageleft'>
                <div className="stageText">
                    <div style={{fontWeight:'600', fontSize:'16px'}}>
                        {
                            status === 'verified' ?
                            <span style={{color:'var(--main)'}}> {title}  </span>
                            :
                            status === 'not_yet_provided' ?
                            <span style={{color:'var(--dark)'}}> {title}  </span>
                            :
                            status === 'not_verified' ?
                            <span style={{color:'grey'}}> {title}  </span>
                            :
                            status === 'not_valid' ?
                            <span style={{color:'var(--dark)'}}> {title}  </span>
                            :
                            null
                        }
                    </div>
                    <div style={{fontWeight:'400', fontSize:'13px'}}>
                        {
                            disableState === "not_yet_provided" ?
                            <span style={{color:'black'}}>Complete the step above</span> 
                            :

                            status === 'not_yet_provided' ?
                            
                            <span style={{color:'black'}}>Recommended next step</span> 

                            :
                            status === 'not_verified' ?
                            <span style={{color:'grey'}}>Pending Verification</span> 
                            
                            :
                            status === 'verified' ?
                            <span style={{color:'var(--main)'}}>Verified</span> 

                            :
                            status === 'not_valid' ?
                            <span style={{color:'red'}}>Need your attention</span> 
                            :
                            null
                        }
                    </div>
                </div>
                <div className="stageArrow">
                    {
                        status === 'not_yet_provided' ?
                            <FiChevronRight color='black'/>
                        :
                        status === 'not_verified' ?
                            null
                        :
                        status === 'verified' ?
                            null
                        :
                        status === 'not_valid' ?
                            <FiChevronRight color='black'/>
                        :
                        null
                    }
                </div>
            </div>
           
        </div>
    )
}

export default RegStages
