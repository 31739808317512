import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
// import Controls from "./controls/Controls";
import CloseIcon from '@material-ui/icons/Close';
import { Grid } from '@mui/material';
import { PuffLoader } from "react-spinners";
import Currency from '../../utils/Currency';
import './style.css';
import Paystack_sender_single_payment from '../Paystack_sender_single_payment';
import SecureLS from 'secure-ls';
import BankTransfer from './BankTransfer';
import dillivrylogo from "../../pages/Sender/SenderDashboard/Assets/dillivrylogo.svg";
import WalletFlow from './WalletFlow';
import App from '../Stripe/App';
import DialogPop from '../DialogPop';

function  MakePayment({ openPopup, setopenPopup, deliveries, walletbalance,setwalletbalance,  promoDetails, loading }) {

    const ls = new SecureLS({
        isCompression: true,
        encodingType: "rabbit",
        encryptionSecret: "dm_dillivry",
    });

    useEffect(() => {
        setsubTotal(deliveries.quote_amount)
        settotal(deliveries.quote_amount)
        if(deliveries.mode_of_payment === "pay_by_ussd" || deliveries.mode_of_payment === "pay_by_card"){
            setpayment_method('pay_by_card') 
        }
        else if(deliveries.mode_of_payment === "pay_by_transfer") {
            setpayment_method('pay_by_transfer')
        }
        else{
            setpayment_method("pay_by_wallet")
        }
        // console.log(deliveries, "delivery")
    }, [deliveries])

    const [subTotal, setsubTotal] = useState(0);
    const [promoCode, setpromoCode] = useState("");
    const [promoValue, setpromoValue] = useState(0);
    const [promoAmount, setPromoAmount] = useState(0);

    const [total, settotal] = useState(0);

    const [applying, setApplying] = useState(false);
    const [errorMsg, seterrorMsg] = useState("");
    const [applied, setapplied] = useState(false);
    const [stripepayment, setStripePayment] = useState(false);

    const [payment_method, setpayment_method] = useState('pay_by_wallet')

    const [showTransferFlow, setShowTransferFlow] = useState(false)

    const [showWalletFlow, setShowWalletFlow] = useState(false)

    const Date_diff = (firstDate, secondDate) => {
        const d = firstDate;
        const today = secondDate;
        const diffTime = d - today;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        return diffDays;
    };
    const numberWithCommas = new Intl.NumberFormat("en-GB");

    const applyPromo = () => {
        setApplying(true);
        setTimeout(() => {
            if (promoDetails === undefined) {
                setapplied(false);
                seterrorMsg("No ongoing promo at the moment");
            } else {
                if (promoCode === promoDetails?.promo_code) {
                    if (Date_diff(new Date(), new Date(promoDetails?.start_date)) < 0) {
                        setapplied(false);
                        seterrorMsg("Promo have not started");
                    } else if (
                        Date_diff(new Date(promoDetails?.end_date), new Date()) > 0
                    ) {
                        if (promoDetails?.usage_left > 0) {
                            setapplied(true);
                            setpromoValue(parseFloat(promoDetails?.discount));
                            settotal((1 - parseFloat(promoDetails?.discount)) * subTotal); //sum up arrays of numbers
                            setPromoAmount(parseFloat(promoDetails?.discount) * subTotal);
                        } else {
                            seterrorMsg("Promo usage reached");
                            setapplied(false);
                        }
                        console.log("promo have not ended");
                    } else {
                        seterrorMsg("promo have ended");
                        setapplied(false);
                    }
                } else {
                    setpromoValue(0);
                    seterrorMsg("invalid promo code");
                    setapplied(false);
                    settotal(subTotal);
                    setPromoAmount(0);
                }
            }
            setApplying(false);
        }, 1000);
    };

    // const {head, children, openPopup, setopenPopup} = props;
    return (
        <>
            <Dialog open={openPopup} maxWidth='xs' fullWidth>
                <DialogTitle>
                    <div style={{ display: "flex" }}>
                        <Typography variant="h6" component="div" style={{ flexGrow: 1, fontSize: '18px' }}>
                            Select payment method
                        </Typography>
                        <CloseIcon onClick={() => { setopenPopup(false) }}></CloseIcon>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="popContainer_payment">
                        {
                            loading ?
                                <div style={{width:'100%', display:'flex', justifyContent:'center', height:'70px'}}>
                                    <PuffLoader
                                        color="var(--main)"
                                        loading={loading}
                                        speedMultiplier={1}
                                        size={36}
                                    />
                                </div>
                                :
                                <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                                    <div
                                        style={{
                                            fontWeight: "500",
                                            fontSize: "13px",
                                            marginBottom: "-12px",
                                        }}
                                    >
                                        Apply promo code
                                    </div>
                                    <div>
                                        <Grid container spacing={1}>
                                            <Grid item xs={8}>
                                                <input
                                                    value={promoCode}
                                                    onChange={(e) => {
                                                        setpromoCode(e.target.value);
                                                        seterrorMsg("");
                                                    }}
                                                    className="promo_input"
                                                    type="number"
                                                    placeholder="Enter promo code"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <button
                                                    disabled={applying}
                                                    onClick={applyPromo}
                                                    className="promo_btn"
                                                >
                                                    {applying ? (
                                                        <PuffLoader
                                                            color="white"
                                                            loading={applying}
                                                            speedMultiplier={1}
                                                            size={18}
                                                        />
                                                    ) : (
                                                        "Apply"
                                                    )}
                                                </button>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div
                                                    style={{
                                                        fontSize: "13px",
                                                        color: "maroon",
                                                        fontStyle: "italic",
                                                    }}
                                                >
                                                    {errorMsg}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "10px",
                                            marginBottom: "20px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span style={{ fontSize: "14px", fontWeight: "500" }}>
                                                Sub total
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "14px",
                                                    fontWeight: "500",
                                                    color: "blue",
                                                }}
                                            >
                                                <Currency location={deliveries?.delivery_location ? deliveries?.delivery_location : deliveries?.vcc} />{numberWithCommas.format(subTotal)}
                                            </span>

                                        </div>
                                        <div
                                            style={{
                                                height: "1px",
                                                backgroundColor: "#E7E7E7",
                                                width: "100%",
                                            }}
                                        ></div>
                                        <div
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span style={{ fontSize: "13px", fontWeight: "400" }}>
                                                Promo value
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "13px",
                                                    fontWeight: "400",
                                                    color: "blue",
                                                }}
                                            >
                                                <Currency location={deliveries?.delivery_location ? deliveries?.delivery_location : deliveries?.vcc} />{numberWithCommas.format(promoAmount)}
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                height: "1px",
                                                backgroundColor: "#E7E7E7",
                                                width: "100%",
                                            }}
                                        ></div>

                                        <div
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <span style={{ fontSize: "15px", fontWeight: "600" }}>
                                                Total
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: "15px",
                                                    fontWeight: "600",
                                                    color: "blue",
                                                }}
                                            >
                                                <Currency location={deliveries?.delivery_location ? deliveries?.delivery_location : deliveries?.vcc} />{numberWithCommas.format(total)}
                                            </span>
                                        </div>
                                    </div>

                                    {deliveries?.delivery_location === "gb" || deliveries?.vcc === "gb" ? (
                                        <>
                                            <div className='paystack_btn'
                                                onClick={() => {
                                                    setStripePayment(!stripepayment);
                                                }}
                                            >
                                                Pay with stripe
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <select className='select_mode' value={payment_method} onChange={(e) => setpayment_method(e.target.value)} id="cars">
                                                <option value="pay_by_wallet">Pay from wallet</option>
                                                <option value="pay_by_transfer">Pay by transfer</option>
                                                <option value="pay_by_card">Pay with paystack</option>
                                                {/* <option value="pay_by_ussd">USSD</option> */}
                                                {/* <option value="pay_by_cash">Cash</option> */}
                                            </select>
                                            {
                                                payment_method === "pay_by_wallet" || payment_method === "pay_by_cash" ?
                                                    <div className="paystack_btn"
                                                        onClick={() => { setShowWalletFlow(true); setopenPopup(false) }}
                                                    >
                                                        Proceed
                                                    </div>
                                                    :
                                                    payment_method === "pay_by_transfer" ?
                                                        <div className="paystack_btn"
                                                            onClick={() => { setShowTransferFlow(true); setopenPopup(false) }}
                                                        >
                                                            Proceed
                                                        </div>
                                                        :
                                                        payment_method === "pay_by_card" || payment_method === "pay_by_ussd" ?
                                                            <Paystack_sender_single_payment
                                                                amount={total + "00"} //for paystack
                                                                amount_paid={total}
                                                                promoCode={applied ? promoCode : ""}
                                                                discount={applied ? promoDetails.discount : ""}
                                                                // discount={promoValue}
                                                                quote_amount={deliveries?.quote_amount}
                                                                fulfiller_id={deliveries?.fulfiller_id}
                                                                delivery_location={deliveries?.delivery_location ? deliveries?.delivery_location : deliveries?.vcc}
                                                                delivery_id={deliveries?.delivery_id}
                                                                email={ls.get("eid")}
                                                                closePopup={() => setopenPopup(false)}
                                                            />
                                                            :
                                                            <></>
                                            }
                                        </>
                                    )}
                                </div>
                        }




                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={showTransferFlow} maxWidth='xs' fullWidth>
                <DialogTitle>
                    <div style={{ display: "flex", width: "100%", }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div className="head_logo">
                                <img
                                    src={dillivrylogo}
                                    alt="dillivrylogo"
                                    className='pay_logo'
                                />
                            </div>

                            <div className="name_and_pay">
                                <div className="sender__email">
                                    <h1 className="main_sender_email">
                                        {deliveries?.user_id}
                                    </h1>
                                </div>
                                <div className="amouttopay">
                                    Pay
                                    <span> <Currency location={deliveries?.delivery_location ? deliveries?.delivery_location : deliveries?.vcc} />{numberWithCommas.format(total)}</span>
                                </div>
                            </div>
                        </div>
                        <CloseIcon
                            style={{ marginRight: '-20px' }}
                            onClick={() => { setShowTransferFlow(false); setopenPopup(true) }}
                        />
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="popContainer_payment">
                        <BankTransfer deliveries={deliveries} total={total} />
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={showWalletFlow} maxWidth='xs' fullWidth>
                <DialogTitle>
                    <div style={{ display: "flex", width: "100%", }}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div className="head_logo">
                                <img
                                    src={dillivrylogo}
                                    alt="dillivrylogo"
                                    className='pay_logo'
                                />
                            </div>

                            <div className="name_and_pay">
                                <div className="sender__email">
                                    <h1 className="main_sender_email">
                                        {deliveries?.user_id}
                                    </h1>
                                </div>
                                <div className="amouttopay">
                                    Pay
                                    <span> <Currency location={deliveries?.delivery_location ? deliveries?.delivery_location : deliveries?.vcc} />{numberWithCommas.format(total)}</span>
                                </div>
                            </div>
                        </div>
                        <CloseIcon
                            style={{ marginRight: '-20px' }}
                            onClick={() => { setShowWalletFlow(false); setopenPopup(true) }}
                        />
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <div className="popContainer_payment">
                        <WalletFlow
                            deliveries={deliveries}
                            total={total}
                            wallet_balance={walletbalance}
                            setwalletbalance={setwalletbalance}
                            closePopup={()=>{setShowWalletFlow(false);}}
                            openPopup={()=>{setShowWalletFlow(true); setopenPopup(false);}}
                        />
                    </div>
                </DialogContent>
            </Dialog>

            <DialogPop
                openPopup={stripepayment}
                setopenPopup={setStripePayment}
                head="Payment"
            >
                <App total={total} item={deliveries} />
            </DialogPop>


        </>
    )
}
export default MakePayment;