import { useEffect,useContext, useState } from 'react'
// import './Message.css';
import moment from 'moment'
import {useHistory, useParams} from 'react-router-dom';
import { AuthContext } from '../../../../context/AuthContext';
import { DashboardContext } from '../../../../context/Dashboard/DashboardProvider/dashboard.provider';
import SecureLS from 'secure-ls';
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import axios from 'axios';
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import CorporateLayout from '../CorporateLayout';
import MobileCorpLayout from "../CorporateComponents/MobileCorpLayout";
import DashBottomBtn from '../DashBottomBtn/DashBottomBtn';

import GlobalizationLanguage , {GlobalizationLanguagewithCOntryCode} from '../../../../components/GlobalizationLanguage';
import { ConnContext } from '../../../../context/ConnContext';
import Cookies from 'js-cookie';
import { Avatar } from '@mui/material';

function MessageFulfiller() {

  const { visitingCountryCode } = useContext(ConnContext);

  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

  useEffect(() => {
      setCountry(Cookies.get('i18next'));
    },[Cookies.get('i18next')])

  const [country,setCountry]=useState(countryvalue);




    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
    const [enquiryMsg, setEnquiryMsg] = useState([])
    const [msgLength, setmsgLength] = useState(0)
    const history = useHistory()
    const [msg, setmsg] = useState([])
    const auth = useContext(AuthContext);

    const {dashboards, updateDashboard} = useContext(DashboardContext)
    const [ShowLogin, setShowLogin] = useState(false)
    const [loadingData, setloadingData] = useState(false)
    
    const endpoint = process.env.REACT_APP_API

   
    useEffect(() => {
        window.scrollTo(0,0);
        if (ls.get('_iRl') === "" || ls.get('_iRl') === ('dillivry-sole-trader-ops') || ls.get('_nodi') === "") {
            setShowLogin(true)
            auth.logout();
        }
        else {
            if (dashboards !== undefined){
                setmsg(dashboards.data.quote_messages)
                setmsgLength(dashboards.data.quote_messages.length);
                setEnquiryMsg(dashboards.data.quote_messages.slice(0, n))
            }
            else{
                const decoded = jwt_decode(ls.get("_nodi"));
                const expired = decoded.exp;
                if (expired < Date.now() / 1000) {
                    Swal.fire({
                        icon: "warning",
                        title: "Session!",
                        text: "Session expired login again!",
                        allowOutsideClick: false,
                        confirmButtonColor: "var(--main)",
                    }).then((result) => {
                        if (result.value) {
                        auth.logout();
                        setShowLogin(true)

                        }
                    });
                }
                else{
                    setloadingData(true);
                    axios.get(`${endpoint}m-dillivry-dashboard-manager`, {
                    headers: {
                        Authorization: ls.get('_nodi')
                    }
                })
                .then((response) => {

                    ls.set(
                        "means_of_delivery",
                        JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                      );
                    setloadingData(false)
                    updateDashboard(response)
                    setmsg(response.data.quote_messages)
                    setmsgLength(response.data.quote_messages.length);
                    setEnquiryMsg(response.data.quote_messages.slice(0, n))       
                })
                .catch((error) => {
                    if (error?.response?.status === 403) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Session!',
                        text: 'Session expired login again!',
                        allowOutsideClick: false,
                        confirmButtonColor: 'var(--main)'
                    })
                        .then((result) => {
                            if (result.value) {
                                auth.logout();
                                setShowLogin(true)
                            }
                        });
                }
                else {
                    auth.logout();
                    setShowLogin(true)
                    }
                    setloadingData(false)
                })
            }}
        }
    }, [])
    const n = 10;

    // const SearchBar = () => {
    //     return (
    //       <div className="search">
    //         <div className="search-input-navbar">
    //           <input
    //             type="text"
    //              value=""
    //             // autoFocus
    //              placeholder="search.."
    //              onChange=""
    //           />
    //           {/* <span className="searchx-icon"><MdSearch style={{color:"#989898"}}/></span> */}
    //         </div>
    //       </div>
    //     );
    //   };

      const capitalize =(x)=>{
        return(
          x
          //  x.charAt(0).toUpperCase() + x.slice(1)
        ) 
      }
    
    return ( 
        <>
        <div className='deskstopcorp_con'>
        <CorporateLayout>
        {/* <SearchBar/> */}
            
        {
            ShowLogin ? 
            history.push('/login')
            :
            <div className='dashboard'>
                

              


                <div className=" dmMessag" style={{ backgroundColor:'white', marginTop:'10px'}}>
                    <div className="msgDivCon">
                        <table style={{border:"none"}}>
                            <thead  style={{border:"none", borderBottom:'1px solid gainsboro' }}>
                                <th   style={{color:'black', fontSize:'16px', fontWeight:"500", backgroundColor:"white"}}>From </th>
                                <th  style={{color:'black', fontSize:'16px' ,fontWeight:"500", backgroundColor:"white"}}> Subject</th>
                                <th style={{color:'black', fontSize:'16px' , fontWeight:"500", backgroundColor:"white"}}> Message</th>
                                <th style={{color:'black', fontSize:'16px' ,  fontWeight:"500",backgroundColor:"white"}}>{"Date & time"} </th>
                            </thead>
                            
                            <tbody >

                            {enquiryMsg.map((msg, index)=>{return <tr className='corpmsgtr' key={index}  style={{border:"none"}}onClick={()=>{
                                var link = GlobalizationLanguagewithCOntryCode(`/message/${msg.delivery_id}`, country, visitingCountryCode)
                                history.push(link)
                                }
                            }> 
                            {
                                enquiryMsg.length === 0 ? 
                                <td className="tablerowwrapper">
                                    You dont have message yet
                                </td>
                                :
                                <>
                                    <td className='corpmsgtd'> 
                                        {
                                            msg.conversation[0]?.fulfiller_id === undefined ?
                                                msg.conversation[0]?.user_id === ls.get('eid') ? 
                                                    <div className="tableRowfrom">{msg?.sender_name}</div>
                                                    :
                                                    <div className="tableRowfrom">{msg?.sender_name}</div>
                                            :
                                            <div className="tableRowfrom">{msg?.sender_name}</div>
                                        }
                                    </td>
                                    <td className='corpmsgtd'> 
                                        <p> 
                                            { 
                                                msg?.quote_subject.charAt(0)?.toUpperCase() + msg?.quote_subject.slice(1) 
                                                
                                            }
                                        </p>
                                        
                                        <span className='showNow'>{msg.conversation[0]?.msg}</span>
                                    </td>
                                    <td className='corpmsgtd' >
                                        {msg.conversation[0]?.msg}
                                    </td >
                                    <td  className='corpmsgtd'>
                                        { moment().diff(msg.conversation[0]?.time_stamp, 'days') === 0?
                                            <> 
                                                {moment(msg.conversation[0]?.time_stamp).format("hh:mm A")}
                                            </>
                                            :
                                            <> 
                                                {moment(msg.conversation[0]?.time_stamp).format("MMM D") }
                                            </>              
                                        } 
                                    </td>
                                    
                                </>
                                }
                            </tr>})}

                            </tbody>
                        </table>
                    </div>
                       {/* <DashBottomBtn activeTab='message'/> */}
                </div>
                {
                loadingData ?
                    <div style={{ width: '100%', height: '100vh', backgroundColor: 'none', zIndex: '300', position: 'fixed', top: '0', paddingTop: '5px' }}>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress color="success" />
                        </Box>
                    </div>

                    : null
                }
            </div>
        }
        </CorporateLayout>

        </div>
       


        <div className="mobilecorp_con"> 
           
              <div className="mobilecorp_gen">
              {
            ShowLogin ? 
            history.push('/login')
            :
            <div>
                  <MobileCorpLayout pageTitle="Message" />
                <table className="mobile-msg-table">
                    {
                        enquiryMsg.length === 0 ?
                        <tbody className="moblie-table">
                        <tr className='mobile-msg-row'>
                            <td className='msg-tdata' colSpan={3}> -- You don't have message(s) yet -- </td>
                        </tr>
                        </tbody>
                        :
                        enquiryMsg.map((msg,index) =>

                        <tbody className="moblie-table">
                            <tr className="mobile-msg-row" key={index} onClick={() => {history.push(GlobalizationLanguagewithCOntryCode(`/message/${msg.delivery_id}`, country, visitingCountryCode)) }}>
                                <td className="mobile-msg-tdata">
                                    <div className="mob-msg-img-con">
                                        <Avatar sx={{ bgcolor: 'var(--main)' }}>
                                            {
                                                msg.conversation[0]?.fulfiller_id === undefined ?
                                                    msg.conversation[0]?.user_id === ls.get('eid') ? 
                                                        capitalize(msg?.sender_name.charAt(0))
                                                        :
                                                        capitalize(msg?.sender_name.charAt(0))
                                                :
                                                capitalize(msg?.sender_name.charAt(0))
                                            }
                                        </Avatar>
                                    </div>
            
                                </td>
                                <td  className="mobile-msg-tdata">
                                {msg.conversation[0]?.msg}
                                </td>
                                <td className="mobile-msg-tdata">
                                { moment().diff(msg.conversation[0]?.time_stamp, 'days') === 0?
                                      <> 
                                        {moment(msg.conversation[0]?.time_stamp).format(" h:mm a ")}
                                      </>
                                      :
                                      <> 
                                        {moment(msg.conversation[0]?.time_stamp).format("MMM Do YYYY ")}
                                      </>              
                                    } 
                                </td>
                            </tr>
                        </tbody>
                        )
                    }
                </table>
                <DashBottomBtn activeTab='message'/>
            </div>
        }
        </div>
    </div>
    </>
    )
}
export default MessageFulfiller
