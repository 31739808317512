import React, { useState, useEffect, useContext } from "react";
import { usePaystackPayment } from "react-paystack";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import SecureLS from "secure-ls";
import { ConnContext } from "../context/ConnContext";
import DialogOutClick from './DialogOutClick';
import LoginCompV2 from './LoginCompV2';
import jwt_decode from "jwt-decode";

const Paystack_sender_fund_wallet = ({
  amount,
  email,
  amount_paid,
  closePopup,
  sender,
  refresh
}) => {
  
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const [TransDate, setTransDate] = useState('')

  const [waiting, setwaiting] = useState(false);
  const [ShowLogin, setShowLogin] = useState(false)

  // const refresh = () =>{
  //       window.location.reload();
  //     }

    useEffect(() => {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      setTransDate(date);
    }, [])

    const endpoint = process.env.REACT_APP_API
    
    const config = {
      reference: (new Date()).getTime(),
      email: email,
      amount: amount,
      channels: ['card', 'ussd'],
      publicKey: process.env.REACT_APP_ENVIRONMENT === "PROD" || process.env.REACT_APP_ENVIRONMENT === "TEST" ? process.env.REACT_APP_PAYSTACK_KEY : process.env.REACT_APP_PAYSTACK_KEY_DEMO, 
  };

  const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });

  const onSuccess = (reference) => {
   const data = {
    payment_gateway: "paystack",
    amount: `${amount_paid}`,
    reference_id: reference.reference,
    transaction_type: "wallet_credit"
   }
    setwaiting(true)
      authAxios
        .post(
          `/m-dillivry_instant-payment?attribute=wallet_credit`, data,
        )
        .then((response) => {

          refresh();

          Swal.fire({
            icon: "success",
            text: "Wallet funded successfully",
            confirmButtonColor: "var(--main)",
            timer: 3000
          })
          // .then(()=>{
            // refresh();
          // })
          ls.remove("item");
          ls.remove("delivery_id");
          
        })
        .catch((err) => {
          console.log(err, 'errrerer')
          if (err?.response?.status === 403) {
            Swal.fire({
              icon: "warning",
              title: "Session!",
              text: "Session expired login again!",
              allowOutsideClick: false,
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                ls.removeAll();
                history.push(`/login`);
              }
            });
          }
        });
    // }
  };

  const history = useHistory();
  // you can call this function anything
  const onClose = () => {
    
  };

  const initializePayment = usePaystackPayment(config);

  const tokenCheck = () =>{
    const decoded = jwt_decode(
      ls.get("_utk")
    );
    const expired = decoded.exp;
    if(expired <= Math.floor(Date.now() / 1000)){
        setShowLogin(true)
      }
    else{
      closePopup(); 
      initializePayment(onSuccess, onClose);
      }
  }

  return (
    <>
     {
       sender ? 
        <div  onClick={tokenCheck}  className={ amount_paid === '' || parseInt(amount_paid) < 100 ? 'paystack_btn_disable' : "paystack_btn"}>
          Proceed
        </div>
       :
       <div
       className={amount_paid === '' ||  parseInt(amount_paid) < 100 ? 'paystack_btn_disable' : "paystack_btn"}
        onClick={ ()=> amount_paid === '' ||  parseInt(amount_paid) < 100  ? console.log('') : tokenCheck()}>
        <span style={{ cursor: "pointer", borderRadius: "0px" }}>
          {" "}
          Proceed{" "}
        </span>
     </div>
     }
    {/* } */}
      
    <DialogOutClick
        open={ShowLogin}
        handleClose ={()=>{setShowLogin(false);  }}
        >
        <LoginCompV2 onFinish={() => {setShowLogin(false); closePopup(); initializePayment(onSuccess, onClose);}} />
      </DialogOutClick>
    </>
  );
};

export default Paystack_sender_fund_wallet;
