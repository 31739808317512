 import { createContext, useEffect } from 'react';
//  import AWS from 'aws-sdk';
 import SecureLS from 'secure-ls'

 export const TrackContext = createContext();

 export const TrackProvider = ({children}) =>{

  const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

    const trackAgent = () => {

      // AWS.config.update({
      //   region: ls.get('iLR_')?.region_name,
      //   endpoint: 'localhost:3000',
      //   accessKeyId: ls.get('iLR_')?.aws_access_key_id,
      //   secretAccessKey: ls.get('iLR_')?.aws_secret_access_key, 
      // });
    
      // var docClient = new AWS.DynamoDB.DocumentClient();

      // var params = {
      //   TableName : "dm_dillivry_tracking_tbl",
      //   KeyConditionExpression : "agent_id = :v1",
      //   ExpressionAttributeValues : {
      //       ':v1': "1234567",
      //   },
      // };

    
      // docClient.query(params, function(err, data) {
      //     if (err) {
      //       console.log (JSON.stringify(err, undefined, 2),'track error');
      //     } else {
      //       console.log (JSON.stringify(data, undefined, 2), 'track success');
      //     }
      // });
    }
     return(
         <TrackContext.Provider value={{
            trackAgent
             }}>
             {children}
         </TrackContext.Provider>
     )
 }

 

