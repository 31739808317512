import React, {useContext} from  'react'
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import axios from "axios";
import { useForm } from "react-hook-form";
import Enquiry from '../../../assets/Enquiry.gif'
import { AuthContext } from '../../../context/AuthContext';
import SecureLS from 'secure-ls';
import { ConnContext } from '../../../context/ConnContext';
import GlobalizationLanguage from '../../../components/GlobalizationLanguage';
import Cookies from 'js-cookie';

export default function EnquiryForm({ item, closePopup}) {
  var login = GlobalizationLanguage("/login")
  var timeout = GlobalizationLanguage("/timeout")

  const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
  const {visitingCountryCode} = useContext(ConnContext)
    const history = useHistory();
    const [submiting, setsubmiting] = useState(false);
    const auth = useContext(AuthContext);

    const Authorization =  ls.get("_nodi") 
        
    
        const authAxios = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: {
          Authorization: Authorization,
        },
      });

      const schema = yup.object().shape({
        QAmount: yup.string().matches(/^[0-9]*$/, "Amount has to be number"),
        // Texterr:  yup.string().required(),
      });
      const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
      });
    

      const quoteNow = (data) => {
       setsubmiting(true);

       authAxios
         .post(`m-dillivry-bid-manager?delivery_location=${Cookies.get("i18next")}`, {
           action_type: "msg",
           delivery_id: item.delivery_id,
           msg: data.msg,
           vcc: visitingCountryCode
         }, {timeout: 240000})
         .then((response) => {
           setsubmiting(false);
           closePopup();
           if (response.status === 200) {
               Swal.fire({
                iconHtml: `<img src=${Enquiry} style="width:100px;">`, // imageUrl: "dm-dillivry-web/web/src/assets/erros.png",
                title: 'Great!',
                imageAlt: 'A tall image',
                text: 'Your enquiry has been conveyed to the lister/viewQuote',
                confirmButtonColor: 'var(--main)',
                timer: 2500,
            })
           }
         })
         .catch((err) => {
            closePopup();
           console.log(err);
          setsubmiting(false);  

           if(err?.response?.status === 403){
              Swal.fire({
                icon: "warning",
                text: "Session expired, login again!",
              //  timer: 2500,
                confirmButtonColor: 'var(--main)',
              })
              .then((result) => {
                if (result.value) {
                  auth.logout();
                  history.push(login);
                }
            });
           }
           else{
            Swal.fire({
              icon: "warning",
              text: "Error occured try again!",
             timer: 2500,
              showConfirmButton: false,
            })
           }
           if (err.code === "ECONNABORTED") {
            console.log('internal-server-error-timeout')
            history.push(timeout)
            // window.location.reload()
          }
       });
      };
    


    return (
        <div  className="enquiry-body"   >
            

       <h2  className="Enquiry-title"  >Make Enquiry</h2>

      <form onSubmit={handleSubmit(quoteNow)} >

      <textarea {...register("msg")}  rows="3" cols="20" className="quoteinputcontent-"  required /> <br />
      {/* <br /> <small>{errors.Texterr?.message}</small> */}
       <div className="parent-send-div" >

        <div></div>

        <div>
{
  submiting ? <p style={{color: '#50b14a'}}  >Submitting</p> :         <button type="submit" className="btn-send"   >Send</button>
}
        </div>


       </div>



      </form>
       
        </div>
    )
}
