import React, { useContext, useEffect, useState } from "react";
import "./Sidebar.css";
import { Link, withRouter, Route  } from "react-router-dom";
import Dashboard from "./Assets/Dashboard.svg";
import { useHistory, useLocation } from 'react-router-dom';
import Quoteicon from '../../../assets/Quoteicon.svg';
import dash from '../../../assets/dash.svg';
import People from '../../../assets/people.svg'
import Api from '../../../assets/data.svg';
import settlement from '../../../assets/emptywallet.svg'
import settings2 from '../../../assets/setting2.svg'
import setting1 from '../../../assets/setting1.svg'
import dillivrylogo from './Assets/dillivrylogo.svg'
import note from '../../../assets/note.svg'
import messagecolor from '../../../assets/messagecolor.svg'
import people2 from './Assets/people2.svg'
import api2 from '../../../assets/api2.svg'
import sms19 from '../../../assets/sms19.svg'
import wallet1 from '../../../assets/wallet1.svg'
import wallet2 from '../../../assets/wallet2.svg'
import referarl from '../../../assets/referarl.svg'
import referralclicked from '../../../assets/referralclicked.svg'

import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
import { GlobalizationLanguagewithCOntryCode } from "../../../components/GlobalizationLanguage";
import Cookies from "js-cookie";
import { ConnContext } from "../../../context/ConnContext";

function Sidebar() {
  const { visitingCountryCode } = useContext(ConnContext);
  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()
  const [country,setCountry]=useState(countryvalue);

  useEffect(() => {
      setCountry(Cookies.get('i18next'));
    },[Cookies.get('i18next')])

  const location = useLocation();
  const history = useHistory();
  const sendersettings=GlobalizationLanguage("/sendersettings")
  const sendersettlement=GlobalizationLanguage("/sendersettlement")
  const senderapi=GlobalizationLanguage("/senderapi")
  const team=GlobalizationLanguage("/team")
  const walletsender = GlobalizationLanguage("/wallet_sender")
  const Referrals = GlobalizationLanguage("/referrals_sender")
   var sendermessage = GlobalizationLanguage("/sendermessage")
  var viewQuote = GlobalizationLanguage("/viewQuote")
  var viewQuote_quote = GlobalizationLanguage(
    "/viewQuote/quotes"
  );

  const QuoteData =  [
     
      {
        path: viewQuote,
        name: "Dashboard",
        icon: dash,
        iconclicked: Dashboard
      },
      {
        path: viewQuote_quote,
        name: "Quotes",
        icon: note,
        iconclicked: Quoteicon
      },
      {
        path: sendermessage ,
        name: "Messages",
        icon: sms19,
        iconclicked: messagecolor
      },
      
      // {
      //   path: team,
      //   name: "Team",
      //   icon: People,
      //   iconclicked: people2

      // },
      
      // {
      //   path: senderapi,
      //   name: "API",
      //   icon: Api,
      //   iconclicked: api2
      // },
      
      // {
      //   path: sendersettlement,
      //   name: "Settlement",
      //   icon: settlement,
      //   iconclicked: settlement
      // },

      {
        path: walletsender,
        name: "Wallet",
        icon: wallet1,
        iconclicked: wallet2
      },

      {
        path: Referrals,
        name: "Referrals",
        icon:  referarl ,
        iconclicked: referralclicked,
      },

      {
        path: sendersettings,
        name: "Settings",
        icon: setting1,
        iconclicked: settings2
      },
    ]
  
  return (
    <>
      <div className="sidemenudetails">
       <div style={{display: "flex", flexDirection: "column", gap:'7px' }}  >
        {
          QuoteData.map((x, i)=>{
            return ( 
              <div style={{marginTop:'0px', cursor: 'pointer',  userSelect: "none"}} key={i}>
              <div 
                onClick={()=> history.push(`${x.path}`)}
                className="image-content" 
                >
                    {
                      location.pathname === x.path ? <img src={x.iconclicked} alt="iconimage" width='25px' className="side-img" /> :  <img src={x.icon} width='25px' alt="iconimage" className="side-img" />
                    }
                  <p className={location.pathname === x.path  ? 'active4 ': 'inactive3' }>{x.name}</p>
                </div>
              </div>
            )
          })
        }
       </div>
          <div className="bottom-logo" onClick={()=>history.push(GlobalizationLanguagewithCOntryCode("/", country, visitingCountryCode ))}>
            <img src={dillivrylogo} alt="dillivrylogo" className="bottom-logo-img" />
          </div>
      </div>
    </>
  );
}
export default withRouter(Sidebar);
