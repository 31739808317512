import React from 'react'
import './RegCard.css'

function RegCard({bgColor, Title, Subtitle, image, alt, onClick}) {
    return (
      <div onClick={onClick} className='regCard' style={{backgroundImage: bgColor}}>
          <div className="left">
              <div className="leftTop">
                  {Title}
              </div>
              <div className="leftBottom">
                {Subtitle}
              </div>
          </div>
          <div className="right">
             <img src={image} alt={alt} style={{maxWidth:'85px'}} /> 
          </div>

      </div>
    )
}

export default RegCard
