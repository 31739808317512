import React, {useState, useContext}from 'react'
import OtpInput from "react-otp-input";
import RegBtn from './RegBtn';
import {HiArrowRight} from 'react-icons/hi'
import {ImArrowRight2} from 'react-icons/im';

import axios from 'axios';
import Swal from 'sweetalert2';
import OTPCountDown from './OTPCountDown';
import SecureLS from 'secure-ls';
import { ConnContext } from '../../context/ConnContext';

function OTPAfter({num}) {
    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
    const {visitingCountryCode} = useContext(ConnContext)
    const [otp, setotp] = React.useState('')
    const [isloading, setisloading] = useState(false)

    const Confirm = ()=>{
        setisloading(true)
        axios.post(`${process.env.REACT_APP_API}fulfillers-reg`,{
            action_type: "validate_phone",
            account_name: ls.get('_tyf_'),
            security_code: otp,
            country_code: visitingCountryCode, 
        })
        .then(()=>{
            setisloading(false)
            window.location.reload();
        })
        .catch((error)=>{
            Swal.fire({
                icon: 'error', 
                text: 'Invalid Security Code',
                allowOutsideClick: false,
                confirmButtonColor: 'var(--main)'
            })
            setisloading(false)
        })
    }

    return (
        <>
            <OtpInput
                value={otp}
                onChange={otp => setotp(otp)}
                numInputs={num}
                shouldAutoFocus={true}
                isInputNum={true}
                separator={
                    <span>
                    <strong></strong>
                    </span>
                }
                inputStyle={{width: "2.5rem",height: "2.5rem",margin: "0 0.3rem",fontSize: "20px",borderRadius: 3,border: "2px solid var(--main)",outline: "none"}}
            />
            <div style={{width:'100%', textAlign:'center', marginTop:'20px'}}><OTPCountDown initialSeconds={60}/></div>
            <div className="btnDeskto">
                <RegBtn 
                    loading={isloading}
                    txt="Continue" 
                    icon={<ImArrowRight2 size='30px'/>}
                    type='button'
                    onClick={Confirm}
                /> 
            </div>
            <div className="btnMobilAfter">
                <RegBtn
                    loading={isloading}
                    txt="Continue" 
                    icon={<ImArrowRight2 size='30px'/>}
                    type='button'
                    onClick={Confirm}
                /> 
            </div>
        </>
        )
    }

export default OTPAfter
