/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useContext, useState } from "react";
import {Radio, RadioGroup} from '@mui/material';
import { FaCloudUploadAlt, FaEdit, FaTextHeight} from "react-icons/fa";
import {FormControlLabel, Grid, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputField from "../../../../../components/InputField";
import DialogBox from "../../../../../components/topNav/DialogBox";
import ButtonCard from "../../../../../components/ButtonCard";
import DateRanger from "../../../../../components/DateRanger";
import Calender from "../../../../../components/Calender";
import "../../../Registration/SenderRegistartion.css";
import imageCompression from "browser-image-compression";
import { HiOutlineMinusCircle } from "react-icons/hi";
import PhoneNumber from "../../../../../components/PhoneNumber";
import PrivacyDialog from "../../../../../components/PrivacyDialog";
import { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import SecureLS from "secure-ls";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Dialogsummary from "../../../../../components/Dialogsummary";
import edit from "../../Assets/edit.png";
import GlobalizationLanguage from "../../../../../components/GlobalizationLanguage";
import Cookies from "js-cookie";
import ReactSelect from "react-select";
import { BiCalendarAlt } from "react-icons/bi";
import './Request.css'

export default function RequestGeneral({
  main_category,
  details_from_location,
  itemCategory,
  sender_name,
  sender_email,
  sender_phone_no,
  onClick,
  previous,
  page,

  delivery_location,
  pickup_address,
  delivery_address,
  pickupIsError,
  deliveryIsError,
  setpickupIsError,
  setdeliveryIsError
}) {
var ThankYou = GlobalizationLanguage("/viewQuote/thank-you")
var timeout = GlobalizationLanguage("/timeout")

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'black' : 'black',
    backgroundColor: state.isSelected ? 'white' : 'white',
  }),

}


const options = [
    { value: 'pay_by_transfer', label: 'Transfer' },
  { value: 'pay_by_wallet', label: 'Wallet' },
  { value: 'pay_by_card', label: 'Card' },
  { value: 'pay_by_ussd', label: 'USSD' },
  { value: 'pay_by_cash', label: 'Cash' },

]
  
  const [mode_of_delivery_list, setmode_of_delivery_list] = useState([]);

  useEffect(() => {
  
    if (location?.state?.state === "instant") {
      let now = new Date();
      setValue("pickup_date", moment(now).format("YYYY-MM-DD"), {
        shouldValidate: true,
        shouldDirty: true,
      });

      setDelivery_time_frame(moment(now).format("YYYY-MM-DD"));
      setDelivery_time_frameduplicate(moment(now).format("YYYY-MM-DD"));
    }
  }, []);

  useEffect(() => {
    if(delivery_location === 'ng'){
      if (
        itemCategory === "Cake & Confectionery" ||
        itemCategory === "Document & Books" ||
        itemCategory === "Medicine" ||
        itemCategory === "Herbal products" ||
        itemCategory === "Beauty & Skincare" ||
        itemCategory === "Beverages" ||
        itemCategory === "Fruits" ||
        itemCategory === "Provisions" ||
        itemCategory === "Wine & Drinks" ||
        itemCategory === "Shoe" ||
        itemCategory === "Clothing" ||
        itemCategory === "Computer & Accessories" ||
        itemCategory === "Home appliances" ||
        itemCategory === "Phones & Accessories" ||
        itemCategory === "Frozen food" ||
        itemCategory === "Printing press"
      ) {
        setmode_of_delivery_list(["Bike", "Car",  "Van"]);
      } else if (
        itemCategory === "Furniture" ||
        itemCategory === "Livestock" 
      ) {
        setmode_of_delivery_list(["Truck", "Van"]);
      } else if (itemCategory === "Food stuffs") {
        setmode_of_delivery_list(["Bike", "Car"]);
      } else if (
        itemCategory === "Vehicle parts" ||
        itemCategory === "Others" ||
        itemCategory === "General items"
      ) {
        setmode_of_delivery_list(["Bike", "Car", "Truck", "Van"]);
      } else if (itemCategory === "Perishables") {
        setmode_of_delivery_list(["Car", "Truck", "Van"]);
      } else if (
        itemCategory === "Jewellery & Accessories" ||
        itemCategory === "Fast food" ||
        itemCategory === "Snacks"

      ) {
        setmode_of_delivery_list(["Bike", "Car", "Truck", "Van"]);
      } else setmode_of_delivery_list([]);
    }
    else{
      if (
        itemCategory === "Beverages" ||
        itemCategory === "Provisions" ||
        itemCategory === "Wine & Drinks" ||
        itemCategory === "General items" ||
        itemCategory === "Boxes" ||
        itemCategory === "Medicine" ||
        itemCategory === "Dry food" ||
        itemCategory === "Fast food" ||
        itemCategory === "Food stuffs" ||
        itemCategory === "Frozen food" 
      ) {
        setmode_of_delivery_list([ "Car",  "Van", "Truck"]);
      } 
      else if (
        itemCategory === "Furniture" ||
        itemCategory === "Livestock" ||
        itemCategory === "Pallets" ||
        itemCategory === "Removal"
      ) {
        setmode_of_delivery_list(["Truck", "Van"]);
      } 
      else if (
        itemCategory === "Computer & Accessories" ||
        itemCategory === "Home appliances" ||
        itemCategory === "Phones & Accessories"
      ) {
        setmode_of_delivery_list(["Car", "Truck", "Van"]);
      } 
      else if (itemCategory === "Pets") {
        setmode_of_delivery_list(["Car", "Van"]);
      } 
      else if (
        itemCategory === "Vehicle parts"
      ) {
        setmode_of_delivery_list(["Car",  "Van"]);
      } 
      else setmode_of_delivery_list([]);
    }
  }, [itemCategory, delivery_location]);

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const endPoint = process.env.REACT_APP_API;
  const history = useHistory();
  const [policyShow, setPolicyShow] = useState(false);
  const [pageToShow, setpageToShow] = useState("");
  const [dimensionUnit, setdimensionUnit] = useState("");
  const [weightUnit, setweightUnit] = useState("");
  const [modeofpayment, setmodeofpayment] = useState("")
  // const [pickup_address, setPickup_address] = useState("");
  // const [delivery_address, setDelivery_address] = useState("");
  // const [pickupIsError, setpickupIsError] = useState(false);
  // const [deliveryIsError, setdeliveryIsError] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [delivery_time_frame, setDelivery_time_frame] = useState("");
  const [delivery_time_frameduplicate, setDelivery_time_frameduplicate] =
    useState("");
  const [openDateRange, setOpenDateRange] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [showdrop, setShowdrop] = useState(false);
  const [modeIsValid, setmodeIsValid] = useState(false);

  const [mode_of_delivery, setmode_of_delivery] = useState("");
  const [showsummary, setshowsummary] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deliveryTFIsValid, setdeliveryTFIsValid] = useState(false);

  const [showImageSpace, setShowImageSpace] = useState(false);
  const [image_files, setImage_files] = useState([]);
  const [sendImage, setSendImage] = useState([]);

  const [isReciever, setIsReciever] = useState("false");
  const [isPickup, setIsPickup] = useState("false");
  const location = useLocation();

  const [pickup_phone_no, setPickup_phone_no] = useState("");
  const [delivery_phone_no, setDelivery_phone_no] = useState("");

  const [payer, setPayer] = useState("sender");
  const [previewdata, setpreviewdata] = useState([]);

  const GreenRadio = ((props) => <Radio 
    color="success" {...props} 
    sx={{
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        marginRight:"3px"
      },
    }}
    />);

  const schema = yup.object().shape({
    mode_of_payment: yup
    .object()
    .shape({
       label: yup.string().required("payment option is required (from label)"),
      value: yup.string().required("payment option is required")
    }),
    item_desc: yup.string().required("Brief description is required"),
    pickup_email: yup
      .string()
      .email("Valid email is required"),
      // .required("Pickup email is required"),
    delivery_email: yup
      .string()
      .email("Valid email is required"),
      // .required("Receiver email is required"),
    pickup_name: yup
      .string()
      .required("Pickup name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    delivery_name: yup
      .string()
      .required("Receiver's name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    item_weight: yup.string().matches(/^[0-9]*$/, "Weight has to be number"),
    item_dim_len: yup.string().matches(/^[0-9]*$/, "Length has to be number"),
    item_dim_wid: yup.string().matches(/^[0-9]*$/, "Width has to be number"),
    item_dim_hei: yup.string().matches(/^[0-9]*$/, "Height has to be number"),
    pickup_date: yup.string().required("Pickup date is required"),
  });

  const {
    control,
    getValues,
    setValue,
    trigger,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });





  const requestDelivery = async (data, e) => {
    e.preventDefault();
    setshowsummary(!showsummary);
    if (
      sender_phone_no === undefined ||
      delivery_phone_no === undefined ||
      pickup_phone_no === undefined
    ) {
      Swal.fire({
        icon: "info",
        text: "Phones number is required",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    } else if (
      !isValidPhoneNumber(sender_phone_no) ||
      !isValidPhoneNumber(delivery_phone_no) ||
      !isValidPhoneNumber(pickup_phone_no)
    ) {
      Swal.fire({
        icon: "info",
        text: "Invalid phone number",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    } else {
      if (data.image_files === undefined) {
        data.image_files = "";
      }
      if (data.item_dim_hei === undefined) {
        data.item_dim_hei = "";
      }
      if (data.item_dim_len === undefined) {
        data.item_dim_len = "";
      }
      if (data.item_dim_wid === undefined) {
        data.item_dim_wid = "";
      }
      if (data.item_weight === undefined) {
        data.item_weight = "";
      }
      data.pickup_address = pickup_address;
      data.delivery_address = delivery_address;

      data.delivery_time_frame = delivery_time_frameduplicate
      data.image_files = sendImage;
      data.pickup_phone_no =
        isPickup === "true"
          ? sender_phone_no
          : parsePhoneNumber(pickup_phone_no).number;
      data.sender_phone_no = sender_phone_no;
      data.delivery_phone_no =
        isReciever === "true"
          ? sender_phone_no
          : parsePhoneNumber(delivery_phone_no).number;
      data.item_dim_hei = data.item_dim_hei + " " + dimensionUnit;
      data.item_dim_len = data.item_dim_len + " " + dimensionUnit;
      data.item_dim_wid = data.item_dim_wid + " " + dimensionUnit;
      data.item_weight = data.item_weight + " " + weightUnit;
      data.mode_of_delivery = mode_of_delivery.toLowerCase();
      data.mode_of_payment = data?.mode_of_payment?.value;
      data.item_category = itemCategory.toLowerCase().split(" ").join("_");
      data.category = main_category.toLowerCase().split(" ").join("_");
      data.sender_email = sender_email.toLowerCase();
      data.sender_name = sender_name;
      data.action_type = "listing";
      data.delivery_type = "bid";
      data.payer = payer;
      // data.vcc = visitingCountryCode;

      setpreviewdata([data]);

      const authAxios = axios.create({
        baseURL: `${endPoint}`,
        headers: {
          Authorization: ls.get("_utk") !== null ? ls.get("_utk") : "",
        },
      });

      setisloading(true);
      setisloading(false);
    }
  };
  

  const submitafterPreview = async (data, e) => {
    // console.log(data, "data after preview")
    // console.log(data.mode_of_payment, "mode_of_payment")
   e.preventDefault();
   setisloading(true);
    if (
      sender_phone_no === undefined ||
      delivery_phone_no === undefined ||
      pickup_phone_no === undefined
    ) {
      Swal.fire({
        icon: "info",
        text: "Phones number is required",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    } else if (
      !isValidPhoneNumber(sender_phone_no) ||
      !isValidPhoneNumber(delivery_phone_no) ||
      !isValidPhoneNumber(pickup_phone_no)
    ) {
      Swal.fire({
        icon: "info",
        text: "Invalid phone number",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    } else {
      if (data.image_files === undefined) {
        data.image_files = "";
      }
      if (data.item_dim_hei === undefined) {
        data.item_dim_hei = "";
      }
      if (data.item_dim_len === undefined) {
        data.item_dim_len = "";
      }
      if (data.item_dim_wid === undefined) {
        data.item_dim_wid = "";
      }
      if (data.item_weight === undefined) {
        data.item_weight = "";
      }
      data.pickup_address = pickup_address;
      data.delivery_address = delivery_address;

      data.delivery_time_frame = delivery_time_frameduplicate
      // data.delivery_time_frame = delivery_time_frameduplicate.replace(/\b(\d)\b/g, "0$1")

      data.image_files = sendImage;
      data.pickup_phone_no =
        isPickup === "true"
          ? sender_phone_no
          : parsePhoneNumber(pickup_phone_no).number;
      data.sender_phone_no = sender_phone_no;
      data.delivery_phone_no =
        isReciever === "true"
          ? sender_phone_no
          : parsePhoneNumber(delivery_phone_no).number;
      data.item_dim_hei = data.item_dim_hei + " " + dimensionUnit;
      data.item_dim_len = data.item_dim_len + " " + dimensionUnit;
      data.item_dim_wid = data.item_dim_wid + " " + dimensionUnit;
      data.item_weight = data.item_weight + " " + weightUnit;
      data.mode_of_delivery = mode_of_delivery.toLowerCase();
      data.mode_of_payment = data?.mode_of_payment?.value;
      data.item_category = itemCategory.toLowerCase().split(" ").join("_");
      data.category = main_category.toLowerCase().split(" ").join("_");
      data.sender_email = sender_email;
      data.sender_name = sender_name;
      data.action_type = "listing";
      data.delivery_type = "bid";
      data.payer = payer;
   

      setpreviewdata([data]);
      setisloading(false)

      const authAxios = axios.create({
        baseURL: `${endPoint}`,
        headers: {
          Authorization: ls.get("_utk") !== null ? ls.get("_utk") : "",
        },
      });

      setisloading(true);

      // console.log(delivery_location,"delivery_location")

      // const Api = `m-dillivry-listing?delivery_location=${delivery_location}`
      const Api = delivery_location !== undefined ? `m-dillivry-listing?delivery_location=${delivery_location}` : `m-dillivry-listing?delivery_location=ng`

      await authAxios
        .post(Api, data, { timeout: 60000 })
        .then((res) => {
          if (res.status === 200) {
            ls.set("eid", data.sender_email);
            ls.remove("auth_status");
            setshowsummary(!showsummary);
            setisloading(false);
            Swal.fire({
              icon: "success",
              title: "Done",
              html:"<h6>Your request has now been listed successfully. Sit back and relax as the quotes start to roll in.</h6>",
              allowOutsideClick: false,
              confirmButtonColor: "var(--main)",
              showConfirmButton: true
            }).then((result) => {
              if (result.value) {
                history.push({
                  pathname: ThankYou,
                });
              }
            });
            setisloading(false);
          }
        })
        .catch((error) => {
          console.log(error, "error");

          if(error.response?.data?.msg === "not yet in the specifcied country"){
            Swal.fire({
              icon: "warning",
              text: `Not yet in the specified country, check your pickup addresses and try again`,
              confirmButtonColor: "var(--main)",
            })
            .then(()=>{
              setisloading(false)
            });
          }
          else if (error.code === "ECONNABORTED") {
            console.log("internal-server-error-timeout");
            history.push(timeout);
            // window.location.reload()
          } else {
            Swal.fire({
              icon: "warning",
              text: `${error?.response?.data?.msg_subject}`,
              confirmButtonColor: "var(--main)",
            });
            setisloading(false);
          }
        })
        .finally(()=>{
          setisloading(false)
        })

    }
  };

  const closemodalandbacktopageone = () => {
    setshowsummary(!showsummary);
  };

  const showPreviewPage = (data) => {
    setshowsummary(!showsummary);
  };

  const handleChange = (e) => {
    setmode_of_delivery(e.target.value);
    setmodeIsValid(false);
  };

  async function imageHandler(e) {
    const file = e.target.files[0];
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 512,
    };
    const output = await imageCompression(file, options);

    const dataa = await imageCompression.getDataUrlFromFile(output);

    setImage_files([...image_files, dataa]);

    const img = dataa.split(",");

    setSendImage([...sendImage, img[1]]);

    setShowImageSpace(true);
  }

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const setDialog = async () => {
    const result = await trigger(["pickup_date"]);
    if (result) {
      setOpenDialog(!openDialog);
      setdeliveryTFIsValid(false);
    }
  };

  const setRange = async () => {
    setOpenDateRange(!openDateRange);
  };

  const handleOpenDate = () => {
    setOpenDate(!openDate);
  };

  const [range, setRanges] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
      color: "#f99804",
    },
  ]);



  return (
    <>
      <form style={{ width: "100%" }}>
        {page === 1 ? (
          <Grid container spacing={3}>
            {/* <Grid item xs={12}>
              <GooglePlaceAutocomplete
                handleChange={(pickup_address) => {
                  setPickup_address(pickup_address);
                  setpickupIsError(true);
                }}
                handleSelect={(pickup_address) => {
                  setPickup_address(pickup_address);
                  setpickupIsError(false);
                }}
                setdeli_locatn_frm_pickup_add = {setdeli_locatn_frm_pickup_add}
                value={pickup_address}
                text="Enter pick up address"
                color="var(--main)"
                error={pickupIsError}
                pickup
              />
            </Grid>

            <Grid item xs={12}>
              <GooglePlaceAutocomplete
                handleChange={(delivery_address) => {
                  setDelivery_address(delivery_address);
                  setdeliveryIsError(true);
                }}
                handleSelect={(delivery_address) => {
                  setDelivery_address(delivery_address);
                  setdeliveryIsError(false);
                }}
                value={delivery_address}
                text="Enter delivery address"
                color="red"
                error={deliveryIsError}
              />
            </Grid> */}

            <Grid item xs={12} sm={12}>
              <Controller
                name="item_desc"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputField
                    label="Brief description of item"
                      {...field}
                      error={errors.item_desc}
                      id="item_desc"
                      type="text"
                      labelWidth={170}
                      icon={
                        <FaTextHeight
                          style={{ marginRight: "5px", color: "var(--main)" }}
                        />
                      }
                    />
                    <div className="err">{errors.item_desc?.message}</div>
                  </FormControl>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
                <InputLabel id="select">Mode of delivery</InputLabel>
                <Select
                  labelId="select"
                  error={modeIsValid}
                  value={mode_of_delivery}
                  onChange={handleChange}
                  label="Mode of delivery"
                >
                  {mode_of_delivery_list?.map((mode, i) => (
                    <MenuItem key={i} value={mode}>
                      {mode?.charAt(0)?.toUpperCase() + mode?.slice(1)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {location?.state?.state === "schedule" ||
            details_from_location?.Schedule === "schedule" ? (
              <>
                <Grid item xs={12}>
                  <Controller
                    name="pickup_date"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl style={{ width: "100%" }} variant="outlined">
                        <label>Pickup Date</label>
                        <InputField
                        // label="dd/mm/yyyy"
                        label="."
                          {...field}
                          error={errors.pickup_date}
                          id="pickup_date"
                          name="pickup_date"
                          type="date"
                          inputProps={{ min: disablePastDate() }}
                          InputLabelProps={{shrink: false}}
                          icon={
                            <FaTextHeight
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                          }
                        />
                        <div className="err">
                          {" "}
                          {errors.pickup_date?.message}{" "}
                        </div>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="delivery_time_frame"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <FormControl
                        style={{ width: "100%", position: "relative" }}
                        variant="outlined"
                      >
                        <label>Delivery time frame</label>
                        <InputField
                        // label="Delivery time frame"
                        label="."
                        InputLabelProps={{shrink: false}}
                          error={deliveryTFIsValid}
                          {...field}
                          name="delivery_time_frame"
                          icon={
                            <FaTextHeight
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                          }
                          type="text"
                          value={delivery_time_frame}
                          onChange={setDialog}
                          endIcon={<BiCalendarAlt style={{fontSize: '20px'}}/>}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                          }}
                          onClick={setDialog}
                        />
                        <div className="err">
                          {errors.delivery_time_frame?.message}
                        </div>
                      </FormControl>
                    )}
                  />
                </Grid>
              </>
            ) : null}

            <Grid item xs={12}>
              <button
                type="button"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  fontWeight: "600",
                  fontSize: "16px",
                  textAlign: "center",
                  userSelect: "none",
                  cursor: "pointer",
                  padding: "13px",
                  backgroundColor: "var(--main)",
                  color: "white",
                  borderRadius: "3px",
                  border: 0,
                }}
                onClick={async () => {
                  const result = await trigger(["item_desc", "pickup_date"]);
                  // console.log("result",result)
                  if (
                    result === true &&
                    pickup_address !== "" &&
                    mode_of_delivery !== "" &&
                    delivery_address !== "" &&
                    delivery_time_frame !== "" && 
                    !pickupIsError &&
                     !deliveryIsError
                  ) {
                    onClick();
                  } else {
                    if (pickup_address === "") {
                      setpickupIsError(true);
                    }
                    if (mode_of_delivery === "") {
                      setmodeIsValid(true);
                    }
                    if (delivery_address === "") {
                      setdeliveryIsError(true);
                    }
                    if (delivery_time_frame === "") {
                      setdeliveryTFIsValid(true);
                    }
                  }
                }}
              >
                Continue
              </button>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              //  onClick={() => setShowdrop(!showdrop)}
              style={{ cursor: "pointer", fontSize: "16px", display: "flex", gap: "4px" }}
            >
              <input
                type="checkbox"
                id="optional"
                name="vehicle1"
                 checked={showdrop}
              ></input>
              &nbsp;
              <label
              style={{
                paddingLeft: '30px',
                marginLeft: '-30px'
              }}
              htmlFor="optional" onClick={() => setShowdrop(!showdrop)}>
                {" "}
                <b style={{ userSelect: "none", cursor: "pointer" }}>
                  {" "}
                  Dimension, weight & photos{" "}
                  <span style={{ fontWeight: "500", fontStyle: "italic" }}>
                    (optional)
                  </span>
                </b>
              </label>
            </Grid>
            {showdrop ? (
              <>
                <Grid item xs={12}>
                  <FormControl size="small" variant="outlined" style={{ width: "100%" }}>
                    <InputLabel id="select">
                      <span
                        style={{ color: "var(--main)", marginRight: "10px" }}
                      >
                        <FaEdit />
                      </span>
                      Select Dimension Unit
                    </InputLabel>
                    <Select
                      labelId="select"
                      value={dimensionUnit}
                      onChange={(e) => setdimensionUnit(e.target.value)}
                      label="Select Dimension Unitsss"
                    >
                      <MenuItem value="centimeters"> Centimeter </MenuItem>
                      <MenuItem value="meters">Meters</MenuItem>
                      <MenuItem value="inches">Inches</MenuItem>
                      <MenuItem value="feet">Feet</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ marginBottom: "12px" }}>Dimension</div>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Controller
                        name="item_dim_len"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            {/* <InputLabel htmlFor="item_dim_len">
                              {" "}
                              <FaEdit
                                style={{
                                  marginRight: "5px",
                                  color: "var(--main)",
                                }}
                              />{" "}
                              Length{" "}
                            </InputLabel> */}
                            <InputField
                            label="Length"
                              {...field}
                              error={errors.item_dim_len}
                              id="item_dim_len"
                              type="number"
                              labelWidth={60}
                              icon={
                                <FaTextHeight
                                  style={{
                                    marginRight: "5px",
                                    color: "var(--main)",
                                  }}
                                />
                              }
                            />
                            <div className="err">
                              {errors.item_dim_len?.message}
                            </div>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="item_dim_hei"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            {/* <InputLabel htmlFor="item_dim_hei">
                              {" "}
                              <FaEdit
                                style={{
                                  marginRight: "5px",
                                  color: "var(--main)",
                                }}
                              />{" "}
                              Height{" "}
                            </InputLabel> */}
                            <InputField
                            label="Height"
                              {...field}
                              error={errors.item_dim_hei}
                              id="item_dim_hei"
                              type="number"
                              labelWidth={60}
                              icon={
                                <FaTextHeight
                                  style={{
                                    marginRight: "5px",
                                    color: "var(--main)",
                                  }}
                                />
                              }
                            />
                            <div className="err">
                              {errors.item_dim_hei?.message}
                            </div>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        name="item_dim_wid"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            {/* <InputLabel htmlFor="item_dim_wid">
                              {" "}
                              <FaEdit
                                style={{
                                  marginRight: "5px",
                                  color: "var(--main)",
                                }}
                              />{" "}
                              Width{" "}
                            </InputLabel> */}
                            <InputField
                            label="Width"
                              {...field}
                              error={errors.item_dim_wid}
                              id="item_dim_wid"
                              type="number"
                              labelWidth={60}
                              icon={
                                <FaTextHeight
                                  style={{
                                    marginRight: "5px",
                                    color: "var(--main)",
                                  }}
                                />
                              }
                            />
                            <div className="err">
                              {errors.item_dim_wid?.message}
                            </div>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl size="small" variant="outlined" style={{ width: "100%" }}>
                        <InputLabel id="select">
                          <span
                            style={{
                              color: "var(--main)",
                              marginRight: "10px",
                            }}
                          >
                            <FaEdit />
                          </span>
                          Weight Unit
                        </InputLabel>
                        <Select
                          labelId="select"
                          value={weightUnit}
                          onChange={(e) => setweightUnit(e.target.value)}
                          label="Weight Unitsss"
                        >
                          <MenuItem value="gram"> Gramme </MenuItem>
                          <MenuItem value="kilogram">Kilogramme</MenuItem>
                          <MenuItem value="tonnes">Tonnes</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="item_weight"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            {/* <InputLabel htmlFor="item_weight">
                              {" "}
                              <FaEdit
                                style={{
                                  marginRight: "5px",
                                  color: "var(--main)",
                                }}
                              />{" "}
                              Weight{" "}
                            </InputLabel> */}
                            <InputField
                            label="Weight"
                              {...field}
                              error={errors.item_weight}
                              id="item_weight"
                              type="number"
                              labelWidth={60}
                              icon={
                                <FaTextHeight
                                  style={{
                                    marginRight: "5px",
                                    color: "var(--main)",
                                  }}
                                />
                              }
                            />
                            <div className="err">
                              {errors.item_weight?.message}
                            </div>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ marginBottom: "12px" }}>Upload photo</div>
                  <div className="photo">
                    <input
                      style={{ display: "none" }}
                      id="image_files"
                      name="image_files"
                      type="file"
                      accept="image/x-png,image/gif,image/jpeg"
                      {...register("image_files")}
                      onChange={imageHandler}
                    />
                    <div style={{ color: "#989898" }}>
                      Browse to choose photos
                    </div>
                    {image_files.length !== 3 ? (
                      <label htmlFor="image_files" className="upload">
                        Upload Picture &nbsp;{" "}
                        <FaCloudUploadAlt style={{ fontSize: "15px" }} />
                      </label>
                    ) : (
                      <label htmlFor="image_file" className="uploaded">
                        Upload Picture &nbsp;{" "}
                        <FaCloudUploadAlt style={{ fontSize: "15px" }} />
                      </label>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "16px" }}>
                  <Grid container spacing={2}>
                    {showImageSpace ? (
                      <>
                        {image_files.map((images, index) => {
                          return (
                            <Grid item xs={4}>
                              <div key={index} className="forPhoto">
                                <div
                                  className="border"
                                  style={{ backgroundImage: `url(${images})` }}
                                >
                                  <span className="icon">
                                    <HiOutlineMinusCircle
                                      onClick={() => {
                                        const image = Object.assign(
                                          [],
                                          image_files
                                        );
                                        image.splice(index, 1);
                                        setImage_files(image);

                                        const tosend = Object.assign(
                                          [],
                                          sendImage
                                        );
                                        tosend.splice(index, 1);
                                        setSendImage(tosend);
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </Grid>
                          );
                        })}
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={7} sm={7}>
                  <div style={{ fontSize: "14px", fontWeight: "500" }}>
                    Are you the pickup contact?
                  </div>
                  <div style={{ fontSize: "11px" }}>
                  Let us know if we’re picking the package from your end, or elsewhere.
                  </div>
                </Grid>
                <Grid item xs={5} sm={5}>
                  <div className="move_left"> 
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        defaultValue={isPickup}
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="true"
                          sx={{ fontSize: "10px" }}
                          onClick={() => {
                            setIsPickup("true");
                            setValue("pickup_email", sender_email, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                            setValue("pickup_name", sender_name, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                            setPickup_phone_no(sender_phone_no);
                          }}
                          control={ <GreenRadio/>}
                          label="Yes"
                        />

                        <FormControlLabel
                          value="false"
                          onClick={() => {
                            setIsPickup("false");
                            setValue("pickup_email", "", {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                            setValue("pickup_name", "", {
                              shouldValidate: true,
                              shouldDirty: true,
                            });
                            setPickup_phone_no("");
                          }}
                          control={<GreenRadio/>}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  
                </Grid>



                {isPickup === "true" ? null : (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div className="txt" style={{marginTop:'15px', marginBottom:'5px'}}>Pickup Contact</div>
                        <Controller
                          name="pickup_name"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              style={{ width: "100%" }}
                              variant="outlined"
                            >
                            
                              <InputField
                              label="Pickup's Name"
                                {...field}
                                // error={isPickNameValid}
                                error={errors.pickup_name}
                                name="pickup_name"
                                id="pickup_name"
                                type="text"
                                labelWidth={150}
                                icon={
                                  <FaTextHeight
                                    style={{
                                      marginRight: "5px",
                                      color: "var(--main)",
                                    }}
                                  />
                                }
                              />
                              <div className="err">
                                {errors.pickup_name?.message}
                              </div>
                            </FormControl>
                          )}
                        />
                      </Grid>




                      <Grid item xs={12}>
                        <Controller
                          name="pickup_email"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              style={{ width: "100%" }}
                              variant="outlined"
                            >
                              {/* <InputLabel htmlFor="pickup_email">
                                <GoMailRead
                                  style={{
                                    marginRight: "5px",
                                    color: "var(--main)",
                                  }}
                                />
                                Pickup's Email(optional)
                              </InputLabel> */}
                              <InputField
                                {...field}
                                label="Pickup's Email(optional)"
                                // error={isPickEmailValid}
                                error={errors.pickup_email}
                                name="pickup_email"
                                id="pickup_email"
                                type="email"
                                labelWidth={150}
                                icon={
                                  <FaTextHeight
                                    style={{
                                      marginRight: "5px",
                                      color: "var(--main)",
                                    }}
                                  />
                                }
                              />
                              <div className="err">
                                {errors.pickup_email?.message}
                              </div>
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <div className="phoneTopic">Pickup Phone</div>
                        <PhoneNumber
                          value={pickup_phone_no}
                          setValue={setPickup_phone_no}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={7} sm={7}>
                  <div style={{ fontSize: "14px", fontWeight: "500" }}>
                    Are you the receiver?
                  </div>
                  <div style={{ fontSize: "11px" }}>
                  Are you the one recieving the package you want to send?
                  </div>
                </Grid>
                <Grid item xs={5} sm={5}>
                  <div className="move_left"> 
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        defaultValue={isReciever}
                        aria-label="gender"
                        name="row-radio-buttons-group"
                      >

                        <FormControlLabel
                          value="true"
                          onClick={() => {
                            setIsReciever("true");
                            const data = getValues([
                              "sender_email",
                              "sender_name",
                            ]);

                            setValue("delivery_email", sender_email, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });

                            setValue("delivery_name", sender_name, {
                              shouldValidate: true,
                              shouldDirty: true,
                            });

                            setDelivery_phone_no(sender_phone_no);
                          }}
                          control={<GreenRadio />}
                          label="Yes"
                        />

                        <FormControlLabel
                          value="false"
                          onClick={() => {
                            setIsReciever("false");
                            const data = getValues([
                              "sender_email",
                              "sender_name",
                            ]);
                            setValue("delivery_email", "", {
                              shouldValidate: true,
                              shouldDirty: true,
                            });

                            setValue("delivery_name", "", {
                              shouldValidate: true,
                              shouldDirty: true,
                            });

                            setDelivery_phone_no("");
                          }}
                          control={<GreenRadio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </Grid>
                {isReciever === "true" ? null : (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div className="txt" style={{marginTop:'15px', marginBottom:'5px'}}>Receiver's Contact</div>

                        <Controller
                          name="delivery_name"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              style={{ width: "100%" }}
                              variant="outlined"
                            >
                              {/* <InputLabel htmlFor="delivery_name">
                                <FaEdit
                                  style={{
                                    marginRight: "5px",
                                    color: "var(--main)",
                                  }}
                                />
                                Receiver's Name
                              </InputLabel> */}
                              <InputField
                              label="Receiver's Name"
                                {...field}
                                name="delivery_name"
                                id="delivery_name"
                                error={errors.delivery_name}
                                type="text"
                                labelWidth={150}
                                icon={
                                  <FaTextHeight
                                    style={{
                                      marginRight: "5px",
                                      color: "var(--main)",
                                    }}
                                  />
                                }
                              />
                               <div className="err">
                                  {errors.delivery_name?.message}
                                </div>
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Controller
                          name="delivery_email"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              style={{ width: "100%" }}
                              variant="outlined"
                            >
                              {/* <InputLabel htmlFor="delivery_email">
                                <GoMailRead
                                  style={{
                                    marginRight: "5px",
                                    color: "var(--main)",
                                  }}
                                />
                                Receiver's Email(optional)
                              </InputLabel> */}
                              <InputField
                              label="Receiver's Email(optional)"
                                {...field}
                                name="delivery_email"
                                id="delivery_email"
                                error={errors.delivery_email}
                                type="email"
                                labelWidth={150}
                                icon={
                                  <FaTextHeight
                                    style={{
                                      marginRight: "5px",
                                      color: "var(--main)",
                                    }}
                                  />
                                }
                              />
                              <div className="err">
                                {errors.delivery_email?.message}
                              </div>
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <div className="phoneTopic">
                          Receiver's Phone Number
                        </div>
                        <PhoneNumber
                          value={delivery_phone_no}
                          setValue={setDelivery_phone_no}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={5} sm={5}>
                  <div style={{ fontSize: "14px", fontWeight: "500" }}>
                    Who is paying?
                  </div>
                  <div style={{ fontSize: "11px" }}>
                    Let us know who is paying for the delivery.
                  </div>
                </Grid>
                <Grid item xs={7} sm={7}>
                  <div  className="move_left">
                  <FormControl component="fieldset">
                    <RadioGroup
                      defaultValue={payer}
                      row
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="sender"
                        onClick={() => {
                          setPayer("sender");
                        }}
                        control={<GreenRadio />}
                        label="Sender"
                      />

                      <FormControlLabel
                        value="receiver"
                        onClick={() => {
                          setPayer("receiver");
                        }}
                        control={<GreenRadio />}
                        label="Reciever"
                      />
                    </RadioGroup>
                  </FormControl>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className="txt" style={{ marginBottom:'5px'}}>Payment option</div>
                  <Controller
                    name="mode_of_payment"
                    control={control}
                    render={({ field }) => (
                      <ReactSelect
                        // defaultValue={options[0]}
                        {...field}
                        // isClearable  enable isClearable to demonstrate extra error handling
                        isSearchable={false}
                        className="react-dropdown"
                        classNamePrefix="dropdown"
                        options={options}
                        //  value={modeofpayment}  
                        //  onChange={(e)=> setmodeofpayment(e)} 
                        placeholder="Select Payment Option" styles={customStyles}
                      />
                    )}
                  />
              <div className="err">{ errors?.mode_of_payment?.value?.message}</div>
              </Grid>
            </Grid>
            </Grid>





























            {/* <Grid item xs={12}>
              <span
                style={{
                  fontSize: "14px",
                  color: "red",
                  fontWeight: "600",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <AiFillCloseCircle /> Cash payment is not allowed
              </span>
            </Grid> */}

            <Grid item xs={12}>
              <div
                style={{
                  textAlign: "justify",
                  fontSize: "13px",
                  paddingTop: "10px",
                  fontSize: "10px",
                  textAlign: "center",
                }}
              >
                By proceeding I agree to Dillivry{" "}
                <a
                  onClick={() => {
                    setPolicyShow(true);
                    setpageToShow("terms");
                  }}
                  style={{ cursor: "pointer", color: "var(--main)" }}
                >
                  Terms of Use
                </a>{" "}
                and acknowledge that I have read the{" "}
                <a
                  onClick={() => {
                    setPolicyShow(true);
                    setpageToShow("privacy");
                  }}
                  style={{ cursor: "pointer", color: "var(--main)" }}
                >
                  Privacy Policy
                </a>
                .
              </div>
              <div
                style={{
                  textAlign: "justify",
                  fontSize: "13px",
                  paddingTop: "10px",
                  fontSize: "10px",
                  textAlign: "center",
                }}
              >
                I also agree that Dillivry or its representative may contact me
                by email, phone or SMS (including by automated means) at the
                email address or number i provide, including for marketing
                purposes.
              </div>
            </Grid>

            <Grid item xs={6}>
              <button
                type="button"
                onClick={previous}
                className="last_btn"
              >
                Previous
              </button>
            </Grid>
            <Grid item xs={6}>
              <button
                type="button"
                disabled={isloading}
                onClick={handleSubmit(requestDelivery)}
                className="last_btn"
              >
                {isloading ? (
                  <span className="btnlabe">
                    <PuffLoader
                      color="white"
                      loading={isloading}
                      speedMultiplier={1}
                      size={24}
                    />
                  </span>
                ) : (
                  <span>Request delivery</span>
                )}
              </button>
            </Grid>
          </Grid>
        )}
      </form>

      <Dialogsummary openPopup={showsummary} setopenPopup={setshowsummary}>
        {previewdata.map((item, index) => {
          return (
            <div key={index}>
              <div className="heading">
                
                <div className="heading__title">
                  <h1 className="heading__title__tag">Request summary</h1>
                </div>

                <div className="heading__sub__title">
                  <p className="heading__subtitle__tag">
                    Review your request details before submission
                  </p>
                </div>
              </div>

              <div className="summary__content">
                <Grid item xs={4}>
                  <div key={index}>

                    {item.image_files.length === 0 ? (
                      <>
                        
                      </>
                    ) : (
                      <div className="forPhoto">
                        <div
                          className="border"
                          style={{
                            backgroundImage: `url(data:image/png;base64,${item.image_files[0]})`,
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                </Grid>

                <div className="summary__content__main">
                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Category</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.category?.charAt(0)?.toUpperCase() +
                          item?.category?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Pickup address</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.pickup_address?.charAt(0)?.toUpperCase() +
                          item?.pickup_address?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Delivery address</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.delivery_address?.charAt(0)?.toUpperCase() +
                          item?.delivery_address
                            ?.replaceAll("_", " ")
                            ?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Item description</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.item_desc?.charAt(0)?.toUpperCase() +
                          item?.item_desc?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Mode of delivery</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.mode_of_delivery?.charAt(0)?.toUpperCase() +
                          item?.mode_of_delivery
                            ?.replaceAll("_", " ")
                            ?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  {
                    item?.item_dim_hei !== " " ?
                    item?.item_dim_hei &&

                    <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main"> Height</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.item_dim_hei === " "
                          ? "NA"
                          : item?.item_dim_hei?.charAt(0)?.toUpperCase() +
                            item?.item_dim_hei?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>
                  : null
                  }

                  {
                    item.item_dim_len !== " " ?

                    item.item_dim_len &&

                    <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Length</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.item_dim_len === " "
                          ? "NA"
                          : item?.item_dim_len?.charAt(0)?.toUpperCase() +
                            item?.item_dim_len?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div> : null

                  }



                  {
                   item?.item_dim_wid === " " ? null :

                    <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main"> Width</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.item_dim_wid === " "
                          ? "NA"
                          : item?.item_dim_wid?.charAt(0)?.toUpperCase() +
                            item?.item_dim_wid?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div> 


                  }



                  {

                  item?.item_weight  !== " " ?
                  

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Weight</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.item_weight === " "
                          ? "NA"
                          : item?.item_weight?.charAt(0)?.toUpperCase() +
                            item?.item_weight?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>  : null
                  
                  }

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Are you the receiver?</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {isReciever === "true" ? "Yes" : "No"}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Receiver’s full name</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">{item?.delivery_name}</h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Receiver’s phone number</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.delivery_phone_no}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">
                        Are you the pickup contact?
                      </h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {isPickup === "true" ? "Yes" : "No"}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Who is paying?</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.payer?.charAt(0)?.toUpperCase() +
                          item?.payer?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="submit__and__edit">
                <div>
                  {" "}
                  <button
                    className="edit-icon-btn"
                    onClick={() => {
                      previous();
                      closemodalandbacktopageone();
                    }}
                  >
                    {" "}
                    <img src={edit} alt="edit-icon" />{" "}
                  </button>
                </div>

                <div>
                  {" "}
                  <button
                    className="submit-btn"
                    onClick={handleSubmit(submitafterPreview)}
                    disabled={isloading}
                  >
                    {" "}
                    {isloading ? "Submiting..." : "Submit"}{" "}
                  </button>
                </div>
              </div>
            </div>
          );
        })}

      </Dialogsummary>

      <DialogBox
        openPopup={openDialog}
        setopenPopup={setDialog}
        head="State delivery time frame"
      >
        <ButtonCard
          onClick={() => {
            setDelivery_time_frame("Within a week");
            setDelivery_time_frameduplicate("Within a week");
            setOpenDialog(false);
          }}
          text="Within a week"
        />
        <ButtonCard
          onClick={() => {
            setDelivery_time_frame("Within 2 week");
            setDelivery_time_frameduplicate("Within 2 week");
            setOpenDialog(false);
          }}
          text="Within 2 weeks"
        />
        <ButtonCard onClick={handleOpenDate} text="On a specific date" />
        <ButtonCard onClick={setRange} text="Within a specific dates " />
        <ButtonCard
          onClick={() => {
            setDelivery_time_frame("Don't have a date yet");
            setDelivery_time_frameduplicate("Don't have a date yet");
            setOpenDialog(false);
          }}
          text="Don't have a date yet"
        />
      </DialogBox>

      <DialogBox openPopup={openDateRange} setopenPopup={setRange}>
        <DateRanger
          onChange={(item) => setRanges([item.selection])}
          ranges={range}
          minDate={getValues("pickup_date")}
        />
        <ButtonCard
          onClick={() => {
            if (
              `${range[0].startDate.getFullYear()}-${range[0].startDate.getMonth()}-${range[0].startDate.getDate()}` ===
              `${range[0].endDate.getFullYear()}-${range[0].endDate.getMonth()}-${range[0].endDate.getDate()}`
            ) {
              setDelivery_time_frame(
                `${range[0].startDate.getDate()}/${(
                  range[0].startDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}/${range[0].startDate.getFullYear()}`
              );
              setDelivery_time_frameduplicate(
                `${range[0].startDate.getFullYear()}-${(
                  range[0].startDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${range[0].startDate.getDate()}`
              );
              setRange();
              setOpenDialog(false);
            } else {
              if (range[0].endDate === null) {
                setDelivery_time_frame("");
                setDelivery_time_frameduplicate("");
                setOpenDialog(false);
              } else {
                setDelivery_time_frame(
                  `${range[0].startDate.getDate()}/${(
                    range[0].startDate.getMonth() + 1
                  )
                    .toString()
                    .padStart(
                      2,
                      "0"
                    )}/${range[0].startDate.getFullYear()} -  ${range[0].endDate.getDate()}/${(
                    range[0].endDate.getMonth() + 1
                  )
                    .toString()
                    .padStart(2, "0")}/${range[0].endDate.getFullYear()}`
                );
                setDelivery_time_frameduplicate(
                  `${range[0].startDate.getFullYear()}-${
                    range[0].startDate.getMonth() + 1
                  }-${range[0].startDate.getDate()} - ${range[0].endDate.getFullYear()}-${
                    range[0].endDate.getMonth() + 1
                  }-${range[0].endDate.getDate()}`
                );
                setRange();
                setOpenDialog(false);
              }
            }
          }}
          text="Ok"
        />
      </DialogBox>
      <DialogBox openPopup={openDate} setopenPopup={handleOpenDate}>
        <Calender
          minDate={getValues("pickup_date")}
          onChange={(item) => {
            setDelivery_time_frame(
              `${item.getDate()}/${(item.getMonth() + 1)
                .toString()
                .padStart(2, "0")}/${item.getFullYear()}`
            );
            setDelivery_time_frameduplicate(
              `${item.getFullYear()}-${item.getMonth() + 1}-${item.getDate()}`
            );
            setOpenDate(false);
            setOpenDialog(false);
          }}
        />
      </DialogBox>

      <PrivacyDialog
        open={policyShow}
        onClick={() => setPolicyShow(false)}
        pageToShow={pageToShow}
        title={
          pageToShow === "terms" ? "Terms and condition" : "Privacy policy"
        }
      ></PrivacyDialog>
    </>
  );
}
