import { useState, useEffect, useContext } from "react";
import Layout from "../../../Layout";
import "./QuotesStatus.css";
import { useHistory } from "react-router-dom";
import { SenderContext } from "../../../../../../context/SenderDashboard/SenderDashboardContext";
import axios from "axios";
import SecureLS from "secure-ls";
import { ConnContext } from "../../../../../../context/ConnContext";
import Swal from "sweetalert2";
import { PuffLoader } from "react-spinners";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import OtpInput from "react-otp-input";
import Otp from "../../../../../../assets/Otp.svg";
import DialogSimplePop from "../../../../../../components/DialogSimplePop";
import SenderUpdateReg from "../../../../Registration/SenderUpdateReg";
import SenderSideBar from "../../../../components/SenderSideBar";
import LoginCompV2 from "../../../../../../components/LoginCompV2";
import DialogOutClick from "../../../../../../components/DialogOutClick";
import SenderBottomNav from "../../../../components/SenderBottomNav";
import Cookies from "js-cookie";
import Wunmi from '../../../../../../assets/wunmi.png'
import Instant from "../../../../../../assets/Instant.svg"

import GlobalizationLanguage, {
  GlobalizationLanguagewithCOntryCode,
} from "../../../../../../components/GlobalizationLanguage";
import OTPCountDown from "../../../../../../components/Reg_components/OTPCountDown";
import DialogPop from "../../../../../../components/DialogPop";

export default function QuotesStatus() {
  const sendersettings = GlobalizationLanguage("/sendersettings");
  const sendersettlement = GlobalizationLanguage("/sendersettlement");
  var RefPage = GlobalizationLanguage("/referrals_sender");

  const senderapi = GlobalizationLanguage("/senderapi");
  const team = GlobalizationLanguage("/team");
 var viewQuote_request_delivery = GlobalizationLanguage("/viewQuote/request-a-delivery")
 
  const [showWelcome, setshowWelcome] = useState(false)

  const location = useLocation();
  const endpoint = process.env.REACT_APP_API;
  var countryvalue = Cookies.get("i18next") || visitingCountryCode.toLowerCase();
  const [country, setCountry] = useState(countryvalue);

  const [recallApi, setrecallApi] = useState(false)

  useEffect(() => {
    setCountry(Cookies.get("i18next"));
  }, [Cookies.get("i18next")]);

  const history = useHistory();
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const [ShowLogin, setShowLogin] = useState(false);

  const {
    initialstate,
    UpdateData,
    UpdateAlldata,
  } = useContext(SenderContext);

  const { visitingCountryCode } = useContext(ConnContext);

  const [deliveries, setDeliveries] = useState([]);
  const [loading, setloading] = useState(false);
  const [businessUpdate, setBusinessUpdate] = useState(false);
  const [otpRequired, setOtpRequired] = useState(false);
  const [otp, setotp] = useState("");
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [loadingOtp, setloadingOtp] = useState(false);
  const [searchclick, setsearchclick] = useState(false);
  const [welcome, setWelcome] = useState(false)
  const [showRequestType, setshowRequestType] = useState(false)
  const [isfetching, setisfetching] = useState(false)

  useEffect(() => {

    // console.log(location, "location")

    if (location?.state === "fromAccept") {
      window.location.reload();
      history.replace({ state: {} });
    }
    // console.log(location.state, "registratiom")
    if (location?.state?.from === "registration") {
      setBusinessUpdate(true);
      history.replace({ state: {} });
    }
    if (location?.state?.from === "otp_required") {
      setOtpRequired(true);
      history.replace({ state: {} });
    }
  }, []);



  useEffect(() => {

    // console.log(location?.state, "location?.state")

    if (location?.state === "from_requestDelivery" ) {
      setisfetching(true)

      axios
          .get(`${endpoint}m-dillivry-sender-api`, {
            headers: {
              Authorization: ls.get("_utk"),
            },
          })
          .then((res) => {
          
            if (res.status === 200) {
              // update all deliveries response data to context
              UpdateData(res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());

              // update UI data with response data to context
              UpdatePage( res.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());
            }
            // update all response data to context
            UpdateAlldata(res);
            if (res.status === 202) {
              setOtpRequired(true);
            }
          })
          .catch((err) => {
            setloading(false);
            console.log(err, "errr");
            if (err.response?.status === 401) {
              setBusinessUpdate(true);
            }
          })
          .finally(()=>{
            setisfetching(false)
          })
          ;
      history.replace({ state: {} });
    }
  }, [])




  useEffect(() => {
    
    if (initialstate !== undefined) {
      UpdatePage(initialstate);
      // console.log(initialstate, "initialstate")
    } else {
      setloading(true);

      axios
        .get(`${endpoint}m-dillivry-sender-api`, {
          headers: {
            Authorization: ls.get("_utk"),
          },
        })
        .then((res) => {
          //  console.log(res, "resss")
          setloading(false);
           
          if (res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat()?.map(x => x?.statusCode)[0] !== undefined) {
            if (res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat().map(x => x?.statusCode[0] === 401)) {
              setBusinessUpdate(true);
            }
          }
          if (res.status === 200) {
            // update all deliveries response data to context
            UpdateData(res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());

            // update UI data with response data to context
            UpdatePage( res.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());
          }
          // update all response data to context
          UpdateAlldata(res);
          if (res.status === 202) {
            setOtpRequired(true);
          } 
        })
        .catch((err) => {
          setloading(false);
          console.log(err, 'errrrr');
          if (err?.response?.status === 401) {
            setBusinessUpdate(true);
          }
        });
    }
  }, []);




  useEffect(() => {
    if (recallApi) {
      setloading(true);

      axios
        .get(`${endpoint}m-dillivry-sender-api`, {
          headers: {
            Authorization: ls.get("_utk"),
          },
        })
        .then((res) => {
          setloading(false);

          if (res.status === 200) {
            // update all deliveries response data to context
            UpdateData(res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());

            // update UI data with response data to context
            UpdatePage( res.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());
          }
          // update all response data to context
          UpdateAlldata(res);
          if (res.status === 202) {
            setOtpRequired(true);
          }
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
          if (err.response?.status === 401) {
            setBusinessUpdate(true);
          }
        });
    }
  }, [recallApi]);

 

  const confirmOTP = () => {
    setloadingOtp(true);
    axios
      .post(`${process.env.REACT_APP_API}sender-reg`, {
        sender_email: ls.get("eid"),
        security_code: otp,
      })
      .then((res) => {
        setloadingOtp(false);
        setOtpRequired(false);
        Swal.fire({
          icon: "success",
          text: "Otp confirmed",
          timer: 3000,
          showConfirmButton: false,
        }).then(() => {
          // history.replace({ state: {} })
          history.replace({
            state: {
              from: "registration",
            },
          });
          window.location.reload();
        });
      })
      .catch((err) => {
        setloadingOtp(false);
        setInvalidOtp(true);
      });
  };

  const links = [
    // {
    //   path: team,
    //   name: "Team",
    //   state: "inactive",
    // },

    // {
    //   path: senderapi,
    //   name: "API",
    //   state: "inactive",
    // },
    // {
    //   path: sendersettlement,
    //   name: "Settlement",
    //   state: "inactive",
    // },
    {
      path: RefPage,
      name: "Referral",
      icon: "devices",
      state: "inactive",
    },
    {
      path: sendersettings,
      name: "Setting",
      state: "inactive",
    },
  ];

  const Item = ({image, item_desc, deli_id, status})=>(
    <div onClick={()=>(history.push(GlobalizationLanguagewithCOntryCode(`/view-status/${deli_id}`, country, visitingCountryCode)))} className="items_status" >
      <img className="items_status_image" src={image} alt={item_desc} />
      <span>
        {item_desc?.charAt(0)?.toUpperCase() + item_desc?.slice(1)}
      </span>
      {/* <span>{status}</span> */}
    </div>
  )
  const UpdatePage = (datum) => {

    if (datum !== undefined) {
      
      const awaiting_quotes = datum?.map((x) => x?.awaiting_quote)?.flat()
      
      const awaiting_ack = datum?.map((x) => x?.awaiting_acknowledgment)?.flat()

      const awaiting_agent_assmt = datum?.map((x) => x?.awaiting_agent_assignment)?.flat()

      const pick_up = datum?.map((x) => x?.awaiting_pickup)?.flat()

      const intransit_data = datum?.map((x) => x?.in_transit)?.flat()

      const out_for_delivery_data = datum?.map((x) => x?.out_for_delivery)?.flat()

      // const completed_data = datum?.map((x) => x?.past_deliveries)?.flat();
      // const missed_data = datum?.map((x) => x?.missed_delivery)?.flat();
      // const expired_data = datum?.map((x) => x?.expired_deliveries)?.flat();

      const data = [
        ...awaiting_quotes,
        ...awaiting_ack,
        ...awaiting_agent_assmt,
        ...pick_up,
        ...intransit_data,
        ...out_for_delivery_data,
        // ...completed_data,
        // ...missed_data,
        // ...expired_data,
      ];

      if(data[0] === undefined){
        //  setBusinessUpdate(true)
      }
      else if(data?.length === 0){
        setWelcome(true)
      }
      else{
        setDeliveries(data);
      }
      setloading(false);
    } 
  }

  const SearchBar = () => {
    return (
      <div className="search">
        <div className="search-input-navbar">
          {/* <input
            type="text"
            value={search}
            autoFocus
            placeholder="search..."
            onChange={filterData}
          /> */}
        </div>
      </div>
    );
  };


  return (
    <div>
      <div className="main-father">
        <SearchBar />
      </div>

      <div className="dsktopscreen">
        <Layout>
          {
            isfetching ?
            <LinearProgress/>
            :
            null
          }

          {loading ? (
            <div
              style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "none",
                zIndex: "300",
                position: "fixed",
                top: "70px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <LinearProgress color="success" />
              </Box>
            </div>
          ) : (
            <div className="quoteCont">
              {deliveries[0] === undefined ? (
                <div className="columnBody1"></div>
              ) 
              :
              deliveries?.length === 0 ? (
                <div className="columnBody1">
                  - currently none to display -</div>
              ) 
              :
               (
                <div className="cont_items_status">
                   {deliveries?.sort((a, b) => new Date(b.date_listed) - new Date(a.date_listed))
                   .map((item, i) => (
                      <Item 
                        key={i} 
                        deli_id = {item?.delivery_id}
                        item_desc={item?.item_desc} 
                        image={item?.image_urls[0]} 
                        status={item?.delivery_status} 
                      />
                  ))}
                </div>
              )}
            </div>
          )}
        </Layout>
      </div>

      <div className="mobilescreen">
        <div>
          <div className="mobile-boby">
            <SenderSideBar
              pageTitle=" Dashboard"
              links={links}
              state={searchclick}
            />
            {
              isfetching ?
              <LinearProgress/>
              :
              null
            }
            <div className="mobile-dash-body">
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    backgroundColor: "none",
                    zIndex: "300",
                    position: "fixed",
                    top: "70px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress color="success" />
                  </Box>
                </div>
              ) : (
                <div className="quoteCont">
                  {deliveries[0] === undefined ? (
                    <div className="columnBody1"></div>
                  ) 
                  :
                  deliveries?.length === 0 ? (
                    <div className="columnBody1">
                      - currently none to display -
                    </div>
                  ) 
                  :
                  deliveries?.sort((a, b) => new Date(b.date_listed) - new Date(a.date_listed))
                  .map((item, i) => (
                    <Item 
                      key={i} 
                      deli_id = {item?.delivery_id}
                      item_desc={item?.item_desc} 
                      image={item?.image_urls[0]} 
                    />
                  ))}
                  </div>
              )}
            </div>
            <SenderBottomNav state = "status" />
          </div>
        </div>
      </div>

      <DialogOutClick
        open={ShowLogin}
        handleClose={() => {
          setShowLogin(false);
        }}
      >
        <LoginCompV2
          onFinish={() => {
            setShowLogin(false);
          }}
        />
      </DialogOutClick>

      <DialogSimplePop openPopup={showWelcome} hideBtn={true}>
        <div className="SenderUpdateRegXX">
          <div className="cont_welc">
            <img className="items_status_imagev2" src={Wunmi} alt="avatar" />
            <span>
              Hello I'm Wunmi. Thank you for using Dillivry, your request is live and active. Sit back while providers place their prices (quotes) for your job. I will be here to assist you till your item is delivered. Just click on me
            </span>
            <button onClick={()=>setshowWelcome(false)}>Ok</button>
          </div>
        </div>
      </DialogSimplePop>

      <DialogSimplePop openPopup={businessUpdate} hideBtn={true}>
        <div className="SenderUpdateRegXX">
          <SenderUpdateReg
            closePopup={() => setBusinessUpdate(false)}
            reload={() => window.location.reload()}
          />
        </div>
      </DialogSimplePop>

      <DialogSimplePop openPopup={otpRequired} hideBtn={true}>
        <div className="otpContainer">
          <img className="otpImag" src={Otp} alt="sent" />
          <div
            style={{
              textAlign: "center",
              fontWeight: "400",
              fontSize: "16px",
            }}
          >
            As a security measure, a one-off code was sent to you via
            Email @ {ls.get("eid")} and SMS
          </div>
          <div
            style={{
              textAlign: "center",
              fontWeight: "400",
              fontSize: "16px",
            }}
          >
            Please enter the one-off code below
          </div>
          <OtpInput
            isInputNum={true}
            value={otp}
            onChange={(otp) => {
              setotp(otp);
              setInvalidOtp(false);
            }}
            numInputs={4}
            shouldAutoFocus={true}
            separator={
              <span>
                <strong></strong>
              </span>
            }
            inputStyle={{width: "2.5rem",height: "2.5rem",margin: "0 0.3rem",fontSize: "20px",borderRadius: 3,border: "2px solid var(--main)",outline: "none"}}

          />
          {invalidOtp ? (
            <div
              style={{
                textAlign: "center",
                color: "red",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              Invalid OTP
            </div>
          ) : null}
          <OTPCountDown initialSeconds={45} type="sender" user_id={ls.get("eid")}/>

          {/* <div style={{textAlign:'center', fontWeight:'400', fontSize:'14px'}}>
        Resend code in 00:46
      </div> */}

          {otp.length === 4 ? (
            <button
              type="button"
              onClick={confirmOTP}
              className="otpConfirmBtn"
            >
              {loadingOtp ? (
                <span className="btnlabe">
                  <PuffLoader
                    color="white"
                    loading={loadingOtp}
                    speedMultiplier={1}
                    size={20}
                  />
                </span>
              ) : (
                <span>Continue</span>
              )}
            </button>
          ) : null}
        </div>
      </DialogSimplePop>
     
      <DialogSimplePop openPopup={businessUpdate} hideBtn={true}>
        <SenderUpdateReg
          closePopup={() => setBusinessUpdate(false)}
          reload={() => {setrecallApi(true); setBusinessUpdate(false)}}
        />
      </DialogSimplePop>

      <DialogPop
        openPopup={welcome}
        setopenPopup={setWelcome}
        spanhead= "Request a delivery"
        centerhead="Choose your preferred service"
      >
        <div>

        <div style={{dispaly: "flex", justifyContent: "center", gap:'10px'   }}   className="instant-schedule">
          <div className="instant"    
          onClick={()=> {
            history.push({
             pathname: viewQuote_request_delivery,
            state: {
              state: `instant`
            },
          });
          setWelcome(!welcome)
        }} 
            
          >
            <div className="icon">
              <img src={Instant} alt="Instant" className="instant-icon" />
            </div>
            <div className="instant-text-subtext">
              <p className="Instant-main">Instant</p>
              <span className="instant-sub">Pickup and delivery today</span>
            </div>
          </div>
          <div className="Schedule" onClick={()=> {
             history.push({
              pathname: viewQuote_request_delivery,
             state: {
               state: `schedule`
              //  from:  'registration'
             },
           });
           setshowRequestType(!showRequestType)
          }   }  >
            <div className="icon">
              <img src={Instant} alt="Schedule" className="Schedule-icon" />
            </div>
            <div className="Schedule-text-subtext">
              <p className="Schedule-main">Schedule</p>
              <span className="Schedule-sub">
                Choose pickup and delivery date
              </span>
            </div>
          </div>
        </div>
        </div>
      </DialogPop>
      
    </div>
  );
}
