import {useEffect, useState} from 'react';
import "./ReceiverPayment.css"
import Dillivry from "../assets/Dillivry.png"
import document from "../assets/document.png"
import delivery from "../assets/delivery.png"
import add from "../assets/add.png"
import add2 from "../assets/add2.png"
import Vector1 from "../assets/Vector1.png"
import Vector2 from "../assets/Vector2.png"
import Vector3 from "../assets/Vector3.png"
import Vector4 from "../assets/Vector4.png"
import file from "../assets/file.png"
import {useParams} from "react-router-dom";
import Footer from "../components/Footer/Footer";
import axios from 'axios';
import PaystackWidgetReceiver from "../components/PaystackWidgetReceiver";
import DialogPop from './DialogPop';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

function ReceiverPayment(){
    const history = useHistory();

    const [viewData, setviewData] = useState([])
    const {delivery_id, country_code} = useParams()
    const [loading, setloading] = useState(false)
    const [background, setbackground] = useState('')
    const [paymentexpired, setpaymentexpired] = useState(false)

    useEffect(() => {
        setloading(true)
        axios.post(`${process.env.REACT_APP_API}receiver-payment`, {
            action_type: "receiver_info",
            delivery_id: delivery_id,
            vcc: country_code
        })
        .then((res)=>{
            if (res?.data?.payment_status === "success"  ||   res?.data?.error_msg === "payment already made for this item"  ) {
               setpaymentexpired(!paymentexpired)
               setloading(false)
                Swal.fire({
                       
                    title: 'Expired Link',
                    text: 'Link Expired. Payment has already been made!',
                    confirmButtonColor: '#50b14a',
                  }).then(()=>{
                    history.push('/')
                  })
              
                  setloading(false)
            }else     {
                
                 setviewData(res?.data)
            }

             setloading(false)
             setbackground(res?.data?.image_urls[0])

        })
        .catch((err)=>{
            console.log(err, "error error")
            setloading(false)

        })





    }, [])
     
    const DelivryInfo = ({icon, title, details})=>{
        return(
            <div className='description-box'>
                <div className='icon-title'> <span className='info-icon'>{icon}</span><span className='info-title'>{title}</span> </div>
                <div className='detailss'>{details}</div>
            </div>
        )
    }

    // const Reload =()=>{
    //   return(
    //     Swal.fire({
                       
    //         title: 'Expired Link',
    //         text: 'Link Expired. Payment has already been made!',
    //       }).then(()=>{
    //         history.push('/')
    //       })
    //   )
    // }


    return(
        <>
        {loading ? 
            <>loading...</>
            :
            <>
            <div className='dil-logo'>
                <img src={Dillivry} style={{width:"150px"}}/> 
            </div>
            {/* { viewData?.payment_status === "success" ?  */}

            {/* {
                console.log(viewData, "ViewData")
            } */}

            { viewData.length === 0 || viewData?.error_msg === "payment already made for this item"  ? <></> 
            
         
                // <DialogPop openPopup={paymentexpired}
                //     setopenPopup={setpaymentexpired}
                //     head="Expired Link"
                // >
                   

                 
                    //  Swal.fire({
                       
                    //     title: 'Expired Link',
                    //     text: 'Link Expired. Payment has already been made!',
                    //   }).then(()=>{
                    //     history.push('/')
                    //   })
                 
                    // Reload()

                //  </DialogPop>
            
            //    <h1>Expired</h1> */}
            
            
            
            
             :
            
            <div className='receiver-payment-wrapper'>
                <div className='receiver-agent-info'>
                    <div className='receiver-item-image' style={{ backgroundImage:`url(${background})`}}></div>
                    {
                        viewData?.delivery_status === "awaiting_quote" || viewData?.delivery_status === "awaiting_agent_assignment" ?
                        <></>
                        :
                        <>
                            <div className='receiver-agent-status'> <div>Agent information</div> </div>
                            <div className='receiver-agent'>
                                <div style={{position:'relative', width:"100%", height:"100%"}}>
                                    <img className='imagereceiver'  src={viewData?.agent_id_card}/>
                                </div>
                            </div>
                            <div className='receiver-agent-status'> 
                                Phone number: {viewData?.agent_phone?.phone_number   ?  viewData?.agent_phone?.phone_number : viewData?.agent_phone}
                            </div>
                            <div className='receiver-agent-status'> 
                                {viewData?.delivery_status?.charAt(0)?.toUpperCase() + viewData?.delivery_status?.slice(1)?.replace(/_/g, " ")}
                            </div>
                        </>
                       
                    }
                </div>
                <div className='receiver-information-wrapper' >
                    <div className='receiver-delivry-information'> 
                        <div className='delivery-info'>Delivery infomation</div>
                        <DelivryInfo
                        title={"Description:"}
                        icon= {<img src={document}/>}
                        details={viewData?.item_desc?.charAt(0)?.toUpperCase() + viewData?.item_desc?.slice(1)}

                        />
                        <DelivryInfo
                        title={"Delivery ID:"}
                        icon= {<img src={delivery}/>}
                        details={viewData?.delivery_id}
                        
                        />
                        <DelivryInfo
                        title={"Delivery time frame:"}
                        icon= {<img src={add}/>}
                        details={viewData?.delivery_time_frame}
                        />
                        <DelivryInfo
                        title={"Delivery status:"}
                        icon= {<img src={add2}/>}
                        details={viewData?.delivery_status?.charAt(0)?.toUpperCase() + viewData?.delivery_status?.slice(1)?.replace(/_/g, " ")}

                        />
                        <DelivryInfo
                        title={"Pickup address:"}
                        icon= {<img src={Vector1}/>}
                        details={viewData?.pickup_address?.charAt(0)?.toUpperCase() + viewData?.pickup_address?.slice(1)}
                        
                        />
                        <DelivryInfo
                        title={"Sender’s name:"}
                        icon= {<img src={Vector1}/>}
                        details={viewData?.sender_name?.charAt(0)?.toUpperCase() + viewData?.sender_name?.slice(1)}

                        />
                        <DelivryInfo
                        title={"Sender’s phone:"}
                        icon= {<img src={Vector2}/>}
                        details={viewData?.sender_phone_no}
                        />
                        <DelivryInfo
                        title={"Delivery address:"}
                        icon= {<img src={Vector3}/>}
                        details={viewData?.delivery_address?.charAt(0)?.toUpperCase() + viewData?.delivery_address?.slice(1)}

                        />
                        <DelivryInfo
                        title={"Receiver’s name:"}
                        icon= {<img src={Vector3}/>}
                        details={viewData?.delivery_contact?.charAt(0)?.toUpperCase() + viewData?.delivery_contact?.slice(1)}

                        />
                        <DelivryInfo
                        title={"Receiver’s phone:"}
                        icon= {<img src={Vector4}/>}
                        details={viewData?.delivery_phone_no}
                        />

                        <DelivryInfo
                        title={"Payment Status:"}
                        icon= {<img src={delivery}/>}
                        details={ viewData?.payment_status === "success" ? "Paid" : "Not Paid"}
                        />
                        {
                            viewData?.delivery_status === "awaiting_quote" || viewData?.payment_status === "success"  ||  viewData?.delivery_status === "awaiting_provider_acknowledgment"  ? 
                            <></>
                            :
                            <>
                                <div className='payment-info'>Payment Infomation</div>
                                <div className='price-box'>
                                    <div className='price-icon-title'> <span><img src={file}/></span><span>Delivery price:</span> </div>
                                    <div className='price-detailss'>₦{viewData?.quote_amount}</div>
                                    <div className='receiver-button'>
                                        <button className='receiver-pay-button'> 
                                            <PaystackWidgetReceiver
                                                amount={viewData?.quote_amount + "00"} //for paystack
                                                amount_paid={viewData?.quote_amount}
                                                fulfiller_id={viewData?.fulfiller_id}
                                                delivery_id={viewData?.delivery_id}
                                                email={viewData?.receiver_email}
                                                sender_id = {viewData?.sender_email}
                                                vcc={country_code}
                                            />
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    {/* {
                        viewData?.delivery_status === "awaiting_quote" ? 
                        null
                        :
                        <div className='receiver-button'>
                            <button className='receiver-pay-button'> 
                                <PaystackWidget2
                                    amount={viewData?.quote_amount + "00"} //for paystack
                                    amount_paid={viewData?.quote_amount}
                                    fulfiller_id={viewData?.fulfiller_id}
                                    delivery_id={viewData?.delivery_id}
                                    email={viewData?.receiver_email}
                                    fromReciever
                                />
                            </button>
                        </div>
                    } */}
                    
                
                </div>
            
            
            </div>
             } 
            <Footer />
            </>
            }
        </>
    )
}
export default ReceiverPayment

