import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import * as React from 'react';
// import Controls from "./controls/Controls";
import CloseIcon from '@material-ui/icons/Close';

function PaymentDialogPop({ head, children, openPopup, setopenPopup, onClick, spanhead, centerhead }) {
    // const {head, children, openPopup, setopenPopup} = props;
    return (
        <Dialog open={openPopup} maxWidth='xs' fullWidth>
            <DialogTitle>
                <div style={{ display: "flex" }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }} onClick={onClick}>
                        {head}
                        {
                            <div style={{display:  "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}  >
                               <div style={{ fontFamily: "Roboto", fontStyle: "normal", fontWeight: "700", fontSize: "16px", color: "#484848" }}     > {spanhead}</div>
                                <div style={{ fontFamily: "Roboto", fontStyle: "normal", fontWeight: "700", fontSize: "16px", color: "#484848" }}      >   {centerhead}</div>
                            </div>
                        }
                    </Typography>
                    <CloseIcon onClick={() => { setopenPopup(false) }}></CloseIcon>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}
export default PaymentDialogPop;