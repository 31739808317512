import {useState, useContext, useEffect} from 'react'
import { useForm, Controller } from "react-hook-form";
import {  FormControl, Grid, InputLabel } from '@material-ui/core';
import InputField from '../../../components/InputField';
import { yupResolver } from "@hookform/resolvers/yup";
import { FaTextHeight, FaEdit } from 'react-icons/fa';
import * as yup from "yup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import PuffLoader from 'react-spinners/PuffLoader';
import SecureLS from 'secure-ls';
import Axios from 'axios'
import erros from "../../../assets/erros.png";
import Swal from 'sweetalert2';
import { ConnContext } from '../../../context/ConnContext';
import { SenderContext } from "../../../context/SenderDashboard/SenderDashboardContext";
import{DashboardContext}  from '../../../context/Dashboard/DashboardProvider/dashboard.provider'
import {useHistory, Link} from 'react-router-dom'
import Dillivry from '../../../assets/Dillivry.png'
import axios from 'axios';
import { AuthContext } from '../../../context/AuthContext';

import GlobalizationLanguage from '../../../components/GlobalizationLanguage';

export default function SenderRegLogin() {
    const Dashboard = GlobalizationLanguage("/Dashboard")
  var riders_and_drivers = GlobalizationLanguage("/riders-and-drivers")
  var viewQuote = GlobalizationLanguage("/viewQuote")
  const forgetpass=GlobalizationLanguage("/forgetpass")

    const history = useHistory();
    const {updateDashboard} = useContext(DashboardContext);
    const {initialstate, UpdateAlldata, alldata, setalldata, UpdateData, UpdateCartData, Cartdata, setCartdata, UpdatePromoInfo, PromoInfo, setPromoInfo,  Updatequote, GBStatistics, NGStatistics} = useContext(SenderContext)
    // const {visitingCountryCode} = useContext(ConnContext)
    const authContext = useContext(AuthContext);
    const [showpassword, setshowpassword] = useState(false);
    const [loading, setloading] = useState(false);
    const {visitingCountryCode, handlelogindata, logindata} = useContext(ConnContext)
    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
    const endpoint = process.env.REACT_APP_API
    const [userType, setuserType] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const schema = yup.object().shape({
        // email: yup.string().email("Valid email is required").required("Email is required"),
        email: yup
        .string()
        .matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, "Valid email is required")
        .required("Email is required"),
        password: yup.string().required("Password is required"),
    })

    const { control,handleSubmit, formState: { errors } } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    // const submitRegForm = (data)=>{
    //     console.log(data)
    // }

    const submitRegForm = async (data)=>{
        setloading(true);
        // console.log(data, "data")

     


        await Axios.post(`${endpoint}m-dillivry-auth-manager`, {
            auth_type: "login",
            username: data.email.toLowerCase(),
            password: btoa(data.password),
            // vcc: visitingCountryCode
          }, )
            .then((response) => {
              handlelogindata(response)
    ls.set("user_location", response?.data?.vcc)

              ls.set("aCs_", response.data?.account_status);
              if (response) {
                ls.set("eid", data.email);
                if(response.status === 202){
                  history.push({
                    pathname: viewQuote,
                   state: {
                     from:'otp_required'
                   },
                 });
                 setloading(false);
                }
      
                if (response.data.auth_status === "new_password_required") {
                  history.push({
                    pathname: "/reset",
                    state: data.email,
                  });
                }
                else if(response?.data?.auth_status === "PasswordResetRequiredException"){
                  setloading(false);
                  Swal.fire({
                    icon: "warning",
                    title: "Password reset",
                    text: "Reset your password with the passcode sent to your email ",
                    allowOutsideClick: false,
                    confirmButtonColor: "gray",
                  }).then((result) => {
                    if (result.value) {
                      history.push({
                        pathname: "/resetpass",
                        state: data.email,
                      });
                      setloading(false);
                    }
                  });
                }
                
                else if (response.data.auth_status === "success") {
                  const { IdToken } = response.data.token;
                  const token = IdToken.split(".")[1];
                  const encodedToken = atob(token);
                  const tokenJSON = JSON.parse(encodedToken);
                  ls.set("_tyl_", tokenJSON["custom:account_name"]);
      
                  if (tokenJSON["custom:role"] !== undefined) {
                    authContext.setAuthState(
                      Object.assign(response.data, { role: tokenJSON["custom:role"] })
                    );
                    ls.set("_nodi", response.data.token.IdToken);
                    ls.set("_iRl", response.data.role);
                    ls.set("nful", tokenJSON["given_name"]);
                    ls.set("_tyf_", tokenJSON["custom:account_name"]);
                    ls.set("_iyD", tokenJSON["custom:account_name"]);
                    ls.remove("_utk");
      
                    if (
                      response.data.role === "dillivry-corp-sys-admin"
                    
                    ) {
                      authContext.setAuthState(
                        Object.assign(response.data, {
                          role: tokenJSON["custom:role"],
                        })
                      );
                      ls.set("_nodi", response?.data?.token?.IdToken);
                      ls.set("eid", response?.data?.user_name);
                      ls.set("_iRl", response?.data?.role);
                      ls.set("nful", tokenJSON["given_name"]);
                      ls.set("_tyf_", tokenJSON["custom:account_name"]);
                      ls.remove("_utk");
      
                      axios
                        .get(`${endpoint}m-dillivry-dashboard-manager`, {
                          headers: {
                            Authorization: response.data.token.IdToken,
                          },
                        })
                        .then((response) => {
                          ls.set(
                            "means_of_delivery",
                            JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                          );
                          updateDashboard(response);
                          history.push({
                            pathname: "/Dashboard"
                          });
                        })
                        .catch((error) => {
                          setloading(false);
      
                          if (error?.response?.status === 404) {
                            history.push("/Dashboard");
                          } 
                          else if (error?.response?.status === 403) {
                          } 
                          else {
                          }
                        });
                    }
                    else if (
                    
                      response.data.role === "dillivry-corp-bid-ops"
                    ) {
                      authContext.setAuthState(
                        Object.assign(response.data, {
                          role: tokenJSON["custom:role"],
                        })
                      );
                      ls.set("_nodi", response.data.token.IdToken);
                      ls.set("eid", response?.data?.user_name);
                      ls.set("_iRl", response.data.role);
                      ls.set("nful", tokenJSON["given_name"]);
                      ls.set("_tyf_", tokenJSON["custom:account_name"]);
                      ls.remove("_utk");
      
                      axios
                        .get(`${endpoint}m-dillivry-dashboard-manager`, {
                          headers: {
                            Authorization: response.data.token.IdToken,
                          },
                        })
                        .then((response) => {
      
                          ls.set(
                            "means_of_delivery",
                            JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                          );
      
                          history.push({
                            pathname: "/Dashboard",
                            state: [response.data, response.status],
                          });
                        })
                        .catch((error) => {
                          setloading(false);
                          if (error?.response?.status === 404) {
                            history.push("/Dashboard");
                          } else if (error?.response?.status === 403) {
                          } else {
                          }
                        });
                    } 
                    else if (
                    
                      response.data.role === "dillivry-sole-trader-ops"
                    ) {
                      setuserType("individualprovider");
                      setShowAlert(true);
                      setloading(false);
                      authContext.setAuthState(
                        Object.assign(response.data, {
                          role: tokenJSON["custom:role"],
                        })
                      );
                      ls.set("_nodi", response.data.token.IdToken);
                      ls.set("eid", response.data.user_name);
                       ls.set("_iRl", response.data.role);
                      ls.set("nful", tokenJSON["given_name"]);
                      ls.set("_tyf_", tokenJSON["custom:account_name"]);
                      ls.remove("_utk");
                      ls.remove("_iRl")
                      ls.remove("_nodi")
                      ls.remove("eid")
                      // history.push("/open-deliveries");
                      setloading(false);
                    }
                  }
                  else {
                    ls.set("_iRl", "individual");
                    ls.remove("_nodi");
                    // ls.set("eid", btoa(data.password));
                    ls.set("eid", response.data.user_name);
                    ls.set("_utk", response.data.token.IdToken);
      
                   console.log("response",response)
                    UpdateAlldata(response)
                    UpdateData(response?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.flat())
                    UpdateCartData(response?.data?.data?.user_info?.cart_history?.deliveries)
                    NGStatistics(response?.data?.data?.deliveries?.map((x) => x?.ng ))
                    UpdatePromoInfo(response?.data?.data?.user_info?.general_promo_code)
                    GBStatistics(response?.data?.data?.deliveries?.map((x) => x?.gb ))
                    Updatequote(response)
                    handlelogindata(response)
                   const Array_data =  response?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.flat()
                   
                   if(response.status === 202){
                    // ls.set("eid", btoa(data.password));
                    ls.set("eid", response.data.user_name);
                    ls.set("eid", data.email);
                    ls.set("_nodi", response?.data?.token?.IdToken);
                    history.push({
                      pathname: viewQuote,
                     state: {
                       from:'otp_required'
                     },
                   });
                   setloading(false);
                  }else{
      
                  
                  // console.log(response.data.data, 'res new data', response.data.role)
                  ls.set("_iRl", "individual");
                  // ls.remove("_nodi");
                //   ls.set("eid", btoa(data.password));
                ls.set("eid", response.data.user_name);
                  ls.set("_utk", response.data.token.IdToken);
                  ls.set("_nodi", response.data.token.IdToken);
                  history.push({
                    pathname: viewQuote,
                    state: Array_data,
                  });
                 
                  setloading(false);
                }
      
                  }
                }
      
              }
            })
            .catch((error) => {
              console.log(error, "errorrr", error.response)
              // console.log(error.response, "error response")
            //   ls.set("eid", btoa(data.password));
            ls.set("eid", data.email);
            ls.set("_utk", error?.response?.data?.token?.IdToken);
              if(error.response?.status === 401){
                // UpdateAlldata(undefined)
                history.push({
                  pathname: viewQuote,
                 state: {
                   from:'registration'
                 },
               });
               setloading(false);
              }
      
             else if (error.response?.status === 403) {
                setloading(false);
                setuserType("agent");
                setShowAlert(true);
              } 
              else if(error?.response?.data?.auth_status === "PasswordResetRequiredException"){
                setloading(false);
                Swal.fire({
                  icon: "warning",
                  title: "Password reset",
                  text: "Reset your password with the passcode sent to your email ",
                  allowOutsideClick: false,
                  confirmButtonColor: "gray",
                }).then((result) => {
                  if (result.value) {
                    history.push({
                      pathname: "/resetpass",
                      state: btoa(data.password),
                    });
                    setloading(false);
                  }
                });
              }
             
              else {
                Swal.fire({
                  iconHtml: `<img src=${erros} style="width:100px;">`, // imageUrl: "dm-dillivry-web/web/src/assets/erros.png",
                  title: "Oops!",
                  imageAlt: "A tall image",
                  text: "Invalid username or password",
                  confirmButtonColor: "var(--main)",
                });
                setloading(false);
              }
            });

    }


    return (
        <div>
            <div className="showimgae">
                <img src={Dillivry} alt="dillivry logo" width='150px' style={{cursor:'pointer', margin:'0 auto'}} onClick={()=>history.push('/')} />
            </div>
            <div style={{textAlign:'center',padding:'50px 0', fontSize:'20px', fontWeight:'500'}}>Sign in</div>

            <form style={{width:'100%'}} onSubmit={handleSubmit(submitRegForm)}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={ls.get('_bisn')}
                            render={({ field }) => <FormControl style={{width:'100%'}} variant="outlined">
                                {/* <InputLabel htmlFor="email">Email</InputLabel> */}
                                <InputField
                                    label="Email"
                                    {...field}
                                    error={errors.email}
                                    id="email"
                                    type="text"
                                    labelWidth={33}
                                />
                                <div className="err">{errors.email?.message}</div>
                            </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <FormControl style={{width:'100%'}} variant="outlined">
                                {/* <InputLabel htmlFor="password">Password</InputLabel> */}
                                <InputField
                                label="Password"
                                    {...field}
                                    error={errors.password}
                                    id="password"
                                    type={showpassword ? "text" : "password"}
                                    labelWidth={60}
                                    endIcon={
                                        showpassword ? (
                                            <VisibilityOffIcon
                                              style={{
                                                color: "grey",
                                                fontSize: "25px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => setshowpassword(!showpassword)}
                                            />
                                        ) : (
                                            <VisibilityIcon
                                              style={{
                                                color: "grey",
                                                fontSize: "25px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => setshowpassword(!showpassword)}
                                            />
                                        )
                                      }
                                />
                                <div className="err">{errors.password?.message}</div>
                            </FormControl>
                            }
                        />
                        <div style={{marginTop:'15px'}}>
                            <label className="check_agree" >
                                <input type="checkbox"/>
                                <span className="checkmarker"></span>
                            </label>
                            <div style={{width:'100%',color:'grey',  display:'flex', justifyContent:'space-between', paddingLeft:'20px', fontSize:'14px'}}>
                                <span>Remember me</span>
                                <span style={{color:'var(--main)', cursor:'pointer', userSelect:'none'}} onClick={()=>history.push(forgetpass)}>Forget password? </span>
                                
                            </div>
                        </div>
                    </Grid>
                   
                    <Grid item xs={12}>
                        <button type={loading? "button":"submit"} style={{width:"100%", marginTop:"40px ", textAlign:'center', userSelect:'none',cursor:'pointer', padding:'15px', backgroundColor:'var(--main)', color:'white', borderRadius:'3px', border:0}}>
                            {
                                loading ? 
                                <span className="btnlabe">
                                    <PuffLoader color='white' loading={loading}  speedMultiplier={1} size={24}/>
                                </span>
                                :
                                <span>
                                  Login  
                                </span>
                            }
                        </button>
                    </Grid>
                    <Grid item xs={12}>
                        <div className='haveAcct'>Don't have an account yet? <Link onClick={()=>window.location.reload()}>Sign up</Link></div>
                    </Grid>
                </Grid>

            </form>
            
        </div>
    )
}
