/* eslint-disable react-hooks/exhaustive-deps */
import  { useEffect } from 'react'
import './ViewFulfiller.css'
import './ViewDelivery.css'
import { BiArrowBack } from 'react-icons/bi'
import { Grid } from '@material-ui/core'
import { IoCheckbox } from 'react-icons/io5'
import {  FaThumbsDown, FaThumbsUp } from 'react-icons/fa'
import { useHistory } from "react-router-dom";
import {FcCancel} from 'react-icons/fc'

function ViewFulfiller({details, handleChange}) {
  
    useEffect(() => {
    window.scrollTo(0,0)
    }, [])

    const history = useHistory();

    return (  
        <div className="fulfillerView">


            
            <div  className="fulfillerViewTop">
                <div onClick={handleChange}>
                    <BiArrowBack size='25px'/>
                </div> 
                {/* <div className='viewLogo'>
                    <img src={dillivry} alt="logo"/>
                </div> */}
                <div className='topCircle'></div>
            </div>

            <div className="fulfillertable">
                <div className="tableTitle">
                    {details?.account_name}
                </div>
                <div className="tableBody">
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <div className='img'
                                style={{ backgroundImage:`url(${details?.cop_logo})`}}
                                >
                            </div>
                            <div className="verified">
                                <IoCheckbox color='var(--main)' /> <span style={{fontSize:'13px', fontWeight:'600'}}>Verified</span>
                            </div>
                            <div>
                                {
                                details?.good_in_transit_insurance?.insurance_coverage?.length > 4  ?
                                <div className="verified">
                                    <IoCheckbox color='var(--main)'/> <span style={{fontSize:'13px', fontWeight:'600'}}>Insurance</span>
                                </div>
                                :
                                <div className="verified">
                                    <FcCancel/> <span style={{fontSize:'13px', fontWeight:'600'}}>Insurance</span>
                                </div>
                                }
                            </div>
                            
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={1} style={{fontSize:'12px'}}>
                                <Grid item xs={7} style={{fontWeight:'500'}}>Positive feedback:</Grid><Grid item xs={5}>({details.Positive_feedback})</Grid>
                                <Grid item xs={7} style={{fontWeight:'500'}}>Completed Jobs:</Grid><Grid item xs={5}>({details.completed_jobs})</Grid>
                                <Grid item xs={7} style={{fontWeight:'500'}}>Rejected Jobs:</Grid><Grid item xs={5}>({details.rejected_jobs})</Grid>
                                <Grid item xs={7} style={{fontWeight:'500'}}>Registered since:</Grid><Grid item xs={5}>{details.account_status?.joined_date?.substr(8, 2)}/{details.account_status?.joined_date?.substr(5, 2)}/{details.account_status?.joined_date?.substr(0, 4)}</Grid>
                                <Grid item xs={7} style={{fontWeight:'500'}}>Provider type:</Grid><Grid item xs={5}>{details?.role === "dillivry-corp-sys-admin" ? "Corporate": details?.role === "dillivry-sole-trader-ops"  ? "Individual" : null  }
                                </Grid>
                                <Grid item xs={12} style={{fontWeight:'500'}}>Insurance coverage:</Grid>
                                <Grid item xs={12}>
                                {details?.good_in_transit_insurance?.insurance_coverage ?   details?.good_in_transit_insurance?.insurance_coverage : 'None' }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div style={{padding:'20px 0'}}>
                    <p style={{padding:'10px', fontSize:'13px', fontWeight:'600'}}>Feedback</p>
                    <table style={{border: '1px solid #c4c4c4'}}>
                        <tr>
                            <th  style={{padding:'10px', fontSize:'12px'}}></th>
                            <th style={{padding:'10px', fontSize:'12px'}}>1 month</th>
                            <th style={{padding:'10px', fontSize:'12px'}}>6 months</th>
                            <th style={{padding:'10px', fontSize:'12px'}}>12 months</th>
                        </tr>
                        <tr style={{color:'var(--main)'}}>
                            <td style={{padding:'10px', fontSize:'12px'}}>Positive</td>        
                            <td style={{padding:'10px', fontSize:'12px'}}>{details.Feedback.positive.one_month}</td>        
                            <td style={{padding:'10px', fontSize:'12px'}}>{details.Feedback.positive.six_month}</td>        
                            <td style={{padding:'10px', fontSize:'12px'}}>{details.Feedback.positive.telv_month}</td>        
                        </tr> 
                        <tr style={{color:'red'}}>
                            <td style={{padding:'10px', fontSize:'12px'}}>Negative</td>   
                            <td style={{padding:'10px', fontSize:'12px'}}>{details.Feedback.negative.one_month}</td>        
                            <td style={{padding:'10px', fontSize:'12px'}}>{details.Feedback.negative.six_month}</td>        
                            <td style={{padding:'10px', fontSize:'12px'}}>{details.Feedback.negative.telv_month}</td>        
                        </tr> 
                    </table>
                </div>

                <div className='reviewTable'>
                    <div className="reviewHeable">
                        Reviews
                    </div>
                        {
                            details.reviews?.map((review, index)=>{ return <div key={index} className="reviewBody">
                                <div> 
                                    <Grid container spacing={1}>
                                        <Grid item xs={3} style={{fontWeight:'500'}}>Rating</Grid> <Grid item xs={9}>{review.rating === "notGreat" ? <FaThumbsDown color='red'/> : <FaThumbsUp color='var(--main)'/>}</Grid>
                                        <Grid item xs={3} style={{fontWeight:'500'}}>Item</Grid> <Grid item xs={9}>{review.item_desc?.charAt(0)
                                          ?.toUpperCase() + review.item_desc?.slice(1) }</Grid>
                                        <Grid item xs={3} style={{fontWeight:'500'}}>Left by</Grid> <Grid item xs={9}>{review.sender_name}</Grid>
                                        <Grid item xs={3} style={{fontWeight:'500'}}>Comment</Grid> <Grid item xs={9}>{review.additional_comments}</Grid>
                                        <Grid item xs={3} style={{fontWeight:'500'}}>Date  </Grid> <Grid item xs={9}>
                                             {review.time_stamp.substr(8, 2)}/{review.time_stamp.substr(5, 2)}/{review.time_stamp.substr(0, 4)}
                                             </Grid>
 
                                    </Grid>
                                </div>
                            </div>
                            })
                        }
                </div>
            </div> 
        </div>
    )
}

export default ViewFulfiller
