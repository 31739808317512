import React, { useEffect } from "react";
import Nav from "../../components/Nav/Nav";
import IndividualSidebar from "../../components/Sidebar/IndividualSidebar";
import imagelogo from "../../assets/Dillivry.png";
import { useHistory } from "react-router-dom";
// import "./Tracking3.css";
import './Missed.css'

import ChairBig from "../../assets/ChairBig.svg";
import Checkmark from "../../assets/Checkmark.svg";
import Greensmiley from "../../assets/Greensmiley.svg";
import Redsmiley2removebgpreview from "../../assets/Redsmiley2removebgpreview.png";
import greensmiley1removebgpreview from "../../assets/greensmiley1removebgpreview.png"
import SecureLS from 'secure-ls';

export default function Missed({data}) {
    const history = useHistory();
  const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});



  // useEffect(() => {
  //  console.log(data)
  // }, []);
  
    return (
        <div className="tracking-body">
      <div className="status-body">
        <div className="section-one">
          <div className="left-side-status">
            <div className="Status-h1">
              <h1 className="staus-main">Status</h1>
            </div>
            <div className="Listed-h1">
              <h1 className="Listed-main">Missed delivery attempt</h1>
            </div>
            <div className="Date-listed-p">
              <p className="Date-listed-main">
                {/* Your item was delivered on Tue 9th Nov at 12:30pm */}
                Your item was Missed
                {/* on {data.delivered_timestamp?.slice(0, 10)} at {data.delivered_timestamp?.slice(12, 19)} */}
              </p>
            </div>
          </div>

          {/* {
  console.log(data.delivered_timestamp)
}
{
  console.log(data.delivered_timestamp.slice(0, 10))
}

{
  console.log(data.delivered_timestamp.slice(12, 19))
}

{
  console.log(new Date(), 'newdate')
}

{
  console.log(data.delivered_timestamp.slice(12, 19).toLocaleString('en-US', { hour: 'numeric', hour12: true }))
} */}
          <div className="rightfirst">
            <div className="right-side-status">
              <div className="to-be-styled-seperately">
                <div className="Tobe-delivered">
                  <p className="tobe-delivered">To be delivered to:</p>
                </div>
                <div className="Contact">
                  <h1 className="contact-text">Contact: {data.delivery_phone_no}</h1>
                  <h1 className="Address-Main">
                  {data.delivery_address}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sectionB-">
          <div className="section-two">
            <div className="section-two-follow-my-item">
              <h1 className="followmyitem">Follow my item</h1>
              <p className="findout">
                Find out where your item has been on its journey to the
                destination
              </p>

              <div className="container">
                <ul className="progressBar-missed">
                  <li className="active-progress">Listed</li>
                  <li>Quote accepted</li>
                  <li>Awaiting pickup</li>
                  <li>Picked up</li>
                  <li>Out for delivery</li>
                  <li>Missed</li>
                  
                  <li>Delivered</li>
                </ul>
              </div>
            </div>

            <div className="img-display">
              <div className="inner-img-div">
                <img src={data.image_urls[0]}  alt="Itemdescription" />
                <p>{data.item_desc}</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="third-section">
          <div className="empty1"></div>
          <div className="main-twp-rate">
            <div className="rate-your">
              <h1 className="rate-your-provider-main">Rate your provider</h1>
            </div>

            <div className="smiley-green-and-red">
              <img 
              
              
              // onClick={()=> history.push(`/9658393812345985/${data.sender_email}/${data.delivery_id}/Great`)}
              onClick={() =>{
                if (ls.get("_utk") === "") {
                    history.push('/login')
                }
                else{
                  history.push(`/9658393812345985/userID=${data.sender_email}/ID=${data.delivery_id}/Great`)
                // history.push(`/TrackingPages/${detail.delivery_id}`)
              }}}
              
              src={greensmiley1removebgpreview} alt="Greensmiley" />
              <img  
 onClick={() =>{
  if (ls.get("_utk") === "") {
      history.push('/login')
  }
  else{
    history.push(`/9658393812345985/userID=${data.sender_email}/ID=${data.delivery_id}/NotGreat`)
  // history.push(`/TrackingPages/${detail.delivery_id}`)
}}}

              
              src={Redsmiley2removebgpreview} alt="RedSmiley" />
            </div>
          </div>
          <div className="empty-three"></div>
        </div> */}
      </div>
    </div>
    )
}
