import { Redirect, Route } from "react-router-dom";
import SecureLS from "secure-ls";
import jwt_decode from "jwt-decode";

function ProtectedRoute({ component: Component, ...restOfProps }) {

  const ls = new SecureLS({ isCompression: true, encodingType: "rabbit", encryptionSecret: "dm_dillivry", });

  let response = ''

  const tokenCheck = () => {
    if (ls.get("_utk") !== '') {
      const decoded = jwt_decode(
        ls.get("_utk")
      );

      const expired = decoded.exp;

      if (expired <= Math.floor(Date.now() / 1000)) {
        response = "expired"
      }
      else {
        response = "valid"
      }
    }
    else {
      response = "expired"
    }
  }

  tokenCheck()

  const isAuthenticated = response

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated === "valid" ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export default ProtectedRoute;