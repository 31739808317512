import React from 'react'
import Webcam from 'react-webcam'
import PassDemo from '../../assets/PassDemo.png'
import CaptureBtn from './CaptureBtn'
import {MdCameraFront} from 'react-icons/md'
import {IoIosImages} from 'react-icons/io'
import imageCompression from 'browser-image-compression'
import SecureLS from 'secure-ls';
import {IoMdArrowRoundBack} from 'react-icons/io'

function Web_capture({goBack, goToPreview}) {
    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

    const [toBack, settoBack] = React.useState(false)

    const swap = () =>{
        settoBack(!toBack)
    }

    const videoConstraints = {
        width: 300,
        height: 300,
        facingMode: toBack ? { exact: "environment" } : "user" 
      };
      const webcamRef = React.useRef(null);

    const capture = React.useCallback(
        () => {
            ls.set('_prv_', webcamRef.current.getScreenshot() )
            goToPreview();
        },
        [webcamRef]
    );

    const  options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 2048,
        }

    // async function formatImage(dataa) {
    //         if (dataa.substring(11,14) === "png"){
    //             setSelfie( dataa.replace('data:image/png;base64,',''));
    //         }
    //         else if (dataa.substring(11,14) === "gif"){
    //             setSelfie( dataa.replace('data:image/gif;base64,',''));
    //         }
    //         else if (dataa.substring(11,14) === "jpg"){
    //             setSelfie( dataa.replace('data:image/jpg;base64,',''));
    //         }
    //         else if (dataa.substring(11,15) === "jpeg"){
    //             setSelfie( dataa.replace('data:image/jpeg;base64,',''));
    //         }
    //         else{
    //             setSelfie( dataa.replace('data:image/webp;base64,',''));
    //         }

    //         ls.set('_it_s', selfie )
    // }

    async function imageHandler (e){
        const file = e.target.files[0]
        const output = await imageCompression(file, options)
        const dataa = await imageCompression.getDataUrlFromFile(output)
            ls.set('_prv_', dataa)
            goToPreview();
        }
    
    return (
        <div>
            <div style={{width:'100%', marginBottom:'40px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <IoMdArrowRoundBack size='30px' onClick={goBack}/>
            </div>

            <input 
                type="file" id="selfie" 
                accept="image/png, image/gif, image/jpeg" 
                onChange={imageHandler} 
                style={{display:'none'}} />

            <div style={{width:'100%',display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                <div style={{width:'240px', height:'240px', borderRadius:'50%', position:'relative', display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <img src={PassDemo} alt="pass_demo" style={{position:'absolute', zIndex:'200', maxHeight:'190px' }}/>
                        <Webcam style={{borderRadius:'50%'}} 
                            audio={false}
                            mirrored={true}
                            ref={webcamRef}
                            screenshotFormat="image/png"
                            width={240}
                            height={240}
                            screenshotQuality={0.1}
                            minScreenshotHeight={150}
                            minScreenshotWidth={150}
                            videoConstraints={videoConstraints}
                        />
                </div>
                
            </div>
            {/* image for preview */}
                {/* <img src={imageSrc} alt="" /> */}
            {/* image to send to database */}
                {/* <p onClick={()=>console.log(selfie)}>show selfie</p> */}

            <div className="btnCaptureDesktop">
                <label htmlFor="selfie">
                    <IoIosImages size="25" color="var(--main)"/>
                </label>
                
                <CaptureBtn onClick={capture}/>

                <label>
                    <MdCameraFront size="25" color="var(--main)" onClick={swap}/>
                </label>
            </div>

            <div className="btnCaptureMobile">
                <label htmlFor="selfie">
                    <IoIosImages size="25" color="var(--main)"/>
                </label>
                
                <CaptureBtn onClick={capture}/>

                <label>
                    <MdCameraFront size="25" color="var(--main)" onClick={swap}/>
                </label>
            </div>
        </div>
    )
}

export default Web_capture
