import React from 'react'
import './TopMenu.css'
import dillivry from '../../assets/Dillivry.png'
import {Link} from 'react-router-dom'

function LoginTopMenu() {
    return (
        <div>
            <div className="wrapper">
                <nav>
                {/* <input type="checkbox" id="show-search"/> */}
                <div className="content">
                    <Link to='/'>
                        <div className="logo">
                            <img src={dillivry} alt="" />
                        </div>
                    </Link> 
                </div>
              
                </nav>
            </div>
        </div>
    )
}

export default LoginTopMenu