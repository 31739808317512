import React from 'react'
import axios from 'axios';

const GenerateReferalBtn = ({setValue}) => {

    const generateToken = () =>{
        // call api and save the respose to setvalue
        setValue("response")
        console.log(" console console response")
    }
    return (
        <div>
            <div className='generate_btn' onClick={generateToken}>
                Generate referral code
            </div>
        </div>
    )
}

export default GenerateReferalBtn