import React, { useState, useEffect, useContext } from "react";
import carticon from "../../../assets/carticon.svg";
import shopping from "../../../assets/shopping.svg";
import "./Navbar.css";
import {useHistory} from 'react-router-dom';
import dillivrylogo from './Assets/dillivrylogo.svg'
import { SenderContext } from "../../../context/SenderDashboard/SenderDashboardContext";
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
import DialogPop from "../../../components/DialogPop";
import Instant from "../../../assets/Instant.svg";
import Cookies from "js-cookie";
import { ConnContext } from "../../../context/ConnContext";
import SecureLS from "secure-ls";
import axios from "axios";


export default function Navbar({ backTo, backToText}) {

  var viewQuote_myCart = GlobalizationLanguage("/viewQuote/myCart")
  var viewQuote_request_delivery = GlobalizationLanguage("/viewQuote/request-a-delivery")
  const { alldata, UpdateAlldata } = useContext(SenderContext);
  const [Cart, SetCart] = useState()
  const [showRequestType, setshowRequestType] = useState(false);
  const [accountInfo, setAccountInfo] = useState()
  
  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()
  const { visitingCountryCode } = useContext(ConnContext);

  useEffect(() => {
      setCountry(Cookies.get('i18next'));
    },[Cookies.get('i18next')])

  const [country,setCountry]=useState(countryvalue);

  const ls = new SecureLS({ isCompression: true, encodingType: "rabbit", encryptionSecret: "dm_dillivry", });

  const endpoint = process.env.REACT_APP_API;

  useEffect(() => {
    if (alldata !== undefined) {
      if(alldata.status === 200){
      const CheckLength =  alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length
      const InfoData = alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info) : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)
      CheckLength === 1 ? setAccountInfo(InfoData[0]) : setAccountInfo(InfoData[1])
      SetCart(alldata?.data?.data?.deliveries ?   alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map((x) => Object.values(x?.account))?.flat()?.map(x => x?.cart_history?.deliveries)?.flat() : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map((x) => Object.values(x?.account))?.flat()?.map(x => x?.cart_history?.deliveries)?.flat())
    }
    }
    else{
      axios
        .get(`${endpoint}m-dillivry-sender-api`, {
          headers: {
            Authorization: ls.get("_utk"),
          },
        })
        .then((res) => {
          UpdateAlldata(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
}, [alldata])

  const history = useHistory()
  

  return (
    <div className="navbar-main" style={{borderBottom:'1px solid #C7E6C5', padding:'5px 0'}}>
      <div className="nav-business-details" style={{backgroundColor:'white'}}>
        <div onClick={()=> history.push("/viewQuote")}   className="nav-img-logo" >
          <img src={dillivrylogo} alt="USER-ICON" />
        </div>
      </div>

      <div onClick={backTo} style={{fontSize:'20px', fontWeight:'600', color:'var(--main)', userSelect:'none', cursor:'pointer'}}>
        {backToText}
      </div>
      <div style={{width:'300px'}}>
        {/* <SearchBar/> */}
      </div>

      <div className="cart-request-question">

        <div className="request-btn">
          <button onClick={()=>setshowRequestType(!showRequestType)} className="request-main-btn">Request a delivery</button>
        </div>

        <div className="cart-icon" onClick={()=>history.push(viewQuote_myCart)}>
          {
            Cart?.length > 0 ? 
            <div className="cart-icon-badge">
              {Cart?.length}
            </div>
            :
            null
          }
          
          <img src={shopping} alt="shoppingcart" />
        </div>
        {/* <div className="messageiconquestion">
          <img
            src={carticon}
            className="icon-question"
            alt="MessageIcon"
          />
        </div> */}
      </div>

      <DialogPop
        openPopup={showRequestType}
        setopenPopup={setshowRequestType}
        spanhead={
          "Hello " +
          accountInfo?.given_name?.charAt(0).toUpperCase() +
          accountInfo?.given_name?.slice(1)
        }
        centerhead="Choose your preferred service"
      >
        <div style={{dispaly: "flex", justifyContent: "center", gap:'10px'   }}   className="instant-schedule">
          <div className="instant"    
          onClick={()=> {
            history.push({
             pathname: viewQuote_request_delivery,
            state: {
              state: `instant`
            },
          });
          setshowRequestType(!showRequestType)
        }} 
            
          >
            <div className="icon">
              <img src={Instant} alt="Instant" className="instant-icon" />
            </div>
            <div className="instant-text-subtext">
              <p className="Instant-main">Instant</p>
              <span className="instant-sub">Pickup and delivery today</span>
            </div>
          </div>

          <div className="Schedule" onClick={()=> {
             history.push({
              pathname: viewQuote_request_delivery,
             state: {
               state: `schedule`
              //  from:  'registration'
             },
           });
           setshowRequestType(!showRequestType)
          }   }  >
            <div className="icon">
              <img src={Instant} alt="Schedule" className="Schedule-icon" />
            </div>
            <div className="Schedule-text-subtext">
              <p className="Schedule-main">Schedule</p>
              <span className="Schedule-sub">
                Choose pickup and delivery date
              </span>
            </div>
          </div>
        </div>
      </DialogPop>

    </div>
  );
}
