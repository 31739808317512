import React from "react";
import "./SenderDashboard.css";
import itemIcon from "../../../assets/itemdetails.png";
import locationIcons from "../../../assets/Vector1.png";
import destinationIcon from "../../../assets/Vector3.png";
import timeFrameIcon from "../../../assets/timeframe.png";
import distanceIcon from "../../../assets/distance.png";
import listedIcon from "../../../assets/timelisted.png";
import moment from "moment";
import Currency from "../../../utils/Currency";

export default function Checkout({
  image,
  payer,
  amount,
  actamount,
  paymentStatus,
  itemDesc,
  itemLocation,
  itemDestination,
  distance,
  deliveryStatus,
  timeframe,
  timelisted,
  deliverymode,
  checkbox,
  checkboxDisabled,
  checkboxPaymentDisabled,
  onRemoveClick,
  removecheck,
  acknowledgeStatus,
  modeofpayment,
  location
}) {
  const numberWithCommas = new Intl.NumberFormat("en-GB");

  return (
    <div>
      <div className="senderquote-img-details-wrap">
        <div className="senderquote-wrap">
          <div>
            {acknowledgeStatus === "ack_requested_by_sender" 
              ? checkboxDisabled
              // :
              // modeofpayment === "pay_by_cash" || modeofpayment === "cash" ?
              // checkboxPaymentDisabled
              : checkbox
            }
          </div>

          <div
            className="quote-info-image"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        </div>

        <div className="senderquote-img-details dsktopscreen">
          <div className="senderquote-details">
            <div className="qouteinfo-left-right">
              <span className="icon-left-text-right">
                {" "}
                <span>
                  {" "}
                  <img src={itemIcon} alt="itemIcon" />{" "}
                </span>{" "}
                <span>
                  {itemDesc?.charAt(0)?.toUpperCase() +
                    itemDesc?.slice(1).replace(/_/g, " ")}
                </span>{" "}
              </span>
              <span>
                Payment type:{" "}
                <span className="item-value">
                  {" "}
                  {paymentStatus === "on_delivery"
                    ? "Pay on delivery"
                    : paymentStatus === "on_pickup"
                    ? "Pay at pickup"
                    : "Null"}{" "}
                </span>
              </span>
            </div>

            <div className="qouteinfo-left-right">
              <span className="icon-left-text-right">
                {" "}
                <span>
                  {" "}
                  <img src={locationIcons} />
                </span>{" "}
                <span>{itemLocation}</span>{" "}
              </span>

              <span>
                Provider acknowledgement:{" "}
                <span className="item-value">
                  {" "}
                  {acknowledgeStatus === "ack_requested_by_sender"
                    ? "No"
                    : "Yes"}
                </span>{" "}
              </span>
            </div>

            <div className="qouteinfo-left-right">
              <span className="icon-left-text-right">
                {" "}
                <span>
                  <img src={destinationIcon} />
                </span>
                <span>{itemDestination}</span>{" "}
              </span>
              <span>
                {amount}{" "}
                <span>
                  {" "}
                  <span className="item-value">
                    {" "}
                    <Currency location={location}/> {numberWithCommas.format(actamount)}{" "}
                  </span>{" "}
                </span>
              </span>
            </div>

            <div className="qouteinfo-left-right">
              <span className="icon-left-text-right">
                {" "}
                <span>
                  <img src={itemIcon} />
                </span>{" "}
                <span> {distance}</span>{" "}
              </span>
              <span>
                Payer:{" "}
                <span>
                  {" "}
                  <span className="item-value">
                    {payer?.charAt(0)?.toUpperCase() + payer?.slice(1)}
                  </span>{" "}
                </span>
              </span>
            </div>

            <div className="qouteinfo-left-right">
              <div className="qouteinfo-left-right">
              <span className="icon-left-text-right">
                <span>
                  <img src={timeFrameIcon} />
                </span>
                <span>{moment(timeframe)?.format("MMM Do, YYYY")}</span>
              </span>
            </div>
              <span>
                Mode of payment:{" "}
                <span>
                  {" "}
                  <span className="item-value">
                    {modeofpayment &&
                      modeofpayment?.charAt(0)?.toUpperCase() +
                        modeofpayment?.slice(1)?.replace(/_/g, " ")}
                  </span>
                </span>
              </span>
            </div>

            

            {removecheck ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="sender-delete-btn"
                onClick={onRemoveClick}
              >
                Remove
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="mobilescreen">
          <div className="main-flex">
            <div className="icon-title-">
              <div className="qouteinfo-left-right">
                <span className="icon-left-text-right">
                  {" "}
                  <span>
                    {" "}
                    <img alt="itemIcon" src={itemIcon} />{" "}
                  </span>{" "}
                  <span>{itemDesc}</span>{" "}
                </span>
              </div>

              <div className="qouteinfo-left-right">
                <span className="icon-left-text-right">
                  {" "}
                  <span>
                    {" "}
                    <img alt="locationIcons" src={locationIcons} />
                  </span>{" "}
                  <span>{itemLocation}</span>{" "}
                </span>
              </div>

              <div className="qouteinfo-left-right">
                <span className="icon-left-text-right">
                  {" "}
                  <span>
                    <img alt="destinationIcon" src={destinationIcon} />
                  </span>
                  <span>{itemDestination}</span>{" "}
                </span>
              </div>

              <div className="qouteinfo-left-right">
                <span className="icon-left-text-right">
                  {" "}
                  <span>
                    <img alt="distanceIcon" src={itemIcon} />
                  </span>{" "}
                  <span> {distance}</span>{" "}
                </span>
              </div>

              <div className="qouteinfo-left-right">
                <span className="icon-left-text-right">
                  <span>
                    <img alt="timeFrameIcon" src={timeFrameIcon} />
                  </span>{" "}
                  <span>{moment(timeframe)?.format("MMM Do, YYYY")}</span>{" "}
                </span>
              </div>

            
            </div>
          </div>

          <div className="main-flex-info">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <div>
                {paymentStatus ? (
                  <span>
                    Payment type:{" "}
                    <span className="item-value">
                      {paymentStatus === "on_delivery"
                        ? "Pay on delivery"
                        : paymentStatus === "on_pickup"
                        ? "Pay at pickup"
                        : "Null"}{" "}
                    </span>
                  </span>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <span>
                  Payer:{" "}
                  <span className="item-value">
                    {payer?.charAt(0)?.toUpperCase() + payer?.slice(1)}
                  </span>
                </span>
              </div>

              <div>
                <span>
                  Mode of Payment:{" "}
                  <span className="item-value">
                    {modeofpayment &&
                      modeofpayment?.charAt(0)?.toUpperCase() +
                        modeofpayment?.slice(1)?.replace(/_/g, " ")}
                  </span>
                </span>
              </div>

              <div>
                <span>
                  Provider acknowledgement:{" "}
                  <span className="item-value">
                    {acknowledgeStatus === "ack_requested_by_sender"
                      ? "No"
                      : "Yes"}
                  </span>
                </span>
              </div>

              <div>
                {actamount ? (
                  <span>
                    Quote amount:{" "}
                    <span className="item-value">
                    <Currency location={location}/>{numberWithCommas.format(actamount)}
                    </span>{" "}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="edit-delete">
              <div>
                {removecheck ? (
                  <span className="sender-delete-btn" onClick={onRemoveClick}>
                    Remove
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
