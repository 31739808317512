import React, { useState, useEffect } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "./PhoneNumber.css";
import Axios from "axios";

function PhoneNumber({ value, setValue, disabled }) {
  const [conuntryCode, setconuntryCode] = useState("");
  useEffect(() => {
    // Axios.get(
    //   "https://geolocation-db.com/json/f9902210-97f0-11eb-a459-b997d30983f1"
    // ).then((response) => {
    //   setconuntryCode(response.data.country_code);
    // // setconuntryCode("GB")
    // });
    setconuntryCode("NG");

  }, []);

  return (
    <div>
      <PhoneInput
        international
        defaultCountry={conuntryCode}
        value={value}
        onChange={setValue}
        style={{ marginBottom: "0px" }}
        disabled={disabled}
      />
      {/* <div className="err">
          {
              // value?.length === 12 && conuntryCode === "NG"   || value?.length === 13 && conuntryCode === "NG"  ?  "Invalid phone number " : 
              value && isValidPhoneNumber(value) ? "" : "Invalid phone number combination"
          }
    
      </div> */}
       <div className="err">{value && isValidPhoneNumber(value) ? '' : 'Invalid phone number combination'}</div>
      {/* {
          console.log(value?.length, conuntryCode, 'value.lengthhh')
      } */}
    </div>
  );
}

export default PhoneNumber;
