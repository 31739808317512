/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./../../Dashboard/Dashboard.css";
import axios from "axios";
import Swal from "sweetalert2";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import SecureLS from "secure-ls";

import './../../Dashboard/CorporateDashboard.css'
import GlobalizationLanguage, {GlobalizationLanguagewithCOntryCode} from "../../../../components/GlobalizationLanguage";
import { AuthContext } from "../../../../context/AuthContext";
import { DashboardContext } from "../../../../context/Dashboard/DashboardProvider/dashboard.provider";
import MobileCorpLayout from "../CorporateComponents/MobileCorpLayout";
import DashBottomBtn from "../DashBottomBtn/DashBottomBtn";
import CorporateLayout from "../CorporateLayout";
import ReactTooltip from 'react-tooltip';
import { IconContext } from "react-icons";
import { FaShuttleVan } from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md';
import MUTable from "../../../../components/MUTable";
import { ConnContext } from "../../../../context/ConnContext";
import Cookies from "js-cookie";

function WatchList() {
    const numberWithCommas = new Intl.NumberFormat("en-GB")

    const { visitingCountryCode } = useContext(ConnContext);

    var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

    useEffect(() => {
        setCountry(Cookies.get('i18next'));
        },[Cookies.get('i18next')])

    const [country,setCountry]=useState(countryvalue);


  var login = GlobalizationLanguage("/login")
  
 const [accountProfile, setAccountProfile] = useState([])

  const {
    dashboards,
    updateDashboard,
  } = useContext(DashboardContext);

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const [role, setRole] = useState("");
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [accountStatus, setaccountStatus] = useState("");

  const [watchlist, setwatchlist] = useState([]);

  const [loadingData, setloadingData] = useState(false);

  const endpoint = process.env.REACT_APP_API;

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
    window.history.replaceState(null, "");
  };



  const updateDashdata = (res) => {

     setAccountProfile(
        res?.data?.account_profile?.account_profile
      );
      setwatchlist(
        res?.data?.deliveries_iam_watching
      )
  };


  useEffect(() => {
    if (dashboards !== undefined) {
      if (
        ls.get("_iRl") === "" ||
        ls.get("_iRl") === "dillivry-sole-trader-ops"
      ) {
        history.push(login);
      } else {
        setRole(ls.get("_iRl"));
        updateDashdata(dashboards)
      }
    }
    else {
      if (
        ls.get("_iRl") === "" ||
        ls.get("_iRl") === "dillivry-sole-trader-ops"
      ) {
        history.push(login);
      } else {
        setloadingData(true);
        setRole(ls.get("_iRl"));
        axios
          .get(`${endpoint}m-dillivry-dashboard-manager`, {
            headers: {
              Authorization: ls.get("_nodi"),
            },
          })
          .then((response) => {
            updateDashboard(response);
            updateDashdata(response)
            setloadingData(false);
          })
          .catch((error) => {
            setloadingData(false);
             if (error?.response?.status === 403) {
              Swal.fire({
                icon: "warning",
                title: "Session!",
                text: "Session expired login again!",
                allowOutsideClick: false,
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  auth.logout();
                  history.push(login);
                }
              });
            } 
            else {
            console.log(error)
            }
          });
      }
    }
  }, []);

  const Hellobanner=({name,amount})=>{
    return(
    <div className="hellbanner">
        <div className="hellbannerTop">
            <h2>Hello {name}</h2>
            {
              accountProfile[0]?.wallet_balance ?

              <span> &#8358;{numberWithCommas.format(parseFloat(amount)?.toFixed(2))}</span>
              :
              <span className="helloright"> &#8358; 00.00</span>

            }
           
            
        </div>
        <div className="hellbannerBottom">
            <span className="helloleft">More delivery, more money</span>
            <span className="helloright">Wallet balance</span>
        </div>

    </div>
    )
}
  return (
    <>
        <div className="dashboard">

          <div className="dashboardDesktop">
           
            {/* {state === "dashboard" ? ( */}
            <CorporateLayout>
            <>
                {loadingData ? (
                <div
                    style={{
                    width: "100%",
                    height: "100vh",
                    backgroundColor: "none",
                    zIndex: "700",
                    position: "fixed",
                    top: "40",
                    padding: "0",
                    }}
                >
                    <Box sx={{ width: "100%" }}>
                    <LinearProgress color="success" />
                    </Box>
                </div>
                ) : null}
                
                <Hellobanner  
                name={accountProfile[0]?.account_holder?.first_name}
                amount={accountProfile[0]?.wallet_balance}
                />
                 <MUTable style={{width:'100%'}}
                    title=""
                    columns= {  
                        [
                            { title: 'Image', field: 'image_urls[0]',   width:'100px', render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]}/> </div> },
                            { title: 'Item description',  field: 'item_desc', render: rowData => <p>{rowData.item_desc.charAt(0)?.toUpperCase() + rowData.item_desc.toLowerCase().slice(1)}</p> },
                            { title: 'Pickup', field: `from`, render: rowData => <span>{rowData?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                            { title: 'Destination', field: 'to', render: rowData => <span>{rowData?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                            { title: 'Status', field: 'quote', render: rowData =>  <span>Awaiting quote</span>},    
                            { title: 'No. quotes', field: 'quote', render: rowData => <span>{rowData.quote}</span> },
                        ]
                    }
                    data={watchlist}
                    onRowClick={(event, rowData) => { history.push( GlobalizationLanguagewithCOntryCode(`/open-delivery/${rowData.delivery_id}` , country, visitingCountryCode))}}
                    options={{
                        showEmptyDataSourceMessage : false,
                        exportButton: true,
                        actionsColumnIndex: -1,
                        headerStyle:{
                            fontSize:'13px',
                            fontWeight:'600',
                            backgroundColor:'white'
                            },
                                rowStyle: rowData => ({
                                    fontSize:'12px',
                                    fontWeight:'500',
                            })
                        }}
                />
            </>
            </CorporateLayout>
          </div>
          {/* desktop state ends here  */}


          {/* mobile ui design start here */}
          <div className="dashboardMobile">

          <MobileCorpLayout pageTitle="Dashboard" />
              
            {loadingData ? (
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  backgroundColor: "none",
                  zIndex: "300",
                  position: "fixed",
                  top: "0",
                  // paddingTop: "70px",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <LinearProgress color="success" />
                </Box>
              </div>
            ) : null}
            <div
              className="dashboardBody"
              style={{
                backgroundColor: "whitesmoke",
              }}
            >

              <div className="mobilecorp_gen">
                <Hellobanner  
                  name={accountProfile[0]?.account_holder?.first_name}
                  amount={accountProfile[0]?.wallet_balance}
                />
                {
                    watchlist.map((item, i)=>(
                        <div key={item.delivery_id} className="quoteCard">
                                <div className="quoteBody">
                                    <div className="quoteBodyDetails">
                                        <div style={{ display: 'flex', gap: '5px' }}>
                                            <div className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${item.image_urls[0]})` }}
                                                onClick={() => {  history.push( GlobalizationLanguagewithCOntryCode(`/open-delivery/${item.delivery_id}` , country, visitingCountryCode)) }}>
                                            </div>
                                            <div className="quoteBodyDetailsLeft">
                                                <div className="quoteBody_Desc">
                                                    <span onClick={() => {  history.push( GlobalizationLanguagewithCOntryCode(`/open-delivery/${item.delivery_id}` , country, visitingCountryCode)) }}>
                                                        {item.item_desc.charAt(0)?.toUpperCase() + item.item_desc.toLowerCase().slice(1)}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="quoteBodyBtn">
                                        <div>
                                        <div className="quoteBody_items">
                                            <span data-tip
                                                data-for="globalMode">

                                                <IconContext.Provider
                                                    value={{
                                                        color: "#2e2e2e",

                                                    }}
                                                >
                                                    {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                    <FaShuttleVan color="green" size='12px' />
                                                </IconContext.Provider>
                                                <ReactTooltip
                                                    id="globalMode"
                                                    aria-haspopup="true"
                                                    role="example"
                                                    type="light"
                                                >
                                                    <p>Mode of Delivery</p>
                                                </ReactTooltip>
                                            </span>
                                            <div>{item?.mode_of_delivery?.charAt(0)?.toUpperCase() + item?.mode_of_delivery?.toLowerCase().slice(1)}</div>
                                        </div>
                                        <div className="quoteBody_items">
                                            <span data-tip
                                                data-for="globaldelibery">

                                                <IconContext.Provider
                                                    value={{
                                                        color: "#2e2e2e",

                                                    }}
                                                >
                                                    {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                    <MdLocationOn color="green" size='12px' />
                                                </IconContext.Provider>
                                                <ReactTooltip
                                                    id="globaldelibery"
                                                    aria-haspopup="true"
                                                    role="example"
                                                    type="light"
                                                >
                                                    <p>PickUp Address</p>
                                                </ReactTooltip>
                                            </span>

                                            <div>{item?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                        </div>
                                        <div className="quoteBody_items">
                                            <span data-tip
                                                data-for="globaldistance">
                                                <IconContext.Provider
                                                    value={{
                                                        color: "#2e2e2e",

                                                    }}
                                                >
                                                    <MdLocationOn color="red" size='12px' />
                                                </IconContext.Provider>
                                                <ReactTooltip
                                                    id="globaldistance"
                                                    aria-haspopup="true"
                                                    role="example"
                                                    type="light"
                                                >
                                                    <p>Delivery Address </p>
                                                </ReactTooltip>
                                            </span>
                                            <div>{item?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                        </div>
                                        <div className="quoteBody_items statusCheck" > Status: <span style={{color:'#004DC1'}}> Awaiting quote</span> </div>
                                        <div className="quoteBody_items statusCheck" > No. quote: <span style={{color:'#004DC1'}}>{item?.quote} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))
                }
                </div>
              
              <DashBottomBtn activeTab="dashboard" />
            </div>
          </div>
        </div>
    </>
  );
}
export default WatchList;
