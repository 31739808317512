import React from 'react'

function ButtonCard({onClick, text}) {
    return (
        <div 
            style={{
                width:'300px', 
                height:'50px',
                marginBottom:'20px', 
                borderRadius:'5px', 
                display:'flex', 
                alignItems:'center', 
                justifyContent:'center', 
                background:'var(--lighest)', 
                color: 'var(--darkest)',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            {text}
        </div>
    )
}
export default ButtonCard
