import React, {useContext} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useState} from 'react';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import { createBrowserHistory } from "history";
import { Link } from 'react-router-dom';
import {ConnContext} from '../context/ConnContext';

const { pathToRegexp, compile } = require("path-to-regexp");
const flags=[
  {
    name:"Canada",
    countryCode:"ca"
  },{
    name:"Ghana",
    countryCode:"gh"
  },{
    name:"Kenya",
    countryCode:"ke"
  },{
    name:"Nigeria",
    countryCode:"ng"
  },{
    name:"Rwanda",
    countryCode:"rw"
  },{
    name:"South Africa",
    countryCode:"za"
  },{
    name:"United Kingdom",
    countryCode:"gb"
  },{
    name:"USA",
    countryCode:"us"
  },
]
const Dropdowncountries= ({ match, location }) => {

  const {visitingCountryCode} = useContext(ConnContext)
  const country = Cookies.get('i18next') || visitingCountryCode
  const history = createBrowserHistory();
  const [countryCode, setcountryCode] = useState(country);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const generateLanguage = (locale, location) => {
    const ROUTE = "/:locale/:path*";
    const definePath = compile(ROUTE);
    const routeComponents = pathToRegexp(ROUTE).exec(location.pathname);

    // console.log(definePath, routeComponents, "incoming", history)
  
    let subPaths = null;
    if (routeComponents) {
      subPaths = routeComponents[2]!==null&&routeComponents[2]!==undefined?routeComponents[2].split("/"):routeComponents[1];
    }
    var i = flags.findIndex(item=>item.countryCode===subPaths)

    if(locale === `${visitingCountryCode.toLowerCase()}`){
      var ROUTE2 = "/:path*";
      if(i!==-1){
        ROUTE2=""
        const definePath2 = compile(ROUTE2);
      return definePath2({
        path: subPaths
      });
      }
      const definePath2 = compile(ROUTE2);
      return definePath2({
        path: subPaths
      });
    }
    else if(i!==-1){
      var ROUTE3="/:locale"
      const definePath2 = compile(ROUTE3);
      return definePath2({
        locale
      })
    }
    else{
    return definePath({
      locale,
      path: subPaths
    });
  }
  };


  const handleClose = (value) => {
      i18next.changeLanguage(value)
    setcountryCode(value);
    setAnchorEl(null);
  };

    return (
      <>
        <span
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <span className={`flag-icon flag-icon-${Cookies.get('i18next')}`}></span>
        </span>
        
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose(countryCode)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          
        >
          <div style={{display: 'flex', flexDirection: "column", padding:'10px', gap:'2px' }}>    
            {flags.map((item,key)=>{
              return(
              <Link  key={key} to={generateLanguage(item.countryCode, history.location)}>
                <MenuItem style={{width:'100%', justifyContent:'flex-start', padding:'4px'}} key={key} onClick={() => handleClose(item.countryCode)}>
                  <span className={`flag-icon flag-icon-${item.countryCode}`}></span>
                  <span style={{ marginLeft: "10px" }}>{item.name}</span>
                </MenuItem>
            </Link>)
            })}
          </div>
        </Menu>
      </>
    );
   
}

export default Dropdowncountries