import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
//  import "./Style.css";
// import Products from "./Products";
// import {product} from './product'
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import SecureLS from "secure-ls";
import qs from "qs";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
// import CompletePage from "./CompletePage";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(
  "pk_test_51LNh8MElaIpJa6DUpJn3RtBkk1AOmgmwcs5TEcZBhaDhdDroyXcKagIyZuMCQkqJ4JVQIPGtYDIqrassQtZEYm0c00RWfKj6hm"
);

export default function App({
  total,
  amount_paid,
  item,
  quote_amount,
  state,
  promocode,
  discount,
  transaction,
}) {
  const [clientSecret, setClientSecret] = useState("");

  const [selectedProduct, setSelectedProduct] = useState(null);

  const location = useLocation();

  // const total = 3000

  var data = qs.stringify({
    amount: `${parseInt(total) * 100}`,
    currency: "GBP",
    //    'payment_method_types': ['card'],
    automatic_payment_methods: {
      enabled: true,
    },
  });

  var config = {
    method: "post",
    url: "https://api.stripe.com/v1/payment_intents",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded)",
      Authorization:
        "Bearer sk_test_51LNh8MElaIpJa6DU6eCzDrspaMeNqM88z2AGoCJ6rH6nQhRc4lYeRu0tDAVkPMKywgedxBMzRdXiTaxpdNbvvyEc00TIlvhaAo",
    },
    data: data,
  };

  useEffect(() => {

    // console.log(total, 'totallllll')
    // console.log(location, "location app");
    // console.log(state, "state---");
    // console.log(item, "itemmmm");
    // console.log(parseInt(location?.amountPaid) * 100);
    // console.log(parseInt(location?.amountPaid) / 100);

    axios(config)
      .then(function (response) {
        // console.log(response);
        setClientSecret(response?.data?.client_secret);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [total]);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            quote_amount={quote_amount}
            promocode={promocode}
            discount={discount}
            transaction={transaction}
            state={state}
            item={item}
            amount_paid={amount_paid}
          />
        </Elements>
      )}
    </>
  );
}
