import React from 'react'
import './DownloadHero.css'
import Appstore from '../../../assets/IOS_App.svg'
import Playstore from '../../../assets/Google_App.svg'
import phonelogo from "../../../assets/phone.png"

export default function DownloadHero({bg, color}) {
  return (
    <div className='DownloadHero' style={{background: bg}}>
        <div className="home_max_width">
            <div className="DownloadHeroCont">
                <div className="leftDownload">
                    <h1 style={{color: color}}>Ready to request delivery?</h1>
                    <div className='downloadImg'>
                        <a href="https://apps.apple.com/us/app/dillivry/id1604161862"><img src={Appstore} alt="Dillivry Appstore"/></a>
                        <a href="https://play.google.com/store/apps/details?id=datamellon.app_mobile"><img src={Playstore} alt="Dillivry Playstore" /></a>
                    </div>
                    <h6 style={{color: color}}> Or sign up as a provider.</h6>
                </div>
                <div className="rightDownload">
                    <img src={phonelogo} alt="Dillivry" />
                </div>
            </div>
        </div>
        
    </div>
  )
}
