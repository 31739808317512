import React, { useState, useEffect, useContext } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { SenderContext } from "../../../context/SenderDashboard/SenderDashboardContext";

const drawerWidth = 320;

export default function Layout({children}) {

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        zindex="1000"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        style={{
            backgroundColor: "#ffffff",
            color: "black",
            boxShadow: "0px 0px 0px 0px",
            borderBottom: "1px solid  #C7E6C5",
          }}>
         <>
          <Navbar />
        </>
      </AppBar>
      

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
     <div className="sidebardiv"  >
      <Toolbar />
      <Sidebar />
      <Divider />
    </div>
      
    </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
          {children}
      </Box>
    </Box>
  );
}