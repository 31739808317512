import React, { useEffect, useContext, useState } from 'react';

import { useHistory } from 'react-router-dom'


import { AuthContext } from '../../../context/AuthContext';
import SecureLS from 'secure-ls';
import { SenderContext } from '../../../context/SenderDashboard/SenderDashboardContext';
import axios from "axios";

import "../SendQuote.css";

import { BiDotsVerticalRounded } from "react-icons/bi";

import Swal from "sweetalert2";

import Nav from "../../../components/Nav/NavHome";

import { FaCarSide } from "react-icons/fa";
import { WiTime4 } from "react-icons/wi";
import { GrMail } from "react-icons/gr";
import { BsFillPersonFill } from "react-icons/bs";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

// import PaystackWidget from '../../../components/PaystackWidget';

import { useParams } from "react-router-dom";
import IndividualSidebar from "../../../components/Sidebar/IndividualSidebar";
import imagelogo from "../../../assets/Dillivry.png";

import { useLocation } from "react-router-dom";
import DialogOutClick from '../../../components/DialogOutClick';
import { ConnContext } from '../../../context/ConnContext';
import GlobalizationLanguage from '../../../components/GlobalizationLanguage';


export default function SenderAcct({user_info}) {
  var sendermessage = GlobalizationLanguage("/sendermessage")
  var timeout = GlobalizationLanguage("/timeout")
  var acctLink = GlobalizationLanguage("/acct")
  const forgetpass=GlobalizationLanguage("/forgetpass")
  var linkUrl = GlobalizationLanguage("/5156886826018256")

    const {initialstate, UpdateData, Updatequote} = useContext(SenderContext)
    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
    const {visitingCountryCode} = useContext(ConnContext)

    const auth = useContext(AuthContext);
    const [passwordResetted, setpasswordResetted] = useState(false)
    const [fullname, setFullname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const [showLogout, setShowLogout] = useState(false)

    const history = useHistory();

    const logout = () => {
        ls.removeAll()

        history.push('/');
    }
    const [isloading, setIsloading] = useState(false);

    useEffect(() => {
        setIsloading(true)

        if (initialstate !== undefined) {
            setIsloading(false)
            // console.log(initialstate?.data?.account?.account_info, 'initial-state')
            setFullname(initialstate?.data?.account?.account_info?.sender_name)
            setEmail(initialstate?.data?.account?.account_info?.sender_email)
            setPhone(initialstate?.data?.account?.account_info?.sender_phone_no?.phone_number)
            if (ls.get('auth_status') === '') {
                setpasswordResetted(true)
            }
            else {
                setpasswordResetted(false)
            }

        }else{
            setIsloading(true)
            axios
            .get(`${process.env.REACT_APP_API}m-dillivry-search`, {
              search_criteria: "sender_data",
              user_id: ls.get("eid"),
              vcc: visitingCountryCode
            }, {timeout: 240000})
            .then((response) => {
            if (response?.status === 200) {
                setIsloading(false)
                    setFullname(response?.data?.account?.account_info?.sender_name)
                    setEmail(response?.data?.account?.account_info?.sender_email)
                    setPhone(response?.data?.account?.account_info?.sender_phone_no?.phone_number)
             UpdateData(response)
}
            }).catch((error) => {
              if (error.code === "ECONNABORTED") {
                console.log('internal-server-error-timeout')
                history.push(timeout)
                // window.location.reload()
              }
            });

        }

        if (ls.get('auth_status') === '') {
            setpasswordResetted(true)
        }
        else {
            setpasswordResetted(false)
        }
    }, [])

    

    const logMeOut = ()=>{
        auth.logout();
    } 

 
   





   
    
      window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      };
      const { type, id } = useParams();
      const [showHelp, setshowHelp] = useState(false);
      const [itemLength, setitemLength] = useState(0);
      const [deefault, setDeefault] = useState([]);

      const location = useLocation();
      const [sendingMsg, setsendingMsg] = useState(false);
    
      const [paginateActivePage, setPaginateActivePage] = useState(1);
      const n = 5;
      const [clicked, setclicked] = useState("awaitingpickup");
    
      const setexpire = ()=>{
        setclicked("expire")
      }
    
      const [functionToCall, createFunctionToCall] = useState(() => () => {});
      const [functionToCall1, createFunctionToCall1] = useState(() => () => {});
      const [functionToCall2, createFunctionToCall2] = useState(() => () => {});
    
      const [loading, setloading] = useState();
    
      const [data, setData] = useState("");
      const [complete, setcomplete] = useState("complete")
      const [active, setactive] = useState("active")
    
      const [showDeletinfo, setshowDeletinfo] = useState();
      const [showEditinfo, setshowEditinfo] = useState();
      const [deliveryidpicked, setdeliveryidpicked] = useState("");
      const [showlogin, setshowlogin] = useState(false);
      const childToParent = () => {};


    
      const showMe = (type, item)=>{
        history.push(`/activedeliveries/${type}/${item}`)
        }
    
    
    const showMessageurl = (type, item)=> {
      history.push(`/message_sender`)
    }
    
    const pastdilvryurl = (type)=>{
      history.push(`/pastdelivery//${complete}`)
    }
    
    const shownewactive = (type) =>{
      history.push(`/activedilivry//${active}`)
    }
    
    const activedilivryurl = (type)=>{
      history.push(`/activedlvry/${type}`)
    }
    
    const showPstdilivyUrl = (type, item) => {
      history.push(`/pastdeliveries/${type}/${item}`)
    } 
    

      window.onbeforeunload = function () {
        window.scrollTo(0, 0);
        window.history.replaceState(null, "");
      };



      const [pastData, setpastData] = useState([]);
      const [showing, setshowing] = useState("complete");
      const [display, setdisplay] = useState("past");
      const [outshow, setoutshow] = useState("miss");
    
      const [activeDeliveries, setActiveDeliveries] = useState([]);
      const [allActive, setallActive] = useState([]);
      const [awaiting_quotes, setawaiting_quotes] = useState([]);
      const [awaiting_pickup, setawaiting_pickup] = useState([]);
      const [awaiting_agent, setawaiting_agent] = useState([]);
      const [out_for_delivery, setout_for_delivery] = useState([]);
      const [feedback, setfeedback] = useState([]);
      const [message, setMessage] = useState([]);
      const [account, setAccount] = useState([]);
      const [numberActive, setNumberActive] = useState(0);
      const [numberPast, setNumberPast] = useState(0);
      const [numberMessage, setNumberMessage] = useState(0);
      const [dataToShow, setdataToShow] = useState([]);
      const [activeState, setActiveState] = useState(false);
      const [pastState, setPastState] = useState(false);
      const [messageState, setMessageState] = useState(false);
      const [quoteMessage, setQuoteMessage] = useState([]);
      const [accountState, setAccountState] = useState(true);
      const [quotesState, setquotesState] = useState(false);
      const [viewState, setviewState] = useState(false);
      const [editState, seteditState] = useState(false);
      const [delivery, setdelivery] = useState({});
      const [activeStatus, setactiveStatus] = useState("awaiting_pickup");
   
      const [lines, setlines] = useState(true);
      const [Quote, setQuote] = useState({});
      const [showdrop, setShowdrop] = useState(false);
      const [currentId, setcurrentId] = useState(null);
    
      const handleClick = (id) => {
        setShowdrop((showdrop) => !showdrop);
        setcurrentId(id);
      };
    
      // const handleChange = (panel) => (event, isExpanded) => {
      //     setExpanded(isExpanded ? panel : false);
      // };
    
      const [pastDeliveries, setPastDeliveries] = useState([]);
      const [expiredDeliveries, setExpiredDeliveries] = useState([]);
      const [missedDeliveries, setmissedDeliveries] = useState([]);
    
      const [showDeleting, setshowDeleting] = useState(false);
      const [deleting, setdeleting] = useState(false);
      const [itemToDelete, setitemToDelete] = useState([]);
      const [labelDelete, setlabelDelete] = useState([]);
      const [quoteRecieved, setquoteRecieved] = useState([]);
    
      const [quoteHistory, setquoteHistory] = useState([]);
    
      const [value, setValue] = useState();
    
    
      const refresh = () => {
       
        setValue({});
      };
      const [showdropmobile, setshowdropmobile] = useState(false);
      const clickdropmobile = () => {
        setshowdropmobile((showdropmobile) => !showdropmobile);
      };
      const [expanded, setExpanded] = React.useState(false);
      const handleChange = () => {
        setviewState(false);
        setActiveState(true);
      };
      const handleGoBack = () => {
        seteditState(false);
        setActiveState(true);
      };
    
      const showActive = () => {
        setActiveState(true);
        setPastState(false);
        setMessageState(false);
        setAccountState(false);
        setquotesState(false);
        setviewState(false);
        seteditState(false);
      };
      const showPast = () => {
        setActiveState(false);
        setPastState(true);
        setMessageState(false);
        setAccountState(false);
        setquotesState(false);
        setviewState(false);
        seteditState(false);
      };
      const showMessage = () => {
        setActiveState(false);
        setPastState(false);
        setMessageState(true);
        setAccountState(false);
        setquotesState(false);
        setviewState(false);
        seteditState(false);
      };
      const showAccount = () => {
        setActiveState(false);
        setPastState(false);
        setMessageState(false);
        setAccountState(true);
        setquotesState(false);
        setviewState(false);
        seteditState(false);
      };
    
      const endPoint = process.env.REACT_APP_API;
    
      const authAxios = axios.create({
        baseURL: `${endPoint}`,
        headers: {
          Authorization: ls.get("_utk"),
        },
      });
      const deleteItem = (e) => {
        e.preventDefault();
    
        setdeleting(true);
    
        authAxios
          .post(`m-dillivry-listing`, {
            action_type: "delete",
            delivery_id: itemToDelete.delivery_id,
            sender_email: ls.get("eid"),
            vcc: visitingCountryCode
          }, {timeout: 240000})
          .then((res) => {
            setshowDeleting(false);
            setdeleting(false);
            // setsendingMsg(false)
            Swal.fire({
              title: "Deleted",
              text: "Item deleted!",
              icon: "success",
              confirmButtonColor: "var(--main)",
              showConfirmButton: false,
              timer: 2000,
            });
            history.go(0);
          })
          .catch((err) => {
            if (err.code === "ECONNABORTED") {
              console.log('internal-server-error-timeout')
              history.push(timeout)
              // window.location.reload()
            }
            setshowDeleting(false);
            setdeleting(false);
            Swal.fire({
              icon: "error",
              text: "error!",
              timer: 2000,
              showConfirmButton: false,
            });
            // console.log(err)
          });
      };
    
      // implemented on the quote click event
      const showQuote = () => {
        setActiveState(false);
        setPastState(false);
        setMessageState(false);
        setAccountState(false);
        setquotesState(true);
        setviewState(false);
        seteditState(false);
      };
      const showView = () => {
        setActiveState(false);
        setPastState(false);
        setMessageState(false);
        setAccountState(false);
        setquotesState(false);
        setviewState(true);
        seteditState(false);
      };
      const showEdit = () => {
        setActiveState(false);
        setPastState(false);
        setMessageState(false);
        setAccountState(false);
        setquotesState(false);
        setviewState(false);
        seteditState(true);
      };
     
      const [defaultdata, setdefaultdata] = useState([]);
    
    

    return (
    <div>
        <div className="page">
        <div className="topmenu showTopMenuDesktop">
          {isloading ? (
            <>
              <Box
                sx={{ width: "100%", marginBottom: "10px", marginTop: "4px" }}
              >
                <LinearProgress color="success" />
              </Box>
            </>
          ) : (
            <></>
          )}
          <Nav />
        </div>
        <div className="ind-nav">
          <IndividualSidebar />
          <div className="logocont">
            <img
              src={imagelogo}
              alt="dillivry"
              onClick={() => history.push("/")}
            />
          </div>
        </div>

        <div className="viewmenudesktop">
          {viewState ? null : (
            <div className="view_menu">
              <div
                className={activeState ? "activeTab" : "tabs"}
                onClick={() => {
                   showActive();
                  // refresh();
                  // activedilivryurl()
                  //  history.push("/activedlvry")
                  shownewactive()
                }}
              >
                {" "}
                <span>
                  <FaCarSide />
                </span>
                &ensp;Active Deliveries 
              </div>
              <div
                className={pastState ? "activeTab" : " tabs"}
                onClick={() => {
                  //  showPast();
                   pastdilvryurl()
                  // refresh();
                  //  history.push("/pastdelivery")
           
                }}
              >
                {" "}
                <span>
                  <WiTime4 />
                </span>{" "}
                &ensp; Past Deliveries 
              </div>
              <div
                className={messageState ? "activeTab" : "tabs"}
                onClick={ () => {
                //    showMessage();
                  // showMessageurl()
                  history.push(sendermessage)
                }
                }
                
              >
                {" "}
                <span>
                  <GrMail />
                </span>{" "}
                &ensp; Messages 
                {numberMessage > 0 ? (
                  <div className="mQuote">{numberMessage}</div>
                ) : null}
              </div>

              <div
                className={accountState ? "activeTab" : "tabs"}
                onClick={
                    // showAccount
                    ()=>{
                        history.push(acctLink)
                    }
                }
              >
                {" "}
                <span>
                  <BsFillPersonFill />
                </span>{" "}
                &ensp; Account
              </div>
            </div>
          )}
        </div>

        <div className="viewmenumobile">
          {viewState ? null : (
            <div className="view_menu">
              <div style={{ width: "200px" }} onClick={clickdropmobile}>
                <p>
               
                  <BiDotsVerticalRounded
                    style={{ height: "30px", width: "30px", color: "green" }}
                  />
                </p>
                {showdropmobile ? (
                  <>
                    <div
                      style={{ position: "fixed", top: "130px", zIndex: "200" }}
                    >
                      {activeState ? (
                        <div>
                          <div className="columnHead">My Dilivry</div>
                          <div
                            className="columnBody2"
                            style={{ backgroundColor: "white" }}
                          >
                            <div
                              className={
                                activeStatus === "quotes" ? "qutActive" : "qut"
                              }
                              onClick={() => {
                                setactiveStatus("quotes");
                                //  setActiveDeliveries(quoteRecieved);
                                //  setDeefault(quoteRecieved);
                                //  setitemLength(quoteRecieved.length);
                                //  refresh();
                                 setclicked("quotes")
                                history.push(`/activedilivry/receivedquotes`)
                              }}
                            >
                              Recieved Quotes 
                            </div>

                            <div
                              className={
                                activeStatus === "awaiting_quotes"
                                  ? "qutActive"
                                  : "qut"
                              }
                              onClick={() => {
                                setactiveStatus("awaiting_quotes");
                                // setActiveDeliveries(awaiting_quotes);
                                setDeefault(awaiting_quotes);
                                // setitemLength(awaiting_quotes.length);
                                // refresh();
                                 setclicked("awaitingquotes");
                                history.push(`/activedilivry/awaitingquote`)
                              }}
                            >
                              {" "}
                              Awaiting Quotes
                            </div>

                            <div
                              className={
                                activeStatus === "awaiting_agent"
                                  ? "qutActive"
                                  : "qut"
                              }
                              onClick={() => {
                                setactiveStatus("awaiting_agent");
                       // setActiveDeliveries(awaiting_agent);
                        setDeefault(awaiting_agent);
                       // setitemLength(awaiting_agent.length);
                       // refresh();
                       history.push(`/activedilivry/awaitingagent`)
                        setclicked("awaitingagent")
                              }}
                            >
                              {" "}
                              Awaiting Agent Assignment
                            </div>

                            <div
                              className={
                                activeStatus === "awaiting_pickup"
                                  ? "qutActive"
                                  : "qut"
                              }
                              onClick={() => {
                                setactiveStatus("awaiting_pickup");
                                //setActiveDeliveries(awaiting_pickup);
                                setDeefault(awaiting_pickup);
                                //setitemLength(awaiting_pickup.length);
                                //refresh();
        
                                history.push(`/activedilivry/awaitingpickup`)
                                setclicked("awaitingpickup")
                              }}
                            >
                              {" "}
                              Awaiting Pickup
                            </div>

                            <div
                              className={
                                activeStatus === "out_for_delivery"
                                  ? "qutActive"
                                  : "qut"
                              }
                              onClick={() => {
                                setactiveStatus("out_for_delivery");
                                // setActiveDeliveries(out_for_delivery);
                                // setDeefault(out_for_delivery);
                                // setitemLength(out_for_delivery.length);
                                refresh();
                                history.push(`/activedilivry/outfordelivery`)
                                setclicked("outfordelivery")
                              }}
                            >
                              {" "}
                              Out For Delivery
                            </div>

                            <div
                              className={
                                activeStatus === "feedback"
                                  ? "qutActive"
                                  : "qut"
                              }
                              // onClick={()=>{ setactiveStatus('feedback')
                              //     }}
                              onClick={() => history.push(linkUrl)}
                            >
                              Leave Feedback
                            </div>

                            <div
                              className="qut"
                              style={{ color: "#C54B4B" }}
                              onClick={() => {
                                setShowLogout(true);
                              }}
                            >
                              {/* onClick={logout}> */}
                              Log out
                            </div>
                          </div>
                        </div>
                      ) : pastState ? (
                        <>
                          <div className="column">
                            <div className="columnHead">My Dilivry</div>
                            <div
                              className="columnBody2"
                              style={{ backgroundColor: "white" }}
                            >
                              <div
                                className={
                                  showing === "complete" ? "qutActive" : "qut"
                                }
                                style={{ paddingLeft: "18px" }}
                                 onClick={functionToCall}
                              

                              >
                                {" "}
                                Complete Deliveries 2
                              </div>
                              <div
                                className={
                                  showing === "expire" ? "qutActive" : "qut"
                                }
                                style={{ paddingLeft: "18px" }}
                                onClick={functionToCall1}
                              >
                                {" "}
                                Expired Deliveries
                              </div> 
                              <div
                                className={
                                  showing === "missed" ? "qutActive" : "qut"
                                }
                                style={{ paddingLeft: "18px" }}
                                //  onClick={functionToCall2}
                                onClick={()=> {  history.push("/pastdelivery/missed")}}
                              >
                                {" "}
                                Missed Deliveries{" "}
                              </div>
                              <div
                                className="qut"
                                style={{ paddingLeft: "18px" }}
                                onClick={() =>
                                  history.push(linkUrl)
                                }
                              >
                                {" "}
                                Leave Feedback
                              </div>
                              <div
                                className="qut"
                                style={{
                                  color: "#C54B4B",
                                  paddingLeft: "18px",
                                }}
                                onClick={() => {
                                  setShowLogout(true);
                                }}
                              >
                                Log out
                              </div>
                            </div>
                          </div>
                        </>
                      ) : messageState ? null : accountState ? null : null}
                    </div>
                  </>
                ) : null}
              </div>

              <div
                className={activeState ? "activeTab" : "tabs"}
                onClick={() => {
                  // showActive();
                  // refresh();
                  history.push(`/activedilivry//active`);
                }}
              >
                {" "}
                <span>
                  <FaCarSide />
                </span>
              </div>
              <div
                className={pastState ? "activeTab" : "tabs"}
                onClick={() => {
                  history.push(`/pastdelivery//${complete}`)
                  // showPast();
                  // refresh();
                }}
              >
                {" "}
                <span>
                  <WiTime4 />
                </span>
              </div>
              <div
                className={messageState ? "activeTab" : "tabs"}
                // onClick={showMessage}
                onClick={()=>  history.push(sendermessage)}
              >
                {" "}
                <span>
                  <GrMail />
                </span>{" "}
                &ensp;
                {numberMessage > 0 ? (
                  <div className="mQuote">{numberMessage}</div>
                ) : null}
              </div>

              <div
                className={accountState ? "activeTab" : "tabs"}
                // onClick={showAccount}
                onClick={()=>  history.push(acctLink)}
              >
                {" "}
                <span>
                  <BsFillPersonFill />
                </span>
              </div>
            </div>
          )}
        </div>




        <div className="context-past">
        

        <div className="context-past-right">
            <div className="column">
                <div className="acchead">
                    <div className="accheadtextright"> Personal Info</div>
                    <div className="accheadtextleft">
                        <button className="accheadtextleft"
                        onClick={() => {
                            setShowLogout(true)
                         }}>
                        
                            Log out
                        </button>
                    </div>
                </div>
                <div className="accbod">
                    <div className="accbodinformation">
                        <div className="inforbd1">Name:</div>
                        <div className="inforbd2">{fullname}</div>
                        <div className="inforbd3"></div>
                    </div>
                    <div className="accbodinformation">
                        <div className="inforbd1">Email</div>
                        <div className="inforbd2">{email}</div>
                        <div className="inforbd3"></div>
                    </div>
                    <div className="accbodinformation">
                        <div className="inforbd1">Phone</div>
                        <div className="inforbd2">{phone}</div>
                    </div>
                </div>
                <div className="acclic" onClick={() => history.push(forgetpass)} style={{ cursor: "pointer" }}>
                    <a className="acclic" >Need to reset your password? </a> </div>
        
              
            </div>
        
        
            <DialogOutClick
            open={showLogout}
            handleClose ={()=>setShowLogout(false)}
            > 
            <div style={{width:'100%',padding:"30px 30px 20px 30px"}}>
                <div style={{width:'100%',marginBottom:"30px", fontSize:'13px', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center' }}>
                    <span>Are you sure you want to logout?</span>
                </div>
                <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
                    <div className="disBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'#FFFFFF', backgroundColor:'#50B14A', border:'1px solid #50B14A'}}
                        onClick={()=>setShowLogout(false)} >
                        No
                    </div>
                    <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'#FFFFFF', backgroundColor:'#808080', border:'1px solid #808080' }}
                        // onClick={logMeOut}>
                             onClick={logout}>
                        Yes
                    </div>
                </div>
            </div>
        </DialogOutClick>
        </div>
        </div>




        </div>

    </div>
    )
}
