import React, { useState, useEffect, useContext, useRef } from "react";
import userAvatar from "../../../assets/userAvatar.jpeg";
import carticon from "../../../assets/carticon.svg";
import shopping from "../../../assets/shopping.svg";
import "./Navbar.css";
import { useHistory, useLocation } from "react-router-dom";
import DialogOutClick from "../../../components/DialogOutClick";
import { AuthContext } from "../../../context/AuthContext";
import DialogPop from "../../../components/DialogPop";
import Instant from "../../../assets/Instant.svg";
import axios from 'axios';
import SecureLS from "secure-ls";

import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
import { SenderContext } from "../../../context/SenderDashboard/SenderDashboardContext";

export default function Navbar() {
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
});
  var sendermessage = GlobalizationLanguage("/sendermessage")
  var viewQuote_myCart = GlobalizationLanguage("/viewQuote/myCart")
  var viewQuote_request_delivery = GlobalizationLanguage("/viewQuote/request-a-delivery")
  const endpoint = process.env.REACT_APP_API;
  
  const history = useHistory()

  const auth = useContext(AuthContext);
  const [ShowLogout, setShowLogout] = useState(false);

  const [showRequestType, setshowRequestType] = useState(false);

  const logMeOut = () => {
    auth.logout();
    history.push("/");
  };

  const [accountInfo, setAccountInfo] = useState();
  const [Cart, SetCart] = useState()
  const { alldata, UpdateAlldata } = useContext(SenderContext);
  const location = useLocation();

  useEffect(() => {
    if (alldata !== undefined) {
      if(alldata.status === 200){
      const CheckLength =  alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length
      const InfoData = alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info) : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)
      CheckLength === 1 ? setAccountInfo(InfoData[0]) : setAccountInfo(InfoData[1])
      // SetCart(alldata?.data?.data?.deliveries ?   alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map((x) => Object.values(x?.account))?.flat()?.map(x => x?.cart_history?.deliveries)?.flat() : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map((x) => Object.values(x?.account))?.flat()?.map(x => x?.cart_history?.deliveries)?.flat())
      SetCart(alldata?.data?.data?.deliveries ?   alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map((x) => Object.values(x?.account))?.flat()?.map(x => x?.cart_history?.deliveries)?.flat() : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map((x) => Object.values(x?.account))?.flat()?.map(x => x?.cart_history?.deliveries)?.flat())
    }
    }
    else{
      axios
        .get(`${endpoint}m-dillivry-sender-api`, {
          headers: {
            Authorization: ls.get("_utk"),
          },
        })
        .then((res) => {

          // setAccountInfo(res.data.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)[0])

          const CheckLength =  res?.data?.data?.deliveries ?  res?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length : res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length
          const InfoData = res?.data?.data?.deliveries ?  res?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info) : res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)
          CheckLength === 1 ? setAccountInfo(InfoData[0]) : setAccountInfo(InfoData[1])
          SetCart(res?.data?.data?.deliveries ?   res?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map((x) => Object.values(x?.account))?.flat()?.map(x => x?.cart_history?.deliveries)?.flat() : res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map((x) => Object.values(x?.account))?.flat()?.map(x => x?.cart_history?.deliveries)?.flat())
          UpdateAlldata(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
}, [])

  return (
    <>
      <div className="navbar-main">
        <div className="nav-business-details">
          {
            accountInfo?.cop_logo !== undefined || null ? (
              <img
                className="nav-img"
                alt="juk"
                src={`${accountInfo?.profile_photo}`}
              />
            ) 
            : 
            accountInfo?.profile_photo !== undefined || null ? (
            <img
              className="nav-img"
              alt="juk"
              src={`${accountInfo?.profile_photo}`}
            />
          ) : (
            <img className="nav-img" alt="juk" src={`${userAvatar}`} />
          )}

          <div>
            <div>
              {accountInfo?.given_name ? (
                <div className="nav-business-name">
                  {accountInfo?.role === "business_admin" ? (
                    <p className="nav-title">
                      {/* {accountInfo?.business_name?.charAt(0).toUpperCase() +
                        accountInfo?.business_name?.slice(1)} */}
                    </p>
                  ) : (
                    <></>
                  )}
                  <span className="business-name">
                    {/* {accountInfo?.sender_name?.charAt(0).toUpperCase() +
                  accountInfo?.sender_name?.slice(1)} */}
                    {accountInfo?.given_name}
                  </span>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div>
              <span
                style={{ userSelect: "none", color: "red", cursor: "pointer" }}
                onClick={() => setShowLogout(true)}
              >
                {" "}
                Logout
              </span>
            </div>
          </div>
        </div>
        {/* <span
                style={{ userSelect: "none", color: "red", cursor: "pointer" }}
                onClick={() => setShowLogout(true)}
              >
                {" "}
                Logout
              </span> */}

        {/* <div className="search input">
          <SearchBar />
        </div> */}

        <div className="cart-request-question">
          <div className="request-btn">
            <button
              onClick={() => {
                if (location.pathname === "/viewQuote/request-a-delivery"){
                  console.log('heloooo')
                }
                else{
                  setshowRequestType(!showRequestType)
                }
              }}
              className="request-main-btn"
            >
              Request a delivery
            </button>
          </div>

          <div
            className="cart-icon"
            onClick={() => history.push(viewQuote_myCart)}
          >
            {Cart ? (
              <div className="cart-icon-badge">{Cart.length}</div>
            ) : null}

            {/* <img src={shopingcart} alt="shoppingcart" /> */}
            <img src={shopping} alt="shoppingcart" />
          </div>
          {/* <div
            className="messageiconquestion"
            onClick={() => {
              history.push(sendermessage);
            }}
          >
            <img src={carticon} className="icon-question" alt="MessageIcon" />
          </div> */}
        </div>
      </div>

      <DialogPop
        openPopup={showRequestType}
        setopenPopup={setshowRequestType}
        spanhead={
          "Hello " +
          accountInfo?.given_name?.charAt(0).toUpperCase() +
          accountInfo?.given_name?.slice(1)
        }
        centerhead="Choose your preferred service"
      >
        <div style={{dispaly: "flex", justifyContent: "center", gap:'10px'   }}   className="instant-schedule">
          <div className="instant"    
          onClick={()=> {
            history.push({
             pathname: viewQuote_request_delivery,
            state: {
              state: `instant`
            },
          });
          setshowRequestType(!showRequestType)
        }} 
            
          >
            <div className="icon">
              <img src={Instant} alt="Instant" className="instant-icon" />
            </div>
            <div className="instant-text-subtext">
              <p className="Instant-main">Instant</p>
              <span className="instant-sub">Pickup and delivery today</span>
            </div>
          </div>

          <div className="Schedule" onClick={()=> {
             history.push({
              pathname: viewQuote_request_delivery,
             state: {
               state: `schedule`
              //  from:  'registration'
             },
           });
           setshowRequestType(!showRequestType)
          }   }  >
            <div className="icon">
              <img src={Instant} alt="Schedule" className="Schedule-icon" />
            </div>
            <div className="Schedule-text-subtext">
              <p className="Schedule-main">Schedule</p>
              <span className="Schedule-sub">
                Choose pickup and delivery date
              </span>
            </div>
          </div>
        </div>
      </DialogPop>

      <DialogOutClick
        open={ShowLogout}
        handleClose={() => setShowLogout(false)}
      >
        <div style={{ width: "100%", padding: "30px 30px 20px 30px" }}>
          <div
            style={{
              width: "100%",
              marginBottom: "30px",
              fontSize: "13px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>Are you sure you want to logout?</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="disBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => setShowLogout(false)}
            >
              No
            </div>
            <div
              className="ediBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "white",
                backgroundColor: "#808080",
                border: "1px solid #808080",
              }}
              onClick={logMeOut}
            >
              Yes
            </div>
          </div>
        </div>
      </DialogOutClick>
    </>
);
}

