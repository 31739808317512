import {HiMenuAlt2} from 'react-icons/hi'
import Dillivry from '../../assets/Dillivry.png'
import {useHistory} from 'react-router-dom'

function TopNavigationHambuger({help, openHelp}) {
    const history = useHistory();
    return (
        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:'30px'}}>
            <div style={{cursor:'pointer'}} onClick={()=>window.location.reload()}><HiMenuAlt2 size="22"/></div>
            <div style={{cursor:'pointer'}} className="dLogo">
                <img src={Dillivry} alt="dillivry logo" style={{width:'90px'}} onClick={()=>history.push('/')}/>
            </div>
            <div style={{cursor:'pointer'}} onClick={openHelp}>{help}</div>
        </div>
    )
}
export default TopNavigationHambuger