import { Grid } from "@material-ui/core";

import "./SubmitQuote.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useContext, useState,useEffect } from "react";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import PuffLoader from "react-spinners/PuffLoader";
import Carousel from "react-material-ui-carousel";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SecureLS from 'secure-ls';
import { ConnContext } from "../../../../context/ConnContext";
import GlobalizationLanguage, {GlobalizationLanguagewithCOntryCode} from "../../../../components/GlobalizationLanguage";
import Cookies from 'js-cookie';


function SubmitQuote({ item, closePopup }) {
  var login=GlobalizationLanguage("/login")
  var timeout = GlobalizationLanguage("/timeout")

  const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

  const history = useHistory();
  const {visitingCountryCode} = useContext(ConnContext)

  const [submiting, setsubmiting] = useState(false);
  var countryvalue =
  Cookies.get("i18next") || visitingCountryCode.toLowerCase();
  const [country, setCountry] = useState(countryvalue);
  const Authorization = ls.get("_nodi") 
   

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: Authorization,
    },
  });

  const schema = yup.object().shape({
    QAmount: yup.string().required('Required').matches(/^[0-9]*$/, "Amount has to be number"),
    // msg: yup.string().max(250, "Maximum of 250 character"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const quoteNow = async (data) => {
    if (data.msg === ''){
      setsubmiting(true);
      await authAxios
        .post(`m-dillivry-bid-manager?delivery_location=${Cookies.get("i18next")}`, {
          action_type: "quote",
          delivery_id: item.delivery_id,
          quote_amount: data.QAmount.replaceAll(",", ""),
          vcc: visitingCountryCode
        }, {timeout: 240000})
        .then((response) => {
          setsubmiting(false);
          closePopup();
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              text: "Quote placed successfully!",
              timer: 2500,
              showConfirmButton: false,
            }).then(() => {
              window.location.reload();
            });
          }
        })
        .catch((err) => {
          if (err.code === "ECONNABORTED") {
            console.log('internal-server-error-timeout')
            history.push(timeout)
            // window.location.reload()
          }
          if (err?.response?.status === 403) {
            Swal.fire({
              icon: "warning",
              text: "Session expired, login again!",
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                // auth.logout();
                history.push(login);
              }
            });
          }
          else if(err?.response?.status === 400){
            if( err?.response?.data?.message === "You can not bid from the selected conutry, please register for the  specifcied country"){
              Swal.fire({
                // icon: "warning",
                title:" Oops forbidden!",
                text: `${err?.response?.data?.message}`,
                showConfirmButton: true,
                confirmButtonColor: "var(--main)",
                customClass: {
                  title:"redBg"
                },
              } )
              

            }
            else if( err?.response?.data?.message === "You are owing dillivry please fund your or accept instant payment method to balance your account"){
              Swal.fire({
                title:" Oops forbidden!",
                text:`${err?.response?.data?.message}`,
                showConfirmButton: true,
                confirmButtonColor: "var(--main)",
                confirmButtonText :"Process",
                customClass: {
                  title:"redBg"
                },
                
              })
              .then(()=>{
                history.push(GlobalizationLanguagewithCOntryCode("/corpwallet", country,visitingCountryCode))
        
              })
              

            }
            else if( err?.response?.data?.message.replace(/[0-9]/g, '') === "You have  item need to be completed before you can bid again"){
              Swal.fire({
                title:" Oops forbidden!",
                text:`${err?.response?.data?.message}`,
                showConfirmButton: true,
                confirmButtonColor: "var(--main)",
                confirmButtonText :"Process",
                customClass: {
                  title:"redBg"
                },
              })
            }
            else{
              Swal.fire({
                title:" Oops forbidden!",
                text: `${err?.response?.data?.message}`,
                showConfirmButton: true,
                confirmButtonColor: "var(--main)",
                customClass: {
                  title:"redBg"
                },
              })
            } 
            setsubmiting(false);

          }
          else {
            Swal.fire({
              icon: "warning",
              text: `${err?.response?.data?.message}`,
              timer: 2500,
              showConfirmButton: false,
            });
          }
          closePopup();
          setsubmiting(false);
        });
    }
    else{
      setsubmiting(true);
      await authAxios
        .post(`m-dillivry-bid-manager?delivery_location=${Cookies.get("i18next")}`, {
          action_type: "quote",
          delivery_id: item.delivery_id,
          quote_amount: data.QAmount.replaceAll(",", ""),
          msg: data.msg,
        }, {timeout: 240000})
        .then((response) => {
          setsubmiting(false);
          closePopup();
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              text: "Quote placed successfully!",
              timer: 2500,
              showConfirmButton: false,
            }).then(() => {
              window.location.reload();
            });
          }
          
        }) 
        .catch((err) => {
          if (err.code === "ECONNABORTED") {
            console.log('internal-server-error-timeout')
            history.push(timeout)
            // window.location.reload()
          }
          if (err?.response?.status === 403) {
            Swal.fire({
              icon: "warning",
              text: "Session expired, login again!",
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                // auth.logout();
                history.push(login);
              }
            });
          }
          else if(err?.response?.status === 400){
            if( err?.response?.data?.message === "You can not bid from the selected conutry, please register for the  specifcied country"){
              Swal.fire({
                // icon: "warning",
                title:" Oops forbidden!",
                text: `${err?.response?.data?.message}`,
                showConfirmButton: true,
                confirmButtonColor: "var(--main)",
                customClass: {
                  title:"redBg"
                },
              } )
            }
            else if( err?.response?.data?.message === "You are owing dillivry please fund your or accept instant payment method to balance your account"){
              Swal.fire({
                title:" Oops forbidden!",
                text: err?.response?.data?.message,
                showConfirmButton: true,
                confirmButtonColor: "var(--main)",
                confirmButtonText :"Process",
                customClass: {
                  title:"redBg"
                },
                
              })
              .then(()=>{
                history.push(GlobalizationLanguagewithCOntryCode("/corpwallet", country,visitingCountryCode))
              })
            }
            else if( err?.response?.data?.message.replace(/[0-9]/g, '') === "You have  item need to be completed before you can bid again"){
              Swal.fire({
                title:" Oops forbidden!",
                text: err?.response?.data?.message,
                showConfirmButton: true,
                confirmButtonColor: "var(--main)",
                confirmButtonText :"Process",
                customClass: {
                  title:"redBg"
                },
              })
              .then(()=>{
                history.push(GlobalizationLanguagewithCOntryCode("/MyDillivry", country,visitingCountryCode))
              })
            }
            else{
              Swal.fire({
                title:" Oops forbidden!",
                text: err?.response?.data?.message,
                showConfirmButton: true,
                confirmButtonColor: "var(--main)",
                customClass: {
                  title:"redBg"
                },
              })
            } 
            setsubmiting(false);
          }
          else {
            Swal.fire({
              icon: "warning",
              text: "An error occured, be sure you are connected to internet",
              timer: 2500,
              showConfirmButton: false,
            });
            setsubmiting(false);
          }
          closePopup();
          setsubmiting(false);
        });
    }
  };

  useEffect(() => {
    setCountry(Cookies.get("i18next"));
  }, [Cookies.get("i18next")])
  

  const numberWithCommas = new Intl.NumberFormat("en-GB")

  return (
    <div className="submitQuot">
      <div className="topItem">
        <div>
          <span style={{ fontWeight: "600" }}>Item category: </span>
          <span>{item.item_category.charAt(0)?.toUpperCase() + item.item_category.slice(1).replace(/_/g , " ")}</span>
        </div>
        <div>
          <span style={{ fontWeight: "600" }}>ID: </span>
          <span>{item.delivery_id}</span>
        </div>
      </div>
      <div className="bodyItem" style={{padding:'20px'}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <div className="itemImg">
                  <Carousel
                    navButtonsAlwaysInvisible={true}
                    indicators={false}
                    animation={"slide"}
                    timeout={100}
                    autoPlay={true}
                  >
                    {item.image_urls?.map((img_url, i) => (
                      <img
                        key={i}
                        src={img_url}
                        style={{ width: "80px", maxHeight: "80px" }}
                        alt="item_imagex"
                      />
                    ))}
                  </Carousel>
                </div>
              </Grid>
              <Grid item xs={9}>
                <div className="itemDescs">
                  <div className="itemDesc">
                    <span style={{ fontWeight: "600" }}>Description:</span>
                    <span style={{ fontWeight: "400" }}>{item.item_desc}</span>
                  </div>
                  <div className="itemDesc">
                    <span style={{ fontWeight: "600" }}>Pickup Date:</span>
                    <span style={{ fontWeight: "400" }}>{item.pickup_date}</span>
                  </div>
                  <div className="itemDesc">
                    <span style={{ fontWeight: "600" }}>
                      Delivery time frame:
                    </span>
                    <span style={{ fontWeight: "400" }}>{item.delivery_time_frame}</span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="qH">Quote information</div>
            <div className="qB">
              <form
                style={{ width: "100%", padding: "0" }}
                onSubmit={handleSubmit(quoteNow)}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={4} sm={3}>
                        <span style={{ fontWeight: "600", fontSize: "12px" }}>
                          Best Quote:
                        </span>
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <span style={{ fontSize: "12px" }}>
                          {numberWithCommas.format(item?.best_quote) === "NaN" ? "No quote yet" : <> &#8358; {numberWithCommas.format(item?.best_quote)}</>}
                        </span>
                      </Grid>
                      <Grid item xs={4} sm={3}></Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={1}
                      style={{ alignItems: "center" }}
                    >
                      <Grid item xs={4} sm={3}>
                        <span style={{ fontWeight: "600", fontSize: "12px" }}>
                          Your Quote:(&#8358;)
                        </span>
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <input
                          {...register("QAmount")}
                          type="text"
                          className="quo_input quo_input_in"
                        />
                        <div className="err">{errors.QAmount?.message}</div>
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={4} sm={3}>
                        <span style={{ fontWeight: "600", fontSize: "12px" }}>
                          Message:
                        </span>
                      </Grid>
                      <Grid item xs={8}>
                        <textarea 
                        rows="4"
                        {...register("msg")}
                        className="quo_input"
                          style={{ outline: "none",
                            color:'black',
                            fontWeight:'400',
                            backgroundColor:'white'
                          }} />

                        <div className="err">{errors.msg?.message}</div>

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={4} sm={3}>

                      </Grid>
                      <Grid item xs={4}>
                      {submiting ? (
                          <input
                            type="button"
                            value="loading..."
                            className="quo_btn"
                          />
                        ) : (
                          <input
                            type="submit"
                            value="Submit"
                            className="quo_btn"
                          />
                        )}
                      </Grid>
                      <Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
          <Grid item xs={12} style={{ fontSize: "12px" }}>
            <Grid container spacing={1} style={{ marginBottom: "7px" }}>
              <Grid
                item
                xs={5}
                style={{ textAlign: "left", fontWeight: "600" }}
              >
                {" "}
                Pickup address:
              </Grid>
              <Grid style={{ textAlign: "right" }} item xs={7}>
                {" "}
                {item.from}
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginBottom: "7px" }}>
              <Grid
                item
                xs={5}
                style={{ textAlign: "left", fontWeight: "600" }}
              >
                {" "}
                Delivery address:
              </Grid>
              <Grid style={{ textAlign: "right" }} item xs={7}>
                {" "}
                {item.to}
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginBottom: "7px" }}>
              <Grid
                item
                xs={5}
                style={{ textAlign: "left", fontWeight: "600" }}
              >
                {" "}
                Distance:
              </Grid>
              <Grid style={{ textAlign: "right" }} item xs={7}>
                {" "}
                {item.distance}
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ marginBottom: "7px" }}>
              <Grid
                item
                xs={5}
                style={{ textAlign: "left", fontWeight: "600" }}
              >
                {" "}
                Mode of delivery:
              </Grid>
              <Grid style={{ textAlign: "right" }} item xs={7}>
                {" "}
                {item.mode_of_delivery}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default SubmitQuote;
