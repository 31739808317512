import React from 'react'
import {useState} from 'react'
import PropTypes from 'prop-types'

import {
  Marker,
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  DirectionsRenderer,
  InfoWindow
} from 'react-google-maps'

import Agenticon from './images/Agenticon.png'
import locationIconActive from './images/location-to.png'

import { MAP_SETTINGS } from './contant'

import mapStyles from './mapStyles.json'
import ReactTooltip from "react-tooltip";

const {
  DEFAULT_ZOOM,
  DEFAULT_CENTER,
  DEFAULT_MAP_OPTIONS,
  MARKER_SIZE,
  
  DIRECTIONS_OPTIONS,
} = MAP_SETTINGS

const DIRECTION_REQUEST_DELAY = 300

const delay = (time) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, time)
  })


  const HoverFunction = ()=>{
    <ReactTooltip
    id="global"
    aria-haspopup="true"
    type="light"
    role="example"
  >
    <p>Destination Address</p>
  </ReactTooltip>

  }

  <ReactTooltip
  id="global"
  aria-haspopup="true"
  type="light"
  role="example"
>
  <p>Destination Address</p>
</ReactTooltip>

const directionsRequest = ({ DirectionsService, origin, destination }) =>
  new Promise((resolve, reject) => {
    DirectionsService.route(
      {
        origin: new window.google.maps.LatLng(origin.lat, origin.lon),
        destination: new window.google.maps.LatLng(
          destination.lat,
          destination.lon
        ),
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          resolve(result)
        } else {
          reject(status)
        }
      }
    )
  })

const MapContainer2 = ({ origins, destinations, hoveredOriginId, hovereffect }) => {
  const [selectedOriginId, setSelectedOriginId] = React.useState(null)
  const [directions, setDirections] = React.useState({})
  const [ setIsClickOutsideDisabled] = React.useState(
    true
  )

  
  const mapRef = React.useRef(null)
  const selectedOrHoveredOriginId = hoveredOriginId || selectedOriginId

  React.useEffect(() => {
    const bounds = new window.google.maps.LatLngBounds()
    origins.forEach(({ coordinates: { lat, lon } }) => {
      bounds.extend(new window.google.maps.LatLng(lat, lon))
    })
    destinations.forEach(({ coordinates: { lat, lon } }) => {
      bounds.extend(new window.google.maps.LatLng(lat, lon))
    })
    mapRef.current.fitBounds(bounds)
  }, [destinations, origins])

  React.useEffect(() => {
      setSelectedOriginId("destination")
  }, [])
  
  const directionsToSelectedOrHoveredOrigin =
    directions[selectedOrHoveredOriginId]
  React.useEffect(() => {
    if (selectedOrHoveredOriginId && !directionsToSelectedOrHoveredOrigin) {
      const DirectionsService = new window.google.maps.DirectionsService()
      const fetchDirections = async () => {
        const selectedOrHoveredOrigin = origins.find(
          ({ id }) => selectedOrHoveredOriginId === id
        )
        const tempDirectionsToOrigin = []
        for (const destination of destinations) {
          const direction = await directionsRequest({
            DirectionsService,
            origin: {
              lat: selectedOrHoveredOrigin.coordinates.lat,
              lon: selectedOrHoveredOrigin.coordinates.lon,
            },
            destination: {
              lat: destination.coordinates.lat,
              lon: destination.coordinates.lon,
            },
          })
          await delay(DIRECTION_REQUEST_DELAY)
          tempDirectionsToOrigin.push(direction)
        }
        setDirections((prevState) => ({
          ...prevState,
          [selectedOrHoveredOriginId]: tempDirectionsToOrigin,
        }))
      }
      fetchDirections()
    }
  }, [
    destinations,
    directionsToSelectedOrHoveredOrigin,
    selectedOrHoveredOriginId,
    origins,
  ])

  const [showhover, setshowhover] = useState(false)

  const HoverFunction = ()=>{
      setshowhover(true)
//     <ReactTooltip
//     id="global"
//     aria-haspopup="true"
//     type="light"
//     role="example"
//   >
//     <p>Destination Address</p>
//   </ReactTooltip>

  }

  return (
    <GoogleMap
      ref={mapRef}
      defaultZoom={DEFAULT_ZOOM}
      defaultCenter={DEFAULT_CENTER}
      defaultOptions={{ ...DEFAULT_MAP_OPTIONS, styles: mapStyles }}
    //   onDragStart={() => setIsClickOutsideDisabled(true)}
    //   onDragEnd={() => setIsClickOutsideDisabled(false)}
    >
      {origins.map(({ coordinates: { lat, lon: lng }, id }) => (
        <Marker
          key={id}
          position={{ lat, lng }}
          icon={{
             url: Agenticon,
            scaledSize: new window.google.maps.Size(MARKER_SIZE, MARKER_SIZE),
          }}
          onClick={() => {
            setSelectedOriginId(id)
          }}
          onMouseOver= {HoverFunction }

        //   onMouseOut = {!HoverFunction}

          onMouseUp ={HoverFunction}
          >

          {
          showhover && (
              <InfoWindow data-tip
              data-for="global" >
                  <h4  data-tip
                    data-for="global6"> Agent Location</h4>
              </InfoWindow>
          )}
      

  </Marker>


      ))}
      {destinations.map(({ coordinates: { lat, lon: lng }, id }) => (
        <Marker
          key={id}
          position={{ lat, lng }}
          icon={{
            url: locationIconActive,
   
            scaledSize: new window.google.maps.Size(MARKER_SIZE, MARKER_SIZE),
          }}
        //   onMouseOver= {HoverFunction }

        //   onMouseOut = {!HoverFunction}
        >

                {/* {
                showhover && (
                    <InfoWindow>
                        <h4>Agent Location</h4>
                    </InfoWindow>
                )}
             */}

        </Marker>
      ))}
      
      {directionsToSelectedOrHoveredOrigin &&
        directionsToSelectedOrHoveredOrigin.map((direction, i) => (
          <DirectionsRenderer
            key={i}
            directions={direction}
            options={DIRECTIONS_OPTIONS}
          />
        ))}
    </GoogleMap>
  )
}

MapContainer2.propTypes = {
  origins: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      coordinates: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lon: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  destinations: PropTypes.arrayOf(
    PropTypes.shape({
      coordinates: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lon: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  hoveredOriginId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
}

export default withScriptjs(withGoogleMap(MapContainer2))
