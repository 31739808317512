import { FaShuttleVan } from 'react-icons/fa'
import { MdLocationOn } from 'react-icons/md';
import { IoMdOpen, IoMdMailOpen } from 'react-icons/io'
import { useContext, useEffect, useMemo, useState } from 'react'
import DialogOutClick from '../../../../components/DialogOutClick'
import SubmitQuote from './SubmitQuote';
import { Button } from '@material-ui/core';
import { BiArrowBack, BiCheckSquare } from 'react-icons/bi';
import dillivry from '../../../../assets/Dillivry.png'
import moment from 'moment'
import { FaCalendarDay } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip';
import { IconContext } from "react-icons";
import { useHistory } from 'react-router-dom';
import DialogPop from '../../../../components/DialogPop';
import SecureLS from 'secure-ls';
import axios from 'axios';
import { ConnContext } from '../../../../context/ConnContext';
import Swal from "sweetalert2";
import { AiOutlineCloseSquare } from 'react-icons/ai';
import GlobalizationLanguage, { GlobalizationLanguagewithCOntryCode } from '../../../../components/GlobalizationLanguage';
import Cookies from 'js-cookie';
import Currency from '../../../../utils/Currency';

function CurrentCard({ agentID, removeTopMenu, setAsignType, completed_quotes, setDeliveryStatus, setDeli_location, setQuoteStatus, setagentDetails, agents, setDeliveryId, toshowAgents, quote, updateStateToMsg, updateQuoteMsg, updateDataToShow, updateStateToView, type, acceptedType, rejectType }) {

    const history = useHistory();

    var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

    useEffect(() => {
        setCountry(Cookies.get('i18next'));
    }, [Cookies.get('i18next')])

    const [country, setCountry] = useState(countryvalue);

    const [get_id, setGet_id] = useState("");

    const ls = new SecureLS({
        isCompression: true,
        encodingType: "rabbit",
        encryptionSecret: "dm_dillivry",
    });

    useEffect(() => {
        setTodayCount(completed_quotes?.filter(function (cQoute) { return moment().dayOfYear() == moment(cQoute?.delivery_agent_details.delivery_timestamp).dayOfYear() }))
        setWeekCount(completed_quotes?.filter(function (cQoute) { return moment().isoWeek() == moment(cQoute?.delivery_agent_details.delivery_timestamp).isoWeek() }))
        setMonthCount(completed_quotes?.filter(function (cQoute) { return moment().month() == moment(cQoute?.delivery_agent_details.delivery_timestamp).month() }))
        setTmonthCount(completed_quotes?.filter(function (cQoute) { return moment().quarter() == moment(cQoute?.delivery_agent_details.delivery_timestamp).quarter() }))
    }, [])
    const [showCount, setshowCount] = useState(true)
    const [countToShow, setcountToShow] = useState([])
    const [todayCount, setTodayCount] = useState([])
    const [weekCount, setWeekCount] = useState([])
    const [monthCount, setMonthCount] = useState([])
    const [TmonthCount, setTmonthCount] = useState([])
    const [openQuote, setopenQuote] = useState(false)
    const [itemToQuote, setitemToQuote] = useState([])
    const [clickCompleted, setClickCompleted] = useState('')
    const [rejecting, setrejecting] = useState(false)
    const handleClickOpen = () => {
        setopenQuote(true);
    };

    const handleClose = () => {
        setopenQuote(false);
    };
    const [submiting, setsubmiting] = useState(false);
    const Authorization = ls.get("_nodi") === "" ? "" : ls.get("_nodi");

    const authAxios = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: {
            Authorization: Authorization,
        },
    });

    const { visitingCountryCode } = useContext(ConnContext)

    const AcceptAck = () => {

        if (ls.get("_nodi") !== "" && ls.get("aCs_") === "active") {
            setsubmiting(true);
            authAxios
                .post(`m-dillivry-bid-manager?delivery_location=${country}`, {
                    action_type: "accept_ack_req",
                    delivery_id: get_id,
                    vcc: visitingCountryCode
                }, { timeout: 240000 })
                .then((response) => {

                    if (response.status === 200) {
                        Swal.fire({
                            icon: "success",
                            text: "Acknowledgement placed successfully!",
                            timer: 2500,
                            showConfirmButton: false,
                        }).then(() => {
                            window.location.reload()
                        });
                    }
                    setopenaccept(false)

                    setsubmiting(false);
                })
                .catch((err) => {
                    console.log(err);

                    setopenaccept(false)

                    if (err?.response?.status === 403) {
                        Swal.fire({
                            icon: "warning",
                            text: "Session expired, login again!",
                            //  timer: 2500,
                            confirmButtonColor: "var(--main)",
                        }).then((result) => {
                            if (result.value) {
                                ls.clear();
                                history.push(GlobalizationLanguage('/login'))
                            }
                        });
                    } else {
                        Swal.fire({
                            icon: "warning",
                            text: "An error occured, be sure you are connected to internet",
                            timer: 2500,
                            showConfirmButton: false,
                        });
                    }
                    if (err.code === "ECONNABORTED") {
                        console.log('internal-server-error-timeout')
                        history.push('/timeout')
                        // window.location.reload()
                    }
                });
        }
    }


    const RejectAck = () => {
        setrejecting(true)
        authAxios.post(`m-dillivry-bid-manager?delivery_location=${country}`, {
            action_type: "reject_bid",
            delivery_id: get_id,
            comment: '',
            vcc: visitingCountryCode
        }, { timeout: 240000 })
            .then((response) => {
                setrejecting(false)
                setopenreject(false)
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'info',
                        text: 'Acknowledgement rejected!',
                        timer: 2500,
                        showConfirmButton: false,
                    })
                        .then(() => {
                            window.location.reload()
                        })
                }

            })
            .catch((err) => {
                if (err.code === "ECONNABORTED") {
                    console.log('internal-server-error-timeout')
                    history.push(GlobalizationLanguage('/timeout'))
                }
                setrejecting(false)
                setopenreject(false)

                Swal.fire({
                    icon: 'error',
                    text: 'error!',
                    timer: 2000,
                    showConfirmButton: false,
                })
                console.log(err)
            });
    }

    var curr = new Date; // get current date
    var first = curr.getDate() - (curr.getDay() - 1); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    var firstday = new Date(curr.setDate(first)).toUTCString();
    var lastday = new Date(curr.setDate(last)).toUTCString();

    const numberWithCommas = new Intl.NumberFormat("en-GB")

    const showMe = (type, delivery_id, delivery_location) => {
        // history.push(`/deliveries/${type}/${delivery_id}`)
        var link = GlobalizationLanguagewithCOntryCode(`/deliveries/${type}/${delivery_id}`, country, visitingCountryCode)
        history.push(link, {
            location: delivery_location
        })
    }

    const onItemClickAll = (item) => {
        if(item.quote_status !== 'outbid'){
        if (item.delivery_status === "awaiting_quote") {
            showMe("current", item.delivery_id, item?.delivery_location)
        }
        if (item.delivery_status === "awaiting_agent_assignment") {
            showMe("unassign", item.delivery_id, item?.delivery_location)
        }
        if (item.delivery_status === "awaiting_pickup") {
            showMe("assign", item.delivery_id, item?.delivery_location)
        }
        if (item.delivery_status === "picked_up") {
            showMe("picked_up", item.delivery_id, item?.delivery_location)
        }
        if (item.delivery_status === "out_for_delivery") {
            showMe("out_for_delivery", item.delivery_id, item?.delivery_location)
        }
        if (item.delivery_status === "missed_delivery") {
            showMe("missed", item.delivery_id, item?.delivery_location)
        }
        if (item.delivery_status === "") {
            showMe("decline", item.delivery_id, item?.delivery_location)
        }
        if (item.delivery_status === "delivered") {
            showMe("complete", item.delivery_id, item?.delivery_location)
        }
    }
    }

    const [openaccept, setopenaccept] = useState(false)
    const [openreject, setopenreject] = useState(false)

    return (
        <>
            {
                type === 'complete' && showCount !== true ?
                    <>
                        <div style={{ width: '100%', padding: "10px 0", boxShadow: '0 10px 10px -6px #777', position: 'fixed', top: '0', backgroundColor: 'white' }}>
                            <div className="fulfillerViewTop" style={{ width: '100%', boxShadow: 'none', padding: ' 60px 20px' }}>
                                <div onClick={() => {
                                    setshowCount(!showCount); removeTopMenu(true)
                                }}>
                                    <BiArrowBack size='25px' />
                                </div>
                                <div className='viewLogo'>
                                    <img src={dillivry} alt="logo" />
                                </div>
                                <div className='topCircle'></div>
                            </div>
                            <div style={{ textAlign: 'center', width: '100%', backgroundColor: 'white' }}><h4>
                                {
                                    clickCompleted === 'today' ?
                                        "Today"
                                        :
                                        clickCompleted === 'this_week' ?
                                            "This week"
                                            :
                                            clickCompleted === 'this_month' ?
                                                "This month"
                                                :
                                                clickCompleted === 'this_quater' ?
                                                    "This quarter"
                                                    :
                                                    clickCompleted === 'all' ?
                                                        "Overall"
                                                        :
                                                        null
                                }
                            </h4></div>
                        </div>
                        <div style={{ textAlign: 'left', width: '100%', padding: '80px 0 10px 0' }}><h4>
                        </h4>
                            {
                                clickCompleted === 'today' ?
                                    <>
                                        <FaCalendarDay color='var(--main)' />
                                        {moment().format("ddd, Do MMM YYYY")}
                                    </>
                                    :
                                    clickCompleted === 'this_week' ?
                                        <>
                                            <FaCalendarDay color='var(--main)' />
                                            {moment(firstday).format("ddd, Do MMM YYYY") + " - " + moment(lastday).format("ddd, Do MMM YYYY")}
                                        </>
                                        :
                                        clickCompleted === 'this_month' ?
                                            <>
                                                <FaCalendarDay color='var(--main)' />
                                                {" 1st" + moment().format(" MMM YYYY") + ' to ' + moment().format("Do MMM YYYY")}
                                            </>
                                            :
                                            clickCompleted === 'this_quater' ?
                                                <>
                                                    <FaCalendarDay color='var(--main)' />
                                                    {
                                                        moment().month() >= 0 && moment().month() <= 2 ?
                                                            `1st Jan, ${moment().format("YYYY")} to 31th, March, ${moment().format("YYYY")}`
                                                            :
                                                            moment().month() >= 3 && moment().month() <= 5 ?
                                                                `1st April, ${moment().format("YYYY")} to 30th, Jun, ${moment().format("YYYY")}`
                                                                :
                                                                moment().month() >= 6 && moment().month() <= 8 ?
                                                                    `1st Jul, ${moment().format("YYYY")} to 30th, Sep, ${moment().format("YYYY")}`
                                                                    :
                                                                    moment().month() >= 9 && moment().month() <= 11 ?
                                                                        `1st Oct, ${moment().format("YYYY")} to 31th, Dec, ${moment().format("YYYY")}`
                                                                        :
                                                                        null
                                                    }
                                                </>
                                                :
                                                clickCompleted === 'all' ?
                                                    "Overall"
                                                    :
                                                    null
                            }
                        </div>
                    </>
                    :
                    null
            }

            {
                quote.map((item, index) => {
                    return <div style={{ width: '100%' }} key={index}>
                        {
                            type === 'all' ?
                                <div className="quoteCard">
                                    <div className="quoteBody">
                                        <div className="quoteBodyDetails">
                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                <div className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${item.image_urls[0]})` }}
                                                    onClick={() => { onItemClickAll(item) }}>
                                                </div>
                                                <div className="quoteBodyDetailsLeft">
                                                    <div className="quoteBody_Desc">
                                                        <span onClick={() => onItemClickAll(item)}>{item.item_desc}</span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', width: '80px', justifyContent: 'flex-end', }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                    <div>
                                                        <div style={{ fontSize: '11px', color: '#004DC1' }}>Your quote</div>
                                                        <div style={{ fontSize: '14px' }}><Currency location={item.delivery_location} /> {numberWithCommas.format(item.quote_amount)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="quoteBodyBtn">
                                            <div>
                                                <div className="quoteBody_items">
                                                    <span data-tip
                                                        data-for="globalMode">

                                                        <IconContext.Provider
                                                            value={{
                                                                color: "#2e2e2e",

                                                            }}
                                                        >
                                                            {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                            <FaShuttleVan color="green" size='12px' />
                                                        </IconContext.Provider>
                                                        <ReactTooltip
                                                            id="globalMode"
                                                            aria-haspopup="true"
                                                            role="example"
                                                            type="light"
                                                        >
                                                            <p>Mode of Delivery</p>
                                                        </ReactTooltip>
                                                    </span>
                                                    <div>{item?.mode_of_delivery?.charAt(0)?.toUpperCase() + item?.mode_of_delivery?.toLowerCase().slice(1)}</div>

                                                </div>
                                                <div className="quoteBody_items">
                                                    <span data-tip
                                                        data-for="globaldelibery">

                                                        <IconContext.Provider
                                                            value={{
                                                                color: "#2e2e2e",

                                                            }}
                                                        >
                                                            {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                            <MdLocationOn color="green" size='12px' />
                                                        </IconContext.Provider>
                                                        <ReactTooltip
                                                            id="globaldelibery"
                                                            aria-haspopup="true"
                                                            role="example"
                                                            type="light"
                                                        >
                                                            <p>PickUp Address</p>
                                                        </ReactTooltip>
                                                    </span>

                                                    <div>{item?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                </div>
                                                <div className="quoteBody_items">
                                                    <span data-tip
                                                        data-for="globaldistance">
                                                        <IconContext.Provider
                                                            value={{
                                                                color: "#2e2e2e",

                                                            }}
                                                        >
                                                            <MdLocationOn color="red" size='12px' />
                                                        </IconContext.Provider>
                                                        <ReactTooltip
                                                            id="globaldistance"
                                                            aria-haspopup="true"
                                                            role="example"
                                                            type="light"
                                                        >
                                                            <p>Delivery Address </p>
                                                        </ReactTooltip>
                                                    </span>
                                                    <div>{item?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                </div>
                                                <div className="quoteBody_items statusCheck" > Status:
                                                    <span style={{ color: '#004DC1' }}>
                                                        {item?.delivery_status === "awaiting_provider_acknowledgment" && item?.quote_status === 'accepted' ?
                                                            "Awaiting provider acknowledgement" :
                                                            item?.delivery_status === "awaiting_provider_acknowledgment" && item?.quote_status.includes('rejected') ?
                                                                "Rejected" :
                                                                item?.delivery_status === "awaiting_provider_acknowledgment" && item?.quote_status !== 'accepted' ?
                                                                    "Outbidded" :
                                                                    item?.quote_status?.replace(/_/g, " ").charAt(0).toUpperCase() + item?.quote_status?.replace(/_/g, " ").slice(1)}
                                                    </span>
                                                </div>
                                                <div className="quoteBody_items statusCheck" > Mode of payment: <span style={{ color: '#004DC1' }}>

                                                    {
                                                        typeof (item?.mode_of_payment) === "string" ?
                                                            <>
                                                                {
                                                                    item?.mode_of_payment === "pay_by_cash" ?
                                                                        <span>Cash</span> :
                                                                        item?.mode_of_payment === "pay_by_transfer" ?
                                                                            <span>Transfer</span> :
                                                                            item?.mode_of_payment === "pay_by_ussd" ?
                                                                                <span>USSD</span> :
                                                                                item?.mode_of_payment === "pay_by_wallet" ?
                                                                                    <span>Online</span> :
                                                                                    item?.mode_of_payment === "pay_by_card" ?
                                                                                        <span>Card</span> :
                                                                                        <span>{item?.mode_of_payment}</span>
                                                                }

                                                            </>

                                                            :
                                                            <span>N/A</span>


                                                    }

                                                </span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                type === 'current' ?
                                    <div className="quoteCard">
                                        <div className="quoteBody">
                                            <div className="quoteBodyDetails">
                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                    <div className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${item.image_urls[0]})` }}
                                                        onClick={() => { showMe("current", item.delivery_id, item?.delivery_location) }}>
                                                    </div>
                                                    <div className="quoteBodyDetailsLeft">
                                                        <div className="quoteBody_Desc">
                                                            <span onClick={() => showMe("current", item.delivery_id, item?.delivery_location)}>{item.item_desc}</span>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', width: '80px', justifyContent: 'flex-end', }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                        <div>
                                                            <div style={{ fontSize: '14px' }}><Currency location={item.delivery_location} />{numberWithCommas.format(item?.best_quote)}</div>
                                                            <div style={{ fontSize: '9px', color: 'var(--main)' }}>Best quote</div>
                                                        </div>
                                                        <div>
                                                            <div style={{ fontSize: '14px' }}><Currency location={item.delivery_location} />{numberWithCommas.format(item.quote_amount)}</div>
                                                            <div style={{ fontSize: '9px', color: item.quote_amount == item?.best_quote ? 'var(--main)' : 'red' }}>Your quote</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="quoteBodyBtn">
                                                <div>
                                                    <div className="quoteBody_items">
                                                        <span data-tip
                                                            data-for="globalMode">

                                                            <IconContext.Provider
                                                                value={{
                                                                    color: "#2e2e2e",

                                                                }}
                                                            >
                                                                {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                <FaShuttleVan color="green" size='12px' />
                                                            </IconContext.Provider>
                                                            <ReactTooltip
                                                                id="globalMode"
                                                                aria-haspopup="true"
                                                                role="example"
                                                                type="light"
                                                            >
                                                                <p>Mode of Delivery</p>
                                                            </ReactTooltip>
                                                        </span>
                                                        <div>{item?.mode_of_delivery?.charAt(0)?.toUpperCase() + item?.mode_of_delivery?.toLowerCase().slice(1)}</div>

                                                    </div>
                                                    <div className="quoteBody_items">
                                                        <span data-tip
                                                            data-for="globaldelibery">

                                                            <IconContext.Provider
                                                                value={{
                                                                    color: "#2e2e2e",

                                                                }}
                                                            >
                                                                {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                <MdLocationOn color="green" size='12px' />
                                                            </IconContext.Provider>
                                                            <ReactTooltip
                                                                id="globaldelibery"
                                                                aria-haspopup="true"
                                                                role="example"
                                                                type="light"
                                                            >
                                                                <p>PickUp Address</p>
                                                            </ReactTooltip>
                                                        </span>

                                                        {/* <MdLocationOn color="green" size='12px'/> */}
                                                        <div>{item?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                    </div>
                                                    <div className="quoteBody_items">
                                                        <span data-tip
                                                            data-for="globaldistance">
                                                            <IconContext.Provider
                                                                value={{
                                                                    color: "#2e2e2e",

                                                                }}
                                                            >
                                                                {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                <MdLocationOn color="red" size='12px' />
                                                            </IconContext.Provider>
                                                            <ReactTooltip
                                                                id="globaldistance"
                                                                aria-haspopup="true"
                                                                role="example"
                                                                type="light"
                                                            >
                                                                <p>Delivery Address </p>
                                                            </ReactTooltip>
                                                        </span>
                                                        {/* <MdLocationOn color="red" size='12px'/> */}
                                                        <div>{item?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                    </div>
                                                    <div className="quoteBody_items statusCheck" > Status: <span style={{ color: '#004DC1' }}> {item?.delivery_status?.replace(/_/g, " ").charAt(0).toUpperCase() + item?.delivery_status?.replace(/_/g, " ").slice(1)}</span> </div>
                                                    <div className="quoteBody_items statusCheck" > Mode of payment: <span style={{ color: '#004DC1' }}>

                                                        {
                                                            typeof (item?.mode_of_payment) === "string" ?
                                                                <>
                                                                    {
                                                                        item?.mode_of_payment === "pay_by_cash" ?
                                                                            <span>Cash</span> :
                                                                            item?.mode_of_payment === "pay_by_transfer" ?
                                                                                <span>Transfer</span> :
                                                                                item?.mode_of_payment === "pay_by_ussd" ?
                                                                                    <span>USSD</span> :
                                                                                    item?.mode_of_payment === "pay_by_wallet" ?
                                                                                        <span>Online</span> :
                                                                                        item?.mode_of_payment === "pay_by_card" ?
                                                                                            <span>Card</span> :
                                                                                            <span>{item?.mode_of_payment}</span>
                                                                    }

                                                                </>

                                                                :
                                                                <span>N/A</span>


                                                        }

                                                    </span>

                                                    </div>

                                                </div>

                                                <div style={{ display: 'flex', gap: '15px', alignItems: 'center', justifyContent: "center" }}>
                                                    <span onClick={() => {
                                                        history.push(GlobalizationLanguagewithCOntryCode(`/message/${type}/${item.delivery_id}`, country, visitingCountryCode))
                                                    }}>
                                                        <IoMdMailOpen size='20px' color='#484848' />
                                                    </span>

                                                    <span onClick={() => { setitemToQuote(item); handleClickOpen() }}>
                                                        <IoMdOpen size='20px' color='#484848' />
                                                    </span>

                                                    {/* <Button
                                                    size="small"
                                                    variant="contained"
                                                    style={{ fontSize: '11px', maxHeight: '30px', border: '1px solid var(--main)', backgroundColor: 'white', color: 'var(--main)', textTransform: 'none' }}
                                                    onClick={() => {
                                                        history.push(`/message/${type}/${item.delivery_id}`)
                                                    }}>
                                                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
                                                        <span><IoMdMailOpen size='12px' /></span>
                                                        <span>Message</span>
                                                    </div>
                                                </Button> */}
                                                    {/* <Button
                                                    size="small"
                                                    variant="contained"
                                                    style={{ fontSize: '11px', maxHeight: '30px', border: '1px solid var(--main)', backgroundColor: 'var(--main)', color: 'white', textTransform: 'none' }}
                                                    onClick={() => { setitemToQuote(item); handleClickOpen() }}
                                                >
                                                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
                                                        <span><IoMdOpen size='12px' /></span>
                                                        <span>Quote</span>
                                                    </div>
                                                </Button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    type === 'accept' ?
                                        acceptedType === 'unassign' ?

                                            <div className="quoteCard">
                                                <div className='quoteCardTitle'>
                                                    <div className='radiusCard' onClick={() => { toshowAgents(); setDeliveryId(item.delivery_id); setDeli_location(item.delivery_location); setQuoteStatus(item.quote_status); setDeliveryStatus(item.delivery_status) }}>?</div>

                                                    <span
                                                        style={{ color: 'var(--main)', cursor: 'pointer', textDecoration: 'underline', fontSize: '12px', userSelect: 'none' }}
                                                        onClick={() => { setAsignType('assign'); agentID(""); toshowAgents(); setDeliveryId(item.delivery_id); setDeli_location(item.delivery_location); setQuoteStatus(item.quote_status); setDeliveryStatus(item.delivery_status); }}
                                                    >Assign agent</span>
                                                </div>

                                                <div className="quoteBody">
                                                    <div className="quoteBodyDetails">
                                                        <div style={{ display: 'flex', gap: '5px' }}>
                                                            <div className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${item.image_urls[0]})` }}
                                                                onClick={() => showMe("unassign", item.delivery_id)}>
                                                            </div>
                                                            <div className="quoteBodyDetailsLeft">
                                                                <div className="quoteBody_Desc">
                                                                    <span onClick={() => showMe("unassign", item.delivery_id, item?.delivery_location)}>{item?.item_desc?.charAt(0)?.toUpperCase() + item?.item_desc?.toLowerCase().slice(1)}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '80px', justifyContent: 'flex-end', }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                <div>
                                                                    <div style={{ fontSize: '14px' }}><Currency location={item.delivery_location} />{numberWithCommas.format(item.quote_amount)}</div>
                                                                    {/* <div style={{fontSize:'9px', color:'var(--main)'}}>Your quote</div> */}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="quoteBodyBtn">
                                                        <div style={{ fontSize: '13px', display: 'flex', flexDirection: 'column', gap: '7px' }}>
                                                            <div className="quoteBody_items">
                                                                <span data-tip
                                                                    data-for="globalMode">

                                                                    <IconContext.Provider
                                                                        value={{
                                                                            color: "#2e2e2e",

                                                                        }}
                                                                    >
                                                                        <FaShuttleVan color="green" size='12px' />
                                                                    </IconContext.Provider>
                                                                    <ReactTooltip
                                                                        id="globalMode"
                                                                        aria-haspopup="true"
                                                                        role="example"
                                                                        type="light"
                                                                    >
                                                                        <p>Mode of Delivery</p>
                                                                    </ReactTooltip>
                                                                </span>

                                                                <div>{item?.mode_of_delivery?.charAt(0)?.toUpperCase() + item?.mode_of_delivery?.toLowerCase().slice(1)}</div>
                                                            </div>
                                                            <div className="quoteBody_items">
                                                                <span data-tip
                                                                    data-for="globaldelibery">

                                                                    <IconContext.Provider
                                                                        value={{
                                                                            color: "#2e2e2e",

                                                                        }}
                                                                    >
                                                                        <MdLocationOn color="green" size='12px' />
                                                                    </IconContext.Provider>
                                                                    <ReactTooltip
                                                                        id="globaldelibery"
                                                                        aria-haspopup="true"
                                                                        role="example"
                                                                        type="light"
                                                                    >
                                                                        <p>PickUp Address</p>
                                                                    </ReactTooltip>
                                                                </span>

                                                                <div>{item.from}</div>
                                                            </div>
                                                            <div className="quoteBody_items">
                                                                <span data-tip
                                                                    data-for="globaldistance">

                                                                    <IconContext.Provider
                                                                        value={{
                                                                            color: "#2e2e2e",

                                                                        }}
                                                                    >
                                                                        <MdLocationOn color="red" size='12px' />
                                                                    </IconContext.Provider>
                                                                    <ReactTooltip
                                                                        id="globaldistance"
                                                                        aria-haspopup="true"
                                                                        role="example"
                                                                        type="light"
                                                                    >
                                                                        <p>Delivery Address </p>
                                                                    </ReactTooltip>
                                                                </span>

                                                                {/* <MdLocationOn color="red" size='12px'/> */}
                                                                <div>{item?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                            </div>
                                                            <div>Payment status: <span style={{ color: 'var(--blue)', fontWeight: 'bold' }}>{item.payment_status === "pay_on_delivery" ? 'Pay on delievry' : item.payment_status === 'success' ? 'Paid' : item.payment_status === 'NA' ? 'NA' : "Not paid"}</span> </div>
                                                            <div>Delivery Status: <span style={{ color: 'var(--brown)', fontWeight: 'bold' }}> Awaiting Pickup </span></div>
                                                            <div className="quoteBody_items statusCheck" > Mode of payment: <span style={{ color: '#004DC1' }}>

                                                                {
                                                                    typeof (item?.mode_of_payment) === "string" ?
                                                                        <>
                                                                            {
                                                                                item?.mode_of_payment === "pay_by_cash" ?
                                                                                    <span>Cash</span> :
                                                                                    item?.mode_of_payment === "pay_by_transfer" ?
                                                                                        <span>Transfer</span> :
                                                                                        item?.mode_of_payment === "pay_by_ussd" ?
                                                                                            <span>USSD</span> :
                                                                                            item?.mode_of_payment === "pay_by_wallet" ?
                                                                                                <span>Online</span> :
                                                                                                item?.mode_of_payment === "pay_by_card" ?
                                                                                                    <span>Card</span> :
                                                                                                    <span>{item?.mode_of_payment}</span>
                                                                            }

                                                                        </>

                                                                        :
                                                                        <span>N/A</span>


                                                                }

                                                            </span>

                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '15px', alignItems: 'center', justifyContent: "center" }}>

                                                            <span onClick={() => {
                                                                history.push(GlobalizationLanguagewithCOntryCode(`/message/unassign/${item.delivery_id}`, country, visitingCountryCode))
                                                            }}>
                                                                <IoMdMailOpen size='20px' color='#484848' />
                                                            </span>

                                                            {/* <Button
                                                            size="small"
                                                            variant="contained"
                                                            style={{ fontSize: '11px', maxHeight: '30px', border: '1px solid var(--main)', backgroundColor: 'white', color: 'var(--main)', textTransform: 'none' }}
                                                            onClick={() => {
                                                                history.push(`/message/unassign/${item.delivery_id}`)
                                                            }}>
                                                            <div style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
                                                                <span><IoMdMailOpen size='12px' /></span>
                                                                <span>Message</span>
                                                            </div>
                                                        </Button> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="quoteCard">
                                                <div className='quoteCardTitle'>
                                                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                                        <div className='radiusCard'>{item?.assigned_agent_details?.agent_name?.charAt(0)?.toUpperCase()}</div>
                                                        <span>{item.assigned_agent_details?.agent_name}</span>
                                                    </div>
                                                    <span
                                                        style={{ color: 'var(--main)', cursor: 'pointer', textDecoration: 'underline', fontSize: '12px', userSelect: 'none' }}
                                                        onClick={() => { setAsignType('reassign'); agentID(item.assigned_agent_details?.pickup_agent_id); toshowAgents(); setDeliveryId(item.delivery_id); setDeli_location(item.delivery_location); setQuoteStatus(item.quote_status); setDeliveryStatus(item.delivery_status); }}
                                                    > Reassign agent</span>
                                                </div>
                                                <div className="quoteBody">
                                                    <div className="quoteBodyDetails">
                                                        <div style={{ display: 'flex', gap: '5px' }}>
                                                            <div className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${item.image_urls[0]})` }}
                                                                onClick={() => {
                                                                    showMe("assign", item.delivery_id, item?.delivery_location);
                                                                    setagentDetails(agents.filter(function (agent) { return agent.user_id === item.assigned_agent_details.pickup_agent_id }));
                                                                }}>
                                                            </div>
                                                            <div className="quoteBodyDetailsLeft">
                                                                <div className="quoteBody_Desc">
                                                                    <span
                                                                        onClick={() => {
                                                                            showMe("assign", item.delivery_id, item?.delivery_location);
                                                                            setagentDetails(agents.filter(function (agent) { return agent.user_id === item.assigned_agent_details.pickup_agent_id }));
                                                                        }}>
                                                                        {item?.item_desc?.charAt(0)?.toUpperCase() + item?.item_desc?.toLowerCase().slice(1)}
                                                                    </span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '80px', justifyContent: 'flex-end', }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                <div>
                                                                    <div style={{ fontSize: '14px' }}><Currency location={item.delivery_location} />{numberWithCommas.format(item?.quote_amount)}</div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="quoteBodyBtn">

                                                        <div style={{ fontSize: '13px', display: 'flex', flexDirection: 'column', gap: '7px' }}>
                                                            <div className="quoteBody_items">
                                                                <span data-tip data-for="globalMode">
                                                                    <IconContext.Provider
                                                                        value={{ color: "#2e2e2e" }}
                                                                    >
                                                                        <FaShuttleVan color="green" size='12px' />
                                                                    </IconContext.Provider>
                                                                    <ReactTooltip id="globalMode" aria-haspopup="true" role="example" type="light" >
                                                                        <p>Mode of Delivery</p>
                                                                    </ReactTooltip>
                                                                </span>
                                                                <div>{item?.mode_of_delivery?.charAt(0)?.toUpperCase() + item?.mode_of_delivery?.toLowerCase().slice(1)}</div>
                                                            </div>

                                                            <div className="quoteBody_items">
                                                                <span data-tip data-for="globaldelibery">
                                                                    <IconContext.Provider
                                                                        value={{ color: "#2e2e2e" }}
                                                                    >
                                                                        <MdLocationOn color="green" size='12px' />
                                                                    </IconContext.Provider>
                                                                    <ReactTooltip id="globaldelibery" aria-haspopup="true" role="example" type="light" >
                                                                        <p>PickUp Address</p>
                                                                    </ReactTooltip>
                                                                </span>
                                                                <div>{item.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                            </div>

                                                            <div className="quoteBody_items">
                                                                <span data-tip data-for="globaldistance">
                                                                    <IconContext.Provider
                                                                        value={{ color: "#2e2e2e" }}
                                                                    >
                                                                        <MdLocationOn color="red" size='12px' />
                                                                    </IconContext.Provider>
                                                                    <ReactTooltip id="globaldistance" aria-haspopup="true" role="example" type="light" >
                                                                        <p>Delivery Address </p>
                                                                    </ReactTooltip>
                                                                </span>
                                                                <div>{item?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                            </div>
                                                            <div>Payment status: <span style={{ color: 'var(--blue)', fontWeight: 'bold' }}>{item.payment_status === "pay_on_delivery" ? 'Pay on delievry' : item.payment_status === 'success' ? 'Paid' : item.payment_status === 'NA' ? 'NA' : "Not paid"}</span> </div>
                                                            <div>Delivery Status: <span style={{ color: 'var(--brown)', fontWeight: 'bold' }}> Awaiting Pickup </span></div>
                                                            <div className="quoteBody_items statusCheck" > Mode of payment: <span style={{ color: '#004DC1' }}>

                                                                {
                                                                    typeof (item?.mode_of_payment) === "string" ?
                                                                        <>
                                                                            {
                                                                                item?.mode_of_payment === "pay_by_cash" ?
                                                                                    <span>Cash</span> :
                                                                                    item?.mode_of_payment === "pay_by_transfer" ?
                                                                                        <span>Transfer</span> :
                                                                                        item?.mode_of_payment === "pay_by_ussd" ?
                                                                                            <span>USSD</span> :
                                                                                            item?.mode_of_payment === "pay_by_wallet" ?
                                                                                                <span>Online</span> :
                                                                                                item?.mode_of_payment === "pay_by_card" ?
                                                                                                    <span>Card</span> :
                                                                                                    <span>{item?.mode_of_payment}</span>
                                                                            }

                                                                        </>

                                                                        :
                                                                        <span>N/A</span>


                                                                }

                                                            </span>

                                                            </div>
                                                            {item.assigned_agent_details !== undefined ?
                                                                <div>Assign time: {moment(item?.assigned_agent_details?.assigned_time_stamp).format("DD-MM-YYYY HH:MM:SS")}</div>

                                                                : null}

                                                        </div>
                                                        <div style={{ display: 'flex', gap: '15px', alignItems: 'center', justifyContent: "center" }}>
                                                            {/* <Button
                                                            size="small"
                                                            variant="contained"
                                                            style={{ fontSize: '11px', maxHeight: '30px', border: '1px solid var(--main)', backgroundColor: 'white', color: 'var(--main)', textTransform: 'none' }}
                                                            onClick={() => {
                                                                history.push(`/message/assign/${item.delivery_id}`)
                                                            }}>
                                                            <div style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
                                                                <span><IoMdMailOpen size='12px' /></span>
                                                                <span>Message</span>
                                                            </div>
                                                        </Button> */}

                                                            <span onClick={() => {
                                                                history.push(GlobalizationLanguagewithCOntryCode(`/message/assign/${item.delivery_id}`, country, visitingCountryCode))
                                                            }}>
                                                                <IoMdMailOpen size='20px' color='#484848' />
                                                            </span>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        : type === 'acknowledge' ?
                                            <div className="quoteCard">
                                                <div className="quoteBody">
                                                    <div className="quoteBodyDetails">
                                                        <div style={{ display: 'flex', gap: '5px' }}>
                                                            <div className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${item.image_urls[0]})` }}
                                                            // onClick={() => { showMe("current", item.delivery_id) }}
                                                            >
                                                            </div>
                                                            <div className="quoteBodyDetailsLeft">
                                                                <div className="quoteBody_Desc">
                                                                    {/* <span onClick={() => showMe("current", item.delivery_id)}>
                                                                    {item.item_desc}</span> 
                                                                    */}
                                                                    <span> {item.item_desc}</span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', width: '80px', justifyContent: 'flex-end', }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                <div>
                                                                    <div style={{ fontSize: '11px' }}>Amount</div>
                                                                    <div style={{ fontSize: '14px', color: "#004DC1" }}><Currency location={item.delivery_location} />{numberWithCommas.format(item.quote_amount)}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="quoteBodyBtn">
                                                        <div>
                                                            <div className="quoteBody_items">
                                                                <span data-tip
                                                                    data-for="globalMode">

                                                                    <IconContext.Provider
                                                                        value={{
                                                                            color: "#2e2e2e",
                                                                        }}
                                                                    >
                                                                        <FaShuttleVan color="green" size='12px' />
                                                                    </IconContext.Provider>
                                                                    <ReactTooltip
                                                                        id="globalMode"
                                                                        aria-haspopup="true"
                                                                        role="example"
                                                                        type="light"
                                                                    >
                                                                        <p>Mode of Delivery</p>
                                                                    </ReactTooltip>
                                                                </span>
                                                                <div>{item?.mode_of_delivery?.charAt(0)?.toUpperCase() + item?.mode_of_delivery?.toLowerCase().slice(1)}</div>
                                                            </div>
                                                            <div className="quoteBody_items">
                                                                <span data-tip
                                                                    data-for="globaldelibery">

                                                                    <IconContext.Provider
                                                                        value={{
                                                                            color: "#2e2e2e",

                                                                        }}
                                                                    >
                                                                        {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                        <MdLocationOn color="green" size='12px' />
                                                                    </IconContext.Provider>
                                                                    <ReactTooltip
                                                                        id="globaldelibery"
                                                                        aria-haspopup="true"
                                                                        role="example"
                                                                        type="light"
                                                                    >
                                                                        <p>PickUp Address</p>
                                                                    </ReactTooltip>
                                                                </span>

                                                                {/* <MdLocationOn color="green" size='12px'/> */}
                                                                <div>{item.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                            </div>
                                                            <div className="quoteBody_items">
                                                                <span data-tip
                                                                    data-for="globaldistance">
                                                                    <IconContext.Provider
                                                                        value={{
                                                                            color: "#2e2e2e",

                                                                        }}
                                                                    >
                                                                        {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                        <MdLocationOn color="red" size='12px' />
                                                                    </IconContext.Provider>
                                                                    <ReactTooltip
                                                                        id="globaldistance"
                                                                        aria-haspopup="true"
                                                                        role="example"
                                                                        type="light"
                                                                    >
                                                                        <p>Delivery Address </p>
                                                                    </ReactTooltip>
                                                                </span>
                                                                {/* <MdLocationOn color="red" size='12px'/> */}
                                                                <div>{item?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                            </div>
                                                            <div className="quoteBody_items statusCheck" > Status: <span style={{ color: '#004DC1' }}> {item?.delivery_status?.replace(/_/g, " ").charAt(0).toUpperCase() + item?.delivery_status?.replace(/_/g, " ").slice(1)}</span> </div>
                                                            <div className="quoteBody_items statusCheck" > Mode of payment: <span style={{ color: '#004DC1' }}>

                                                                {
                                                                    typeof (item?.mode_of_payment) === "string" ?
                                                                        <>
                                                                            {
                                                                                item?.mode_of_payment === "pay_by_cash" ?
                                                                                    <span>Cash</span> :
                                                                                    item?.mode_of_payment === "pay_by_transfer" ?
                                                                                        <span>Transfer</span> :
                                                                                        item?.mode_of_payment === "pay_by_ussd" ?
                                                                                            <span>USSD</span> :
                                                                                            item?.mode_of_payment === "pay_by_wallet" ?
                                                                                                <span>Online</span> :
                                                                                                item?.mode_of_payment === "pay_by_card" ?
                                                                                                    <span>Card</span> :
                                                                                                    <span>{item?.mode_of_payment}</span>
                                                                            }

                                                                        </>

                                                                        :
                                                                        <span>N/A</span>


                                                                }

                                                            </span>

                                                            </div>

                                                        </div>
                                                        <div style={{ display: 'flex', gap: '15px', alignItems: 'center', justifyContent: "center" }}>
                                                            <span onClick={() => {
                                                                // setitemToQuote(item); handleClickOpen() 
                                                                setopenreject(!openreject)
                                                                setGet_id(item.delivery_id)
                                                            }}>
                                                                <AiOutlineCloseSquare size='20px' color='red' />
                                                            </span>
                                                            <span onClick={() => {
                                                                setopenaccept(!openaccept)
                                                                setGet_id(item.delivery_id)
                                                            }}>
                                                                <BiCheckSquare size='20px' color='var(--main)' />
                                                            </span>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            :
                                            type === 'missed' ?
                                                <div className="quoteCard">
                                                    <div className='quoteCardTitle'>
                                                        <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                                            <div className='radiusCard'>{item?.assigned_agent_details?.agent_name?.charAt(0)?.toUpperCase()}</div>
                                                            <span>{item.assigned_agent_details?.agent_name}</span>
                                                        </div>
                                                        <span
                                                            style={{ color: 'var(--main)', cursor: 'pointer', textDecoration: 'underline', fontSize: '12px', userSelect: 'none' }}
                                                            onClick={() => { setAsignType('reassign'); agentID(item.assigned_agent_details?.pickup_agent_id); toshowAgents(); setDeliveryId(item.delivery_id); setDeli_location(item.delivery_location); setQuoteStatus(item.quote_status); setDeliveryStatus(item.delivery_status); }}
                                                        > Reassign agent</span>
                                                    </div>
                                                    <div className="quoteBody">
                                                        <div className="quoteBodyDetails">
                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                <div className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${item.image_urls[0]})` }}
                                                                    onClick={() => {
                                                                        showMe("missed", item.delivery_id, item?.delivery_location);
                                                                    }}>
                                                                </div>
                                                                <div className="quoteBodyDetailsLeft">
                                                                    <div className="quoteBody_Desc">
                                                                        <span onClick={() => {
                                                                            showMe("missed", item.delivery_id);
                                                                            setagentDetails(agents.filter(function (agent) { return agent.user_id === item.assigned_agent_details.pickup_agent_id }));
                                                                        }}>{item?.item_desc?.charAt(0)?.toUpperCase() + item?.item_desc?.toLowerCase().slice(1)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ display: 'flex', width: '80px', justifyContent: 'flex-end', }}>
                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                    <div>
                                                                        <div style={{ fontSize: '14px' }}><Currency location={item.delivery_location} />{numberWithCommas.format(item.quote_amount)}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="quoteBodyBtn">
                                                            <div style={{ fontSize: '13px', display: 'flex', flexDirection: 'column', gap: '7px' }}>
                                                                <div className="quoteBody_items">
                                                                    <span data-tip
                                                                        data-for="globalMode">

                                                                        <IconContext.Provider
                                                                            value={{
                                                                                color: "#2e2e2e",

                                                                            }}
                                                                        >
                                                                            {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                            <FaShuttleVan color="green" size='12px' />
                                                                        </IconContext.Provider>
                                                                        <ReactTooltip
                                                                            id="globalMode"
                                                                            aria-haspopup="true"
                                                                            role="example"
                                                                            type="light"
                                                                        >
                                                                            <p>Mode of Delivery</p>
                                                                        </ReactTooltip>
                                                                    </span>
                                                                    {/* <FaShuttleVan color="green" size='12px'/> */}
                                                                    <div>{item?.mode_of_delivery?.charAt(0)?.toUpperCase() + item?.mode_of_delivery?.toLowerCase().slice(1)}</div>
                                                                </div>
                                                                <div className="quoteBody_items">
                                                                    <span data-tip
                                                                        data-for="globaldelibery">
                                                                        <IconContext.Provider
                                                                            value={{
                                                                                color: "#2e2e2e",

                                                                            }}
                                                                        >
                                                                            {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                            <MdLocationOn color="green" size='12px' />
                                                                        </IconContext.Provider>
                                                                        <ReactTooltip
                                                                            id="globaldelibery"
                                                                            aria-haspopup="true"
                                                                            role="example"
                                                                            type="light"
                                                                        >
                                                                            <p>PickUp Address</p>
                                                                        </ReactTooltip>
                                                                    </span>
                                                                    {/* <MdLocationOn color="green" size='12px'/> */}
                                                                    <div>{item?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                                </div>
                                                                <div className="quoteBody_items">
                                                                    <span data-tip
                                                                        data-for="globaldistance">
                                                                        <IconContext.Provider
                                                                            value={{
                                                                                color: "#2e2e2e",

                                                                            }}
                                                                        >
                                                                            {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                            <MdLocationOn color="red" size='12px' />
                                                                        </IconContext.Provider>
                                                                        <ReactTooltip
                                                                            id="globaldistance"
                                                                            aria-haspopup="true"
                                                                            role="example"
                                                                            type="light"
                                                                        >
                                                                            <p>Delivery Address </p>
                                                                        </ReactTooltip>
                                                                    </span>
                                                                    {/* <MdLocationOn color="red" size='12px'/> */}
                                                                    <div>{item?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                                </div>
                                                                <div>Payment status: <span style={{ color: 'var(--blue)', fontWeight: 'bold' }}>{item.payment_status === "pay_on_delivery" ? 'Pay on delievry' : item.payment_status === 'success' ? 'Paid' : item.payment_status === 'NA' ? 'NA' : "Not paid"}</span></div>
                                                                <div>Delivery Status: <span style={{ color: 'var(--brown)', fontWeight: 'bold' }}>{item.delivery_status === "out_for_delivery" ? "Out for delivery" : "Missed"}</span></div>
                                                                <div className="quoteBody_items statusCheck" > Mode of payment: <span style={{ color: '#004DC1' }}>

                                                                    {
                                                                        typeof (item?.mode_of_payment) === "string" ?
                                                                            <>
                                                                                {
                                                                                    item?.mode_of_payment === "pay_by_cash" ?
                                                                                        <span>Cash</span> :
                                                                                        item?.mode_of_payment === "pay_by_transfer" ?
                                                                                            <span>Transfer</span> :
                                                                                            item?.mode_of_payment === "pay_by_ussd" ?
                                                                                                <span>USSD</span> :
                                                                                                item?.mode_of_payment === "pay_by_wallet" ?
                                                                                                    <span>Online</span> :
                                                                                                    item?.mode_of_payment === "pay_by_card" ?
                                                                                                        <span>Card</span> :
                                                                                                        <span>{item?.mode_of_payment}</span>
                                                                                }

                                                                            </>

                                                                            :
                                                                            <span>N/A</span>


                                                                    }

                                                                </span>

                                                                </div>
                                                            </div>
                                                            <div style={{ display: 'flex', gap: '15px', alignItems: 'center', justifyContent: "center" }}>
                                                                {/* <Button
                                                                    size="small"
                                                                    variant="contained"
                                                                    style={{ fontSize: '11px', maxHeight: '30px', border: '1px solid var(--main)', backgroundColor: 'white', color: 'var(--main)', textTransform: 'none' }}
                                                                    onClick={() => {
                                                                        history.push(`/message/${type}/${item.delivery_id}`)
                                                                    }}>
                                                                    <div style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
                                                                        <span><IoMdMailOpen size='12px' /></span>
                                                                        <span>Message</span>
                                                                    </div>
                                                                </Button> */}

                                                                <span onClick={() => {
                                                                    history.push(GlobalizationLanguagewithCOntryCode(`/message/${type}/${item.delivery_id}`, country, visitingCountryCode))
                                                                }}>
                                                                    <IoMdMailOpen size='20px' color='#484848' />
                                                                </span>


                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                                :
                                                type === 'out_for_delivery' ?

                                                    <div className="quoteCard">
                                                        <div className='quoteCardTitle'>
                                                            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                                                <div className='radiusCard'>{item?.assigned_agent_details?.agent_name?.charAt(0)?.toUpperCase()}</div>
                                                                <span>{item.assigned_agent_details?.agent_name}</span>
                                                            </div>
                                                            <span
                                                                style={{ color: 'var(--main)', cursor: 'pointer', textDecoration: 'underline', fontSize: '12px', userSelect: 'none' }}
                                                                onClick={() => { setAsignType('reassign'); agentID(item.assigned_agent_details?.pickup_agent_id); toshowAgents(); setDeliveryId(item.delivery_id); setDeli_location(item.delivery_location); setQuoteStatus(item.quote_status); setDeliveryStatus(item.delivery_status); }}
                                                            > Reassign agent</span>
                                                        </div>
                                                        <div className="quoteBody">
                                                            <div className="quoteBodyDetails">
                                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                                    <div className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${item.image_urls[0]})` }}
                                                                        onClick={() => {
                                                                            showMe(type, item.delivery_id, item?.delivery_location);
                                                                            setagentDetails(agents.filter(function (agent) { return agent.user_id === item.assigned_agent_details.pickup_agent_id }));
                                                                        }}>
                                                                    </div>
                                                                    <div className="quoteBodyDetailsLeft">
                                                                        <div className="quoteBody_Desc">
                                                                            <span onClick={() => {
                                                                                showMe(type, item.delivery_id, item?.delivery_location);
                                                                                setagentDetails(agents.filter(function (agent) { return agent.user_id === item.assigned_agent_details.pickup_agent_id }));
                                                                            }}>{item?.item_desc?.charAt(0)?.toUpperCase() + item?.item_desc?.toLowerCase().slice(1)}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div style={{ display: 'flex', width: '80px', justifyContent: 'flex-end', }}>
                                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                        <div>
                                                                            <div style={{ fontSize: '14px' }}><Currency location={item.delivery_location} />{numberWithCommas.format(item.quote_amount)}</div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="quoteBodyBtn">
                                                                <div style={{ fontSize: '13px', display: 'flex', flexDirection: 'column', gap: '7px' }}>
                                                                    <div className="quoteBody_items">
                                                                        <span data-tip
                                                                            data-for="globalMode">

                                                                            <IconContext.Provider
                                                                                value={{
                                                                                    color: "#2e2e2e",

                                                                                }}
                                                                            >
                                                                                {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                                <FaShuttleVan color="var(--blue)" size='12px' />
                                                                            </IconContext.Provider>
                                                                            <ReactTooltip
                                                                                id="globalMode"
                                                                                aria-haspopup="true"
                                                                                role="example"
                                                                                type="light"
                                                                            >
                                                                                <p>Mode of Delivery</p>
                                                                            </ReactTooltip>
                                                                        </span>
                                                                        <div>{item?.mode_of_delivery?.charAt(0)?.toUpperCase() + item?.mode_of_delivery?.toLowerCase().slice(1)}</div>
                                                                    </div>
                                                                    <div className="quoteBody_items">
                                                                        <span data-tip
                                                                            data-for="globaldelibery">

                                                                            <IconContext.Provider
                                                                                value={{
                                                                                    color: "#2e2e2e",

                                                                                }}
                                                                            >
                                                                                <MdLocationOn color="green" size='12px' />
                                                                            </IconContext.Provider>
                                                                            <ReactTooltip
                                                                                id="globaldelibery"
                                                                                aria-haspopup="true"
                                                                                role="example"
                                                                                type="light"
                                                                            >
                                                                                <p>PickUp Address</p>
                                                                            </ReactTooltip>
                                                                        </span>
                                                                        <div>{item.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                                    </div>
                                                                    <div className="quoteBody_items">
                                                                        <span data-tip
                                                                            data-for="globaldistance">
                                                                            <IconContext.Provider
                                                                                value={{
                                                                                    color: "#2e2e2e",
                                                                                }}
                                                                            >
                                                                                <MdLocationOn color="red" size='12px' />
                                                                            </IconContext.Provider>
                                                                            <ReactTooltip
                                                                                id="globaldistance"
                                                                                aria-haspopup="true"
                                                                                role="example"
                                                                                type="light"
                                                                            >
                                                                                <p>Delivery Address </p>
                                                                            </ReactTooltip>
                                                                        </span>
                                                                        {/* <MdLocationOn color="red" size='12px'/> */}
                                                                        <div>{item?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                                    </div>
                                                                    <div>Payment status: <span style={{ color: 'var(--blue)', fontWeight: 'bold' }}> {item.payment_status === "pay_on_delivery" ? 'Pay on delievry' : item.payment_status === 'success' ? 'Paid' : item.payment_status === 'NA' ? 'NA' : "Not paid"}</span></div>
                                                                    <div>Delivery Status: <span style={{ color: 'var(--brown)', fontWeight: 'bold' }}> Picked up</span></div>
                                                                    <div className="quoteBody_items statusCheck" > Mode of payment: <span style={{ color: '#004DC1' }}>

                                                                        {
                                                                            typeof (item?.mode_of_payment) === "string" ?
                                                                                <>
                                                                                    {
                                                                                        item?.mode_of_payment === "pay_by_cash" ?
                                                                                            <span>Cash</span> :
                                                                                            item?.mode_of_payment === "pay_by_transfer" ?
                                                                                                <span>Transfer</span> :
                                                                                                item?.mode_of_payment === "pay_by_ussd" ?
                                                                                                    <span>USSD</span> :
                                                                                                    item?.mode_of_payment === "pay_by_wallet" ?
                                                                                                        <span>Online</span> :
                                                                                                        item?.mode_of_payment === "pay_by_card" ?
                                                                                                            <span>Card</span> :
                                                                                                            <span>{item?.mode_of_payment}</span>
                                                                                    }

                                                                                </>

                                                                                :
                                                                                <span>N/A</span>


                                                                        }

                                                                    </span>

                                                                    </div>
                                                                </div>
                                                                <div style={{ display: 'flex', gap: '15px', alignItems: 'center', justifyContent: "center" }}>

                                                                    <span onClick={() => {
                                                                        history.push(GlobalizationLanguagewithCOntryCode(`/message/${type}/${item.delivery_id}`, country, visitingCountryCode))
                                                                    }}>
                                                                        <IoMdMailOpen size='20px' color='#484848' />
                                                                    </span>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    type === 'picked_up' ?

                                                        <div className="quoteCard">
                                                            <div className='quoteCardTitle'>
                                                                <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                                                                    <div className='radiusCard'>{item?.assigned_agent_details?.agent_name?.charAt(0)?.toUpperCase()}</div>
                                                                    <span>{item.assigned_agent_details?.agent_name}</span>
                                                                </div>
                                                                <span
                                                                    style={{ color: 'var(--main)', cursor: 'pointer', textDecoration: 'underline', fontSize: '12px', userSelect: 'none' }}
                                                                    onClick={() => { setAsignType('reassign'); agentID(item.assigned_agent_details?.pickup_agent_id); toshowAgents(); setDeliveryId(item.delivery_id); setDeli_location(item.delivery_location); setQuoteStatus(item.quote_status); setDeliveryStatus(item.delivery_status); }}
                                                                > Reassign agent</span>
                                                            </div>
                                                            <div className="quoteBody">
                                                                <div className="quoteBodyDetails">
                                                                    <div style={{ display: 'flex', gap: '5px' }}>
                                                                        <div className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${item.image_urls[0]})` }}
                                                                            onClick={() => {
                                                                                showMe("picked_up", item.delivery_id, item?.delivery_location);
                                                                                setagentDetails(agents.filter(function (agent) { return agent.user_id === item.assigned_agent_details.pickup_agent_id }));
                                                                            }}>
                                                                        </div>
                                                                        <div className="quoteBodyDetailsLeft">
                                                                            <div className="quoteBody_Desc">
                                                                                <span onClick={() => {
                                                                                    showMe("picked_up", item.delivery_id, item?.delivery_location);
                                                                                    setagentDetails(agents.filter(function (agent) { return agent.user_id === item.assigned_agent_details.pickup_agent_id }));
                                                                                }}>{item?.item_desc?.charAt(0)?.toUpperCase() + item?.item_desc?.toLowerCase().slice(1)}</span>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div style={{ display: 'flex', width: '80px', justifyContent: 'flex-end', }}>
                                                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                            <div>
                                                                                <div style={{ fontSize: '14px' }}><Currency location={item.delivery_location} />{numberWithCommas.format(item.quote_amount)}</div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div className="quoteBodyBtn">
                                                                    <div style={{ fontSize: '13px', display: 'flex', flexDirection: 'column', gap: '7px' }}>
                                                                        <div className="quoteBody_items">
                                                                            <span data-tip
                                                                                data-for="globalMode">

                                                                                <IconContext.Provider
                                                                                    value={{
                                                                                        color: "#2e2e2e",

                                                                                    }}
                                                                                >
                                                                                    {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                                    <FaShuttleVan color="green" size='12px' />
                                                                                </IconContext.Provider>
                                                                                <ReactTooltip
                                                                                    id="globalMode"
                                                                                    aria-haspopup="true"
                                                                                    role="example"
                                                                                    type="light"
                                                                                >
                                                                                    <p>Mode of Delivery</p>
                                                                                </ReactTooltip>
                                                                            </span>
                                                                            {/* <FaShuttleVan color="green" size='12px'/> */}
                                                                            <div>{item?.mode_of_delivery?.charAt(0)?.toUpperCase() + item?.mode_of_delivery?.toLowerCase().slice(1)}</div>
                                                                        </div>
                                                                        <div className="quoteBody_items">
                                                                            <span data-tip
                                                                                data-for="globaldelibery">

                                                                                <IconContext.Provider
                                                                                    value={{
                                                                                        color: "#2e2e2e",

                                                                                    }}
                                                                                >
                                                                                    <MdLocationOn color="green" size='12px' />
                                                                                </IconContext.Provider>
                                                                                <ReactTooltip
                                                                                    id="globaldelibery"
                                                                                    aria-haspopup="true"
                                                                                    role="example"
                                                                                    type="light"
                                                                                >
                                                                                    <p>PickUp Address</p>
                                                                                </ReactTooltip>
                                                                            </span>
                                                                            <div>{item?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                                        </div>
                                                                        <div className="quoteBody_items">
                                                                            <span data-tip
                                                                                data-for="globaldistance">
                                                                                <IconContext.Provider
                                                                                    value={{
                                                                                        color: "#2e2e2e",

                                                                                    }}
                                                                                >
                                                                                    <MdLocationOn color="red" size='12px' />
                                                                                </IconContext.Provider>
                                                                                <ReactTooltip
                                                                                    id="globaldistance"
                                                                                    aria-haspopup="true"
                                                                                    role="example"
                                                                                    type="light"
                                                                                >
                                                                                    <p>Delivery Address </p>
                                                                                </ReactTooltip>
                                                                            </span>
                                                                            <div>{item?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                                        </div>
                                                                        <div>Payment status: <span style={{ color: 'var(--blue)', fontWeight: 'bold' }}> {item.payment_status === "pay_on_delivery" ? 'Pay on delievry' : item.payment_status === 'success' ? 'Paid' : item.payment_status === 'NA' ? 'NA' : "Not paid"}</span></div>
                                                                        <div>Delivery Status: <span style={{ color: 'var(--brown)', fontWeight: 'bold' }}> Out for delivery</span></div>


                                                                        <div>Pick up time: {moment(item?.assigned_agent_details?.pickup_timestamp).format("DD-MM-YYYY HH:MM:SS")}
                                                                        </div>
                                                                        <div className="quoteBody_items statusCheck" > Mode of payment: <span style={{ color: '#004DC1' }}>

                                                                            {
                                                                                typeof (item?.mode_of_payment) === "string" ?
                                                                                    <>
                                                                                        {
                                                                                            item?.mode_of_payment === "pay_by_cash" ?
                                                                                                <span>Cash</span> :
                                                                                                item?.mode_of_payment === "pay_by_transfer" ?
                                                                                                    <span>Transfer</span> :
                                                                                                    item?.mode_of_payment === "pay_by_ussd" ?
                                                                                                        <span>USSD</span> :
                                                                                                        item?.mode_of_payment === "pay_by_wallet" ?
                                                                                                            <span>Online</span> :
                                                                                                            item?.mode_of_payment === "pay_by_card" ?
                                                                                                                <span>Card</span> :
                                                                                                                <span>{item?.mode_of_payment}</span>
                                                                                        }

                                                                                    </>

                                                                                    :
                                                                                    <span>N/A</span>


                                                                            }

                                                                        </span>

                                                                        </div>

                                                                    </div>
                                                                    <div style={{ display: 'flex', gap: '15px', alignItems: 'center', justifyContent: "center" }}>
                                                                        {/* <Button
                                                                            size="small"
                                                                            variant="contained"
                                                                            style={{ fontSize: '11px', maxHeight: '30px', border: '1px solid var(--main)', backgroundColor: 'white', color: 'var(--main)', textTransform: 'none' }}
                                                                            onClick={() => {
                                                                                history.push(`/message/${type}/${item.delivery_id}`)

                                                                            }}>
                                                                            <div style={{ display: 'flex', gap: '5px', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <span><IoMdMailOpen size='12px' /></span>
                                                                                <span>Message</span>
                                                                            </div>
                                                                        </Button> */}
                                                                        <span onClick={() => {
                                                                            history.push(GlobalizationLanguagewithCOntryCode(`/message/${type}/${item.delivery_id}`, country, visitingCountryCode))
                                                                        }}>
                                                                            <IoMdMailOpen size='20px' color='#484848' />
                                                                        </span>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        type === 'decline' ?
                                                            rejectType === 'sender' ?

                                                                <div className="quoteCard">
                                                                    <div className="quoteBody">
                                                                        <div className="quoteBodyDetails">
                                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                                <div onClick={() => showMe("decline", item.delivery_id, item?.delivery_location)} className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${item.image_urls[0]})` }}>
                                                                                </div>
                                                                                <div className="quoteBodyDetailsLeft">
                                                                                    <div className="quoteBody_Desc">
                                                                                        <span onClick={() => showMe("decline", item.delivery_id, item?.delivery_location)}>{item?.item_desc?.charAt(0)?.toUpperCase() + item?.item_desc?.toLowerCase().slice(1)}</span>
                                                                                    </div>
                                                                                    <div className="quoteBody_items">
                                                                                        {
                                                                                            item.comment_on_quote_rejected === 'price_too_high' ?
                                                                                                <span style={{ fontSize: '12px' }}>The Lister rejected your quote because he/she feels you price is too high</span>
                                                                                                :
                                                                                                item.comment_on_quote_rejected === 'no_insurance' ?
                                                                                                    <span style={{ fontSize: '12px' }}>The Lister rejected your quote because he/she feels you have no insurance policy</span>
                                                                                                    :
                                                                                                    item.comment_on_quote_rejected === 'low_rating' ?
                                                                                                        <span style={{ fontSize: '12px' }}>The Lister rejected your quote because he/she feels you have low rating</span>
                                                                                                        :
                                                                                                        item.comment_on_quote_rejected
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ display: 'flex', width: '80px', justifyContent: 'flex-end', }}>
                                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                                    <div>
                                                                                        <div style={{ fontSize: '11px', color: 'red' }}>Rejected quote</div>
                                                                                        <div style={{ fontSize: '14px', color: 'red' }}><Currency location={item.delivery_location} />{numberWithCommas.format(item.quote_amount)}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="quoteBodyBtn">
                                                                            <div></div>
                                                                            <div style={{ display: 'flex', gap: '15px', alignItems: 'center', justifyContent: "center" }}>
                                                                                {/* <div className="quoteBtn2"
                                                                                    onClick={() => {
                                                                                        history.push(`/message/${type}/${item.delivery_id}`)
                                                                                       }}>
                                                                                    <IoMdMailOpen size='12px' />
                                                                                    <span>Message</span>
                                                                                </div>
                                                                                <div className="quoteBtn1" onClick={() => { setitemToQuote(item); handleClickOpen() }}>
                                                                                    <IoMdOpen size='12px' />
                                                                                    <span>Requote</span>
                                                                                </div> */}
                                                                                <span onClick={() => {
                                                                                    history.push(GlobalizationLanguagewithCOntryCode(`/message/${type}/${item.delivery_id}`, country, visitingCountryCode))
                                                                                }}>
                                                                                    <IoMdMailOpen size='20px' color='#484848' />
                                                                                </span>

                                                                                <span onClick={() => { setitemToQuote(item); handleClickOpen() }}
                                                                                >
                                                                                    <IoMdOpen size='20px' color='#484848' />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <>
                                                                    <div className="quoteCard">
                                                                        <div className="quoteBody">
                                                                            <div className="quoteBodyDetails">
                                                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                                                    <div
                                                                                        // onClick={()=>showMe(item)} 
                                                                                        className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${item.image_urls[0]})` }}>
                                                                                    </div>
                                                                                    <div className="quoteBodyDetailsLeft">
                                                                                        <div className="quoteBody_Desc">
                                                                                            <span
                                                                                            // onClick={()=>showMe(item)}
                                                                                            >{item?.item_desc?.charAt(0)?.toUpperCase() + item?.item_desc?.toLowerCase().slice(1)}</span>
                                                                                        </div>
                                                                                        <div className="quoteBody_items">
                                                                                            <span style={{ fontSize: '12px' }}>{item.comment_by_provider}</span>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            :
                                                            type === 'watchlist' || type === 'save' ?
                                                                <div className="quoteCard">
                                                                    <div className="quoteBody" onClick={() => history.push(GlobalizationLanguagewithCOntryCode(`/open-delivery/${item.delivery_id}`, country, visitingCountryCode))} >
                                                                        <div className="quoteBodyDetails">
                                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                                <div className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${item.image_urls[0]})` }}
                                                                                    onClick={() => history.push(GlobalizationLanguage(`/open-delivery/${item.delivery_id}`))}
                                                                                >
                                                                                </div>
                                                                                <div className="quoteBodyDetailsLeft">
                                                                                    <div className="quoteBody_Desc">
                                                                                        <span
                                                                                            onClick={() => history.push(GlobalizationLanguage(`/open-delivery/${item.delivery_id}`))}
                                                                                        >{item?.item_desc?.charAt(0)?.toUpperCase() + item?.item_desc?.toLowerCase().slice(1)}</span>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <div className="quoteBodyBtn">
                                                                            <div>
                                                                                <div className="quoteBody_items">
                                                                                    <span data-tip
                                                                                        data-for="globalMode">

                                                                                        <IconContext.Provider
                                                                                            value={{
                                                                                                color: "#2e2e2e",

                                                                                            }}
                                                                                        >
                                                                                            {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                                            <FaShuttleVan color="green" size='12px' />
                                                                                        </IconContext.Provider>
                                                                                        <ReactTooltip
                                                                                            id="globalMode"
                                                                                            aria-haspopup="true"
                                                                                            role="example"
                                                                                            type="light"
                                                                                        >
                                                                                            <p>Mode of Delivery</p>
                                                                                        </ReactTooltip>
                                                                                    </span>
                                                                                    {/* <FaShuttleVan color="green" size='12px'/> */}
                                                                                    <div>{item?.mode_of_delivery?.charAt(0)?.toUpperCase() + item?.mode_of_delivery?.toLowerCase().slice(1)}</div>
                                                                                </div>
                                                                                <div className="quoteBody_items">
                                                                                    <span data-tip
                                                                                        data-for="globaldelibery">

                                                                                        <IconContext.Provider
                                                                                            value={{
                                                                                                color: "#2e2e2e",
                                                                                            }}
                                                                                        >
                                                                                            {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                                            <MdLocationOn color="green" size='12px' />
                                                                                        </IconContext.Provider>
                                                                                        <ReactTooltip
                                                                                            id="globaldelibery"
                                                                                            aria-haspopup="true"
                                                                                            role="example"
                                                                                            type="light"
                                                                                        >
                                                                                            <p>PickUp Address</p>
                                                                                        </ReactTooltip>
                                                                                    </span>
                                                                                    {/* <MdLocationOn color="green" size='12px'/> */}
                                                                                    <div>{item?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                                                </div>
                                                                                <div className="quoteBody_items">
                                                                                    <span data-tip
                                                                                        data-for="globaldistance">
                                                                                        <IconContext.Provider
                                                                                            value={{
                                                                                                color: "#2e2e2e",

                                                                                            }}
                                                                                        >
                                                                                            {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                                            <MdLocationOn color="red" size='12px' />
                                                                                        </IconContext.Provider>
                                                                                        <ReactTooltip
                                                                                            id="globaldistance"
                                                                                            aria-haspopup="true"
                                                                                            role="example"
                                                                                            type="light"
                                                                                        >
                                                                                            <p>Delivery Address </p>
                                                                                        </ReactTooltip>
                                                                                    </span>
                                                                                    {/* <MdLocationOn color="red" size='12px'/> */}
                                                                                    <div>{item?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</div>
                                                                                </div>
                                                                                <div className="quoteBody_items statusCheck" > Status: <span style={{ color: '#004DC1' }}> Awaiting quote</span> </div>
                                                                                <div className="quoteBody_items statusCheck" > No. quote: <span style={{ color: '#004DC1' }}>{item.quote} </span></div>

                                                                            </div>
                                                                            <div style={{ display: 'flex', gap: '15px', alignItems: 'center', justifyContent: "center" }}>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                type === 'outbid' ?

                                                                    <div className="quoteCard">
                                                                        <div className="quoteBody">
                                                                            <div className="quoteBodyDetails">
                                                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                                                    <div className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${item.image_urls[0]})` }}>
                                                                                    </div>
                                                                                    <div className="quoteBodyDetailsLeft">
                                                                                        <div className="quoteBody_Desc">
                                                                                            <span>{item?.item_desc?.charAt(0)?.toUpperCase() + item?.item_desc?.toLowerCase().slice(1)}</span>
                                                                                        </div>
                                                                                        <div className="quoteBody_items">
                                                                                            <span style={{ fontSize: '12px' }}>You got out bidded by another provider</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ display: 'flex', width: '80px', justifyContent: 'flex-end', }}>
                                                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                                        <div>
                                                                                            <div style={{ fontSize: '14px' }}><Currency location={item.delivery_location} />{numberWithCommas.format(item.quote_amount)}</div>
                                                                                            <div style={{ fontSize: '9px', color: 'red' }}>My quote</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                        }
                    </div>
                })
            }
            {
                type === 'complete' ?
                    <>
                        {
                            showCount ?
                                <div style={{ width: '100%' }}>
                                    <div style={{ width: '100%', padding: '12px', borderTopLeftRadius: '7px', borderTopRightRadius: '7px', border: '1px solid #EAEAEA', backgroundColor: '#EAEAEA', textAlign: 'right' }}>Completed</div>
                                    <div onClick={() => { setClickCompleted('today'); removeTopMenu(false); setcountToShow(todayCount); setshowCount(!showCount) }} style={{ width: '100%', cursor: 'pointer', padding: '12px 20px', border: '1px solid #EAEAEA', display: 'flex', alignItems: 'center', backgroundColor: '#ffffff', justifyContent: 'space-between' }}>
                                        <span style={{ fontWeight: '500' }}>Today</span><span>{todayCount.length}</span>
                                    </div>
                                    <div onClick={() => { setClickCompleted('this_week'); removeTopMenu(false); setcountToShow(weekCount); setshowCount(!showCount) }} style={{ width: '100%', cursor: 'pointer', padding: '12px 20px', border: '1px solid #EAEAEA', display: 'flex', alignItems: 'center', backgroundColor: '#ffffff', justifyContent: 'space-between' }}>
                                        <span style={{ fontWeight: '500' }}>This week</span><span>{weekCount.length}</span>
                                    </div>
                                    <div onClick={() => { setClickCompleted('this_month'); removeTopMenu(false); setcountToShow(monthCount); setshowCount(!showCount) }} style={{ width: '100%', cursor: 'pointer', padding: '12px 20px', border: '1px solid #EAEAEA', display: 'flex', alignItems: 'center', backgroundColor: '#ffffff', justifyContent: 'space-between' }}>
                                        <span style={{ fontWeight: '500' }}>This month</span><span>{monthCount.length}</span>
                                    </div>
                                    <div onClick={() => { setClickCompleted('this_quater'); removeTopMenu(false); setcountToShow(TmonthCount); setshowCount(!showCount) }} style={{ width: '100%', cursor: 'pointer', padding: '12px 20px', border: '1px solid #EAEAEA', display: 'flex', alignItems: 'center', backgroundColor: '#ffffff', justifyContent: 'space-between' }}>
                                        <span style={{ fontWeight: '500' }}>This quarter</span><span>{TmonthCount.length}</span>
                                    </div>

                                    <div onClick={() => { setClickCompleted('all'); removeTopMenu(false); setcountToShow(quote); setshowCount(!showCount) }} style={{ width: '100%', cursor: 'pointer', padding: '12px 20px', borderBottomLeftRadius: '7px', borderBottomRightRadius: '7px', border: '1px solid #EAEAEA', display: 'flex', alignItems: 'center', backgroundColor: '#ffffff', justifyContent: 'space-between' }}>
                                        <span style={{ fontWeight: '500' }}>Overall</span><span>{completed_quotes?.length}</span>
                                    </div>
                                </div>
                                :
                                countToShow.map((toShow) => {
                                    return <div className="quoteCard">
                                        <div className="quoteBody">
                                            <div className="quoteBodyDetails">
                                                <div style={{ display: 'flex', gap: '5px' }}>

                                                    <div onClick={() => showMe("complete", toShow.delivery_id, toShow?.delivery_location)} className="quoteBodyDetailsRight" style={{ backgroundImage: `url(${toShow.image_urls[0]})` }}>
                                                    </div>
                                                    <div className="quoteBodyDetailsLeft">
                                                        <div className="quoteBody_Desc">
                                                            <span onClick={() => {
                                                                showMe("complete", toShow.delivery_id);

                                                            }}>{toShow?.item_desc?.charAt(0)?.toUpperCase() + toShow?.item_desc?.toLowerCase().slice(1)}</span>
                                                        </div>
                                                        <div className="quoteBody_items">
                                                            <span data-tip
                                                                data-for="globalMode">

                                                                <IconContext.Provider
                                                                    value={{
                                                                        color: "#2e2e2e",

                                                                    }}
                                                                >
                                                                    {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                    <FaShuttleVan color="green" size='12px' />
                                                                </IconContext.Provider>
                                                                <ReactTooltip
                                                                    id="globalMode"
                                                                    aria-haspopup="true"
                                                                    role="example"
                                                                    type="light"
                                                                >
                                                                    <p>Mode of Delivery</p>
                                                                </ReactTooltip>
                                                            </span>
                                                            {/* <FaShuttleVan color="green" size='12px'/> */}
                                                            <div>{toShow?.mode_of_delivery?.charAt(0)?.toUpperCase() + toShow?.mode_of_delivery?.toLowerCase().slice(1)}</div>
                                                        </div>
                                                        <div className="quoteBody_items">
                                                            <span data-tip
                                                                data-for="globaldelibery">

                                                                <IconContext.Provider
                                                                    value={{
                                                                        color: "#2e2e2e",

                                                                    }}
                                                                >
                                                                    {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                    <MdLocationOn color="green" size='12px' />
                                                                </IconContext.Provider>
                                                                <ReactTooltip
                                                                    id="globaldelibery"
                                                                    aria-haspopup="true"
                                                                    role="example"
                                                                    type="light"
                                                                >
                                                                    <p>PickUp Address</p>
                                                                </ReactTooltip>
                                                            </span>
                                                            {/* <MdLocationOn color="green" size='12px'/> */}
                                                            <div>{toShow.from}</div>
                                                        </div>
                                                        <div className="quoteBody_items">
                                                            <span data-tip
                                                                data-for="globaldistance">
                                                                <IconContext.Provider
                                                                    value={{
                                                                        color: "#2e2e2e",
                                                                    }}
                                                                >
                                                                    {/* <GiPathDistance   style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px'}} /> */}
                                                                    <MdLocationOn color="red" size='12px' />
                                                                </IconContext.Provider>
                                                                <ReactTooltip
                                                                    id="globaldistance"
                                                                    aria-haspopup="true"
                                                                    role="example"
                                                                    type="light">
                                                                    <p>Delivery Address </p>
                                                                </ReactTooltip>
                                                            </span>
                                                            {/* <MdLocationOn color="red" size='12px'/> */}
                                                            <div>{toShow.to}</div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div style={{ display: 'flex', width: '80px', justifyContent: 'flex-end', }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                        <div>
                                                            <div style={{ fontSize: '14px' }}><Currency location={toShow.delivery_location} />{numberWithCommas.format(toShow.quote_amount)}</div>

                                                            <div style={{ fontSize: '9px', color: 'var(--main)' }}>Your quote</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                })
                        }


                    </>

                    :
                    null
            }

            <DialogOutClick
                open={openQuote}
                handleClose={handleClose}
            >
                <SubmitQuote item={itemToQuote} closePopup={handleClose} />
            </DialogOutClick>




            <DialogPop
                openPopup={openaccept}
                setopenPopup={setopenaccept}
                head="Acknowledgement"
            >

                <p>
                    You are about to accept. Are you sure you are available to do this delivery?

                </p>


                <div
                    // style={{border: "1px solid #50B14A",
                    //                     backgroundColor: "#50B14A",
                    //                     color: "#ffffff", padding: "5px", fontSize: "12px"}} 
                    style={{ display: 'flex', justifyContent: 'center', marginTop: "30px" }}
                >
                    <button
                        style={{ background: "#50B14A", border: "1px solid #50B14A", cursor: "pointer", borderRadius: '4px', color: "#ffffff", width: "161px", height: "42px" }}
                        onClick={AcceptAck}
                    >
                        {submiting ? "Loading..." : "Accept"}
                    </button>
                </div>



            </DialogPop>



            <DialogPop
                openPopup={openreject}
                setopenPopup={setopenreject}
                head="Acknowledgement"
            >

                <p>
                    You are about to reject. Are you sure you won’t be available to do this delivery?

                </p>


                <div
                    style={{ display: 'flex', justifyContent: 'center', marginTop: "30px" }}
                >
                    <button onClick={RejectAck} style={{ background: "#F40101", border: "1px solid #F40101", cursor: "pointer", borderRadius: '4px', color: "#ffffff", width: "161px", height: "42px" }} >
                        {rejecting ? "Loading..." : "Reject"}
                    </button>
                </div>



            </DialogPop>
        </>
    )
}

export default CurrentCard
