import React from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PuffLoader from 'react-spinners/PuffLoader';

const useStyles = makeStyles((theme) => ({
  right: {
    margin: theme.spacing(0, 0, 4),
    backgroundColor:'var(--main)',
    color:'white',
    fontWeight:'600',
    marginBottom:'15px'
  },
  left: {
    margin: theme.spacing(0, 0, 4),
    backgroundColor:'white',
    color:'var(--main)',
    border:'1px solid var(--main)',
    fontWeight:'600',
    marginBottom:'15px'
  }
  }));

function RegBtn({rightText, leftText, rightClick, leftClick, uploading}) {
    const classes = useStyles();
    return (
      <div style={{width:'100%', display:'flex', gap:'15px'}}>
          <Button
              onClick={leftClick}
              type="button"
              fullWidth
              variant="contained"
              className={classes.left}
              >
              <div style={{width:'100%', display:'flex', justifyContent:'center', textTransform:'none'}}>
                  {leftText}
              </div>
          </Button>
          <Button
              onClick={rightClick}
              type="button"
              fullWidth
              variant="contained"
              className={classes.right}
              >
                
                  <div style={{width:'100%', display:'flex', justifyContent:'center', textTransform:'none'}}>
                   {
                    uploading ? 
                    <PuffLoader color='white' loading={uploading}  speedMultiplier={1} size={24}/>
                    : 
                    rightText
                  }
                </div>
                
              
          </Button>
      </div>
        
    )
}

export default RegBtn
