import Dialog from '@material-ui/core/Dialog';

export default function DialogOutClick({open,children, handleClose}) { 

  return (
    <div className='dialogOut'>
      <Dialog
        open={open}
        onClose={handleClose}>

        {children}
       
      </Dialog>
    </div>
  );
}