/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useContext, useCallback } from "react";
import CountDown from "../../../../../components/CountDown";
import moment from "moment";
import Frame from "../../../../../assets/Frame.svg";
import { useHistory } from "react-router-dom";
import editicon from "../../../../../assets/editicon.svg";
import trash from "../../../../../assets/trash.svg";
import note from "../../../../../assets/note.svg";
import cardposagent from "../../../../../assets/cardposagent.svg";
import trackingtrack from "../../../../../assets/trackingtrack.svg";
import DialogPop from "../../../../../components/DialogPop";
import { PuffLoader } from "react-spinners";
import axios from "axios";
import SecureLS from "secure-ls";
import { ConnContext } from "../../../../../context/ConnContext";
import Swal from "sweetalert2";
import DialogOutClick from "../../../../../components/DialogOutClick";

import jwt_decode from "jwt-decode";
import LoginCompV2 from "../../../../../components/LoginCompV2";
import ReactTooltip from "react-tooltip";
import GlobalizationLanguage, {
  GlobalizationLanguagewithCOntryCode,
} from "../../../../../components/GlobalizationLanguage";
import Cookies from "js-cookie";

import '../../../../../../src/components/Stripe/stripestyle.css'
import Currency from "../../../../../utils/Currency";
import MakePayment from "../../../../../components/MakePayment";


function QuoteTablerow({
  promoDetails,
  item,
  index,
  onClick,
  activeValue,
  bool,
  statusmemory,
  wallet_bln,
  setwallet_bln
}) {

  const numberWithCommas = new Intl.NumberFormat("en-GB");
  var editquote = GlobalizationLanguage(editquote);
  var viewQuote_request_delivery = GlobalizationLanguage(
    "/viewQuote/request-a-delivery"
  );
  var activedeliveries = GlobalizationLanguage(
    `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`
  );
  var timeout = GlobalizationLanguage("/timeout");
  var stripecheckout = GlobalizationLanguage("/stripecheckout");

  const [showDeleting, setshowDeleting] = useState(false);
  const [deleting, setdeleting] = useState(false);
  const { visitingCountryCode } = useContext(ConnContext);
  const [itemToDelete, setitemToDelete] = useState([]);
  const [clicked, setclicked] = useState("");
  const [showpayment_method, setshowPayment_method] = useState(false);

  const [subTotal, setsubTotal] = useState(0);

  const [total, settotal] = useState(0);
  const [modeofpayment, setModeOfPayment] = useState("");
  
  const [ShowLogin, setShowLogin] = useState(false);
  const [showrelist, setshowrelist] = useState(false);
  const [time, setTime] = useState("");
  const history = useHistory();
  const [indexclicked, setindexclicked] = useState([]);
  const [waiting, setWaiting] = useState(false);
  var countryvalue =
    Cookies.get("i18next") || visitingCountryCode.toLowerCase();
  const [country, setCountry] = useState(countryvalue);

  const [withdrawamt, setwithdrawamt] = useState("");
  const [addamt, setaddamt] = useState();
  const [isloading, setisloading] = useState(false);
  const [creditstate, setcreditstate] = useState(false);
  const [allstatet, setallstatet] = useState("all");

  const [walletHistory, setwalletHistory] = useState([]);
  const [walletBalc, setwalletBalc] = useState(0);
  const [refreshBalance, setrefreshBalance] = useState(false);
  const [checkfocus, setcheckfocus] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [digit, setdigit] = useState(0);

  const [textId, settextId] = useState({ textId: 0 });

  const placeholderText = ["one", "two", "three"];
  const [state, setState] = useState("");


  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: ls?.get("_utk"),
    },
  });

  const [TransDate, setTransDate] = useState('')



  const refresh = () => {
    window.location.reload();
  }

  useEffect(() => {
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    setTransDate(date);
  }, [])


  const authAxios3 = axios.create({
    baseURL: ``,
    headers: {
      Authorization: ls.get("_utk"),
    },

    response: true,
  });

  useEffect(() => {
    setCountry(Cookies.get("i18next"));
  }, [Cookies.get("i18next")]);

  const ShowMeHereNow = ({ item, index, e }) => {
    var Tracking = GlobalizationLanguage(`/tracking/${item.delivery_id}`);
    return (
      <div data-key={index}>
        <ReactTooltip
          id="editicon"
          aria-haspopup="true"
          type="light"
          role="example"
        >
          <p>Edit Item</p>
        </ReactTooltip>

        <ReactTooltip
          id="deleteicon"
          aria-haspopup="true"
          type="light"
          role="example"
        >
          <p>Delete Item</p>
        </ReactTooltip>

        <ReactTooltip
          id="numberofquotes"
          aria-haspopup="true"
          type="light"
          role="example"
        >
          <p>Total number of quote received</p>
        </ReactTooltip>

        <ReactTooltip
          id="makepayment"
          aria-haspopup="true"
          type="light"
          role="example"
        >
          <p>Make Payment</p>
        </ReactTooltip>

        <ReactTooltip
          id="trackitem"
          aria-haspopup="true"
          type="light"
          role="example"
        >
          <p>Track Item</p>
        </ReactTooltip>

        <ReactTooltip
          id="menuicon"
          aria-haspopup="true"
          type="light"
          role="example"
        >
          <p>Menu Icon</p>
        </ReactTooltip>

        {item?.delivery_status === "awaiting_quote" &&
          item?.total_quote_received <= 0 ? (
          <div style={{ right: "50px" }} className="awaiting-top-bottom">
            <div
              onClick={() =>
                history.push({
                  // pathname: editquote,
                  pathname: GlobalizationLanguagewithCOntryCode(
                    `/editquote`,
                    country,
                    visitingCountryCode
                  ),
                  state: item,
                })
              }
              className="top-awaiting"
              data-tip
              data-for="editicon"
            >
              <img src={editicon} alt="edit_icon" />
            </div>

            <div
              onClick={() => {
                setitemToDelete(item);
                setshowDeleting(true);
              }}
              className="bottom-awaiting"
              data-tip
              data-for="deleteicon"
            >
              <img src={trash} alt="trash_icon" />
            </div>
          </div>
        ) : item?.total_quote_received >= 1 &&
          item.delivery_status === "awaiting_quote" ? (
          <div style={{ right: "50px" }} className="awaiting-top-bottom">
            <div data-tip data-for="numberofquotes" className="top-awaiting">
              <div
                onClick={() =>
                  history.push(
                    // activedeliveries
                    GlobalizationLanguagewithCOntryCode(
                      `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                      country,
                      visitingCountryCode
                    )
                  )
                }
                className="top-cart"
              >
                <div className="top-cart-icon">
                  {item?.total_quote_received}
                </div>
                <img src={note} alt="edit_icon" />
              </div>
            </div>

            <div
              onClick={() =>
                item?.total_quote_received >= 1 &&
                  item.delivery_status === "awaiting_quote"
                  ? setshowrelist(true)
                  : () =>
                    history.push({
                      pathname: editquote,
                      state: item,
                    })
              }
              className="bottom-awaiting"
              data-tip
              data-for="editicon"
            >
              <img src={editicon} alt="trash_icon" />
            </div>

            <div
              onClick={() => {
                setitemToDelete(item);
                setshowDeleting(true);
              }}
              className="bottom-awaiting"
              data-tip
              data-for="deleteicon"
            >
              <img src={trash} alt="trash_icon" />
            </div>
          </div>
        ) : item.delivery_status === "awaiting_provider_acknowledgment" ? (
          <div className="ack-div">
            {time < 0 ? (
              <div>
                <div className="ack-div-">
                  <div className="waitdiv">
                    <button
                      onClick={() => {
                        tokenCheck(item);
                        setclicked(item.delivery_id);
                      }}
                      className="wait-for-morequote"
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      {" "}
                      {waiting && clicked === item.delivery_id
                        ? "Please wait"
                        : "Wait for more quotes "}
                    </button>

                    <button
                      type="button"
                      onClick={() => acceptExistClick(item.delivery_id)}
                      className="accept-existing-quote"
                    >
                      Accept another quote{" "}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="accptdiv">
                  <button
                    disabled
                    className="accept-existing-quote-1"
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    {" "}
                    Wait for more quotes 4{" "}
                  </button>

                  <button disabled className="accept-existing-quote-2">
                    Accept another quote
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : item.delivery_status === "awaiting_agent_assignment" ? (
          <div style={{ right: "50px" }} className="awaiting-top-bottom">
            <div
              onClick={() => {
                if (item?.payment_status === "success") {
                } else {
                  setModeOfPayment(item?.mode_of_payment);
                  setshowPayment_method(true);
                  settotal(item.quote_amount);
                  setsubTotal(item.quote_amount);
                  // console.log(item, 'itemmm')
                }
              }}
              className="top-awaiting"
              data-tip
              data-for="makepayment"
            >
              <img src={cardposagent} alt="edit_icon" />
            </div>


          </div>
        ) : item.delivery_status === "awaiting_pickup" ? (

          <div style={{ right: "50px" }} className="awaiting-top-bottom">
            <div
              onClick={() => {
                if (item?.payment_status === "success") {
                } else {
                  setshowPayment_method(true);
                  setModeOfPayment(item?.mode_of_payment);
                  settotal(item?.quote_amount);
                  setsubTotal(item.quote_amount);
                  console.log(item, 'itemmm')
                }
              }}
              className="top-awaiting"
              data-tip
              data-for="makepayment"
            >
              <img src={cardposagent} alt="edit_icon" />
            </div>

            <div className="bottom-awaiting" data-tip data-for="trackitem">
              <img src={trackingtrack} alt="trash_icon" />
            </div>
          </div>
        ) : item.delivery_status === "in_transit" ? (
          <div style={{ right: "50px" }} className="awaiting-top-bottom">
            <div
              onClick={() => {
                if (item?.payment_status === "success") {
                } else {
                  setshowPayment_method(true);
                  setModeOfPayment(item?.mode_of_payment);
                  settotal(item.quote_amount);
                  setsubTotal(item.quote_amount);
                  console.log(item, 'itemmm')
                }
              }}
              className="top-awaiting"
              data-tip
              data-for="makepayment"
            >
              <img src={cardposagent} alt="edit_icon" />
            </div>

            <div
              className="bottom-awaiting"
              onClick={() => history.push(Tracking)}
              data-tip
              data-for="trackitem"
            >
              <img src={trackingtrack} alt="trash_icon" />
            </div>
          </div>
        ) : item.delivery_status === "out_for_delivery" ? (
          <div style={{ right: "50px" }} className="awaiting-top-bottom">
            <div
              onClick={() => {
                if (item?.payment_status === "success") {
                } else {
                  setshowPayment_method(true);
                  setModeOfPayment(item?.mode_of_payment);
                  settotal(item.quote_amount);
                  setsubTotal(item.quote_amount);
                  console.log(item, 'itemmm')
                }
              }}
              className="top-awaiting"
              data-tip
              data-for="makepayment"
            >
              <img src={cardposagent} alt="edit_icon" />
            </div>

            <div
              className="bottom-awaiting"
              onClick={() => history.push(Tracking)}
              data-tip
              data-for="trackitem"
            >
              <img src={trackingtrack} alt="trash_icon" />
            </div>
          </div>
        ) : item.delivery_status === "delivered" ? (
          <div style={{ right: "50px" }} className="awaiting-top-bottom">
            <div
              className="bottom-awaiting"
              onClick={() => history.push(Tracking)}
              data-tip
              data-for="trackitem"
            >
              <img src={trackingtrack} alt="trash_icon" />
            </div>
          </div>
        ) : item.delivery_status === "missed_delivery" ? (
          <div style={{ right: "50px" }} className="awaiting-top-bottom">
            <div
              onClick={() => {
                if (item?.payment_status === "success") {
                } else {
                  setshowPayment_method(true);
                  setModeOfPayment(item?.mode_of_payment);
                  settotal(item.quote_amount);
                  setsubTotal(item.quote_amount);
                  console.log(item, 'itemmm')
                }
              }}
              className="top-awaiting"
              data-tip
              data-for="makepayment"
            >
              <img src={cardposagent} alt="edit_icon" />
            </div>

            <div className="bottom-awaiting" data-tip data-for="deleteicon">
              <img src={trash} alt="trash_icon" />
            </div>
          </div>
        ) : item.delivery_status === "expired" ? (
          <div style={{ right: "50px" }} className="awaiting-top-bottom">
            <div
              onClick={() => {
                setitemToDelete(item);
                setshowDeleting(true);
              }}
              className="bottom-awaiting"
              data-tip
              data-for="deleteicon"
            >
              <img src={trash} alt="trash_icon" />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const deleteItem = (e) => {
    e.preventDefault();
    setdeleting(true);
    authAxios
      .post(
        `m-dillivry-listing?delivery_location=${itemToDelete.vcc}`,
        {
          action_type: "delete",
          delivery_id: itemToDelete.delivery_id,
          sender_email: ls.get("eid"),
        },
        { timeout: 240000 }
      )
      .then((res) => {
        setshowDeleting(false);
        setdeleting(false);
        Swal.fire({
          title: "Deleted",
          text: "Item deleted!",
          icon: "success",
          confirmButtonColor: "var(--main)",
          showConfirmButton: false,
          timer: 2000,
        });
        history.go(0);
      })
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          console.log("internal-server-error-timeout");
          history.push(timeout);
        }
        setshowDeleting(false);
        setdeleting(false);
        Swal.fire({
          icon: "error",
          text: "error!",
          timer: 2000,
          showConfirmButton: false,
        }).then(() => {
          window?.location?.reload()
        });
        if (err.code === "ECONNABORTED") {
          console.log("internal-server-error-timeout");
          history.push(timeout);
        }
      });
  };

  const Date_diff = (firstDate, secondDate) => {
    const d = firstDate;
    const today = secondDate;
    const diffTime = d - today;
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return diffDays;
  };

  useEffect(() => {
    const thirty_min_after = moment(
      new Date(item?.ack_req?.ack_req_time_stamp)
    ).add(30, "m");

    const d = new Date(thirty_min_after);

    let date = d.getDate();
    let month = d.getMonth();
    let year = d.getFullYear();
    let hour = d.getHours();
    let min = d.getMinutes();

    const waitingTimeInMinute =
      moment([year, month, date, hour, min]).diff(moment()) / 1000;
    setTime(Math.floor(waitingTimeInMinute));
  }, [statusmemory]);



  const acceptExistClick = (delivery_id) => {
    history.push({
      pathname: GlobalizationLanguagewithCOntryCode(
        `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
        country,
        visitingCountryCode
      ),
      state: "from_acknowledgement",
      default: {
        walletbln: wallet_bln,
        modeofpayment: item,
      },
    });
  };

  const tokenCheck = (item) => {
    const decoded = jwt_decode(ls.get("_utk"));
    const expired = decoded.exp;
    if (expired <= Math.floor(Date.now() / 1000)) {
      setShowLogin(true);
    } else {
      wait_for_more_quote(item);
    }
  };

  const wait_for_more_quote = async (item) => {
    setWaiting(true);
    authAxios
      .post(
        `m-dillivry-quote-manager?delivery_location=${item?.vcc}`,
        {
          action_type: "reject",
          delivery_id: item.delivery_id,
          user_id: item.user_id,
          fulfiller_id: item.fulfiller_id,
          comment: "",
          vcc: visitingCountryCode,
        },
        { timeout: 240000 }
      )
      .then((response) => {
        setWaiting(false);
        if (response.status === 200) {
          history.push({
            pathname: GlobalizationLanguagewithCOntryCode(
              `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
              country,
              visitingCountryCode
            ),
            state: "from_acknowledgement",
            default: {
              walletbln: wallet_bln,
              modeofpayment: item,
            },
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              ls.removeAll();
              history.push(GlobalizationLanguage(`/login`));
            }
          });
        }
        if (err.code === "ECONNABORTED") {
          console.log("internal-server-error-timeout");
          history.push(timeout);
        }
        setWaiting(false);

        console.log(err);
      });
  };

  return (
    <tbody>
      <tr className="msg-trow-" key={index}>
        <td
          className="msg-tdata-"
          onClick={() =>
            item?.delivery_status === "awaiting_provider_acknowledgment"
              ? null
              : history.push({
                pathname: GlobalizationLanguagewithCOntryCode(
                  `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                  country,
                  visitingCountryCode
                ),
                default: {
                  walletbln: wallet_bln,
                  modeofpayment: item,
                },
              })
          }
        >
          <div
            className="quote-info-image-url"
            style={{
              backgroundImage: `url(${item?.image_urls[0]})`,
            }}
          ></div>
        </td>
        <td
          onClick={() =>
            item?.delivery_status === "awaiting_provider_acknowledgment"
              ? null
              : history.push({
                pathname: GlobalizationLanguagewithCOntryCode(
                  `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                  country,
                  visitingCountryCode
                ),
                default: {
                  walletbln: wallet_bln,
                  modeofpayment: item,
                },
              })
          }
          className="msg-tdata"
        >
          <span
            style={{ cursor: "pointer" }}
            className="itemdesc-span"
            onClick={() =>
              item?.delivery_status === "awaiting_provider_acknowledgment"
                ? null
                : history.push({
                  pathname: GlobalizationLanguagewithCOntryCode(
                    `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                    country,
                    visitingCountryCode
                  ),
                  default: {
                    walletbln: wallet_bln,
                    modeofpayment: item,
                  },
                })
            }
            data-tip
            data-for="global3"
          >
            {item?.item_desc?.charAt(0)?.toUpperCase() +
              item?.item_desc?.slice(1)?.toLowerCase()?.replace(/_/g, " ")}
          </span>
        </td>
        <td
          onClick={() =>
            item?.delivery_status === "awaiting_provider_acknowledgment"
              ? null
              : history.push({
                pathname: GlobalizationLanguagewithCOntryCode(
                  `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                  country,
                  visitingCountryCode
                ),
                default: {
                  walletbln: wallet_bln,
                  modeofpayment: item,
                },
              })
          }
          className="msg-tdata"
        >
          {item?.payer?.charAt(0)?.toUpperCase() + item?.payer?.slice(1)}
        </td>
        <td
          onClick={() =>
            item?.delivery_status === "awaiting_provider_acknowledgment"
              ? null
              : history.push({
                pathname: GlobalizationLanguagewithCOntryCode(
                  `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                  country,
                  visitingCountryCode
                ),
                default: {
                  walletbln: wallet_bln,
                  modeofpayment: item,
                },
              })
          }
          className="msg-tdata"
        >
          <div>
            <div>
              <span
                style={{
                  color:
                    item?.delivery_status === "awaiting_quote" ||
                      item?.delivery_status === "awaiting_provider_acknowledgment"
                      ? "#004DC1"
                      : item?.delivery_status === "delivered"
                        ? "#50B14A"
                        : item?.delivery_status === "missed_delivery" ||
                          item?.delivery_status === "expired"
                          ? "#F40101"
                          : "#B77E02",
                }}
              >
                {item?.delivery_status === "delivered"
                  ? "Completed"
                  : item?.total_quote_received >= 1 &&
                    item?.delivery_status === "awaiting_quote"
                    ? "Received quote"
                    : item?.delivery_status === "awaiting_provider_acknowledgment"
                      ? "Awaiting acknowledgement "
                      : item?.delivery_status?.charAt(0)?.toUpperCase() +
                      item?.delivery_status?.slice(1)?.replace(/_/g, " ")}
              </span>
            </div>

            {
              <div>
                {
                  <>
                    <span>
                      <span>
                        {time > 0 ? (
                          item?.ack_req &&
                            item?.delivery_status ===
                            "awaiting_provider_acknowledgment" ? (
                            <span style={{ color: "#004DC1" }}>
                              {" "}
                              {Math.floor(time / 60)}: {time % 60}{" "}
                            </span>
                          ) : null
                        ) : item?.ack_req &&
                          item?.delivery_status ===
                          "awaiting_provider_acknowledgment" &&
                          time < 0 ? (
                          <span style={{ color: "#004DC1" }}>00</span>
                        ) : null}
                      </span>
                    </span>

                    <small>
                      <CountDown setTime={setTime} time={time} />
                    </small>
                  </>
                }
              </div>
            }
          </div>
        </td>
        <td
          onClick={() =>
            item?.delivery_status === "awaiting_provider_acknowledgment"
              ? null
              : history.push(activedeliveries)
          }
          className="msg-tdata"
        >
          {item?.payment_status !== undefined ? (
            <div className="itemDesc_">
              <span className="">
                {item?.payment_status === "success" ? "Paid" : "Not paid"}
              </span>
            </div>
          ) : (
            "Not paid"
          )}
        </td>

        <td
          onClick={() =>
            item?.delivery_status === "awaiting_provider_acknowledgment"
              ? null
              : history.push(activedeliveries)
          }
          className="msg-tdata"
        >
          <div className="itemDesc_">
            <span className="">
              {item?.mode_of_payment.value
                ? item?.mode_of_payment.value.charAt(0)?.toUpperCase() +
                item?.mode_of_payment.value
                  .slice(1)
                  ?.toLowerCase()
                  ?.replace(/_/g, " ")
                : item?.mode_of_payment.charAt(0)?.toUpperCase() +
                item?.mode_of_payment
                  .slice(1)
                  ?.toLowerCase()
                  ?.replace(/_/g, " ")}
            </span>
          </div>
        </td>

        {item?.quote_amount ? (
          <td
            onClick={() =>
              item?.delivery_status === "awaiting_provider_acknowledgment"
                ? null
                : history.push(activedeliveries)
            }
            className="msg-tdata"
          >
            {item?.quote_amount ? (
              <span className="item-value-">
                <Currency location={item?.vcc} />
                {numberWithCommas.format(item?.quote_amount)}
              </span>
            ) : (
              "N/A"
            )}{" "}
          </td>
        ) : (
          <td className="msg-tdata">
            {item?.best_quote ? (
              <span className="item-value-">
                <Currency location={item?.vcc} />
                {numberWithCommas.format(item?.best_quote)}
              </span>
            ) : (
              "N/A"
            )}{" "}
          </td>
        )}
        <td
          onClick={() =>
            item?.delivery_status === "awaiting_provider_acknowledgment"
              ? null
              : history.push(activedeliveries)
          }
        >
          {item?.payment_time ? (
            <span>
              <span>
                {" "}
                {item?.payment_time === "pay_on_delivery"
                  ? "Pay on delivery"
                  : item?.payment_time === "pay_at_pickup"
                    ? "Pay at pickup"
                    : "Null"}{" "}
              </span>
            </span>
          ) : (
            "N/A"
          )}
        </td>
        <td>
          <img onClick={onClick} src={Frame} alt="frame_icon" />

          {activeValue === item?.delivery_id && !bool ? (
            <ShowMeHereNow item={item} index={index} />
          ) : (
            <></>
          )}
        </td>
      </tr>

      <DialogPop
        openPopup={showDeleting}
        setopenPopup={setshowDeleting}
      // head="Delete Item"
      >
        <div className="quote_div">
          <p style={{ fontSize: "15px" }}>
            {" "}
            Are you sure you want to delete this item?
          </p>
          {
            <form style={{ display: "flex", width: "100%" }}>
              <div className="dialogdiv">
                <button
                  onClick={() => setshowDeleting(false)}
                  className="dialodivbt"
                  type="button"
                  style={{
                    cursor: "pointer",
                    color: "#FFFFFF",
                    backgroundColor: "#50B14A",
                    border: "1px solid #50B14A",
                  }}
                >
                  {" "}
                  No
                </button>{" "}
                &nbsp; &nbsp;
                {deleting ? (
                  <button
                    className="dialodivbt"
                    type="button"
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "grey",
                      border: "1px solid grey",
                    }}
                  >
                    <PuffLoader
                      color="white"
                      loading={deleting}
                      speedMultiplier={1}
                      size={16}
                    />
                  </button>
                ) : (
                  <button
                    className="dialodivbt"
                    type="button"
                    onClick={deleteItem}
                    style={{
                      cursor: "pointer",
                      color: "#FFFFFF",
                      backgroundColor: "grey",
                      border: "1px solid grey",
                    }}
                  >
                    Yes
                  </button>
                )}
              </div>
            </form>
          }
        </div>
      </DialogPop>



      <DialogPop
        openPopup={showrelist}
        setopenPopup={setshowrelist}
        head="Update Item Description"
      >
        <div className="item-desc-details">
          <p className="item-para">
            Sorry item description edit is forbidden.You have already received a
            quote from a provider.To change the description you need to re-list
            the request. Click the Re-list button to proceed.
          </p>

          <div className="relist-btn">
            <button
              onClick={() =>
                history.push({
                  pathname: viewQuote_request_delivery,
                  state: { data: item, Schedule: "schedule" },
                  // state: `schedule`
                })
              }
            >
              Re-list
            </button>
          </div>
        </div>
      </DialogPop>

       <MakePayment
          openPopup={showpayment_method}
          setopenPopup={setshowPayment_method}
          deliveries={item}
          walletbalance={wallet_bln}
          setwalletbalance={setwallet_bln}
          promoDetails={promoDetails}
        />

      <DialogOutClick
        open={ShowLogin}
        handleClose={() => {
          setShowLogin(false);
        }}
      >
        <LoginCompV2
          onFinish={() => {
            setShowLogin(false);
          }}
        />
      </DialogOutClick>
    </tbody>
  );
}

export default QuoteTablerow;
