import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'
import { FiPower } from 'react-icons/fi'
import DialogOutClick from '../../../components/DialogOutClick';
import { AuthContext } from '../../../context/AuthContext';
import SecureLS from 'secure-ls';
import GlobalizationLanguage from '../../../components/GlobalizationLanguage';
function Account({ user_info }) {
    const forgetpass=GlobalizationLanguage("/forgetpass")

    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

    useEffect(() => {
        setFullname(user_info.account_info.sender_name)
        setEmail(user_info.account_info.sender_email)
        setPhone(user_info.account_info.sender_phone_no)
        if (ls.get('auth_status') === '') {
            setpasswordResetted(true)
        }
        else {
            setpasswordResetted(false)
        }
    }, [])

    

    const logMeOut = ()=>{
        auth.logout();
    } 

    const auth = useContext(AuthContext);
    const [passwordResetted, setpasswordResetted] = useState(false)
    const [fullname, setFullname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const [showLogout, setShowLogout] = useState(false)

    const history = useHistory();

    const logout = () => {
        ls.removeAll()

        history.push('/');
    }

    return (
        
        <div className="context-past">
        

            <div className="context-past-right">
                <div className="column">
                    <div className="acchead">
                        <div className="accheadtextright"> Personal Info</div>
                        <div className="accheadtextleft">
                            <button className="accheadtextleft"
                            onClick={() => {
                                setShowLogout(true)
                             }}>
                            {/* onClick={logout}> */}
                                {/* <FiPower/>  */}
                                Log out
                            </button>
                        </div>
                    </div>
                    <div className="accbod">
                        <div className="accbodinformation">
                            <div className="inforbd1">Name:</div>
                            <div className="inforbd2">{fullname}</div>
                            <div className="inforbd3"></div>
                        </div>
                        <div className="accbodinformation">
                            <div className="inforbd1">Email</div>
                            <div className="inforbd2">{email}</div>
                            <div className="inforbd3"></div>
                        </div>
                        <div className="accbodinformation">
                            <div className="inforbd1">Phone</div>
                            <div className="inforbd2">{phone}</div>
                        </div>
                    </div>
                    <div className="acclic" onClick={() => history.push(forgetpass)} style={{ cursor: "pointer" }}>
                        <a className="acclic" >Need to reset your password? </a> </div>

                    {/* <div className="acclic" style={{color:'var(--main)', cursor:"pointer"}} ><span onClick={()=>history.push(forgetpass)}>Need to reset your password? </span></div> */}


                    {/* <div className="columnHead">
                        <div className="headiing">
                            <div className="right">Account</div>
                            <div className="left">
                                <button onClick={logout}><FiPower /> Logout</button>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className="columnBody1"> */}
                    {/* <button onClick={()=>{console.log(information)}}>show</button> */}
                    {/* <div className="informtion">
                            <div className="informed">
                                <div className="pers">
                                    Personal Info
                                </div>
                                <div className="formation">
                                    <div className="infoTitle">NAME</div>
                                    <div className="infoDetails">{fullname}</div>
                                    <div className="divida"></div>
                                </div>
                                <div className="formation">
                                    <div className="infoTitle">EMAIL</div>
                                    <div className="infoDetails">{email}</div>
                                    <div className="divida"></div>
                                </div>
                                <div className="formation">
                                    <div className="infoTitle">PHONE</div>
                                    <div className="infoDetails">{phone}</div>
                                </div>
                            </div>
                        </div> */}
                    {/* <div className="need"><a href=''>Need to reset your password? </a> </div>

                    </div> */}
                </div>


                <DialogOutClick
                open={showLogout}
                handleClose ={()=>setShowLogout(false)}
                > 
                <div style={{width:'100%',padding:"30px 30px 20px 30px"}}>
                    <div style={{width:'100%',marginBottom:"30px", fontSize:'13px', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <span>Are you sure you want to logout?</span>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
                        <div className="disBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'#FFFFFF', backgroundColor:'#50B14A', border:'1px solid #50B14A'}}
                            onClick={()=>setShowLogout(false)} >
                            No
                        </div>
                        <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'#FFFFFF', backgroundColor:'#808080', border:'1px solid #808080' }}
                            // onClick={logMeOut}>
                                 onClick={logout}>
                            Yes
                        </div>
                    </div>
                </div>
            </DialogOutClick>
            </div>
        </div>




    )
}

export default Account