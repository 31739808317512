import React from 'react'
import './EarnExtra.css'
import { useHistory } from 'react-router-dom'
import GlobalizationLanguage from '../../../components/GlobalizationLanguage'

export default function EarnExtra() {

const history = useHistory()
var create_account = GlobalizationLanguage("/create-account")
  return (
    <div className='earnExtraCont'>
        <div className="home_max_width">
            <div className="earnExtra">
                <div className="earnExtraBody">
                    <div className="extraText">
                        <h3>Earn extra cash delivering</h3>
                        <h4>Work when you want to, be your own boss.</h4>
                    </div>
                    <div className="extraButton" onClick={()=>history.push(create_account)} >
                        SIGN UP TO DELIVER
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
