/* eslint-disable react/jsx-pascal-case */
import { Grid } from "@mui/material";
import React, { useState, useEffect, useContext, useCallback } from "react";
import "./QuoteDrillDown.css";
import { FaChevronLeft } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import sms1 from "../../../../../assets/sms1.svg";
import profilecircle from "../../../../../assets/profilecircle.svg";
import closesquare from "../../../../../assets/closesquare.svg";
import ticksquare from "../../../../../assets/ticksquare.svg";
import itemIcon from "../../../../../assets/itemdetails.png";
import locationIcons from "../../../../../assets/Vector1.png";
import destinationIcon from "../../../../../assets/Vector3.png";
import bagTick from "../../../../../assets/bag-tick.svg";
import distanceIcon from "../../../../../assets/distance.png";
import listedIcon from "../../../../../assets/timelisted.png";

import modeIcon from "../../../../../assets/car.png";

import { SenderContext } from "../../../../../context/SenderDashboard/SenderDashboardContext";
import { ConnContext } from "../../../../../context/ConnContext";
import SecureLS from "secure-ls";
import GoogleMapWidget from "../../../../../components/Map/GoogleMapWidget";
import Layout from "../../Layout";
import DialogOutClick from "../../../../../components/DialogOutClick";
import CloseIcon from "@material-ui/icons/Close";
import { PuffLoader } from "react-spinners";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import QuoteDrillDownMobile from "./QuoteDrillDownMobile";
import { useLocation } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import jwt_decode from "jwt-decode";
import LoginCompV2 from "../../../../../components/LoginCompV2";
import GlobalizationLanguage, {
  GlobalizationLanguagewithCOntryCode,
} from "../../../../../components/GlobalizationLanguage";
import Cookies from "js-cookie";
import truckTick from "../../../../../assets/truck-tick.svg";
import LinearStepper from "../../../../../components/Steppers/LinearStepper";
import Currency from "../../../../../utils/Currency";
import MakePayment from "../../../../../components/MakePayment";

function QuoteDrillDown() {
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

 
  const location = useLocation();
  const endpoint = process.env.REACT_APP_API;

  const numberWithCommas = new Intl.NumberFormat("en-GB");



  const [promoDetails, setpromoDetails] = useState({});
  const [ShowLogin, setShowLogin] = useState(false);
  const [statusIndex, setstatusIndex] = useState(0);


  const [showinsufficient, setshowinsufficient] = useState(false);

  const [walletbalance, setwalletbalance] = useState();

  const [loading_wallet_balc, setloading_wallet_balc] = useState(false)
  
  useEffect(() => {
    setloading_wallet_balc(true);
    axios
      .get(`${endpoint}m-dillivry-sender-api`, {
        headers: {
          Authorization: ls.get("_utk"),
        },
      })
      .then((res) => {
        // console.log(res)
        if (res.status === 200) {
          setwalletbalance(
            res?.data?.data
              ?.map((x) => x?.ng ?? x?.gb)
              ?.map((x) => x?.account)
              ?.flat()
              ?.filter((x) => x.account_info)
              ?.map((x) => x?.account_info)[0]?.wallet_balance
          );
          
          setpromoDetails(
            res?.data?.data
              ?.map((x) => x?.ng ?? x?.gb)
              ?.map((x) => x?.account)
              ?.flat()
              ?.filter((x) => x.account_info)
              ?.map((x) => x?.account_info)[0]?.general_promo_code
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(()=>{
        setloading_wallet_balc(false);
      })
    },[])

  const authAxios3 = axios.create({
    baseURL: ``,
    headers: {
      Authorization: ls.get("_utk"),
    },
    response: true,
  });

  const history = useHistory();

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });

  const styles = {
    popContainer: {},
    iconCont: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      flexWrap: "wrap",
    },
  };
  let { vcc, id } = useParams();
  var timeout = GlobalizationLanguage("/timeout");

  const { visitingCountryCode } = useContext(ConnContext);
  var countryvalue =
    Cookies.get("i18next") || visitingCountryCode.toLowerCase();

  const [country, setCountry] = useState(countryvalue);

  useEffect(() => {
    setCountry(Cookies.get("i18next"));
  }, [Cookies.get("i18next")]);

  const { Cartdata } = useContext(SenderContext);
  const [showpayment_method, setshowPayment_method] = useState(false);

  const [payment_type, setPayment_type] = useState("");
  const [detailsToAccept, setdetailsToAccept] = useState({});
  const route_history = useHistory();
  const [deliveries, setDeliveries] = useState([]);
  const [deliveries2, setDeliveries2] = useState([]);
  const [origin, setorigin] = useState([]);
  const [destination, setdestination] = useState([]);
  const [loading, setloading] = useState(false);

  const [accepting, setaccepting] = useState(false);

  const [showAccept, setShowAccept] = useState(false);
  const [rejecting, setrejecting] = useState(false);

  const [deliveryStatus, setdeliveryStatus] = useState("");
  const [quoteCount, setquoteCount] = useState(0);

  const [showReject, setShowReject] = useState(false);
  const [comment, setcomment] = useState("");


  const [subTotal, setsubTotal] = useState(0);
  const [promoValue, setpromoValue] = useState(0);
  const [promoAmount, setPromoAmount] = useState(0);
  const [total, settotal] = useState(0);
  const [wallet_bln, setwalletbln] = useState();
  const [modeofpayment, setmodeofPayment] = useState();


  const tokenCheck = () => {
    const decoded = jwt_decode(ls.get("_utk"));
    const expired = decoded.exp;
    if (expired <= Math.floor(Date.now() / 1000)) {
      console.log("session expired");
      setShowLogin(true);
    } else {
      setShowAccept(true);
    }
  };

  const tokenCheckReject = () => {
    const decoded = jwt_decode(ls.get("_utk"));
    const expired = decoded.exp;
    if (expired <= Math.floor(Date.now() / 1000)) {
      console.log("session expired");
      setShowLogin(true);
    } else {
      setShowReject(true);
    }
  };

  const Date_diff = (firstDate, secondDate) => {
    const d = firstDate;
    const today = secondDate;
    const diffTime = d - today;
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return diffDays;
  };

  // const applyPromo = () => {
  //   setApplying(true);
  //   setTimeout(() => {
  //     if (promoDetails === undefined) {
  //       setapplied(false);
  //       seterrorMsg("No ongoing promo at the moment");
  //     } else {
  //       if (promoCode === promoDetails?.promo_code) {
  //         if (Date_diff(new Date(), new Date(promoDetails?.start_date)) < 0) {
  //           setapplied(false);
  //           seterrorMsg("Promo have not started");
  //         } else if (
  //           Date_diff(new Date(promoDetails?.end_date), new Date()) > 0
  //         ) {
  //           if (promoDetails?.usage_left > 0) {
  //             setapplied(true);
  //             setpromoValue(parseFloat(promoDetails?.discount));
  //             settotal((1 - parseFloat(promoDetails?.discount)) * subTotal); //sum up arrays of numbers
  //             setPromoAmount(parseFloat(promoDetails?.discount) * subTotal);
  //           } else {
  //             seterrorMsg("Promo usage reached");
  //             setapplied(false);
  //           }
  //           console.log("promo have not ended");
  //         } else {
  //           seterrorMsg("promo have ended");
  //           setapplied(false);
  //         }
  //       } else {
  //         setpromoValue(0);
  //         seterrorMsg("invalid promo code");
  //         setapplied(false);
  //         settotal(subTotal);
  //         setPromoAmount(0);
  //       }
  //     }
  //     setApplying(false);
  //   }, 1000);
  // };

  const rejectQuote = async (e) => {
    e.preventDefault();
    setrejecting(true);
    authAxios
      .post(
        `m-dillivry-quote-manager?delivery_location=${vcc}`,
        {
          action_type: "reject",
          delivery_id: deliveries?.delivery_id,
          user_id: ls.get("eid"),
          fulfiller_id: detailsToAccept?.fulfiller_id,
          comment: comment,
        },
        { timeout: 240000 }
      )
      .then((response) => {
        setShowReject(false);
        setrejecting(false);
        if (response?.status === 200) {
          Swal.fire({
            icon: "info",
            text: "Quote rejected!",
            timer: 2500,
            showConfirmButton: false,
          }).then(() => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          console.log("internal-server-error-timeout");
          history.push(timeout);
          // window.location.reload()
        }
        setShowReject(false);
        setrejecting(false);
        Swal.fire({
          icon: "error",
          text: "error!",
          timer: 2000,
          showConfirmButton: false,
        });
        console.log(err);
      });
  };

  const acceptQuote = () => {
    setaccepting(true);
    authAxios
      .post(`m-dillivry-quote-manager?delivery_location=${vcc}`, {
        action_type: "accept",
        payment_type: payment_type,
        delivery_id: deliveries?.delivery_id,
        fulfiller_id: detailsToAccept?.fulfiller_id,
        quoted_amount: detailsToAccept?.quote_amount,
      })
      .then((response) => {
        setShowAccept(false);

        if (response?.data?.status === "OK") {
          // AddToCart(
          //   deliveries?.delivery_id,
          //   detailsToAccept?.fulfiller_id,
          //   detailsToAccept?.quote_amount
          // )
          Swal.fire({
            icon: "success",
            text:
              payment_type === "on_delivery"
                ? "Quote Accepted, Payment on delivery"
                : "Quote Accepted, Payment on pick up",
            timer: 5000,
            confirmButtonColor: "var(--main)",
          })
            .then((result) => {
              if (result) {
                setaccepting(false);
                history.push({
                  // pathname: GlobalizationLanguage("/viewQuote"),
                  pathname: GlobalizationLanguagewithCOntryCode(
                    `/view-status/${deliveries?.delivery_id}`,
                    country,
                    visitingCountryCode
                  ),
                  state: "fromAccept",
                });
              }
            })
            .catch((err) => {
              console.log(err, "errorrr");
              if (err?.response?.status === 403) {
                Swal.fire({
                  icon: "warning",
                  title: "Session!",
                  text: "Session expired login again!",
                  allowOutsideClick: false,
                  confirmButtonColor: "var(--main)",
                }).then((result) => {
                  if (result.value) {
                    ls.removeAll();
                    history.push(GlobalizationLanguage("/login"));
                  }
                });
              }
              if (err.code === "ECONNABORTED") {
                console.log("internal-server-error-timeout");
                //   history.push("/timeout");
                // window.location.reload()
              }
              console.log(err);
              setShowAccept(false);

              Swal.fire({
                icon: "warning",
                text: `${err?.response?.data}"`,
                timer: 5000,
              });
              setaccepting(false);
            });
        } else if (response.status === 201) {
          Swal.fire({
            icon: "error",
            text: `${response?.data?.error}`,

            confirmButtonColor: "grey",
          });
          setaccepting(false);
        }
      })
      .then((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
   
      setloading(true);
    setwalletbln(location?.default?.walletbln);
    setmodeofPayment(location?.default?.modeofpayment?.mode_of_payment);

    setloading(true);
    axios
      .get(
        `${process.env.REACT_APP_API}m-dillivry-sender-api?vcc=${vcc}&attribute=query_delivery&delivery_id=${id}`,
        {
          headers: {
            Authorization: ls.get("_utk"),
          },
        }
      )
      .then((res) => {
        // console.log(res, "res");
        setsubTotal(res?.data?.data?.quote_amount);
        settotal((1 - promoValue) * res?.data?.data?.quote_amount); //sum up arrays of numbers
        setPromoAmount(promoValue * subTotal);

        setmodeofPayment(res?.data?.data?.mode_of_payment);
        setDeliveries(res?.data?.data);
        setDeliveries2(res?.data?.data);
        setdeliveryStatus(res?.data?.data?.delivery_status);
        setquoteCount(Object?.keys(res?.data?.data?.quote)?.length);

        if (
          res?.data?.data?.delivery_status === "awaiting_quote" &&
          res?.data?.data?.total_quote_received === 0
        ) {
          setstatusIndex(0);
        } else if (
          res?.data?.data?.delivery_status === "awaiting_quote" &&
          res?.data?.data?.total_quote_received > 0
        ) {
          setstatusIndex(1);
        } else if (
          res?.data?.data?.delivery_status ===
          "awaiting_provider_acknowledgment"
        ) {
          setstatusIndex(2);
        } else if (
          res?.data?.data?.delivery_status === "awaiting_agent_assignment"
        ) {
          setstatusIndex(3);
        } else if (res?.data?.data?.delivery_status === "awaiting_pickup") {
          setstatusIndex(4);
        } else if (res?.data?.data?.delivery_status === "in_transit") {
          setstatusIndex(5);
        } else if (res?.data?.data?.delivery_status === "out_for_delivery") {
          setstatusIndex(6);
        } else if (res?.data?.data?.delivery_status === "delivered") {
          setstatusIndex(7);
        }

        const startLat = parseFloat(res?.data?.data?.start_location?.lat);
        const startlng = parseFloat(res?.data?.data?.start_location?.lng);
        const endLat = parseFloat(res?.data?.data?.end_location?.lat);
        const endLng = parseFloat(res?.data?.data?.end_location?.lng);

        setorigin([
          {
            id: "pickup",
            title: "Pickup",
            coordinates: {
              lat: startLat,
              lon: startlng,
            },
          },
        ]);
        setdestination([
          {
            id: "destination",
            title: "Destination",
            coordinates: {
              lat: endLat,
              lon: endLng,
            },
          },
        ]);
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
    // setloading(false);
  }, []);

  return (
    <div>
      <div className="mbl-dsk">
        <div className="dsktopscreen">
          <Layout>
            <div className="quoted_view_con" style={{ padding: "0" }}>
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    backgroundColor: "none",
                    position: "fixed",
                    top: "0",
                    paddingTop: "70px",
                    zIndex: "10000",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress color="success" />
                  </Box>
                </div>
              ) : (
                <div>
                  <div className="delivery-wrapper">
                    <div>
                      <span
                        // onClick={()=> history.push(GlobalizationLanguagewithCOntryCode(`/viewQuote`, country, visitingCountryCode))}
                        onClick={() => history.goBack()}
                        style={{
                          cursor: "pointer",
                          marginBottom: "10px",
                          color: "var(--main)",
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <span>
                          <FaChevronLeft size="17px" />
                        </span>
                        <span style={{ fontSize: "20px", fontWeight: "600" }}>
                          Back
                        </span>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    >
                      <span>
                        Category:{" "}
                        {deliveries?.item_category?.charAt(0)?.toUpperCase() +
                          deliveries?.item_category
                            ?.slice(1)
                            .replace(/_/g, " ")}
                      </span>
                      <span>Delivery id: {deliveries?.delivery_id}</span>
                    </div>

                    <LinearStepper activeStep={statusIndex} />

                    {(deliveries?.quote_history?.length > 0 &&
                      deliveries?.delivery_status === "awaiting_quote") ||
                    deliveries?.delivery_status ===
                      "awaiting_provider_acknowledgment" ? (
                      <div
                        className="table-body table-header-"
                        style={{ margin: "0" }}
                      >
                        <div
                          className="main-parent"
                          style={{ marginBottom: "0" }}
                        >
                          <div className="" style={{ position: "relative" }}>
                            <header className="qoute-header">
                              <h4>Quote history </h4>
                            </header>
                          </div>
                        </div>
                        <table
                          id="pdf-table"
                          style={{ margin: "10px 0 20px 0" }}
                        >
                          <thead style={{ color: "white" }}>
                            <tr>
                              <td>Qoute amount</td>
                              <td>Logistics provider</td>
                              <td>Time</td>
                              <td>Reject</td>
                              <td>Accept</td>
                              <td>Profile</td>
                              <td>Message</td>
                            </tr>
                          </thead>

                          <tbody>
                            {deliveries?.quote_history.map((history, i) => (
                              <tr key={i}>
                                <td>
                                  &#8358;
                                  {numberWithCommas.format(
                                    history.quote_amount
                                  )}
                                </td>
                                <td>{history.fulfiller_id}</td>
                                <td>{history.time_stamp}</td>

                                {history?.quote_status ===
                                "awaiting_acceptance" ? (
                                  <>
                                    <td>
                                      <img
                                        src={closesquare}
                                        alt="sms"
                                        onClick={() => {
                                          if (
                                            deliveries?.quote_history
                                              .map((i) => i.quote_status)
                                              .includes("accepted")
                                          ) {
                                            console.log("already accepted");
                                          } else {
                                            tokenCheckReject();
                                            setdetailsToAccept(history);
                                          }
                                        }}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </td>
                                    <td>
                                      <img
                                        src={ticksquare}
                                        alt="sms"
                                        onClick={() => {
                                          if (
                                            deliveries?.quote_history
                                              .map((i) => i.quote_status)
                                              .includes("accepted")
                                          ) {
                                            console.log("already aceepted");
                                          } else {
                                            tokenCheck();
                                            setdetailsToAccept(history);
                                          }
                                        }}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </td>
                                  </>
                                ) : history?.quote_status === "accepted" ? (
                                  <>
                                    <td>
                                      <span
                                        style={{
                                          color: "var(--main)",
                                          fontSize: "13px",
                                          textTransform: "none",
                                        }}
                                      >
                                        Accepted
                                      </span>
                                    </td>
                                    <td></td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "13px",
                                          textTransform: "none",
                                        }}
                                      >
                                        Rejected
                                      </span>
                                    </td>
                                    <td></td>
                                  </>
                                )}

                                <td>
                                  <img
                                    src={profilecircle}
                                    alt="profilephoto"
                                    onClick={() =>
                                      route_history.push(
                                        GlobalizationLanguagewithCOntryCode(
                                          `/deliveries_/${id}/${history.fulfiller_id}`,
                                          country,
                                          visitingCountryCode
                                        )
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </td>
                                <td>
                                  <img
                                    src={sms1}
                                    alt="sms"
                                    onClick={() =>
                                      route_history.push({
                                        pathname:
                                          GlobalizationLanguagewithCOntryCode(
                                            `/mssgg/${id}`,
                                            country,
                                            visitingCountryCode
                                          ),
                                        state: history.fulfiller_id,
                                        id: id,
                                        default: {
                                          data: history,
                                          delivery_id: id,
                                          deliveries: deliveries,
                                        },
                                        from: "receivedquote",
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : null}

                    <div className="item_detail">
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <div className="item_imges_con">
                            <div className="imgage-content">
                              <Carousel
                                navButtonsAlwaysInvisible={true}
                                indicators={true}
                                animation={"slide"}
                                timeout={100}
                                autoPlay={true}
                              >
                                {deliveries?.image_urls?.map((img_url, i) => (
                                  <img
                                    key={i}
                                    src={img_url}
                                    style={{ width: "100%" }}
                                    alt="Item_image"
                                    className="carousel_image-"
                                  />
                                ))}
                              </Carousel>
                            </div>

                            {deliveries?.delivery_status === "in_transit" ||
                            deliveries?.delivery_status ===
                              "out_for_delivery" ||
                            deliveries?.delivery_status === "missed_delivery" ||
                            deliveries?.delivery_status === "awaiting_pickup" ||
                            deliveries?.delivery_status === "delivered" ? (
                              <>
                                {deliveries?.delivery_status ===
                                "awaiting_agent_assignment" ? (
                                  <></>
                                ) : (
                                  <div className="agentCard">
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "-20px",
                                        fontSize: "13px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Assigned agent
                                    </span>
                                    <div
                                      className="agent_ima"
                                      style={{
                                        backgroundImage: `url(${
                                          deliveries?.agent_pics ||
                                          deliveries?.d_agent_pics
                                        })`,
                                      }}
                                    ></div>
                                    <div className="agent_info">
                                      <span>
                                        {deliveries?.agent_name ||
                                          deliveries?.d_agent_name}
                                      </span>
                                      <span>
                                        Completed Deliveries &nbsp; &nbsp;
                                        &nbsp; (
                                        {
                                          deliveries?.completed_jobs
                                            ?.completed_jobs
                                        }
                                        )
                                      </span>
                                    </div>
                                  </div>
                                )}

                                <div className="track-make">
                                  {deliveries?.delivery_status ===
                                  "awaiting_pickup" ? (
                                    <></>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        history.push(
                                          GlobalizationLanguagewithCOntryCode(
                                            `/tracking/${deliveries?.delivery_id}`,
                                            country,
                                            visitingCountryCode
                                          )
                                        )
                                      }
                                      className="btnTrack"
                                    >
                                      {" "}
                                      Track agent
                                    </div>
                                  )}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="item_inform">
                            <div className="topTitlee">
                              {" "}
                              Delivery information
                            </div>
                            <div className="itemDescs">
                              <div className="itemDesc">
                                <span> </span>
                                <span>
                                  <img src={bagTick} alt="mode" />{" "}
                                </span>
                                <span
                                  className="icon-desc"
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span style={{ fontWeight: "normal" }}>
                                    {" "}
                                    Pickup code:
                                  </span>
                                  {
                                    deliveries?.pickup_code?.length < 11 ? 
                                    <>
                                      <span
                                        style={{
                                          wordBreak: "break-word",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {deliveries?.pickup_code}
                                      </span>
                                    </>
                                    :
                                    <>
                                      <span>
                                        Pending
                                      </span>
                                    </>
                                  }

                                  {/* {deliveries?.quote ? (
                                    <>
                                      {Object.values(deliveries?.quote)[0]
                                        ?.pickup_code?.token ? (
                                        <>
                                          <span
                                            style={{
                                              wordBreak: "break-word",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {
                                              Object.values(
                                                deliveries?.quote
                                              )[0]?.pickup_code?.token
                                            }
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span
                                            style={{ fontWeight: "normal" }}
                                          >
                                            Pending{" "}
                                          </span>
                                        </>
                                      )}
                                    </>
                                  ) : null} */}
                                </span>
                              </div>

                              <div className="itemDesc">
                                <span> </span>
                                <span>
                                  <img src={truckTick} alt="mode" />{" "}
                                </span>
                                <span
                                  className="icon-desc"
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <span style={{ fontWeight: "normal" }}>
                                    Delivery code:{" "}
                                  </span>

                                  {
                                    deliveries?.delivery_code?.length < 11 ? 
                                    <>
                                      <span
                                        style={{
                                          wordBreak: "break-word",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        {deliveries?.delivery_code}
                                      </span>
                                    </>
                                    :
                                    <>
                                      <span>
                                        Pending
                                      </span>
                                    </>
                                  }

                                  {/* {deliveries?.quote ? (
                                    <>
                                      {Object.values(deliveries?.quote)[0]
                                        ?.delivery_code?.token ? (
                                        <>
                                          <span
                                            style={{
                                              wordBreak: "break-word",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {
                                              Object.values(
                                                deliveries?.quote
                                              )[0]?.delivery_code?.token
                                            }
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span
                                            style={{ fontWeight: "normal" }}
                                          >
                                            Pending{" "}
                                          </span>
                                        </>
                                      )}
                                    </>
                                  ) : null} */}
                                </span>
                              </div>

                              <div className="itemDesc">
                                <span> </span>
                                <span>
                                  <img src={itemIcon} alt="mode" />{" "}
                                </span>
                                <span className="icon-desc">
                                  {deliveries?.item_desc
                                    ?.charAt(0)
                                    ?.toUpperCase() +
                                    deliveries?.item_desc
                                      ?.slice(1)
                                      ?.replace(/_/g, " ")}
                                </span>
                              </div>

                              <div className="itemDesc">
                                <span> </span>
                                <span>
                                  <img src={itemIcon} alt="mode" />{" "}
                                </span>
                                <span className="icon-desc">
                                  {deliveries?.item_category
                                    ?.charAt(0)
                                    ?.toUpperCase() +
                                    deliveries?.item_category
                                      ?.slice(1)
                                      ?.replace(/_/g, " ")}
                                </span>
                              </div>
                              <div className="itemDesc">
                                <span> </span>
                                <span>
                                  <img src={locationIcons} alt="mode" />{" "}
                                </span>
                                <span className="icon-desc">
                                  {deliveries?.from}
                                </span>
                              </div>
                              <div className="itemDesc">
                                <span> </span>
                                <span>
                                  <img src={destinationIcon} alt="mode" />{" "}
                                </span>
                                <span className="icon-desc">
                                  {deliveries?.to}
                                </span>
                              </div>

                              <div className="itemDesc">
                                <span> </span>
                                <span>
                                  <img src={distanceIcon} alt="mode" />{" "}
                                </span>
                                <span className="icon-desc">
                                  {deliveries?.distance}
                                </span>
                              </div>
                              {deliveries?.delivery_status === "in_transit" ||
                              deliveries?.delivery_status ===
                                "awaiting_pickup" ||
                              deliveries?.delivery_status === "delivered" ||
                              deliveries?.delivery_status ===
                                "missed_delivery" ||
                              deliveries?.delivery_status ===
                                "out_for_delivery" ? (
                                <div className="itemDesc">
                                  <span> </span>
                                  <ReactTooltip
                                    id="AssignTime"
                                    aria-haspopup="true"
                                    type="light"
                                    role="example"
                                  >
                                    <p>Assign time</p>
                                  </ReactTooltip>

                                  <span>
                                    <img
                                      src={listedIcon}
                                      alt="mode"
                                      data-tip
                                      data-for="AssignTime"
                                    />{" "}
                                  </span>

                                  {deliveries?.assigned_time_stamp !==
                                  undefined ? (
                                    <span
                                      className="icon-desc"
                                      data-tip
                                      data-for="AssignTime"
                                    >
                                      {moment(
                                        deliveries?.assigned_time_stamp
                                      ).format("DD-MM-YY HH:MM:SS")}

                                      {/* {moment(
                                      deliveries.assigned_time_stamp
                                    ).format("YYYY-MM-DD HH:MM:SS")} */}
                                    </span>
                                  ) : null}
                                </div>
                              ) : null}
                              <div className="itemDesc">
                                <span> </span>
                                <span>
                                  <img src={modeIcon} alt="mode" />
                                </span>
                                <span className="icon-desc">
                                  {deliveries?.mode_of_delivery
                                    ? deliveries?.mode_of_delivery
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                      deliveries?.mode_of_delivery
                                        ?.slice(1)
                                        .replace(/_/g, " ")
                                    : deliveries?.mode_of_movement
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                      deliveries?.mode_of_movement
                                        ?.slice(1)
                                        .replace(/_/g, " ")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Grid>

                        <Grid item xs={4}>
                          <>
                            <div className="item_inform">
                              <div className="topTitlee">
                                {" "}
                                Quote information
                              </div>

                              <div className="itemDescs">
                                {deliveries?.payment_status !== undefined ? (
                                  <div className="itemDesc_2">
                                    <span>Payment status:</span>
                                    <span className="item-value">
                                      {deliveries?.payment_status === "success"
                                        ? "Paid"
                                        : "Not paid"}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                <div className="itemDesc_2">
                                  Delivery status:
                                  <span
                                    className="item-value--"
                                    style={{
                                      color:
                                        deliveries?.delivery_status ===
                                          "awaiting_quote" ||
                                        deliveries?.delivery_status ===
                                          "awaiting_provider_acknowledgment"
                                          ? "#004DC1"
                                          : deliveries?.delivery_status ===
                                            "delivered"
                                          ? "#50B14A"
                                          : deliveries?.delivery_status ===
                                              "missed_delivery" ||
                                            deliveries?.delivery_status ===
                                              "expired"
                                          ? "#F40101"
                                          : "#B77E02",
                                    }}
                                  >
                                    {deliveries?.delivery_status === "delivered"
                                      ? "Completed"
                                      : deliveries?.delivery_status
                                          ?.charAt(0)
                                          ?.toUpperCase() +
                                        deliveries?.delivery_status
                                          ?.slice(1)
                                          .replace(/_/g, " ")}
                                  </span>
                                </div>
                                {deliveries?.delivery_status === "in_transit" ||
                                deliveries?.delivery_status ===
                                  "missed_delivery" ||
                                deliveries?.delivery_status ===
                                  "out_for_delivery" ||
                                deliveries?.delivery_status === "delivered" ? (
                                  <div className="itemDesc_2">
                                    Pickup Time:
                                    <span className="item-value">
                                      {moment(
                                        deliveries?.pickup_timestamp
                                      ).format("DD-MM-YY HH:MM:SS")}
                                    </span>
                                  </div>
                                ) : null}
                                {deliveries?.delivery_status === "delivered" ? (
                                  <div className="itemDesc_2">
                                    Delivery Time:
                                    <span className="item-value">
                                      {deliveries?.delivery_timestamp !==
                                      null ? (
                                        <>
                                          {moment(
                                            deliveries?.delivery_timestamp
                                          ).format("YYYY-MM-DD HH:MM:SS")}
                                        </>
                                      ) : null}
                                    </span>
                                  </div>
                                ) : null}

                                {deliveries?.total_quote_received === 0 ? (
                                  <></>
                                ) : deliveries?.best_quote ? (
                                  <div className="itemDesc_2">
                                    Best quote:
                                    <span className="item-value">
                                    <Currency location={deliveries?.delivery_location} />
                                      {numberWithCommas.format(
                                        deliveries?.best_quote
                                      )}
                                    </span>
                                  </div>
                                ) : deliveries?.quote_amount ? (
                                  <div className="itemDesc_2">
                                    Quote amount:
                                    <span className="item-value">
                                    <Currency location={deliveries?.delivery_location} />
                                      {numberWithCommas.format(
                                        deliveries?.quote_amount
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                {deliveries?.payment_time ? (
                                  <div className="itemDesc_2">
                                    Payment type:
                                    <span className="item-value">
                                      {deliveries?.payment_time
                                        ?.slice()
                                        .replace(/_/g, " ")}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                <div className="itemDesc_2">
                                  Payer:
                                  <span className="item-value">
                                    {deliveries?.payer
                                      ?.charAt(0)
                                      ?.toUpperCase() +
                                      deliveries?.payer?.slice(1)}
                                  </span>
                                </div>
                              </div>

                              <div className="makepayment-new">
                                {deliveries?.delivery_status ===
                                  "awaiting_pickup" ||
                                deliveries?.delivery_status ===
                                  "awaiting_agent_assignment" ||
                                deliveries?.delivery_status ===
                                  "out_for_delivery" ||
                                deliveries?.delivery_status === "in_transit" ||
                                deliveries?.delivery_status ===
                                  "missed_delivery" ? (
                                  <>
                                    <div className="track-make">
                                      <div className="make-payement">
                                        {deliveries?.payment_status ===
                                        "success" ? (
                                          <div className="accept-btn">
                                            Make payment
                                          </div>
                                        ) : (
                                          <div
                                            className="accpt_t"
                                            onClick={() =>
                                              setshowPayment_method(true)
                                            }
                                          >
                                            <span
                                              style={{
                                                cursor: "pointer",
                                                borderRadius: "0px",
                                              }}
                                            >
                                              {" "}
                                              Make Payment
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </>
                        </Grid>
                      </Grid>
                    </div>

                    <div className="view_Mapp">
                      <GoogleMapWidget
                        origins={destination}
                        destinations={origin}
                      />
                    </div>

                    {([...[], deliveries?.inquiry_details?.inquiry_details]
                      .length > 0 &&
                      deliveries?.delivery_status === "awaiting_quote") ||
                    deliveries?.delivery_status ===
                      "awaiting_provider_acknowledgment" ? (
                      <>
                        <div
                          className="main-father"
                          style={{ position: "relative", marginTop: "30px" }}
                        >
                          <header className="qoute-header enquiry-header">
                            <h4>Enquires from providers</h4>
                          </header>
                        </div>
                        {[
                          ...[],
                          deliveries?.inquiry_details?.inquiry_details,
                        ]?.map((msg, i) => {
                          return (
                            <div className="Enquiry-div-message">
                              {msg?.message?.reverse()?.map((x) => (
                                <div>
                                  <p>
                                    {x?.fulfiller_id && (
                                      <b>
                                        Provider:{" "}
                                        {moment(
                                          x?.fulfiller_id && x?.time_stamp
                                        ).format("L, h:mm a ")}
                                        {moment(x?.time_stamp).fromNow()}{" "}
                                      </b>
                                    )}
                                  </p>
                                  <p>{x?.fulfiller_id && x?.msg}</p>

                                  <p>
                                    {x?.user_id && (
                                      <b>
                                        Sender:
                                        {moment(
                                          x?.user_id && x?.time_stamp
                                        ).format("L, h:mm a ")}
                                        {moment(x?.time_stamp).fromNow()}{" "}
                                      </b>
                                    )}
                                  </p>
                                  <p>{x?.user_id && x?.msg}</p>
                                </div>
                              ))}
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Layout>
        </div>

        <div className="mobilescreen">
          <QuoteDrillDownMobile
            promoDetails={promoDetails}
            loading={loading}
            goBack={() => route_history.goBack()}
            destination={destination}
            origin={origin}
            deliveries={deliveries}
            setshowPayment_method={setshowPayment_method}
            setdetailsToAccept={setdetailsToAccept}
            tokenCheckReject={tokenCheckReject}
            tokenCheck={tokenCheck}
            // setShowAccept={setShowAccept}
            // setShowReject={setShowReject}

            route_history={route_history}
            listedIcon={listedIcon}
            id={id}
            ls={ls}
            status_index={statusIndex}
          />
        </div>

        <DialogOutClick
          open={showAccept}
          handleClose={() => {
            setShowAccept(false);
            setPayment_type("");
          }}
        >
          <div className="popContainer">
            <div style={styles.iconCont}>
              <CloseIcon
                onClick={() => {
                  setShowAccept(false);
                  setPayment_type("");
                }}
              ></CloseIcon>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <h3>Quote acceptance</h3>

              <span style={{ fontSize: "15px", color: "#444343" }}>
                You are about to accept {detailsToAccept?.fulfiller_id} quote of
                <Currency location={detailsToAccept.vcc} />{numberWithCommas.format(detailsToAccept?.quote_amount)}.{" "}
                <br />
                The provider is expected to acknowledge your quote acceptance
                with 30min to confirm readiness to carry out the job. <br />
                Click on add to chart to accept.
              </span>

              {/* <span
              style={{
                fontSize: "15px",
                color: "red",
                fontWeight: "600",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <AiFillCloseCircle /> Cash payment is not allowed
            </span> */}

              <span
                style={{
                  fontSize: "15px",
                  color: "#444343",
                  fontWeight: "600",
                }}
              >
                Select payment time
              </span>
              <div style={{ marginBottom: "40px" }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    defaultValue={payment_type}
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="sender"
                      onClick={() => {
                        setPayment_type("on_pickup");
                      }}
                      control={<Radio />}
                      label="Pay on pickup"
                    />

                    <FormControlLabel
                      value="receiver"
                      onClick={() => {
                        setPayment_type("on_delivery");
                      }}
                      control={<Radio />}
                      label="Pay on delivery"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <button
                    onClick={() => {
                      setShowAccept(false);
                      setPayment_type("");
                    }}
                    type="button"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      border: "1px solid var(--main)",
                      userSelect: "none",
                      cursor: "pointer",
                      padding: "10px",
                      backgroundColor: "white",
                      color: "var(--main)",
                      borderRadius: "3px",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    Back
                  </button>
                </Grid>
                <Grid item xs={6}>
                  <button
                    onClick={acceptQuote}
                    type={accepting ? "button" : "submit"}
                    disabled={payment_type === ""}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      userSelect: "none",
                      cursor: "pointer",
                      padding: "10px",
                      backgroundColor:
                        payment_type === "" ? "#B0AFB0" : "var(--main)",
                      color: "white",
                      borderRadius: "3px",
                      border: 0,
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {accepting ? (
                      <span className="btnlabe">
                        <PuffLoader
                          color="white"
                          loading={accepting}
                          speedMultiplier={1}
                          size={24}
                        />
                      </span>
                    ) : (
                      <span>Add to cart</span>
                    )}
                  </button>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogOutClick>

        <DialogOutClick
          open={showReject}
          handleClose={() => setShowReject(false)}
        >
          <div className="popContainer">
            <div style={styles.iconCont}>
              <CloseIcon
                onClick={() => {
                  setShowReject(false);
                }}
              ></CloseIcon>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <h3>Quote Rejection</h3>

              <span style={{ fontSize: "13px", color: "#444343" }}>
                State why you are rejecting {detailsToAccept?.fulfiller_id}{" "}
                quote
              </span>
              <textarea
                col={4}
                value={comment}
                onChange={(e) => setcomment(e.target.value)}
                placeholder="enter details here"
                style={{
                  backgroundColor: "white",
                  border: "solid 1px #E7E7E7",
                  borderRadius: "10px",
                  height: "110px",
                  color: "#444343",
                }}
              />
              <Grid container spacing={4}>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <button
                    type={rejecting ? "button" : "submit"}
                    disabled={comment.length < 4}
                    onClick={rejectQuote}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      userSelect: "none",
                      cursor: "pointer",
                      padding: "10px",
                      backgroundColor:
                        comment.length < 4 ? "#B0AFB0" : "#F40101",
                      color: "white",
                      borderRadius: "3px",
                      border: 0,
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {rejecting ? (
                      <span className="btnlabe">
                        <PuffLoader
                          color="white"
                          loading={rejecting}
                          speedMultiplier={1}
                          size={24}
                        />
                      </span>
                    ) : (
                      <span>Reject</span>
                    )}
                  </button>
                </Grid>
              </Grid>
            </div>
          </div>
        </DialogOutClick>

        <MakePayment
          openPopup={showpayment_method}
          setopenPopup={setshowPayment_method}
          deliveries={deliveries}
          walletbalance={walletbalance}
          setwalletbalance={setwalletbalance}
          promoDetails={promoDetails}
          loading={loading_wallet_balc}
        />

        <DialogOutClick
          open={ShowLogin}
          handleClose={() => {
            setShowLogin(false);
          }}
        >
          <LoginCompV2
            onFinish={() => {
              setShowLogin(false);
              setShowAccept(true);
            }}
          />
        </DialogOutClick>

      </div>
    </div>
  );
}

export default QuoteDrillDown;
