import { useState, useEffect, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import GlobalizationLanguage, {GlobalizationLanguagewithCOntryCode} from "../../../../components/GlobalizationLanguage";
import { ConnContext } from "../../../../context/ConnContext";
import Cookies from "js-cookie";
import DialogOutClick from "../../../../components/DialogOutClick";
import SubmitQuote from "./SubmitQuote";
import { MdOpenInNew } from "react-icons/md";
import Currency from "../../../../utils/Currency";

export default function OpenRequestCardDesktop({ data, littleheading }) {
  const history = useHistory();
  const [openQuote, setopenQuote] = useState(false)
  const [itemToQuote, setitemToQuote] = useState([])

  const handleClickOpen = () => {
    setopenQuote(true);
};

const handleClose = () => {
    setopenQuote(false);
};
const numberWithCommas = new Intl.NumberFormat("en-GB")

  const {visitingCountryCode} = useContext(ConnContext)

  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

  useEffect(() => {
      setCountry(Cookies.get('i18next'));
    },[Cookies.get('i18next')])

  const [country,setCountry]=useState(countryvalue);

  const TableHead =
    [
        {
            head:"Items"
        },
        {
            head:"Description"
        },
        {
            head:"Mode of delivery"
        },
        {
            head:"Pickup"
        },
        {
            head:"Destination"
        },
        {
          head:"Mode of payment"
        },
        {
            head:"Best quote"
        },
        {
          head:"Action"
      },
    ]

  return (
    <div className="openrequests">
      
      <h3>{littleheading}</h3>
      <table>
        <thead >
          <tr>
          {
            TableHead?.map((x, i) => {
              return (
                <th style={{background:'white',textAlign:'left', borderBottom: '0.5px solid #e2e2e2'}} key={i}>{x?.head}</th>
              )
            })
          }
          </tr>
        </thead>
        {
          data?.map((x, i) => {
            return (
              <tbody key={i}>
                <tr>
                  <td onClick={() => history.push(GlobalizationLanguagewithCOntryCode(`/open-delivery/${x?.delivery_id}`, country, visitingCountryCode))}> <img src={x?.image_urls[0]} alt="" style={{ width: "50px" }} /> </td>
                  <td style={{textAlign:'left'}} onClick={() => history.push(GlobalizationLanguagewithCOntryCode(`/open-delivery/${x?.delivery_id}`, country, visitingCountryCode))} > {x?.item_desc?.charAt(0)?.toUpperCase() + x.item_desc?.slice(1)} </td>
                  <td style={{textAlign:'left'}} onClick={() => history.push(GlobalizationLanguagewithCOntryCode(`/open-delivery/${x?.delivery_id}`, country, visitingCountryCode))} > {x?.mode_of_delivery?.charAt(0)?.toUpperCase() + x.mode_of_delivery?.slice(1)}</td>
                  <td style={{textAlign:'left'}} onClick={() => history.push(GlobalizationLanguagewithCOntryCode(`/open-delivery/${x?.delivery_id}`, country, visitingCountryCode))} > {x?.from} </td>
                  <td style={{textAlign:'left'}} onClick={() => history.push(GlobalizationLanguagewithCOntryCode(`/open-delivery/${x?.delivery_id}`, country, visitingCountryCode))}> {x?.to} </td>
                  <td style={{textAlign:'left'}} onClick={() => history.push(GlobalizationLanguagewithCOntryCode(`/deliveries/current/${x?.delivery_id}`, country, visitingCountryCode))} >
                    {
                      typeof( x?.mode_of_payment) === "string"  ?
                      <>
                        {
                          x?.mode_of_payment === "pay_by_cash" ?
                          <div>Cash</div>:
                          x?.mode_of_payment === "pay_by_transfer" ?
                          <div>Transfer</div>:
                          x?.mode_of_payment === "pay_by_ussd" ?
                          <div>USSD</div>:
                          x?.mode_of_payment === "pay_by_wallet" ?
                          <div>Online</div>:
                          x?.mode_of_payment === "pay_by_card" ?
                          <div>Card</div>:
                          <div>{x?.mode_of_payment}</div>
                        }
                      </>
                      :
                      <div>N/A</div>
                    }
                  </td>
                  <td style={{textAlign:'left'}} onClick={() => history.push(GlobalizationLanguagewithCOntryCode(`/open-delivery/${x?.delivery_id}`, country, visitingCountryCode))}> 
                      {x?.best_quote ? 
                      <><Currency location={Cookies.get('i18next')}/>{numberWithCommas.format(x?.best_quote)}</> : 'None'} 
                    </td>
                  <td onClick={() => {setitemToQuote(x); handleClickOpen()}}> 
                    <div data-tip data-for="open">
                      <MdOpenInNew style={{color:'#3e3e3e', fontSize:'22px'}}/>
                    </div>
                  </td>
                </tr>
              </tbody>
            )
          })
        }
      </table>
     
      <DialogOutClick
          open={openQuote}
          handleClose={handleClose}
       >
        <SubmitQuote item={itemToQuote} closePopup={handleClose} />
      </DialogOutClick>

    </div>

  )
}