import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SecureLS from "secure-ls";
import { AuthContext } from "../../../context/AuthContext";
import { DashboardContext } from "../../../context/Dashboard/DashboardProvider/dashboard.provider";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import CorporateLayout from "./CorporateLayout";
import MobileCorpLayout from "./CorporateComponents/MobileCorpLayout";
import DashBottomBtn from "./DashBottomBtn/DashBottomBtn";
import DialogOutClick from "../../../components/DialogOutClick";
import "./corpreferrals.css";
import Referralmobile from "./Referralmobile";
import copyart from "../../../assets/copyart.svg";
import { useClipboard } from "use-clipboard-copy";
import GlobalizationLanguage, {
  GlobalizationLanguagewithCOntryCode,
} from "../../../components/GlobalizationLanguage";
import Cookies from "js-cookie";
import { ConnContext } from "../../../context/ConnContext";

const CorpReferrals = () => {
  const { visitingCountryCode, handlelogindata, logindata } =
    useContext(ConnContext);

  var countryvalue =
    Cookies.get("i18next") || visitingCountryCode.toLowerCase();

  useEffect(() => {
    setCountry(Cookies.get("i18next"));
  }, [Cookies.get("i18next")]);

  const [country, setCountry] = useState(countryvalue);

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [showaddfund, setshowaddfund] = useState(false);
  const [showlist, setshowlist] = useState(false);

  const [showwithdraw, setshowwithdraw] = useState(false);
  const [proceedwithdraw, setproceedwithdraw] = useState(false);

  const { dashboards, updateDashboard, update_balc_withdraw } =
    useContext(DashboardContext);
  const [ShowLogin, setShowLogin] = useState(false);
  const [loadingData, setloadingData] = useState(false);
  const [withdrawamt, setwithdrawamt] = useState("");
  const [isloading, setisloading] = useState(false);
  const [creditstate, setcreditstate] = useState(false);
  const [allstatet, setallstatet] = useState("all");
  const [bankDetails, setbankDetails] = useState({});
  const [addamt, setaddamt] = useState("");
  const [payment_method, setpayment_method] = useState("paystack");

  const [walletHistory, setwalletHistory] = useState([]);
  const [walletBalc, setwalletBalc] = useState(0);
  const [refreshBalance, setrefreshBalance] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [checkfocus, setcheckfocus] = useState(false);
  const [showsettings, setshowsettings] = useState(false);
  const [showpayment_method, setshowPayment_method] = useState(false);
  const [checkfocusAdd, setcheckfocusAdd] = useState(false);
  const [refdata, setrefdata] = useState([]);
  const [refcode, setrefcode] = useState("")
  const [refbycode, setrefbycode] = useState("")

  const endpoint = process.env.REACT_APP_API;

  const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers: {
      Authorization: ls.get("_nodi"),
    },
  });

  const Withdrawalfunction = (e) => {
    e.preventDefault();
    setisloading(true);
    authAxios
      .post(`m-dillivry_instant-payment`, {
        transaction_type: "debit",
        amount: withdrawamt,
      })
      .then((res) => {
        setisloading(false);
        setproceedwithdraw(false);
        Swal.fire({
          icon: "success",
          text: `Your withdrawal of #${withdrawamt} was successful`,
          showConfirmButton: true,
          confirmButtonColor: "#50B14A",
          confirmButtonText: "Continue",
        }).then((result) => {
          if (result.value) {
            update_balc_withdraw(
              `${parseFloat(walletBalc) - parseFloat(withdrawamt)}`
            );
            setShowIcon(true);
            setTimeout(() => {
              setShowIcon(false);
              setrefreshBalance(!refreshBalance);
            }, 2000);
          }
        });
      })
      .catch((err) => {
        // console.log(err.response);
        setisloading(false);
        setproceedwithdraw(false);
        Swal.fire({
          icon: "error",
          text: `${
            err?.response?.statusText
              ? err?.response?.statusText
              : "Withdrawal failed. Try again"
          }`,
          // text: err?.response?.statusText !== '' ? `Connection timeout. Please try again` : "Error occured",
          confirmButtonColor: "#50B14A",
          confirmButtonText: "Continue",
        });
        setisloading(false);
      });
  };

  // useEffect(() => {
  //   setloadingData(true);

  // axios
  //     .get(`${endpoint}m-dillivry-promo-code?&type=referrals_earning`, {
  //       headers: {
  //         Authorization: ls.get("_nodi"),
  //       },
  //     }).then((response)=>{
  //       setloadingData(false);
  //       console.log(response)
  //       setrefdata(response?.data?.data)
  //     }).catch((error)=>{
  //       console.log(error.response)
  //       setloadingData(false);
  //     })

  // if (dashboards !== undefined) {
  //   console.log(dashboards)
  // }else{

  // }

  // }, []);

  useEffect(() => {
    //  console.log(dashboards, "dasjbbbbb")
    // console.log(dashboards?.data?.my_account_info?.my_account_info.map(x => x.referral_code))
    setrefcode(dashboards?.data?.my_account_info?.my_account_info?.map(x => x?.referral_code))
    setrefbycode(dashboards?.data?.my_account_info?.my_account_info?.map(x => x?.referred_by_code))
    setloadingData(true);
    console.log(dashboards?.data?.my_account_info?.my_account_info, 'account_infoooo')
    window.scrollTo(0, 0);
    if (ls.get("_nodi") === "") {
      // setShowLogin(true);
      // history.push('/login')
      history.push(
        GlobalizationLanguagewithCOntryCode(
          "/login",
          country,
          visitingCountryCode
        )
      );
      auth.logout();
    }
     else {
      const decoded = jwt_decode(ls.get("_nodi"));
      const expired = decoded.exp;
      if (expired < Date.now() / 1000) {
        Swal.fire({
          icon: "warning",
          title: "Session!",
          text: "Session expired login again!",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            auth.logout();
            // setShowLogin(true);
            history.push(
              GlobalizationLanguagewithCOntryCode(
                "/login",
                country,
                visitingCountryCode
              )
            );
          }
        });
      } else {
        setloadingData(true);
        axios
          .get(`${endpoint}m-dillivry-promo-code?&type=referrals_earning`, {
            headers: {
              Authorization: ls.get("_nodi"),
            },
          })
          .then((response) => {
            // console.log(response, "response -refdashbord");

            setloadingData(false);
            // console.log(response.data);
            setrefdata(response?.data?.data);
          })
          .catch((error) => {
            setloadingData(false);
            // console.log(error.response)
            setloadingData(false);
          });
      }
    }

    if (dashboards === undefined) {
      setloadingData(true);
      axios
        .get(`${endpoint}m-dillivry-dashboard-manager`, {
          headers: {
            Authorization: ls.get("_nodi"),
          },
        })
        .then((response) => {
          // console.log(response, "response")
          setrefcode(response?.data?.my_account_info?.my_account_info?.map(x => x?.referral_code))
          setrefbycode(response?.data?.my_account_info?.my_account_info?.map(x => x?.referred_by_code))
          ls.set(
            "means_of_delivery",
            JSON.stringify(
              response?.data?.account_profile?.account_profile[0]
                ?.mode_of_delivery
            )
          );
          setloadingData(false);
          updateDashboard(response);
          // console.log(response, "dashboards")
          setwalletHistory(response?.data?.wallet_history)
          // console.log(response?.data?.wallet_history, "walletbalance")
          setwalletBalc(response?.data?.account_profile?.account_profile[0]
            ?.wallet_balance)

            setbankDetails(response?.data?.account_profile?.account_profile[0]?.bank_detail);
        })
        .catch((error) => {
          if (error?.response?.status === 403) {
            Swal.fire({
              icon: "warning",
              title: "Session!",
              text: "Session expired login again!",
              allowOutsideClick: false,
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                auth.logout();
                // setShowLogin(true);
                history.push('/login')
              }
            })}
    })
  }
    
  

  }, [refreshBalance ]);

  const dataHead = [
    {
      thead: "Date registered",
    },
    {
      thead: "Name",
    },
    {
      thead: "No of delivery",
    },

    {
      thead: "Avg earning per Delivery",
    },

    {
      thead: "Total earnings",
    },

    // {
    //   thead: (
    //     <img src={Frame} alt="frame_icon" className="icon-logo" style={{}} />
    //   ),
    // },
  ];

  const Transdata = [
    {
      Name: "John Doe",
      count: "20",
      Earning: "#1000",
      totalearning: "#30000",
      Date: "12- 05-2022 10:25:20",
    },
    {
      Name: "John Doe",
      count: "20",
      Earning: "#1000",
      totalearning: "#30000",
      Date: "12- 05-2022 10:25:20",
    },
    {
      Name: "John Doe",
      count: "20",
      Earning: "#1000",
      totalearning: "#30000",
      Date: "12- 05-2022 10:25:20",
    },
    {
      Name: "John Doe",
      count: "20",
      Earning: "#1000",
      totalearning: "#30000",
      Date: "12- 05-2022 10:25:20",
    },
    {
      Name: "John Doe",
      count: "20",
      Earning: "#1000",
      totalearning: "#30000",
      Date: "12- 05-2022 10:25:20",
    },
    {
      Name: "John Doe",
      count: "20",
      Earning: "#1000",
      totalearning: "#30000",
      Date: "12- 05-2022 10:25:20",
    },
    {
      Name: "John Doe",
      count: "20",
      Earning: "#1000",
      totalearning: "#30000",
      Date: "12- 05-2022 10:25:20",
    },
    {
      Name: "John Doe",
      count: "20",
      Earning: "#1000",
      totalearning: "#30000",
      Date: "12- 05-2022 10:25:20",
    },
    {
      Name: "John Doe",
      count: "20",
      Earning: "#1000",
      totalearning: "#30000",
      Date: "12- 05-2022 10:25:20",
    },
  ];

  const clipboard = useClipboard({
    copiedTimeout: 600, // timeout duration in milliseconds
  });

  return (
    <>
      <div className="deskstopcorp_con">
        {console.log(refdata, "refdata")}
        {/* {console.log(refdata, "refdata")} */}
        <CorporateLayout>
          <div>
            {/* <SearchBar/> */}
            {ShowLogin ? (
              history.push("/login")
            ) : (
              <div className="dashboard">
                {loadingData ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100vh",
                      backgroundColor: "none",
                      zIndex: "300",
                      position: "fixed",
                      top: "0",
                      paddingTop: "80px",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress color="success" />
                    </Box>
                  </div>
                ) : (
                  <div
                    style={{
                      padding: "0px 55px",
                    }}
                    className="dashboardDesktop"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "30px",
                      }}
                      className="referandref"
                    >
                      <div
                        className="leftref"
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "700",
                        }}
                      >
                         Referral code
                      
                      </div>

                      <div
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "700",
                        }}
                        className="leftref"
                      >
                         Referred by code
                      </div>
                    </div>

                  {
                    <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                    className="referandref"
                  >
                    {     refcode === "" || refcode === undefined || refcode === null || refcode?.length === 0 || refcode?.includes('undefined') || refcode[0] === undefined  ? <span>NA</span> :
               
               <div
                className="leftref"
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  borderRadius: "8px",
                  padding: "6px",
                  color: "#484848",
                  fontSize: "16px",
                  fontFamily: "Roboto",

                  fontStyle: "normal",
                }}
              >
                {/* <p>DVL189LV </p> */}

                <input
                  style={{
                     width: "75px",
                    border: "none",
                  }}
                  //  width={100}
                  ref={clipboard.target}
                  value={refcode}
                  readOnly
                />
                {/* <button onClick={clipboard.copy}>Copy Link</button> */}
                <img
                  onClick={clipboard.copy}
                  src={copyart}
                  alt="copytag"
                  className="tagcopy"
                  data-ho
                />
                {clipboard.copied ? "Copied" : ""}
              </div>



                    }


                    {        refbycode === " " || refbycode === undefined || refbycode === null || refbycode?.length === 0 || refbycode?.length === 0  || refbycode?.includes('undefined') || refbycode[0] === undefined   ? <span>NA</span> :
                    <div
                      style={
                        {
                          // fontFamily: "Roboto",
                          // fontSize: "16px",
                          // fontStyle: "normal",
                          // fontWeight: "700",
                        }
                      }
                      className="leftref"
                    >
                      <p
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          borderRadius: "8px",
                          padding: "6px",
                          color: "#484848",
                          fontSize: "16px",
                          fontFamily: "Roboto",

                          fontStyle: "normal",
                        }}
                      >
                       {refbycode}
                      </p>
                    </div>}
                  </div>
                  }



                    <h6
                      style={{
                        marginBottom: "20px",
                      }}
                    >
                      Referral history
                    </h6>

                    <table className="msg-table">
                      <thead>
                        {dataHead.map((msg, i) => (
                          <th
                            style={{
                              // textAlign: "start",
                              background: "#ffffff",
                            }}
                            key={i}
                            className="data-thead--ff"
                          >
                            {msg.thead}
                          </th>
                        ))}
                      </thead>
                      {
                        

                        refdata.map((x, i) => {
                          return (
                            <tbody>
                              <tr className="msg-trow-" key={i}>
                                <td className="msg-tdata-f">
                                  <span
                                    style={{
                                      color: "#004DC1",
                                    }}
                                    className="itemdesc-span"
                                  >
                                   {moment(x?.date_registered)?.format("DD-MM-YYYY")}  &nbsp; 
                                    {moment(x?.date_registered)?.format('LTS')} 
                                  </span>
                                </td>
                                <td className="msg-tdata-f">
                                  <span className="itemdesc-span">
                                    {x?.referral_name}
                                  </span>
                                </td>
                                <td className="msg-tdata-f">
                                  <span className="itemdesc-span">
                                    {x?.completed_jobs}
                                  </span>
                                </td>
                                <td className="msg-tdata-f">
                                  <span className="itemdesc-span">
                                  &#8358;
                                    {x?.average_earn.toLocaleString()}
                                  </span>
                                </td>
                                <td className="msg-tdata-f">
                                  <span className="itemdesc-span">
                                  &#8358;
                                    {x?.credit_amount_total.toLocaleString()}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })




                      }
                    </table>

                      {
                        refdata?.length === 0 ?

                        <div style={{
                          textAlign: "center",
                          marginTop: "20px"
                        }} >No Referral data</div> : null
                      }


                  </div>
                )}

                {/* <>
     <h1>Referrals</h1>
  </> */}
              </div>
            )}
          </div>
        </CorporateLayout>
      </div>

      <div className="mobilecorp_con">
        <MobileCorpLayout pageTitle="Referrals" />
        <Referralmobile data={refdata} refcode={refcode} refbycode={refbycode} />

        <div>
          {ShowLogin ? (
            history.push("/login")
          ) : (
            <div className="mobilecorp_gen">
              {loadingData ? (
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    backgroundColor: "none",
                    zIndex: "300",
                    position: "fixed",
                    top: "0",
                    paddingTop: "80px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress color="success" />
                  </Box>
                </div>
              ) : null}

              {/* <div className="dashboardDesktop">
          <h1>Referrals</h1>
          </div> */}
            </div>
          )}
        </div>
        <DashBottomBtn activeTab="Referral" />
      </div>
    </>
  );
};

export default CorpReferrals;
