import { Grid } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import "./QuoteDrillDown.css";
import { FaChevronLeft } from "react-icons/fa";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import itemIcon from "../../../../../assets/itemdetails.png";
import locationIcons from "../../../../../assets/Vector1.png";
import destinationIcon from "../../../../../assets/Vector3.png";
import modeIcon from "../../../../../assets/car.png";
import sms from "../../Assets/sms.svg";
import profileCircle from "../../Assets/profileCircle.png";
import GoogleMapWidget from "../../../../../components/Map/GoogleMapWidget";
import SenderSideBar from "../../../components/SenderSideBar";
import Carousel from "react-material-ui-carousel";
import { useHistory } from "react-router-dom";
import moment from "moment";
import GlobalizationLanguage, { GlobalizationLanguagewithCOntryCode } from "../../../../../components/GlobalizationLanguage";
import { ConnContext } from "../../../../../context/ConnContext";
import Cookies from "js-cookie";
import truckTick from "../../../../../assets/truck-tick.svg";
import bagTick from "../../../../../assets/bag-tick.svg";
import { IoCard } from "react-icons/io5";
import { MoonLoader } from "react-spinners";
import LinearStepper from "../../../../../components/Steppers/LinearStepper";
import Currency from "../../../../../utils/Currency";

function QuoteDrillDownMobile({
    loading,
    goBack,
    destination,
    deliveries,
    setShowReject,
    tokenCheck,
    tokenCheckReject,
    setshowPayment_method,
    setShowAccept,
    setdetailsToAccept,
    route_history,
    id,
    origin,
    ls,
    listedIcon,
    status_index
}) {
    const links = [
        // {
        //     path: "/team",
        //     name: "Team",
        // },
        // {
        //     path: "/senderapi",
        //     name: "API",
        // },

        // {
        //     path: "/sendersettlement",
        //     name: "Settlement",
        // },
        {
            path: "/sendermessage",
            name: "Help",
        },
    ]

    const history = useHistory()
    const numberWithCommas = new Intl.NumberFormat("en-GB")


    const { visitingCountryCode } = useContext(ConnContext);
    var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

    const [country, setCountry] = useState(countryvalue);

    useEffect(() => {
        setCountry(Cookies.get('i18next'));
    }, [Cookies.get('i18next')])

    return (
        <div>
            <SenderSideBar pageTitle="View Delivery" links={links} />

            <div className="quoted_view_con" style={{ padding: "0" }}>
                {loading ? (
                    <div
                        style={{
                            width: "100%",
                            height: "100vh",
                            backgroundColor: "none",
                            position: "fixed",
                            top: "0",
                            paddingTop: "50px",
                            zIndex: "10000",
                        }}
                    >
                        <Box sx={{ width: "100%" }}>
                            <LinearProgress color="success" />
                        </Box>
                    </div>
                ) : 
                    deliveries === undefined ? 
                    <div style={{ width: '100%', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <MoonLoader color='var(--main)' loading={true} speedMultiplier={1} size={50} /> </div>
                    :
                    <div className="delivery-wrapper" >
                        <div>
                            <span onClick={goBack} style={{ cursor: "pointer", marginBottom: "10px", color: "var(--main)", display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <span><FaChevronLeft size="17px" /></span>
                                <span style={{ fontSize: '20px', fontWeight: '600' }}>Back</span>
                            </span>
                        </div>

                        <LinearStepper activeStep={status_index}/>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: "10px", width: '100%' }}>
                            <span>{deliveries?.item_category?.charAt(0)?.toUpperCase() + deliveries?.item_category?.slice(1).replace(/_/g, " ")}</span>
                            <span>{deliveries?.delivery_id}</span>
                        </div>

                        {
                            deliveries?.quote_history?.length > 0 && deliveries?.delivery_status === "awaiting_quote" || deliveries?.delivery_status === "awaiting_provider_acknowledgment" ?
                                <>
                                    <div className="deliv-head" style={{ color: 'black', marginTop: '20px' }}>
                                        Quote history
                                    </div>
                                    <div className='item_hist'>
                                        {
                                            deliveries?.quote_history.map((history, i) => (
                                                <div>
                                                    <div className="itemDesc_2" style={{ border: '0' }}>
                                                        <span><Currency location={deliveries?.delivery_location} />{numberWithCommas.format(history.quote_amount)}</span>
                                                        <span>
                                                            <img src={profileCircle}
                                                                alt="profilephoto"
                                                                onClick={() => route_history.push(GlobalizationLanguagewithCOntryCode(`/deliveries_/${id}/${history?.fulfiller_id}`, country, visitingCountryCode))}
                                                                style={{ cursor: "pointer" }}
                                                            />
                                                        </span>
                                                    </div>

                                                    <div className="itemDesc_2" style={{ border: '0' }}>
                                                        <span>{history.fulfiller_id}</span>
                                                        <span>
                                                            <img src={sms}
                                                                alt="sms"
                                                                onClick={() => route_history.push(
                                                                    {
                                                                        //  pathname: `/mssg/${id}`,
                                                                        //  pathname: GlobalizationLanguage(`/mssg/${id}`),
                                                                        pathname: GlobalizationLanguagewithCOntryCode(`/mssgg/${id}`, country, visitingCountryCode),
                                                                        state: history.fulfiller_id,
                                                                        id: id,
                                                                        default: {
                                                                            data: history,
                                                                            delivery_id: id,
                                                                            deliveries: deliveries

                                                                        }
                                                                    }
                                                                )}
                                                                style={{ cursor: "pointer" }}
                                                            />
                                                        </span>
                                                    </div>

                                                    <div className="itemDesc_2" style={{ border: '0' }}>
                                                        <span>{history.time_stamp}</span>
                                                        <span>
                                                        </span>
                                                    </div>

                                                    <div className="itemDesc_2" style={{ border: '0' }}>
                                                        <span>{history?.quote_status?.slice()
                                                            .replace(/_/g, " ")}</span>
                                                        <span>
                                                        </span>
                                                    </div>


                                                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 15px 20px 15px', gap: '20px', borderBottom: '1px solid #e5e5e5' }}>
                                                        {
                                                            history?.quote_status === "awaiting_acceptance" ?

                                                                <>
                                                                    <td>
                                                                        <Button
                                                                            style={{ background: 'white', padding: '0 15px', boxShadow: 'none', border: '1px solid red', color: 'red', fontSize: '13px', textTransform: 'none' }}
                                                                            size="small"
                                                                            variant="contained"
                                                                            onClick={() => {
                                                                                if(deliveries?.quote_history.map((i)=> i.quote_status).includes('accepted')){
                                                                                    console.log('already accepted')
                                                                                  }
                                                                                  else{
                                                                                 tokenCheckReject(); setdetailsToAccept(history) 
                                                                                  }
                                                                                }}>
                                                                            Reject
                                                                        </Button>
                                                                    </td>
                                                                    <td>
                                                                        <Button
                                                                            style={{ background: 'var(--main)', color: 'white', padding: '0 15px', boxShadow: 'none', fontSize: '13px', textTransform: 'none' }}
                                                                            size="small"
                                                                            variant="contained"
                                                                            onClick={() => { 
                                                                                if(deliveries?.quote_history.map((i)=> i.quote_status).includes('accepted')){
                                                                                    console.log('already accepted')
                                                                                  }
                                                                                  else{
                                                                                tokenCheck(); setdetailsToAccept(history) }}}>
                                                                            Accept
                                                                        </Button>
                                                                    </td>
                                                                </>
                                                                :
                                                                history?.quote_status === "accepted" ?
                                                                <>
                                                                    <td>
                                                                        <div
                                                                            style={{ background: 'white', padding: '0 15px', boxShadow: 'none', color: 'var(--main)', fontSize: '14px', textTransform: 'none' }}
                                                                            >
                                                                            Accepted
                                                                        </div></td>
                                                                    <td></td>
                                                                </>
                                                                :
                                                                <>
                                                                    <td>
                                                                        <Button
                                                                            style={{ background: 'white', padding: '0 15px', boxShadow: 'none', color: 'red', fontSize: '13px', textTransform: 'none' }}
                                                                            size="small"
                                                                            variant="contained"
                                                                            disabled={true}>
                                                                            Rejected
                                                                        </Button></td>
                                                                    <td></td>
                                                                </>

                                                        }
                                                    </div>
                                                    {/* <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 15px 20px 15px', gap: '20px', borderBottom: '1px solid #e5e5e5' }}>
                                                        <Button
                                                            style={{ background: 'white', padding: '0 15px', boxShadow: 'none', border: '1px solid red', color: 'red', fontSize: '13px', textTransform: 'none' }}
                                                            size="small"
                                                            variant="contained"
                                                            onClick={() => { setShowReject(true); setdetailsToAccept(history) }}>
                                                            Reject
                                        </Button>
                                                        <Button
                                                            style={{ background: 'var(--main)', color: 'white', padding: '0 15px', boxShadow: 'none', fontSize: '13px', textTransform: 'none' }}
                                                            size="small"
                                                            variant="contained"
                                                            onClick={() => { setShowAccept(true); setdetailsToAccept(history) }}>
                                                            Accept
                                        </Button>
                                                    </div> */}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                                :
                                <></>
                        }

                        <div className="item_detail" >
                            <Grid container spacing={2}>

                                <Grid item xs={12}>
                                    <div className="deliveryCard">
                                        <div className="deliv-head">
                                            Delivery information 
                                        </div>

                                        <div className="deliv-body_space">
                                            <div className="deliv-bodyv2">
                                                <span><img src={bagTick} alt="mode" /></span>
                                                <span>Pickup code</span>
                                            </div>
                                            <div>
                                                {
                                                    deliveries?.pickup_code?.length < 11 ? 
                                                    <>
                                                    <span
                                                        style={{
                                                        wordBreak: "break-word",
                                                        fontWeight: "normal",
                                                        }}
                                                    >
                                                        {deliveries?.pickup_code}
                                                    </span>
                                                    </>
                                                    :
                                                    <>
                                                        <span>
                                                            Pending
                                                        </span>
                                                    </>
                                                }
                                                {/* {
                                                    deliveries?.quote ?
                                                        <>
                                                            {
                                                                Object.values(deliveries?.quote)[0]?.pickup_code?.token ?
                                                                    <>
                                                                        <span style={{ wordBreak: "break-word", fontWeight: "normal" }} >
                                                                            {Object?.values(deliveries?.quote)[0]?.pickup_code?.token}
                                                                        </span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <span style={{ fontWeight: "normal" }}>Pending </span>
                                                                    </>
                                                            }
                                                        </>
                                                        :
                                                        null
                                                } */}
                                            </div>
                                        </div>
                                        <div className="deliv-body_space">
                                            <div className="deliv-bodyv2">
                                                <span><img src={truckTick} alt="mode" /></span>
                                                <span>Delivery code</span>
                                            </div>
                                            <div>
                                                {
                                                    deliveries?.delivery_code?.length < 11 ? 
                                                    <>
                                                    <span
                                                        style={{
                                                        wordBreak: "break-word",
                                                        fontWeight: "normal",
                                                        }}
                                                    >
                                                        {deliveries?.delivery_code}
                                                    </span>
                                                    </>
                                                    :
                                                    <>
                                                        <span>
                                                            Pending
                                                        </span>
                                                    </>
                                                }
                                                {/* {
                                                    deliveries?.quote ?
                                                        <>
                                                            {
                                                                Object.values(deliveries?.quote)[0]?.delivery_code?.token ?
                                                                    <>
                                                                        <span style={{ wordBreak: "break-word", fontWeight: "normal" }} >
                                                                            {Object.values(deliveries?.quote)[0]?.delivery_code?.token}
                                                                        </span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <span style={{ fontWeight: "normal" }}>Pending </span>
                                                                    </>
                                                            }
                                                        </>
                                                        :
                                                        null

                                                } */}
                                            </div>

                                        </div>



                                        <div className="deliv-body">
                                            <span><img src={itemIcon} alt="mode" /></span>
                                            <span>{deliveries?.item_desc?.charAt(0)?.toUpperCase() + deliveries?.item_desc?.slice(1)?.replace(/_/g, " ")}</span>
                                        </div>

                                        <div className="deliv-body">
                                            <span><img src={locationIcons} alt="mode" /></span>
                                            <span>{deliveries?.from}</span>
                                        </div>

                                        <div className="deliv-body">
                                            <span><img src={destinationIcon} alt="mode" /></span>
                                            <span>{deliveries?.to}</span>
                                        </div>

                                        {
                                            deliveries?.delivery_status === "in_transit" ||
                                                deliveries?.delivery_status === "awaiting_pickup" ||
                                                deliveries?.delivery_status === "delivered" ||
                                                deliveries?.delivery_status === "out_for_delivery" ||
                                                deliveries?.delivery_status === "missed_delivery" ?
                                                <div className="deliv-body">
                                                    <span>
                                                        <img src={listedIcon} alt="mode" />{" "}
                                                    </span>

                                                    {
                                                        deliveries?.assigned_time_stamp !== undefined ?

                                                            <span >{moment(deliveries?.assigned_time_stamp)?.format("DD-MM-YYYY HH:MM:SS")}</span>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                :
                                                null
                                        }

                                        <div className="deliv-body">
                                            <span><img src={modeIcon} alt="mode" /></span>
                                            <span>
                                                {deliveries?.mode_of_delivery
                                                    ? deliveries?.mode_of_delivery
                                                        ?.charAt(0)
                                                        ?.toUpperCase() +
                                                    deliveries?.mode_of_delivery
                                                        ?.slice(1)
                                                        .replace(/_/g, " ")
                                                    : deliveries?.mode_of_movement
                                                        ?.charAt(0)
                                                        ?.toUpperCase() +
                                                    deliveries?.mode_of_movement
                                                        ?.slice(1)
                                                        .replace(/_/g, " ")}
                                            </span>
                                        </div>

                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="deliveryCard">

                                        {
                                            deliveries?.payment_status !== undefined ?
                                                <div className="payStat">
                                                    <span>Payment status:
                                                        <span className="item-value">
                                                            {deliveries?.payment_status === "success" ? "Paid" : "Not paid"}
                                                        </span>
                                                    </span>

                                                    {
                                                        deliveries?.delivery_status === "awaiting_pickup" || 
                                                        deliveries?.delivery_status === "awaiting_agent_assignment"  ||
                                                        deliveries?.delivery_status === "out_for_delivery" ||
                                                        deliveries?.delivery_status === "in_transit" ||
                                                        deliveries?.delivery_status === "missed_delivery"
                                                        ?
                                                            <>
                                                                <div className="make-pay-mobile">
                                                                    {
                                                                        deliveries?.payment_status === "success" ?
                                                                            <IoCard size={25} color="grey" />
                                                                            :
                                                                            <span onClick={() => setshowPayment_method(true)} style={{ cursor: "pointer", borderRadius: "0px", userSelect: 'none' }}>
                                                                                <IoCard size={25} color="black" />
                                                                            </span>
                                                                    }
                                                                </div>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                                :
                                                <></>
                                        }
                                        <div className="payStat">
                                            <span>Delivery status: <span className="item-value"> {deliveries?.delivery_status?.charAt(0)?.toUpperCase() + deliveries?.delivery_status?.slice(1).replace(/_/g, " ")} </span></span>
                                            {/* <span className="pay-item-delete">Delete</span> */}
                                        </div>
                                        {
                                            deliveries?.delivery_status === "in_transit" || deliveries?.delivery_status === "missed_delivery" || deliveries?.delivery_status === "out_for_delivery" || deliveries?.delivery_status === "delivered" ?
                                                <div className="payStat">
                                                    <span> Pickup Time: <span className="item-value"> {moment(deliveries?.pickup_timestamp).format("YYYY-MM-DD HH:MM:SS")} </span></span>

                                                </div>
                                                :
                                                null
                                        }

                                        {
                                            deliveries?.delivery_status === "delivered" ?
                                                <div className="payStat">
                                                    <span> Delivery Time:
                                                        <span className="item-value">
                                                            {
                                                                deliveries?.delivery_timestamp !== null ?
                                                                    <>
                                                                        {moment(deliveries?.delivery_timestamp).format("DD-MM-YYYY HH:MM:SS")}
                                                                    </>
                                                                    : null
                                                            }
                                                        </span>
                                                    </span>

                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            deliveries?.total_quote_received === 0 ?
                                                <></>
                                                :
                                                deliveries?.best_quote ?
                                                    <div className="payStat">
                                                        <span>Best quote:
                                                            <span className="item-value"><Currency location={deliveries?.delivery_location} />{numberWithCommas.format(deliveries?.best_quote)}</span>
                                                        </span>
                                                        <span></span>
                                                    </div>
                                                    :
                                                    deliveries?.quote_amount ?
                                                        <div className="payStat">
                                                            <span >Quote amount:
                                                                <span className="item-value"><Currency location={deliveries?.delivery_location} />{numberWithCommas.format(deliveries?.quote_amount)}</span>
                                                            </span>
                                                            <span></span>
                                                        </div>
                                                        :
                                                        <></>
                                        }
                                        {
                                            deliveries?.payment_time ?
                                                <div className="payStat">
                                                    <span>Payment type: <span className="item-value"> {deliveries?.payment_time?.slice().replace(/_/g, " ")}</span></span>
                                                </div>
                                                :
                                                <></>
                                        }
                                        <div className="payStat">
                                            <span>Payer: <span className="item-value">{deliveries?.payer?.charAt(0)?.toUpperCase() + deliveries?.payer?.slice(1)}</span>
                                            </span>
                                            <span></span>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                <div className="aaagent">Assigned agent</div>
                                {deliveries?.delivery_status === "in_transit" ||
                                deliveries?.delivery_status === "out_for_delivery" ||
                                deliveries?.delivery_status === "missed_delivery" ||
                                deliveries?.delivery_status === "awaiting_pickup" ||
                                deliveries?.delivery_status === "delivered" ? (
                                    <>
                                    {deliveries?.delivery_status ===
                                    "awaiting_agent_assignment" ? (
                                        <></>
                                    ) : (
                                        <div className="agentCardV2_track">
                                            <div className="agentCardV2">
                                                <div
                                                    className="agent_ima"
                                                    style={{
                                                    backgroundImage: `url(${
                                                        deliveries?.agent_pics ||
                                                        deliveries?.d_agent_pics
                                                    })`,
                                                    }}
                                                ></div>
                                                <div className="agent_info">
                                                    <span> {deliveries?.agent_name || deliveries?.d_agent_name} </span>
                                                    <span>
                                                        Completed Deliveries &nbsp; (
                                                        {deliveries?.completed_jobs?.completed_jobs})
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="track-make">
                                                {deliveries?.delivery_status ===
                                                "awaiting_pickup" ? (
                                                <></>
                                                ) : (
                                                <div
                                                    onClick={() =>
                                                    history.push(
                                                        GlobalizationLanguagewithCOntryCode(`/tracking/${deliveries?.delivery_id}`, country, visitingCountryCode)
                                                    )
                                                    }
                                                    className="btnTrack"
                                                >
                                                    {" "}
                                                    Track agent
                                                </div>
                                                )}
                                            </div>

                                        </div>
                                    )}
                                    </>
                                ) : (
                                    <></>
                                )}
                                </Grid>

                                <Grid item xs={12}>
                                    <div className="view_Mapp">
                                        <GoogleMapWidget
                                            origins={destination}
                                            destinations={origin}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <Carousel
                                        navButtonsAlwaysInvisible={true}
                                        indicators={true}
                                        animation={"slide"}
                                        timeout={100}
                                        autoPlay={true}
                                    >
                                        {deliveries?.image_urls?.map((img_url, i) => (
                                            <img
                                                key={i}
                                                src={img_url}
                                                style={{
                                                    width: "100%",
                                                }}
                                                alt="Item_image"
                                                className="img-cdiv"
                                            />
                                        ))}
                                    </Carousel>

                                </Grid>
                            </Grid>

                            {
                                [...[], deliveries?.inquiry_details?.inquiry_details].length > 0 && deliveries?.delivery_status === "awaiting_quote" || deliveries?.delivery_status === "awaiting_provider_acknowledgment" ?
                                    <>
                                        <div
                                            className="main-father"
                                            style={{ position: "relative", marginTop: "30px" }}
                                        >
                                            <header className="qoute-header enquiry-header">
                                                <h4>Enquires from providers</h4>
                                            </header>
                                        </div>

                                        {[...[], deliveries?.inquiry_details?.inquiry_details]?.map((msg, i) => {
                                            return (
                                                <div className="Enquiry-div-message">
                                                    {msg?.message?.reverse()?.map((x) => (
                                                        <div>
                                                            <p>
                                                                {x?.fulfiller_id &&
                                                                    <b>
                                                                        Provider:{" "}
                                                                        {moment(
                                                                            x?.fulfiller_id && x?.time_stamp
                                                                        ).format("L, h:mm a ")}
                                                                        {moment(x?.time_stamp).fromNow()}{" "}
                                                                    </b>
                                                                }
                                                            </p>
                                                            <p>{x?.fulfiller_id && x?.msg}</p>

                                                            <p>
                                                                {x?.user_id &&
                                                                    <b>
                                                                        Sender:
                                                                        {moment(
                                                                            x?.user_id && x?.time_stamp
                                                                        ).format("L, h:mm a ")}
                                                                        {moment(x?.time_stamp).fromNow()}{" "}
                                                                    </b>
                                                                }
                                                            </p>

                                                            <p>{x?.user_id && x?.msg}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            );
                                        })}
                                    </>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                    // ))
                }
            </div>
        </div>
    );
}

export default QuoteDrillDownMobile;
