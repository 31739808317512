import React, { useState,useContext, useEffect } from "react";

import "./Settings.css"
import { PuffLoader } from 'react-spinners';

import Multiselect from 'multiselect-react-dropdown';
import userAvatar from "../../../../assets/userAvatar.jpeg"

import SenderSideBar from "../../components/SenderSideBar";
import SenderBottomNav from "../../components/SenderBottomNav";
import GlobalizationLanguage from "../../../../components/GlobalizationLanguage";
export default function BusinessSettingsMobile({
  image_file,
  userPassport,
  imageHandler,
  address,
  setaddress,
  website,
  setwebsite,
  options,
  onSelect,
  onRemove,
  mode_of_transport,
  CustomStyle,
  average,
  setaverage,
  good_desc,
  setgood_desc,
  passclick,
  onSaveClick,
  loading,
  updatingPassport,
  history,
  showmode,
  setshowmode,
  mode,

}) {
  const sendersettings_business=GlobalizationLanguage("/sendersettings/business")
  const sendersettings=GlobalizationLanguage("/sendersettings")
  var sendermessage = GlobalizationLanguage("/sendermessage")
  var viewQuote = GlobalizationLanguage("/viewQuote")
  var viewQuote_quote = GlobalizationLanguage("/viewQuote/quotes");
  var viewQuote_request_delivery = GlobalizationLanguage("/viewQuote/request-a-delivery")
  const forgetpass=GlobalizationLanguage("/forgetpass")

  const businessLink =  [
    {
      path: sendersettings,
      name: "Personal",
      state: "inactive"
    },
    {
      path: sendersettings_business,
      name: "Business",
      state: "active"
    },
    // {
    //   path: sendersettings,
    //   name: "Security",
    //   state: "inactive"
    // }
  ]

  return(
    <div>
      
      <SenderSideBar pageTitle="Business setting" links={businessLink}/>

      <div className='sender-settings-wrap'>
        <div className="business-settings">

          <div className="personal-image">
          {
            updatingPassport ?
            <PuffLoader color='var(--main)' loading={updatingPassport} speedMultiplier={1} size={100} />
              :
              <>
              <img src={image_file !== "" ? image_file : userPassport !== "" ? userPassport : userAvatar} />
              <div className="change-image">
                <label htmlFor="image_files" style={{ color: 'var(--main)', textDecoration: 'underline', cursor: 'pointer' }}>change</label>
                <input
                  style={{ display: "none" }}
                  id="image_files"
                  name="image_files"
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  onChange={imageHandler}
                />
              </div>
            </>
          }
          </div>

          <div className="personal-info" >
            <ul className="business-input-field-wrap">
              <li>
                <span className="send-text">Business address:</span>
                <input className="business-input"
                  type="text"
                  value={address}
                  onChange={(e) => setaddress(e.target.value)}
                />
                {/* <span><input type="text" value="7 Adefowope street Ilupeju, Lagos"/></span> */}

              </li>
              <li>
                <span className="send-text">Website address:</span>
                <input className="business-input"
                  placeholder="website.com"
                  type="text"
                  value={website}
                  onChange={(e) => setwebsite(e.target.value)}
                />
                {/* <span>www.jagbanpress.com</span> */}
              </li>
              <li>
                <span className="send-text">Prefered means of deliveries:</span>
                <span>
                {
                  showmode ? 
                  <Multiselect
                    options={options} // Options to display in the dropdown
                    onSelect={onSelect}
                    onRemove={onRemove} // Function will trigger on remove event
                    showCheckbox={true}
                    isObject={false}
                    placeholder={mode_of_transport.length === 0 ? "E.g car, bike, van" : ""}
                    style={CustomStyle}
                  />
                  :
                  mode?.map((x,i)=>(
                    <span key={i} onClick={()=>setshowmode(true)}>{x},</span>
                  ))
                }
                </span>
              </li>
              <li>
                <span className="send-text">Average number of dispatches per week:</span>
                <input className="business2-input"
                  type="number"
                  placeholder="Average number of dispatches per week"
                  value={average}
                  onChange={(e) => setaverage(e.target.value)}
                />
                {/* <span>20</span> */}
              </li>
              <li>
                <span className="send-text">Goods description:</span>
                <input className="business-input"
                  type="text"
                  placeholder="Goods description"
                  value={good_desc}
                  onChange={(e) => setgood_desc(e.target.value)}
                />
                {/* <span>Printing materials</span> */}
              </li>

            </ul>

            <div className="change-password">
              <div className={passclick ? "passnone" : "changebox"}>
                <p onClick={()=>history.push(forgetpass)}  >Change password</p>

              </div>

              <input type="password" className={passclick ? "password-change" : "passnone"} />
            </div>

            <div className="update-info-btn">
              <button className="cancel-update">Cancel</button>

              <button onClick={onSaveClick} className="save-update" disabled={loading}>
                {
                  loading ?
                    <span className="btnlabe">
                      <PuffLoader color='white' loading={loading} speedMultiplier={1} size={24} />
                    </span>
                    :
                    <span>
                      Save
                </span>
                }
              </button>

            </div>
          </div>

        </div>



      </div>
        <SenderBottomNav/>
      
    </div>
  );
}
