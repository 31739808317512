import React,{useState,useContext} from 'react'
import Layout from '../SenderDashboard/Layout'
import "./SenderRegistartion.css"
import { useForm, Controller } from "react-hook-form";
import {  FormControl, Grid, InputLabel } from '@material-ui/core';
import InputField from '../../../components/InputField';
import { yupResolver } from "@hookform/resolvers/yup";
import {ImSortNumbericDesc, ImLocation} from 'react-icons/im'
import {BiGlobe} from 'react-icons/bi'
import { IoDocumentText} from 'react-icons/io5';
import * as yup from "yup";
import Multiselect from 'multiselect-react-dropdown';
import { PuffLoader } from 'react-spinners';
import SecureLS from 'secure-ls';
import Swal from 'sweetalert2';
import axios from 'axios';
import { ConnContext } from '../../../context/ConnContext';
import { SenderContext } from '../../../context/SenderDashboard/SenderDashboardContext';

export default function SenderUpdateReg({closePopup, reload}) {
    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
    
    const {visitingCountryCode} = useContext(ConnContext)
    const { UpdateData, UpdateAlldata } = useContext(SenderContext);
 
    const endpoint = process.env.REACT_APP_API;

    const [mode_of_transport, setMode_of_transport] = useState([]);
    const [modeValid, setmodeValid] = useState(false)
    const [loading, setloading] = useState(false)
    
    const schema = yup.object().shape({
        business_address: yup.string().required("Business address is required").min(10, "Can't be lesser than 10 digits").max(60, "Must be exactly 60 digits"),
    })

    const { control,handleSubmit, formState: { errors } } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });
    const submitRegForm = (data)=>{
        setloading(true)
        const dataToSend = {
            country_code: visitingCountryCode,
            sender_email: ls.get('eid'),
            account_type: "business",
            prefered_means_of_deliveries: mode_of_transport,
            website_address: data.website_address,
            business_address: data.business_address,
            good_description: data.description,
            average_number_of_dispatches_per_week: data.average,
        }

        axios.post(`${process.env.REACT_APP_API}sender-reg`,
            dataToSend
            )
            .then((res)=>{
                if(res.status === 200){
                    closePopup()    
                    setloading(false)
                    Swal.fire({
                        icon: "success",
                        text: "Business info updated",
                        showConfirmButton: true,
                    })
                    .then(()=>{ reload()})
                }
            })
            .catch((err)=>{
                console.log(err)
                setloading(false)
            })
    }

    const onRemove = (selectedList, selectedItem)=>{
    
        const selectedListToLower = selectedList.map(name => name.toLowerCase())

        if (selectedList.length === 0){
          setmodeValid(false)
        }
        else{setmodeValid(true)}
        setMode_of_transport(selectedListToLower)
      }

    const onSelect = (selectedList, selectedItem)=>{
        const selectedListToLower = selectedList.map(name => name.toLowerCase())
       
        if (selectedList.length === 0){
          setmodeValid(false)
        }
        else{setmodeValid(true)}
        setMode_of_transport(selectedListToLower)
      }

      const CustomStyle ={
            chips: { 
            background: 'var(--main)'
            },
            option: {
            background:'none', color:'black', margin:'10px'
            },
        }
    const options = ['Bike', 'Car', 'Truck', 'Van']

    return (
        <div>
            <div className="updateCon">
                <div className="formContain">
                    <form style={{ width: '100%' }} onSubmit={handleSubmit(submitRegForm)}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <h3 style={{color:'var(--main)', fontSize:'22px'}}>Setup your business information</h3>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="business_address"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <FormControl style={{ width: '100%' }} variant="outlined">
                                        {/* <InputLabel htmlFor="business_address"><ImLocation style={{ marginRight: "5px", color: "var(--main)" }} />Business address</InputLabel> */}
                                        <InputField
                                        label="Business address"
                                            {...field}
                                            error={errors.business_address}
                                            id="business_address"
                                            type="text"
                                            labelWidth={130}
                                            icon={<ImLocation style={{ marginRight: "5px", color: "var(--main)" }} />}
                                        />
                                        <div className="err">{errors.business_address?.message}</div>
                                    </FormControl>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="website_address"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <FormControl style={{ width: '100%' }} variant="outlined">
                                        {/* <InputLabel htmlFor="website_address"><BiGlobe style={{ marginRight: "5px", color: "var(--main)" }} />Website address</InputLabel> */}
                                        <InputField
                                        label="Website address (optional)"
                                            {...field}
                                            error={errors.website_address}
                                            id="website_address"
                                            type="text"
                                            labelWidth={130}
                                            icon={<BiGlobe style={{ marginRight: "5px", color: "var(--main)" }} />}
                                        />
                                        <div className="err">{errors.website_address?.message}</div>
                                    </FormControl>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h5 style={{color:'#444343', marginBottom:'5px'}}>Prefered means of delivery</h5>
                                <Multiselect
                                    options={options} // Options to display in the dropdown
                                    onSelect={onSelect}
                                    onRemove={onRemove} // Function will trigger on remove event
                                    showCheckbox={true}
                                    isObject={false}
                                    placeholder="Select means of delivery"
                                    style={CustomStyle}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="average"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <FormControl style={{ width: '100%' }} variant="outlined">
                                        {/* <InputLabel htmlFor="average"><ImSortNumbericDesc style={{ marginRight: "5px", color: "var(--main)" }} />Average number of dispatches per week</InputLabel> */}
                                        <InputField
                                        label="Average number of dispatches per week"
                                            {...field}
                                            error={errors.average}
                                            id="average"
                                            type="number"
                                            labelWidth={270}
                                            icon={<ImSortNumbericDesc style={{ marginRight: "5px", color: "var(--main)" }} />}
                                        />
                                        <div className="err">{errors.average?.message}</div>
                                    </FormControl>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="description"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => <FormControl style={{ width: '100%' }} variant="outlined">
                                        {/* <InputLabel htmlFor="description"><IoDocumentText style={{ marginRight: "5px", color: "var(--main)" }} />Enter goods description e.g Printing materials</InputLabel> */}
                                        <InputField
                                        label="Enter goods description e.g Printing materials"
                                            {...field}
                                            error={errors.description}
                                            id="description"
                                            type="text"
                                            labelWidth={310}
                                            icon={<IoDocumentText style={{ marginRight: "5px", color: "var(--main)" }} />}
                                        />
                                        <div className="err">{errors.description?.message}</div>
                                    </FormControl>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <button type='submit' style={{width:"100%",textAlign:'center', userSelect:'none',cursor:'pointer', padding:'10px', backgroundColor:'var(--main)', color:'white', borderRadius:'3px', border:0}}>
                                    {
                                        loading ? 
                                        <span className="btnlabe">
                                            <PuffLoader color='white' loading={loading}  speedMultiplier={1} size={24}/>
                                        </span>
                                        :
                                        <span>
                                            Save  
                                        </span>
                                        
                                    }
                                </button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    )
}
