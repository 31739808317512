import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "../Stripe/stripestyle.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import SecureLS from "secure-ls";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

export default function CheckoutForm({
  amount_paid,
  quote_amount,
  item,
  state,
  promocode,
  discount,
  transaction,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [TransDate, setTransDate] = useState("");

  const history = useHistory();

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: ls?.get("_utk"),
    },
  });

  useEffect(() => {
    // console.log(state, "stateeeeeeee checkout");

    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    setTransDate(date);

    // console.log(location, "location");
    // console.log(item, "checkoutform");

    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
    //   console.log(paymentIntent, "payment-intent-1");
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          setIsLoading(false);
          break;
        case "processing":
          setMessage("Your payment is processing.");
        //   console.log(paymentIntent, "payment-intent-2");
          setIsLoading(false);
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          setIsLoading(false);
          break;
        default:
          setMessage("Something went wrong.");
          setIsLoading(false);
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: "http://localhost:3000",
        },
        redirect: "if_required",
      })
      .then((res) => {
        // console.log(res, "resssss");
        if (res?.paymentIntent?.status === "succeeded") {
          setIsLoading(false);
          //  window.location.reload()

          const data =
            promocode !== ""
              ? {
                  action_type: "make_payment",
                  delivery_id: `${item?.delivery_id}`,
                  fulfiller_id: `${item?.fulfiller_id}`,
                  amount_paid: `${item?.quote_amount}`,
                  payment_date: TransDate,
                  quoted_amount: `${item?.quote_amount}`,
                  payment_status: "success",
                  StatusCode: 200,
                  reference_id: res?.paymentIntent?.id,
                  message: "success",
                  transaction: res?.paymentIntent?.id,
                  payment_gateway: "stripe",
                  promocode: promocode,
                  promo_discount: discount,
                }
              : {
                  action_type: "make_payment",
                  delivery_id: `${item?.delivery_id}`,
                  fulfiller_id: `${item?.fulfiller_id}`,
                  amount_paid: `${item?.quote_amount}`,
                  payment_date: TransDate,
                  quoted_amount: `${item?.quote_amount}`,
                  payment_status: "success",
                  StatusCode: 200,
                  reference_id: res?.paymentIntent?.id,
                  message: "success",
                  transaction: res?.paymentIntent?.id,
                  payment_gateway: "stripe",
                  //   promocode: item?.promoCode,
                  //   promo_discount: `${item.promoDetails?.discount === undefined ? '' :   item?.promoDetails?.discount}`,
                };
          // if(reference.status === 'success'){
          authAxios
            .post(
              `m-dillivry-quote-manager?delivery_location=${item?.vcc === undefined ? item?.delivery_location : item?.vcc}`,
              data,
              { timeout: 240000 }
            )
            .then((response) => {
              Swal.fire({
                icon: "success",
                text: "Payment made successfully",
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  // history.push('viewQuote/quotes')
                  window?.location?.reload();
                }
              });
            })
            .catch((err) => {
              console.log("err", err);
              console.log("err", err?.data);
              setMessage("An error occured");
              if (err?.response?.status === 403) {
                Swal.fire({
                  icon: "warning",
                  title: "Session!",
                  text: "Session expired login again!",
                  allowOutsideClick: false,
                  confirmButtonColor: "var(--main)",
                }).then((result) => {
                  if (result.value) {
                    ls.removeAll();
                    history.push(`/login`);
                  }
                });
              }
            });

        //   console.log("Call quote manager now");
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setIsLoading(false);
      });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const handleSubmitmultiple = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: "http://localhost:3000",
        },
        redirect: "if_required",
      })
      .then((res) => {
        // console.log(res, "resssss");
        if (res?.paymentIntent?.status === "succeeded") {
          setIsLoading(false);
          //  window.location.reload()

          const data =
            promocode !== ""
              ? {
                  action_type: "make_payment",
                  transactions: transaction,

                  amount_paid: `${amount_paid}`,
                  payment_date: TransDate,
                  quoted_amount: `${quote_amount}`,
                  payment_status: "success",
                  StatusCode: 200,
                  reference_id: res?.paymentIntent?.id,
                  message: "success",

                  transaction: res?.paymentIntent?.id,
                  payment_gateway: "stripe",
                  promocode: promocode,
                  promo_discount: discount,
                  //    promocode: item?.promoCode,
                  //    promo_discount: `${item.promoDetails?.discount === undefined ? '' :   item?.promoDetails?.discount}`,
                }
              : {
                  action_type: "make_payment",
                  transactions: transaction,

                  amount_paid: `${amount_paid}`,
                  payment_date: TransDate,
                  quoted_amount: `${quote_amount}`,
                  payment_status: "success",
                  StatusCode: 200,
                  reference_id: res?.paymentIntent?.id,
                  message: "success",

                  transaction: res?.paymentIntent?.id,
                  payment_gateway: "stripe",
                  //  promocode: item?.promoCode,
                  //  promo_discount: `${item.promoDetails?.discount === undefined ? '' :   item?.promoDetails?.discount}`,
                };
          // if(reference.status === 'success'){
          authAxios
            .post(
              `m-dillivry-quote-manager?delivery_location=${transaction[0].delivery_location}`,
              data,
              { timeout: 240000 }
            )
            .then((response) => {
              Swal.fire({
                icon: "success",
                text: "Payment made successfully",
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  // history.push('viewQuote/quotes')
                  window?.location?.reload();
                }
              });
            })
            .catch((err) => {
              console.log("err", err);
            //   console.log("err", err?.data);
              setMessage("An error occured");
              if (err?.response?.status === 403) {
                Swal.fire({
                  icon: "warning",
                  title: "Session!",
                  text: "Session expired login again!",
                  allowOutsideClick: false,
                  confirmButtonColor: "var(--main)",
                }).then((result) => {
                  if (result.value) {
                    ls.removeAll();
                    history.push(`/login`);
                  }
                });
              }
            });

        //   console.log("Call quote manager now");
        }
      })
      .catch((err) => {
        console.log(err, "err");
        setIsLoading(false);
      });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <div className="stripecontainer">
      <div className="stripebody">
        <form
          id="payment-form"
          onSubmit={state === "checkout" ? handleSubmitmultiple : handleSubmit}
        >
          <PaymentElement id="payment-element" />
          <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">
              {isLoading ? (
                <div className="spinner" id="spinner"></div>
              ) : (
                "Pay now"
              )}
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message">{message}</div>}
        </form>
      </div>
    </div>
  );
}
