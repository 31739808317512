import { Button, Dialog,DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

function DialogSimplePop({btnText,hideBtn, children, openPopup, onClick}) {
    const theme = createTheme({
        palette: {
          primary: {
            main: '#50B14A',
          },
        },
      });
    return (
      <ThemeProvider theme={theme}>
        <Dialog open={openPopup} maxWidth="sm">
          <DialogContent>
            {children}
            {
              hideBtn ? 
                <></>
                :
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: '20px'
                  }}
                >

                  <Button color="primary" variant='contained' onClick={onClick}>
                    <span style={{ color: 'white', textTransform: 'none' }}>{btnText}</span>
                  </Button>
                </div>
            }
           
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
}
export default DialogSimplePop;