import { useContext, useEffect, useState } from 'react'
// import './ViewDelivery.css';
import Carousel from 'react-material-ui-carousel';
import GoogleMapWidget from '../../../components/Map/GoogleMapWidget';
import dillivry from '../../../assets/Dillivry.png'
import { AiFillFileText } from 'react-icons/ai'
import { MdLocationOn } from 'react-icons/md'
import { FaShuttleVan } from 'react-icons/fa'
import { GiPathDistance } from 'react-icons/gi'
import Recta from "../../../assets/Recta.svg"
import msgpi from "../../../assets/msgpi.svg";
import cursworking from "../../../assets/cursworking.png";
import dpddd1 from "../../../assets/dpddd1.png";
import PuffLoader from 'react-spinners/PuffLoader';
import to from "../../../assets/to.svg";
import From from "../../../assets/From.svg";

import { Button, Grid } from '@material-ui/core'
import { useForm } from "react-hook-form";
import axios from 'axios'
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BiArrowBack } from 'react-icons/bi';
import DialogOutClick from '../../../components/DialogOutClick';
import ViewFulfiller from '../../Fulfiller/Dashboard/ViewFulfiller';
import PaystackWidget2 from '../../../components/PaystackWidget2';

import { Radio, RadioGroup, CssBaseline } from '@material-ui/core';
import { Select, MenuItem, FormControl, FormControlLabel, InputLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import PaystackWidget from '../../../components/PaystackWidget';
import SecureLS from 'secure-ls';
import ReactTooltip from 'react-tooltip';
import { IconContext } from "react-icons";
import { AiOutlineInfoCircle } from "react-icons/ai";
import HomeHelp from '../../../components/HomeHelp';
import { ConnContext } from '../../../context/ConnContext';
import GlobalizationLanguage from '../../../components/GlobalizationLanguage';
import Cookies from 'js-cookie';



function ShowQuote1({ data, handleChange }) {
  var viewQuote = GlobalizationLanguage("/viewQuote")
  var timeout = GlobalizationLanguage("/timeout")

  const ls = new SecureLS({ isCompression: true, encodingType: 'rabbit', encryptionSecret: 'dm_dillivry' });

  useEffect(() => {
    setviewData([...viewData, data]);
    // console.log(data)
    const fulfi = data?.quote_detail.filter(function (accepted) { return accepted.quote_status === "accepted" })
    setacceptedDetails(data?.quote_history.filter(function (fulfillerdetail) { return fulfillerdetail?.fulfiller_id == fulfi[0]?.fulfiller_id }))
    setbestQuoteFulfillerDetails(data?.quote_history.filter(function (fulfillerdetail) { return fulfillerdetail?.quote_amount == data?.best_quote }))
    window.scrollTo(0, 0)
  }, [])

  const history = useHistory();
  const {visitingCountryCode} = useContext(ConnContext)
  const [showSubmitQuote, setShowSubmitQuote] = useState(false)
  const [isloading, setisloading] = useState(false)
  const [submiting, setsubmiting] = useState(false)
  const [showQuoteType, setshowQuoteType] = useState('quote');
  const [delivery_id, setdelivery_id] = useState('')
  const [delivery_location, setdelivery_location] = useState('')
  const [userEmail, setuserEmail] = useState('');
  const [showHelp, setshowHelp] = useState(false);
  const [showAccept, setshowAccept] = useState(false)
  const [amountToAccept, setamountToAccept] = useState('')
  const [fulfilllerToAccept, setfulfilllerToAccept] = useState('')
  const [acceptType, setAcceptType] = useState('')

  const payOnDelivery = () => {
    setisloading(true)
    authAxios.post(`m-dillivry-quote-manager?delivery_location=${delivery_location}`, {
      action_type: "accept",
      payment_type: 'on_delivery',
      delivery_id: delivery_id,
      fulfiller_id: fulfilllerToAccept,
      quoted_amount: amountToAccept,
      encrypted_token: btoa(btoa(ls.get('_utk')))
    }, {timeout: 240000})
      .then((response) => {
        setshowAccept(true)
        Swal.fire({
          icon: 'success',
          text: 'Quote Accepted, Pay on delivery',
          timer: 5000,
          confirmButtonColor: 'var(--main)'
        })
        ls.remove("item");
        ls.remove("delivery_id")
        history.push(viewQuote)
        setisloading(false)
      })
      .catch((err) => {

        if (err?.response?.status === 403) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              ls.removeAll();
              history.push(`/login`);
            }
          });
        }
        else{
          Swal.fire({
            icon: 'warning',
            text: 'Request timeout',
            timer: 5000
          })
        }
        setshowAccept(false)
        setisloading(false)
        if (err.code === "ECONNABORTED") {
          console.log('internal-server-error-timeout')
          history.push(timeout)
          // window.location.reload()
        }
      })
  }
  const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const useStyles = makeStyles(() => ({
    formControl: {
      width: `100%`,
    },
  }));

  const [fulfillerToShow, setfulfillerToShow] = useState({})
  const [acceptedDetails, setacceptedDetails] = useState([]);
  const [bestQuoteFulfillerDetails, setbestQuoteFulfillerDetails] = useState([]);
  const [showAgent, setShowAgent] = useState(false);

  const [showdrop, setShowdrop] = useState(false)
  const handleClick = () => {
    setShowdrop(showdrop => !showdrop)
  }


  // const Authorization = ls.get('_nodi') === null ? '' : ls.get('_nodi')));
  const Authorization = ls.get('_utk')

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: Authorization
    }
  });

  const schema = yup.object().shape({
    QAmount: yup.string().required("Amount required").matches(/^[0-9]*$/, 'Amount has to be number'),
  });
  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const quoteNow = (formData) => {
    setsubmiting(true)
    authAxios.post(`m-dillivry-bid-manager?delivery_location=${Cookies.get("i18next")}`, {
      action_type: "quote",
      delivery_id: data.delivery_id,
      quote_amount: formData.QAmount.replaceAll(',', ''),
      msg: formData.msg,
      vcc: visitingCountryCode
    }, {timeout: 240000})
      .then((response) => {
        setShowSubmitQuote(false)
        setsubmiting(false)
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            text: 'Quote placed successfully!',
            timer: 2500,
            showConfirmButton: false,
          })
            .then(() => {
              window.location.reload()
            });
        }
      })
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          console.log('internal-server-error-timeout')
          history.push(timeout)
          // window.location.reload()
        }
        setShowSubmitQuote(false)
        // console.log(err)
        setsubmiting(false)
        Swal.fire({
          icon: 'warning',
          text: 'Session expired, login again!',
          timer: 2500,
          showConfirmButton: false,
        })
          .then((result) => {
            if (result.value) {
              //  auth.logout();
              history.push(`/login`)
            }
          });
          if (err.code === "ECONNABORTED") {
            console.log('internal-server-error-timeout')
            history.push(timeout)
            // window.location.reload()
          }
      })
  }

  const [viewData, setviewData] = useState([]);

  const [origin, setorigin] = useState([]);
  const [destination, setdestination] = useState([]);

  useEffect(() => {
    // console.log(data)
    setviewData([...viewData, data])
    const startLat = parseFloat(data.start_location.lat)
    const startlng = parseFloat(data.start_location.lng)
    const endLat = parseFloat(data.end_location.lat)
    const endLng = parseFloat(data.end_location.lng)

    setorigin([
      {
        id: 'pickup',
        title: 'Pickup',
        coordinates: {
          lat: startLat,
          lon: startlng
        },
      }
    ])
    setdestination([
      {
        id: 'destination',
        title: 'Destination',
        coordinates: {
          lat: endLat,
          lon: endLng
        },
      }
    ])
  }, [])

  const numberWithCommas = new Intl.NumberFormat("en-GB")

  return (
    <div className="viewSearch">
      {/* {
                showQuoteType === 'quote' ?
                    <div  className="fulfillerViewTop">
                        <div onClick={handleChange}>
                            <BiArrowBack size='25px'/>
                        </div> 
                        <div className='viewLogo'>
                            <img src={dillivry} alt="logo"/>
                        </div>
                        <div className='topCircle'></div>
                    </div> 
                    :
                    null
            } */}
      <button
        style={{ backgroundColor: "#50B14A", border: "none", padding: "5px 5px 5px 5px", borderRadius: "4px", marginLeft: "20px", marginTop: "20px" }}
        onClick={handleChange}
      >
        <span style={{ color: "#FFFFFF" }}>Back</span>
      </button>


      {
        showQuoteType === 'quote' ?
          viewData.map((view) => {
            return <div key={view.delivery_id} className="view_Container">

              <div className="view_Top_mobile">
                <div className="topItem">
                  <div><span style={{ fontWeight: '600' }}>Item category: </span><span>
                    {view.item_category.charAt(0)?.toUpperCase() + view.item_category.slice(1).replace(/_/g, " ")}
                  </span></div>
                  <div><span style={{ fontWeight: '600' }}>ID: </span><span>{view.delivery_id}</span></div>
                </div>
              </div>

              <div className="view_Map_mobile">
                <GoogleMapWidget
                  origins={destination}
                  destinations={origin}
                />
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '20px' }}>
                <span></span>
                <Button
                  size="small"
                  variant="contained"
                  style={{ fontSize: '12px', border: '1px solid var(--main)', backgroundColor: 'var(--main)', color: 'white', textTransform: 'none' }}
                  // onClick={() => setshowHelp(!showHelp)}
                  onClick={() => setshowHelp(!showHelp)}
                >
                  Report
                </Button>
              </div>
              <div className='submitQuot'>
                <div className="bodyItem" >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <div className='imgage-content'>
                            <Carousel navButtonsAlwaysInvisible={true} indicators={true} animation={"slide"} timeout={100} autoPlay={true}>
                              {
                                view.image_urls?.map((img_url, i) => <img key={i} src={img_url} style={{ width: "100%", height: "100%" }} className="carousel_image-" />)
                              }
                            </Carousel>
                          </div>
                        </Grid>
                        {/* <Grid item xs={9}>
                                                <div className="itemDescs">
                                                    <div className="itemDesc">
                                                        <span style={{fontWeight:'600'}}>Pickup Date:</span><span style={{fontWeight:'400'}}>{view.pickup_date}</span>
                                                    </div>
                                                    <div className="itemDesc">
                                                        <span style={{fontWeight:'600'}}>Delivery time frame:</span><span style={{fontWeight:'400'}}>{view.delivery_time_frame}</span>
                                                    </div>
                                                </div>
                                            </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>

                      {
                        view.delivery_status === "awaiting_quote" ?
                          view.total_quote_received === 0 ?
                            <> </>
                            :

                            <>
                            {
                              bestQuoteFulfillerDetails.length === 0 ?
                              null
                              :
                              <>  
                              <div className="right-content-information">
                                <div className="betqt">
                                  <div className="betqthead">
                                    <p> Best quote</p>
                                  </div>
                                  <div className="betgrcontain">
                                    <div className="betgrcontainpic">
                                      {/* <img src={bestQuoteFulfillerDetails[0].provider_info.profile_photo.profile_photo} alt="Recta_icon" style={{ width: "60px", height: "60px" }} /> */}
                                     <img src={bestQuoteFulfillerDetails[0].provider_info.cop_logo} alt="Recta_icon" style={{ width: "60px", height: "60px" }} />
                                      
                                    </div>
                                    <div className="betgrcontaintext">
                                      <p
                                        onClick={() => {
                                          setfulfillerToShow(bestQuoteFulfillerDetails[0].provider_info);
                                          setshowQuoteType('fulfiller')
                                          // console.log(bestQuoteFulfillerDetails[0].provider_info);

                                        }}
                                        style={{ color: "#50B14A", cursor: "pointer" }}>{bestQuoteFulfillerDetails[0]?.provider_info.account_holder?.first_name + " " + bestQuoteFulfillerDetails[0]?.provider_info.account_holder?.last_name}</p>
                                      <p>{bestQuoteFulfillerDetails[0].provider_info.Positive_feedback} </p>
                                      <p>  <img src={msgpi} alt="msgpi_icon" />&ensp;{bestQuoteFulfillerDetails[0]?.provider_info.good_in_transit_insurance.state}</p>
                                    </div>
                                  </div>
                                  <div className="betqprice" >
                                    <p>&#8358;{
                                      numberWithCommas.format(bestQuoteFulfillerDetails[0].quote_amount)
                                    }</p>
                                  </div>
                                  <div className="betqinfo-">
                                    <p>Insurance <img src={msgpi} alt="msgpi_icon" />: {bestQuoteFulfillerDetails[0]?.provider_info.good_in_transit_insurance.state} </p>
                                  
                                    <button style={{ cursor: "pointer" }} className="betbt"
                                      onClick={() => { setshowAccept(true); setdelivery_id(view.delivery_id);setdelivery_location(view.vcc); setamountToAccept(bestQuoteFulfillerDetails[0]?.quote_amount); setfulfilllerToAccept(bestQuoteFulfillerDetails[0]?.fulfiller_id) }}
                                    > Accept quote</button>
                                  </div>
                                </div>
                              </div> 
                              </>
                            }

                            </>
                          :
                          view.delivery_status === "awaiting_agent_assignment" ?
                            <>
                              <div style={{ width: "100%" }} className="right-content-information">
                                <div className="betqt">
                                  <div className="betqthead">
                                    <p> Accepted quote</p>
                                  </div>
                                  <div className="betgrcontain">
                                    <div className="betgrcontainpic">
                                      <img src={acceptedDetails[0]?.provider_info?.cop_logo} alt="Recta_icon" style={{ width: "60px", height: "60px" }} />
                                    </div>
                                    <div className="betgrcontaintext">
                                      <p style={{ color: "#50B14A", cursor: 'pointer' }}
                                        onClick={() => {
                                          setfulfillerToShow(bestQuoteFulfillerDetails[0].provider_info);
                                          setshowQuoteType('fulfiller')
                                          // console.log(bestQuoteFulfillerDetails[0].provider_info);


                                        }}
                                      >{acceptedDetails[0]?.provider_info.account_holder?.first_name + " " + acceptedDetails[0]?.provider_info.account_holder?.last_name}</p>
                                      <p> {acceptedDetails[0]?.provider_info.Positive_feedback}</p>
                                      <p>  <img src={msgpi} alt="msgpi_icon" />&ensp;{bestQuoteFulfillerDetails[0]?.provider_info.good_in_transit_insurance.state}</p>
                                    </div>
                                  </div>
                                  <div className="betqprice" >
                                    <p>&#8358;{
                                      numberWithCommas.format(acceptedDetails[0]?.quote_amount)}</p>
                                  </div>
                                  <div className="betqinfo-1">
                                    <p>Insurance <img src={msgpi} alt="msgpi_icon" />: {bestQuoteFulfillerDetails[0]?.provider_info.good_in_transit_insurance.state} </p>
                                    {/* <p>of Goods by Road up to ₦20,000</p> */}
                                  </div>

                                </div>
                              </div>
                            </>
                            :
                            <>
                              <div className="AgetDe">
                                <div className="AgetDetop" style={{ width: "100%" }}> Agent details
                                </div>
                                <div className="AgetDepictext" style={{ width: "100%" }}>
                                  <div className="AgetDepic">
                                    {/* <img src={data?.agent_pics} alt="agent_icon"  style={{width:"70px",height:"70px"}}/>*/}
                                    {view.delivery_status === "awaiting_pickup" ?
                                      <>

                                        <img src={data?.agent_pics} alt="agent_icon" style={{ width: "70px", height: "70px" }} />
                                      </>
                                      :
                                      <>
                                        <img src={data?.d_agent_pics} alt="Recta_icon" style={{ width: "60px", height: "60px" }} />
                                      </>


                                    }
                                  </div>
                                  <div className="AgetDetext">
                                    <p>{data?.agent_name}</p>
                                    <p>{data?.d_agent_name}</p>
                                    <button className="showidbt" onClick={() => setShowAgent(true)}> Show ID</button>
                                  </div>
                                </div>
                                <div className="Ageinfo" style={{ height: "15%", width: "100%" }}>
                                  <p> Completed Deliveries: &nbsp; ({data?.completed_jobs.completed_jobs})</p>
                                </div>
                              </div>
                            </>
                      }
                      {/* <div className="qH">
                                            Quote information
                                        </div>
                                        <div className="qB">
                                                
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={1} >
                                                            <Grid item xs={4}>
                                                                <span style={{fontWeight:'600', fontSize:'13px'}}>
                                                                Best Quote:
                                                                </span>
                                                                
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <span style={{ fontSize:'14px'}}>
                                                                &#8358;{numberWithCommas.format(view?.best_quote)}
                                                                </span>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid  container spacing={2} style={{alignItems:"center"}}>
                                                            <Grid item xs={4}>
                                                                <span style={{fontWeight:'600', fontSize:'13px'}}>
                                                                Your Quote: &#8358;
                                                                </span>
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {
                                                                    <input type='button' value="Submit quote" style={{outline:'none',borderRadius:'5px', border:'none',color:'white', fontWeight:'600', backgroundColor:'var(--main)', height:'30px', width:'100%'}}
                                                                    onClick={()=>setShowSubmitQuote(true)}/> 
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                        </div> */}

                    </Grid>
                    <Grid item xs={12} style={{ fontSize: '12px' }}>
                      {/* <Grid container spacing={1} style={{marginBottom:'7px'}}><Grid item xs={5} style={{textAlign:'left', fontWeight:'600'}}> <span style={{marginRight:'5px'}}><AiFillFileText/></span> Description:</Grid><Grid style={{textAlign:'right'}} item xs={7}> {view.item_desc.charAt(0)?.toUpperCase() + view.item_desc.toLowerCase().slice(1)}</Grid></Grid>
                                        <Grid container spacing={1} style={{marginBottom:'7px'}}><Grid item xs={5} style={{textAlign:'left', fontWeight:'600'}}><span style={{marginRight:'5px', color:'var(--main)'}}><MdLocationOn/></span> Pickup address:</Grid><Grid style={{textAlign:'right'}} item xs={7}> {view.from}</Grid></Grid>
                                        <Grid container spacing={1} style={{marginBottom:'7px'}}><Grid item xs={5} style={{textAlign:'left', fontWeight:'600'}}><span style={{marginRight:'5px', color:'red'}}><MdLocationOn/></span> Delivery address:</Grid><Grid style={{textAlign:'right'}} item xs={7}> { view.to}</Grid></Grid>
                                        <Grid container spacing={1} style={{marginBottom:'7px'}}><Grid item xs={5} style={{textAlign:'left', fontWeight:'600'}}><span style={{marginRight:'5px', color:'blue'}}><FaShuttleVan/></span> Mode of delivery:</Grid><Grid style={{textAlign:'right'}} item xs={7}> { view.mode_of_delivery}</Grid></Grid>
                                        <Grid container spacing={1} style={{marginBottom:'7px'}}><Grid item xs={5} style={{textAlign:'left', fontWeight:'600'}}><span style={{marginRight:'5px', color:'var(--main)'}}><GiPathDistance/></span> Distance:</Grid><Grid style={{textAlign:'right'}} item xs={7}> { view.distance}</Grid></Grid>
                                        <Grid container spacing={1} style={{marginBottom:'7px'}}><Grid item xs={5} style={{textAlign:'left', fontWeight:'600'}}><span style={{marginRight:'5px', color:'blue'}}><FaShuttleVan/></span> Status:</Grid><Grid style={{textAlign:'right'}} item xs={7}> { view.delivery_status}</Grid></Grid>
                                        <Grid container spacing={1} style={{marginBottom:'7px'}}><Grid item xs={5} style={{textAlign:'left', fontWeight:'600'}}><span style={{marginRight:'5px', color:'blue'}}><FaShuttleVan/></span> Payment Status:</Grid><Grid style={{textAlign:'right'}} item xs={7}> {view.payment_status ==="success" ? 'Paid' :
                                                  view.payment_status ==="pay_on_delivery" ? 'Pay on Delivery' :
                                                  view.payment_status ==="NA" ? 'Pay on Delivery' : 
                                                  view.payment_status
                                                } 
                                                
                                                </Grid> */}

                      {/* </Grid> */}
                      {/* {
                                                        view.payment_status ==="pay_on_delivery" ?
                                                        <> 
                                                      
                                                        <div style={{marginLeft:"-40px"}}>
                                                        <PaystackWidget2 
                                                                  amount={view.quote_amount + '00'} //for paystack
                                                                  amount_paid={view.quote_amount}
                                                                  fulfiller_id={view.fulfiller_id}
                                                                  delivery_id={view.delivery_id}
                                                                  email={ls.get('eid')))}
                                                                  closePopup={()=>console.log('loading')}
                                                              />
                                                              </div>
                                                        </>
                                                        :
                                                        <> </>
                                                     }      */}


                      <Grid>
                        <div style={{ height: "100%", border: "1px solid #EAEAEA", borderRadius: "6px", width: "100%" }}>
                          <div style={{ height: "35px", backgroundColor: "#50B14A", color: "#FFFFFF", fontSize: "14px", padding: "5px 5px 5px 10px", borderTopRightRadius: "6px", borderTopLeftRadius: "6px" }}>
                            <p>Delivery infomation</p>
                          </div>
                          <div style={{ padding: "4px 2px 2px 10px", border: "1px solid #EAEAEA", height: "25px", fontSize: "12px" }}>
                            {/* <span><AiFillFileText/></span>  */}
                            <span data-tip
                              data-for="globaldes">

                              <IconContext.Provider
                                value={{
                                  color: "#2e2e2e",
                                  className: "global-class-name-icon",
                                }}
                              >
                                {" "}
                                <AiFillFileText />
                              </IconContext.Provider>

                              <ReactTooltip
                                id="globaldes"
                                aria-haspopup="true"
                                role="example"
                                type="light"
                              >
                                <p>Item Description</p>
                              </ReactTooltip>
                            </span>
                            &nbsp;&nbsp;<span> {view.item_desc.charAt(0)?.toUpperCase() + view.item_desc.toLowerCase().slice(1)} </span>

                          </div>
                          <div style={{ padding: "4px 2px 2px 10px", border: "1px solid #EAEAEA", height: "25px", fontSize: "12px" }}>
                            {/* <span><img src={dpddd1} alt="" width="10px" /></span>  */}
                            <span data-tip
                              data-for="globalcat">

                              <IconContext.Provider
                                value={{
                                  color: "#2e2e2e",
                                  className: "global-class-name-icon",
                                }}
                              >
                                {" "}
                                <img src={dpddd1} alt="" width="10px" />
                              </IconContext.Provider>

                              <ReactTooltip
                                id="globalcat"
                                aria-haspopup="true"
                                role="example"
                                type="light"
                              >
                                <p>Item Category</p>
                              </ReactTooltip>
                            </span>

                            &nbsp; &nbsp;&nbsp;<span>
                              {view.item_category.charAt(0)?.toUpperCase() + view.item_category.slice(1).replace(/_/g, " ")} </span>
                          </div>
                          <div style={{ padding: "4px 2px 2px 10px", border: "1px solid #EAEAEA", height: "100%", fontSize: "12px" }}>


                            <span data-tip
                              data-for="global5">

                              <IconContext.Provider
                                value={{
                                  color: "#2e2e2e",
                                  className: "global-class-name-icon",
                                }}
                              >
                                {/* <MdLocationOn  style={{ marginRight: "5px", color: "red", fontSize: '18px'}} /> */}
                                {" "}
                                <img src={to} alt="" style={{ width: "8px" }} />
                                {/* <span style={{color:'var(--main)'}}><MdLocationOn/></span> &nbsp;&nbsp; */}
                              </IconContext.Provider>

                              <ReactTooltip
                                id="global5"
                                aria-haspopup="true"
                                role="example"
                                type="light"
                              >
                                <p>Pickup Address</p>
                              </ReactTooltip>
                            </span>

                            {/* <span style={{color:'var(--main)'}}><MdLocationOn/></span> &nbsp;&nbsp; */}


                            &nbsp;&nbsp;<span> {view.from.charAt(0)?.toUpperCase() + view.from.toLowerCase().slice(1)} </span>

                          </div>
                          <div style={{ padding: "4px 2px 2px 10px", border: "1px solid #EAEAEA", height: "100%", fontSize: "12px" }}>
                            <span data-tip
                              data-for="global6">

                              <IconContext.Provider
                                value={{
                                  color: "#2e2e2e",
                                  className: "global-class-name-icon",
                                }}
                              >
                                {" "}
                                <img src={From} alt="" style={{ width: "8px" }} />
                              </IconContext.Provider>

                              <ReactTooltip
                                id="global6"
                                aria-haspopup="true"
                                role="example"
                                type="light"
                              >
                                <p>Delivery Address</p>
                              </ReactTooltip>
                            </span>
                            {/* <span style={{color:'red'}}><MdLocationOn/></span> &nbsp;&nbsp; */}

                            &nbsp;&nbsp;    <span>

                              {view.to.charAt(0)?.toUpperCase() + view.to.toLowerCase().slice(1)} </span>

                          </div>
                          <div style={{ padding: "4px 2px 2px 10px", border: "1px solid #EAEAEA", height: "25px", fontSize: "12px" }}>
                            {/* <span style={{color:'blue'}}><FaShuttleVan/></span>  */}
                            <span data-tip
                              data-for="globalcov">

                              <IconContext.Provider
                                value={{
                                  color: "#2e2e2e",
                                  className: "global-class-name-icon",
                                }}
                              >
                                {" "}
                                <FaShuttleVan style={{ color: 'blue' }} />
                              </IconContext.Provider>

                              <ReactTooltip
                                id="globalcov"
                                aria-haspopup="true"
                                role="example"
                                type="light"
                              >
                                <p>Distance</p>
                              </ReactTooltip>
                            </span>

                            &nbsp;&nbsp;<span>{view.mode_of_delivery.charAt(0)?.toUpperCase() + view.mode_of_delivery.toLowerCase().slice(1)} </span>

                          </div>
                          <div style={{ padding: "4px 2px 2px 10px", border: "1px solid #EAEAEA", height: "25px", fontSize: "12px" }}>
                            {/* <span style={{color:'var(--main)'}}><GiPathDistance/></span>  */}
                            <span data-tip
                              data-for="globalmode">

                              <IconContext.Provider
                                value={{
                                  color: "#2e2e2e",
                                  className: "global-class-name-icon",
                                }}
                              >
                                {" "}
                                <GiPathDistance style={{ color: 'var(--main)' }} />
                              </IconContext.Provider>

                              <ReactTooltip
                                id="globalmode"
                                aria-haspopup="true"
                                role="example"
                                type="light"
                              >
                                <p>Mode of Delivery</p>
                              </ReactTooltip>
                            </span>
                            &nbsp;&nbsp;<span> {view.distance} </span>
                          </div>
                          <div style={{ padding: "4px 2px 2px 10px", border: "1px solid #EAEAEA", height: "25px", fontSize: "12px" }}>
                            <span> Pickup Date:</span>  <span> {view.pickup_date} </span>

                          </div>
                          <div style={{ padding: "4px 2px 2px 10px", border: "1px solid #EAEAEA", height: "25px", fontSize: "12px" }}>
                            <span> Delivery time frame:</span>  <span> {view.delivery_time_frame} </span>

                          </div>


                          <div style={{ padding: "4px 2px 2px 10px", border: "1px solid #EAEAEA", height: "25px", fontSize: "12px" }}>
                            <span>Delivery Status:</span> &nbsp;&nbsp;<span>                {
                              view.delivery_status === "awaiting_quote" ? 'Awaiting Quote' :
                                view.delivery_status === "awaiting_agent_assignment" ? 'Awaiting agent assignment' :
                                  view.delivery_status === "awaiting_pickup" ? 'Awaiting pickup' :
                                    view.delivery_status === "out_for_delivery" ? 'Out for Delivery' :
                                      view.delivery_status === "delivered" ? 'Completed' :
                                        view.delivery_status === "missed_delivery" ? 'Missed' :

                                          view.delivery_status
                            }
                            </span>

                          </div>



                        </div>

                      </Grid>
                      <br></br>
                      <Grid>

                        {
                          view.delivery_status === "awaiting_quote" ?
                            <>
                            </>
                            :
                            <>



                              <div style={{ height: "100%", border: "1px solid #EAEAEA", borderRadius: "6px", width: "100%" }}>
                                <div style={{ height: "27px", backgroundColor: "#50B14A", color: "#FFFFFF", fontSize: "13px", padding: "5px 5px 5px 10px", borderTopRightRadius: "6px", borderTopLeftRadius: "6px" }}>
                                  <p>Payment infomation</p>
                                </div>
                                <div className="paymentstatus" style={{ padding: "4px 2px 2px 10px", border: "none", height: "100%", fontSize: "12px" }}>
                                  <span>Payment Status:</span> &nbsp;&nbsp;<span>{view.payment_status === "success" ? 'Paid' :
                                    view.payment_status === "pay_on_delivery" ? 'Pay on Delivery' :
                                      view.payment_status === "NA" ?
                                        //           <button disabled 
                                        //  style={{border:"none",backgroundColor:"#50B14A",color:"#FFFFFF",padding:"4px 4px 4px 4px",borderRadius:"4px",fontSize:"12px", margin:'7px 0'}}> Book delivery arranged with provider</button>
                                        <span>     <span>

                                          <span
                                            data-tip
                                            data-for="global">


                                            <IconContext.Provider
                                              value={{
                                                color: "#2e2e2e",
                                                className: "global-class-name-icon",
                                              }}
                                            >
                                              Offline&nbsp;<AiOutlineInfoCircle />
                                            </IconContext.Provider>

                                            <ReactTooltip
                                              id="global"
                                              aria-haspopup="true"
                                              role="example"
                                              type="light"
                                            >
                                              <p>Arranged payment between the sender and provider</p>
                                              {/* <p>Mode of movement</p> */}
                                            </ReactTooltip>

                                          </span>
                                        </span>

                                        </span>
                                        :
                                        view.payment_status}

                                    {
                                      view.payment_status === "pay_on_delivery" ?
                                        <>
                                          {/* <button style={{backgroundColor:"#50B14A",borderRadius:"4px", border:"none",color:"#FFFFFF",width:"100px",height:"20px",marginTop:"5px"}}>Make Payment</button> */}
                                          <div style={{ marginLeft: "-40px" }}>
                                            <PaystackWidget2
                                              amount={view.quote_amount + '00'} //for paystack
                                              amount_paid={view.quote_amount}
                                              fulfiller_id={view.fulfiller_id}
                                              delivery_id={view.delivery_id}
                                              email={ls.get('eid')}
                                              closePopup={() => console.log('loading')}
                                            />
                                          </div>
                                        </>
                                        :
                                        <>


                                        </>
                                    }
                                  </span>
                                </div>

                              </div>
                            </>
                        }

                      </Grid>
                    </Grid>
                  </Grid>


                  <Grid>
                    <span>
                      {" "}
                      {
                        view.delivery_status === "out_for_delivery" ?
                          <button onClick={handleClick} style={{ backgroundColor: '#50B14A', border: '1px solid #50B14A', borderRadius: "4px", color: "#FFFFFF", padding: "2px 5px 2px 5px", cursor: "pointer", marginTop: "10px" }}>Track item</button>

                          :
                          view.delivery_status === "missed_delivery" ?
                            <>
                              <button onClick={handleClick} style={{ backgroundColor: '#50B14A', border: '1px solid #50B14A', borderRadius: "4px", color: "#FFFFFF", padding: "2px 5px 2px 5px", cursor: "pointer", marginTop: "10px" }}>Track item</button>
                            </>
                            :

                            <>

                            </>
                      }
                      {/* {
                                view.delivery_status === "missed" ?
                                <p>  na here i dey oo</p>
                                :
                               <> </>
                           } */}
                      {
                        showdrop ?
                          <p><img src={cursworking} alt="tra" style={{ width: "20px", height: "25px", paddingTop: "10px" }} />Item position:</p>
                          :
                          null
                      }

                    </span>
                  </Grid>

                  {
                    view.delivery_status === "awaiting_quote" ?
                      view.total_quote_received === 0 ?
                        <>
                          <div style={{ backgroundColor: 'white', padding: '20px 0' }}>
                            <p style={{ padding: "10px 10px 10px 8px", fontSize: '13px', fontWeight: '600' }}>Quote history</p>
                            <table>
                              <tr>
                                <th style={{ padding: '10px', fontSize: '12px' }}>Quote amount</th>
                                <th style={{ padding: '10px', fontSize: '12px' }}>Logistic provider</th>
                                <th style={{ padding: '10px', fontSize: '12px' }}>Providers info</th>
                              </tr>
                              {
                                view.quote_history?.map((detail, index) => {
                                  return <tr key={index}>
                                    <td style={{ padding: '10px', fontSize: '12px' }}> &#8358;{numberWithCommas.format(detail.quote_amount)}</td>
                                    <td style={{ padding: '10px', fontSize: '12px' }}>{detail.fulfiller_id}</td>
                                    <td style={{ padding: '10px', fontSize: '12px' }}>
                                      <Button

                                        size="small"
                                        variant="contained"
                                        style={{ fontSize: '8px', maxHeight: '20px', border: '1px solid var(--main)', backgroundColor: 'var(--main)', color: 'white', textTransform: 'none' }}
                                        onClick={() => {
                                          setfulfillerToShow(detail.provider_info);
                                          setshowQuoteType('fulfiller')
                                        }}
                                      >
                                        View profile
                                      </Button>
                                    </td>
                                  </tr>
                                })
                              }
                            </table>
                          </div>
                        </>
                        :
                        <>
                          <div style={{ backgroundColor: 'white', padding: '20px 0' }}>
                            <p style={{ padding: "10px 10px 10px 8px", fontSize: '13px', fontWeight: '600' }}>Quote history</p>
                            <table>
                              <tr>
                                <th style={{ padding: '10px', fontSize: '12px' }}>Quote amount</th>
                                <th style={{ padding: '10px', fontSize: '12px' }}>Logistic provider</th>
                                <th style={{ padding: '10px', fontSize: '12px' }}>Providers info</th>
                              </tr>
                              {
                                view.quote_history?.map((detail, index) => {
                                  return <tr key={index}>
                                    <td style={{ padding: '10px', fontSize: '12px' }}> &#8358;{numberWithCommas.format(detail.quote_amount)}</td>
                                    <td style={{ padding: '10px', fontSize: '12px' }}>{detail.fulfiller_id}</td>
                                    <td style={{ padding: '10px', fontSize: '12px' }}>
                                      <Button

                                        size="small"
                                        variant="contained"
                                        style={{ fontSize: '8px', maxHeight: '20px', border: '1px solid var(--main)', backgroundColor: 'var(--main)', color: 'white', textTransform: 'none' }}
                                        onClick={() => {
                                          setfulfillerToShow(detail.provider_info);
                                          setshowQuoteType('fulfiller')
                                        }}
                                      >
                                        View profile
                                      </Button>
                                    </td>
                                  </tr>
                                })
                              }
                            </table>
                          </div>
                        </>
                      :
                      view.delivery_status === "awaiting_agent_assignment" ?
                        <>

                        </>
                        :
                        view.delivery_status === "awaiting_pickup" ?
                          <>
                            <div style={{ backgroundColor: 'white', padding: '20px 0' }}>
                              <p style={{ padding: '10px 20px', fontSize: '13px', fontWeight: '600' }}>Quote history</p>
                              <table>
                                <tr>
                                  <th style={{ padding: '10px', fontSize: '12px' }}>Quote amount</th>
                                  <th style={{ padding: '10px', fontSize: '12px' }}>Logistic provider</th>
                                  <th style={{ padding: '10px', fontSize: '12px' }}>Providers info</th>
                                </tr>
                                {
                                  view.quote_history?.map((detail, index) => {
                                    return <tr key={index}>
                                      <td style={{ padding: '10px', fontSize: '12px' }}> &#8358;{numberWithCommas.format(detail.quote_amount)}</td>
                                      <td style={{ padding: '10px', fontSize: '12px' }}>{detail.fulfiller_id}</td>
                                      <td style={{ padding: '10px', fontSize: '12px' }}>
                                        <Button

                                          size="small"
                                          variant="contained"
                                          style={{ fontSize: '8px', maxHeight: '20px', border: '1px solid var(--main)', backgroundColor: 'var(--main)', color: 'white', textTransform: 'none' }}
                                          onClick={() => {
                                            setfulfillerToShow(detail.provider_info);
                                            setshowQuoteType('fulfiller')
                                          }}
                                        >
                                          View profile
                                        </Button>
                                      </td>
                                    </tr>
                                  })
                                }
                              </table>
                            </div>
                          </>
                          :
                          view.delivery_status === "in-transit" ?
                            <>

                            </>
                            :
                            null

                  }

                  {/* <div style={{backgroundColor:'white', padding:'20px 0'}}>
                                    <p style={{padding:'10px 20px', fontSize:'13px', fontWeight:'600'}}>Quote history</p>
                                    <table>
                                        <tr>
                                            <th  style={{padding:'10px', fontSize:'12px'}}>Quote amount</th>
                                            <th style={{padding:'10px', fontSize:'12px'}}>Logistic provider</th>
                                            <th style={{padding:'10px', fontSize:'12px'}}>Providers info</th>
                                        </tr>
                                        {
                                            view.quote_history?.map((detail, index)=>{return <tr key={index}>
                                                <td style={{padding:'10px', fontSize:'12px'}}> &#8358;{numberWithCommas.format(detail.quote_amount)}</td>
                                                <td style={{padding:'10px', fontSize:'12px'}}>{detail.fulfiller_id}</td>
                                                <td style={{padding:'10px', fontSize:'12px'}}>
                                                    <Button 
                                                   
                                                        size="small" 
                                                        variant="contained" 
                                                        style={{fontSize:'8px', maxHeight:'20px', border:'1px solid var(--main)', backgroundColor:'var(--main)', color:'white', textTransform:'none'}}
                                                        onClick={()=>{
                                                            setfulfillerToShow(detail.provider_info);
                                                            setshowQuoteType('fulfiller')
                                                        }}
                                                    >
                                                        View profile
                                                    </Button>
                                                </td>
                                            </tr> })
                                        }
                                    </table>
                                </div> */}
                </div>
              </div>
            </div>
          })
          :
          <>
            <ViewFulfiller details={fulfillerToShow} handleChange={() => setshowQuoteType('quote')} />
          </>
      }

{showHelp ? (
                <HomeHelp
                  showDialog={showHelp}
                  onClose={() => setshowHelp(false)}
                />
              ) : null}


      <DialogOutClick
        open={showSubmitQuote}
        handleClose={() => setShowSubmitQuote(false)}>
        {
          ls.get('_nodi') === null ?
            <>
              login form here
            </>
            :
            <>
              <Grid container style={{ padding: '20px' }}>
                <Grid item xs={12}>
                  <div className="qB">
                    <form
                      style={{ width: "100%", padding: "0" }}
                      onSubmit={handleSubmit(quoteNow)}>

                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={4}>
                              <span style={{ fontWeight: "600", fontSize: "13px" }}>
                                Best Quote:
                              </span>
                            </Grid>
                            <Grid item xs={4}>
                              <span style={{ fontSize: "14px" }}>
                                &#8358;{viewData[0]?.best_quote}
                              </span>
                            </Grid>
                            <Grid item xs={4}></Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={1}
                            style={{ alignItems: "center" }}
                          >
                            <Grid item xs={4}>
                              <span style={{ fontWeight: "600", fontSize: "13px" }}>
                                Your Quote: &#8358;
                              </span>
                            </Grid>
                            <Grid item xs={4}>
                              <input
                                {...register("QAmount")}
                                type="text"
                                style={{
                                  outline: "none",
                                  padding: "5px 10px",
                                  borderRadius: "7px",
                                  width: "100%",
                                  border: "1px solid #EAEAEA",
                                  height: "30px",
                                }}
                              />
                              <div className="err">{errors.QAmount?.message}</div>
                            </Grid>
                            <Grid item xs={4}>

                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={4}>
                              <span style={{ fontWeight: "600", fontSize: "13px" }}>
                                Message:
                              </span>
                            </Grid>
                            <Grid item xs={8}>
                              <textarea
                                rows="4"
                                {...register("msg")}
                                style={{
                                  outline: "none",
                                  padding: "5px 10px",
                                  borderRadius: "7px",
                                  width: "100%",
                                  color: 'black',
                                  fontWeight: '400',
                                  border: "1px solid #EAEAEA",
                                  backgroundColor: 'white'
                                }} />
                              <div className="err">{errors.msg?.message}</div>

                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={4}>

                            </Grid>
                            <Grid item xs={4}>
                              {submiting ? (
                                <input
                                  type="button"
                                  value="loading..."
                                  style={{
                                    outline: "none",
                                    borderRadius: "5px",
                                    border: "none",
                                    color: "white",
                                    fontWeight: "600",
                                    backgroundColor: "var(--main)",
                                    height: "30px",
                                    width: '100%'
                                  }}
                                />
                              ) : (
                                <input
                                  type="submit"
                                  value="Submit"
                                  style={{
                                    outline: "none",
                                    borderRadius: "5px",
                                    border: "none",
                                    color: "white",
                                    fontWeight: "600",
                                    backgroundColor: "var(--main)",
                                    height: "30px",
                                    width: '100%'
                                  }}
                                />
                              )}
                            </Grid>
                            <Grid>

                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </Grid>
              </Grid>
            </>

        }



      </DialogOutClick>

      <DialogOutClick
        open={showAccept}
        handleClose={() => { setshowAccept(!showAccept); setAcceptType('') }}>
        <div style={{ padding: '20px' }}>
          <p style={{ paddingBottom: "20px" }}> Select your payment option</p>

          <Grid item xs={12}>
            <RadioGroup aria-label="payment" name="row-radio-buttons-group">
              <FormControlLabel value="instant" onClick={() => {
                setAcceptType('instant')
              }} control={<GreenRadio />} label="Instant payment" />

              <FormControlLabel value="pay_on_delivery" onClick={() => {
                setAcceptType('pay_on_delivery')
              }} control={<GreenRadio />} label="Pay on delivery" />
            </RadioGroup>
          </Grid>
          {
            acceptType === 'instant' ?
              <PaystackWidget
                amount={amountToAccept + '00'} //for paystack
                amount_paid={amountToAccept}
                fulfiller_id={fulfilllerToAccept}
                delivery_id={delivery_id}
                email={ls.get('eid')}
                closePopup={() => setshowAccept(false)}
              />
              :
              acceptType === 'pay_on_delivery' ?
                isloading ?
                  <div className="acceptBTN" style={{ width: "80px", height: "30px", marginLeft: "60px" }}>
                    <span style={{ cursor: "pointer", borderRadius: "0px" }}
                      onClick={payOnDelivery}

                    >
                      <span className="btnlabe">
                        <PuffLoader color='white' loading={isloading} speedMultiplier={1} size={24} />
                      </span>

                      Proceed </span>
                  </div>
                  :
                  <div className="acceptBTN" style={{ width: "70px", height: "21px", marginLeft: "60px" }}>
                    <span style={{ cursor: "pointer", borderRadius: "0px" }}
                      onClick={payOnDelivery}

                    >
                      Proceed </span>
                  </div>

                : null

          }

        </div>
      </DialogOutClick>
      <DialogOutClick
        open={showAgent}
        handleClose={() => setShowAgent(!showAgent)}
      >
        <div>
          <img src={data.agent_id_card_front_url} width='270px' />
        </div>
        <div>
          <img src={data.d_agent_id_card_front_url} width='270px' />
        </div>
      </DialogOutClick>
    </div>
  )
}

export default ShowQuote1