/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Component, useContext } from "react";
import { MdLocationOn } from "react-icons/md";
import { HiOutlineViewGrid } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import "../SendQuote.css";
import cursworking from "../../../assets/cursworking.png";
import DialogOutClick from "../../../components/DialogOutClick";
import DialogSimplePop from "../../../components/DialogSimplePop";
import oops from "../../../assets/oops.png";
import HomeHelp from "../../../components/HomeHelp";
import Paginate from "react-paginate";
import { GiPathDistance } from "react-icons/gi";
import { MdDescription } from "react-icons/md";

import bd1 from "../../../assets/bd1.svg";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin3Line, RiTruckLine } from "react-icons/ri";
// eslint-disable-next-line no-unused-vars
import ShowQuote from "./ShowQuote";
import EditQuote from "./EditQuote";
import ShowQuoteDesk from "./ShowQuoteDesk";
import ShowQuote1 from "./ShowQuote1";
import PaystackWidget2 from "../../../components/PaystackWidget2";
import SecureLS from "secure-ls";
import ReactTooltip from "react-tooltip";
import { IconContext } from "react-icons";
import QuoteSignInForm from "../../Fulfiller/Dashboard/QuoteSignInForm";
import "../SendQuote.css";
import { Dialog, DialogContent } from "@material-ui/core";
import TopMenu from "../../../components/topNav/TopMenu";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Message from "./Message";
import Account from "./Account";
import axios from "axios";
import LazyLoader from "../../../components/LazyLoader";

import AcceptQuote from "./AcceptQuote";

import Swal from "sweetalert2";

import PuffLoader from "react-spinners/PuffLoader";
import DialogPop from "../../../components/DialogPop";
import { Button, TextField } from "@material-ui/core";
import { AiOutlineWarning } from "react-icons/ai";
import Nav from "../../../components/Nav/NavHome";

import { FaCarSide } from "react-icons/fa";
import { WiTime4 } from "react-icons/wi";
import { GrMail } from "react-icons/gr";
import { BsFillPersonFill } from "react-icons/bs";

import MessageSender from "./MessageSender";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

// import PaystackWidget from '../../../components/PaystackWidget';

import IndividualSidebar from "../../../components/Sidebar/IndividualSidebar";
import imagelogo from "../../../assets/Dillivry.png";

import { blue } from "@material-ui/core/colors";

import { id } from "date-fns/locale";
import { useLocation } from "react-router-dom";

import { AiOutlineInfoCircle } from "react-icons/ai";

import Login from "../../Login/Login";
import { SenderContext } from "../../../context/SenderDashboard/SenderDashboardContext";
// import Tracking2 from "../Tracking2";
import { useParams } from "react-router-dom";
import Missed from "../Missed";
import { compareAsc } from "date-fns";
import { ConnContext } from "../../../context/ConnContext";
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
function PastDelivery({
  past,
  expired,
  missed,
  //  createFunctionToCall,
  //  createFunctionToCall1,
  //  createFunctionToCall2,
}) {
  const { type, id } = useParams();
  var linkUrl = GlobalizationLanguage("/5156886826018256")
  const login = GlobalizationLanguage("/login")

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const {visitingCountryCode} = useContext(ConnContext)

  const [itemToDelete, setitemToDelete] = useState([]);
  const [showDeleting, setshowDeleting] = useState(false);
  const [dataToShow, setdataToShow] = useState([]);
  const [activeState, setActiveState] = useState(false);
  const [pastState, setPastState] = useState(true);
  const [messageState, setMessageState] = useState(false);
  const [accountState, setAccountState] = useState(false);
  const [quotesState, setquotesState] = useState(false);
  const [viewState, setviewState] = useState(false);
  const [editState, seteditState] = useState(false);

  const [showLogout, setShowLogout] = useState(false);
  const [currentId, setcurrentId] = useState(null);
  const [showHelp, setshowHelp] = useState(false);

  const [showDeletinfo, setshowDeletinfo] = useState();
  const [showEditinfo, setshowEditinfo] = useState();

  const [deefault, setDeefault] = useState([]);
  const [itemLength, setitemLength] = useState(0);
  const [paginateActivePage, setPaginateActivePage] = useState(1);
  const n = 5;

  const [showing, setshowing] = useState("");
  const [display, setdisplay] = useState("past");
  const [outshow, setoutshow] = useState("miss");
  const [showlogin, setshowlogin] = useState(false);
  const [clicked, setclicked] = useState("complete");
  const [missed_, setmissed_] = useState("missed");
  const history = useHistory();

  const [defaultdata, setdefaultdata] = useState([]);

  const [isloading, setIsloading] = useState(true);

  const toCompleted = () => {};

  const logout = () => {
    ls.removeAll();
    history.push("/");
  };
  const [showdrop, setShowdrop] = useState(false);

  const handleClick = (id) => {
    setShowdrop((showdrop) => !showdrop);
    setcurrentId(id);
  };

  const showPstdilivyUrl = (type, item) => {
    history.push(`/pastdeliveries/${type}/${item}`);
  };

  const location = useLocation();
  const [sendingMsg, setsendingMsg] = useState(false);

  const setexpire = () => {
    setclicked("expire");
  };

  const [functionToCall, createFunctionToCall] = useState(() => () => {});
  const [functionToCall1, createFunctionToCall1] = useState(() => () => {});
  const [functionToCall2, createFunctionToCall2] = useState(() => () => {});

  const [loading, setloading] = useState();

  const [data, setData] = useState("");

  const [deliveryidpicked, setdeliveryidpicked] = useState("");

  const childToParent = () => {};

  const showMe = (type, item) => {
    history.push(`/activedeliveries/${type}/${item}`);
  };

  const showMessageurl = (type, item) => {
    history.push(`/message_sender`);
  };

  const pastdilvryurl = () => {
    history.push(`/pastdelivery`);
  };

  const activedilivryurl = () => {
    history.push(`/activedlvry`);
  };

  const { initialstate, UpdateData, Updatequote } = useContext(SenderContext);
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
    window.history.replaceState(null, "");
  };


  const newapi = ()=>{
    //console.log('alot to do')
   // setIsloading(true)
       window.location.reload()
    // setclicked("missed");
  }

  useEffect(() => {
    //console.log(initialstate, "initial state");
    setIsloading(true);
    if (initialstate !== undefined) {
      setIsloading(false);
      ls.set("fo_in", JSON.stringify(initialstate.data.account));
      let Data = [];

      if (type === "complete") {
        Data = initialstate.data.past_deliveries;
        ls.set("fo_in", JSON.stringify(initialstate.data.account));
        setshowing("complete");
        setclicked(missed_);
        setDeefault([...initialstate.data.past_deliveries]);

        setitemLength([...initialstate.data.past_deliveries].length);
        setdefaultdata([...Data].slice(0, n));
      }

      if (type === "missed") {
        Data = initialstate.data.missed_delivery;
        ls.set("fo_in", JSON.stringify(initialstate.data.account));
        setshowing("missed");
        setDeefault([...initialstate.data.missed_delivery]);

        setitemLength([...initialstate.data.missed_delivery].length);
        setdefaultdata([...Data].slice(0, n));
      }

      setdefaultdata([...Data].slice(0, n));
      setIsloading(false);
    } 
    
    else {
      setIsloading(true);
      const id = ls.get("eid");
      axios
        .get(`${process.env.REACT_APP_API}m-dillivry-search`, {
          search_criteria: "sender_data",
          user_id: id,
          vcc: visitingCountryCode
        })
        .then((response) => {
          if (response.status === 200) {
            setIsloading(false);
            //console.log(response.data, "oys");
            ls.set("fo_in", JSON.stringify(response.data.account));

            let Data = [];

            if (type === "complete") {
              Data = response.data.past_deliveries;
              //console.log(Data, "data");
              ls.set("fo_in", JSON.stringify(response.data.account));
              setshowing("complete");
              setDeefault([...response.data.past_deliveries]);

              setitemLength([...response.data.past_deliveries].length);

              setdefaultdata([...Data].slice(0, n));
            }

            if (type === "missed") {
              Data = response.data.missed_delivery;
              //console.log(Data, "dataaaa");
              ls.set("fo_in", JSON.stringify(response.data.account));
              setshowing("missed");
              setDeefault([...response.data.missed_delivery]);

              setitemLength([...response.data.missed_delivery].length);

              setdefaultdata([...Data].slice(0, n));
            }

            setdefaultdata([...Data].slice(0, n));
            setIsloading(false);
          }
        })
        .catch((error) => {});
    }
  }, []);

  const displayActive = defaultdata.map((detail) => {
  var Tracking = GlobalizationLanguage(`/tracking/${detail.delivery_id}`);
    return (
      <div key={detail.delivery_id}>
        {/* {console.log(defaultdata, "default-data")} */}

        <div className="columnBo">
          <div className="topCapture">
            <div
              className="capture"
              onClick={() => {
                setdataToShow(detail);
                setActiveState(false);
                setPastState(false);
                setMessageState(false);
                setAccountState(false);
                setquotesState(false);
                // setviewState(true);
                seteditState(false);
                //console.log(clicked, 'clicked')
                showPstdilivyUrl(type, detail.delivery_id);
              }}
            >
              {detail.image_urls.length > 0 ? (
                <img src={detail.image_urls[0]} alt="image001" />
              ) : null}
            </div>
            <div className="info">
              <div
                className="data"
                onClick={() => {
                  // setdataToShow(detail);
                  // setActiveState(false);
                  // setPastState(false);
                  // setMessageState(false);
                  // setAccountState(false);
                  // setquotesState(false);
                  // setviewState(true);
                  // seteditState(false);
                  showPstdilivyUrl(type, detail.delivery_id);
                }}
                style={{ cursor: "Pointer" }}
              >
                <span data-tip data-for="globalDescri">
                  <IconContext.Provider
                    value={{
                      color: "#2e2e2e",
                      className: "global-class-name-icon",
                    }}
                  >
                    <MdDescription
                      style={{
                        marginRight: "5px",
                        color: "var(--main)",
                        fontSize: "18px",
                      }}
                    />
                  </IconContext.Provider>

                  <ReactTooltip
                    id="globalDescri"
                    aria-haspopup="true"
                    role="example"
                    type="light"
                  >
                    <p>Item Description</p>
                  </ReactTooltip>
                </span>
                {/* <span><MdDescription style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px' }} /></span> */}
                <span className="description">
                  {detail.item_desc.charAt(0).toUpperCase() +
                    detail.item_desc.toLowerCase().slice(1)}
                </span>
              </div>
              <div className="data">
                <span data-tip data-for="globalcategory">
                  <IconContext.Provider
                    value={{
                      color: "#2e2e2e",
                      className: "global-class-name-icon",
                    }}
                  >
                    <HiOutlineViewGrid
                      style={{
                        marginRight: "5px",
                        color: "var(--main)",
                        fontSize: "18px",
                      }}
                    />
                  </IconContext.Provider>

                  <ReactTooltip
                    id="globalcategory"
                    aria-haspopup="true"
                    role="example"
                    type="light"
                  >
                    <p>Item Category</p>
                  </ReactTooltip>
                </span>
                {/* <span><HiOutlineViewGrid style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px' }} /></span> */}
                <span>
                  {detail.item_category === "furniture"
                    ? "Furniture"
                    : detail.item_category === "general_items"
                    ? "Geneal Items"
                    : detail.item_category === "car"
                    ? "Car"
                    : detail.item_category === "haulage"
                    ? "Haulage"
                    : detail.item_category === "moving_home"
                    ? "Moving Home"
                    : detail.item_category}
                </span>
              </div>
              <div className="data">
                {/* <span><MdLocationOn style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px' }} /></span> */}

                <span data-tip data-for="global8">
                  <IconContext.Provider
                    value={{
                      color: "#2e2e2e",
                      className: "global-class-name-icon",
                    }}
                  >
                    <span>
                      <MdLocationOn
                        style={{
                          marginRight: "5px",
                          color: "var(--main)",
                          fontSize: "18px",
                        }}
                      />
                    </span>
                    {/* {" "}
                              <img src={to} alt="" /> */}
                  </IconContext.Provider>

                  <ReactTooltip
                    id="global8"
                    aria-haspopup="true"
                    role="example"
                    type="light"
                  >
                    <p>Pickup Address</p>
                    {/* <p>Mode of movement</p> */}
                  </ReactTooltip>
                </span>
                <span>
                  {detail.from.charAt(0).toUpperCase() +
                    detail.from.toLowerCase().slice(1)}
                </span>
              </div>
              <div className="data">
                {/* <span><MdLocationOn style={{ marginRight: "5px", color: "red", fontSize: '18px' }} /></span> */}
                <span data-tip data-for="global9">
                  <IconContext.Provider
                    value={{
                      color: "#2e2e2e",
                      className: "global-class-name-icon",
                    }}
                  >
                    <span>
                      <MdLocationOn
                        style={{
                          marginRight: "5px",
                          color: "red",
                          fontSize: "18px",
                        }}
                      />
                    </span>
                  </IconContext.Provider>

                  <ReactTooltip
                    id="global9"
                    aria-haspopup="true"
                    role="example"
                    type="light"
                  >
                    <p>Destination Address</p>
                    {/* <p>Mode of movement</p> */}
                  </ReactTooltip>
                </span>
                <span>
                  {detail.to.charAt(0).toUpperCase() +
                    detail.to.toLowerCase().slice(1)}
                </span>
              </div>
              <div className="data">
                <span data-tip data-for="globalCov">
                  <IconContext.Provider
                    value={{
                      color: "#2e2e2e",
                      className: "global-class-name-icon",
                    }}
                  >
                    <GiPathDistance
                      style={{
                        marginRight: "5px",
                        color: "var(--main)",
                        fontSize: "18px",
                      }}
                    />
                  </IconContext.Provider>

                  <ReactTooltip
                    id="globalCov"
                    aria-haspopup="true"
                    role="example"
                    type="light"
                  >
                    <p>Distance</p>
                  </ReactTooltip>
                </span>
                {/* <span><GiPathDistance style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px' }} /></span> */}
                <span>{detail.distance}</span>
              </div>
              <div className="data">
                <span data-tip data-for="globalMode">
                  <IconContext.Provider
                    value={{
                      color: "#2e2e2e",
                      className: "global-class-name-icon",
                    }}
                  >
                    <RiTruckLine
                      style={{
                        marginRight: "5px",
                        marginTop: "-3px",
                        color: "var(--main)",
                        fontSize: "18px",
                      }}
                    />
                  </IconContext.Provider>

                  <ReactTooltip
                    id="globalMode"
                    aria-haspopup="true"
                    role="example"
                    type="light"
                  >
                    <p>Mode of Delivery</p>
                  </ReactTooltip>
                </span>
                {/* <span><RiTruckLine style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px' }} /></span> */}
                <span>
                  {detail.mode_of_delivery.charAt(0).toUpperCase() +
                    detail.mode_of_delivery.toLowerCase().slice(1)}
                </span>
              </div>
            </div>
          </div>
          <div className="timeAction">
            <div className="time" style={{ display: "block" }}>
              <p>
                Delivery status:{" "}
                {

                
                    showing === "complete"
                  ? "Completed"
                  : showing === "missed"
                  ? "Missed"
                  : showing === "expire"
                  ? "Expired"
                  : null
                

                }
              </p>

              <div>
                <span>Payment status:</span>
                <span>
                  {detail.payment_status === "success" ? (
                    "Paid"
                  ) : detail.payment_status === "pay_on_delivery" ? (
                    "Pay on Delivery"
                  ) : detail.payment_status === "NA" ? (
                    <button
                      disabled
                      // className="acceptBTN"
                      style={{
                        border: "none",
                        backgroundColor: "#50B14A",
                        color: "#FFFFFF",
                        padding: "2px 2px 2px 2px",
                        borderRadius: "4px",
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      Book delivery arrange with provider
                    </button>
                  ) : (
                    detail.payment_status
                  )}
                  {detail.payment_status === "pay_on_delivery" ? (
                    <>
                      {/* <button style={{backgroundColor:"#50B14A",borderRadius:"4px", border:"none",color:"#FFFFFF",width:"100px",height:"20px",marginTop:"5px"}}>Make Payment</button> */}
                      <div style={{ marginLeft: "-40px" }}>
                        <PaystackWidget2
                          amount={detail.quote_amount + "00"} //for paystack
                          amount_paid={detail.quote_amount}
                          fulfiller_id={detail.fulfiller_id}
                          delivery_id={detail.delivery_id}
                          email={ls.get("eid")}
                          closePopup={() => console.log("loading")}
                        />
                      </div>
                    </>
                  ) : (
                    <> </>
                  )}
                </span>
              </div>

              {/* {showing === "complete" ? (
                <div className="itemDescs-" style={{ border: "none" }}>
                  <button
                    className="itmclick"
                    //   onClick ={()=>handleClick(detail.delivery_id)}
                    onClick={() => {
                      if (ls.get("_utk") === "") {
                        history.push(login);
                      } else {
                        history.push(`tracking/${detail.delivery_id}`);

                        setshowlogin(false);
                      }
                    }}
                    style={{
                      backgroundColor: "#50B14A",
                      border: "1px solid #50B14A",
                      borderRadius: "4px",
                      color: "#FFFFFF",
                      padding: "2px 5px 2px 5px",
                      cursor: "pointer",
                    }}
                  >
                    Track item
                  </button>
                </div>
              ) : (
                <></>
              )} */}

              {showing === "missed" ? (
                <div className="itemDescs-" style={{ border: "none" }}>
                  <button
                    className="itmclick"
                    // onClick ={()=>handleClick(detail.delivery_id)}
                    onClick={() => {
                      if (ls.get("_utk") === "") {
                        history.push(login);
                      } else {
                        // history.push(`tracking/${detail.delivery_id}`);
                          history.push(Tracking)
                        setshowlogin(false);
                      }
                    }}
                    // onClick={() => {
                    //   if (ls.get("_utk") === "") {
                    //     history.push(login);
                    //   } else {
                    //     history.push(`tracking/${detail.delivery_id}`);

                    //     setshowlogin(false);
                    //   }
                    // }}
                    style={{
                      backgroundColor: "#50B14A",
                      border: "1px solid #50B14A",
                      borderRadius: "4px",
                      color: "#FFFFFF",
                      padding: "2px 5px 2px 5px",
                      cursor: "pointer",
                    }}
                  >
                    Track item
                  </button>
                </div>
              ) : (
                <></>
              )}
              {showdrop && currentId == detail.delivery_id ? (
                <p>
                  <img
                    src={cursworking}
                    alt="tra"
                    style={{
                      width: "25px",
                      height: "30px",
                      paddingTop: "15px",
                    }}
                  />{" "}
                  &nbsp;Item position:
                </p>
              ) : null}

              {/* pay on delivry button */}
            </div>

            {detail.delivery_status === "awaiting_quote" ? (
              <div className="actions">
                <div
                  className="customBtnUp"
                  onClick={() => {
                    setdataToShow(detail);
                    setActiveState(false);
                    setPastState(false);
                    setMessageState(false);
                    setAccountState(false);
                    setquotesState(false);
                    setviewState(true);
                    seteditState(false);
                  }}
                >
                  <img
                    src={bd1}
                    alt="bd1_icon"
                    style={{ marginRight: "10px" }}
                  />
                  View mbi
                </div>
                <div
                  className="customBtn"
                  onClick={() => {
                    setdataToShow(detail);
                    setActiveState(false);
                    setPastState(false);
                    setMessageState(false);
                    setAccountState(false);
                    setquotesState(false);
                    setviewState(false);
                    seteditState(true);
                  }}
                >
                  <BiEdit style={{ marginRight: "10px" }} />
                  Edit
                </div>

                <div
                  className="customBtnDown"
                  onClick={() => {
                    setshowDeleting(true);
                    setitemToDelete(detail);
                  }}
                >
                  <RiDeleteBin3Line style={{ marginRight: "10px" }} />
                  Delete
                </div>
              </div>
            ) : (
              <div className="actions">
                <div
                  className="customBtnUp"
                  onClick={() => {
                    setdataToShow(detail);
                    setActiveState(false);
                    setPastState(false);
                    setMessageState(false);
                    setAccountState(false);
                    setquotesState(false);
                    // setviewState(true);
                    seteditState(false);
                    showPstdilivyUrl(type, detail.delivery_id);
                  }}
                >
                  {" "}
                  <img
                    src={bd1}
                    alt="bd1_icon"
                    style={{ marginRight: "10px" }}
                  />
                  View 
                </div>
                <div
                  className="customBtn"
                  style={{ backgroundColor: "#EAEAEA" }}
                  onClick={() => {
                    setshowEditinfo(true);
                    // setitemToDelete(detail);
                  }}
                >
                  <BiEdit style={{ marginRight: "10px" }} />
                  Edit
                </div>

                <div
                  className="customBtnDown"
                  style={{ backgroundColor: "#EAEAEA" }}
                  onClick={() => {
                    setshowDeletinfo(true);
                    // setitemToDelete(detail);
                  }}
                >
                  <RiDeleteBin3Line style={{ marginRight: "10px" }} />
                  Delete
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      {viewState ? (
        <div>
          <div className="showMobileQuote">
            <ShowQuote1
              data={dataToShow}
              handleChange={() => setviewState(!viewState)}
            />
          </div>

          <div
            className="myowncontex"
            style={{ marginTop: "-30px", width: "100%" }}
          >
            <ShowQuoteDesk
              data={dataToShow}
              handleChange={() => setviewState(!viewState)}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="context-past">
            <div className="context-past-left">
              <div className="column">
                <div className="columnHead">My Dilivry</div>
                <div className="columnBody2">
                  <div
                    className={showing === "complete" ? "qutActive" : "qut"}
                    style={{ paddingLeft: "18px" }}
                    onClick={() => {
                      setshowing("complete");
                      setclicked("complete");
                      // setclicked("missed")
                      // setmissed_("missed");
                  
                       history.push(`/pastdelivery/${clicked}`);
                       window.location.reload()
                      //  newapi()
                      // console.log(clicked, 'clicked');
                      // console.log(showing, 'showing')

                    }}
                  >
                    {" "}
                    Complete Deliveries {" "}
                  </div>
                  <div
                    className={showing === "expire" ? "qutActive" : "qut"}
                    style={{ paddingLeft: "18px" }}
                    onClick={() => {
                      setshowing("expire");
                      // setpastData(expired);
                      // setDeefault(expired);
                      // setitemLength(expired.length);
                      // history.push(`/pastdelivery/expire`);
                    
                      setclicked("expire");
                      history.push("/pastdelivery/expire")
                      // setclicked("expire");
                    }}
                  >
                    {" "}
                    Expired Deliveries {" "}
                  </div>
                  <div
                    className={showing === "missed" ? "qutActive" : "qut"}
                    style={{ paddingLeft: "18px" }}
                    onClick={() => {
                      setshowing("missed");
                      // setclicked("missed");
                      // history.push(`/pastdelivery/${missed_}`);
                      history.push("/pastdelivery/missed")
                      //  window.location.reload()
                      // newapi()
                      //  history.push(`/pastdelivery/missed`);
                      // console.log(missed_, "clicked");
                      //console.log(clicked, 'clicked')
                    }}
                  >
                    {" "}
                    Missed Deliveries{" "}
                  </div>
                  <div
                    className="qut"
                    style={{ paddingLeft: "18px" }}
                    onClick={() => history.push(linkUrl)}
                  >
                    {" "}
                    Leave Feedback
                  </div>
                  <div
                    className="qut"
                    style={{ color: "#C54B4B", paddingLeft: "18px" }}
                    // onClick={logout}>
                    onClick={() => {
                      setShowLogout(true);
                    }}
                  >
                    Log out
                  </div>
                </div>
              </div>
            </div>

            <div className="context-past-right">
              {display === "past" ? (
                <div className="column">
                  <div className="comdeli">
                    <div
                      className="comdelitop"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {showing === "complete" ? (
                        <p> Complete Deliveries </p>
                      ) : showing === "expire" ? (
                        <p> Expired Deliveries</p>
                      ) : showing === "missed" ? (
                        <p> Missed Deliveries</p>
                      ) : null}

                      <div className="viewmenudesktop">
                        <span
                          className="paginateButtons"
                          style={{
                            color: paginateActivePage === 1 ? "white" : "",
                            backgroundColor:
                              paginateActivePage === 1 ? "var(--main)" : "",
                            cursor:
                              paginateActivePage === 1
                                ? "not-allowed"
                                : "pointer",
                          }}
                          onClick={() => {
                            setdefaultdata(deefault.slice(0, n));
                            setPaginateActivePage(1);
                          }}
                        >
                          First
                        </span>
                        {paginateActivePage === 1 ? (
                          <span
                            className="paginateButtons"
                            style={{ color: "#989898", cursor: "not-allowed" }}
                          >
                            Previous
                          </span>
                        ) : (
                          <span
                            className="paginateButtons"
                            onClick={() => {
                              setdefaultdata(
                                deefault.slice(
                                  (paginateActivePage - 2) * n,
                                  (paginateActivePage - 1) * n
                                )
                              );
                              setPaginateActivePage(paginateActivePage - 1);
                            }}
                          >
                            Previous
                          </span>
                        )}

                        {[...Array(Math.ceil(itemLength / n))].map((e, i) => (
                          <span
                            className="paginateButtons"
                            style={{
                              backgroundColor:
                                paginateActivePage === i + 1
                                  ? "var(--main)"
                                  : "",
                              color:
                                paginateActivePage === i + 1 ? "white" : "",
                            }}
                            key={i}
                            onClick={() => {
                              setdefaultdata(deefault.slice(n * i, n * i + n));
                              setPaginateActivePage(i + 1);
                            }}
                          >
                            {i + 1}
                          </span>
                        ))}
                        {paginateActivePage === Math.ceil(itemLength / n) ? (
                          <span
                            className="paginateButtons"
                            style={{ color: "#989898", cursor: "not-allowed" }}
                          >
                            Next
                          </span>
                        ) : (
                          <span
                            className="paginateButtons"
                            onClick={() => {
                              setdefaultdata(
                                deefault.slice(
                                  n * paginateActivePage,
                                  n * (paginateActivePage + 1)
                                )
                              );
                              setPaginateActivePage(paginateActivePage + 1);
                            }}
                          >
                            Next
                          </span>
                        )}
                        <span
                          className="paginateButtons"
                          style={{
                            backgroundColor:
                              paginateActivePage === Math.ceil(itemLength / n)
                                ? "var(--main)"
                                : "",
                            color:
                              paginateActivePage === Math.ceil(itemLength / n)
                                ? "white"
                                : "",
                          }}
                          onClick={() => {
                            setdefaultdata(
                              deefault.slice(
                                Math.floor(itemLength / n) * n,
                                itemLength
                              )
                            );
                            setPaginateActivePage(Math.ceil(itemLength / n));
                          }}
                        >
                          Last
                        </span>
                      </div>
                    </div>
                    {past.length === 0 ? (
                      <div className="columnBody1">
                        - currently none to display -
                        {/* {console.log(past, "past-to-load")} */}
                        {/* <>{displayActive}</> */}
                      </div>
                    ) : isloading === true ?   (
                     <h1>loading...</h1>
                    ) :  <>{displayActive}</>  }
                  </div>
                </div>
              ) : // :
              // display === 'view' ?
              // <ShowQuoteDesk data = {dataToShow}/>
              // :
              // display === 'edit' ?
              // <EditQuote delivery_data = {dataToShow} />
              null}
            </div>
          </div>
        </>
      )}

      {/* pagination here ooo */}

      {/* <div className="viewmenumobile" style={{border: "1px solid #EAEAEA", height: "44px", backgroundColor: "#F9F9F9", marginRight: "20px", marginLeft: "20px", padding: "13px 25px 10px 25px" ,marginTop:"-30px"}}>
                                                    <span className="paginateButtons" style={{ color: paginateActivePage === 1 ? 'white' : '', backgroundColor: paginateActivePage === 1 ? 'var(--main)' : '', cursor: paginateActivePage === 1 ? 'not-allowed' : 'pointer' }} onClick={() => {
                                                        setpastData(deefault.slice(0, n));
                                                        setPaginateActivePage(1)
                                                    }}>First</span>
                                                    {
                                                        paginateActivePage === 1 ?
                                                            <span className="paginateButtons" style={{ color: '#989898', cursor: 'not-allowed' }} >Previous</span>
                                                            :
                                                            <span className="paginateButtons" onClick={() => {
                                                                setpastData(deefault.slice(((paginateActivePage - 2) * n), ((paginateActivePage - 1) * n)));
                                                                setPaginateActivePage(paginateActivePage - 1);
                                                            }}>Previous</span>
                                                    }

                                                    {
                                                        [...Array(Math.ceil(itemLength / n))].map((e, i) => <span className='paginateButtons' style={{ backgroundColor: paginateActivePage === i + 1 ? 'var(--main)' : '', color: paginateActivePage === i + 1 ? 'white' : '' }} key={i} onClick={() => {
                                                            setpastData(deefault.slice((n * i), (n * i) + n));
                                                            setPaginateActivePage(i + 1)
                                                        }}
                                                        >{i + 1}</span>)
                                                    }
                                                    {
                                                        paginateActivePage === Math.ceil(itemLength / n) ?
                                                            <span className="paginateButtons" style={{ color: '#989898', cursor: 'not-allowed' }}>Next</span>
                                                            :
                                                            <span className="paginateButtons" onClick={() => {
                                                                setpastData(deefault.slice(n * paginateActivePage, n * (paginateActivePage + 1)));
                                                                setPaginateActivePage(paginateActivePage + 1);
                                                            }}>Next</span>
                                                    }
                                                    <span className="paginateButtons" style={{ backgroundColor: paginateActivePage === Math.ceil(itemLength / n) ? 'var(--main)' : '', color: paginateActivePage === Math.ceil(itemLength / n) ? 'white' : '' }} onClick={() => {
                                                        setpastData(deefault.slice((Math.floor(itemLength / n) * n), itemLength))
                                                        setPaginateActivePage(Math.ceil(itemLength / n));
                                                    }}>Last</span>
                                                </div> */}
      <DialogOutClick
        open={showLogout}
        handleClose={() => setShowLogout(false)}
      >
        <div style={{ width: "100%", padding: "30px 30px 20px 30px" }}>
          <div
            style={{
              width: "100%",
              marginBottom: "30px",
              fontSize: "13px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>Are you sure you want to logout?</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="disBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "#FFFFFF",
                backgroundColor: "#50B14A",
                border: "1px solid #50B14A",
              }}
              onClick={() => setShowLogout(false)}
            >
              No
            </div>
            <div
              className="ediBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "#FFFFFF",
                backgroundColor: "grey",
                border: "1px solid grey",
              }}
              // onClick={logMeOut}>
              onClick={logout}
            >
              Yes
            </div>
          </div>
        </div>
      </DialogOutClick>

      <DialogSimplePop
        openPopup={showDeletinfo}
        btnText="Ok"
        onClick={() => setshowDeletinfo(false)}
      >
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <img src={oops} alt="dillivry" style={{ width: "60px" }} />
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "500",
            width: "100%",
            marginTop: "10px",
          }}
        >
          You can't delete delivery in this state.<br></br>
          Please contact
          <span
            style={{ color: "rgb(80, 177, 74)", cursor: "pointer" }}
            onClick={() => {
              setshowDeletinfo(false);
              setshowHelp(!showHelp);
            }}
          >
            {" "}
            customer care
          </span>{" "}
          for support.
        </div>
      </DialogSimplePop>

      <DialogSimplePop
        openPopup={showEditinfo}
        btnText="Ok"
        onClick={() => setshowEditinfo(false)}
      >
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <img src={oops} alt="dillivry" style={{ width: "60px" }} />
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "500",
            width: "100%",
            marginTop: "10px",
          }}
        >
          You can't edit delivery in this state.<br></br>
          Please contact
          <span
            style={{ color: "rgb(80, 177, 74)", cursor: "pointer" }}
            onClick={() => {
              setshowEditinfo(false);
              setshowHelp(!showHelp);
            }}
          >
            {" "}
            customer care
          </span>{" "}
          for support.
        </div>
      </DialogSimplePop>

      {showHelp ? (
        <HomeHelp showDialog={showHelp} onClose={() => setshowHelp(false)} />
      ) : null}

      {showlogin ? (
        <DialogOutClick
          open={showlogin}
          handleClose={() => setshowlogin(!showlogin)}
        >
          <QuoteSignInForm
            onClose={() => {
              setshowlogin(!showlogin);
            }}
          />
        </DialogOutClick>
      ) : null}
    </div>
  );
}

export default PastDelivery;
