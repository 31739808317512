import React, {useState} from 'react'
import TopBarHome from '../../components/TopBar/TopBarHome'
import "./How_it_works.css"
import How_it_works_card from './How_it_works_card'
import How_it_works_card1 from './How_it_works_card1'
import Request from '../../assets/Request.svg'
import Pickup from '../../assets/Item_Pickup.svg'
import Compare from '../../assets/Compare.svg'
import BestPrice from '../../assets/BestPrice.svg'
import Payment from '../../assets/Payment.svg'
import Register from '../../assets/Register.svg'
import Quote from '../../assets/Quote.svg'
import Deliver from '../../assets/Deliver.svg'


import Footer from '../../components/Footer/Footer'

export default function How_it_works() {

    const [showProvider, setshowProvider] = useState(false)
  return (
      <div>
          <TopBarHome />
          <div className='How_it_works'>
              <div className="home_max_width">
                  <div className="How_it_works_cont">
                      <h1>How does Dillivry work?</h1>
                        <div className='spanContainer'>
                            <div className='spanBody'>
                                <span style={{color: showProvider ? "var(--textColor)" : "var(--main)", borderBottom: showProvider ? "3px solid var(--lighest)" : "3px solid var(--main)"}} className='spanText' onClick={()=>setshowProvider(false)}>Sender</span> 
                                <span style={{color: !showProvider ? "var(--textColor)" : "var(--main)", borderBottom: !showProvider ? "3px solid var(--lighest)" : "3px solid var(--main)"}} className='spanText' onClick={()=>setshowProvider(true)}>Logistics providers</span> 
                            </div>
                        </div>
                       {
                           showProvider ?
                            <>
                                <How_it_works_card 
                                    title="Register" 
                                    description="Signup for free on the web or download the Dillivry app in App store or Google play. Upload all the required documentation and get verified within minutes. Once your account is verified, start receiving delivery request alerts." 
                                    image={Register}
                                    showImage={true}
                                />
                                <How_it_works_card1
                                    title="Quote" 
                                    description="Submit your free quote and make necessary enquiries about each delivery if you need to. Review every delivery request carefully and ensure the mode of delivery is the same as your means of transportation." 
                                    image={Quote}
                                    showImage={true}
                                />
                                <How_it_works_card 
                                    title="Pickup and Deliver" 
                                    description="Get instant quote acceptance notification from sender. Acknowledge quote acceptance by the sender and see the full pickup and delivery addresses. Proceed to pickup and deliver according to the sender's instructions." 
                                    image={Deliver}
                                    showImage={true}
                                />
                                <How_it_works_card1
                                    title="Payment" 
                                    description="Get paid every Monday for deliveries completed the previous week. Instant payout after a job is completed - COMING SOON." 
                                    image={Payment}
                                />
                            </>
                            :
                            <>
                                <How_it_works_card 
                                    title="Request Delivery" 
                                    description="Describe your item. State your pickup and delivery contacts. Specify preferred date to carry out the job." 
                                    image={Request}
                                    showImage={true}
                                />
                                <How_it_works_card1
                                    title="Compare Quotes" 
                                    description="Review competitive quotes from dispatch riders, drivers or logistics companies depending on the mode of transport. Receive instant alert notification each time a quote is submitted for your job." 
                                    image={Compare}
                                    showImage={true}
                                />
                                <How_it_works_card 
                                    title="Choose the Best Price" 
                                    description="Accept the best quote in terms of price and ratings from past jobs. Receive quote acceptance acknowledgement from a provider to confirm readiness to carry out the job according to your instructions." 
                                    image={BestPrice}
                                    showImage={true}
                                />
                                <How_it_works_card1
                                    title="Payment" 
                                    description="Pay at pickup or delivery. All payments must be through the Dillivry app or web. Cash payment is not allowed. Sit back and relax while your item is delivered safely." 
                                    image={Pickup}
                                />
                            </>
                       }
                  </div>
              </div>
            <Footer/>
          </div>
      </div>
  )
}
