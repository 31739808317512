import { BarChart, Bar,XAxis, YAxis,Tooltip,Legend} from "recharts";
import "./PieChart.css"
export default function BarGraph({data}) {
  return (
    <>
    <div className="chartweb">
      <BarChart width={750} height={250} data={data} margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
        <XAxis dataKey="day">
          </XAxis>
        <YAxis label={{ value: 'Amount', angle: -90, position: 'left' }} />
        <Tooltip cursor={false} />
        <Legend />
        <Bar dataKey="amount" fill="var(--main)" barSize={30}/>
    </BarChart>
    </div>
    <div className="chartMob">
      <BarChart width={400} height={250} data={data}>
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip cursor={false} />
          <Legend />
          <Bar label="amount" dataKey="amount" fill="var(--main)" barSize={10}/>
      </BarChart>
    </div>
    </>
    
  );
}