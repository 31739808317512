import React from 'react'
import Webcam from 'react-webcam'
import CaptureBtn from './CaptureBtn'
import {MdCameraFront} from 'react-icons/md'
import {IoIosImages} from 'react-icons/io'
import imageCompression from 'browser-image-compression'
import {IoMdArrowRoundBack} from 'react-icons/io'
import SecureLS from 'secure-ls';


function Image_capture({ goToPreview,goToBack,videoWidth, videoHeight, camWidth, camHeight, shotWidth, shotHeight}) {
    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

    const [toBack, settoBack] = React.useState(false)

    const swap = () =>{
        settoBack(!toBack)
    }

    const videoConstraints = {
        width: videoWidth,
        height: videoHeight,
        facingMode: toBack ?  "user" : { exact: "environment" } 
      };
      const webcamRef = React.useRef(null);

    const capture = React.useCallback(
        () => {
            ls.set('_prv_', webcamRef.current.getScreenshot())
            goToPreview();
        },
        [webcamRef]
    );

    const  options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 2048,
        }

    async function imageHandler (e){
        const file = e.target.files[0]
        
        const output = await imageCompression(file, options)
        const dataa = await imageCompression.getDataUrlFromFile(output)
        ls.set('_prv_', dataa)
            goToPreview();
        }
    
    return (
        <div>
            <div style={{width:'100%', marginBottom:'40px', }}>
                <IoMdArrowRoundBack size='30px' onClick={goToBack}/>
            </div>

            <input 
                type="file" id="selfie" 
                accept="image/png, image/gif, image/jpeg" 
                onChange={imageHandler} 
                style={{display:'none'}} />

            <div style={{width:'100%',display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                {/* <div style={{width:'300px', height:'400px', borderRadius:'50%', position:'relative', display:'flex', justifyContent:'center', alignItems:'center'}}> */}
                    <Webcam 
                        audio={false}
                        mirrored={false}
                        ref={webcamRef}
                        screenshotFormat="image/png"
                        // width={camWidth}
                        // height={camHeight}
                        screenshotQuality={0.4}
                        // minScreenshotWidth={shotWidth}
                        // minScreenshotHeight={shotHeight}
                        videoConstraints={videoConstraints}
                    />
                {/* </div> */}
                
            </div>

            <div className="btnCaptureDesktop">

                <label htmlFor="selfie">
                    <IoIosImages size="25" color="var(--main)"/>
                </label>
                
                <CaptureBtn onClick={capture}/>

                <label>
                    <MdCameraFront size="25" color="var(--main)" onClick={swap}/>
                </label>
            </div>
             <div className="btnCaptureMobile">

                <label htmlFor="selfie">
                    <IoIosImages size="25" color="var(--main)"/>
                </label>
                
                <CaptureBtn onClick={capture}/>

                <label>
                    <MdCameraFront size="25" color="var(--main)" onClick={swap}/>
                </label>
            </div>
        </div>
    )
}

export default Image_capture
