import React from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PuffLoader from 'react-spinners/PuffLoader';

const useStyles = makeStyles((theme) => ({
   
    submit: {
      margin: theme.spacing(0, 0, 4),
      backgroundColor:'var(--main)',
      color:'white',
      fontWeight:'600',
      marginBottom:'15px'
    }
  }));

function RegBtn({txt, icon, type, onClick, loading, disabled}) {
    const classes = useStyles();
    return (
      <Button
        onClick={onClick}
        type={loading ? 'button' : type  }
        fullWidth
        variant="contained"
        disabled={disabled}
        className={classes.submit}
        >
          <div style={{width:'100%',  display:'flex', justifyContent:'center'}}>
            {
              loading ?
              <PuffLoader color='white' loading={loading}  speedMultiplier={1} size={24}/>
              :
              <div style={{width:'100%', height:'100%', display:'flex', alignItems:'center'}}>
                <div style={{width:'95%', display:'flex', justifyContent:'center',textTransform:'none'}}>
                    {txt}
                </div>
                <div style={{width:'5%',height:'100%', display:'flex',alignItems:'center', justifyContent:'center'}}>
                    {icon}
                </div>
              </div>
            } 
          </div>
      </Button>
    )
}

export default RegBtn
