import { createContext, useEffect, useState } from "react";
import Axios from "axios";
import SecureLS from "secure-ls";

import Cookies from "js-cookie";
export const ConnContext = createContext();

export const ConnProvider = ({ children }) => {
  const [logindata, setlogindata] = useState([]);
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  useEffect(() => {
    // Axios.get(
    //   "https://geolocation-db.com/json/f9902210-97f0-11eb-a459-b997d30983f1"
    // ).then((response) => {
    //   ls.set("vccname",response?.data?.country_name)
    //   ls.set("vccCode",response?.data?.country_code)
    //   setCountryName(response?.data?.country_name)
    //   setvisitingCountryCode(response?.data?.country_code);
    // });

    ls.set("vccname","Nigeria")
    ls.set("vccCode","NG")
    setCountryName("Nigeria")
    setvisitingCountryCode("NG");

  }, []);

  const [visitingCountryCode, setvisitingCountryCode] = useState("");
  const [countryName, setCountryName] = useState("");

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      fetch("//google.com", {
        mode: "no-cors",
      })
        .then(() => {})
        .catch((error, Error) => {
          if (error|| Error ) {
            window.document.reload()
          }
          return false;
        });

      return true;
    }
    return false;
  };

  const handlelogindata = (response) => {
    setlogindata(response);
    // console.log(logindata);
  };

  return (
    <ConnContext.Provider
      value={{
        handleConnectionChange,
        visitingCountryCode,
        countryName,
        handlelogindata,
        logindata,
      }}
    >
      {children}
    </ConnContext.Provider>
  );
};
