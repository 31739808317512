import React, { useEffect, useState, useContext } from "react";
import SecureLS from "secure-ls";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import Default from "../../pages/SearchDeliveries/AllData/Default";
import Nav from "../../components/Nav/Nav";
import IndividualSidebar from "../../components/Sidebar/IndividualSidebar";
import imagelogo from "../../assets/Dillivry.png";
import OutforDelivery from "./OutforDelivery";
import Pickedup from "./Pickedup";
import Delivered from "./Delivered";
import Missed from "./Missed";
import LoginComponent from "../../components/LoginComponent";
import Popup from "../../components/Popup";
import DialogSimplePop from "../../components/DialogSimplePop";
import erros from '../../assets/erros.png'
import { ConnContext } from "../../context/ConnContext";
import TopBar from "../../components/TopBar/TopBar";
import GlobalizationLanguage from "../../components/GlobalizationLanguage";

export default function TrackingPages() {
  const history = useHistory();
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const Authorization = ls.get("_utk") === "" ? "" : ls.get("_utk");

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: Authorization,
    },
  });

  const [loading, setisloading] = useState(false);

  const [listed, setlisted] = useState("");
  const [listeddata, setlistedata] = useState([]);
  const [outfordilivry, setoutfordilivry] = useState([]);
  const [dilivered, setdilivered] = useState([]);
  const [missed, setmissed] = useState([]);
  const [state, setstate] = useState(false);
  let { id, userID, comment } = useParams();

  const [nolocation, setnolocation] = useState("false")

 

  const {visitingCountryCode} = useContext(ConnContext)

  useEffect(() => {
    // setitemid(id.substr(3));
    // setemailID(userID.substr(7));
  }, []);

 

  useEffect(() => {
    setisloading(!loading);

    if (ls.get("_utk") === "") {
      setstate(true);
      setisloading(false);
    } else {
      setstate(false);
      authAxios
        .post(`${process.env.REACT_APP_API}m-dillivry-search`, {
          search_criteria: "agent_tracking",
          delivery_id: id,
          vcc: visitingCountryCode
          //   delivery_id: id.substr(3)
        }, {timeout: 240000})
        .then((response) => {
          setisloading(loading);
          // console.log(response, "tracking response");
          // console.log(response?.data?.default_data?.filter(x => x.delivery_id === id))
          // if (response.) {
            
          // }

          if (Object.keys(response.data.start_location) === 0 && response.data.start_location === Object )   {
            setnolocation(true)
          }

           else if (response.data.start_location === undefined || response.data.end_location  === undefined) {
            setnolocation(true)
            }else{
              setnolocation(false)
            }


          setlistedata(response.data);
          response.data.assigned_agent_name !== undefined ? (
            setoutfordilivry(response.data)
          ) : response.data.delivery_status === "delivered" ? (
            setdilivered(response.data)
          ) : response.data.delivery_status === "missed_delivery" ? (
            setmissed(response.data)) : (
            <></>
          );
          response.data.delivery_status === "picked_up" ? (
            setlisted("listed")
          ) : response.data.delivery_status === "out_for_delivery" &&
            response.data.delivering_today === "yes" ? (
            setlisted("out_for_dilivery")
          ) : response.data.delivery_status === "out_for_delivery" &&
            response.data.delivering_today === "no" ? (
            setlisted("nottoday")
          ) : response.data.delivery_status === "delivered" ? (
            setlisted("delivered")
          ): response.data.delivery_status === "missed_delivery" ? (
            setlisted("missed"))   : (
            <></>
          );
          // history.push(`/TrackingPages/${response.data.delivery_id}`)
        })
        .catch((err) => {
          if (err.code === "ECONNABORTED") {
            console.log('internal-server-error-timeout')
            history.push(GlobalizationLanguage('/timeout'))
            // window.location.reload()
          }
         
          if (err.Error === "Network Error" || err === 502) {
           
          }
          err ? setisloading(loading) : <></>;
        });
    }
  }, []);

  // let { id, userID, comment } = useParams();
  // console.log(listeddata.delivery_id, 'delivery-idddd')
 
  return (
    <div>
      <div className="topmenu showTopMenuDesktop">
        {loading ? (
          <StyledEngineProvider injectFirst>
            <Default />
          </StyledEngineProvider>
        ) : null}
        {/* <Nav /> */}
        <div className="header-body-">
              <TopBar/>
            </div>
      </div>
      {/* <div className="ind-nav">
        <IndividualSidebar />
        <div className="logocont">
          <img
            src={imagelogo}
            alt="dillivry"
            onClick={() => history.push("/")}
          />
        </div>
      </div> */}



      {
       state === false ? (
      
      listed === "listed" ? (
        <Pickedup data={listeddata} />
      ) : listed === "out_for_dilivery" ? (
        <OutforDelivery data={outfordilivry} />
      ) : listed === "delivered" ? (
        <Delivered data={dilivered} />
      ): listed === "missed" ? <Missed data={missed}/>: null
       ):

       (

        <Popup
          trigger={state}
          setTrigger={setstate}
      >
          <LoginComponent        onFinish={() => {
                 setstate(!state);
                   window.location.reload();
                 }}/>

      </Popup>
       )
      
      }


      {
        nolocation === true ? 

        <DialogSimplePop openPopup={nolocation}
        btnText="Ok"
          onClick={() => setnolocation(false)}>


<div
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <img src={erros} alt="dillivry" style={{ width: "60px" }} />
            <div
              style={{
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "500",
                width: "100%",
              }}
            >
                <>
                  <div>Your item location cannot be determined at the moment. You may want to retry in few minutes</div>
                  <div
                    style={{
                      display: "flex",
                      gap: "20px",
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "30px",
                    }}
                  >
                  </div>
                </>
             
            </div>
          </div>


          </DialogSimplePop> :

          <>


          </>


      }



    </div>
  );
}