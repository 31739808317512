/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef, useContext } from "react";
 import "./ViewDelivry2.css";
import Carousel from "react-material-ui-carousel";
import GoogleMapWidget from "../../../components/Map/GoogleMapWidget";

import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import to from "../../../assets/to.svg";
import From from "../../../assets/From.svg";
import itemdescr_ from "../../../assets/itemdescr_.svg";
import Distance from "../../../assets/Distance.svg";
import Duration from "../../../assets/Duration.svg";
import Listed from "../../../assets/Listed.svg";
import Fastdelivery from "../../../assets/Fastdelivery.svg";
import Footer from "../../../components/Footer/Footer";
import DialogOutClick from "../../../components/DialogOutClick";
import QuoteSignInForm from "./QuoteSignInForm";
import HomeHelp from "../../../components/HomeHelp";
import Nav from "../../../components/Nav/Nav";
import { useLocation } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import { AuthContext } from "../../../context/AuthContext";
import SecureLS from 'secure-ls';
import ReactTooltip from 'react-tooltip';
import jwt_decode from 'jwt-decode';
import dimensions from '../../../assets/dimensions.png'
import weighingmachine from '../../../assets/weighingmachine.png'
import { ConnContext } from "../../../context/ConnContext";
import TopBar from "../../../components/TopBar/TopBar";
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
import Cookies from 'js-cookie';
import Currency from "../../../utils/Currency";

export default function ViewDeliver2({
  data,
  handleChange,
  viewFulfiller,
}) {
  var login=GlobalizationLanguage("/login")
  var timeout = GlobalizationLanguage("/timeout")
  var deliver_with_us = GlobalizationLanguage("/create-account")

  const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

  const history = useHistory();
  const [showpopup, setShowpopup] = useState(false);
  const [showpopup2, setShowpopup2] = useState(false);
  const [componenttoshow, setComponenttoshow] = useState(false);
  const [componenttoshow2, setComponenttoshow2] = useState(false);
  const [submiting, setsubmiting] = useState(false);
  const [fulfillerDetailToShow, setFulfillerDetailToShow] = useState({});
  const [showHelp, setshowHelp] = useState(false);
  const [showSubmitQuote, setShowSubmitQuote] = useState(false);
  const [isSearching, setisSearching] = useState(false);
  const [watched_items, setwatched_items] = useState([]);
  const [allselectedids, setllselectedids] = useState([]);
  const location = useLocation();
  const auth = useContext(AuthContext);
  const {visitingCountryCode} = useContext(ConnContext)

  const formatString = (string)=>{
    let trimStr = string.trim();
    let trimStr2 = trimStr.split(' ');
    trimStr2 = trimStr2.filter(e => e.length !== 0)
    return trimStr2 
  } 




  // const [submiting, setsubmiting] = useState(false);
  const Authorization = 
      ls.get("_nodi") === ''
        ? ""
        : ls.get("_nodi")
    

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: Authorization,
    },
  });

  useEffect(() => {
    setisSearching(true);

    authAxios
      .get(`${process.env.REACT_APP_API}m-dillivry-search`, {
        search_criteria: "default",
        vcc: visitingCountryCode
      }, {timeout: 240000})
      .then((response) => {

        const watched = response.data.default_data.filter(function (item) {
          return item.watched === "yes";
        });

        setwatched_items(watched.map((a) => a.delivery_id));

        setisSearching(false);
      })
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          console.log('internal-server-error-timeout')
          history.push(timeout)
          // window.location.reload()
        }
        console.log(error, "error");
        setisSearching(false);
      });
  }, []);

  useEffect(
    (e) => {
      <Nav />;
    },
    [setComponenttoshow]
  );

  const schema = yup.object().shape({
    QAmount: yup
      .string()
      .required("Amount required")
      .matches(/^[0-9]*$/, "Amount has to be number"),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

 
  const quoteNow = (formData) => {
    //  reset()
    // setsubmiting(true);
      if (formData.msg === "") {
        // setsubmiting(true);
        if (ls.get("_nodi")  !== '') {
          // setsubmiting(false);
          setsubmiting(true);
        authAxios
          .post(`m-dillivry-bid-manager?delivery_location=${Cookies.get("i18next")}`, {
            action_type: "quote",
            delivery_id: data.delivery_id,
            quote_amount: formData.QAmount.replaceAll(",", ""),
            vcc: visitingCountryCode
          }, {timeout: 240000})
          .then((response) => {
            setShowSubmitQuote(false);
            //  setsubmiting(false);
            if (response.status === 200) {
           
              Swal.fire({
                icon: "success",
                text: "Quote placed successfully!",
                timer: 2500,
                showConfirmButton: false,
              }).then(() => {
                // window.location.reload();
              });
              reset({})
            }
            // reset();
             setsubmiting(false);
          })
          .catch((err) => {
            if (err.code === "ECONNABORTED") {
              console.log('internal-server-error-timeout')
              history.push(timeout)
              // window.location.reload()
            }
            setShowSubmitQuote(false);
            console.log(err);
            //  setsubmiting(false);
        
            if(err?.response?.status === 403){
              Swal.fire({
                icon: "warning",
                text: "Session expired, login again!",
              //  timer: 2500,
                confirmButtonColor: 'var(--main)',
              })
              .then((result) => {
                if (result.value) {
                  auth.logout();
                  history.push(login);
                }
            });
           }
           else{
            Swal.fire({
              icon: "warning",
              text: 'An error occured, be sure you are connected to internet',
             timer: 2500,
              showConfirmButton: false,
            })
           }
          //  setsubmiting(false);
          console.log(err);
          });
        }
      } 
      
      else {
        // setsubmiting(true);
     if(ls.get("_nodi")  !== ''){
      setsubmiting(true);
      authAxios
      .post(`m-dillivry-bid-manager?delivery_location=${Cookies.get("i18next")}`, {
        action_type: "quote",
        delivery_id: data.delivery_id,
        quote_amount: formData.QAmount.replaceAll(",", ""),
        msg: formData.msg,
        vcc: visitingCountryCode
      }, {timeout: 240000})
      .then((response) => {
        setShowSubmitQuote(false);
         setsubmiting(false);
        if (response.status === 200) {
         
          Swal.fire({
            icon: "success",
            text: "Quote placed successfully!",
            timer: 2500,
            showConfirmButton: false,
          }).then(() => {
            // window.location.reload()
           
          });
          reset({})
          setsubmiting(false)
        }
        // reset()
      })
      .catch((err) => {
        setShowSubmitQuote(false);
        console.log(err);
        // setsubmiting(false);
      
        if(err?.response?.status === 403){
          Swal.fire({
            icon: "warning",
            text: "Session expired, login again!",
          //  timer: 2500,
            confirmButtonColor: 'var(--main)',
          })
          .then((result) => {
            if (result.value) {
              auth.logout();
              history.push(login);
            }
            setsubmiting(false)
        }).catch((err)=>{
          if (err.code === "ECONNABORTED") {
            console.log('internal-server-error-timeout')
            history.push(timeout)
            // window.location.reload()
          }
        })
       }
       else{
        Swal.fire({
          icon: "warning",
          text: "Error occured try again!",
         timer: 2500,
          showConfirmButton: false,
        })
        setsubmiting(false)
       }
      });
      // reset()
     }
      }
    };




  useEffect(() => {
    window.scrollTo(0, 0);

    if (location.state === "back") {
      executeScrollbacktotop();
    }
  }, []);

  const scrollToRef = (ref) =>
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  const backToTop = useRef(null);
  const executeScrollbacktotop = () => scrollToRef(backToTop);
  const [newdilId, setnewdilId] = useState([]);
  const [removedilId, setremovedilId] = useState([]);
  // const [newdilId, setnewdilId] = useState([]);
  const watchNow = (delivery_id_) => {
    if (newdilId.includes(delivery_id_)) {
      setnewdilId(
        newdilId.filter(function (item) {
          return item !== delivery_id_;
        })
      );

      authAxios
        .post(`m-dillivry-listing?delivery_location=${Cookies.get('i18next')}`, {
          action_type: "watchlist",
          ops_type: "remove",
          delivery_id: delivery_id_,
          fulfiller_id: ls.get("_iyD")
            ? ls.get("_iyD")
            : null,
            vcc: visitingCountryCode
        }, {timeout: 240000})
        .then((response) => {
          if (response.status === 200) {
            // Nav.reload()
            Swal.fire({
              icon: "success",
              text: "successfully removed",
              timer: 2500,
              showConfirmButton: false,
            }).then(() => {});
          } else {
            alert("Error occured!");
          }
        })
        .catch((err) => {
          if (err.code === "ECONNABORTED") {
            console.log('internal-server-error-timeout')
            history.push(timeout)
            // window.location.reload()
          }
          console.log(err);
        });
    } else {
      setnewdilId([...newdilId, delivery_id_]);
      authAxios
        .post(`m-dillivry-listing?delivery_location=${Cookies.get('i18next')}`, {
          action_type: "watchlist",
          ops_type: "watch",
          delivery_id: delivery_id_,
          fulfiller_id: ls.get("_iyD")
            ? ls.get("_iyD")
            : null,
            vcc: visitingCountryCode
        }, {timeout: 240000})
        .then((response) => {
          if (response.status === 200) {
            Swal.fire({
              icon: "success",
              text: "successfully added to watchlist",
              timer: 2500,
              showConfirmButton: false,
            }).then(() => {});
          } else {
            alert("Error occured!");
          }
        })
        .catch((err) => {
          if (err.code === "ECONNABORTED") {
            console.log('internal-server-error-timeout')
            history.push(timeout)
            // window.location.reload()
          }
          console.log(err);
        });
    }
  };
  const [show, setshow] = useState("hide");
  const [selected, setselected] = useState("hide");

  const [answer, setanswer] = useState({});
  const [watch, setWatch] = useState(false);
  const [watchType, setwatchType] = useState("add");

  const handlerRadioChange = (e) => {
    if (e.target.value === "show") {
      setshow("show");
      setselected(e.target.value);
    } else {
      setshow("hide");
      setselected(e.target.value);
    }
  };

  // const Authorization = atob(
  //   atob(
  //     ls.get("_nodi") === null
  //       ? ""
  //       : ls.get("_nodi")
  //   )
  // );

  // const authAxios = axios.create({
  //   baseURL: `${process.env.REACT_APP_API}`,
  //   headers: {
  //     Authorization: Authorization,
  //   },
  // });

  const [viewData, setviewData] = useState([]);
  const [deliveryStatus, setdeliveryStatus] = useState("");
  //const [fulfillerDetailToShow, setFulfillerDetailToShow] = useState({});

  const [origin, setorigin] = useState([]);
  const [destination, setdestination] = useState([]);

  const container = { width: "100%", height: "100%" };

  useEffect(() => {
    setviewData([...viewData, data]);
    setdeliveryStatus(data.delivery_status);
    const startLat = parseFloat(data.start_location.lat);
    const startlng = parseFloat(data.start_location.lng);
    const endLat = parseFloat(data.end_location.lat);
    const endLng = parseFloat(data.end_location.lng);

    setorigin([
      {
        id: "pickup",
        title: "Pickup",
        coordinates: {
          lat: startLat,
          lon: startlng,
        },
      },
    ]);
    setdestination([
      {
        id: "destination",
        title: "Destination",
        coordinates: {
          lat: endLat,
          lon: endLng,
        },
      },
    ]);
  }, []);

  const numberWithCommas = new Intl.NumberFormat("en-GB");

  const [sampledata, setsampledata] = useState({
    type_1: "watch",
    id: [],
  });

  const WatchNow = (delivery_id_) => {
    authAxios
      .post(`m-dillivry-listing?delivery_location=${Cookies.get('i18next')}`, {
        action_type: "watchlist",
        ops_type: "watch",
        delivery_id: [delivery_id_],
        fulfiller_id: ls.get("_iyD")
          ? ls.get("_iyD")
          : null,
          vcc: visitingCountryCode
      }, {timeout: 240000})
      .then((response) => {
        setllselectedids([...allselectedids, delivery_id_]);
        if (response.status === 200) {
          console.log("added successfully");
        } else {
          alert("Error occured!");
        }
      })
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          console.log('internal-server-error-timeout')
          history.push(timeout)
          // window.location.reload()
        }
        console.log(err);
      });
  };

  const RemoveNow = (delivery_id_) => {
    authAxios
      .post(`m-dillivry-listing?delivery_location=${Cookies.get('i18next')}`, {
        action_type: "watchlist",
        ops_type: "remove",
        delivery_id: [delivery_id_],
        fulfiller_id: ls.get("_iyD")
          ? ls.get("_iyD")
          : null,
          vcc: visitingCountryCode
      }, {timeout: 240000})
      .then((response) => {

        setllselectedids(
          allselectedids.filter(function (n) {
            return n !== delivery_id_;
          })
        );

        setwatched_items(
          watched_items.filter(function (n) {
            return n !== delivery_id_;
          })
        );

        if (response.status === 200) {
          console.log("Removed successfully");
        } else {
          alert("Error occured!");
        }
      })
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          console.log('internal-server-error-timeout')
          history.push(timeout)
          // window.location.reload()
        }
        console.log(err);
      });
  };

  const childRef = useRef();
  
  return (
    <div className="viewSearch">
      {viewData.map((view) => {
        return (
          <div className="Desktop-screen-div">
            <TopBar/>

            <div key={view.delivery_id} className="view_Container">
              <div className="view_Top_mobile-">
                <div className="topItem">
                  <div className="topItem-first-div">
                    <button
                      className="topItem-first-div-btn"
                      onClick={handleChange}
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => executeScrollbacktotop()}
                      >
                        Back
                      </span>
                    </button>
                    <span
                      className="topItem-first-div-category"
                      style={{ fontWeight: "600" }}
                    >
                      {" "}
                      Category:{" "}
                    </span>
                    <span className="topItem-first-div-item-category">
                      {view.item_category.charAt(0)?.toUpperCase() + view.item_category.slice(1).replace(/_/g , " ")}
                    </span>
                  </div>

                  <div className="topItem-second-div">
                    <span
                      className="topItem-second-div-Id"
                      style={{ fontWeight: "600" }}
                    >
                      ID:{" "}
                    </span>
                    <span className="topItem-second-div-item-category">
                      {view.delivery_id}
                    </span>

                    {/* {watched_items.includes(view.delivery_id) ||
                    allselectedids.includes(view.delivery_id) ? (
                      <button
                        className="watch-btn-star viewfulfiler-btn"
                        onClick={(e) => {}}
                      >
                        <IconContext.Provider
                          value={{
                            color: "#50b14a",
                            className: "global-class-name-watch-icon",
                          }}
                        >
                          <div>
                            <AiTwotoneStar />
                          </div>
                        </IconContext.Provider>
                        <span
                          onClick={() => {
                            setShowpopup(!showpopup);
                            if (ls.get("_nodi") === '') {
                              setComponenttoshow(true);
                            } else {
                              setComponenttoshow(false);
                              setShowpopup(showpopup);

                              RemoveNow(view.delivery_id);
                            }
                          }}
                        >
                          Remove
                        </span>
                      </button>
                    ) : (
                      <button className="watch-btn-star viewfulfiler-btn">
                        <IconContext.Provider
                          value={{
                            color: "#50b14a",
                            className: "global-class-name-watch-icon",
                          }}
                        >
                          <div>
                            <AiTwotoneStar />
                          </div>
                        </IconContext.Provider>
                        <span
                          onClick={(e) => {
                            setShowpopup(!showpopup);
                            if (ls.get("_nodi") === '') {
                              setComponenttoshow(true);
                            } else {
                              setComponenttoshow(false);
                              setShowpopup(showpopup);

                              WatchNow(view.delivery_id);
                            }
                          }}
                        >
                          Watch
                        </span>
                      </button>
                    )} */}

                    <button
                      className="topItem-second-div-report"
                      onClick={() => history.push("/complaints")}
                    >
                      <span>Report</span>
                    </button>
                  </div>
                  
                </div>
                <div></div>
              </div>

              <div className="view_Map">
                <GoogleMapWidget origins={destination} destinations={origin} />
              </div>
              <div className="view_Map_mobile">
                <GoogleMapWidget origins={destination} destinations={origin} />
              </div>

              <div className="main-fulfiler-page">
                <div className="imgage-content">
                  <Carousel
                    navButtonsAlwaysInvisible={true}
                    indicators={true}
                    animation={"slide"}
                    timeout={100}
                    autoPlay={true}
                  >
                    {view.image_urls?.map((img_url, i) => (
                      <img
                        key={i}
                        src={img_url}
                        style={{ width: "100%", maxHeight: "100%" }}
                        alt="Item_image"
                        className="carousel_image-"
                      />
                    ))}
                  </Carousel>
                </div>

                <div className="dillivry-info">
                  <div className="right-content-information---">
                    <div className="submit-quote-content">
                      <div className="quote-div">
                        {" "}
                        <h1>Delivery infomation</h1>
                      </div>
                      <div className="current-quote-body  dilivry-one   ">
                        <div>
                          <div className="">
                            <div className="itemDescs-desc">
                              <div className="itemDescs- quote-info--- ">
                                <div>
                                  {" "}
                                  <img
                                    src={itemdescr_}
                                    alt=""
                                    className="icn-one-"
                                  />{" "}
                                </div>
                                <div>
                                  {" "}
                                  <span className="icon-desc">
                                    {view.item_desc.charAt(0)?.toUpperCase() + view.item_desc.toLowerCase().slice(1)}
                                  </span>
                                </div>
                              </div>
                              <div className="itemDescs-">
                                <div>
                                  {" "}
                                  <img
                                    src={to}
                                    alt=""
                                    className="icn-one-"
                                  />{" "}
                                </div>
                                <div data-tip
                                  data-for="global1"  >
                                  {" "}
                                  <span className="icon-desc">{view.from}</span>
                                </div>
                              </div>

                              <div className="itemDescs-">
                                <div>
                                  {" "}
                                  <img src={From} alt="" className="icn-two-" />
                                </div>


                                <ReactTooltip
              id="global"
              aria-haspopup="true"
              type="light"
              role="example"
            >
              <p>Destination Address</p>
              
            </ReactTooltip>



                                <div>
                                  {" "}
                                  <span className="icon-desc">{view.to}</span>
                                </div>
                              </div>
                              <div className="itemDescs-">
                                <div>
                                  {" "}
                                  <img
                                    src={Distance}
                                    alt=""
                                    className="icn3-"
                                  />
                                </div>

                                <div>
                                  <span className="icon-desc">
                                    {view.distance}
                                  </span>
                                </div>
                              </div>
                              <div className="itemDescs-">
                                <div>
                                  {" "}
                                  <img
                                    src={Duration}
                                    alt=""
                                    className="icn4-"
                                  />
                                </div>

                                <div>
                                  {" "}
                                  <span className="icon-desc">
                                    {view.duration}
                                  </span>
                                </div>
                              </div>

                              <div className="itemDescs-">
                                <div>
                                  <img src={Listed} alt="" className="icn5-" />
                                </div>

                                <div>
                                  {" "}
                                  <span className="icon-desc">
                                    {view.date_listed.charAt(0)
                                      ?.toUpperCase() +
                                      view.date_listed
                                        .toLowerCase()
                                        .slice(1)}
                                  </span>
                                </div>
                              </div>

                              <div className="itemDescs-">
                                <div>
                                  {" "}
                                  <img
                                    src={Fastdelivery}
                                    alt=""
                                    className="icn6-"
                                  />
                                </div>

                                <div>
                                  <span className="icon-desc">
                                    {view.mode_of_delivery.charAt(0)
                                      ?.toUpperCase() +
                                      view.mode_of_delivery
                                        .toLowerCase()
                                        .slice(1)}
                                  </span>{" "}
                                </div>
                              </div>
                              
                              {
                                        view.item_category === "general_items" || view.item_category === "furniture" || view.item_category === "vehicle_parts" ? 
                                        
                                        <div
                                          className="itemDescs-"
                                          data-tip
                                          data-for="global8"
                                        >
                                          <div>
                                            <img
                                              className="icn6-"
                                              src={dimensions}
                                              alt=""
                                              style={{ height: "20px" }}
                                            />
                                          </div>

                                          <div>
                                            <span className="icon-desc">
                                              {
                                                view?.item_dim?.length === " centimeters" && view?.item_dim?.width === " centimeters" && view?.item_dim?.height === " centimeters" ?
                                                "Not provided by sender"
                                                :
                                                <>
                                                {
                                                formatString(view?.item_dim?.length)[0]}
                                                 {formatString(view?.item_dim?.length)[1] === "centimeters" ? "cm" : formatString(view?.item_dim?.length)[1] === "meters" ? "m" : formatString(view?.item_dim?.length)[1] === "inches" ? "inches" : "ft"
                                                }
                                               &nbsp;  x  &nbsp; 
                                                {
                                                  formatString(view?.item_dim?.width)[0]}
                                                  {formatString(view?.item_dim?.width)[1] === "centimeters" ? "cm" : formatString(view?.item_dim?.width)[1] === "meters" ? "m" : formatString(view?.item_dim?.width)[1] === "inches" ? "inches" : "ft"
                                                }
                                                 &nbsp;  x  &nbsp; 
                                                {
                                                  formatString(view?.item_dim?.height)[0]}
                                                  {formatString(view?.item_dim?.height)[1] === "centimeters" ? "cm" : formatString(view?.item_dim?.height)[1] === "meters" ? "m" : formatString(view?.item_dim?.height)[1] === "inches" ? "inches" : "ft"
                                                }
                                                (L x W x H)
                                                </>
                                              } 
                                            </span>
                                            <div className="tobeflexmain"></div>
                                          </div>
                                        </div>
                                        :
                                        null
                                    }

                                    {
                                        view.item_category === "general_items" || view.item_category === "furniture" || view.item_category === "vehicle_parts" ? 

                                        <div
                                          className="itemDescs-"
                                          data-tip
                                          data-for="global9"
                                        >
                                          <div>
                                            <img
                                              className="icn6-"
                                              src={weighingmachine}
                                              alt=""
                                              style={{ height: "20px" }}
                                            />
                                          </div>

                                          <div>
                                            <span className="icon-desc"   data-tip
                                          data-for="global9" >
                                              {
                                                view?.item_dim?.weight === " grammes" ?
                                                "Not provided by sender"
                                                :
                                                <>
                                                {
                                                formatString(view?.item_dim?.weight)[0]}
                                                 {formatString(view?.item_dim?.weight)[1] === "gramme" ? "gr" : formatString(view?.item_dim?.weight)[1] === "kilogrammes" ? "kg" : "tonnes"
                                                }
                                               
                                                </>
                                              } 
                                            </span>
                                            <div className="tobeflexmain"></div>
                                          </div>
                                        </div>
                                        :
                                        view.item_category === "haulage" ?
                                        <div
                                          className="itemDescs-"
                                          data-tip
                                          data-for="global9"
                                        >
                                          <div>
                                            <img
                                              className="icn6-"
                                              src={weighingmachine}
                                              alt=""
                                              style={{ height: "20px" }}
                                              data-tip
                                              data-for="global9"
                                            />
                                            
                                          </div>

                                          <div>
                                            <span className="icon-desc">
                                              {
                                                view?.item_dim === "" ?
                                                "Not provided by sender"
                                                :
                                                <>
                                                {
                                                formatString(view?.item_dim)}
                                                </>
                                              } 
                                            </span>
                                            <div className="tobeflexmain"></div>
                                          </div>
                                        </div>

                                        :
                                        null
                                    }




                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" quote-info ß  submit-quote-content">
                  <div className="quote-div">
                    <h1>Quote Information</h1>
                  </div>
                  <div className="itemDescs-desc current-quote-body ">
                    <div>
                      <div className="quote-info--">
                        <div>
                          {" "}
                          <span className="Quote-Title">
                            Quote status:{" "}
                          </span>{" "}
                        </div>
                        <div>
                          <span className="quote-amount">
                            Awaiting Quote Acceptance
                          </span>
                        </div>
                      </div>

                      <div>
                        <div className="quote-info--">
                          <div>
                            {" "}
                            <span className="Quote-Title">
                              Pick up date:{" "}
                            </span>{" "}
                          </div>
                          <div>
                            <span className="quote-amount">
                              {view?.pickup_date}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="quote-info--">
                          <div>
                            {" "}
                            <span className="Quote-Title">
                              Delivery time frame:{" "}
                            </span>{" "}
                          </div>
                          <div>
                            <span className="quote-amount">
                              {view.delivery_time_frame}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="quote-info--">
                          <div>
                            {" "}
                            <span className="Quote-Title">
                              Best Quote:{" "}
                            </span>{" "}
                          </div>
                          <div>
                            <span className="quote-amount">
                            <Currency location={view?.delivery_location}/> {view?.best_quote}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <form onSubmit={handleSubmit(quoteNow)}>
                          <div className="quote-info--">
                            <div>
                              {" "}
                              <span className="Quote-Title">
                                Your Quote:{" "}
                              </span>{" "}
                            </div>
                            <div>
                              <span className="quote-amount">
                              <Currency location={view?.delivery_location}/>{" "}
                                <input
                                  {...register("QAmount")}
                                  type="text"
                                  className="quoteinputcontent"
                                  // required
                                />
                                <br /> <small>{errors.QAmount?.message}</small>
                              </span>
                            </div>
                          </div>

                          <div className="quote-info--">
                            <div>
                              {" "}
                              <div
                                className=""
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  {" "}
                                  <span className="Quote-Title-message   ques-">
                                    {" "}
                                    Would you like to add message?
                                  </span>{" "}
                                </div>

                                <div style={{ display: "flex" }}>
                                  <div style={{ marginRight: "30px" }}>
                                    <input
                                      type="radio"
                                      value="show"
                                      className="message_yes_response"
                                      name="content"
                                      onClick={handlerRadioChange}
                                      checked={selected === "show"}
                                    />
                                    <label htmlFor="Yes">Yes</label>
                                  </div>

                                  <div>
                                    <input
                                      type="radio"
                                      value="hide"
                                      className="message_yes_response"
                                      name="content"
                                      onClick={handlerRadioChange}
                                      checked={selected === "hide"}
                                    />
                                    <label htmlFor="No_">No</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div></div>
                          </div>

                          {show === "show" ? (
                            <div className="">
                              <div className="">
                                <textarea
                                  {...register("msg")}
                                  rows="3"
                                  cols="20"
                                  className="quoteinputcontent-"
                                  required
                                ></textarea>
                              </div>
                            </div>
                          ) : null}

                          <div className="enquiry-submit">
                            {view.delivery_status === undefined ? (
                              <div className=" enquiry-mssg">
                                {" "}
                                <p
       onClick={(e) => {
        setShowpopup(!showpopup);
        setShowpopup2(!showpopup2);
        setshow("hide");
        if (
          ls.get("_nodi") === '' // logedincheck
        ) {
          setComponenttoshow(true);
          setComponenttoshow2(false);
        } else {
          setShowpopup(!showpopup);
          setShowpopup2(!showpopup2);
          setComponenttoshow(false);
          setComponenttoshow2(true);

        }            
        if ( ls.get("_nodi") === '' ) {
          setComponenttoshow2(false);
        }      

      }}
    >
      
        Make Enquiry
      </p>
                              </div>
                            ) : null}

                            <div className="">
                              {submiting ? (
                                <p
                                  style={{
                                    textAlign: "center",
                                    color: "green",
                                  }}
                                >
                                  Submitting...
                                </p>
                              ) : (
                                <div className="parent-submit-quote-enquiry-msg">
                                  <div>
                                  <button
                                      type="submit"
                                      className="btn-quote-form"
                                      onClick={(e) => {
                                        setShowpopup(!showpopup);
                                        setshow("hide");
                                        if (
                                          ls.get("_nodi") ===
                                                       ''
                                        ) {
                                          setComponenttoshow(true);
                                          setComponenttoshow2(false);
                                        } else {
                                          setShowpopup(!showpopup);
                                          setComponenttoshow(false);
                                          setComponenttoshow2(false);
                                          setShowpopup2(!showpopup2);
                                        }

                                       if (ls.get('_nodi'  !== '')) {
                                        const decoded = jwt_decode(ls.get('_nodi'));
                                        const exp = decoded.exp;

                                        // // console.log(ls.get('_nodi'));
                                        //   console.log(jwt_decode(ls.get('_nodi')))
                                        //   console.log(new Date(decoded.exp * 1000), 'exp')
                                        //   console.log(new Date (decoded.auth_time * 1000), 'auth-time')

                                         if (exp < Date.now() / 1000) {
                                         console.log('expired-session')
                                         setComponenttoshow(true);
                                        }else{
                                          console.log('still in session')
                                          setComponenttoshow(false);
                                        }

                                       }


                                      }}
                                    >
                                      Submit quote
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="provider-history-div">
                <h2 className="provider-history">Provider History</h2>

                <table className="table_  table---mb tabledelivry">
                <tr>
                    <th>Quote amount</th>
                    <th>Logistic provider</th>
                    <th className="third-tr">Providers info</th>
                  </tr>


                  {view.quote_history?.map((detail, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {" "}
                          <Currency location={view?.delivery_location}/>
                          {numberWithCommas.format(detail.quote_amount)}
                        </td>
                        <td>{detail.fulfiller_id}</td>
                        <td>
                          <button
                            onClick={() => {
                              setFulfillerDetailToShow(detail.provider_info);

                              viewFulfiller(detail.provider_info);
                              // console.log(detail.provider_info);
                            }}
                            className="profile-btn"
                          >
                            <span>View profile</span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </table>

                {/* <table className="table_  table-tr-th  tabledelivry">
                  <tr>
                    <th>Quote amount</th>
                    <th>Logistic provider</th>
                    <th className="third-tr">Providers info</th>
                  </tr>
                  {view.quote_history?.map((detail, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {" "}
                          &#8358;
                          {numberWithCommas.format(detail.quote_amount)}
                        </td>
                        <td>{detail.fulfiller_id}</td>
                        <td>
                          <button
                            onClick={() => {
                              setFulfillerDetailToShow(detail.provider_info);

                              viewFulfiller(detail.provider_info);
                              // console.log(detail.provider_info);
                            }}
                            className="profile-btn"
                          >
                            <span>View profile</span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </table> */}
                {/* {console.log(view, "view")} */}

                <div className="a">
                  <div className="a1"></div>
                  <div className="a2">
                    <h2 className="logicstics-content">
                      Are you a logistics provider?
                    </h2>
                  </div>
                  {/* <div className="a2"><h2 className="logicstics-content">Are you a logistics provider?</h2></div>  */}

                  <div className="a3">
                    <button
                      onClick={() => history.push(deliver_with_us)}
                      className="signup-btn"
                    >
                      <span className="signup-btn-">Signup</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="footerover-ride">{/* <Footer /> */}</div>
            </div>

            {showHelp ? (
              <HomeHelp
                showDialog={showHelp}
                onClose={() => setshowHelp(false)}
              />
            ) : null}
  {componenttoshow ? (
              <DialogOutClick
                open={showpopup}
                handleClose={() => setShowpopup(!showpopup)}
              >
                
               

                 <QuoteSignInForm  onFinish={() => {
                    setShowpopup(!showpopup); 
                    setComponenttoshow2(true)
                    // setnavrefresh(true)
                    setComponenttoshow(false)
                  }} handleClose={() => setShowpopup(!showpopup)}  
                  
                  onClose={
                    ()=>{
                      setShowpopup(!showpopup);
                    }
                  }

                  /> 

              </DialogOutClick>
            ) : null}  
            {componenttoshow2  ? (
              <DialogOutClick
                open={showpopup2}
                handleClose={() => setShowpopup2(!showpopup2)}
              >
                <EnquiryForm
                  item={view}
                  onFinish={() => {
                    setShowpopup2(!showpopup2);
                    // setnavrefresh(true)
                  }}
                  closePopup={() => setShowpopup2(!showpopup2)}
                />
              </DialogOutClick>
            ) : null}


            
          </div>
        );
      })}
      <Footer />
    </div>
  );
}
