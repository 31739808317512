import React, { useState, useEffect, useContext } from "react";
import Layout from '../Layout';
import { useHistory, Link } from 'react-router-dom';

import "./Settings.css"
import userAvatar from "../../../../assets/userAvatar.jpeg"

import { SenderContext } from "../../../../context/SenderDashboard/SenderDashboardContext";
import { ConnContext } from "../../../../context/ConnContext";
import SecureLS from "secure-ls";
import axios from 'axios';
import imageCompression from 'browser-image-compression'
import SettingsMobile from "./SettingsMobile";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@material-ui/core";
import Swal from "sweetalert2";
import { PuffLoader } from 'react-spinners';
import GlobalizationLanguage from "../../../../components/GlobalizationLanguage";
export default function Settings() {
  const sendersettings_business=GlobalizationLanguage("/sendersettings/business")
  const sendersettings=GlobalizationLanguage("/sendersettings")
  const forgetpass=GlobalizationLanguage("/forgetpass")

  const { initialstate, UpdateData, alldata,  UpdateAlldata } = useContext(SenderContext);
  const endpoint = process.env.REACT_APP_API;
  const {visitingCountryCode} = useContext(ConnContext)
  const [loading, setloading] = useState(false)

  const ls = new SecureLS({isCompression: true,encodingType: "rabbit",encryptionSecret: "dm_dillivry",});

  const [active, setActive] = useState("personal");
  const history = useHistory();
  const [email, setemail] = useState("");
  const [personaName, setpersonaName] = useState("");
  const [userPassport, setuserPassport] = useState("")
  const [accountType, setaccountType] = useState("");

  const [updatingPassport, setUpdatingPassport] = useState(false)

  const[passclick , setpassclick ] =useState(false)



  const [image_file, setImage_file] = useState("")
  const [sendImage, setSendImage] = useState("")
  
  const [should_reload, setshould_reload] = useState('')

  useEffect(() => {
    setloading(true);
    console.log(alldata, "alldata")
    console.log(initialstate, "inistate")


    // const CheckLength =  alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.length : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.length
    // const InfoData = alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account) : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)
    // CheckLength === 1 ? console.log(InfoData[0]) : console.log(InfoData[1])

    //  console.log(InfoData.flat(), "Infodata", CheckLength, "checklength")
    // console.log(initialstate, "initialstate")
    // console.log(initialstate, "inistate", alldata)
    // console.log(initialstate.map(x => x.account).flat()[0])
    // if (initialstate !== undefined) {
      if(alldata?.status === 200){

        const CheckLength =  alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.length : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.length
        const InfoData = alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account) : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)
        CheckLength === 1 ? updatePage(InfoData[0]) : updatePage(InfoData[1])


        // updatePage(initialstate?.map(x => x?.account)?.flat()[0])
        setloading(false);
      }
    // }
    else{
      setloading(true);
      axios
      .get(`${endpoint}m-dillivry-sender-api`, {
        headers: {
          Authorization: ls.get("_utk"),
        },
      })
        .then((response) => {
        //  console.log(response, "apiii")
          if(response?.status === 200){
            // console.log(response?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat().map(x => x?.account)?.flat()[0])
            updatePage(response?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat()?.map(x => x?.account)?.flat()[0])
            UpdateAlldata(response)
            // UpdateData(response?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());
            // console.log(response)
            // console.log(response?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat()?.map(x => x?.account)?.flat())
            setloading(false);
          }
        })
        .catch((err)=>{
          setloading(false);
          console.log(err)
          setloading(false);
        })
    }
  }, [])




  useEffect(() => {
    if(should_reload === "should_reload"){
      setloading(true);
     
        // .post(`${process.env.REACT_APP_API}m-dillivry-search`, {
        //   search_criteria: "sender_data",
        //   user_id: ls.get("eid"),
        //   vcc: visitingCountryCode
        // })
        axios
        .get(`${endpoint}m-dillivry-sender-api`, {
          headers: {
            Authorization: ls.get("_utk"),
          },
        })
        .then((response) => {
          // console.log(response, "should-reloadddd")
          setloading(false);
          if(response.status === 200){
            // console.log(response, "should-reload")
            updatePage(response)
            UpdateAlldata(response)
            // UpdateData(response)
          }
        })
        .catch((err)=>{
          setloading(false);
          console.log(err)
        })
    }
    
  }, [should_reload])

  const updatePassport = (img)=>{
    // console.log(ls.get("eid"))
    setUpdatingPassport(true)
        axios.post(`${process.env.REACT_APP_API}sender-reg`,{
            profile_photo: img,
            sender_email: ls.get("eid"),
        })
        .then((res)=>{
          setUpdatingPassport(false)
            Swal.fire({
                icon: "success",
                text: "Profile photo uploaded",
                timer: 3000,
                showConfirmButton: false,
            })
            window.location.reload()
            setshould_reload("should_reload")
        })
        .catch((err)=>{
          console.log(err)
          setUpdatingPassport(false)
        })
  }

  const updatePage = (value)=>{
    //  console.log(value, "valueeee")
    // setemail(value?.data?.account?.account_info?.sender_email)
    // setpersonaName(value?.data?.account?.account_info?.sender_name)
    // setaccountType(value?.data?.account?.account_info?.role)
    // setuserPassport(value?.data?.account?.account_info?.profile_photo)
    setemail(value?.account_info?.user_id)
    setpersonaName(value?.account_info?.given_name)
    setaccountType(value?.account_info?.role)
    setuserPassport(value?.account_info?.profile_photo)
    
  }

  const topDataIndividual = {
    tabs: [
      {
        type: "personal",
        name: "Personal settings",
      },
     
      // {
      //   type: "security",
      //   name: "Security",
      // },
     
    ],
  };

  const topData = {
    tabs: [
      {
        type: "personal",
        name: "Personal settings",
      },
      {
        type: "business",
        name: "Business settings",
      },
      // {
      //   type: "security",
      //   name: "Security",
      // },
     
    ],
  };

  const filterItem = (type) =>{
    setActive(type)
    if (type === "personal"){
      history.push(sendersettings)
    }
    if( type === "business"){
      history.push(sendersettings_business)
    }
    // if( type === "security"){
    //   history.push("/sendersettings/security")
      
    // }

  }

  async function imageHandler(e) {
    const file = e.target.files[0]
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 512,
    }
    const output = await imageCompression(file, options)
    const dataa = await imageCompression.getDataUrlFromFile(output)
    setImage_file(dataa)
    const img = dataa.split(',');
    setSendImage(img[1]);

    updatePassport(img[1]);
  }

  return(
  <div className="mbl-dsk">
    <div className="dsktopscreen">
    {
        loading ?
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "none",
            zIndex: "300",
            position: "fixed",
            top: "70px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="success" />
          </Box>
        </div>
        :
        <></>
      }
  <Layout>
    <div className='sender-settings-wrap'>
      
    <ul  className="tab-flex" >
      {
        accountType === "business_admin" ?
        topData.tabs.map((x, i)=>{
          return (
              <li  onClick={()=>filterItem(x.type)} className={ active    === x.type ? 'activeuser': 'inactiveuser'}>
                {x.name}
              </li>
          )
        })
        :
        topDataIndividual.tabs.map((x, i)=>{
          return (
              <li  onClick={()=>filterItem(x.type)} className={ active    === x.type ? 'activeuser': 'inactiveuser'}>
                {x.name}
              </li>
          )
        })

      }
    </ul>
    <div className="personal-settings">

        <div className="personal-image">
        {
          updatingPassport ?
          <PuffLoader color='var(--main)' loading={updatingPassport} speedMultiplier={1} size={100} />
            :
            <>
              <img className="image_dp" src={image_file !== "" ? image_file : userPassport !== "" ? userPassport : userAvatar}  alt="userPassport" />
              {/* <div className="change-image"> */}
                <label htmlFor="image_files" style={{ color: 'var(--main)', textDecoration: 'underline', cursor: 'pointer' }}>Change</label>
                <input
                  style={{ display: "none" }}
                  id="image_files"
                  name="image_files"
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  onChange={imageHandler}
                />
              {/* </div> */}
            </>
          }
        </div>
        
        <div className="personal-info" >
            <div className="personal-input-field-wrap">
              <div className="personal-input-field">
                <input type="text" disabled value={personaName} />
                <input type="text" disabled value={email} />
              </div>
            </div>

            <div className="change-password">
              <div className={passclick ? "passnone":"changebox" }>
                  <p onClick={()=>history.push(forgetpass)}>Change password</p>
                
              </div>
              
              <input type="password"  className={passclick ? "password-change" :"passnone" }/>
            </div>
        
            {/* <div className="update-info-btn">
              <button className="cancel-update">Cancel</button>

              <button 
                className={image_file === "" ? "accept-btn" : "save-update"}
                  onClick={()=>image_file === "" ? console.log('hi') : updatePassport() }
               >{updatingPassport ? "Loading..." : "Save"}</button>
            </div> */}
        </div>

      </div>

      </div>
   </Layout>
   </div>
   <div className="mobilescreen">
     <SettingsMobile
        accountType={accountType}
        image_file={image_file}
        userPassport={userPassport}
        imageHandler={imageHandler}
        personaName={personaName}
        email={email}
        passclick={passclick}
        updatingPassport={updatingPassport}
        history={history}
     />
     </div>

  </div>
  );
}
