/* eslint-disable import/no-anonymous-default-export */
export default {
    GET_DEFAULT_SEARCH_DATA: 'GET_DEFAULT_SEARCH_DATA',
    ASSIGN_AGENT: "ASSIGN_AGENT",
    REASSIGN_AGENT: "REASSIGN_AGENT",
    REASSIGN_PICKEDUP: "REASSIGN_PICKEDUP",
    REASSIGN_OUT: "REASSIGN_OUT",
    REASSIGN_MISSED: "REASSIGN_MISSED",
    UPDATE_DM_MSG: "UPDATE_DM_MSG",
    UPDATE_MSG: "UPDATE_MSG",
    UPDATE_MEANS: "UPDATE_MEANS",
    UPDATE_LOGO: "UPDATE_LOGO",
    UPDATE_BALC_WITHDRAW: "UPDATE_BALC_WITHDRAW",
    dd:'dd'
  };