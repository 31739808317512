import './RegWelcome.css'
import Dillivry from '../../assets/Dillivry.png'
import Thumb from '../../assets/Thumb.png'
import RegBtn from './RegBtn'
import {useHistory} from 'react-router-dom'
import SecureLS from 'secure-ls';

function RegWelcome() {
    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

    const history = useHistory();
    return (
        <div className="welcomeReg">
            <div>
                <img src={Dillivry} alt="dillivry" height='30px'/>
            </div>
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:'10px'}}>
                <img src={Thumb} alt="done" height='120px' />
                <div style={{fontSize:'18px', fontWeight:'600'}}>Welcome to Dillivry</div>
            </div>
            <div>
                <RegBtn
                    type='button'
                    txt='Login'
                    onClick={()=>{
                        history.push('/login'); 
                        ls.remove('_tyf_')
                    }}
                />
            </div>
        </div>
    )
}

export default RegWelcome
