import { GOOGLE_MAP_URL } from './contant'

import MapContainer2 from './MapContainer2'

const GoogleMapWidget2 = (props) => (
<MapContainer2
      googleMapURL={GOOGLE_MAP_URL}
      loadingElement={<div style={{height:"100%"}}  />}
      containerElement={<div style={{height:"100%"}}  />}
      mapElement={<div style={{height:"100%"}}  />}
      {...props}
    />
)
export default GoogleMapWidget2
