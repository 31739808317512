import React,{useState,useEffect, useContext} from 'react'
import { useForm, Controller } from "react-hook-form";
import {  FormControl,Select,MenuItem, Grid, InputLabel } from '@mui/material';
import InputField from '../../../components/InputField';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DialogOTP_Pop from '../../../components/DialogOTP_Pop';
import OtpInput from "react-otp-input";
import Otp from '../../../assets/Otp.svg'
import Thumb from '../../../assets/Thumb.png'
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import axios from 'axios';
import PuffLoader from 'react-spinners/PuffLoader';
import { ConnContext } from "../../../context/ConnContext";
import Swal from 'sweetalert2';
import SecureLS from 'secure-ls';
import PhoneNumber from '../../../components/PhoneNumber';
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { useHistory, useLocation, Link } from 'react-router-dom';
import SnackbarUser from '../../../components/SnackBarUser';
import PrivacyDialog from '../../../components/PrivacyDialog';
import Country_dropdown from '../../../components/Country_dropdown';
import OTPCountDown from '../../../components/Reg_components/OTPCountDown';
const endpoint = process.env.REACT_APP_API;

export default function SenderRegForm({business, gotoLogin, goback}) {
    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
    
    const history = useHistory();

    const location = useLocation();
    
    const [isDisabled, setIsDisabled] = useState(false);
    const [isValid, setIsValid] = useState(false);
  const [promocodesmap, setpromocodemap] = useState([])
   

    const {visitingCountryCode} = useContext(ConnContext)
    const [userreferralCode, setUserreferralCode] = useState("")
    const [isreferralerror, setisreferralerror] = useState("")

    const [showOtpPopup, setshowOtpPopup] = useState(false);
    const [showLoginPopup, setshowLoginPopup] = useState(false);
    const [otp, setotp] = useState('')
    const [invalidOtp, setInvalidOtp] = useState(false);
    const [showpassword, setshowpassword] = useState(false)
    const [showcpassword, setshowcpassword] = useState(false)
    const [loading, setloading] = useState(false)
    const [loadingOtp, setloadingOtp] = useState(false)
    const [phone_no, setPhone_no] = useState(undefined)
    const [policyShow, setPolicyShow] = useState(false);
    const [pageToShow, setpageToShow] = useState("");
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [preferredCountry, setPreferredCountry] = useState("")

    const [accout_type, setaccout_type] = useState("")

    const [checked, setChecked] = useState(false)
    const [acctErroor, setAcctErroor] = useState(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}m-dillivry-promo-code`).then((res) => {
          if (res?.status === 200) {
          setpromocodemap(res?.data?.data?.map(x => x?.promo_code))
          }
        }).catch((err) => {
          console.log(err)
        })
      }, []);
    
      const checkRefralCode =(value)=>{
        setUserreferralCode(value)
         if(value.length===6){
        axios
        .get(`${endpoint}m-dillivry-promo-code?referral_code=${value}`)
        .then((response) => {
          setisreferralerror(response.data.message)
        })
        .catch((error)=>{
          console.log("error",error)
        })
      }
      else{
        if(value.length>0 && value.length<6){
        setisreferralerror("Referral code can not be less than six digits");
      }
      else{
        setisreferralerror("")
      }
      }
       }
    const onKeyUpToCheckReferal = (e) => {
        setUserreferralCode(e)
    
        userreferralCode.length <= 6 ? setIsDisabled(true) : setIsDisabled(false)
        
        if(userreferralCode.length === 6 && !promocodesmap.includes(userreferralCode) ? "Invalid code" : userreferralCode.length < 6 && userreferralCode.length > 1 && !promocodesmap.includes(userreferralCode) ? "Referral code can not be less than six digits" : null){
            setIsValid(false)
        }
        else{
            setIsValid(true)
        }
      }

    const schema = yup.object().shape({
        full_name: yup.string().required("Fullname is required").min(3, "Can't be lesser than 3 digits").matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
        // email: yup.string().email("Valid email is required").required("Email is required").required("Email is required"),
        email: yup
        .string()
        .matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, "Valid email is required")
        .required("Email is required"),
        password: yup.string().required("Password is required").min(6, "Can't be lesser than 6 digits"),
        cpassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Password mismatch!")
        .required("Required"),
    })
    
    const schemaBusiness = yup.object().shape({
        full_name: yup.string().required("Fullname is required").min(3, "Can't be lesser than 3 digits").matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
        // email: yup.string().email("Valid email is required").required("Email is required"),
        email: yup.string().matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, "Valid email is required").required("Email is required"),
        businessName: yup.string().required("Business name is required").min(3, "Can't be lesser than 3 digits").max(60, "Must be exactly 60 digits"),
        password: yup.string().required("Password is required").min(6, "Can't be lesser than 6 digits"),
        cpassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Password mismatch!")
        .required("Required"),
    })

    const { setValue, control,handleSubmit, formState: { errors } } = useForm({
        mode: 'all',
        resolver: yupResolver(accout_type === "business" ? schemaBusiness : schema),
    });

    const present = ['ng', 'gb']

    const submitRegForm = (data)=>{

        if(!present.includes(preferredCountry.toLowerCase())){
            Swal.fire({
              icon: "info",
              text: "Not currently live in the selected country",
              timer: 3000,
              showConfirmButton: false,
              iconColor: "var(--main)",
            });
          }
        else if (phone_no === undefined || !isValidPhoneNumber(phone_no)){
            // console.log(phone_no)
        }
        else{
            if(accout_type === ""){
                setAcctErroor(true)
            }
            else{
            setloading(true)
            let dataToSend = {};

            if (accout_type === "business"){
                dataToSend = {
                    country_code: preferredCountry,
                    account_type: "business",
                    business_name: data.businessName,
                    sender_email: data.email.toLowerCase(),
                    sender_phone_no  : parsePhoneNumber(phone_no).number,
                    sender_name : data.full_name,
                    password : btoa(data.password),
                    referral_code: isValid ? userreferralCode : ""
                }
            }
            else{
                dataToSend = {
                    country_code: preferredCountry,
                    account_type: "individual",
                    sender_email: data.email.toLowerCase(),
                    sender_phone_no  : parsePhoneNumber(phone_no).number,
                    sender_name : data.full_name,
                    password : btoa(data.password),
                    referral_code: isValid ? userreferralCode : ""
                }
            }
            ls.set('_bisn', data.email)
            axios.put(`${process.env.REACT_APP_API}sender-reg`,
                dataToSend
            )
            .then((res)=>{
                if(res.status === 200){
                    // console.log(res);
                    setloading(false)
                    setshowOtpPopup(true)
                }
                if(res.status === 201){
                    setloading(false)
                    Swal.fire({
                        icon: "info",
                        text: "User already exist, Try login",
                        timer: 3000,
                        showConfirmButton: false,
                    });
                }
            })
            .catch((err)=>{
                console.log(err)
                setloading(false)
                if (err?.response?.status === 400) {
                    Swal.fire({
                      icon: "info",
                      text: "We are not yet live in this country, kindly subscribe to our news letter to let you know when we will be live in this country",
                      //  timer: 2500,
                      confirmButtonColor: "var(--main)",
                    })
                }
                if (err?.response?.status === 402) {
                    Swal.fire({
                      icon: "info",
                      text: "We are not yet live in this country, kindly subscribe to our news letter to let you know when we will be live in this country",
                      //  timer: 2500,
                      confirmButtonColor: "var(--main)",
                    })
                }
            })
            }
        }
    }

    const confirmOTP = ()=>{
        setloadingOtp(true)
        axios.post(`${process.env.REACT_APP_API}sender-reg`,{
            sender_email: ls.get('_bisn'),
            security_code: otp,
        })
        .then((res)=>{
            setloadingOtp(false)
            setshowOtpPopup(!showOtpPopup); 
            setshowLoginPopup(true)   
        })
        .catch((err)=>{
            setloadingOtp(false)
            setInvalidOtp(true)
        })
    }

    useEffect(() => {
        if(location?.state?.from === "Home_page"){
            setValue("email", location?.state?.email, {
                shouldValidate: true,
                shouldDirty: true
            });
            // setPhone_no( "+" + getCountryCallingCode(visitingCountryCode ? visitingCountryCode : "NG") + location?.state?.phone_no?.slice(1));
            setPhone_no( location?.state?.phone_no);

            setTimeout(() => {
                setShowSnackBar(true)
            }, 3000);
        }
        else if(location?.state?.from === "Home_page_OTP_required"){
            setshowOtpPopup(true)
            setPhone_no(location?.state?.phone_no)
        }
    }, [visitingCountryCode])
    
    return (
        <div>
            <form style={{width:'100%'}} onSubmit={handleSubmit(submitRegForm)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                         <FormControl size='small' fullWidth variant="outlined">
                             {/* <div style={{ fontSize: "16px",fontWeight:'500', marginBottom:'8px'}}>Account type</div> */}
                             <InputLabel id="account_type">Account type</InputLabel>
                            <Select
                                labelId="account_type"
                                id="account_type"
                                value={accout_type}
                                error = {acctErroor}
                                onChange={(e) => {setaccout_type(e.target.value); setAcctErroor(false)}}
                                label="Account type"
                                >
                                <MenuItem value="personal">Personal</MenuItem>
                                <MenuItem value="business">Business</MenuItem>
                            </Select>
                            {/* <div className="err">{errors.full_name?.message}</div> */}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                             name="full_name"
                             control={control}
                            defaultValue=""
                            render={({ field }) => <FormControl style={{width:'100%'}} variant="outlined">
                                <InputField
                                label="Full name"
                                    {...field}
                                    error={errors.full_name}
                                    id="full_name"
                                    type="text"
                                    labelWidth={60}
                                />
                                <div className="err">{errors.full_name?.message}</div>
                            </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <FormControl style={{width:'100%'}} variant="outlined">
                                <InputField
                                label="Email"
                                    {...field}
                                    error={errors.email}
                                    id="email"
                                    type="text"
                                    labelWidth={35}
                                />
                                <div className="err">{errors.email?.message}</div>
                            </FormControl>
                            }
                        />
                    </Grid>
                    {
                        accout_type === "business" ? 
                        <Grid item xs={12}>
                            <Controller
                                name="businessName"
                                control={control}
                                defaultValue=""
                                render={({ field }) => <FormControl style={{width:'100%'}} variant="outlined">
                                    <InputField
                                    label="Business name"
                                        {...field}
                                        error={errors.businessName}
                                        id="businessName"
                                        type="text"
                                        labelWidth={95}
                                    />
                                    <div className="err">{errors.businessName?.message}</div>
                                </FormControl>
                                }
                            />
                        </Grid>
                        :
                        null
                    }
                    <Grid item xs={12} style={{zIndex:2}}>
                        <Country_dropdown setDataValue={setPreferredCountry}/>
                    </Grid>
                    <Grid item xs={12}>
                        <PhoneNumber value={phone_no} setValue={setPhone_no} />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="referral"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <> <FormControl style={{width:'100%'}} variant="outlined">
                                <InputField
                                label="Referral code"
                                    error={userreferralCode.length > 0 && !promocodesmap.includes(userreferralCode)}
                                    {...field}
                                    id="referral"
                                    type="number" 
                                    onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
                                    onKeyUp={onKeyUpToCheckReferal(userreferralCode)} txt="Referral code" size="small" placeholder="Referral code (Optional)"  name="referral" htmlFor="referral" value={userreferralCode}
                                    // onChange={(e) => setUserreferralCode(e?.target?.value)}
                                    onChange={(e) => checkRefralCode(e?.target?.value)}

                                />
                            </FormControl>
                            <div className="err">
                            {isreferralerror}
                            {/* {
                              userreferralCode.length === 6 && !promocodesmap.includes(userreferralCode) ? "Invalid code" : userreferralCode.length < 6 && userreferralCode.length > 1 && !promocodesmap.includes(userreferralCode) ? "Referral code can not be less than six digits" : null
                            } */}
                          </div>
                          </>
                            }
                            
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <FormControl style={{width:'100%'}} variant="outlined">
                                <InputField
                                label="Password"
                                    {...field}
                                    error={errors.password}
                                    id="password"
                                    type={showpassword ? "text" : "password"}
                                    labelWidth={60}
                                    endIcon={
                                        showpassword ? (
                                            <VisibilityOffIcon
                                              style={{
                                                color: "grey",
                                                fontSize: "25px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => setshowpassword(!showpassword)}
                                            />
                                        ) : (
                                            <VisibilityIcon
                                              style={{
                                                color: "grey",
                                                fontSize: "25px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => setshowpassword(!showpassword)}
                                            />
                                        )
                                      }
                                />
                                <div className="err">{errors.password?.message}</div>
                            </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="cpassword"
                            control={control}
                            defaultValue=""
                            render={({ field }) => <FormControl style={{width:'100%'}} variant="outlined">
                                <InputField
                                    label="Confirm password"
                                    {...field}
                                    error={errors.cpassword}
                                    id="cpassword"
                                    type={showcpassword ? "text" : "password"}
                                    endIcon={
                                        showcpassword ? (
                                            <VisibilityOffIcon
                                              style={{
                                                color: "grey",
                                                fontSize: "25px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => setshowcpassword(!showcpassword)}
                                            />
                                        ) : (
                                            <VisibilityIcon
                                              style={{
                                                color: "grey",
                                                fontSize: "25px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => setshowcpassword(!showcpassword)}
                                            />
                                        )
                                      }
                                />
                                <div className="err">{errors.cpassword?.message}</div>
                            </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <>
                            <label className="check_agree" >
                                <input type="checkbox" onClick={()=>setChecked(!checked)}/>
                                <span className="checkmarker"></span>
                            </label>
                            <div className='proceeding'>By proceeding I agree to Dillivry <span onClick={() => { setPolicyShow(true); setpageToShow("terms");}}>Terms of Use</span> and acknowledge that I have read the <span onClick={() => { setPolicyShow(true); setpageToShow("privacy");}}>Privacy Policy.</span></div>
                        </>
                    </Grid>
                    
                    <Grid item xs={12}>
                        <button type={loading ? 'button': checked === false || isreferralerror!==""? 'button' : 'submit'} style={{width:"100%",textAlign:'center', userSelect:'none',cursor:'pointer', padding:'10px', backgroundColor: checked && isreferralerror===""? 'var(--main)' : "#B0AFB0" , color:'white', fontWeight:'600',fontSize:'15px', borderRadius:'3px', border:0}}>
                            {
                                loading ? 
                                <span className="btnlabe">
                                    <PuffLoader color='white' loading={loading}  speedMultiplier={1} size={24}/>
                                </span>
                                :
                                <span>Create account </span>
                            }
                            
                            
                        </button>
                    </Grid>
                    <Grid item xs={12}>
                        <div className='haveAcct'>Already have an account? <Link onClick={gotoLogin}>Login</Link></div>
                    </Grid>
                </Grid>

            </form>

            <DialogOTP_Pop openPopup={showOtpPopup}>
                <div className='otpContainer'>
                    <img className='otpImag' src={Otp} alt="sent" />
                    <div style={{textAlign:'center', fontWeight:'400', fontSize:'14px'}}>
                        {
                            location?.state?.from === "Home_page_OTP_required" ? 
                            `We discover you are not new to Dillvry as your email already exist, As a security measure, a one-off code was sent to you via Email @ ${ls.get('_bisn')} and SMS to ${phone_no}`
                            :
                            `As a security measure, a one-off code was sent to you via Email @ ${ls.get('_bisn')} and SMS to ${phone_no}`
                        }
                       
                    </div>
                    <div style={{textAlign:'center', fontWeight:'400', fontSize:'16px'}}>
                        Please enter the one-off code below
                    </div>
                    <OtpInput
                        value={otp}
                        onChange={otp => {setotp(otp); setInvalidOtp(false)}}
                        numInputs={4}
                        shouldAutoFocus={true}
                        isInputNum={true}
                        separator={
                            <span>
                                <strong></strong>
                            </span>
                        }
                        inputStyle={{width: "2.5rem",height: "2.5rem",margin: "0 0.3rem",fontSize: "20px",borderRadius: 3,border: "2px solid var(--main)",outline: "none"
                        }}
                    />
                    {
                        invalidOtp ?
                        <div style={{textAlign:'center',color:'red', fontWeight:'400', fontSize:'14px'}}>
                            Invalid OTP
                        </div>
                        :
                        null
                    }
                    <div
              style={{
                textAlign: "center",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              <OTPCountDown initialSeconds={45} type="sender" user_id={ls.get("_bisn")}/>
            </div>
                    {/* <div style={{textAlign:'center', fontWeight:'400', fontSize:'14px'}}>
                        Resend code in 00:46
                    </div> */}
                    
                    {
                        otp.length === 4 ?
                            <button type='button' onClick={confirmOTP} className="otpConfirmBtn">
                               {
                                loadingOtp ? 
                                <span className="btnlabe">
                                    <PuffLoader color='white' loading={loadingOtp}  speedMultiplier={1} size={20}/>
                                </span>
                                :
                                <span>Continue</span> 
                               }
                            </button>
                            :
                            null
                    }
                    
                </div>
            </DialogOTP_Pop>

            <DialogOTP_Pop openPopup={showLoginPopup}>
                <div style={{ width: '330px', height:'600px', alignItems: 'center',justifyContent:'center', color:"#444343", display: 'flex', flexDirection: 'column', gap: '30px', padding:'20px' }}>
                    <img src={Thumb} alt="sent" height="150px"/>
                    <div style={{textAlign:'center', fontWeight:'500', fontSize:'20px'}}>
                        Verification successful
                    </div>
                    <div style={{height:'60px'}}></div>
                    <button type='button'  onClick={()=>{setshowLoginPopup(!showLoginPopup); gotoLogin()}} style={{width:"calc(100% - 60px)",position:'absolute', bottom:'60px', right:'30px', textAlign:'center', userSelect:'none',cursor:'pointer', padding:'10px', backgroundColor:'var(--main)', color:'white', borderRadius:'3px', border:0}}>
                        Login
                    </button>
                </div>
            </DialogOTP_Pop>
            <PrivacyDialog
                open={policyShow}
                onClick={() => setPolicyShow(false)}
                pageToShow={pageToShow}
                title={
                pageToShow === "terms" ? "Terms and condition" : "Privacy policy"
                }
            ></PrivacyDialog>

            {/* <SnackbarUser
                open={showSnackBar}
                setHandleOpen={setShowSnackBar}
                severity= "success"
                message="Welcome to Dillivry"
            /> */}
        </div>
    )
}
const Timer = (props) => {
    const {initialMinute = 0,initialSeconds = 0} = props;
    const [ minutes, setMinutes ] = useState(initialMinute);
    const [seconds, setSeconds ] =  useState(initialSeconds);
    useEffect(()=>{
    let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
          };
    });

    return (
        minutes === 0 && seconds === 0 
            ? <span style={{color:"#50B14A"}} onClick={()=>setSeconds("45")}>Resend code again</span>
            :  `Resend code in ${minutes}:${seconds < 10 ?  `0${seconds}` : seconds}` 
    )
}