import React, { useState, useEffect , useContext} from 'react'
import { MdNavigateNext, MdCancel } from 'react-icons/md'
import { IoIosPricetags } from 'react-icons/io'
import { SiAdguard } from 'react-icons/si'
import { BiStar } from 'react-icons/bi'
import { AiFillQuestionCircle } from 'react-icons/ai'
import { Radio, RadioGroup, CssBaseline } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import SmallBtn from '../../../components/SmallBtn'
// import {Select,Button,MenuItem, FormControl,FormControlLabel, InputLabel} from '@material-ui/core';

// import SmallBtn from '../../../components/SmallBtn'
import './AcceptQuote.css';
import PaystackWidget from '../../../components/PaystackWidget';
import axios from 'axios';
import PuffLoader from 'react-spinners/PuffLoader';
import Swal from 'sweetalert2';
import DialogPop from '../../../components/DialogPop';
import { Select, MenuItem, Button, FormControl, FormControlLabel, InputLabel, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import mark from "../../../assets/mark.svg";
import { withStyles } from '@material-ui/core/styles';

import { useHistory } from 'react-router-dom'
import { yellow } from '@material-ui/core/colors'
import DialogOutClick from '../../../components/DialogOutClick'
import SecureLS from 'secure-ls';
import PastDelivery from './PastDelivery'
import { ConnContext } from '../../../context/ConnContext'
import GlobalizationLanguage from '../../../components/GlobalizationLanguage'
const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles(() => ({
    formControl: {
        width: `100%`,
    },
}));



function AcceptQuote({ quoteInfos, deliveryDetail, handleChange }) {
    const {visitingCountryCode} = useContext(ConnContext)
    var viewQuote = GlobalizationLanguage("/viewQuote")
    var timeout = GlobalizationLanguage("/timeout")

    const ls = new SecureLS({ isCompression: true, encodingType: 'rabbit', encryptionSecret: 'dm_dillivry' });

    useEffect(() => {
        setquoteInfo(quoteInfos)
        setuserEmail(ls.get('eid'));
        setdelivery_id(deliveryDetail.delivery_id);
        setdelivery_Location(deliveryDetail?.vcc);
    }, [])

    const useStyles = makeStyles((theme) => ({
        formControl: {
            marginBottom: theme.spacing(3),
            minWidth: `100%`,
        }
    }));
    const [rejectstage, setrejectstage] = useState(false)
    const classes = useStyles();
    const [acceptType, setAcceptType] = useState('')
    const [comment, setComment] = useState('')
    const [clicked, setclicked] = useState(0)
    const [quoteInfo, setquoteInfo] = useState([]);
    const [Showmsg, setShowmsg] = useState(false);
    const [userEmail, setuserEmail] = useState('');
    const [delivery_id, setdelivery_id] = useState('')
    const [delivery_location, setdelivery_Location] = useState('')
    const [fulfiller_id, setfulfiller_id] = useState('')
    const [reply, setReply] = useState("");
    const [showReject, setshowReject] = useState(false);
    const [rejecting, setrejecting] = useState(false)
    const [showAccept, setshowAccept] = useState(false)
    const [showdrop, setShowdrop] = useState(false)
    const [showcate, setShowcate] = useState(false)

    const handleClick = () => {
        setShowdrop(showdrop => !showdrop)
        if (showcate) {
            setShowcate(false)
        }
        // handleOverviewClick();
        // setShowcate(false)
    }


    const [isloading, setisloading] = useState(false)
    const [isRequired, setisRequired] = useState(false)

    const [rejectList, setrejectList] = useState([])

    // const handleChange = () => { setviewState(false); setActiveState(true) }
    // const handleGoBack = () => { seteditState(false); setActiveState(true) }

    const [fulfilllerToAccept, setfulfilllerToAccept] = useState('')
    const [amountToAccept, setamountToAccept] = useState('')

    const history = useHistory()

    const [submitting, setSubmitting] = useState(false)

    const endpoint = process.env.REACT_APP_API

    const authAxios = axios.create({
        baseURL: `${endpoint}`,
        headers: {
            Authorization: ls.get('_utk')
        }
    })


    const replyMessage = () => {
        if (reply === '') {
            setisRequired(true);
        }
        else {
            setisRequired(false)
            setSubmitting(true)
            authAxios.post(`m-dillivry-quote-manager?delivery_location=${delivery_location}`, {
                action_type: "quote_msg",
                delivery_id: delivery_id,
                fulfiller_id: fulfiller_id,
                msg: reply,
            }, {timeout: 240000})
                .then((response) => {
                    setSubmitting(false)
                    setReply('');
                    if (response.status === 200) {

                        Swal.fire({
                            icon: 'success',
                            text: 'Message sent',
                            timer: 2500,
                            showConfirmButton: false,
                        })
                    }
                })
                .catch((err) => {
                    if (err.code === "ECONNABORTED") {
                        console.log('internal-server-error-timeout')
                        history.push(timeout)
                        // window.location.reload()
                      }

                      if (err?.response?.status === 403) {
                        Swal.fire({
                          icon: "warning",
                          title: "Session!",
                          text: "Session expired login again!",
                          allowOutsideClick: false,
                          confirmButtonColor: "var(--main)",
                        }).then((result) => {
                          if (result.value) {
                            ls.removeAll();
                            history.push(`/login`);
                          }
                        });
                      }
                      else{
                        Swal.fire({
                            icon: 'info',
                            text: 'Ops! Message not sent, try again later',
                            timer: 4000,
                            showConfirmButton: false,
                        })
                      }
                    setSubmitting(false)
                    setReply('');
                })

        }
    }

    const numberWithCommas = new Intl.NumberFormat("en-GB")

    const rejectQuote = async (e) => {
        e.preventDefault()
        setrejecting(true)
        authAxios.post(`m-dillivry-quote-manager?delivery_location=${delivery_location}`, {
            action_type: "reject",
            delivery_id: delivery_id,
            user_id: ls.get('eid'),
            fulfiller_id: fulfiller_id,
            comment: comment,
        }, {timeout: 240000})
            .then((response) => {
                setshowReject(false)
                setrejecting(false)
                setrejectList([...rejectList, fulfiller_id])
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'info',
                        text: 'Quote rejected!',
                        timer: 2500,
                        showConfirmButton: false,
                    })
                    setrejectstage(true)
                }
            })
            .catch((err) => {
                if (err.code === "ECONNABORTED") {
                    console.log('internal-server-error-timeout')
                    history.push(timeout)
                    // window.location.reload()
                  }
                if (err?.response?.status === 403) {
                    Swal.fire({
                        icon: "warning",
                        title: "Session!",
                        text: "Session expired login again!",
                        allowOutsideClick: false,
                        confirmButtonColor: "var(--main)",
                    }).then((result) => {
                        if (result.value) {
                        ls.removeAll();
                        history.push(`/login`);
                        }
                    });
                }
                else{
                    Swal.fire({
                        icon: 'error',
                        text: 'error!',
                        timer: 2000,
                        showConfirmButton: false,
                    })
                }
                setshowReject(false)
                setrejecting(false)
            });
    }
    const refresh = () => {
        window.location.reload();
    }

    const payOnDelivery = () => {
        setisloading(true)
        authAxios.post(`m-dillivry-quote-manager?delivery_location=${delivery_location}`, {
            action_type: "accept",
            payment_type: 'on_delivery',
            delivery_id: delivery_id,
            fulfiller_id: fulfilllerToAccept,
            quoted_amount: amountToAccept,
            encrypted_token: btoa(btoa(ls.get('_utk')))
        }, {timeout: 240000})
            .then((response) => {
                setshowAccept(false)
                Swal.fire({
                    icon: 'success',
                    text: 'Quote Accepted, Payment on delivery',
                    allowOutsideClick: false,
                    confirmButtonColor: 'var(--main)',
                })
                .then((result)=>{
                    if(result.value){
                        ls.remove("item");
                        ls.remove("delivery_id")
                        history.push(viewQuote)
                        refresh();
                        setisloading(false) 
                    }
                })
                
            })
            .catch((err) => {
                if (err?.response?.status === 403) {
                    Swal.fire({
                      icon: "warning",
                      title: "Session!",
                      text: "Session expired login again!",
                      allowOutsideClick: false,
                      confirmButtonColor: "var(--main)",
                    }).then((result) => {
                      if (result.value) {
                        ls.removeAll();
                        history.push(`/login`);
                      }
                    });
                  }
                 console.log(err)
                setshowAccept(false)
                setisloading(false)
            })
    }

    return (


        <div style={{ display: "flex", width: '100%', gap: '20px', padding: '0px 100px' }}>
            <div style={{ width: '100%' }}>
                <div style={{ borderRadius: "4px", width: "135px", marginBottom: "10px", marginTop: "10px" }}>
                    <SmallBtn

                        label='Back'
                        onClick={handleChange}
                    />

                </div>
                <Grid container>
                    <div className="acceptQuote">
                        <div className="grid-container">

                            <div className='gridHead'>

                                <div>Quote Amount </div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            {
                                quoteInfo.map((item, index) => {
                                    return <div key={index}>
                                        <div className='gridBody'>
                                            <div style={{ paddingLeft: "15px" }} className="Qamt">&#8358;{numberWithCommas.format(item.quote_amount)}</div>
                                            <div className="msgBTN"
                                                onClick={() => { setShowmsg(!Showmsg); setclicked(index); setfulfiller_id(item.fulfiller_id) }}
                                            >
                                                <span style={{ width: "100px" }}>View Messages</span>
                                                <span className='msgCount'>{item.message.length}</span>
                                            </div>


                                            {
                                                item.quote_status.substring(0, 8) === 'rejected' || rejectList.includes(item.fulfiller_id) ?

                                                    <p style={{ color: "#989898", marginLeft: "30px", fontSize: "12px" }}> Declined <AiFillQuestionCircle style={{ color: "#EB996E" }} /> </p>

                                                    :
                                                    <>

                                                        <div className="acceptBTN" style={{ width: "60%", height: "23px", marginLeft: "20px" }}
                                                            onClick={() => { setshowAccept(true); setamountToAccept(item.quote_amount); setfulfilllerToAccept(item.fulfiller_id) }}>

                                                            <span>Accept  </span> <div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            onClick={() => { setshowReject(true); setfulfiller_id(item.fulfiller_id); }}>
                                                            <span>
                                                                <span className="acceptBTN" style={{ backgroundColor: '#A92003', width: "60%", height: "23px", marginLeft: "0px" }} > Reject </span>
                                                            </span>

                                                        </div>

                                                    </>

                                            }
                                        </div>
                                        {

                                            Showmsg && index === clicked ?
                                                <div className='meesegrid'>
                                                    <div className="ask">

                                                        <div className="asked"

                                                            onClick={() => console.log(item)}>Ask {item.fulfiller_id} a Question</div>
                                                        <textarea name="" id="" value={reply} onChange={(e) => { setReply(e.target.value); setisRequired(false) }} placeholder='Type the questions you want to asked here' style={{ width: '98%', backgroundColor: "white", border: isRequired ? "1px solid red" : "1px solid #C4C4C4", height: "100px", marginLeft: "10px", marginRight: "10px" }} rows="4" required />
                                                        {/* <input type="submit"/> */}

                                                        <div className="acceptBTN" style={{ width: '110px', height: "22px" }} onClick={replyMessage}>
                                                            {
                                                                submitting ?
                                                                    <PuffLoader color='white' loading={submitting} speedMultiplier={1} size={16} />
                                                                    :
                                                                    <>
                                                                        <span> Send message </span>
                                                                        <div>

                                                                        </div>
                                                                    </>

                                                            }
                                                        </div>


                                                        <div>

                                                        </div>
                                                    </div>

                                                    {
                                                        item.message.length === 0 ?
                                                            null
                                                            :

                                                            item.message.map((mssg, index) => {
                                                                return <div className="wrote" style={{ borderLeft: "none", borderRight: "none" }}>
                                                                    <span style={{ color: "#50B14A" }}>{item.fulfiller_id} </span> wrote on {mssg.time_stamp.substring(0, 10)} at {item.message[0].time_stamp.substring(11, 4)}
                                                                    <div className='mssg'>{mssg.msg}</div>

                                                                </div>
                                                            })


                                                    }

                                                </div>

                                                :
                                                null

                                        }
                                    </div>


                                })
                            }

                        </div>


                        {
                            quoteInfo.map((item, index) => {
                                return <div style={{ width: "300px", marginLeft: "-120px", border: "1px solid #EAEAEA" }} className='_mobile' key={index}>



                                    <div className="grid-container-mobile" >
                                        <div style={{ marginLeft: "40px", marginTop: "10px" }}>Quote Amount</div><div style={{ marginTop: "8px" }} className="Qamt">{numberWithCommas.format(item.quote_amount)}</div>
                                    </div>
                                    {
                                        item.quote_status.substring(0, 8) === 'rejected' ?
                                            <p style={{ color: "#989898", marginLeft: "30px", fontSize: "12px" }}> Declined <AiFillQuestionCircle style={{ color: "#EB996E" }} /> </p>

                                            :
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                <div className="acceptBTN"
                                                    onClick={() => { setshowAccept(true); setamountToAccept(item.quote_amount); setfulfilllerToAccept(item.fulfiller_id) }}
                                                    style={{ width: "60px", height: "20px" }}
                                                >
                                                    <span>Accept </span>
                                                    <div>
                                                    </div>
                                                </div>

                                                <div className="acceptBTN" style={{ backgroundColor: '#A92003', width: "60px", height: "20px" }}
                                                    onClick={() => { setshowReject(true); setfulfiller_id(item.fulfiller_id) }}
                                                >
                                                    <span>Reject </span>
                                                    <div>
                                                    </div>
                                                </div>

                                            </div>
                                    }

                                    <div className="msgBTN"
                                        onClick={() => { setShowmsg(!Showmsg); setclicked(index); setfulfiller_id(item.fulfiller_id) }}>
                                        <span>View Messages</span>
                                        <span className='msgCount'>{item.message.length}</span>
                                    </div>
                                    {
                                        Showmsg && index === clicked ?
                                            <div className="ask">
                                                <div className="asked" onClick={() => console.log(item)}>Ask {item.fulfiller_id} a question</div>
                                                <textarea name="" id="" value={reply} onChange={(e) => { setReply(e.target.value); setisRequired(false) }} placeholder='Type the questions you want to asked here' style={{ width: '100%', backgroundColor: "white", border: isRequired ? "1px solid red" : "1px solid #EAEAEA" }} rows="4"></textarea>

                                                <div className="acceptBTN" onClick={replyMessage} style={{ width: "110px", height: "20px", marginLeft: "80px" }}>
                                                    {
                                                        submitting ?
                                                            <PuffLoader color='white' loading={submitting} speedMultiplier={1} size={16} />
                                                            :
                                                            <><span>Send message </span></>
                                                    }
                                                </div>
                                                {
                                                    item.message.length === 0 ?
                                                        null
                                                        :
                                                        <div className="wrote" style={{ height: "100%", borderBottom: "1px solid #EAEAEA" }}>
                                                            {item.fulfiller_id} wrote on {item.message[0].time_stamp.substring(0, 10)} at {item.message[0].time_stamp.substring(11, 4)}

                                                            {
                                                                item.message.map((mssg, index) => { return <div key={index} className='mssg'>{mssg.msg}</div> })
                                                            }
                                                        </div>
                                                }

                                            </div>

                                            :
                                            null

                                    }
                                </div>
                            })}

                         <DialogPop
                            openPopup={showReject}
                            setopenPopup={setshowReject}

                         >
                            {comment === '' ? <p> Give reason</p> : null}

                            <FormControl className={classes.formControl} style={{ marginTop: '24px', marginBottom: '10px' }} variant="outlined">
                                <InputLabel id="Why_Rejecting"><span style={{ color: "var(--main)", marginRight: "10px" }}><MdCancel /></span>Reason</InputLabel>
                                <Select
                                    labelId="Why_Rejecting"
                                    label="Why_Rejecting"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                >
                                    <MenuItem value="price_too_high" onClick={() => setShowdrop(false)}><span style={{ color: "var(--main)", marginRight: "10px" }}><IoIosPricetags /></span>Price too high</MenuItem>
                                    <MenuItem value="no_insurance" onClick={() => setShowdrop(false)} ><span style={{ color: "var(--main)", marginRight: "10px" }}><SiAdguard /></span>No insurance</MenuItem>
                                    <MenuItem value="low_rating" onClick={() => setShowdrop(false)}><span style={{ color: "var(--main)", marginRight: "10px" }}><BiStar /></span>Low rating</MenuItem>
                                    <MenuItem value="" onClick={handleClick}><span style={{ color: "var(--main)", marginRight: "10px" }}><BiStar /></span>Others</MenuItem>
                                </Select>

                            </FormControl>
                            {
                                showdrop ?
                                    < div>
                                        <textarea 
                                        value={comment}
                                        onChange={(e)=>setComment(e.target.value)} 
                                        style={{ border: "1px solid #EAEAEA", marginTop: "10px", height: "100px", marginBottom: "10px" }}>

                                        </textarea>

                                    </div>
                                    :
                                    null
                            }
                            {comment === '' ?
                                <>
                                    <Button

                                        size="medium"
                                        variant="contained"
                                        type="submit"
                                        style={{
                                            backgroundColor: "var(--msgBorderline)",
                                            width: '150px',
                                            color: "white",
                                            borderRadius: '10px',
                                            textTransform: "none",
                                            marginBottom: '20px'
                                        }}

                                    >
                                        <span style={{}}> Reject </span>

                                    </Button>
                                </>
                                :
                                <>
                                    <Button

                                        size="medium"
                                        variant="contained"
                                        type="submit"
                                        style={{
                                            backgroundColor: "var(--main)",
                                            width: '150px',
                                            color: "white",
                                            borderRadius: '10px',
                                            textTransform: "none",
                                            marginBottom: '20px'
                                        }}
                                        onClick={rejectQuote}
                                    >
                                        {rejecting ?
                                            <PuffLoader color='white' loading={rejecting} speedMultiplier={1} size={20} />
                                            :
                                            // comment === '' ? 

                                            // <span className="btnlabel" > select an option </span>
                                            //  : 

                                            <span className="btnlabel" > Reject </span>
                                        }
                                    </Button>
                                </>


                            }
                             </DialogPop>

                            {/* <MenuItem value="price_too_high"><span style={{ color: "var(--main)", marginRight: "10px" }}><IoIosPricetags /></span>Price too high</MenuItem>
                            <MenuItem value="no_insurance"><span style={{ color: "var(--main)", marginRight: "10px" }}><SiAdguard /></span>No Insurance</MenuItem>
                            <MenuItem value="low_rating"><span style={{ color: "var(--main)", marginRight: "10px" }}><BiStar /></span>Low Rating</MenuItem>
                                </Select>
                            </FormControl>
                    <Button

                        size="medium"
                        variant="contained"
                        type="submit"
                        style={{
                            backgroundColor: "var(--main)",
                            width: '150px',
                            color: "white",
                            borderRadius: '10px',
                            textTransform: "none",
                            marginBottom: '20px'
                        }}
                        onClick={rejectQuote}
                    >
                        {rejecting ?
                            <PuffLoader color='white' loading={rejecting} speedMultiplier={1} size={20} />
                            :
                            <span className="btnlabel" > Reject </span>
                        }
                    </Button>
                        </DialogPop> */}

                <DialogOutClick
                    open={showAccept}
                    handleClose={() => { setshowAccept(!showAccept); setAcceptType('') }}>
                    <div style={{ padding: '20px' }}>
                        <p style={{ paddingBottom: "20px" }}> Select your payment option</p>

                        <Grid item xs={12}>
                            <RadioGroup aria-label="payment" name="row-radio-buttons-group">
                                <FormControlLabel value="instant" onClick={() => {
                                    setAcceptType('instant')
                                }} control={<GreenRadio />} label="Instant payment" />

                                <FormControlLabel value="pay_on_delivery" onClick={() => {
                                    setAcceptType('pay_on_delivery')
                                }} control={<GreenRadio />} label="Pay on delivery" />
                            </RadioGroup>
                        </Grid>
                        {
                            acceptType === 'instant' ?
                                <PaystackWidget
                                    amount={amountToAccept + '00'} //for paystack
                                    amount_paid={amountToAccept}
                                    fulfiller_id={fulfilllerToAccept}
                                    delivery_id={delivery_id}
                                    email={userEmail}
                                    closePopup={() => setshowAccept(false)}
                                />
                                :
                                acceptType === 'pay_on_delivery' ?
                                    isloading ?
                                        <div className="acceptBTN" style={{ width: "100px", height: "40px", marginLeft: "60px" }}>
                                            <span style={{ cursor: "pointer", borderRadius: "0px" }}
                                                onClick={payOnDelivery}

                                            >
                                                <span className="btnlabe">
                                                    <PuffLoader color='white' loading={isloading} speedMultiplier={1} size={24} />
                                                </span>
                                                Proceed </span>
                                        </div>
                                        :
                                        <div className="acceptBTN" style={{ width: "70px", height: "21px", marginLeft: "60px" }}>
                                            <span style={{ cursor: "pointer", borderRadius: "0px" }}
                                                onClick={payOnDelivery}

                                            >
                                                Proceed </span>
                                        </div>

                                    : null
                        }

                    </div>
                </DialogOutClick>
            </div>
                </Grid>
            </div >
        </div >


    )
}
export default AcceptQuote
