import { IoMdOpen, IoMdMailOpen } from 'react-icons/io';
import { TiCancelOutline } from 'react-icons/ti';
import { CgCloseR, CgCheckR } from 'react-icons/cg';
import { useContext, useEffect, useState } from 'react';
import DialogOutClick from '../../../../components/DialogOutClick';
import SubmitQuote from './SubmitQuote';
import MUTable from '../../../../components/MUTable';
import { TiUserAddOutline } from 'react-icons/ti'
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import DialogPop from '../../../../components/DialogPop';
import { ConnContext } from '../../../../context/ConnContext';
import SecureLS from 'secure-ls';
import axios from "axios"
import Swal from "sweetalert2";
import { DashboardContext } from "../../../../context/Dashboard/DashboardProvider/dashboard.provider";
import GlobalizationLanguage, { GlobalizationLanguagewithCOntryCode } from '../../../../components/GlobalizationLanguage';
import Cookies from 'js-cookie';
import Currency from '../../../../utils/Currency';

function TableCard({ loading, setAsignType, rejectType, completed_quotes, setQuoteStatus, setDeli_location, setDeliveryStatus, setagentDetails, agents, setDeliveryId, toshowAgents, quote, agentID, updateQuoteMsg, updateDataToShow, updateStateToView, type, acceptedType }) {

    const { visitingCountryCode } = useContext(ConnContext)

    const history = useHistory();
    var timeout = GlobalizationLanguage("/timeout")

    var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

    useEffect(() => {
        setCountry(Cookies.get('i18next'));
    }, [Cookies.get('i18next')])

    const [country, setCountry] = useState(countryvalue);


    const ls = new SecureLS({
        isCompression: true,
        encodingType: "rabbit",
        encryptionSecret: "dm_dillivry",
    });


    const { setpage, page } = useContext(DashboardContext);


    const Authorization = ls.get("_nodi") === "" ? "" : ls.get("_nodi");

    const authAxios = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: {
            Authorization: Authorization,
        },
    });

    const [rejecting, setrejecting] = useState(false)

    const [openQuote, setopenQuote] = useState(false)
    const [itemToQuote, setitemToQuote] = useState([])
    const [openaccept, setopenaccept] = useState(false)
    const [openreject, setopenreject] = useState(false)
    const [submiting, setsubmiting] = useState(false);
    const [get_id, setGet_id] = useState("");
    const [deli_location, setdeli_location] = useState("");
    const handleClickOpen = () => {
        setopenQuote(true);
    };

    const handleClose = () => {
        setopenQuote(false);
    };

    const AcceptAck = () => {

        if (ls.get("_nodi") !== "" && ls.get("aCs_") === "active") {
            setsubmiting(true);
            authAxios
                .post(`m-dillivry-bid-manager?delivery_location=${deli_location}`, {
                    action_type: "accept_ack_req",
                    delivery_id: get_id,
                }, { timeout: 240000 })
                .then((response) => {

                    if (response.status === 200) {
                        Swal.fire({
                            icon: "success",
                            text: "Acknowledgement accepted successfully!",
                            timer: 2500,
                            showConfirmButton: false,
                        }).then(() => {
                            window.location.reload()
                        });
                    }
                    setopenaccept(false)

                    setsubmiting(false);
                })
                .catch((err) => {
                    console.log(err);

                    setopenaccept(false)

                    if (err?.response?.status === 403) {
                        Swal.fire({
                            icon: "warning",
                            text: "Session expired, login again!",
                            //  timer: 2500,
                            confirmButtonColor: "var(--main)",
                        }).then((result) => {
                            if (result.value) {
                                ls.clear();
                                history.push('/login')
                            }
                        });
                    } else {
                        Swal.fire({
                            icon: "warning",
                            text: "An error occured, be sure you are connected to internet",
                            timer: 2500,
                            showConfirmButton: false,
                        });
                    }
                    if (err.code === "ECONNABORTED") {
                        console.log('internal-server-error-timeout')
                        history.push(timeout)
                        // window.location.reload()
                    }
                });
        }
    }

    const RejectAck = () => {
        setrejecting(true)
        authAxios.post(`m-dillivry-bid-manager?delivery_location=${deli_location}`, {
            action_type: "reject_bid",
            delivery_id: get_id,
            comment: '',
        }, { timeout: 240000 })
            .then((response) => {
                setrejecting(false)
                setopenreject(false)
                if (response.status === 200) {
                    Swal.fire({
                        icon: 'info',
                        text: 'Acknowledgement rejected!',
                        timer: 2500,
                        showConfirmButton: false,
                    })
                        .then(() => {
                            window.location.reload()
                        })
                }

            })
            .catch((err) => {
                if (err.code === "ECONNABORTED") {
                    console.log('internal-server-error-timeout')
                    history.push(timeout)
                }
                setrejecting(false)
                setopenreject(false)

                Swal.fire({
                    icon: 'error',
                    text: 'error!',
                    timer: 2000,
                    showConfirmButton: false,
                })
                console.log(err)
            });
    }

    const numberWithCommas = new Intl.NumberFormat("en-GB")

    const showMe = (type, delivery_id, delivery_location) => {
        var link = GlobalizationLanguagewithCOntryCode(`/deliveries/${type}/${delivery_id}`, country, visitingCountryCode)
        history.push(link, {
            location: delivery_location
        })
        // console.log(link)
    }
    const onItemClickAll = (item) => {
        setpage("all")
        // console.log("MyDillivry")
if(item.quote_status !== 'outbid'){

        if (item.delivery_status === "awaiting_quote") {
            showMe("current", item.delivery_id, item?.delivery_location)
        }
        if (item.delivery_status === "awaiting_agent_assignment") {
            showMe("unassign", item.delivery_id, item?.delivery_location)
        }
        if (item.delivery_status === "awaiting_pickup") {
            showMe("assign", item.delivery_id, item?.delivery_location)
        }
        if (item.delivery_status === "picked_up") {
            showMe("picked_up", item.delivery_id, item?.delivery_location)
        }
        if (item.delivery_status === "out_for_delivery") {
            showMe("out_for_delivery", item.delivery_id, item?.delivery_location)
        }
        if (item.delivery_status === "missed_delivery") {
            showMe("missed", item.delivery_id, item?.delivery_location)
        }
        if (item.delivery_status === "") {
            showMe("decline", item.delivery_id, item?.delivery_location)
        }
        if (item.delivery_status === "delivered") {
            showMe("complete", item.delivery_id, item?.delivery_location)
        }
    }
        //    if(item.delivery_status === "watchlist"){
        //     showMe("watchlist", item.delivery_id)
        //    }
    }

    return (
        <>
            {
                type === 'all' ?
                    <MUTable style={{ width: '100%' }}
                        title=""
                        columns={
                            [
                                { title: 'Items', field: 'image_urls[0]', width: '100px', render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]} /> </div> },
                                { title: 'Description', field: 'item_desc', render: rowData => <p>{rowData.item_desc.charAt(0)?.toUpperCase() + rowData.item_desc.toLowerCase().slice(1)}</p> },
                                { title: 'Pickup', field: `from`, render: rowData => <span>{rowData?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                { title: 'Destination', field: 'to', render: rowData => <span>{rowData?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                {
                                    title: 'Status', field: 'quote_status', render: rowData => (
                                        <span>{
                                            rowData?.delivery_status === "awaiting_provider_acknowledgment" && rowData?.quote_status === 'accepted' ?
                                                "Awaiting provider acknowledgement" :
                                                rowData?.delivery_status === "awaiting_provider_acknowledgment" && rowData?.quote_status.includes('rejected') ?
                                                    "Rejected" :
                                                    rowData?.delivery_status === "awaiting_provider_acknowledgment" && rowData?.quote_status !== 'accepted' ?
                                                        "Outbidded" :
                                                        rowData?.quote_status?.replace(/_/g, " ").charAt(0).toUpperCase() + rowData?.quote_status?.replace(/_/g, " ").slice(1)
                                        }</span>
                                    )
                                },
                                { title: 'Your quote', field: 'quote_amount', render: rowData => <span><Currency location={rowData.delivery_location} />{numberWithCommas.format(rowData.quote_amount)}</span> },
                                {
                                    title: "Mode of payment", field: "mode_of_payment",
                                    render: (rowData) => (
                                        <div>
                                            {
                                                typeof (rowData?.mode_of_payment) === "string" ?
                                                    <>
                                                        {
                                                            rowData?.mode_of_payment === "pay_by_cash" ?
                                                                <div>Cash</div> :
                                                                rowData?.mode_of_payment === "pay_by_transfer" ?
                                                                    <div>Transfer</div> :
                                                                    rowData?.mode_of_payment === "pay_by_ussd" ?
                                                                        <div>USSD</div> :
                                                                        rowData?.mode_of_payment === "pay_by_wallet" ?
                                                                            <div>Online</div> :
                                                                            rowData?.mode_of_payment === "pay_by_card" ?
                                                                                <div>Card</div> :
                                                                                <div>{rowData?.mode_of_payment}</div>

                                                        }

                                                    </>
                                                    :
                                                    <div>N/A</div>


                                            }


                                        </div>

                                    )
                                },

                            ]
                        }
                        data={quote}
                        // isLoading={loadingData ? true : false }
                        onRowClick={(event, rowData) => { setpage("all"); onItemClickAll(rowData) }}
                        options={{
                            showEmptyDataSourceMessage: false,
                            exportButton: true,
                            actionsColumnIndex: -1,
                            headerStyle: {
                                fontSize: '13px',
                                fontWeight: '600',
                                backgroundColor: 'white'
                            },
                            rowStyle: rowData => ({
                                fontSize: '12px',
                                fontWeight: '500',
                                // backgroundColor: (selectedRow === rowData.delivery_id) ? 'rgb(236, 252, 242)' : 'var(--white)'
                            })
                        }}
                        isLoading={loading}
                    />
                    :
                    //   current quote start here
                    type === 'current' ?
                        <MUTable style={{ width: '100%' }}
                            title=""
                            columns={
                                [
                                    { title: 'Items', field: 'image_urls[0]', width: '100px', render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]} /> </div> },
                                    { title: 'Description', field: 'item_desc', render: rowData => <p>{rowData.item_desc.charAt(0)?.toUpperCase() + rowData.item_desc.toLowerCase().slice(1)}</p> },
                                    { title: 'Pickup', field: `from`, render: rowData => <span>{rowData?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                    { title: 'Destination', field: 'to', render: rowData => <span>{rowData?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                    { title: 'Status', field: 'quote_status', render: rowData => <p style={{ color: '#004DC1' }}>Awaiting quote acceptance</p> },
                                    { title: 'Your quote', field: 'quote_amount', render: rowData => <span><Currency location={rowData.delivery_location} />{numberWithCommas.format(rowData.quote_amount)}</span> },
                                    { title: 'Best quote', field: 'best_quote', render: rowData => <span><Currency location={rowData.delivery_location} />{numberWithCommas.format(rowData?.best_quote)}</span> },
                                    {
                                        title: "Mode of payment", field: "mode_of_payment",
                                        render: (rowData) => (
                                            <div>
                                                {
                                                    typeof (rowData?.mode_of_payment) === "string" ?
                                                        <>
                                                            {
                                                                rowData?.mode_of_payment === "pay_by_cash" ?
                                                                    <div>Cash</div> :
                                                                    rowData?.mode_of_payment === "pay_by_transfer" ?
                                                                        <div>Transfer</div> :
                                                                        rowData?.mode_of_payment === "pay_by_ussd" ?
                                                                            <div>USSD</div> :
                                                                            rowData?.mode_of_payment === "pay_by_wallet" ?
                                                                                <div>Online</div> :
                                                                                rowData?.mode_of_payment === "pay_by_card" ?
                                                                                    <div>Card</div> :
                                                                                    <div>{rowData?.mode_of_payment}</div>
                                                            }
                                                        </>
                                                        :
                                                        <div>N/A</div>
                                                }
                                            </div>
                                        )
                                    },

                                ]
                            }
                            data={quote}
                            // isLoading={loadingData ? true : false }
                            onRowClick={(event, rowData) => { setpage("current"); showMe("current", rowData.delivery_id, rowData?.delivery_location) }}
                            options={{
                                showEmptyDataSourceMessage: false,
                                exportButton: true,
                                actionsColumnIndex: -1,
                                headerStyle: {
                                    fontSize: '13px',
                                    fontWeight: '600',
                                    backgroundColor: 'white'
                                },
                                rowStyle: rowData => ({
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    // backgroundColor: (selectedRow === rowData.delivery_id) ? 'rgb(236, 252, 242)' : 'var(--white)'
                                })
                            }}
                            isLoading={loading}
                            actions={
                                [
                                    {
                                        icon: IoMdMailOpen,
                                        iconProps: {
                                            color: 'green',
                                            fontSize: '20px'
                                        },

                                        tooltip: 'Messages',
                                        onClick: (event, rowData) => {
                                            var link = GlobalizationLanguage(`/message/${type}/${rowData.delivery_id}`)
                                            history.push(link)
                                            // const row = Object.assign({}, rowData)
                                            // updateStateToMsg()
                                            // updateQuoteMsg([{ sender_name : rowData?.quote_detail[0]?.sender_name , conversation : rowData?.quote_detail[0].message , delivery_id: rowData?.delivery_id, fulfiller_id: rowData?.quote_detail[0].fulfiller_id, quote_subject: rowData?.item_desc, delivery_address: rowData?.to, pickup_address: rowData?.from, image_urls: rowData?.image_urls} ]);
                                        }
                                    },
                                    {
                                        icon: IoMdOpen,
                                        iconProps: {
                                            color: 'green',
                                            fontSize: '20px'
                                        },
                                        tooltip: 'Quote',
                                        onClick: (event, rowData) => {
                                            setitemToQuote(rowData); handleClickOpen()
                                        }
                                    },


                                ]
                            }
                        />
                        // current quote ends here
                        :
                        type === 'acknowledge' ?
                            <MUTable style={{ width: '100%' }}
                                title=""
                                columns={
                                    [
                                        { title: 'Items', field: 'image_urls[0]', width: '100px', render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]} /> </div> },
                                        { title: 'Description', field: 'item_desc', render: rowData => <p>{rowData.item_desc.charAt(0)?.toUpperCase() + rowData.item_desc.toLowerCase().slice(1)}</p> },
                                        { title: 'Pickup', field: `from`, render: rowData => <span>{rowData?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                        { title: 'Destination', field: 'to', render: rowData => <span>{rowData?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                        { title: 'Status', field: 'quote_status', render: rowData => <p style={{ color: '#004DC1' }}>Awaiting acknowledgement</p> },
                                        { title: 'Your quote', field: 'quote_amount', render: rowData => <span><Currency location={rowData.delivery_location} />{numberWithCommas.format(rowData.quote_amount)}</span> },
                                        {
                                            title: "Mode of payment", field: "mode_of_payment",
                                            render: (rowData) => (
                                                <div>
                                                    {
                                                        typeof (rowData?.mode_of_payment) === "string" ?
                                                            <>
                                                                {
                                                                    rowData?.mode_of_payment === "pay_by_cash" ?
                                                                        <div>Cash</div> :
                                                                        rowData?.mode_of_payment === "pay_by_transfer" ?
                                                                            <div>Transfer</div> :
                                                                            rowData?.mode_of_payment === "pay_by_ussd" ?
                                                                                <div>USSD</div> :
                                                                                rowData?.mode_of_payment === "pay_by_wallet" ?
                                                                                    <div>Online</div> :
                                                                                    rowData?.mode_of_payment === "pay_by_card" ?
                                                                                        <div>Card</div> :
                                                                                        <div>{rowData?.mode_of_payment}</div>

                                                                }

                                                            </>
                                                            :
                                                            <div>N/A</div>
                                                    }


                                                </div>

                                            )
                                        },
                                        // { title: 'Best quote (NGN)', field: 'best_quote', render: rowData => <span>{numberWithCommas.format(rowData?.best_quote)}</span> },
                                    ]
                                }
                                data={quote}

                                // onRowClick={(event, rowData) => { showMe("current", rowData)}}
                                options={{
                                    showEmptyDataSourceMessage: false,
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        fontSize: '13px',
                                        fontWeight: '600',
                                        backgroundColor: 'white'
                                    },
                                    rowStyle: rowData => ({
                                        fontSize: '12px',
                                        fontWeight: '500',
                                    })
                                }}
                                isLoading={loading}
                                actions={
                                    [
                                        {
                                            icon: () => <CgCheckR style={{
                                                color: "#50B14A",
                                                fontSize: "20px"
                                            }} />,
                                            tooltip: 'Accept',
                                            onClick: (event, rowData) => {

                                                setopenaccept(!openaccept)
                                                setGet_id(rowData.delivery_id)
                                                setdeli_location(rowData.delivery_location)
                                                console.log(rowData, "rowdata")
                                            }

                                        },
                                        {
                                            icon: () => <CgCloseR
                                                style={{

                                                    color: "#F40101", fontSize: "20px"
                                                }} />,
                                            iconProps: {
                                                color: 'green',
                                                fontSize: '20px'
                                            },
                                            tooltip: 'Reject',
                                            onClick: (event, rowData) => {
                                                setopenreject(!openreject)
                                                setGet_id(rowData.delivery_id)
                                                setdeli_location(rowData.delivery_location)
                                            }
                                        },


                                    ]
                                }
                            />
                            :
                            type === 'accept' ?
                                <>
                                    {
                                        acceptedType === 'unassign' ?
                                            <MUTable style={{ width: '100%' }}
                                                title=""
                                                columns={
                                                    [
                                                        { title: 'Items', field: 'image_urls[0]', width: '100px', render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]} /> </div> },
                                                        { title: 'Description', field: 'item_desc', render: rowData => <p>{rowData.item_desc.charAt(0)?.toUpperCase() + rowData.item_desc.toLowerCase().slice(1)}</p> },
                                                        { title: 'Pickup', field: `from`, render: rowData => <span>{rowData?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                        { title: 'Destination', field: 'to', render: rowData => <span>{rowData?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                        { title: 'Status', field: 'quote_status', render: rowData => <p style={{ color: '#B77E02' }}>Awaiting Agent Assignment</p> },
                                                        { title: 'Your quote', field: 'quote_amount', render: rowData => <span><Currency location={rowData.delivery_location} />{numberWithCommas.format(rowData.quote_amount)}</span> },
                                                        {
                                                            title: "Mode of payment", field: "mode_of_payment",
                                                            render: (rowData) => (
                                                                <div>
                                                                    {
                                                                        typeof (rowData?.mode_of_payment) === "string" ?
                                                                            <>
                                                                                {
                                                                                    rowData?.mode_of_payment === "pay_by_cash" ?
                                                                                        <div>Cash</div> :
                                                                                        rowData?.mode_of_payment === "pay_by_transfer" ?
                                                                                            <div>Transfer</div> :
                                                                                            rowData?.mode_of_payment === "pay_by_ussd" ?
                                                                                                <div>USSD</div> :
                                                                                                rowData?.mode_of_payment === "pay_by_wallet" ?
                                                                                                    <div>Online</div> :
                                                                                                    rowData?.mode_of_payment === "pay_by_card" ?
                                                                                                        <div>Card</div> :
                                                                                                        <div>{rowData?.mode_of_payment}</div>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <div>N/A</div>
                                                                    }
                                                                </div>

                                                            )
                                                        },
                                                    ]
                                                }
                                                data={quote}
                                                isLoading={loading}
                                                onRowClick={(event, rowData) => { setpage("accept"); showMe("unassign", rowData.delivery_id, rowData?.delivery_location) }
                                                }
                                                options={{
                                                    showEmptyDataSourceMessage: false,

                                                    exportButton: true,
                                                    actionsColumnIndex: -1,
                                                    headerStyle: {
                                                        fontSize: '13px',
                                                        fontWeight: '600',
                                                        backgroundColor: 'white'
                                                    },
                                                    rowStyle: rowData => ({
                                                        fontSize: '12px',
                                                        fontWeight: '500',
                                                        // backgroundColor: (selectedRow === rowData.delivery_id) ? 'rgb(236, 252, 242)' : 'var(--white)'
                                                    })
                                                }}
                                                actions={
                                                    [
                                                        {
                                                            icon: TiUserAddOutline,
                                                            iconProps: {
                                                                color: 'green',
                                                                fontSize: '20px'
                                                            },
                                                            tooltip: 'Assign to agent',
                                                            onClick: (event, rowData) => {
                                                                setAsignType('assign'); toshowAgents(); agentID(""); toshowAgents(); toshowAgents(); setDeliveryId(rowData.delivery_id); setDeli_location(rowData.delivery_location); setQuoteStatus(rowData.quote_status); setDeliveryStatus(rowData.delivery_status)
                                                            }
                                                        },
                                                        {
                                                            icon: IoMdMailOpen,
                                                            iconProps: {
                                                                color: 'green',
                                                                fontSize: '20px'
                                                            },
                                                            tooltip: 'Messages',
                                                            onClick: (event, rowData) => {
                                                                var link = GlobalizationLanguage(`/message/unassign/${rowData.delivery_id}`)
                                                                history.push(link)
                                                                // const row = Object.assign({}, rowData)
                                                                // updateStateToMsg()
                                                                // updateQuoteMsg([{ sender_name : rowData?.quote_detail[0]?.sender_name , conversation : rowData?.quote_detail[0].message , delivery_id: rowData?.delivery_id, fulfiller_id: rowData?.quote_detail[0].fulfiller_id, quote_subject: rowData?.item_desc, delivery_address: rowData?.to, pickup_address: rowData?.from, image_urls: rowData?.image_urls} ]);
                                                            }
                                                        },
                                                    ]
                                                }
                                            />
                                            :
                                            <MUTable style={{ width: '100%' }}
                                                title=""
                                                columns={
                                                    [
                                                        { title: 'Items', field: 'image_urls[0]', width: '100px', render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]} /> </div> },
                                                        // { title: 'Delivery ID', field: 'delivery_id' },
                                                        { title: 'Description', field: 'item_desc', render: rowData => <p>{rowData.item_desc.charAt(0)?.toUpperCase() + rowData.item_desc.toLowerCase().slice(1)}</p> },
                                                        // { title: 'Category',field: 'item_category', render: rowData => <p>{rowData.item_category.charAt(0)?.toUpperCase() + rowData.item_category.toLowerCase().slice(1).replace(/_/g , " ")}</p>},
                                                        { title: 'Pickup', field: 'from' },
                                                        { title: 'Destination', field: 'to' },
                                                        { title: 'Status', field: 'quote_status', render: rowData => <p style={{ color: '#B77E02' }}>Awaiting Pickup</p> },
                                                        { title: 'Your quote', field: 'quote_amount', render: rowData => <span><Currency location={rowData.delivery_location} />{numberWithCommas.format(rowData.quote_amount)}</span> },
                                                        { title: 'Assign time', field: 'quote_amount', render: rowData => <span>{moment(rowData?.assigned_agent_details?.assigned_time_stamp).format("DD-MM-YYYY HH:MM:SS")}</span> },
                                                        {
                                                            title: "Mode of payment", field: "mode_of_payment",
                                                            render: (rowData) => (
                                                                <div>
                                                                    {
                                                                        typeof (rowData?.mode_of_payment) === "string" ?
                                                                            <>
                                                                                {
                                                                                    rowData?.mode_of_payment === "pay_by_cash" ?
                                                                                        <div>Cash</div> :
                                                                                        rowData?.mode_of_payment === "pay_by_transfer" ?
                                                                                            <div>Transfer</div> :
                                                                                            rowData?.mode_of_payment === "pay_by_ussd" ?
                                                                                                <div>USSD</div> :
                                                                                                rowData?.mode_of_payment === "pay_by_wallet" ?
                                                                                                    <div>Online</div> :
                                                                                                    rowData?.mode_of_payment === "pay_by_card" ?
                                                                                                        <div>Card</div> :
                                                                                                        <div>{rowData?.mode_of_payment}</div>

                                                                                }

                                                                            </>

                                                                            :
                                                                            <div>N/A</div>


                                                                    }


                                                                </div>

                                                            )
                                                        },
                                                    ]
                                                }
                                                data={quote}
                                                isLoading={loading}
                                                onRowClick={(event, rowData) => {
                                                    setpage("accept");
                                                    showMe("assign", rowData.delivery_id, rowData?.delivery_location);
                                                    setagentDetails(agents.filter(function (agent) { return agent.user_id === rowData.assigned_agent_details?.pickup_agent_id }));
                                                }
                                                }
                                                options={{
                                                    showEmptyDataSourceMessage: false,

                                                    exportButton: true,
                                                    actionsColumnIndex: -1,
                                                    headerStyle: {
                                                        fontSize: '13px',
                                                        fontWeight: '600',
                                                        backgroundColor: 'white'
                                                    },
                                                    rowStyle: rowData => ({
                                                        fontSize: '12px',
                                                        fontWeight: '500',
                                                        // backgroundColor: (selectedRow === rowData.delivery_id) ? 'rgb(236, 252, 242)' : 'var(--white)'
                                                    })
                                                }}
                                                actions={
                                                    [
                                                        {
                                                            icon: IoMdMailOpen,
                                                            iconProps: {
                                                                color: 'green',
                                                                fontSize: '20px'
                                                            },
                                                            tooltip: 'Messages',
                                                            onClick: (event, rowData) => {
                                                                var link = GlobalizationLanguage(`/message/assign/${rowData.delivery_id}`)

                                                                history.push(link)
                                                            }
                                                        },
                                                        {
                                                            icon: TiUserAddOutline,
                                                            iconProps: {
                                                                color: 'green',
                                                                fontSize: '20px'
                                                            },
                                                            tooltip: 'Reassign agent',
                                                            onClick: (event, rowData) => {
                                                                setAsignType('reassign'); toshowAgents(); agentID(rowData.assigned_agent_details?.pickup_agent_id); setDeliveryId(rowData.delivery_id); setDeli_location(rowData.delivery_location); setQuoteStatus(rowData.quote_status); setDeliveryStatus(rowData.delivery_status);
                                                            }
                                                        },
                                                    ]
                                                }
                                            />
                                    }
                                </>
                                :
                                type === 'out_for_delivery' || type === 'picked_up' ?
                                    <>
                                        <MUTable style={{ width: '100%' }}
                                            title=""
                                            columns={
                                                [
                                                    { title: 'Items', field: 'image_urls[0]', width: '100px', render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]} /> </div> },
                                                    // { title: 'Delivery ID', field: 'delivery_id' },
                                                    { title: 'Description', field: 'item_desc', render: rowData => <p>{rowData?.item_desc.charAt(0)?.toUpperCase() + rowData.item_desc.toLowerCase().slice(1)}</p> },
                                                    // { title: 'Category',field: 'item_category', render: rowData => <p>{rowData?.item_category.charAt(0)?.toUpperCase() + rowData.item_category.toLowerCase().slice(1).replace(/_/g , " ")}</p>},
                                                    { title: 'Pickup', field: `from`, render: rowData => <span>{rowData?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                    { title: 'Destination', field: 'to', render: rowData => <span>{rowData?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                    { title: 'Status', field: 'quote_status', render: rowData => <p style={{ color: '#B77E02' }}>{rowData?.delivery_status?.replace(/_/g, " ").charAt(0).toUpperCase() + rowData?.delivery_status?.replace(/_/g, " ").slice(1)}</p> },
                                                    { title: 'Your quote', field: 'quote_amount', render: rowData => <span><Currency location={rowData?.delivery_location} />{numberWithCommas.format(rowData?.quote_amount)}</span> },
                                                    { title: 'Pick up time', field: "pickup_timestamp", render: rowData => <span>{moment(rowData?.assigned_agent_details?.pickup_timestamp).format('DD-MM-YYYY hh:mm:ss ')}</span> },
                                                    {
                                                        title: "Mode of payment", field: "mode_of_payment",
                                                        render: (rowData) => (
                                                            <div>
                                                                {
                                                                    typeof (rowData?.mode_of_payment) === "string" ?
                                                                        <>
                                                                            {
                                                                                rowData?.mode_of_payment === "pay_by_cash" ?
                                                                                    <div>Cash</div> :
                                                                                    rowData?.mode_of_payment === "pay_by_transfer" ?
                                                                                        <div>Transfer</div> :
                                                                                        rowData?.mode_of_payment === "pay_by_ussd" ?
                                                                                            <div>USSD</div> :
                                                                                            rowData?.mode_of_payment === "pay_by_wallet" ?
                                                                                                <div>Online</div> :
                                                                                                rowData?.mode_of_payment === "pay_by_card" ?
                                                                                                    <div>Card</div> :
                                                                                                    <div>{rowData?.mode_of_payment}</div>






                                                                            }

                                                                        </>

                                                                        :
                                                                        <div>N/A</div>


                                                                }


                                                            </div>

                                                        )
                                                    },
                                                ]
                                            }
                                            data={quote}
                                            isLoading={loading}
                                            onRowClick={(event, rowData) => {
                                                setpage(type === "out_for_delivery" ? "out_for_delivery" : "picked_up");
                                                showMe(type, rowData.delivery_id, rowData?.delivery_location);
                                                setagentDetails(agents.filter(function (agent) { return agent.user_id === rowData.assigned_agent_details.pickup_agent_id }));
                                            }
                                            }
                                            options={{
                                                showEmptyDataSourceMessage: false,
                                                exportButton: true,
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    fontSize: '13px',
                                                    fontWeight: '600',
                                                    backgroundColor: 'white'
                                                },
                                                rowStyle: rowData => ({
                                                    fontSize: '12px',
                                                    fontWeight: '500',
                                                    // backgroundColor: (selectedRow === rowData.delivery_id) ? 'rgb(236, 252, 242)' : 'var(--white)'
                                                })
                                            }}
                                            actions={
                                                [
                                                    {
                                                        icon: TiUserAddOutline,
                                                        iconProps: {
                                                            color: 'green',
                                                            fontSize: '20px'
                                                        },
                                                        tooltip: 'Reassign to agent',
                                                        onClick: (event, rowData) => {
                                                            setAsignType('reassign'); toshowAgents(); agentID(rowData.assigned_agent_details?.pickup_agent_id); toshowAgents(); setDeliveryId(rowData.delivery_id); setDeli_location(rowData.delivery_location); setQuoteStatus(rowData.quote_status); setDeliveryStatus(rowData.delivery_status)
                                                        }
                                                    },
                                                    {
                                                        icon: IoMdMailOpen,
                                                        iconProps: {
                                                            color: 'green',
                                                            fontSize: '20px'
                                                        },
                                                        tooltip: 'Messages',
                                                        onClick: (event, rowData) => {
                                                            // console.log('hi')
                                                            var link = GlobalizationLanguage(`/message/${type}/${rowData.delivery_id}`)
                                                            history.push(link)
                                                        }
                                                    }
                                                ]
                                            }
                                        />
                                    </>
                                    :
                                    type === 'in_transit' ?
                                        <>
                                            <MUTable style={{ width: '100%' }}
                                                title=""
                                                columns={
                                                    [
                                                        { title: 'Items', field: 'image_urls[0]', width: '100px', render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]} /> </div> },
                                                        // { title: 'Delivery ID', field: 'delivery_id' },
                                                        { title: 'Description', field: 'item_desc', render: rowData => <p>{rowData.item_desc.charAt(0)?.toUpperCase() + rowData.item_desc.toLowerCase().slice(1)}</p> },
                                                        // { title: 'Category',field: 'item_category', render: rowData => <p>{rowData.item_category.charAt(0)?.toUpperCase() + rowData.item_category.toLowerCase().slice(1).replace(/_/g , " ")}</p>},
                                                        { title: 'Pickup', field: `from`, render: rowData => <span>{rowData?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                        { title: 'Destination', field: 'to', render: rowData => <span>{rowData?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                        { title: 'Status', field: 'quote_status', render: rowData => <p style={{ color: '#B77E02' }}>Out for delivery</p> },
                                                        { title: 'Your quote', field: 'quote_amount', render: rowData => <span><Currency location={rowData.delivery_location} />{numberWithCommas.format(rowData.quote_amount)}</span> },
                                                        {
                                                            title: "Mode of payment", field: "mode_of_payment",
                                                            render: (rowData) => (
                                                                <div>
                                                                    {
                                                                        typeof (rowData?.mode_of_payment) === "string" ?
                                                                            <>
                                                                                {
                                                                                    rowData?.mode_of_payment === "pay_by_cash" ?
                                                                                        <div>Cash</div> :
                                                                                        rowData?.mode_of_payment === "pay_by_transfer" ?
                                                                                            <div>Transfer</div> :
                                                                                            rowData?.mode_of_payment === "pay_by_ussd" ?
                                                                                                <div>USSD</div> :
                                                                                                rowData?.mode_of_payment === "pay_by_wallet" ?
                                                                                                    <div>Online</div> :
                                                                                                    rowData?.mode_of_payment === "pay_by_card" ?
                                                                                                        <div>Card</div> :
                                                                                                        <div>{rowData?.mode_of_payment}</div>






                                                                                }

                                                                            </>

                                                                            :
                                                                            <div>N/A</div>


                                                                    }


                                                                </div>

                                                            )
                                                        },
                                                    ]
                                                }
                                                data={quote}
                                                isLoading={loading}
                                                onRowClick={(event, rowData) => {
                                                    setpage("in_transit");
                                                    showMe("out_for_delivery", rowData.delivery_id, rowData?.delivery_location);
                                                    setagentDetails(agents.filter(function (agent) { return agent.user_id === rowData.assigned_agent_details.pickup_agent_id }));
                                                }
                                                }
                                                options={{
                                                    showEmptyDataSourceMessage: false,
                                                    exportButton: true,
                                                    actionsColumnIndex: -1,
                                                    headerStyle: {
                                                        fontSize: '13px',
                                                        fontWeight: '600',
                                                        backgroundColor: 'white'
                                                    },
                                                    rowStyle: rowData => ({
                                                        fontSize: '12px',
                                                        fontWeight: '500',
                                                        // backgroundColor: (selectedRow === rowData.delivery_id) ? 'rgb(236, 252, 242)' : 'var(--white)'
                                                    })
                                                }}
                                                actions={
                                                    [
                                                        {
                                                            icon: TiUserAddOutline,
                                                            iconProps: {
                                                                color: 'green',
                                                                fontSize: '20px'
                                                            },
                                                            tooltip: 'Reassign to agent',
                                                            onClick: (event, rowData) => {
                                                                setAsignType('reassign'); toshowAgents(); agentID(rowData.assigned_agent_details?.pickup_agent_id); toshowAgents(); setDeliveryId(rowData.delivery_id); setDeli_location(rowData.delivery_location); setQuoteStatus(rowData.quote_status); setDeliveryStatus(rowData.delivery_status)
                                                            }
                                                        },
                                                        {
                                                            icon: IoMdMailOpen,
                                                            iconProps: {
                                                                color: 'green',
                                                                fontSize: '20px'
                                                            },
                                                            tooltip: 'Messages',
                                                            onClick: (event, rowData) => {
                                                                var link = GlobalizationLanguage(`/message/${type}/${rowData.delivery_id}`)
                                                                history.push(link)
                                                            }
                                                        }
                                                    ]
                                                }
                                            />
                                        </>
                                        :

                                        type === 'missed' ?
                                            <>
                                                <MUTable style={{ width: '100%' }}
                                                    title=""
                                                    columns={
                                                        [
                                                            { title: 'Items', field: 'image_urls[0]', width: '100px', render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]} /> </div> },
                                                            // { title: 'Delivery ID', field: 'delivery_id' },
                                                            { title: 'Description', field: 'item_desc', render: rowData => <p>{rowData.item_desc.charAt(0)?.toUpperCase() + rowData.item_desc.toLowerCase().slice(1)}</p> },
                                                            // { title: 'Category',field: 'item_category', render: rowData => <p>{rowData.item_category.charAt(0)?.toUpperCase() + rowData.item_category.toLowerCase().slice(1).replace(/_/g , " ")}</p>},

                                                            { title: 'Pickup', field: `from`, render: rowData => <span>{rowData?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                            { title: 'Destination', field: 'to', render: rowData => <span>{rowData?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                            { title: 'Status', field: 'quote_status', render: rowData => <p style={{ color: 'maroon' }}>Missed</p> },
                                                            { title: 'Your quote', field: 'quote_amount', render: rowData => <span><Currency location={rowData.delivery_location} />{numberWithCommas.format(rowData.quote_amount)}</span> },
                                                            {
                                                                title: "Mode of payment", field: "mode_of_payment",
                                                                render: (rowData) => (
                                                                    <div>
                                                                        {
                                                                            typeof (rowData?.mode_of_payment) === "string" ?
                                                                                <>
                                                                                    {
                                                                                        rowData?.mode_of_payment === "pay_by_cash" ?
                                                                                            <div>Cash</div> :
                                                                                            rowData?.mode_of_payment === "pay_by_transfer" ?
                                                                                                <div>Transfer</div> :
                                                                                                rowData?.mode_of_payment === "pay_by_ussd" ?
                                                                                                    <div>USSD</div> :
                                                                                                    rowData?.mode_of_payment === "pay_by_wallet" ?
                                                                                                        <div>Online</div> :
                                                                                                        rowData?.mode_of_payment === "pay_by_card" ?
                                                                                                            <div>Card</div> :
                                                                                                            <div>{rowData?.mode_of_payment}</div>






                                                                                    }

                                                                                </>

                                                                                :
                                                                                <div>N/A</div>


                                                                        }


                                                                    </div>

                                                                )
                                                            },
                                                        ]
                                                    }
                                                    data={quote}
                                                    isLoading={loading}
                                                    onRowClick={(event, rowData) => {
                                                        setpage("missed");
                                                        showMe("missed", rowData.delivery_id, rowData?.delivery_location);
                                                        setagentDetails(agents.filter(function (agent) { return agent.user_id === rowData.delivery_agent_details.pickup_agent_id }));
                                                    }
                                                    }
                                                    options={{
                                                        showEmptyDataSourceMessage: false,
                                                        exportButton: true,
                                                        actionsColumnIndex: -1,
                                                        headerStyle: {
                                                            fontSize: '13px',
                                                            fontWeight: '600',
                                                            backgroundColor: 'white'
                                                        },
                                                        rowStyle: rowData => ({
                                                            fontSize: '12px',
                                                            fontWeight: '500',
                                                            // backgroundColor: (selectedRow === rowData.delivery_id) ? 'rgb(236, 252, 242)' : 'var(--white)'
                                                        })
                                                    }}
                                                    actions={
                                                        [
                                                            {
                                                                icon: TiUserAddOutline,
                                                                iconProps: {
                                                                    color: 'green',
                                                                    fontSize: '20px'
                                                                },
                                                                tooltip: 'Reassign to agent',
                                                                onClick: (event, rowData) => {
                                                                    setAsignType('reassign'); toshowAgents(); agentID(rowData.assigned_agent_details?.pickup_agent_id); toshowAgents(); setDeliveryId(rowData.delivery_id); setDeli_location(rowData.delivery_location); setQuoteStatus(rowData.quote_status); setDeliveryStatus(rowData.delivery_status)
                                                                }
                                                            },
                                                            {
                                                                icon: IoMdMailOpen,
                                                                iconProps: {
                                                                    color: 'green',
                                                                    fontSize: '20px'
                                                                },
                                                                tooltip: 'Messages',
                                                                onClick: (event, rowData) => {
                                                                    var link = GlobalizationLanguage(`/message/${type}/${rowData.delivery_id}`)
                                                                    history.push(link)
                                                                    // const row = Object.assign({}, rowData)
                                                                    // updateStateToMsg()
                                                                    // updateQuoteMsg([{ sender_name : rowData?.quote_detail[0]?.sender_name , conversation : rowData?.quote_detail[0].message , delivery_id: rowData?.delivery_id, fulfiller_id: rowData?.quote_detail[0].fulfiller_id, quote_subject: rowData?.item_desc, delivery_address: rowData?.to, pickup_address: rowData?.from, image_urls: rowData?.image_urls} ]);
                                                                }
                                                            }
                                                        ]
                                                    }
                                                />
                                            </>
                                            :
                                            type === 'decline' ?
                                                rejectType === 'sender' ?
                                                    <>
                                                        <MUTable style={{ width: '100%' }}
                                                            title=""
                                                            columns={
                                                                [
                                                                    { title: 'Items', field: 'image_urls[0]', width: '100px', render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]} /> </div> },
                                                                    // { title: 'Delivery ID', field: 'delivery_id' },
                                                                    { title: 'Description', field: 'item_desc', render: rowData => <p>{rowData.item_desc.charAt(0)?.toUpperCase() + rowData.item_desc.toLowerCase().slice(1)}</p> },
                                                                    // { title: 'Category',field: 'item_category', render: rowData => <p>{rowData.item_category.charAt(0)?.toUpperCase() + rowData.item_category.toLowerCase().slice(1).replace(/_/g , " ")}</p>},

                                                                    { title: 'Pickup', field: `from`, render: rowData => <span>{rowData?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                                    { title: 'Destination', field: 'to', render: rowData => <span>{rowData?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                                    { title: 'Status', field: 'quote_status', render: rowData => <p style={{ color: 'maroon' }}>Rejected</p> },
                                                                    { title: 'Your quote', field: 'quote_amount', render: rowData => <span><Currency location={rowData.delivery_location} />{numberWithCommas.format(rowData.quote_amount)}</span> },
                                                                    {
                                                                        title: "Mode of payment", field: "mode_of_payment",
                                                                        render: (rowData) => (
                                                                            <div>
                                                                                {
                                                                                    typeof (rowData?.mode_of_payment) === "string" ?
                                                                                        <>
                                                                                            {
                                                                                                rowData?.mode_of_payment === "pay_by_cash" ?
                                                                                                    <div>Cash</div> :
                                                                                                    rowData?.mode_of_payment === "pay_by_transfer" ?
                                                                                                        <div>Transfer</div> :
                                                                                                        rowData?.mode_of_payment === "pay_by_ussd" ?
                                                                                                            <div>USSD</div> :
                                                                                                            rowData?.mode_of_payment === "pay_by_wallet" ?
                                                                                                                <div>Online</div> :
                                                                                                                rowData?.mode_of_payment === "pay_by_card" ?
                                                                                                                    <div>Card</div> :
                                                                                                                    <div>{rowData?.mode_of_payment}</div>






                                                                                            }

                                                                                        </>

                                                                                        :
                                                                                        <div>N/A</div>


                                                                                }


                                                                            </div>

                                                                        )
                                                                    },
                                                                ]
                                                            }
                                                            data={quote}
                                                            isLoading={loading}
                                                            onRowClick={(event, rowData) => {
                                                                setpage("decline");
                                                                showMe("decline", rowData.delivery_id, rowData?.delivery_location);
                                                                // setagentDetails(agents.filter(function (agent){return agent.user_id === rowData.assigned_agent_details.pickup_agent_id})); 
                                                            }
                                                            }
                                                            options={{
                                                                showEmptyDataSourceMessage: false,
                                                                exportButton: true,
                                                                actionsColumnIndex: -1,
                                                                headerStyle: {
                                                                    fontSize: '13px',
                                                                    fontWeight: '600',
                                                                    backgroundColor: 'white'
                                                                },
                                                                rowStyle: rowData => ({
                                                                    fontSize: '12px',
                                                                    fontWeight: '500',
                                                                    // backgroundColor: (selectedRow === rowData.delivery_id) ? 'rgb(236, 252, 242)' : 'var(--white)'
                                                                })
                                                            }}
                                                            actions={
                                                                [
                                                                    {
                                                                        icon: IoMdMailOpen,
                                                                        iconProps: {
                                                                            color: 'green',
                                                                            fontSize: '20px'
                                                                        },
                                                                        tooltip: 'Messages',
                                                                        onClick: (event, rowData) => {
                                                                            var link = GlobalizationLanguage(`/message/${type}/${rowData.delivery_id}`)
                                                                            history.push(link)
                                                                            // const row = Object.assign({}, rowData)
                                                                            // updateStateToMsg()
                                                                            // updateQuoteMsg([{ sender_name : rowData?.quote_detail[0]?.sender_name , conversation : rowData?.quote_detail[0].message , delivery_id: rowData?.delivery_id, fulfiller_id: rowData?.quote_detail[0].fulfiller_id, quote_subject: rowData?.item_desc, delivery_address: rowData?.to, pickup_address: rowData?.from, image_urls: rowData?.image_urls} ]);
                                                                        }
                                                                    },
                                                                    {
                                                                        icon: IoMdOpen,
                                                                        iconProps: {
                                                                            color: 'green',
                                                                            fontSize: '20px'
                                                                        },
                                                                        tooltip: 'Requote',
                                                                        onClick: (event, rowData) => {
                                                                            setitemToQuote(rowData); handleClickOpen()
                                                                        }
                                                                    }
                                                                ]
                                                            }
                                                        />
                                                    </>
                                                    :
                                                    <>
                                                        <MUTable style={{ width: '100%' }}
                                                            title=""
                                                            columns={
                                                                [
                                                                    { title: 'Items', field: 'image_urls[0]', width: '100px', render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]} /> </div> },
                                                                    // { title: 'Delivery ID', field: 'delivery_id' },
                                                                    { title: 'Description', field: 'item_desc', render: rowData => <p>{rowData.item_desc.charAt(0)?.toUpperCase() + rowData.item_desc.toLowerCase().slice(1)}</p> },
                                                                    // { title: 'Category',field: 'item_category', render: rowData => <p>{rowData.item_category.charAt(0)?.toUpperCase() + rowData.item_category.toLowerCase().slice(1).replace(/_/g , " ")}</p>},

                                                                    { title: 'Pickup', field: 'from' },
                                                                    { title: 'Destination', field: 'to' },
                                                                    { title: 'Status', field: 'quote_status', render: rowData => <p>Rejected</p> },
                                                                    { title: 'Reason', field: 'comment_by_provider', render: rowData => <p>{rowData.comment_by_provider}</p> },
                                                                    {
                                                                        title: "Mode of payment", field: "mode_of_payment",
                                                                        render: (rowData) => (
                                                                            <div>
                                                                                {
                                                                                    typeof (rowData?.mode_of_payment) === "string" ?
                                                                                        <>
                                                                                            {
                                                                                                rowData?.mode_of_payment === "pay_by_cash" ?
                                                                                                    <div>Cash</div> :
                                                                                                    rowData?.mode_of_payment === "pay_by_transfer" ?
                                                                                                        <div>Transfer</div> :
                                                                                                        rowData?.mode_of_payment === "pay_by_ussd" ?
                                                                                                            <div>USSD</div> :
                                                                                                            rowData?.mode_of_payment === "pay_by_wallet" ?
                                                                                                                <div>Online</div> :
                                                                                                                rowData?.mode_of_payment === "pay_by_card" ?
                                                                                                                    <div>Card</div> :
                                                                                                                    <div>{rowData?.mode_of_payment}</div>






                                                                                            }

                                                                                        </>

                                                                                        :
                                                                                        <div>N/A</div>


                                                                                }


                                                                            </div>

                                                                        )
                                                                    },
                                                                ]
                                                            }
                                                            isLoading={loading}
                                                            data={quote}
                                                        />
                                                    </>
                                                :

                                                type === 'watchlist' || type === 'save' ?
                                                    <>
                                                        <MUTable style={{ width: '100%' }}
                                                            title=""
                                                            columns={
                                                                [
                                                                    { title: 'Items', field: 'image_urls[0]', width: '100px', render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]} /> </div> },
                                                                    // { title: 'Delivery ID', field: 'delivery_id' },
                                                                    { title: 'Description', field: 'item_desc', render: rowData => <p>{rowData.item_desc.charAt(0)?.toUpperCase() + rowData.item_desc.toLowerCase().slice(1)}</p> },
                                                                    // { title: 'Category',field: 'item_category', render: rowData => <p>{rowData.item_category.charAt(0)?.toUpperCase() + rowData.item_category.toLowerCase().slice(1).replace(/_/g , " ")}</p>},

                                                                    { title: 'Pickup', field: `from`, render: rowData => <span>{rowData?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                                    { title: 'Destination', field: 'to', render: rowData => <span>{rowData?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                                    { title: 'Status', field: 'quote', render: rowData => <p>Awaiting quote</p> },
                                                                    { title: 'No. quote', field: 'quote', render: rowData => <span>{rowData.quote}</span> },
                                                                    { title: 'Best quote (NGN)', field: 'best_quote', render: rowData => <span>{numberWithCommas.format(rowData?.best_quote) === "NaN" ? "-" : numberWithCommas.format(rowData?.best_quote)}</span> },

                                                                ]
                                                            }
                                                            data={quote}
                                                            isLoading={loading}
                                                            // isLoading={loadingData ? true : false }
                                                            onRowClick={(event, rowData) => history.push(`/open-delivery/${rowData.delivery_id}`)}
                                                            options={{
                                                                showEmptyDataSourceMessage: false,
                                                                exportButton: true,
                                                                actionsColumnIndex: -1,
                                                                headerStyle: {
                                                                    fontSize: '13px',
                                                                    fontWeight: '600',
                                                                    backgroundColor: 'white'
                                                                },
                                                                rowStyle: rowData => ({
                                                                    fontSize: '12px',
                                                                    fontWeight: '500',
                                                                    // backgroundColor: (selectedRow === rowData.delivery_id) ? 'rgb(236, 252, 242)' : 'var(--white)'
                                                                })
                                                            }}
                                                        />
                                                    </>
                                                    :
                                                    type === 'outbid' ?
                                                        <>
                                                            <MUTable style={{ width: '100%' }}
                                                                title=""
                                                                columns={
                                                                    [
                                                                        { title: 'Items', field: 'image_urls[0]', width: '100px', render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]} /> </div> },
                                                                        { title: 'Description', field: 'item_desc', render: rowData => <p>{rowData.item_desc.charAt(0)?.toUpperCase() + rowData.item_desc.toLowerCase().slice(1)}</p> },
                                                                        { title: 'Pickup', field: `from`, render: rowData => <span>{rowData?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                                        { title: 'Destination', field: 'to', render: rowData => <span>{rowData?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                                        { title: 'Status', field: 'quote_status', render: rowData => <p>You got out bidded by another provider</p> },
                                                                        { title: 'Your quote', field: 'quote_amount', render: rowData => <span><Currency location={rowData.delivery_location} />{numberWithCommas.format(rowData.quote_amount)}</span> },
                                                                        {
                                                                            title: "Mode of payment", field: "mode_of_payment",
                                                                            render: (rowData) => (
                                                                                <div>
                                                                                    {
                                                                                        typeof (rowData?.mode_of_payment) === "string" ?
                                                                                            <>
                                                                                                {
                                                                                                    rowData?.mode_of_payment === "pay_by_cash" ?
                                                                                                        <div>Cash</div> :
                                                                                                        rowData?.mode_of_payment === "pay_by_transfer" ?
                                                                                                            <div>Transfer</div> :
                                                                                                            rowData?.mode_of_payment === "pay_by_ussd" ?
                                                                                                                <div>USSD</div> :
                                                                                                                rowData?.mode_of_payment === "pay_by_wallet" ?
                                                                                                                    <div>Online</div> :
                                                                                                                    rowData?.mode_of_payment === "pay_by_card" ?
                                                                                                                        <div>Card</div> :
                                                                                                                        <div>{rowData?.mode_of_payment}</div>
                                                                                                }

                                                                                            </>

                                                                                            :
                                                                                            <div>N/A</div>


                                                                                    }


                                                                                </div>

                                                                            )
                                                                        },
                                                                    ]
                                                                }
                                                                isLoading={loading}
                                                                data={quote}

                                                                options={{
                                                                    showEmptyDataSourceMessage: false,
                                                                    exportButton: true,
                                                                    actionsColumnIndex: -1,
                                                                    headerStyle: {
                                                                        fontSize: '13px',
                                                                        fontWeight: '600',
                                                                        backgroundColor: 'white'
                                                                    },
                                                                    rowStyle: rowData => ({
                                                                        fontSize: '12px',
                                                                        fontWeight: '500',
                                                                        // backgroundColor: (selectedRow === rowData.delivery_id) ? 'rgb(236, 252, 242)' : 'var(--white)'
                                                                    })
                                                                }}
                                                            />
                                                        </>
                                                        :
                                                        type === 'complete' ?
                                                            <>
                                                                <MUTable style={{ width: '100%' }}
                                                                    title=""
                                                                    columns={
                                                                        [
                                                                            { title: 'Items', field: 'image_urls[0]', width: '100px', render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]} /> </div> },
                                                                            // { title: 'Delivery ID', field: 'delivery_id' },
                                                                            { title: 'Description', field: 'item_desc', render: rowData => <p>{rowData.item_desc.charAt(0)?.toUpperCase() + rowData.item_desc.toLowerCase().slice(1)}</p> },
                                                                            // { title: 'Category',field: 'item_category', render: rowData => <p>{rowData.item_category.charAt(0)?.toUpperCase() + rowData.item_category.toLowerCase().slice(1).replace(/_/g , " ")}</p>},
                                                                            { title: 'Pickup', field: `from`, render: rowData => <span>{rowData?.from?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                                            { title: 'Destination', field: 'to', render: rowData => <span>{rowData?.to?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</span> },
                                                                            { title: 'Status', field: 'quote_status', render: rowData => <p style={{ color: 'var(--main)' }}>Completed</p> },
                                                                            { title: 'Delivery time ', render: rowData => <p>{moment(rowData?.delivery_agent_details?.delivery_timestamp).format('DD-MM-YYYY hh:mm:ss ')}</p> },
                                                                            { title: 'Your quote', field: 'quote_amount', render: rowData => <span><Currency location={rowData.delivery_location} />{numberWithCommas.format(rowData.quote_amount)}</span> },
                                                                            {
                                                                                title: "Mode of payment", field: "mode_of_payment",
                                                                                render: (rowData) => (
                                                                                    <div>
                                                                                        {
                                                                                            typeof (rowData?.mode_of_payment) === "string" ?
                                                                                                <>
                                                                                                    {
                                                                                                        rowData?.mode_of_payment === "pay_by_cash" ?
                                                                                                            <div>Cash</div> :
                                                                                                            rowData?.mode_of_payment === "pay_by_transfer" ?
                                                                                                                <div>Transfer</div> :
                                                                                                                rowData?.mode_of_payment === "pay_by_ussd" ?
                                                                                                                    <div>USSD</div> :
                                                                                                                    rowData?.mode_of_payment === "pay_by_wallet" ?
                                                                                                                        <div>Online</div> :
                                                                                                                        rowData?.mode_of_payment === "pay_by_card" ?
                                                                                                                            <div>Card</div> :
                                                                                                                            <div>{rowData?.mode_of_payment}</div>






                                                                                                    }

                                                                                                </>

                                                                                                :
                                                                                                <div>N/A</div>


                                                                                        }


                                                                                    </div>

                                                                                )
                                                                            },

                                                                        ]
                                                                    }
                                                                    data={quote}
                                                                    // isLoading={loadingData ? true : false }
                                                                    isLoading={loading}
                                                                    onRowClick={(event, rowData) => {
                                                                        setpage("complete");
                                                                        showMe("complete", rowData.delivery_id, rowData?.delivery_location);
                                                                        setagentDetails(agents.filter(function (agent) { return agent.user_id === rowData.delivery_agent_details.pickup_agent_id }));
                                                                    }}

                                                                    options={{
                                                                        showEmptyDataSourceMessage: false,
                                                                        exportButton: true,
                                                                        actionsColumnIndex: -1,
                                                                        headerStyle: {
                                                                            fontSize: '13px',
                                                                            fontWeight: '600',
                                                                            backgroundColor: 'white'
                                                                        },
                                                                        rowStyle: rowData => ({
                                                                            fontSize: '12px',
                                                                            fontWeight: '500',
                                                                            // backgroundColor: (selectedRow === rowData.delivery_id) ? 'rgb(236, 252, 242)' : 'var(--white)'
                                                                        })
                                                                    }}
                                                                />
                                                            </>
                                                            :
                                                            <></>


            }
            <DialogOutClick
                open={openQuote}
                handleClose={handleClose}
            >
                <SubmitQuote item={itemToQuote} closePopup={handleClose} />
            </DialogOutClick>


            <DialogPop
                openPopup={openaccept}
                setopenPopup={setopenaccept}
                head="Acknowledgement"
            >

                <p>
                    You are about to accept. Are you sure you are available to do this delivery?

                </p>


                <div style={{ display: 'flex', justifyContent: 'center', marginTop: "30px" }} >
                    <button onClick={AcceptAck} style={{ background: "#50B14A", border: "1px solid #50B14A", cursor: "pointer", borderRadius: '4px', color: "#ffffff", width: "161px", height: "42px" }} >
                        {submiting ? "Loading..." : "Accept"}
                    </button>
                </div>



            </DialogPop>



            <DialogPop
                openPopup={openreject}
                setopenPopup={setopenreject}
                head="Acknowledgement"
            >

                <p>
                    You are about to reject. Are you sure you won’t be available to do this delivery?

                </p>


                <div style={{ display: 'flex', justifyContent: 'center', marginTop: "30px" }} >
                    <button onClick={RejectAck} style={{ background: "#F40101", border: "1px solid #F40101", cursor: "pointer", borderRadius: '4px', color: "#ffffff", width: "161px", height: "42px" }} >
                        {rejecting ? "Loading..." : "Reject"}
                    </button>
                </div>



            </DialogPop>



        </>
    )
}

export default TableCard