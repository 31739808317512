import React, { useState, useContext } from 'react'
import { FormControl, Button } from '@material-ui/core';
import InputField from '../components/InputField'
import { Link } from 'react-router-dom';
import { HiOutlineMail } from 'react-icons/hi'
import { RiLockPasswordLine } from 'react-icons/ri'
import Axios from 'axios';
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2';
import PuffLoader from 'react-spinners/PuffLoader';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import './LoginComponents.css'
import erros from "../assets/erros.png";
import SecureLS from 'secure-ls';
import { ConnContext } from '../context/ConnContext';
import GlobalizationLanguage from './GlobalizationLanguage';

function LoginComponent({ onFinish }) {
    var reset = GlobalizationLanguage("/reset")
    const forgetpass = GlobalizationLanguage("/forgetpass")

    var deliver_with_us = GlobalizationLanguage("/create-account")
    const ls = new SecureLS({ isCompression: true, encodingType: 'rabbit', encryptionSecret: 'dm_dillivry' });
    const { visitingCountryCode } = useContext(ConnContext)
    const history = useHistory();
    const endPoint = process.env.REACT_APP_API

    const [username, setusername] = useState('');
    const [password, setpassword] = useState('');
    const [submitting, setsubmitting] = useState(false)
    const [showpassword, setshowpassword] = useState(false)

    const handleLogin = async (e) => {
        e.preventDefault();
        var encodedPassword = btoa(password);
        setsubmitting(true);

        await Axios.post(`${endPoint}m-dillivry-auth-manager`, {
            auth_type: "login",
            username: username.toLowerCase(),
            password: encodedPassword,
            // vcc: visitingCountryCode
        })
            .then((response) => {
                ls.set("user_location", response?.data?.vcc)

                ls.set("aCs_", response.data?.account_status)
                onFinish();
                ls.set("eid", username);
                if (response) {
                    if (response.data.auth_status === "new_password_required") {
                        history.push({
                            pathname: reset,
                            state: username
                        });
                    }
                    else if (response.data.auth_status === "success") {
                        const { IdToken } = response.data.token;
                        const token = IdToken.split(".")[1];
                        const encodedToken = atob(token);
                        const tokenJSON = JSON.parse(encodedToken);

                        if (tokenJSON['custom:role'] !== undefined) {

                            Swal.fire({
                                icon: 'warning',
                                text: 'Login details cannot be used to list item',
                                timer: 2000,
                                showConfirmButton: false
                            })
                        }
                        else {
                            ls.set('_iRl', 'individual');
                            ls.remove('_nodi');
                            ls.set('_utk', response.data.token.IdToken);
                            ls.set("btwl", response?.data?.data?.user_info[0]?.wallet_balance);// walllet balance

                            Swal.fire({
                                icon: 'success',
                                text: 'Logged in',
                                timer: 2000,
                                showConfirmButton: false
                            })
                        }
                    }
                    setsubmitting(false)
                }
            })
            .catch((error) => {
                if (error?.response?.status === 403) {
                    Swal.fire({
                        iconHtml: `<img src=${erros} style="width:100px;">`, // imageUrl: "dm-dillivry-web/web/src/assets/erros.png",
                        title: 'Oops!',
                        imageAlt: 'opps image',
                        text: 'Invalid username or password',
                        confirmButtonColor: 'var(--main)',

                    })
                }
                setsubmitting(false)
            })
    }
    return (
        <>
            <form onSubmit={handleLogin} style={{ paddingTop: "0px" }}>

                <div className='Xfff'>

                    <div className='XformCont'>

                        <FormControl className='formInput' variant="outlined">
                            <InputField
                                label="Email"
                                required
                                name="username"
                                id="username"
                                type="email"
                                value={username}
                                labelWidth={60}
                                onChange={(e) => { setusername(e.target.value); }}
                                icon={<HiOutlineMail style={{ marginRight: "5px", color: "var(--main)" }} />}
                            />
                        </FormControl>

                        <FormControl className='formInput' variant="outlined">
                            <InputField
                                label="Password"
                                required
                                name="password"
                                id="password"
                                type={showpassword ? 'text' : 'password'}
                                endIcon={
                                    showpassword ?
                                        <Tooltip title="Hide password" interactive>
                                            <VisibilityOffIcon style={{ color: 'grey', fontSize: '25px', cursor: 'pointer' }} onClick={() => setshowpassword(!showpassword)} />
                                        </Tooltip>
                                        :
                                        <Tooltip title="Show password" interactive>
                                            <VisibilityIcon style={{ color: 'grey', fontSize: '25px', cursor: 'pointer' }} onClick={() => setshowpassword(!showpassword)} />
                                        </Tooltip>
                                }
                                value={password}
                                labelWidth={90}
                                onChange={(e) => { setpassword(e.target.value); }}
                                icon={<RiLockPasswordLine style={{ marginRight: "5px", color: "var(--main)" }}
                                />}
                            />
                        </FormControl>
                        <div className="Xsubtitle" style={{ textAlign: "left" }}>
                            <span className='XlgCheckBoxCon' >Remember?<input type="checkbox" className='XlgcheckBox'></input></span>
                            <Link to={forgetpass} style={{ color: "var(--main)" }}>Forgot password?</Link>
                        </div>
                        <div className='lgBtnn'>
                            {
                                submitting ?
                                    <Button
                                        size="large"
                                        variant="contained"
                                        type="submit"
                                        disabled
                                        style={{
                                            backgroundColor: "var(--main)",
                                            width: '25%',
                                            color: "white",
                                            borderRadius: '10px',
                                            padding: '10px',
                                            height: '30px',
                                            textTransform: "none"
                                        }}
                                    >
                                        <span className="btnlabe">
                                            <PuffLoader color='white' loading={submitting} speedMultiplier={1} size={24} />
                                        </span>
                                    </Button>

                                    :
                                    <Button
                                        size="large"
                                        variant="contained"
                                        type="submit"
                                        style={{
                                            backgroundColor: "var(--main)",
                                            width: '25%',
                                            color: "white",
                                            borderRadius: '10px',
                                            padding: '10px',
                                            height: '30px',
                                            textTransform: "none"
                                        }}
                                    >
                                        <span className="btnlabe">Login</span>
                                    </Button>
                            }
                        </div>
                    </div>
                </div>
            </form>


        </>
    )
}

export default LoginComponent
