import React, { useState, useEffect, Component, useContext } from "react";
import { useHistory } from "react-router-dom";
import "../SendQuote.css";
import { Dialog, DialogContent } from "@material-ui/core";
import TopMenu from "../../../components/topNav/TopMenu";
import PastDelivery from "./PastDelivery";
import { MdLocationOn } from "react-icons/md";
import { HiOutlineViewGrid } from "react-icons/hi";
import { GiPathDistance } from "react-icons/gi";
import { MdDescription } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin3Line } from "react-icons/ri";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Message from "./Message";
import Account from "./Account";
import axios from "axios";
import LazyLoader from "../../../components/LazyLoader";
import Paginate from "react-paginate";
import AcceptQuote from "./AcceptQuote";
import ShowQuote from "./ShowQuote";
import EditQuote from "./EditQuote";
import Swal from "sweetalert2";
import { RiTruckLine } from "react-icons/ri";
import PuffLoader from "react-spinners/PuffLoader";
import DialogPop from "../../../components/DialogPop";
import { Button, TextField } from "@material-ui/core";
import { AiOutlineWarning } from "react-icons/ai";
import Nav from "../../../components/Nav/NavHome";
import bd1 from "../../../assets/bd1.svg";
import { FaCarSide } from "react-icons/fa";
import { WiTime4 } from "react-icons/wi";
import { GrMail } from "react-icons/gr";
import { BsFillPersonFill } from "react-icons/bs";
import ShowQuote1 from "./ShowQuote1";
import ShowQuoteDesk from "./ShowQuoteDesk";
import MessageSender from "./MessageSender";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import DialogSimplePop from "../../../components/DialogSimplePop";
// import PaystackWidget from '../../../components/PaystackWidget';
import PaystackWidget2 from "../../../components/PaystackWidget2";

import IndividualSidebar from "../../../components/Sidebar/IndividualSidebar";
import imagelogo from "../../../assets/Dillivry.png";
import oops from "../../../assets/oops.png";
import cursworking from "../../../assets/cursworking.png";
import { blue } from "@material-ui/core/colors";
import DialogOutClick from "../../../components/DialogOutClick";
import { id } from "date-fns/locale";
import { useLocation } from "react-router-dom";

import SecureLS from "secure-ls";
import ReactTooltip from "react-tooltip";
import { IconContext } from "react-icons";
import { AiOutlineInfoCircle } from "react-icons/ai";
import HomeHelp from "../../../components/HomeHelp";
import QuoteSignInForm from "../../Fulfiller/Dashboard/QuoteSignInForm";
import Login from "../../Login/Login";
import { SenderContext } from "../../../context/SenderDashboard/SenderDashboardContext";
import { ConnContext } from "../../../context/ConnContext";
// import Tracking2 from "../Tracking2";
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
function ActiveDilivry({ msg, past, expired, missed }) {
var sendermessage = GlobalizationLanguage("/sendermessage")
var timeout = GlobalizationLanguage("/timeout")
var acctLink = GlobalizationLanguage("/acct")
var linkUrl = GlobalizationLanguage("/5156886826018256")
const login = GlobalizationLanguage("/login")

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  const [showHelp, setshowHelp] = useState(false);
  const [itemLength, setitemLength] = useState(0);
  const [deefault, setDeefault] = useState([]);
  const [showLogout, setShowLogout] = useState(false);
  const location = useLocation();
  const [sendingMsg, setsendingMsg] = useState(false);

  const [paginateActivePage, setPaginateActivePage] = useState(1);
  const n = 5;
  const [clicked, setclicked] = useState("all");

  const setexpire = ()=>{
    setclicked("expire")
  }

  const [functionToCall, createFunctionToCall] = useState(() => () => {});
  const [functionToCall1, createFunctionToCall1] = useState(() => () => {});
  const [functionToCall2, createFunctionToCall2] = useState(() => () => {});

  const [loading, setloading] = useState();

  const [data, setData] = useState("");

  const [showDeletinfo, setshowDeletinfo] = useState();
  const [showEditinfo, setshowEditinfo] = useState();
  const [deliveryidpicked, setdeliveryidpicked] = useState("");
  const [showlogin, setshowlogin] = useState(false);
  const childToParent = () => {};
  const [complete, setcomplete] = useState("complete")

  const showMe = (type, item)=>{
    history.push(`/activedeliveries/${type}/${item}`)
}


const showMessageurl = (type, item)=> {
  history.push(`/message_sender`)
}

const pastdilvryurl = (type)=>{
  history.push(`/pastdelivery//${complete}`)
}

const activedilivryurl = (type)=>{
  history.push(`/activedillivry/${type}`)
}

const showPstdilivyUrl = (type, item) => {
  history.push(`/pastdeliveries/${type}/${item}`)
} 

const {initialstate, UpdateData, Updatequote} = useContext(SenderContext)
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
    window.history.replaceState(null, "");
  };

  useEffect(() => {
   
    if (initialstate !== undefined) {
      ls.set("fo_in", JSON.stringify(initialstate.data.account));
      setAccount(initialstate.data.account);
      setDeefault([
        ...initialstate.data.awaiting_agent_assignment,
        ...initialstate.data.awaiting_quote,
        ...initialstate.data.awaiting_pickup,
        ...initialstate.data.expired_deliveries,
        ...initialstate.data.out_for_delivery,
      ]);

      setitemLength(
        [
          ...initialstate.data.awaiting_agent_assignment,
          ...initialstate.data.awaiting_quote,
          ...initialstate.data.awaiting_pickup,
          ...initialstate.data.expired_deliveries,
          ...initialstate.data.out_for_delivery,
        ].length
      );
      setActiveDeliveries(
        [
          ...initialstate.data.awaiting_agent_assignment,
          ...initialstate.data.awaiting_quote,
          ...initialstate.data.awaiting_pickup,
          ...initialstate.data.expired_deliveries,
          ...initialstate.data.out_for_delivery,
        ].slice(0, n)
      );
      // setallActive([...response.data.awaiting_agent_assignment, ...response.data.awaiting_quote, ...response.data.awaiting_pickup, ...response.data.expired_deliveries, ...response.data.out_for_delivery ])

      // setActiveDeliveries(response.data.awaiting_quote.filter(function(item){return item.total_quote_received > 0}))

      setquoteRecieved(
        initialstate.data.awaiting_quote.filter(function (item) {
          return item.total_quote_received > 0;
        })
      );

      setawaiting_quotes(
        initialstate.data.awaiting_quote.filter(function (item) {
          return item.total_quote_received === 0;
        })
      );
      setawaiting_pickup(initialstate.data.awaiting_pickup);
      setawaiting_agent(initialstate.data.awaiting_agent_assignment);
      setout_for_delivery(initialstate.data.out_for_delivery);

      setPastDeliveries(initialstate.data.past_deliveries);
      setExpiredDeliveries(initialstate.data.expired_deliveries);
      setmissedDeliveries(initialstate.data.missed_delivery);

      setMessage(initialstate.data.enquiry_messages);
      setQuoteMessage(initialstate.data.quote_messages);
      setNumberActive(initialstate.data.num_of_active_deliveries);
      setNumberPast(initialstate.data.num_of_past_deliveries);
      // setNumberMessage(response.data.num_of_messages)
      setNumberMessage(initialstate.data.enquiry_messages.length);

      // setquoteHistory(response.data.quote_history)

      setIsloading(false);
    } else {
        setIsloading(true);
      const id = ls.get("eid");
      axios
        .get(`${process.env.REACT_APP_API}m-dillivry-search`, {
          search_criteria: "sender_data",
          user_id: id,
          vcc: visitingCountryCode
        })
        .then((response) => {
            if (response.status === 200) {
                setIsloading(false);
                UpdateData(response)
                 // console.log(response.data)
          ls.set("fo_in", JSON.stringify(response.data.account));

          setAccount(response.data.account);
          setDeefault([
            ...response.data.awaiting_agent_assignment,
            ...response.data.awaiting_quote,
            ...response.data.awaiting_pickup,
            ...response.data.expired_deliveries,
            ...response.data.out_for_delivery,
          ]);
          setitemLength(
            [
              ...response.data.awaiting_agent_assignment,
              ...response.data.awaiting_quote,
              ...response.data.awaiting_pickup,
              ...response.data.expired_deliveries,
              ...response.data.out_for_delivery,
            ].length
          );
          setActiveDeliveries(
            [
              ...response.data.awaiting_agent_assignment,
              ...response.data.awaiting_quote,
              ...response.data.awaiting_pickup,
              ...response.data.expired_deliveries,
              ...response.data.out_for_delivery,
            ].slice(0, n)
          );
          // setallActive([...response.data.awaiting_agent_assignment, ...response.data.awaiting_quote, ...response.data.awaiting_pickup, ...response.data.expired_deliveries, ...response.data.out_for_delivery ])

          // setActiveDeliveries(response.data.awaiting_quote.filter(function(item){return item.total_quote_received > 0}))

          setquoteRecieved(
            response.data.awaiting_quote.filter(function (item) {
              return item.total_quote_received > 0;
            })
          );
          setawaiting_quotes(
            response.data.awaiting_quote.filter(function (item) {
              return item.total_quote_received === 0;
            })
          );
          setawaiting_pickup(response.data.awaiting_pickup);
          setawaiting_agent(response.data.awaiting_agent_assignment);
          setout_for_delivery(response.data.out_for_delivery);

          setPastDeliveries(response.data.past_deliveries);
          setExpiredDeliveries(response.data.expired_deliveries);
          setmissedDeliveries(response.data.missed_delivery);

          setMessage(response.data.enquiry_messages);
          setQuoteMessage(response.data.quote_messages);
          setNumberActive(response.data.num_of_active_deliveries);
          setNumberPast(response.data.num_of_past_deliveries);
          // setNumberMessage(response.data.num_of_messages)
          setNumberMessage(response.data.enquiry_messages.length);

          // setquoteHistory(response.data.quote_history)

          setIsloading(false);
 
            }


        })
        .catch((error) => {});
    }
  }, []);



  const [pastData, setpastData] = useState([]);
  const [showing, setshowing] = useState("complete");
  const [display, setdisplay] = useState("past");
  const [outshow, setoutshow] = useState("miss");
  const history = useHistory();
  const [activeDeliveries, setActiveDeliveries] = useState([]);
  const [allActive, setallActive] = useState([]);
  const [awaiting_quotes, setawaiting_quotes] = useState([]);
  const [awaiting_pickup, setawaiting_pickup] = useState([]);
  const [awaiting_agent, setawaiting_agent] = useState([]);
  const [out_for_delivery, setout_for_delivery] = useState([]);
  const [feedback, setfeedback] = useState([]);
  const [message, setMessage] = useState([]);
  const [account, setAccount] = useState([]);
  const [numberActive, setNumberActive] = useState(0);
  const [numberPast, setNumberPast] = useState(0);
  const [numberMessage, setNumberMessage] = useState(0);
  const [dataToShow, setdataToShow] = useState([]);
  const [activeState, setActiveState] = useState(true);
  const [pastState, setPastState] = useState(false);
  const [messageState, setMessageState] = useState(false);
  const [quoteMessage, setQuoteMessage] = useState([]);
  const [accountState, setAccountState] = useState(false);
  const [quotesState, setquotesState] = useState(false);
  const [viewState, setviewState] = useState(false);
  const [editState, seteditState] = useState(false);
  const [delivery, setdelivery] = useState({});
  const [activeStatus, setactiveStatus] = useState("all");
  const [isloading, setIsloading] = useState(true);
  const [lines, setlines] = useState(true);
  const [Quote, setQuote] = useState({});
  const [showdrop, setShowdrop] = useState(false);
  const [currentId, setcurrentId] = useState(null);

  const handleClick = (id) => {
    setShowdrop((showdrop) => !showdrop);
    setcurrentId(id);
  };

  // const handleChange = (panel) => (event, isExpanded) => {
  //     setExpanded(isExpanded ? panel : false);
  // };

  const [pastDeliveries, setPastDeliveries] = useState([]);
  const [expiredDeliveries, setExpiredDeliveries] = useState([]);
  const [missedDeliveries, setmissedDeliveries] = useState([]);

  const [showDeleting, setshowDeleting] = useState(false);
  const [deleting, setdeleting] = useState(false);
  const [itemToDelete, setitemToDelete] = useState([]);
  const [labelDelete, setlabelDelete] = useState([]);
  const [quoteRecieved, setquoteRecieved] = useState([]);

  const [quoteHistory, setquoteHistory] = useState([]);

  const [value, setValue] = useState();

  // const refreshPage = () =>{
  //     window.location.reload();

  //     console.log('clicked')
  // }
  const refresh = () => {
    // it re-renders the component
    setValue({});
  };
  const [showdropmobile, setshowdropmobile] = useState(false);
  const clickdropmobile = () => {
    setshowdropmobile((showdropmobile) => !showdropmobile);
  };
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = () => {
    setviewState(false);
    setActiveState(true);
  };
  const handleGoBack = () => {
    seteditState(false);
    setActiveState(true);
  };

  const showActive = () => {
    setActiveState(true);
    setPastState(false);
    setMessageState(false);
    setAccountState(false);
    setquotesState(false);
    setviewState(false);
    seteditState(false);
  };
  const showPast = () => {
    setActiveState(false);
    setPastState(true);
    setMessageState(false);
    setAccountState(false);
    setquotesState(false);
    setviewState(false);
    seteditState(false);
  };
  const showMessage = () => {
    setActiveState(false);
    setPastState(false);
    setMessageState(true);
    setAccountState(false);
    setquotesState(false);
    setviewState(false);
    seteditState(false);
  };
  const showAccount = () => {
    setActiveState(false);
    setPastState(false);
    setMessageState(false);
    setAccountState(true);
    setquotesState(false);
    setviewState(false);
    seteditState(false);
  };

  const endPoint = process.env.REACT_APP_API;
  const {visitingCountryCode} = useContext(ConnContext)

  const authAxios = axios.create({
    baseURL: `${endPoint}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });
  const deleteItem = (e) => {
    e.preventDefault();

    setdeleting(true);

    authAxios
      .post(`m-dillivry-listing`, {
        action_type: "delete",
        delivery_id: itemToDelete.delivery_id,
        sender_email: ls.get("eid"),
        vcc: visitingCountryCode
      }, {timeout: 240000})
      .then((res) => {
        setshowDeleting(false);
        setdeleting(false);
        // setsendingMsg(false)
        Swal.fire({
          title: "Deleted",
          text: "Item deleted!",
          icon: "success",
          confirmButtonColor: "var(--main)",
          showConfirmButton: false,
          timer: 2000,
        });
        history.go(0);
      })
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          console.log('internal-server-error-timeout')
          history.push(timeout)
          // window.location.reload()
        }
        setshowDeleting(false);
        setdeleting(false);
        Swal.fire({
          icon: "error",
          text: "error!",
          timer: 2000,
          showConfirmButton: false,
        });
        // console.log(err)
      });
  };

  // implemented on the quote click event
  const showQuote = () => {
    setActiveState(false);
    setPastState(false);
    setMessageState(false);
    setAccountState(false);
    setquotesState(true);
    setviewState(false);
    seteditState(false);
  };
  const showView = () => {
    setActiveState(false);
    setPastState(false);
    setMessageState(false);
    setAccountState(false);
    setquotesState(false);
    setviewState(true);
    seteditState(false);
  };
  const showEdit = () => {
    setActiveState(false);
    setPastState(false);
    setMessageState(false);
    setAccountState(false);
    setquotesState(false);
    setviewState(false);
    seteditState(true);
  };
  const logout = () => {
    ls.removeAll();
    history.push("/");
  };



  const displayActive = activeDeliveries.map((detail) => {
    return (
      <div key={detail.delivery_id}>
        {/* {
          console.log(detail, '....detail')
        } */}
        <div></div>
        <div className="columnBo">
          <div className="topCapture">
            <div
              className="capture"
              onClick={() => {
                setdataToShow(detail);
                setActiveState(false);
                setPastState(false);
                setMessageState(false);
                setAccountState(false);
                setquotesState(false);
                 showMe(clicked, detail.delivery_id)
                //  setviewState(true);
                seteditState(false);
                // console.log(detail, 'tocopy')
                // UpdateData(detail)
                
              }}
              style={{ cursor: "Pointer" }}
            >
              {detail.image_urls.length > 0 ? (
                <>
                  {/* {console.log(detail.image_urls)} */}
                  {/* <img src={detail.image_urls[0]} alt="image001" /> */}
                  <img src={detail.image_urls[0]} />
                </>
              ) : // <div className="camera">
              //     <div style={{width: '100px', margin: '0 auto', marginTop:"30px"}}>
              //         <input type="file"></input>
              //     </div>
              // </div>
              null}
            </div>
            <div className="info">
              <div
                className="data"
                onClick={() => {
                  // setdataToShow(detail);
                  // setActiveState(false);
                  // setPastState(false);
                  // setMessageState(false);
                  // setAccountState(false);
                  // setquotesState(false);
                  // setviewState(true);
                  // seteditState(false);
                  showMe(clicked, detail.delivery_id)
                }}
                style={{ cursor: "Pointer" }}
              >
                <span data-tip data-for="globaldescription">
                  <IconContext.Provider
                    value={{
                      color: "#2e2e2e",
                      className: "global-class-name-icon",
                    }}
                  >
                    <MdDescription
                      style={{
                        marginRight: "5px",
                        color: "var(--main)",
                        fontSize: "18px",
                      }}
                    />
                  </IconContext.Provider>

                  <ReactTooltip
                    id="globaldescription"
                    aria-haspopup="true"
                    role="example"
                    type="light"
                  >
                    <p>Item Description</p>
                  </ReactTooltip>
                </span>

                {/* <span><MdDescription style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px' }} /></span>  */}
                <span className="description">
                  {detail.item_desc.charAt(0)?.toUpperCase() +
                    detail.item_desc.toLowerCase().slice(1)}
                </span>
              </div>

              <div className="data">
                <span data-tip data-for="globalcategory">
                  <IconContext.Provider
                    value={{
                      color: "#2e2e2e",
                      className: "global-class-name-icon",
                    }}
                  >
                    <HiOutlineViewGrid
                      style={{
                        marginRight: "5px",
                        color: "var(--main)",
                        fontSize: "18px",
                      }}
                    />
                  </IconContext.Provider>

                  <ReactTooltip
                    id="globalcategory"
                    aria-haspopup="true"
                    role="example"
                    type="light"
                  >
                    <p>Item Category</p>
                  </ReactTooltip>
                </span>

                {/* <span><HiOutlineViewGrid style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px' }} /></span> */}
                <span>
                  {detail.item_category.charAt(0)?.toUpperCase() +
                    detail.item_category.slice(1).replace(/_/g, " ")}
                </span>
              </div>
              <div className="data">
                {" "}
                <span data-tip data-for="global2">
                  <IconContext.Provider
                    value={{
                      color: "#2e2e2e",
                      className: "global-class-name-icon",
                    }}
                  >
                    <MdLocationOn
                      style={{
                        marginRight: "5px",
                        color: "var(--main)",
                        fontSize: "18px",
                      }}
                    />
                  </IconContext.Provider>

                  <ReactTooltip
                    id="global2"
                    aria-haspopup="true"
                    role="example"
                    type="light"
                  >
                    <p>Pickup Address</p>
                    {/* <p>Mode of movement</p> */}
                  </ReactTooltip>

                  {/* style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px' }} /> */}
                </span>
                <span>
                  {detail.from.charAt(0)?.toUpperCase() +
                    detail.from.toLowerCase().slice(1)}
                </span>
              </div>
              <div className="data">
                {/* <span><MdLocationOn  */}
                <span data-tip data-for="global3">
                  <IconContext.Provider
                    value={{
                      color: "#2e2e2e",
                      className: "global-class-name-icon",
                    }}
                  >
                    <MdLocationOn
                      style={{
                        marginRight: "5px",
                        color: "red",
                        fontSize: "18px",
                      }}
                    />
                  </IconContext.Provider>

                  <ReactTooltip
                    id="global3"
                    aria-haspopup="true"
                    role="example"
                    type="light"
                  >
                    <p>Destination Address</p>
                    {/* <p>Mode of movement</p> */}
                  </ReactTooltip>

                  {/* style={{ marginRight: "5px", color: "red", fontSize: '18px' }} /> */}
                </span>
                <span>
                  {detail.to.charAt(0)?.toUpperCase() +
                    detail.to.toLowerCase().slice(1)}
                </span>
              </div>
              <div className="data">
                <span data-tip data-for="globaldistance">
                  <IconContext.Provider
                    value={{
                      color: "#2e2e2e",
                      className: "global-class-name-icon",
                    }}
                  >
                    <GiPathDistance
                      style={{
                        marginRight: "5px",
                        color: "var(--main)",
                        fontSize: "18px",
                      }}
                    />
                  </IconContext.Provider>

                  <ReactTooltip
                    id="globaldistance"
                    aria-haspopup="true"
                    role="example"
                    type="light"
                  >
                    <p>Distance</p>
                  </ReactTooltip>
                </span>

                {/* <span><GiPathDistance style={{ marginRight: "5px", color: "var(--main)", fontSize: '18px' }} /></span> */}
                <span>{detail.distance}</span>
              </div>
              <div className="data">
                <span data-tip data-for="globalMode">
                  <IconContext.Provider
                    value={{
                      color: "#2e2e2e",
                      className: "global-class-name-icon",
                    }}
                  >
                    <RiTruckLine
                      style={{
                        marginRight: "5px",
                        marginTop: "-3px",
                        color: "var(--main)",
                        fontSize: "18px",
                      }}
                    />
                  </IconContext.Provider>

                  <ReactTooltip
                    id="globalMode"
                    aria-haspopup="true"
                    role="example"
                    type="light"
                  >
                    <p>Mode of Delivery</p>
                  </ReactTooltip>
                </span>

                <span>
                  { detail.mode_of_delivery === null ? 'NA' :    detail.mode_of_delivery === "moving_home"
                    ? "Moving home"
                    : detail.mode_of_delivery.charAt(0)?.toUpperCase() +
                      detail.mode_of_delivery.toLowerCase().slice(1)}
                </span>
              </div>
            </div>
          </div>

          <div className="timeAction">
            <div className="time" style={{ display: "block" }}>
              {detail.delivery_status === "awaiting_quote" ? (
                detail.total_quote_received !== 0 ? (
                  <div
                    className="aQuote"
                    onClick={() => {
                      setQuote(detail.quote_detail);
                      setdelivery(detail);
                      setActiveState(false);
                      setPastState(false);
                      setMessageState(false);
                      setAccountState(false);
                      setquotesState(true);
                      setviewState(false);
                      seteditState(false);
                    }}
                  >
                    {" "}
                    View Quotes &nbsp;
                    <div className="cQuote1">{detail.total_quote_received}</div>
                    {/* <div className="cQuote">{detail.total_quote_received }</div> */}
                  </div>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    You have not received any quotes. Don't worry, we will email
                    them to you as soon as they arrive.
                  </div>
                )
              ) : (
                <>
                  {detail.delivery_status === "awaiting_agent_assignment" ? (
                    <>
                      {" "}
                      Delivery status:&nbsp;
                      {detail.delivery_status === "awaiting_agent_assignment"
                        ? "Awaiting agent Assignment"
                        : detail.delivery_status}
                      <br></br>
                      Payment status:&nbsp;
                      {detail.payment_status === "success" ? (
                        "Paid"
                      ) : detail.payment_status === "pay_on_delivery" ? (
                        "Pay on Delivery"
                      ) : detail.payment_status === "NA" ? (
                        <span>
                          {" "}
                          <span>
                            <span data-tip data-for="global">
                              <IconContext.Provider
                                value={{
                                  color: "#2e2e2e",
                                  className: "global-class-name-icon",
                                }}
                              >
                                Offline&nbsp;
                                <AiOutlineInfoCircle
                                  style={{ color: "green" }}
                                />
                              </IconContext.Provider>

                              <ReactTooltip
                                id="global"
                                aria-haspopup="true"
                                role="example"
                                type="light"
                              >
                                <p>
                                  Arranged payment between the sender and
                                  provider
                                </p>
                                {/* <p>Mode of movement</p> */}
                              </ReactTooltip>
                            </span>
                          </span>
                        </span>
                      ) : (
                        // <button disabled

                        //     style={{ border: "none", marginTop: "5px", backgroundColor: "#50B14A", color: "#FFFFFF", padding: "5px 5px 5px 5px", borderRadius: "4px", fontSize: "12px" }}> Book delivery arranged  <br></br>with provider</button>

                        detail.payment_status
                      )}
                      <br></br>
                      {detail.payment_status === "pay_on_delivery" ? (
                        <>
                          {/* <button style={{backgroundColor:"#50B14A",borderRadius:"4px", border:"none",color:"#FFFFFF",width:"100px",height:"20px",marginTop:"5px"}}>Make Payment</button>*/}
                          <div style={{ marginLeft: "-40px" }}>
                            <PaystackWidget2
                              amount={detail.quote_amount + "00"} //for paystack
                              amount_paid={detail.quote_amount}
                              fulfiller_id={detail.fulfiller_id}
                              delivery_id={detail.delivery_id}
                              email={ls.get("eid")}
                              closePopup={() => console.log("loading")}
                            />
                          </div>
                        </>
                      ) : (
                        <> </>
                      )}
                    </>
                  ) : detail.delivery_status === "awaiting_pickup" ? (
                    <>
                      Delivery status:&nbsp;
                      {detail.delivery_status === "awaiting_pickup"
                        ? "Awaiting pickup"
                        : detail.delivery_status}
                      <br></br>
                      Payment status:&nbsp;
                      {detail.payment_status === "success" ? (
                        "Paid"
                      ) : detail.payment_status === "pay_on_delivery" ? (
                        "Pay on Delivery"
                      ) : detail.payment_status === "NA" ? (
                        <span>
                          {" "}
                          <span>
                            <span data-tip data-for="global">
                              <IconContext.Provider
                                value={{
                                  color: "#2e2e2e",
                                  className: "global-class-name-icon",
                                }}
                              >
                                Offline&nbsp;
                                <AiOutlineInfoCircle />
                              </IconContext.Provider>

                              <ReactTooltip
                                id="global"
                                aria-haspopup="true"
                                role="example"
                                type="light"
                              >
                                <p>
                                  Arranged payment between the sender and
                                  provider
                                </p>
                                {/* <p>Mode of movement</p> */}
                              </ReactTooltip>
                            </span>
                          </span>
                        </span>
                      ) : (
                        detail.payment_status
                      )}
                      <br></br>
                      {detail.payment_status === "pay_on_delivery" ? (
                        <>
                          {/* <button style={{backgroundColor:"#50B14A",borderRadius:"4px", border:"none",color:"#FFFFFF",width:"100px",height:"20px",marginTop:"5px"}}>Make Payment</button>*/}
                          <div style={{ marginLeft: "-40px" }}>
                            <PaystackWidget2
                              amount={detail.quote_amount + "00"} //for paystack
                              amount_paid={detail.quote_amount}
                              fulfiller_id={detail.fulfiller_id}
                              delivery_id={detail.delivery_id}
                              email={ls.get("eid")}
                              closePopup={() => console.log("loading")}
                            />
                          </div>
                        </>
                      ) : (
                        <> </>
                      )}
                    </>
                  ) : detail.delivery_status === "out_for_delivery" ? (
                    <>
                      {" "}
                      Delivery status:&nbsp;
                      {detail.delivery_status === "out_for_delivery"
                        ? "In transit"
                        : detail.delivery_status}
                      <br></br>
                      Payment status:&nbsp;
                      {detail.payment_status === "success" ? (
                        "Paid"
                      ) : detail.payment_status === "pay_on_delivery" ? (
                        "Pay on Delivery"
                      ) : detail.payment_status === "NA" ? (
                        <span>
                          {" "}
                          <span>
                            <span data-tip data-for="global">
                              <IconContext.Provider
                                value={{
                                  color: "#2e2e2e",
                                  className: "global-class-name-icon",
                                }}
                              >
                                Offline&nbsp;
                                <AiOutlineInfoCircle />
                              </IconContext.Provider>

                              <ReactTooltip
                                id="global"
                                aria-haspopup="true"
                                role="example"
                                type="light"
                              >
                                <p>
                                  Arranged payment between the sender and
                                  provider
                                </p>
                                {/* <p>Mode of movement</p> */}
                              </ReactTooltip>
                            </span>
                          </span>
                        </span>
                      ) : (
                        detail.payment_status
                      )}
                      <br></br>
                      {detail.payment_status === "pay_on_delivery" ? (
                        <>
                          {/* <button style={{backgroundColor:"#50B14A",borderRadius:"4px", border:"none",color:"#FFFFFF",width:"100px",height:"20px",marginTop:"5px"}}>Make Payment</button>*/}
                          <div style={{ marginLeft: "-40px" }}>
                            <PaystackWidget2
                              amount={detail.quote_amount + "00"} //for paystack
                              amount_paid={detail.quote_amount}
                              fulfiller_id={detail.fulfiller_id}
                              delivery_id={detail.delivery_id}
                              email={ls.get("eid")}
                              closePopup={() => console.log("loading")}
                            />
                          </div>
                        </>
                      ) : (
                        <> </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}

             
              {/* {console.log(detail)}
              {console.log(
                detail.delivery_id,
                "detailssss line 949",
                detail.sender_email,
                "sender_email"
              )} */}

              {detail.delivery_status === "out_for_delivery" ? (
                <button
                  className="itmclick"
                  onClick={() => {
                    if (ls.get("_utk") === "") {
                      history.push(login);
                    } else {
                      history.push(`tracking/${detail.delivery_id}`);

                      setshowlogin(false);
                    }
                  }}
                  style={{
                    backgroundColor: "#50B14A",
                    border: "1px solid #50B14A",
                    borderRadius: "4px",
                    color: "#FFFFFF",
                    padding: "5px 5px 2px 5px",
                    cursor: "pointer",
                    marginTop: "5px",
                  }}
                >
                  Track item
                </button>
              ) : (
                <></>
              )}

              {detail.delivery_status === "out_for_delivery" ? (
                <div>
                  {showdrop && currentId === detail.delivery_id ? (
                    <p>
                      <img
                        src={cursworking}
                        alt="tra"
                        style={{
                          width: "25px",
                          height: "30px",
                          paddingTop: "15px",
                        }}
                      />{" "}
                      &nbsp;Item position:
                    </p>
                  ) : null}
                </div>
              ) : (
                <div></div>
              )}
            </div>

            {detail.delivery_status === "awaiting_quote" ? (
              <div className="actions">
                <div
                  className="customBtnUp"
                  onClick={() => {
                    setdataToShow(detail);
                    setActiveState(false);
                    setPastState(false);
                    setMessageState(false);
                    setAccountState(false);
                    setquotesState(false);
                    // setviewState(true);
                    seteditState(false);
                    showMe(clicked, detail.delivery_id)
                  }}
                >
                  <img
                    src={bd1}
                    alt="bd1_icon"
                    style={{ marginRight: "10px" }}
                  />
                  View 
                </div>
                <div
                  className="customBtn"
                  onClick={() => {
                    setdataToShow(detail);
                    setActiveState(false);
                    setPastState(false);
                    setMessageState(false);
                    setAccountState(false);
                    setquotesState(false);
                    setviewState(false);
                    seteditState(true);
                  }}
                >
                  <BiEdit style={{ marginRight: "10px" }} />
                  Edit
                </div>

                <div
                  className="customBtnDown"
                  onClick={() => {
                    setshowDeleting(true);
                    setitemToDelete(detail);
                  }}
                >
                  <RiDeleteBin3Line style={{ marginRight: "10px" }} />
                  <span> Delete </span>

                  {/* {
                                            sendingMsg ?
                                                <PuffLoader color='white' loading={sendingMsg} speedMultiplier={1} size={16} />
                                                :
                                                <>
                                                    <span>Delete </span> 
                                                </>
                                        } */}
                </div>
              </div>
            ) : (
              <div className="actions">
                <div
                  className="customBtnUp"
                  onClick={() => {
                    setdataToShow(detail);
                    setActiveState(false);
                    setPastState(false);
                    setMessageState(false);
                    setAccountState(false);
                    setquotesState(false);
                      //  setviewState(true);
                    seteditState(false);
                      showMe(clicked, detail.delivery_id)
                  }}
                >
                  {" "}
                  <img
                    src={bd1}
                    alt="bd1_icon"
                    style={{ marginRight: "10px" }}
                  />
                  View 
                </div>
                <div
                  className="customBtn"
                  style={{ backgroundColor: "#EAEAEA" }}
                  onClick={() => {
                    setshowEditinfo(true);
                    // setitemToDelete(detail);
                  }}
                >
                  <BiEdit style={{ marginRight: "10px" }} />
                  Edit
                </div>

                <div
                className="customBtnDown"
                  style={{ backgroundColor: "#EAEAEA" }}
                  onClick={() => {
                    setshowDeletinfo(true);
                    // setitemToDelete(detail);
                  }}
                >
                  <RiDeleteBin3Line style={{ marginRight: "10px" }} />
                  Delete
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      <div className="page">
        {/* <TopMenu/> */}
        <div className="topmenu showTopMenuDesktop">
          {isloading ? (
            <>
              <Box
                sx={{ width: "100%", marginBottom: "10px", marginTop: "4px" }}
              >
                <LinearProgress color="success" />
              </Box>
            </>
          ) : (
            <></>
          )}
          <Nav />
        </div>
        <div className="ind-nav">
          <IndividualSidebar />
          <div className="logocont">
            <img
              src={imagelogo}
              alt="dillivry"
              onClick={() => history.push("/")}
            />
          </div>
        </div>

        <div className="viewmenudesktop">
          {viewState ? null : (
            <div className="view_menu">
              <div
                className={activeState ? "activeTab" : "tabs"}
                onClick={() => {
                  showActive();
                  refresh();
                  activedilivryurl()
                  //  history.push("/activedillivry")
                }}
              >
                {" "}
                <span>
                  <FaCarSide />
                </span>
                &ensp;Active Deliveries
              </div>
              <div
                className={pastState ? "activeTab" : " tabs"}
                onClick={() => {
                //    showPast();
                   pastdilvryurl()
                  // refresh();
                  //  history.push("/pastdelivery")
                }}
              >
                {" "}
                <span>
                  <WiTime4 />
                </span>{" "}
                &ensp; Past Deliveries 
              </div>
              <div
                className={messageState ? "activeTab" : "tabs"}
                onClick={ () => {
                   showMessage();
                  // showMessageurl()
                  history.push(sendermessage)
                }
                }
                
              >
                {" "}
                <span>
                  <GrMail />
                </span>{" "}
                &ensp; Messages 
                {numberMessage > 0 ? (
                  <div className="mQuote">{numberMessage}</div>
                ) : null}
              </div>

              <div
                className={accountState ? "activeTab" : "tabs"}
                onClick={
                  // showAccount
                  ()=>{
                    history.push(acctLink)
                }
                }
              >
                {" "}
                <span>
                  <BsFillPersonFill />
                </span>{" "}
                &ensp; Account
              </div>
            </div>
          )}
        </div>

        <div className="viewmenumobile">
          {viewState ? null : (
            <div className="view_menu">
              <div style={{ width: "200px" }} onClick={clickdropmobile}>
                <p>
               
                  <BiDotsVerticalRounded
                    style={{ height: "30px", width: "30px", color: "green" }}
                  />
                </p>
                {showdropmobile ? (
                  <>
                    <div
                      style={{ position: "fixed", top: "130px", zIndex: "200" }}
                    >
                      {activeState ? (
                        <div>
                          <div className="columnHead">My Dilivry</div>
                          <div
                            className="columnBody2"
                            style={{ backgroundColor: "white" }}
                          >
                            <div
                              className={
                                activeStatus === "quotes" ? "qutActive" : "qut"
                              }
                              onClick={() => {
                                // setactiveStatus("quotes");
                                // setActiveDeliveries(quoteRecieved);
                                // setDeefault(quoteRecieved);
                                // setitemLength(quoteRecieved.length);
                                // refresh();
                                setactiveStatus("quotes");
                                //  setActiveDeliveries(quoteRecieved);
                                //  setDeefault(quoteRecieved);
                                //  setitemLength(quoteRecieved.length);
                                //  refresh();
                                 setclicked("quotes")
                                history.push(`/activedilivry/receivedquotes`)
                              }}
                            >
                              Recieved Quotes 
                            </div>

                            <div
                              className={
                                activeStatus === "awaiting_quotes"
                                  ? "qutActive"
                                  : "qut"
                              }
                              onClick={() => {
                                // setactiveStatus("awaiting_quotes");
                                // setActiveDeliveries(awaiting_quotes);
                                // setDeefault(awaiting_quotes);
                                // setitemLength(awaiting_quotes.length);
                                // refresh();
                                setactiveStatus("awaiting_quotes");
                                // setActiveDeliveries(awaiting_quotes);
                                setDeefault(awaiting_quotes);
                                // setitemLength(awaiting_quotes.length);
                                // refresh();
                                 setclicked("awaitingquotes");
                                history.push(`/activedilivry/awaitingquote`)
                              }}
                            >
                              {" "}
                              Awaiting Quotes
                            </div>

                            <div
                              className={
                                activeStatus === "awaiting_agent"
                                  ? "qutActive"
                                  : "qut"
                              }
                              onClick={() => {
                                // setactiveStatus("awaiting_agent");
                                // setActiveDeliveries(awaiting_agent);
                                // setDeefault(awaiting_agent);
                                // setitemLength(awaiting_agent.length);
                                // refresh();
                                setactiveStatus("awaiting_agent");
                                // setActiveDeliveries(awaiting_agent);
                                 setDeefault(awaiting_agent);
                                // setitemLength(awaiting_agent.length);
                                // refresh();
                                history.push(`/activedilivry/awaitingagent`)
                                 setclicked("awaitingagent")
                              }}
                            >
                              {" "}
                              Awaiting Agent Assignment
                            </div>

                            <div
                              className={
                                activeStatus === "awaiting_pickup"
                                  ? "qutActive"
                                  : "qut"
                              }
                              onClick={() => {
                                // setactiveStatus("awaiting_pickup");
                                // setActiveDeliveries(awaiting_pickup);
                                // setDeefault(awaiting_pickup);
                                // setitemLength(awaiting_pickup.length);
                                // refresh();
                                setactiveStatus("awaiting_pickup");
                                //setActiveDeliveries(awaiting_pickup);
                                setDeefault(awaiting_pickup);
                                //setitemLength(awaiting_pickup.length);
                                //refresh();
        
        
        
                                history.push(`/activedilivry/awaitingpickup`)
                                setclicked("awaitingpickup")
                              }}
                            >
                              {" "}
                              Awaiting Pickup
                            </div>

                            <div
                              className={
                                activeStatus === "out_for_delivery"
                                  ? "qutActive"
                                  : "qut"
                              }
                              onClick={() => {
                                setactiveStatus("out_for_delivery");
                                // setActiveDeliveries(out_for_delivery);
                                // setDeefault(out_for_delivery);
                                // setitemLength(out_for_delivery.length);
                                // refresh();
                                refresh();
                                history.push(`/activedilivry/outfordelivery`)
                                setclicked("outfordelivery")
                              }}
                            >
                              {" "}
                              Out For Delivery
                            </div>

                            <div
                              className={
                                activeStatus === "feedback"
                                  ? "qutActive"
                                  : "qut"
                              }
                              // onClick={()=>{ setactiveStatus('feedback')
                              //     }}
                              onClick={() => history.push(linkUrl)}
                            >
                              Leave Feedback
                            </div>

                            <div
                              className="qut"
                              style={{ color: "#C54B4B" }}
                              onClick={() => {
                                setShowLogout(true);
                              }}
                            >
                              {/* onClick={logout}> */}
                              Log out
                            </div>
                          </div>
                        </div>
                      ) : pastState ? (
                        <>
                          <div className="column">
                            <div className="columnHead">My Dilivry</div>
                            <div
                              className="columnBody2"
                              style={{ backgroundColor: "white" }}
                            >
                              <div
                                className={
                                  showing === "complete" ? "qutActive" : "qut"
                                }
                                style={{ paddingLeft: "18px" }}
                                onClick={functionToCall}

                              >
                                {" "}
                                Complete Deliveries 2
                              </div>
                              <div
                                className={
                                  showing === "expire" ? "qutActive" : "qut"
                                }
                                style={{ paddingLeft: "18px" }}
                                onClick={functionToCall1}
                              >
                                {" "}
                                Expired Deliveries
                              </div> 
                              <div
                                className={
                                  showing === "missed" ? "qutActive" : "qut"
                                }
                                style={{ paddingLeft: "18px" }}
                                 onClick={functionToCall2}
                              >
                                {" "}
                                Missed Deliveries{" "}
                              </div>
                              <div
                                className="qut"
                                style={{ paddingLeft: "18px" }}
                                onClick={() =>
                                  history.push(linkUrl)
                                }
                              >
                                {" "}
                                Leave Feedback
                              </div>
                              <div
                                className="qut"
                                style={{
                                  color: "#C54B4B",
                                  paddingLeft: "18px",
                                }}
                                onClick={() => {
                                  setShowLogout(true);
                                }}
                              >
                                Log out
                              </div>
                            </div>
                          </div>
                        </>
                      ) : messageState ? null : accountState ? null : null}
                    </div>
                  </>
                ) : null}
              </div>

              <div
                className={activeState ? "activeTab" : "tabs"}
                onClick={() => {
                  // showActive();
                  // refresh();
                  // history.push(`/activedlvry//${complete}`)
                  history.push("/activedilivry//active");
                }}
              >
                {" "}
                <span>
                  <FaCarSide />
                </span>
              </div>
              <div
                className={pastState ? "activeTab" : "tabs"}
                onClick={() => {
                  // showPast();
                  // refresh();
                  history.push(`/pastdelivery//${complete}`)
                }}
              >
                {" "}
                <span>
                  <WiTime4 />
                </span>
              </div>
              <div
                className={messageState ? "activeTab" : "tabs"}
                onClick={()=> history.push(sendermessage)}
              >
                {" "}
                <span>
                  <GrMail />
                </span>{" "}
                &ensp;
                {numberMessage > 0 ? (
                  <div className="mQuote">{numberMessage}</div>
                ) : null}
              </div>

              <div
                className={accountState ? "activeTab" : "tabs"}
                onClick={()=> history.push(acctLink)}
              >
                {" "}
                <span>
                  <BsFillPersonFill />
                </span>
              </div>
            </div>
          )}
        </div>

        {activeState ? (
          <>
            <div className="context-past">
              <div className="context-past-left">
                <div className="column">
                  <div className="columnHead">My Dilivry</div>
                  <div className="columnBody2">
                    <div
                      className={
                        activeStatus === "quotes" ? "qutActive" : "qut"
                      }
                      onClick={() => {
                        setactiveStatus("quotes");
                        //  setActiveDeliveries(quoteRecieved);
                        //  setDeefault(quoteRecieved);
                        //  setitemLength(quoteRecieved.length);
                        //  refresh();
                         setclicked("quotes")
                        history.push(`/activedilivry/receivedquotes`)
                        // setactiveStatus("quotes");
                        // setActiveDeliveries(quoteRecieved);
                        // setDeefault(quoteRecieved);
                        // setitemLength(quoteRecieved.length);
                        // refresh();
                        // setclicked("quotes")
                      }}
                    >
                      Recieved Quotes 
                    </div>

                    <div
                      className={
                        activeStatus === "awaiting_quotes" ? "qutActive" : "qut"
                      }
                      onClick={() => {
                        setactiveStatus("awaiting_quotes");
                        // setActiveDeliveries(awaiting_quotes);
                        setDeefault(awaiting_quotes);
                        // setitemLength(awaiting_quotes.length);
                        // refresh();
                         setclicked("awaitingquotes");
                        history.push(`/activedilivry/awaitingquote`)
                        // setactiveStatus("awaiting_quotes");
                        // setActiveDeliveries(awaiting_quotes);
                        // setDeefault(awaiting_quotes);
                        // setitemLength(awaiting_quotes.length);
                        // refresh();
                        // setclicked("awaitingquotes");
                      }}
                    >
                      {" "}
                      Awaiting Quotes
                    </div>

                    <div
                      className={
                        activeStatus === "awaiting_agent" ? "qutActive" : "qut"
                      }
                      onClick={() => {
                        // setactiveStatus("awaiting_agent");
                        // setActiveDeliveries(awaiting_agent);
                        // setDeefault(awaiting_agent);
                        // setitemLength(awaiting_agent.length);
                        // refresh();
                        // setclicked("awaitingagent")
                        setactiveStatus("awaiting_agent");
                        // setActiveDeliveries(awaiting_agent);
                         setDeefault(awaiting_agent);
                        // setitemLength(awaiting_agent.length);
                        // refresh();
                        history.push(`/activedilivry/awaitingagent`)
                         setclicked("awaitingagent")
                      }}
                    >
                      {" "}
                      Awaiting Agent Assignment
                    </div>

                    <div
                      className={
                        activeStatus === "awaiting_pickup" ? "qutActive" : "qut"
                      }
                      onClick={() => {
                        setactiveStatus("awaiting_pickup");
                        //setActiveDeliveries(awaiting_pickup);
                        setDeefault(awaiting_pickup);
                        //setitemLength(awaiting_pickup.length);
                        //refresh();



                        history.push(`/activedilivry/awaitingpickup`)
                        setclicked("awaitingpickup")
                        // setactiveStatus("awaiting_pickup");
                        // setActiveDeliveries(awaiting_pickup);
                        // setDeefault(awaiting_pickup);
                        // setitemLength(awaiting_pickup.length);
                        // refresh();
                        // setclicked("awaitingpickup")
                      }}
                    >
                      {" "}
                      Awaiting Pickup
                    </div>

                    <div
                      className={
                        activeStatus === "out_for_delivery"
                          ? "qutActive"
                          : "qut"
                      }
                      onClick={() => {
                        // setactiveStatus("out_for_delivery");
                        // setActiveDeliveries(out_for_delivery);
                        // setDeefault(out_for_delivery);
                        // setitemLength(out_for_delivery.length);
                        // refresh();
                        // setclicked("outfordelivery")
                        setactiveStatus("out_for_delivery");
                        // setActiveDeliveries(out_for_delivery);
                        // setDeefault(out_for_delivery);
                        // setitemLength(out_for_delivery.length);
                        refresh();
                        history.push(`/activedilivry/outfordelivery`)
                        setclicked("outfordelivery")
                      }}
                    >
                      {" "}
                      Out For Delivery
                    </div>

                    <div
                      className={
                        activeStatus === "feedback" ? "qutActive" : "qut"
                      }
                      // onClick={()=>{ setactiveStatus('feedback')
                      //     }}
                      onClick={() => history.push(linkUrl)}
                    >
                      Leave Feedback
                    </div>

                    <div
                      className="qut"
                      style={{ color: "#C54B4B" }}
                      // onClick={logout}>
                      onClick={() => {
                        setShowLogout(true);
                      }}
                    >
                      Log out
                    </div>
                  </div>
                </div>
              </div>

              <div className="context-past-right">
                <div className="column">
                  <div
                    className="columnHead"
                    style={{
                      backgroundColor: "#F9F9F9",
                      borderBottom: "solid rgb(230, 224, 224) 1px",
                      justifyContent: "space-between",
                    }}
                  >
                    {activeStatus === "all"
                      ? "Active Quotes request"
                      : activeStatus === "quotes"
                      ? "Recieved Quotes"
                      : activeStatus === "awaiting_quotes"
                      ? "Awaiting Quotes"
                      : activeStatus === "awaiting_agent"
                      ? "Awaiting Agent Assignment"
                      : activeStatus === "awaiting_pickup"
                      ? "Awaiting Pickup"
                      : activeStatus === "out_for_delivery"
                      ? "Out For Delivery"
                      : activeStatus === "feedback"
                      ? "Feedback"
                      : null}
                  

                    <div>
             
                      <div className="viewmenudesktop">
                        <span
                          className="paginateButtons"
                          style={{
                            color: paginateActivePage === 1 ? "white" : "",
                            backgroundColor:
                              paginateActivePage === 1 ? "var(--main)" : "",
                            cursor:
                              paginateActivePage === 1
                                ? "not-allowed"
                                : "pointer",
                          }}
                          onClick={() => {
                            setActiveDeliveries(deefault.slice(0, n));
                            setPaginateActivePage(1);
                          }}
                        >
                          First
                        </span>
                        {paginateActivePage === 1 ? (
                          <span
                            className="paginateButtons"
                            style={{ color: "#989898", cursor: "not-allowed" }}
                          >
                            Previous
                          </span>
                        ) : (
                          <span
                            className="paginateButtons"
                            onClick={() => {
                              setActiveDeliveries(
                                deefault.slice(
                                  (paginateActivePage - 2) * n,
                                  (paginateActivePage - 1) * n
                                )
                              );
                              setPaginateActivePage(paginateActivePage - 1);
                            }}
                          >
                            Previous
                          </span>
                        )}

                        {[...Array(Math.ceil(itemLength / n))].map((e, i) => (
                          <span
                            className="paginateButtons"
                            style={{
                              backgroundColor:
                                paginateActivePage === i + 1
                                  ? "var(--main)"
                                  : "",
                              color:
                                paginateActivePage === i + 1 ? "white" : "",
                            }}
                            key={i}
                            onClick={() => {
                              setActiveDeliveries(
                                deefault.slice(n * i, n * i + n)
                              );
                              setPaginateActivePage(i + 1);
                            }}
                          >
                            {i + 1}
                          </span>
                        ))}
                        {paginateActivePage === Math.ceil(itemLength / n) ? (
                          <span
                            className="paginateButtons"
                            style={{ color: "#989898", cursor: "not-allowed" }}
                          >
                            Next
                          </span>
                        ) : (
                          <span
                            className="paginateButtons"
                            onClick={() => {
                              setActiveDeliveries(
                                deefault.slice(
                                  n * paginateActivePage,
                                  n * (paginateActivePage + 1)
                                )
                              );
                              setPaginateActivePage(paginateActivePage + 1);
                            }}
                          >
                            Next
                          </span>
                        )}
                        <span
                          className="paginateButtons"
                          style={{
                            backgroundColor:
                              paginateActivePage === Math.ceil(itemLength / n)
                                ? "var(--main)"
                                : "",
                            color:
                              paginateActivePage === Math.ceil(itemLength / n)
                                ? "white"
                                : "",
                          }}
                          onClick={() => {
                            setActiveDeliveries(
                              deefault.slice(
                                Math.floor(itemLength / n) * n,
                                itemLength
                              )
                            );
                            setPaginateActivePage(Math.ceil(itemLength / n));
                          }}
                        >
                          Last
                        </span>
                      </div>

                      {/* </div> */}
                    </div>
                  </div>
                  {activeDeliveries.length === 0 ? (
                    <div className="columnBody1"></div>
                  ) : (
                    <>{displayActive}</>
                  )}
                
                  <div
                    className="viewmenumobile"
                    style={{
                      border: "1px solid #EAEAEA",
                      height: "44px",
                      backgroundColor: "#F9F9F9",
                      marginRight: "0px",
                      marginLeft: "0px",
                      padding: "13px 25px 10px 25px",
                    }}
                  >
                    <div>
                      <span
                        className="paginateButtons"
                        style={{
                          color: paginateActivePage === 1 ? "white" : "",
                          backgroundColor:
                            paginateActivePage === 1 ? "var(--main)" : "",
                          cursor:
                            paginateActivePage === 1
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() => {
                          setActiveDeliveries(deefault.slice(0, n));
                          setPaginateActivePage(1);
                        }}
                      >
                        First
                      </span>
                      {paginateActivePage === 1 ? (
                        <span
                          className="paginateButtons"
                          style={{ color: "#989898", cursor: "not-allowed" }}
                        >
                          Previous
                        </span>
                      ) : (
                        <span
                          className="paginateButtons"
                          onClick={() => {
                            setActiveDeliveries(
                              deefault.slice(
                                (paginateActivePage - 2) * n,
                                (paginateActivePage - 1) * n
                              )
                            );
                            setPaginateActivePage(paginateActivePage - 1);
                          }}
                        >
                          Previous
                        </span>
                      )}

                      {[...Array(Math.ceil(itemLength / n))].map((e, i) => (
                        <span
                          className="paginateButtons"
                          style={{
                            backgroundColor:
                              paginateActivePage === i + 1 ? "var(--main)" : "",
                            color: paginateActivePage === i + 1 ? "white" : "",
                          }}
                          key={i}
                          onClick={() => {
                            setActiveDeliveries(
                              deefault.slice(n * i, n * i + n)
                            );
                            setPaginateActivePage(i + 1);
                          }}
                        >
                          {i + 1}
                        </span>
                      ))}
                      {paginateActivePage === Math.ceil(itemLength / n) ? (
                        <span
                          className="paginateButtons"
                          style={{ color: "#989898", cursor: "not-allowed" }}
                        >
                          Next
                        </span>
                      ) : (
                        <span
                          className="paginateButtons"
                          onClick={() => {
                            setActiveDeliveries(
                              deefault.slice(
                                n * paginateActivePage,
                                n * (paginateActivePage + 1)
                              )
                            );
                            setPaginateActivePage(paginateActivePage + 1);
                          }}
                        >
                          Next
                        </span>
                      )}
                      <span
                        className="paginateButtons"
                        style={{
                          backgroundColor:
                            paginateActivePage === Math.ceil(itemLength / n)
                              ? "var(--main)"
                              : "",
                          color:
                            paginateActivePage === Math.ceil(itemLength / n)
                              ? "white"
                              : "",
                        }}
                        onClick={() => {
                          setActiveDeliveries(
                            deefault.slice(
                              Math.floor(itemLength / n) * n,
                              itemLength
                            )
                          );
                          setPaginateActivePage(Math.ceil(itemLength / n));
                        }}
                      >
                        Last
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {pastState ? (
              <PastDelivery
                expired={expiredDeliveries}
                past={pastDeliveries}
                missed={missedDeliveries}
                createFunctionToCall2={createFunctionToCall2}
                createFunctionToCall1={createFunctionToCall1 }
                createFunctionToCall={createFunctionToCall}
              />
              // <h1>Hello</h1>
            ) : (
              <>
                {messageState ? (
                  <MessageSender
                    msg={quoteMessage}
                    handleChange={handleChange}
                  />
                ) : (
                  <>
                    {accountState ? (
                      <Account user_info={account} />
                    ) : quotesState ? (
                      <AcceptQuote
                        quoteInfos={Quote}
                        deliveryDetail={delivery}
                        handleChange={handleChange}
                      />
                    ) : viewState ? (
                      <>
                        <div className="showMobileQuote">
                          <ShowQuote1
                            data={dataToShow}
                            handleChange={handleChange}
                          />
                        </div>
                        <div className="showDeskQuote">
                          <ShowQuoteDesk
                            data={dataToShow}
                            handleChange={handleChange}
                          />
                        </div>
                      </>
                    ) : editState ? (
                      <>
                        <EditQuote
                          delivery_data={dataToShow}
                          handleChange={handleGoBack}
                        />
                      </>
                    ) : null}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>

      <DialogPop
        openPopup={showDeleting}
        setopenPopup={setshowDeleting}
        // head="Delete Item"
      >
        <div className="quote_div">
          <p style={{ fontSize: "15px" }}>
            {" "}
            Are you sure you want to delete this item?
          </p>
          {
            <form style={{ display: "flex", width: "100%" }}>
              <div className="dialogdiv">
                <button
                  className="dialodivbt"
                  type="button"
                  style={{
                    cursor: "pointer",
                    color: "#FFFFFF",
                    backgroundColor: "#50B14A",
                    border: "1px solid #50B14A",
                  }}
                >
                  {" "}
                  No
                </button>{" "}
                &nbsp; &nbsp;
                {deleting ? (
                  <button
                    className="dialodivbt"
                    type="button"
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "grey",
                      border: "1px solid grey",
                    }}
                  >
                    <PuffLoader
                      color="white"
                      loading={deleting}
                      speedMultiplier={1}
                      size={16}
                    />
                  </button>
                ) : (
                  <button
                    className="dialodivbt"
                    type="button"
                    onClick={deleteItem}
                    style={{
                      cursor: "pointer",
                      color: "#FFFFFF",
                      backgroundColor: "grey",
                      border: "1px solid grey",
                    }}
                  >
                    Yes
                  </button>
                )}
              </div>
             
            </form>
          }
        </div>
      </DialogPop>

     
      <DialogSimplePop
        openPopup={showDeletinfo}
        btnText="Ok"
        onClick={() => setshowDeletinfo(false)}
      >
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <img src={oops} alt="dillivry" style={{ width: "60px" }} />
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "500",
            width: "100%",
            marginTop: "10px",
          }}
        >
          You can't delete delivery in this state.<br></br>
          Please contact
          <span
            style={{ color: "rgb(80, 177, 74)", cursor: "pointer" }}
            onClick={() => {
              setshowDeletinfo(false);
              setshowHelp(!showHelp);
            }}
          >
            {" "}
            customer care
          </span>{" "}
          for support.
        </div>
      </DialogSimplePop>

      {showHelp ? (
        <HomeHelp showDialog={showHelp} onClose={() => setshowHelp(false)} />
      ) : null}
      {/* edit dailogbox */}

      <DialogSimplePop
        openPopup={showEditinfo}
        btnText="Ok"
        onClick={() => setshowEditinfo(false)}
      >
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <img src={oops} alt="dillivry" style={{ width: "60px" }} />
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "500",
            width: "100%",
            marginTop: "10px",
          }}
        >
          You can't edit delivery in this state.<br></br>
          Please contact
          <span
            style={{ color: "rgb(80, 177, 74)", cursor: "pointer" }}
            onClick={() => {
              setshowEditinfo(false);
              setshowHelp(!showHelp);
            }}
          >
            {" "}
            customer care
          </span>{" "}
          for support.
        </div>
      </DialogSimplePop>

      <DialogOutClick
        open={showLogout}
        handleClose={() => setShowLogout(false)}
      >
        <div style={{ width: "100%", padding: "30px 30px 20px 30px" }}>
          <div
            style={{
              width: "100%",
              marginBottom: "30px",
              fontSize: "13px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>Are you sure you want to logout?</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="disBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "#FFFFFF",
                backgroundColor: "#50B14A",
                border: "1px solid #50B14A",
              }}
              onClick={() => setShowLogout(false)}
            >
              No
            </div>
            <div
              className="ediBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "#FFFFFF",
                backgroundColor: "grey",
                border: "1px solid grey",
              }}
              // onClick={logMeOut}>
              onClick={logout}
            >
              Yes
            </div>
          </div>
        </div>
      </DialogOutClick>

      {showlogin ? (
        <DialogOutClick
          open={showlogin}
          handleClose={() => setshowlogin(!showlogin)}
        >
          <QuoteSignInForm
            onClose={() => {
              setshowlogin(!showlogin);
            }}
          />
        </DialogOutClick>
      ) : null}
    </div>
  );
}
export default ActiveDilivry;
