import {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import Footer from '../../components/Footer/Footer';
import DownloadApp from '../../components/DownloadApp';
import Nav from '../../components/Nav/NavHome';

export default function AgentDashboar() {

  useEffect(() => {
    window.scrollTo(0, 0);
}, [])

const history = useHistory();

  return (
    // <div className="registration">
    <div>

      <div>
        <Nav/>
      </div>
      <DownloadApp/>
      
      {/* <div className="pageTitle">
        
        <div className="pageSubtage">
            Download Dillivry App on
        </div>
        <div className="pageSubtage">
            Playstore for android
        </div>
        <div className="pageSubtage">
            Appstore for IOS to carryout delivery activities
        </div>
      </div>
      <div className="formSide">

        <div className="regCont">
          <div style={{display:'flex', justifyContent:'center', marginBottom:'20px'}}>
              <img src={Dillivry} alt="dillivry" height='30px' onClick={()=>history.push('/')}/>
          </div>
          <div style={{display:"flex", alignItems:'center', justifyContent:'center', width:'100%'}}>
            <TitleText 
              subHead='Download Dillivry App on Playstore for android' 
              subHead2="Appstore for IOS to carryout delivery activities"
              margin_base="40px" />
          </div>
          
            <div style={{display:'flex', gap:'20px', width:'100%', justifyContent:'center', alignItems:'center', paddingBottom:'30px'}}>
              <img src={Playstore} alt="playstore" className="storeLogo"/>
              <img src={Appstore} alt="appstore"  className="storeLogo"/>
            </div>
         
      </div>
    </div> */}
    <Footer/>
    </div>
  );
}


