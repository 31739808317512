//new
import React, { useState, useEffect, useContext } from 'react';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import PhoneNumber from '../components/PhoneNumber';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Swal from 'sweetalert2'
import { IoIosArrowForward } from "react-icons/io"
import RegInput from '../components/Reg_components/RegInput';
import RegBtn from '../components/Reg_components/RegBtn';
import Captcha from "demos-react-captcha";
import { HiOutlineArrowLeft } from 'react-icons/hi'
import { AiFillCloseSquare } from "react-icons/ai"
import { BsBook } from 'react-icons/bs';
import axios from 'axios';
import { useForm, Controller, trigger } from "react-hook-form";
import 'react-country-dropdown/dist/index.css'
import { FiThumbsUp } from "react-icons/fi";
import { FiThumbsDown } from "react-icons/fi";
import { FiExternalLink } from "react-icons/fi";
import { Button } from '@material-ui/core';
import ReCAPTCHA from "react-google-recaptcha";
import CustomizedSnackbars from '../components/SnackBar'
import SearchIcon from "@material-ui/icons/Search";
import { parsePhoneNumber } from 'react-phone-number-input';
import { Link, useHistory } from 'react-router-dom'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ConnContext } from '../context/ConnContext';
import GlobalizationLanguage,{GlobalizationLanguagewithCOntryCode} from './GlobalizationLanguage';
import Cookies from "js-cookie";

const theme = createTheme({
    palette: {
        primary: {
            main: '#50B14A',
        },
    },
});

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },

    form: {
        width: '100%',
    },
    submit: {
        margin: theme.spacing(0, 0, 4),
        backgroundColor: 'var(--main)',
        color: 'white',
        fontWeight: '600'
    },
    formLabel: {
        fontWeight: '600',
        fontSize: '14px',
    },
    itemGrid: {
        marginBottom: '5px'
    },
    input: {
        borderColor: "var(--main)",
        marginTop: '7px',
    }
}));

function HomeHelp({ showDialog, onClose, showContactDefault }) {
    var logistics_companies = GlobalizationLanguage("/logistics-companies")
    var faq =GlobalizationLanguage(`/faq`)
    const {visitingCountryCode} = useContext(ConnContext)
    const [Contact, setContact] = React.useState('');
    useEffect(() => {
        setShowContact(showContactDefault)
    }, [])
    var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()
    const [country,setCountry]=useState(countryvalue);


    const handleChange = (event) => {
        setContact(event.target.value);
    };
  
    const [showSnack, setshowSnack] = useState(false)

    
    const classes = useStyles();
    const [isloading, setisloading] = useState(false);
    const history = useHistory();
    const [signup, setSignup] = useState(false)
    const [showContact, setShowContact] = useState(false);
    const [showQuestionA, setShowQuestionA] = useState(false);
    const [showQuestionB, setShowQuestionB] = useState(false);
    const [showQuestionC, setShowQuestionC] = useState(false);
    const [showQuestionD, setShowQuestionD] = useState(false);
    const [showQuestionE, setShowQuestionE] = useState(false);
    const [showQuestionF, setShowQuestionF] = useState(false);
    const [showQuestionG, setShowQuestionG] = useState(false);
    const [showQuestionH, setShowQuestionH] = useState(false);
    const [showQuestionI, setShowQuestionI] = useState(false);
    const [showQuestionJ, setShowQuestionJ] = useState(false);
    const [Name, setName] = useState('');
    const [phone, setphone] = useState('');
    const [capval, setcapval] = useState(false);
    const recaptchaRef = React.createRef();
    const [newsearch, setNewsearch] = useState('');
    const schemaEnquiry = yup.object().shape({
        name: yup.string().required("Name is required").min(3, "Can't be lesser than 3 digits").max(20, "Can't exceed 20 digits").matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
        email_id: yup.string().email("Valid email is required").required("Email is required"),
        message: yup.string().required("This is required").min(3, "Can't be lesser than 3 digits"),
    })

    const schemaContact = yup.object().shape({
        name: yup.string().required("Name is required").min(3, "Can't be lesser than 3 digits").max(20, "Can't exceed 20 digits").matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
        email_id: yup.string().email("Valid email is required").required("Email is required"),
        message: yup.string().required("This is required").min(3, "Can't be lesser than 3 digits"),
        delivery_id: yup.string().required("This is required").max(12, "Can't exceed 12 digits").matches(/^[0-9a-zA-Z]+$/, "Must contain alphanumeric only"),
    })

    const { control: controlCon, reset: resetCon, handleSubmit: handleSubmitCon, formState: { errors: errorsCon } } = useForm({
        mode: 'all',
        resolver: yupResolver(schemaContact),

    });

    const { control: controlEnq, reset: resetEnq, handleSubmit: handleSubmitEnq, formState: { errors: errorsEnq } } = useForm({
        mode: 'all',
        resolver: yupResolver(schemaEnquiry),

    });

    const resetmyEnqdata = () => {
        resetEnq({
            name: "",
            email_id: "",
            delivery_id: "",
            phone: setphone(""),
            country: "",
            complaint_type: "",
            message: "",
            capval: setcapval(false),
        });
    }

    const resetmyCondata = () => {
        resetCon({
            name: "",
            email_id: "",
            delivery_id: "",
            phone: setphone(""),
            country: "",
            complaint_type: "",
            message: "",
            capval: setcapval(false),
            Captcha
        });
    }

    const resetmydata = () => {
        resetmyCondata()
        resetmyEnqdata()
        setContact("")
    }

    const savemyEnqdata = async (data) => {
        if (capval) {
            setisloading(true)
            data.action_type = "enquiry"
            data.phone_no = phone
            data.country = parsePhoneNumber(phone).country
            data.complaint_type = Contact

            await axios.post(`${process.env.REACT_APP_API}m-dillivry-subscriber`,
                {
                    "action_type": data.action_type,
                    "enquiry_type": data.complaint_type,
                    "country": data.country,
                    "email_id": data.email_id,
                    "message": data.message,
                    "name": data.name,
                    "phone_no": data.phone_no,
                    "vcc": visitingCountryCode
                },
            )
                .then((response) => {
                    setisloading(false)
                    if (response.status === 200) {
                        Swal.fire({
                            icon: 'success',
                            text: 'Your enquiry has been successfully lodged!',
                            timer: 2500,
                            showConfirmButton: false,
                        })
                        //new
                        resetmydata()
                    }
                })
                .catch((error) => {
                    setisloading(false)
                    setName("");
                    if (error.response.status === 500) {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Note',
                            html: `<h2>Dear ${data.name},</h2>Sorry, we noticed you already have a support ticket created for this enquiry with your email. Kindly reach out to Dillivry care team referencing the support ID created for your enquiry.`,

                            confirmButtonColor: '#50b14a',
                        });
                    } else
                        Swal.fire({
                            icon: 'error',
                            text: 'An error occurred',
                            timer: 2500,
                            showConfirmButton: false,
                        })
                    resetmydata()

                })
        } else {
            Swal.fire({
                icon: 'error',
                text: 'Answer the recaptcha puzzle',
                timer: 2500,
                showConfirmButton: false,
            })
        }
    }

    const savemyCondata = async (data) => {
        if (capval) {
            setisloading(true)
            data.action_type = "complaint"
            data.phone_no = phone
            data.country = parsePhoneNumber(phone).country
            data.complaint_type = Contact
            data.vcc = visitingCountryCode

            await axios.post(`${process.env.REACT_APP_API}m-dillivry-subscriber`,
                data
            )
                .then((response) => {
                    setisloading(false)
                    if (response.status === 200) {
                        Swal.fire({
                            icon: 'success',
                            text: 'Your complaint has been successfully lodged!',
                            timer: 2500,
                            showConfirmButton: false,
                        })
                        resetmydata()
                    }
                })
                .catch((error) => {
                    setisloading(false)
                    setName("");
                    if (error.response.status === 500) {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Note',
                            html: `<h2>Dear ${data.name},</h2> Sorry, we noticed you already have a support ticket created for this delivery ID. Kindly reach out to Dillivry care team referencing the support ID created for you.`,

                            confirmButtonColor: '#50b14a',
                        });
                    } else
                        Swal.fire({
                            icon: 'error',
                            text: 'An error occurred',
                            timer: 2500,
                            showConfirmButton: false,
                        })
                    resetmydata()
                })
        } else {
            Swal.fire({
                icon: 'error',
                text: 'Answer the recaptcha puzzle',
                timer: 2500,
                showConfirmButton: false,
            })
        }
    }

    function onChange(value) {
        value ? setcapval(true) : setcapval(false)
    }

    useEffect(() => {
    }, []);

    const signUp = () => {
        setSignup(true);
        history.push(logistics_companies)
    }

   
    return (

        showDialog ?
            <div className="floatLeftDiv">
                {
                    showContact ? <div className="top-court" style={{
                        zIndex: '200',
                        cursor: 'pointer',
                        borderRadius: '5px',
                        fontSize: '14px'
                    }}>
                        <div className="back-my-icon" width="100%">

                            <button onClick={() => { setShowContact(false); setContact("");/*new*/  resetmydata() }} style={{ display: 'flex', border: "none", padding: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                <div style={{ paddingTop: '4px' }}><HiOutlineArrowLeft size="15px" /></div> &nbsp;
                                <span style={{ fontSize: '14px', paddingBottom: "1px", }}>Back</span>

                            </button>
                        </div>
                       
                        <div className="close-icon" onClick={onClose} ><AiFillCloseSquare size="35px" borderRadius="10px"></AiFillCloseSquare></div>
                    </div> : showQuestionA ? <div className="top-court" style={{
                        zIndex: '200',
                        cursor: 'pointer',
                        borderRadius: '5px',
                        fontSize: '14px'
                    }}>
                       
                        <div className="back-my-icon">

                            <button onClick={() => { setShowQuestionA(false); /*new*/  resetmydata() }} style={{ display: 'flex', border: "none", padding: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                <div style={{ paddingTop: '4px' }}><HiOutlineArrowLeft size="15px" /></div> &nbsp;
                                <span style={{ fontSize: '14px', paddingBottom: "1px", }}>Back</span>

                            </button>
                        </div>
                        <div className="close-icon" onClick={onClose} ><AiFillCloseSquare size="35px" borderRadius="10px"></AiFillCloseSquare></div>
                    </div> :
                        showQuestionB ? <div className="top-court" style={{
                            zIndex: '200',
                            cursor: 'pointer',
                            // height: '10px',
                            borderRadius: '5px',
                            fontSize: '14px'
                        }}>
                            {/* <div style={{ backgroundColor: "white" }}> <HiOutlineArrowLeft style={{ height: '20px' }} onClick={() => { setShowDialog(false) }} style={{ height: "10px", fontSize: '19px', }}></HiOutlineArrowLeft> </div>
                <div style={{ backgroundColor: "white" }}> Back </div> */}
                            <div className="back-my-icon">

                                <button onClick={() => { setShowQuestionB(false); /*new*/  resetmydata() }} style={{ display: 'flex', border: "none", padding: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                    <div style={{ paddingTop: '4px' }}><HiOutlineArrowLeft size="15px" /></div> &nbsp;
                                    <span style={{ fontSize: '14px', paddingBottom: "1px", }}>Back</span>

                                </button>
                            </div>
                            <div className="close-icon" onClick={onClose} ><AiFillCloseSquare size="35px" borderRadius="10px"></AiFillCloseSquare></div>
                        </div> :
                            showQuestionC ? <div className="top-court" style={{
                                zIndex: '200',
                                cursor: 'pointer',
                                // height: '10px',
                                borderRadius: '5px',
                                fontSize: '14px'
                            }}>

                                <div className="back-my-icon">

                                    <button onClick={() => { setShowQuestionC(false); /*new*/  resetmydata() }} style={{ display: 'flex', border: "none", padding: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                        <div style={{ paddingTop: '4px' }}><HiOutlineArrowLeft size="15px" /></div> &nbsp;
                                        <span style={{ fontSize: '14px', paddingBottom: "1px", }}>Back</span>
                                    </button>
                                </div>
                                <div className="close-icon" onClick={onClose} ><AiFillCloseSquare size="35px" borderRadius="10px"></AiFillCloseSquare></div>
                            </div>
                                :
                                showQuestionD ? <div className="top-court" style={{
                                    zIndex: '200',
                                    cursor: 'pointer',
                                    // height: '10px',
                                    borderRadius: '5px',
                                    fontSize: '14px'
                                }}>

                                    <div className="back-my-icon">

                                        <button onClick={() => { setShowQuestionD(false); /*new*/  resetmydata() }} style={{ display: 'flex', border: "none", padding: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                            <div style={{ paddingTop: '4px' }}><HiOutlineArrowLeft size="15px" /></div> &nbsp;
                                            <span style={{ fontSize: '14px', paddingBottom: "1px", }}>Back</span>


                                        </button>
                                    </div>
                                    <div className="close-icon" onClick={onClose} ><AiFillCloseSquare size="35px" borderRadius="10px"></AiFillCloseSquare></div>
                                </div>
                                    :
                                    showQuestionE ? <div className="top-court" style={{
                                        zIndex: '200',
                                        cursor: 'pointer',
                                        // height: '10px',
                                        borderRadius: '5px',
                                        fontSize: '14px'
                                    }}>

                                        <div className="back-my-icon">

                                            <button onClick={() => { setShowQuestionE(false); /*new*/  resetmydata() }} style={{ display: 'flex', border: "none", padding: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                                <div style={{ paddingTop: '4px' }}><HiOutlineArrowLeft size="15px" /></div> &nbsp;
                                                <span style={{ fontSize: '14px', paddingBottom: "1px", }}>Back</span>


                                            </button>
                                        </div>
                                        <div className="close-icon" onClick={onClose} ><AiFillCloseSquare size="35px" borderRadius="10px"></AiFillCloseSquare></div>
                                    </div>
                                        :
                                        showQuestionF ? <div className="top-court" style={{
                                            zIndex: '200',
                                            cursor: 'pointer',
                                            // height: '10px',
                                            borderRadius: '5px',
                                            fontSize: '14px'
                                        }}>

                                            <div className="back-my-icon">

                                                <button onClick={() => { setShowQuestionF(false); /*new*/  resetmydata() }} style={{ display: 'flex', border: "none", padding: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                                    <div style={{ paddingTop: '4px' }}><HiOutlineArrowLeft size="15px" /></div> &nbsp;
                                                    <span style={{ fontSize: '14px', paddingBottom: "1px", }}>Back</span>


                                                </button>
                                            </div>
                                            <div className="close-icon" onClick={onClose} ><AiFillCloseSquare size="35px" borderRadius="10px"></AiFillCloseSquare></div>
                                        </div>
                                            :
                                            showQuestionG ? <div className="top-court" style={{
                                                zIndex: '200',
                                                cursor: 'pointer',
                                                // height: '10px',
                                                borderRadius: '5px',
                                                fontSize: '14px'
                                            }}>

                                                <div className="back-my-icon">

                                                    <button onClick={() => { setShowQuestionG(false); /*new*/  resetmydata() }} style={{ display: 'flex', border: "none", padding: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                                        <div style={{ paddingTop: '4px' }}><HiOutlineArrowLeft size="15px" /></div> &nbsp;
                                                        <span style={{ fontSize: '14px', paddingBottom: "1px", }}>Back</span>


                                                    </button>
                                                </div>
                                                <div className="close-icon" onClick={onClose} ><AiFillCloseSquare size="35px" borderRadius="10px"></AiFillCloseSquare></div>
                                            </div>
                                                :
                                                showQuestionH ? <div className="top-court" style={{
                                                    zIndex: '200',
                                                    cursor: 'pointer',
                                                    // height: '10px',
                                                    borderRadius: '5px',
                                                    fontSize: '14px'
                                                }}>

                                                    <div className="back-my-icon">

                                                        <button onClick={() => { setShowQuestionH(false); /*new*/  resetmydata() }} style={{ display: 'flex', border: "none", padding: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                                            <div style={{ paddingTop: '4px' }}><HiOutlineArrowLeft size="15px" /></div> &nbsp;
                                                            <span style={{ fontSize: '14px', paddingBottom: "1px", }}>Back</span>


                                                        </button>
                                                    </div>
                                                    <div className="close-icon" onClick={onClose} ><AiFillCloseSquare size="35px" borderRadius="10px"></AiFillCloseSquare></div>
                                                </div>
                                                    :
                                                    showQuestionI ? <div className="top-court" style={{
                                                        zIndex: '200',
                                                        cursor: 'pointer',
                                                        // height: '10px',
                                                        borderRadius: '5px',
                                                        fontSize: '14px'
                                                    }}>

                                                        <div className="back-my-icon">

                                                            <button onClick={() => { setShowQuestionI(false); /*new*/  resetmydata() }} style={{ display: 'flex', border: "none", padding: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                                                <div style={{ paddingTop: '4px' }}><HiOutlineArrowLeft size="15px" /></div> &nbsp;
                                                                <span style={{ fontSize: '14px', paddingBottom: "1px", }}>Back</span>


                                                            </button>
                                                        </div>
                                                        <div className="close-icon" onClick={onClose} ><AiFillCloseSquare size="35px" borderRadius="10px"></AiFillCloseSquare></div>
                                                    </div>
                                                        :
                                                        showQuestionJ ? <div className="top-court" style={{
                                                            zIndex: '200',
                                                            cursor: 'pointer',
                                                            // height: '10px',
                                                            borderRadius: '5px',
                                                            fontSize: '14px'
                                                        }}>

                                                            <div className="back-my-icon">

                                                                <button onClick={() => { setShowQuestionJ(false); /*new*/  resetmydata() }} style={{ display: 'flex', border: "none", padding: '4px', justifyContent: 'center', alignItems: 'center' }} >
                                                                    <div style={{ paddingTop: '4px' }}><HiOutlineArrowLeft size="15px" /></div> &nbsp;
                                                                    <span style={{ fontSize: '14px', paddingBottom: "1px", }}>Back</span>


                                                                </button>
                                                            </div>
                                                            <div className="close-icon" onClick={onClose} ><AiFillCloseSquare size="35px" borderRadius="10px"></AiFillCloseSquare></div>
                                                        </div> :
                                                            <div class="top-content">
                                                                <div className="lefttext"  >Dillivry Support</div>
                                                                <div className="close-icon" onClick={onClose} ><AiFillCloseSquare size="30px"></AiFillCloseSquare></div>
                                                            </div>
                }
                <div className="par_div">
                    {
                        showContact ?
                            <>
                                <div className="modals">
                                    <div className="bellsha">

                                        <div className="taxisbells">
                                            <h4>Contact us</h4>
                                            <Container maxWidth="xs">
                                                {/* <CssBaseline /> */}
                                                <div className={classes.paper}>
                                                    <form onSubmit={Contact === "enquiry" ? handleSubmitEnq(savemyEnqdata) : handleSubmitCon(savemyCondata)} className={classes.form} noValidate>
                                                        <Grid container spacing={2} >
                                                            <Grid className={classes.itemGrid} item xs={12}>
                                                                <Controller
                                                                    name="name"
                                                                    control={Contact === "enquiry" ? controlEnq : controlCon}
                                                                    defaultValue=""
                                                                    render={({ field }) => <RegInput
                                                                        {...field}
                                                                        htmlFor="name"
                                                                        name="name"
                                                                        id='name'
                                                                        txt='Name'
                                                                        type="text"
                                                                        size='small'
                                                                        error={Contact === "enquiry" ? errorsEnq.name : errorsCon.name}
                                                                    // value={Name}
                                                                    // onChange={(e) => setName(e.target.value)}
                                                                    />
                                                                    }
                                                                />
                                                                <div className="err">{Contact === "enquiry" ? errorsEnq.name && errorsEnq.name?.message : errorsCon.name && errorsCon.name?.message}</div>
                                                            </Grid>
                                                            <Grid className={classes.itemGrid} item xs={12}>
                                                                <Controller
                                                                    name="email_id"
                                                                    control={Contact === "enquiry" ? controlEnq : controlCon}
                                                                    defaultValue=""
                                                                    render={({ field }) => <RegInput
                                                                        {...field}
                                                                        htmlFor="email_id"
                                                                        name="email_id"
                                                                        id='email_id'
                                                                        txt='Email Address'

                                                                        type='email'
                                                                        size='small'
                                                                    // error={errors.email}
                                                                    // value={email}
                                                                    // onChange={(e) => setemail(e.target.value)}
                                                                    />
                                                                    }
                                                                />
                                                                <div className="err">{Contact === "enquiry" ? errorsEnq.email_id && errorsEnq.email_id?.message : errorsCon.email_id && errorsCon.email_id?.message}</div>
                                                            </Grid>

                                                            {/* <Grid className={classes.itemGrid} item xs={12}>
                                                                <Controller
                                                                    name="delivery_id"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => <RegInput
                                                                        {...field}
                                                                        htmlFor="delivery_id"
                                                                        name="delivery_id"
                                                                        id="text"
                                                                        txt="Delivery ID "
                                                                        type='text'
                                                                        size='small'
                                                                    // value={delivery}
                                                                    // onChange={(e) => setdelivery(e.target.value)}
                                                                    />
                                                                    }
                                                                />
                                                                <div className="err">{errors.delivery_id && errors.delivery_id?.message}</div>

                                                            </Grid>
 */}

                                                            <Grid className={classes.itemGrid} item xs={12}>
                                                                <div style={{ fontWeight: "600", fontSize: "14px" }}>
                                                                    Phone Number
                                                                </div>
                                                                <PhoneNumber value={phone}
                                                                    setValue={setphone} />
                                                                {/* <div className="err">{phone == undefined ? 'Invalid phones number combination' : ''}</div> */}
                                                            </Grid>
                                                            {/* <Grid className={classes.itemGrid} item xs={12}>
                                                                <Controller
                                                                    name="country"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => <div>
                                                                        <p> Select Country</p>
                                                                        <ReactCountryDropdown onSelect={handleSelect} position={'fixed'} countryCode='NG' styles={{
                                                                            width: "100%",
                                                                            height: "40px",
                                                                            borderRadius: "5px",
                                                                            padding: "0 10px",
                                                                            outline: "none",
                                                                            marginLeft: "2rem",
                                                                            position: "relative",
                                                                            border: "1px solid var(--main)",
                                                                        }} />

                                                                    </div>
                                                                    }
                                                                />
                                                                <div className="err">{errors.country && errors.country?.message}</div>
                                                            </Grid> */}



                                                            <Grid className={classes.itemGrid} item xs={12}>
                                                                {/* <span style={{
                                                                    fontWeight: '600',
                                                                    fontSize: '14px',

                                                                }} >Contact us for </span> */}

                                                                <FormControl fullWidth>

                                                                    <InputLabel id="demo-simple-select-label" style={{ color: "black", fontWeight: "600" }} > Contact us for </InputLabel>

                                                                    <ThemeProvider theme={theme}>

                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            value={Contact}

                                                                            label="Contact"
                                                                            txt="Contact us for"

                                                                            onChange={handleChange}
                                                                            variant='standard'

                                                                        >
                                                                            <MenuItem value='enquiry'>Enquiry</MenuItem>
                                                                            <MenuItem value='support'>Support</MenuItem>

                                                                        </Select>
                                                                    </ThemeProvider>

                                                                </FormControl>
                                                                {/* <span style={{
                                                                fontWeight: '600',
                                                                fontSize: '14px',
                                                            }} >Complaint type</span>
                                                                <Controller
                                                                    name="complaint_type"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => <input style={{

                                                                        width: "100%", height: "40px", marginBottom: '5px',

                                                                    }}
                                                                        {...field}
                                                                        htmlFor="complaint_type"
                                                                        name="complaint_type"
                                                                        id="complaint_type"
                                                                        txt="Complaint type"
                                                                        type='text'
                                                                        size='small'
                                                                        error={errors.complaint}
                                                                    // value={complaint}
                                                                    // onChange={(e) => setcomplaint(e.target.value)}
                                                                    />
                                                                    }
                                                                />
                                                                <div className="err">{errors.complaint_type && errors.complaint_type?.message}</div> */}
                                                            </Grid>
                                                            {
                                                                Contact === 'support' ?
                                                                    <>
                                                                        <Grid className={classes.itemGrid} item xs={12}>
                                                                            <Controller
                                                                                name="delivery_id"
                                                                                control={Contact === "enquiry" ? controlEnq : controlCon}
                                                                                // control={control}
                                                                                defaultValue=""
                                                                                render={({ field }) => <RegInput
                                                                                    {...field}
                                                                                    htmlFor="delivery_id"
                                                                                    name="delivery_id"
                                                                                    id="text"
                                                                                    txt="Delivery ID "
                                                                                    type='text'
                                                                                    size='small'
                                                                                // value={delivery}
                                                                                // onChange={(e) => setdelivery(e.target.value)}
                                                                                />
                                                                                }
                                                                            />
                                                                            <div className="err">{Contact === "enquiry" ? errorsEnq.delivery_id && errorsEnq.delivery_id?.message : errorsCon.delivery_id && errorsCon.delivery_id?.message}</div>
                                                                            {/* <div className="err">{errors.delivery_id && errors.delivery_id?.message}</div> */}

                                                                        </Grid>
                                                                        <br></br>
                                                                        <Grid className={classes.itemGrid} item xs={12}> <span style={{
                                                                            fontWeight: '600',
                                                                            fontSize: '14px',
                                                                        }} >Message </span>
                                                                            <Controller
                                                                                name="message"
                                                                                control={Contact === "enquiry" ? controlEnq : controlCon}
                                                                                // control={control}
                                                                                defaultValue=""
                                                                                error={Contact === "enquiry" ? errorsEnq.message : errorsCon.message}
                                                                                // error={errors.message}
                                                                                render={({ field }) => <textarea {...field} style={{
                                                                                    width: "100%", borderStyle: "solid",
                                                                                }} rows='5' type="text" name="message"
                                                                                    id="message" ></textarea>}
                                                                            />
                                                                            <div className="err">{Contact === "enquiry" ? errorsEnq.message && errorsEnq.message?.message : errorsCon.message && errorsCon.message?.message}</div>
                                                                            {/* <div className="err">{errors.message && errors.message?.message}</div> */}

                                                                        </Grid>
                                                                        {/* <Grid className={classes.itemGrid} item xs={12}> */}
                                                                        <br></br>

                                                                        <ReCAPTCHA
                                                                            ref={recaptchaRef}
                                                                            // sitekey="6LcKmmUcAAAAAPHiOqcHKPy1FwsbAgd_Y5nLMFyv"
                                                                            sitekey="6LeYgE8dAAAAAF1QHr0GF62EGuz7ocx7065Ahxqu"
                                                                            onChange={onChange}
                                                                        // onVerify={resetCaptcha}

                                                                        />
                                                                        <br></br><br></br> <br></br><br></br>   <br></br>
                                                                        <RegBtn loading={isloading} txt="Send" type="submit" />

                                                                        <div style={{ marginBottom: '10px' }}></div>
                                                                    </>
                                                                    :
                                                                    Contact === 'enquiry' ?
                                                                        <>
                                                                            <Grid className={classes.itemGrid} item xs={12}> <span style={{
                                                                                fontWeight: '600',
                                                                                fontSize: '14px',
                                                                            }} >Message </span>
                                                                                <Controller
                                                                                    name="message"
                                                                                    // control={control}
                                                                                    control={Contact === "enquiry" ? controlEnq : controlCon}
                                                                                    defaultValue=""
                                                                                    error={Contact === "enquiry" ? errorsEnq.message : errorsCon.message}
                                                                                    // error={errors.message}
                                                                                    render={({ field }) => <textarea {...field} style={{
                                                                                        width: "100%", borderStyle: "solid",
                                                                                    }} rows='5' type="text" name="message"
                                                                                        id="message" ></textarea>}
                                                                                />
                                                                                <div className="err">{Contact === "enquiry" ? errorsEnq.message && errorsEnq.message?.message : errorsCon.message && errorsCon.message?.message}</div>
                                                                                {/* <div className="err">{errors.message && errors.message?.message}</div> */}

                                                                            </Grid>
                                                                            <ReCAPTCHA
                                                                                // ref={(r) => setCaptchaRef(r)}
                                                                                // ref={el => { captcha = el; }}
                                                                                ref={recaptchaRef}
                                                                                // sitekey="6LcKmmUcAAAAAPHiOqcHKPy1FwsbAgd_Y5nLMFyv"
                                                                                sitekey="6LeYgE8dAAAAAF1QHr0GF62EGuz7ocx7065Ahxqu"

                                                                                
                                                                                onChange={onChange}

                                                                            />

                                                                            <br></br><br></br> <br></br><br></br>   <br></br>

                                                                            <RegBtn style={{}} loading={isloading} txt="Send" type="submit" />

                                                                            <div style={{ marginBottom: '10px' }}></div>
                                                                        </>
                                                                        :
                                                                        null
                                                            }
                                                            {/* <Grid className={classes.itemGrid} item xs={12}>
                                                                <Controller
                                                                    name="delivery_id"
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field }) => <RegInput
                                                                        {...field}
                                                                        htmlFor="delivery_id"
                                                                        name="delivery_id"
                                                                        id="text"
                                                                        txt="Delivery ID "
                                                                        type='text'
                                                                        size='small'
                                                                    // value={delivery}
                                                                    // onChange={(e) => setdelivery(e.target.value)}
                                                                    />
                                                                    }
                                                                />
                                                                <div className="err">{errors.delivery_id && errors.delivery_id?.message}</div>

                                                            </Grid>
                                                            <br></br> */}

                                                            {/* <Grid className={classes.itemGrid} item xs={12}> */}



                                                            {/* </Grid> */}

                                                            {/* <Grid item style={{ marginBottom: '20px' }} xs={12}>
                                                                <div style={{ fontWeight: '600', marginBottom: '10px', fontSize: '14px' }}>  <span style={{ fontSize: "12px", fontStyle: 'italic', color: '#B7B7B7' }}></span></div>
                                                                <input type="file" id='imageUpload-dropzone' testid="imageUpload-dropzone" accept="image/x-png,image/jpg,image/jpeg" style={{ display: 'none', width: '52px', height: '52px' }} />

                                                                <label htmlFor="logo"
                                                                    style={{ width: '100%', height: '150px', display: 'flex', border: 'dashed 2px #C7C7C7', alignItems: 'center', justifyContent: 'center', borderRadius: '5px' }}>
                                                                    Click here to add or drag and drop files
                                                                </label>
                                                            </Grid> */}

                                                        </Grid>







                                                    </form>
                                                </div>


                                            </Container>

                                        </div>

                                    </div>
                                </div>

                            </>
                            :
                            showQuestionA ?
                                <>
                                    <div className="modals">
                                        <div className="belis-content">

                                            <div className="taxisbellss">
                                                <h4> How do I list a delivery request?<span>
                                                    <Link to={GlobalizationLanguagewithCOntryCode("/faq", country, visitingCountryCode )}>
                                                        <FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink >
                                                    </Link>

                                                     {/* <a href="https://dev.dillivry.com/faqs" target="_blank"> */}
                                                        {/* <FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink > */}
                                                         {/* </a> */}
                                                    </span></h4>
                                                <div className="uniqueQ">
                                                    <p>Describe the item to move, state your pickup and destination addresses and contacts and specify preferred date to carry out the job.</p>
                                                    {/* <p>If you no longer require quotes from logistics providers for whatever reason, please delete your request from the marketplace.</p>
                                                    <p>When you delete your request you will stop receiving any further quotes.</p>
                                                    <p>Please just follow these steps:</p>
                                                    <p>1) Go to your <a href="https://dev.dillivry.com/" target="_blank"> Active Deliveries </a>page.</p>
                                                    <p>2) Click on the "Delete" tab (Rubbish Bin) next to the delivery request in question. </p> */}


                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="buttsup">
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Button variant="outlined" type="button" className="button1" onClick={() => setshowSnack(!showSnack)} >

                                                    <FiThumbsUp size="15px"></FiThumbsUp><span style={{ textTransform: 'none', marginLeft: '10px' }}>Helpful</span>   </Button>
                                                <CustomizedSnackbars
                                                    open={showSnack}
                                                    setHandleOpen={setshowSnack}
                                                />

                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button variant="outlined" type="button" className="button2" onClick={() => {
                                                    setShowContact(true)
                                                }}><FiThumbsDown size="15px"></FiThumbsDown><span style={{ textTransform: 'none', marginLeft: '10px' }}>Not Helpful</span></Button>

                                            </Grid>
                                        </Grid>
                                    </div>


                                </>
                                :
                                showQuestionB ?
                                    <>
                                        <div className="modals">
                                            <div className="belis-content">

                                                <div className="taxisbellss">
                                                    <h4> How do i cancel a delivery request? <span> 
                                                        <Link to={GlobalizationLanguagewithCOntryCode("/faq", country, visitingCountryCode )}> <FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink ></Link>
                                                        {/* <a href="https://dev.dillivry.com/faqs" target="_blank"><FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink > </a> */}
                                                        </span></h4>
                                                    <div className="uniqueQ"><p>If you no longer need a delivery request, login to your account, go to the Active deliveries tab. </p>
                                                        <p>Select the request and hit the Delete button to delete the request.</p>
                                                        <p>  If you need to cancel a delivery request after accepting a quote please speak to Dillivry  <a onClick={() => { setShowContact(true) }} style={{ color: '#50b14a', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}>
                                                            customer care</a> </p></div>





                                                </div>

                                            </div>
                                        </div>
                                        <div className="buttsup">
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Button variant="outlined" type="button" className="button1" onClick={() => setshowSnack(!showSnack)} >

                                                        <FiThumbsUp size="15px"></FiThumbsUp><span style={{ textTransform: 'none', marginLeft: '10px' }}>Helpful</span>   </Button>
                                                    <CustomizedSnackbars
                                                        open={showSnack}
                                                        setHandleOpen={setshowSnack}
                                                    />

                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button variant="outlined" type="button" className="button2" onClick={() => {
                                                        setShowContact(true)
                                                    }}><FiThumbsDown size="15px"></FiThumbsDown><span style={{ textTransform: 'none', marginLeft: '10px' }}>Not Helpful</span></Button>

                                                </Grid>
                                            </Grid>
                                        </div>



                                    </> :
                                    showQuestionC ?
                                        <>
                                            <div className="modals">
                                                <div className="bellsha">

                                                    <div className="taxisbells">
                                                        <h4> My provider cancelled after payment has been made?<span> 
                                                        <Link to={GlobalizationLanguagewithCOntryCode("/faq", country, visitingCountryCode )}> <FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink ></Link>
                                                            
                                                            {/* <a href="https://dev.dillivry.com/faqs" target="_blank"><FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink > </a> */}
                                                            </span></h4>
                                                        <div className="uniqueQ">
                                                            {/* <p>Please note that direct contact information for Shiply logistics providers is not and should not be exchanged prior to quote acceptance. If you need to communicate with a logistics provider before wanting to accept their quote, please follow these steps:</p>
                                                            <p>1) &nbsp;  Log in at: <a href="https://dev.dillivry.com/login" target="_blank">Dillivry.com</a> </p>

                                                            <p>2) &nbsp; Click the "View Quotes" button of your active delivery.</p>
                                                            <p>3) &nbsp; Click on the button "View Messages" button for the logistics provider you wish to ask a question.</p>
                                                            <p> You will need to file a cancellation request which the logistics provider will accept or reject depending on the circumstances. You can file for a deposit credit by contacting <a href="mailto:care@dillivry.com" target="_blank"> Dillivry care</a> team directly.</p>
                                                            <p>4) &nbsp; The message section will display and you can type your question.</p>
                                                            <p> Once you have accepted a quote from a logistics provider, direct contact information is exchanged.</p>
                                                            <p> The logistics provider is urged to contact you first, so please, if the job is not urgent, do allow them some time to contact you.</p> */}
                                                            <p>If this situation ever arises please get in touch with the <a onClick={() => { setShowContact(true) }} style={{ color: '#50b14a', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}>
                                                                customer care</a>  to re-route the job to another provider.</p>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="buttsup">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <Button variant="outlined" type="button" className="button1" onClick={() => setshowSnack(!showSnack)} >

                                                            <FiThumbsUp size="15px"></FiThumbsUp><span style={{ textTransform: 'none', marginLeft: '10px' }}>Helpful</span>   </Button>
                                                        <CustomizedSnackbars
                                                            open={showSnack}
                                                            setHandleOpen={setshowSnack}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Button variant="outlined" type="button" className="button2" onClick={() => {
                                                            setShowContact(true)
                                                        }}><FiThumbsDown size="15px"></FiThumbsDown><span style={{ textTransform: 'none', marginLeft: '10px' }}>Not Helpful</span></Button>

                                                    </Grid>
                                                </Grid>

                                            </div>
                                        </> :
                                        showQuestionD ?
                                            <>
                                                <div className="modals">
                                                    <div className="bellsha">

                                                        <div className="taxisbells">
                                                            <h4>What are the payment method? <span> 
                                                                <Link to={GlobalizationLanguage(`/faq`)}> <FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink ></Link>

                                                                {/* <a href="https://dev.dillivry.com/faqs" target="_blank"><FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink > </a> */}
                                                                </span></h4>
                                                            <div className="uniqueQ"><p>When you accept a quote, you can pay the deposit via debit/credit card.</p>
                                                                <p>Payment methods and requirements vary between logistics providers so it is best to discuss this with them prior to accepting a quote.  </p>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="buttsup">
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Button variant="outlined" type="button" className="button1" onClick={() => setshowSnack(!showSnack)} >

                                                                <FiThumbsUp size="15px"></FiThumbsUp><span style={{ textTransform: 'none', marginLeft: '10px' }}>Helpful</span>   </Button>
                                                            <CustomizedSnackbars
                                                                open={showSnack}
                                                                setHandleOpen={setshowSnack}
                                                            />

                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button variant="outlined" type="button" className="button2" onClick={() => {
                                                                setShowContact(true)
                                                            }}><FiThumbsDown size="15px"></FiThumbsDown><span style={{ textTransform: 'none', marginLeft: '10px' }}>Not Helpful</span></Button>

                                                        </Grid>
                                                    </Grid>

                                                </div>

                                            </> :
                                            showQuestionE ?
                                                <>
                                                    <div className="modals">
                                                        <div className="bellsha">

                                                            <div className="taxisbells">
                                                                <h4>I've accepted the wrong quote, can I go back? <span> 
                                                                    {/* <a href="https://dev.dillivry.com/faqs" target="_blank"><FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink > </a> */}
                                                                        <Link to={GlobalizationLanguage(`/faq`)}> <FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink ></Link>

                                                                    </span></h4>
                                                                <div className="uniqueQ"><p>If you have accepted the wrong quote but have not yet paid the deposit then you will be able to go back onto your listing and accept the correct quote. Simply return to the view quotes page and press the green “Accept Quote” button for the correct quote.</p>
                                                                    <p> However, if you have already paid the deposit you will need to contact  <a href="mailto:care@dillivry.com" target="_blank"> Dillivry care</a> team who will be able to transfer the payment to the correct logistics provider.</p>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="buttsup">
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={6}>
                                                                <Button variant="outlined" type="button" className="button1" onClick={() => setshowSnack(!showSnack)} >

                                                                    <FiThumbsUp size="15px"></FiThumbsUp><span style={{ textTransform: 'none', marginLeft: '10px' }}>Helpful</span>   </Button>
                                                                <CustomizedSnackbars
                                                                    open={showSnack}
                                                                    setHandleOpen={setshowSnack}
                                                                />

                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Button variant="outlined" type="button" className="button2" onClick={() => {
                                                                    setShowContact(true)
                                                                }}><FiThumbsDown size="15px"></FiThumbsDown><span style={{ textTransform: 'none', marginLeft: '10px' }}>Not Helpful</span></Button>

                                                            </Grid>
                                                        </Grid>

                                                    </div>

                                                </> :
                                                showQuestionF ?
                                                    <>
                                                        <div className="modals">
                                                            <div className="bellsha">

                                                                <div className="taxisbells">
                                                                    <h4>How can I contact my chosen logistics provider?? <span> 
                                                                        <Link to={GlobalizationLanguage(`/faq`)}> <FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink ></Link>
                                                                        {/* <a href="https://dev.dillivry.com/faqs" target="_blank"><FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink > </a> */}
                                                                    </span></h4>
                                                                    <div className="uniqueQ"><p>If you have been in contact with your logistics provider and they are unable to complete the delivery, you should contact our <a href="mailto:care@dillivry.com" target="_blank"> dillivry care</a> team as soon as possible. There are two options if your logistics provider has cancelled: </p>
                                                                        <p>1) &nbsp;  Re-list your item</p>
                                                                        <p>This will allow you to find a new logistics provider and get your goods moved asap. We will credit the deposit that you have already paid onto this new listing. When you accept a new quote the deposit will automatically transfer so you wont need to pay it again</p>
                                                                        <p>2) &nbsp; A full credit of the deposit. </p>
                                                                        <p> You will need to file a cancellation request which the logistics provider will accept or reject depending on the circumstances. You can file for a deposit credit by contacting <a href="mailto:care@dillivry.com" target="_blank"> Dillivry care</a> team directly.</p>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="buttsup">
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6}>
                                                                    <Button variant="outlined" type="button" className="button1" onClick={() => setshowSnack(!showSnack)} >

                                                                        <FiThumbsUp size="15px"></FiThumbsUp><span style={{ textTransform: 'none', marginLeft: '10px' }}>Helpful</span>   </Button>
                                                                    <CustomizedSnackbars
                                                                        open={showSnack}
                                                                        setHandleOpen={setshowSnack}
                                                                    />

                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <Button variant="outlined" type="button" className="button2" onClick={() => {
                                                                        setShowContact(true)
                                                                    }}><FiThumbsDown size="15px"></FiThumbsDown><span style={{ textTransform: 'none', marginLeft: '10px' }}>Not Helpful</span></Button>

                                                                </Grid>
                                                            </Grid>

                                                        </div>

                                                    </> :
                                                    showQuestionG ?
                                                        <>
                                                            <div className="modals">
                                                                <div className="bellsha">

                                                                    <div className="taxisbells">
                                                                        <h4>I've received my delivery but it is damaged, what can I do? <span> 
                                                                       
                                                                        <Link to={GlobalizationLanguage(`/faq`)}> <FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink ></Link>
                                                                        
                                                                        {/* <a href="https://dev.dillivry.com/faqs" target="_blank"><FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink > </a> */}
                                                                        </span></h4>
                                                                        <div className="uniqueQ"><p>IUnfortunately the nature of deliveries means that damages can sometimes occur during transit. </p>
                                                                            <p>If you have received your delivery but find that the item has been damaged please contact your transport provider to organise an insurance claim.</p>
                                                                            <p>We act as a neutral venue through which Transport Providers and Users can meet and enter into negotiations and are not involved with the actual transaction.</p>

                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="buttsup">
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={6}>
                                                                        <Button variant="outlined" type="button" className="button1" onClick={() => setshowSnack(!showSnack)} >

                                                                            <FiThumbsUp size="15px"></FiThumbsUp><span style={{ textTransform: 'none', marginLeft: '10px' }}>Helpful</span>   </Button>
                                                                        <CustomizedSnackbars
                                                                            open={showSnack}
                                                                            setHandleOpen={setshowSnack}
                                                                        />

                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Button variant="outlined" type="button" className="button2" onClick={() => {
                                                                            setShowContact(true)
                                                                        }}><FiThumbsDown size="15px"></FiThumbsDown><span style={{ textTransform: 'none', marginLeft: '10px' }}>Not Helpful</span></Button>

                                                                    </Grid>
                                                                </Grid>

                                                            </div>

                                                        </> :
                                                        showQuestionH ?
                                                            <>
                                                                <div className="modals">
                                                                    <div className="bellsha">

                                                                        <div className="taxisbells">
                                                                            <h4>How do I accept a quote? <span> 
                                                                                {/* <a href="https://dev.dillivry.com/faqs" target="_blank"><FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink > </a> */}
                                                                        <Link to={GlobalizationLanguage(`/faq`)}> <FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink ></Link>
                                                                                
                                                                                </span></h4>
                                                                            <div className="uniqueQ"><p>To accept a quote, please follow these steps:</p>
                                                                                <p>1) &nbsp;  R Go to your active deliveries page here: 
                                                                        <Link to={GlobalizationLanguage(`/faq`)}> <FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink ></Link>
                                                                                   
                                                                                    {/* <a href="https://dev.dillivry.com/faqs" target="_blank"></a> */}
                                                                                    </p>
                                                                                <p>This will allow you to find a new logistics provider and get your goods moved asap. We will credit the deposit that you have already paid onto this new listing. When you accept a new quote the deposit will automatically transfer so you wont need to pay it again</p>
                                                                                <p>2) &nbsp;  Click on the green "View Quotes" button next to the delivery request in question.</p>
                                                                                <p> You will need to file a cancellation request which the logistics provider will accept or reject depending on the circumstances. You can file for a deposit credit by contacting <a href="mailto:care@dillivry.com" target="_blank"> Dillivry care</a> team directly.</p>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="buttsup">
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={6}>
                                                                            <Button variant="outlined" type="button" className="button1" onClick={() => setshowSnack(!showSnack)} >

                                                                                <FiThumbsUp size="15px"></FiThumbsUp><span style={{ textTransform: 'none', marginLeft: '10px' }}>Helpful</span>   </Button>
                                                                            <CustomizedSnackbars
                                                                                open={showSnack}
                                                                                setHandleOpen={setshowSnack}
                                                                            />

                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Button variant="outlined" type="button" className="button2" onClick={() => {
                                                                                setShowContact(true)
                                                                            }}><FiThumbsDown size="15px"></FiThumbsDown><span style={{ textTransform: 'none', marginLeft: '10px' }}>Not Helpful</span></Button>

                                                                        </Grid>
                                                                    </Grid>

                                                                </div>

                                                            </> :
                                                            showQuestionI ?
                                                                <>
                                                                    <div className="modals">
                                                                        <div className="bellsha">

                                                                            <div className="taxisbells">
                                                                                <h4>My logistics provider has cancelled, what can I do? <span> 
                                                                        <Link to={GlobalizationLanguage(`/faq`)}> <FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink ></Link>

                                                                                    {/* <a href="https://dev.dillivry.com/faqs" target="_blank"><FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink > </a> */}
                                                                                    </span></h4>
                                                                                <div className="uniqueQ"><p>If you have been in contact with your logistics provider and they are unable to complete the delivery, you should contact our <a href="mailto:care@dillivry.com" target="_blank"> dillivry care</a> team as soon as possible. There are two options if your logistics provider has cancelled: </p>
                                                                                    <p>1) &nbsp;  Re-list your item</p>
                                                                                    <p>This will allow you to find a new logistics provider and get your goods moved asap. We will credit the deposit that you have already paid onto this new listing. When you accept a new quote the deposit will automatically transfer so you wont need to pay it again</p>
                                                                                    <p>2) &nbsp; A full credit of the deposit. </p>
                                                                                    <p> You will need to file a cancellation request which the logistics provider will accept or reject depending on the circumstances. You can file for a deposit credit by contacting <a href="mailto:care@dillivry.com" target="_blank"> Dillivry care</a> team directly.</p>
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="buttsup">
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={6}>
                                                                                <Button variant="outlined" type="button" className="button1" onClick={() => setshowSnack(!showSnack)} >

                                                                                    <FiThumbsUp size="15px"></FiThumbsUp><span style={{ textTransform: 'none', marginLeft: '10px' }}>Helpful</span>   </Button>
                                                                                <CustomizedSnackbars
                                                                                    open={showSnack}
                                                                                    setHandleOpen={setshowSnack}
                                                                                />

                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <Button variant="outlined" type="button" className="button2" onClick={() => {
                                                                                    setShowContact(true)
                                                                                }}><FiThumbsDown size="15px"></FiThumbsDown><span style={{ textTransform: 'none', marginLeft: '10px' }}>Not Helpful</span></Button>

                                                                            </Grid>
                                                                        </Grid>

                                                                    </div>

                                                                </> :
                                                                showQuestionJ ?
                                                                    <>
                                                                        <div className="modals">
                                                                            <div className="bellsha">

                                                                                <div className="taxisbells">
                                                                                    <h4> <span> 
                                                                                        <Link to={GlobalizationLanguage(`/faq`)}> <FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink ></Link>

                                                                                        {/* <a href="https://dev.dillivry.com/faqs" target="_blank"><FiExternalLink size="13px" style={{ color: 'black' }}></FiExternalLink > </a> */}
                                                                                        </span></h4>
                                                                                    <div className="uniqueQ"><p>If you have been in contact with your logistics provider and they are unable to complete the delivery, you should contact our <a href="mailto:care@dillivry.com" target="_blank"> dillivry care</a> team as soon as possible. There are two options if your logistics provider has cancelled: </p>
                                                                                        <p>1) &nbsp;  Re-list your item</p>
                                                                                        <p>This will allow you to find a new logistics provider and get your goods moved asap. We will credit the deposit that you have already paid onto this new listing. When you accept a new quote the deposit will automatically transfer so you wont need to pay it again</p>
                                                                                        <p>2) &nbsp; A full credit of the deposit. </p>
                                                                                        <p> You will need to file a cancellation request which the logistics provider will accept or reject depending on the circumstances. You can file for a deposit credit by contacting <a href="mailto:care@dillivry.com" target="_blank"> Dillivry care</a> team directly.</p>
                                                                                    </div>

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="buttsup">
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={6}>
                                                                                    <Button variant="outlined" type="button" className="button1" onClick={() => setshowSnack(!showSnack)} >

                                                                                        <FiThumbsUp size="15px"></FiThumbsUp><span style={{ textTransform: 'none', marginLeft: '10px' }}>Helpful</span>   </Button>
                                                                                    <CustomizedSnackbars
                                                                                        open={showSnack}
                                                                                        setHandleOpen={setshowSnack}
                                                                                    />

                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <Button variant="outlined" type="button" className="button2" onClick={() => {
                                                                                        setShowContact(true)
                                                                                    }}><FiThumbsDown size="15px"></FiThumbsDown><span style={{ textTransform: 'none', marginLeft: '10px' }}>Not Helpful</span></Button>

                                                                                </Grid>
                                                                            </Grid>

                                                                        </div>





                                                                    </>
                                                                    :
                                                                    <div className="modals">
                                                                        <div className="bottom-content">
                                                                            <div className="textss"> Got a Question?</div>
                                                                            <div className="sup">
                                                                                <div className="searchess">
                                                                                    <input type="text" placeholder="Search for help" style={{ fontSize: '15px', width: '100%', height: '100%', borderRadius: '10px', outline: 'none', padding: '0 20px' }} value={newsearch} onChange={e => setNewsearch(e.target.value)} />
                                                                                    {/* <span><SearchIcon onClick={() => handleSearch(peopleData, newsearch)} style={{ backgroundColor: "white", position: "absolute", right: '5px', bottom: '7px', fontSize: '25px' }} /></span>
                                                                                    {console.log(newsearch, 'newsearch value')} */}
                                                                                    <span ><SearchIcon onClick={() => history.push(faq)} style={{ position: "absolute", borderRadius: "3px", width: "30px", height: "30px", right: '7px', bottom: '4px', fontSize: '15px', paddingLeft: "5px", backgroundColor: "#c4c4c4" }} /></span>
                                                                                    {/* <AiOutlineSearch onClick={() => { searchData() }} style={{ fontSize: "40px", position: "relative", left: "-30px" }} /> */}
                                                                                </div>

                                                                                <div class="innerSup">
                                                                                    <h6 >
                                                                                        Relevant articles
                                                                                    </h6>
                                                                                    <div class="arto" onClick={() => {
                                                                                        setShowQuestionA(true)

                                                                                    }}>

                                                                                        <BsBook />&nbsp; How do I list a delivery request? </div>
                                                                                    <div class="arto" onClick={() => {
                                                                                        setShowQuestionB(true)

                                                                                    }}>

                                                                                        <BsBook /> &nbsp; How do i cancel a delivery request?</div>
                                                                                    <div class="arto" onClick={() => {
                                                                                        setShowQuestionC(true)

                                                                                    }}>

                                                                                        <BsBook />&nbsp;My provider cancelled after payment has been made?</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                        <div className="btnDiv">
                                                                            <div class="clickLike" onClick={() => {
                                                                                setShowContact(true)

                                                                            }}>

                                                                                <h4 >Contact us</h4>
                                                                                <div className="btnin" ><IoIosArrowForward onClick={() => {
                                                                                    setShowContact(true)

                                                                                }}></IoIosArrowForward></div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                    }
                </div>

            </div >
            :
            null

    )
}

export default HomeHelp
