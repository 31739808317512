import { StyledEngineProvider } from "@mui/material";
import React from "react";
import { Button } from "@material-ui/core";

import { useHistory, useParams } from "react-router-dom";
import './Timeout.css'
import Footer from "../../components/Footer/Footer";
import TopBar from "../../components/TopBar/TopBar";

export default function Timeout() {
    const history = useHistory();

  return (
    <div>
      <div>
      <div className="topmenu showTopMenuDesktop">
       <TopBar/>
      </div>


      <div className="timeout-div"  >

          <div>
          <div onClick={()=> history.goBack()}   ><Button variant="contained" style={{textTransform:'none', fontSize:'14px'}}>Take Me Back </Button></div>
            <div> <h1>Time Out  </h1></div>
          </div>
      </div>
      </div>

      <Footer/>
    </div>
  );
}
