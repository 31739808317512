import React,{useState, createContext} from 'react';
import SecureLS from 'secure-ls';


const AuthContext = createContext();

const {Provider} = AuthContext;


const AuthProvider = ({children}) =>{

const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

    const logout = () =>{
        ls.removeAll();
        
        setAuthState({
            user_name: null,
            auth_status: null,
            token:{},
            role: null
        })
        
        
    }

    const [authState, setAuthState] = useState({
        user_name: null,
        auth_status: null,
        token:{}
    })

    const setAuthInfo = ({token, user_name, auth_status, role}) =>{
        setAuthState({
            token, user_name, auth_status, role
        })
    }
    
    const isCorporate = () =>{
        return authState.role === "dillivry-corp-sys-admin"
        
    }

    const isAuth = ()=>{
        // const validToken = new Date().getTime / 1000;
        // if (validToken < authState.token.ExpiresIn){
        //     return true;
        // }
        // else { return false; }

        if (authState.auth_status === "success"){
                return true;
            }
            else { return false; }
    }
    const isAdmin = ()=>{
        if (authState.role === "dillivry-corp-sys-admin" || authState.role === "dillivry-sole-trader-ops"){
                return true;
            }
            else { return false; }
    }
    
 return (
            <Provider
                value={{
                    authState,
                    setAuthState: authInfo => setAuthInfo(authInfo),
                    isAuth,
                    isCorporate,
                    logout
                }}
            >
            {children}   
            </Provider>
    )
}
export {AuthContext, AuthProvider};
