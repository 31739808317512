import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import './style.css'

const Copy = ({ tocopy, children }) => {
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false)
            }, 2000);
        }
    }, [copied])

    return (
        <div className="email_copy">
            <CopyToClipboard
                onCopy={() => setCopied(true)}
                text={tocopy}>
                    {children}
             </CopyToClipboard>
           
            {
                copied ? 
                    <div className='copied' >Copied</div> 
                    : ""
            }
        </div>
    )
}

export default Copy
