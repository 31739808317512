import React, { useEffect, useState,useContext } from 'react'
import {useHistory } from 'react-router-dom';
import SmallBtn from '../../../components/SmallBtn'
import './MobileTransaction.css'
import {IoMdArrowRoundBack} from 'react-icons/io'
import{DashboardContext}  from '../../../context/Dashboard/DashboardProvider/dashboard.provider'
import SecureLS from "secure-ls";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import axios from "axios";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import {Link} from "react-router-dom"
import dillivry from '../../../assets/Dillivry.png'
import TopBar from '../../../components/TopBar/TopBar';
import GlobalizationLanguage from '../../../components/GlobalizationLanguage';

 
function MobileTransaction() {
    var login=GlobalizationLanguage("/login")

    const ls = new SecureLS({
        isCompression: true,
        encodingType: "rabbit",
        encryptionSecret: "dm_dillivry",
      });

    const history = useHistory();

    const [loadingData, setloadingData] = useState(false)

    const {dashboards, updateDashboard} = useContext(DashboardContext)

    const [transaction, setTransaction] = useState([])

    useEffect(() => {
        if (dashboards !== undefined){
            setTransaction(dashboards.data.transaction_details.transaction_details)     
        }
        else{
            if(ls.get('_nodi') === ""){
                history.push(login)
              }
            else{
                const decoded = jwt_decode(ls.get("_nodi"));
                const expired = decoded.exp; 
                if (expired < Date.now() / 1000) {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Session!',
                      text: 'Session expired login again!',
                      allowOutsideClick: false,
                      confirmButtonColor: 'var(--main)'
                      })
                      .then((result) => {
                          if (result.value) {
                              ls.removeAll()
                              history.push(login)
                          }
                        });
                }
                else{
                    axios.get(`${process.env.REACT_APP_API}m-dillivry-dashboard-manager`, {
                        headers: {
                            Authorization: ls.get('_nodi')
                        }
                    })
                    .then((response) => {
                        setloadingData(false);

                        ls.set(
                            "means_of_delivery",
                            JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                          );
          
                        if (response.status === 200) {
                        setTransaction(response.data.transaction_details.transaction_details)     
                          updateDashboard(response);
                        } 
                    })
                }
            }
        }
    }, [])

    const numberWithCommas = new Intl.NumberFormat("en-GB")
    return (
        
        <>

        {
            loadingData ?
                <div style={{ width: '100%', height: '100vh', backgroundColor: 'none', zIndex: '300', position: 'fixed', top: '0', paddingTop: '5px' }}>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress color="success" />
                    </Box>
                    <header className="item-header-content-header">
                        <div className="item-header-content">
                            <div className="item-logo-content">
                            <Link to="/"> <img src={dillivry} alt="" className="item-content-logo" /></Link>
                            </div>
                        </div>
                    </header>
                </div>
                : null
            }
         {/* <div style={{display:'flex', marginBottom:'20px', gap:'20px'}}>
            <IoMdArrowRoundBack onClick={()=>history.goBack()} size='24px'/>
            <h2>Transaction</h2>
        </div> */}

        {
            transaction.map((info, index)=>{ return <div key={index} className="quoteCard">
                <div className="trans"> <span>Reference ID:</span> <div className="spanItem">{info.reference_id} </div> </div>
                <div className="trans"> <span>Delivery ID:</span>  <div className="spanItem">{info.delivery_id} </div> </div>
                <div className="trans"> <span>Amount due</span>  <div className="spanItem">&#8358;{numberWithCommas.format(info.amount_due_to_fulfiller)}</div>  </div>
                <div className="trans"> <span>Charges: </span>  <div className="spanItem">&#8358;{numberWithCommas.format(info.amount_due_to_platform)} </div> </div>
                <div className="trans"> <span>Gross Amount: </span>  <div className="spanItem">&#8358;{numberWithCommas.format(info.gross_amount)} </div> </div>
                <div className="trans"> <span>Payment Date: </span>  <div className="spanItem">{info.payment_date} </div> </div>
            </div>

            })
        }
            
        </>
    )
}

export default MobileTransaction
