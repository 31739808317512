import React,{useState, useEffect,useContext } from "react";
import Dillivry from "../../assets/Dillivry.png";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from 'react-router-dom';
import { Button, makeStyles } from "@material-ui/core";
import { AuthContext } from '../../context/AuthContext'
import SecureLS from 'secure-ls';
import DropdownComponent from "../DropdownComponent";
import Dropdowncountries from "../DropdownCountries";
import "./TopBar.css"
import SideBar from "./SideBar";
import GlobalizationLanguage from "../GlobalizationLanguage";

export default function TopBarHome() {
  const login = GlobalizationLanguage("/login")
  const Dashboard = GlobalizationLanguage("/Dashboard")
  var viewQuote = GlobalizationLanguage("/viewQuote")

  const auth = useContext(AuthContext);

  const logMeOut = () => {
    auth.logout();
    history.push('/')
    window.location.reload()
}

  const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

  const authContext = useContext(AuthContext)

  const [loggedIn, setLogggedIn] = useState(false);
  const [type, setType] = useState('')


  useEffect(() => {

    console.log(ls.get('eid'), 'individual')
    console.log(ls.get('_iRl'), 'irl')


    window.scrollTo(0, 0)
    if(ls.get('eid') === ""){
      setLogggedIn(false)
    }
    else{
      const acc_type = ls.get('_iRl');
        if(acc_type === 'individual'){
          setType('individual')
          
        }
        else if(acc_type === 'dillivry-sole-trader-ops'){
          setType('individual_provider')
          setLogggedIn(false)
        }
        else{setType('business')}
          setLogggedIn(true)
          
    }
}, [])

  const history = useHistory();
  const location = useLocation();

  const useStyles = makeStyles( theme => ({
    root: {
        width: "100%",
    },
    btn: {
        padding:'7px 18px',
        width:'120px',
        display:'flex',
        justifyContent:'center',
        borderRadius:'20px',
        background: 'white',
        color:  'var(--main)',
        fontSize:'16px',
        fontWeight:'400',
        border:'1px solid var(--main)',
        '&:hover': {
           background: 'var(--main)',
           color:  'white',
           border: `1px solid var(--main)`,
        },
      }
    }));

const classes = useStyles();
  return (
      <div className="topBar">
        <div className="displayDesktop">
          <div className="home_max_width" >
            <div className= "topBarBody">
              
            <Link to={GlobalizationLanguage('/')} className="homeLogo">
                <img src={Dillivry} alt="dillivry"  />
              </Link>

              <div className="linksCont">
                <Link to={GlobalizationLanguage(`/riders-and-drivers`)} style={{color: location.pathname.toLowerCase() === GlobalizationLanguage(`/riders-and-drivers`) ? "#50B14A" : "#484848"}}>Riders</Link>
                <Link to={GlobalizationLanguage(`/riders-and-drivers`)} style={{color: location.pathname.toLowerCase() === GlobalizationLanguage(`/riders-and-drivers`) ? "#50B14A" : "#484848"}}>Drivers</Link>
                <Link to={GlobalizationLanguage(`/logistics-companies`)} style={{color: location.pathname.toLowerCase() === GlobalizationLanguage(`/logistics-companies`) ? "#50B14A" : "#484848"}}>Logistic companies</Link>
                <Link to={GlobalizationLanguage(`/how-it-works`)} style={{color: location.pathname.toLowerCase() === GlobalizationLanguage(`/how-it-works`) ? "#50B14A" : "#484848"}}>How it works</Link>
                <Link to={GlobalizationLanguage(`/open-deliveries`)} style={{color: location.pathname.toLowerCase() === GlobalizationLanguage(`/open-deliveries`) ? "#50B14A" : "#484848"}}>Open Deliveries</Link>
              </div>
              <div className="actionBtnCont">
                {
                  loggedIn ?
                    <></>
                    :
                     <DropdownComponent  text="Create account" outline="white" color="white" bgColor="var(--main)" Size="small" style={{textTransform:'none', fontSize:'14px'}}/>
                }

                {
                    loggedIn ?
                      <>
                      {
                        type !== 'individual_provider' ?
                          <Link to={ type === 'individual' ?  viewQuote : authContext.isAuth ? type ==='individual_provider' ?  GlobalizationLanguage(`/open-deliveries`) : Dashboard : login}>
                            <div className={classes.btn}>My Dillivry</div>
                          </Link>
                          :
                          <>
                            <Link onClick={logMeOut} >
                                <Button variant="contained" style={{textTransform:'none', fontSize:'14px'}}>Logout</Button>
                            </Link>
                          </>
                      } 
                      </>
                      :
                      <Link to={login}>
                      <div 
                          className={classes.btn}>
                            Login
                          </div>
                      </Link>
                }
                    <Dropdowncountries/>
              </div>
              
            </div> 
        </div>
      </div>
      <div className="displayMobile"> 
        <SideBar/>
      </div>
    </div>
  );
}
