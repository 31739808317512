import { useState, useEffect, useContext } from "react";
import Layout from "../../Layout";
import "./Quotes.css";
import { useHistory } from "react-router-dom";

import axios from "axios";
import SecureLS from "secure-ls";

import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@material-ui/core";

import { useLocation } from "react-router-dom";

import { InputLabel, Grid } from "@material-ui/core";

import "../../../Registration/SenderRegistartion.css";

export default function ItemUpdatePage({
  main_category,
  itemCategory,
  sender_name,
  sender_email,
  sender_phone_no,
  onClick,
  previous,
  page = 1,
}) {
  const location = useLocation();
  const history = useHistory();
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });

  const [loading, setloading] = useState(false);

  const senderEmail = ls.get("eid");

  const n = 5;

  const [categorynew, setcategorynew] = useState(
    location?.state?.itemdetailss?.item_category?.charAt(0)?.toUpperCase() +
      location?.state?.itemdetailss?.item_category
        ?.toLowerCase()
        ?.slice(1)
        ?.replaceAll("_", " ")
  );

  const [from, setfrom] = useState(location?.state?.itemdetailss?.from);
  const [to, setto] = useState(location?.state?.itemdetailss?.to);
  const [descp, setdescp] = useState("");
  const [mode_of_delivery_new, setmode_of_delivery_new] = useState(
    location?.state?.itemdetailss?.mode_of_delivery
  );

  const ItemUpdate = (e) => {
    e.preventDefault();
    setdescp("")
    console.log(from, 1, to, 2, descp, 3, mode_of_delivery_new, 4, categorynew, 5);
  };


useEffect(() => {
   
console.log(location.state, 'item-to-use-toprefil')

}, []);


  return (
    <div>
      <div>
        <div className="dsktopscreen">
          <Layout>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  backgroundColor: "none",
                  zIndex: "300",
                  position: "fixed",
                  top: "70px",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <LinearProgress color="success" />
                </Box>
              </div>
            ) : (
              <div className="quoteCont">
                <h2 style={{ display: "flex", justifyContent: "center" }}>
                  Request a delivery
                </h2>

                <div className="formContain_request-">
                  <div className="form-style">
                    <form style={{ width: "100%" }} onSubmit={ItemUpdate}  >
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <div className="input-container">
                              <input
                                className="input-field"
                                readOnly
                                value={
                                  location?.state?.itemdetailss?.item_category
                                    ?.charAt(0)
                                    ?.toUpperCase() +
                                  location?.state?.itemdetailss?.item_category
                                    ?.toLowerCase()
                                    ?.slice(1)
                                    ?.replaceAll("_", " ")
                                }
                                type="text"
                                placeholder=""
                                name="usrnm"
                              />
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                            <div className="input-container">
                              <input
                                className="input-field-location-from"
                                readOnly
                                value={from}
                                type="text"
                                placeholder=""
                                name="usrnm"
                              />
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                            <div className="input-container">
                              <input
                                className="input-field-location-to"
                                readOnly
                                value={to}
                                type="text"
                                placeholder=""
                                name="usrnm"
                              />
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                            <div className="input-container">
                              <input
                                className="input-field-item-description"
                                onChange={(e) => setdescp(e.target.value)}
                                required
                                type="text"
                                placeholder=""
                                name="usrnm"
                              />
                            </div>
                          </Grid>

                          <InputLabel id="select">
                            <span
                              style={{
                                color: "var(--main)",
                                marginRight: "10px",
                              }}
                            ></span>
                            Mode of delivery
                          </InputLabel>
                          <Grid item xs={12}>
                            <div className="input-container">
                              <input
                                className="input-field"
                                readOnly
                                value={mode_of_delivery_new}
                                type="text"
                                placeholder=""
                                name="usrnm"
                              />
                            </div>
                          </Grid>

                          <Grid item xs={12}>
                            <button
                            //   onClick={ItemUpdate}
                              type="submit"
                              style={{
                                marginTop: "10px",
                                width: "100%",
                                fontWeight: "600",
                                fontSize: "16px",
                                textAlign: "center",
                                userSelect: "none",
                                cursor: "pointer",
                                padding: "13px",
                                backgroundColor: "var(--main)",
                                color: "white",
                                borderRadius: "3px",
                                border: 0,
                              }}
                              value="Update"
                            >
                                Update
                                </button>
                          </Grid>
                        </Grid>
                     
                    </form>
                  </div>
                </div>
              </div>
            )}
          </Layout>
        </div>
      </div>
    </div>
  );
}
