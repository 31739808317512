import {useState,useContext,useEffect} from 'react'
import {useHistory} from 'react-router-dom';
import { AuthContext } from '../../../../context/AuthContext';
import { TrackContext } from '../../../../context/TrackingContext';
import UserSetting from '../UserSetting';
import DialogOutClick from '../../../../components/DialogOutClick';
import { PuffLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import axios from 'axios';
import SecureLS from 'secure-ls';
import{DashboardContext}  from '../../../../context/Dashboard/DashboardProvider/dashboard.provider'
import { ConnContext } from '../../../../context/ConnContext';
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import '../Fulfiller_account.css'
import AccountLayout from '../AccountLayout';
import MobileCorpLayout from '../CorporateComponents/MobileCorpLayout';

function CorpUsers() {
    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
    const {dashboards, updateDashboard} = useContext(DashboardContext)

    const auth = useContext(AuthContext);
    const track = useContext(TrackContext)

    const history = useHistory();
    const [showLogout, setShowLogout] = useState(false)
    const [showDeteleAcct, setShowDeteleAcct] = useState(false)
    const [loading, setloading] = useState(false)
    const [loadingData, setloadingData] = useState(false)
    const [deleteInput, setdeleteInput] = useState('')
    
    const [account, setaccount] = useState([])
    const [userProfile, setuserProfile] = useState([])
    const authAxios = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers:{
           Authorization:  ls.get('_nodi')
        }
    })

    useEffect(() => {
        if (dashboards !== undefined) {
            setaccount(dashboards.data.my_account_info.my_account_info)
            setuserProfile(dashboards.data.account_profile.account_profile[0])
        }
        else{
            setloadingData(true)
            authAxios.get(`m-dillivry-dashboard-manager`)
            .then((response) => {
                ls.set(
                    "means_of_delivery",
                    JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                  );
                  
                setloadingData(false)
                updateDashboard(response);
                setaccount(response.data.my_account_info.my_account_info)
                setuserProfile(response.data.account_profile.account_profile[0])
            })
            .catch((err)=>{
                setloadingData(false)
                if (err?.response?.status === 403) {
                    Swal.fire({
                      icon: "warning",
                      title: "Session!",
                      text: "Session expired login again!",
                      allowOutsideClick: false,
                      confirmButtonColor: "var(--main)",
                    }).then((result) => {
                      if (result.value) {
                        auth.logout();
                        history.push(`/login`);
                      }
                    });
                } 
            })
        }
    }, [])
    const logMeOut = ()=>{
       auth.logout();
        history.push('/') 
    }
   
    const endpoint = process.env.REACT_APP_API
    const {visitingCountryCode} = useContext(ConnContext)

    const disableAccount = () =>{
        axios.delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
            headers:{
                Authorization:  ls.get('_nodi')
             },
             data : {
                "action_type": "disable_account",
                "vcc": visitingCountryCode
            }
          })
        .then((res)=>{
            setShowDeteleAcct(false)
            Swal.fire({
                title: 'Account disabled',
                text: 'contact the dillivry team to enable you account back',
                allowOutsideClick: false,
                confirmButtonColor:'var(--main)',
            })
            .then((result)=>{
                if (result.value) {
                    auth.logout();
                    history.push('/') 
                }
            });
        })
        .catch((err)=>{
            if(err?.response?.status === 403){
                Swal.fire({
                    icon: 'warning',
                    title: 'Session!',
                    text: 'Session expired login again!',
                    allowOutsideClick: false,
                    confirmButtonColor: 'var(--main)'
                })
                .then((result) => {
                    if (result.value) {
                        auth.logout();
                        history.push('/login') 
                    }
                });
            }
            else{
                Swal.fire({
                    icon: 'info',
                    text: 'An error occur try again',
                    timer: 3000,
                    showConfirmButton: false,
                })
            }
        })
    }
   
    const deleteMyAccount = () =>{
        setloading(true);
        axios.delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
            headers:{
                Authorization:  ls.get('_nodi')
             },
             data : {
                "action_type": "delete_account",
                "vcc": visitingCountryCode
            }
          })
        .then((res)=>{
            setShowDeteleAcct(false)

            Swal.fire({
                title: 'Account deleted',
                text: 'We are very sorry to see you go',
                allowOutsideClick: false,
                confirmButtonColor:'var(--main)',
            })
            .then((result)=>{
                if (result.value) {
                    auth.logout();
                    history.push('/') 
                }
            });
        })
        .catch((err)=>{
            if(err?.response?.status === 403){
                Swal.fire({
                    icon: 'warning',
                    title: 'Session!',
                    text: 'Session expired login again!',
                    allowOutsideClick: false,
                    confirmButtonColor: 'var(--main)'
                })
                    .then((result) => {
                        if (result.value) {
                            auth.logout();
                            history.push('/login') 
                        }
                    });
            }
            else{
                Swal.fire({
                    icon: 'info',
                    text: 'An error occur try again',
                    timer: 3000,
                    showConfirmButton: false,
                })
            }
    })
}

const SearchBar = () => {
    return (
      <div className="search">
        <div className="search-input-navbar">
          <input
            type="text"
             value=""
            // autoFocus
             placeholder="search.."
             onChange=""
          />
          {/* <span className="searchx-icon"><MdSearch style={{color:"#989898"}}/></span> */}
        </div>
      </div>
    );
  };


    const numberWithCommas = new Intl.NumberFormat("en-GB")
    return (
        <>
       
            <div className='deskstopcorp_con'>
            <AccountLayout>
                {/* <SearchBar/> */}
                <>
                    {loadingData ? (
                        <div
                        style={{
                            width: "100%",
                            height: "100vh",
                            backgroundColor: "none",
                            zIndex: "300",
                            position: "fixed",
                            top: "0",
                            paddingTop: "5px",
                        }}
                        >
                        <Box sx={{ width: "100%" }}>
                            <LinearProgress color="success" />
                        </Box>
                        </div>
                    ) : null}

                        <div className="ccount">
                        
                        <UserSetting/>
                    </div>

                </>
            
           </AccountLayout>
            </div>

       
        <div className="mobilecorp_con">
            
        <MobileCorpLayout pageTitle="User manager" state={"account"} />
            {/* mobile view start here */}
                <div style={{padding:"20px"}} >
                    <UserSetting/>
                </div>
            {/* mobile view ends here */}

           
        </div>


        <DialogOutClick
                open={showLogout}
                handleClose ={()=>setShowLogout(false)}
                > 
                <div style={{width:'100%',padding:"30px 30px 20px 30px"}}>
                    <div style={{width:'100%',marginBottom:"30px", fontSize:'13px', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <span>Are you sure you want to logout?</span>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
                        <div className="disBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer'}}
                            onClick={()=>setShowLogout(false)} >
                            No
                        </div>
                        <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'white', backgroundColor:'#808080', border:'1px solid #808080' }}
                            onClick={logMeOut}>
                            Yes
                        </div>
                    </div>
                </div>
            </DialogOutClick>
            <DialogOutClick
                open={showDeteleAcct}
                handleClose ={()=>setShowDeteleAcct(false)}
                > 
                <div style={{width:'100%',padding:"30px 30px 20px 30px"}}>
                    <div style={{width:'100%',marginBottom:"30px", fontSize:'13px', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <span>Are you sure you want to delete this account? Type in <b>permanently delete</b> in the input below to confirm deletion</span>
                    </div>
                    <div style={{marginBottom:'30px'}}>
                        <input 
                            type="text" 
                             placeholder="permanently delete"
                             value={deleteInput}
                             onChange={(e)=>setdeleteInput(e.target.value)}
                             style={{border:'1px solid var(--main)', padding:'7px 10px', outline:'none', borderRadius:'5px', fontSize:'13px'}}
                        />
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
                        <div className="disBtn" style={{fontSize:'13px', fontWeight:'bold', cursor:'pointer',border:'#0080FF solid 1px', backgroundColor:'#0080FF', color:'white'}}
                            onClick={disableAccount} >
                            Disable instead
                        </div>
                        <div className="disBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer'}}
                            onClick={()=>setShowDeteleAcct(false)} >
                            Cancel
                        </div>
                        {
                            deleteInput === "permanently delete" ?
                            <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'white', backgroundColor:'red', border:'1px solid red' }}
                                onClick={deleteMyAccount}>
                                {
                                    loading ? 
                                    <PuffLoader color='var(--main)' loading={loading}  speedMultiplier={1} size={15}/> 
                                    :
                                    'Yes'
                                }
                            </div>
                            :
                            <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'not-allowed', color:'white', backgroundColor:'#B86566', border:'1px solid #B86566' }}>
                                Yes
                            </div>
                        }
                    </div>
                </div>
            </DialogOutClick>
    </>
       
    )
}

export default CorpUsers
