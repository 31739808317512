import React, {useState, useContext, useEffect} from 'react'
import {Grid} from '@material-ui/core';
import RequestGeneral from './RequestGeneral';
import '../../../Registration/SenderRegistartion.css'
import Layout from '../../Layout';
import { SenderContext } from '../../../../../context/SenderDashboard/SenderDashboardContext';
import RequestHaulage from './RequestHaulage';
import RequestHome from './RequestHome';
import RequestDeliveryMobile from './RequestDeliveryMobile';
import CategorySelector from '../../../../../components/CategorySelector';
import { useLocation } from "react-router-dom";
import SecureLS from "secure-ls";
import usePreventReload from './usePreventReload';
import axios from 'axios';
import GooglePlaceAutocomplete from '../../../../../components/GooglePlaceAutocomplete';

export default function RequestDelivery({noshowLayout}) {
    const ls = new SecureLS({
        isCompression: true,
        encodingType: "rabbit",
        encryptionSecret: "dm_dillivry",
    });

    usePreventReload(true);

    const [catIsValid, setcatIsValid] = useState(false);

    const [item_category, setitem_category] = useState('');
    
    const [main_item_category, setmain_item_category] = useState('');
    const endpoint = process.env.REACT_APP_API;

    const handleChange = (cat) => {
        setitem_category(cat);
        setcatIsValid(false)
    };

    const handleChangeMainCategory = (cat) => {
        setmain_item_category(cat);
    };
    
    const location = useLocation();
    const [userInfo, setuserInfo] = useState({})

    const [page, setPage] = useState(1)
    
    const {initialstate, UpdateAlldata, alldata,  } = useContext(SenderContext)

    const [pickup_address, setPickup_address] = useState("");
    const [delivery_address, setDelivery_address] = useState("");
    const [pickupIsError, setpickupIsError] = useState(false);
    const [deliveryIsError, setdeliveryIsError] = useState(false);
    const [deli_locatn_frm_pickup_add, setdeli_locatn_frm_pickup_add] = useState('')

    useEffect(() => {
        if(alldata !== undefined){
            setuserInfo({
                sender_name :   alldata?.data?.data?.deliveries ?   alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map((x) => x?.account).flat().map(x => x?.account_info).flat()?.map(x => x.given_name)[0] : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map((x) => x?.account).flat().map(x => x?.account_info).flat()?.map(x => x.given_name)[0]  ,
                sender_email : alldata?.data?.data?.deliveries ?   alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map((x) => x?.account).flat().map(x => x?.account_info).flat()?.map(x => x.user_id)[0] :  alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map((x) => x?.account).flat().map(x => x?.account_info).flat()?.map(x => x.user_id)[0],
                sender_phone_no : alldata?.data?.data?.deliveries ?   alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map((x) => x?.account).flat().map(x => x?.account_info).flat()?.map(x => x.phone_number?.phone_number)[0] : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map((x) => x?.account).flat().map(x => x?.account_info).flat()?.map(x => x.phone_number?.phone_number)[0]
            })
        }
        else{
            axios.get(`${endpoint}m-dillivry-sender-api?vcc=ng&attribute=get_user`, {
                headers: {
                  Authorization: ls.get("_utk"),
                },
            })
            .then((res) => {
                setuserInfo({
                    sender_name : res?.data?.given_name,
                    sender_email : res?.data?.user_id,
                    sender_phone_no : res?.data?.phone_number?.phone_number,
                })
            })

            .catch((err)=>{
            console.log(err)
            // setloading(false)
            })

            axios.get(`${endpoint}m-dillivry-sender-api`, {
                headers: {
                  Authorization: ls.get("_utk"),
                },
            })
                .then((res) => {
                    UpdateAlldata(res)
                }).catch((error)=>{
                    console.log(error, )
                })
        }
    }, [])

    useEffect(() => {
        
        location?.state?.data?.item_category === "jewellery_&_accessories" ?
        setitem_category(`Jewellery & Accessories`) :
        location?.state?.data?.item_category === "document_&_books" ?
        setitem_category(`Document & Books`) :
        location?.state?.data?.item_category === "beauty_&_skincare" ?
        setitem_category(`Beauty & Skincare`) :

        location?.state?.data?.item_category === "cake_&_confectionery" ?
        setitem_category(`Cake & Confectionery`) :

        location?.state?.data?.item_category === "computer_&_accessories" ?
        setitem_category(`Computer & Accessories`) :

        location?.state?.data?.item_category === "electronics_&_appliances" ?
        setitem_category(`Electronics & Appliances`) :

        location?.state?.data?.item_category === "phones_&_accessories" ?
        setitem_category(`Phones & Accessories`) :

        location?.state?.data?.item_category === "wine_&_drinks" ?
        setitem_category(`Wine & Drinks`) 
        
        : setitem_category(location?.state?.data?.item_category?.charAt(0)?.toUpperCase() +  location?.state?.data?.item_category?.replaceAll("_", " ")?.slice(1) )
        
        }, []);

    return (
        <div className="mbl-dsk">
            <div className="dsktopscreen">
            <Layout>
                <div className="updateCon" style={{height:'auto'}}>
                    <div className="formContain_request" style={{height:'100vh'}}>
                        <form style={{ width: '100%' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <h3 style={{ color: 'var(--main)' }}>Request a delivery</h3>
                                </Grid>
                                {
                                    page === 1 ?
                                    <>
                                    <Grid item xs={12}>
                                        <GooglePlaceAutocomplete
                                            handleChange={(pickup_address) => {
                                            setPickup_address(pickup_address);
                                            setpickupIsError(true);
                                            }}
                                            handleSelect={(pickup_address) => {
                                            setPickup_address(pickup_address);
                                            setpickupIsError(false);
                                            }}
                                            setdeli_locatn_frm_pickup_add = {setdeli_locatn_frm_pickup_add}
                                            value={pickup_address}
                                            text= {item_category === 'Moving home' ? "Address moving from" :"Enter pick up address"}
                                            color="var(--main)"
                                            error={pickupIsError}
                                            pickup
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <GooglePlaceAutocomplete
                                            handleChange={(delivery_address) => {
                                            setDelivery_address(delivery_address);
                                            setdeliveryIsError(true);
                                            }}
                                            handleSelect={(delivery_address) => {
                                            setDelivery_address(delivery_address);
                                            setdeliveryIsError(false);
                                            }}
                                            value={delivery_address}
                                            text= {item_category === 'Moving home' ? "Address moving to" : "Enter delivery address"}
                                            color="red"
                                            error={deliveryIsError}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CategorySelector country={deli_locatn_frm_pickup_add} setmainCategory={handleChangeMainCategory} setitem_category={handleChange} categorySelected={item_category}/>
                                    </Grid>
                                    </>
                                :
                                null
                                }
                                <Grid item xs={12}>
                                { 
                                item_category === 'Haulage'   || location?.state?.data?.item_category === "haulage"  ? 
                                <RequestHaulage  page={page}  onClick={()=>{
                                    setPage(page+1);
                                }}
                                previous={()=>{
                                    setPage(1);
                                }}
                                    itemCategory={item_category} 
                                    sender_name = {userInfo?.sender_name}
                                    sender_email = {userInfo?.sender_email}
                                    sender_phone_no = {userInfo?.sender_phone_no}
                                    details_from_location={location?.state}

                                    delivery_location = {deli_locatn_frm_pickup_add}
                                    pickup_address = {pickup_address}
                                    delivery_address = {delivery_address}
                                    pickupIsError = {pickupIsError}
                                    deliveryIsError = {deliveryIsError}
                                    setpickupIsError = {setpickupIsError}
                                    setdeliveryIsError = {setdeliveryIsError}
                                 />    

                                :
                                item_category === 'Moving home' || location?.state?.data?.item_category?.charAt(0)?.toUpperCase() +  location?.state?.data?.item_category?.replaceAll("_", " ")?.slice(1) === 'Moving home' ? 
                                <RequestHome
                                    page={page}  onClick={()=>{
                                        setPage(page+1);
                                    }}
                                    previous={()=>{
                                        setPage(1);
                                    }}
                                    itemCategory={item_category} 
                                    sender_name = {userInfo?.sender_name}
                                    sender_email = {userInfo?.sender_email}
                                    sender_phone_no = {userInfo?.sender_phone_no}
                                    details_from_location={location?.state}

                                    delivery_location = {deli_locatn_frm_pickup_add}
                                    pickup_address = {pickup_address}
                                    delivery_address = {delivery_address}
                                    pickupIsError = {pickupIsError}
                                    deliveryIsError = {deliveryIsError}
                                    setpickupIsError = {setpickupIsError}
                                    setdeliveryIsError = {setdeliveryIsError}
                                        
                                 />    
                                :
                                <RequestGeneral 
                                    sender_name = {userInfo?.sender_name}
                                    sender_email = {userInfo?.sender_email}
                                    sender_phone_no = {userInfo?.sender_phone_no}
                                    itemCategory={item_category} 
                                    main_category={main_item_category} 
                                    details_from_location={location?.state}

                                    delivery_location = {deli_locatn_frm_pickup_add}
                                    pickup_address = {pickup_address}
                                    delivery_address = {delivery_address}
                                    pickupIsError = {pickupIsError}
                                    deliveryIsError = {deliveryIsError}
                                    setpickupIsError = {setpickupIsError}
                                    setdeliveryIsError = {setdeliveryIsError}
                                    onClick={()=>{
                                        setPage(page+1);
                                    }}
                                    previous={()=>{
                                        setPage(1);
                                        
                                    }}
                                    page={page}
                                />
                                }
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Layout>
            
        </div>
            
            <div className="mobilescreen">
                <RequestDeliveryMobile
                    page={page}
                    catIsValid={catIsValid}
                    item_category={item_category}
                    main_category={main_item_category} 
                    handleChange={handleChange}
                    handleChangeMainCategory = {handleChangeMainCategory}
                    userInfo={userInfo}
                    setPage={setPage}
                    noshowLayout={noshowLayout}

                    setPickup_address = {setPickup_address}
                    setpickupIsError = {setpickupIsError}
                    setdeli_locatn_frm_pickup_add = {setdeli_locatn_frm_pickup_add}
                    pickupIsError = {pickupIsError}
                    pickup_address = {pickup_address}
                    setDelivery_address = {setDelivery_address}
                    deliveryIsError={deliveryIsError}
                    delivery_address={delivery_address}
                    setdeliveryIsError={setdeliveryIsError}
                    deli_locatn_frm_pickup_add={deli_locatn_frm_pickup_add}
                />
            </div>
        </div>
    )
}
