import { Dialog, DialogContent } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

function DialogOTP_Pop({ children, openPopup}) {
    const theme = createTheme({
        palette: {
          primary: {
            main: '#50B14A',
          },
        },
      });
    return (
      <ThemeProvider theme={theme}>
        <Dialog open={openPopup} maxWidth="sm">
          <DialogContent>
            {children}
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    );
}
export default DialogOTP_Pop;