// import "./styles.css";
import React from "react";
import { PieChart, Pie, Cell} from "recharts";
import './PieChart.css'

// const data = [
//   { name: "Group A", value: 400 },
//   { name: "Group B", value: 300 },
//   { name: "Group C", value: 300 },
//   { name: "Group D", value: 200 }
// ];
const numberWithCommas = new Intl.NumberFormat("en-GB")

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function PieGraph({data}) {
  return (
    <>
    <div className="chartweb" >
      <div style={{display:'flex', gap:'100px'}}>
        <PieChart width={320} height={220}>
          <Pie
            data={data}
            cx={200}
            cy={100}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={100}
            fill="#8884d8"
            dataKey="amount"
            >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>

          <div className="dayss">
            <div className="dayHead">
              <span>Days</span>
              <span style={{width:'40%', paddingLeft:'7px'}}>Revenue (&#8358;)</span>
            </div>
            {
              data.map((datum, index)=>{ return <div key={index} className="dayBody">
                  <span style={{display:'flex', gap:'5px',alignItems:'center', padding:'5px 10px'}} > 
                    <div className="colorBox" style={{backgroundColor:`${datum.color}`}}></div> 
                      {datum.day}
                  </span>
                  <span style={{width:'40%',paddingLeft:'7px',  borderLeft:'1px solid #EAEAEA', padding:'5px 10px'}}>
                  {numberWithCommas.format(datum.amount)}
                  </span>
                </div>
              })
            }
          </div>
        </div>
    </div>

  <div className="chartMob">
    <div style={{display:'flex', gap:'20px', flexDirection:'column'}}>
    <PieChart width={320} height={220}>
      <Pie
        data={data}
        cx={200}
        cy={100}
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={100}
        fill="#8884d8"
        dataKey="amount"
        >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>
    </PieChart>

      <div className="dayss">
        <div className="dayHead">
          <span>Days</span>
          <span style={{width:'40%', paddingLeft:'7px'}}>Revenue (NGN)</span>
        </div>
        {
          data.map((datum, index)=>{ return <div key={index} className="dayBody">
              <span style={{display:'flex', gap:'5px',alignItems:'center', padding:'5px 10px'}} > 
                <div className="colorBox" style={{backgroundColor:`${datum.color}`}}></div> 
                  {datum.day}
              </span>
              <span style={{width:'40%',paddingLeft:'7px',  borderLeft:'1px solid #EAEAEA', padding:'5px 10px'}}>
              {numberWithCommas.format(datum.amount)}
              </span>
            </div>
          })
        }
      </div>
    </div>

</div>
    </>
  );
}
