/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { FaTimes } from "react-icons/fa";
import "./QuoteSignInForm.css";
import { IconContext } from "react-icons";
import { RiLockPasswordLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { AuthContext } from "../../../context/AuthContext";
import SecureLS from "secure-ls";
import DialogOutClick from "../../../components/DialogOutClick";
import DialogSimplePop from "../../../components/DialogSimplePop";
import Accounthand2 from "../../../assets/Accounthand2.svg";
import erros from "../../../assets/erros.png";
import firstimage from "../../../assets/iOS-getApp.svg";
import secondimage from "../../../assets/Google-GetApp.svg";
import { AiOutlineUserAdd } from "react-icons/ai";
import { ConnContext } from "../../../context/ConnContext";
import { FormControl, Button, InputLabel } from "@material-ui/core";
import InputField from "../../../components/InputField";
import { HiOutlineMail } from "react-icons/hi";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
export default function QuoteSignInForm({ onFinish, onClose, onCloseCorp }) {
  var login=GlobalizationLanguage("/login")
  var deliver_with_us = GlobalizationLanguage("/create-account")
  const forgetpass=GlobalizationLanguage("/forgetpass")

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const history = useHistory();
  const endPoint = process.env.REACT_APP_API;
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [submitting, setsubmitting] = useState(false);
  const authContext = useContext(AuthContext);
  const [userType, setuserType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const auth = useContext(AuthContext);
  const endpoint = process.env.REACT_APP_API;
  const {visitingCountryCode} = useContext(ConnContext)

  const [showpassword, setshowpassword] = useState(false);

  const [showAlertSender, setshowAlertSender] = useState(false)

  const handleLogin = (e) => {
    e.preventDefault();
    var encodedPassword = btoa(password);
    setsubmitting(true);
    axios
      .post(`${endPoint}m-dillivry-auth-manager`, {
        auth_type: "login",
        username: username.toLowerCase(),
        password: encodedPassword,
        // vcc: visitingCountryCode,
      })
      .then((response) => {
        
        if (response) {
          if (response.data.auth_status === "success") {
    ls.set("user_location", response?.data?.vcc)

            const { IdToken } = response.data.token;
            const token = IdToken.split(".")[1];
            const encodedToken = atob(token);
            const tokenJSON = JSON.parse(encodedToken);
            
            if (tokenJSON["custom:role"] !== undefined) {
              authContext.setAuthState(
                Object.assign(response.data, { role: tokenJSON["custom:role"] })
              );
              if ( response.data.role === "dillivry-corp-sys-admin" ) {
                ls.set("_nodi", response.data.token.IdToken);
                ls.set("_iRl", response.data.role);
                ls.set("nful", tokenJSON["given_name"]);
                ls.set("_tyf_", tokenJSON["custom:account_name"]);
                ls.set("_iyD", tokenJSON["custom:account_name"]);
                ls.remove("_utk")
                ls.set("aCs_", response.data?.account_status);
                ls.set("eid", response.data?.user_name);
                ls.set("btwl", response?.data?.data?.user_info[0]?.wallet_balance);// walllet balance
                setsubmitting(false);
                window.location.reload()
                if (response.data.account_status !== "active") { onFinish("inactive");} 
                else { 
                  axios
                  .get(`${endpoint}m-dillivry-dashboard-manager`, {
                    headers: {
                      Authorization: response.data.token.IdToken,
                    },
                  })
                  .then((response) => {
                    ls.set(
                      "means_of_delivery",
                      JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                    );
                    setsubmitting(false);

                    onFinish();
                  })
                  .catch((error) => {
                  console.log('dashboard error', error)
                  });
                }
              }
              if (
                response.data.role === "dillivry-sole-trader-ops" 
              ) {
                setuserType("individual-as-corporate");
                setShowAlert(true);
                // setuserType("individualprovider");
               
                setsubmitting(false);
                ls.set("aCs_", response.data?.account_status);
                
                ls.set("_nodi", response.data.token.IdToken);
                ls.set("_iRl", response.data.role);
                ls.set("nful", tokenJSON["given_name"]);
                ls.set("_tyf_", tokenJSON["custom:account_name"]);
                ls.set("_iyD", tokenJSON["custom:account_name"]);
                ls.set("eid", response.data.user_name);
                ls.remove("_utk");
                ls.remove("_iRl")
                ls.remove("_nodi")
                ls.remove("eid")
                setsubmitting(false);
                // window.location.reload()
                if (response?.data?.account_status !== "active") {
                  onFinish("inactive");
                } 
                else { 
                  axios
                  .get(`${endpoint}m-dillivry-dashboard-manager`, {
                    headers: {
                      Authorization: response.data.token.IdToken,
                    },
                  })
                  .then((response) => {
                    ls.set(
                      "means_of_delivery",
                      JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                    );
                    setsubmitting(false);

                    onFinish(); 
                  })
                  .catch((error) => {
                  console.log('dashboard error', error)
                  });
                }
              }    
              if (
                response.data.role === "dillivry-corp-bid-ops" 
              ) {
                ls.set("_nodi", response.data.token.IdToken);
                ls.set("_iRl", response.data.role);
                ls.set("nful", tokenJSON["given_name"]);
                ls.set("_tyf_", tokenJSON["custom:account_name"]);
                ls.set("_iyD", tokenJSON["custom:account_name"]);
                ls.set("eid", response.data.user_name);
                ls.remove("_utk");
                setsubmitting(false);
                window.location.reload()
                if (response?.data?.account_status !== "active") {
                  onFinish("inactive");
                  setsubmitting(false);
                } else { 
                  axios
                  .get(`${endpoint}m-dillivry-dashboard-manager`, {
                    headers: {
                      Authorization: response.data.token.IdToken,
                    },
                  })
                  .then((response) => {
                    ls.set(
                      "means_of_delivery",
                      JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                    );
                    setsubmitting(false);
                    onFinish(); 
                  })
                  .catch((error) => {
                  console.log('dashboard error', error)
                  });
                }
              }
            }
            else{
              setshowAlertSender(true)
              onFinish()
              setsubmitting(false);
            }
          }
        }
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          onFinish();
       
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              auth.logout();
              history.push(login);
            }
          });
        } else {
          setuserType("error-login");
          onFinish();
          Swal.fire({
            title: "Oops!",
            imageAlt: "A tall image",
            text: "Invalid username or password",
            confirmButtonColor: "var(--main)",
          });
          setsubmitting(false);
        }
      });
  };

  return (
    <div>
      <div className="form-body">
        <div className="form-heading-close-button">
          <div className="left-title">
            <p className="left-content-title">Please sign in or register</p>
          </div>
          <div onClick={() => onClose()} className="right-close-btn">
            <IconContext.Provider
              value={{ color: "white", className: "global-class" }}
            >
              <div>
                <FaTimes />
              </div>
            </IconContext.Provider>
          </div>
        </div>

        <p className="form-instructions">
          New to Dillivry? Click the Register button for free registration.
        </p>

        <div className="line-top"></div>

        <div className="form-providertype-drop-down-corporate">
          <div className="form-providertype-drop-down-corporate-inner">
            <div className="provider-type">
              {" "}
              <p className="provider-type-content">Account sign in </p>
            </div>

            <div className="provider-checkbox-type">
            
            </div>
          </div>
        </div>

        <div className="form-body-sign-in">
          <div className="form-home-">
            <form action="" onSubmit={handleLogin} className="form-body-form">
            <FormControl className="formInput" variant="outlined">
                        <InputField
                        label="Email"
                          required
                          name="username"
                          id="username"
                          type="email"
                          value={username}
                          labelWidth={60}
                          onChange={(e) => {
                            setusername(e.target.value.trim());
                          }}
                          icon={
                            <HiOutlineMail
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                          }
                        />
                      </FormControl>

                      <FormControl className="formInput" variant="outlined">
                        
                        <InputField
                          // error={modelisValid}
                          label="Password"
                          required
                          name="password"
                          id="password"
                          type={showpassword ? "text" : "password"}
                          endIcon={
                            showpassword ? (
                              <Tooltip title="Hide password" interactive>
                                <VisibilityOffIcon
                                  style={{
                                    color: "grey",
                                    fontSize: "25px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setshowpassword(!showpassword)}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Show password" interactive>
                                <VisibilityIcon
                                  style={{
                                    color: "grey",
                                    fontSize: "25px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setshowpassword(!showpassword)}
                                />
                              </Tooltip>
                            )
                          }
                          value={password}
                          labelWidth={90}
                          onChange={(e) => {
                            setpassword(e.target.value);
                          }}
                          icon={
                            <RiLockPasswordLine
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                          }
                        />
                      </FormControl>



              <div className="remember-forgot-password">
                <div className="remmber-me">
                  <p className="remember-me-content">
                    Remember?{" "}
                    <input type="checkbox" className="remember-checkbox" />
                  </p>
                </div>

                <div className="forgot-password-">
                  <p
                    className="forgort-password-content"
                    onClick={() => history.push(forgetpass)}
                  >
                    Forgot password?
                  </p>
                </div>
              </div>

              <div className="sign-in-to-your-account">
                {" "}
                {submitting ? (
                  <p style={{ textAlign: "center", color: "green" }}>
                    Loading...
                  </p>
                ) : (
                  <p className="sign-in-content">
                    <button type="submit">Sign in to your Account</button>
                  </p>
                )}
              </div>
            </form>

            <div className="new-dillivry">
              <div className="inner-register-content">
                <div className="content-question">
                  <p className="newtodillivry-content">New to Dillivry?</p>
                </div>

                <div className="register-btn-">
                  <button
                    onClick={() => history.push(deliver_with_us)}
                    className="register-content"
                  >
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DialogSimplePop
        openPopup={showAlert}
        btnText="Ok"
        onClick={() => setShowAlert(false)}
      >
        {userType === "individual-as-corporate" ? (
          // <>
          //   <div>Oops! You have selected a wrong provider type.</div>
          //   <div>Check and try again.</div>
          //   <br></br>
          //   <div style={{ width: "100%" }}>
          //     <img src={Accounthand2} alt="accounthand2" className="handLogo" />
          //   </div>
          // </>
          <>
          <div>Oops! All Riders/Drivers operations are within the app.</div>
          <div>Please download the app and login to your account.</div>
          <div
            style={{
              display: "flex",
              gap: "20px",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "30px",
            }}
          >
            <img
              src={secondimage}
              alt="playstore"
              className="storeLogo"
            />
            <img src={firstimage} alt="appstore" className="storeLogo" />
          </div>
        </>

        ) : userType === "corporate-as-individual" ||
          userType === "corporate-as-bidder" ||
          userType === "bidder-as-individual" ? (
          <>
            <div>Oops! You have selected a wrong provider type.</div>
            <div>Check and try again.</div>
            <br></br>
            <div style={{ width: "100%" }}>
              <img src={Accounthand2} alt="accounthand2" className="handLogo" />
            </div>
          </>
        ) : null}
      </DialogSimplePop>

      <DialogOutClick
        open={showAlertSender}
        handleClose={() => setshowAlertSender(false)}
        >
          <>
          <div style={{ width: "100%",justifyContent: "center", alignItems: "center",display: "flex",flexDirection: "column",gap: "20px", padding:'20px'}} >
              <img src={erros} alt="dillivry" style={{ width: "60px" }} />
              <div style={{textAlign: "center", fontSize: "16px",fontWeight: "500", width: "100%", }}>
                <div>You cannot perform this action as a sender</div>
                <div>Login as a provider or create account to continue</div>
              </div>

              <Button size ="small"
                onClick={() => {history.push(deliver_with_us);}}
                variant="contained"
                style={{fontSize: "12px",
                border:'1px solid var(--main)', backgroundColor:'white', color:'var(--main)',
                  textTransform: "none",
                }}>
                <div style={{ display: "flex",gap: "5px",alignItems: "center",justifyContent:"center",}}>
                  <span>
                    <AiOutlineUserAdd size="12px" />
                  </span>
                  <span>Create account</span>
                </div>
              </Button>
            </div>
          </>
      </DialogOutClick>

      
    </div>
  );
}
