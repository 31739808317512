import {useState,useContext,useEffect} from 'react'
import {useHistory} from 'react-router-dom';
import MUTable from '../../../../components/MUTable'
import { AuthContext } from '../../../../context/AuthContext';
import DialogOutClick from '../../../../components/DialogOutClick';
import { PuffLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import axios from 'axios';
import SecureLS from 'secure-ls';
import{DashboardContext}  from '../../../../context/Dashboard/DashboardProvider/dashboard.provider'
import { ConnContext } from '../../../../context/ConnContext';
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import '../Fulfiller_account.css'
import AccountLayout from '../AccountLayout';
import MobileCorpLayout from '../CorporateComponents/MobileCorpLayout';
import MobileTransaction from '../MobileTransaction';
import DashBottomBtn from '../DashBottomBtn/DashBottomBtn';

function CorpAccount({booked, settlement, users}) {
    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

    const auth = useContext(AuthContext);
    const history = useHistory();
    const [showLogout, setShowLogout] = useState(false)
    const [showDeteleAcct, setShowDeteleAcct] = useState(false)
    const [loading, setloading] = useState(false)
    const [deleteInput, setdeleteInput] = useState('')
    const [loadingData, setloadingData] = useState(false)
    const [account, setaccount] = useState([])
    const [transaction, setTransaction] = useState([])
    const [userProfile, setuserProfile] = useState([])
  const [registered_country, setregistered_country] = useState([])

    
    const authAxios = axios.create({
      baseURL: `${process.env.REACT_APP_API}`,
      headers:{
         Authorization:  ls.get('_nodi')
      }
  })

    useEffect(() => {
      if (dashboards !== undefined) {
        setaccount(dashboards.data.my_account_info.my_account_info)
        setTransaction(dashboards.data.transaction_details.transaction_details)
        setuserProfile(dashboards.data.account_profile.account_profile[0])
        setregistered_country(dashboards?.data.account_profile?.account_profile[0]?.registered_countries)
        console.log(dashboards?.data.account_profile?.account_profile[0]?.registered_countries)
      }
      else{
          setloadingData(true)
          authAxios.get(`m-dillivry-dashboard-manager`)
          .then((response) => {
              setloadingData(false)
              ls.set(
                "means_of_delivery",
                JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
              );
              updateDashboard(response);
              setaccount(response.data.my_account_info.my_account_info)
              setTransaction(response.data.transaction_details.transaction_details)
              setuserProfile(response.data.account_profile.account_profile[0])
              setregistered_country(response?.data.account_profile?.account_profile[0]?.registered_countries)

          })
          .catch((err)=>{
              setloadingData(false)
              if (err?.response?.status === 403) {
                  Swal.fire({
                    icon: "warning",
                    title: "Session!",
                    text: "Session expired login again!",
                    allowOutsideClick: false,
                    confirmButtonColor: "var(--main)",
                  }).then((result) => {
                    if (result.value) {
                      auth.logout();
                      history.push(`/login`);
                    }
                  });
              } 
          })
      }
  }, [])

    const logMeOut = ()=>{
       auth.logout();
        history.push('/') 
    }
    const {dashboards, updateDashboard} = useContext(DashboardContext)
   
    const endpoint = process.env.REACT_APP_API
    const {visitingCountryCode} = useContext(ConnContext)

    const disableAccount = () =>{
        axios.delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
            headers:{
                Authorization:  ls.get('_nodi')
             },
             data : {
                "action_type": "disable_account",
                "vcc": registered_country[0],
            }
          })
        .then((res)=>{
            setShowDeteleAcct(false)
            Swal.fire({
                title: 'Account disabled',
                text: 'contact the dillivry team to enable you account back',
                allowOutsideClick: false,
                confirmButtonColor:'var(--main)',
            })
            .then((result)=>{
                if (result.value) {
                    auth.logout();
                    history.push('/') 
                }
            });
        })
        .catch((err)=>{
            if(err?.response?.status === 403){
                Swal.fire({
                    icon: 'warning',
                    title: 'Session!',
                    text: 'Session expired login again!',
                    allowOutsideClick: false,
                    confirmButtonColor: 'var(--main)'
                })
                .then((result) => {
                    if (result.value) {
                        auth.logout();
                        history.push('/login') 
                    }
                });
            }
            else{
                Swal.fire({
                    icon: 'info',
                    text: 'An error occur try again',
                    timer: 3000,
                    showConfirmButton: false,
                })
            }
        })
    }
   
    const deleteMyAccount = () =>{
        setloading(true);
        axios.delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
            headers:{
                Authorization:  ls.get('_nodi')
             },
             data : {
                "action_type": "delete_account",
                "vcc": registered_country[0],
            }
          })
        .then((res)=>{
            setShowDeteleAcct(false)

            Swal.fire({
                title: 'Account deleted',
                text: 'We are very sorry to see you go',
                allowOutsideClick: false,
                confirmButtonColor:'var(--main)',
            })
            .then((result)=>{
                if (result.value) {
                    auth.logout();
                    history.push('/') 
                }
            });
        })
        .catch((err)=>{
            if(err?.response?.status === 403){
                Swal.fire({
                    icon: 'warning',
                    title: 'Session!',
                    text: 'Session expired login again!',
                    allowOutsideClick: false,
                    confirmButtonColor: 'var(--main)'
                })
                    .then((result) => {
                        if (result.value) {
                            auth.logout();
                            history.push('/login') 
                        }
                    });
            }
            else{
                Swal.fire({
                    icon: 'info',
                    text: 'An error occur try again',
                    timer: 3000,
                    showConfirmButton: false,
                })
            }
    })
}

const SearchBar = () => {
    return (
      <div className="search">
        <div className="search-input-navbar">
          <input
            type="text"
             value=""
            // autoFocus
             placeholder="search.."
             onChange=""
          />
          {/* <span className="searchx-icon"><MdSearch style={{color:"#989898"}}/></span> */}
        </div>
      </div>
    );
  };


    const numberWithCommas = new Intl.NumberFormat("en-GB")
    return (
      <>
      <div className='deskstopcorp_con'>
        <AccountLayout>
            {/* <SearchBar/> */}
             <>
      {loadingData ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "none",
            zIndex: "300",
            position: "fixed",
            top: "0",
            paddingTop: "5px",
          }}
        >
        <Box sx={{ width: "100%" }}>
            <LinearProgress color="success" />
        </Box>
        </div>
        ) : null}
       
      
        <div className="Fulfiller_account">
          <div className="ccount">
          
            <div className="fulfillers">
              <div className="btnAssigns">
                <div></div>
             
              </div>
              <div className="usersTable">
                <MUTable
                  style={{ width: "100%", fontSize: "8px" }}
                  title="Transaction"
                  columns={[
                    { title: "Delivery id", field: "delivery_id" },
                    { title: "Reference id", field: "reference_id" },
                    {
                      title: "Amount due",
                      field: "amount_due_to_fulfiller",
                      render: (rowData) => (
                        <div >
                          {numberWithCommas.format(
                            rowData.amount_due_to_fulfiller
                          )}{" "}
                        </div>
                      ),
                    },
                    {
                      title: "Charges",
                      field: "amount_due_to_platform",
                      render: (rowData) => (
                        <div>
                          {numberWithCommas.format(
                            rowData.amount_due_to_platform
                          )}{" "}
                        </div>
                      ),
                    },
                    {
                      title: "Gross Amount",
                      field: "gross_amount",
                      render: (rowData) => (
                        <div>
                          {numberWithCommas.format(rowData.gross_amount)}{" "}
                        </div>
                      ),
                    },
                    { title: "Payment Date", field: "payment_date" },
                  ]}
                  data={transaction}
                  options={{
                    pageSize: 10,
                    search:false,
                    rowStyle: {
                        textAlign:"center"
                      }
                    
                  }}
                  
                />
              </div>
            </div>
          </div>

        
         
        
        </div>
      </>

        </AccountLayout>

      </div>
        
        <div className='mobilecorp_con'>
          <MobileCorpLayout pageTitle="Transaction" state={"account"} />
            {/* mobile view start here */}
            <div style={{marginTop:"20px", paddingBottom:'60px'}}>
              <MobileTransaction/>
            </div>

            <DashBottomBtn activeTab='account'/>
        </div>


        <DialogOutClick
            open={showLogout}
            handleClose={() => setShowLogout(false)}
          >
            <div style={{ width: "100%", padding: "30px 30px 20px 30px" }}>
              <div
                style={{
                  width: "100%",
                  marginBottom: "30px",
                  fontSize: "13px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>Are you sure you want to logout?</span>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <div
                  className="disBtn"
                  style={{
                    width: "50px",
                    fontSize: "13px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowLogout(false)}
                >
                  No
                </div>
                <div
                  className="ediBtn"
                  style={{
                    width: "50px",
                    fontSize: "13px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "#808080",
                    border: "1px solid #808080",
                  }}
                  onClick={logMeOut}
                >
                  Yes
                </div>
              </div>
            </div>
          </DialogOutClick>
          <DialogOutClick
            open={showDeteleAcct}
            handleClose={() => setShowDeteleAcct(false)}
          >
            <div style={{ width: "100%", padding: "30px 30px 20px 30px" }}>
              <div
                style={{
                  width: "100%",
                  marginBottom: "30px",
                  fontSize: "13px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>
                  Are you sure you want to delete this account? Type in
                  permanently delete in the input below to confirm deletion
                </span>
              </div>
              <div style={{ marginBottom: "30px" }}>
                <input
                  type="text"
                  placeholder="permanently delete"
                  value={deleteInput}
                  onChange={(e) => setdeleteInput(e.target.value)}
                  style={{
                    border: "1px solid var(--main)",
                    padding: "7px 10px",
                    outline: "none",
                    borderRadius: "5px",
                    fontSize: "13px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <div
                  className="disBtn"
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    border: "#0080FF solid 1px",
                    backgroundColor: "#0080FF",
                    color: "white",
                  }}
                  onClick={disableAccount}
                >
                  Disable instead
                </div>
                <div
                  className="disBtn"
                  style={{
                    width: "50px",
                    fontSize: "13px",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowDeteleAcct(false)}
                >
                  Cancel
                </div>
                {deleteInput === "permanently delete" ? (
                  <div
                    className="ediBtn"
                    style={{
                      width: "50px",
                      fontSize: "13px",
                      fontWeight: "bold",
                      cursor: "pointer",
                      color: "white",
                      backgroundColor: "red",
                      border: "1px solid red",
                    }}
                    onClick={deleteMyAccount}
                  >
                    {loading ? (
                      <PuffLoader
                        color="var(--main)"
                        loading={loading}
                        speedMultiplier={1}
                        size={15}
                      />
                    ) : (
                      "Yes"
                    )}
                  </div>
                ) : (
                  <div
                    className="ediBtn"
                    style={{
                      width: "50px",
                      fontSize: "13px",
                      fontWeight: "bold",
                      cursor: "not-allowed",
                      color: "white",
                      backgroundColor: "#B86566",
                      border: "1px solid #B86566",
                    }}
                  >
                    Yes
                  </div>
                )}
              </div>
            </div>
          </DialogOutClick>
      </>
     
     
    );
}

export default CorpAccount
