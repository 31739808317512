import { useState, useEffect, useContext, useCallback } from "react";
import Layout from "../../Layout";
import "./Quotes.css";
import { useHistory } from "react-router-dom";
import { SenderContext } from "../../../../../context/SenderDashboard/SenderDashboardContext";
import axios from "axios";
import SecureLS from "secure-ls";
import { ConnContext } from "../../../../../context/ConnContext";
import Pagination from "../../../../../components/Pagination";
import DialogPop from "../../../../../components/DialogPop";
import Swal from "sweetalert2";
import { PuffLoader } from "react-spinners";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import moment from "moment";
import QuoteTablerow from "./QuoteTablerow";
import Frame from "../../../../../assets/Frame.svg";
import OtpInput from "react-otp-input";
import Otp from "../../../../../assets/Otp.svg";
import DialogSimplePop from "../../../../../components/DialogSimplePop";
import SenderUpdateReg from "../../../Registration/SenderUpdateReg";
import ReactTooltip from "react-tooltip";
import jwt_decode from "jwt-decode";
import SenderSideBar from "../../../components/SenderSideBar";
import QuoteInfoCardMobile from "../../QuoteInfoCardMobile";
import LoginCompV2 from "../../../../../components/LoginCompV2";
import DialogOutClick from "../../../../../components/DialogOutClick";
import SenderBottomNav from "../../../components/SenderBottomNav";
import AcknowledgeMobileCard from "../../AcknowledgeMobileCard";
import Cookies from "js-cookie";

import GlobalizationLanguage, {
  GlobalizationLanguagewithCOntryCode,
} from "../../../../../components/GlobalizationLanguage";
import OTPCountDown from "../../../../../components/Reg_components/OTPCountDown";
export default function Quotes() {
  var timeout = GlobalizationLanguage("/timeout"); // const [freshData, setfreshData] = useState({});
  const sendersettings = GlobalizationLanguage("/sendersettings");
  const sendersettlement = GlobalizationLanguage("/sendersettlement");
  const senderapi = GlobalizationLanguage("/senderapi");
  const team = GlobalizationLanguage("/team");
  var sendermessage = GlobalizationLanguage("/sendermessage");
 
  var RefPage = GlobalizationLanguage("/referrals_sender");

  const [waiting, setWaiting] = useState(false);
  const location = useLocation();
  const endpoint = process.env.REACT_APP_API;
  const [clicked, setclicked] = useState("all");
  var countryvalue =
    Cookies.get("i18next") || visitingCountryCode.toLowerCase();
  const [country, setCountry] = useState(countryvalue);

  useEffect(() => {
    setCountry(Cookies.get("i18next"));
  }, [Cookies.get("i18next")]);

  const history = useHistory();
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const [ShowLogin, setShowLogin] = useState(false);

  const wait_for_more_quote = async (item) => {
    
    setWaiting(true);
    authAxios
      .post(
        `m-dillivry-quote-manager?delivery_location=${item?.vcc}`,
        {
          action_type: "reject",
          delivery_id: item.delivery_id,
          user_id: ls.get("eid"),
          fulfiller_id: item?.fulfiller_id,
          comment: "",
        },
        { timeout: 240000 }
      )
      .then((response) => {
        setWaiting(false);
        if (response.status === 200) {
          history.push({
            pathname: GlobalizationLanguagewithCOntryCode(
              `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
              country,
              visitingCountryCode
            ),
            state: "from_acknowledgement",
          });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              ls.removeAll();
              history.push(`/login`);
            }
          });
        }
        if (err.code === "ECONNABORTED") {
          history.push("/timeout");
        }
        setWaiting(false);
        console.log(err);
      });
  };

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });

  const {
    initialstate,
    UpdateData,
    UpdateStatusMemory,
    statusmemory,
    alldata,
    UpdateAlldata,
  } = useContext(SenderContext);
  const { visitingCountryCode } = useContext(ConnContext);
  const [datumreplacement, setdatumreplacement] = useState();

  const [dataToShow, setdataToShow] = useState([]);
  const [showDeleting, setshowDeleting] = useState(false);
  const [deleting, setdeleting] = useState(false);
  const [itemToDelete, setitemToDelete] = useState([]);
  const [search, setsearch] = useState("");
  const [walletbalance, setwalletbalance] = useState();

  const [deefault, setDeefault] = useState([]);
  const [deliveries, setDeliveries] = useState([]);
  const [deliveriesduplicate, setDeliveriesduplicate] = useState([]);
  const [itemLength, setitemLength] = useState(0);
  const [loading, setloading] = useState(false);
  const [show, setshow] = useState(false);
  const [businessUpdate, setBusinessUpdate] = useState(false);
  const [otpRequired, setOtpRequired] = useState(false);
  const [otp, setotp] = useState("");
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [loadingOtp, setloadingOtp] = useState(false);
  const [clickedid, setclcikedid] = useState("");
  const [activeRow, setActiveRow] = useState("");
  const [bollean, setbollean] = useState(false);
  const [searchclick, setsearchclick] = useState(false);

  const [freshData, setfreshData] = useState({});

  const [paginateActivePage, setPaginateActivePage] = useState(1);

  const [promoDetails, setpromoDetails] = useState({});

  const n = 43;

  useEffect(() => {

console.log(location?.state?.from, "location?.state?.from")

    if (location?.state === "from_requestDelivery") {
      window.location.reload();
      history.replace({ state: {} });
    }
    if (location?.state === "fromAccept") {
      window.location.reload();
      history.replace({ state: {} });
    }
    // console.log(location.state, "registratiom")
    if (location?.state?.from === "registration") {
      setBusinessUpdate(true);
      history.replace({ state: {} });
    }
    if (location?.state?.from === "otp_required") {
      setOtpRequired(true);
      history.replace({ state: {} });
    }
  }, []);

  const confirmOTP = () => {
    setloadingOtp(true);
    axios
      .post(`${process.env.REACT_APP_API}sender-reg`, {
        sender_email: ls.get("eid"),
        security_code: otp,
      })
      .then((res) => {
        setloadingOtp(false);
        setOtpRequired(false);
        Swal.fire({
          icon: "success",
          text: "Otp confirmed",
          timer: 3000,
          showConfirmButton: false,
        }).then(() => {
          // history.replace({ state: {} })
          history.replace({
            state: {
              from: "registration",
            },
          });
          window.location.reload();
        });
      })
      .catch((err) => {
        setloadingOtp(false);
        setInvalidOtp(true);
      });
  };

  const links = [
    // {
    //   path: team,
    //   name: "Team",
    //   state: "inactive",
    // },

    // {
    //   path: senderapi,
    //   name: "API",
    //   state: "inactive",
    // },
    // {
    //   path: sendersettlement,
    //   name: "Settlement",
    //   state: "inactive",
    // },
    // {
    //   path: sendermessage,
    //   name: "Help",
    //   state: "inactive",
    // },
    {
      path: RefPage,
      name: "Referral",
      icon: "devices",
      state: "inactive",
    },
    {
      path: sendersettings,
      name: "Settings",
      icon: "settings",
      state: "inactive",
    },
  ];

  useEffect(() => {
    setloading(true);
    setshow(false);
    if (initialstate?.map((x) => x?.awaiting_quote)[0] !== undefined) {
      setloading(true);

      setwalletbalance(
        initialstate
          ?.map((x) => x?.account)
          ?.flat()
          ?.filter((x) => x.account_info)
          ?.map((x) => x?.account_info)[0]?.wallet_balance
      );

      UpdatePage(initialstate, statusmemory);

      setdatumreplacement(initialstate);
    } else {
      setloading(true);
      axios
        .get(`${endpoint}m-dillivry-sender-api`, {
          headers: {
            Authorization: ls.get("_utk"),
          },
        })
        .then((res) => {
          console.log(res, "resres")
          if (res.status === 200) {
            setwalletbalance(
              res?.data?.data
                ?.map((x) => x?.ng ?? x?.gb)
                ?.map((x) => x?.account)
                ?.flat()
                ?.filter((x) => x.account_info)
                ?.map((x) => x?.account_info)[0]?.wallet_balance
            );
            UpdateData(res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());
            setloading(true);

            res?.data?.data
              ?.map((x) => x?.ng ?? x?.gb)
              ?.flat()
              ?.map((x) => x?.awaiting_quote)[0] === undefined
              ? UpdatePage([], statusmemory)
              : UpdatePage(
                  res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat(),
                  statusmemory
                );
            setdatumreplacement(res);
          }
          UpdateAlldata(res);
          if (res.status === 202) {
            setOtpRequired(true);
          }
        })
        .catch((err) => {
          setloading(false);
          console.log(err);
          if (err.response?.status === 401) {
            setBusinessUpdate(true);
          }
        });
    }
    AcknowledgementTimer();
  }, []);

  const UpdatePage = (datum, quote_status) => {
    setpromoDetails(datum?.account?.account_info?.general_promo_code);
    setfreshData(datum);
    setshow(false);

    if (quote_status === "all") {
      if (datum !== undefined) {
        const awaiting_quotes = datum
          ?.map((x) => x?.awaiting_quote)
          ?.flat()
          ?.filter(function (x) {
            return x?.total_quote_received === 0;
          });
        const received_quotes = datum
          ?.map((x) => x?.awaiting_quote)
          ?.flat()
          .filter(function (x) {
            return x?.total_quote_received > 0;
          });

        const awaiting_ack = datum
          ?.map((x) => x?.awaiting_acknowledgment)
          ?.flat()
          ?.filter(function (x) {
            return x?.total_quote_received > 0;
          });
        const awaiting_agent_assmt = datum
          ?.map((x) => x?.awaiting_agent_assignment)
          ?.flat()
          ?.filter(function (x) {
            return x?.total_quote_received > 0;
          });
        const pick_up = datum
          ?.map((x) => x?.awaiting_pickup)
          ?.flat()
          ?.filter(function (x) {
            return x?.total_quote_received > 0;
          });
        const intransit_data = datum
          ?.map((x) => x?.in_transit)
          ?.flat()
          ?.filter(function (x) {
            return x?.total_quote_received > 0;
          });
        const out_for_delivery_data = datum
          ?.map((x) => x?.out_for_delivery)
          ?.flat()
          ?.filter(function (x) {
            return x?.total_quote_received > 0;
          });
        const completed_data = datum?.map((x) => x?.past_deliveries)?.flat();
        const missed_data = datum?.map((x) => x?.missed_delivery)?.flat();
        const expired_data = datum?.map((x) => x?.expired_deliveries)?.flat();

        const data = [
          ...awaiting_quotes,
          ...received_quotes,
          ...awaiting_ack,
          ...awaiting_agent_assmt,
          ...pick_up,
          ...intransit_data,
          ...out_for_delivery_data,
          ...completed_data,
          ...missed_data,
          ...expired_data,
        ];

        setDeliveries(data);
        setDeliveriesduplicate(data);
        data.map((item, i) => setdataToShow(item));
        setDeliveries(data.slice(0, n));
        setDeliveriesduplicate(data.slice(0, n));
        setDeefault(data);
        setitemLength(data.length);
      }
      setloading(false);
    } else if (quote_status === "awaiting") {
      const awaiting_quotes = datum
        ?.map((x) => x?.awaiting_quote)
        ?.flat()
        .filter(function (x) {
          return x?.total_quote_received === 0;
        });

      setDeliveries(awaiting_quotes);
      setDeliveriesduplicate(awaiting_quotes);
      awaiting_quotes.map((item, i) => setdataToShow(item));
      setDeliveries(awaiting_quotes.slice(0, n));
      setDeliveriesduplicate(awaiting_quotes.slice(0, n));

      setDeefault(awaiting_quotes);
      setitemLength(awaiting_quotes.length);
      setloading(false);
    } else if (quote_status === "received") {
      // console.log(datum, "datum")
      // const datum = datum?.data?.data?.deliveries ?        datum?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.flat() :   datum?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat()
      // console.log(datum, "datum")
      const received_quotes = datum
        ?.map((x) => x?.awaiting_quote)
        ?.flat()
        .filter(function (x) {
          return x?.total_quote_received > 0;
        });
      setDeliveries(received_quotes);
      setDeliveriesduplicate(received_quotes);
      received_quotes.map((item, i) => setdataToShow(item));
      setDeliveries(received_quotes.slice(0, n));
      setDeliveriesduplicate(received_quotes.slice(0, n));
      setDeefault(received_quotes);
      setitemLength(received_quotes.length);
      setloading(false);
    } else if (quote_status === "acknowledge") {
      const awaiting_ack = datum
        ?.map((x) => x?.awaiting_acknowledgment)
        ?.flat()
        ?.filter(function (x) {
          return x?.total_quote_received > 0;
        });
      setDeliveries(awaiting_ack);
      setDeliveriesduplicate(awaiting_ack);
      awaiting_ack.map((item, i) => setdataToShow(item));
      setDeliveries(awaiting_ack.slice(0, n));
      setDeliveriesduplicate(awaiting_ack);

      setDeefault(awaiting_ack);
      setitemLength(awaiting_ack.length);
      setloading(false);
    } else if (quote_status === "agents") {
      const awaiting_agent_assmt = datum
        ?.map((x) => x?.awaiting_agent_assignment)
        ?.flat()
        ?.filter(function (x) {
          return x?.total_quote_received > 0;
        });
      setDeliveries(awaiting_agent_assmt);
      setDeliveriesduplicate(awaiting_agent_assmt);
      awaiting_agent_assmt.map((item, i) => setdataToShow(item));
      setDeliveries(awaiting_agent_assmt.slice(0, n));
      setDeliveriesduplicate(awaiting_agent_assmt.slice(0, n));

      setDeefault(awaiting_agent_assmt);
      setitemLength(awaiting_agent_assmt.length);
      setloading(false);
    } else if (quote_status === "pickup") {
      const pick_up = datum
        ?.map((x) => x?.awaiting_pickup)
        ?.flat()
        ?.filter(function (x) {
          return x?.total_quote_received > 0;
        });

      setDeliveries(pick_up);
      setDeliveriesduplicate(pick_up);
      pick_up.map((item, i) => setdataToShow(item));
      setDeliveries(pick_up.slice(0, n));
      setDeliveriesduplicate(pick_up.slice(0, n));

      setDeefault(pick_up);
      setitemLength(pick_up.length);
      setloading(false);
    } else if (quote_status === "intransit") {
      const intransit_data = datum
        ?.map((x) => x?.in_transit)
        ?.flat()
        ?.filter(function (x) {
          return x?.total_quote_received > 0;
        });
      setDeliveries(intransit_data);
      setDeliveriesduplicate(intransit_data);
      intransit_data.map((item, i) => setdataToShow(item));
      setDeliveries(intransit_data.slice(0, n));
      setDeliveriesduplicate(intransit_data.slice(0, n));

      setDeefault(intransit_data);
      setitemLength(intransit_data.length);
      setloading(false);
    } else if (quote_status === "outfordelivery") {
      const out_for_delivery_data = datum
        ?.map((x) => x?.out_for_delivery)
        ?.flat()
        ?.filter(function (x) {
          return x?.total_quote_received > 0;
        });
      setDeliveries(out_for_delivery_data);
      setDeliveriesduplicate(out_for_delivery_data);
      out_for_delivery_data.map((item, i) => setdataToShow(item));
      setDeliveries(out_for_delivery_data.slice(0, n));
      setDeliveriesduplicate(out_for_delivery_data.slice(0, n));

      setDeefault(out_for_delivery_data);
      setitemLength(out_for_delivery_data.length);
      setloading(false);
    } else if (quote_status === "Completed") {
      const completed_data = datum?.map((x) => x?.past_deliveries)?.flat();

      setDeliveries(completed_data);
      setDeliveriesduplicate(completed_data);
      completed_data.map((item, i) => setdataToShow(item));
      setDeliveries(completed_data.slice(0, n));
      setDeliveriesduplicate(completed_data.slice(0, n));

      setDeefault(completed_data);
      setitemLength(completed_data.length);
      setloading(false);
    } else if (quote_status === "Missed") {
      const missed_data = datum?.map((x) => x?.missed_delivery)?.flat();

      setDeliveries(missed_data);
      setDeliveriesduplicate(missed_data);
      missed_data.map((item, i) => setdataToShow(item));
      setDeliveries(missed_data.slice(0, n));
      setDeliveriesduplicate(missed_data.slice(0, n));

      setDeefault(missed_data);
      setitemLength(missed_data.length);
      setloading(false);
    } else if (quote_status === "Expired") {
      const expired_data = datum?.map((x) => x?.expired_deliveries)?.flat();
      setDeliveries(expired_data);
      setDeliveriesduplicate(expired_data);
      expired_data.map((item, i) => setdataToShow(item));
      setDeliveries(expired_data.slice(0, n));
      setDeliveriesduplicate(expired_data.slice(0, n));

      setDeefault(expired_data);
      setitemLength(expired_data.length);
      setloading(false);
    } else {
      setloading(false);
    }
    // setloading(false)
  };

  const acceptExistClick = (delivery_id, vcc) => {
    // history.push(`/activedeliveries/viewDelivery/${vcc}/${delivery_id}`)
    history.push(
      GlobalizationLanguagewithCOntryCode(
        `/activedeliveries/viewDelivery/${vcc}/${delivery_id}`,
        country,
        visitingCountryCode
      )
    );
    // history.push(`/activedeliveries/viewDelivery/${delivery_id}`);
  };

  // const [datumreplacement, setdatumreplacement] = useState();

  const tokenCheck = (item) => {
    const decoded = jwt_decode(ls.get("_utk"));
    const expired = decoded.exp;
    if (expired <= Math.floor(Date.now() / 1000)) {
      setShowLogin(true);
    } else {
      wait_for_more_quote(item);
    }
  };

  const AcknowledgementTimer = (req_time) => {
    const thirty_min_after = moment(
      new Date("2022-04-27T13:16:33.573004+01:00")
    ).add(30, "m");

    const d = new Date(thirty_min_after);

    let date = d.getDate();
    let month = d.getMonth();
    let year = d.getFullYear();
    let hour = d.getHours();
    let min = d.getMinutes();

    const waitingTimeInMinute =
      moment([year, month, date, hour, min]).diff(moment()) / 1000;

    return Math.floor(waitingTimeInMinute);
  };

  const deleteItem = (e) => {
    e.preventDefault();

    setdeleting(true);

    authAxios
      .post(
        `m-dillivry-listing?delivery_location=${itemToDelete.vcc}`,
        {
          action_type: "delete",
          delivery_id: itemToDelete.delivery_id,
          sender_email: ls.get("eid"),
        },
        { timeout: 240000 }
      )
      .then((res) => {
        setshowDeleting(false);
        setdeleting(false);
        // setsendingMsg(false)
        Swal.fire({
          title: "Deleted",
          text: "Item deleted!",
          icon: "success",
          confirmButtonColor: "var(--main)",
          showConfirmButton: false,
          timer: 2000,
        });
        history.go(0);
      })
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          console.log("internal-server-error-timeout");
          history.push(timeout);
          // window.location.reload()
        }
        setshowDeleting(false);
        setdeleting(false);
        Swal.fire({
          icon: "error",
          text: "error!",
          timer: 2000,
          showConfirmButton: false,
        });
        if (err.code === "ECONNABORTED") {
          console.log("internal-server-error-timeout");
          history.push(timeout);
          // window.location.reload()
        }
      });
  };

  const ListData = {
    Users: [
      {
        type: "all",
        name: "All",
      },
      {
        type: "awaiting",
        name: "Awaiting quotes",
      },
      {
        type: "received",
        name: "Received quotes",
      },
      {
        type: "acknowledge",
        name: "Await acknowledgement",
      },
      {
        type: "agents",
        name: "Await agent assignment",
      },
      {
        type: "pickup",
        name: "Await pick up",
      },
      {
        type: "intransit",
        name: "In transit",
      },
      {
        type: "outfordelivery",
        name: "Out for delivery",
      },
      {
        type: "Completed",
        name: "Completed",
      },
      {
        type: "Missed",
        name: "Missed",
      },
      {
        type: "Expired",
        name: "Expired",
      },
    ],
  };

  const dataHead = [
    {
      thead: "Image",
    },
    {
      thead: "Item",
    },
    {
      thead: "Payer",
    },

    {
      thead: "Delivery status",
    },

    {
      thead: "Payment status",
    },
    {
      thead: "Mode of payment",
    },
    {
      thead: "Quote amount",
    },
    {
      thead: "Payment time",
    },
    {
      thead: (
        <img src={Frame} alt="frame_icon" className="icon-logo" style={{}} />
      ),
    },
  ];

  const DropDown = () => {
    return (
      <div className="parent-dropdown">
        <ReactTooltip
          id="menuicon"
          aria-haspopup="true"
          type="light"
          role="example"
        >
          <p>Menu Icon</p>
        </ReactTooltip>

        {ListData.Users.map((x, i) => {
          return (
            <div
              key={i}
              className="each"
              onClick={() => {
                // FilterbyStatus(x.type);
                setloading(true);
                UpdatePage(freshData, x.type);
                UpdateStatusMemory(x.type);
              }}
            >
              <span
                style={{
                  marginLeft: "10px",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
              >
                {x.name}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  const filterData = (e) => {
    setsearch(e.target.value);
    const filtereddata = deliveries.filter((names) =>
      names.item_desc.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setDeliveries(
      deliveriesduplicate.filter((names) =>
        names.item_desc.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const SearchBar = () => {
    return (
      <div className="search">
        <div className="search-input-navbar">
          <input
            type="text"
            value={search}
            autoFocus
            placeholder="search.."
            onChange={filterData}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="main-father">
        <SearchBar />
      </div>

      <div className="dsktopscreen">
        <Layout>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div onClick={() => setshow(!show)} className="status-body-">
              <div className="filterandicon">
                <div>
                  <h1 className="fiter-content">Filter by delivery status</h1>
                </div>

                <IoMdArrowDropdown
                  onClick={() => setshow(!show)}
                  color="white"
                />
              </div>
            </div>
          </div>

          {loading ? (
            <div
              style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "none",
                zIndex: "300",
                position: "fixed",
                top: "70px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <LinearProgress color="success" />
              </Box>
            </div>
          ) : (
            <div className="quoteCont">
              {deliveries?.length === 0 ? (
                <div className="columnBody1">- currently none to display -</div>
              ) : (
                <div>
                  <table className="msg-table">
                    {dataHead.map((msg, i) => (
                      <th key={i} className="data-thead">
                        {msg.thead}
                      </th>
                    ))}

                    {deliveries?.length === 0 ? (
                      <tbody>
                        <tr className="msg-trow">
                          <td className="msg-tdata" colSpan={3}>
                            {" "}
                            -- You don't have a delivery yet --{" "}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      deliveries?.map((item, index) => {
                        return (
                          <>
                            <QuoteTablerow
                              wallet_bln={walletbalance}
                            setwallet_bln={setwalletbalance}
                              key={index}
                              promoDetails={promoDetails}
                              statusmemory={statusmemory}
                              item={item}
                              index={index}
                              activeValue={activeRow}
                              bool={!bollean}
                              onClick={() => {
                                setActiveRow(item.delivery_id);
                                setbollean(!bollean);
                              }}
                            />
                          </>
                        );
                      })
                    )}
                  </table>
                </div>
              )}

              {deliveries?.length === 0 ? (
                <></>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "20px 0",
                  }}
                >
                  <Pagination
                    paginateActivePage={paginateActivePage}
                    n={n}
                    onFirstClick={() => {
                      setDeliveries(deefault.slice(0, n));
                      setPaginateActivePage(1);
                    }}
                    onPreviousClick={() => {
                      setDeliveries(
                        deefault.slice(
                          (paginateActivePage - 2) * n,
                          (paginateActivePage - 1) * n
                        )
                      );
                      setPaginateActivePage(paginateActivePage - 1);
                    }}
                    onNextClick={() => {
                      setDeliveries(
                        deefault.slice(
                          n * paginateActivePage,
                          n * (paginateActivePage + 1)
                        )
                      );
                      setPaginateActivePage(paginateActivePage + 1);
                    }}
                    onNumClick={(i) => {
                      setDeliveries(deefault.slice(n * i, n * i + n));
                      setPaginateActivePage(i + 1);
                    }}
                    onLastClick={() => {
                      setDeliveries(
                        deefault.slice(
                          Math.floor(itemLength / n) * n,
                          itemLength
                        )
                      );
                      setPaginateActivePage(Math.ceil(itemLength / n));
                    }}
                    itemLength={itemLength}
                  />
                </div>
              )}
            </div>
          )}
        </Layout>
      </div>

      <div className="mobilescreen">
        <div>
          <div className="mobile-boby">
            <SenderSideBar
              pageTitle="Quotes"
              links={links}
              state={searchclick}
            />
            <div className="mobile-dash-body">
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    backgroundColor: "none",
                    zIndex: "300",
                    position: "fixed",
                    top: "70px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress color="success" />
                  </Box>
                </div>
              ) : (
                <div className="quoteCont">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "30px",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    <div
                      onClick={() => setshow(!show)}
                      className="status-body-"
                    >
                      <div className="filterandicon">
                        <div>
                          <h1 className="fiter-content">
                            Filter by delivery status
                          </h1>
                        </div>
                        {
                          show ?
                          <IoMdArrowDropup color="white" />
                          :
                          <IoMdArrowDropdown color="white"/>
                        }
                      </div>
                    </div>

                    <div className="filter-search">
                      {/* <input type="text" placeholder="search"  className="search-input" /> */}
                      <SearchBar />
                    </div>
                  </div>
                  {deefault?.length === 0 ? (
                    <div className="columnBody1">
                      - currently none to display -
                    </div>
                  ) : (
                    <></>
                  )}
                  {deefault.sort((a, b) => new Date(b.date_listed) - new Date(a.date_listed))
                    ?.map((item, i) => (
                    <>
                      <div key={i}>
                        {clicked === "all" ? (
                          <QuoteInfoCardMobile
                          wallet_bln={walletbalance}
                            setwallet_bln={setwalletbalance}
                            onClick={() =>
                              item?.delivery_status ===
                              "awaiting_provider_acknowledgment"
                                ? null
                                : history.push(
                                    GlobalizationLanguagewithCOntryCode(
                                      `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                                      country,
                                      visitingCountryCode
                                    )
                                  )
                            }
                            image={item?.image_urls[0]}
                            amount=""
                            actamount=""
                            paymentStatus={item?.payment_status}
                            best_quote={item?.best_quote}
                            payer={item?.payer}
                            itemDesc={item?.item_desc}
                            itemLocation={item?.from}
                            itemDestination={item?.to}
                            distance={item?.distance}
                            deliveryStatus={item?.delivery_status}
                            timeframe={item?.pickup_date}
                            timelisted={item?.delivery_time_frame}
                            deliverymode={item?.mode_of_delivery}
                            payment_time={item?.payment_time}
                            onDeleteClick={() => {
                              setitemToDelete(item);
                              setshowDeleting(true);
                            }}
                            quote_amount={item?.quote_amount}
                            noshowEdit
                            showPayment
                            data={item}
                            itemdetailss={item}
                            promoDetails={promoDetails}
                            items={item}
                            onWaitClick={() => {
                              tokenCheck(item);
                              setclcikedid(item?.delivery_id);
                            }}
                            clicked={clickedid}
                            onAcceptExistClick={() =>
                              acceptExistClick(item?.delivery_id, item?.vcc)
                            }
                            statusmemory={statusmemory}
                            modeofpayment={item.mode_of_payment}
                          />
                        ) : clicked === "acknowledge" ? (
                          // <h1>jijijnj</h1>
                          <AcknowledgeMobileCard
                            image={item?.image_urls[0]}
                            amount=""
                            actamount=""
                            payer={item?.payer}
                            itemDesc={item?.item_desc}
                            itemLocation={item?.from}
                            itemDestination={item?.to}
                            distance={item?.distance}
                            paymentStatus={item?.payment_status}
                            provider_name={item?.fulfiller_id}
                            paymentTime={item?.payment_time}
                            deliveryStatus={item?.delivery_status}
                            timeframe={item?.pickup_date}
                            timelisted={item?.delivery_time_frame}
                            deliverymode={item?.mode_of_delivery}
                            onDeleteClick={() => {
                              setitemToDelete(item);
                              setshowDeleting(true);
                            }}
                            items={item}
                            onWaitClick={() => {
                              tokenCheck(item);
                              setclcikedid(item?.delivery_id);
                            }}
                            clicked={clickedid}
                            onAcceptExistClick={() =>
                              acceptExistClick(item?.delivery_id, item?.vcc)
                            }
                          />
                        ) : clicked === "received" ? (
                          <QuoteInfoCardMobile
                          wallet_bln={walletbalance}
                          setwallet_bln={setwalletbalance}

                            onClick={() =>
                              item?.delivery_status ===
                              "awaiting_provider_acknowledgment"
                                ? null
                                : history.push(
                                    GlobalizationLanguagewithCOntryCode(
                                      `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                                      country,
                                      visitingCountryCode
                                    )
                                  )
                            }
                            image={item?.image_urls[0]}
                            amount=""
                            actamount=""
                            editicon
                            statusmemory={statusmemory}
                            // data={item}
                            itemdetailss={item}
                            paymentStatus={item?.payment_status}
                            best_quote={item?.best_quote}
                            payer={item?.payer}
                            itemDesc={item?.item_desc}
                            itemLocation={item?.from}
                            itemDestination={item?.to}
                            distance={item?.distance}
                            deliveryStatus={item?.delivery_status}
                            timeframe={item?.pickup_date}
                            timelisted={item?.delivery_time_frame}
                            deliverymode={item?.mode_of_delivery}
                            quoteCount={item?.total_quote_received}
                            onDeleteClick={() => {
                              setitemToDelete(item);
                              setshowDeleting(true);
                            }}
                            onWaitClick={() => {
                              tokenCheck(item);
                              setclcikedid(item?.delivery_id);
                            }}
                            onEditClick={() => {
                              //  console.log("edit")
                              // seteditState(true)
                              history.push({
                                pathname: "/editquote",
                                state: item,
                              });
                            }}
                            modeofpayment={item.mode_of_payment}
                          />
                        ) : clicked === "agents" ? (
                          <QuoteInfoCardMobile
                          wallet_bln={walletbalance}
                          setwallet_bln={setwalletbalance}

                            onClick={() =>
                              item?.delivery_status ===
                              "awaiting_provider_acknowledgment"
                                ? null
                                : history.push(
                                    GlobalizationLanguagewithCOntryCode(
                                      `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                                      country,
                                      visitingCountryCode
                                    )
                                  )
                            }
                            image={item.image_urls[0]}
                            amount=""
                            statusmemory={statusmemory}
                            actamount=""
                            paymentStatus={item?.payment_status}
                            best_quote={item?.best_quote}
                            payer={item?.payer}
                            itemDesc={item?.item_desc}
                            itemLocation={item?.from}
                            itemDestination={item?.to}
                            distance={item?.distance}
                            deliveryStatus={item?.delivery_status}
                            timeframe={item?.pickup_date}
                            timelisted={item?.delivery_time_frame}
                            deliverymode={item?.mode_of_delivery}
                            payment_time={item?.payment_time}
                            quote_amount={item?.quote_amount}
                            noshowEdit
                            showPayment
                            data={item}
                            onWaitClick={() => {
                              tokenCheck(item);
                              setclcikedid(item?.delivery_id);
                            }}
                            itemdetailss={item}
                            promoDetails={promoDetails}
                            modeofpayment={item.mode_of_payment}
                          />
                        ) : clicked === "pickup" ? (
                          <QuoteInfoCardMobile
                          wallet_bln={walletbalance}
                          setwallet_bln={setwalletbalance}

                            onClick={() =>
                              item?.delivery_status ===
                              "awaiting_provider_acknowledgment"
                                ? null
                                : history.push(
                                    GlobalizationLanguagewithCOntryCode(
                                      `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                                      country,
                                      visitingCountryCode
                                    )
                                  )
                            }
                            image={item.image_urls[0]}
                            amount=""
                            statusmemory={statusmemory}
                            actamount=""
                            paymentStatus={item?.payment_status}
                            payer={item?.payer}
                            itemDesc={item?.item_desc}
                            itemLocation={item?.from}
                            itemDestination={item?.to}
                            distance={item?.distance}
                            deliveryStatus={item?.delivery_status}
                            timeframe={item?.pickup_date}
                            timelisted={item?.delivery_time_frame}
                            deliverymode={item?.mode_of_delivery}
                            payment_time={item?.payment_time}
                            quote_amount={item?.quote_amount}
                            noshowEdit
                            showPayment
                            data={item}
                            onWaitClick={() => {
                              tokenCheck(item);
                              setclcikedid(item?.delivery_id);
                            }}
                            itemdetailss={item}
                            promoDetails={promoDetails}
                            modeofpayment={item.mode_of_payment}
                          />
                        ) : clicked === "intransit" ? (
                          <QuoteInfoCardMobile
                          wallet_bln={walletbalance}
                          setwallet_bln={setwalletbalance}

                            onClick={() =>
                              item?.delivery_status ===
                              "awaiting_provider_acknowledgment"
                                ? null
                                : history.push(
                                    GlobalizationLanguagewithCOntryCode(
                                      `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                                      country,
                                      visitingCountryCode
                                    )
                                  )
                            }
                            image={item?.image_urls[0]}
                            amount=""
                            actamount=""
                            statusmemory={statusmemory}
                            paymentStatus={item?.payment_status}
                            payer={item?.payer}
                            itemDesc={item?.item_desc}
                            itemLocation={item?.from}
                            itemDestination={item?.to}
                            distance={item?.distance}
                            deliveryStatus={item?.delivery_status}
                            timeframe={item?.pickup_date}
                            timelisted={item?.delivery_time_frame}
                            deliverymode={item?.mode_of_delivery}
                            payment_time={item?.payment_time}
                            quote_amount={item?.quote_amount}
                            noshowEdit
                            showPayment
                            data={item}
                            itemdetailss={item}
                            onWaitClick={() => {
                              tokenCheck(item);
                              setclcikedid(item?.delivery_id);
                            }}
                            TrackItem
                            promoDetails={promoDetails}
                            modeofpayment={item.mode_of_payment}
                          />
                        ) : clicked === "outfordelivery" ? (
                          <QuoteInfoCardMobile
                          wallet_bln={walletbalance}
                          setwallet_bln={setwalletbalance}

                            onClick={() =>
                              item?.delivery_status ===
                              "awaiting_provider_acknowledgment"
                                ? null
                                : history.push(
                                    GlobalizationLanguagewithCOntryCode(
                                      `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                                      country,
                                      visitingCountryCode
                                    )
                                  )
                            }
                            image={item.image_urls[0]}
                            amount=""
                            actamount=""
                            statusmemory={statusmemory}
                            paymentStatus={item?.payment_status}
                            payer={item?.payer}
                            itemDesc={item?.item_desc}
                            itemLocation={item?.from}
                            itemDestination={item?.to}
                            distance={item?.distance}
                            deliveryStatus={item?.delivery_status}
                            timeframe={item?.pickup_date}
                            timelisted={item?.delivery_time_frame}
                            deliverymode={item?.mode_of_delivery}
                            payment_time={item?.payment_time}
                            quote_amount={item?.quote_amount}
                            noshowEdit
                            showPayment
                            itemdetailss={item}
                            TrackItem
                            promoDetails={promoDetails}
                            onWaitClick={() => {
                              tokenCheck(item);
                              setclcikedid(item?.delivery_id);
                            }}
                            modeofpayment={item.mode_of_payment}
                          />
                        ) : clicked === "awaiting" ? (
                          <QuoteInfoCardMobile
                            // onClick={() =>
                            //   history.push(
                            //     `/activedeliveries/viewDelivery/${item.delivery_id}`
                            //   )
                            // }
                            wallet_bln={walletbalance}
                            setwallet_bln={setwalletbalance}

                            onClick={() =>
                              item?.delivery_status ===
                              "awaiting_provider_acknowledgment"
                                ? null
                                : history.push(
                                    GlobalizationLanguagewithCOntryCode(
                                      `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                                      country,
                                      visitingCountryCode
                                    )
                                  )
                            }
                            image={item.image_urls[0]}
                            amount=""
                            actamount=""
                            statusmemory={statusmemory}
                            paymentStatus={item?.payment_status}
                            best_quote={item?.best_quote}
                            payer={item?.payer}
                            itemDesc={item?.item_desc}
                            itemLocation={item?.from}
                            itemDestination={item?.to}
                            distance={item?.distance}
                            deliveryStatus={item?.delivery_status}
                            timeframe={item?.pickup_date}
                            timelisted={item?.delivery_time_frame}
                            deliverymode={item?.mode_of_delivery}
                            payment_time={item?.payment_time}
                            quote_amount={item?.quote_amount}
                            noshowEdit
                            showPayment
                            data={item}
                            itemdetailss={item}
                            promoDetails={promoDetails}
                            onWaitClick={() => {
                              tokenCheck(item);
                              setclcikedid(item?.delivery_id);
                            }}
                            modeofpayment={item.mode_of_payment}
                          />
                        ) : clicked === "Completed" ? (
                          <QuoteInfoCardMobile
                            // onClick={() =>
                            //   history.push(
                            //     `/activedeliveries/viewDelivery/${item.delivery_id}`
                            //   )
                            // }
                            wallet_bln={walletbalance}
                            setwallet_bln={setwalletbalance}

                            onClick={() =>
                              item?.delivery_status ===
                              "awaiting_provider_acknowledgment"
                                ? null
                                : history.push(
                                    GlobalizationLanguagewithCOntryCode(
                                      `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                                      country,
                                      visitingCountryCode
                                    )
                                  )
                            }
                            image={item.image_urls[0]}
                            amount=""
                            actamount=""
                            statusmemory={statusmemory}
                            editicon
                            itemdetailss={item}
                            payer={item?.payer}
                            itemDesc={item?.item_desc}
                            itemLocation={item?.from}
                            itemDestination={item?.to}
                            distance={item?.distance}
                            deliveryStatus={item?.delivery_status}
                            timeframe={item?.pickup_date}
                            timelisted={item?.delivery_time_frame}
                            deliverymode={item?.mode_of_delivery}
                            onDeleteClick={() => {
                              setitemToDelete(item);
                              setshowDeleting(true);
                              // console.log("clicked-delete");
                            }}
                            onEditClick={() => {
                              history.push({
                                pathname: "/editquote",
                                state: item,
                              });
                            }}
                            onWaitClick={() => {
                              tokenCheck(item);
                              setclcikedid(item?.delivery_id);
                            }}
                            modeofpayment={item.mode_of_payment}
                          />
                        ) : clicked === "Missed" ? (
                          <QuoteInfoCardMobile
                          wallet_bln={walletbalance}
                          setwallet_bln={setwalletbalance}

                            onClick={() =>
                              item?.delivery_status ===
                              "awaiting_provider_acknowledgment"
                                ? null
                                : history.push(
                                    GlobalizationLanguagewithCOntryCode(
                                      `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                                      country,
                                      visitingCountryCode
                                    )
                                  )
                            }
                            image={item.image_urls[0]}
                            amount=""
                            actamount=""
                            payer={item?.payer}
                            statusmemory={statusmemory}
                            itemDesc={item?.item_desc}
                            itemLocation={item?.from}
                            itemDestination={item?.to}
                            distance={item?.distance}
                            deliveryStatus={item?.delivery_status}
                            timeframe={item?.pickup_date}
                            timelisted={item?.delivery_time_frame}
                            deliverymode={item?.mode_of_delivery}
                            paymentStatus={item?.payment_status}
                            quote_amount={item?.quote_amount}
                            noshowEdit
                            showPayment
                            data={item}
                            itemdetailss={item}
                            promoDetails={promoDetails}
                            onWaitClick={() => {
                              tokenCheck(item);
                              setclcikedid(item?.delivery_id);
                            }}
                            modeofpayment={item.mode_of_payment}
                          />
                        ) : clicked === "Expired" ? (
                          <QuoteInfoCardMobile
                            // onClick={() =>
                            //   history.push(
                            //     `/activedeliveries/viewDelivery/${item.delivery_id}`
                            //   )
                            // }
                            wallet_bln={walletbalance}
                            setwallet_bln={setwalletbalance}

                            onClick={() =>
                              item?.delivery_status ===
                              "awaiting_provider_acknowledgment"
                                ? null
                                : history.push(
                                    GlobalizationLanguagewithCOntryCode(
                                      `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                                      country,
                                      visitingCountryCode
                                    )
                                  )
                            }
                            image={item.image_urls[0]}
                            amount=""
                            actamount=""
                            statusmemory={statusmemory}
                            payer={item?.payer}
                            itemDesc={item?.item_desc}
                            itemLocation={item?.from}
                            itemDestination={item?.to}
                            distance={item?.distance}
                            deliveryStatus={item?.delivery_status}
                            timeframe={item?.pickup_date}
                            timelisted={item?.delivery_time_frame}
                            deliverymode={item?.mode_of_delivery}
                            onDeleteClick={() => {
                              setitemToDelete(item);
                              setshowDeleting(true);
                            }}
                            noshowEdit
                            itemdetailss={item}
                            onWaitClick={() => {
                              tokenCheck(item);
                              setclcikedid(item?.delivery_id);
                            }}
                            modeofpayment={item.mode_of_payment}
                          />
                        ) : (
                          <QuoteInfoCardMobile
                            // onClick={() =>
                            //   history.push(
                            //     `/activedeliveries/viewDelivery/${item.delivery_id}`
                            //   )
                            // }
                            wallet_bln={walletbalance}
                            setwallet_bln={setwalletbalance}
                            onClick={() =>
                              item?.delivery_status ===
                              "awaiting_provider_acknowledgment"
                                ? null
                                : history.push(
                                    GlobalizationLanguagewithCOntryCode(
                                      `/activedeliveries/viewDelivery/${item?.vcc}/${item?.delivery_id}`,
                                      country,
                                      visitingCountryCode
                                    )
                                  )
                            }
                            image={item.image_urls[0]}
                            amount=""
                            actamount=""
                            statusmemory={statusmemory}
                            paymentStatus={item?.payment_status}
                            best_quote={item?.best_quote}
                            payer={item?.payer}
                            itemDesc={item?.item_desc}
                            itemLocation={item?.from}
                            itemDestination={item?.to}
                            distance={item?.distance}
                            deliveryStatus={item?.delivery_status}
                            timeframe={item?.pickup_date}
                            timelisted={item?.delivery_time_frame}
                            deliverymode={item?.mode_of_delivery}
                            payment_time={item?.payment_time}
                            onDeleteClick={() => {
                              setitemToDelete(item);
                              setshowDeleting(true);
                            }}
                            quote_amount={item?.quote_amount}
                            noshowEdit
                            showPayment
                            data={item}
                            itemdetailss={item}
                            onWaitClick={() => {
                              tokenCheck(item);
                              setclcikedid(item?.delivery_id);
                            }}
                            modeofpayment={item.mode_of_payment}
                          />
                        )}
                      </div>
                    </>
                  ))}
                  <SenderBottomNav state="quotes" />
                </div>
              )}
              <SenderBottomNav state="quotes" />

              {show ? <DropDown /> : null}
            </div>

            <SenderBottomNav state="quotes" />
          </div>
        </div>
      </div>

      <DialogPop
        openPopup={showDeleting}
        setopenPopup={setshowDeleting}
        // head="Delete Item"
      >
        <div className="quote_div">
          <p style={{ fontSize: "15px" }}>
            {" "}
            Are you sure you want to delete this item?
          </p>
          {
            <form style={{ display: "flex", width: "100%" }}>
              <div className="dialogdiv">
                <button
                  onClick={() => setshowDeleting(false)}
                  className="dialodivbt"
                  type="button"
                  style={{
                    cursor: "pointer",
                    color: "#FFFFFF",
                    backgroundColor: "#50B14A",
                    border: "1px solid #50B14A",
                  }}
                >
                  {" "}
                  No
                </button>{" "}
                &nbsp; &nbsp;
                {deleting ? (
                  <button
                    className="dialodivbt"
                    type="button"
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "grey",
                      border: "1px solid grey",
                    }}
                  >
                    <PuffLoader
                      color="white"
                      loading={deleting}
                      speedMultiplier={1}
                      size={16}
                    />
                  </button>
                ) : (
                  <button
                    className="dialodivbt"
                    type="button"
                    onClick={deleteItem}
                    style={{
                      cursor: "pointer",
                      color: "#FFFFFF",
                      backgroundColor: "grey",
                      border: "1px solid grey",
                    }}
                  >
                    Yes
                  </button>
                )}
              </div>
            </form>
          }
        </div>
      </DialogPop>

      <DialogOutClick
        open={ShowLogin}
        handleClose={() => {
          setShowLogin(false);
        }}
      >
        <LoginCompV2
          onFinish={() => {
            setShowLogin(false);
          }}
        />
      </DialogOutClick>

      <DialogSimplePop openPopup={businessUpdate} hideBtn={true}>
        <div className="SenderUpdateRegXX">
          <SenderUpdateReg
            closePopup={() => setBusinessUpdate(false)}
            reload={() => window.location.reload()}
          />
        </div>
      </DialogSimplePop>

      <DialogSimplePop openPopup={otpRequired} hideBtn={true}>
        <div className="otpContainer">
          <img className="otpImag" src={Otp} alt="sent" />
          <div
            style={{
              textAlign: "center",
              fontWeight: "400",
              fontSize: "16px",
            }}
          >
            As a security measure, a one-off code was sent to you via Email @{" "}
            {ls.get("eid")} and SMS
          </div>
          <div
            style={{
              textAlign: "center",
              fontWeight: "400",
              fontSize: "16px",
            }}
          >
            Please enter the one-off code below
          </div>
          <OtpInput
            isInputNum={true}
            value={otp}
            onChange={(otp) => {
              setotp(otp);
              setInvalidOtp(false);
            }}
            numInputs={4}
            shouldAutoFocus={true}
            separator={
              <span>
                <strong></strong>
              </span>
            }
            inputStyle={{
              width: "2.5rem",
              height: "2.5rem",
              margin: "0 0.3rem",
              fontSize: "20px",
              borderRadius: 3,
              border: "2px solid var(--main)",
              outline: "none",
            }}
          />
          {invalidOtp ? (
            <div
              style={{
                textAlign: "center",
                color: "red",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              Invalid OTP
            </div>
          ) : null}
          <OTPCountDown initialSeconds={45} type="sender" user_id={ls.get("eid")}/>
          {/* <div style={{textAlign:'center', fontWeight:'400', fontSize:'14px'}}>
              Resend code in 00:46
            </div> */}

          {otp.length === 4 ? (
            <button
              type="button"
              onClick={confirmOTP}
              className="otpConfirmBtn"
            >
              {loadingOtp ? (
                <span className="btnlabe">
                  <PuffLoader
                    color="white"
                    loading={loadingOtp}
                    speedMultiplier={1}
                    size={20}
                  />
                </span>
              ) : (
                <span>Continue</span>
              )}
            </button>
          ) : null}
        </div>
      </DialogSimplePop>
      <DialogPop
        openPopup={showDeleting}
        setopenPopup={setshowDeleting}
        // head="Delete Item"
      >
        <div className="quote_div">
          <p style={{ fontSize: "15px" }}>
            {" "}
            Are you sure you want to delete this item?
          </p>
          {
            <form style={{ display: "flex", width: "100%" }}>
              <div className="dialogdiv">
                <button
                  onClick={() => setshowDeleting(false)}
                  className="dialodivbt"
                  type="button"
                  style={{
                    cursor: "pointer",
                    color: "#FFFFFF",
                    backgroundColor: "#50B14A",
                    border: "1px solid #50B14A",
                  }}
                >
                  {" "}
                  No
                </button>{" "}
                &nbsp; &nbsp;
                {deleting ? (
                  <button
                    className="dialodivbt"
                    type="button"
                    style={{
                      color: "#FFFFFF",
                      backgroundColor: "grey",
                      border: "1px solid grey",
                    }}
                  >
                    <PuffLoader
                      color="white"
                      loading={deleting}
                      speedMultiplier={1}
                      size={16}
                    />
                  </button>
                ) : (
                  <button
                    className="dialodivbt"
                    type="button"
                    onClick={deleteItem}
                    style={{
                      cursor: "pointer",
                      color: "#FFFFFF",
                      backgroundColor: "grey",
                      border: "1px solid grey",
                    }}
                  >
                    Yes
                  </button>
                )}
              </div>
            </form>
          }
        </div>
      </DialogPop>

      <DialogSimplePop openPopup={businessUpdate} hideBtn={true}>
        <SenderUpdateReg
          closePopup={() => setBusinessUpdate(false)}
          reload={() => window.location.reload()}
        />
      </DialogSimplePop>
      {show ? <DropDown /> : null}
    </div>
  );
}
