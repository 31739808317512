import { useEffect, useState, useRef, useContext } from "react";
import Carousel from "react-material-ui-carousel";
import GoogleMapWidget from "../../../components/Map/GoogleMapWidget";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import to from "../../../assets/to.svg";
import From from "../../../assets/From.svg";
import itemdescr_ from "../../../assets/itemdescr_.svg";
import Distance from "../../../assets/Distance.svg";
import Duration from "../../../assets/Duration.svg";
import Listed from "../../../assets/Listed.svg";
import Fastdelivery from "../../../assets/Fastdelivery.svg";
import Footer from "../../../components/Footer/Footer";
import DialogOutClick from "../../../components/DialogOutClick";
import QuoteSignInForm from "./QuoteSignInForm";
import HomeHelp from "../../../components/HomeHelp";
import Nav from "../../../components/Nav/Nav";
import { AiFillCloseCircle } from "react-icons/ai";
import { useLocation, useParams } from "react-router-dom";
import EnquiryForm from "./EnquiryForm";
import { AuthContext } from "../../../context/AuthContext";
import SecureLS from "secure-ls";
import jwt_decode from "jwt-decode";
import ReactTooltip from "react-tooltip";
import dimensions from "../../../assets/dimensions.png";
import weighingmachine from "../../../assets/weighingmachine.png";
import erros from "../../../assets/erros.png";
import { BiNotificationOff } from "react-icons/bi";
import { PuffLoader } from "react-spinners";
import { DashboardContext } from "../../../context/Dashboard/DashboardProvider/dashboard.provider";
import { BsInfoCircle } from "react-icons/bs";
import LoginCorporate from "../../Login/LoginCorporate";
import { ConnContext } from "../../../context/ConnContext";
import moment from "moment";
import { MdPayment } from "react-icons/md";
import TopBar from "../../../components/TopBar/TopBar";
import GlobalizationLanguage, {GlobalizationLanguagewithCOntryCode} from "../../../components/GlobalizationLanguage";
import Cookies from "js-cookie";
import Currency from "../../../utils/Currency";

export default function DashDrillDownCurrent() {
  const Dashboard = GlobalizationLanguage("/Dashboard")
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  let { type, id } = useParams();

  const history = useHistory();
  var open_deliveries=GlobalizationLanguage(`/open-deliveries`)
  var timeout = GlobalizationLanguage("/timeout")
  var myDillivry = GlobalizationLanguage('/MyDillivry')
  var complaint = GlobalizationLanguage("/complaints")

  const [ShowLogin, setShowLogin] = useState(false);
  const [showpopup, setShowpopup] = useState(false);
  const [showpopup2, setShowpopup2] = useState(false);

  const [showAccountStatus, setshowAccountStatus] = useState(false);
  const [loadingData, setloadingData] = useState(false);

  const [componenttoshow, setComponenttoshow] = useState(false);
  const [componenttoshow2, setComponenttoshow2] = useState(false);
  const [submiting, setsubmiting] = useState(false);
  const [showHelp, setshowHelp] = useState(false);
  const [showSubmitQuote, setShowSubmitQuote] = useState(false);
  const [viewData, setviewData] = useState([]);
  const location = useLocation();
  const auth = useContext(AuthContext);
  const [newState, setnewState] = useState("");
  const [error, seterror] = useState("");
  const [isError, setIsError] = useState(false);
  const { visitingCountryCode } = useContext(ConnContext);

  const Authorization = ls.get("_nodi") === "" ? "" : ls.get("_nodi");

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: Authorization,
    },
  });

  useEffect(() => {
    <Nav />;
  }, [setComponenttoshow]);

  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

  useEffect(() => {
      setCountry(Cookies.get('i18next'));
    },[])

  const [country,setCountry]=useState(countryvalue);



  const schema = yup.object().shape({
    QAmount: yup
      .string()
      .required("Amount required")
      .matches(/^[0-9]*$/, "Amount has to be number"),
  });

  const schemamobile = yup.object().shape({
    QAmountmobile: yup
      .string()
      .required("Amount required")
      .matches(/^[0-9]*$/, "Amount has to be number"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    reset: reset1,
    formState: { errors: errors1 },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schemamobile),
  });

  const quoteNow = (formData) => {
    if (formData.msg === "") {
      if (ls.get("_nodi") !== "" && ls.get("aCs_") === "active") {
        setsubmiting(true);
        authAxios
          .post(
            `m-dilm-dillivry-bid-manager?delivery_location=${Cookies.get("i18next")}livry-bid-manager`,
            {
              action_type: "quote",
              delivery_id: viewData[0].delivery_id,
              quote_amount: formData.QAmount.replaceAll(",", ""),
              vcc: visitingCountryCode,
            },
            { timeout: 240000 }
          )
          .then((response) => {
            setShowSubmitQuote(false);
            if (response.status === 200) {
              Swal.fire({
                icon: "success",
                text: "Quote placed successfully!",
                timer: 2500,
                showConfirmButton: false,
              }).then(() => {
                // window.location.reload()
              });
              reset({});
            }
            setsubmiting(false);
          })
          .catch((err) => {
            setShowSubmitQuote(false);

            if (err?.response?.status === 403) {
              Swal.fire({
                icon: "warning",
                text: "Session expired, login again!",
                //  timer: 2500,
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  auth.logout();
                  setShowLogin(true);
                }
              });
            } else {
              Swal.fire({
                icon: "warning",
                text: `${err?.response?.data?.message}`,
                timer: 2500,
                showConfirmButton: false,
              });
            }
            if (err.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push(timeout);
              // window.location.reload()
            }
          });
      }
    } else {
      // setsubmiting(true);
      if (ls.get("_nodi") !== "" && ls.get("aCs_") === "active") {
        setsubmiting(true);
        authAxios
          .post(
            `m-dillivry-bid-manager?delivery_location=${Cookies.get("i18next")}`,
            {
              action_type: "quote",
              delivery_id: viewData[0].delivery_id,
              quote_amount: formData.QAmount.replaceAll(",", ""),
              msg: formData.msg,
              vcc: visitingCountryCode,
            },
            { timeout: 240000 }
          )
          .then((response) => {
            setShowSubmitQuote(false);
            setsubmiting(false);
            if (response.status === 200) {
              Swal.fire({
                icon: "success",
                text: "Quote placed successfully!",
                timer: 2500,
                showConfirmButton: false,
              }).then(() => {
                // window.location.reload()
              });
              reset({});
              setsubmiting(false);
            }
          })
          .catch((err) => {
            setShowSubmitQuote(false);
            console.log(err);
            // setsubmiting(false);

            if (err?.response?.status === 403) {
              Swal.fire({
                icon: "warning",
                text: "Session expired, login again!",
                //  timer: 2500,
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  auth.logout();
                  setShowLogin(true);
                }
                setsubmiting(false);
              });
            } 
            else if(err?.response?.status === 400){
              if( err?.response?.data?.message === "You can not bid from the selected conutry, please register for the  specifcied country"){
                Swal.fire({
                  title:" Oops forbidden!",
                  text: `${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  customClass: {
                    title:"redBg"
                  },
                } )
              }
              else if( err?.response?.data?.message === "You are owing dillivry please fund your or accept instant payment method to balance your account"){
                Swal.fire({
                  title:" Oops forbidden!",
                  text: `${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  confirmButtonText :"Process",
                  customClass: {
                    title:"redBg"
                  },
                })
                .then(()=>{
                  history.push(GlobalizationLanguagewithCOntryCode("/corpwallet", country,visitingCountryCode))
                })
              }
              else if( err?.response?.data?.message.replace(/[0-9]/g, '') === "You have  item need to be completed before you can bid again"){
                Swal.fire({
                  title:" Oops forbidden!",
                  text:`${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  confirmButtonText :"Process",
                  customClass: {
                    title:"redBg"
                  },
                })
                .then(()=>{
                  history.push(GlobalizationLanguagewithCOntryCode("/MyDillivry", country,visitingCountryCode))
                })
              }
              else{
                Swal.fire({
                  title:" Oops forbidden!",
                  text: `${err?.response?.data?.message}`,
                  showConfirmButton: true,
                  confirmButtonColor: "var(--main)",
                  customClass: {
                    title:"redBg"
                  },
                })
              }
              setsubmiting(false);
            }
            else {
              Swal.fire({
                icon: "warning",
                text: `${err?.response?.data?.message}`,
                timer: 2500,
                showConfirmButton: false,
              });
              setsubmiting(false);
            }
            if (err.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push(timeout);
              // window.location.reload()
            }
          });
      }
    }
  };

  const quoteNowMobile = (formData) => {
    if (formData.msg === "") {
      if (ls.get("_nodi") !== "" && ls.get("aCs_") === "active") {
        setsubmiting(true);
        authAxios
          .post(
            `m-dillivry-bid-manager?delivery_location=${Cookies.get("i18next")}`,
            {
              action_type: "quote",
              delivery_id: viewData[0].delivery_id,
              quote_amount: formData.QAmountmobile.replaceAll(",", ""),
              vcc: visitingCountryCode,
            },
            { timeout: 240000 }
          )
          .then((response) => {
            setShowSubmitQuote(false);
            if (response.status === 200) {
              Swal.fire({
                icon: "success",
                text: "Quote placed successfully!",
                timer: 2500,
                showConfirmButton: false,
              }).then(() => {
                // window.location.reload();
              });
              reset1({});
            }
            setsubmiting(false);
          })
          .catch((err) => {
            setShowSubmitQuote(false);
            console.log(err);
            if (err.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push(timeout);
              // window.location.reload()
            }

            if (err?.response?.status === 403) {
              Swal.fire({
                icon: "warning",
                text: "Session expired, login again!",
                //  timer: 2500,
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  auth.logout();
                  setShowLogin(true);
                }
              });
            } else {
              Swal.fire({
                icon: "warning",
                text: `${err?.response?.data?.message}`,
                timer: 2500,
                showConfirmButton: false,
              });
            }
            if (err.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push(timeout);
              // window.location.reload()
            }
          });
      }
    } else {
      // setsubmiting(true);
      if (ls.get("_nodi") !== "" && ls.get("aCs_") === "active") {
        setsubmiting(true);
        authAxios
          .post(
            `m-dillivry-bid-manager?delivery_location=${Cookies.get("i18next")}`,
            {
              action_type: "quote",
              delivery_id: viewData[0].delivery_id,
              quote_amount: formData.QAmountmobile.replaceAll(",", ""),
              msg: formData.msg,
              vcc: visitingCountryCode,
            },
            { timeout: 240000 }
          )
          .then((response) => {
            setShowSubmitQuote(false);
            setsubmiting(false);
            if (response.status === 200) {
              Swal.fire({
                icon: "success",
                text: "Quote placed successfully!",
                timer: 2500,
                showConfirmButton: false,
              }).then(() => {
                // window.location.reload()
              });
              reset1({});
              setsubmiting(false);
            }
          })
          .catch((err) => {
            setShowSubmitQuote(false);
            console.log(err);
            // setsubmiting(false);

            if (err?.response?.status === 403) {
              Swal.fire({
                icon: "warning",
                text: "Session expired, login again!",
                //  timer: 2500,
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  auth.logout();
                  setShowLogin(true);
                }
                setsubmiting(false);
              });
            } else {
              Swal.fire({
                icon: "warning",
                text:  `${err?.response?.data?.message}`,
                timer: 2500,
                showConfirmButton: false,
              });
              setsubmiting(false);
            }
          });
      }
    }
  };

  useEffect(() => {
    console.log(location?.state?.location, "fgdggdggdg")
    window.scrollTo(0, 0);
  }, []);

  const [show, setshow] = useState("hide");
  const [selected, setselected] = useState("hide");

  const handlerRadioChange = (e) => {
    if (e.target.value === "show") {
      setshow("show");
      setselected(e.target.value);
    } else {
      setshow("hide");
      setselected(e.target.value);
    }
  };

  const [origin, setorigin] = useState([]);
  const [destination, setdestination] = useState([]);

  const { dashboards, updateDashboard,setpage,page } = useContext(DashboardContext);

  const formatString = (string) => {
    let trimStr = string.trim();
    let trimStr2 = trimStr.split(" ");
    trimStr2 = trimStr2.filter((e) => e.length !== 0);
    return trimStr2;
  };
// using dashbaord API
  // useEffect(() => {
  //   if (dashboards !== undefined) {
  //     let data = [];
  //     if (type === "current") {
  //       data = dashboards.data.current_quotes.filter(
  //         (item) => item.delivery_id === id
  //       );
  //     }

  //     if (type === "decline") {
  //       data = dashboards.data.unsuccessful_quotes.filter(
  //         (item) => item.delivery_id === id
  //       );
  //     }

  //     setviewData(data);
  //     console.log(data, "data")
  //     const startLat = parseFloat(data[0]?.start_location?.lat);
  //     const startlng = parseFloat(data[0]?.start_location?.lng);
  //     const endLat = parseFloat(data[0]?.end_location?.lat);
  //     const endLng = parseFloat(data[0]?.end_location?.lng);

  //     setorigin([
  //       {
  //         id: "pickup",
  //         title: "Pickup",
  //         coordinates: {
  //           lat: startLat,
  //           lon: startlng,
  //         },
  //       },
  //     ]);
  //     setdestination([
  //       {
  //         id: "destination",
  //         title: "Destination",
  //         coordinates: {
  //           lat: endLat,
  //           lon: endLng,
  //         },
  //       },
  //     ]);
  //   } else {
  //     if (ls.get("_nodi") === "") {
  //       auth.logout();
  //       setShowLogin(true);
  //     } else {
  //       const decoded = jwt_decode(ls.get("_nodi"));
  //       const expired = decoded.exp;

  //       if (expired < Date.now() / 1000) {
  //         Swal.fire({
  //           icon: "warning",
  //           title: "Session!",
  //           text: "Session expired login again!",
  //           allowOutsideClick: false,
  //           confirmButtonColor: "var(--main)",
  //         }).then((result) => {
  //           if (result.value) {
  //             auth.logout();
  //             setShowLogin(true);
  //           }
  //         });
  //       } else {
  //         if (
  //           type !== "current" &&
  //           type !== "assign" &&
  //           type !== "unassign" &&
  //           type !== "out_for_delivery" &&
  //           type !== "complete" &&
  //           type !== "missed" &&
  //           type !== "decline"
  //         ) {
  //           setIsError(true);
  //           seterror("invalid");
  //         } else {
  //           setIsError(false);
  //           setloadingData(true);

  //           axios
  //             .get(`${process.env.REACT_APP_API}m-dillivry-dashboard-manager`, {
  //               headers: {
  //                 Authorization: ls.get("_nodi"),
  //               },
  //             })
  //             .then((response) => {
  //               ls.set(
  //                 "means_of_delivery",
  //                 JSON.stringify(
  //                   response?.data?.account_profile?.account_profile[0]
  //                     ?.mode_of_delivery
  //                 )
  //               );
  //               setloadingData(false);

  //               if (response.status === 200) {
  //                 updateDashboard(response);
  //                 let data = [];

  //                 if (type === "current") {
  //                   data = response.data.current_quotes.filter(
  //                     (item) => item.delivery_id === id
  //                   );
  //                 }

  //                 if (type === "decline") {
  //                   data = response.data.unsuccessful_quotes.filter(
  //                     (item) => item.delivery_id === id
  //                   );
  //                 }

  //                 setviewData(data);
  //                 const startLat = parseFloat(data[0].start_location.lat);
  //                 const startlng = parseFloat(data[0].start_location.lng);
  //                 const endLat = parseFloat(data[0].end_location.lat);
  //                 const endLng = parseFloat(data[0].end_location.lng);

  //                 setorigin([
  //                   {
  //                     id: "pickup",
  //                     title: "Pickup",
  //                     coordinates: {
  //                       lat: startLat,
  //                       lon: startlng,
  //                     },
  //                   },
  //                 ]);
  //                 setdestination([
  //                   {
  //                     id: "destination",
  //                     title: "Destination",
  //                     coordinates: {
  //                       lat: endLat,
  //                       lon: endLng,
  //                     },
  //                   },
  //                 ]);
  //               }
  //             })
  //             .catch((err) => {
  //               if (err?.response?.status === 403) {
  //                 Swal.fire({
  //                   icon: "warning",
  //                   title: "Session!",
  //                   text: "Session expired login again!",
  //                   allowOutsideClick: false,
  //                   confirmButtonColor: "var(--main)",
  //                 }).then((result) => {
  //                   if (result.value) {
  //                     auth.logout();
  //                     setShowLogin(true);
  //                   }
  //                 });
  //               } else {
  //                 setIsError(true);
  //                 seterror("not_foung");
  //               }
  //             });
  //         }
  //       }
  //     }
  //   }
  // }, []);


// using open delivery API
  
  useEffect(() => {
    if (dashboards !== undefined) {
      ls.set(
        "means_of_delivery",
        JSON.stringify(dashboards?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
      );
    }
    setloadingData(true);
    const url = location?.state?.location ? location?.state?.location : Cookies.get('i18next')
      if(url  !== "" || url  !== undefined){
        authAxios
        .get(`${process.env.REACT_APP_API}m-dillivry-search?vcc=${url}&attribute=query_delivery&delivery_id=${id}`,
            { timeout: 24000 }
          )
          .then((response) => {
            
            setviewData([response.data.data])
  
            const searchData = response.data.data
  
            if (searchData.length !== 0) {
              const startLat = parseFloat(searchData.start_location.lat);
              const startlng = parseFloat(searchData.start_location.lng);
              const endLat = parseFloat(searchData.end_location.lat);
              const endLng = parseFloat(searchData.end_location.lng);
  
              setorigin([
                {
                  id: "pickup",
                  title: "Pickup",
                  coordinates: {
                    lat: startLat,
                    lon: startlng,
                  },
                },
              ]);
              setdestination([
                {
                  id: "destination",
                  title: "Destination",
                  coordinates: {
                    lat: endLat,
                    lon: endLng,
                  },
                },
              ]);
            }
          })
          .catch((err) => {
            if (err.code === "ECONNABORTED") {
              console.log("internal-server-error-timeout");
              history.push("/timeout");
              // window.location.reload()
            }
          })
          .finally(()=>{
            setloadingData(false);
          })

      }
  }, []);

  const numberWithCommas = new Intl.NumberFormat("en-GB");

  return (
    <>
      {ShowLogin ? (
        <LoginCorporate />
      ) : (
        <>
          {isError ? (
            error === "invalid" ? (
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "fixed",
                  top: "80px",
                  zIndex: "10",
                  flexDirection: "column",
                }}
              >
                <span>
                  <BsInfoCircle size={60} color="var(--main)" />
                </span>
                <span
                  style={{
                    fontSize: "30px",
                    textAlign: "center",
                    padding: "20px",
                  }}
                >
                  Invalid address url, page not found
                </span>
              </div>
            ) : null
          ) : null}

          <div className="deskShow">
            <div className="viewSearch">
              <ReactTooltip
                id="global"
                aria-haspopup="true"
                type="light"
                role="example"
              >
                <p>Destination Address</p>
              </ReactTooltip>
              <div className="Desktop-screen-div">
                <div className="header-body-">
                  <TopBar/>
                </div>
                {viewData.length !== 0 ? (
                  viewData.map((view) => {
                    return (
                      <>
                        {/* {console.log(viewData,"viewData")} */}
                        <div key={view.delivery_id} className="view_Container">
                          <div className="view_Top_mobile">
                            <div className="topItem">
                              <div className="topItem-first-div">
                                <button
                                  className="topItem-first-div-btn"
                                  style={{ cursor: "pointer" }}
                                >
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={()=>
                                      {
                                        history.goBack()
                                      }}
                                  >
                                    Back
                                  </span>
                                </button>
                                <span
                                  className="topItem-first-div-category"
                                  style={{ fontWeight: "600" }}
                                >
                                  {" "}
                                  Category:{" "}
                                </span>
                                <span className="topItem-first-div-item-category">
                                  {view.item_category.charAt(0)?.toUpperCase() +
                                    view.item_category
                                      ?.slice(1)
                                      .replace(/_/g, " ")}
                                </span>
                              </div>
                              <div className="topItem-second-div">
                                <span
                                  className="topItem-second-div-Id"
                                  style={{ fontWeight: "600" }}
                                >
                                  ID:{" "}
                                </span>
                                <span className="topItem-second-div-item-category">
                                  {view.delivery_id}
                                </span>

                                <button
                                  className="topItem-second-div-report"
                                  onClick={() => history.push("/complaints")}
                                >
                                  <span>Report</span>
                                </button>
                              </div>
                            </div>
                            <div></div>
                          </div>

                          <ReactTooltip
                            id="global1"
                            aria-haspopup="true"
                            type="light"
                            role="example"
                          >
                            <p>Pick up Address</p>
                          </ReactTooltip>

                          <ReactTooltip
                            id="global"
                            aria-haspopup="true"
                            type="light"
                            role="example"
                          >
                            <p>Destination Address</p>
                          </ReactTooltip>

                          <div className="view_Map">
                            <GoogleMapWidget
                              origins={destination}
                              destinations={origin}
                            />
                          </div>

                          <div className="main-fulfiler-page">
                            <div className="imgage-content">
                              <Carousel
                                navButtonsAlwaysInvisible={true}
                                indicators={true}
                                animation={"slide"}
                                timeout={100}
                                autoPlay={true}
                              >
                                {view.image_urls?.map((img_url, i) => (
                                  <img
                                    key={i}
                                    src={img_url}
                                    style={{ width: "100%" }}
                                    alt="Item_image"
                                    className="carousel_image-"
                                  />
                                ))}
                              </Carousel>
                            </div>

                            <ReactTooltip
                              id="global3"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Item Description</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="global4"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Distance</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="global5"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Duration In Traffic</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="global6"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Date Listed</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="global7"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Mode of Delivery</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="global18"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Mode of Movement</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="dimension"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Dimension</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="Weight"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Weight</p>
                            </ReactTooltip>

                            <div className="dillivry-info">
                              <div className="right-content-information---">
                                <div className="submit-quote-content">
                                  <div className="quote-div">
                                    {" "}
                                    <h1>Delivery information </h1>
                                  </div>
                                  <div className="current-quote-body  dilivry-one   ">
                                    <div>
                                      <div className="">
                                        <div className="itemDescs-desc">
                                          <div
                                            className="itemDescs- quote-info--- "
                                            data-tip
                                            data-for="global3"
                                          >
                                            <div data-tip data-for="global3">
                                              {" "}
                                              <img
                                                src={itemdescr_}
                                                alt=""
                                                className="icn-one-"
                                              />{" "}
                                            </div>
                                            <div data-tip data-for="global3">
                                              {" "}
                                              <span className="icon-desc">
                                                {view.item_desc
                                                  .charAt(0)
                                                  ?.toUpperCase() +
                                                  view.item_desc
                                                    .toLowerCase()
                                                    ?.slice(1)}
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            className="itemDescs-"
                                            data-tip
                                            data-for="global1"
                                          >
                                            <div>
                                              {" "}
                                              <img
                                                src={to}
                                                alt=""
                                                className="icn-one-"
                                              />{" "}
                                            </div>
                                            <div>
                                              {" "}
                                              <span className="icon-desc">
                                                {view.from}
                                              </span>
                                            </div>
                                          </div>

                                          <div
                                            className="itemDescs-"
                                            data-tip
                                            data-for="global"
                                          >
                                            <div>
                                              {" "}
                                              <img
                                                src={From}
                                                alt=""
                                                className="icn-two-"
                                              />
                                            </div>

                                            <div>
                                              {" "}
                                              <span className="icon-desc">
                                                {view.to}
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            className="itemDescs-"
                                            data-tip
                                            data-for="global4"
                                          >
                                            <div>
                                              {" "}
                                              <img
                                                src={Distance}
                                                alt=""
                                                className="icn3-"
                                              />
                                            </div>

                                            <div>
                                              <span className="icon-desc">
                                                {view.distance}
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            className="itemDescs-"
                                            data-tip
                                            data-for="global5"
                                          >
                                            <div>
                                              <img
                                                src={Duration}
                                                alt=""
                                                className="icn4-"
                                              />
                                            </div>

                                            <div>
                                              <span className="icon-desc">
                                                {view.duration}
                                              </span>
                                            </div>
                                          </div>

                                          <div
                                            className="itemDescs-"
                                            data-tip
                                            data-for="global6"
                                          >
                                            <div>
                                              <img
                                                src={Listed}
                                                alt=""
                                                className="icn5-"
                                              />
                                            </div>

                                            <div>
                                              <span className="icon-desc">
                                              <span className="icon-desc">
                                                { moment(view.date_listed).format('DD-MM-YYYY HH:MM:SS')  }
                                              </span>
                                              </span>
                                            </div>
                                          </div>

                                          {view?.mode_of_delivery ===
                                            "haulage" ||
                                          view?.mode_of_delivery ===
                                            "moving_home" ? (
                                            <div>
                                              <div
                                                className="itemDescs-"
                                                data-tip
                                                data-for="global18"
                                              >
                                                <div>
                                                  <img
                                                    src={Fastdelivery}
                                                    alt=""
                                                    className="icn6-"
                                                  />
                                                </div>

                                                <div>
                                                  <span className="icon-desc">
                                                    {view?.mode_of_delivery
                                                      ?.charAt(0)
                                                      ?.toUpperCase() +
                                                      view?.mode_of_delivery
                                                        ?.toLowerCase()
                                                        .slice(1)
                                                        .replaceAll("_", " ")}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              className="itemDescs-"
                                              data-tip
                                              data-for="global7"
                                            >
                                              <div>
                                                <img
                                                  src={Fastdelivery}
                                                  alt=""
                                                  className="icn6-"
                                                />
                                              </div>

                                              <div>
                                                <span className="icon-desc">
                                                  {view?.mode_of_delivery
                                                    ?.charAt(0)
                                                    ?.toUpperCase() +
                                                    view?.mode_of_delivery
                                                      ?.slice(1)
                                                      .replaceAll("_", " ")}
                                                </span>
                                              </div>
                                            </div>
                                          )}

                                          {view.item_category ===
                                            "general_items" ||
                                          view.item_category === "furniture" ||
                                          view.item_category ===
                                            "vehicle_parts" ? (
                                            <div
                                              className="itemDescs-"
                                              data-tip
                                              data-for="dimension"
                                            >
                                              <div data-tip data-for="">
                                                <img
                                                  className="icn6-"
                                                  src={dimensions}
                                                  alt=""
                                                  style={{ height: "20px" }}
                                                />
                                              </div>

                                              <div>
                                                <span
                                                  className="icon-desc"
                                                  data-tip
                                                  data-for=""
                                                >
                                                  {view?.item_dim?.length ===
                                                    " centimeters" &&
                                                  view?.item_dim?.width ===
                                                    " centimeters" &&
                                                  view?.item_dim?.height ===
                                                    " centimeters" ? (
                                                    "Not provided by sender"
                                                  ) : (
                                                    <>
                                                      {
                                                        formatString(
                                                          view?.item_dim?.length
                                                        )[0]
                                                      }
                                                      {formatString(
                                                        view?.item_dim?.length
                                                      )[1] === "centimeters"
                                                        ? "cm"
                                                        : formatString(
                                                            view?.item_dim
                                                              ?.length
                                                          )[1] === "meters"
                                                        ? "m"
                                                        : formatString(
                                                            view?.item_dim
                                                              ?.length
                                                          )[1] === "inches"
                                                        ? "inches"
                                                        : "ft"}
                                                      &nbsp; x &nbsp;
                                                      {
                                                        formatString(
                                                          view?.item_dim?.width
                                                        )[0]
                                                      }
                                                      {formatString(
                                                        view?.item_dim?.width
                                                      )[1] === "centimeters"
                                                        ? "cm"
                                                        : formatString(
                                                            view?.item_dim
                                                              ?.width
                                                          )[1] === "meters"
                                                        ? "m"
                                                        : formatString(
                                                            view?.item_dim
                                                              ?.width
                                                          )[1] === "inches"
                                                        ? "inches"
                                                        : "ft"}
                                                      &nbsp; x &nbsp;
                                                      {
                                                        formatString(
                                                          view?.item_dim?.height
                                                        )[0]
                                                      }
                                                      {formatString(
                                                        view?.item_dim?.height
                                                      )[1] === "centimeters"
                                                        ? "cm"
                                                        : formatString(
                                                            view?.item_dim
                                                              ?.height
                                                          )[1] === "meters"
                                                        ? "m"
                                                        : formatString(
                                                            view?.item_dim
                                                              ?.height
                                                          )[1] === "inches"
                                                        ? "inches"
                                                        : "ft"}{" "}
                                                      &nbsp; (L x W x H)
                                                    </>
                                                  )}
                                                </span>
                                                <div className="tobeflexmain"></div>
                                              </div>
                                            </div>
                                          ) : null}

                                          {view.item_category ===
                                            "general_items" ||
                                          view.item_category === "furniture" ||
                                          view.item_category ===
                                            "vehicle_parts" ? (
                                            <div
                                              className="itemDescs-"
                                              data-tip
                                              data-for="Weight"
                                            >
                                              <div data-tip data-for="">
                                                <img
                                                  className="icn6-"
                                                  src={weighingmachine}
                                                  alt=""
                                                  style={{ height: "20px" }}
                                                />
                                              </div>

                                              <div>
                                                <span
                                                  className="icon-desc"
                                                  data-tip
                                                  data-for=""
                                                >
                                                  {view?.item_dim?.weight ===
                                                  " grammes" ? (
                                                    "Not provided by sender"
                                                  ) : (
                                                    <>
                                                      {
                                                        formatString(
                                                          view?.item_dim?.weight
                                                        )[0]
                                                      }
                                                      {formatString(
                                                        view?.item_dim?.weight
                                                      )[1] === "gramme"
                                                        ? "gr"
                                                        : formatString(
                                                            view?.item_dim
                                                              ?.weight
                                                          )[1] === "kilogrammes"
                                                        ? "kg"
                                                        : "tonnes"}
                                                    </>
                                                  )}
                                                </span>
                                                <div className="tobeflexmain"></div>
                                              </div>
                                            </div>
                                          ) : view.item_category ===
                                            "haulage" ? (
                                            <div
                                              className="itemDescs-"
                                              data-tip
                                              data-for="Weight"
                                            >
                                              <div>
                                                <img
                                                  className="icn6-"
                                                  src={weighingmachine}
                                                  alt=""
                                                  style={{ height: "20px" }}
                                                />
                                              </div>

                                              <div>
                                                <span className="icon-desc">
                                                  {view?.item_dim === "" ? (
                                                    "Not provided by sender"
                                                  ) : (
                                                    <>
                                                      {formatString(
                                                        view?.item_dim
                                                      )}{" "}
                                                      &nbsp;
                                                    </>
                                                  )}
                                                </span>
                                                <div className="tobeflexmain"></div>
                                              </div>
                                            </div>
                                          ) : null}





                                            <div
                                            className="itemDescs-"
                                            data-tip
                                            data-for="Payer"
                                          >
                                            <div>
                                              {/* <img
                                                src={Listed}
                                                alt=""
                                                className="icn5-"
                                              /> */}
                                                <MdPayment style={{color:"var(--main",  fontSize:"18px" , marginRight: '14px' }}/>
                                            </div>

                                            <div  data-tip
                                            data-for="Payer">
                                              <span  data-tip data-for="Payer" className="icon-desc">
                                              Payer: {view?.payer.charAt(0)?.toUpperCase() +
                                                              view?.payer?.slice(1)}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className=" quote-info ß  submit-quote-content">
                              <div className="quote-div">
                                <h1>Quote Information</h1>
                              </div>
                              <div className="itemDescs-desc current-quote-body ">
                                <div>
                                  <div>
                                    <div className="quote-info--">
                                      <div>
                                        {" "}
                                        <span className="Quote-Title">
                                        Mode of payment:{" "}
                                        </span>{" "}
                                      </div>
                                      <div>
                                        <span className="quote-amount">
                                        {
                                    typeof( view?.mode_of_payment) === "string"  ?
                                    <>
                                    {
                                      view?.mode_of_payment === "pay_by_cash" ?
                                      <div>Cash</div>:
                                      view?.mode_of_payment === "pay_by_transfer" ?
                                      <div>Transfer</div>:
                                      view?.mode_of_payment === "pay_by_ussd" ?
                                      <div>USSD</div>:
                                      view?.mode_of_payment === "pay_by_wallet" ?
                                      <div>Online</div>:
                                      view?.mode_of_payment === "pay_by_card" ?
                                      <div>Card</div>:
                                      <div>{view?.mode_of_payment}</div>

                                    }
                                    
                                    </>
        
                                    :
                                    <div>N/A</div>
                                    
                                  }
                                        
                                        
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div className="quote-info--">
                                      <div>
                                        {" "}
                                        <span className="Quote-Title">
                                          Pick up date:{" "}
                                        </span>{" "}
                                      </div>
                                      <div>
                                        <span className="quote-amount">
                                        {moment(view.pickup_date)?.format("DD-MM-YYYY")}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div className="quote-info--">
                                      <div>
                                        {" "}
                                        <span className="Quote-Title">
                                          Delivery time frame:{" "}
                                        </span>{" "}
                                      </div>
                                      <div>
                                        <span className="quote-amount">
                                          {view.delivery_time_frame}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div className="quote-info--">
                                      <div>
                                        {" "}
                                        <span className="Quote-Title">
                                          Best Quote:{" "}
                                        </span>{" "}
                                      </div>
                                      <div>
                                        <span className="quote-amount">
                                          {
                                            view.best_quote ?
                                              <>
                                                <Currency location={location?.state?.location}/> {numberWithCommas.format(view.best_quote)}
                                              </>  
                                             :
                                              "None"
                                          }
                                          {/* {console.log(view, "view")} */}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <form onSubmit={handleSubmit(quoteNow)} style={{marginBottom:'20px'}}>
                                      <div className="quote-info--">
                                        <div>
                                          {" "}
                                          <span className="Quote-Title">
                                            Your Quote:{" "}
                                          </span>{" "}
                                        </div>
                                        <div>
                                          <span className="quote-amount">
                                            <Currency location={location?.state?.location}/>{" "}
                                            <input
                                              {...register("QAmount")}
                                              type="text"
                                              className="quoteinputcontent"
                                            />
                                            <br />{" "}
                                            <small>
                                              {errors.QAmount?.message}
                                            </small>
                                          </span>
                                        </div>
                                      </div>

                                      <div className="quote-info--">
                                        <div>
                                          {" "}
                                          <div
                                            className=""
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>
                                              {" "}
                                              <span className="Quote-Title-message   ques-">
                                                {" "}
                                                Would you like to add message?
                                              </span>{" "}
                                            </div>

                                            <div style={{ display: "flex" }}>
                                              <div
                                                style={{ marginRight: "30px" }}
                                              >
                                                <input
                                                  type="radio"
                                                  value="show"
                                                  className="message_yes_response"
                                                  name="content"
                                                  onClick={handlerRadioChange}
                                                  checked={selected === "show"}
                                                />
                                                <label htmlFor="Yes">Yes</label>
                                              </div>

                                              <div>
                                                <input
                                                  type="radio"
                                                  value="hide"
                                                  className="message_yes_response"
                                                  name="content"
                                                  onClick={handlerRadioChange}
                                                  checked={selected === "hide"}
                                                />
                                                <label htmlFor="No_">No</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div></div>
                                      </div>

                                      {show === "show" ? (
                                        <div className="">
                                          <div className="">
                                            <textarea
                                              {...register("msg")}
                                              rows="3"
                                              cols="20"
                                              className="quoteinputcontent-"
                                              required
                                            ></textarea>
                                          </div>
                                        </div>
                                      ) : null}

                                      <div className="enquiry-submit" style={{marginBottom:'20px'}}>
                                        <div className="">
                                          {submiting ? (
                                            <p
                                              style={{
                                                textAlign: "center",
                                                color: "green",
                                              }}
                                            >
                                              Submitting...
                                            </p>
                                          ) : (
                                            <div className="parent-submit-quote-enquiry-msg">
                                              <div>
                                                <button
                                                  type="submit"
                                                  className="btn-quote-form"
                                                  onClick={(e) => {
                                                    setShowpopup(!showpopup);
                                                    setshow("hide");
                                                    if (
                                                      ls.get("_nodi") === ""
                                                    ) {
                                                      setComponenttoshow(true);
                                                      setComponenttoshow2(
                                                        false
                                                      );
                                                    } else if (
                                                      ls.get("aCs_") !==
                                                      "active"
                                                    ) {
                                                      setshowAccountStatus(
                                                        !showAccountStatus
                                                      );
                                                      setComponenttoshow(false);
                                                      setComponenttoshow2(
                                                        false
                                                      );
                                                      setShowpopup2(false);
                                                      setShowpopup(false);
                                                    } else {
                                                      const decoded =
                                                        jwt_decode(
                                                          ls.get("_nodi")
                                                        );
                                                      const expired =
                                                        decoded.exp;

                                                      if (
                                                        expired <
                                                        Date.now() / 1000
                                                      ) {
                                                        console.log(
                                                          "session expired"
                                                        );
                                                        Swal.fire({
                                                          icon: "warning",
                                                          title: "Session!",
                                                          text: "Session expired login again!",
                                                          allowOutsideClick: false,
                                                          confirmButtonColor:
                                                            "var(--main)",
                                                        }).then((result) => {
                                                          if (result.value) {
                                                            auth.logout();
                                                            setShowpopup(true);
                                                            setComponenttoshow(
                                                              true
                                                            );
                                                          }
                                                        });
                                                      } else {
                                                        setShowpopup(
                                                          !showpopup
                                                        );
                                                        setComponenttoshow(
                                                          false
                                                        );
                                                        setComponenttoshow2(
                                                          false
                                                        );
                                                        setShowpopup2(false);
                                                      }
                                                    }

                                                    // if (ls.get("_nodi" !== "")) {
                                                    //   const decoded = jwt_decode(
                                                    //     ls.get("_nodi")
                                                    //   );
                                                    //   const exp = new Date(
                                                    //     decoded.exp * 1000
                                                    //   );
                                                    //   if (exp < Date.now() / 1000) {
                                                    //     setComponenttoshow(true);
                                                    //   } else {
                                                    //     setComponenttoshow(false);
                                                    //   }
                                                    // }
                                                  }}
                                                >
                                                  Submit quote 
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="provider-history-div">
                            <h2 className="provider-history">
                              Provider History
                            </h2>
                            <table className="table__  table-tr-th  tabledelivry  ">
                              <tr>
                                <th>Quote amount</th>
                                <th>Logistic provider</th>
                                <th>Time</th>
                                <th>Quote Status</th>
                                <th className="third-tr">Providers info </th>
                              </tr>

                              {view.quote_history?.map((detail, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {" "}
                                      <Currency location={location?.state?.location}/>
                                      {numberWithCommas.format(
                                        detail?.quote_amount
                                      )}
                                    </td>
                                    <td>{detail?.fulfiller_id}</td>
                                    <td>{detail?.time_stamp}</td>
                                    <td>
                                      {detail?.quote_status === "rejected,"
                                        ? "Rejected"
                                        : detail?.quote_status
                                            ?.replaceAll("_", " ")
                                            ?.charAt(0)
                                            ?.toUpperCase() +
                                          detail?.quote_status
                                            ?.replaceAll("_", " ")
                                            ?.slice(1)}
                                    </td>

                                    <td>
                                      <button
                                        onClick={() => {
                                          history.push(
                                            GlobalizationLanguagewithCOntryCode(`/open-delivery/${viewData[0].delivery_id}/${detail.fulfiller_id}`, country, visitingCountryCode)
                                          );
                                        }}
                                        className="profile-btn"
                                      >
                                        <span>View Profile</span>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </table>
                          </div>

                          <div
                            className="main-father"
                            style={{ position: "relative", marginTop: "30px" }}
                          >
                            <header className="qoute-header enquiry-header">
                              <h4>Enquires from providers</h4>
                            </header>
                          </div>
                          {[...[], view?.inquiry_details]?.map((msg, i) => {
                            return (
                              <div className="Enquiry-div-message">
                                {msg?.message?.reverse()?.map((x) => (
                                  <div>
                                    <p>
                                      {x?.fulfiller_id && (
                                        <b>
                                          Provider:{" "}
                                          {moment(
                                            x?.fulfiller_id && x?.time_stamp
                                          ).format("L, h:mm a ")}
                                          {moment(x?.time_stamp).fromNow()}{" "}
                                        </b>
                                      )}
                                    </p>
                                    <p>{x?.fulfiller_id && x?.msg}</p>

                                    <p>
                                      {x?.user_id && (
                                        <b>
                                          Sender:
                                          {moment(
                                            x?.user_id && x?.time_stamp
                                          ).format("L, h:mm a ")}
                                          {moment(x?.time_stamp).fromNow()}{" "}
                                        </b>
                                      )}
                                    </p>
                                    <p>{x?.user_id && x?.msg}</p>
                                  </div>
                                ))}
                              </div>
                            );
                          })}

                          <div className="footerover-ride"></div>
                        </div>

                        {showHelp ? (
                          <HomeHelp
                            showDialog={showHelp}
                            onClose={() => setshowHelp(false)}
                          />
                        ) : null}

                        {componenttoshow2 ? (
                          <DialogOutClick
                            open={showpopup2}
                            handleClose={() => setShowpopup2(!showpopup2)}
                          >
                            <EnquiryForm
                              item={view}
                              onFinish={(state) => {
                                if (state === "inactive") {
                                  // setshowAccountStatus(true)
                                  // setShowpopup2(showpopup2);
                                  setComponenttoshow2(false);
                                } else {
                                  setShowpopup2(!showpopup2);
                                }
                              }}
                              closePopup={() => setShowpopup2(!showpopup2)}
                            />
                          </DialogOutClick>
                        ) : null}

                        <DialogOutClick
                          open={showAccountStatus}
                          handleClose={() =>
                            setshowAccountStatus(!showAccountStatus)
                          }
                        >
                          <div
                            style={{
                              width: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "column",
                              gap: "20px",
                              padding: "20px",
                              paddingTop: "40px",
                            }}
                          >
                            <AiFillCloseCircle
                              onClick={() =>
                                setshowAccountStatus(!showAccountStatus)
                              }
                              size="28px"
                              color="red"
                              style={{
                                position: "absolute",
                                right: "15px",
                                top: "15px",
                              }}
                            />
                            <img
                              src={erros}
                              alt="dillivry"
                              style={{ width: "60px" }}
                            />
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                width: "100%",
                              }}
                            >
                              {ls.get("_iRl") === "dillivry-corp-sys-admin" ? (
                                <>
                                  <div>
                                    Your account is currently pending validation
                                  </div>
                                  <div>
                                    Click Ok to see your account status.
                                  </div>
                                  <br></br>
                                  <span
                                    style={{
                                      padding: "7px 12px",
                                      backgroundColor: "var(--main)",
                                      color: "white",
                                      cursor: "pointer",
                                      borderRadius: "5px",
                                    }}
                                    onClick={() => history.push(Dashboard)}
                                  >
                                    Ok{" "}
                                  </span>
                                </>
                              ) : ls.get("_iRl") ===
                                "dillivry-sole-trader-ops" ? (
                                <>
                                  <div>
                                    Your account is currently pending validation
                                  </div>
                                  <div>
                                    Login to your Dillivry App to see the status
                                    of your registration
                                  </div>
                                  <br></br>
                                  <span
                                    style={{
                                      padding: "7px 12px",
                                      backgroundColor: "var(--main)",
                                      color: "white",
                                      cursor: "pointer",
                                      borderRadius: "5px",
                                    }}
                                    onClick={() =>
                                      history.push(open_deliveries)
                                    }
                                  >
                                    Ok{" "}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </DialogOutClick>
                      </>
                    );
                  })
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "50px 0",
                      fontSize: "28px",
                      fontWeight: "600",
                      color: "var(--main)",
                      flexDirection: "column",
                    }}
                  >
                    {loadingData ? (
                      <PuffLoader
                        color="#50b14a"
                        loading={loadingData}
                        speedMultiplier={1}
                        size={100}
                      />
                    ) : (
                      <>
                        <span>
                          {" "}
                          <BiNotificationOff size="50px" />
                        </span>
                        <span>Item not found</span>
                      </>
                    )}
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>

          {/* MOBILE SCREEEN */}
          {/* MOBILE SCREEEN */}
          {/* MOBILE SCREEEN */}
          {/* MOBILE SCREEEN */}

          <div className="mobileShow">
            <div className="viewSearch">
              <ReactTooltip
                id="global"
                aria-haspopup="true"
                type="light"
                role="example"
              >
                <p>Destination Address</p>
              </ReactTooltip>
              <div className="Desktop-screen-div">
                <div className="header-body-">
                <TopBar/>
                </div>
                {viewData.length !== 0 ? (
                  viewData.map((view) => {
                    return (
                      <>
                        <div key={view.delivery_id} className="view_Container">
                          <div className="view_Top_mobile">
                            <div className="topItem">
                              <div className="topItem-first-div">
                                <button
                                  className="topItem-first-div-btn"
                                  style={{ cursor: "pointer" }}
                                >
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={()=>{
                                      history.goBack()
                                      // history.push(myDillivry)
                                    }} >
                                    Back
                                  </span>
                                </button>
                                <span>
                                  {" "}
                                </span>
                                <span className="topItem-first-div-item-category">
                                  {view.item_category
                                    ?.charAt(0)
                                    ?.toUpperCase() +
                                    view.item_category
                                      ?.slice(1)
                                      .replace(/_/g, " ")}
                                </span>
                              </div>
                              <div className="topItem-second-div">
                                <span className="topItem-second-div-item-category">
                                  {view.delivery_id}
                                </span>

                                <button
                                  className="topItem-second-div-report"
                                  onClick={() => history.push(complaint)}
                                >
                                  <span>Report</span>
                                </button>
                              </div>
                            </div>
                            <div></div>
                          </div>

                          <ReactTooltip
                            id="global1"
                            aria-haspopup="true"
                            type="light"
                            role="example"
                          >
                            <p>Pick up Address</p>
                          </ReactTooltip>

                          <ReactTooltip
                            id="global"
                            aria-haspopup="true"
                            type="light"
                            role="example"
                          >
                            <p>Destination Address</p>
                          </ReactTooltip>

                          <div className="view_Map">
                            <GoogleMapWidget
                              origins={destination}
                              destinations={origin}
                            />
                          </div>

                          <div className="new_flex">
                            <span>
                              {view.item_category?.charAt(0)?.toUpperCase() +
                                view.item_category
                                  .slice(1)
                                  .replace(/_/g, " ")}
                            </span>
                            <span >
                              {view.delivery_id}
                            </span>
                          </div>
                          <div className="main-fulfiler-page">
                            <div className="imgage-content">
                              <Carousel
                                navButtonsAlwaysInvisible={true}
                                indicators={true}
                                animation={"slide"}
                                timeout={100}
                                autoPlay={true}
                              >
                                {view.image_urls?.map((img_url, i) => (
                                  <img
                                    key={i}
                                    src={img_url}
                                    style={{ width: "100%" }}
                                    alt="Item_image"
                                    className="carousel_image-"
                                  />
                                ))}
                              </Carousel>
                            </div>

                            <ReactTooltip
                              id="Payer"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Payer</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="global10"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Weight</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="global3"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Item Description</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="global4"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Distance</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="global5"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Duration In Traffic</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="global6"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Date Listed</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="global7"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Mode of Delivery</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="dimension"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Dimension</p>
                            </ReactTooltip>

                            <ReactTooltip
                              id="Weight"
                              aria-haspopup="true"
                              type="light"
                              role="example"
                            >
                              <p>Weight</p>
                            </ReactTooltip>

                            

                            <div className="dillivry-info">
                              <div className="right-content-information---">
                                <div className="submit-quote-content">
                                  <div className="quote-div">
                                    {" "}
                                    <h1>Delivery information  </h1>
                                  </div>
                                  <div className="current-quote-body  dilivry-one   ">
                                    <div>
                                      <div className="">
                                        <div className="itemDescs-desc">
                                          <div
                                            className="itemDescs- quote-info--- "
                                            data-tip
                                            data-for="global3"
                                          >
                                            <div data-tip data-for="global3">
                                              {" "}
                                              <img
                                                src={itemdescr_}
                                                alt=""
                                                className="icn-one-"
                                              />{" "}
                                            </div>
                                            <div data-tip data-for="global3">
                                              {" "}
                                              <span className="icon-desc">
                                                {view.item_desc
                                                  ?.charAt(0)
                                                  ?.toUpperCase() +
                                                  view.item_desc
                                                    .toLowerCase()
                                                    ?.slice(1)}
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            className="itemDescs-"
                                            data-tip
                                            data-for="global1"
                                          >
                                            <div>
                                              {" "}
                                              <img
                                                src={to}
                                                alt=""
                                                className="icn-one-"
                                              />{" "}
                                            </div>
                                            <div>
                                              {" "}
                                              <span className="icon-desc">
                                                {view.from}
                                              </span>
                                            </div>
                                          </div>

                                          <div
                                            className="itemDescs-"
                                            data-tip
                                            data-for="global"
                                          >
                                            <div>
                                              {" "}
                                              <img
                                                src={From}
                                                alt=""
                                                className="icn-two-"
                                              />
                                            </div>

                                            <div>
                                              {" "}
                                              <span className="icon-desc">
                                                {view.to}
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            className="itemDescs-"
                                            data-tip
                                            data-for="global4"
                                          >
                                            <div>
                                              {" "}
                                              <img
                                                src={Distance}
                                                alt=""
                                                className="icn3-"
                                              />
                                            </div>

                                            <div>
                                              <span className="icon-desc">
                                                {view.distance}
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            className="itemDescs-"
                                            data-tip
                                            data-for="global5"
                                          >
                                            <div>
                                              {" "}
                                              <img
                                                src={Duration}
                                                alt=""
                                                className="icn4-"
                                              />
                                            </div>

                                            <div>
                                              {" "}
                                              <span className="icon-desc">
                                                {view.duration}
                                              </span>
                                            </div>
                                          </div>

                                          <div
                                            className="itemDescs-"
                                            data-tip
                                            data-for="global6"
                                          >
                                            <div>
                                              <img
                                                src={Listed}
                                                alt=""
                                                className="icn5-"
                                              />
                                            </div>

                                            <div>
                                              {" "}
                                              <span className="icon-desc">
                                                { moment(view.date_listed).format('DD-MM-YYYY HH:MM:SS')  }
                                              </span>
                                            </div>
                                          </div>

                                          {view?.mode_of_delivery ===
                                            "haulage" ||
                                          view?.mode_of_delivery ===
                                            "moving_home" ? (
                                            <div>
                                              <div
                                                className="itemDescs-"
                                                data-tip
                                                data-for="global18"
                                              >
                                                <div>
                                                  <img
                                                    src={Fastdelivery}
                                                    alt=""
                                                    className="icn6-"
                                                  />
                                                </div>

                                                <div>
                                                  <span className="icon-desc">
                                                    {view?.mode_of_delivery
                                                      ?.charAt(0)
                                                      ?.toUpperCase() +
                                                      view?.mode_of_delivery
                                                        ?.toLowerCase()
                                                        .slice(1)
                                                        .replaceAll("_", " ")}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div
                                              className="itemDescs-"
                                              data-tip
                                              data-for="global7"
                                            >
                                              <div>
                                                <img
                                                  src={Fastdelivery}
                                                  alt=""
                                                  className="icn6-"
                                                />
                                              </div>

                                              <div>
                                                <span className="icon-desc">
                                                  {view?.mode_of_delivery
                                                    ?.charAt(0)
                                                    ?.toUpperCase() +
                                                    view?.mode_of_delivery
                                                      ?.toLowerCase()
                                                      .slice(1)
                                                      .replaceAll("_", " ")}
                                                </span>
                                              </div>
                                            </div>
                                          )}

                                          {view.item_category ===
                                            "general_items" ||
                                          view.item_category === "furniture" ||
                                          view.item_category ===
                                            "vehicle_parts" ? (
                                            <div
                                              className="itemDescs-"
                                              data-tip
                                              data-for="dimension"
                                            >
                                              <div>
                                                <img
                                                  className="icn6-"
                                                  src={dimensions}
                                                  alt=""
                                                  style={{ height: "20px" }}
                                                />
                                              </div>

                                              <div>
                                                <span className="icon-desc">
                                                  {view?.item_dim?.length ===
                                                    " centimeters" &&
                                                  view?.item_dim?.width ===
                                                    " centimeters" &&
                                                  view?.item_dim?.height ===
                                                    " centimeters" ? (
                                                    "Not provided by sender"
                                                  ) : (
                                                    <>
                                                      {
                                                        formatString(
                                                          view?.item_dim?.length
                                                        )[0]
                                                      }
                                                      {formatString(
                                                        view?.item_dim?.length
                                                      )[1] === "centimeters"
                                                        ? "cm"
                                                        : formatString(
                                                            view?.item_dim
                                                              ?.length
                                                          )[1] === "meters"
                                                        ? "m"
                                                        : formatString(
                                                            view?.item_dim
                                                              ?.length
                                                          )[1] === "inches"
                                                        ? "inches"
                                                        : "ft"}
                                                      &nbsp; x &nbsp;
                                                      {
                                                        formatString(
                                                          view?.item_dim?.width
                                                        )[0]
                                                      }
                                                      {formatString(
                                                        view?.item_dim?.width
                                                      )[1] === "centimeters"
                                                        ? "cm"
                                                        : formatString(
                                                            view?.item_dim
                                                              ?.width
                                                          )[1] === "meters"
                                                        ? "m"
                                                        : formatString(
                                                            view?.item_dim
                                                              ?.width
                                                          )[1] === "inches"
                                                        ? "inches"
                                                        : "ft"}
                                                      &nbsp; x &nbsp;
                                                      {
                                                        formatString(
                                                          view?.item_dim?.height
                                                        )[0]
                                                      }
                                                      {formatString(
                                                        view?.item_dim?.height
                                                      )[1] === "centimeters"
                                                        ? "cm"
                                                        : formatString(
                                                            view?.item_dim
                                                              ?.height
                                                          )[1] === "meters"
                                                        ? "m"
                                                        : formatString(
                                                            view?.item_dim
                                                              ?.height
                                                          )[1] === "inches"
                                                        ? "inches"
                                                        : "ft"}{" "}
                                                      &nbsp; &nbsp; &nbsp; (L x
                                                      W x H)
                                                    </>
                                                  )}
                                                </span>
                                                <div className="tobeflexmain"></div>
                                              </div>
                                            </div>
                                          ) : null}

                                          {view.item_category ===
                                            "general_items" ||
                                          view.item_category === "furniture" ||
                                          view.item_category ===
                                            "vehicle_parts" ? (
                                            <div
                                              className="itemDescs-"
                                              data-tip
                                              data-for="Weight"
                                            >
                                              <div>
                                                <img
                                                  className="icn6-"
                                                  src={weighingmachine}
                                                  alt=""
                                                  style={{ height: "20px" }}
                                                />
                                              </div>

                                              <div>
                                                <span className="icon-desc">
                                                  {view?.item_dim?.weight ===
                                                  " grammes" ? (
                                                    "Not provided by sender"
                                                  ) : (
                                                    <>
                                                      {
                                                        formatString(
                                                          view?.item_dim?.weight
                                                        )[0]
                                                      }{" "}
                                                      &nbsp; &nbsp;
                                                      {formatString(
                                                        view?.item_dim?.weight
                                                      )[1] === "gramme"
                                                        ? "gr"
                                                        : formatString(
                                                            view?.item_dim
                                                              ?.weight
                                                          )[1] === "kilogrammes"
                                                        ? "kg"
                                                        : "tonnes"}
                                                    </>
                                                  )}
                                                </span>
                                                <div className="tobeflexmain"></div>
                                              </div>
                                            </div>
                                          ) : view.item_category ===
                                            "haulage" ? (
                                            <div className="itemDescs-">
                                              <div>
                                                <img
                                                  className="icn6-"
                                                  src={weighingmachine}
                                                  alt=""
                                                  style={{ height: "20px" }}
                                                />
                                              </div>
                                              <div>
                                                <span className="icon-desc">
                                                  {view?.item_dim === "" ? (
                                                    "Not provided by sender"
                                                  ) : (
                                                    <>
                                                      {formatString(
                                                        view?.item_dim
                                                      )}
                                                    </>
                                                  )}
                                                </span>
                                                <div className="tobeflexmain"></div>
                                              </div>
                                            </div>
                                          ) : null}




                                              <div
                                            className="itemDescs-"
                                            data-tip  data-for="Payer" 
                                          >
                                            <div  data-tip  data-for="Payer" >
                                            
                                                <MdPayment style={{color:"var(--main",  fontSize:"18px" , marginRight: '14px' }}/>
                                            </div>

                                            <div   >
                                              <span   className="icon-desc">
                                               Payer: {view?.payer.charAt(0)?.toUpperCase() +
                                                              view?.payer?.slice(1)}
                                              
                                              </span>
                                            </div>
                                          </div>



                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className=" quote-info ß  submit-quote-content">
                              <div className="quote-div">
                                <h1>Quote Information</h1>
                              </div>
                              <div className="itemDescs-desc current-quote-body ">
                                <div>
                                  <div>
                                    <div className="quote-info--">
                                      <div>
                                        {" "}
                                        <span className="Quote-Title">
                                        Mode of payment:{" "}
                                        </span>{" "}
                                      </div>
                                      <div>
                                        <span className="quote-amount">
                                        {
                                    typeof( view?.mode_of_payment) === "string"  ?
                                    <>
                                    {
                                      view?.mode_of_payment === "pay_by_cash" ?
                                      <div>Cash</div>:
                                      view?.mode_of_payment === "pay_by_transfer" ?
                                      <div>Transfer</div>:
                                      view?.mode_of_payment === "pay_by_ussd" ?
                                      <div>USSD</div>:
                                      view?.mode_of_payment === "pay_by_wallet" ?
                                      <div>Online</div>:
                                      view?.mode_of_payment === "pay_by_card" ?
                                      <div>Card</div>:
                                      <div>{view?.mode_of_payment}</div>

                                    }
                                    
                                    </>
        
                                    :
                                    <div>N/A</div>
                                    
                                  }
                                      
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div className="quote-info--">
                                      <div>
                                        {" "}
                                        <span className="Quote-Title">
                                          Pick up date:{" "}
                                        </span>{" "}
                                      </div>
                                      <div>
                                        <span className="quote-amount">
                                        {moment(view.pickup_date)?.format("DD-MM-YYYY")}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div className="quote-info--">
                                      <div>
                                        {" "}
                                        <span className="Quote-Title">
                                          Delivery time frame:{" "}
                                        </span>{" "}
                                      </div>
                                      <div>
                                        <span className="quote-amount">
                                          {view.delivery_time_frame}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <div className="quote-info--">
                                      <div>
                                        {" "}
                                        <span className="Quote-Title">
                                          Best Quote:{" "}
                                        </span>{" "}
                                      </div>
                                      <div>
                                        <span className="quote-amount">
                                        <Currency location={location?.state?.location}/> {view.best_quote}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <form
                                      onSubmit={handleSubmit1(quoteNowMobile)}
                                    >
                                      <div className="quote-info--">
                                        <div>
                                          {" "}
                                          <span className="Quote-Title">
                                            Your Quote:{" "}
                                          </span>{" "}
                                        </div>
                                        <div>
                                          <span className="quote-amount">
                                          <Currency location={location?.state?.location}/>{" "}
                                            <input
                                              {...register1("QAmountmobile")}
                                              type="text"
                                              className="quoteinputcontent"
                                            />
                                            <br />{" "}
                                            <small>
                                              {errors1.QAmountmobile?.message}
                                            </small>
                                          </span>
                                        </div>
                                      </div>

                                      <div className="quote-info--">
                                        <div>
                                          {" "}
                                          <div
                                            className=""
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>
                                              {" "}
                                              <span className="Quote-Title-message   ques-">
                                                {" "}
                                                Would you like to add message?
                                              </span>{" "}
                                            </div>

                                            <div style={{ display: "flex" }}>
                                              <div
                                                style={{ marginRight: "30px" }}
                                              >
                                                <input
                                                  type="radio"
                                                  value="show"
                                                  className="message_yes_response"
                                                  name="content"
                                                  onClick={handlerRadioChange}
                                                  checked={selected === "show"}
                                                />
                                                <label htmlFor="Yes">Yes</label>
                                              </div>

                                              <div>
                                                <input
                                                  type="radio"
                                                  value="hide"
                                                  className="message_yes_response"
                                                  name="content"
                                                  onClick={handlerRadioChange}
                                                  checked={selected === "hide"}
                                                />
                                                <label htmlFor="No_">No</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div></div>
                                      </div>

                                      {show === "show" ? (
                                        <div className="">
                                          <div className="">
                                            <textarea
                                              {...register1("msg")}
                                              rows="3"
                                              cols="20"
                                              className="quoteinputcontent-"
                                              required
                                            ></textarea>
                                          </div>
                                        </div>
                                      ) : null}

                                      <div className="enquiry-submit">
                                        <div className="">
                                          {submiting ? (
                                            <p
                                              style={{
                                                textAlign: "center",
                                                color: "green",
                                              }}
                                            >
                                              Submitting...
                                            </p>
                                          ) : (
                                            <div className="parent-submit-quote-enquiry-msg">
                                              <div>
                                                <button
                                                  type="submit"
                                                  className="btn-quote-form"
                                                  onClick={(e) => {
                                                    setShowpopup(!showpopup);
                                                    setshow("hide");
                                                    if (
                                                      ls.get("_nodi") === ""
                                                    ) {
                                                      setComponenttoshow(true);
                                                      setComponenttoshow2(
                                                        false
                                                      );
                                                    } else if (
                                                      ls.get("aCs_") !==
                                                      "active"
                                                    ) {
                                                      setshowAccountStatus(
                                                        !showAccountStatus
                                                      );
                                                      setComponenttoshow(false);
                                                      setComponenttoshow2(
                                                        false
                                                      );
                                                      setShowpopup2(false);
                                                      setShowpopup(false);
                                                    } else {
                                                      setShowpopup(!showpopup);
                                                      setComponenttoshow(false);
                                                      setComponenttoshow2(
                                                        false
                                                      );
                                                      setShowpopup2(false);
                                                    }

                                                    if (
                                                      ls.get("_nodi" !== "")
                                                    ) {
                                                      const decoded =
                                                        jwt_decode(
                                                          ls.get("_nodi")
                                                        );
                                                      const exp = decoded.exp;

                                                      if (
                                                        exp <
                                                        Date.now() / 1000
                                                      ) {
                                                        setComponenttoshow(
                                                          true
                                                        );
                                                      } else {
                                                        setComponenttoshow(
                                                          false
                                                        );
                                                      }
                                                    }

                                                    if (
                                                      newState === "inactive"
                                                    ) {
                                                      setshowAccountStatus(
                                                        true
                                                      );
                                                      setShowpopup2(false);
                                                      setComponenttoshow2(
                                                        false
                                                      );
                                                    }
                                                  }}
                                                >
                                                  Submit quote
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div className="provider-history-div">
                          <h2 className="provider-history">Provider History</h2>

                          <table className="table_  tabledelivry">
                            <tr>
                              <th>Quote amount</th>
                              <th>Logistic provider</th>
                              <th className="third-tr">Providers info</th>
                            </tr>
                            {view.quote_history?.map((detail, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    {" "}
                                    &#8358;
                                    {numberWithCommas.format(detail.quote_amount)}
                                  </td>
                                  <td>{detail.fulfiller_id}</td>
                                  <td>
                                    <button
                                      onClick={() => {
                                        history.push(
                                          `/open-delivery/` +
                                            viewData[0].delivery_id +
                                            "/" +
                                            detail.fulfiller_id
                                        );
                                        // setFulfillerDetailToShow(detail.provider_info);
                                        // viewFulfiller(detail.provider_info);
                                      }}
                                      className="profile-btn"
                                    >
                                      <span>View Profile</span>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div> */}

                          {view?.quote_history?.map((detail, index) => {
                            return (
                              <div className="quote-content-div">
                                <p>
                                  <b>Quote amount:</b> <Currency location={location?.state?.location}/>{" "}
                                  {numberWithCommas.format(detail.quote_amount)}{" "}
                                </p>
                                <p>
                                  <b>Logistic provider:</b>{" "}
                                  {detail.fulfiller_id}
                                </p>
                                <p>
                                  <b>Time:</b>
                                  {detail?.time_stamp}
                                </p>
                                <p>
                                  <b>Quote Status: </b>
                                  {detail?.quote_status === "rejected,"
                                    ? "Rejected"
                                    : detail?.quote_status
                                        ?.replaceAll("_", " ")
                                        ?.charAt(0)
                                        ?.toUpperCase() +
                                      detail?.quote_status
                                        ?.replaceAll("_", " ")
                                        ?.slice(1)}
                                </p>
                                <div className="flex-btn">
                                  <div></div>
                                  <div>
                                    <button
                                      onClick={() => {
                                        history.push(
                                          GlobalizationLanguagewithCOntryCode(`/open-delivery/${viewData[0].delivery_id}/${detail.fulfiller_id}`, country, visitingCountryCode)
                                        );
                                      }}
                                      className="profile-btn"
                                    >
                                      <span>View Profile</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          <div
                            className="main-father"
                            style={{ position: "relative", marginTop: "30px" }}
                          >
                            <header className="qoute-header enquiry-header">
                              <h4>Enquires from providers</h4>
                            </header>
                          </div>

                          {[...[], view?.inquiry_details]?.map((msg, i) => {
                            return (
                              <div className="Enquiry-div-message">
                                {msg?.message?.reverse()?.map((x) => (
                                  <div>
                                    <p>
                                      {x?.fulfiller_id && (
                                        <b>
                                          Provider:{" "}
                                          {moment(
                                            x?.fulfiller_id && x?.time_stamp
                                          ).format("L, h:mm a ")}
                                          {moment(x?.time_stamp).fromNow()}{" "}
                                        </b>
                                      )}
                                    </p>
                                    <p>{x?.fulfiller_id && x?.msg}</p>

                                    <p>
                                      {x?.user_id && (
                                        <b>
                                          Sender:
                                          {moment(
                                            x?.user_id && x?.time_stamp
                                          ).format("L, h:mm a ")}
                                          {moment(x?.time_stamp).fromNow()}{" "}
                                        </b>
                                      )}
                                    </p>

                                    <p>{x?.user_id && x?.msg}</p>
                                  </div>
                                ))}
                              </div>
                            );
                          })}

                          <div className="footerover-ride">
                            {/* <Footer /> */}
                          </div>
                        </div>

                        {showHelp ? (
                          <HomeHelp
                            showDialog={showHelp}
                            onClose={() => setshowHelp(false)}
                          />
                        ) : null}

                        {componenttoshow ? (
                          <DialogOutClick
                            open={showpopup}
                            handleClose={() => setShowpopup(!showpopup)}
                          >
                            <QuoteSignInForm
                              onFinish={(state) => {
                                setShowpopup(!showpopup);
                                setComponenttoshow2(true);
                                setComponenttoshow(false);
                                if (state === "inactive") {
                                  setshowAccountStatus(true);
                                  setComponenttoshow2(false);
                                }
                              }}
                              onCloseCorp={() => {
                                setShowpopup(!showpopup);
                                setComponenttoshow2(true);
                                setComponenttoshow(false);
                              }}
                              onClose={() => {
                                setShowpopup(!showpopup);
                              }}
                              handleClose={() => setShowpopup(!showpopup)}
                            />
                          </DialogOutClick>
                        ) : null}

                        {componenttoshow2 ? (
                          <DialogOutClick
                            open={showpopup2}
                            handleClose={() => setShowpopup2(!showpopup2)}
                          >
                            <EnquiryForm
                              item={view}
                              onFinish={(state) => {
                                setnewState(state);
                                if (state === "inactive") {
                                  setshowAccountStatus(true);
                                  setShowpopup2(false);
                                  setComponenttoshow2(false);
                                } else {
                                  setShowpopup2(!showpopup2);
                                }
                              }}
                              closePopup={() => setShowpopup2(false)}
                            />
                          </DialogOutClick>
                        ) : null}
                      </>
                    );
                  })
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "50px 0",
                      fontSize: "28px",
                      fontWeight: "600",
                      color: "var(--main)",
                      flexDirection: "column",
                    }}
                  >
                    {loadingData ? (
                      <PuffLoader
                        color="#50b14a"
                        loading={loadingData}
                        speedMultiplier={1}
                        size={100}
                      />
                    ) : (
                      <>
                        <span>
                          {" "}
                          <BiNotificationOff size="50px" />
                        </span>
                        <span>Item not found</span>
                      </>
                    )}
                  </div>
                )}
              </div>
              <Footer />
            </div>
          </div>
        </>
      )}
    </>
  );
}