import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SecureLS from "secure-ls";
import { AuthContext } from "../../../context/AuthContext";
import { DashboardContext } from "../../../context/Dashboard/DashboardProvider/dashboard.provider";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import axios from "axios";
import wallet3 from "../../../assets/wallet3.svg";
import walletminus from "../../../assets/walletminus.svg";
import emptywalletadd from "../../../assets/emptywalletadd.svg";
import DialogPop from "../../../components/DialogPop";
import moment from "moment";
import ClipLoader from 'react-spinners/ClipLoader';
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import CorporateLayout from "./CorporateLayout";
import MobileCorpLayout from "./CorporateComponents/MobileCorpLayout";
import DashBottomBtn from "./DashBottomBtn/DashBottomBtn";
import {
  Grid,
} from "@material-ui/core";
import Paystack_provider_withdraw from "../../../components/Paystack_provider_withdraw";
import FundWalletBankTransfer from "../../../components/MakePayment/FundWalletBankTransfer";

const CorpWallet = () => {
  const numberWithCommas = new Intl.NumberFormat("en-GB")

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [showaddfund, setshowaddfund] = useState(false)

  const [showwithdraw, setshowwithdraw] = useState(false);
  const [proceedwithdraw, setproceedwithdraw] = useState(false);

  const { dashboards, updateDashboard, update_balc_withdraw } = useContext(DashboardContext);
  const [ShowLogin, setShowLogin] = useState(false);
  const [loadingData, setloadingData] = useState(false);
  const [withdrawamt, setwithdrawamt] = useState("");
  const [isloading, setisloading] = useState(false);
  const [creditstate, setcreditstate] = useState(false);
  const [allstatet, setallstatet] = useState("all");
  const [bankDetails, setbankDetails] = useState({});
  const [addamt, setaddamt] = useState("");

  const [walletHistory, setwalletHistory] = useState([]);
  const [walletBalc, setwalletBalc] = useState(0);
  const [refreshBalance, setrefreshBalance] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  const endpoint = process.env.REACT_APP_API;

  const withdrawamount = (e) => {
    e.preventDefault();
    setproceedwithdraw(true);
    setshowwithdraw(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setshowwithdraw(false);
  };


  const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers: {
      Authorization: ls.get("_nodi"),
    },
  });

  const [showTranferFromWallet, setShowTranferFromWallet] = useState(false)
  const [payment_method, setpayment_method] = useState('pay_by_transfer')

  const Withdrawalfunction = (e) => {
    e.preventDefault()
    setisloading(true);
    authAxios
      .post(`m-dillivry_instant-payment`, {
        transaction_type: "debit",
        amount: withdrawamt,
      })
      .then((res) => {
        setisloading(false);
        setproceedwithdraw(false);
        Swal.fire({
          icon: "success",
          text: `Your withdrawal of ${numberWithCommas.format(withdrawamt)} was successful`,
          showConfirmButton: true,
          confirmButtonColor: "#50B14A",
          confirmButtonText: "Continue",
          timer: 2000
        }).then(() => {
          setrefreshBalance(!refreshBalance)
        })

      })
      .catch((err) => {
        // console.log(err.response);
        setisloading(false);
        setproceedwithdraw(false);
        Swal.fire({
          icon: "error",
          text: `${err?.response?.statusText
              ? err?.response?.statusText
              : "Withdrawal failed. Try again"
            }`,
          // text: err?.response?.statusText !== '' ? `Connection timeout. Please try again` : "Error occured",
          confirmButtonColor: "#50B14A",
          confirmButtonText: "Continue"
        });
        setisloading(false);
      });
  };


  const handleChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setaddamt(e.target.value)
    }
  }

  const handleChangewithdrawamt = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setwithdrawamt(e.target.value)
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      ls.get("_iRl") === "" ||
      ls.get("_iRl") === "dillivry-sole-trader-ops" ||
      ls.get("_nodi") === ""
    ) {
      // setShowLogin(true);
      history.push('/login')
      auth.logout();
    } else {
        const decoded = jwt_decode(ls.get("_nodi"));
        const expired = decoded.exp;
        if (expired < Date.now() / 1000) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              auth.logout();
              // setShowLogin(true);
              history.push('/login')
            }
          });
        } else {
          setloadingData(true);
          axios
            .get(`${endpoint}m-dillivry-dashboard-manager`, {
              headers: {
                Authorization: ls.get("_nodi"),
              },
            })
            .then((response) => {
              // console.log(response, "response")
              ls.set(
                "means_of_delivery",
                JSON.stringify(
                  response?.data?.account_profile?.account_profile[0]
                    ?.mode_of_delivery
                )
              );
              setloadingData(false);
              updateDashboard(response);
              // console.log(response, "dashboards")
              setwalletHistory(response?.data?.wallet_history)
              // console.log(response?.data?.wallet_history, "walletbalance")
              setwalletBalc(response?.data?.account_profile?.account_profile[0]
                ?.wallet_balance)

              setbankDetails(response?.data?.account_profile?.account_profile[0]?.bank_detail);
            })
            .catch((error) => {
              if (error?.response?.status === 403) {
                Swal.fire({
                  icon: "warning",
                  title: "Session!",
                  text: "Session expired login again!",
                  allowOutsideClick: false,
                  confirmButtonColor: "var(--main)",
                }).then((result) => {
                  if (result.value) {
                    auth.logout();
                    // setShowLogin(true);
                    history.push('/login')
                  }
                });
              } else {
                auth.logout();
                // setShowLogin(true);
                history.push('/login')
              }
              setloadingData(false);
            });
        }
    }
  }, [refreshBalance]);

  const SearchBar = () => {
    return (
      <div className="search">
        <div className="search-input-navbar">
          <input
            type="text"
            value=""
            // autoFocus
            placeholder="search.."
            onChange=""
          />
          {/* <span className="searchx-icon"><MdSearch style={{color:"#989898"}}/></span> */}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="deskstopcorp_con">
        <CorporateLayout>
          <div>
            {/* <SearchBar/> */}
            {ShowLogin ? (
              history.push("/login")

            ) : (
              <div className="dashboard">
                {loadingData ? (
                  <div
                    style={{
                      width: "100%",
                      height: "50vh",
                      backgroundColor: "none",
                      zIndex: "300",
                      position: "fixed",
                      top: "0",
                      paddingTop: "70px",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress color="success" />
                    </Box>
                  </div>
                ) : null}
                <div className="dashboardDesktop">
                  {/* <div className="topmenu">
              <TopBar />
            </div> */}
                  {/* <div className="content-view_menu">

              <div className="tab" onClick={() => history.push("/dashboard")}>
                <AiTwotoneShop /> &nbsp; Dillivry Activities
              </div>
              <div className="activeTabs">
                <BiWallet /> &nbsp; Wallet
              </div>
              <div onClick={() => history.push("/messages")} className="tab">
                <TiMessages /> &nbsp; Messages
              </div>
              <div
                className="tab"
                onClick={() => history.push("/deliveries_history")}
              >
                <BiTimer /> &nbsp; Quote history
              </div>
              <div
                className="tab"
                onClick={() => history.push("/account/transaction")}
              >
                <RiAccountPinCircleLine /> &nbsp; Account
              </div>
            </div> */}
                </div>
                {/* <div className="dashboardMobile">
            <TopBar />
          </div> */}

                <div className="wallet_home" style={{ marginTop: '20px' }}>
                  <div className="wallet_ballance">
                    <div className="wallet_balance_and_amount">
                      <div>
                        {" "}
                        <p className="waleet_title" style={{ width: "100%" }}>Wallet balance</p>
                      </div>
                      <div>
                        {
                          showIcon ?
                            <ClipLoader color='white' loading={showIcon} speedMultiplier={1} size={32} />
                            :



                            walletBalc === 0 || walletBalc === undefined || walletBalc === NaN ?

                              <h1 className="wallet_amt">
                                &#8358;0.00
                              </h1> :

                              <h1 className="wallet_amt">
                                {/* {
                        console.log(walletBalc)
                      } */}
                                &#8358;
                                {numberWithCommas.format(parseFloat(walletBalc)?.toFixed(2))}
                              </h1>
                        }

                      </div>
                    </div>

                    <div className="wallet_icon">
                      <img
                        src={wallet3}
                        alt="walleticon"
                        className="wallet_icn_img"
                      />
                    </div>
                  </div>
                </div>

                <div className="fwsettings">
                  <div className="fund-withdraw-settings">
                    <div onClick={() => {
                      setshowaddfund(true);
                    }}
                      className="addfund"
                      style={{
                        cursor: "pointer",
                      }}>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <img src={emptywalletadd} alt="" />
                      </div>
                      <div>
                        {" "}
                        <span>Add fund</span>
                      </div>
                    </div>

                    <div
                      className="withdraw"
                      onClick={() => {
                        setshowwithdraw(true);
                      }}

                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center"
                        }}
                      >
                        {" "}
                        <img src={walletminus} alt="" />
                      </div>
                      <div>
                        {" "}
                        <span>Withdraw</span>
                      </div>
                    </div>

                    {/* <div className="settings">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor:"pointer"
                  }}
                  onClick={()=>setshowsettings(!showsettings)}
                >
                  {" "}
                  <img src={setting2} alt="" />
                </div>
                <div>
                  {" "}
                  <span>Settings</span>
                </div>
              </div> */}
                  </div>
                </div>

                <>
                  <div className="wallet-history">
                    <div>
                      {" "}
                      <h2>Wallet History</h2>
                    </div>
                  </div>

                  <div className="allcreditdebit">
                    <div className="alldiv">
                      <div style={{ backgroundColor: allstatet === "all" ? "#E0FCE7" : "white" }} className="all" onClick={() => setallstatet("all")}>
                        <span>All</span>
                      </div>

                      <div
                        style={{ backgroundColor: allstatet === "credit" ? "#E0FCE7" : "white" }}
                        className="credits"
                        onClick={() => {
                          setcreditstate(true);
                          setallstatet("credit");
                        }}
                      >
                        <span>Credits</span>
                      </div>

                      <div style={{ backgroundColor: allstatet === "debit" ? "#E0FCE7" : "white" }} onClick={() => setallstatet("debit")} className="debits">
                        <span>Debits</span>
                      </div>
                    </div>
                  </div>

                  {allstatet === "all" ? (
                    <div>
                      <div
                        className={`${allstatet === "all"
                          ? "transaction-history"
                          : "transaction-history"
                          }`}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {walletHistory.sort((x, y) => new Date(y.timestamp) - new Date(x.timestamp))
                          ?.slice(0, 10)
                          ?.map((x, i) => {
                            return (
                              <div key={i} className="transdetails">
                                <div className="transtitleanddate">
                                  {x.transaction_type === "credit" || x.transaction_type === "wallet_credit" ? (
                                    <h1 className="transtitle">
                                      Payment made.
                                    </h1>
                                  ) : (
                                    <h1 className="transtitle">
                                      Withdrawal made
                                    </h1>
                                  )}
                                  <span className="transdate">
                                    {moment(
                                      x.timestamp.slice(0, 10)
                                    ).format("DD-MM-YY")}{" "}
                                    &nbsp; {x.timestamp.slice(12, 19)}
                                  </span>
                                </div>

                                <div className="transamount transamtdebit  ">
                                  <h1
                                    className={`${x.transaction_type === "wallet_credit" || x.transaction_type === "credit"
                                      ? "transamt credit   "
                                      : "transamtno  debit"
                                      }`}
                                  >
                                    &#8358;
                                    {`${x.transaction_type === "debit"
                                      ? numberWithCommas.format(x.debit_amount)
                                      : numberWithCommas.format(x.credit_amount)
                                      }`}
                                  </h1>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ) : allstatet === "credit" ? (
                    <div>
                      {walletHistory?.filter((x) => (x.transaction_type === "credit" || x.transaction_type === "wallet_credit"))
                        ?.sort((x, y) => new Date(y.timestamp) - new Date(x.timestamp)
                        ).slice(0, 10)
                        ?.map((x, i) => {
                          return (
                            <div key={i} className="transaction-history-  credittrans  ">
                              <div className="transdetails  credittransinner  ">
                                <div>
                                  <h2 className="user_id">
                                    {" "}
                                    Payment made.
                                  </h2>

                                  <span className="transdate">
                                    {moment(
                                      x.timestamp.slice(0, 10)
                                    ).format("DD-MM-YY")}{" "}
                                    &nbsp; {x.timestamp.slice(12, 19)}
                                  </span>
                                </div>
                                {/* {console.log(x)} */}
                                <div>
                                  <span className="transamt">
                                    &#8358;{numberWithCommas.format(x.credit_amount)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <div>
                      {walletHistory?.filter((x) => x.transaction_type === "debit")
                        ?.sort((x, y) => new Date(y.timestamp) - new Date(x.timestamp)
                        ).slice(0, 10)?.map((x, i) => {
                          return (
                            <div key={i} className="transaction-history- debittrans">
                              <div className="transdetails debittransinner  ">
                                <div>
                                  {x.transaction_type === "debit" &&
                                    x.debit_amount ? (
                                    <h2 className="user_id">
                                      {" "}
                                      Withdrawal made
                                    </h2>
                                  ) : null}

                                  {x.transaction_type === "debit" &&
                                    x.debit_amount ? (
                                    <span className="transdate">
                                      {moment(
                                        x.timestamp.slice(0, 10)
                                      ).format("DD-MM-YY")}{" "}
                                      &nbsp; {x.timestamp.slice(12, 19)}
                                    </span>
                                  ) : null}
                                </div>
                                <div>
                                  {x.transaction_type === "debit" &&
                                    x.debit_amount ? (
                                    <span className="transamtno">
                                      &#8358;{numberWithCommas.format(x.debit_amount)}
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}

                </>




              </div>
            )}


          </div>

        </CorporateLayout>

      </div>


      <div className="mobilecorp_con">
        <MobileCorpLayout pageTitle="Wallet" />
        <div >
          {ShowLogin ? (
            history.push('/login')
          ) : (
            <div className="mobilecorp_gen">
              {loadingData ? (
                <div
                  style={{
                    width: "100%",
                    height: "50vh",
                    backgroundColor: "none",
                    zIndex: "300",
                    position: "fixed",
                    top: "0",
                    paddingTop: "80px",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <LinearProgress color="success" />
                  </Box>
                </div>
              ) : null}
              <div className="dashboardDesktop">
                {/* <div className="topmenu">
              <TopBar />
            </div> */}
                {/* <div className="content-view_menu">

              <div className="tab" onClick={() => history.push("/dashboard")}>
                <AiTwotoneShop /> &nbsp; Dillivry Activities
              </div>
              <div className="activeTabs">
                <BiWallet /> &nbsp; Wallet
              </div>
              <div onClick={() => history.push("/messages")} className="tab">
                <TiMessages /> &nbsp; Messages
              </div>
              <div
                className="tab"
                onClick={() => history.push("/deliveries_history")}
              >
                <BiTimer /> &nbsp; Quote history
              </div>
              <div
                className="tab"
                onClick={() => history.push("/account/transaction")}
              >
                <RiAccountPinCircleLine /> &nbsp; Account
              </div>
            </div> */}
              </div>
              {/* <div className="dashboardMobile">
            <TopBar />
          </div> */}

              <div className="wallet_home" style={{ marginTop: '20px' }}>
                <div className="wallet_ballance">
                  <div className="wallet_balance_and_amount">
                    <div>
                      {" "}
                      <p className="waleet_title" style={{ width: "100%" }}>Wallet balance</p>
                    </div>
                    <div>
                      {
                        showIcon ?
                          <ClipLoader color='white' loading={showIcon} speedMultiplier={1} size={32} />
                          :
                          walletBalc === 0 || walletBalc === undefined || walletBalc === NaN ?

                            <h1 className="wallet_amt">
                              &#8358;0.00
                            </h1> :

                            <h1 className="wallet_amt">
                              {/* {
                        console.log(walletBalc)
                      } */}
                              &#8358;
                              {numberWithCommas.format(parseFloat(walletBalc)?.toFixed(2))}
                            </h1>
                      }

                    </div>
                  </div>

                  <div className="wallet_icon">
                    <img
                      src={wallet3}
                      alt="walleticon"
                      className="wallet_icn_img"
                    />
                  </div>
                </div>
              </div>

              <div className="fwsettings">
                <div className="fund-withdraw-settings">
                  <div onClick={() => {
                    setshowaddfund(true);
                  }} className="addfund">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <img src={emptywalletadd} alt="" />
                    </div>
                    <div>
                      {" "}
                      <span>Add fund</span>
                    </div>
                  </div>

                  <div
                    className="withdraw"
                    onClick={() => {
                      setshowwithdraw(true);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      <img src={walletminus} alt="" />
                    </div>
                    <div>
                      {" "}
                      <span>Withdraw</span>
                    </div>
                  </div>

                  {/* <div className="settings">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={()=>setshowsettings(!showsettings)}
                >
                  {" "}
                  <img src={setting2} alt="" />
                </div>
                <div>
                  {" "}
                  <span>Settings</span>
                </div>
              </div> */}
                </div>
              </div>

              <div className="wallet-history">
                <div>
                  {" "}
                  <h2>Wallet History</h2>
                </div>
              </div>

              <div className="allcreditdebit">
                <div className="alldiv">
                  <div style={{ backgroundColor: allstatet === "all" ? "#E0FCE7" : "white" }} className="all" onClick={() => setallstatet("all")}>
                    <span>All</span>
                  </div>

                  <div
                    style={{ backgroundColor: allstatet === "credit" ? "#E0FCE7" : "white" }}
                    className="credits"
                    onClick={() => {
                      setcreditstate(true);
                      setallstatet("credit");
                    }}
                  >
                    <span>Credits</span>
                  </div>

                  <div style={{ backgroundColor: allstatet === "debit" ? "#E0FCE7" : "white" }} onClick={() => setallstatet("debit")} className="debits">
                    <span>Debits</span>
                  </div>
                </div>
              </div>

              {allstatet === "all" ? (
                <div>
                  <div
                    className={`${allstatet === "all"
                      ? "transaction-history"
                      : "transaction-history"
                      }`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {walletHistory?.map((x, i) => {
                      return (
                        <div key={i} className="transdetails">
                          <div className="transtitleanddate">
                            {x.transaction_type === "debit" ? (
                              <h1 className="transtitle">Withdrawal made</h1>
                            ) : (
                              <h1 className="transtitle">
                                Payment made.
                              </h1>
                            )}
                            <span className="transdate">{moment(x.timestamp.slice(0, 10)).format('DD-MM-YY')} &nbsp;  {x.timestamp.slice(12, 19)}</span>
                          </div>

                          <div className="transamount transamtdebit  ">
                            <h1
                              className={`${x.transaction_type === "credit"
                                ? "transamt credit   "
                                : "transamtno  debit"
                                }`}
                            >
                              &#8358;{`${x.transaction_type === "debit"
                                ? numberWithCommas.format(x.debit_amount)
                                : numberWithCommas.format(x.credit_amount)
                                }`}
                            </h1>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) :
                allstatet === "credit" ?
                  (
                    <div>

                      {
                        walletHistory?.map((x, i) => {
                          return (
                            <div className="transaction-history-  credittrans  " >

                              <div className="transdetails  credittransinner  ">
                                <div>{
                                  x.transaction_type === "credit" ? <h2 className="user_id" > Payment made.</h2> : null
                                }

                                  {
                                    x.transaction_type === "credit" ? <span className="transdate">{moment(x.timestamp.slice(0, 10)).format('DD-MM-YY')} &nbsp;  {x.timestamp.slice(12, 19)}</span> : null
                                  }

                                </div>


                                <div>
                                  {
                                    x.transaction_type === "credit" ? <span className="transamt" >&#8358;{numberWithCommas.format(x.credit_amount)}</span> : null
                                  }
                                </div>

                              </div>

                            </div>
                          )
                        })



                      }
                    </div>

                  ) :
                  <div>

                    {
                      walletHistory?.map((x, i) => {
                        return (
                          <div className="transaction-history- debittrans" >

                            <div className="transdetails debittransinner  ">
                              <div>{
                                x.transaction_type === "debit" && x.debit_amount ? <h2 className="user_id" > Withdrawal made</h2> : null
                              }

                                {
                                  x.transaction_type === "debit" && x.debit_amount ? <span className="transdate">{moment(x.timestamp.slice(0, 10)).format('DD-MM-YY')} &nbsp;  {x.timestamp.slice(12, 19)}</span> : null
                                }

                              </div>
                              <div>
                                {
                                  x.transaction_type === "debit" && x.debit_amount ? <span className="transamtno" >&#8358;{numberWithCommas.format(x.debit_amount)}</span> : null
                                }
                              </div>

                            </div>

                          </div>
                        )
                      })



                    }
                  </div>

              }


            </div>
          )}
        </div>
        <DashBottomBtn activeTab="wallet" />
      </div>

      <DialogPop
        openPopup={showaddfund}
        setopenPopup={setshowaddfund}
        head="Add fund"
      >
        <div className="withdrawform">
          <form onSubmit={withdrawamount} action="">
            <div className="form-div">

              <input className='withdrawinput' autoFocus style={{ outline: "none" }} value={addamt} type="text" placeholder='Enter amount to add' onChange={(e) => handleChange(e)} />
              <div
                style={{
                  color: "red",
                  marginTop: "0px",
                  marginBottom: "10px",
                  fontSize: "13px",
                  textDecoration: "italic",
                }}
              >
                {parseInt(addamt) < 100 ? (
                  <>You can only add from &#8358;100 and above</>
                ) : null}
              </div>

              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <select className='select_mode' value={payment_method} onChange={(e) => setpayment_method(e.target.value)} id="cars">
                    <option value="pay_by_transfer">Pay by transfer</option>
                    <option value="pay_by_card">Pay with paystack</option>
                    {/* <option value="pay_by_ussd">USSD</option> */}
                  </select>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "4px",
                    }}
                  >
                    {

                      payment_method === "pay_by_transfer" ?
                        addamt === '' || parseInt(addamt) < 100 ?
                          <div className="paystack_btn_disable" >
                            Proceed
                          </div>
                          :
                          <div className="paystack_btn"
                            onClick={() => { setShowTranferFromWallet(true) }}
                          >
                            Proceed
                          </div>

                        :
                        <Paystack_provider_withdraw
                          amount={addamt + "00"} //for paystack
                          amount_paid={addamt}
                          email={ls.get("eid")}
                          closePopup={() => setshowaddfund(false)}
                          onComplete={()=>{
                            setrefreshBalance(!refreshBalance); 
                            setshowaddfund(false)
                          }}
                        />
                    }
                  </div>
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </DialogPop>

      <DialogPop
        openPopup={showwithdraw}
        setopenPopup={setshowwithdraw}
        head="Withdrawal"
      >
        <div>
          <div className="withdrwalbody">
            <div>
              <h1 className="withdrawtag">
                Withdrawable: &nbsp; &nbsp; &nbsp;{" "}
              </h1>
            </div>

            <div>
              <span className="withdawspan">
                &#8358;{walletBalc === 0 || walletBalc === undefined || walletBalc === isNaN ? "0.00" : numberWithCommas.format(parseFloat(walletBalc).toFixed(2))}
              </span>
            </div>
          </div>

          <div className="withdrawform">
            <form onSubmit={withdrawamount} action="">
              <div className="form-div">
                <input className='withdrawinput' autoFocus style={{ outline: "none" }} value={withdrawamt} type="text" placeholder='Enter amount to add' onChange={(e) => handleChangewithdrawamt(e)} />

                <div style={{ color: 'red', marginTop: '5px', fontSize: '13px', textDecoration: 'italic' }}>
                  {
                    parseInt(withdrawamt) < 100 ?
                      <>You can only withdraw from &#8358;100 and above</>
                      :
                      parseFloat(withdrawamt) > parseFloat(walletBalc) ?
                        <>Amount cannot be more than your withdrawable</>
                        : null
                  }
                </div>
                <div className="cancelandproceed">
                  <div onClick={handleCancel} className="cancel">
                    Cancel
                  </div>

                  {
                    withdrawamt === "" ?
                      <button className="proceed" disabled>
                        Proceed
                      </button>
                      :
                      parseInt(withdrawamt) < 100 ?
                        <button className="proceed" disabled>
                          Proceed
                        </button>
                        :
                        parseFloat(withdrawamt) > parseFloat(walletBalc) || walletBalc === undefined || walletBalc === isNaN ?

                          <button className="proceed" disabled>
                            Proceed
                          </button>
                          :
                          <button className="proceedplus" type="submit">
                            <span>Proceed</span>
                          </button>
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </DialogPop>

      <DialogPop
        openPopup={proceedwithdraw}
        setopenPopup={setproceedwithdraw}
        centerhead="Withdrawal"
      >
        <div>
          <h1 className="withdrawinner">
            Kindly confirm you are about to withdraw the sum of &#8358;{withdrawamt} from your wallet
          </h1>

          <div className="yesandnobtn">
            <button onClick={() => setproceedwithdraw(false)} className="nobtn">
              No
            </button>

            <button onClick={Withdrawalfunction} className="yesbtn">
              {/* {isloading ? "loading..." : "Yes"} */}
              {isloading ? <ClipLoader color='white' loading={isloading} speedMultiplier={1} size={32} /> : "Yes"}
            </button>
          </div>
        </div>
      </DialogPop>

      <DialogPop
        openPopup={showTranferFromWallet}
        setopenPopup={setShowTranferFromWallet}
        head="Fund your wallet"
      >
        <div className="popContainer_payment">
          <FundWalletBankTransfer 
            amount={addamt} 
            onSuccess={()=>{
              setrefreshBalance(!refreshBalance); 
              setShowTranferFromWallet(false)
              setshowaddfund(false)
            }}
            onError={()=>{
              setShowTranferFromWallet(false);
              setshowaddfund(false);
            }}
          />
        </div>
      </DialogPop>



    </>


  );
};

export default CorpWallet;
