import { useEffect, useState, useRef, useContext } from "react";
import { IoCheckbox } from "react-icons/io5";
import "./ViewFulfiler2.css";
import "./ViewFulfiller.css";
import "./ViewDelivry2.css";
import "../../API_Page/Apipage.css"
import "../../SearchDilivry2/Table.css"
import "../../SearchDeliveries/NewSearch.css"
import feedback_logo from "../../../assets/feedback_logo.svg";
import Footer from "../../../components/Footer/Footer";
import Reviews from "../../../assets/Reviews.svg";
import ReactPaginate from "react-paginate";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { useHistory, useLocation} from "react-router-dom";
import Nav from "../../../components/Nav/Nav";
import IndividualSidebar from "../../../components/Sidebar/IndividualSidebar";
import imagelogo from "../../../assets/Dillivry.png";
import { SearchContext } from '../../../context/SearchProvider/search.provider';
import {useParams}  from 'react-router-dom'
import {Grid} from "@material-ui/core"
import { BiArrowBack } from "react-icons/bi";
import SecureLS from "secure-ls";
import axios from "axios";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import {FcCancel} from 'react-icons/fc'
import { ConnContext } from "../../../context/ConnContext";
import TopBar from "../../../components/TopBar/TopBar";
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
import Cookies from "js-cookie";
export default function ViewFulfilerNew() {
  var timeout = GlobalizationLanguage("/timeout")
  var deliver_with_us = GlobalizationLanguage("/create-account")

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const {delivery_id, fulfiller_id} = useParams();
  const [loading, setloading] = useState(false)
  const Authorization = ls.get("_nodi") === "" ? "" : ls.get("_nodi");
  const {visitingCountryCode} = useContext(ConnContext)
  var countryvalue =
  Cookies.get("i18next") || visitingCountryCode.toLowerCase();
const [country, setCountry] = useState(countryvalue);

useEffect(() => {
  setCountry(Cookies.get("i18next"));
}, [Cookies.get("i18next")]);
  
  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: Authorization,
    },
  });

  function reverseArr(input) {
    var ret = new Array();
    for (var i = input?.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }
  
  const joiner = (data)=>{
    const newarrrr = data.split("-");
     const merge = newarrrr[0].concat(newarrrr[1],newarrrr[2])
     const newarrr = merge.split(" ");
     const merged = newarrr[0].concat(newarrr[1])
     const newarrrf = merged.split(":");
     const mergedd = newarrrf[0].concat(newarrrf[1],newarrrf[2])
     return(mergedd)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
      setloading(true)
      authAxios
      .get(`${process.env.REACT_APP_API}m-dillivry-search?vcc=${country.toLowerCase()}&attribute=feedback_page&provider_id=${fulfiller_id}`, 
       {timeout: 240000}).then((response) => {
        setloading(false)
      setdetails(response.data);
      }).catch((err)=>{
        if (err.code === "ECONNABORTED") {
          console.log('internal-server-error-timeout')
          history.push(timeout)
        }
      })
  }, []); 

  const history = useHistory();

  const [details, setdetails] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [lastshowvalue, setLastShowValue] = useState(4);
  const [PER_PAGE, setPERPAGE] = useState(lastshowvalue);
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(details?.length / PER_PAGE);
  const handleLastShowValue = (e) => {
    let { name, value } = e.target;
    // console.log(Number(value));
    // console.log(setPERPAGE(Number(value)));
  };
  const location = useLocation();



  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
   };

  return (
    <>
    {
      loading ?
        <div style={{ width: '100%', height: '100vh', backgroundColor: 'none', zIndex: '300', position: 'fixed', top: '0', paddingTop: '5px' }}>
            <Box sx={{ width: '100%' }}>
                <LinearProgress color="success" />
            </Box>
        </div>
        : null
    }
    <div className="deskShow">
      <div className="header-body-">
       <TopBar/>
      </div>
      <div className="button-back-to-search">
        {" "}
        <button className="back-to-search" onClick={()=>history.goBack()}>
          <span className="back-tosearch-content"  style={{cursor: "pointer", userSelect:'none'}}>Back </span>
        </button>
      </div>

      <div className="htnlmk">
        <div className="fulfilername-body">
          <p>
            {details?.account_name}({details?.completed_jobs})
          </p>
        </div>
      </div>

      <div className="fulfiler-main">
        <div className="top-flex">
          <div className="fulfiler-inner-content">
            <div className="image-feedback-details">
              <div className="image-provider">
                <img
                  src={details?.cop_logo}
                  alt="Provider-Profile"
                />
              </div>
              <div className="checkbox-under-image">
                <div className="verified">
                  <IoCheckbox color="var(--main)" />{" "}
                  <span style={{ fontSize: "13px", fontWeight: "600" }}>
                    Verified
                  </span>
                </div>
                <div>
                {
                  details?.good_in_transit_insurance?.insurance_coverage?.length > 4  ?
                  <div className="verified">
                      <IoCheckbox color='var(--main)'/> <span style={{fontSize:'13px', fontWeight:'600'}}>Insurance</span>
                  </div>
                  :
                  <div className="verified">
                      <FcCancel/> <span style={{fontSize:'13px', fontWeight:'600'}}>Insurance</span>
                  </div>
                }
                </div>
              </div>
            </div>

            <div className="feedback-percentage">
              <div className="fxflex">
                <div className="iner-flex">
                  <div>
                    <p className="paragraph-feedback">Positive feedback: </p>
                  </div>
                  <div>
                    <span className="span-content">
                      {details?.Positive_feedback}
                    </span>
                  </div>
                </div>

                <div className="iner-flex">
                  <div>
                    <p className="paragraph-feedback">Completed Jobs: </p>
                  </div>
                  <div>
                    <span className="span-content">
                      ({details?.completed_jobs})
                    </span>
                  </div>
                </div>

                <div className="iner-flex">
                  <div>
                    <p className="paragraph-feedback">Rejected Jobs: </p>
                  </div>
                  <div>
                    <span className="span-content">
                      ({details?.rejected_jobs})
                    </span>
                  </div>
                </div>
                <div className="iner-flex">
                 
                  <div>
                    <p className="paragraph-feedback">Mode of deliveries: </p>
                  </div>
                
                  {
                    details?.mode_of_delivery?.map((x,i)=> 

                      <div key={i}>
                      <span className="span-content" >
                        {x }
                      </span>
                    </div>

                    )
                  }
                 
                </div>

                <div className="iner-flex">
                  <div>
                    <p className="paragraph-feedback">Insurance coverage:</p>
                  </div>
                  <div>
                    <span className="span-content">
                     
                      {details?.good_in_transit_insurance?.insurance_coverage ?   details?.good_in_transit_insurance?.insurance_coverage : 'None' }
                    </span>
                  </div>
                </div>
                <div className="iner-flex">
                  <div>
                    <p className="paragraph-feedback">Profile message: </p>
                  </div>
                  <div>
                    <span className="span-content">
                      {details?.profile_message?.msg_profile}
                    </span>
                  </div>
                </div>


              </div>
              
            </div>
          </div>

          <div className="feedback-table">
            <div
              className="logo-feedback"
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <img src={feedback_logo} alt="feedback_logo" />
              <p className="feedback-logo">Feedback</p>
            </div>

            <div className="feedbacktable-div">
              <table className="feedbacks-table">
                <tr className="feedback-trow">
                  <th></th>
                  <th>1 month</th>
                  <th>6 month</th>
                  <th>12 month</th>
                </tr>

                <tr className="positive-feedback">
                  <td>Positive</td>
                  <td>{details?.Feedback?.positive.one_month}</td>
                  <td>{details?.Feedback?.positive.six_month}</td>
                  <td>{details?.Feedback?.positive.telv_month}</td>
                </tr>
                <tr>
                  <td>Negative</td>
                  <td>{details?.Feedback?.negative.one_month}</td>
                  <td>{details?.Feedback?.negative.six_month}</td>
                  <td>{details?.Feedback?.negative.telv_month}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div className="provider-history-div" style={{marginTop :'0px'}}>
          <h2 className="provider-history">
            {" "}
            <img src={Reviews} alt="Reviews-icons" /> Reviews 
          </h2>

          <table className="table_  table-tr-th   table-fulfiler">
            <thead>
            <tr>
              <th>Rating</th>
              <th>Item</th>
              <th>Left by</th>
              <th>Comments</th>
              <th>Date</th>
            </tr>
            </thead>

            {/* {
              console.log(details, 'detailssss')
            } */}

           {details?.reviews?.map((i, d) => {
             
              return (
                <tr>
                  {/* {
                    console.log(i, "iiiiiii", )
                  } */}
                  <td>
                    {i.rating === "NotGreat" ? (
                      <FaThumbsDown color="red" />
                    ) : (
                      <FaThumbsUp color="var(--main)" />
                    )}
                  </td>
                  {/* {
                    console.log(details, 'iii')
                  } */}

                  <td>{i.item_desc ? i.item_desc?.charAt(0)
                                          ?.toUpperCase() + i.item_desc?.slice(1)  : null}</td>
                  <td>
                    {i.sender_name} ({i.count}){" "}
                  </td>
                  <td>{i.additional_comments}</td>
                  {/* {
                    console.log(i, 'reviews')
                  } */}
                  <td>
                    {/* {
                      i.time_stamp
                    } */}
                   {i.time_stamp.substr(8, 2)}/{i.time_stamp.substr(5, 2)}/{i.time_stamp.substr(0, 4)} 
                    {/* {details?.account_status?.joined_date.substr(8, 2)}/
                    {details?.account_status?.joined_date.substr(5, 2)}/
                    {details?.account_status?.joined_date.substr(0, 4)} */}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>

        {
          <div className="bottom-search-viewfulfiler">
            <div>
              <div className="bottom-search">
                <div className="left-entries">
                  <p className="showing-entries">
                    Showing {PER_PAGE * currentPage + 1} to{" "}
                    {(currentPage + 1) * PER_PAGE > details?.length
                      ? details?.length
                      : (currentPage + 1) * PER_PAGE}{" "}
                    of &nbsp;
                    {details?.length} entries
                  </p>
                </div>
                <div className="right-search">
                  <div className="right-inner">
                    <div className="right-inner-search">
                      <p className="search-value">Search:</p>
                    </div>
                    <div className="right-input">
                      <input type="text" className="right-input-field" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="pagination">
                <div className="left-pagination">
                  <div className="left-pagination-show">
                    <p className="pagination-show">Show</p>
                  </div>
                  <div className="left-pagination-input">
                    <select
                      name=""
                      id=""
                      className="pagination-input"
                      onChange={handleLastShowValue}
                      value="15"
                    >
                      <option value="5">5</option>
                      <option value="15">20</option>
                      <option value="20">15</option>
                    </select>
                  </div>
                  <div className="left-pagination-entries">
                    <p className="pagination-entries">entries</p>
                  </div>
                </div>

                <div className="">
                  <div className="">
                    <ReactPaginate
                      previousLabel={"First"}
                      nextLabel={"Last"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={4}
                      pageRangeDisplayed={6}
                      onPageChange={handlePageClick}
                      containerClassName={"new-pagination"}
                      pageClassName={"pagination-one"}
                      pageLinkClassName={""}
                      previousClassName={"first-pagination"}
                      nextClassName={"last-pagination"}
                      nextLinkClassName={""}
                      breakClassName={"pagination-one--"}
                      breakLinkClassName={"pagination-one--"}
                      activeClassName={"active-"}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="new-pagination"></div>
            
          </div>
        }
      </div>

      <div className="a-fulfuler">
        <div className="a1"></div>
        <div className="a2">
          <h2 className="logicstics-content">Are you a logistics provider?</h2>
        </div>
        
        <div className="a3">
          <button
            onClick={() => history.push(deliver_with_us)}
            className="signup-btn"
          >
            <span className="signup-btn-">Signup</span>
          </button>
        </div>
      </div> 

      <Footer />
    </div>
    <div className="mobileShow">
    <div className="fulfillerView">
            <div className="topmenu">
              <TopBar/>
            </div>

            
            <div  style={{marginTop:"80px"}}>
                <div onClick={
                  history.goBack
                } style={{paddingLeft:"20px"}}>
                    <BiArrowBack size='25px'/>
                </div>
            </div>

            <div className="fulfillertable">
                <div className="tableTitle">
                    {details?.account_name}
                </div>
                <div className="tableBody">
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                          
                            <div className='img'
                                style={{ backgroundImage:`url(${details?.cop_logo})`}}
                                >
                            </div>
                            <div className="verified">
                                <IoCheckbox color='var(--main)' /> <span style={{fontSize:'13px', fontWeight:'600'}}>Verified</span>
                            </div>
                            {
                                details?.good_in_transit_insurance?.insurance_coverage?.length > 4  ?
                                <div className="verified">
                                    <IoCheckbox color='var(--main)'/> <span style={{fontSize:'13px', fontWeight:'600'}}>Insurance</span>
                                </div>
                                :
                                <div className="verified">
                                    <FcCancel/> <span style={{fontSize:'13px', fontWeight:'600'}}>Insurance</span>
                                </div>
                              }
                            
                        </Grid>
                        <Grid item sm={8}>
                            <Grid container spacing={1} style={{fontSize:'12px'}}>
                                <Grid item xs={6} style={{fontWeight:'500'}}>Positive feedback:</Grid><Grid item xs={6}>({details?.Positive_feedback})</Grid>
                                <Grid item xs={6} style={{fontWeight:'500'}}>Completed Jobs:</Grid><Grid item xs={6}>({details?.completed_jobs})</Grid>
                                <Grid item xs={6} style={{fontWeight:'500'}}>Rejected Jobs:</Grid><Grid item xs={6}>({details?.rejected_jobs})</Grid>
                                <Grid item xs={6} style={{fontWeight:'500'}}>Profile message:</Grid><Grid item xs={6}>  {details?.profile_message?.msg_profile}</Grid>
                                <Grid item xs={6} style={{fontWeight:'500'}}>Mode of deliveries :</Grid><Grid item xs={6}>
                                 {

                                   details?.mode_of_delivery?.map((x,i)=>
                                   <span key={i} style={{marginRight:"10px"}}>
                                     <span>{x}</span>
                                   </span>
                                    )}
                                    </Grid>
                                <Grid item xs={6} style={{fontWeight:'500'}}>Insurance coverage:</Grid>
                                <Grid item xs={6}>
                                  {details?.good_in_transit_insurance?.insurance_coverage ? details?.good_in_transit_insurance?.insurance_coverage : 'None'  }
                                </Grid>
                               {/* {console.log(details, 'details from viewfulfiler')} */}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <div style={{padding:'20px 0'}}>
                    <p style={{padding:'10px', fontSize:'13px', fontWeight:'600'}}>Feedback</p>
                    <table style={{border: '1px solid #c4c4c4'}}>
                        <tr>
                            <th  style={{padding:'10px', fontSize:'12px'}}></th>
                            <th style={{padding:'10px', fontSize:'12px'}}>1 month</th>
                            <th style={{padding:'10px', fontSize:'12px'}}>6 months</th>
                            <th style={{padding:'10px', fontSize:'12px'}}>12 months</th>
                        </tr>
                        <tr style={{color:'var(--main)'}}>
                            <td style={{padding:'10px', fontSize:'12px'}}>Positive</td>        
                            <td style={{padding:'10px', fontSize:'12px'}}>{details?.Feedback?.positive.one_month}</td>        
                            <td style={{padding:'10px', fontSize:'12px'}}>{details?.Feedback?.positive.six_month}</td>        
                            <td style={{padding:'10px', fontSize:'12px'}}>{details?.Feedback?.positive.telv_month}</td>        
                        </tr> 
                        <tr style={{color:'red'}}>
                            <td style={{padding:'10px', fontSize:'12px'}}>Negative</td>   
                            <td style={{padding:'10px', fontSize:'12px'}}>{details?.Feedback?.negative.one_month}</td>        
                            <td style={{padding:'10px', fontSize:'12px'}}>{details?.Feedback?.negative.six_month}</td>        
                            <td style={{padding:'10px', fontSize:'12px'}}>{details?.Feedback?.negative.telv_month}</td>        
                        </tr> 
                    </table>
                </div>

                <div className='reviewTable'>
                    <div className="reviewHeable">
                        Reviews 
                    </div>
                            {/* {
                              console.log(details.reviews.map(x => console.log(x.reviews.time_stamp, 'xxx')), 'details-reviews-mobile')
                            } */}

                        {
                            details?.reviews?.map((review, index)=>{ return <div key={index} className="reviewBody">
                                <div> 
                                    <Grid container spacing={1}>
                                        <Grid item xs={3} style={{fontWeight:'500'}}>Rating</Grid> <Grid item xs={9}>{review.rating === "NotGreat" ? <FaThumbsDown color='red'/> : <FaThumbsUp color='var(--main)'/>}</Grid>
                                        <Grid item xs={3} style={{fontWeight:'500'}}>Item</Grid> <Grid item xs={9}>{review.item_desc?.charAt(0)
                                          ?.toUpperCase() + review.item_desc?.slice(1) }</Grid>
                                        <Grid item xs={3} style={{fontWeight:'500'}}>Left by</Grid> <Grid item xs={9}>{review.sender_name}</Grid>
                                        <Grid item xs={3} style={{fontWeight:'500'}}>Comment</Grid> <Grid item xs={9}>{review.additional_comments}</Grid>
                                        {/* <Grid item xs={3} style={{fontWeight:'500'}}>Date</Grid> <Grid item xs={9}> {review.time_stamp.substr(8, 2)}/{review.time_stamp.substr(5, 2)}/{review.time_stamp.substr(0, 4)}</Grid> */}
                                          <Grid item xs={3} style={{fontWeight:'500'}}>Date</Grid> <Grid item xs={9}> 
                                          {review.time_stamp.substr(8, 2)}/{review.time_stamp.substr(5, 2)}/{review.time_stamp.substr(0, 4)}
                                          </Grid> 
                                    </Grid>
                                </div>
                            </div>
                            })
                        }
                </div>
            </div> 
        </div>
    </div>
    </>
  );
}
