import React, { useState,useContext, useEffect } from "react";
import Layout from '../Layout';
import { useHistory, Link } from 'react-router-dom';
import "./Settings.css"
import Multiselect from 'multiselect-react-dropdown';
import { textAlign } from "@mui/system";
import { SenderContext } from "../../../../context/SenderDashboard/SenderDashboardContext";
import { ConnContext } from "../../../../context/ConnContext";
import SecureLS from "secure-ls";
import axios from 'axios';
import imageCompression from 'browser-image-compression'
import Swal from 'sweetalert2';
import { PuffLoader } from 'react-spinners';
import BusinessSettingsMobile from './BusinessSettingsMobile'
import userAvatar from "../../../../assets/userAvatar.jpeg"
import GlobalizationLanguage from "../../../../components/GlobalizationLanguage";

export default function Settings() {
  const sendersettings_business=GlobalizationLanguage("/sendersettings/business")
  const sendersettings=GlobalizationLanguage("/sendersettings")
  const forgetpass=GlobalizationLanguage("/forgetpass")

  const { initialstate, UpdateData, alldata, UpdateAlldata } = useContext(SenderContext);
  const endpoint = process.env.REACT_APP_API;
  const {visitingCountryCode} = useContext(ConnContext)
  const [loading, setloading] = useState(false)
  const [accountType, setaccountType] = useState("");
  
  const [updatingPassport, setUpdatingPassport] = useState(false)

  const [showmode, setshowmode] = useState(false)

  const [mode, setmode] = useState([])
  const [should_reload, setshould_reload] = useState('')

  const ls = new SecureLS({isCompression: true,encodingType: "rabbit",encryptionSecret: "dm_dillivry",});


  const [active, setActive] = useState("business");
  const history = useHistory();
  const[passclick , setpassclick ] =useState(false)
  const [mode_of_transport, setMode_of_transport] = useState([]);
  const [modeValid, setmodeValid] = useState(false)

  const [address, setaddress] = useState('')
  const [website, setwebsite] = useState('')
  const [average, setaverage] = useState('')
  const [good_desc, setgood_desc] = useState('')

  const [image_file, setImage_file] = useState("")
  const [sendImage, setSendImage] = useState("")
  const [userPassport, setuserPassport] = useState("")

  const options = ['bike','car', 'truck', 'van']

  const onSelect = (selectedList, selectedItem)=>{
    if (selectedList.length === 0){
      setmodeValid(false)
    }
    else{setmodeValid(true)}
    setMode_of_transport(selectedList)
  }

  const onRemove = (selectedList, selectedItem)=>{
    if (selectedList.length === 0){
      setmodeValid(false)
    }
    else{setmodeValid(true)}
    setMode_of_transport(selectedList)
  }

  const CustomStyle ={
    chips: { 
      background: 'var(--main)'
      },
    option: {
      background:'none', color:'black',margin:"10px"
    },
    searchBox: { 
        border: "none",
      },
    inputField: { 
        textAlign:"right",
        // width:"50px"
    },
}

useEffect(() => {
  setloading(true);
    if(alldata?.status === 200){
      const CheckLength =  alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length
      const InfoData = alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info) : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)
      CheckLength === 1 ? updatePage(InfoData[0]) : updatePage(InfoData[1])
      setloading(false);
    }
  else{
    setloading(true);
    axios
    .get(`${endpoint}m-dillivry-sender-api`, {
      headers: {
        Authorization: ls.get("_utk"),
      },
    })
      .then((response) => {
        if(response?.status === 200){
          const CheckLength =  response?.data?.data?.map((x) => x?.ng ?? x?.gb).map((x) => x?.account).flat().filter((x)=> x.account_info)?.map(x => x?.account_info).length

          CheckLength === 1 ? updatePage(response?.data?.data?.map((x) => x?.ng ?? x?.gb).map((x) => x?.account).flat().filter((x)=> x.account_info)?.map(x => x?.account_info)[0]) : updatePage(response?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map((x) => x?.account)?.flat()?.filter((x)=> x.account_info)?.map(x => x?.account_info)[1])
          UpdateAlldata(response)
          setloading(false);
        }
      })
      .catch((err)=>{
        setloading(false);
        console.log(err)
        setloading(false);
      })
  }
}, [])

useEffect(() => {
  if(should_reload === "should_reload"){
    setloading(true);
      axios
      .get(`${endpoint}m-dillivry-sender-api`, {
        headers: {
          Authorization: ls.get("_utk"),
        },
      })
      .then((response) => {
        setloading(false);
        if(response.status === 200){
          updatePage(response)
        }
      })
      .catch((err)=>{
        setloading(false);
        console.log(err)
      })
  }
}, [should_reload])


const updatePage = (value)=>{
  // console.log(value, "valueeee")
  setaddress(value?.business_info?.business_address)
  setwebsite(value?.business_info?.website_address)
  setgood_desc(value?.business_info?.goods_description)
  setaverage(value?.business_info?.average_number_of_dispatches_per_week)
  setmode(value?.business_info?.preferred_means_of_deliveries)
  setaccountType(value?.role)
  setuserPassport(value?.cop_logo)
  if(value?.business_info?.preferred_means_of_deliveries?.length > 0 ){
    setshowmode(false)
  }
  else{
    setshowmode(true)
  }
}

const updatePassport = (img)=>{
  setUpdatingPassport(true)
    axios.post(`${process.env.REACT_APP_API}sender-reg`,{
        cop_logo: img,
        sender_email: ls.get("eid"),
    })
    .then((res)=>{
      setUpdatingPassport(false)
        Swal.fire({
            icon: "success",
            text: "Business logo uploaded",
            timer: 3000,
            showConfirmButton: false,
        })
        setshould_reload("should_reload")
    })
    .catch((err)=>{
      console.log(err)
      setUpdatingPassport(false)
    })
}

const onSaveClick =  async ()=>{
  setloading(true)

  axios.post(`${process.env.REACT_APP_API}sender-reg`,{
    country_code: visitingCountryCode,
    sender_email: ls.get('eid'),
    account_type: "business",
    prefered_means_of_deliveries: mode_of_transport,
    website_address: website,
    business_address: address,
    good_description: good_desc,
    average_number_of_dispatches_per_week: average,
  })
  .then((res)=>{
      if(res.status === 200){
          setloading(false)
          Swal.fire({
            icon: "success",
            text: "Business info updated",
            timer: 3000,
            showConfirmButton: false,
          })
          .then(()=>{
            window.location.reload()     
          })
      }
  })
  .catch((err)=>{
      console.log(err)
      setloading(false)
  })

}

  const topData = {
    tabs: [
      {
        type: "personal",
        name: "Personal settings",
      },
      {
        type: "business",
        name: "Business settings",
      },
      // {
      //   type: "security",
      //   name: "Security",
      // },
     
    ],
  };

  const filterItem = (type) =>{
    setActive(type)
    if (type === "personal"){
      history.push(sendersettings)
    }
    if( type === "business"){
      history.push(sendersettings_business)

    }
    // if( type === "security"){
    //   history.push("/sendersettings/security")
      
    // }

  }

  async function imageHandler(e) {
    const file = e.target.files[0]
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 512,
    }
    const output = await imageCompression(file, options)
    const dataa = await imageCompression.getDataUrlFromFile(output)
    setImage_file(dataa)
    const img = dataa.split(',');
    setSendImage(img[1])

    updatePassport(img[1])
  }


  return(
    <div className="mbl-dsk">
      <div className="dsktopscreen">
        <Layout>
          <div className='sender-settings-wrap'>
            <ul className="tab-flex" >
              {
                topData.tabs.map((x, i) => {
                  return (
                    <li onClick={() => filterItem(x.type)} className={active === x.type ? 'activeuser' : 'inactiveuser'}>
                      {x.name}
                    </li>
                  )
                })
              }
            </ul>

            <div className="business-settings">
              <div className="personal-image">
                {
                  updatingPassport ?
                  <PuffLoader color='var(--main)' loading={updatingPassport} speedMultiplier={1} size={100} />
                    :
                    <>
                      <img src={image_file !== "" ? image_file : userPassport !== "" ? userPassport : userAvatar} />
                      <div className="change-image">
                        <label htmlFor="image_files" style={{ color: 'var(--main)', textDecoration: 'underline', cursor: 'pointer' }}>change</label>
                        <input
                          style={{ display: "none" }}
                          id="image_files"
                          name="image_files"
                          type="file"
                          accept="image/x-png,image/gif,image/jpeg"
                          onChange={imageHandler}
                        />
                      </div>
                    </>
                }

              </div>
              <div className="personal-info" >
                <ul className="business-input-field-wrap">
                  <li>
                    <span className="send-text">Business address:</span>
                    <input className="business-input"
                      type="text"
                      value={address}
                      onChange={(e) => setaddress(e.target.value)}
                    />

                  </li>
                  <li>
                    <span className="send-text">Website address:</span>
                    <input className="business-input"
                      placeholder="website.com"
                      type="text"
                      value={website}
                      onChange={(e) => setwebsite(e.target.value)}
                    />
                  </li>
                  <li>
                    <span className="send-text">Prefered means of deliveries:</span>
                    
                    <span>
                      {
                        showmode ? 
                        <Multiselect
                          options={options} // Options to display in the dropdown
                          onSelect={onSelect}
                          onRemove={onRemove} // Function will trigger on remove event
                          showCheckbox={true}
                          isObject={false}
                          placeholder={mode_of_transport.length === 0 ? "E.g car, bike, van" : ""}
                          style={CustomStyle}
                        />
                        :
                        mode?.map((x,i)=>(
                          <span key={i} onClick={()=>setshowmode(true)}>{x},</span>
                        ))
                      }
                      
                    </span>
                  </li>
                  <li>
                    <span className="send-text">Average number of dispatches per week:</span>
                    <input className="business2-input"
                      type="number"
                      placeholder="Average number of dispatches per week"
                      value={average}
                      onChange={(e) => setaverage(e.target.value)}
                    />
                    {/* <span>20</span> */}
                  </li>
                  <li>
                    <span className="send-text">Goods description:</span>
                    <input className="business-input"
                      type="text"
                      placeholder="Goods description"
                      value={good_desc}
                   
        
                      onChange={(e) => setgood_desc(e.target.value)}
                    />
                    {/* <span>Printing materials</span> */}
                  </li>

                </ul>

                <div className="change-password">
                  <div className={passclick ? "passnone" : "changebox"}>
                    <p onClick={()=>history.push(forgetpass)} >Change password</p>

                  </div>

                  <input type="password" className={passclick ? "password-change" : "passnone"} />
                </div>

                <div className="update-info-btn">
                  <button className="cancel-update">Cancel</button>

                  <button onClick={onSaveClick} className="save-update" disabled={loading}>
                    {
                      loading ?
                        <span className="btnlabe">
                          <PuffLoader color='white' loading={loading} speedMultiplier={1} size={24} />
                        </span>
                        :
                        <span>
                          Save
                        </span>
                    }
                  </button>

                </div>
              </div>

            </div>



          </div>
        </Layout>
      </div>
      <div className="mobilescreen">
        <BusinessSettingsMobile
          updatingPassport={updatingPassport}
          image_file={image_file}
          userPassport={userPassport}
          imageHandler={imageHandler}
          address={address}
          setaddress={setaddress}
          website={website}
          setwebsite={setwebsite}
          options={options}
          onSelect={onSelect}
          onRemove={onRemove}
          mode_of_transport={mode_of_transport}
          CustomStyle={CustomStyle}
          average={average}
          setaverage={setaverage}
          good_desc={good_desc}
          setgood_desc={setgood_desc}
          passclick={passclick}
          onSaveClick={onSaveClick}
          loading={loading}
          history={history}
          setshowmode={setshowmode}
          mode={mode}
          showmode={showmode}
        />
      </div>

    </div>
  );
}
