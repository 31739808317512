import SenderBottomNav from "../../../components/SenderBottomNav";
import Box from "@mui/material/Box";
import moment from "moment";
import send from "../../../../../assets/Stroke.svg";
import horiz from "../../../../../assets/horiz.svg";
import PuffLoader from "react-spinners/PuffLoader";
import { BsCheckAll } from "react-icons/bs";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import warngreen from "../../../../../assets/warngreen.svg";
import warnrred from "../../../../../assets/warnrred.svg";
import { MdKeyboardBackspace } from "react-icons/md";
import { useHistory } from "react-router-dom";
import userAvatar from "../../../../../assets/userAvatar.jpeg";

import GlobalizationLanguage from "../../../../../components/GlobalizationLanguage";
import { IconButton } from "@mui/material";
export default function MessageDrilldownMobile({ isloading, DMconversation, msg, msgIndex, initiateMessage, location,
    setReply, setisRequired, setReplying, replying, newMessage, freshmsg,
    setMsgIndex, setshow, setfulfiller_id, show, reverseArr, sendingMsg, reply, errors, replyMessage }) {
    const history = useHistory();
   

    const capitalize = (x) => {
      return x;
      //  x?.charAt(0).toUpperCase() + x.slice(1)
    };
   
  return (
    <div>
      {/* <SenderSideBar pageTitle="Message" links={links} /> */}
      <div className="moblie-msg-wrap">
        {isloading ? (
          <>
            <Box sx={{ width: "100%", marginBottom: "10px", marginTop: "4px" }}>
              {/* <LinearProgress color="success" /> */}
            </Box>
          </>
        ) : (
          <></>
        )}
        {msg.length !== 0 ? (
          <div className="chat-con">
            <div>
              <MdKeyboardBackspace
                color="#50b14a"
                fontSize="25px"
                onClick={()=>{
                  history.goBack()
                }}
              />
            </div>
            
            {msg[0] === undefined ? (
              <></>
            ) : (
              <div className="msgViewContainerTop">
                <div
                  className="msgViewContainerTopImage"
                  style={{ backgroundImage: `url(${msg[0]?.image_urls})` }}
                ></div>

                <div className="msgViewTopText">
                  <p>
                    {" "}
                    {msg[0]?.quote_subject.charAt(0)?.toUpperCase() +
                      msg[0]?.quote_subject.slice(1) +
                      " (" +
                      msg[0]?.delivery_id +
                      ")"}
                    {/* {msg[0].quote_subject} */}
                  </p>
                  <p>
                    {" "}
                    <img src={warngreen} alt="msgpi_icon" />
                    &ensp;{msg[0]?.pickup_address}
                  </p>
                  <p>
                    {" "}
                    <img src={warnrred} alt="msgpi_icon" />
                    &ensp;{msg[0]?.delivery_address}
                  </p>
                </div>
              </div>
            )}

            {reverseArr(DMconversation).map((converse, index) => {
              return (
                <div key={index}>
                  {converse?.fulfiller_id ? (
                    <>
                      <div
                        style={{
                          padding: "10px",
                          border: "1px solid #c4c4c4",
                          marginTop: "3px",
                          marginBottom: "3px",
                          borderTopRightRadius: "5px",
                          borderTopLeftRadius: "5px",
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setMsgIndex(index);
                          setshow(!show);
                          setfulfiller_id(converse.fulfiller_id);
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "var(--main)",
                          }}
                        >
                          {capitalize(converse?.fulfiller_id)}
                        </div>
                        {msgIndex === index && show ? (
                          <FaChevronUp color="var(--main)" />
                        ) : (
                          <FaChevronDown color="var(--main)" />
                        )}
                      </div>

                      {msgIndex === index && show
                        ? reverseArr(converse?.conversation).map(
                            (mssg, index) => (
                              <>
                                {mssg.fulfiller_id === undefined ? (
                                  <>
                                    <div className="right-wrapper">
                                      <div class="message-right-mobile">
                                        <div className="main-msg">
                                          <p> {capitalize(mssg?.msg)}</p>{" "}
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <div
                                              className="timediplaydesk"
                                              style={{ fontSize: "10px" }}
                                            >
                                              {moment(mssg.time_stamp).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                            <div
                                              className="timediplaymobile"
                                              style={{ fontSize: "7px" }}
                                            >
                                              {moment(mssg.time_stamp).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                          </div>
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <BsCheckAll
                                              color="white"
                                              size="18px"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="left-wrapper">
                                      <span class="imageView-cropper">
                                        <img src={userAvatar} class="rounded" />
                                      </span>
                                      <div class="message-left-mobile">
                                        <div>
                                          <strong>
                                            {capitalize(mssg?.fulfiller_id)}
                                          </strong>
                                        </div>
                                        <div>
                                          <p className="text-sty">
                                            {capitalize(mssg?.msg)}{" "}
                                          </p>
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <div
                                              className="timediplaydesk"
                                              style={{ fontSize: "10px" }}
                                            >
                                              {moment(mssg.time_stamp).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                            <div
                                              className="timediplaymobile"
                                              style={{ fontSize: "7px" }}
                                            >
                                              {moment(mssg.time_stamp).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                          </div>
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <BsCheckAll
                                              color="var(--main)"
                                              size="18px"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )
                          )
                        : null}
                      {msgIndex === index && show ? (
                        <>
                          <div className="reply-message-wrap">
                            <div className="reply-message-con">
                              <div style={{display:"flex",alignItems:"center"}}>
                                <textarea
                                  requiredtype="text"
                                  className="reply-input"
                                  style={{ width: "90%", paddingTop: "18px" }}
                                  value={replying === index ? reply : null}
                                  onChange={(e) => {
                                    setReply(e.target.value);
                                    setisRequired(false);
                                    setReplying(index);
                                  }}
                                  type="text"
                                  placeholder="Reply..."
                                />
                                <span type="submit" value="submit">
                                  {sendingMsg && replying === index ? (
                                    <span className="sending">
                                      <PuffLoader
                                        color="var(--main)"
                                        loading={sendingMsg}
                                        speedMultiplier={1}
                                        size={25}
                                      />
                                    </span>
                                  ) : (
                                    <span>
                                      <img
                                        src={send}
                                        alt=""
                                        onClick={() => replyMessage(index)}
                                        style={{ width: "20px" }}
                                      />
                                    </span>
                                  )}
                                </span>
                              </div>
                              <p>{errors.message?.message}</p>
                            </div>
                            <div>
                              <br></br>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
              );
            })}
          </div>
        ) : (
          <>
            {newMessage.length !== 0 ? (
              <div className="chat-con">
                {msg[0] === undefined ? (
                  <></>
                ) : (
                  <div className="msgViewContainerTop">
                    <div
                      className="msgViewContainerTopImage"
                      style={{
                        backgroundImage: `url(${newMessage[0]?.image_urls[0]})`,
                      }}
                    ></div>

                    <div className="msgViewTopText">
                      <p>
                        {" "}
                        {newMessage[0]?.item_desc.charAt(0)?.toUpperCase() +
                          newMessage[0]?.item_desc.slice(1) +
                          " (" +
                          newMessage[0]?.delivery_id +
                          ")"}
                        {/* {msg[0].quote_subject} */}
                      </p>
                      <p>
                        {" "}
                        <img src={warngreen} alt="msgpi_icon" />
                        &ensp;{newMessage[0]?.from}
                      </p>
                      <p>
                        {" "}
                        <img src={warnrred} alt="msgpi_icon" />
                        &ensp;{newMessage[0]?.to}
                      </p>
                    </div>
                  </div>
                )}
                <div>
                  <div
                    style={{
                      padding: "10px",
                      border: "1px solid #c4c4c4",
                      marginTop: "3px",
                      marginBottom: "3px",
                      borderTopRightRadius: "5px",
                      borderTopLeftRadius: "5px",
                      cursor: "pointer",
                      userSelect: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "var(--main)",
                      }}
                    >
                      {location.state}
                    </div>
                  </div>
                </div>

                {freshmsg !== undefined
                  ? reverseArr(freshmsg).map((mssg, index) => {
                      return (
                        <div key={index} >
                          <div className="right-wrapper">
                            <div class="message-right-mobile">
                              <div className="main-msg">
                                <p> {capitalize(mssg.msg)}</p>{" "}
                              </div>
                              <div className="message-timeStamp">
                                <div className="all-time">
                                  <div
                                    className="timediplaydesk"
                                    style={{ fontSize: "10px" }}
                                  >
                                    {moment(mssg.time_stamp).format(
                                      "MMM Do YYYY, h:mm a "
                                    )}
                                    ({moment(mssg.time_stamp).fromNow()})
                                  </div>
                                  <div
                                    className="timediplaymobile"
                                    style={{ fontSize: "7px" }}
                                  >
                                    {moment(mssg.time_stamp).format(
                                      "MMM Do YYYY, h:mm a "
                                    )}
                                    ({moment(mssg.time_stamp).fromNow()})
                                  </div>
                                </div>
                              </div>
                              <div className="message-timeStamp">
                                <div className="all-time">
                                  <BsCheckAll color="white" size="18px" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : null}
                <div className="reply-message-wrap">
                  <div className="reply-message-con">
                  <div style={{display:"flex",alignItems:"center"}}>
                    <textarea
                      requiredtype="text"
                      className="reply-input"
                      type="text"
                      value={reply}
                      onChange={(e) => {
                        setReply(e.target.value);
                        setisRequired(false);
                      }}
                      placeholder="Reply..."
                    />
                    
                    <div className="send-con">
                      <div className="send-icons">
                        {" "}
                        <span>
                          <img src={horiz} alt="" />
                        </span>
                      </div>
                      <div className="send-icons">
                        <span
                          type="submit"
                          value="submit"
                          className="send-icon"
                        >
                          {sendingMsg ? (
                            <>
                              <span className="sending">
                                <PuffLoader
                                  color="var(--main)"
                                  loading={sendingMsg}
                                  speedMultiplier={1}
                                  size={25}
                                />
                              </span>
                            </>
                          ) : (
                            <span>
                              <img
                                src={send}
                                alt=""
                                style={{ width: "20px" }}
                                onClick={initiateMessage}
                              />
                            </span>
                          )}
                        </span>
                        <span>
                          {" "}
                          <img
                            src={horiz}
                            alt=""
                            style={{ marginLeft: "20px" }}
                          />{" "}
                        </span>
                      </div>
                    </div>
                    </div>
                    <p>{errors.message?.message}</p>
                  </div>
                  <div>
                    <br></br>
                  </div>
                </div>
              </div>
            ) : (
              <div className="chat-con">
                {msg[0] === undefined ? (
                  <></>
                ) : (
                  <div className="msgViewContainerTop">
                    <div
                      className="msgViewContainerTopImage"
                      style={{ backgroundImage: `url(${msg[0]?.image_urls})` }}
                    ></div>

                    <div className="msgViewTopText">
                      <p>
                        {" "}
                        {msg[0]?.quote_subject.charAt(0)?.toUpperCase() +
                          msg[0]?.quote_subject.slice(1) +
                          " (" +
                          msg[0]?.delivery_id +
                          ")"}
                      </p>
                      <p>
                        {" "}
                        <img src={warngreen} alt="msgpi_icon" />
                        &ensp;{msg[0]?.pickup_address}
                      </p>
                      <p>
                        {" "}
                        <img src={warnrred} alt="msgpi_icon" />
                        &ensp;{msg[0]?.delivery_address}
                      </p>
                    </div>
                  </div>
                )}
                {DMconversation.map((converse, index) => {
                  return (
                    <div key={index} >
                      <div
                        style={{
                          padding: "10px",
                          border: "1px solid #c4c4c4",
                          marginTop: "3px",
                          marginBottom: "3px",
                          borderTopRightRadius: "5px",
                          borderTopLeftRadius: "5px",
                          cursor: "pointer",
                          userSelect: "none",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setMsgIndex(index);
                          setshow(!show);
                          setfulfiller_id(converse.fulfiller_id);
                        }}
                      >
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "var(--main)",
                          }}
                        >
                          {capitalize(converse?.fulfiller_id)}
                        </div>
                        {msgIndex === index && show ? (
                          <FaChevronUp color="var(--main)" />
                        ) : (
                          <FaChevronDown color="var(--main)" />
                        )}
                      </div>
                      {msgIndex === index && show
                        ? reverseArr(converse?.conversation).map(
                            (mssg, index) => (
                              <>
                                {mssg.fulfiller_id === undefined ? (
                                  <>
                                    <div className="right-wrapper">
                                      <div class="message-right-mobile">
                                        <div className="main-msg">
                                          <p> {capitalize(mssg?.msg)}</p>{" "}
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <div
                                              className="timediplaydesk"
                                              style={{ fontSize: "10px" }}
                                            >
                                              {moment(mssg.time_stamp).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                            <div
                                              className="timediplaymobile"
                                              style={{ fontSize: "7px" }}
                                            >
                                              {moment(mssg.time_stamp).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                          </div>
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <BsCheckAll
                                              color="white"
                                              size="18px"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="left-wrapper">
                                      <span class="imageView-cropper">
                                        <img src={userAvatar} class="rounded" />
                                      </span>
                                      <div class="message-left-mobile">
                                        <div>
                                          <strong>
                                            {capitalize(mssg?.fulfiller_id)}
                                          </strong>
                                        </div>
                                        <div>
                                          <p className="text-sty">
                                            {capitalize(mssg?.msg)}{" "}
                                          </p>
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <div
                                              className="timediplaydesk"
                                              style={{ fontSize: "10px" }}
                                            >
                                              {moment(mssg.time_stamp).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                            <div
                                              className="timediplaymobile"
                                              style={{ fontSize: "7px" }}
                                            >
                                              {moment(mssg.time_stamp).format(
                                                "MMM Do YYYY, h:mm a "
                                              )}
                                              (
                                              {moment(
                                                mssg.time_stamp
                                              ).fromNow()}
                                              )
                                            </div>
                                          </div>
                                        </div>
                                        <div className="message-timeStamp">
                                          <div className="all-time">
                                            <BsCheckAll
                                              color="var(--main)"
                                              size="18px"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )
                          )
                        : null}
                      {msgIndex === index && show ? (
                        <>
                          <div className="reply-message-wrap">
                            <div className="reply-message-con">
                            <div style={{display:"flex",alignItems:"center"}}>
                              <textarea
                                requiredtype="text"
                                className="reply-input"
                                value={replying === index ? reply : null}
                                onChange={(e) => {
                                  setReply(e.target.value);
                                  setisRequired(false);
                                  setReplying(index);
                                }}
                                type="text"
                                // style={{   border: isRequired && requiredIndex === index ? "1px solid red" : "1px solid #C4C4C4", color: "black" }}
                                // rows="3"
                                placeholder="Reply..."
                              />
                              
                              <div className="send-con">
                                <div className="send-icons">
                                  {" "}
                                  <span>
                                    <img src={horiz} alt="" />
                                  </span>
                                </div>
                                <div className="send-icons">
                                  <span
                                    type="submit"
                                    value="submit"
                                    className="send-icon"
                                  >
                                    {/* <BiSend onClick={()=>replyMessage(index)}  size={25}/></span>  */}
                                    {sendingMsg && replying === index ? (
                                      <>
                                        <span className="sending">
                                          <PuffLoader
                                            color="var(--main)"
                                            loading={sendingMsg}
                                            speedMultiplier={1}
                                            size={25}
                                          />
                                        </span>
                                      </>
                                    ) : (
                                      <span>
                                        <img
                                          src={send}
                                          alt=""
                                          onClick={() => replyMessage(index)}
                                          style={{ width: "20px" }}
                                        />
                                      </span>
                                    )}
                                  </span>
                                  <span>
                                    {" "}
                                    <img src={horiz} alt="" />{" "}
                                  </span>
                                </div>
                              </div>
                              </div>
                              <p>{errors.message?.message}</p>
                            </div>
                            <div>
                              <br></br>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
      <SenderBottomNav state = "message" />
    </div>
  );
}
