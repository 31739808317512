import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import InputField from "../../components/InputField";
import { FaEdit, FaTextHeight } from "react-icons/fa";
import PuffLoader from "react-spinners/PuffLoader";
import { HiOutlineMinusCircle } from "react-icons/hi";
import { FaCloudUploadAlt } from "react-icons/fa";
import "./SendQuote.css";
import { useSpring } from "react-spring";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
// import {
//   Button,
//   FormControlLabel,
// } from "@material-ui/core";

import { MenuItem, Button, FormControlLabel } from "@material-ui/core";

import { Select, FormControl, InputLabel } from "@mui/material";


import { Grid } from "@material-ui/core";
import { Radio, CssBaseline, RadioGroup } from "@mui/material";

import DialogBox from "../../components/topNav/DialogBox";
import DateRanger from "../../components/DateRanger";
import ButtonCard from "../../components/ButtonCard";
import Calender from "../../components/Calender";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import GooglePlaceAutocomplete from "../../components/GooglePlaceAutocomplete";
import PhoneNumber from "../../components/PhoneNumber";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";
import Swal from "sweetalert2";
import imageCompression from "browser-image-compression";

import { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import LoginComponent from "../../components/LoginComponent";
import { Link } from "react-router-dom";
import Popup from "../../components/Popup";
import SecureLS from "secure-ls";
import PrivacyDialog from "../../components/PrivacyDialog";
import { FaRunning } from "react-icons/fa";
import { ConnContext } from "../../context/ConnContext";
import { AiFillCloseCircle } from "react-icons/ai";
import Dialogsummary from "../../components/Dialogsummary";
import edit from "../Sender/SenderDashboard/Assets/edit.png";

import GlobalizationLanguage from '../../components/GlobalizationLanguage';
import Cookies from "js-cookie";
import ReactSelect from "react-select";
import { BiCalendarAlt } from "react-icons/bi";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(() => ({
  formControl: {
    width: `100%`,
  },
}));

function SendHaulage({
  location, 
  itemCategory, 
  onClick, 
  previous, 
  page,

  delivery_location,
  pickup_address,
  delivery_address,
  pickupIsError,
  deliveryIsError,
  setpickupIsError,
  setdeliveryIsError
}) {
    const {visitingCountryCode} = useContext(ConnContext)

  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()
  const [country,setCountry]=useState(countryvalue);

  useEffect(() => {
      setCountry(Cookies.get('i18next'));

    // console.log(Cookies.get('i18next'), "Cookies.get('i18next')")
    // console.log(visitingCountryCode, "Visitingcountrcode")
    // console.log(Cookies.get('i18next').toLocaleUpperCase() === visitingCountryCode)



    },[Cookies.get('i18next')])

    var timeout = GlobalizationLanguage("/timeout")
    var login = GlobalizationLanguage("/login")
    var ThankYou = GlobalizationLanguage("/viewQuote/thank-you")


    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'black' : 'black',
        backgroundColor: state.isSelected ? 'white' : 'white',
        
        // padding: 20,
      }),
    
      // control: (provided: Record<string, unknown>) => ({
      //   ...provided,
      //   height: 52,
      //   '&:hover': {
      //     border: '1px solid #ff8b67',
      //     boxShadow: '0px 0px 6px #ff8b67',
      //   },
      //   '&:focus': {
      //     border: '1px solid #ff8b67',
      //     boxShadow: '0px 0px 6px #ff8b67',
      //   },
      // }),
    
    
    
    
    
    
      // control: () => ({
      //   width: 200,
    
    
    
      // }),
      // singleValue: (provided, state) => {
      //   const opacity = state.isDisabled ? 0.5 : 1;
      //   const transition = 'opacity 300ms';
    
      //   return { ...provided, opacity, transition };
      // }
    }
    
    

  


  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const [policyShow, setPolicyShow] = useState(false);
  const [pageToShow, setpageToShow] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    // setPickup_address(location?.pickup);
    // setDelivery_address(location?.destination);

    if (ls.get("_utk") === "") {
      setauthorize("");
    } else {
      setauthorize(ls.get("_utk"));

      const IdToken = ls.get("_utk");
      const token = IdToken.split(".")[1];
      const encodedToken = atob(token);
      const tokenJSON = JSON.parse(encodedToken);

      setdisableField(true);

      setValue("sender_email", tokenJSON.email);
      setValue("sender_name", tokenJSON.given_name);
      setSender_phone_no(tokenJSON?.phone_number);
      setsendermssg(tokenJSON.email);
    }
  }, []);

  const endPoint = process.env.REACT_APP_API;

  const history = useHistory();

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 300,
  });

  const [isloading, setisloading] = useState(false);
  const [checked, setChecked] = useState(false);
  // const [pickup_address, setPickup_address] = useState("");
  // const [delivery_address, setDelivery_address] = useState("");

  const [showdrop, setShowdrop] = useState(false);
  const [showcate, setShowcate] = useState(false);
  const [payer, setPayer] = useState("sender");

  const handleClick = () => {
    setShowdrop((showdrop) => !showdrop);
    if (showcate) {
      setShowcate(false);
    }
    // handleOverviewClick();
    // setShowcate(false)
  };
  const [showImageSpace, setShowImageSpace] = useState(false);
  const [image_files, setImage_files] = useState([]);
  const [sendImage, setSendImage] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [delivery_time_frame, setDelivery_time_frame] = useState("");
  const [delivery_time_frameduplicate, setDelivery_time_frameduplicate] =
    useState("");
  const [deliveryTFIsValid, setdeliveryTFIsValid] = useState(false);
  const [pickup_phone_no, setPickup_phone_no] = useState("");
  const [sender_phone_no, setSender_phone_no] = useState("");
  const [delivery_phone_no, setDelivery_phone_no] = useState("");
  // const [pickupIsError, setpickupIsError] = useState(false);
  // const [deliveryIsError, setdeliveryIsError] = useState(false);
  const [isReciever, setIsReciever] = useState("false");
  const [isPickup, setIsPickup] = useState("false");
  const auth = useContext(AuthContext);
  const [authorize, setauthorize] = useState("");
  const [disableField, setdisableField] = useState(false);
  const [ShowLogin, setShowLogin] = useState(false);
  const [showsummary, setshowsummary] = useState(false);
  const [weightUnit, setweightUnit] = useState("");
  const [previewdata, setpreviewdata] = useState([]);
  const [sendermssg, setsendermssg] = useState();
  const [sendermssgerror, setsendermssgerror] = useState(false);

  const [modeIsValid, setmodeIsValid] = useState(false);

  const [mode_of_delivery, setmode_of_delivery] = useState("");

  const options = [
     { value: 'pay_by_transfer', label: 'Transfer' },
  { value: 'pay_by_cash', label: 'Cash' },
   { value: 'pay_by_wallet', label: 'Wallet' },
  { value: 'pay_by_card', label: 'Card' },
  { value: 'pay_by_ussd', label: 'USSD' },

]



const handleChange = (e) => {
  setsendermssg(e.target.value);
  setsendermssgerror(false);
};

const handleModeofDelivery = (e) => {
  setmode_of_delivery(e.target.value);
  setmodeIsValid(false);
};



  const [range, setRanges] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
      color: "var(--main)",
    },
  ]);
  const setDialog = async () => {
    const result = await trigger(["pickup_date"]);
    if (result) {
      setOpenDialog(!openDialog);
      setdeliveryTFIsValid(false);
    }
  };
  const setRange = () => {
    setOpenDateRange(!openDateRange);
  };
  const handleOpenDate = () => {
    setOpenDate(!openDate);
  };
  async function imageHandler(e) {
    const file = e.target.files[0];
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 512,
    };
    const output = await imageCompression(file, options);

    const dataa = await imageCompression.getDataUrlFromFile(output);

    setImage_files([...image_files, dataa]);

    const img = dataa.split(",");

    setSendImage([...sendImage, img[1]]);

    setShowImageSpace(true);
  }
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const useStyles = makeStyles((theme) => ({
    formControl: {
      // marginBottom: theme.spacing(3),
      width: `100%`,
    },
    formControlin2: {
      marginBottom: theme.spacing(4),
      minWidth: `100%`,
    },
    formControl75: {
      marginBottom: theme.spacing(2),
    },
    formControl25: {
      marginBottom: theme.spacing(2),
      width: `50%`,
    },
  }));

  // const useStyles = makeStyles((theme) => ({

  //   // formControl: {
  //   //   width: `100%`,
  //   //   marginBottom: theme.spacing(3),
  //   // },
  //   formControl: {
  //     // width: `100%`,
  //     background: "lime",
  //     marginTop: "30px"
  //   },
  // }));


  const classes = useStyles();

  const schema = yup.object().shape({
    mode_of_payment: yup
    .object()
    .shape({
       label: yup.string().required("payment option is required (from label)"),
      value: yup.string().required("payment option is required")
    }),
    // .nullable() // for handling null value when clearing options via clicking "x"
    // .required("payment option is required (from outter null check)"),
    item_desc: yup.string().required("Brief description is required"),
   // mode_of_delivery: yup.string().required("Mode of delivery is required"),


    haulage_desc: yup.string().required("Haulage description is required"),
    packaging_desc: yup.string().required("Packaging description is required"),
    // mode_of_movement: yup.string().required("Mode of delivery is required"),
    // relevant_item_dim: yup.string().matches(/^[0-9]*$/ ,'Relevant dimension is required'),
    pickup_email: yup.string().email("Valid email is required"),
    // .required("Pickup email is required"),
    // sender_email: yup
    //   .string()
    //   .matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, "Valid email is required")
    //   .required("Sender email is required"),
    delivery_email: yup.string().email("Valid email is required"),
    // .required("Receiver email is required"),
    pickup_name: yup
      .string()
      .required("Pickup name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    delivery_name: yup
      .string()
      .required("Receiver's name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    sender_name: yup
      .string()
      .required("Your name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    pickup_date: yup.string().required("Pickup date is required"),
  });
  const {
    control,
    getValues,
    setValue,
    trigger,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const closemodalandbacktopageone = () => {
    setshowsummary(!showsummary);
  };



  const submitafterPreview = async (data, e) => {
    e.preventDefault();

    if (sender_phone_no === undefined || delivery_phone_no === undefined || pickup_phone_no === undefined) {
      Swal.fire({
        icon: 'info',
        text: 'Phones number is required',
        timer: 2500,
        showConfirmButton: false,
        iconColor: 'var(--main)'
      })
    }

    else if (!isValidPhoneNumber(sender_phone_no) || !isValidPhoneNumber(delivery_phone_no) || !isValidPhoneNumber(pickup_phone_no)) {
      Swal.fire({
        icon: 'info',
        text: 'Invalid phone number',
        timer: 2500,
        showConfirmButton: false,
        iconColor: 'var(--main)'
      })
    }
    data.pickup_address = pickup_address;
    data.delivery_address = delivery_address;
    data.delivery_time_frame = delivery_time_frameduplicate
    // data.delivery_time_frame = delivery_time_frameduplicate.replace(/\b(\d)\b/g, "0$1")
    data.image_files = sendImage;
    data.pickup_phone_no = parsePhoneNumber(pickup_phone_no).number;
    data.sender_phone_no = parsePhoneNumber(sender_phone_no).number;
    data.delivery_phone_no = parsePhoneNumber(delivery_phone_no).number;
    data.item_category = itemCategory.toLowerCase().split(" ").join("_");
    data.category = itemCategory.toLowerCase().split(" ").join("_");
    data.password = btoa(data.password)
    data.action_type = 'listing'
    data.delivery_type = 'bid'
    data.relevant_item_dim = data.relevant_item_dim ? data.relevant_item_dim : ""
    data.payer = payer;
    data.vcc = visitingCountryCode;
    data.mode_of_delivery = mode_of_delivery.toLowerCase();
    data.sender_email = sendermssg.toLowerCase();
    data.mode_of_payment = data?.mode_of_payment?.value;

    setpreviewdata([data]);

    const authAxios = axios.create({
      baseURL: `${endPoint}`,
      headers: {
        Authorization: ls.get('_utk') !== null ? ls.get('_utk') : ''
      }
    });

    setisloading(true);
    setisloading(true);

    // const Api = Cookies?.get('i18next')?.toLowerCase() === visitingCountryCode.toLowerCase() ? `m-dillivry-listing` : `m-dillivry-listing?vcc=${country}`
    // const Api = deli_locatn_frm_pickup_add !== undefined ? `m-dillivry-listing?delivery_location=${deli_locatn_frm_pickup_add}` : `m-dillivry-listing?delivery_location=ng`
    const Api = delivery_location !== undefined ? `m-dillivry-listing?delivery_location=${delivery_location}` : `m-dillivry-listing?delivery_location=ng`

    await authAxios.post(Api ,
      data, { timeout: 240000 }
    )
      .then((response) => {
        // ls.set("eid", data.sender_email);
        ls.remove("auth_status");
        setshowsummary(!showsummary);
        setisloading(false);
        Swal.fire({
          icon: 'success',
          title: 'Done',
          html:"<h6>Your request has now been listed successfully. Sit back and relax as the quotes start to roll in.</h6>",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            history.push({
                pathname: ThankYou,
                state: "from_requestDelivery",
              });
          }
        });
        setisloading(false);
      })

      .catch((error) => {
        if(error.response?.data?.msg === "not yet in the specifcied country"){
          Swal.fire({
            icon: "warning",
            text: `Not yet in the specified country, check your pickup addresses and try again`,
            confirmButtonColor: "var(--main)",
          });
        }
        else if (error.code === "ECONNABORTED") {
          console.log('internal-server-error-timeout')
          history.push(timeout)
          // window.location.reload()
        }
        if (error.response.data.msg !== undefined && error.response.data.msg === 'user name already exists') {
          Swal.fire({
            icon: 'warning',
            text: `${error.response.data.msg}`,
            confirmButtonColor: 'var(--main)',
            confirmButtonText: 'Login to continue'
          })
            .then((result) => {
              if (result.value) {
                setShowLogin(true)
              }
            });
          setisloading(false)
        }
        else {
          Swal.fire({
            icon: "warning",
            text: `${error.response.data.msg}`,
            confirmButtonColor: 'var(--main)'
          })
          setisloading(false)

        }
      })
      .finally(()=>{
        setisloading(false)
      })
  };



  const submitForm = async (data, e) => {
    e.preventDefault();

    setshowsummary(!showsummary);
    if (
      sender_phone_no === undefined ||
      delivery_phone_no === undefined ||
      pickup_phone_no === undefined
    ) {
      Swal.fire({
        icon: "info",
        text: "Phones number is required",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    } else if (
      !isValidPhoneNumber(sender_phone_no) ||
      !isValidPhoneNumber(delivery_phone_no) ||
      !isValidPhoneNumber(pickup_phone_no) || 
      (!sender_phone_no?.length === 14 
      && visitingCountryCode == "NG") ||
      (!sender_phone_no?.length === 15 
        && visitingCountryCode == "NG") ||
      (sender_phone_no?.length === 13 
      && visitingCountryCode == "GB")
    ) {
      Swal.fire({
        icon: "info",
        text: "Invalid phone number",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    }
    data.pickup_address = pickup_address;
    data.delivery_address = delivery_address;

    data.delivery_time_frame = delivery_time_frameduplicate.replace(
      /\b(\d)\b/g,
      "0$1"
    );

    data.image_files = sendImage;
    data.pickup_phone_no = parsePhoneNumber(pickup_phone_no).number;
    data.sender_phone_no = parsePhoneNumber(sender_phone_no).number;
    data.delivery_phone_no = parsePhoneNumber(delivery_phone_no).number;
    data.item_category = itemCategory.toLowerCase().split(" ").join("_");
    data.category = itemCategory.toLowerCase().split(" ").join("_");
    data.password = btoa(data.password);
    data.action_type = "listing";
    data.delivery_type = "bid";
    data.relevant_item_dim = data.relevant_item_dim
      ? data.relevant_item_dim
      : "";
    data.payer = payer;
    data.vcc = visitingCountryCode;
    data.mode_of_delivery = mode_of_delivery.toLowerCase();
    data.mode_of_payment = data?.mode_of_payment?.value;
    data.sender_email = sendermssg.toLowerCase();

    setpreviewdata([data]);

    const authAxios = axios.create({
      baseURL: `${endPoint}`,
      headers: {
        Authorization: ls.get("_utk") !== null ? ls.get("_utk") : "",
      },
    });

    setisloading(false);
  };

  return (
    <>
      <CssBaseline />
      <form onSubmit={handleSubmit(submitForm)} style={{ width: "100%" }}>
        {page === 1 ? (
          <>
            {/* <animated.div style={props}> */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} >
                <Controller
                  name="item_desc"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                      
                    >
                      {/* <InputLabel htmlFor="description">
                        <FaEdit
                          style={{ marginRight: "5px", color: "var(--main)" }}
                        />
                        Brief Description
                      </InputLabel> */}
                      <InputField
                      label="Brief Description"
                        {...field}
                        error={errors.item_desc}
                        id="description"
                        type="text"
                        labelWidth={150}
                        icon={
                          <FaTextHeight
                            style={{ marginRight: "5px", color: "var(--main)" }}
                          />
                        }
                      />
                      <div className="err">{errors.item_desc?.message}</div>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="haulage_desc"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl
                      className={classes.formControl}
                      // style={{ marginTop: "-25px" }}
                      variant="outlined"
                    >
                      {/* <InputLabel htmlFor="haulage">
                        <FaEdit
                          style={{ marginRight: "5px", color: "var(--main)" }}
                        />
                        Haulage Description
                      </InputLabel> */}
                      <InputField
                      label="Haulage Description"
                        {...field}
                        error={errors.haulage_desc}
                        id="haulage"
                        type="text"
                        labelWidth={160}
                        icon={
                          <FaTextHeight
                            style={{ marginRight: "5px", color: "var(--main)" }}
                          />
                        }
                      />
                      <div className="err">{errors.haulage_desc?.message}</div>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="packaging_desc"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl
                      className={classes.formControl}
                      // style={{ marginTop: "-25px" }}
                      variant="outlined"
                    >
                      {/* <InputLabel htmlFor="packaging">
                        <FaEdit
                          style={{ marginRight: "5px", color: "var(--main)" }}
                        />
                        Package Description
                      </InputLabel> */}
                      <InputField
                        label="Package Description"
                        {...field}
                        error={errors.packaging_desc}
                        id="packaging"
                        type="text"
                        labelWidth={170}
                        icon={
                          <FaTextHeight
                            style={{ marginRight: "5px", color: "var(--main)" }}
                          />
                        }
                      />
                      <div className="err">
                        {errors.packaging_desc?.message}
                      </div>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} >
                <Controller
                  name="pickup_date"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl
                      className={classes.formControl}
                      // style={{ marginTop: "-25px" }}
                      variant="outlined"
                    >
                      <label>Pickup Date</label>
                      <InputField
                        // label="dd/mm/yyyy"
                        label="."
                        {...field}
                        error={errors.pickup_date}
                        id="pickup_date"
                        name="pickup_date"
                        type="date"
                        inputProps={{ min: disablePastDate() }}
                        InputLabelProps={{shrink: false}}
                        // value={pickup_date}
                        // onChange={(e)=> {setPickup_date(e.target.value); setpickDateIsValid(false)}}
                        icon={
                          <FaTextHeight
                            style={{ marginRight: "5px", color: "var(--main)" }}
                          />
                        }
                      />
                      <div className="err">{errors.pickup_date?.message}</div>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} >
                <Controller
                  name="delivery_time_frame"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                      style={{ position: "relative"}}
                    >
                      <label>Delivery time frame</label>
                      <InputField
                      // label="Delivery time frame"
                      InputLabelProps={{shrink: false}}
                      label="."
                      endIcon={<BiCalendarAlt style={{fontSize: '20px'}}/>}

                        error={deliveryTFIsValid}
                        {...field}
                        name="delivery_time_frame"
                        icon={
                          <FaTextHeight
                            style={{ marginRight: "5px", color: "var(--main)" }}
                          />
                        }
                        type="text"
                        value={delivery_time_frame}
                        // onChange={(e)=> setDelivery_time_frame(e.target.value)}
                        onChange={setDialog}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                        }}
                        onClick={setDialog}
                      />
                      <div className="err">
                        {errors.delivery_time_frame?.message}
                      </div>
                    </FormControl>
                  )}
                />
              </Grid>

              {/* <Grid item xs={12} sm={12}>
                <Controller
                  name="mode_of_delivery"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                    >
                      <InputLabel id="mode_of_delivery">
                       
                        Mode of Delivery
                      </InputLabel>
                      <Select
                        {...field}
                        error={errors.mode_of_delivery}
                        labelId="mode_of_delivery"
                        id="mode_of_delivery"
                        label=" Mode of Delivery"
                        size="small"
                      
                      >
                        <MenuItem value="Truck">Truck</MenuItem>
                        <MenuItem value="Van">Van</MenuItem>
                      </Select>

                      <div className="err">
                        {errors.mode_of_delivery?.message}
                      </div>
                    </FormControl>
                  )}
                />
              </Grid> */}

<Grid item xs={12}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="select">
                    <span style={{ color: "var(--main)", marginRight: "10px" }}>
                      <FaEdit />
                    </span>
                    Mode of delivery
                  </InputLabel>
                  <Select
                    labelId="select"
                    error={modeIsValid}
                    value={mode_of_delivery}
                    onChange={handleModeofDelivery}
                    label="Select Item Categor"
                    size="small"
                  >
                    <MenuItem value="Truck">Truck</MenuItem>
                    <MenuItem value="Van">Van</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                onClick={handleClick}
                style={{
                  cursor: "pointer",
                  fontSize: "15px",
                  marginTop: "16px",
                }}
              >
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="none"
                ></input>
                &nbsp;
                <label for="vehicle1" onClick={handleClick}>
                  {" "}
                  <b> Add optional item dimension & photos </b>
                </label>{" "}
              </Grid>
              {showdrop ? (
                <>
                  <Grid item xs={12} sm={12} style={{ marginTop: "20px" }}>
                    <Controller
                      name="relevant_item_dim"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          className={classes.formControl}
                          style={{ marginTop: "-25px" }}
                          variant="outlined"
                        >
                          {/* <InputLabel htmlFor="relevant">
                            <FaEdit
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                            Relevant item dimension
                          </InputLabel> */}
                          <InputField
                          label="Relevant item dimension"
                            {...field}
                            error={errors.relevant_item_dim}
                            id="relevant"
                            type="text"
                            labelWidth={210}
                            icon={
                              <FaTextHeight
                                style={{
                                  marginRight: "5px",
                                  color: "var(--main)",
                                }}
                              />
                            }
                          />
                          <div className="err">
                            {errors.relevant_item_dim?.message}
                          </div>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <div className="title">Upload photo</div>
                    <div className="photo">
                      <input
                        style={{ display: "none" }}
                        id="image_files"
                        name="image_files"
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg"
                        {...register("image_files")}
                        onChange={imageHandler}
                      />
                      <div style={{ color: "#989898" }}>
                        Browse to choose photos
                      </div>
                      {image_files.length !== 3 ? (
                        <label htmlFor="image_files" className="upload">
                          Upload Picture &nbsp;{" "}
                          <FaCloudUploadAlt style={{ fontSize: "15px" }} />
                        </label>
                      ) : (
                        <label htmlFor="image_file" className="uploaded">
                          Upload Picture &nbsp;{" "}
                          <FaCloudUploadAlt style={{ fontSize: "15px" }} />
                        </label>
                      )}
                    </div>
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {showImageSpace ? (
                    <>
                      {image_files.map((images, index) => {
                        return (
                          <Grid item xs={4}>
                            <div key={index} className="forPhoto">
                              <div
                                className="border"
                                style={{ backgroundImage: `url(${images})` }}
                              >
                                <span className="icon">
                                  <HiOutlineMinusCircle
                                    onClick={() => {
                                      const image = Object.assign(
                                        [],
                                        image_files
                                      );
                                      image.splice(index, 1);
                                      setImage_files(image);

                                      const tosend = Object.assign(
                                        [],
                                        sendImage
                                      );
                                      tosend.splice(index, 1);
                                      setSendImage(tosend);
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          </Grid>
                        );
                      })}
                    </>
                  ) : // </div>
                  null}
                </Grid>
              </Grid>
              <Button
                size="large"
                variant="contained"
                type="button"
                style={{
                  backgroundColor: "var(--main)",
                  width: "100%",
                  marginTop: "0px",
                  color: "white",
                  borderRadius: "10px",
                  textTransform: "none",
                  marginBottom: "30px",
                }}
                onClick={async () => {
                  const result = await trigger([
                    "item_desc",
                    // "mode_of_delivery",
                    "pickup_date",
                    "item_desc",
                    "haulage_desc",
                    "packaging_desc",
                    "relevant_item_dim",
                  ]);
                  if (
                    result === true &&
                    mode_of_delivery !== "" &&
                    pickup_address !== undefined &&
                    delivery_address !== undefined &&
                    itemCategory !== "" && !pickupIsError && !deliveryIsError &&
                    delivery_time_frame !== ""
                  ) {
                    // nextPage()
                    onClick();
                  } else {
                    if (sendermssg === "") {
                      setsendermssgerror(true);
                    }
                    if (pickup_address === "" || pickup_address === undefined) {
                      setpickupIsError(true);
                    }
                    if ( delivery_address === "" ||
                    delivery_address === undefined) {
                      setdeliveryIsError(true);
                    }
                    if (itemCategory === "") {
                      Swal.fire({
                        icon: "info",
                        title: "Required",
                        text: "Select a category",
                        timer: 3000,
                        showConfirmButton: false,
                      });
                    }
                    if (delivery_time_frame === "") {
                      setdeliveryTFIsValid(true);
                    }
                  }
                }}
              >
                Continue
                {/* <span className="btnlabel">Collection & Delivery Details <FaArrowRight /></span> */}
              </Button>
            </Grid>
            {/* </animated.div> */}
            {
              console.log(errors, "errors")
            }
          </>
        ) : (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div
                  className="txt"
                  style={{
                    color: "var(--main)",
                    fontWeight: "1000",
                    fontSize: "20px",
                    letterSpacing: "1px",
                  }}
                >
                  Contact Info
                </div>
                <div className="txt">My Information</div>
                <Controller
                  name="sender_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                    >
                      {/* <InputLabel htmlFor="sender_name">
                        <FaEdit
                          style={{
                            marginRight: "5px",
                            color: "var(--main)",
                          }}
                        />
                        Sender's Name
                      </InputLabel> */}
                      <InputField
                      label="Sender's Name"
                        // error={isSenderNameValid}
                        {...field}
                        error={errors.sender_name}
                        name="sender_name"
                        id="sender_name"
                        type="text"
                        labelWidth={150}
                        icon={
                          <FaTextHeight
                            style={{
                              marginRight: "5px",
                              color: "var(--main)",
                            }}
                          />
                        }
                        disabled={disableField}
                      />
                      <div className="err">{errors.sender_name?.message}</div>
                    </FormControl>
                  )}
                />
              </Grid>

              {/* <Grid item xs={12}>
                <Controller
                  name="sender_email"
                  error={errors.sender_email}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                    >
                     
                      <InputField
                      label="Sender's Email"
                      
                        name="sender_email"
                        {...field}
                        error={errors.sender_email}
                        id="sender_email"
                        type="email"
                        labelWidth={150}
                        icon={
                          <FaTextHeight
                            style={{
                              marginRight: "5px",
                              color: "var(--main)",
                            }}
                          />
                        }
                        disabled={disableField}
                      />
                      <div className="err">
                        {errors.sender_email && errors.sender_email?.message}
                      </div>
                    </FormControl>
                  )}
                />
              </Grid> */}

<Grid item xs={12}>
                {/* <Controller
                  name="sender_email"
                  error={errors.sender_email}
                  control={control}
                  defaultValue=""

                  render={({ field }) => (
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="sender_email">
                        <GoMailRead
                          style={{
                            marginRight: "5px",
                            color: "var(--main)",
                          }}
                        />
                        Sender's Email
                      </InputLabel>
                      <InputField
                        // error={isSenderEmailValid}
                        name="sender_email"
                        // {...field}
                        // error={errors.sender_email}
                        id="sender_email"
                        type="email"
                        labelWidth={150}
                        icon={
                          <FaTextHeight
                            style={{
                              marginRight: "5px",
                              color: "var(--main)",
                            }}
                          />
                        }
                        disabled={disableField}
                      />
                      <div className="err">
                        {errors.sender_email && errors.sender_email?.message}
                      </div>
                    </FormControl>
                  )}
                /> */}

                <InputField
                  style={{ width: "100%" }}
                  // error={isSenderEmailValid}
                  name="sender_email"
                  // {...field}
                  // error={errors.sender_email}
                  label="Sender's Email"
                  required
                  id="sender_email"
                  type="email"
                  pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                  labelWidth={150}
                  error={sendermssgerror}
                  value={sendermssg}
                  onChange={handleChange}
                  icon={
                    <FaTextHeight
                      style={{
                        marginRight: "5px",
                        color: "var(--main)",
                      }}
                    />
                  }
                  disabled={disableField}
                />
              </Grid>


              <Grid item xs={12}>
                <div className="phoneTopic">Sender Phone Number</div>
                <PhoneNumber
                  value={sender_phone_no}
                  setValue={setSender_phone_no}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <div style={{ fontSize: "18px", fontWeight: "500" }}>
                  Are you the pickup contact?
                </div>
                <div style={{ fontSize: "12px" }}>
                  Is the item being collected from you, or someone else?
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="gender"
                    defaultValue={isPickup}
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="true"
                      onClick={() => {
                        setIsPickup("true");
                        const data = getValues(["sender_email", "sender_name"]);

                        setValue("pickup_email", sendermssg, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        setValue("pickup_name", data[1], {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        setPickup_phone_no(sender_phone_no);
                      }}
                      control={<GreenRadio />}
                      label="Yes"
                    />

                    <FormControlLabel
                      value="false"
                      onClick={() => {
                        setIsPickup("false");

                        const data = getValues(["sender_email", "sender_name"]);

                        setValue("pickup_email", "", {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        setValue("pickup_name", "", {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        setPickup_phone_no("");
                      }}
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {isPickup === "true" ? null : (
                <>
                  <Grid item xs={12}>
                    <div className="txt">Pickup Contact</div>
                    <Controller
                      name="pickup_name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          className={classes.formControl}
                          variant="outlined"
                        >
                          {/* <InputLabel htmlFor="pickup_name">
                            <FaEdit
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                            Pickup's Name
                          </InputLabel> */}
                          <InputField
                          label="Pickup's Name"
                            {...field}
                            // error={isPickNameValid}
                            error={errors.pickup_name}
                            name="pickup_name"
                            id="pickup_name"
                            type="text"
                            labelWidth={150}
                            icon={
                              <FaTextHeight
                                style={{
                                  marginRight: "5px",
                                  color: "var(--main)",
                                }}
                              />
                            }
                          />
                          <div className="err">
                            {errors.pickup_name?.message}
                          </div>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name="pickup_email"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          className={classes.formControl}
                          variant="outlined"
                        >
                          {/* <InputLabel htmlFor="pickup_email">
                            <GoMailRead
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                            Pickup's Email(optional)
                          </InputLabel> */}
                          <InputField
                          label="Pickup's Email(optional)"
                            {...field}
                            // error={isPickEmailValid}
                            error={errors.pickup_email}
                            name="pickup_email"
                            id="pickup_email"
                            type="email"
                            labelWidth={150}
                            icon={
                              <FaTextHeight
                                style={{
                                  marginRight: "5px",
                                  color: "var(--main)",
                                }}
                              />
                            }
                          />
                          <div className="err">
                            {errors.pickup_email?.message}
                          </div>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div className="phoneTopic">Pickup Phone</div>
                    <PhoneNumber
                      value={pickup_phone_no}
                      setValue={setPickup_phone_no}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={8}>
                <div style={{ fontSize: "18px", fontWeight: "500" }}>
                  Are you the receiver?
                </div>
                <div style={{ fontSize: "12px" }}>
                  Are you the receiver of the item?
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl component="fieldset">
                  <RadioGroup
                    defaultValue={isReciever}
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="true"
                      onClick={() => {
                        setIsReciever("true");
                        const data = getValues(["sender_email", "sender_name"]);

                        setValue("delivery_email", sendermssg, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        setValue("delivery_name", data[1], {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        setDelivery_phone_no(sender_phone_no);
                      }}
                      control={<GreenRadio />}
                      label="Yes"
                    />

                    <FormControlLabel
                      value="false"
                      onClick={() => {
                        setIsReciever("false");
                        const data = getValues(["sender_email", "sender_name"]);
                        setValue("delivery_email", "", {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        setValue("delivery_name", "", {
                          shouldValidate: true,
                          shouldDirty: true,
                        });

                        setDelivery_phone_no("");
                      }}
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {isReciever === "true" ? null : (
                <>
                  <Grid item xs={12}>
                    <div className="txt">Receiver's Contact</div>

                    <Controller
                      name="delivery_name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          className={classes.formControl}
                          variant="outlined"
                        >
                          {/* <InputLabel htmlFor="delivery_name">
                            <FaEdit
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                            Receiver's Name
                          </InputLabel> */}
                          <InputField
                          label="Receiver's Name"
                            {...field}
                            name="delivery_name"
                            id="delivery_name"
                            error={errors.delivery_name}
                            type="text"
                            labelWidth={150}
                            icon={
                              <FaTextHeight
                                style={{
                                  marginRight: "5px",
                                  color: "var(--main)",
                                }}
                              />
                            }
                          />
                          <div className="err">
                            {errors.delivery_name?.message}
                          </div>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name="delivery_email"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <FormControl
                          className={classes.formControl}
                          variant="outlined"
                        >
                          {/* <InputLabel htmlFor="delivery_email">
                            <GoMailRead
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                            Receiver's Email(optional)
                          </InputLabel> */}
                          <InputField
                          label="Receiver's Email(optional)"
                            // error={isDeliveryEmailValid}
                            {...field}
                            name="delivery_email"
                            id="delivery_email"
                            error={errors.delivery_email}
                            type="email"
                            labelWidth={150}
                            icon={
                              <FaTextHeight
                                style={{
                                  marginRight: "5px",
                                  color: "var(--main)",
                                }}
                              />
                            }
                          />
                          <div className="err">
                            {errors.delivery_email?.message}
                          </div>
                        </FormControl>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div className="phoneTopic">Receiver's Phone Number</div>
                    <PhoneNumber
                      value={delivery_phone_no}
                      setValue={setDelivery_phone_no}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} sm={6}>
                <div style={{ fontSize: "18px", fontWeight: "500" }}>
                  Who is paying for this delivery?
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset">
                  <RadioGroup
                    defaultValue={payer}
                    row
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="sender"
                      onClick={() => {
                        setPayer("sender");
                      }}
                      control={<GreenRadio />}
                      label="Sender"
                    />

                    <FormControlLabel
                      value="receiver"
                      onClick={() => {
                        setPayer("receiver");
                      }}
                      control={<GreenRadio />}
                      label="Reciever"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>



              <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <div className="txt">Payment option</div>



                        <Controller
          name="mode_of_payment"
          control={control}
          render={({ field }) => (
            <ReactSelect
              // defaultValue={options[0]}
              {...field}
              // isClearable  enable isClearable to demonstrate extra error handling
              isSearchable={false}
              className="react-dropdown"
              classNamePrefix="dropdown"
              options={options}
              // value={modeofpayment}  
              // onChange={(e)=> setmodeofpayment(e)} 



              placeholder="Select Payment Option" styles={customStyles}
            />
          )}
        />

<div className="err">{ errors?.mode_of_payment?.value?.message}</div>

{/* 
                        <Controller
                          name="delivery_email"
                          control={control}
                          defaultValue=""
                          // as={Select}
                          options={[{ value: "", label: "Select Device Type" }, ...options]}
                          rules={{ required: true }}
                          render={({ field }) => (
                         

<FormControl variant="outlined" style={{ width: "100%" }}>
      
      <ReactSelect   name="delivery_email"
                                  id="delivery_email"  error={errors.delivery_email} placeholder="Select Payment Option" value={modeofpayment}  onChange={(e)=> setmodeofpayment(e)}  options={options} styles={customStyles} />
    <div className="err">
                                  {errors.delivery_email?.message}
                                </div>
        </FormControl>
                          )}
                        /> */}















{/* <Controller
                name="mode_of_payment"
                // as={Select}
                defaultValue=""
                options={[{ value: "", label: "Select Device Type" }, ...options]}
                control={control}
                rules={{ required: true }}
              />
              {errors.mode_of_payment && errors.mode_of_payment.type === "required" ? (
                <div>
                  <span className="text-danger">
                    {"Device Type is required"}
                  </span>
                </div>
              ) : null} */}



                      </Grid>

                      {/* <Grid item xs={12}>
                      {errors.mode_of_payment && errors.mode_of_payment.type === "required" ? (
                <div>
                  <span style={{color: "red"}} className="text-danger">
                    Mode_of_payment is required
                  </span>
                </div>
              ) : null}
                      </Grid> */}

                     
                    </Grid>



              {/* <Grid item xs={12}>
                <span
                  style={{
                    fontSize: "14px",
                    color: "red",
                    fontWeight: "600",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <AiFillCloseCircle /> Cash payment is not allowed
                </span>
              </Grid> */}

              <Grid container spacing={12}>
                <div
                  style={{
                    paddingTop: "10px",
                    textAlign: "justify",
                    fontSize: "13px",
                  }}
                >
                  By proceeding I agree to Dillivry{" "}
                  <Link
                    onClick={() => {
                      setPolicyShow(true);
                      setpageToShow("terms");
                    }}
                    style={{ color: "var(--main)" }}
                  >
                    Terms of Use
                  </Link>{" "}
                  and acknowledge that I have read the{" "}
                  <Link
                    onClick={() => {
                      setPolicyShow(true);
                      setpageToShow("privacy");
                    }}
                    style={{ color: "var(--main)" }}
                  >
                    Privacy Policy
                  </Link>
                  .
                </div>
              </Grid>
              <Grid container spacing={12}>
                <div
                  style={{
                    paddingTop: "10px",
                    textAlign: "justify",
                    fontSize: "13px",
                  }}
                >
                  I also agree that Dillivry or its representative may contact
                  me by email, phone or SMS (including by automated means) at
                  the email address or number i provide, including for marketing
                  purposes.
                </div>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6} style={{ marginTop: "20px" }}>
                  <Button
                    size="small"
                    variant="contained"
                    type="button"
                    style={{
                      backgroundColor: "var(--main)",
                      width: "100%",
                      marginTop: "0px",
                      color: "white",
                      borderRadius: "5px",
                      padding: "10px",
                      textTransform: "none",
                    }}
                    onClick={previous}
                  >
                    <span className="btnlabel">Previous </span>
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "20px" }}>
                  {isloading ? (
                    <Button
                      size="small"
                      variant="contained"
                      type="button"
                      disabled
                      style={{
                        backgroundColor: "var(--main)",
                        width: "100%",
                        marginTop: "0px",
                        color: "white",
                        borderRadius: "5px",
                        padding: "10px",
                        textTransform: "none",
                      }}
                    >
                      {/* <ClipLoader color='white' loading={isloading}  speedMultiplier={1} size={20}/>   */}
                      <PuffLoader
                        color="white"
                        loading={isloading}
                        speedMultiplier={1}
                        size={24}
                      />
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      type="submit"
                      style={{
                        backgroundColor: "var(--main)",
                        width: "100%",
                        color: "white",
                        borderRadius: "5px",
                        padding: "10px",
                        textTransform: "none",
                      }}
                    >
                      <span className="btnlabel">Request a delivery</span>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </form>
      <DialogBox
        openPopup={openDialog}
        setopenPopup={setDialog}
        head="State delivery time frame"
      >
        <ButtonCard
          onClick={() => {
            setDelivery_time_frame("Within a week");
            setDelivery_time_frameduplicate("Within a week");
            setOpenDialog(false);
          }}
          text="Within a week"
        />
        <ButtonCard
          onClick={() => {
            setDelivery_time_frame("Within 2 week");
            setDelivery_time_frameduplicate("Within 2 week");
            setOpenDialog(false);
          }}
          text="Within 2 weeks"
        />
        <ButtonCard onClick={handleOpenDate} text="On a specific date" />
        <ButtonCard onClick={setRange} text="Within a specific dates " />
        <ButtonCard
          onClick={() => {
            setDelivery_time_frame("Don't have a date yet");
            setDelivery_time_frameduplicate("Don't have a date yet");
            setOpenDialog(false);
          }}
          text="Don't have a date yet"
        />
        {/* <DateRanger/> */}
      </DialogBox>

      <Dialogsummary openPopup={showsummary} setopenPopup={setshowsummary}>
        {previewdata.map((item, index) => {
          return (
            <div key={index}>
              <div className="heading">
                
                <div className="heading__title">
                  <h1 className="heading__title__tag">Request summary</h1>
                </div>

                <div className="heading__sub__title">
                  <p className="heading__subtitle__tag">
                    Review your request details before submission
                  </p>
                </div>
              </div>

              <div className="summary__content">
                <Grid item xs={4}>
                  <div key={index}>

                    {item.image_files.length === 0 ? (
                      <>
                        
                      </>
                    ) : (
                      <div className="forPhoto">
                        <div
                          className="border"
                          style={{
                            backgroundImage: `url(data:image/png;base64,${item.image_files[0]})`,
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                </Grid>

                <div className="summary__content__main">
                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Category</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.category?.charAt(0)?.toUpperCase() +
                          item?.category?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Pickup address</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.pickup_address?.charAt(0)?.toUpperCase() +
                          item?.pickup_address?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Delivery address</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.delivery_address?.charAt(0)?.toUpperCase() +
                          item?.delivery_address
                            ?.replaceAll("_", " ")
                            ?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Item description</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.item_desc?.charAt(0)?.toUpperCase() +
                          item?.item_desc?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Mode of delivery</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.mode_of_delivery?.charAt(0)?.toUpperCase() +
                          item?.mode_of_delivery
                            ?.replaceAll("_", " ")
                            ?.slice(1)}
                      </h1>
                    </div>
                  </div>
                  {
                    console.log(item, "itemmm")
                  }


                  {
                    item?.item_dim_hei &&

                    <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main"> Height</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.item_dim_hei === " "
                          ? "NA"
                          : item?.item_dim_hei?.charAt(0)?.toUpperCase() +
                            item?.item_dim_hei?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>
                  }


                  {
                    item.item_dim_len &&

                    <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Length</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.item_dim_len === " "
                          ? "NA"
                          : item?.item_dim_len?.charAt(0)?.toUpperCase() +
                            item?.item_dim_len?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>

                  }



                  {


                    item?.item_dim_wid &&

                    <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main"> Width</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.item_dim_wid === " "
                          ? "NA"
                          : item?.item_dim_wid?.charAt(0)?.toUpperCase() +
                            item?.item_dim_wid?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>


                  }



                  {

                  item?.item_weight &&
                  

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Weight</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.item_weight === " "
                          ? "NA"
                          : item?.item_weight?.charAt(0)?.toUpperCase() +
                            item?.item_weight?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div> 
                  
                  }

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Are you the receiver?</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {isReciever === "true" ? "Yes" : "No"}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Receiver’s full name</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">{item?.delivery_name}</h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Receiver’s phone number</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.delivery_phone_no}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">
                        Are you the pickup contact?
                      </h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {isPickup === "true" ? "Yes" : "No"}
                      </h1>
                    </div>
                  </div>

                  <div className="summary_category">
                    <div className="sum_category">
                      <h1 className="sum_cat_main">Who is paying?</h1>
                    </div>

                    <div className="sum__cate__content">
                      <h1 className="sum_cat_main_sub">
                        {item?.payer?.charAt(0)?.toUpperCase() +
                          item?.payer?.replaceAll("_", " ")?.slice(1)}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="submit__and__edit">
                <div>
                  {" "}
                  <button
                    className="edit-icon-btn"
                    onClick={() => {
                      previous();
                      closemodalandbacktopageone();
                    }}
                  >
                    {" "}
                    <img src={edit} alt="edit-icon" />{" "}
                  </button>
                </div>

                <div>
                  {" "}
                  <button
                    className="submit-btn"
                    onClick={handleSubmit(submitafterPreview)}
                    disabled={isloading}
                  >
                    {" "}
                    {isloading ? "Submiting..." : "Submit"}{" "}
                  </button>
                </div>
              </div>
            </div>
          );
        })}

      </Dialogsummary>

      <DialogBox
        openPopup={openDateRange}
        setopenPopup={setRange}
        // head="State dalivery time frame"
      >
        <DateRanger
          onChange={(item) => setRanges([item.selection])}
          ranges={range}
          minDate={getValues("pickup_date")}
        />
        <ButtonCard
          onClick={() => {
            if (
              `${range[0].startDate.getFullYear()}-${range[0].startDate.getMonth()}-${range[0].startDate.getDate()}` ===
              `${range[0].endDate.getFullYear()}-${range[0].endDate.getMonth()}-${range[0].endDate.getDate()}`
            ) {
              // setDelivery_time_frame(`${range[0].startDate.getFullYear()}/${(range[0].startDate.getMonth() + 1)}/${range[0].startDate.getDate()}`);

              setDelivery_time_frame(
                `${range[0].startDate.getDate()}/${(
                  range[0].startDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}/${range[0].startDate.getFullYear()}`
              );

              setDelivery_time_frameduplicate(
                `${range[0].startDate.getFullYear()}-${(
                  range[0].startDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${range[0].startDate.getDate()}`
              );
              setRange();
              setOpenDialog(false);
            } else {
              if (range[0].endDate === null) {
                setDelivery_time_frame("");
                setDelivery_time_frameduplicate("");
                setOpenDialog(false);
              } else {
                // setDelivery_time_frame(`${range[0].startDate.getFullYear()}/${(range[0].startDate.getMonth() + 1).toString().padStart(2, "0")}/${range[0].startDate.getDate()} - ${range[0].endDate.getFullYear()}/${range[0].endDate.getMonth() + 1}/${range[0].endDate.getDate()}`);
                setDelivery_time_frame(
                  `${range[0].startDate.getDate()}/${(
                    range[0].startDate.getMonth() + 1
                  )
                    .toString()
                    .padStart(
                      2,
                      "0"
                    )}/${range[0].startDate.getFullYear()} -  ${range[0].endDate.getDate()}/${(
                    range[0].endDate.getMonth() + 1
                  )
                    .toString()
                    .padStart(2, "0")}/${range[0].endDate.getFullYear()}`
                );

                setDelivery_time_frameduplicate(
                  `${range[0].startDate.getFullYear()}-${
                    range[0].startDate.getMonth() + 1
                  }-${range[0].startDate.getDate()} - ${range[0].endDate.getFullYear()}-${
                    range[0].endDate.getMonth() + 1
                  }-${range[0].endDate.getDate()}`
                );
                setRange();
                setOpenDialog(false);
              }
            }
          }}
          text="Ok"
        />
      </DialogBox>
      <DialogBox
        openPopup={openDate}
        setopenPopup={handleOpenDate}
        // head="State dalivery time frame"
      >
        <Calender
          minDate={getValues("pickup_date")}
          onChange={(item) => {
            // setDelivery_time_frame(`${item.getFullYear()}/${item.getMonth() + 1}/${item.getDate()}`);
            setDelivery_time_frame(
              `${item.getDate()}/${(item.getMonth() + 1)
                .toString()
                .padStart(2, "0")}/${item.getFullYear()}`
            );
            setDelivery_time_frameduplicate(
              `${item.getFullYear()}-${item.getMonth() + 1}-${item.getDate()}`
            );
            setOpenDate(false);
            setOpenDialog(false);
          }}
        />
      </DialogBox>

      <Popup trigger={ShowLogin} setTrigger={setShowLogin}>
        <LoginComponent onFinish={() => setShowLogin(false)} />
      </Popup>
      <PrivacyDialog
        open={policyShow}
        onClick={() => setPolicyShow(false)}
        pageToShow={pageToShow}
        title={
          pageToShow === "terms" ? "Terms and condition" : "Privacy policy"
        }
      ></PrivacyDialog>

      {/* <DialogPop
          openPopup={ShowLogin}
          setopenPopup={setShowLogin}
          head="Login"
        >
          <LoginComponent onFinish={()=>setShowLogin(false)}/>
        </DialogPop> */}
    </>
  );
}

export default SendHaulage;
