import {useState} from 'react';
import './Registration.css'
import TopNavigation from '../../components/Reg_components/TopNavigation'
import TitleText from '../../components/Reg_components/TitleText'
import RegCard from '../../components/Reg_components/RegCard'

import Ful_Individual from '../../assets/Ful_Individual.png'
import Ful_Business from '../../assets/Ful_Business.png'

import LoginTopMenu from '../../components/topNav/LoginTopMenu';
import {useHistory} from 'react-router-dom'
import lady from "../../assets/lady.png";
import {Helmet} from 'react-helmet'
import GlobalizationLanguage from '../../components/GlobalizationLanguage';
function RegistrationBlade() {
    const history = useHistory()
var logistics_companies = GlobalizationLanguage("/logistics-companies")
var riders_and_drivers = GlobalizationLanguage("/riders-and-drivers")
var sender_signup = GlobalizationLanguage("/sender-signup")

    return (
        <>
        <Helmet>
            <title>Dillivry - Become a rider, driver or logistics company</title>
            <meta charSet="utf-8" />
            <meta
                name="description"
                content="Partner with us and start earning. With thousands of daily delivery jobs posted every minute, there is no shortage of opportunities."  
            />
        </Helmet>
        <div className='registration'>
            <div className="topDesktop">
              <LoginTopMenu/>  
            </div>
            
            <div className="afterReg">
                
                <div className="pageTitle">  
                    <div className="pageTag" style={{fontSize:"20px"}}>
                    Provider or Sender ?
                    </div>
                    <div className="pageSubtage">
                   
                    Choose how you'd like to operate on Dillivry.
                    </div>
                </div>
                <div className="formSide">
                    {/* first page start here */}
                    <div className="regCont">
                        <TopNavigation onBackClick={()=> history.push('/')} help='.'/>
                        <TitleText head=" Provider or Sender ?" 
                            subHead="Choose how you'd like to operate on Dillivry." 
                            // subHead2='Logistics provider? Select provider to continue.' 
                            margin_base="90px" />
                            
                            {/* updated by muyiwa start here */}
                            {/* <div onClick={()=>history.push('/sender-signup')} className='regCard' style={{backgroundImage: "linear-gradient(to top right,rgba(40,167,86, 1), rgba(156,224,95,1) )"}}>
                                <div className="left">
                                    <div className="leftTop">
                                        Sender
                                    </div>
                                    <div style={{ marginTop:"10px"}}
                                     className="leftBottom"
                                     >
                                        <div className="selectqb">
                                           
                                        <button className="selectqbutton" onClick={()=>history.push('/sender-signup')}>Sender signup</button>&nbsp;&nbsp;&nbsp;
                                    
                                       
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    <img src={lady} alt="Dillivry" style={{ }} />
                                </div>

                            </div> */}
                            {/* updated by muyiwa ends here*/}

                            <RegCard
                                onClick={()=>history.push(sender_signup)}
                                bgColor = "linear-gradient(to top right,rgba(40,167,86, 1), rgba(156,224,95,1) )"
                                Title = "Sender"
                                Subtitle = "I have item to send."
                                image = {lady}
                                alt='myself'
                            />

                            <RegCard
                                onClick={()=>history.push(riders_and_drivers)}
                                bgColor = "linear-gradient(to top right,rgba(40,167,86, 1), rgba(156,224,95,1) )"
                                Title = "Individual"
                                Subtitle = "I want to make deliveries myself."
                                image = {Ful_Individual}
                                alt='myself'
                            />
                            <RegCard
                                onClick={()=>history.push(logistics_companies)}
                                bgColor = "linear-gradient(to bottom right,rgba(40,167,86, 1), rgba(156,224,95,1) )"
                                Title = "Corporate"
                                Subtitle = "I have a logistic company."
                                image = {Ful_Business}
                                alt='business'
                            />
                    </div>
                </div>
            </div>
        </div>
        </>
        
    )
}
export default RegistrationBlade
