import React, { useEffect } from 'react'
import './style.css'
import Apple from './Assets/applelogo.svg'
import Google from './Assets/Googlebrand.svg'
import Phone from './Assets/PhoneWithBackground.svg'
import ThumbUp from './Assets/ThumbsUp.gif'
import { useHistory } from "react-router-dom";
import GlobalizationLanguage from '../../components/GlobalizationLanguage'
import SecureLS from 'secure-ls'
import $ from 'jquery'

const ThankYou = () => {

  const history = useHistory();

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

    var viewQuote = GlobalizationLanguage("/viewQuote")

    useEffect(() => {
        if(process.env.REACT_APP_ENVIRONMENT === 'PROD'){    
            $("head").append(`<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NBCF4V4');</script>`);
      
            $("body")
              .append(`<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NBCF4V4"
              height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`);
          }
    }, [])
    

    return (
        <>
            <section className="containersection">
                <section className="innercontaner">
                    <section className="left_sect">
                        <div className="top_text">
                            <h1 className="top_title">
                                For instant alert and notification of quotes please download the
                                Dillivry app.
                            </h1>
                        </div>

                        <div className="download_buttons">
                            <div className="applebtn">
                                <a href="https://apps.apple.com/us/app/dillivry/id1604161862"
                                    title="Dillivry on appStore" target="_blank" >
                                    <img src={Apple} />
                                </a>
                            </div>
                            <div className="googlebtn">
                            <a href="https://play.google.com/store/apps/details?id=datamellon.app_mobile"
                                    title="Dillivry on playStore" target="_blank" >
                                <img src={Google} />
                                </a>
                            </div>
                        </div>
                    </section>
                    <section className="right_sect">
                        <div className="phone_img">
                            <img src={Phone} />
                        </div>
                    </section>
                </section>
            </section>
            <section className="contenter">
                <div className="circle_image">
                    <img src={ThumbUp} />
                </div>
                <div className="content_writing">
                    <p>Thank you for making a request on Dillivry, we are currently connecting you to</p> 
                    logistics providers who will compete by submitting their quotes for your request.
                </div>
                <div onClick={()=>{
                    if( ls.get("_utk") !== "" && ls.get("eid") !== ""){
                        history.push({
                            pathname: viewQuote,
                            state: "from_requestDelivery",
                          });
                    }
                    else{
                        history.push(`/login`);
                    }
                    
                }}>
                    <button type="button">OK</button>
                </div>
            </section>
        </>
    )
}

export default ThankYou