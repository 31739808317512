import React,{useState, useEffect} from 'react'
import "./SendQuote.css";
import { makeStyles } from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';

import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import SendGeneral from './SendGeneral';
import SendHaulage from './SendHaulage';
import SendHome from './SendHome';
import { useHistory, useLocation } from "react-router-dom";
import {Helmet} from 'react-helmet'
import CategorySelector from '../../components/CategorySelector';
import TopBar from '../../components/TopBar/TopBar';
import GooglePlaceAutocomplete from '../../components/GooglePlaceAutocomplete';

function SendQuote() {

    const location = useLocation()
    
    useEffect(() => {
        window.scrollTo(0, 0)
            if (location.state !== undefined){
                setitem_category(location?.state?.category);
                setmain_item_category(location?.state?.main_category)
            }
      }, []);

      window.onbeforeunload = function () {
        window.scrollTo(0, 0);
      }
   
      const [pickup_address, setPickup_address] = useState("");
    const [delivery_address, setDelivery_address] = useState("");
    const [pickupIsError, setpickupIsError] = useState(false);
    const [deliveryIsError, setdeliveryIsError] = useState(false);
    const [deli_locatn_frm_pickup_add, setdeli_locatn_frm_pickup_add] = useState('')


    const [item_category, setitem_category] = useState('');
    const [main_item_category, setmain_item_category] = useState('');

    const handleChange = (e) => {
        setitem_category(e.target.value);
        setcatIsValid(false)
    };

    const useStyles = makeStyles((theme) => ({
        formControl: {
          minWidth: `100%`,
        }
        
      }));

    const [page, setPage] = useState(1);

    const [catIsValid, setcatIsValid] = useState(false);
    const formContainer = document.getElementById("getQForm")
    return (
        <div>
            <Helmet>
                <title>Dillivry - Request a delivery | Get free quotes in mins</title>
                <meta charSet="utf-8" />
                <meta
                    name="description"
                    content="Request a delivery and receive multiple instant quotes from our logistics partners. Review the quotes and accept the best price."  
                />
            </Helmet>

            <TopBar/>

            <div className="bgx">
                <div className="page">
                    <div className="form_parent" style={{height:'auto'}}>
                        <div className="getQuoteForm"  id="getQForm">
                            {
                                page === 1 ?
                                <>
                                <Grid container spacing={2}>
                                <Grid item xs={12}>
                                        <GooglePlaceAutocomplete
                                            handleChange={(pickup_address) => {
                                            setPickup_address(pickup_address);
                                            setpickupIsError(true);
                                            }}
                                            handleSelect={(pickup_address) => {
                                             setPickup_address(pickup_address);
                                            setpickupIsError(false);
                                            }}
                                            setdeli_locatn_frm_pickup_add = {setdeli_locatn_frm_pickup_add}
                                            value={pickup_address}
                                            text= {item_category === 'Moving home' ? "Address moving from" :"Enter pick up address"}
                                            color="var(--main)"
                                            error={pickupIsError}
                                            pickup
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <GooglePlaceAutocomplete
                                            handleChange={(delivery_address) => {
                                            setDelivery_address(delivery_address);
                                            setdeliveryIsError(true);
                                            }}
                                            handleSelect={(delivery_address) => {
                                             setDelivery_address(delivery_address);
                                            setdeliveryIsError(false);
                                            }}
                                            value={delivery_address}
                                            text= {item_category === 'Moving home' ? "Address moving to" : "Enter delivery address"}
                                            color="red"
                                            error={deliveryIsError}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{marginBottom:'16px'}}>
                                         <CategorySelector country={deli_locatn_frm_pickup_add} setmainCategory={setmain_item_category}  setitem_category={setitem_category}  categorySelected={ item_category === "" ? location?.state?.category : item_category}/>
                                    </Grid>

                                </Grid>
                                </>
                                :
                                null
                            }
                            
                            { 
                                 item_category === 'Haulage' ? 
                                    <SendHaulage
                                        location={location?.state}
                                        itemCategory={item_category}  

                                        delivery_location = {deli_locatn_frm_pickup_add}
                                        pickup_address = {pickup_address}
                                        delivery_address = {delivery_address}
                                        pickupIsError = {pickupIsError}
                                        deliveryIsError = {deliveryIsError}
                                        setpickupIsError = {setpickupIsError}
                                        setdeliveryIsError = {setdeliveryIsError}

                                        onClick={()=>{
                                            setPage(page+1);
                                            formContainer.scrollTo(0,0)
                                        }}
                                        previous={()=>{
                                            setPage(1);
                                            formContainer.scrollTo(0,0)
                                        }}
                                        page={page}
                                    />
                                    :
                                        item_category === 'Moving home'  ? 
                                        <SendHome
                                            // location={location?.state}
                                            itemCategory={item_category} 

                                            delivery_location = {deli_locatn_frm_pickup_add}
                                            pickup_address = {pickup_address}
                                            delivery_address = {delivery_address}
                                            pickupIsError = {pickupIsError}
                                            deliveryIsError = {deliveryIsError}
                                            setpickupIsError = {setpickupIsError}
                                            setdeliveryIsError = {setdeliveryIsError} 

                                            onClick={()=>{
                                                setPage(page+1);
                                                formContainer.scrollTo(0,0)
                                            }}
                                            previous={()=>{
                                                setPage(1);
                                                formContainer.scrollTo(0,0)
                                            }}
                                            page={page}
                                        />
                                        :
                                        <SendGeneral 
                                            location={location?.state}
                                            itemCategory={item_category}
                                            
                                            delivery_location = {deli_locatn_frm_pickup_add}
                                            pickup_address = {pickup_address}
                                            delivery_address = {delivery_address}
                                            pickupIsError = {pickupIsError}
                                            deliveryIsError = {deliveryIsError}
                                            setpickupIsError = {setpickupIsError}
                                            setdeliveryIsError = {setdeliveryIsError}

                                            onClick={()=>{
                                                setPage(page+1);
                                                formContainer.scrollTo(0,0)
                                                // console.log()
                                            }}
                                            previous={()=>{
                                                setPage(1);
                                                formContainer.scrollTo(0,0)
                                            }}
                                            page={page}
                                            main_category={main_item_category}
                                        />
                                    }
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default SendQuote
