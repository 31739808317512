import {createContext, useReducer} from 'react';
import searchReducer, { INITIAL_STATE } from './search.reducer';
import searchTypes from './search.types';


export const SearchContext = createContext({
    ...INITIAL_STATE
  });

  const SearchProvider = ({ children }) => {
    const [store, dispatch] = useReducer(searchReducer, INITIAL_STATE);
    const { searches } = store;
  
    const updateSearch = search => {
      dispatch({
        type: searchTypes.GET_DEFAULT_SEARCH_DATA,
        payload: search
      });
    };

    const addTowatch = add => {
      dispatch({
        type: searchTypes.ADD_To_WATCH,
        payload: add
      });
    };

    const removeFromWatch = remove => {
      dispatch({
        type: searchTypes.REMOVE_FROM_WATCH,
        payload: remove
      });
    };
    
    return (
      <SearchContext.Provider value={{
        updateSearch,
        addTowatch,
        removeFromWatch,
        searches
      }}>
        {children}
      </SearchContext.Provider>
    );
  };
  
  export default SearchProvider;
  