
import { useContext, useEffect, useState } from 'react'
import SmallBtn from '../../../components/SmallBtn'
import '../../Fulfiller/Dashboard/Dashboard.css'
import { useForm, Controller, set } from "react-hook-form";
import { Select, MenuItem, Button, FormControl, InputLabel, Grid } from '@material-ui/core';
import InputField from '../../../components/InputField'
import { FaTextHeight } from 'react-icons/fa';
import { makeStyles } from '@material-ui/core/styles';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { HiOutlineMinusCircle } from 'react-icons/hi';
import { HiPencilAlt } from 'react-icons/hi';

import PuffLoader from 'react-spinners/PuffLoader';
import axios from 'axios';
import Swal from "sweetalert2"
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import SecureLS from 'secure-ls';
import { ConnContext } from '../../../context/ConnContext';


function EditQuote({ delivery_data, handleChange }) {
    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
    const {visitingCountryCode} = useContext(ConnContext)
    const [imageToDelete, setimageToDelete] = useState([]);
    const [imageToEdit, setimageToEdit] = useState([]);
    const [image_filesDup, setImage_filesDup] = useState([]);

    useEffect(() => {
        setImage_files(delivery_data.image_urls)
        setImage_filesDup(delivery_data.image_urls)
        if (delivery_data.image_urls.length === 0) {
            setShowImageSpace(false)
        }
        else {
            setShowImageSpace(true)
        }
    }, [])

    const endPoint = process.env.REACT_APP_API

    const [isloading, setisloading] = useState(false)


    const submitEdit = async (data) => {
        if (data.image_files === undefined) {
            data.image_files = "";
        }
        if (data.item_dim_hei === undefined) {
            data.item_dim_hei = "";
        }
        if (data.item_dim_len === undefined) {
            data.item_dim_len = "";
        }
        if (data.item_dim_wid === undefined) {
            data.item_dim_wid = "";
        }
        if (data.item_weight === undefined) {
            data.item_weight = "";
        }
        data.image_files = imageToEdit;
        data.item_dim_hei = data.item_dim_hei + ' ' + dimensionUnit;
        data.item_dim_len = data.item_dim_len + ' ' + dimensionUnit;
        data.item_dim_wid = data.item_dim_wid + ' ' + dimensionUnit;
        data.item_weight = data.item_weight + ' ' + dimensionUnitWie;
        data.action_type = 'update'
        data.sender_email = ls.get('eid')
        data.delivery_id = delivery_data.delivery_id
        data.to_be_deleted = imageToDelete;
        data.vcc = visitingCountryCode

        // console.log(data, "data")

        setisloading(true);
        await axios.post(`${endPoint}m-dillivry-listing`,
            data
        )
            .then((response) => {
                // setisloading(false)
                // handleChange()
                // window.location.reload()

                if(response.data.status === "success"){
                    Swal.fire({
                        icon: 'success',
                        text: 'Changes saved',
                        showConfirmButton: false,
                        timer: 2500
                    })
                     setisloading(false)
                   
                    // window.location.reload()

                    setInterval(() => {
                        handleChange()
                        window.location.reload()
                        setisloading(false)
                    }, 2501);
                }



            })
            .catch((error) => {
                //console.log(error)
                if (error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Ooopz, an error occur',
                    });
                    setisloading(false)
                }

            })

    }

    const [image_files, setImage_files] = useState([]);
    const [sendImage, setSendImage] = useState([])
    const [submitting, setsubmitting] = useState(false)

    const schema = yup.object().shape({
        item_weight: yup.string().matches(/^[0-9]*$/, 'Weight Has to be number'),
        item_dim_len: yup.string().matches(/^[0-9]*$/, 'Length Has to be number'),
        item_dim_wid: yup.string().matches(/^[0-9]*$/, 'Width Has to be number'),
        item_dim_hei: yup.string().matches(/^[0-9]*$/, 'Height Has to be number'),
    });
    const imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setImage_files([...image_files, reader.result])
                setSendImage([...sendImage, reader.result.split(',')[1]]);
                setimageToEdit([...imageToEdit, reader.result.split(',')[1]])

                setShowImageSpace(true)
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    const { control, trigger, register, handleSubmit, formState: { errors } } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const useStyles = makeStyles((theme) => ({

        formControl75: {
            marginBottom: theme.spacing(2),
            width: `70%`,
        },
        formControl25: {
            marginBottom: theme.spacing(2),
            width: `30%`,
        },
    }));
    const classes = useStyles();

    const [showImageSpace, setShowImageSpace] = useState(false)
    const [dimensionUnit, setDimensionUnit] = useState('')
    const [dimensionUnitWie, setDimensionUnitWie] = useState('')

    return (
        <div className="viewSearch" style={{ padding: '0px 10px' }}>
            <div className="editdesktop">
            <div className="view_Container" style={{ padding: '0 20px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0', }}>
                    <div className="div_right" style={{ paddingLeft: "80px", borderRadius: "4px", width: "215px" }}>
                        <SmallBtn
                            label='Back'
                            onClick={handleChange}
                        />

                    </div>
                </div>
                <form onSubmit={handleSubmit(submitEdit)} style={{ width: '100%' }}>
                    <div className="edititemcon">
                        <div className="edititem1">
                            Edit item
                        </div>
                        <div className="upitem1"> Upload photo</div>
                        <div className="picitem">
                            <div className="photsize" style={{ justifyContent: 'flex-start', padding: '5px 20px 0px 20px', alignItems: 'flex-start', flexDirection: 'column' }}>
                                <div className="photo" style={{ paddingTop: "25px", height: "100px" }}>

                                    <input
                                        style={{ display: 'none' }}
                                        id="image_files"
                                        name="image_files"
                                        type="file"
                                        accept="image/x-png,image/gif,image/jpeg"
                                        {...register("image_files")}
                                        onChange={imageHandler}
                                    />
                                    <div style={{ color: '#989898' }}>Browse to choose photos</div>
                                    {image_files.length !== 3 ? (
                                        <label htmlFor="image_files"
                                            className="upload" style={{ width: "100px", paddingLeft: "10px", paddingRight: "10px", fontSize: "12px" }}>
                                            Upload Photo  &nbsp;{" "}
                                        </label>

                                    ) : (
                                        <label htmlFor="image_file" className="uploaded">Upload Picture &nbsp;{" "} <FaCloudUploadAlt style={{ fontSize: '20px' }} /></label>
                                    )}
                                </div>
                            </div>
                        </div>



                        <div style={{ width: "95%", paddingLeft: "25px" }}>


                            <Grid item xs={12} style={{ marginBottom: '16px' }}>
                                <Grid container spacing={2}>

                                    {showImageSpace ? (
                                        <>
                                            {image_files.map((images, index) => {
                                                return (
                                                    <Grid item xs={4}>
                                                        <div key={index} className="forPhoto">
                                                            <div className="border" style={{ backgroundImage: `url(${images})` }}>
                                                                <span className="icon">
                                                                    <HiOutlineMinusCircle
                                                                        onClick={() => {
                                                                            const image = Object.assign([], image_files);
                                                                            
                                                                            image.splice(index, 1);
                                                                            setImage_files(image);

                                                                            const tosend = Object.assign([], sendImage);
                                                                            tosend.splice(index, 1);
                                                                            setSendImage(tosend);

                                                                            if(image_filesDup.includes(images)){
                                                                                setimageToDelete([...imageToDelete, images]);
                                                                                //console.log([...imageToDelete, images], 'item delete');
                                                                            }


                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                );
                                            })}
                                        </>
                                        // </div>
                                    ) : null}
                                </Grid>
                            </Grid>
                        </div>

                        <div style={{ paddingLeft: "20px", width: "680px" }}>


                            {/* <h2>Hrehbiiuguygbygui</h2> */}



                            <Grid container>
                                <Grid item xs={12}>
                                    <Controller
                                        name=""
                                        control={control}
                                        defaultValue=""
                                        error={errors.item_dim_wid}
                                        render={({ field }) => <FormControl className={classes.formControl75} variant="outlined">
                                            {/* <InputLabel htmlFor=""><HiPencilAlt style={{ marginRight: "5px", color: "var(--main)" }} />Brief description</InputLabel> */}
                                            <InputField
                                            label="Brief description"
                                                {...field}
                                                name=""
                                                id=""
                                                type="text"
                                                // value={item_dim_len} 
                                                // onChange={(e)=> setItem_dim_len(e.target.value)}
                                                labelWidth={400}
                                                icon={<FaTextHeight style={{ marginRight: "5px", color: "var(--main)" }} />}
                                            />
                                            {/* <div className="err">{errors.item_dim_wid?.message}</div> */}
                                        </FormControl>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <div className="unidem" style={{ width: '100%', marginTop: "10px" }}>
                            <div style={{ marginBottom: "20px" }}>
                                <Grid container>
                                    <Grid item xs={3}>Unit</Grid>
                                    <Grid item xs={9}>Dimension</Grid>
                                </Grid>
                            </div>

                            <Grid container>

                                <Grid item xs={3} >
                                    <FormControl className={classes.formControl25} variant="outlined" style={{ width: "100px" }}>
                                        <InputLabel id="unit">Unit</InputLabel>
                                        <Select

                                            labelId="unit"
                                            value={dimensionUnit}
                                            onChange={(e) => setDimensionUnit(e.target.value)}
                                            label="Unit"
                                        >
                                            <MenuItem value="centimeters">Centimeter</MenuItem>
                                            <MenuItem value="meters">Meters</MenuItem>
                                            <MenuItem value="inches">Inches</MenuItem>
                                            <MenuItem value="feet">Feet</MenuItem>
                                        </Select>
                                        

                                        {/* <div className="err">{errors.item_desc?.message}</div>  */}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={9}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Controller
                                                name="item_dim_len"
                                                control={control}
                                                defaultValue=""
                                                error={errors.item_dim_len}
                                                render={({ field }) => <FormControl className={classes.formControl75} variant="outlined">
                                                    {/* <InputLabel htmlFor="item_dim_len"><HiPencilAlt style={{ marginRight: "5px", color: "var(--main)" }} />Length</InputLabel> */}
                                                    <InputField
                                                    label="Length"
                                                        {...field}
                                                        name="item_dim_len"
                                                        id="item_dim_len"
                                                        type="text"
                                                        // value={item_dim_len} 
                                                        // onChange={(e)=> setItem_dim_len(e.target.value)}
                                                        labelWidth={90}
                                                        icon={<FaTextHeight style={{ marginRight: "5px", color: "var(--main)" }} />}
                                                    />
                                                    <div className="err">{errors.item_dim_len?.message}</div>
                                                </FormControl>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Controller
                                                name="item_dim_wid"
                                                control={control}
                                                defaultValue=""
                                                error={errors.item_dim_wid}
                                                render={({ field }) => <FormControl className={classes.formControl75} variant="outlined">
                                                    {/* <InputLabel htmlFor="item_dim_wid"><HiPencilAlt style={{ marginRight: "5px", color: "var(--main)" }} />Width</InputLabel> */}
                                                    <InputField
                                                    label="Width"
                                                        {...field}
                                                        name="item_dim_wid"
                                                        id="item_dim_wid"
                                                        type="text"
                                                        // value={item_dim_len} 
                                                        // onChange={(e)=> setItem_dim_len(e.target.value)}
                                                        labelWidth={90}
                                                        icon={<FaTextHeight style={{ marginRight: "5px", color: "var(--main)" }} />}
                                                    />
                                                    <div className="err">{errors.item_dim_wid?.message}</div>
                                                </FormControl>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Controller
                                                name="item_dim_hei"
                                                control={control}
                                                defaultValue=""
                                                error={errors.item_dim_hei}
                                                render={({ field }) => <FormControl className={classes.formControl75} variant="outlined">
                                                    {/* <InputLabel htmlFor="item_dim_hei"><HiPencilAlt style={{ marginRight: "5px", color: "var(--main)" }} />Height</InputLabel> */}
                                                    <InputField
                                                        label="Height"
                                                        {...field}
                                                        name="item_dim_hei"
                                                        id="item_dim_hei"
                                                        type="text"
                                                        // value={item_dim_len} 
                                                        // onChange={(e)=> setItem_dim_len(e.target.value)}
                                                        labelWidth={90}
                                                        icon={<FaTextHeight style={{ marginRight: "5px", color: "var(--main)" }} />}
                                                    />
                                                    <div className="err">{errors.item_dim_hei?.message}</div>
                                                </FormControl>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="unidem">
                            <Grid container>
                                <Grid item xs={3}>
                                    <FormControl className={classes.formControl25} variant="outlined" style={{ width: "100px" }}>
                                        <InputLabel id="unit">Unit</InputLabel>
                                        <Select
                                        labelId="unit"
                                        value={dimensionUnitWie} 
                                        onChange={(e)=> setDimensionUnitWie(e.target.value)} 
                                        label="Unit"
                                        >
                                        <MenuItem value= "gram">Gram</MenuItem>
                                        <MenuItem value= "kilogram">Kilogram</MenuItem>
                                        <MenuItem value= "tonnes">Tonnes</MenuItem>
                                    </Select>

                                        {/* <div className="err">{errors.item_desc?.message}</div>  */}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Controller
                                                name="item_weight"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => <FormControl className={classes.formControl75} variant="outlined">
                                                    {/* <InputLabel htmlFor="item_weight"><HiPencilAlt style={{ marginRight: "5px", color: "var(--main)" }} />Weight</InputLabel> */}
                                                    <InputField
                                                    label="Weight"
                                                        {...field}
                                                        name="item_weight"
                                                        id="item_weight"
                                                        error={errors.item_weight}
                                                        type="text"
                                                        // value={item_dim_len} 
                                                        // onChange={(e)=> setItem_dim_len(e.target.value)}
                                                        labelWidth={90}
                                                        icon={<FaTextHeight style={{ marginRight: "5px", color: "var(--main)" }} />}
                                                    />
                                                    <div className="err">{errors.item_weight?.message}</div>
                                                </FormControl>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={4}></Grid>
                                        <Grid item xs={4}></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <div>
                            {
                                isloading ?
                                    <Button
                                        size="large"
                                        variant="contained"
                                        type="submit"
                                        disabled
                                        style={{
                                            backgroundColor: "var(--main)",
                                            width: '150px',
                                            marginTop: '20px',
                                            color: "white",
                                            borderRadius: '0px',
                                            padding: '10px',
                                            textTransform: "none",
                                            marginBottom: '30px',
                                            marginLeft: "20px",
                                            height: "35px"
                                        }}
                                    >
                                        <span className="btnlabe">
                                            <PuffLoader color='white' loading={isloading} speedMultiplier={1} size={24} />
                                        </span>
                                    </Button>

                                    :
                                    <Button
                                        size="large"
                                        variant="contained"
                                        type="submit"
                                        style={{
                                            backgroundColor: "var(--main)",
                                            width: '120px',
                                            marginTop: '20px',
                                            color: "white",
                                            borderRadius: '4px',
                                            padding: '10px',
                                            textTransform: "none",
                                            marginBottom: '30px',
                                            marginLeft: "20px",
                                            height: "25px",
                                        }}
                                    >

                                        <span
                                            className="btnlabel">save changes
                                            {/* <BiCheck/> */}
                                        </span>
                                    </Button>
                            }


                        </div>
                    </div>
                    
                </form>
            </div>
            </div>
            <div className="editmobile">

            <div className="viewSearch" style={{ padding: '0px 10px' }}>

                <div className="view_Container" style={{ padding: '0 20px' }}>

                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0', }}>
                        <div className="div_right" style={{ marginLeft:"-40px", borderRadius: "4px", width: "215px" }}>
                            <SmallBtn

                                label='Back'
                                // icon={<TiArrowBack/>}
                                onClick={handleChange}
                            />

                        </div>
                        {/* <div className="div_right">
        <SmallBtn
            label='Report'
            icon={<MdReport/>}
            onClick={handleChange}
        /> 
        </div> */}
                    </div>
                    <form onSubmit={handleSubmit(submitEdit)} style={{ width: '100%' }}>
                        <div className="edititemcon" style={{width:"100%",margin:"0"}}>
                            <div className="edititem1" style={{width:"100%"}}>
                                Edit item
                            </div>
                            <div className="upitem1"> Upload photo</div>
                            <div className="picitem">
                                <div className="photsize" style={{ justifyContent: 'flex-start', padding: '5px 20px 0px 20px', alignItems: 'flex-start', flexDirection: 'column' }}>
                                    <div className="photo" style={{ paddingTop: "25px", height: "100px" }}>

                                        <input
                                            style={{ display: 'none' }}
                                            id="image_files"
                                            name="image_files"
                                            type="file"
                                            accept="image/x-png,image/gif,image/jpeg"
                                            {...register("image_files")}
                                            onChange={imageHandler}

                                        />
                                        <div style={{ color: '#989898' }}>Browse to choose photos</div>
                                        {image_files.length !== 3 ? (
                                            <label htmlFor="image_files"
                                                className="upload" style={{ width: "100px", paddingLeft: "10px", paddingRight: "10px", fontSize: "12px" }}>
                                                Upload Photo  &nbsp;{" "}
                                                {/* &nbsp; <FaCloudUploadAlt 
                 style={{fontSize:'20px'}}/> */}
                                            </label>

                                        ) : (
                                            <label htmlFor="image_file" className="uploaded">Upload Picture &nbsp;{" "} <FaCloudUploadAlt style={{ fontSize: '20px' }} /></label>
                                        )}
                                    </div>


                                </div>
                            </div>



                            <div style={{ width: "95%", paddingLeft: "25px" }}>


                                <Grid item xs={12} style={{ marginBottom: '16px' }}>
                                    <Grid container spacing={2}>

                                        {showImageSpace ? (
                                            <>
                                                {image_files.map((images, index) => {
                                                    return (
                                                        <Grid item xs={4}>
                                                            <div key={index} className="forPhoto">
                                                                <div className="border" style={{ backgroundImage: `url(${images})` }}>
                                                                    <span className="icon">
                                                                        <HiOutlineMinusCircle
                                                                            onClick={() => {
                                                                                const image = Object.assign(
                                                                                    [],
                                                                                    image_files
                                                                                );
                                                                                image.splice(index, 1);
                                                                                setImage_files(image);

                                                                                const tosend = Object.assign([], sendImage);
                                                                                tosend.splice(index, 1);
                                                                                setSendImage(tosend);

                                                                                const tosend2 = Object.assign([], imageToEdit);
                                                                                tosend2.splice(index, 1);
                                                                                setimageToEdit(tosend2);

                                                                                //console.log(tosend2)
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    );
                                                })}
                                            </>
                                            // </div>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </div>

                            <div style={{ paddingLeft: "20px", width: "680px" }}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Controller
                                            name=""
                                            control={control}
                                            defaultValue=""
                                            error={errors.item_dim_wid}
                                            render={({ field }) => <FormControl className={classes.formControl75} variant="outlined">
                                                {/* <InputLabel htmlFor=""><HiPencilAlt style={{ marginRight: "5px", color: "var(--main)" }} />Brief description</InputLabel> */}
                                                <InputField
                                                label="Brief description"
                                                    {...field}
                                                    name=""
                                                    id=""
                                                    type="text"
                                                    // value={item_dim_len} 
                                                    // onChange={(e)=> setItem_dim_len(e.target.value)}
                                                    labelWidth={200}
                                                    icon={<FaTextHeight style={{ marginRight:"5px", color: "var(--main)" }} />}
                                                />
                                                {/* <div className="err">{errors.item_dim_wid?.message}</div> */}
                                            </FormControl>
                                            }
                                        />



                                    </Grid>
                                </Grid>
                            </div>
                            <div className="unidem" style={{ width: '100%', marginTop: "10px" }}>
                                <div style={{marginBottom:"5px"}}>
                                    <Grid container>
                                        <Grid item xs={12}>Dimension unit</Grid>
                                    </Grid>
                                </div>
                               <Grid container>
                               <Grid item xs={12}>
                                    <FormControl style={{width:'100%', marginBottom:'10px'}} variant="outlined" >
                                        <InputLabel id="unit">Unit</InputLabel>
                                        <Select
                                            labelId="unit"
                                            value={dimensionUnit}
                                            onChange={(e) => setDimensionUnit(e.target.value)}
                                            label="Unit">
                                            <MenuItem value="centimeters">Centimeter</MenuItem>
                                            <MenuItem value="meters">Meters</MenuItem>
                                            <MenuItem value="inches">Inches</MenuItem>
                                            <MenuItem value="feet">Feet</MenuItem>
                                        </Select>
                                        {/* <div className="err">{errors.item_desc?.message}</div>  */}
                                    </FormControl>
                               </Grid>
                               </Grid>
                               <div style={{marginBottom:"5px"}}>
                                <Grid container>
                                        <Grid item xs={12}>Dimension</Grid>
                                </Grid>
                               </div>
                               
                               <Grid container>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="item_dim_len"
                                            control={control}
                                            defaultValue=""
                                            error={errors.item_dim_len}
                                            render={({ field }) => <FormControl style={{width:'100%', marginBottom:'10px'}} variant="outlined">
                                                {/* <InputLabel htmlFor="item_dim_len"><HiPencilAlt style={{ marginRight: "5px", color: "var(--main)" }} />Length</InputLabel> */}
                                                <InputField
                                                label="Length"
                                                    {...field}
                                                    name="item_dim_len"
                                                    id="item_dim_len"
                                                    type="text"
                                                    // value={item_dim_len} 
                                                    // onChange={(e)=> setItem_dim_len(e.target.value)}
                                                    labelWidth={90}
                                                    icon={<FaTextHeight style={{ marginRight: "5px", color: "var(--main)" }} />}
                                                />
                                                <div className="err">{errors.item_dim_len?.message}</div>
                                            </FormControl>
                                            }
                                        />

                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            name="item_dim_wid"
                                            control={control}
                                            defaultValue=""
                                            error={errors.item_dim_wid}
                                            render={({ field }) => <FormControl style={{width:'100%', marginBottom:'10px'}} variant="outlined">
                                                {/* <InputLabel htmlFor="item_dim_wid"><HiPencilAlt style={{ marginRight: "5px", color: "var(--main)" }} />Width</InputLabel> */}
                                                <InputField
                                                label="Width"
                                                    {...field}
                                                    name="item_dim_wid"
                                                    id="item_dim_wid"
                                                    type="text"
                                                    // value={item_dim_len} 
                                                    // onChange={(e)=> setItem_dim_len(e.target.value)}
                                                    labelWidth={90}
                                                    icon={<FaTextHeight style={{ marginRight: "5px", color: "var(--main)" }} />}
                                                />
                                                <div className="err">{errors.item_dim_wid?.message}</div>
                                            </FormControl>
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Controller
                                            name="item_dim_hei"
                                            control={control}
                                            defaultValue=""
                                            error={errors.item_dim_hei}
                                            render={({ field }) => <FormControl style={{width:'100%', marginBottom:'10px'}} variant="outlined">
                                                {/* <InputLabel htmlFor="item_dim_hei"><HiPencilAlt style={{ marginRight: "5px", color: "var(--main)" }} />Height</InputLabel> */}
                                                <InputField
                                                label="Height"
                                                    {...field}
                                                    name="item_dim_hei"
                                                    id="item_dim_hei"
                                                    type="text"
                                                    // value={item_dim_len} 
                                                    // onChange={(e)=> setItem_dim_len(e.target.value)}
                                                    labelWidth={90}
                                                    icon={<FaTextHeight style={{ marginRight: "5px", color: "var(--main)" }} />}
                                                />
                                                <div className="err">{errors.item_dim_hei?.message}</div>
                                            </FormControl>
                                            }
                                        />
                                    </Grid>
                               </Grid>

                               <Grid container>
                                <Grid item xs={12} style={{marginBottom:"5px",marginTop:"15px" }}>Weight unit</Grid>
                                    <Grid item xs={12}>
                                        <FormControl style={{width:'100%', marginBottom:'10px'}} variant="outlined">
                                            <InputLabel id="unit">Unit</InputLabel>
                                            <Select
                                                labelId="unit"
                                                value={dimensionUnitWie} 
                                                onChange={(e)=> setDimensionUnitWie(e.target.value)} 
                                                label="Unit"
                                                >
                                                <MenuItem value= "gram">Gram</MenuItem>
                                                <MenuItem value= "kilogram">Kilogram</MenuItem>
                                                <MenuItem value= "tonnes">Tonnes</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}> 
                                    <Controller
                                        name="item_weight"
                                        control={control}
                                        defaultValue=""
                                        error={errors.item_weight}
                                        render={({ field }) => <FormControl style={{width:'100%', marginBottom:'10px'}} variant="outlined">
                                        {/* <InputLabel htmlFor="item_weight"><HiPencilAlt style={{ marginRight: "5px", color: "var(--main)" }} />Weight</InputLabel> */}
                                        <InputField
                                        label="Weight"
                                            {...field}
                                            name="item_weight"
                                            id="item_weight"
                                            error={errors.item_weight}
                                            type="text"
                                            labelWidth={90}
                                            icon={<FaTextHeight style={{ marginRight: "5px", color: "var(--main)" }} />}
                                        />
                                        <div className="err">{errors.item_weight?.message}</div>
                                    </FormControl>
                                    }
                                    />
                                    </Grid>
                                </Grid>
                               
                               
                               
                               
                                {/* <div style={{ marginBottom: "20px" }}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            
                                        </Grid>
                                        <Grid item xs={9}>Dimension</Grid>
                                    </Grid>
                                </div> */}

                                {/* <Grid container>

                                    <Grid item xs={4} >
                                        <FormControl className={classes.formControl25} variant="outlined" style={{ width: "100px" }}>
                                            <InputLabel id="unit">Unit</InputLabel>
                                            <Select

                                                labelId="unit"
                                                value={dimensionUnit}
                                                onChange={(e) => setDimensionUnit(e.target.value)}
                                                label="Unit"
                                            >
                                                <MenuItem value="centimeters">Centimeter</MenuItem>
                                                <MenuItem value="meters">Meters</MenuItem>
                                                <MenuItem value="inches">Inches</MenuItem>
                                                <MenuItem value="feet">Feet</MenuItem>
                                            </Select> */}

                                            {/* <div className="err">{errors.item_desc?.message}</div>  */}
                                        {/* </FormControl>
                                    </Grid> */}

                                    {/* <Grid item xs={9}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name="item_dim_len"
                                                    control={control}
                                                    defaultValue=""
                                                    error={errors.item_dim_len}
                                                    render={({ field }) => <FormControl className={classes.formControl75} variant="outlined">
                                                        <InputLabel htmlFor="item_dim_len"><HiPencilAlt style={{ marginRight: "5px", color: "var(--main)" }} />Length</InputLabel>
                                                        <InputField
                                                            {...field}
                                                            name="item_dim_len"
                                                            id="item_dim_len"
                                                            type="text" */}
                                                            {/* // value={item_dim_len} 
                                                            // onChange={(e)=> setItem_dim_len(e.target.value)}
                                                            labelWidth={90}
                                                            icon={<FaTextHeight style={{ marginRight: "5px", color: "var(--main)" }} />}
                                                        />
                                                        <div className="err">{errors.item_dim_len?.message}</div>
                                                    </FormControl>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name="item_dim_wid"
                                                    control={control}
                                                    defaultValue=""
                                                    error={errors.item_dim_wid}
                                                    render={({ field }) => <FormControl className={classes.formControl75} variant="outlined">
                                                        <InputLabel htmlFor="item_dim_wid"><HiPencilAlt style={{ marginRight: "5px", color: "var(--main)" }} />Width</InputLabel>
                                                        <InputField
                                                            {...field}
                                                            name="item_dim_wid"
                                                            id="item_dim_wid"
                                                            type="text" */}
                                                            {/* // value={item_dim_len} 
                                                            // onChange={(e)=> setItem_dim_len(e.target.value)}
                                                            labelWidth={90}
                                                            icon={<FaTextHeight style={{ marginRight: "5px", color: "var(--main)" }} />}
                                                        />
                                                        <div className="err">{errors.item_dim_wid?.message}</div>
                                                    </FormControl>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name="item_dim_hei" */}
                                                    {/* control={control}
                                                    defaultValue=""
                                                    error={errors.item_dim_hei}
                                                    render={({ field }) => <FormControl className={classes.formControl75} variant="outlined">
                                                        <InputLabel htmlFor="item_dim_hei"><HiPencilAlt style={{ marginRight: "5px", color: "var(--main)" }} />Height</InputLabel>
                                                        <InputField
                                                            {...field}
                                                            name="item_dim_hei"
                                                            id="item_dim_hei"
                                                            type="text"
                                                            // value={item_dim_len} 
                                                            // onChange={(e)=> setItem_dim_len(e.target.value)}
                                                            labelWidth={90}
                                                            icon={<FaTextHeight style={{ marginRight: "5px", color: "var(--main)" }} />}
                                                        />
                                                        <div className="err">{errors.item_dim_hei?.message}</div>
                                                    </FormControl>
                                                    }
                                                />
                                            </Grid> */}
                                        {/* </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="unidem">
                                <Grid container>
                                    <Grid item xs={3}>
                                        <FormControl className={classes.formControl25} variant="outlined" style={{ width: "100px" }}>
                                            <InputLabel id="unit">Unit</InputLabel>
                                            <Select
                                                labelId="unit"
                                                value={dimensionUnit}
                                                onChange={(e) => setDimensionUnit(e.target.value)}
                                                label="Unit"
                                                labelWidth={100}
                                            >
                                                <MenuItem value="centimeters">Centimeter</MenuItem>
                                                <MenuItem value="meters">Meters</MenuItem>
                                                <MenuItem value="inches">Inches</MenuItem>
                                                <MenuItem value="feet">Feet</MenuItem>
                                            </Select> */}

                                            {/* <div className="err">{errors.item_desc?.message}</div>  */}
                                        {/* </FormControl>
                                    </Grid> */}
                                    {/* <Grid item xs={9}>
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name="item_weight"
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => <FormControl className={classes.formControl75} variant="outlined">
                                                        <InputLabel htmlFor="item_weight"><HiPencilAlt style={{ marginRight: "5px", color: "var(--main)" }} />Weight</InputLabel>
                                                        <InputField
                                                            {...field}
                                                            name="item_weight"
                                                            id="item_weight"
                                                            error={errors.item_weight}
                                                            type="text"
                                                            // value={item_dim_len} 
                                                            // onChange={(e)=> setItem_dim_len(e.target.value)}
                                                            labelWidth={90}
                                                            icon={<FaTextHeight style={{ marginRight: "5px", color: "var(--main)" }} />}
                                                        />
                                                        <div className="err">{errors.item_weight?.message}</div>
                                                    </FormControl>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={4}></Grid>
                                            <Grid item xs={4}></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                            </div>
                            <div>
                                {
                                    isloading ?
                                        <Button
                                            size="large"
                                            variant="contained"
                                            type="submit"
                                            disabled
                                            style={{
                                                backgroundColor: "var(--main)",
                                                width: '150px',
                                                marginTop: '20px',
                                                color: "white",
                                                borderRadius: '0px',
                                                padding: '10px',
                                                textTransform: "none",
                                                marginBottom: '30px',
                                                marginLeft: "20px",
                                                height: "35px"
                                            }}
                                        >
                                            <span className="btnlabe">
                                                <PuffLoader color='white' loading={isloading} speedMultiplier={1} size={24} />
                                            </span>
                                        </Button>

                                        :
                                        <Button
                                            size="large"
                                            variant="contained"
                                            type="submit"
                                            style={{
                                                backgroundColor: "var(--main)",
                                                width: '120px',
                                                marginTop: '20px',
                                                color: "white",
                                                borderRadius: '4px',
                                                padding: '10px',
                                                textTransform: "none",
                                                marginBottom: '30px',
                                                marginLeft: "20px",
                                                height: "25px",
                                            }}
                                        >

                                            <span
                                                className="btnlabel">save changes
                                                {/* <BiCheck/> */}
                                            </span>
                                        </Button>
                                }


                            </div>
                        </div>


                    </form>
                </div>
             </div>
            </div>

        </div>
    )
}

export default EditQuote

