import { useContext, useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import GoogleMapWidget from "../../../components/Map/GoogleMapWidget";

import msgpi from "../../../assets/msgpi.svg";
import cursworking from "../../../assets/cursworking.png";
import PuffLoader from "react-spinners/PuffLoader";
import ReactTooltip from "react-tooltip";
import { IconContext } from "react-icons";

import { AiOutlineInfoCircle } from "react-icons/ai";
import { Grid } from "@material-ui/core";

import axios from "axios";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import to from "../../../assets/to.svg";
import From from "../../../assets/From.svg";
import Distance from "../../../assets/Distance.svg";
import dpdd2 from "../../../assets/dpdd2.png";
import dpddd1 from "../../../assets/dpddd1.png";

import Fastdelivery from "../../../assets/Fastdelivery.svg";
import Footer from "../../../components/Footer/Footer";

import HomeHelp from "../../../components/HomeHelp";
import DialogOutClick from "../../../components/DialogOutClick";
import ViewFulfiler2 from "../../Fulfiller/Dashboard/ViewFulfiler2";

import PaystackWidget2 from "../../../components/PaystackWidget2";

import { Radio, RadioGroup } from "@material-ui/core";
import { FormControlLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import PaystackWidget from "../../../components/PaystackWidget";
import SecureLS from "secure-ls";
import { ConnContext } from "../../../context/ConnContext";
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
export default function ShowQuoteDesk({
  data,
  handleChange,
  quoteInfos,
  deliveryDetail,
}) {
var viewQuote = GlobalizationLanguage("/viewQuote")
var timeout = GlobalizationLanguage("/timeout")
var deliver_with_us = GlobalizationLanguage("/create-account")

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const {visitingCountryCode} = useContext(ConnContext)

  const refresh = () => {
    window.location.reload();
  };

  const payOnDelivery = () => {
    setisloading(true);
    authAxios
      .post(`m-dillivry-quote-manager?delivery_location=${delivery_location}`, {
        action_type: "accept",
        payment_type: "on_delivery",
        delivery_id: delivery_id,
        fulfiller_id: fulfilllerToAccept,
        quoted_amount: amountToAccept,
      } , {timeout: 240000})
      .then((response) => {
        setshowAccept(false);
        Swal.fire({
          icon: "success",
          text: "Quote Accepted, Payment on delivery",
          timer: 5000,
          confirmButtonColor: "var(--main)",
        });
        ls.remove("item");
        ls.remove("delivery_id");
        history.push(viewQuote);
        refresh();
        setisloading(false);
      })
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          console.log('internal-server-error-timeout')
          history.push(timeout)
          // window.location.reload()
        }
        // console.log(err)
        setshowAccept(false);

        if (err?.response?.status === 403) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              ls.removeAll();
              history.push(`/login`);
            }
          });
        }
        else{
            Swal.fire({
            icon: "warning",
            text: "Request timeout",
            timer: 5000,
          });
        }

        setisloading(false);
        if (err.code === "ECONNABORTED") {
          console.log('internal-server-error-timeout')
          history.push(timeout)
        }
      });
      
  };
  const history = useHistory();
  const [showAgent, setShowAgent] = useState(false);

  const [showHelp, setshowHelp] = useState(false);
  const [isloading, setisloading] = useState(false);

  const [delivery_id, setdelivery_id] = useState("");
  const [delivery_location, setdelivery_location] = useState("");
  const [fulfillerToShow, setfulfillerToShow] = useState({});

  const [showQuoteType, setshowQuoteType] = useState("quote");

  const [showAccept, setshowAccept] = useState(false);
  const [amountToAccept, setamountToAccept] = useState("");
  const [fulfilllerToAccept, setfulfilllerToAccept] = useState("");
  const [acceptType, setAcceptType] = useState("");

  const GreenRadio = withStyles({
    root: {
      color: green[400],
      "&$checked": {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const [acceptedDetails, setacceptedDetails] = useState([]);
  const [bestQuoteFulfillerDetails, setbestQuoteFulfillerDetails] = useState(
    []
  );

  const Authorization = ls.get("_utk");

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: Authorization,
    },
  });

  const [showdrop] = useState(false);

  const [viewData, setviewData] = useState([]);

  const [deliveryStatus, setdeliveryStatus] = useState("");

  const [origin, setorigin] = useState([]);
  const [destination, setdestination] = useState([]);

  useEffect(() => {
    setviewData([...viewData, data]);

    const fulfi = data?.quote_detail.filter(function (accepted) {
      return accepted.quote_status === "accepted";
    });


    // console.log(fulfi, 'fulfiiiiii')

    setacceptedDetails(
      data?.quote_history.filter(function (fulfillerdetail) {
        return fulfillerdetail?.fulfiller_id == fulfi[0]?.fulfiller_id;
      })
    );

    // console.log(acceptedDetails, 'accepted-details')

    setbestQuoteFulfillerDetails(
      data?.quote_history.filter(function (fulfillerdetail) {
        return fulfillerdetail?.quote_amount == data?.best_quote;
      })
    );

    // console.log(bestQuoteFulfillerDetails, 'bestquotedetails')

    setdeliveryStatus(data.delivery_status);

    // console.log(deliveryStatus, 'delivry-status')

    const startLat = parseFloat(data.start_location.lat);
    const startlng = parseFloat(data.start_location.lng);
    const endLat = parseFloat(data.end_location.lat);
    const endLng = parseFloat(data.end_location.lng);

    setorigin([
      {
        id: "pickup",
        title: "Pickup",
        coordinates: {
          lat: startLat,
          lon: startlng,
        },
      },
    ]);
    setdestination([
      {
        id: "destination",
        title: "Destination",
        coordinates: {
          lat: endLat,
          lon: endLng,
        },
      },
    ]);
  }, []);

  const numberWithCommas = new Intl.NumberFormat("en-GB");

  return (
    <div className="viewSearch">
      {showQuoteType === "quote" ? (
        viewData.map((view) => {
          var TrackingPages = GlobalizationLanguage(`/TrackingPages/${view.delivery_id}`)
          return (
            <div className="Desktop-screen-div">
              <div
                className="header-body-"
                style={{ height: "65px", boxShadow: "none" }}
              ></div>

              <div
                key={view.delivery_id}
                className="view_Container"
                style={{ marginBottom: "-130px" }}
              >
                <div className="view_Top_mobile">
                  <div className="topItem">
                    <div className="topItem-first-div">
                      <button
                        className="topItem-first-div-btn"
                        onClick={handleChange}
                      >
                        <span>Back</span>
                      </button>
                      <span
                        className="topItem-first-div-category"
                        style={{ fontWeight: "600" }}
                      >
                        {" "}
                        Category:{" "}
                      </span>
                      <span className="topItem-first-div-item-category">
                        {view.item_category === "furniture"
                          ? "Furniture"
                          : view.item_category === "general_items"
                          ? "Geneal Items"
                          : view.item_category === "car"
                          ? "Car"
                          : view.item_category === "haulage"
                          ? "Haulage"
                          : view.item_category === "moving_home"
                          ? "Moving Home"
                          : view.item_category === "other_vehicle"
                          ? "Other vehicle"
                          : view.item_category === "motorcycle"
                          ? "Motorcycle"
                          : view.item_category === "vehicle_parts"
                          ? "Vehicle part"
                          : view.item_category}
                      </span>
                    </div>

                    <div className="topItem-second-div">
                      <span
                        className="topItem-second-div-Id"
                        style={{ fontWeight: "600" }}
                      >
                        ID:{" "}
                      </span>
                      <span className="topItem-second-div-item-category">
                        {view.delivery_id}
                      </span>

                      <button
                        className="topItem-second-div-report"
                        onClick={() => setshowHelp(!showHelp)}
                      >
                        Report
                      </button>
                    </div>
                  </div>
                  <div></div>
                </div>

                <div className="view_Map">
                  <GoogleMapWidget
                    origins={destination}
                    destinations={origin}
                  />
                </div>

                <div className="cdqdiv">
                  <div className="cdqdivpict">
                    <div>
                      <Carousel
                        navButtonsAlwaysInvisible={true}
                        indicators={true}
                        animation={"slide"}
                        timeout={100}
                        autoPlay={true}
                      >
                        {view.image_urls?.map((img_url, i) => (
                          <img
                            key={i}
                            src={img_url}
                            style={{
                              width: "100%",
                            }}
                            alt="Item_image"
                            className="img-cdiv"
                          />
                        ))}
                      </Carousel>
                    </div>
                  </div>
                  <div className="cdqdivinfo">
                    <div className="content-icon">
                      <div
                        className="itemDescs-desc"
                        style={{
                          border: "0.5px solid #E0E0E0",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          className="itemDescs-"
                          style={{
                            border: "0.5px solid #E0E0E0",
                            borderTopRightRadius: "10px",
                            borderTopLeftRadius: "10px",
                            borderTop: "none",
                            borderRight: "none",
                            borderLeft: "none",
                            padding: "5px 2px 5px 10px",
                            color: "#FFFFFF",
                            backgroundColor: "#50B14A",
                            height: "35px",
                            fontSize: "15px",
                          }}
                        >
                          <h4>Delivery infomation</h4>
                        </div>
                        <div
                          className="itemDescs-"
                          style={{
                            border: "0.5px solid #E0E0E0",
                            borderTop: "none",
                            borderRight: "none",
                            borderLeft: "none",
                            padding: "2px 2px 2px 10px",
                            fontSize: "14px",
                            height: "30px",
                          }}
                        >
                          {" "}
                          <span data-tip data-for="globaldes">
                            <IconContext.Provider
                              value={{
                                color: "#2e2e2e",
                                className: "global-class-name-icon",
                              }}
                            >
                              {" "}
                              <img src={dpdd2} alt="" />
                            </IconContext.Provider>

                            <ReactTooltip
                              id="globaldes"
                              aria-haspopup="true"
                              role="example"
                              type="light"
                            >
                              <p>Item Description</p>
                            </ReactTooltip>
                          </span>
                          <span>
                            {view.item_desc.charAt(0).toUpperCase() +
                              view.item_desc.slice(1)}
                          </span>
                        </div>
                        <div
                          className="itemDescs-"
                          style={{
                            border: "0.5px solid #E0E0E0",
                            borderTop: "none",
                            borderRight: "none",
                            borderLeft: "none",
                            padding: "2px 2px 2px 10px",
                            fontSize: "14px",
                            height: "30px",
                          }}
                        >
                          {" "}
                          <span data-tip data-for="globalcate">
                            <IconContext.Provider
                              value={{
                                color: "#2e2e2e",
                                className: "global-class-name-icon",
                              }}
                            >
                              {" "}
                              <img src={dpddd1} alt="" />
                            </IconContext.Provider>

                            <ReactTooltip
                              id="globalcate"
                              aria-haspopup="true"
                              role="example"
                              type="light"
                            >
                              <p>Item Category</p>
                            </ReactTooltip>
                          </span>
                          <span>
                            {view.item_category === "furniture"
                              ? "Furniture"
                              : view.item_category === "general_items"
                              ? "Geneal Items"
                              : view.item_category === "car"
                              ? "Car"
                              : view.item_category === "haulage"
                              ? "Haulage"
                              : view.item_category === "moving_home"
                              ? "Moving Home"
                              : view.item_category === "other_vehicle"
                              ? "Other vehicle"
                              : view.item_category === "motorcycle"
                              ? "Motorcycle"
                              : view.item_category === "vehicle_parts"
                              ? "Vehicle part"
                              : view.item_category}
                          </span>
                        </div>
                        <div
                          className="itemDescs-"
                          style={{
                            border: "0.5px solid #E0E0E0",
                            borderTop: "none",
                            borderRight: "none",
                            borderLeft: "none",
                            padding: "2px 2px 2px 10px",
                          }}
                        >
                          {" "}
                          <span data-tip data-for="global4">
                            <IconContext.Provider
                              value={{
                                color: "#2e2e2e",
                                className: "global-class-name-icon",
                              }}
                            >
                              {" "}
                              <img src={to} alt="" />
                            </IconContext.Provider>

                            <ReactTooltip
                              id="global4"
                              aria-haspopup="true"
                              role="example"
                              type="light"
                            >
                              <p>Pickup Address</p>
                            </ReactTooltip>
                          </span>
                          <span className="icon-desc">
                            {view.from.charAt(0).toUpperCase() +
                              view.from.slice(1)}{" "}
                          </span>
                        </div>
                        <div
                          className="itemDescs-"
                          style={{
                            border: "0.5px solid #E0E0E0",
                            borderTop: "none",
                            borderRight: "none",
                            borderLeft: "none",
                            padding: "2px 2px 2px 10px",
                          }}
                        >
                          <span data-tip data-for="global5">
                            <IconContext.Provider
                              value={{
                                color: "#2e2e2e",
                                className: "global-class-name-icon",
                              }}
                            >
                              {" "}
                              <img src={From} alt="" />
                            </IconContext.Provider>

                            <ReactTooltip
                              id="global5"
                              aria-haspopup="true"
                              role="example"
                              type="light"
                            >
                              <p>Destination Address</p>
                            </ReactTooltip>
                          </span>
                          <span className="icon-desc">
                            {view.to.charAt(0).toUpperCase() + view.to.slice(1)}
                          </span>
                        </div>
                        <div
                          className="itemDescs-"
                          style={{
                            border: "0.5px solid #E0E0E0",
                            borderTop: "none",
                            borderRight: "none",
                            borderLeft: "none",
                            padding: "2px 2px 2px 10px",
                          }}
                        >
                          {" "}
                          <span data-tip data-for="globalCove">
                            <IconContext.Provider
                              value={{
                                color: "#2e2e2e",
                                className: "global-class-name-icon",
                              }}
                            >
                              {" "}
                              <img src={Distance} alt="" />
                            </IconContext.Provider>

                            <ReactTooltip
                              id="globalCove"
                              aria-haspopup="true"
                              role="example"
                              type="light"
                            >
                              <p>Distance </p>
                            </ReactTooltip>
                          </span>
                          {/* <img src={Distance} alt="" /> */}
                          <span className="icon-desc">{view.distance}</span>
                        </div>

                        <div
                          className="itemDescs-"
                          style={{
                            border: "0.5px solid #E0E0E0",
                            borderTop: "none",
                            borderRight: "none",
                            borderLeft: "none",
                            padding: "2px 2px 2px 10px",
                          }}
                        >
                          {" "}
                          <span data-tip data-for="globalmode">
                            <IconContext.Provider
                              value={{
                                color: "#2e2e2e",
                                className: "global-class-name-icon",
                              }}
                            >
                              {" "}
                              <img src={Fastdelivery} alt="" />
                            </IconContext.Provider>

                            <ReactTooltip
                              id="globalmode"
                              aria-haspopup="true"
                              role="example"
                              type="light"
                            >
                              <p>Mode of Delivery</p>
                            </ReactTooltip>
                          </span>
                          <span className="icon-desc">
                            {view.mode_of_delivery === "moving_home"
                              ? "Moving home"
                              : view.mode_of_delivery.charAt(0).toUpperCase() +
                                view.mode_of_delivery.slice(1)}
                          </span>
                        </div>

                        <div
                          className="itemDescs-"
                          style={{
                            fontSize: "14px",
                            border: "0.5px solid #E0E0E0",
                            borderTop: "none",
                            borderRight: "none",
                            borderLeft: "none",
                            height: "30px",
                            padding: "2px 2px 2px 10px",
                          }}
                        >
                          <span> Pickup Date:</span>&nbsp;&nbsp;
                          {view.pickup_date}
                        </div>
                        <div
                          className="itemDescs-"
                          style={{
                            fontSize: "14px",
                            border: "0.5px solid #E0E0E0",
                            borderTop: "none",
                            borderRight: "none",
                            borderLeft: "none",
                            height: "30px",
                            padding: "2px 2px 2px 10px",
                          }}
                        >
                          <span> Delivery time frame:</span>&nbsp;&nbsp;
                          {view.delivery_time_frame}
                        </div>
                        <div
                          className="itemDescs-"
                          style={{
                            border: "0.5px solid #E0E0E0",
                            border: "none",
                            padding: "2px 2px 2px 10px",
                            height: "100%",
                          }}
                        >
                          <span>
                            {" "}
                            <p style={{ width: "115px", fontSize: "14px" }}>
                              Delivery Status:
                            </p>
                          </span>
                          <span
                            className="icon-desc"
                            style={{
                              width: "200px",
                              fontSize: "14px",
                              height: "25px",
                            }}
                          >
                            {view.delivery_status === "awaiting_quote"
                              ? "Awaiting Quote"
                              : view.delivery_status ===
                                "awaiting_agent_assignment"
                              ? "Awaiting agent assignment"
                              : view.delivery_status === "awaiting_pickup"
                              ? "Awaiting pickup"
                              : view.delivery_status === "out_for_delivery"
                              ? "Out for Delivery"
                              : view.delivery_status === "delivered"
                              ? "Completed"
                              : view.delivery_status === "missed_delivery"
                              ? "Missed"
                              : view.delivery_status}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cdqdiviagt">
                    {view.delivery_status === "awaiting_quote" ? (
                      view.total_quote_received === 0 ? (
                        <> </>
                      ) : (
                        <>
                          {bestQuoteFulfillerDetails.length === 0 ? null : (
                            <>
                              <div className="right-content-information">
                                <div className="betqt">
                                  <div className="betqthead">
                                    <span> Best quote</span>
                                  </div>

                                  <div className="betgrcontain">
                                    <div className="betgrcontainpic">
                                      <img
                                        src={
                                          bestQuoteFulfillerDetails[0]
                                            .provider_info.cop_logo
                                        }
                                        alt="Recta_icon"
                                        style={{
                                          width: "60px",
                                          height: "60px",
                                        }}
                                      />
                                    </div>

                                    <div className="betgrcontaintext">
                                      <p
                                        onClick={() => {
                                          setfulfillerToShow(
                                            bestQuoteFulfillerDetails[0]
                                              .provider_info
                                          );
                                          setshowQuoteType("fulfiller");
                                          // console.log(
                                          //   bestQuoteFulfillerDetails[0]
                                          //     .provider_info
                                          // );
                                        }}
                                        style={{
                                          color: "#50B14A",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {bestQuoteFulfillerDetails[0]
                                          ?.provider_info.account_holder
                                          ?.first_name +
                                          " " +
                                          bestQuoteFulfillerDetails[0]
                                            ?.provider_info.account_holder
                                            ?.last_name}
                                      </p>

                                      <p>
                                        {
                                          bestQuoteFulfillerDetails[0]
                                            ?.provider_info.Positive_feedback
                                        }{" "}
                                      </p>
                                      <p>
                                        {" "}
                                        <img src={msgpi} alt="msgpi_icon" />
                                        &ensp;
                                        {
                                          bestQuoteFulfillerDetails[0]
                                            ?.provider_info
                                            .good_in_transit_insurance.state
                                        }
                                      </p>
                                    </div>
                                  </div>

                                  <div className="betqprice">
                                    <p>
                                      &#8358;
                                      {numberWithCommas.format(
                                        bestQuoteFulfillerDetails[0]
                                          ?.quote_amount
                                      )}
                                    </p>
                                  </div>
                                  <div className="betqinfo-">
                                    <div>
                                      {" "}
                                      <p>
                                        Insurance{" "}
                                        <img src={msgpi} alt="msgpi_icon" />:{" "}
                                        {
                                          bestQuoteFulfillerDetails[0]
                                            ?.provider_info
                                            .good_in_transit_insurance.state
                                        }{" "}
                                      </p>
                                    </div>
                                    <div>
                                      <button
                                        className="betbt"
                                        style={{
                                          width: "70px",
                                          borderRadius: "4px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          setshowAccept(true);
                                          setdelivery_id(view.delivery_id);
                                          setdelivery_location(view?.vcc);
                                          setamountToAccept(
                                            bestQuoteFulfillerDetails[0]
                                              ?.quote_amount
                                          );
                                          setfulfilllerToAccept(
                                            bestQuoteFulfillerDetails[0]
                                              ?.fulfiller_id
                                          );
                                        }}
                                      >
                                        {" "}
                                        Accept quote
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )
                    ) : view.delivery_status === "awaiting_agent_assignment" ? (
                      <>
                        <div className="right-content-information">
                          <div className="betqt">
                            <div className="betqthead">
                              <p> Accepted quote</p>
                            </div>
                            <div className="betgrcontain">
                              <div className="betgrcontainpic">
                                <img
                                  src={
                                    acceptedDetails[0].provider_info
                                      .profile_photo.profile_photo
                                  }
                                  alt="Recta_icon"
                                  style={{ width: "60px", height: "60px" }}
                                />
                              </div>

                              <div className="betgrcontaintext">
                                <p
                                  style={{
                                    color: "#50B14A",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setfulfillerToShow(
                                      bestQuoteFulfillerDetails[0].provider_info
                                    );
                                    setshowQuoteType("fulfiller");
                                    // console.log(
                                    //   bestQuoteFulfillerDetails[0].provider_info
                                    // );
                                  }}
                                >
                                  {acceptedDetails[0]?.provider_info
                                    .account_holder?.first_name +
                                    " " +
                                    acceptedDetails[0]?.provider_info
                                      .account_holder?.last_name}
                                </p>
                                <p>
                                  {" "}
                                  {
                                    acceptedDetails[0].provider_info
                                      .Positive_feedback
                                  }
                                </p>
                                <p>
                                  {" "}
                                  <img src={msgpi} alt="msgpi_icon" />
                                  &ensp;
                                  {
                                    bestQuoteFulfillerDetails[0]?.provider_info
                                      .good_in_transit_insurance.state
                                  }
                                </p>
                              </div>
                            </div>
                            <div className="betqprice">
                              <p>
                                &#8358;
                                {numberWithCommas.format(
                                  acceptedDetails[0].quote_amount
                                )}
                              </p>
                            </div>
                            <div className="betqinfo-1">
                              <p>
                                Insurance <img src={msgpi} alt="msgpi_icon" />:
                                {
                                  bestQuoteFulfillerDetails[0]?.provider_info
                                    .good_in_transit_insurance.state
                                }{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="AgetDe">
                        <div className="AgetDetop"> Agent details</div>
                        <div className="AgetDepictext">
                          <div className="AgetDepic">
                            {view.delivery_status === "awaiting_pickup" ? (
                              <>
                                <img
                                  src={data?.agent_pics}
                                  alt="agent_icon"
                                  style={{ width: "70px", height: "70px" }}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={data?.d_agent_pics}
                                  alt="Recta_icon"
                                  style={{ width: "60px", height: "60px" }}
                                />
                              </>
                            )}
                          </div>
                          <div className="AgetDetext">
                            <p>{data?.agent_name}</p>
                            <p>{data?.d_agent_name}</p>

                            <button
                              style={{ cursor: "pointer" }}
                              className="showidbt"
                              onClick={() => setShowAgent(true)}
                            >
                              {" "}
                              Show ID
                            </button>
                          </div>
                        </div>
                        <div
                          className="Ageinfo"
                          style={{ paddingLeft: "60px", lineHeight: "25px" }}
                        >
                          <p>
                            {" "}
                            Completed Deliveries: &nbsp; (
                            {data?.completed_jobs.completed_jobs})
                          </p>
                        </div>
                      </div>
                    )}

                    {view.delivery_status === "awaiting_quote" ? (
                      <></>
                    ) : (
                      <>
                        <div
                          className="itemDescs-desc"
                          style={{
                            border: "0.5px solid #E0E0E0",
                            borderRadius: "10px",
                            marginTop: "15px",
                            width: "100%",
                          }}
                        >
                          <div
                            className="itemDescs-"
                            style={{
                              border: "0.5px solid #E0E0E0",
                              borderTopRightRadius: "10px",
                              borderTopLeftRadius: "10px",
                              borderTop: "none",
                              borderRight: "none",
                              borderLeft: "none",
                              padding: "5px 2px 5px 10px",
                              color: "#FFFFFF",
                              backgroundColor: "#50B14A",
                              height: "35px",
                              fontSize: "13px",
                            }}
                          >
                            <h4>Payment infomation</h4>
                          </div>
                          <div
                            className="paymentstatus"
                            style={{
                              border: "none",
                              width: "100%",
                              padding: "2px 2px 2px 10px",
                            }}
                          >
                            <div>
                              <span>
                                <p style={{ fontSize: "14px" }}>
                                  Payment Status:
                                </p>{" "}
                              </span>
                            </div>
                            <div>
                              &nbsp;
                              <span style={{ fontSize: "12px" }}>
                                {view.payment_status === "success" ? (
                                  "Paid"
                                ) : view.payment_status ===
                                  "pay_on_delivery" ? (
                                  "Pay on Delivery"
                                ) : view.payment_status === "NA" ? (
                                  <span>
                                    {" "}
                                    <span>
                                      <span data-tip data-for="global">
                                        <IconContext.Provider
                                          value={{
                                            color: "#2e2e2e",
                                            className: "global-class-name-icon",
                                          }}
                                        >
                                          Offline&nbsp;
                                          <AiOutlineInfoCircle />
                                        </IconContext.Provider>

                                        <ReactTooltip
                                          id="global"
                                          aria-haspopup="true"
                                          role="example"
                                          type="light"
                                        >
                                          <p>
                                            Arranged payment between the sender
                                            and provider
                                          </p>
                                        </ReactTooltip>
                                      </span>
                                    </span>
                                  </span>
                                ) : (
                                  view.payment_status
                                )}
                              </span>
                              <span>
                                {view.payment_status === "pay_on_delivery" ? (
                                  <>
                                    <div
                                      style={{
                                        marginLeft: "-140px",
                                        marginTop: "-10px",
                                        marginBottom: "-10px",
                                      }}
                                    >
                                      <PaystackWidget2
                                        amount={view.quote_amount + "00"} //for paystack
                                        amount_paid={view.quote_amount}
                                        fulfiller_id={view.fulfiller_id}
                                        delivery_id={view.delivery_id}
                                        email={ls.get("eid")}
                                        closePopup={() =>
                                          console.log("loading")
                                        }
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <> </>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <span style={{ marginTop: "20px", marginLeft: "20px" }}>
                      {" "}
                      {view.delivery_status === "out_for_delivery" ? (
                        <div className="itemDescs-" style={{ border: "none" }}>
                          <button
                            className="itmclick"
                            onClick={() =>
                              history.push(TrackingPages)
                            }
                            style={{
                              backgroundColor: "#50B14A",
                              border: "1px solid #50B14A",
                              borderRadius: "4px",
                              color: "#FFFFFF",
                              padding: "2px 5px 2px 5px",
                              cursor: "pointer",
                            }}
                          >
                            Track item
                          </button>
                        </div>
                      ) : view.delivery_status === "missed_delivery" ? (
                        <>
                          {" "}
                          <div
                            className="itemDescs-"
                            style={{ border: "none" }}
                          >
                            <button
                              className="itmclick"
                              onClick={() =>
                                history.push(TrackingPages)
                              }
                              style={{
                                backgroundColor: "#50B14A",
                                border: "1px solid #50B14A",
                                borderRadius: "4px",
                                color: "#FFFFFF",
                                padding: "2px 5px 2px 5px",
                                cursor: "pointer",
                              }}
                            >
                              Track item
                            </button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {showdrop ? (
                        <p>
                          <img
                            src={cursworking}
                            alt="tra"
                            style={{
                              width: "25px",
                              height: "30px",
                              paddingTop: "15px",
                            }}
                          />{" "}
                          &nbsp;Item position:
                        </p>
                      ) : null}
                    </span>
                    <span className="icon-desc"></span>
                  </div>
                </div>
              </div>

              <div className="quohistrydiv" style={{ marginBottom: "200px" }}>
                {view.delivery_status === "awaiting_quote" ? (
                  view.total_quote_received === 0 ? (
                    <>
                      {" "}
                      <div
                        className="provider-history-div"
                        style={{ marginTop: "20px" }}
                      >
                        <h2 className="provider-history"> Quote History</h2>

                        <table className="table_  table-tr-th">
                          <tr style={{ height: "10px", borderRadius: "4px" }}>
                            <th>Quote amount</th>
                            <th>Logistic provider</th>
                            <th>Providers info</th>
                          </tr>

                          {view.quote_history?.map((detail, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {" "}
                                  &#8358;
                                  {numberWithCommas.format(detail.quote_amount)}
                                </td>
                                <td>{detail.fulfiller_id}</td>
                                <td>
                                  <button
                                    style={{
                                      width: "80px",
                                      borderRadius: "4px",
                                      height: "24px",
                                    }}
                                    onClick={() => {
                                      setfulfillerToShow(detail.provider_info);
                                      setshowQuoteType("fulfiller");
                                      // console.log(detail.provider_info);
                                    }}
                                    className="profile-btn"
                                  >
                                    <span>View profile</span>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>{" "}
                    </>
                  ) : (
                    <>
                      <div
                        className="provider-history-div"
                        style={{ marginTop: "10px" }}
                      >
                        <h2
                          className="provider-history"
                          style={{ marginLeft: "50px" }}
                        >
                          Quote History
                        </h2>

                        <table className="table_  table-tr-th">
                          <tr style={{ height: "10px", borderRadius: "4px" }}>
                            <th>Quote amount</th>
                            <th>Logistic provider</th>
                            <th>Providers info</th>
                          </tr>
                          {view.quote_history?.map((detail, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {" "}
                                  &#8358;
                                  {numberWithCommas.format(detail.quote_amount)}
                                </td>
                                <td>{detail.fulfiller_id}</td>
                                <td>
                                  <button
                                    style={{
                                      width: "80px",
                                      borderRadius: "4px",
                                      height: "24px",
                                    }}
                                    onClick={() => {
                                      setfulfillerToShow(detail.provider_info);
                                      setshowQuoteType("fulfiller");
                                      // console.log(detail.provider_info);
                                    }}
                                    className="profile-btn"
                                  >
                                    <span>View profile</span>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    </>
                  )
                ) : view.delivery_status === "awaiting_agent_assignment" ? (
                  <></>
                ) : view.delivery_status === "awaiting_pickup" ? (
                  <>
                    <div
                      className="provider-history-div"
                      style={{ marginTop: "00px" }}
                    >
                      <h2
                        className="provider-history"
                        style={{ marginLeft: "50px" }}
                      >
                        Quote History
                      </h2>

                      <table className="table_  table-tr-th">
                        <tr style={{ height: "10px", borderRadius: "4px" }}>
                          <th>Quote amount</th>
                          <th>Logistic provider</th>
                          <th>Providers info</th>
                        </tr>
                        {view.quote_history?.map((detail, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {" "}
                                &#8358;
                                {numberWithCommas.format(detail.quote_amount)}
                              </td>
                              <td>{detail.fulfiller_id}</td>
                              <td>
                                <button
                                  style={{
                                    width: "80px",
                                    borderRadius: "4px",
                                    height: "24px",
                                  }}
                                  onClick={() => {
                                    setfulfillerToShow(detail.provider_info);
                                    setshowQuoteType("fulfiller");
                                  }}
                                  className="profile-btn"
                                >
                                  <span>View profile</span>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </>
                ) : view.delivery_status === "in-transit" ? (
                  <></>
                ) : null}
              </div>

              <div className="provider-history-div">
                <div className="areullog">
                  <div className="a" style={{ marginTop: "-80px" }}>
                    <div className="a2">
                      <h2 className="logicstics-content">
                        Are you a logistics provider?
                      </h2>
                    </div>

                    <div className="a3">
                      <button
                        style={{
                          width: "80px",
                          borderRadius: "4px",
                          height: "24px",
                        }}
                        className="signup-btn"
                        onClick={() => history.push(deliver_with_us)}
                      >
                        <span className="signup-btn-">Signup</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {showHelp ? (
                <HomeHelp
                  showDialog={showHelp}
                  onClose={() => setshowHelp(false)}
                />
              ) : null}

              <Footer />
            </div>
          );
        })
      ) : (
        // <h1>Hello</h1>
        <ViewFulfiler2
          details={fulfillerToShow}
          handleChange={() => setshowQuoteType("quote")}
        />
      )}

      <DialogOutClick
        open={showAgent}
        handleClose={() => setShowAgent(!showAgent)}
      >
        <div>
          <img
            src={data.agent_id_card_front_url}
            width="270px"
            alt={data.agent_id_card_front_url}
          />
        </div>
        <div>
          <img
            src={data.d_agent_id_card_front_url}
            width="270px"
            alt={data.d_agent_id_card_front_url}
          />
        </div>
      </DialogOutClick>

      <DialogOutClick
        open={showAccept}
        handleClose={() => {
          setshowAccept(!showAccept);
          setAcceptType("");
        }}
      >
        <div style={{ padding: "20px" }}>
          <p style={{ paddingBottom: "20px" }}> Select your payment option</p>

          <Grid item xs={12}>
            <RadioGroup aria-label="payment" name="row-radio-buttons-group">
              <FormControlLabel
                value="instant"
                onClick={() => {
                  setAcceptType("instant");
                }}
                control={<GreenRadio />}
                label="Instant payment"
              />

              <FormControlLabel
                value="pay_on_delivery"
                onClick={() => {
                  setAcceptType("pay_on_delivery");
                }}
                control={<GreenRadio />}
                label="Pay on delivery"
              />
            </RadioGroup>
          </Grid>
          {acceptType === "instant" ? (
            <PaystackWidget
              amount={amountToAccept + "00"} //for paystack
              amount_paid={amountToAccept}
              fulfiller_id={fulfilllerToAccept}
              delivery_id={delivery_id}
              email={ls.get("eid")}
              closePopup={() => setshowAccept(false)}
            />
          ) : acceptType === "pay_on_delivery" ? (
            isloading ? (
              <div
                className="acceptBTN"
                style={{ width: "100px", height: "40px", marginLeft: "60px" }}
              >
                <span style={{ cursor: "pointer", borderRadius: "0px" }}>
                  <span className="btnlabe">
                    <PuffLoader
                      color="white"
                      loading={isloading}
                      speedMultiplier={1}
                      size={24}
                    />
                  </span>
                  Proceed{" "}
                </span>
              </div>
            ) : (
              <div
                className="acceptBTN"
                style={{ width: "70px", height: "21px", marginLeft: "60px" }}
              >
                <span
                  style={{ cursor: "pointer", borderRadius: "0px" }}
                  onClick={payOnDelivery}
                >
                  Proceed{" "}
                </span>
              </div>
            )
          ) : null}
        </div>
      </DialogOutClick>
    </div>
  );
}
