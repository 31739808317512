/* eslint-disable jsx-a11y/anchor-is-valid */

import { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./Dashboard.css";
import axios from "axios";
import { IoWarningOutline } from "react-icons/io5";
import { AuthContext } from "../../../context/AuthContext";
import Swal from "sweetalert2";

import UpdateReg from "../../Registration/UpdateReg";
import DialogSimplePop from "../../../components/DialogSimplePop";
import OTPAfter from "../../../components/Reg_components/OTPAfter";
import RegFormAgent from "../../Registration/RegFormAgent";
import RegLinkToApp from "../../Registration/RegLinkToApp";
import AgentDashboar from "../../Registration/AgentDashboar";
import DialogOutClick from "../../../components/DialogOutClick";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import SecureLS from "secure-ls";
import UpdateTopMenu from "../../../components/topNav/UpdateTopMenu";

import { DashboardContext } from "../../../context/Dashboard/DashboardProvider/dashboard.provider";
import { ConnContext } from "../../../context/ConnContext";
import DashBottomBtn from "./DashBottomBtn/DashBottomBtn";
import CorporateLayout from "./CorporateLayout";
import MobileCorpLayout from "./CorporateComponents/MobileCorpLayout";
import './CorporateDashboard.css'
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
import OpenRequestCard from "./DashCards.js/OpenRequestCard";
import { MoonLoader } from "react-spinners";
import OpenRequestCardDesktop from "./DashCards.js/OpenRequestCardDesktop";
import Cookies from 'js-cookie';

function Dashboard() {
  var login = GlobalizationLanguage("/login")
  const location = useLocation();

  const [rerun, setrerun] = useState(false);

  const numberWithCommas = new Intl.NumberFormat("en-GB")

  const {
    dashboards,
    updateDashboard,
  } = useContext(DashboardContext);

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const [role, setRole] = useState("");
  const history = useHistory();
  const [isempty, setisempty] = useState(false);
  const auth = useContext(AuthContext);
  const [accountStatus, setaccountStatus] = useState("");
  const [quote, setQuote] = useState([]);
  const [showLogout, setShowLogout] = useState(false);

  const [loadingData, setloadingData] = useState(false);
  const [clicked, setclicked] = useState("all");
  const [state, setstate] = useState("dashboard");
  const [accountProfile, setAccountProfile] = useState([]);
  const [RegistrationNotCompleted, setRegistrationNotCompleted] =
    useState(false);
  const [bankVerified, setbankVerified] = useState("");
  const [bankList, setBankList] = useState([]);

  const [profileVerified, setprofileVerified] = useState("");
  const [proofVerified, setproofVerified] = useState("");
  const [goodsVerified, setgoodsVerified] = useState("");
  const [showDeviceNeedDialog, setshowDeviceNeedDialog] = useState(false);
  const [showAgentNeedDialog, setshowAgentNeedDialog] = useState(false);
  const [showAgentCreateDialog, setshowAgentCreateDialog] = useState(false);
  const [showActivateDevice, setshowActivateDevice] = useState(false);
  const [showIAmAnAgent, setshowIAmAnAgent] = useState(false);
  const [showOTPRequired, setshowOTPRequired] = useState(false);
 
  const endpoint = process.env.REACT_APP_API;

  const [loadingDeliveries, setloadingDeliveries] = useState(false)

  const logMeOut = () => {
    auth.logout();
    history.push("/");
  };

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
    window.history.replaceState(null, "");
  };

  function reverseArr(input) {
    var ret = new Array();
    for (var i = input?.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  const joiner = (data) => {
    const newarrrr = data.split("-");
    const merge = newarrrr[0].concat(newarrrr[1], newarrrr[2]);
    const newarrr = merge.split(" ");
    const merged = newarrr[0].concat(newarrr[1]);
    const newarrrf = merged.split(":");
    const mergedd = newarrrf[0].concat(newarrrf[1], newarrrf[2]);
    return mergedd;
  };


  useEffect(() => {
    setclicked(location.state ? location.state : "current");
  }, []);

  const updateDashdata = (res) => {
    // console.log(res, 'dasgboard datata')
    if (
      res.data.current_quotes !== undefined ||
      res.data.accepted_quotes !== undefined ||
      res.data.unsuccessful_quotes !== undefined ||
      res.data.completed_quotes !== undefined ||
      res.data.outbid_auction !== undefined
    ) {
      if (
        res.data.my_account_info.role !== undefined &&
        res.data.my_account_info.role ===
        "dillivry-sole-trader-ops"
      ) {
        setRegistrationNotCompleted(true);
        setshowIAmAnAgent(true);
      } else {
        setRegistrationNotCompleted(false);
        ls.remove("nful");
        ls.remove("dwp");

        ls.set(
          "means_of_delivery",
          JSON.stringify(res?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
        );

        ls.set(
          "_Rts",
          res.data.account_profile.account_profile[0]
            .account_status.state
        );
        ls.set("aCs_", "active");

        // setQuote(res.data.current_quotes);
        setAccountProfile(
          res.data.account_profile.account_profile
        );

        setisempty(false);

        setTimeout(() => {
          setaccountStatus(
            res.data.account_profile.account_profile[0]
              .account_status.state
          );

          ls.set(
            "st_rg",
            res.data.my_account_info.my_account_info[0]
              .account_status.device_status
          );
        }, 3000);
      }
    } else if (
      res?.data?.msg === "your phone number has not been verified"
    ) {
      setshowOTPRequired(true);
    } else if (res?.status === 201) {
      setRegistrationNotCompleted(true);
      setgoodsVerified(res?.data?.good_in_transit_insurance);
      setproofVerified(res?.data?.proof_of_identity);
      setprofileVerified(res?.data?.profile_photo);
      setbankVerified(res?.data?.bank_detail);
      setBankList(res?.data?.list_of_nig_banks);
        ls.set("lGd", res?.data);

    } else if (
      res.data.my_account_info.role !== undefined &&
      res.data.my_account_info.role ===
      "dillivry-corp-last-mile-ops"
    ) {
      setRegistrationNotCompleted(true);
      setshowIAmAnAgent(true);
    }
    setloadingData(false);
  }

  const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers: {
      Authorization: ls.get("_nodi"),
    },
  });


  useEffect(()=>{
    setloadingDeliveries(true)
    authAxios.get(
      `m-dillivry-search?vcc=${Cookies.get('i18next')}`,
    )
      .then((response) => {
        setloadingDeliveries(false)
        setQuote(
          reverseArr(
            response.data.default_data.sort(function (a, b) {
              return joiner(a?.time_stamp) - joiner(b?.time_stamp);
            })
          ).slice(0, 5));
      })
      .catch((error)=>{
        setloadingDeliveries(false)
        console.log(error)
      })
  },[Cookies.get('i18next')])

  useEffect(() => {
    if (dashboards !== undefined) {
      // console.log(dashboards, "dashboardsdashboards")
      if (
        ls.get("_iRl") === "" ||
        ls.get("_iRl") === "dillivry-sole-trader-ops"
      ) {
        history.push(login);
      } else {
        setRole(ls.get("_iRl"));
        updateDashdata(dashboards)
      }
    }
    else {
      if (
        ls.get("_iRl") === "" ||
        ls.get("_iRl") === "dillivry-sole-trader-ops"
      ) {
        history.push(login);
      } else {
        setloadingData(true);
        setRole(ls.get("_iRl"));


        axios
          .get(`${endpoint}m-dillivry-dashboard-manager`, {
            headers: {
              Authorization: ls.get("_nodi"),
            },
          })
          .then((response) => {
            // console.log("m-dillivry-dashboard-manager",response.data)
           
            
            updateDashboard(response);
            updateDashdata(response)
          })
          .catch((error) => {
            setloadingData(false);
            if (error?.response?.status === 404) {
              if (error.response.data.msg === "Provider has 0 active agent") {
                setshowAgentNeedDialog(true);
              } else if (
                error.response.data.msg ===
                "device and card registration is required before you can use this platform"
              ) {
                setRegistrationNotCompleted(true);
                setshowIAmAnAgent(true);
              }
            } else if (error?.response?.status === 403) {
              Swal.fire({
                icon: "warning",
                title: "Session!",
                text: "Session expired login again!",
                allowOutsideClick: false,
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  auth.logout();
                  history.push(login);
                }
              });
            } else {
              Swal.fire({
                icon: "warning",
                title: "Session!",
                text: "Session expired login again!",
                allowOutsideClick: false,
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  auth.logout();
                  history.push(login);
                }
              });
            }
            // console.log(error)
          });
      }
    }
  }, [rerun]);

  const Hellobanner=({name,amount})=>{
    return(
    <div className="hellbanner">
        <div className="hellbannerTop">
            <h2>Hello {name}</h2>
            {
              accountProfile[0]?.wallet_balance ?

              <span> &#8358;{numberWithCommas.format(parseFloat(amount)?.toFixed(2))}</span>
              :
              <span className="helloright"> &#8358; 00.00</span>

            }
           
            
        </div>
        <div className="hellbannerBottom">
            <span className="helloleft">More delivery, more money</span>
            <span className="helloright">Wallet balance</span>
        </div>

    </div>
    )
}

  return (
    <>
      {RegistrationNotCompleted ? (
        showIAmAnAgent ? (
          <div>
            <AgentDashboar />
          </div>
        ) : (
          <div className="registration">
            <div className="topDesktop">
              <UpdateTopMenu />
            </div>
            <div className="afterReg">
              <div className="pageTitle">
                <div className="pageTag">Welcome, {ls.get("nful")}</div>
                <div className="pageSubtage">Required steps</div>
                <div className="pageSubtage">
                  Here's what you need to do to setup your account
                </div>
              </div>
              <div className="formSide">
                <UpdateReg
                  bankVerified={bankVerified}
                  proofVerified={proofVerified}
                  profileVerified={profileVerified}
                  goodsVerified={goodsVerified}
                  bankList={bankList}
                // bioVerified={bioVerified}
                />
              </div>
            </div>
          </div>
        )
      ) : showAgentCreateDialog ? (
        <RegFormAgent />
      ) : showActivateDevice ? (
        <RegLinkToApp />
      ) : (
        <div className="dashboard">

          <div className="dashboardDesktop">
           
            {/* {state === "dashboard" ? ( */}
            <CorporateLayout>
            <>
              {accountStatus === "pending validation" ? (
                <div className="forNotActive">
                  Your account is pending validation, sit back as the Dillivry
                  team review your account
                </div>
              ) : null}
                <>
                  {loadingData ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100vh",
                        backgroundColor: "none",
                        zIndex: "700",
                        position: "fixed",
                        top: "40",
                        padding: "0",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress color="success" />
                      </Box>
                    </div>
                  ) : null}
                  
                  <Hellobanner  
                    name={accountProfile[0]?.account_holder?.first_name}
                    amount={accountProfile[0]?.wallet_balance}
                  />
                  {
                    quote.length === 0 ?
                      <div className="columnBody1">
                      - currently none to display -
                      </div>
                      :
                      <>
                      {
                        loadingDeliveries ? 
                          <div style={{ width: '100%', height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <MoonLoader color='var(--main)' loading={loadingDeliveries} speedMultiplier={1} size={50} /> </div>
                          :
                          <OpenRequestCardDesktop
                          littleheading="Open requests" 
                          data={quote}
                        />
                      }
                      </>
                     
                  }
                </>
            </>
            </CorporateLayout>
            {/* )  */}
          </div>
          {/* desktop state ends here  */}

          {/* mobile ui design start here */}

          
          <div className="dashboardMobile">

          <MobileCorpLayout pageTitle="Dashboard" />
              
                
            {loadingData ? (
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  backgroundColor: "none",
                  zIndex: "300",
                  position: "fixed",
                  top: "0",
                  // paddingTop: "70px",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <LinearProgress color="success" />
                </Box>
              </div>
            ) : null}
            <div
              className="dashboardBody"
              style={{
                backgroundColor: state === "message" ? "white" : "whitesmoke",
              }}
            >

              <div className="mobilecorp_gen" style={{paddingBottom:'15px'}}>
                <Hellobanner  
                  name={accountProfile[0]?.account_holder?.first_name}
                  amount={accountProfile[0]?.wallet_balance}
                />
                </div>
              <>
                {accountStatus === "pending validation" ? (
                  <div className="forNotActive">
                    Your account is pending validation, sit back as the
                    Dillivry team review your account
                  </div>
                ) : null}
                <>
                  {isempty ? (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      No quote yet
                    </div>
                  ) : (
                    <div className="quoteCards">
                       {/* <CurrentCard
                        type={clicked}
                        quote={quote}
                      /> */}
                      {
                        loadingDeliveries ? 
                        <div style={{ width: '100%', height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <MoonLoader color='var(--main)' loading={loadingDeliveries} speedMultiplier={1} size={35} /> </div>
                        :
                        <OpenRequestCard
                          data={quote}
                        />
                      }
                      
                    </div>
                  )}
                </>
              </>
              <DashBottomBtn activeTab="dashboard" />
            </div>
          </div>
        </div>
      )}

      <DialogSimplePop
        openPopup={showDeviceNeedDialog}
        btnText="Activate Mobile Phone"
        onClick={() => setshowActivateDevice(true)}
      >
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <IoWarningOutline size="50" color="#ffbf00" />
          <div
            style={{ textAlign: "center", fontSize: "16px", fontWeight: "500" }}
          >
            Awesome! One more step to go. Activate your mobile phone for
            delivery operations.
          </div>
        </div>
      </DialogSimplePop>

      <DialogSimplePop
        openPopup={showAgentNeedDialog}
        btnText="Register Agent"
        onClick={() => {
          setshowAgentCreateDialog(true);
          setshowAgentNeedDialog(false);
        }}
      >
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <IoWarningOutline size="50" color="#ffbf00" />
          <div
            style={{ textAlign: "center", fontSize: "16px", fontWeight: "500" }}
          >
            Awesome! One more step to go. You need at least one active agent for
            collection and delivery operations.
          </div>
        </div>
      </DialogSimplePop>

      <DialogSimplePop openPopup={showOTPRequired}>
        <OTPAfter num={4} />
      </DialogSimplePop>

      <DialogOutClick
        open={showLogout}
        handleClose={() => setShowLogout(false)}
      >
        <div style={{ width: "100%", padding: "30px 30px 20px 30px" }}>
          <div
            style={{
              width: "100%",
              marginBottom: "30px",
              fontSize: "13px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>Are you sure you want to logout?</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="disBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => setShowLogout(false)}
            >
              No
            </div>
            <div
              className="ediBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "white",
                backgroundColor: "#808080",
                border: "1px solid #808080",
              }}
              onClick={logMeOut}
            >
              Yes
            </div>
          </div>
        </div>
      </DialogOutClick>
    </>
  );
}
export default Dashboard;
