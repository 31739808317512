import React, {useEffect, useContext, useState} from 'react'
import TopBarHome from '../../components/TopBar/TopBarHome'
import "./CreateAccount.css"
import { Grid } from '@material-ui/core';
import Footer from '../../components/Footer/Footer';
import CustomPhoneNumber from '../../components/CustomPhoneNumber';
import { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {useHistory} from 'react-router-dom'
import SecureLS from 'secure-ls';
import GlobalizationLanguage, {GlobalizationLanguagewithCOntryCode} from '../../components/GlobalizationLanguage';
import { ConnContext } from '../../context/ConnContext';
import Cookies from "js-cookie";

function CreateAccount() {
    const { visitingCountryCode } = useContext(ConnContext);

    var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

    useEffect(() => {
        setCountry(Cookies.get('i18next'));
        },[Cookies.get('i18next')])

    const [country,setCountry]=useState(countryvalue);

    const [isError, setisError] = useState(false)

    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
    var logistics_companies = GlobalizationLanguage("/logistics-companies")
    var riders_and_drivers = GlobalizationLanguage(`/riders-and-drivers`)
    var login = GlobalizationLanguage(`/login`)
    var sender_signup = GlobalizationLanguage("/sender-signup")

    const history = useHistory();

    const schema = yup.object().shape({
        // email: yup.string().email("Valid email is required").required("Email is required"),
        email: yup
        .string()
        // .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Valid email is required")
        .matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, "Valid email is required")
        .required("Email is required"),
    });

    const { register, handleSubmit, formState: { errors }} = useForm({
        mode: 'all',
    resolver: yupResolver(schema),
    });


    const [userType, setUserType] = useState("")
    const [email, setEmail] = useState("")
    const [phone_no, setphone_no] = useState("");
    const [userTypeErr, setuserTypeErr] = useState(false)
    const [loading, setloading] = useState(false)
    
    const handleFormSubmit = (data)=>{
        if(userType === ""){
            setuserTypeErr(true)
        }
        else if (phone_no === undefined || !isValidPhoneNumber(phone_no)){
            console.log('number invalid')
            setisError(true)
        }
        else{
            if (userType !== ""){
                setloading(true)
                axios.post(`${process.env.REACT_APP_API}m-dillivry-prospect-funnel`,{
                    email: data.email.toLowerCase(),
                    phone_number: parsePhoneNumber(phone_no).number,
                    item_category: userType
                })
                .then((response) => {
                    setloading(false)
                    if(response?.data?.msg === "proceed to registration"){
                        if(userType === "senders"){
                            history.push({
                                pathname: sender_signup,
                                state: {
                                    from: 'Home_page',
                                    email: data?.email,
                                    phone_no: phone_no,
                                    }
                                })
                        }
                        else if(userType === "riders" || userType === "drivers"){
                            history.push(riders_and_drivers)
                        }
                        
                        else if(userType === "logistics_company"){
                            history.push({
                                pathname: logistics_companies,
                                state: {
                                    from: 'create_account',
                                    email: data?.email,
                                    phone_no: phone_no,
                                    }
                                })
                        }
                        else{

                        }
                       
                    }
                    else if( (response?.data?.user_status === "active" || response?.data?.user_status === "CONFIRMED") && response?.data?.phone_status === "verified"){
                        history.push({
                            pathname: login,
                            state: {
                                from: 'Home_page',
                                email: data?.email
                                }
                            })
                    }
                    else if( (response?.data?.user_status === "active" || response?.data?.user_status === "CONFIRMED") && response?.data?.phone_status === "not_verified"){
                        ls.set('_bisn',data?.email)
                        history.push({
                            pathname: sender_signup,
                            state: {
                                from: 'Home_page_OTP_required',
                                email: data?.email,
                                phone_no: phone_no,
                                }
                            })
                    }
                    else if (response?.data?.role === "dillivry-corp-sys-admin" ){
                        history.push({
                            pathname: GlobalizationLanguagewithCOntryCode('/login' , country, visitingCountryCode),
                            state: {
                                from: 'Home_page',
                                email: data?.email
                                }
                            })
                    }
                    else if (response?.data?.role === "dillivry-corp-last-mile-ops"){
                        // setShowErrorGet(true);
                    }
                    else if (response?.data?.role === "dillivry-sole-trader-ops"){
                        // setShowErrorGet(true);
                    }
                    else{
                        history.push(login)
                    }
                })
                .catch((err)=>{
                    console.log(err,"err")
                    setloading(false)
                })

            }
            
        }
    }

  return (
    <div>
        <TopBarHome/>
            <div className='createAccount'>
                <div className="home_max_width">
                    <div className="createAccountCont1">
                        <div className="leftCreateDownload">
                            {
                                userType === "senders" ? 
                                <>
                                    <h1>Sender</h1>
                                    <h6>Sign up  as a Business or Individual.</h6>
                                </>
                                :
                                userType === "riders" ? 
                                <>
                                    <h1>Riders</h1>
                                    <h6>Sign up  as a riders.</h6>
                                </>
                                :
                                userType === "drivers" ? 
                                <>
                                    <h1>Drivers</h1>
                                    <h6>Sign up  as a driver.</h6>
                                </>
                                :
                                userType === "logistics_company" ? 
                                <>
                                    <h1>Logistic company</h1>
                                    <h6 style={{marginBottom: "0"}}>Sign up if you have fleet of  different means of transportation</h6>
                                    <h6>- Bike, Car, Van, Truck</h6>
                                </>
                                :
                                <>
                                    <h1>Deliver with us</h1>
                                    <h6>Earn money with your vehicle.</h6>
                                </>
                            }
                            
                        </div>
                        <div className="rightCreateDownload">
                            <form onSubmit={handleSubmit(handleFormSubmit)} style={{width:'100%'}}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className ='comp_input_type1' style={{border: userTypeErr ? "1px solid red" : "none"}}>
                                           <select onChange={(e)=>{setuserTypeErr(false); setUserType(e.target.value)}}>
                                                <option value="">Account type</option>
                                                <option value="senders">Senders</option>
                                                <option value="riders">Riders</option>
                                                <option value="drivers">Drivers</option>
                                                <option value="logistics_company">Logistics company</option>
                                            </select> 
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomInput
                                            register={register}
                                            title="email"
                                            type='email'
                                            placeholder="Enter email address"
                                            borderColor={email !== "" ? "active" : ''}
                                            setvalue={setEmail}
                                            error={errors.email}
                                            value={email} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomPhoneNumber
                                            setPhone_number={setphone_no}
                                            bg="white"
                                            isError = {isError}
                                            setisError={setisError}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <input 
                                            type = "submit"
                                            className = 'comp_btn1' 
                                            value = {loading ? "Loading..." : "Get Started"}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        <Footer/>
    </div>
  )

}
export default CreateAccount;

const CustomInput = ({error, type,register,title, borderColor, placeholder, setvalue, value}) =>(
    <div className="CustomInput_container">
      <div className="CustomInput_input" 
            style={{border: error ? '1px solid red' : 
            borderColor === 'active' ? 
            '1px solid var(--main)' : 
            '1px solid #C4C4C4' ,
        }}>
        <input 
            {...register(title)}
            type = {type} 
            className = 'Input_input' 
            placeholder = {placeholder}
            value = {value}
            onChange={(e)=>setvalue(e.target.value)}
            required
        />
      </div>
    </div>
)