import React, { useState, useEffect, useContext } from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import { DashboardContext } from "../../../context/Dashboard/DashboardProvider/dashboard.provider";

import AccountSidebar from "./CorporateComponents/AccountSidebar";
import AccountNavbar from "./CorporateComponents/AccountNavbar";

const drawerWidth = 320;

export default function AccountLayout({children}) {

  const [accountInfo, setAccountInfo] = useState();

  const { dashboards} = useContext(DashboardContext);
 
  useEffect(() => {
    if (dashboards !== undefined) {
    setAccountInfo(dashboards?.data?.account_profile?.account_profile[0])
  }
}, [dashboards])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        style={{
            backgroundColor: "#ffffff",
            color: "black",
            boxShadow: "0px 0px 0px 0px",
            borderBottom: "1px solid  #C7E6C5",
            zIndex:"1280"
          }}
          
      >
         <>
          <AccountNavbar/>
        </>
      </AppBar>
      <div className="gogo">
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          
        }}
        variant="permanent"
        anchor="left"
      >
     
     <div className="accounttop"  style={{ backgroundColor:"#E0FCE7", paddingTop:"70px" }} >
      
      {/* <Toolbar /> */}
      <AccountSidebar accountInfo={accountInfo}/>
      <Divider />
     
    </div>
      
    </Drawer>
          
      </div>
    
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
          {children}
      </Box>
    </Box>
  );
}