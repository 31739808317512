import React,{Suspense} from 'react';
import ReactDOM from 'react-dom';
import "@stripe/react-stripe-js"
// import 'fixed-data-table-2/dist/fixed-data-table.css'
import App from './App';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
// import { createTheme } from '@material-ui/core/styles';
// import { ThemeProvider } from '@material-ui/styles';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    supportedLngs:['ng','gb','rw','gh','ke','za','ca'],
    fallbackLng: "gb",
    // defaultLocale: 'gb',
    detection:{
    order: [ 'path','cookie','htmlTag', 'localStorage',  'subdomain'],
    caches:['cookie'],
    },
    backend:{
      loadPath: '/assets/locales/{{lng}}/translation.json', 
    },
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

  const loading=(
    <div>
      loading
    </div>
  )
  const theme = createTheme({
    palette: {
      primary: {
        main: '#50B14A',
      },
      secondary: {
        main: '#11cb5f',
      },
    },
  });
ReactDOM.render(
  <Suspense fallback={loading}>
  <React.StrictMode>
  <ThemeProvider theme={theme}>
    <App/>
    </ThemeProvider>
  </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);
