import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import SecureLS from 'secure-ls';
import Currency from '../../utils/Currency';
import Swal from "sweetalert2";
import { IoArrowBack } from 'react-icons/io5';
import { FormControl, FormControlLabel, Box, LinearProgress, Radio, RadioGroup } from '@mui/material';
import { PuffLoader } from 'react-spinners';
import progress from "../../assets/progress.svg";
import check from "../../assets/check.svg";
import Copy from '../Copy';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const BankTransfer = ({ deliveries, total }) => {
    const numberWithCommas = new Intl.NumberFormat("en-GB");
    const [validrandom, setValidrandom] = useState();
    // const [checkpayment, setCheckpayment] = useState(false);
    const [checking, setChecking] = useState(false)
    
    const [showing, setshowing] = useState('bank_selection')
    const [progressing, setprogressing] = useState(false)
    const [timer, settimer] = useState(0)

    // useEffect(() => {
    //     if(timer !== 0){
    //         ConfirmPayment()
    //     }
    // }, [ timer])


    // useEffect(() => {
    //     RandomNumber()
    // }, [])

    

    // const RandomNumber = () => {
    //     return setValidrandom(Math.random().toString().substr(2, 6));
    // };


    const [selectedValue, setSelectedValue] = useState('providus_bank');
    const [accountDetails, setAccountDetails] = useState({ 'name': 'Providus Bank', 'acct_num': '5401083438' });

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        if (event.target.value === 'providus_bank') {
            setAccountDetails({
                'name': 'Providus Bank', 'acct_num': '5401083438'
            })
        }
        else if (event.target.value === 'gtbank') {
            setAccountDetails({
                'name': 'GTBank', 'acct_num': '0715260356'
            })
        }
        else if (event.target.value === 'sterling') {
            setAccountDetails({
                'name': 'Sterling Bank', 'acct_num': '0090748438'
            })
        }
        else {
            setAccountDetails({
                'name': 'Wema Bank', 'acct_num': '0124231027'
            })
        }
    };



    const ls = new SecureLS({
        isCompression: true,
        encodingType: "rabbit",
        encryptionSecret: "dm_dillivry",
    });

    const authAxios3 = axios.create({
        baseURL: ``,
        headers: {
            "authorization": ls.get("_utk"),
        },
        // response: true,
    });
    const endpoint = process.env.REACT_APP_API

    const authAxios = axios.create({
        baseURL: `${endpoint}`,
        headers: {
            Authorization: ls.get("_utk"),
        },
    });

     var callback = (data, error) => {
        if (error) {
            setprogressing(false)
            Swal.fire({
                icon : "info",
                iconColor : '#70c06c',
                html : `<div class="line_high"> ${error?.response?.data?.message ?  error?.response?.data?.message : 'Operation not successful'}</div>`,
                confirmButtonColor:'#50B14A'
            }).then(()=>{
                setChecking(false)
            })
          return;
        }

        setprogressing(false)
        Swal.fire({
            icon: "success",
            text: "Payment made successfully",
            timer: 3000,
            showConfirmButton: false,
        }).then(() => {
            window.location.reload();
        });
      };

    const ConfirmPayment = (retries, callback) =>{
        setprogressing(true)
        setChecking(true)
        authAxios.put(`m-dillivry-payment-and-verification-ops?delivery_location=ng&delivery_id=${deliveries.delivery_id}&narration=${validrandom}&type=confirm_payment&amount_paid=${total}&fulfiller_id=${deliveries?.fulfiller_id}`)
        .then((result)=>{
            callback(result);
        })
        .catch((error)=>{
            if (retries > 0) {
                setTimeout(() => {
                  ConfirmPayment(--retries, callback);
                }, 25000);
              }
              else {
                callback([], error);
              }
        })
    }

    const [gettingNarration, setgettingNarration] = useState(false);

    const getNarration = ()=>{
      setgettingNarration(true)
      authAxios.get(`${endpoint}m-dillivry-payment-and-verification-ops?type=payment_intent&delivery_location=ng&amount_paid=${total}&intent_type=pay_for_delivery&delivery_id=${deliveries.delivery_id}&fulfiller_id=${deliveries.fulfiller_id}`)
      .then((res)=>{
        setValidrandom(res?.data?.data?.narration_id);
        setshowing('transfer_page')
      })
      .catch((err)=>{
        console.log(err, "error resposen")
      })
      .finally(()=>{
        setgettingNarration(false)
      })
    }

    // const addNarration = () => {
    //     setCheckpayment(true)
    //     authAxios
    //         .post(
    //             `m-dillivry-quote-manager?delivery_location=ng&narration=${validrandom}&type=narration`,
    //             {
    //                 delivery_id: [deliveries?.delivery_id],
    //             }
    //         )
    //         .then((res) => {
    //             if (res?.status === 200) {
    //                 setChecking(true);
    //                 // settimer((prev)=> prev + 1)
    //                 ConfirmPayment();
    //                 console.log(res, "res from inititiate success");
    //             }
    //         })
    //         .catch((err) => {
    //             if (err?.response?.status === 400) {
    //                 console.log(
    //                     "error from initiate error but still call confirm payment",
    //                     err
    //                 );
    //                 // settimer((prev)=> prev + 1)
    //                 // console.log('timer', timer)
    //                 setChecking(true);
    //                 ConfirmPayment();

    //             }
    //         })
    //         .finally(() => {
    //             setCheckpayment(false)
    //         })
    // };

    return (
        <div>
            {
                showing === 'bank_selection' ?
                    <div className='popContainer_payment'>
                        <h1 style={{ fontSize: "14px", color: "#6F6F6F", margin: '0', textAlign: 'left', marginBottom: '15px' }}>
                            Kindly select your preferred bank
                        </h1>
                        <div className="radio_cont">
                            <FormControl>
                                <RadioGroup
                                    value={selectedValue}
                                    onChange={handleChange}
                                >
                                    <FormControlLabel className='radio_lbl' value="providus_bank" control={<Radio color="success" />} label="Providus Bank" />
                                    <FormControlLabel disabled className='radio_lbl' value="gtbank" control={<Radio color="success" />} label="GTBank" />
                                    <FormControlLabel disabled className='radio_lbl' value="sterling" control={<Radio color="success" />} label="Sterling Bank" />
                                    <FormControlLabel disabled className='radio_lbl' value="wema" control={<Radio color="success" />} label="Wema Bank" />
                                </RadioGroup>
                            </FormControl>
                        </div>

                        <div className="sent__btn">
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "20px 30px"
                                }}
                            >
                                {/* <div
                                    className="paystack_btn"
                                    style={{ padding: '6px' }}
                                    onClick={() => setshowing('transfer_page')}
                                >
                                    Proceed
                                </div> */}

                                <div
                                    className="paystack_btn"
                                    style={{ padding: '6px' }}
                                    onClick={gettingNarration ? ()=>console.log("hello") : getNarration}
                                >
                                    {
                                        gettingNarration ? "Loading..." : "Proceed"
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    showing === 'transfer_page' ?
                        <div className='popContainer_payment'>
                            {
                                checking ?
                                    <>
                                        <h1
                                            style={{
                                                fontFamily: "Roboto",
                                                fontStyle: "normal",
                                                fontSize: "14px",
                                                color: "#6F6F6F",
                                            }}
                                            className="transfer_text"
                                        >We are waiting to receive your transfer. This can take up to a minute</h1>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                gap: "20px",
                                            }}
                                            className="progressapprovedimg"
                                        >
                                            <div className="received">
                                                <img src={check} alt="checked" className="receivedimg" />
                                                <p
                                                    className="sent__text"
                                                    style={{
                                                        color: "#50B14A",
                                                    }}
                                                >
                                                    Sent
                                                </p>
                                            </div>

                                            <Box mt={3} mb={2} sx={{ width: "100%" }}>
                                                <LinearProgress loading={progressing} />
                                            </Box>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                }}
                                                className="sent"
                                            >
                                                <img
                                                    src={progress}
                                                    alt="progress"
                                                    className="receivedimg"
                                                />

                                                <p className="received">Received</p>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="transfer_amt"  >
                                            <div onClick={() => setshowing('bank_selection')}>
                                                <IoArrowBack size={20} />
                                            </div>
                                            <h1 style={{ fontSize: "14px", color: "#6F6F6F", margin: '0' }}>
                                                Transfer &#8358;{numberWithCommas.format(total)} to the account{" "}
                                            </h1>
                                        </div>
                                        <div className="bank__details">
                                            <h1 className="bank__name">{accountDetails.name} </h1>
                                            <h1 className="bank__acct">
                                                {accountDetails.acct_num}
                                                <Copy tocopy={accountDetails.acct_num}>
                                                    <ContentCopyOutlinedIcon sx={{fontSize:'20px', marginLeft:'10px', color:'grey'}}/>
                                                </Copy>
                                            </h1>
                                        </div>

                                        <div>
                                            <div
                                                className="text_informate" >
                                                <p style={{ textAlign: "center" }}>
                                                    Use this unique ID <br />
                                                    <span
                                                        style={{
                                                            color: "#004DC1",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            fontFamily: "Roboto",
                                                            fontSize: "32px",
                                                        }}
                                                    >
                                                        
                                                        {`${validrandom}`}
                                                        <Copy tocopy={validrandom}>
                                                            <ContentCopyOutlinedIcon sx={{fontSize:'20px', marginLeft:'10px', color:'grey'}}/>
                                                        </Copy>
                                                    </span>
                                                </p>
                                            </div>

                                            <div className="textid">
                                                <p
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                    className="maintxt"
                                                >
                                                    in your narration as payment reference to get value for the
                                                    payment
                                                </p>
                                            </div>

                                            <div className="textid" style={{color:'grey', fontSize:'12px', marginTop:'10px'}}>
                                                <p style={{  textAlign: "center" }} >
                                                    Click the button below once transfer is made successfully
                                                </p>
                                            </div>

                                        </div>

                                        <div className="sent__btn">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    marginTop: "20px",
                                                    marginBottom: "20px",
                                                }}
                                            >
                                                <div
                                                    onClick={()=>ConfirmPayment(10, callback)}
                                                    className="paystack_btn"
                                                    style={{ height: '50px' }}
                                                > I have sent the money </div>
                                            </div>
                                        </div>
                                    </>

                            }

                        </div>
                        :
                        null
            }

        </div>
    )
}

export default BankTransfer