import React, { useState, useContext, useEffect } from "react";
import { FormControl, Button, InputLabel, Grid } from "@material-ui/core";
import InputField from "../../components/InputField";
import InputAdornment from '@mui/material/InputAdornment';
import "./Login.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import PuffLoader from "react-spinners/PuffLoader";
import { AuthContext } from "../../context/AuthContext";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DialogSimplePop from "../../components/DialogSimplePop";
import Playstore from "../../assets/Playstore.png";
import Appstore from "../../assets/Appstore.png";
import{DashboardContext}  from '../../context/Dashboard/DashboardProvider/dashboard.provider'
import Accounthand2 from "../../assets/Accounthand2.svg";
import erros from "../../assets/erros.png";
import firstimage from "../../assets/iOS-getApp.svg";
import secondimage from "../../assets/Google-GetApp.svg";
import SecureLS from "secure-ls";
import { SenderContext } from "../../context/SenderDashboard/SenderDashboardContext";
import { ConnContext } from "../../context/ConnContext";
import Dillivry from "../../assets/Dillivry.png";
import Small from "../../assets/pageEclipseSmall.svg";
import Big from "../../assets/pageEclipse.svg";
import GlobalizationLanguage, { GlobalizationLanguagewithCOntryCode } from "../../components/GlobalizationLanguage";
import Cookies from "js-cookie";
function Login() {
  const Dashboard = GlobalizationLanguage("/Dashboard")
  var resetpass = GlobalizationLanguage("/resetpass")
  var reset = GlobalizationLanguage("/reset")
  var viewQuote = GlobalizationLanguage("/viewQuote")
  const forgetpass=GlobalizationLanguage("/forgetpass")

  const history = useHistory();
  const location = useLocation()

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if(location?.state?.from === "Home_page"){
      setusername(location?.state?.email)
    }
}, [])

  const {updateDashboard} = useContext(DashboardContext);
  const {initialstate, UpdateAlldata, alldata, setalldata, UpdateData, UpdateCartData, Cartdata, setCartdata, UpdatePromoInfo, PromoInfo, setPromoInfo,  Updatequote, GBStatistics, NGStatistics} = useContext(SenderContext)


  const authContext = useContext(AuthContext);
  const endPoint = process.env.REACT_APP_API;

  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [submitting, setsubmitting] = useState(false);
  const [showpassword, setshowpassword] = useState(false);
  const [userType, setuserType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const {visitingCountryCode, handlelogindata} = useContext(ConnContext)
  const endpoint = process.env.REACT_APP_API;

  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

  useEffect(() => {
      setCountry(Cookies.get('i18next'));
    },[Cookies.get('i18next')])

  const [country,setCountry]=useState(countryvalue);


  const handleLogin = async (e) => {
    e.preventDefault();
    var encodedPassword = btoa(password);
    setsubmitting(true);
    await Axios?.post(`${endPoint}m-dillivry-auth-manager`, {
      auth_type: "login",
      username: username.toLowerCase(),
      password: encodedPassword,
    }, )
      .then((response) => {

// console.log(response, "response")

    ls.set("user_location", response?.data?.vcc)

        handlelogindata(response)
        ls.set("aCs_", response?.data?.account_status);
        if (response) {
          ls.set("eid", response?.data?.user_name);
          if(response?.status === 202){
            history.push({
              // pathname: "/viewQuote",
              pathname: viewQuote,
             state: {
               from:'otp_required'
             },
           });
           setsubmitting(false);
          }

          if (response?.data?.auth_status === "new_password_required") {
            history.push({
              pathname: reset,
              state: username,
            });
          }
          else if(response?.data?.auth_status === "PasswordResetRequiredException"){
            setsubmitting(false);
            Swal.fire({
              icon: "warning",
              title: "Password reset",
              text: "Reset your password with the passcode sent to your email ",
              allowOutsideClick: false,
              confirmButtonColor: "gray",
            }).then((result) => {
              if (result.value) {
                history.push({
                  pathname: resetpass,
                  state: username,
                });
                setsubmitting(false);
              }
            });
          }
          
          else if (response?.data?.auth_status === "success") {
            const { IdToken } = response.data.token;
            const token = IdToken.split(".")[1];
            const encodedToken = atob(token);
            const tokenJSON = JSON.parse(encodedToken);
            ls.set("_tyl_", tokenJSON["custom:account_name"]);

            if (tokenJSON["custom:role"] !== undefined) {
              authContext.setAuthState(
                Object.assign(response.data, { role: tokenJSON["custom:role"] })
              );
              ls.set("_nodi", response?.data?.token?.IdToken);
              ls.set("_iRl", response.data.role);
              ls.set("nful", tokenJSON["given_name"]);
              ls.set("_tyf_", tokenJSON["custom:account_name"]);
              ls.set("_iyD", tokenJSON["custom:account_name"]);
              ls.remove("_utk");

              if (
                response.data.role === "dillivry-corp-sys-admin"
              
              ) {
                // console.log(response?.data?.data?.user_info[0]?.wallet_balance)
                authContext.setAuthState(
                  Object.assign(response.data, {
                    role: tokenJSON["custom:role"],
                  })
                );
                ls.set("_nodi", response?.data?.token?.IdToken);
                ls.set("eid", response?.data?.user_name);
                ls.set("_iRl", response?.data?.role);
                ls.set("nful", tokenJSON["given_name"]);
                ls.set("_tyf_", tokenJSON["custom:account_name"]);
                ls.set("btwl", response?.data?.data?.user_info[0]?.wallet_balance);// walllet balance

                ls.remove("_utk");

                axios
                  .get(`${endpoint}m-dillivry-dashboard-manager`, {
                    headers: {
                      Authorization: response.data.token.IdToken,
                    },
                  })
                  .then((response) => {
                    if(response.status === 201){
                      ls.set("lGd", response.data);
                    }
                    ls.set(
                      "means_of_delivery",
                      JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                    );
                    updateDashboard(response);
                    history.push({
                      pathname: Dashboard
                    });
                  })
                  .catch((error) => {
                    setsubmitting(false);

                    if (error?.response?.status === 404) {
                      history.push(Dashboard);
                    } 
                    else if (error?.response?.status === 403) {
                    } 
                    else {
                    }
                  });
              }
              else if (
              
                response.data.role === "dillivry-corp-bid-ops"
              ) {
                authContext.setAuthState(
                  Object.assign(response.data, {
                    role: tokenJSON["custom:role"],
                  })
                );
                ls.set("_nodi", response.data.token.IdToken);
                ls.set("eid", response.data.user_name);
                ls.set("_iRl", response.data.role);
                ls.set("nful", tokenJSON["given_name"]);
                ls.set("_tyf_", tokenJSON["custom:account_name"]);
                ls.remove("_utk");

                axios
                  .get(`${endpoint}m-dillivry-dashboard-manager`, {
                    headers: {
                      Authorization: response.data.token.IdToken,
                    },
                  })
                  .then((response) => {

                    ls.set(
                      "means_of_delivery",
                      JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                    );

                    history.push({
                      pathname: Dashboard,
                      state: [response.data, response.status],
                    });
                  })
                  .catch((error) => {
                    setsubmitting(false);
                    if (error?.response?.status === 404) {
                      history.push(Dashboard);
                    } else if (error?.response?.status === 403) {
                    } else {
                    }
                  });
              } 
              else if (
              
                response.data.role === "dillivry-sole-trader-ops"
              ) {
                setuserType("individualprovider");
                setShowAlert(true);
                setsubmitting(false);
                authContext.setAuthState(
                  Object.assign(response.data, {
                    role: tokenJSON["custom:role"],
                  })
                );
                ls.set("_nodi", response.data.token.IdToken);
                ls.set("eid", response.data.user_name);
                 ls.set("_iRl", response.data.role);
                ls.set("nful", tokenJSON["given_name"]);
                ls.set("_tyf_", tokenJSON["custom:account_name"]);
                ls.remove("_utk");
                ls.remove("_iRl")
                ls.remove("_nodi")
                ls.remove("eid")
                // history.push("/open-deliveries");
                setsubmitting(false);
              }
            }
            else {
              // console.log(response, "responseeee")
              ls.set("_iRl", "individual");
              ls.remove("_nodi");
              ls.set("eid", response.data.user_name);
              ls.set("_utk", response.data.token.IdToken);

              UpdateAlldata(response)
              UpdateData(response?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.flat())
              UpdateCartData(response?.data?.data?.user_info?.cart_history?.deliveries)
              NGStatistics(response?.data?.data?.deliveries?.map((x) => x?.ng ))
              UpdatePromoInfo(response?.data?.data?.user_info?.general_promo_code)
              GBStatistics(response?.data?.data?.deliveries?.map((x) => x?.gb ))
              Updatequote(response)
              handlelogindata(response)
             const Array_data =  response?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.flat()
             
             if(response?.status === 202){
              ls.set("eid", response.data.user_name);
              history.push({
                pathname: viewQuote,
                state: {
                 from:'otp_required'
               },
             });
             setsubmitting(false);
            }else{

            
            // console.log(response.data.data, 'res new data', response.data.role)
            ls.set("_iRl", "individual");
            // ls.remove("_nodi");
            ls.set("eid", response.data.user_name);
            ls.set("_utk", response.data.token.IdToken);
            // ls.set("_nodi", response.data.token.IdToken);
            history.push({
              pathname: viewQuote,
              state: Array_data,
            });
           
            setsubmitting(false);
          }

            }
          }

        }
      })
      .catch((error) => {
        console.log(error, "errorrr", error?.response)
        // ls.set("eid", username);
        ls.set("_utk", error?.response?.data?.token?.IdToken);
        if(error.response?.status === 401){
          ls.set("eid", username);
          // UpdateAlldata(undefined)
          // history.push({
          //   pathname: '/'
          // })
          history.push({
            pathname: viewQuote,
            state: {
             from:'registration'
           },
         });
         setsubmitting(false);
        }

       else if (error.response?.status === 403) {
        ls.set("eid", username);
          setsubmitting(false);
          setuserType("agent");
          setShowAlert(true);
        } 
        else if(error?.response?.data?.auth_status === "PasswordResetRequiredException"){
          setsubmitting(false);
          Swal.fire({
            icon: "warning",
            title: "Password reset",
            text: "Reset your password with the passcode sent to your email ",
            allowOutsideClick: false,
            confirmButtonColor: "gray",
          }).then((result) => {
            if (result.value) {
              history.push({
                pathname: resetpass,
                state: username,
              });
              setsubmitting(false);
            }
          });
        }
       
        else {
          Swal.fire({
            iconHtml: `<img src=${erros} style="width:100px;">`, // imageUrl: "dm-dillivry-web/web/src/assets/erros.png",
            title: "Oops!",
            imageAlt: "A tall image",
            text: "Invalid username or password",
            confirmButtonColor: "var(--main)",
          });
          setsubmitting(false);
        }
      });
  };
  
  return (
    
    <div className="loginContainer">
      <div className='loginHead'>
          <div className="loginLogo">
              <img src={Dillivry} alt="dillivry" onClick={()=>history.push(GlobalizationLanguagewithCOntryCode('/', country, visitingCountryCode))} />
          </div>
          <h3>Sign in</h3>
      </div>
      
        <form className="loginFormContainer" onSubmit={handleLogin}>
          <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControl className="formInput" variant="outlined">
                <InputField
                    label="Email"
                     required
                     name="username"
                     id="username"
                     type="email"
                     value={username}
                     onChange={(e) => {
                       setusername(e.target.value.trim());
                     }}
                    />
                </FormControl>
                {/* {
                  console.log(username, "username----")
                } */}
              </Grid>
              <Grid item xs={12}>
                <FormControl className="formInput" variant="outlined">
                  <InputField
                    label="Password"
                    required
                    name="password"
                    id="password"
                    type={showpassword ? "text" : "password"}
                    endIcon={
                      showpassword ? (
                        <Tooltip title="Hide password" interactive>
                          <VisibilityOffIcon
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                            onClick={() => setshowpassword(!showpassword)}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Show password" interactive>
                          <VisibilityIcon
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                            onClick={() => setshowpassword(!showpassword)}
                          />
                        </Tooltip>
                      )
                    }
                    value={password}
                    labelWidth={63}
                    onChange={(e) => { setpassword(e.target.value)}}
                  />
                  {/* {
                    console.log(password, "password")
                  } */}
                </FormControl>
              </Grid>


              
              <Grid item xs={12}>
                <div className="subtitle" style={{ textAlign: "left", fontSize:'13px' }}>
                  <span className="lgCheckBoxCon">
                    <input type="checkbox" className="lgcheckBox"></input>
                    <span style={{marginLeft: "20px"}}>Remember?</span> 
                  </span>

                  <span>
                    {/* <span>Forgot password? </span> */}
                    <Link to={forgetpass}>
                      <span style={{ color: "var(--main)" }}>Forgot password? </span>
                      {/* <span style={{ color: "var(--main)" }}>Rese</span> */}
                    </Link>
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} style={{margin:'20px 0'}}>
                <div className="lgBtnn">
                  {submitting ? (
                    <Button
                      size="large"
                      variant="contained"
                      type="submit"
                      disabled
                      style={{
                        backgroundColor: "var(--main)",
                        width: "100%",
                        color: "white",
                        borderRadius: "15px",
                        padding: "15px",
                        height: "40px",
                        textTransform: "none",
                      }}
                    >
                      <span className="btnlabe">
                        <PuffLoader
                          color="white"
                          loading={submitting}
                          speedMultiplier={1}
                          size={24}
                        />
                      </span>
                    </Button>
                  ) : (
                    <Button
                      size="large"
                      variant="contained"
                      type="submit"
                      disabled={username.length < 5 || password.length < 6}
                      style={{
                        backgroundColor: username.length >= 5 && password.length >= 6 ? "var(--main)" : "#B0AFB0",
                        width: "100%",
                        color: "white",
                        borderRadius: "15px",
                        padding: "15px",
                        textTransform: "none",
                      }}
                    >
                      <span className="btnlabe">Login</span>
                    </Button>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} style={{textAlign:'center', fontSize:'14px'}}>
                <span>
                  <span>Don’t have an account yet? </span>
                  <Link to={GlobalizationLanguage("/Create-account")}>
                    <span style={{ color: "var(--main)" }}>Signup</span>
                  </Link>
                </span>
              </Grid>
            </Grid>
        </form>

        <div className='logintopLeft'>
            <img src={Small} alt="dillivry"/>
        </div>
        <div className='logintopRight'>
            <img src={Big} alt="dillivry"/>
        </div>
        <div className='loginbottomLeft'>
            <img src={Big} alt="dillivry"/>
        </div>
        <div className='loginbottomRight'>
            <img src={Small} alt="dillivry"/>
        </div>

      <DialogSimplePop
        openPopup={showAlert}
        btnText="Ok"
        onClick={() => setShowAlert(false)}
      >
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <img src={erros} alt="dillivry" style={{ width: "60px" }} />
          <div
            style={{
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "500",
              width: "100%",
            }}
          >
            {userType === "agent" ? (
              <>
                <div>Oops! All agent operations is within the app.</div>
                <div>Please download the app and login to your account.</div>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "30px",
                  }}
                >
                  <img
                    src={secondimage}
                    alt="playstore"
                    className="storeLogo"
                  />
                  <img src={firstimage} alt="appstore" className="storeLogo" />
                </div>
              </>
            ) : userType === "individualprovider" ? (
             
              <>
              <div>Oops! All Riders/Drivers operations are within the app.</div>
              <div>Please download the app and login to your account.</div>
              <div
                style={{
                  display: "flex",
                  gap: "20px",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "30px",
                }}
              >
                <img
                  src={secondimage}
                  alt="playstore"
                  className="storeLogo"
                />
                <img src={firstimage} alt="appstore" className="storeLogo" />
              </div>
            </>


            ) : userType === "error" ? (
              <>
                <div>Forbidden access denied!</div>
                <div>
                  {" "}
                  Agent/Individual Provider? Please download the app and sign
                  in.
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "30px",
                  }}
                >
                  <img src={Playstore} alt="playstore" className="storeLogo" />
                  <img src={Appstore} alt="appstore" className="storeLogo" />
                </div>
              </>
            ) : userType === "bidder" ? (
              <>
                <div>Oops! You have selected a wrong account type.</div>
                <div>Check and try again.</div>
                <br></br>
                <div style={{ width: "100%" }}>
                  <img
                    src={Accounthand2}
                    alt="accounthand2"
                    className="handLogo"
                  />
                </div>
              </>
            ) : userType === "sender" ? (
              <>
                <div>Oops! You have selected a wrong account type.</div>
                <div>Check and try again.</div>
                <br></br>
                <div style={{ width: "100%" }}>
                  <img
                    src={Accounthand2}
                    alt="accounthand2"
                    className="handLogo"
                  />
                </div>
              </>
            ) : userType === "corporate" ? (
              <>
                <div>Oops! You have selected a wrong account type.</div>
                <div>Check and try again.</div>
                <br></br>
                <div style={{ width: "100%" }}>
                  <img
                    src={Accounthand2}
                    alt="accounthand"
                    className="handLogo"
                  />
                </div>
              </>
            ) : (
              <>
                <div>Oops! You have selected a wrong account type.</div>
                <div>Check and try again.</div>
                <br></br>
                <div style={{ width: "100%" }}>
                  <img
                    src={Accounthand2}
                    alt="accounthand"
                    className="handLogo"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </DialogSimplePop>
    </div>
  );
}

export default Login;