import React, { useState, useEffect, useContext } from 'react'
import { usePaystackPayment} from 'react-paystack';
import {MdNavigateNext} from 'react-icons/md'
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import Swal from 'sweetalert2'
import SecureLS from 'secure-ls';
import { ConnContext } from '../context/ConnContext';
import GlobalizationLanguage from './GlobalizationLanguage';
import Cookies from 'js-cookie';
const PaystackWidget2 = ({amount, email, delivery_id, fulfiller_id, amount_paid, fromReciever }) => {
  var viewQuote = GlobalizationLanguage("/viewQuote")
  var timeout = GlobalizationLanguage("/timeout")
  const login = GlobalizationLanguage("/login")

  const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
  const {visitingCountryCode} = useContext(ConnContext)
  const history = useHistory();
  const [TransDate, setTransDate] = useState('')
  const refresh = () =>{
    window.location.reload();}

    useEffect(() => {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      setTransDate(date);
    }, [])

    const endpoint = process.env.REACT_APP_API 
    
    const config = {
      reference: (new Date()).getTime(),
      email: email,
      amount: amount,
      channels: ['card', 'ussd'],
      // currency: 
      publicKey: process.env.REACT_APP_ENVIRONMENT === "PROD" || process.env.REACT_APP_ENVIRONMENT === "TEST" ? process.env.REACT_APP_PAYSTACK_KEY : process.env.REACT_APP_PAYSTACK_KEY_DEMO, 

  };

  // const { visitingCountryCode } = useContext(ConnContext);
  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

    const [country,setCountry]=useState(countryvalue);

    useEffect(() => {
      
        setCountry(Cookies.get('i18next'));
      },[Cookies.get('i18next')])
      


  // you can call this function anything
  const onSuccess = (reference) => {
    if(reference.status === 'success'){
      axios.post(`${endpoint}/m-dillivry-quote-manager?delivery_location=${country}`,{
        action_type: "make_payment",
        delivery_id: delivery_id,
        fulfiller_id: fulfiller_id,
        amount_paid: amount_paid,
        payment_date: TransDate,
        quoted_amount: amount_paid,
        payment_status: 'success',
        StatusCode: 200,
        reference_id: reference.reference,
        message: reference.message,
        transaction: reference.transaction,
        vcc: visitingCountryCode,
        payment_gateway: "paystack",
      }, {timeout: 240000})
      .then((response)=>{
          Swal.fire({
            icon: 'success',
            text: fromReciever ? "Payment made successfully" : 'Payment made successfully, refresh your page to reflect',
            confirmButtonColor:'var(--main)'
          })
          .then((result)=>{
            if(result.value && fromReciever){
              history.push('/')
            }
            else{
              ls.remove("item");
              ls.remove("delivery_id")
              history.push(viewQuote)
              refresh(); 
            }
          })
      })
      .catch((err)=>{
        console.log(err, 'error')
          Swal.fire({
            icon:'warning',
            text:'Request timeout',
            timer: 5000
          })
          if (err.code === "ECONNABORTED") {
            console.log('internal-server-error-timeout')
            history.push(timeout)
            // window.location.reload()
          }
          history.push(login)
      })
    }
    // Implementation for whatever you want to do with reference and after success call.
    
  };

  // you can call this function anything
  const onClose = () => {
  }

    const initializePayment = usePaystackPayment(config);
    return (
          <div className= {fromReciever ? "" : "acceptBTN"}  style={{padding:'10px 25px'}}
              onClick={() => {
                // closePopup();
                initializePayment(onSuccess, onClose);
               
              }}
              >
              <span style={{cursor:"pointer",borderRadius:"0px"}}> Make Payment </span>
               <div>
                 {/* <MdNavigateNext /> */}
               </div>
          </div>
        
    );
};

export default PaystackWidget2