import React from 'react';
import { useState } from 'react';
import './Popup.css'
import CloseIcon from '@material-ui/icons/Close';


function Popup(props){
    return(props.trigger)? (
        <div className="popup">
            {/* <button onClick={()=>props.setTrigger(false)} className="clsBtn"> close</button> */}
            
            
            <div className="popup-inner">
                <div className="XXheadingg">
                <span className='XXacctTyp'>Login</span>
                {
                    props.hideClose ? 
                    null
                    :
                    <CloseIcon onClick={()=>props.setTrigger(false)} className="clsBtn" style={{width:'25px', height:'35px'}}></CloseIcon>
                }

                </div>
           
                
                {props.children}
            </div>
        </div>
    )
    :"";

}
export default Popup