import React from 'react';
import style from "../components/DropdownComponent.module.css";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import GlobalizationLanguage from './GlobalizationLanguage';

const DropdownComponent = ({text, bgColor, color, outline, Size, widthh}) => {
    var create_account = GlobalizationLanguage("/create-account")

    const history = useHistory();

    const useStyles = makeStyles( theme => ({
        root: {
            width: "100%",
        },
        btn: {
            width: widthh,
            padding:'7px 22px',
            borderRadius:'20px',
            background: bgColor,
            color:  color,
            cursor: "pointer",
            border: `1px solid ${outline}`,
            '&:hover': {
               background: color,
               color:  bgColor,
               border: `1px solid ${bgColor}`,
            },
          },
    }));

    const classes = useStyles();
    return (
        <>
            <div className={style.dropdown} onClick={()=>history.push(create_account)}>
                <div className={classes.btn}
                    size={Size} 
                    style={{textTransform: "none"}} 
                >
                    <div style={{display: "flex",gap: "5px", alignItems: "center",justifyContent:"center"}}>
                        <span>{text}</span>
                        {/* <span>
                            <FaChevronDown size="12px" />
                        </span> */}
                    </div>
                </div>

                {/* <div className={style.dropdowncontent}>
                    <Link to="/sender-signup">Senders</Link>
                    <div className={style.dividar}></div>
                    <Link to="/riders-and-drivers">Riders</Link>
                    <div className={style.dividar}></div>
                    <Link to="/riders-and-drivers">Drivers</Link>
                    <div className={style.dividar}></div>
                    <Link to="/logistics-companies">Logistics company</Link>
                </div> */}
            </div>
        </>
    )
}

export default DropdownComponent