import React,{useEffect,useContext, useState} from 'react'
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import {BiBlock,BiArrowBack} from 'react-icons/bi'
import DialogOutClick from '../../../components/DialogOutClick';
import './UserView.css';
import axios from 'axios'
import PuffLoader from "react-spinners/PuffLoader";
import { ImUserCheck } from 'react-icons/im';
import { AuthContext } from '../../../context/AuthContext';
import SecureLS from 'secure-ls';
import {FormControl, Select, MenuItem, Button,InputLabel, Grid} from '@material-ui/core';
import {FaUserTag, FaUserTie,FaUserCircle} from 'react-icons/fa'
import Swal from 'sweetalert2';
import {useHistory} from 'react-router-dom';
import { ConnContext } from '../../../context/ConnContext';
import moment from "moment"
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
function UserView({userDetails, userType, goBack, updateUserOnDelete, updateUserOnDisable, updateUserOnEnable}) {
    var login=GlobalizationLanguage("/login")
  var timeout = GlobalizationLanguage("/timeout")

    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
    const history = useHistory();
    const {visitingCountryCode} = useContext(ConnContext)
    const auth = useContext(AuthContext);

    const [showDelete, setShowDelete] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [showDisable, setShowDisable] = useState(false)
    const [showAble, setShowAble] = useState(false)
    const [fulfillerType, setfulfillerType] = useState()
    
    const [loading, setloading] = useState(false);
    
    const endpoint = process.env.REACT_APP_API

    const deleteUser = () =>{
        if(userDetails?.num_assgn_deliveries > 0 || userDetails?.num_intransit_deliveries > 0 )
            {
                setShowDelete(false);
                Swal.fire({
                    icon: 'warning',
                    text: 'This account cannot be deleted. The agent has delivery request(s) yet to be completed. To delete, reassign the delivery request(s) to other agent(s)',
                    allowOutsideClick: false,
                    confirmButtonColor: 'var(--main)'
                })
            }
        else{
            setloading(true);
            axios.delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
                headers:{
                    Authorization: ls.get('_nodi')
                },
                data : {
                    "action_type": "delete_user",
                    "email": userDetails.user_id,
                    "vcc": visitingCountryCode
                }
            })
            .then((res)=>{
                setloading(false);
                setShowDelete(false);
                goBack();
                updateUserOnDelete(userDetails.user_id);
            })
            .catch((err)=>{
                setloading(false);
                if(err?.response?.status === 403){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Session!',
                        text: 'Session expired login again!',
                        allowOutsideClick: false,
                        confirmButtonColor: 'var(--main)'
                    })
                    .then((result) => {
                        if (result.value) {
                            auth.logout();
                            history.push(login) 
                        }
                    });
                }
                else{
                    Swal.fire({
                        icon: 'info',
                        text: 'An error occur try again',
                        timer: 3000,
                        showConfirmButton: false,
                    })
                }
            })
        }
    }

    const disableUser = () =>{
        if(userDetails?.num_assgn_deliveries > 0 || userDetails?.num_intransit_deliveries > 0 )
            {
                setShowDisable(false)
                Swal.fire({
                    icon: 'warning',
                    text: 'This account cannot be disabled. The agent has delivery request(s) yet to be completed. To disable, reassign the delivery request(s) to other agent(s)',
                    allowOutsideClick: false,
                    confirmButtonColor: 'var(--main)'
                })
            }
        else{
            setloading(true);
            axios.delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
                headers:{
                    Authorization: ls.get('_nodi')
                },
                data : {
                    "action_type": "disable_user",
                    "email": userDetails.user_id,
                    "vcc": visitingCountryCode
                // }
                }
            })

            .then((res)=>{
                setloading(false);
                setShowDisable(false);
                goBack();
                
                updateUserOnDisable(userDetails.user_id);

            })
            .catch((err)=>{
                setloading(false);
                setShowDisable(false)
                if(err?.response?.status === 403){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Session!',
                        text: 'Session expired login again!',
                        allowOutsideClick: false,
                        confirmButtonColor: 'var(--main)'
                    })
                    .then((result) => {
                        if (result.value) {
                            auth.logout();
                            history.push(login) 
                        }
                    });
                }
                else{
                    console.log(err)
                    setShowDisable(false)
                    Swal.fire({
                        icon: 'info',
                        text: 'An error occur try again',
                        timer: 3000,
                        showConfirmButton: false,
                    })
                }
            })
        }
    }

    const enableUser = () =>{

        setloading(true);
       
          axios.delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
            headers:{
                Authorization: ls.get('_nodi')
             },
             data : {
                "action_type": "enable_user",
                "email": userDetails.user_id,
                "vcc": visitingCountryCode
            }
          })

        .then((res)=>{
            setloading(false);
            setShowAble(false);
            goBack();
            updateUserOnEnable(userDetails.user_id);
        })
        .catch((err)=>{
            console.log(err)
            setloading(false);
            setShowAble(false)
            if(err?.response?.status === 403){
                Swal.fire({
                    icon: 'warning',
                    title: 'Session!',
                    text: 'Session expired login again!',
                    allowOutsideClick: false,
                    confirmButtonColor: 'var(--main)'
                })
                .then((result) => {
                    if (result.value) {
                        auth.logout();
                        history.push(login) 
                    }
                });
            }
            else{
                setShowAble(false)
                Swal.fire({
                    icon: 'info',
                    text: 'An error occur try again',
                    timer: 3000,
                    showConfirmButton: false,
                })
            }
        })
    }
    const authAxios = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers:{
           Authorization:  ls.get('_nodi')
        }
    })

    const updateUser = () =>{
        setloading(true);
       
        authAxios.post(`m-dillivry-user-manager`, {
                "action_type": "update",
                "role" : fulfillerType,
                "email": userDetails.user_id,
                "vcc": visitingCountryCode
          }, {timeout: 240000})

        .then((res)=>{
            setloading(false);
            setShowEdit(false);
            goBack();
            updateUserOnEnable(userDetails.user_id);
            window.location.reload()
        })
        .catch((err)=>{
            setloading(false);
            setShowEdit(false);
            if(err?.response?.status === 403){
                Swal.fire({
                    icon: 'warning',
                    title: 'Session!',
                    text: 'Session expired login again!',
                    allowOutsideClick: false,
                    confirmButtonColor: 'var(--main)'
                })
                .then((result) => {
                    if (result.value) {
                        auth.logout();
                        history.push(login) 
                    }
                });
            }
            else{
                setShowEdit(false);
                Swal.fire({
                    icon: 'info',
                    text: 'An error occur try again',
                    timer: 3000,
                    showConfirmButton: false,
                })
            }
            if (err.code === "ECONNABORTED") {
                console.log('internal-server-error-timeout')
                history.push(timeout)
                // window.location.reload()
              }
        })
    }

    return (
        <div className="userView">
           <div onClick={goBack} style={{color:"var(--main)"}}><BiArrowBack fontSize={25}/>  </div>
            <div className="userCards">
                <div className="userCardsTop">
                    {userDetails.account_name}
                </div>
                <div className="userCardsBody">
                    <div style={{padding:'10px'}}>
                        <div className="userCardsImage" style={{backgroundImage: `url(${userDetails?.profile_photo?.profile_photo})`}}>
                        </div>
                    </div>
                    <div className="userCardsDetailsss">
                        <div className="userCardsDetails">
                            <div className="userCardsDetail">
                                <span style={{width:'130px', display:'flex', justifyContent:'flex-start'}}>Name:</span>
                                <span>{userDetails.last_name + " " +userDetails.first_name}</span>
                            </div>
                        </div>
                        <div className="userCardsDetails">
                            <div className="userCardsDetail">
                                <span style={{width:'130px', display:'flex', justifyContent:'flex-start'}}>Phone no.:</span>
                                <span>{userDetails?.phone_number}</span>
                            </div>
                        </div>
                        <div className="userCardsDetails">
                            <div className="userCardsDetail">
                                <span style={{width:'130px', display:'flex', justifyContent:'flex-start'}}>Email:</span>
                                <span>{userDetails.user_id}</span>
                            </div>
                        </div>
                        <div className="userCardsDetails">
                            <div className="userCardsDetail">
                                <span style={{width:'130px', display:'flex', justifyContent:'flex-start'}}>Role:</span>
                                <span style={{display:'flex', justifyContent:'flex-start'}}>{userDetails.role === 'dillivry-corp-last-mile-ops' ? 'Agent' : userDetails.role === 'dillivry-corp-sys-admin' ? 'Admin' : 'Bidder'}</span>
                            </div>
                        </div>
                        <div className="userCardsDetails">
                            <div className="userCardsDetail">
                                <span style={{width:'130px', display:'flex', justifyContent:'flex-start'}}>Status:</span>
                                {
                                    userDetails.current_status === "active" ?
                                        <div style={{display:'flex', justifyContent:'flex-start'}}>
                                           <span style={{background:'var(--main)', padding:'2px 10px', color:"white", borderRadius:'10px'}}>Active</span> 
                                        </div> 
                                        :
                                        userDetails.current_status === "disabled" ?
                                            <div style={{display:'flex', justifyContent:'flex-start'}}>
                                                <span style={{background:'rgba(255, 99, 71, 0.5)', padding:'2px 10px', color:"red", borderRadius:'10px'}}>Disabled</span>
                                             
                                            </div> 
                                            :
                                            userDetails.current_status === "device_not_active" ?
                                                <div style={{display:'flex', justifyContent:'flex-start'}}>
                                                    <span style={{background:'#a9a9a9', padding:'2px 10px', color:"white", borderRadius:'10px'}}>Device not activated</span>
                                                </div>
                                                :
                                                null
                                }
                            </div>
                        </div>
                        <div className="userCardsDetails">
                            <div className="userCardsDetail">
                                <span style={{width:'130px', display:'flex', justifyContent:'flex-start'}}>Created time:</span>
                                <span style={{display:'flex', justifyContent:'flex-start'}}>{moment(userDetails.account_status?.create_time_stamp).format('DD-MM-YYYY hh:mm:ss')}</span>
                            </div>
                        </div>
                        

                        {
                             userDetails.current_status === "disabled" && userDetails.account_status.disabled_date ?

                        
                        <div className="userCardsDetails">
                            <div className="userCardsDetail">
                                <span style={{width:'130px', display:'flex', justifyContent:'flex-start'}}>Disabled time:</span>
                     
                                <span> { userDetails.account_status.disabled_date.disabled_time_stamp}</span>
                                                
                            </div>
                        </div>
                         :
                         userDetails.account_status.enabled_date ?
                            <div className="userCardsDetails">
                                <div className="userCardsDetail">
                                    <span style={{width:'130px', display:'flex', justifyContent:'flex-start'}}>Enabled time:</span>
                        
                                    <span> { userDetails?.account_status.enabled_date.enabled_time_stamp}</span>
                                                    
                                </div>
                            </div>
                            :

                         null

                        }
   

                        
                       
                       
                        {
                            userType === 'dillivry-corp-sys-admin' ? 
                            null
                            :
                            <>
                                {userDetails.fulfilling_agent_id !== undefined ? 
                                    <>
                                    <div className="userCardsDetails">
                                        <div className="userCardsDetail">
                                            <span style={{width:'130px', display:'flex', justifyContent:'flex-start'}}>Out for delivery:</span>
                                            <span style={{display:'flex', justifyContent:'flex-start'}}>({userDetails?.num_intransit_deliveries})</span>
                                        </div>
                                    </div>
                                    <div className="userCardsDetails">
                                        <div className="userCardsDetail">
                                            <span style={{width:'130px', display:'flex', justifyContent:'flex-start'}}>Awaiting pickup:</span>
                                            <span style={{display:'flex', justifyContent:'flex-start'}}>({userDetails?.num_assgn_deliveries})</span>
                                        </div>
                                    </div>
                                    {/* <div className="userCardsDetails">
                                        <div className="userCardsDetail">
                                            <span>Missed:</span>
                                            <span>Julius iyabode</span>
                                        </div>
                                    </div> */}
                                    <div className="userCardsDetails">
                                        <div className="userCardsDetail">
                                            <span style={{width:'130px', display:'flex', justifyContent:'flex-start'}}>Completed:</span>
                                            <span style={{display:'flex', justifyContent:'flex-start'}}>({userDetails?.num_completed_deliveries})</span>
                                        </div>
                                    </div>
                                    </>
                                    :
                                    null
                                }
                            </>
                        }
                        
                    </div>
                    <div className="userCardsButtons">
                        {/* {
                            userDetails.role === "dillivry-corp-last-mile-ops" ? 
                            null
                            : */}
                            <div className="delBtn" onClick={()=>setShowDelete(true)}>
                             <span><AiFillDelete/></span> <span>Delete</span> 
                        </div>
                        {/* } */}
                        
                        {
                            userDetails.current_status === 'disabled' ?
                                <div className="ediBtn" onClick={()=>setShowAble(true)}>
                                    <span><ImUserCheck/></span> <span>Enable</span> 
                                </div>
                                :
                                userDetails.current_status === 'active' ?
                                <div className="disBtn" onClick={()=>setShowDisable(true)}>
                                    <span><BiBlock/></span> <span>Disable</span> 
                                </div>
                                :
                                null
                               
                        }
                        
                        {
                            userDetails.current_status === "device_not_active" ? 
                            null
                            :
                            userDetails.role === "dillivry-corp-bid-ops" ?
                                <div className="ediBtn" onClick={()=>setShowEdit(true)}>
                                    <span><AiFillEdit/></span> <span>Edit</span> 
                                </div>
                                :
                                null
                        }
                            
                    </div>
                </div>
            </div>
            <DialogOutClick
                open={showDelete}
                handleClose ={()=>setShowDelete(false)}
                > 
                <div style={{width:'100%',padding:"30px 30px 20px 30px"}}>
                    <div style={{width:'100%',marginBottom:"30px", fontSize:'13px', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <span>Are you sure you want to delete this user account?</span>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
                        <div className="disBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer'}}
                            onClick={()=>setShowDelete(false)} >
                            No
                        </div>
                        <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'white', backgroundColor:'#808080', border:'1px solid #808080' }}
                            onClick={deleteUser}>
                                {
                                    loading ? 
                                    <PuffLoader color='white' loading={loading}  speedMultiplier={1} size={15}/> 
                                    :
                                    'Yes'
                                }
                        </div>
                    </div>
                </div>
            </DialogOutClick>
            <DialogOutClick
                open={showDisable}
                handleClose ={()=>setShowDisable(false)}
                > 
                <div style={{width:'100%',padding:"30px 30px 20px 30px"}}>
                    <div style={{width:'100%',marginBottom:"30px", fontSize:'13px', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <span>Are you sure you want to disable this user account?</span>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
                        <div className="disBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer'}}
                            onClick={()=>setShowDisable(false)} >
                            No
                        </div>
                        <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'white', backgroundColor:'#808080', border:'1px solid #808080' }}
                            onClick={disableUser}>
                            {
                                loading ? 
                                <PuffLoader color='white' loading={loading}  speedMultiplier={1} size={15}/> 
                                :
                                'Yes'
                            }
                        </div>
                    </div>
                </div>
            </DialogOutClick>
            <DialogOutClick
                open={showAble}
                handleClose ={()=>setShowAble(false)}
                > 
                <div style={{width:'100%',padding:"30px 30px 20px 30px"}}>
                    <div style={{width:'100%',marginBottom:"30px", fontSize:'13px', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <span>Are you sure you want to able this user account?</span>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
                        <div className="disBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer'}}
                            onClick={()=>setShowAble(false)} >
                            No
                        </div>
                        <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'white', backgroundColor:'#808080', border:'1px solid #808080' }}
                            onClick={enableUser}>
                            {
                                loading ? 
                                <PuffLoader color='white' loading={loading}  speedMultiplier={1} size={15}/> 
                                :
                                'Yes'
                            }
                        </div>
                    </div>
                </div>
            </DialogOutClick>
            <DialogOutClick
                open={showEdit}
                handleClose ={()=>setShowEdit(false)}
                > 
                <Grid container spacing={3} style={{padding:'20px', width:'300px'}}>
                    <Grid item xs={12}>
                        <h4>Update user role </h4>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl size='small' variant="outlined" className='formInput'>
                        <InputLabel id="select"><span style={{color:"var(--main)", marginRight:"10px"}}><FaUserCircle/></span>Select User Role</InputLabel>
                        <Select
                            labelId="select"
                            value={fulfillerType}
                            onChange={(e)=>setfulfillerType(e.target.value)}
                            label="Select User Role"
                            >
                            <MenuItem value= "corp_sys_admin"><span style={{color:"var(--main)",  marginRight:"10px"}}><FaUserTie/></span>Admin</MenuItem>
                            {/* <MenuItem value= "corp_bid_ops"><span style={{ color:"var(--main)", marginRight:"10px"}}><FaUserCircle/></span>Bidder</MenuItem> */}
                            {/* <MenuItem value= "corp_last_mile_ops"><span style={{color:"var(--main)",  marginRight:"10px"}}><FaWalking/></span>Fulfilling Agent</MenuItem> */}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{display:'flex',justifyContent:'flex-end'}}>
                           {loading ?
                                <Button
                                    size="small" 
                                    variant="contained" 
                                    style={{fontSize:'11px', maxHeight:'30px', border:'1px solid var(--main)', backgroundColor:'var(--main)', color:'white', textTransform:'none'}}
                                    
                                    >
                                    <div style={{display:'flex', gap:'5px', alignItems:'center', justifyContent:'center'}}>
                                        <PuffLoader color='white' loading={loading}  speedMultiplier={1} size={15}/> 
                                    </div>
                                </Button>
                                :
                                <Button
                                    size="small" 
                                    variant="contained" 
                                    style={{fontSize:'11px', maxHeight:'30px', border:'1px solid var(--main)', backgroundColor:'var(--main)', color:'white', textTransform:'none'}}
                                    onClick={updateUser}
                                    >
                                        <div style={{display:'flex', gap:'5px', alignItems:'center', justifyContent:'center'}}>
                                            <span><FaUserTag  size='12px'/></span>
                                            <span>Update</span>
                                        </div>
                                </Button>
                           } 
                        </div>
                    </Grid>
                </Grid>
            </DialogOutClick>
        </div>
    )
}

export default UserView
