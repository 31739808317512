import React, { useState, useEffect, useContext } from "react";
import Layout from '../../Layout';
import "./Message.css"
import directMsg from "../../../../../assets/plusIcon.svg"
import { useHistory, Link } from 'react-router-dom';
import moment from "moment";
import axios from "axios";
import SecureLS from "secure-ls";
import { SenderContext } from "../../../../../context/SenderDashboard/SenderDashboardContext"
import { ConnContext } from "../../../../../context/ConnContext";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import MessageMobile from "./MessageMobile";
import GlobalizationLanguage, { GlobalizationLanguagewithCOntryCode } from "../../../../../components/GlobalizationLanguage";
import Cookies from "js-cookie";
export default function Messages() {
  var timeout = GlobalizationLanguage("/timeout")
  

  const capitalize =(x)=>{
    return(
      x
      //  x?.charAt(0)?.toUpperCase() + x?.slice(1)

    ) 
  }


  const messageHead=[
    {
      thead: "Subject"
    },
    {
      thead: "Message"
    },
    {
      thead: "Date & Time"
    }, 
  ]

  const history = useHistory();
  const { initialstate, UpdateData, alldata , msgdrildown, UpdateMssgDrill} = useContext(SenderContext);

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const {visitingCountryCode} = useContext(ConnContext)
  const [enquiryMsg, setEnquiryMsg] = useState([]);
  const [msgLength, setmsgLength] = useState(0);
  // const [paginateActivePage, setPaginateActivePage] = useState(1);
  const [viewData, setviewData] = useState([]);
  // const n = 10;
  const [isloading, setIsloading] = useState(false);
  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

  const [country,setCountry]=useState(countryvalue);

  useEffect(() => {
      setCountry(Cookies.get('i18next'));
    },[Cookies.get('i18next')])
    


  useEffect(() => {
    setIsloading(true);
      axios.get(
        `${process.env.REACT_APP_API}m-dillivry-sender-api?attribute=get_message_list`,
        {
          headers: {
            Authorization: ls.get("_utk"),
          },
        }
      )

        .then((response) => {
          console.log(response.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat().map(x => x.quote_messages).flat(), "responseee")
          if (response.status === 200) {

            // UpdateData(response)
            setIsloading(false);
            ls.set("fo_in", JSON?.stringify(response?.data?.account));
            let Data = [];
            Data = response?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat()?.map(x => x.quote_messages)?.flat();
            setviewData([...Data]);
            setmsgLength(Data.length);
            setEnquiryMsg(Data);
            UpdateMssgDrill(response?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat().map(x => x?.quote_messages).flat())
            // setEnquiryMsg(Data.slice(0, n));
         
          }
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            console.log('internal-server-error-timeout')
            history.push('/timeout')
          }
          setIsloading(false)
        });
    
  }, []);


  
  return (
    <div>
      <div className="dsktopscreen">
      <Layout>
      <div className='msg-wrapper'>
      {isloading ? (
            <>
              <Box
                sx={{ width: "100%", marginBottom: "10px", marginTop: "4px" }}
              >
                <LinearProgress color="success" />
              </Box>
            </>
          ) : (
            <></>
          )
      }

        {/* <div className="first-msg"> */}
        <table className='msg-table'>
        {
        messageHead.map((msg)=> <th className='msg-thead'>{msg.thead}</th> )
        
        }

        
      {
       enquiryMsg?.length === 0 ?
         <tbody>
           <tr className='msg-trow'>
             <td className='msg-tdata' colSpan={3}> -- You don't have message(s) yet -- </td>
           </tr>
        </tbody>
        :
      [...enquiryMsg]?.reverse()?.sort(function (left, right) {
        return moment.utc(left.msg_time_stamp).diff(moment.utc(right.msg_time_stamp))
        })?.reverse()?.map((msg,index) =>
        <tbody>
          <tr className='msg-trow' key={index} 
          onClick={()=> history.push(GlobalizationLanguagewithCOntryCode(`/mssg/${msg?.delivery_id}`, country, visitingCountryCode))}
          >
            <td className='msg-tdata'> {capitalize(msg['subject:quote']) }</td>
            <td className='msg-tdata'> {capitalize(msg?.fulfiller_conversation[0]?.conversation[0]?.msg)}</td>
            <td className='msg-tdata'>
            { moment().diff(msg?.msg_time_stamp, 'days') === 0?
              <> 
                {moment(msg?.msg_time_stamp).format(
                "MMM Do YYYY, h:mm a ")}
                </>
                :
                <> 
                {moment(msg?.msg_time_stamp).format(
                "MMM Do YYYY, h:mm a ")}
                </>              
              } 
            
            </td>
          </tr>
        </tbody>
        )
        
        }
        
        
        
      </table>
        {/* </div> */}



       









      {/* <div className='direct-msg-icon-con' onClick={() => {history.push( `/mssg/${msg.delivery_id}`) }} > <img src={directMsg} alt="" className='direct-msg-icon' /></div> */}
      {/* <div className='direct-msg-icon-con'  > <img src={directMsg} alt="" className='direct-msg-icon' /></div> */}
      </div>
    </Layout>
      </div>
      <div className="mobilescreen">
        <MessageMobile isloading={isloading} enquiryMsg={enquiryMsg} />
      </div>
    </div>
  );
}
