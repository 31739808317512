import React from 'react'

export default function Pagination({ onFirstClick,onPreviousClick,onNextClick,onNumClick,onLastClick, setitemLength,itemLength, setDeefault,deefault,paginateActivePage,setPaginateActivePage, n}) {
    return (
        <div>
            <div className="viewmenudesktop">
              <span
                className="paginateButtons"
                style={{
                  color: paginateActivePage === 1 ? "white" : "",
                  backgroundColor:
                    paginateActivePage === 1 ? "var(--main)" : "",
                  cursor: paginateActivePage === 1 ? "not-allowed" : "pointer",
                }}
                onClick={onFirstClick}
              >
                First
              </span>
              {paginateActivePage === 1 ? (
                <span
                  className="paginateButtons"
                  style={{ color: "#989898", cursor: "not-allowed" }}
                >
                  Previous
                </span>
              ) : (
                <span
                  className="paginateButtons"
                  onClick={onPreviousClick}
                >
                  Previous
                </span>
              )}

              {[...Array(Math.ceil(itemLength / n))].map((e, i) => (
                <span
                  className="paginateButtons"
                  style={{
                    backgroundColor:
                      paginateActivePage === i + 1 ? "var(--main)" : "",
                    color: paginateActivePage === i + 1 ? "white" : "",
                  }}
                  key={i}
                  onClick={()=>onNumClick(i)}
                >
                  {i + 1}
                </span>
              ))}
              {paginateActivePage === Math.ceil(itemLength / n) ? (
                <span
                  className="paginateButtons"
                  style={{ color: "#989898", cursor: "not-allowed" }}
                >
                  Next
                </span>
              ) : (
                <span
                  className="paginateButtons"
                  onClick={onNextClick}
                >
                  Next
                </span>
              )}
              <span
                className="paginateButtons"
                style={{
                  backgroundColor:
                    paginateActivePage === Math.ceil(itemLength / n)
                      ? "var(--main)"
                      : "",
                  color:
                    paginateActivePage === Math.ceil(itemLength / n)
                      ? "white"
                      : "",
                }}
                onClick={onLastClick}
              >
                Last
              </span>
            </div>
          </div>
    )
}
