import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SecureLS from "secure-ls";
import { AuthContext } from "../../../context/AuthContext";
import { DashboardContext } from "../../../context/Dashboard/DashboardProvider/dashboard.provider";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import axios from "axios";
import LoginCorporate from "../../Login/LoginCorporate";
import TopBar from "../../../components/TopBar/TopBar";
import { TiMessages } from "react-icons/ti";
import { AiTwotoneShop } from "react-icons/ai";
import { BiTimer, BiWallet, } from "react-icons/bi";
import { RiAccountPinCircleLine } from "react-icons/ri";
import "./Wallet.css";
import wallet3 from "../../../assets/wallet3.svg";
import setting2 from "../../../assets/setting2.svg";
import walletminus from "../../../assets/walletminus.svg";
import emptywalletadd from "../../../assets/emptywalletadd.svg";
import DialogPop from "../../../components/DialogPop";
import moment from "moment";
import ClipLoader from 'react-spinners/ClipLoader';
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import DashBottomBtn from "../../Fulfiller/Dashboard/DashBottomBtn/DashBottomBtn";

import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
const Wallet = () => {
  var account = GlobalizationLanguage("/account")
  var accounttransaction = GlobalizationLanguage("/account/transaction")
  var deliveries_history = GlobalizationLanguage("/deliveries_history")
  var messages = GlobalizationLanguage("/messages")
  const Dashboard = GlobalizationLanguage("/Dashboard")

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [showwithdraw, setshowwithdraw] = useState(false);
  const [proceedwithdraw, setproceedwithdraw] = useState(false);

  const { dashboards, updateDashboard, update_balc_withdraw } = useContext(DashboardContext);
  const [ShowLogin, setShowLogin] = useState(false);
  const [loadingData, setloadingData] = useState(false);
  const [withdrawamt, setwithdrawamt] = useState("");
  const [isloading, setisloading] = useState(false);
  const [creditstate, setcreditstate] = useState(false);
  const [allstatet, setallstatet] = useState("all");

  const [walletHistory, setwalletHistory] = useState([]);
  const [walletBalc, setwalletBalc] = useState(0);
  const [refreshBalance, setrefreshBalance] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [checkfocus, setcheckfocus] = useState(false)


  const numberWithCommas = new Intl.NumberFormat("en-GB")

  const endpoint = process.env.REACT_APP_API;

  const withdrawamount = (e) => {
    e.preventDefault();
    setproceedwithdraw(true);
    setshowwithdraw(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setshowwithdraw(false);
  };

  const buttonstyle = () => {
    if(withdrawamt === "" ){
      console.log("can't find anything")
      setcheckfocus(!checkfocus)
    }
  };

  const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers: {
      Authorization: ls.get("_nodi"),
    },
  });

  const Withdrawalfunction = (e) => {
    e.preventDefault()
    setisloading(true);
    authAxios
      .post(`m-dillivry_instant-payment`, {
        transaction_type: "debit",
        amount: withdrawamt,
      })
      .then((res) => {
        setisloading(false);
        setproceedwithdraw(false);
        Swal.fire({
          icon: "success",
          text: `Your withdrawal of #${withdrawamt} was successful`,
          showConfirmButton: true,
          confirmButtonColor: "#50B14A",
          confirmButtonText: "Continue"
        }).then((result) => {
          if (result.value) {
            update_balc_withdraw(`${parseFloat(walletBalc) - parseFloat(withdrawamt)}`)
            // window.location.reload()
            setShowIcon(true)
            setTimeout(() => {
              setShowIcon(false)
              setrefreshBalance(!refreshBalance)
            }, 2000);
          }
        })

      })
      .catch((err) => {
        console.log(err.response);
        setisloading(false);
        setproceedwithdraw(false);
        Swal.fire({
          icon: "error",
          text: `${
            err?.response?.statusText
              ? err?.response?.statusText
              : "Withdrawal failed. Try again"
          }`,
          // text: err?.response?.statusText !== '' ? `Connection timeout. Please try again` : "Error occured",
          confirmButtonColor: "#50B14A",
          confirmButtonText: "Continue"
        });
        setisloading(false);
      });
  };



  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      ls.get("_iRl") === "" ||
      ls.get("_iRl") === "dillivry-sole-trader-ops" ||
      ls.get("_nodi") === ""
    ) {
      // setShowLogin(true);
      history.push('/login')
      auth.logout();
    } else {
      if (dashboards !== undefined) {
        setwalletHistory(dashboards?.data?.wallet_history)
        setwalletBalc(dashboards?.data?.account_profile?.account_profile[0]
          ?.wallet_balance)
      } else {
        const decoded = jwt_decode(ls.get("_nodi"));
        const expired = decoded.exp;
        if (expired < Date.now() / 1000) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              auth.logout();
              // setShowLogin(true);
              history.push('/login')
            }
          });
        } else {
          setloadingData(true);
          axios
            .get(`${endpoint}m-dillivry-dashboard-manager`, {
              headers: {
                Authorization: ls.get("_nodi"),
              },
            })
            .then((response) => {
              ls.set(
                "means_of_delivery",
                JSON.stringify(
                  response?.data?.account_profile?.account_profile[0]
                    ?.mode_of_delivery
                )
              );
              setloadingData(false);
              updateDashboard(response);
              setwalletHistory(response?.data?.wallet_history)
              setwalletBalc(response?.data?.account_profile?.account_profile[0]
                ?.wallet_balance)
            })
            .catch((error) => {
              if (error?.response?.status === 403) {
                Swal.fire({
                  icon: "warning",
                  title: "Session!",
                  text: "Session expired login again!",
                  allowOutsideClick: false,
                  confirmButtonColor: "var(--main)",
                }).then((result) => {
                  if (result.value) {
                    auth.logout();
                    // setShowLogin(true);
                    history.push('/login')
                  }
                });
              } else {
                auth.logout();
                // setShowLogin(true);
                history.push('/login')
              }
              setloadingData(false);
            });
        }
      }
    }
  }, [refreshBalance]);

 

  return (
    <div>
      
     
      {ShowLogin ? (
        <LoginCorporate />
      ) : (
        
        <div className="dashboard">
          
          {loadingData ? (
            <div
              style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "none",
                zIndex: "300",
                position: "fixed",
                top: "0",
                paddingTop: "80px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <LinearProgress color="success" />
              </Box>
            </div>
          ) : null}
          <div className="dashboardDesktop">
            <div className="topmenu">
              <TopBar />
            </div>
            <div className="content-view_menu">

              <div className="tab" onClick={() => history.push(Dashboard)}>
                <AiTwotoneShop /> &nbsp; Dillivry Activities
              </div>
              <div className="activeTabs">
                <BiWallet /> &nbsp; Wallet
              </div>
              <div onClick={() => history.push(messages)} className="tab">
                <TiMessages /> &nbsp; Messages
              </div>
              <div
                className="tab"
                onClick={() => history.push(deliveries_history)}
              >
                <BiTimer /> &nbsp; Quote history
              </div>
              <div
                className="tab"
                onClick={() => history.push(accounttransaction)}
              >
                <RiAccountPinCircleLine /> &nbsp; Account
              </div>
            </div>
          </div>
          <div className="dashboardMobile">
            <TopBar />
          </div>

          <div className="wallet_home">
            
            <div className="wallet_ballance">
              <div className="wallet_balance_and_amount">
            
                <div>
                  {" "}
                  <p className="waleet_title">Wallet balance</p>
                </div>
                <div>
                  {
                    showIcon ? 
                    <ClipLoader color='white' loading={showIcon}  speedMultiplier={1} size={32}/>
                    :
                  
                   walletBalc === 0 || walletBalc === undefined || walletBalc === NaN ?

                   <h1 className="wallet_amt">
                   &#8358;0.00
                 </h1> :

                    <h1 className="wallet_amt">
                      {/* {
                        console.log(walletBalc)
                      } */}
                      &#8358;
                      {numberWithCommas.format(parseFloat(walletBalc)?.toFixed(2))}
                    </h1>
                  }
                  
                </div>
              </div>

              <div className="wallet_icon">
                <img
                  src={wallet3}
                  alt="walleticon"
                  className="wallet_icn_img"
                />
              </div>
            </div>
          </div>

          <div className="fwsettings">
            <div className="fund-withdraw-settings">
              <div className="addfund">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <img src={emptywalletadd} alt="" />
                </div>
                <div>
                  {" "}
                  <span>Add fund</span>
                </div>
              </div>

              <div
                className="withdraw"
                onClick={() => {
                  setshowwithdraw(true);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <img src={walletminus} alt="" />
                </div>
                <div>
                  {" "}
                  <span>Withdraw</span>
                </div>
              </div>

              <div className="settings">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <img src={setting2} alt="" />
                </div>
                <div>
                  {" "}
                  <span>Settings</span>
                </div>
              </div>
            </div>
          </div>

          <div className="wallet-history">
            <div>
              {" "}
              <h2>Wallet History</h2>
            </div>
          </div>

          <div className="allcreditdebit">
            <div className="alldiv">
              <div style={{ backgroundColor: allstatet === "all" ? "#E0FCE7" : "white" }} className="all" onClick={() => setallstatet("all")}>
                <span>All</span>
              </div>

              <div
                style={{ backgroundColor: allstatet === "credit" ? "#E0FCE7" : "white" }}
                className="credits"
                onClick={() => {
                  setcreditstate(true);
                  setallstatet("credit");
                }}
              >
                <span>Credits</span>
              </div>

              <div style={{ backgroundColor: allstatet === "debit" ? "#E0FCE7" : "white" }} onClick={() => setallstatet("debit")} className="debits">
                <span>Debits</span>
              </div>
            </div>
          </div>

          {allstatet === "all" ? (
            <div>
              <div
                className={`${allstatet === "all"
                    ? "transaction-history"
                    : "transaction-history"
                  }`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {walletHistory?.map((x, i) => {
                  return (
                    <div key={i} className="transdetails">
                      <div className="transtitleanddate">
                        {x.transaction_type === "debit" ? (
                          <h1 className="transtitle">Withdrawal made</h1>
                        ) : (
                          <h1 className="transtitle">
                            Payment made.
                          </h1>
                        )}
                        <span className="transdate">{moment(x.timestamp.slice(0, 10)).format('DD-MM-YY')} &nbsp;  {x.timestamp.slice(12, 19)}</span>
                      </div>

                      <div className="transamount transamtdebit  ">
                        <h1
                          className={`${x.transaction_type === "credit"
                              ? "transamt credit   "
                              : "transamtno  debit"
                            }`}
                        >
                          &#8358;{`${x.transaction_type === "debit"
                              ? x.debit_amount
                              : x.credit_amount
                            }`}
                        </h1>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) :
            allstatet === "credit" ?
              (
                <div>

                  {
                    walletHistory?.map((x, i) => {
                      return (
                        <div className="transaction-history-  credittrans  " >

                          <div className="transdetails  credittransinner  ">
                            <div>{
                              x.transaction_type === "credit" ? <h2 className="user_id" > Payment made.</h2> : null
                            }
                              {
                                x.transaction_type === "credit" ? <span className="transdate">{moment(x.timestamp.slice(0, 10)).format('DD-MM-YY')} &nbsp;  {x.timestamp.slice(12, 19)}</span> : null
                              }
                            </div>

                            <div>
                              {
                                x.transaction_type === "credit" ? <span className="transamt" >&#8358;{x.credit_amount}</span> : null
                              }
                            </div>

                          </div>

                        </div>
                      )
                    })



                  }
                </div>

              ) :
              <div>

                {
                  walletHistory?.map((x, i) => {
                    return (
                      <div className="transaction-history- debittrans" >

                        <div className="transdetails debittransinner  ">
                          <div>{
                            x.transaction_type === "debit" && x.debit_amount ? <h2 className="user_id" > Withdrawal made</h2> : null
                          }

                            {
                              x.transaction_type === "debit" && x.debit_amount ? <span className="transdate">{moment(x.timestamp.slice(0, 10)).format('DD-MM-YY')} &nbsp;  {x.timestamp.slice(12, 19)}</span> : null
                            }

                          </div>
                          <div>
                            {
                              x.transaction_type === "debit" && x.debit_amount ? <span className="transamtno" >&#8358;{x.debit_amount}</span> : null
                            }
                          </div>

                        </div>

                      </div>
                    )
                  })



                }
              </div>

          }


        </div>
      )}

      <DialogPop
        openPopup={showwithdraw}
        setopenPopup={setshowwithdraw}
        head="Withdrawal"
      >
        <div>
          <div className="withdrwalbody">
            <div>
              <h1 className="withdrawtag">
                Withdrawable: &nbsp; &nbsp; &nbsp;{" "}
              </h1>
            </div>

            <div>
              <span className="withdawspan">
                &#8358;{ walletBalc === 0 || walletBalc === undefined || walletBalc === isNaN ? "0.00" :   parseFloat(walletBalc).toFixed(2)}
              </span>
            </div>
          </div>

          <div className="withdrawform">
            <form onSubmit={withdrawamount} action="">
              <div className="form-div">
                <input
                   type="number"
                  onKeyUp={buttonstyle}
                  name="withdrawinput"
                  value={withdrawamt}
                  onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                  onChange={(e) => {
                    setwithdrawamt(e.target.value);
                  }}
                  required
                  placeholder="Enter amount to withdraw"
                  className="withdrawinput"
                  
                />
                <div style={{color:'red', marginTop:'5px', fontSize:'13px', textDecoration: 'italic'}}>
                  {
                    parseInt(withdrawamt) < 100 ? 
                    <>You can only withdraw from &#8358;100 and above</>
                    :
                    parseFloat(withdrawamt) > parseFloat(walletBalc) ? 
                    <>Amount cannot be more than your withdrawable</>
                    :
                    // <></>
                    checkfocus ?
                    <>You can only enter a digit</> : null
                  }
                </div>
                <div className="cancelandproceed">
                  <div onClick={handleCancel} className="cancel">
                    Cancel
                  </div>

                  { 
                    withdrawamt === "" ? 
                    <button className="proceed" disabled>
                      Proceed
                    </button>
                    :
                    parseInt(withdrawamt) < 100 ? 
                    <button className="proceed" disabled>
                      Proceed
                    </button>
                   : 
                   parseFloat(withdrawamt) > parseFloat(walletBalc) || walletBalc === undefined || walletBalc === isNaN  ?

                   <button className="proceed" disabled>
                      Proceed 
                    </button>
                    :
                    <button className="proceedplus" type="submit">
                      <span>Proceed</span>
                    </button>
                  }
                </div>
              </div>
            </form>
          </div>
        </div>
      </DialogPop>

      <DialogPop
        openPopup={proceedwithdraw}
        setopenPopup={setproceedwithdraw}
        centerhead="Withdrawal"
      >
        <div>
          <h1 className="withdrawinner">
            Kindly confirm you are about to withdraw the sum of &#8358;{withdrawamt} from your wallet
          </h1>

          <div className="yesandnobtn">
            <button onClick={() => setproceedwithdraw(false)} className="nobtn">
              No
            </button>

            <button onClick={Withdrawalfunction} className="yesbtn">
              {/* {isloading ? "loading..." : "Yes"} */}
             { isloading ?  <ClipLoader color='white' loading={isloading}  speedMultiplier={1} size={32}/> : "Yes"  }
            </button>
          </div>
        </div>
      </DialogPop>
      <DashBottomBtn activeTab='wallet'/>
    </div>
  );
};

export default Wallet;
