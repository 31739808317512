import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import  React from 'react';
import ActionButton from "./ActionButton";
import CloseIcon from '@material-ui/icons/Close';

function DialogBox (props){
    const {head, children, openPopup, setopenPopup} = props;
    return(
        <Dialog open={openPopup} maxWidth="sm">
            <DialogTitle>
                <div style={{display:"flex"}}>
                <Typography variant="h6" component="div" style={{flexGrow:1}}>
                  {head}
                </Typography>
                <ActionButton
                        // color="secondary"
                        onClick={()=>{setopenPopup(false)}}>
                        <CloseIcon/>
                </ActionButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent> 
        </Dialog>
    )
}
export default DialogBox;