import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import RegBtn from "../../components/Reg_components/RegBtn";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import RegInput from "../../components/Reg_components/RegInput";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneNumber from "../../components/PhoneNumber";
import axios from "axios";
import TopNavigation from "../../components/Reg_components/TopNavigation";
import TitleText from "../../components/Reg_components/TitleText";
import imageCompression from "browser-image-compression";
import { parse, isDate } from "date-fns";
import PrivacyDialog from "./../../components/PrivacyDialog";
import { ImArrowRight2 } from "react-icons/im";
import Swal from "sweetalert2";
// import { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from 'react-phone-number-input'

import erros from "../../assets/erros.png";
import HomeHelp from "../../components/HomeHelp";
import SecureLS from "secure-ls";
import { useLocation } from "react-router-dom";
import Multiselect from 'multiselect-react-dropdown';
import Dillivry from "../../assets/Dillivry.png";
import { useHistory, Link } from "react-router-dom";
import Country_dropdown from "../../components/Country_dropdown";
import './Registration.css'
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { IoMdCloseCircle } from "react-icons/io";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputField from "../../components/InputField";
const endpoint = process.env.REACT_APP_API;

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  form: {
    width: "100%",
  },
  submit: {
    margin: theme.spacing(0, 0, 4),
    backgroundColor: "var(--main)",
    color: "white",
    fontWeight: "600",
  },
  formLabel: {
    fontWeight: "600",
    fontSize: "14px",
  },
  itemGrid: {
    marginBottom: "5px",
  },
  input: {
    borderColor: "var(--main)",
    marginTop: "7px",
  },
}));

export default function RegFormCompany({
  handleBtnClick,
  countryCode,
  handleGoBack,
  compDetails
}) {
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const location = useLocation()
  const history = useHistory()

  const [promocodevalues, setpromocodevalues] = useState([])
  const [promocodesmap, setpromocodemap] = useState([])

  const [showpassword, setshowpassword] = useState(false);
  const [showcpassword, setshowcpassword] = useState(false);




  useEffect(() => {
    if (compDetails !== undefined) {
      setValue("business_name", compDetails?.compName, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("cac_reg_number", compDetails?.comRegNum, {
        shouldValidate: true,
        shouldDirty: true
      });
      setValue("cac_reg_address", compDetails?.compAddr, {
        shouldValidate: true,
        shouldDirty: true
      });
    }

    if (location?.state?.email !== undefined) {
      setValue("email", location?.state?.email, {
        shouldValidate: true,
        shouldDirty: true
      });
      setPhone_num(location?.state?.phone_no)
    }
  }, []);

  const [logo, setLogo] = useState("");
  const [uploadLogo, setUploadLogo] = useState("");

  const [mode_of_transport, setMode_of_transport] = useState([]);
  const [modeValid, setmodeValid] = useState(false)

  const [phone_num, setPhone_num] = useState("");
  const [formNum, setformNum] = useState(1);
  const [isloading, setisloading] = useState(false);
  const [policyShow, setPolicyShow] = useState(false);
  const [pageToShow, setpageToShow] = useState("");
  const [preferredCountry, setPreferredCountry] = useState("")
  const [isEightPlus, setisEightPlus] = useState("false")
  const [isAgreed, setisAgreed] = useState(false)
  const [isreferralempty, setisreferralempty] = useState("")
  const [isreferralerror, setisreferralerror] = useState("")
  const [isValided, setIsValided] = useState(false);

  function parseDateString(value, originalValue) {
    const parsedDate = isDate(originalValue)
      ? originalValue
      : parse(originalValue, "yyyy-MM-dd", new Date());

    return parsedDate;
  }
 const checkRefralCode =(value)=>{
  setisreferralempty(value)
  if(value.length===6){
  axios
  .get(`${endpoint}m-dillivry-promo-code?referral_code=${value}`)
  .then((response) => {
    console.log("response",response)
    setisreferralerror(response.data.message)
  })
  .catch((error)=>{
    console.log("error",error)
  })
}
else{
  if(value.length>0 && value.length<6){
  setisreferralerror("Referral code can not be less than six digits");
}
else{
  setisreferralerror("")
}
}
}
  const onRemove = (selectedList, selectedItem) => {
    const selectedListToLower = selectedList.map(name => name.toUpperCase())

    if (selectedList.length === 0) {
      setmodeValid(false)
    }
    else { 
      setmodeValid(true) }
      setMode_of_transport(selectedListToLower)
  }

  const onSelect = (selectedList, selectedItem) => {
    const selectedListToLower = selectedList.map(name => name.toLowerCase())

    if (selectedList.length === 0) {
      setmodeValid(false)
    }
    else { setmodeValid(true) }
    setMode_of_transport(selectedListToLower)
  }
  
  const CustomStyle = {
    chips: {
      background: 'var(--main)'
    },
    option: {
      background: 'none', color: 'black', margin: '10px'
    },
  }
  const options = ['Bike', 'Car', 'Truck', 'Van']

  const GreenRadio = withStyles({
    root: {
      color: green[400],
      '&$checked': {
        color: green[600],
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  const schema = yup.object().shape({
    business_name: yup
      .string()
      .required("Company name is required")
      .min(3, "Can't be lesser than 3 characters")
      .max(50, "Can't exceed 50 characters"),
    cac_reg_number: yup
      .string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/,
        "Must contain alphabet and numbers"
      )
      .required("Company registration number is required")
      .min(3, "Can't be lesser than 3 characters")
      .max(11, "Can't exceed 11 characters"),
    cac_reg_address: yup
      .string()
      .required("Company address is required")
      .min(10, "Can't be lesser than 10 characters")
      .max(70, "Can't exceed 70 characters"),
    first_name: yup
      .string()
      .required("Firstname is required")
      .min(3, "Can't be lesser than 3 characters")
      .max(20, "Can't exceed 20 characters")
      .matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
    middle_name: yup
      .string()
      .matches(/^$|^[aA-zZ\s]+$/, "Can't contain number or special character")
      .notRequired(),
    last_name: yup
      .string()
      .required("Lastname is required")
      .min(3, "Can't be lesser than 3 characters")
      .max(20, "Can't exceed 20 characters")
      .matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
    // dob: yup
    //   .date("Date of birth")
    //   .transform(parseDateString)
    //   .max(eighteenYearsAgo, "You have to be 18years and above")
    //   .required("Date of birth is required"),

    email: yup
      .string()
      .matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, "Valid email is required")
      .required("Email is required"),
    password: yup.string().min(6, "Can't be lesser than 6 characters"),
    cpassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Password mismatch!")
      .required("Required"),
    home_address: yup
      .string()
      .required("Contact address is required")
      .min(10, "Can't be lesser than 10 characters")
      .max(70, "Can't exceed 70 characters"),
  });

  const {
    control,
    trigger,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  async function imageHandler(e) {
    const file = e.target.files[0];
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 512,
    };
    const output = await imageCompression(file, options);

    const dataa = await imageCompression.getDataUrlFromFile(output);

    setUploadLogo(dataa);

    if (dataa.substring(11, 14) === "png") {
      setLogo(dataa.replace("data:image/png;base64,", ""));
    } else if (dataa.substring(11, 14) === "gif") {
      setLogo(dataa.replace("data:image/gif;base64,", ""));
    } else if (dataa.substring(11, 14) === "jpg") {
      setLogo(dataa.replace("data:image/jpg;base64,", ""));
    } else if (dataa.substring(11, 15) === "jpeg") {
      setLogo(dataa.replace("data:image/jpeg;base64,", ""));
    } else {
      setLogo(dataa.replace("data:image/webp;base64,", ""));
    }
  }
  const formatBusiness = (string) => {
    let trimStr = string.trim();
    let trimStr2 = trimStr.split(" ");
    trimStr2 = trimStr2.filter((e) => e.length !== 0);
    trimStr2 = trimStr2.join(" ");
    return trimStr2.toLowerCase();
  };


  const [isDisabled, setIsDisabled] = useState(false);

  const onKeyUpToCheckReferal = (e) => {
    setisreferralempty(e)
    isreferralempty.length <= 6 ? setIsDisabled(true) : setIsDisabled(false)
    if(isreferralempty.length === 6 && !promocodesmap.includes(isreferralempty) ? "Invalid code" : isreferralempty.length < 6 && isreferralempty.length > 1 && !promocodesmap.includes(isreferralempty) ? "Referral code can not be less than six digits" : null){
      setIsValided(false)
    }
    else{
      setIsValided(true)
    }
  }


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}m-dillivry-promo-code`).then((res) => {
      if (res?.status === 200) {
        setpromocodevalues(...promocodevalues, res?.data?.data)
      }
      setpromocodemap(res?.data?.data?.map(x => x?.promo_code))
    }).catch((err) => {
      console.log(err)
    })
  }, []);

  const present = ['ng', 'gb']

  const submitForm = async (data) => {

    if(!present.includes(preferredCountry.toLowerCase())){
      Swal.fire({
        icon: "info",
        text: "Not currently live in the selected country",
        timer: 3500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    }
    else if (phone_num === undefined) {
      Swal.fire({
        icon: "info",
        text: "Phone number is required",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    } 
    else if (!isValidPhoneNumber(`${phone_num}`)) {
      Swal.fire({
        icon: "info",
        text: "Invalid phone number",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    }
    else if (!modeValid) {
      Swal.fire({
        icon: "info",
        text: "Mean of delivery required",
        timer: 2500,
        showConfirmButton: false,
        iconColor: "var(--main)",
      });
    }
    else {
      delete data.cpassword;
      delete data.referral;
      // console.log("data",data)

      setisloading(true);
      data.action_type = "fulfiller_reg";
      data.type = "business";
      data.registered_countries = preferredCountry;
      data.phone_number = phone_num;
      data.cop_logo = logo;
      data.dob = 'yes';
      data.password = btoa(data.password);
      // data.cpassword = btoa(data.cpassword);
      data.email = data.email.toLowerCase();
      data.business_name = formatBusiness(data?.business_name);
      data.mode_of_delivery = mode_of_transport;
      data.banner = "";
      data.referred_by_code = isValided ? isreferralempty : ""
      data.privacy_policy=true

      axios
        .post(`${process.env.REACT_APP_API}fulfillers-reg`, data)
        .then((response) => {
          console.log(response);
          if (response?.status === 200 && response?.data?.message === "success") {
            setisloading(false);
            handleBtnClick();
            ls.set("_tyf_", data.business_name);
            ls.set("_tyfRC",data.registered_countries);
          } 
          // else if(response?.status === "ok" && response?.message === "success") {
          //   setisloading(false);
          //   handleBtnClick();
          //   ls.set("_tyf_", data.business_name);
          // }
          else if (
            response?.status === 201 &&
            response?.data?.status ===
            "account already exists or in process of being created"
          ) {
            Swal.fire({
              iconHtml: `<img src=${erros} style="width:100px;">`, // imageUrl: "dm-dillivry-web/web/src/assets/erros.png",
              title: "Oops!",
              imageAlt: "oops",
              text: `An account with the name "${data?.business_name}" already exist`,
              confirmButtonColor: "var(--main)",
            });
          }
          setisloading(false);
        })
        .catch((error) => {
          console.log(error?.response, "error-coming");
          if (
            error?.response?.status !== undefined &&
            error?.response?.status === 400
          )
            Swal.fire({
              iconHtml: `<img src=${erros} style="width:100px;">`, // imageUrl: "dm-dillivry-web/web/src/assets/erros.png",
              title: "Oops!",
              imageAlt: "oops",
              text: "Email address already exist",
              confirmButtonColor: "var(--main)",
            });
          else if (error?.response?.status === 500) {
            Swal.fire({
              icon: "info",
              text: "We are not yet live in this country, kindly subscribe to our news letter to let you know when we will be live in this country",
              //  timer: 2500,
              confirmButtonColor: "var(--main)",
            })
          }
          else {
            Swal.fire({
              icon: "error",
              text: "An error occured, be sure you are connected to internet and try again",
              timer: 3000,
              showConfirmButton: false,
            });
          }
          setisloading(false);
        });
    }
  };

  const classes = useStyles();

  const nextPage = () => {
    setformNum((prev) => prev + 1);
  };

  const prevPage = () => {
    setformNum((prev) => prev - 1);
  };

  const [showHelp, setshowHelp] = useState(false);
  return (
    <>
      {formNum === 1 ? (
        <>
          <div className="logoshower">
            <img src={Dillivry} alt="dillivry logo" style={{ width: '90px' }} onClick={() => history.push('/')} />
          </div>
          <TitleText head="Let's get to know your business" margin_base="0" />
        </>
      ) : (
        <>
          <TopNavigation
            onBackClick={prevPage}
            help="Help"
            openHelp={() => setshowHelp(!showHelp)}
          />
          <TitleText head="Let’s create your admin account" margin_base="0" />
        </>
      )}
      <form
        className={classes.form}
        onSubmit={handleSubmit(submitForm)}
        noValidate
      >
        {formNum >= 1 && (
          <div className={formNum === 1 ? "shown" : "hidden"}>
            <Grid container spacing={1}>
              <Grid item style={{ marginBottom: "10px" }} xs={12}>
                <Controller
                  name="business_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RegInput
                      {...field}
                      htmlFor="business_name"
                      name="business_name"
                      txt="Company name"
                      id="business_name"
                      placeholder="Company name"
                      autoFocus
                      type="text"
                      size="small"
                    />
                  )}
                />
                <div className="err">
                  {errors.business_name && errors.business_name?.message}
                </div>
              </Grid>

              <Grid item style={{ marginBottom: "10px" }} xs={12}>
                <Controller
                  name="cac_reg_number"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RegInput
                      {...field}
                      htmlFor="cac_reg_number"
                      name="cac_reg_number"
                      txt="Company registration number"
                      id="cac_reg_number"
                      placeholder="Company registration number"
                      type="text"
                      size="small"
                    />
                  )}
                />
                <div className="err">
                  {errors.cac_reg_number && errors.cac_reg_number?.message}
                </div>
              </Grid>
              <Grid item style={{ marginBottom: "10px" }} xs={12}>
                <Controller
                  name="cac_reg_address"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RegInput
                      {...field}
                      htmlFor="cac_reg_address"
                      name="cac_reg_address"
                      txt="Company address"
                      id="cac_reg_address"
                      placeholder="Company address"
                      type="text"
                      size="small"
                    />
                  )}
                />
                <div className="err">
                  {errors.cac_reg_address && errors.cac_reg_address?.message}
                </div>
              </Grid>
              <Grid item style={{ marginBottom: "10px" }} xs={12}>
                <div
                  style={{
                    fontWeight: "600",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}
                >
                  {" "}
                  Company logo{" "}
                  <span
                    style={{
                      fontSize: "12px",
                      fontStyle: "italic",
                      color: "#B7B7B7",
                    }}
                  >
                    (optional)
                  </span>
                </div>
                <input
                  type="file"
                  id="logo"
                  accept="image/x-png,image/jpg,image/jpeg"
                  style={{ display: "none" }}
                  onChange={imageHandler}
                />
                <label
                  htmlFor="logo"
                  style={{
                    width: "100%",
                    height: "70px",
                    display: "flex",
                    border: "dashed 2px #C7C7C7",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "5px",
                  }}
                >
                  {uploadLogo === "" ? (
                    <span>Click here to add your company logo </span>
                  ) : (
                    <div style={{ position: 'relative' }}>
                      <img src={uploadLogo} alt="business logo" height="60px" />
                      <IoMdCloseCircle
                        onClick={() => setUploadLogo("")}
                        style={{ borderRadius: '50%', position: 'absolute', top: "-10", right: "-10", backgroundColor: "white" }} size={24} color="maroon"
                      />
                    </div>
                  )}
                </label>
              </Grid>
              <Grid item style={{ marginBottom: "10px" }} xs={12}>
                <RegBtn
                  onClick={async () => {
                    const result = await trigger([
                      "business_name",
                      "cac_reg_number",
                      "cac_reg_address",
                    ]);
                    if (result === true) {
                      nextPage();
                    }
                  }}
                  txt="Continue "
                  icon={<ImArrowRight2 size="30px" />}
                  type="button"
                />
              </Grid>
            </Grid>
          </div>
        )}

        {formNum >= 2 && (
          <div className={formNum === 2 ? "shown" : "hidden"}>
            <Grid container spacing={2}>
              <Grid className={classes.itemGrid} item xs={12} sm={12}>
                <Controller
                  name="first_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RegInput
                      {...field}
                      htmlFor="first_name"
                      name="first_name"
                      txt="First name"
                      id="first_name"
                      placeholder="First name"
                      type="text"
                      size="small"
                      error={errors.first_name}
                    />
                  )}
                />
                <div className="err">
                  {errors.first_name && errors.first_name?.message}
                </div>
              </Grid>
              <Grid className={classes.itemGrid} item xs={6} sm={6}>
                <Controller
                  name="last_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RegInput
                      {...field}
                      htmlFor="last_name"
                      name="last_name"
                      id="last_name"
                      txt="Last name"
                      placeholder="Last name"
                      type="text"
                      size="small"
                      error={errors.last_name}
                    />
                  )}
                />
                <div className="err">
                  {errors.last_name && errors.last_name?.message}
                </div>
              </Grid>
              <Grid className={classes.itemGrid} item xs={6} sm={6}>
                <Controller
                  name="middle_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RegInput
                      {...field}
                      htmlFor="middle_name"
                      name="middle_name"
                      id="middle_name"
                      txt="Middle name"
                      placeholder="Middle name"
                      type="text"
                      size="small"
                    />
                  )}
                />
                <div className="err">
                  {errors.middle_name && errors.middle_name?.message}
                </div>
              </Grid>
              {/* <Grid className={classes.itemGrid} item xs={6} sm={6}>
                <Controller
                  name="dob"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RegInput
                      {...field}
                      htmlFor="dob"
                      name="dob"
                      id="dob"
                      txt="Date of Birth"
                      placeholder="Date of Birth"
                      type="date"
                      size="small"
                      error={errors.dob}
                    />
                  )}
                />
                <div className="err">{errors.dob && errors.dob?.message}</div>
              </Grid> */}

              <Grid className={classes.itemGrid} item xs={12}>
                <Controller
                  name="home_address"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RegInput
                      {...field}
                      htmlFor="home_address"
                      name="home_address"
                      id="home_address"
                      txt="Contact address"
                      placeholder="Contact address"
                      type="text"
                      size="small"
                      error={errors.home_address}
                    />
                  )}
                />
                <div className="err">
                  {errors.home_address && errors.home_address?.message}
                </div>
              </Grid>
              <Grid className={classes.itemGrid} item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <RegInput
                      {...field}
                      htmlFor="email"
                      name="email"
                      id="email"
                      txt="Email address"
                      placeholder="Email address"
                      type="email"
                      size="small"
                      error={errors.email}
                    />
                  )}
                />
                <div className="err">
                  {errors.email && errors.email?.message}
                </div>
              </Grid>
              <Grid className={classes.itemGrid} item xs={12} style={{ zIndex: 2 }}>
                <div style={{ fontWeight: "600", fontSize: "14px", marginBottom: '5px' }}>
                Select the country you want to operate
                </div>
                <Country_dropdown setDataValue={setPreferredCountry} />
              </Grid>
              <Grid className={classes.itemGrid} item xs={12}>
                <div style={{ fontWeight: "600", fontSize: "14px", marginBottom: '5px' }}>
                  Phone number
                </div>
                <PhoneNumber value={phone_num} setValue={setPhone_num} />

                {/* <CustomPhoneNumber
                      setPhone_number={setPhone_no}
                      borderRadius="5px"
                  /> */}
              </Grid>

              <Grid className={classes.itemGrid} item xs={12} sm={12}>
                <div style={{ fontWeight: "600", fontSize: "14px", marginBottom: '5px' }}>
                  Means of delivery
                </div>
                <Multiselect
                  options={options} // Options to display in the dropdown
                  onSelect={onSelect}
                  onRemove={onRemove} // Function will trigger on remove event
                  showCheckbox={true}
                  isObject={false}
                  placeholder={mode_of_transport.length === 0 ? "E.g Car, Bike, Van" : null}
                  style={CustomStyle}
                />

                <div className="err">
                  {modeValid ? "" : "Required"}
                </div>

                {/* <Controller
                  name="first_name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                   
                  )}
                />
                <div className="err">
                  {errors.first_name && errors.first_name?.message}
                </div> */}
              </Grid>

              <Grid className={classes.itemGrid} item xs={12}>
                <Controller
                  name="referral"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <div style={{
                      display: "flex", flexDirection: "column", gap: "3px"
                    }} >
                      <label style={{fontSize: "14px", fontWeight: "600"}}  >Referral</label>
                      <input  {...field}
                        type="number" onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
                        className="input-referall"
                        onKeyUp={onKeyUpToCheckReferal(isreferralempty)} txt="Referral code" size="small" placeholder="Referral code (Optional)" id="referral" name="referral" htmlFor="referral" value={isreferralempty}
                        onChange={(e) => checkRefralCode(e?.target?.value)}
                      />
                    </div>
                  )}
                />
                <div className="err">
                  {isreferralerror}
                </div>
              </Grid>

              <Grid className={classes.itemGrid} item xs={12}>
                <div style={{
                  position: "relative",



                }}    >


                  <div>

                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <RegInput
                          {...field}
                          htmlFor="password"
                          name="password"
                          id="password"
                          txt="Password"
                          placeholder="Password"
                          // type="password"
                          type={showpassword ? "text" : "password"}
                          // type={showpassword ? "text" : "password"}
                          size="small"
                          error={errors.password}
                          endIcon

                        />

                      )}
                    />
                  </div>

                  <div style={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "flex-end",
                    top: 35,
                    right: 10,

                  }}  >


                    {
                      showpassword ? (
                        <Tooltip title="Hide password" interactive>
                          <VisibilityOffIcon
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                            onClick={() => setshowpassword(!showpassword)}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Show password" interactive>
                          <VisibilityIcon
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                            onClick={() => setshowpassword(!showpassword)}
                          />
                        </Tooltip>
                      )
                    }
                  </div>



                  <div>

                  </div>
                </div>
                <div className="err">
                  {errors.password && errors.password?.message}
                </div>
              </Grid>










              <Grid className={classes.itemGrid} item xs={12}>
                <div style={{
                  position: "relative"

                }}    >
                  <div>
                    <Controller
                      name="cpassword"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <RegInput
                          {...field}
                          htmlFor="cpassword"
                          name="cpassword"
                          id="cpassword"
                          txt="Confirm password"
                          placeholder="Confirm password"
                          type={showcpassword ? "text" : "password"}
                          size="small"
                          error={errors.cpassword}
                        />
                      )}
                    />
                  </div>

                  <div style={{
                    position: "absolute",
                    display: "flex",
                    justifyContent: "flex-end",
                    top: 35,
                    right: 10
                  }}  >


                    {
                      showcpassword ? (
                        <Tooltip title="Hide password" interactive>
                          <VisibilityOffIcon
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                            onClick={() => setshowcpassword(!showcpassword)}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Show password" interactive>
                          <VisibilityIcon
                            style={{
                              color: "grey",
                              fontSize: "25px",
                              cursor: "pointer",
                            }}
                            onClick={() => setshowcpassword(!showcpassword)}
                          />
                        </Tooltip>
                      )
                    }
                  </div>


                </div>
                <div className="err">
                  {errors.cpassword && errors.cpassword?.message}
                </div>
              </Grid>

              <Grid item xs={12} className="above18" style={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'space-between' }}>
                <span>Are you above 18 years old?</span>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    row
                    value={isEightPlus}
                    onChange={(e) => setisEightPlus(e.target.value)}

                  >
                    <FormControlLabel
                      value="true"
                      control={<GreenRadio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<GreenRadio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <span className="AgreeCheckboxContainer">
                  <input checked={isAgreed} onClick={() => setisAgreed(!isAgreed)} type="checkbox" className="AgreeCheckbox"></input>
                  {/* <span style={{ marginLeft: "20px" }}> */}
                  <div style={{ fontSize: "10px", margin: "10px 25px", display: 'inline-block' }}>
                    By proceeding I agree to Dillivry
                    <span
                      style={{ color: "#004DC1", cursor: "pointer" }}
                      onClick={() => {
                        setPolicyShow(true);
                        setpageToShow("terms");
                      }}
                    >
                      {" "}
                      Terms of Use{" "}
                    </span>
                    and acknowledge that I have read the{" "}
                    <span
                      onClick={() => {
                        setPolicyShow(true);
                        setpageToShow("privacy");
                      }}
                      style={{ color: "#004DC1", cursor: "pointer" }}
                    >
                      {" "}
                      Privacy Policy.{" "}
                    </span>
                  </div>
                  {/* </span> */}
                </span>
              </Grid>
            </Grid>

            <RegBtn
              loading={isloading}
              txt="Continue"
              icon={<ImArrowRight2 size="30px" />}
              type="submit"
              disabled={!(isEightPlus === "true" && isreferralerror==="" && isAgreed === true)}
            />
          </div>
        )}
        <div style={{ margin: "0 0 30px 0", textAlign: 'center' }}>
          Already have an account? <Link to="/login" style={{ color: "#50B14A" }}>Login</Link>
        </div>
      </form>

      <PrivacyDialog
        open={policyShow}
        onClick={() => setPolicyShow(false)}
        pageToShow={pageToShow}
        title={
          pageToShow === "terms" ? "Terms and condition" : "Privacy policy"
        }
      ></PrivacyDialog>
      {showHelp ? (
        <HomeHelp showDialog={showHelp} onClose={() => setshowHelp(false)} />
      ) : null}
    </>
  );
}
