import { useEffect,useContext, useState } from 'react'
// import './Message.css';
import SmallBtn from '../../../../components/SmallBtn';
import {TiArrowBack} from 'react-icons/ti' 
import axios from 'axios';
import Swal from 'sweetalert2'
import PuffLoader from 'react-spinners/PuffLoader';

import warngreen from "../../../../assets/warngreen.svg"
import warnrred from "../../../../assets/warnrred.svg"
import moment from 'moment'
import { BsCheckAll } from 'react-icons/bs';
import {useHistory, useParams} from 'react-router-dom';
import { AuthContext } from '../../../../context/AuthContext';
import { DashboardContext } from '../../../../context/Dashboard/DashboardProvider/dashboard.provider';
import SecureLS from 'secure-ls';

import userAvatar from '../../../../assets/userAvatar.jpeg'
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import jwt_decode from "jwt-decode";
import send from "../../../../assets/Stroke.svg";
import horiz from "../../../../assets/horiz.svg";
import { ConnContext } from '../../../../context/ConnContext';
import GlobalizationLanguage from '../../../../components/GlobalizationLanguage';
function MessageFulfillerDM() {

    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
    var timeout = GlobalizationLanguage("/timeout")
    const Dashboard = GlobalizationLanguage("/Dashboard")

    const {type, id} = useParams()
    
    const [dmReply, setDmReply] = useState('');
    const [DMconversation, setDMconversation] = useState([])
    
    const [ShowLogin, setShowLogin] = useState(false);
    
    const [sendingMsg, setsendingMsg] = useState(false)
    const [error, seterror] = useState(false);
    const [DMsender, setDMsender] = useState('') 
    const history = useHistory()
    const [msg, setmsg] = useState([])

    const [myDP, setmyDP] = useState('')

    const [loadingData, setloadingData] = useState(false)

    const auth = useContext(AuthContext);

    const {dashboards, updateDashboard, update_dm_conversation} = useContext(DashboardContext)


    useEffect(() => {
        window.scrollTo(0,0);
        if (ls.get('_iRl') === "" || ls.get('_iRl') === ('dillivry-sole-trader-ops') || ls.get('_nodi') === "") {
            setShowLogin(true)
        }
        else {
            if (dashboards !== undefined){
                setmyDP(dashboards.data.account_profile.account_profile[0].cop_logo);
                let data = []
                if(type === 'current'){
                const deliveryData = dashboards.data.current_quotes.filter((item)=> item.delivery_id === id) 
                data = [{
                  delivery_location: deliveryData[0].delivery_location,
                    sender_name: deliveryData[0].quote_detail[0].sender_name,
                    conversation: deliveryData[0].quote_detail[0].message,
                    delivery_id: deliveryData[0].delivery_id,
                    fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                    quote_subject: deliveryData[0].item_desc,
                    delivery_address: deliveryData[0].to,
                    pickup_address: deliveryData[0].from,
                    image_urls: deliveryData[0].image_urls,
                }]
                }
                if(type === 'assign'){
                    const deliveryData = dashboards.data.accepted_quotes.assigned.filter((item)=> item.delivery_id === id)    
                data = [{
                  delivery_location: deliveryData[0].delivery_location,
                    sender_name: deliveryData[0].quote_detail[0].sender_name,
                    conversation: deliveryData[0].quote_detail[0].message,
                    delivery_id: deliveryData[0].delivery_id,
                    fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                    quote_subject: deliveryData[0].item_desc,
                    delivery_address: deliveryData[0].to,
                    pickup_address: deliveryData[0].from,
                    image_urls: deliveryData[0].image_urls,
                }]
                }
                if(type === 'unassign'){
                    const deliveryData = dashboards.data.accepted_quotes.unassigned.filter((item)=> item.delivery_id === id)
                    data = [{
                      delivery_location: deliveryData[0].delivery_location,
                    sender_name: deliveryData[0].quote_detail[0].sender_name,
                    conversation: deliveryData[0].quote_detail[0].message,
                    delivery_id: deliveryData[0].delivery_id,
                    fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                    quote_subject: deliveryData[0].item_desc,
                    delivery_address: deliveryData[0].to,
                    pickup_address: deliveryData[0].from,
                    image_urls: deliveryData[0].image_urls,
                }]    
                }
                if(type === 'picked_up'){
                    const deliveryData = dashboards.data.picked_up.filter((item)=> item.delivery_id === id)
                    data = [{
                      delivery_location: deliveryData[0].delivery_location,
                    sender_name: deliveryData[0].quote_detail[0].sender_name,
                    conversation: deliveryData[0].quote_detail[0].message,
                    delivery_id: deliveryData[0].delivery_id,
                    fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                    quote_subject: deliveryData[0].item_desc,
                    delivery_address: deliveryData[0].to,
                    pickup_address: deliveryData[0].from,
                    image_urls: deliveryData[0].image_urls,
                }]
                
                }
                if(type === 'out_for_delivery'){
                    const deliveryData = dashboards.data.out_for_delivery.filter((item)=> item.delivery_id === id) 
                    data = [{
                      delivery_location: deliveryData[0].delivery_location,
                    sender_name: deliveryData[0].quote_detail[0].sender_name,
                    conversation: deliveryData[0].quote_detail[0].message,
                    delivery_id: deliveryData[0].delivery_id,
                    fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                    quote_subject: deliveryData[0].item_desc,
                    delivery_address: deliveryData[0].to,
                    pickup_address: deliveryData[0].from,
                    image_urls: deliveryData[0].image_urls,
                    }]  
                }
                if(type === 'missed'){
                    const deliveryData = dashboards.data.missed_delivery.filter((item)=> item.delivery_id === id) 
                    data = [{
                      delivery_location: deliveryData[0].delivery_location,
                    sender_name: deliveryData[0].quote_detail[0].sender_name,
                    conversation: deliveryData[0].quote_detail[0].message,
                    delivery_id: deliveryData[0].delivery_id,
                    fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                    quote_subject: deliveryData[0].item_desc,
                    delivery_address: deliveryData[0].to,
                    pickup_address: deliveryData[0].from,
                    image_urls: deliveryData[0].image_urls,
                }]    
                    
                }
               
                if(type === 'decline'){
                    const deliveryData = dashboards.data.unsuccessful_quotes.filter((item)=> item.delivery_id === id) 
                    data = [{
                      delivery_location: deliveryData[0].delivery_location,
                    sender_name: deliveryData[0].quote_detail[0].sender_name,
                    conversation: deliveryData[0].quote_detail[0].message,
                    delivery_id: deliveryData[0].delivery_id,
                    fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                    quote_subject: deliveryData[0].item_desc,
                    delivery_address: deliveryData[0].to,
                    pickup_address: deliveryData[0].from,
                    image_urls: deliveryData[0].image_urls,
                }]   
                }
                if(type === 'complete'){
                    const deliveryData = dashboards.data.completed_quotes.filter((item)=> item.delivery_id === id)           
                    data = [{
                      delivery_location: deliveryData[0].delivery_location,
                        sender_name: deliveryData[0].quote_detail[0].sender_name,
                        conversation: deliveryData[0].quote_detail[0].message,
                        delivery_id: deliveryData[0].delivery_id,
                        fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                        quote_subject: deliveryData[0].item_desc,
                        delivery_address: deliveryData[0].to,
                        pickup_address: deliveryData[0].from,
                        image_urls: deliveryData[0].image_urls,
                    }]
                }

                setmsg(data)
                setDMconversation(data[0]?.conversation);
                setDMsender(data[0]?.sender_name);
            }
            else {
                const decoded = jwt_decode(ls.get("_nodi"));
                const expired = decoded.exp;
                if (expired < Date.now() / 1000) {
                    Swal.fire({
                        icon: "warning",
                        title: "Session!",
                        text: "Session expired login again!",
                        allowOutsideClick: false,
                        confirmButtonColor: "var(--main)",
                    }).then((result) => {
                        if (result.value) {
                        auth.logout();
                        setShowLogin(true)
                        }
                    });
                }
                else{
                    setloadingData(true);
                    axios.get(`${endpoint}m-dillivry-dashboard-manager`, {
                    headers: {
                        Authorization: ls.get('_nodi')
                    }
                })
                .then((response) => {
                    ls.set(
                        "means_of_delivery",
                        JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                      );
                    updateDashboard(response)
                    setloadingData(false)
                    setmyDP(response.data.account_profile.account_profile[0].cop_logo);

                    let data = []
                    if(type === 'current'){
                    const deliveryData = response.data.current_quotes.filter((item)=> item.delivery_id === id) 
                    
                    data = [{
                      delivery_location: deliveryData[0].delivery_location,
                      sender_name: deliveryData[0].quote_detail[0].sender_name,
                        conversation: deliveryData[0].quote_detail[0].message,
                        delivery_id: deliveryData[0].delivery_id,
                        fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                        quote_subject: deliveryData[0].item_desc,
                        delivery_address: deliveryData[0].to,
                        pickup_address: deliveryData[0].from,
                        image_urls: deliveryData[0].image_urls,
                    }]
                    }
                    if(type === 'assign'){
                        const deliveryData = response.data.accepted_quotes.assigned.filter((item)=> item.delivery_id === id) 
                    

                    data = [{
                      delivery_location: deliveryData[0].delivery_location,
                        sender_name: deliveryData[0].quote_detail[0].sender_name,
                        conversation: deliveryData[0].quote_detail[0].message,
                        delivery_id: deliveryData[0].delivery_id,
                        fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                        quote_subject: deliveryData[0].item_desc,
                        delivery_address: deliveryData[0].to,
                        pickup_address: deliveryData[0].from,
                        image_urls: deliveryData[0].image_urls,
                    }]
                    }
                    if(type === 'unassign'){
                        const deliveryData = response.data.accepted_quotes.unassigned.filter((item)=> item.delivery_id === id)
                      data = [{
                        delivery_location: deliveryData[0].delivery_location,
                        sender_name: deliveryData[0].quote_detail[0].sender_name,
                        conversation: deliveryData[0].quote_detail[0].message,
                        delivery_id: deliveryData[0].delivery_id,
                        fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                        quote_subject: deliveryData[0].item_desc,
                        delivery_address: deliveryData[0].to,
                        pickup_address: deliveryData[0].from,
                        image_urls: deliveryData[0].image_urls,
                    }]    
                    }
                    if(type === 'out_for_delivery'){
                        const deliveryData = response.data.picked_up.filter((item)=> item.delivery_id === id)
                    
                        
                        data = [{
                          delivery_location: deliveryData[0].delivery_location,
                        sender_name: deliveryData[0].quote_detail[0].sender_name,
                        conversation: deliveryData[0].quote_detail[0].message,
                        delivery_id: deliveryData[0].delivery_id,
                        fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                        quote_subject: deliveryData[0].item_desc,
                        delivery_address: deliveryData[0].to,
                        pickup_address: deliveryData[0].from,
                        image_urls: deliveryData[0].image_urls,
                    }]
                    
                    }
                    if(type === 'missed'){
                        
                      const deliveryData = response.data.missed_delivery.filter((item)=> item.delivery_id === id) 
                    
                        
                      data = [{
                        delivery_location: deliveryData[0].delivery_location,
                        sender_name: deliveryData[0].quote_detail[0].sender_name,
                        conversation: deliveryData[0].quote_detail[0].message,
                        delivery_id: deliveryData[0].delivery_id,
                        fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                        quote_subject: deliveryData[0].item_desc,
                        delivery_address: deliveryData[0].to,
                        pickup_address: deliveryData[0].from,
                        image_urls: deliveryData[0].image_urls,
                    }]   
                        
                    }
                    if(type === 'decline'){
                        
                      const deliveryData = response.data.unsuccessful_quotes.filter((item)=> item.delivery_id === id) 
                    
                       
                        data = [{
                          delivery_location: deliveryData[0].delivery_location,
                        sender_name: deliveryData[0].quote_detail[0].sender_name,
                        conversation: deliveryData[0].quote_detail[0].message,
                        delivery_id: deliveryData[0].delivery_id,
                        fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                        quote_subject: deliveryData[0].item_desc,
                        delivery_address: deliveryData[0].to,
                        pickup_address: deliveryData[0].from,
                        image_urls: deliveryData[0].image_urls,
                    }]   
                    }
                    if(type === 'complete'){
                        const deliveryData = response.data.completed_quotes.filter((item)=> item.delivery_id === id)           
                    
                        
                        data = [{
                          delivery_location: deliveryData[0].delivery_location,
                            sender_name: deliveryData[0].quote_detail[0].sender_name,
                            conversation: deliveryData[0].quote_detail[0].message,
                            delivery_id: deliveryData[0].delivery_id,
                            fulfiller_id: deliveryData[0].quote_detail[0].fulfiller_id,
                            quote_subject: deliveryData[0].item_desc,
                            delivery_address: deliveryData[0].to,
                            pickup_address: deliveryData[0].from,
                            image_urls: deliveryData[0].image_urls,
                        }]
                    }
                    setmsg(data)
                    setDMconversation(data[0]?.conversation);
                    setDMsender(data[0]?.sender_name);

                    })
                    .catch((error) => {
                        if (error?.response?.status === 403) {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Session!',
                            text: 'Session expired login again!',
                            allowOutsideClick: false,
                            confirmButtonColor: 'var(--main)'
                        })
                            .then((result) => {
                                if (result.value) {
                                    auth.logout();
                                    setShowLogin(true)
                                }
                            });
                    }
                    else {
                        auth.logout();
                        setShowLogin(true)
                    }
                    setloadingData(false)
                })
                }
            }
        }
        
    }, [])
    function reverseArr(input) {
        var ret = new Array;
        for(var i = input?.length-1; i >= 0; i--) {
            ret.push(input[i]);
        }
        return ret;
    }
    const capitalize = (x) => {
        return x;
        //  x?.charAt(0).toUpperCase() + x.slice(1)
      };

      const handleenter=(e)=>{
        // replyMessage();
        if (e.keyCode === 13) {
            replyMessageDM();
      }
        // console.log("hello")
       
       
       
      
      }

    const endpoint = process.env.REACT_APP_API
    const authAxios = axios.create({
        baseURL: `${endpoint}`,
        headers:{
           Authorization:  ls.get('_nodi')
        }
    })

    const insert = (arr, index, ...newItems) => [
        ...arr.slice(0, index),
        ...newItems,
        ...arr.slice(index)
    ]

   const replyMessageDM = ()=>{

    if(dmReply === '') {
        seterror(true)
    }
    else{
        seterror(false)
        setsendingMsg(true)
        authAxios.post(`m-dillivry-bid-manager?delivery_location=${msg[0]?.delivery_location}`,{
            action_type:"quote",
            delivery_id: msg[0]?.delivery_id,
            msg: dmReply,
        }, {timeout: 240000})
        .then((res)=>{
            setsendingMsg(false)
            setDmReply('');
            let new_msg = {"fulfiller_id": msg[0]?.fulfiller_id, "msg":dmReply, "time_stamp": new Date()}
            let new_conversation = insert(DMconversation,0, new_msg);
            setDMconversation(new_conversation)
            const send_to_context = {
               "delivery" : msg[0]?.delivery_id,
               "delivery_type" : type,
               "updated_msg" : new_conversation
            }

            update_dm_conversation(send_to_context) 
        })
        .catch((err)=>{
            if (err.code === "ECONNABORTED") {
                console.log('internal-server-error-timeout')
                history.push(timeout)
              }
            if(err?.response?.status === 400){
              Swal.fire({
                icon: 'info',
                text: `${err?.response?.data?.message}`,
                confirmButtonColor: 'var(--main)'
            })
            }
        })
        .finally(()=>{
          setsendingMsg(false)
        })
    }
   }
    return ( 
        <>
    { 
      ShowLogin ?  history.push("/login")
      : 
        <div className="context dmMessage" style={{ backgroundColor:'white'}}>
            <div className="padding4Mobile" style={{paddingTop:'40px'}}>
                <div style={{width:'100px', marginBottom:"15px"}}>
                    <SmallBtn 
                        label='Back'
                        icon={<TiArrowBack/>}
                        onClick={history.goBack}
                    /> 
                </div> 
                
                <div className="msgView" style={{marginTop:"15px", marginBottom:'15px',border:"none"}}>
                    <div className="msgViewContainer">
                        <div className="msgViewContainerTop">
                            <div className="msgViewContainerTopImage" style={{ backgroundImage:`url(${msg[0]?.image_urls})`}}>   
                            </div>
                            <div className="msgViewTopText">
                                <p> {msg[0]?.quote_subject.charAt(0)?.toUpperCase() + msg[0]?.quote_subject.slice(1) +" ("+msg[0]?.delivery_id + ")" }</p>
                                <p> <img src={warngreen} alt="msgpi_icon"/>&ensp;{msg[0]?.pickup_address}</p>
                                <p> <img src={warnrred} alt="msgpi_icon"/>&ensp;{msg[0]?.delivery_address}</p>
                            </div>
                        </div>
                        {
                            
                    reverseArr(DMconversation).map((converse, index)=>{
                        
                        return(

                            <div key={index}>
                            {converse.fulfiller_id === undefined ? (
                              <>
      
                              <div className="left-wrapper">
                                <span class="imageView-cropper">
                                  <img
                                    src={userAvatar}
                                    class="rounded"
                                  />
                                </span>
                                <div class="message-left">
                                  <div>
                                    {/* <strong>{mssg.fulfiller_id}</strong> */}
                                    <strong>
                                      {capitalize(DMsender)}
                                    </strong>
                                  </div>
                                  <div>
                                    <p className="text-sty">
                                      {capitalize(converse.msg)}{" "}
                                    </p>
                                  </div>
                                  <div className="message-timeStamp">
                                    <div className="all-time">
                                      <div
                                        className="timediplaydesk"
                                        style={{ fontSize: "10px" }}
                                      >
                                        {moment(converse.time_stamp).format(
                                          "MMM Do YYYY, h:mm a "
                                        )}
                                        (
                                        {moment(
                                          converse.time_stamp
                                        ).fromNow()}
                                        )
                                      </div>
                                      <div
                                        className="timediplaymobile"
                                        style={{ fontSize: "7px" }}
                                      >
                                        {moment(converse.time_stamp).format(
                                          "MMM Do YYYY, h:mm a "
                                        )}
                                        (
                                        {moment(
                                          converse.time_stamp
                                        ).fromNow()}
                                        )
                                      </div>
                                    </div>
                                  </div>
                                  <div className="message-timeStamp">
                                    <div className="all-time">
                                      <BsCheckAll
                                        color="var(--main)"
                                        size="18px"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
      
                              </>
                           
                            ) : (
                              <>
                               <div className="right-wrapper">
                              <div class="message-right">
                                <div className="main-msg">
                                  <p>
                                    {" "}
                                    {capitalize(converse.msg)}
                                  </p>{" "}
                                </div>
                                <div className="message-timeStamp">
                                  <div className="all-time">
                                    <div
                                      className="timediplaydesk"
                                      style={{
                                        fontSize: "10px",
                                      }}
                                    >
                                      {moment(
                                        converse.time_stamp
                                      ).format(
                                        "MMM Do YYYY, h:mm a "
                                      )}
                                      (
                                      {moment(
                                        converse.time_stamp
                                      ).fromNow()}
                                      )
                                    </div>
                                    <div
                                      className="timediplaymobile"
                                      style={{
                                        fontSize: "7px",
                                      }}
                                    >
                                      {moment(
                                        converse.time_stamp
                                      ).format(
                                        "MMM Do YYYY, h:mm a "
                                      )}
                                      (
                                      {moment(
                                        converse.time_stamp
                                      ).fromNow()}
                                      )
                                    </div>
                                  </div>
                                </div>
                                <div className="message-timeStamp">
                                  <div className="all-time">
                                    <BsCheckAll
                                      color="white"
                                      size="18px"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
      
                              
                              </>
                             
                            )}
                          </div>
                        
                    //     <div key={index} >
                    // {
                    //     converse.fulfiller_id === undefined ? 
                    //             <div className="msgCard" style={{backgroundColor:'#E6F4E5'}}>
                    //                 <div className="msgCardPics" style={{backgroundImage: `url(${userAvatar})`}}>
                    //                 </div>
                    //                 <div className="msgCardChat">
                    //                     <h4>{DMsender}</h4>
                    //                     <span> 
                    //                         {converse.msg}
                    //                     </span>
                    //                 </div>
                    //                 <span style={{position:'absolute', top:'10px', right:'10px'}}>
                    //                     <div className="timediplaydesk" style={{fontSize:"10px"}}>
                    //                         {moment(converse.time_stamp).format("MMM Do YYYY, h:mm a ")}
                    //                         ({moment(converse.time_stamp).fromNow()})
                    //                     </div>
                    //                     <div className="timediplaymobile" style={{fontSize:"7px"}}>
                    //                         {moment(converse.time_stamp).format("MMM Do YYYY, h:mm a ")}
                    //                         ({moment(converse.time_stamp).fromNow()})
                    //                     </div>
                    //                 </span>
                    //                 <span style={{position:'absolute', bottom:'10px', right:'10px'}}>
                    //                     <BsCheckAll color="var(--main)" size='18px'/>
                    //                 </span>
                    //             </div>
                    //                 :
                    //                 <div className="msgCard">
                    //                     <div className="msgCardPics" style={{ backgroundImage:`url(${myDP})`}}>
                    //                     </div>
                    //                     <div className="msgCardChat">
                    //                         <h4>{converse.fulfiller_id}</h4>
                    //                         <span>
                    //                             {converse.msg}
                    //                         </span>
                    //                     </div>
                    //                     <span style={{position:'absolute', top:'10px', right:'10px'}}>
                    //                         <div className="timediplaydesk" style={{fontSize:"10px"}}>
                    //                             {moment(converse.time_stamp).format("MMM Do YYYY, h:mm a ")}
                    //                             ({moment(converse.time_stamp).fromNow()})
                    //                         </div>
                    //                         <div className="timediplaymobile" style={{fontSize:"7px"}}>
                    //                             {moment(converse.time_stamp).format("MMM Do YYYY, h:mm a ")}
                    //                             ({moment(converse.time_stamp).fromNow()})

                    //                         </div>
                    //                     </span>
                    //                     <span style={{position:'absolute', bottom:'10px', right:'10px'}}>
                    //                         <BsCheckAll color="var(--main)" size='18px'/>
                    //                     </span>
                    //                 </div>
                    //             }
                    //     </div>
                        
                        );
                        })}


                        
                    </div>
                </div>

                <div className="reply-message-wrap">
                                    <div className="reply-message-con">
                                      <div className="send-con">
                                        <div className="send-icons" style={{width: '100%'}}>
                                          {/* <span> */}
                                            <input
                                              autoFocus
                                              requiredtype="text"
                                              className="reply-input"
                                              style={{width: '100%'}}
                                              value={
                                                dmReply
                                              }
                                              onChange={(e) => {
                                                setDmReply(e.target.value);
                                                seterror(false);
                                              }}
                                              onKeyDown={(e) => handleenter(e) }
                                            
                                              type="text"
                                              placeholder="Type your message..."
                                            />
                                            {/* <p>{errors.message?.message}</p> */}
                                          {/* </span> */}
                                        </div>
                                        <div className="send-icons">
                                          <span
                                            type="submit"
                                            value="submit"
                                            className="send-icon"
                                          >
                                            {sendingMsg ? (
                                              <>
                                                <span className="sending">
                                                  <PuffLoader
                                                    color="var(--main)"
                                                    loading={sendingMsg}
                                                    speedMultiplier={1}
                                                    size={25}
                                                  />
                                                </span>
                                              </>
                                            ) : (
                                              <span>
                                                <img
                                                  src={send}
                                                  alt=""
                                                  onClick={replyMessageDM}
                                                  style={{ width: "20px" }}
                                                />
                                              </span>
                                            )}
                                          </span>
                                          <span>
                                            {" "}
                                            <img
                                              src={horiz}
                                              alt=""
                                              style={{ marginLeft: "20px" }}
                                            />{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <br></br>
                                    </div>
              </div>
                
                {/* <div style={{padding:'20px', border:'1px solid #c4c4c4', borderTop:'none'}}>
                    <textarea className='textarea' value={dmReply} onChange={(e)=>{setDmReply(e.target.value);seterror(false)}} placeholder='Type your message...' style={{ width: '100%', backgroundColor: "white", border: error ? "1px solid red" : "1px solid #C4C4C4", color:'black', }}  rows="4"/>   
                  

                    <div className="acceptBTN" style={{width:'150px'}} onClick={replyMessageDM}>
                        {
                            sendingMsg ?
                            <PuffLoader color='white' loading={sendingMsg}  speedMultiplier={1} size={16}/>
                            :
                            <>
                                <span>Send message </span> <div><MdNavigateNext/></div>
                            </>
                        }
                    </div>
                </div> */}
                
            </div>
            {
                loadingData ?
                    <div style={{ width: '100%', height: '100vh', backgroundColor: 'none', zIndex: '300', position: 'fixed', top: '0', paddingTop: '5px' }}>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress color="success" />
                        </Box>
                    </div>

                    : null
            }
        </div>
        }
    </>
    )
}
export default MessageFulfillerDM
