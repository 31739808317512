import {useEffect, useState} from 'react'
import './App.css';
import { AuthProvider } from './context/AuthContext';
import { ConnProvider } from './context/ConnContext';
import { TrackProvider } from './context/TrackingContext';
import SearchProvider from './context/SearchProvider/search.provider';
import $ from 'jquery'
import Routing from './Routing'
import RoutingDefault from './RoutingDefault'
import DashboardProvider from './context/Dashboard/DashboardProvider/dashboard.provider';
import SenderDashboardContext from './context/SenderDashboard/SenderDashboardContext';
import Cookies from 'js-cookie';
import i18next from 'i18next';
import Axios from 'axios'
import i18n from "i18next";
import { BrowserRouter,Router, Link, Redirect, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { I18nextProvider } from "react-i18next";

const history = createBrowserHistory();

function App() {
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    if(process.env.REACT_APP_ENVIRONMENT === 'PROD'){    
      $("head").append(`<script>
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${process.env.REACT_APP_FACEBOOK_PIXEL}');
        fbq('track', 'PageView');
      </script>
      <noscript><img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=${process.env.REACT_APP_FACEBOOK_PIXEL}&ev=PageView&noscript=1"
      /></noscript>`);

      $("body")
        .append(`<script async src="https://www.googletagmanager.com/gtag/js?id=UA-149014179-1"></script>
    <script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag("js", new Date());gtag("config", "UA-149014179-1");</script>`);
 
      document.addEventListener('contextmenu', function(e) {
        e.preventDefault();
      });

    }
    
    else if(process.env.REACT_APP_ENVIRONMENT === 'DEV'){
      var node = `<meta http-equiv="Cache-control" max-age = 0 > 
        <meta name="robots" content="noindex"> 
        <meta name="googlebot" content="noindex"> `
      $('head').append(node);
    }
    else{
     var node = `<meta http-equiv="Cache-control" max-age = 0 > 
        <meta name="robots" content="noindex"> 
        <meta name="googlebot" content="noindex">`
      $('head').append(node);
    }

      // Axios.get('https://geolocation-db.com/json/f9902210-97f0-11eb-a459-b997d30983f1')
      //   .then((response) => {
      //     const country = Cookies.get('i18next') || response.data.country_code.toLowerCase()
      //     i18next.changeLanguage(country)

      //     setCountryCode(response.data.country_code.toLowerCase())
      //   })
      setCountryCode('NG')
  }, [])


  return (
    <>
      <ConnProvider>
        <AuthProvider>
          <TrackProvider>
            <SenderDashboardContext>
              <DashboardProvider>
                <SearchProvider>
                  <I18nextProvider i18n={i18n}>
                    <BrowserRouter
                      getUserConfirmation={(message, callback) => {
                        const allowTransition = window.confirm(message);
                        callback(allowTransition);
                      }}
                      history={history}
                    >
                      <Switch>
                        <Route path="/:locale" component={Routing} />
                          <Route path="*" isStar={true} component={Routing} />
                        <Redirect to="/gb" />
                      </Switch>
                    </BrowserRouter>
                  </I18nextProvider>
                </SearchProvider>
              </DashboardProvider>
            </SenderDashboardContext>
          </TrackProvider>
        </AuthProvider>
      </ConnProvider>
    </>
  );
}
export default App;