import moment from 'moment';
import React from 'react'
import { useClipboard } from "use-clipboard-copy";
import copyart from "../../../assets/copyart.svg";

function ReferralPageMobile({ data, refcode, refbycode }) {
    // console.log(data, refcode, refbycode, "allthree codes")


  const clipboard = useClipboard({
    copiedTimeout: 600, // timeout duration in milliseconds
  });

    return (
        <div
          style={{
            padding: "10px 30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "30px",
            }}
            className="referandref"
          >
            <div
              className="leftref"
              style={{
                fontFamily: "Roboto",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
              }}
            > Referral code 
              {/* Referred code */}
            </div>
    
            <div
              style={{
                fontFamily: "Roboto",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "700",
              }}
              className="leftref"
            >
               Referred by code
            </div>
          </div>
    
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
            className="referandref"
          >


{                      refcode === " " || refcode === undefined || refcode === null || refcode?.length === 0  ? 
                  <span>NA</span> :
              <div
                        className="leftref"
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          borderRadius: "8px",
                          padding: "6px",
                          color: "#484848",
                          fontSize: "16px",
                          fontFamily: "Roboto",

                          fontStyle: "normal",
                        }}
                      >
                        {/* <p>DVL189LV </p> */}

                        <input
                          style={{
                             width: "75px",
                            border: "none",
                          }}
                          //  width={100}
                          ref={clipboard.target}
                          value={refcode}
                          readOnly
                        />
                        {/* <button onClick={clipboard.copy}>Copy Link</button> */}
                        <img
                          onClick={clipboard.copy}
                          src={copyart}
                          alt="copytag"
                          className="tagcopy"
                          data-ho
                        />
                        {clipboard.copied ? "Copied" : ""}
              </div>
}


{        refbycode === " " || refbycode === undefined || refbycode === null || refbycode?.length === 0 || refbycode?.length === 0  ? <span>NA</span> :
                      <div
                        style={
                          {
                            // fontFamily: "Roboto",
                            // fontSize: "16px",
                            // fontStyle: "normal",
                            // fontWeight: "700",
                          }
                        }
                        className="leftref"
                      >
                        <p
                          style={{
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            borderRadius: "8px",
                            padding: "6px",
                            color: "#484848",
                            fontSize: "16px",
                            fontFamily: "Roboto",

                            fontStyle: "normal",
                          }}
                        >
                         {refbycode}
                        </p>
                      </div>
                      
                      }
          </div>
    
          <div
            style={{
              marginBottom: "30px",
            }}
            className="reftitle"
          >
            <h1
              style={{
                fontFamily: "Roboto",
    
                fontStyle: "normal",
                color: "#484848",
                fontSize: "16px",
              }}
              className="mainreftitle"
            >
              Referral history
            </h1>
          </div>
    
          <div 
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "40px"
            }}
          
          className="ref_mobile_general">
           {
            data.map((x, i)=>{
                return (
                    <div key={i} >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // flexWrap: "wrap",
                marginBottom: "10px",
              }}
              className="ref_ref__general"
            >
              <div
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  color: "#484848",
                }}
                className="date_ref"
              >
                Date registered:
              </div>
              <div
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  color: "#004DC1",
                }}
                className="maindate_ref"
              >
                                    {moment(x?.date_registered)?.format("DD-MM-YYYY")}  &nbsp; 
                                    {moment(x?.date_registered)?.format('LTS')} 
              </div>
            </div>
    
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // flexWrap: "wrap",
                marginBottom: "10px",
              }}
              className="ref_ref__general"
            >
              <div
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  color: "#484848",
                }}
                className="date_ref"
              >
                Name:
              </div>
              <div
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  color: "#484848",
                }}
                className="maindate_ref"
              >
                {x?.referral_name}
              </div>
            </div>
    
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // flexWrap: "wrap",
                marginBottom: "10px",
              }}
              className="ref_ref__general"
            >
              <div
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  color: "#484848",
                }}
                className="date_ref"
              >
                No of delivery:
              </div>
              <div
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  color: "#484848",
                }}
                className="maindate_ref"
              >
                {x.completed_jobs}
              </div>
            </div>
    
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // flexWrap: "wrap",
                marginBottom: "10px",
              }}
              className="ref_ref__general"
            >
              <div
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  color: "#484848",
                }}
                className="date_ref"
              >
                Avg earning per Delivery:
              </div>
              <div
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  color: "#484848",
                }}
                className="maindate_ref"
              >
                                   &#8358;
                                            {x?.average_earn.toLocaleString()}
              </div>
            </div>
    
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // flexWrap: "wrap",
              }}
              className="ref_ref__general"
            >
              <div
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  color: "#484848",
                }}
                className="date_ref"
              >
                Total earnings
              </div>
              <div
                style={{
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  color: "#484848",
                }}
                className="maindate_ref"
              >
                   &#8358;
                                            {x?.credit_amount_total.toLocaleString()}
              </div>
            </div>
                    </div>
                )
            })
           }
          </div>
    
    
          {
        data?.length === 0 ?
        <div style={{
            textAlign: "center"
        }}  >No Referral data</div> : null
      }
    
          
        </div>
      );
}

export default ReferralPageMobile