import React,{useEffect, useState,useContext} from 'react'
import {useHistory} from 'react-router-dom';
import {BiLogOut, BiTimer, BiTransfer, BiWallet} from 'react-icons/bi'
import MUTable from '../../../../components/MUTable'
import { AuthContext } from '../../../../context/AuthContext';
import {GiCycle} from 'react-icons/gi'
import MobileQuoteHistory from './MobileQuoteHistory';
import DialogOutClick from '../../../../components/DialogOutClick';
import ViewDeliveryUnassign2 from '../ViewDeliverUnAssign2';
import{DashboardContext}  from '../../../../context/Dashboard/DashboardProvider/dashboard.provider'
import { TiMessages } from 'react-icons/ti';
import { AiTwotoneShop } from 'react-icons/ai';
import { RiAccountPinCircleLine } from 'react-icons/ri';
import Nav from '../../../../components/Nav/Nav';
import IndividualSidebar from "../../../../components/Sidebar/IndividualSidebar";
import dillivry from "../../../../assets/Dillivry.png";
import TopBar from '../../../../components/TopBar/TopBar';
import GlobalizationLanguage from '../../../../components/GlobalizationLanguage';

function QuoteHistory() {
    var wallet = GlobalizationLanguage("/wallet")
    var Dashboard = GlobalizationLanguage("/Dashboard")
    var accounttransaction = GlobalizationLanguage("/account/transaction")
    var messages = GlobalizationLanguage("/messages")

    const {dashboards, updateDashboard} = useContext(DashboardContext)

    const auth = useContext(AuthContext);
    const history = useHistory();
    const [missed, setmissed] = useState([])
    const [completed, setcompleted] = useState([])
    const [total, settotal] = useState([])
    const [all, setall] = useState([])
    const [active, setactive] = useState('transaction')
    const [showLogout, setShowLogout] = useState(false)

    const [clicked, setclicked] = useState('');

    const goBack =()=> setactive('transaction')
    
    const logMeOut = ()=>{
        auth.logout();
         history.push('/') 
     }
    useEffect(() => {
        setmissed(dashboards.data.missed_delivery)
        setcompleted(dashboards.data.completed_quotes)
        settotal([...dashboards.data.missed_delivery, ...dashboards.data.completed_quotes])
        setall([...dashboards.data.missed_delivery, ...dashboards.data.completed_quotes])
    }, [])

    const numberWithCommas = new Intl.NumberFormat("en-GB")
    return (
        <>
        
            <div className="dashboard">
                <div className="dashboardDesktop">
                    <div className="topmenu">
                        <TopBar/>
                    </div>
                    <div className="content-view_menu">
                        <div className="tab" onClick={()=>history.push(Dashboard)}><AiTwotoneShop /> &nbsp; Dillivry Activities</div>
                        <div className="tab"  onClick={() => history.push(wallet) }  >
                    <BiWallet /> &nbsp; Wallet
                  </div>
                        {/* <div className = "tab" onClick={()=>history.push('/BookDelivery')}><TiMessages /> &nbsp; Create delivery</div> */}
                        <div className= "tab" onClick={()=>history.push(messages)}><TiMessages /> &nbsp; Messages</div>
                        <div className="activeTabs"><BiTimer /> &nbsp;  Quote history</div>
                        <div className="tab" onClick={()=>history.push(accounttransaction)}><RiAccountPinCircleLine /> &nbsp;  Account</div>
                    </div>
                </div>
                <div className="dashboardMobile">
                    <div className="ind-nav" style={{ marginBottom: '10px' }}>
                        <TopBar />
                        <div className="logocont">
                            <img src={dillivry} alt="dillivry" onClick={() => history.push('/')} />
                        </div>
                    </div>
                </div>

                <div className="_account" style={{paddingLeft:'0'}}>
                    <div className="fulfillers" style={{padding:'30px'}}>
                        <div className="wideCard">
                            <div className={clicked === "all" ? "innerCardActive" : "innerCard"} onClick={()=>{setall(total); setclicked('all')}}>
                                <div className="uppercard">
                                    <GiCycle color="white" size="30px"/>
                                </div>
                                <div className="cardCount">
                                    <span className='cardno'>{total.length}</span>
                                    <span className='cardIco'><GiCycle size="26px"/></span>
                                </div>
                                <div className="cardCount">
                                    <span className='cardTag'>Total Transactions</span>
                                </div>
                            </div>
                            <div className={clicked === "missed" ? "innerCardActive" : "innerCard"} onClick={()=>{setall(missed); setclicked('missed')}}>
                                <div className="uppercard">
                                    <GiCycle color="white" size="30px"/>
                                </div>
                                <div className="cardCount">
                                    <span className='cardno'>{missed.length}</span>
                                    <span className='cardIco'><GiCycle size="26px"/></span>
                                </div>
                                <div className="cardCount">
                                    <span className='cardTag'>Missed Transactions</span>
                                </div>
                            </div>
                            <div className={clicked === "completed" ? "innerCardActive" : "innerCard"} onClick={()=>{setall(completed); setclicked('completed')}}>
                                <div className="uppercard">
                                    <GiCycle color="white" size="30px"/>
                                </div>
                                <div className="cardCount">
                                    <span className='cardno'>{completed.length}</span>
                                    <span className='cardIco'><GiCycle size="26px"/></span>
                                </div>
                                <div className="cardCount">
                                    <span className='cardTag'>Completed Transactions</span>
                                </div>
                            </div>
                        </div>
                        <div className='btnAssigns'>
                            <div>
                            </div>
                            <div className="btnAssign" 
                                    onClick={() => {
                                        setShowLogout(true)
                                    }}>
                                <BiLogOut size='18px'/> Logout
                            </div>
                        </div> 
                        <div className="usersTable">
                            <MUTable
                                style={{width:'100%', fontSize:'8px'}}
                                title="Quote history"
                                columns={[
                                    { title: '', 
                                        field: 'image_urls', 
                                        render: rowData => <div className='myImage'> <img src={rowData.image_urls[0]}/> </div>,
                                        width: '100px'
                                    },
                                    { title: 'Item', 
                                        field: 'item_desc', 
                                    },
                                    { title: 'From', 
                                        field: 'from', 
                                    },
                                    { title: 'To', 
                                        field: 'to',
                                    },
                                    { title: 'Amount (NGN)', field: 'quote_amount', width:'100px',
                                        render: rowData => <span>{numberWithCommas.format(rowData.quote_amount)}</span> 
                                    },
                                    { title: 'Status', field: 'quote_status',
                                        render: rowData => rowData.quote_status === 'accepted' ? <span>Accepted</span> : rowData.quote_status === 'awaiting_acceptance' ? <span>Awaiting Quote Acceptance</span> : rowData.quote_status === "missed_delivery" ? "Missed" : 'Completed'

                                     },
                                    ]}
                                data={all}
                                onRowClick={(event, rowData) => {
                                    var link = GlobalizationLanguage(`/delivery_history/${rowData.quote_status === "missed_delivery" ? 'missed' : 'complete'}/${rowData.delivery_id}`)
                                    history.push(link)
                                }}
                                options={{
                                    exportButton: true,
                                    actionsColumnIndex: -1,
                                    // filtering: true,
                                    headerStyle:{
                                        fontSize:'13px',
                                        fontWeight:'600',
                                        backgroundColor:'rgb(236, 252, 242)'
                                    }
                                }}
                                actions={[
                                    ]}
                                    
                            />
                        </div>
                      </div>
            </div>
            

            {/* mobile view start here */}
            <div className="_accountMobile">
                <MobileQuoteHistory/>
            </div>
            <DialogOutClick
                open={showLogout}
                handleClose ={()=>setShowLogout(false)}
                > 
                <div style={{width:'100%',padding:"30px 30px 20px 30px"}}>
                    <div style={{width:'100%',marginBottom:"30px", fontSize:'13px', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <span>Are you sure you want to logout?</span>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
                        <div className="disBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer'}}
                            onClick={()=>setShowLogout(false)} >
                            No
                        </div>
                        <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'white', backgroundColor:'#808080', border:'1px solid #808080' }}
                            onClick={logMeOut}>
                            Yes
                        </div>
                    </div>
                </div>
            </DialogOutClick>
        </div>
        </>
       
    )
}

export default QuoteHistory
