import {useState} from 'react'
import './SenderRegistartion.css'
import Dillivry from '../../../assets/Dillivry.png'
import SenderRegForm from './SenderRegForm'
import SenderRegLogin from './SenderRegLogin'
import SenderSideBar from '../components/SenderSideBar'
import { Helmet } from 'react-helmet'
import ReactPlayer from 'react-player/youtube'
import Small from "../../../assets/pageEclipseSmall.svg";
import Big from "../../../assets/pageEclipse.svg";

function SenderRegistartion({history}) {
    // const [page, setpage] = useState('select_account')

    const [page, setpage] = useState('registration_form')
    
    const links =  [
        {
          path: "/",
          name: "Home",
        },
      ]

    return (
        <div className='sender_reg_container'>
            <Helmet>
                <title>Dillivry - Sender Sign Up</title>
                <meta charSet="utf-8"/>
                <meta
                    name="description"
                    content="Download the Dillivry App in App store or Google play and get started!"  
                />
            </Helmet>

            <div className="centerLogoMobile">
                <img src={Dillivry} alt="dillivry logo" width='150px' style={{cursor:'pointer'}} onClick={()=>history.push('/')} />
            </div>

            <div style={{width:'100%', display:'flex', justifyContent:'center', marginBottom:'30px', color:'#484848', textAlign:'center', fontWeight:'400'}}> <h3>Sign up as Individual or Business</h3>  </div>

            <div className="senderReg">
                <div className='sendLeft'>
                    <h3>Sender's guide</h3>
                    <p>Play the video to discover how dillivry work best for you as a sender</p>
                    <div className='playerCon'>
                        <ReactPlayer 
                          url='https://youtu.be/LSQRoRaCcTM'
                          width='100%'
                          height='100%' 
                          className='react-player'
                        />
                      </div>
                </div>
                <div className="sendRightt">
                    <div className="sRight">
                        {
                            page === "registration_form" ?
                            <>
                            <SenderRegForm gotoLogin={()=>setpage('login')}/>
                            </>
                            :
                            page === "login" ?
                            <SenderRegLogin/>
                            :
                            null
                        }
                    </div>
                </div>
            </div>

            <div className='topLeftSender'>
                <img src={Small} alt="dillivry"/>
            </div>
            <div className='topRightSender'>
                <img src={Big} alt="dillivry"/>
            </div>
            <div className='bottomLeftSender'>
                <img src={Big} alt="dillivry"/>
            </div>
            <div className='bottomRightSender'>
                <img src={Small} alt="dillivry"/>
            </div>

        </div>
    )
}

export default SenderRegistartion