import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import Dillivry from '../assets/Dillivry.png'
import {Link} from 'react-router-dom'
import './Popup.css';

export default function PrivacyDialog({open,title, pageToShow, onClick}) {
    const theme = createTheme({
        palette: {
          primary: {
            main: '#50B14A',
          },
        },
      });

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        maxWidth="sm"
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {pageToShow === "privacy" ? (
              <div className="priva">
                <div className="tit">
                  <Link to="/">
                    <img src={Dillivry} alt="Dillivry Logo" style={{width:'150px'}} />{" "}
                  </Link>
                </div>
                <div className="tit">Dillivry - Privacy Policy</div>
                <div className="conte">
                  We at Datamellon, we view the security of our customer's data
                  as vital. Any personal data you provide to us and from which
                  you can be identified is stored securely and confidentially
                  and is processed in accordance with applicable legislation and
                  this privacy policy, which sets out what personal data we
                  collect about you when you interact with us and how we use
                  your data. We take appropriate technical and organisational
                  measures to protect against unauthorised or unlawful
                  processing of your personal data, including encrypting your
                  information to applicable industry standards. Where we have
                  given you (or where you have chosen) a password which enables
                  you to access certain parts of our site, you are responsible
                  for keeping this password confidential. We ask you not to
                  share a password with anyone. So you only need to look in one
                  place to find out all you need to know about how the
                  Datamellon collects and processes your personal information,
                  this policy covers our Website (being any website operated by
                  Datamellon.com), our App (being any app operated by
                  Datamellon.com), and any correspondence you have with Dillivry
                  in relation to our operations. Any changes we make to this
                  policy will be reflected on this page and notified to you by
                  email, where appropriate.
                </div>
                <div className="tit">Who we are</div>
                <div className="conte">
                  As set out in our terms and conditions, this Website and the
                  App are developed and operated by Datamellon Limited, whose
                  registered addresses are Epworth House, 25 City Rd,
                  Shoreditch, London, EC1Y 1AA and 7 Adefowope, Ilupeju, Lagos,
                  Nigeria. With company number: 07039424 and RC: 1679193
                  Datamellon is the controller of your Personal Data and
                  responsible for the collection, processing and disclosure of
                  your Personal Data as described in this Privacy Policy unless
                  expressly specified otherwise. By visiting dillivry.com and
                  using the App, you agree to be bound by the terms and
                  conditions of this Privacy Policy and expressly consent to our
                  use and disclosure of your personal information in accordance
                  with this Privacy Policy. If you do not agree, please do not
                  use or access our site. This Privacy Policy is incorporated
                  into and subject to the terms of the Dillivry User Agreement.
                  We reserve the right to modify this Privacy Policy at any
                  time, so please review it frequently.
                </div>
                <div className="tit">Information we may hold about you</div>
                <div className="conte">
                  When you use our Website or App, whether or not you are
                  registered, subscribes to our services, register to receive
                  our emails, receive and open or click our emails, or make a
                  job application, we and our partner advertising networks,
                  advertisers and advertising affiliates (Third Party
                  Advertisers), will collect user information such as your
                  location, language, assumed gender, IP address, when you use
                  our Website or App, how you arrived on our Website or App,
                  where you visit on our Website or App, the pages you visited,
                  how long you spend browsing individual pages on our Website or
                  App, any services you have viewed, and the browser (where
                  applicable) and device you used to access our Website or App.
                  In order to use most services or features on Dillivry.com
                  either as a sender or logistic provider, you must first
                  complete the registration form and create a username and
                  password. During the registration process, you are required to
                  provide contact information such as name, address, phone
                  number, and email address. Note, once you agree to provide us
                  with your personal information, you are no longer anonymous to
                  us. If you choose to use our services, we may require you to
                  provide contact and identity information, billing information,
                  and other personal information as indicated on the forms
                  throughout the Site. Under some circumstances we may require
                  some additional financial information. Where possible, we
                  indicate which fields are required and which are optional. You
                  always have the option to not provide information by choosing
                  not to use a particular service or feature. If you choose to
                  list/send items, quote/bid, or match on the Dillivry.com and
                  App, we collect information about your activity and behavior.
                  We also collect other users’ comments about you in our
                  feedback area Additionally, as part of the registration
                  process, Dillivry allows members registering as a logistic
                  provider the option to provide some certain relevant
                  information such as a valid driving license, goods in transit
                  insurance policy number and other documents deemed necessary
                  for their smooth operations. It is up to each logistic
                  provider to provide this information because it will go a long
                  way in increasing their rating and overall feasibility on
                  Dillivry.com. Dillivry will validate this information
                  programmatically to all relevant bodies issuing the provided
                  information. If you choose to post messages on our Dillivry
                  discussion forums, Listing Q&A boards, or leave feedback for
                  other members, we will collect that information you provide to
                  us. We retain this information as necessary to resolve
                  disputes, provide customer support and troubleshoot problems
                  as permitted by law. See “Dillivry Discussion Forums” section
                  below. If you send us personal correspondence, such as emails
                  or letters, or if other users or third parties send us
                  correspondence about your activities or postings on the Site,
                  we may collect such information into a file specific to you.
                  From time-to-time we may provide you the opportunity to
                  participate in contests or surveys on the Site or App. If you
                  choose to participate, we will request certain personal
                  information, including your email address, from you.
                  Participation in these surveys or contests is completely
                  voluntary and you therefore have a choice whether or not to
                  disclose this information. The requested information typically
                  includes contact information and demographic information. We
                  will not share the personal information you provide through a
                  contest or survey with other third parties unless we give you
                  prior notice and choice. In circumstances where you contact
                  Dillivry by telephone, calls may be recorded for quality,
                  training and security purposes. Calls may also be monitored
                  without your consent in the following circumstances: to
                  provide evidence of a business transaction; to prevent or
                  detect a crime; to ensure that the Dillivry complies with
                  regulatory procedures; to see that quality standards or
                  targets are being met; and to secure the effective operation
                  of the telecom system.
                </div>
                <div className="tit">Log Files</div>
                <div className="conte">
                  As is true of most Web sites, at Dillivry.com we gather
                  certain information automatically and store it in log files.
                  This information includes internet protocol (IP) addresses,
                  browser type, internet service provider (ISP), referring/exit
                  pages, operating system, date/time stamp, and clickstream
                  data. We use this information to analyze trends, to administer
                  the site, to track customers’ movements around the site and to
                  gather demographic information about our customer base as a
                  whole. We track trends in customers’ usage and volume
                  statistics to create a more efficient and usable site, and to
                  determine areas of the site that could be improved to enhance
                  the customer experience. Log files are used on the website,
                  and in any link to the website from an email.
                </div>
                <div className="tit">Tracking Technologies</div>
                <div className="conte">
                  Technologies such as: cookies and similar technologies are
                  used by Dillivry and our marketing partners, affiliates, and
                  customer support and fraud prevention service providers. These
                  technologies are used in analyzing trends, administering the
                  site, tracking customers’ movements around the site and to
                  gather demographic information about our customers’ base as a
                  whole. We may receive reports based on the use of these
                  technologies by these companies on an individual as well as
                  aggregated basis. We use cookies to remember our
                  visitors/users/customers preferences and for authentication.
                  Visitor/user/customer can control the use of cookies at the
                  individual browser level. For more information please visit
                  our Cookie Policy page. If you reject cookies, you may still
                  use our site, but your ability to use some features or areas
                  of our site may be limited.
                </div>
                <div className="tit">Behavioural Targeting/Retargeting</div>
                <div className="conte">
                  We partner with third parties to manage our advertisement on
                  other sites. Our third party partners may use technologies
                  such as cookies and similar technologies to gather information
                  about your activities on Dillivry.com site and Dillivry App in
                  order to provide you advertisement based upon your browsing
                  activities and interests. If you wish to not have this
                  information used for the purpose of serving you interest-based
                  ads, you may opt-out. Please note this does not opt you out of
                  being served ads. You will continue to receive generic ads.
                </div>
                <div className="tit">How we use your personal data</div>
                <div className="conte">
                  We collect and use your data primarily for the following
                  purposes:
                  <ul>
                    <li>
                      Provide services and features that are most likely to meet
                      your needs and facilitate the services you request.
                    </li>
                    <li>
                      Customize your experience and the content, products, and
                      services offered to you.
                    </li>
                    <li>
                      {" "}
                      Verify the accuracy of your name, address, and other
                      information.
                    </li>
                    <li>
                      Bill you for your use of our services and collect fees
                      owed.
                    </li>
                    <li>
                      Provide support for your transactions, products, and
                      services you use.
                    </li>
                    <li> Assist you to resolve disputes.</li>
                    <li>Comply with legal requirements.</li>
                    <li>
                      Inform you about online and offline offers, products,
                      services, and updates.
                    </li>
                    <li>
                      Provide targeted advertising, including the use of
                      re-targeting technology.
                    </li>
                    <li>
                      {" "}
                      Measure consumer interest in our products and services.
                    </li>
                    <li>
                      Detects and protects us against error, fraud, and other
                      criminal activity and enforce our User Agreement.
                    </li>
                    <li>
                      Look across multiple users to identify problems or resolve
                      disputes and identify users using multiple usernames or
                      aliases.
                    </li>
                    <li>
                      Compare and review your personal information for errors,
                      omissions, and accuracy.
                    </li>
                    <li>
                      Conduct internal research on our users’/customers’
                      demographics, interest, and behavior to better understand,
                      protect, and serve you and our community.
                    </li>
                    <li>
                      Improve our marketing and promotional efforts, analyze
                      site usage, improve our content and product offerings, and
                      customize the Site's content, layout, and services.
                    </li>
                    <li>
                      Collect personal information on behalf of our payment
                      processors in order to meet compliance obligations, such
                      as Know Your Customer.
                    </li>
                  </ul>
                </div>
                <div className="tit">Information Sharing and Disclosure</div>
                <div className="conte">
                  As a matter of policy, we do not sell, rent or share any of
                  your personal information, including your email address, to
                  third parties for their marketing purposes without your
                  explicit consent. The profile you create on Dillivry.com and
                  Dillivry App will be publicly accessible unless otherwise
                  indicated. You may change the privacy settings of your profile
                  through your Dillivry account portal. However, the following
                  describes some of the ways that your personal information may
                  be disclosed in the normal scope of business to provide our
                  services. Dillivry Platform: Your Dillivry username is
                  displayed throughout our Site, App and is therefore available
                  to the public. All of your activities on our Site and App will
                  be identifiable to your username. Other people can see your
                  quotes/bids, lists you have listed, your feedback rating and
                  associated comments, your postings on the site, and so on.
                  Therefore, if you associate your name with your username, the
                  people you have revealed your name to will be able to
                  personally identify your dillivry activities. If you are
                  involved in a transaction, we have features that allow parties
                  to the transaction to view your email address and obtain your
                  contact information to help complete the transaction.
                  Furthermore, we may send out notices to you or other platform
                  members regarding suspicious activity or policy violations on
                  Dillivry.com and App. Additionally, we may share your written
                  correspondence, including emails to the other party to your
                  transaction as part of the dispute resolutions process. To
                  request removal of your personal information from the Dillivry
                  platform, you can contact us using our contact information. In
                  some cases, we may not be able to remove your personal
                  information, in which case we will let you know if we are
                  unable to do so and why. External Service Providers: There may
                  be separate services offered by third parties that we refer to
                  as external service providers that may be complementary to
                  your use of Dillivry.com and App. If you choose to use these
                  separate services, disclose information to the external
                  service providers, and/or grant them permission to collect
                  information about you, then their use of your information is
                  governed by their privacy policy. We do not disclose your
                  personal information, including your email address, to
                  external service providers unless you provide your explicit
                  consent. With your consent we may provide some of your
                  personal information to the external service provider offering
                  such services, for your convenience. To prevent our disclosure
                  of your personal information to an external service provider,
                  you can decline such consent or simply not use their services.
                  Because we do not control the privacy practices of these third
                  parties, you should evaluate their practices before deciding
                  to use their services. Internal Service Providers for Our
                  Operations: We may use third parties that we refer to as
                  internal service providers to facilitate or outsource one or
                  more aspects of the business, product and service operations
                  that we provide to you and therefore we may provide some of
                  your personal information, including your email address,
                  directly to these internal service providers. Any sensitive
                  information that is transmitted to internal service providers
                  involved in any payment processing functions will be encrypted
                  or tokenized. These internal service providers' are subject to
                  confidentiality agreements with us and other legal
                  restrictions that prohibit their use of the information we
                  provide them for any other purpose except to facilitate the
                  specific outsourced Dillivry related operation, unless you
                  have explicitly agreed or given your prior permission to them
                  for additional uses. If you provide additional information to
                  an internal service provider directly, then their use of your
                  personal information is governed by their applicable privacy
                  policy. Legal Requests: Dillivry cooperates with law
                  enforcement inquiries, as well as other third parties to
                  enforce laws, such as: intellectual property rights, fraud and
                  other rights, to help protect you and the Dillivry platform
                  from bad actors. Therefore, in response to a verified request
                  by law enforcement or other government officials relating to a
                  criminal investigation or alleged illegal activity, we can
                  (and you authorize us to) disclose your name, city, state,
                  telephone number, email address, user history, fraud
                  complaints, and quoting and listing history without a
                  subpoena. Without limiting the above, in an effort to respect
                  your privacy and our ability to keep the platform free from
                  bad actors, we will not otherwise disclose your personal
                  information to law enforcement or other government officials
                  without a subpoena, court order or substantially similar legal
                  procedure, except when we believe in good faith that the
                  disclosure of information is necessary to: prevent imminent
                  physical harm or financial loss; or report suspected illegal
                  activity. Due to the existing regulatory environment, we
                  cannot ensure that all of your private communications and
                  other personal information will never be disclosed in ways not
                  otherwise described in this Privacy Policy. By way of example
                  (without limiting the foregoing), we may be forced to disclose
                  personal information, including your email address, to the
                  government or third parties under certain circumstances, third
                  parties may unlawfully intercept or access transmissions or
                  private communications, or users may abuse or misuse your
                  personal information that they collect from Dillivry.com and
                  App. Therefore, although we use industry standard practices to
                  protect your privacy, we do not promise, and you should not
                  expect, that your personal information or private
                  communications will always remain private. See “Dillivry
                  Security Section”. We collect your location-based information
                  for the purpose of locating a place that you may be searching
                  for in your area. We will only share this information with our
                  mapping provider for the sole purpose of providing you this
                  service. You may opt-out of location based services at any
                  time by editing the setting at the device level or by emailing
                  using our contact information.
                </div>
                <div className="tit"> Communications from the Site</div>
                <div className="conte">
                  We will occasionally send you information on products,
                  services, special deals, and promotions, as well as a
                  newsletter. You are signed up for these emails at registration
                  and you may unsubscribe at any time by going to your account
                  tab and opt out of the emails, or by following the directions
                  to unsubscribe in the email. Out of respect for your privacy,
                  we provide you a way to unsubscribe from each of these
                  communications when we ask for it. If you no longer wish to
                  receive our newsletter and promotional communications, you may
                  opt-out of receiving them by following the instructions
                  included in each newsletter or communication or by emailing us
                  using our contact information. You will be notified when your
                  personal information is collected by any third party that is
                  not our agent/service provider, so you can make an informed
                  choice as to whether or not to share your information with
                  that party. We will send you strictly service-related
                  announcements on rare occasions when it is necessary to do so.
                  For instance, if our service is temporarily suspended for
                  maintenance, we might send you an email. Generally, you may
                  not opt-out of these communications, which are not promotional
                  in nature. If you do not wish to receive them, you have the
                  option to deactivate your account. Based upon the personal
                  information you provide us, we will send you a welcoming email
                  to verify your email address, username and password. We will
                  also communicate with you in response to your inquiries, to
                  provide the services you request, and to manage your account.
                  Email is the primary means of communication between members
                  doing transactions on Dillivry.com, App and you must be able
                  to receive this information to use Dillivry properly.
                </div>
                <div className="tit">Links to Other Sites </div>
                <div className="conte">
                  Dillivry.com contains links to other sites that are not owned
                  or controlled by Dillivry. Please be aware that we are not
                  responsible for the privacy practices of such other sites. We
                  encourage you to be aware when you leave our site and to read
                  the privacy policies of each and every Web site that collects
                  personal information. This privacy policy applies only to
                  information collected by this Web site. Some portions of the
                  Dillivry platform implement Google Maps mapping services,
                  including Google Maps API(s). Your use of Google Maps is
                  subject to Google’s Terms of Service and Google’s Privacy
                  Policy, as may be amended by Google from time to time.
                  Dillivry Discussion Forums If you use a bulletin board on the
                  Site, you should be aware that any personal information,
                  including your email address, you submit there can be read,
                  collected, or used by other users of these forums, and could
                  be used to send you unsolicited messages. We are not
                  responsible for the personal information you choose to submit
                  in these forums. All other information pertaining to the
                  collection and use of information can be found in Dillivry
                  Content Policy page. To request removal of your personal
                  information from the Dillivry platform, contact us using our
                  contact information. In some cases, we may not be able to
                  remove your personal information, in which case we will let
                  you know if we are unable to do so and why.
                </div>
                <div className="tit">Links to Other Sites </div>
                <div className="conte">
                  Dillivry.com contains links to other sites that are not owned
                  or controlled by Dillivry. Please be aware that we are not
                  responsible for the privacy practices of such other sites. We
                  encourage you to be aware when you leave our site and to read
                  the privacy policies of each and every Web site that collects
                  personal information. This privacy policy applies only to
                  information collected by this Web site. Some portions of the
                  Dillivry platform implement Google Maps mapping services,
                  including Google Maps API(s). Your use of Google Maps is
                  subject to Google’s Terms of Service and Google’s Privacy
                  Policy, as may be amended by Google from time to time.
                </div>
                <div className="tit">Testimonials</div>
                <div className="conte">
                  We display personal testimonials of satisfied customers on
                  Dillivry.com and App in addition to other endorsements. We may
                  post your testimonial along with your first name and last
                  initial. If you wish to update or delete your testimonial, you
                  can contact us using our contact information.
                </div>
                <div className="tit">Social Media Connections & Features</div>
                <div className="conte">
                  Our website includes social media features, such as the
                  Facebook 'Like' button and widgets, such as the 'Share this'
                  button or interactive mini-programs that run on our site.
                  These features may collect information such as your IP address
                  and which page you are visiting on our site, and they may set
                  a cookie to enable the feature to function properly. Social
                  media features and widgets are either hosted by a third party
                  or hosted directly on our Site. Your interactions with these
                  features are governed by the privacy policy of the company
                  providing them.
                </div>
                <div className="tit">Access to Personal Information</div>
                <div className="conte">
                  Upon request we will provide you with information about
                  whether we hold, or process on behalf of a third party, any of
                  your personal information. You may request this information by
                  contacting us using contact information. We will respond to
                  your request within 30 days. If you are a registered user, you
                  may correct or rectify your data at any time. We provide you
                  with tools to access, correct, delete or modify the personal
                  data you provided to us through your Dillivry Account page or
                  by contacting our Customer Operations team at Dillivry contact
                  information. If you no longer desire to use our service you
                  can deactivate your account at any time by making the change
                  on your Dillivry Account page or by contacting our Customer
                  Operations team at Dillivry contact information. You can also
                  manage your privacy settings by accessing your Communication
                  Preferences through your Dillivry Account page. If you
                  deactivate your account, or request that your account be
                  deactivated please keep in mind that search engines may still
                  retain copies of your public information in line with their
                  retention policies. You will need to contact those 3rd parties
                  directly to remove your information. We will retain your
                  information for as long as your account is active or as needed
                  to provide you services. We will retain and use your
                  information as necessary to comply with our legal obligations,
                  resolve disputes, and enforce our agreements
                </div>
                <div className="tit">Security</div>
                <div className="conte">
                  The security of your personal information is important to us.
                  When you enter sensitive information (such as credit/debit
                  card number) on our registration or order forms, we encrypt
                  that information using secure socket layer technology (SSL).
                  We follow generally accepted industry standards to protect the
                  personal information, including your email address, submitted
                  to us, both during transmission and once we receive it. No
                  method of transmission over the Internet, or method of
                  electronic storage, is 100% secure, however. Therefore, while
                  we strive to use commercially acceptable means to protect your
                  personal information, we cannot guarantee its absolute
                  security.
                </div>
                <div className="tit">Business Transitions</div>
                <div className="conte">
                  In the event Dillivry goes through a business transition, such
                  as a merger, acquisition by another company, or sale of all or
                  a portion of its assets, your personal information, including
                  your email address, will likely be among the assets
                  transferred. You will be notified via email of any such change
                  in ownership or control of your personal information.
                </div>
                <div className="tit">Changes in this Privacy Policy</div>
                <div className="conte">
                  If we decide to change our privacy policy, we will post those
                  changes to this privacy policy on our website and other places
                  we deem appropriate so that you are aware of what information
                  we collect, how we use it, and under what circumstances, if
                  any, we disclose it.
                </div>
                <div className="tit">Contact Us</div>
                <div className="conte">
                  If you have any questions or suggestions regarding our privacy
                  policy or to exercise your rights regarding your personal
                  information, please contact us using our contact information
                  or by mail at the address below.
                </div>
              </div>
            ) : 
            pageToShow === 'terms' ? (

              
              <div>
                {/* <div className="tit">Terms and condition</div> */}
                <div>
                <h2>Dillivry Platform Terms of Use  </h2>
                <p>
                Dillivry.com and App operate as an online logistic marketplace to enable Users/Senders/Listers to list/send jobs/items, and Logistic Providers to quote/bids for these items/jobs, with a view to entering into a Transaction. 
                </p>
                <h2>Information which we are required to give you </h2>
                 <p><Link to="/"><p className="dilP" style={{display:'inline'}}>www.dillivry.com</p></Link> or Dillivry App is a product of Datamellon Ltd. (<b>"we"</b> or <b>"us"</b>), 
                a company which is registered in England and Nigeria under company number 07039424 and RC number 1679193. 
                Our registered offices are at Epworth House, 25 City Rd, Shoreditch, London, EC1Y 1AA, and 7 Adefowope,
                Ilupeju, Lagos, Nigeria.  With our email {/*<Link style={{ display:"inline" }} className="conlink">contact information.</Link></p>*/}
                contact information.</p>
                
                <p>Part A (the <b>"General Conditions"</b>) 
                and Part B (the <b>"Supplemental Conditions"</b>) of these terms and conditions (together with the 
                documents referred to within) set out the terms and conditions on which we will provide our services to you. 
                </p>
                <p>You may access most areas of Dillivry.com or Dillivry App without the need for Registration in which case only 
                the General Conditions will apply to you. Certain areas of Dillivry.com or Dillivry App are only open to you if 
                you are a Member and if you decide to register as a Member you will be asked to confirm expressly that you have 
                read and accepted the Supplemental Conditions as well as the General Conditions.</p>
                <p> The Conditions (as defined below) 
                apply to the access and use of the Dillivry.com or Dillivry App, and to any correspondence by e-mail between you 
                and us. Please read these terms carefully before using Dillivry.com or Dillivry App. Using Dillivry.com or Dillivry 
                App indicates that you accept the General Conditions regardless of whether or not you choose to become a Member. 
                If you do not accept the General Conditions, do not use Dillivry.com or Dillivry App.</p>
                <p> We may revise the Conditions 
                at any time by updating this posting. You should check the Site from time to time to review the current version of 
                the Conditions because they are binding on you. Certain provisions in the Conditions may be superseded by expressly 
                designated legal notices located on particular pages of the Site.</p>
                <p> Any rights not expressly granted in these terms are reserved.
                </p>
    
                <h2 className='headsss'>PART A - GENERAL CONDITIONS </h2>
                <div className="headlings">1.  Definitions </div>
                <p>
                <b>Logistic Provider</b> means a Member who has submitted a quotation in respect of a Job/item listed by a sender/Lister; 
                </p>
                <p><b>"Conditions"</b> means all conditions applicable to a person accessing Dillivry.com or Dillivry App in connection with 
                his/her use of Dillivry.com or Dillivry App (i.e. for a mere visitor, the General Conditions alone, and for a Member, 
                the General Conditions and the Supplementary Conditions);</p>
                <p><b>"User/Sender/Lister"</b> means a Member 
                who has posted a Job/item on Dillivry.com or Dillivry App;</p>
                <p><b> "Job/Item"</b> means the movement request posted by the User/Sender/Lister 
                on Dillivry.com or Dillivry App;</p>
                <p><b>"Member"</b> means a person who has registered on Dillivry.com or Dillivry App (and "Membership" means the 
                status of being a Member);</p>
                <p><b>"Registration"</b> or <b>"Register"</b> means setting up a membership account having agreed to the Conditions;</p>
                <p><b>"Site"</b> means www.dillivry.com; and</p> 
                <p><b>"Transaction" </b>means the agreement entered into between a Logistic Provider and a <b>User/Sender/Lister</b> for the performance of a Job.</p>
                <p><b>"Book Now"</b> means Jobs/items which are booked after seeing instant rates rather than undergoing the auction process. 
                </p>
                <div className="headlings">2. 	Access  </div>
                <p>
                    <ul className='termlist'>
                        <li>Whilst we endeavor to ensure that Dillivry.com or Dillivry App is available 24 hours a day, we shall not be liable if, for any reason, 
                        the Dillivry.com or Dillivry App is unavailable at any time or for any period.</li>
                        <li> Access to the Site or App may be suspended temporarily 
                        and without notice in the case of a system failure, maintenance, or repair, or for any reasons beyond our control.</li>
                     </ul>
                </p>
                <div className="headlings">3. 	Content  </div>
                <p>
                    <ul className='termlist2'>

                        <li>Whilst we endeavor to ensure that the information on the Dillivry.com or Dillivry App is correct, we do not warrant
                        the accuracy or completeness of the material on the Site or App. We may make changes to the material on the Site or App 
                        at any time and without notice.</li> 
                        <li>The material on the Site may be out of date, and we make no commitment to update such material. 
                        The contents of the Site are provided "as is", and we provide no warranties in respect of such contents. You are prohibited from 
                        posting or transmitting to or from the Site any material:</li>
                            <ul className='termlist3'>
                                <li>
                                that is threatening, defamatory, obscene, indecent, offensive, pornographic, abusive, liable to incite racial hatred, discriminatory, menacing, scandalous, inflammatory, blasphemous, in breach of confidence, in breach of privacy or which may cause annoyance or inconvenience; 

                                </li>
                                <li>
                                for which you have not obtained all necessary licenses and/or approvals; 
                                </li>
                                <li>
                                which constitutes or encourages conduct that would be considered a criminal offence, gives rise to civil liability, is otherwise unlawful or infringes the rights of any third party, in any country in the world; or 
                                </li>
                                <li>
                                which is technically harmful (including, without limitation, computer viruses, logic bombs, Trojan horses, worms, harmful components, corrupted data or other malicious software or harmful data).</li> 
                            </ul>
                        <li>You may not misuse the Site (including, without limitation, by hacking).</li>
                        <li>We will cooperate fully with any law enforcement authorities or court order requesting or directing us to disclose the identity of, or locate, anyone posting any material in breach of this clause 3.</li>
                     </ul>
                
                </p>
                
                <div className="headlings">4. 	Licence  </div>
                <p>
                    <ul className='termlist2 '>
                        
                        <li>You are permitted to view, print and download extracts from the Site or App for your own use on the basis that: </li>
                            <ul className='termlist3'>
                                <li>
                                    no documents or related graphics on the Site or App are modified in any way; 

                                </li>
                                <li>
                                    no graphics on the Site or App are used separately from the corresponding text; and

                                </li>
                                <li>
                                    our copyright notices and this permission notice appear in all copies.
                                </li>
                            </ul>
                        <li>Unless otherwise stated, the copyright 
                            and other intellectual property rights in all material on the Site (including, without limitation, photographs and 
                            graphical images) are owned by us or our licensors. Saved as agreed otherwise, any use of extracts from Dillivry.com or 
                            Dillivry App other than in accordance with clause 4.1 is prohibited, and if you breach clause 4.1, your permission to use 
                            the Site automatically terminates and you must immediately destroy any downloaded or printed extracts from the Site.</li>
                        <li>
                        Subject to clause 4.1, no part of the Site or App may be reproduced or stored on any other website or included in any public or private 
                        electronic retrieval system or service without our prior written permission. 
                        </li>
                    </ul>
                </p>
                <div className="headlings">5.     Disclaimer </div>
                <p>
                    <ul className='termlist2'>
                        <li>To the extent permitted by law we hereby exclude: </li>
                        <li>all conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity; and </li>
                        <li>any liability for any direct, indirect or consequential loss or damage incurred by any person in connection with the Site or in connection with the use, inability to use, or results of the use of the Site, any websites linked to it and any materials posted on it, including, without limitation any liability for:</li> 
                
                            <ul className="termlist3">

                                <li>loss of income or revenue; </li>

                                <li>loss of business; </li>

                                <li>loss of profits or contracts;</li> 

                                <li>loss of anticipated savings; </li>

                                <li>loss of data;</li> 

                                <li>loss of goodwill;</li> 

                                <li>wasted management or office time; and </li>

                                <li> for any other loss or damage of any kind, however arising and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable</li>
                            </ul>
                        <li> This does not affect our liability for death or personal injury arising from our negligence, nor our liability for fraudulent misrepresentation or misrepresentation as to a fundamental matter, nor any other liability which cannot be excluded or limited under applicable law.</li> 
                    </ul>
                </p>
                <div className="headlings">6. 	Information About You and Your Visit to Our Site </div>
                <p>
                Any information about you that we process will be processed in accordance with our privacy policy which can be found on our privacy policy page. By using the Site you consent to such processing.
                </p>
                <div className="headlings">7. 	Termination   </div>
                <p>
                We may, at our sole discretion, restrict your access to Dillivry.com or Dillivry App and/or refuse to correspond with you without prior notice where: 
                    <ul className='termlist3'>
                        <li>there is a regulatory or statutory change limiting our ability to provide access to Dillivry.com or Dillivry App; </li>
                        <li>there is any event beyond our reasonable control preventing us from providing access to Dillivry.com or Dillivry App (for example, and without limitation, technical difficulties, capacity problems and communications failures); or </li>
                        <li>we consider that you are abusing Dillivry.com or Dillivry App or are otherwise acting in breach of the Conditions. </li>
                    </ul>
                </p>
                <div className="headlings">8. 	Links To And From Other Websites  </div>
                <p>
                    <ul className="termlist2 ">
                        <li>Links to third party websites on Dillivry.com or Dillivry App are provided solely for your convenience. If you use these links, you leave the Site. We have not reviewed all of these third party websites and do not control and are not responsible for their content or availability. We do not therefore endorse or make any representations about them, or any material found there, or any consequences of using them. If you decide to access any of the third party websites linked to Dillivry.com or Dillivry App, you do so entirely at your own risk.</li> 

                        <li> If you would like to link to Dillivry.com or Dillivry App, you may only do so on the basis that you link to, but do not replicate, the home page of the Site or App, and subject to the following conditions: </li>
                            <ul className="termlist3 ">
                                <li>you do not remove, distort or otherwise alter the size or appearance of any logos used by us on Dillivry.com or Dillivry App; </li>

                                <li>you do not create a frame or any other browser or border environment around the Dillivry.com or Dillivry App;</li> 

                                <li>you do not in any way imply that we are endorsing any products or services other than our own; </li>

                                <li>you do not misrepresent your relationship with us nor present any other false information about it; </li>

                                <li>you do not otherwise use any trademarks owned by Dillivry or App (whether these are registered or unregistered) which are displayed on the Site or App without our express written permission; </li>

                                <li>you do not link from a website that is not owned by you; and </li>

                                <li>your website does not contain content that we, in our sole discretion, consider to be distasteful, offensive or controversial, in infringement of any intellectual property rights or other rights of any other person or which doesn't otherwise comply with all applicable laws and regulations.</li> 
                            </ul>

                        <li>We expressly reserve the right to revoke the right granted in clause 8.2 for  breach of the Conditions and to take any action that we deem appropriate. </li>

                        <li> You shall fully indemnify us for any loss or damage that we may suffer for breach of clause 8.2.</li>  
                    </ul>
                </p>
                <div className="headlings">9. Domain Name Protection   </div>
                <p>
                You agree not to register any domain name that includes the word 'Dillivry'; infers it is connected to us or in any way casts aspersions on us. 
                </p>
                <div className="headlings">10. General   </div>
                <p>
                    <ul className="termlist ">
                        <li>Unless otherwise specified, the Site is directed solely at those who access it from Nigeria. Should you choose to access the Site from locations outside Nigeria then you will be responsible for compliance with local laws if and to the extent local laws are applicable.</li> 

                        <li>You warrant that it is legal for you to view the Site in the jurisdiction to which you are subject. You are responsible for compliance with all laws of that jurisdiction, in viewing or using its contents. </li>

                        <li>A failure or delay in enforcing compliance with one or more provisions of these Conditions shall not constitute a waiver of any other provision of these Conditions. </li>

                        <li>No provision of the Conditions shall be enforceable under the Contracts (Rights of Third Parties) Act by any third party. </li>

                        <li>If any provisions of the Conditions shall be unlawful, void or for any reason unenforceable then that provision shall be deemed severable and shall not affect the validity and enforceability of the remaining provisions. </li>

                        <li>The Conditions constitute the entire agreement between ourselves as to your use of the Site or App and shall supersede any prior agreement or representation in respect thereof. </li>

                        <li>Any and all notices to be given by either one of us to the other pursuant to or in connection with the Conditions shall be deemed sufficiently given when forwarded by e-mail or facsimile transmission in each case addressed to you at any e-mail address or facsimile number you have given us or to us at the e-mail address or facsimile number displayed at the start of these General Conditions.</li> 

                        <li>The Conditions are governed by the laws of Nigeria and you agree to submit to the exclusive jurisdiction of Nigerian courts.</li> 
                    </ul>
                </p>


                <h2>PART B - SUPPLEMENTAL CONDITIONS </h2>
                <p>
                Please note that these terms and conditions are supplemental to the General Conditions. The Conditions (Parts A and B) form the basis of the agreement that you enter into with us in order to Register and become a Member (the <b>"Agreement"</b>) and will govern your conduct as a Member. 
                </p>
               
                <div className="headlings">1. 	Registration </div>
                <p>
                    <ul className='termlist4'>
                        <li>Only those in a position to form legally binding contracts under Nigerian law may register. Registration is not open to persons aged under 18 or to any persons whose Membership has been suspended or terminated. If you are registering as a business entity, you represent that you have the authority to bind the entity to this Agreement.</li>

                        <li>Each Registration is for a single user only. We do not permit you to share your user name and password with any other person nor with multiple users on a network. Furthermore, your Membership may not be transferred to a third party.</li>

                        <li>Responsibility for the security of any passwords issued rests with you and you are responsible for all actions taken with your username and password. </li>
                    </ul>
                </p>
                <div className="headlings">2.  Our Services - Neutral and Digital Logistic Platform   </div>
                <p>
                    <ul className='termlist4'>
                        <li>We are not a logistic service provider, freight forwarder, broker or intermediary of any sort. Dillivry.com or Dillivry App acts as a neutral platform through which Logistic Providers and Users/Senders/Listers can meet and enter into negotiations with a view to entering into Transactions. We are in no way involved in the actual Transaction. We have no, and assume no, control over the quality, safety, or legal aspects of the Jobs and/or Transactions that take place through Dillivry.com or Dillivry App.  </li> 
                        <li>If you are a Logistic Provider you hereby agree that Dillivry.com or Dillivry App is for shipping/moving/haulage/logistic related services only and that work that you provide will only involve services. Except with our prior written agreement, you agree that you will not use your Membership as a means to market or sell any goods, products or other services unrelated to the Job. </li>
                        <li>It is the responsibility of the User/Sender/Lister to select a suitable Logistic Provider for the provision of the Job and the responsibility of the User/Sender/Lister and the Logistic Provider to negotiate the terms of the Transaction. </li>
                    </ul>
                </p>
                <div className="headlings">3. 	General Disclaimer  </div>
                <p>
                    <ul className='termlist4'>
                        <li>We are not a logistic service provider, freight forwarder, broker or intermediary of any sort. Dillivry.com or Dillivry App acts as a neutral platform through which Logistic Providers and Users/Senders/Listers can meet and enter into negotiations with a view to entering into Transactions. We are in no way involved in the actual Transaction. We have no, and assume no, control over the quality, safety, or legal aspects of the Jobs and/or Transactions that take place through Dillivry.com or Dillivry App. </li>

                        <li>If you are a Logistic Provider you hereby agree that Dillivry.com or Dillivry App is for shipping/moving/haulage/logistic related services only and that work that you provide will only involve services. Except with our prior written agreement, you agree that you will not use your Membership as a means to market or sell any goods, products or other services unrelated to the Job. </li>

                        <li>It is the responsibility of the User/Sender/Lister to select a suitable Logistic Provider for the provision of the Job and the responsibility of the User/Sender/Lister and the Logistic Provider to negotiate the terms of the Transaction. </li>
                    </ul>
                </p>
                <div className="headlings">4.  Unlawful Transactions  </div>
                <p>
                     <ul className='termlist4'>
                        <li>Members shall not use Dillivry.com or Dillivry App for unlawful activities. Any Job or aspect of a Transaction carried out, or sought to be carried out unlawfully, by either Logistic Provider or User/Sender/Lister, is prohibited.</li>
                        <li>Certain Transactions may be governed by statutory or other regulations and cannot therefore be negotiated freely. It is for the Members to identify and adhere to such regulations.</li> 
                    </ul>
                </p>
                <div className="headlings">5. 	Trasportation/Conveyance/Movement  </div>
                <p>
                    <ul className='termlist4'>
                        <li>The posting of Jobs/items for conveyance of certain items are prohibited or restricted. These include hazardous or dangerous goods. Additionally there are certain items that do not generally pose a danger to health, safety, or property while being transported but are regulated or banned for public policy reasons from being transported. Generally, Jobs/items for movement of goods that cannot be sent through Mail (excluding living creatures) are not permitted to be listed on the Site. However some hazardous goods can lawfully be transported provided they are properly packaged and labelled. Such Jobs/items may be listed on the Site provided that the shipment listing contains a clear notice of the hazardous nature of the material and a description of the planned method of shipping that complies with the law. If you are unsure of the legality of your shipment, we recommend you contact our customer care team via the contact information details at the end of these Supplemental Conditions, prior to listing the Job. A member of our team will be able to refer you to the appropriate authority to answer your question. </li>

                        <li>Full responsibility for compliance with all laws and regulations that relate to the transportation of hazardous and restricted goods rests with the parties to the Transaction. Anyone who sends, or causes to be sent, a prohibited or restricted item or improperly packaged hazardous material may be subject to severe regulatory, or indeed criminal penalties. </li>

                        <li>In order to assist Members we provide distance calculations and driving directions (or links to 3rd party driving directions) as part of our service (the "Directions''). However the Directions should be used as a guide only and you should always drive in accordance with national rules, regulations, and highway codes. The Directions may not take into consideration issues such as road closures, direction of traffic, one-way systems, emergency access roads, height regulations, road conditions or restrictions on maneuverability. No representation is made or warranty given as to the content or usability of the Directions or their appropriateness for any particular journey or vehicle. We assume no responsibility for any loss, damage, or delay however resulting from use of these Directions. However, if you do find an error or omission, please let us know so we can notify the relevant persons to enable them to correct the error or omission and improve the service. We recommend that you verify any pickup and delivery information that we provide with the other party to the Transaction. </li>
                    </ul>
                </p>
                <div className="headlings">6. 	Fees </div>
                <p>
                    <ul className='termlist4'>
                        <li>Dillivry is free to join either as a logistic provider or user/lister/viewQuote and it is free to request quotes. We charge a success fee (the "Fee") to Logistic Providers who have secured a Job ("Successful Logistic Providers'”). A Job will be considered to be secured if after quoting/bidding, messaging or contacting the User/Lister/Sender via Dillivry.com or Dillivry App a Logistic Provider is paid the Deposit (as defined hereafter). </li>

                        <li>When you become a Member you will be allocated an account into which Deposits will be paid and from which Fees (in each case as defined hereafter) will be deducted by us in accordance with this clause 6 (the "Account")</li> 

                        <li>The Fee is based upon a tiered percentage of the Successful Logistic Provider's accepted quote price. The Fee is dynamically calculated at the time of placing a quote and differs category by category. Logistic Provider’s may quickly and easily view the Fee amount charged on a Job by visiting their sales report on their dashboard.</li> 

                        <li>The incurring of the Fee is independent of the actual performance of the Job. Subject to clause 7, the obligation to pay the Fee therefore still applies if a Job is placed via Dillivry.com or Dillivry App but is not subsequently carried out and/or the Transaction is later terminated. We may waive our right to receive the Fee as a gesture of goodwill; however the grant of any such waiver is entirely within our discretion and if so granted is always without accepting any legal obligation to do so.</li> 

                        <li>As a User/Sender/Lister, when you accept a quote, you will pay a deposit in the amount of the Fee (the "Deposit") into your Account which is immediately transferred to the Successful Logistic Provider's Account from which we then debit the sum as payment of the Fee.</li> 

                        <li>If you are a Logistic Provider you are responsible for collecting and remitting any and all VAT associated with a Transaction. Your quote amount for the Job is inclusive of VAT in accordance with clause 12.1.2. You shall not submit a quote amount excluding VAT and then charge VAT on top of your quote price once your quote has been accepted. </li>

                        <li>We reserve the right to waive or change the basis for the calculation of the Fee at any time. We may also temporarily change or suspend the Fee for a promotional period. Any such changes will be described on the Site.</li>
                    </ul>
                </p>
                <div className="headlings">7.  Cancellations </div>
                <p>
                    <ul className='termlist4'>
                        <li>If a Job is cancelled by a Successful Logistic Provider AFTER a quote was accepted but BEFORE any work on the Job commenced (a "Cancellation") the user/lister/viewQuote may seek to file a "Cancellation Request" and have the Deposit returned to them either as Credit to use on future Dillivry deposit payments or a direct Refund to the funding payment method used. A cancellation request must be filed within 28 days of quote acceptance, requests made after this time will be invalid. If a cancellation request is accepted (see 7.x clauses below), the user/lister/viewQuote can choose to receive either a Credit or Refund and this will be issued in the amount of the Deposit already paid. </li>

                        <li>Credit may be used to accept future quotes on Dillivry within 1 month of the "Cancellation Request" date. After a period of 1 month from the date of the "Cancellation Request", this credit will expire and be unusable. Dillivry credit is non with-drawable / refundable and may only be used for accepting quotes on Dillivry. </li>

                        <li>Refunds of Deposits will be made within 5 working days of the "Cancellation Request" being accepted. Refunds can only be sent to the same source from which the funds originated.</li> 

                        <li>Users/Senders/Listers and Successful Logistic Providers will negotiate directly once there has been a successful quote. Either party can make a Cancellation Request; however it should only make once all reasonable attempts to reach an agreeable solution have been exhausted and it is clear that the Job will not be performed. You may not make a Cancellation Request until at least 7 days after the date on which the quote was originally accepted. When you make a Cancellation Request the other party to the proposed Transaction will be notified and will have 72 hours to respond by either: </li>

                        <li>accepting the Cancellation Request and your reason; or </li>

                        <li>rejecting the Cancellation Request and requesting review by our staff.</li> 

                        <li>If your Cancellation Request is accepted by the other party the accepted quote can be retracted and the Job/Item can either be relisted or deleted. If the request is rejected by the other party, then it is sent to us for review and a decision will be made on whether to approve or not to approve the Cancellation Request. Once we have given our verdict on whether to approve the Cancellation Request we will not enter into any further correspondence - our decision is final.</li> 

                        <li>If the other party does not respond within the 72 hour grace period, your Cancellation Request and reason will automatically be accepted. Depending on the reasons for the Cancellation Request, any amounts associated with the Deposit or the Fee may be credited to your Dillivry account at our sole discretion and in line with clause 7.1. </li>

                        <li>We take abuse of the procedure for Cancellation Requests very seriously. Abuse includes submitting false or exaggerated reasons, attempting to retract accepted quotes, or colluding with other Members to abuse the procedure. If a Member is found abusing or is suspected of abusing the procedure in this clause 7 his Cancellation Request will automatically be denied and his Membership may be revoked without further notice and/or he may also be subject to additional fees.</li>
                    </ul>
                </p>
                <div className="headlings">8. Feedback   </div>
                <p>
                    <ul className='termlist4'>
                        <li>Whilst we are unable ourselves to conduct any due diligence on our Members, we do enable Users/Senders/Listers and Successful Logistic Providers to provide feedback evaluations on each other's performance in the course of the Transaction including but not limited to the performance of the Job (or, as the case may be, their failure to enter into a Transaction and/or perform the Job). The evaluations seek to provide Members with guidance on the skills and/or reliability of other Members prior to quoting or, in the case of Users/Senders/Listers accepting a quote from a Logistic Provider. </li>

                        <li>The feedback process involves leaving a rating along with a short comment about the performance of the Member. There are three feedback scores that you can give: +1 (positive,) 0 (neutral), and -1 (negative). A Member's overall feedback score is the average score of the ratings that he or she has received.</li> 

                        <li>You accept that by posting a Job or by quoting/bidding on a Job it is probable that another Member will leave feedback about you and you acknowledge that your feedback consists solely of comments left by other Members and the overall feedback score calculated in accordance with clause 8.2. You agree that you will not use any feedback received on the Dillivry.com or Dillivry App on any venue or website that is not the Site. Do always use common sense and respect when leaving feedback on another Member as unwarranted, potentially libellous feedback could result in legal claims being made against you. We do not censor feedback or investigate it for accuracy, and you acknowledge that we are in no way legally responsible for any feedback that is left.</li> 

                        <li>Any use of profanity, libellous behaviour, illegal activity, or any other form of abuse of the feedback function or any other part of the Site may result in a ban from further use of the Site and, if applicable, being reported to any relevant authorities. </li>
                    </ul>
                </p>
                <div className="headlings">9.  No Agency   </div>
                <p>    
                Nothing herein shall create or be deemed to create any joint venture, agency or partnership relationship between ourselves and any Members whatsoever. Members shall not hold themselves out as implying any such relationship with us.
                </p>
                <div className="headlings">10.  Account Flagging / Reporting System  </div>
                <p>
                The Site is policed by the visitors to it through a flagging system. The flagging system is used to ensure that insofar as possible the Site complies with the Conditions. Visitors are able to 'flag' aspects of the Site that do not 
                comply with the Conditions. White flags are located in areas that can be reported. These include: messages, the quote details section, member profiles, and shipment listing information. To flag any part of the text you may click on the white flag following the text in question and the white flag will turn yellow.
                A yellow flag is sent to our team for review. Following the review, the team will either grant a 'green flag', meaning there was no violation, or a 'red flag', meaning there was a violation and the content has been removed. We reserve the right to suspend the Membership of any Member who has received a red flag on more than one occasion. 
                </p>
                <div className="headlings">11.  Information You Submit  </div>
                <p>
                    <ul className='termlist4'>
                        <li>You solely are responsible for any information You provide to us or other users in the registration, shipping, logistic process, or any other use of our Services. Your personal information and any delivery listings must be true, legal, accurate, and non-fraudulent. You authorize Dillivry to use the information You supply to us in connection with our Services and in accordance with this Agreement. You acknowledge and agree that our Services act as a passive conduit for any and all communication and/or distribution of information.</li> 

                        <li>Other than personally identifiable information, which is covered under our Privacy Policy Page any Information shall be considered non-confidential and non-proprietary. We shall have no obligations with respect to such material. We shall be free to copy, disclose, distribute, incorporate and otherwise use the Information and all data, images, sounds, text and other things embodied therein for any and all commercial or non-commercial purposes.</li> 

                        <li>You agree to use common sense and good judgment when conducting or posting any Information. We insist that all Members abide by netiquette and communicate with each other in a respectful manner. </li>
                    </ul>
                </p>
                <div className="headlings">12.  Fraud </div>
                <p>
                    An important aspect of Dillivry.com or Dillivry App is the ability of Members to leave feedback about each other (whether positive, neutral or negative). As described in clause 8, the feedback you receive is likely to influence the choices that other Members make as to whether to negotiate a Transaction with you. Accordingly, to preserve the integrity of the feedback system, you agree not to:
                    <ul className='termlist4'>

                       

                        <li>improperly influence in any manner, or cause another to improperly influence in any manner, the feedback of a Member; or </li>

                        <li>post or attempt to post, in any manner or by any means, a feedback review on your own account.</li>
                    </ul>
                </p>
                <div className="headlings">13.  Right to Suspend or Remove Users   </div>
                <p>
                    <ul className='termlist4'>
                        <li>We reserve the right to reject your Registration or cancel your Membership at any time and for any reason or for no reason and without notice to you. </li>

                        <li>We reserve the right to notify other Members of any actions that we, in our sole discretion deem serious, and which have led to the cancellation of your Membership. </li>

                        <li>If you are so removed or rejected you may appeal for reinstatement. Your appeal must include a written statement as to why you should be reinstated along with your contact information. Your appeal may be reviewed at our discretion and any determination as to your reinstatement will be at our sole discretion. Your submission of an appeal does not, in any manner, guarantee that you will be reinstated or that the appeal will necessarily be reviewed. We will contact you as to our decision to reinstate you. We are not obligated to give you any reasoning as to our decision. All decisions are final.</li> 
                    </ul>
                </p>
                <div className="headlings">14.  Confidentiality/Non-Disclosure  </div>
                <p>
                During the course of the performance of this Agreement and whether due to any intentional or negligent act or omission, we may disclose to You or You may otherwise learn of or discover, our documents, business practices, object code, source code, management styles, day-to-day business operations, capabilities, systems, current and future strategies, marketing information, financial information, software, technologies, processes, procedures, methods and applications, or other aspects of our business ("Our Information"). You hereby agree and acknowledge that any and all of Our Information is confidential and shall be our sole and exclusive intellectual property and proprietary information. You agree to use Our Information only for the specific purposes as allowed by the performance of this Agreement. Any disclosure of Our Information to a third party specifically including a direct competitor is strictly prohibited. All obligations contained in this Section shall survive the termination of this Agreement. Furthermore, You acknowledge that our information is proprietary, confidential, and extremely valuable to us, and that we would be materially damaged by your disclosure of Our Information. You acknowledge and agree that monetary damages provide an insufficient remedy for the breach of this confidentiality obligation, and that we shall be entitled to injunctive relief in addition to recovery for damages. 
                </p>
                <div className="headlings">15.  Non-Solicitation  </div>
                <p>
                You agree that you shall not acquire the word "Dillivry" or any variant that includes the word “Dillivry" or may be confused with it on Google Adwords or any similar internet advertising service. 
                </p>
                
                <div className="headlings">17.  No Convictions   </div>
                <p>
                You represent that you, and any individuals associated with you who will be involved in a Transaction have not been convicted of a crime which is related in any way to the shipping and/or logistic or haulage business. Furthermore, you warrant and represent that there are currently no legal proceedings instituted against you that would prevent you from performing a Transaction to which you are a party or your obligations under the Conditions. 
                </p>
                <div className="headlings">18.  Other Terms and Conditions   </div>
                <p>
                You acknowledge and agree that we have the sole discretion to set forth and post additional terms and conditions for your use of the Dillivry Services. You agree that any additional terms and conditions that apply to your use of our Services shall be considered an effective amendment to this Agreement and said terms and conditions shall be incorporated herein. Furthermore, You expressly agree that if there is any conflict between those additional terms and conditions and the specific terms and conditions set forth herein, the terms and conditions set forth in those additional terms shall govern. 
                </p>
            </div>
              </div>
            )
            :
            null
        }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClick} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
