import React from 'react'

const CountDown = ({time, setTime}) => {

    const tick = () => {
        setTime(time - 1)
    };
    
    React.useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => {
            clearInterval(timerId);
        };
    });
    
    return (
        <>
        
        </>
            
    );
}

export default CountDown;