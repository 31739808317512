import {createContext, useReducer, useState} from 'react';
import dashboardReducer, { INITIAL_STATE } from './dashboard.reducer';
import dashboardTypes from './dashboard.types';

export const DashboardContext = createContext({
    ...INITIAL_STATE
  });


const DashboardProvider = ({ children }) => {
    const [store, dispatch] = useReducer(dashboardReducer, INITIAL_STATE);
    const { dashboards } = store;
    const [filterstatus, setfilterstatus] = useState('all')
    const [page, setpage] = useState('')

    const updateDashboard = dash => {
        dispatch({
          type: dashboardTypes.GET_DEFAULT_SEARCH_DATA,
          payload: dash
        });
      };

      const assignAgent = agent => {
        dispatch({
          type: dashboardTypes.ASSIGN_AGENT,
          payload: agent
        });
      };


      const re_assignAgent = agent => {
        dispatch({
          type: dashboardTypes.REASSIGN_AGENT,
          payload: agent
        });
      };

      const re_assign_pickedup = agent => {
        dispatch({
          type: dashboardTypes.REASSIGN_PICKEDUP,
          payload: agent
        });
      };

      const re_assign_out = agent => {
        dispatch({
          type: dashboardTypes.REASSIGN_OUT,
          payload: agent
        });
      };
      
      const re_assign_missed = agent => {
        dispatch({
          type: dashboardTypes.REASSIGN_MISSED,
          payload: agent
        });
      };
      
      const update_dm_conversation = new_msg => {
        dispatch({
          type: dashboardTypes.UPDATE_DM_MSG,
          payload: new_msg
        });
      };

      const update_conversation = new_msg => {
        dispatch({
          type: dashboardTypes.UPDATE_MSG,
          payload: new_msg
        });
      };

      const update_mean_of_delivery = means => {
        dispatch({
          type: dashboardTypes.UPDATE_MEANS,
          payload: means
        });
      };

      const update_cop_logo = means => {
        dispatch({
          type: dashboardTypes.UPDATE_LOGO,
          payload: means
        });
      };

      const update_balc_withdraw = withdrawn => {
        dispatch({
          type: dashboardTypes.UPDATE_BALC_WITHDRAW,
          payload: withdrawn
        });
      };
  



      return (
        <DashboardContext.Provider value={{
            updateDashboard,
            assignAgent,
            re_assignAgent,
            re_assign_pickedup,
            re_assign_out,
            re_assign_missed,
            update_dm_conversation,
            update_conversation,
            update_mean_of_delivery,
            update_cop_logo,
            update_balc_withdraw,
            setfilterstatus,
            setpage,
            page,
            dashboards,
            filterstatus
        }}>
          {children}
        </DashboardContext.Provider>
      );
}
export default DashboardProvider;