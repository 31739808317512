
import React, {useState} from 'react'
import {MdLocationOn, MdOutlineLocationOn} from "react-icons/md"
import {FormControl, InputLabel} from '@material-ui/core';
import './GooglePlaceAutocomplete.css';
import PlacesAutocomplete from 'react-places-autocomplete';
import InputField from './InputField'
import { makeStyles } from '@material-ui/core/styles';
import { BsInfoCircle } from 'react-icons/bs';
import { geocodeByAddress } from "react-places-autocomplete";
import data from '../countries.json'

function GooglePlaceAutocomplete({setdeli_locatn_frm_pickup_add, handleChange,  pickup,   handleSelect, value,text, color, error}) {
    const useStyles = makeStyles((theme) => ({
        formControl: {
          minWidth: `100%`,
        },
      }));
    const classes = useStyles();
    
    let country =  data.Countries.map(cont=>cont.CountryName)

    const handleGetCountryCode = (addr) =>{
       let res = geocodeByAddress(addr)
        res.then(re=>{
           let add_country = re[0].address_components.filter((res) => country.includes(res.long_name))
           setdeli_locatn_frm_pickup_add(add_country[0].short_name.toLowerCase())
        })
    }
    return (
        <div>

            <PlacesAutocomplete
                value={value}
                onChange={handleChange}
                onSelect={(addr)=>{
                    handleSelect(addr) 
                    handleGetCountryCode(addr)
                }}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <FormControl className={classes.formControl} variant="outlined">
                        <InputField 
                            label = {text}
                            {...getInputProps({
                                labelWidth : 155
                            })}
                            error={error}
                            endIcon={<MdOutlineLocationOn style={{ marginRight:"5px", color: color, fontSize: '18px'}}/>}
                            />
                    </FormControl> 
                    <div className="ggg">
                    {loading && <div>Loading...</div>}
                    {suggestions.map(suggestion => {
                        const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                        ? { backgroundColor: '#000000', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                        return (
                        <div
                            {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                            })}>
                            <div className="suggestion">{suggestion.description}</div>
                        </div>
                        );
                    })}
                    </div>
                    {
                        error && suggestions.length === 0 ? 
                        <div style={{backgroundColor:'var(--lighest)', color:'var(--dark)',fontSize:'11px',fontWeight:'500', padding:'10px', display:'flex', alignItems:'center'}}> 
                            <span style={{marginRight:'10px'}}>
                                <BsInfoCircle/>
                            </span> 
                            <span>
                            {
                                pickup ? "Sorry the pickup address is not found in Google Map. Please confirm and enter the right address or closer bus stop/landmark address found in Google Map" : "Sorry the delivery address is not found in Google Map. Please confirm and enter the right address or closer bus stop/landmark address found in Google Map"
                            }   
                            </span>
                            
                            </div>
                        :
                        null
                    }
                    
                </div>
                )}
            </PlacesAutocomplete>
        </div>
    )
}

export default GooglePlaceAutocomplete
