import React,{useState, useEffect, useContext} from 'react';
import Checkout from './Checkout';
import NavbarCart from "./NavbarCart";
import "./Checkout.css"
import axios from "axios";
import SecureLS from "secure-ls";
import { SenderContext } from '../../../context/SenderDashboard/SenderDashboardContext';
import { ConnContext } from '../../../context/ConnContext';
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@material-ui/core";
import {useLocation} from 'react-router-dom'
import SenderSideBar from '../components/SenderSideBar';
import Swal from 'sweetalert2';
import GlobalizationLanguage, {
    GlobalizationLanguagewithCOntryCode
  } from '../../../components/GlobalizationLanguage';
  import Cookies from "js-cookie";

const Cart = ({history}) => {
    const sendersettings=GlobalizationLanguage("/sendersettings")
    const sendersettlement=GlobalizationLanguage("/sendersettlement")
    const senderapi=GlobalizationLanguage("/senderapi")
    const team=GlobalizationLanguage("/team")
    var sendermessage = GlobalizationLanguage("/sendermessage")
    var viewQuote = GlobalizationLanguage("/viewQuote")
    var viewQuote_request_delivery = GlobalizationLanguage("/viewQuote/request-a-delivery")

    const location = useLocation()
    const endpoint = process.env.REACT_APP_API;

    const { initialstate, UpdateData, UpdateDatamain, UpdateAlldata,  Cartdata, setCartdata, UpdateCartData, alldata } = useContext(SenderContext);

    const ls = new SecureLS({isCompression: true,encodingType: "rabbit",encryptionSecret: "dm_dillivry"});
  
    const {visitingCountryCode} = useContext(ConnContext)

    const [loading, setloading] = useState(false)

    const [MyCart, setMyCart] = useState([])

    const [ID, setID] = useState([])

    const [filterbypayment, setFilterbyPayment] = useState([])

    const [itemLength, setitemLength] = useState(0)
    var countryvalue =
    Cookies.get("i18next") || visitingCountryCode.toLowerCase();
  const [country, setCountry] = useState(countryvalue);

  useEffect(() => {
    setCountry(Cookies.get("i18next"));
  }, [Cookies.get("i18next")]);

      const handleChange = (e) => {
        
        if(ID.filter((d)=> d.delivery_id === e.delivery_id)){
            const itemIndex = ID.findIndex( item=> item.delivery_id === e.delivery_id);
            if(itemIndex === -1){
                setID([...ID, e])
                setitemLength([...ID, e].length)
            }
            else{
                ID.splice(itemIndex,1)
                setitemLength(ID.length)
            }
        }
    }




      const handleChange_disable = () => {
        Swal.fire({
            icon: "info",
            text: "You cannot checkout this item because provider is yet to acknowledge",
            timer: 5000,
            showConfirmButton: false,
        })
      };
      const handleChangePayment_disable = () => {
        Swal.fire({
            icon: "info",
            text: "You cannot checkout this item because the mode of payment is cash",
            timer: 5000,
            showConfirmButton: false,
        })
      };

      const CheckSelected = (data)=>{

        //  const walletcheck = data?.filter(item => item.mode_of_payment === "pay_by_wallet");
        //  const transfercheck = data?.filter(item => item.mode_of_payment === "pay_by_transfer");
        //  const cashcheck = data?.filter(item => item.mode_of_payment === "pay_by_cash");

         const ng = data?.filter(item => item?.delivery_location === "ng");
         const gb = data?.filter(item => item?.delivery_location === "gb");
    
        // if (walletcheck?.length >= 1 && transfercheck?.length >= 1) {
        //     Swal.fire({
        //         icon: "info",
        //         text: "please select same mode of payment",
        //         timer: 5000,
        //         showConfirmButton: false,
        //     })
        // }
        if (ng?.length >= 1 && gb?.length >= 1) {
            Swal.fire({
                icon: "info",
                text: "You cannot checkout on items in different countries",
                confirmButtonColor: 'var(--main)'
            })
        }
        else{
            history.push({
             pathname: GlobalizationLanguagewithCOntryCode(
             `/viewQuote/checkout`,
           country,
           visitingCountryCode
          ),
         state: ID
        })
        }
     }

    useEffect(() => {
        if(location.state === 'from_cart'){
            window.location.reload();
            history.replace({ state: {} })
        }
        else{
            setloading(true)
            if (initialstate !== undefined) {
                const InfoData = initialstate?.data?.data?.deliveries ?  initialstate?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info) : initialstate?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)
                const data = initialstate?.map((x) => Object.values(x?.account?.account_info?.cart_history?.deliveries)?.flat())?.flat()
                // console.log(data, "cart datadata")
                setMyCart(data.sort((a, b)=> new Date(b.time_stamp) - new Date(a.time_stamp)))
            setloading(false)
            }
            else{
            setloading(true)
            axios.get(`${endpoint}m-dillivry-sender-api`, {
                headers: {
                  Authorization: ls.get("_utk"),
                },
            })
                .then((res) => {
                    // console.log(res?.data?.data?.map((x) => x?.ng ?? x?.gb).map((x) => Object.values(x?.account?.account_info?.cart_history?.deliveries)?.flat())?.flat())
                UpdateDatamain(res.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat())
                //  UpdateData(res);
                UpdateData(res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());
                 UpdateAlldata(res)
                UpdateCartData(res?.data?.data?.map((x) => x?.ng ?? x?.gb).map((x) => Object.values(x?.account?.account_info?.cart_history?.deliveries)?.flat())?.flat())
                setloading(false)
                const data = res?.data?.data?.map((x) => x?.ng ?? x?.gb).map((x) => Object.values(x?.account?.account_info?.cart_history?.deliveries)?.flat())?.flat()
                setMyCart(data)
                // console.log(data, "cart datadata")
                setloading(false)
                })
                .catch((err)=>{
                console.log(err)
                setloading(false)
                })
            }
        }
      }, [])

      const links =  [
        {
            path: viewQuote,
            name: "Quotes",
            state:'inactive'
        },
       
        {
            path: sendermessage,
            name: "Message",
            state:'inactive'
        },
        
        // {
        //     path: senderapi,
        //     name: "API",
        //     state:'inactive'
        // },
        // {
        //     path: team,
        //     name: "Team",
        //     state:'inactive'
        // },
        // {
        //     path: sendersettlement,
        //     name: "Settlement",
        //     state:'inactive'
        // },
        {
          path: sendersettings,
          name: "Settings",
          state:'inactive'
        }
      ]


    return (
        <div>
            <div className="dsktopscreen">
                <NavbarCart backToText="Back to dashboard" backTo={() => history.push(viewQuote)} />
            </div>
            {/* } */}
           
            <div className="mobilescreen">
                <SenderSideBar pageTitle="Cart" links={links} />
            </div>

            <div className="cart-cont">
                {
                    loading ?
                        <div
                            style={{
                                width: "100%",
                                height: "100vh",
                                backgroundColor: "none",
                                zIndex: "300",
                                position: "fixed",
                                top: "0px",
                            }}
                        >
                            <Box sx={{ width: "100%" }}>
                                {/* <h1>loading...</h1> */}
                                <LinearProgress color="success" />
                            </Box>
                        </div>
                        :


                        <div className="cart-cards">
                            
                            <div style={{ textAlign: 'center', color: 'var(--main)', fontWeight: '500', fontSize: '24px', marginBottom: '20px' }}>Cart</div>
                            {
                                MyCart?.length === 0 ? 
                                <div style={{textAlign:'center', padding:"30px"}}> ... Cart is empty ...</div>
                                :
                                <>
                                {
                                    MyCart?.map((x, i) => {
                                        return (
                                            <div key={i}>
                                                <Checkout
                                                    checkbox={
                                                    <>
                                                        <label className="check_container" onChange={() => handleChange(x)}>
                                                            <input type="checkbox"/>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </>
                                                    }

                                                    checkboxDisabled={
                                                    <>
                                                        <label className="check_container_disable" onClick={() => handleChange_disable(x)}>
                                                            <span className="checkmark_disable"></span>
                                                        </label>
                                                    </>
                                                    }
                                                    checkboxPaymentDisabled={
                                                        <>
                                                            <label className="check_container_disable" onClick={() => handleChangePayment_disable(x)}>
                                                                <span className="checkmark_disable"></span>
                                                            </label>
                                                        </>
                                                        }

                                                    image={x?.image_urls[0]}
                                                    amount="Quote amount:"
                                                    actamount={x?.quote_amount}
                                                    paymentStatus={x?.payment_type}
                                                    itemDesc={x?.item_desc}
                                                    itemLocation={x?.pickup_address}
                                                    itemDestination={x?.delivery_address}
                                                    distance={x?.delivery_id}
                                                    deliveryStatus={x?.delivery_status}
                                                    acknowledgeStatus={x?.ack_status}
                                                    timeframe={x?.time_stamp}
                                                    timelisted={x?.date_listed}
                                                    deliverymode={x?.mode_of_delivery}
                                                    payer={x?.payer}
                                                    modeofpayment={x?.mode_of_payment}
                                                    location={x?.delivery_location}
                                                />
                                            </div>
                                        )
                                    })
                                }
                                <div className="checkout-btn" >
                                    {
                                        itemLength > 0 ?
                                        <button type='button'
                                            onClick={()=> CheckSelected(ID)
                                        }
                                            style={{ textAlign: 'center', userSelect: 'none', cursor: 'pointer', padding: '12px 50px', backgroundColor: 'var(--main)', color: 'white', borderRadius: '3px', border: 0 }}>
                                            Proceed to checkout
                                        </button>
                                        : 
                                        <button type='button'
                                            style={{ textAlign: 'center', userSelect: 'none', cursor: 'pointer', padding: '12px 50px', backgroundColor: '#B0AFB0', color: 'white', borderRadius: '3px', border: 0 }}>
                                            Proceed to checkout
                                        </button>
                                    }
                                </div>
                            </>

                            }
                        </div>
                }
            </div>
        </div>
    );
}

export default Cart;
