import React, { useState, useEffect, useContext, useRef } from "react";
import carticon from '../../../../assets/carticon.svg'
import {useHistory} from 'react-router-dom';
import SecureLS from "secure-ls";
import DialogOutClick from "../../../../components/DialogOutClick";
import { AuthContext } from "../../../../context/AuthContext";
import { BsArrowLeft } from 'react-icons/bs';
import Cookies from "js-cookie";
import GlobalizationLanguage, {GlobalizationLanguagewithCOntryCode} from '../../../../components/GlobalizationLanguage'
import { ConnContext } from "../../../../context/ConnContext";


export default function AccountNavbar() {

  const history = useHistory()

  const auth = useContext(AuthContext);
  const [ShowLogout, setShowLogout] = useState(false)
  var countryvalue =
    Cookies.get("i18next") || visitingCountryCode.toLowerCase();
  const [country, setCountry] = useState(countryvalue);


  useEffect(() => {
    setCountry(Cookies.get("i18next"));
  }, [Cookies.get("i18next")]);

  const { visitingCountryCode } = useContext(ConnContext);

   const logMeOut = () => {
    auth.logout();
    history.push("/");
  };

  return (
    <>
      <div  style={{height:"70px", display:"flex" , justifyContent:"space-between"}}>
      <p 
        className="backaccount"
        style={{paddingTop:"20px" ,paddingLeft:"30px" , display:"flex",  cursor:"pointer",backgroundColor:"#E0FCE7", width:"320px"}} 
        onClick={()=> 
          history.push(
            GlobalizationLanguagewithCOntryCode(
              `/Dashboard`,
              country,
              visitingCountryCode
            )
          )}> 
          <span style={{marginRight:"10px"}} >
          <BsArrowLeft fontSize={15} /> </span><span style={{ fontSize:"18px"}}>Back to dashboard</span> 
        </p>
     
     
        <div
          style={{padding:"20px" , cursor:"pointer"}}
            onClick={() => {
              history.push("/Messages");
            }}
          >
            <img
              src={carticon}
              className="icon-question"
              alt="MessageIcon"
            />
          </div>
    
      </div>

      <DialogOutClick
        open={ShowLogout}
        handleClose={() => setShowLogout(false)}
      >
        <div style={{ width: "100%", padding: "30px 30px 20px 30px" }}>
          <div
            style={{
              width: "100%",
              marginBottom: "30px",
              fontSize: "13px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>Are you sure you want to logout?</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="disBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => setShowLogout(false)}
            >
              No
            </div>
            <div
              className="ediBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "white",
                backgroundColor: "#808080",
                border: "1px solid #808080",
              }}
              onClick={logMeOut}
            >
              Yes
            </div>
          </div>
        </div>
      </DialogOutClick>
    </>
  );
}
