import React,{useEffect} from "react";
import "./AcknowledgeMobileCard.css";
import itemIcon from "../../../assets/itemdetails.png";
import locationIcons from "../../../assets/Vector1.png";
import destinationIcon from "../../../assets/Vector3.png";
import timeFrameIcon from "../../../assets/timeframe.png";
import distanceIcon from "../../../assets/distance.png";
import listedIcon from "../../../assets/timelisted.png";
import modeIcon from "../../../assets/car.png";
import moment from 'moment'
import CountDown from "../../../components/CountDown";

export default function AcknowledgeMobileCard({
  quote_amount,
  quoteCount,
  onClick,
  image,
  payer,
  best_quote,
  amount,
  actamount,
  paymentStatus,
  paymentTime,
  provider_name,
  itemDesc,
  itemLocation,
  itemDestination,
  distance,
  deliveryStatus,
  timeframe,
  timelisted,
  deliverymode,
  waiting,
  onWaitClick,
  onAcceptExistClick,
  clicked,
  items
}) {

  useEffect(() => {
    const thirty_min_after =  moment(new Date(items?.ack_req?.ack_req_time_stamp)).add(30, 'm')

    const d = new Date(thirty_min_after);
    
    let date = d.getDate();
    let month = d.getMonth();
    let year = d.getFullYear();
    let hour = d.getHours();
    let min = d.getMinutes();

    const waitingTimeInMinute = (moment([year, month, date, hour, min])).diff(moment())/1000
    
    setTime(Math.floor(waitingTimeInMinute))

  }, [])

  const [time, setTime] = React.useState("");

  return (
    <div className="ack-wrap">
      <div className="image-div">
          <img onClick={onClick} src={`${image}`} className="item-image" alt="item-img" />
      </div>

      <div className="main-flex">
        
      <div className="icon-title-">
        <div className="qouteinfo-left-right">
          <span onClick={onClick} className="icon-left-text-right">
            {" "}
            <span>
              {" "}
              <img alt="itemIcon" src={itemIcon} />{" "}
            </span>{" "}
            <span>{itemDesc}</span>{" "}
          </span>
          
        </div>

        <div className="qouteinfo-left-right">
          <span onClick={onClick} className="icon-left-text-right">
            {" "}
            <span>
              {" "}
              <img alt="locationIcons" src={locationIcons} />
            </span>{" "}
            <span>{itemLocation}</span>{" "}
          </span>
          
        </div>

        <div className="qouteinfo-left-right">
          <span onClick={onClick} className="icon-left-text-right">
            {" "}
            <span>
              <img alt="destinationIcon" src={destinationIcon} />
            </span>
            <span>{itemDestination}</span>{" "}
          </span>
         
        </div>

        <div className="qouteinfo-left-right">
          <span onClick={onClick} className="icon-left-text-right">
            {" "}
            <span>
              <img alt="distanceIcon" src={distanceIcon} />
            </span>{" "}
            <span> {distance}</span>{" "}
          </span>
         
        </div>

        <div className="qouteinfo-left-right">
          <span onClick={onClick} className="icon-left-text-right">
            <span>
              <img alt="timeFrameIcon" src={timeFrameIcon} />
            </span>{" "}
            <span>{timeframe}</span>{" "}
          </span>
        </div>

        <div className="qouteinfo-left-right">
          <span onClick={onClick} className="icon-left-text-right">
            <span>
              {" "}
              <img alt="listedIcon" src={listedIcon} />
            </span>{" "}
            <span>{timelisted}</span>{" "}
          </span>
          {/* <span onClick={onEditClick} className="sender-edit-btn">
          Edit{" "}
        </span> */}
        </div>

        <div className="qouteinfo-left-right">
          <span onClick={onClick} className="icon-left-text-right">
            <span>
              <img alt="modeIcon" src={modeIcon} />
            </span>{" "}
            <span>
              {deliverymode?.charAt(0)?.toUpperCase() +
                deliverymode?.slice(1).replace(/_/g, " ")}
            </span>{" "}
          </span>
          {/* <span onClick={onDeleteClick} className="sender-delete-btn">
          Delete{" "}
        </span> */}
        </div>
      </div>

    
      </div>

      <div className="sub-flex-info">
        <div className="payment-status">
          <div>
            {paymentStatus ? (
              <span>
                Payment status:{" "}
                <span className="item-value">
                  {paymentStatus === "success"
                    ? "Paid"
                    : "Not paid"}
                  </span>
              </span>
            ) : (
              <></>
            )}
          </div>

          <div>
            <span>
              Delivery status:{" "}
                <span className="item-value">
                  {deliveryStatus?.charAt(0)?.toUpperCase() +
                    deliveryStatus?.slice(1).replace(/_/g, " ")}
              </span>
            </span>
          </div>

          <div>
            <span>
              Payer:{" "}
                <span className="item-value">
                   {payer?.charAt(0)?.toUpperCase() + payer?.slice(1)}
              </span>
            </span>
          </div>

          <div>
            
            {best_quote ? <span>Best quote: <span className="item-value"> {best_quote}</span> </span> : <></>}
            {quote_amount ? <span>Quote amount: <span className="item-value">{quote_amount}</span> </span> : <></>}

          </div>

          <div>
            <span>
              Payment type:{" "}
              <span className="item-value"> {paymentTime === "pay_on_delivery"
                ? "Pay on delivery"
                : paymentTime === "pay_at_pickup"
                ? "Pay on pickup"
                : "Null"}{" "}</span>
            </span>
          </div>

          <div>
            <span>
            Provider’s name:{" "}
              <span className="item-value"> {provider_name?.charAt(0)?.toUpperCase() +
                      provider_name?.slice(1).replace(/_/g, " ")}</span>
            </span>
          </div>


        </div>
      </div>

      <div className="ack-con">
      {
        time < 0 ?
        <>
         <div>
            <span>
              <span> Acknowledgement time: 00:00 </span> 
            </span>
          </div>
          <div>
            <p className="ack-text">You can now accept from existing quotes or wait for more quotes</p>

          </div>
        </>
        :
        <>
          <div>
            <span>
              <span> Acknowledgement time: {Math.floor(time / 60)}: {time % 60} </span> 
            </span>
          </div>
          <div>
            <CountDown setTime={setTime} time={time}/>

            <p className="ack-text">After 30 minitues you can accept from existing quotes or
                  wait for more quotes.</p>

          </div>
        </>
      }
      {
        time > 0 ?
        <div className="ack-butn-con">
          <button className="wait-btn"> Wait for more quotes </button>
          <button className="accept-btn">Accept existing quotes</button>
        </div>
        :
        <div className="ack-butn-con">
          <button className="wait" onClick={onWaitClick}> { waiting && clicked === items.delivery_id ? "Please wait" : "Wait for more quotes"}</button>
          <button className="accpt" onClick={onAcceptExistClick}>Accept existing quotes</button>
        </div>
      }
      </div>
    </div>
  );
}
