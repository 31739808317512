import {useContext, useState} from 'react'
import './TopMenu.css'
import dillivry from '../../assets/Dillivry.png'
import {useHistory} from 'react-router-dom'
import { BiLogOut } from 'react-icons/bi'
import DialogOutClick from '../DialogOutClick'
import { AuthContext } from '../../context/AuthContext';

function UpdateTopMenu() {
    const [showLogout, setShowLogout] = useState(false);

    const auth = useContext(AuthContext);

    const logMeOut = ()=>{
        auth.logout();
         history.push('/') 
     }

    const history = useHistory()
    return (
        <div>
            <div className="wrapper">
                <div  className="content1">
                    <div className="logo" onClick={()=>history.push('/')}>
                        <img src={dillivry} alt="" />
                    </div>
                    <div>
                        <span className='logoutBtn' onClick={() => setShowLogout(true)}> <BiLogOut/>  Logout</span>
                    </div>
                </div>
                

            </div>
            <DialogOutClick
                open={showLogout}
                handleClose ={()=>setShowLogout(false)}
                > 
                <div style={{width:'100%',padding:"30px 30px 20px 30px"}}>
                    <div style={{width:'100%',marginBottom:"30px", fontSize:'13px', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <span>Are you sure you want to logout?</span>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
                        <div className="disBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer'}}
                            onClick={()=>setShowLogout(false)} >
                            No
                        </div>
                        <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'white', backgroundColor:'#808080', border:'1px solid #808080' }}
                            onClick={logMeOut}>
                            Yes
                        </div>
                    </div>
                </div>
            </DialogOutClick>
        </div>
    )
}

export default UpdateTopMenu