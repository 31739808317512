import React, { useState, useMemo, useContext, useEffect } from 'react'
// import Select from 'react-select'
import countryList from 'react-select-country-list'
import { ConnContext } from '../context/ConnContext'
import axios from 'axios'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

function Country_dropdown({setDataValue}) {
    
  const [value, setValue] = useState("")
  const options = useMemo(() => countryList().getData(), [])

  const option = [
    {
      "label":"Canada",
      "value":"CA"
    },{
      "label":"Ghana",
      "value":"GH"
    },{
      "label":"Kenya",
      "value":"KE"
    },{
      "label":"Nigeria",
      "value":"NG"
    },{
      "label":"Rwanda",
      "value":"RW"
    },{
      "label":"South Africa",
      "value":"ZA"
    },{
      "label":"United Kingdom",
      "value":"GB"
    },{
      "label":"USA",
      "value":"US"
    },
  ]

  const {visitingCountryCode} = useContext(ConnContext);

  const changeHandler = value => {
    setValue(value)
    setDataValue(value)
  }

  useEffect(() => {
      // if(visitingCountryCode === ""){
      //   axios.get(
      //       "https://geolocation-db.com/json/f9902210-97f0-11eb-a459-b997d30983f1"
      //     ).then((response) => {
      //       // setValue({value: `${response?.data?.country_code}` , label: `${response?.data?.country_name}`})
      //       // setDataValue({value: `${response?.data?.country_code}` , label: `${response?.data?.country_name}`})
      //       setValue(`${response?.data?.country_code}`)
      //       setDataValue(`${response?.data?.country_code}`)
      //     });
      // }
      // else{
      //   setValue(`${visitingCountryCode}` )
      //   setDataValue(`${visitingCountryCode}`)
      // }

      setValue('NG' )
        setDataValue('NG')

  }, [])

  return (
       <FormControl size='small' fullWidth variant="outlined">
            {/* <div style={{ fontSize: "16px",fontWeight:'500', marginBottom:'8px'}}>Account type</div> */}
            <InputLabel id="account_ype">Country</InputLabel>
          <Select
              labelId="account_ype"
              id="account_ype"
              value={value}
              onChange={(e) => {changeHandler(e.target.value)}}
              label="Country"
              >
                {
                  option.map((option =>
                    <MenuItem key={option.value} value={option.value}>
                    <div>{option.label}</div>
                    </MenuItem>
                  ))
                }
          </Select>
      </FormControl>
    )}

export default Country_dropdown