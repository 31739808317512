import { useEffect, useState } from 'react'
import RegBtnHalf from '../Reg_components/RegBtnHalf'
import {IoMdArrowRoundBack} from 'react-icons/io'
import SecureLS from 'secure-ls';


function Web_capture_preview({leftClick, rightClick, uploading}) {

    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

    const [imageToShow, setimageToShow] = useState('')

    useEffect(() => {
        setimageToShow(ls.get('_prv_'))

        const dataa = ls.get('_prv_');

        if (dataa.substring(11,14) === "png"){
            ls.set( '_ig_m', dataa.replace('data:image/png;base64,',''));
            //console.log(dataa.replace('data:image/png;base64,',''), 'png')
        }
        else if (dataa.substring(11,14) === "gif"){
            ls.set( '_ig_m', dataa.replace('data:image/gif;base64,',''));
            //console.log(dataa.replace('data:image/gif;base64,',''), 'gif')
        }
        else if (dataa.substring(11,14) === "jpg"){
            ls.set( '_ig_m', dataa.replace('data:image/jpg;base64,',''));
            //console.log(dataa.replace('data:image/jpg;base64,',''), 'jpg')
        }
        else if (dataa.substring(11,15) === "jpeg"){
            ls.set( '_ig_m', dataa.replace('data:image/jpeg;base64,',''));
            //console.log(dataa.replace('data:image/jpeg;base64,',''), 'jpeg')
        }
        else{
            ls.set( '_ig_m', dataa.replace('data:image/webp;base64,',''));
            //console.log(dataa.replace('data:image/webp;base64,',''), 'last')
        }

    }, [])
    return (
        <div style={{height:'100%'}}>
            <div style={{width:'100%', marginBottom:'40px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <IoMdArrowRoundBack size='30px' onClick={leftClick}/>
            </div>
            <div style={{width:'100%', display:'flex', justifyContent:'center',alignItems:'center', flexDirection:'column'}}>
                <div style={{border:'1px solid var(--main)', width:'240px', height:'240px', borderRadius:'50%', backgroundImage:`url(${imageToShow})`, backgroundSize:'cover', backgroundPosition:'center', backgroundRepeat:'no-repeat', marginBottom:'40px'}}>
                </div>
                <div style={{fontSize:'16px', fontWeight:'600', marginBottom:'20px'}}>
                    Use this photo 
                </div>
                <div style={{fontSize:'13px', fontWeight:'400', textAlign:'center'}}>
                    By tapping save, you agree that Dillivry or a trusted vendor can collect and process your photos with technology that allows us to verify your identity.
                </div>
            </div>
            <div  className="btnMobil2">
                <RegBtnHalf
                    uploading={uploading}
                    leftText="Retake"
                    rightText="Save"
                    leftClick = {leftClick}
                    rightClick = {rightClick}
                /> 
            </div>
            <div  className="btnDeskto">
                <RegBtnHalf
                    uploading={uploading}
                    leftText="Retake"
                    rightText="Save "
                    leftClick = {leftClick}
                    rightClick = {rightClick}
                /> 
            </div>
        </div>       
    )
}

export default Web_capture_preview
