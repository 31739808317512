import React from 'react'
import './StepCardProvider.css'

function StepsCardProvider({ image, title, text, img_size, gap }) {
  return (
    <div className="stepHomeCardp">
      <div className="StepImgp">
        <div
          className="stepCard_imgp"
          style={{
            backgroundImage: `url(${image})`,
            float: "left",
            width: "100px",
            height: "100px",
            objectFit: "cover"

            // width: img_size,
            // height: img_size,
            // maxWidth: "120px",
          }}
        ></div>
      </div>

      <div className="StepDetailsp">
        <h3>{title}</h3>
        <h4>{text}</h4>
      </div>
    </div>
  )
}

export default StepsCardProvider