import { useEffect, useContext, useState } from 'react'
import MUTable from '../../../components/MUTable'
import '../Dashboard/Dashboard.css'
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import { AiOutlineUserAdd } from 'react-icons/ai'
import DialogPop from '../../../components/DialogPop';
import { ImUsers } from 'react-icons/im'
import { FaWalking, FaUserTie,  FaUsers } from 'react-icons/fa'
import InputField from '../../../components/InputField';
import {  FormControl,Select,MenuItem, Grid, InputLabel, Button } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BiRename } from 'react-icons/bi'
import PhoneNumber from '../../../components/PhoneNumber';
import PuffLoader from 'react-spinners/PuffLoader';
import axios from 'axios'
import Swal from 'sweetalert2'
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import { IoMdArrowDropdown } from 'react-icons/io';
import { TiUserAdd } from 'react-icons/ti';
import UserView from './UserView';
import DialogOutClick from '../../../components/DialogOutClick';
import SecureLS from 'secure-ls';
import SnackbarUser from '../../../components/SnackBarUser';
import { DashboardContext } from '../../../context/Dashboard/DashboardProvider/dashboard.provider'
import { ConnContext } from '../../../context/ConnContext';
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import moment from 'moment'
import ReactTooltip from 'react-tooltip';


import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
import ReactSelect from 'react-select';
function UserSetting() {
    var login = GlobalizationLanguage("/login")
    var timeout = GlobalizationLanguage("/timeout")
    var account = GlobalizationLanguage("/account")

    const { visitingCountryCode } = useContext(ConnContext)
    const { dashboards, updateDashboard } = useContext(DashboardContext)

    const ls = new SecureLS({ isCompression: true, encodingType: 'rabbit', encryptionSecret: 'dm_dillivry' });

    const history = useHistory();
    const auth = useContext(AuthContext);
    const [showAddForm, setshowAddForm] = useState(false)

    const [show, setshow] = useState('all')
    const [filtershow, setfiltershow] = useState(false);
    const [admin, setadmin] = useState([]);
    const [sysops, setsysops] = useState([]);
    const [lastmile, setlastmile] = useState([])
    const [total, settotal] = useState([])
    const [allusers, setallusers] = useState([])
    const [userToShow, setUserToShow] = useState([]);
    const [userType, setuserType] = useState('')

    const [clicked, setclicked] = useState('all')

    const [showUser, setshowUser] = useState(false)

    const [addingFulfiller, setaddingFulfiller] = useState(false)

    const [fulfillerType, setfulfillerType] = useState('')

    const [phone_num, setphone_num] = useState('')
    const [showLogout, setShowLogout] = useState(false)

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [action, setAction] = useState("")

    const [loading, setloading] = useState(false)

    const logMeOut = () => {
        auth.logout();
        history.push('/')
    }
    const schema = yup.object().shape({
        first_name: yup.string().required("Firstname is required"),
        last_name: yup.string().required("Lastname is required"),
        // email: yup.string().email("Valid email is required").required("Email address is required"),
        email: yup
        .string()
        .matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, "Valid email is required")
        .required("Email is required"),
    });

    const { control, handleSubmit, reset, formState: { errors, isValid }, getValues } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const authAxios = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers: {
            Authorization: ls.get('_nodi')
        }
    })

    const options = [
        { value: 'corp_sys_admin', label: 'Admin' },
        { value: 'corp_bid_ops', label: 'Bidder' },
        { value: 'corp_last_mile_ops', label: 'Fulfilling agent' }
      ]
    const customStyles = {
        control: (provided, state) => ({
            ...provided, 
            boxShadow: state.isFocused ? '0 0 0 1px var(--main)' : ''
        }),
      }

    const submitForm = async (data) => {

        if (phone_num === undefined) {

        }
        else if (!isValidPhoneNumber(phone_num)) {

        }
        else {

            setaddingFulfiller(true)
            data.role = fulfillerType;
            data.phone_number = parsePhoneNumber(phone_num).number;
            data.action_type = "create";
            data.email = data.email.toLowerCase();
            data.vcc = visitingCountryCode

            authAxios.post(`m-dillivry-user-manager`,
                data, { timeout: 240000 }
            )
                .then((res) => {
                    setaddingFulfiller(false)
                    setshowAddForm(false)
                    if (res.status === 200) {
                        Swal.fire({
                            icon: 'success',
                            text: 'User added successfully',
                            timer: 2500,
                            showConfirmButton: false,
                        })
                            .then(() => {
                                window.location.reload()
                            })
                    }
                    reset({
                        first_name: "",
                        last_name: "",
                        email: "",
                    });
                    setphone_num('')
                    setfulfillerType('')
                })
                .catch((err) => {
                    setaddingFulfiller(false)
                    setshowAddForm(false)
                    if (err?.response?.status === 400) {
                        Swal.fire({
                            icon: 'question',
                            text: 'Username/email already exist',
                            timer: 3000,
                            showConfirmButton: false
                        })
                    }
                    else if (err?.response?.status === 403) {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Session!',
                            text: 'Session expired login again!',
                            allowOutsideClick: false,
                            confirmButtonColor: 'var(--main)'
                        })
                            .then((result) => {
                                if (result.value) {
                                    auth.logout();
                                    history.push(login);
                                }
                            });
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: 'An error occured, be sure you are connected to internet',
                            timer: 3000,
                            showConfirmButton: false
                        })
                    }
                    if (err.code === "ECONNABORTED") {
                        console.log('internal-server-error-timeout')
                        history.push(timeout)
                        // window.location.reload()
                    }
                })
        }
    }

    useEffect(() => {
        if (dashboards !== undefined) {
            updatePage(dashboards)
        }
        else {
            setloading(true)
            authAxios.get(`m-dillivry-dashboard-manager`)
                .then((response) => {
                    setloading(false)
                    updateDashboard(response);
                    updatePage(response)

                    ls.set(
                        "means_of_delivery",
                        JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                    );
                })
                .catch((err) => {
                    setloading(false)
                    if (err?.response?.status === 403) {
                        Swal.fire({
                            icon: "warning",
                            title: "Session!",
                            text: "Session expired login again!",
                            allowOutsideClick: false,
                            confirmButtonColor: "var(--main)",
                        }).then((result) => {
                            if (result.value) {
                                auth.logout();
                                history.push(login);
                            }
                        });
                    }
                })
        }
    }, [])

    const ListData = {

        Users: [
            {
                type: "all",
                name: "All",
            },
            {
                type: "admin",
                name: "Admin",
            },
            {
                type: "bidder",
                name: "Bidder",
            },
            {
                type: "agent",
                name: "Agent",
            },

        ],
    };

    const updatePage = (dashData) => {
        ls.set(
            "means_of_delivery",
            JSON.stringify(dashData?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
        );
        setlastmile(dashData?.data?.all_users_list?.all_users_list?.filter(function (lastmile) { return lastmile?.role === "dillivry-corp-last-mile-ops" && lastmile?.current_status !== "deleted" }))
        setadmin(dashData?.data?.all_users_list?.all_users_list?.filter(function (admin) { return admin?.role === "dillivry-corp-sys-admin" && admin?.current_status !== "deleted" }))
        setsysops(dashData?.data?.all_users_list?.all_users_list?.filter(function (sys_ops) { return sys_ops?.role === "dillivry-corp-bid-ops" && sys_ops?.current_status !== "deleted" }))
        settotal([
            ...dashData?.data?.all_users_list?.all_users_list?.filter(function (admin) { return admin?.role === "dillivry-corp-sys-admin" && admin?.current_status !== "deleted" }),
            ...dashData?.data?.all_users_list?.all_users_list?.filter(function (sys_ops) { return sys_ops?.role === "dillivry-corp-bid-ops" && sys_ops?.current_status !== "deleted" }),
            ...dashData?.data?.all_users_list?.all_users_list?.filter(function (lastmile) { return lastmile?.role === "dillivry-corp-last-mile-ops" && lastmile?.current_status !== "deleted" })
        ])
        setallusers([
            ...dashData?.data?.all_users_list?.all_users_list?.filter(function (admin) { return admin?.role === "dillivry-corp-sys-admin" && admin?.current_status !== "deleted" }),
            ...dashData?.data?.all_users_list?.all_users_list?.filter(function (sys_ops) { return sys_ops?.role === "dillivry-corp-bid-ops" && sys_ops?.current_status !== "deleted" }),
            ...dashData?.data?.all_users_list?.all_users_list?.filter(function (lastmile) { return lastmile?.role === "dillivry-corp-last-mile-ops" && lastmile?.current_status !== "deleted" })
        ])
    }


    const Filterbanner = () => {
        return (
            <div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div onClick={() => setfiltershow(!filtershow)} className="status-body-">
                        <div className="filterandicon">
                            <div>
                                <h1 className="fiter-content">Filter by role</h1>
                            </div>

                            <IoMdArrowDropdown
                                onClick={() => setfiltershow(!filtershow)}
                                color="white"
                            />
                        </div>
                    </div>
                </div>

                {
                    filtershow ?
                        <div className="parent-dropdown">

                            <ReactTooltip
                                id="menuicon"
                                aria-haspopup="true"
                                type="light"
                                role="example"
                            >
                                <p>Menu Icon</p>
                            </ReactTooltip>


                            {ListData.Users.map((x, i) => {
                                return (
                                    <div
                                        key={i}
                                        className="each"
                                        onClick={() => {
                                            filtering(x.type);
                                            // setfilterstatus(x.type);

                                            // UpdatePage(freshData, x.type);
                                            // UpdateStatusMemory(x.type)
                                        }}
                                    >
                                        <span
                                            style={{
                                                marginLeft: "10px",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                            }}
                                        >
                                            {x.name}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                        :
                        null
                }

            </div>

        )
    }
    const filtering = (type) => {

        if (type === "all") {

            settotal(allusers);

            console.log("all");


            setfiltershow(false);


        }
        else if (type === "admin") {
            settotal(admin);

            console.log("admin");


            setfiltershow(false);


        }
        else if (type === "bidder") {
            settotal(sysops);

            console.log("bidder");


            setfiltershow(false);


        }
        else if (type === "agent") {
            settotal(lastmile);

            console.log("agent");


            setfiltershow(false);


        }

    }



    return (


        <>{
            showUser ?
                <div style={{ width: '100%' }}>


                    <UserView userDetails={userToShow} userType={userType} goBack={() => setshowUser(false)}

                        updateUserOnDelete={(userId) => {
                            setlastmile(lastmile.filter(function (agent) { return agent.user_id !== userId }));
                            setadmin(admin.filter(function (adm) { return adm.user_id !== userId }));
                            setsysops(sysops.filter(function (sys) { return sys.user_id !== userId }));
                            settotal(total.filter(function (agent) { return agent.user_id !== userId }));
                            setallusers(allusers.filter(function (agent) { return agent.user_id !== userId }));
                            setAction('delete')
                            setShowSnackBar(true)
                        }}

                        updateUserOnDisable={(userId) => {

                            var index = allusers.findIndex(x => x.user_id === userId);
                            if (index !== -1) {
                                let temp_state = [...allusers];
                                let temp_element = { ...temp_state[index] };
                                temp_element.current_status = "disabled";
                                temp_state[index] = temp_element;
                                setallusers(temp_state);
                            }

                            var index1 = admin.findIndex(x => x.user_id === userId);
                            if (index1 !== -1) {
                                let temp_state = [...admin];
                                let temp_element = { ...temp_state[index1] };
                                temp_element.current_status = "disabled";
                                temp_state[index1] = temp_element;
                                setadmin(temp_state);
                            }

                            var index2 = sysops.findIndex(x => x.user_id === userId);
                            if (index2 !== -1) {
                                let temp_state = [...sysops];
                                let temp_element = { ...temp_state[index2] };
                                temp_element.current_status = "disabled";
                                temp_state[index2] = temp_element;
                                setsysops(temp_state);
                            }

                            var index3 = lastmile.findIndex(x => x.user_id === userId);
                            if (index3 !== -1) {
                                let temp_state = [...lastmile];
                                let temp_element = { ...temp_state[index3] };
                                temp_element.current_status = "disabled";
                                temp_state[index3] = temp_element;
                                setlastmile(temp_state);
                            }

                            var index5 = total.findIndex(x => x.user_id === userId);
                            if (index5 !== -1) {
                                let temp_state = [...total];
                                let temp_element = { ...temp_state[index5] };
                                temp_element.current_status = "disabled";
                                temp_state[index5] = temp_element;
                                settotal(temp_state);
                            }
                            setAction('disabled')
                            setShowSnackBar(true)

                        }}

                        updateUserOnEnable={(userId) => {
                            var index = allusers.findIndex(x => x.user_id === userId);
                            if (index !== -1) {
                                let temp_state = [...allusers];
                                let temp_element = { ...temp_state[index] };
                                temp_element.current_status = "active";
                                temp_state[index] = temp_element;
                                setallusers(temp_state);
                            }

                            var index1 = admin.findIndex(x => x.user_id === userId);
                            if (index1 !== -1) {
                                let temp_state = [...admin];
                                let temp_element = { ...temp_state[index1] };
                                temp_element.current_status = "active";
                                temp_state[index1] = temp_element;
                                setadmin(temp_state);
                            }

                            var index2 = sysops.findIndex(x => x.user_id === userId);
                            if (index2 !== -1) {
                                let temp_state = [...sysops];
                                let temp_element = { ...temp_state[index2] };
                                temp_element.current_status = "active";
                                temp_state[index2] = temp_element;
                                setsysops(temp_state);
                            }

                            var index3 = lastmile.findIndex(x => x.user_id === userId);
                            if (index3 !== -1) {
                                let temp_state = [...lastmile];
                                let temp_element = { ...temp_state[index3] };
                                temp_element.current_status = "active";
                                temp_state[index3] = temp_element;
                                setlastmile(temp_state);
                            }

                            var index5 = total.findIndex(x => x.user_id === userId);
                            if (index5 !== -1) {
                                let temp_state = [...total];
                                let temp_element = { ...temp_state[index5] };
                                temp_element.current_status = "active";
                                temp_state[index5] = temp_element;
                                settotal(temp_state);
                            }
                            setAction('enabled')
                            setShowSnackBar(true)
                        }}
                    />
                </div>
                :
                <>
                    {loading ? (
                        <div
                            style={{
                                width: "100%",
                                height: "100vh",
                                backgroundColor: "none",
                                zIndex: "300",
                                position: "fixed",
                                top: "0",
                                paddingTop: "5px",
                            }}
                        >
                            <Box sx={{ width: "100%" }}>
                                <LinearProgress color="success" />
                            </Box>
                        </div>
                    ) : null}
                    <div className="userMobile">
                        {/* <div style={{display:'flex', marginBottom:'20px', gap:'20px'}}>
                    <IoMdArrowRoundBack onClick={()=>history.push('/account')} size='24px'/>
                    <h2>User manager</h2>
                </div> */}
                        <div style={{ display: 'flex', marginBottom: '20px', gap: '20px', width: '100%', alignItems: 'center' }}>
                            <div className={clicked === 'all' ? "activeAllUsers" : "allUsers"}
                                onClick={() => { setclicked('all'); setallusers(total) }}>
                                <span> <FaUsers size="20px" /></span>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <span>All</span>
                                    <span className="spanBadge">{total.length}</span>
                                </div>

                            </div>
                            <div className={clicked === 'admin' ? "activeAllUsers" : "allUsers"}
                                onClick={() => { setclicked('admin'); setallusers(admin) }}>
                                <span> <FaUserTie size="20px" /></span>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <span>Admin</span>
                                    <span className="spanBadge">{admin.length}</span>
                                </div>
                            </div>
                            <div className={clicked === 'bidder' ? "activeAllUsers" : "allUsers"}
                                onClick={() => { setclicked('bidder'); setallusers(sysops) }}>
                                <span> <ImUsers size="20px" /></span>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <span>Bidder</span>
                                    <span className="spanBadge">{sysops.length}</span>
                                </div>
                            </div>
                            <div className={clicked === 'agent' ? "activeAllUsers" : "allUsers"}
                                onClick={() => { setclicked('agent'); setallusers(lastmile) }}>
                                <span> <FaWalking size="20px" /></span>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    <span>Agent</span>
                                    <span className="spanBadge">{lastmile.length}</span>
                                </div>
                            </div>

                        </div>
                        <div style={{ width: '100%' }}>
                            {
                                allusers.map((user, index) => {
                                    return <div key={index} className="quoteCard">
                                        <div style={{ display: 'flex', width: '100%', userSelect: 'none', fontWeight: '500' }}
                                            onClick={() => {
                                                setshowUser(true);
                                                setUserToShow(user);
                                                setuserType(user.role)
                                            }}
                                        >
                                            <div className='radiusCard' style={{ margin: '5px 0 0 5px', backgroundImage: `url(${user?.profile_photo?.profile_photo})` }}></div>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '83%', padding: '10px 0 10px 10px', gap: '10px' }}>
                                                <div style={{ display: "flex", fontSize: '14px', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                    <span>First name:</span>
                                                    <div className="spanItem">{user.first_name}</div>
                                                </div>
                                                <div style={{ display: "flex", fontSize: '14px', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                    <span>Last name</span>
                                                    <div className="spanItem">{user.last_name}</div>
                                                </div>
                                                <div style={{ display: "flex", fontSize: '14px', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                    <span>User id</span>
                                                    <div className="spanItem" style={{ fontWeight: '400' }}>{user.user_id}</div>
                                                </div>
                                                <div style={{ display: "flex", fontSize: '14px', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                    <span>Phone number:</span>
                                                    <div className="spanItem" style={{ fontWeight: '400' }}>{user?.phone_number}</div>
                                                </div>
                                                <div style={{ display: "flex", fontSize: '14px', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                    <span>Role:</span>
                                                    <div className="spanItem" style={{ fontWeight: '400' }}>{user.role === 'dillivry-corp-last-mile-ops' ? <span>Agent</span> : user.role === 'dillivry-corp-sys-admin' ? <span>Admin</span> : <span>Bidder</span>}</div>
                                                </div>
                                                <div style={{ display: "flex", fontSize: '14px', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                    <span>Status:</span>
                                                    {
                                                        user.current_status === "active" ?
                                                            <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                <span style={{ background: 'var(--main)', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Active</span>
                                                            </div>
                                                            :
                                                            user.current_status === 'disabled' ?
                                                                <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                    <span style={{ background: 'rgba(255, 99, 71, 0.5)', padding: '2px 10px', color: "red", borderRadius: '10px' }}>Disabled</span>
                                                                </div>
                                                                :
                                                                user.current_status === "device_not_active" ?
                                                                    <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                        <span style={{ background: '#a9a9a9', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Device not activated</span>
                                                                    </div>
                                                                    :
                                                                    user.current_status === "deleted" ?
                                                                        <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                            <span style={{ background: '#a9a9a9', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Deleted</span>
                                                                        </div>
                                                                        :
                                                                        null
                                                    }

                                                </div>
                                                <div style={{ display: "flex", fontSize: '14px', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                    <span>Created time:</span>
                                                    <div className="spanItem" style={{ fontWeight: '400' }}>
                                                        {moment(user.account_status?.create_time_stamp).format('DD-MM-YYYY hh:mm:ss ')}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }

                        </div>
                        <div style={{ position: 'fixed', right: '20px', bottom: '70px' }}
                            onClick={() => setshowAddForm(true)}
                        >
                            <div style={{ width: '40px', height: '40px', borderRadius: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'var(--main)', cursor: 'pointer' }}>
                                <TiUserAdd color="white" size="26px" />
                            </div>
                        </div>
                    </div>

                    {/* desktop view here */}
                    <div className="corpuserDesktop">
                        <div className="corpfulfillers">

                            <div className='btnAssigns'>
                                <div className="btnAssign" onClick={() => setshowAddForm(!showAddForm)}>
                                    Create User <AiOutlineUserAdd size='18px' />
                                </div>
                                <Filterbanner />
                                {/* <div className="btnAssign" 
                            onClick={() => {
                                setShowLogout(true)
                            }}>
                         Filter by role
                    </div> */}
                            </div>
                            <div className="usersTable">
                                {
                                    show === 'all' ?
                                        <MUTable
                                            style={{ width: '100%', fontSize: '8px' }}
                                            title="All users"
                                            columns={[
                                                { title: 'User id', field: 'user_id' },
                                                { title: 'First name', field: 'first_name' },
                                                { title: 'Last name', field: 'last_name' },
                                                { title: 'Phone number', field: 'phone_number' },
                                                {
                                                    title: 'Role',
                                                    field: 'role',
                                                    render: rowData => rowData.role === 'dillivry-corp-last-mile-ops' ? <span>Agent</span> : rowData.role === 'dillivry-corp-sys-admin' ? <span>Admin</span> : <span>Bidder</span>
                                                },
                                                {
                                                    title: 'Status',
                                                    render: rowData =>
                                                        rowData.current_status === "active" ?
                                                            <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                <span style={{ background: 'var(--main)', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Active</span>
                                                            </div>
                                                            :
                                                            rowData.current_status === 'disabled' ?
                                                                <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                    <span style={{ background: 'rgba(255, 99, 71, 0.5)', padding: '2px 10px', color: "red", borderRadius: '10px' }}>Disabled</span>
                                                                </div>
                                                                :
                                                                rowData.current_status === "device_not_active" ?
                                                                    <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                        <span style={{ background: '#a9a9a9', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Device not activated</span>
                                                                    </div>
                                                                    :
                                                                    rowData.current_status === "deleted" ?
                                                                        <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                            <span style={{ background: '#a9a9a9', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Deleted</span>
                                                                        </div>
                                                                        :
                                                                        null

                                                },
                                                {
                                                    title: 'Created time', render: rowData => <span>{moment(rowData.account_status?.create_time_stamp).format('DD-MM-YYYY hh:mm:ss')}</span>
                                                },


                                            ]}
                                            data={total}
                                            onRowClick={(event, rowData) => {
                                                setUserToShow(rowData);
                                                setshowUser(true)
                                                setuserType(rowData.role)
                                            }
                                            }
                                            pageSize={10}
                                            options={{
                                                exportButton: true,
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    fontSize: '13px',
                                                    fontWeight: '600',
                                                    backgroundColor: 'rgb(236, 252, 242)'
                                                },

                                            }}
                                            actions={[

                                            ]}

                                        />

                                        :
                                        show === 'admin' ?
                                            <MUTable
                                                style={{ width: '100%', fontSize: '8px' }}
                                                title="Admin(s)"
                                                columns={[
                                                    { title: 'User id', field: 'user_id' },
                                                    { title: 'First name', field: 'first_name' },
                                                    { title: 'Last name', field: 'last_name' },
                                                    { title: 'Phone number', field: 'phone_number' },
                                                    {
                                                        title: 'Role',
                                                        field: 'role',
                                                        render: rowData => rowData.role === 'dillivry-corp-last-mile-ops' ? <span>Agent</span> : rowData.role === 'dillivry-corp-sys-admin' ? <span>Admin</span> : <span>Bidder</span>
                                                    },
                                                    {
                                                        title: 'Status',
                                                        render: rowData =>
                                                            rowData.current_status === "active" ?
                                                                <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                    <span style={{ background: 'var(--main)', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Active</span>
                                                                </div>
                                                                :
                                                                rowData.current_status === 'disabled' ?
                                                                    <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                        <span style={{ background: 'rgba(255, 99, 71, 0.5)', padding: '2px 10px', color: "red", borderRadius: '10px' }}>Disabled</span>
                                                                    </div>
                                                                    :
                                                                    rowData.current_status === "device_not_active" ?
                                                                        <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                            <span style={{ background: '#a9a9a9', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Device not activated</span>
                                                                        </div>
                                                                        :
                                                                        rowData.current_status === "deleted" ?
                                                                            <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                                <span style={{ background: '#a9a9a9', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Deleted</span>
                                                                            </div>
                                                                            :
                                                                            null

                                                    },
                                                    {
                                                        title: 'Create Timestamp', render: rowData => <span>{moment(rowData.account_status?.create_time_stamp).format('DD-MM-YYYY hh:mm:ss')}</span>
                                                    },

                                                ]}
                                                data={admin}
                                                onRowClick={(event, rowData) => {
                                                    setUserToShow(rowData);

                                                    setshowUser(true)
                                                    setuserType(rowData.role)
                                                }
                                                }
                                                pageSize={10}
                                                options={{
                                                    exportButton: true,
                                                    actionsColumnIndex: -1,
                                                    headerStyle: {
                                                        fontSize: '13px',
                                                        fontWeight: '600',
                                                        backgroundColor: 'rgb(236, 252, 242)'
                                                    },

                                                }}
                                                actions={[

                                                ]}

                                            />
                                            :
                                            show === 'operation' ?
                                                <MUTable
                                                    style={{ width: '100%', fontSize: '8px' }}
                                                    title="Bidder(s)"
                                                    columns={[
                                                        { title: 'User id', field: 'user_id' },
                                                        { title: 'First name', field: 'first_name' },
                                                        { title: 'Last name', field: 'last_name' },
                                                        { title: 'Phone number', field: 'phone_number' },
                                                        {
                                                            title: 'Role',
                                                            field: 'role',
                                                            render: rowData => rowData.role === 'dillivry-corp-last-mile-ops' ? <span>Agent</span> : rowData.role === 'dillivry-corp-sys-admin' ? <span>Admin</span> : <span>Bidder</span>
                                                        },
                                                        {
                                                            title: 'Status',
                                                            render: rowData =>
                                                                rowData.current_status === "active" ?
                                                                    <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                        <span style={{ background: 'var(--main)', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Active</span>
                                                                    </div>
                                                                    :
                                                                    rowData.current_status === 'disabled' ?
                                                                        <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                            <span style={{ background: 'rgba(255, 99, 71, 0.5)', padding: '2px 10px', color: "red", borderRadius: '10px' }}>Disabled</span>
                                                                        </div>
                                                                        :
                                                                        rowData.current_status === "device_not_active" ?
                                                                            <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                                <span style={{ background: '#a9a9a9', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Device not activated</span>
                                                                            </div>
                                                                            :
                                                                            rowData.current_status === "deleted" ?
                                                                                <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                                    <span style={{ background: '#a9a9a9', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Deleted</span>
                                                                                </div>
                                                                                :
                                                                                null

                                                        },
                                                        {
                                                            title: 'Create Timestamp', render: rowData => <span>{moment(rowData.account_status?.create_time_stamp).format('DD-MM-YYYY hh:mm:ss')}</span>
                                                        },

                                                    ]}
                                                    data={sysops}
                                                    onRowClick={(event, rowData) => {
                                                        setUserToShow(rowData);

                                                        setuserType(rowData.role)
                                                        setshowUser(true)
                                                    }
                                                    }
                                                    options={{
                                                        exportButton: true,
                                                        actionsColumnIndex: -1,
                                                        headerStyle: {
                                                            fontSize: '13px',
                                                            fontWeight: '600',
                                                            backgroundColor: 'rgb(236, 252, 242)'
                                                        },

                                                    }}
                                                    pageSize={10}
                                                />
                                                :
                                                show === 'agent' ?
                                                    <MUTable
                                                        style={{ width: '100%', fontSize: '8px' }}
                                                        title="Agents"
                                                        columns={[
                                                            { title: 'User id', field: 'user_id' },
                                                            { title: 'First name', field: 'first_name' },
                                                            { title: 'Last name', field: 'last_name' },
                                                            { title: 'Phone number', field: 'phone_number' },
                                                            {
                                                                title: 'Role',
                                                                field: 'role',
                                                                render: rowData => rowData.role === 'dillivry-corp-last-mile-ops' ? <span>Agent</span> : rowData.role === 'dillivry-corp-sys-admin' ? <span>Admin</span> : <span>Bidder</span>
                                                            },
                                                            {
                                                                title: 'Status',
                                                                render: rowData =>
                                                                    rowData.current_status === "active" ?
                                                                        <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                            <span style={{ background: 'var(--main)', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Active</span>
                                                                        </div>
                                                                        :
                                                                        rowData.current_status === 'disabled' ?
                                                                            <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                                <span style={{ background: 'rgba(255, 99, 71, 0.5)', padding: '2px 10px', color: "red", borderRadius: '10px' }}>Disabled</span>
                                                                            </div>
                                                                            :
                                                                            rowData.current_status === "device_not_active" ?
                                                                                <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                                    <span style={{ background: '#a9a9a9', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Device not activated</span>
                                                                                </div>
                                                                                :
                                                                                rowData.current_status === "deleted" ?
                                                                                    <div className="spanItem" style={{ fontWeight: '400' }}>
                                                                                        <span style={{ background: '#a9a9a9', padding: '2px 10px', color: "white", borderRadius: '10px' }}>Deleted</span>
                                                                                    </div>
                                                                                    :
                                                                                    null

                                                            },
                                                            {
                                                                title: 'Create Timestamp', render: rowData => <span>{moment(rowData.account_status?.create_time_stamp).format('DD-MM-YYYY hh:mm:ss')}</span>
                                                            },
                                                        ]}
                                                        data={lastmile}
                                                        onRowClick={(event, rowData) => {
                                                            setUserToShow(rowData);
                                                            setshowUser(true)
                                                            setuserType(rowData.role)

                                                        }
                                                        }
                                                        pageSize={10}
                                                        options={{
                                                            exportButton: true,
                                                            actionsColumnIndex: -1,
                                                            headerStyle: {
                                                                fontSize: '13px',
                                                                fontWeight: '600',
                                                                backgroundColor: 'rgb(236, 252, 242)'
                                                            },

                                                        }}


                                                    />
                                                    :
                                                    null
                                }
                            </div>
                        </div>

                    </div>

                    <DialogPop
                        openPopup={showAddForm}
                        setopenPopup={setshowAddForm}
                        head="Create User"
                    >
                        <div className="user_container">
                            <div style={{paddingBottom: '10px' }}>
                                <form onSubmit={handleSubmit(submitForm)} noValidate style={{ width: '100%' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <div style={{ fontSize: '13px', fontWeight: '600', color: 'var(--main)' }}>All fields are required</div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="first_name"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => <FormControl fullWidth variant="outlined">
                                                    {/* <InputLabel htmlFor="firstname"><BiRename style={{ marginRight:"5px", color:"var(--main)"}}/>Firstname</InputLabel> */}
                                                    <InputField
                                                        label="Firstname"
                                                        {...field}
                                                        error={errors.first_name}
                                                        // disabled={true}
                                                        id="firstname"
                                                        name="first_name"
                                                        type="text"
                                                        labelWidth={150}
                                                        icon={<BiRename style={{ marginRight: "5px", color: "var(--main)" }} />}
                                                    />
                                                    <div className="err">{errors.first_name && errors.first_name?.message}</div>
                                                </FormControl>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="last_name"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => <FormControl size='small' fullWidth variant="outlined">
                                                    {/* <InputLabel htmlFor="lastname"><BiRename style={{ marginRight:"5px", color:"var(--main)"}}/>Lastname</InputLabel> */}
                                                    <InputField
                                                        label="Lastname"
                                                        {...field}
                                                        error={errors.last_name}
                                                        // disabled={true}
                                                        id="lastname"
                                                        name="last_name"
                                                        type="text"
                                                        labelWidth={100}
                                                        icon={<BiRename style={{ marginRight: "5px", color: "var(--main)" }} />}
                                                    />
                                                    <div className="err">{errors.last_name && errors.last_name?.message}</div>
                                                </FormControl>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="email"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => <FormControl fullWidth variant="outlined" style={{ backgroundColor: 'white' }}>
                                                    <InputField
                                                        label="Email"
                                                        {...field}
                                                        error={errors.email}
                                                        // disabled={true}
                                                        id="email"
                                                        type="email"
                                                        // labelWidth={70}
                                                        // icon={<BiRename style={{ marginRight: "5px", color: "var(--main)" }} />}
                                                    />
                                                    <div className="err">{errors.email && errors.email?.message}</div>
                                                </FormControl>
                                                }
                                            />
                                        </Grid>
                                       
         
                                        <Grid item xs={12}>
                                            <ReactSelect
                                                onChange={(e) => setfulfillerType(e.value)}
                                                placeholder="Select user role"
                                                options={options}
                                                styles={customStyles}
                                            />
                                                    
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div>
                                                <div className="phoneTopic">Phone Number</div>
                                                <PhoneNumber
                                                    value={phone_num}
                                                    setValue={setphone_num}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    {
                                        phone_num === "" ?
                                            <Button
                                                size="large"
                                                variant="contained"
                                                type="submit"
                                                disabled
                                                style={{
                                                    backgroundColor: "rgb(151, 199, 168)",
                                                    width: '100%',
                                                    marginTop: '20px',
                                                    color: "white",
                                                    borderRadius: '10px',
                                                    padding: '8px',
                                                    textTransform: "none",
                                                }}
                                            >
                                                <span className="btnlabel">Add <AiOutlineUserAdd /></span>
                                            </Button>
                                            :
                                            fulfillerType === "" ?
                                                <Button
                                                    size="large"
                                                    variant="contained"
                                                    type="submit"
                                                    disabled
                                                    style={{
                                                        backgroundColor: "rgb(151, 199, 168)",
                                                        width: '100%',
                                                        marginTop: '20px',
                                                        color: "white",
                                                        borderRadius: '10px',
                                                        padding: '8px',
                                                        textTransform: "none",
                                                    }}
                                                >
                                                    <span className="btnlabel">Add <AiOutlineUserAdd /></span>
                                                </Button>
                                                :
                                                <Button
                                                    size="large"
                                                    variant="contained"
                                                    type="submit"
                                                    style={{
                                                        backgroundColor: "var(--main)",
                                                        width: '100%',
                                                        marginTop: '20px',
                                                        color: "white",
                                                        borderRadius: '10px',
                                                        padding: '8px',
                                                        textTransform: "none",
                                                    }}
                                                >
                                                    {
                                                        addingFulfiller ?
                                                            <PuffLoader color='white' loading={addingFulfiller} speedMultiplier={1} size={24} />
                                                            :
                                                            <span className="btnlabel">Add  <AiOutlineUserAdd /></span>
                                                    }
                                                </Button>
                                    }
                                </form>
                            </div>
                        </div>
                    </DialogPop>
                    <DialogOutClick
                        open={showLogout}
                        handleClose={() => setShowLogout(false)}
                    >
                        <div style={{ width: '100%', padding: "30px 30px 20px 30px" }}>
                            <div style={{ width: '100%', marginBottom: "30px", fontSize: '13px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span>Are you sure you want to logout?</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px' }}>
                                <div className="disBtn" style={{ width: '50px', fontSize: '13px', fontWeight: 'bold', cursor: 'pointer' }}
                                    onClick={() => setShowLogout(false)} >
                                    No
                                </div>
                                <div className="ediBtn" style={{ width: '50px', fontSize: '13px', fontWeight: 'bold', cursor: 'pointer', color: 'white', backgroundColor: '#808080', border: '1px solid #808080' }}
                                    onClick={logMeOut}>
                                    Yes
                                </div>
                            </div>
                        </div>
                    </DialogOutClick>
                </>
        }
            <SnackbarUser
                open={showSnackBar}
                setHandleOpen={setShowSnackBar}
                severity={action === 'delete' ? "error" : action === 'disabled' ? "warning" : action === 'enabled' ? "info" : ''}
                message={action === 'delete' ? "User deleted" : action === 'disabled' ? "User disabled" : action === 'enabled' ? "User enabled" : ''}
            />
        </>



    )
}
export default UserSetting
