
import React from 'react';
import CorporateLayout from "./CorporateLayout";



export default function CorpHelp() {

    return(
        <CorporateLayout>
             <div>hello</div>


        </CorporateLayout>
       
    )
}
