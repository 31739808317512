/* eslint-disable no-unused-vars */
import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BiLogOut, BiTimer, BiTransfer, BiWallet } from "react-icons/bi";
import { AuthContext } from "../../../../context/AuthContext";
import { MdDelete, MdPayment } from "react-icons/md";
import { FaPencilAlt, FaUsersCog } from "react-icons/fa";
import { RiAccountPinCircleLine } from "react-icons/ri";
import { GoChevronRight } from "react-icons/go";
import { FiPower, FiSettings } from "react-icons/fi";
import DialogOutClick from "../../../../components/DialogOutClick";
import { PuffLoader } from "react-spinners";
import Swal from "sweetalert2";
import axios from "axios";
import SecureLS from "secure-ls";
import { DashboardContext } from "../../../../context/Dashboard/DashboardProvider/dashboard.provider";
import { IoMdArrowRoundBack } from "react-icons/io";
import { AiTwotoneShop, AiOutlineClose } from "react-icons/ai";
import { TiMessages } from "react-icons/ti";
import { ConnContext } from "../../../../context/ConnContext";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactTooltip from "react-tooltip";
import DialogPop from "../../../../components/DialogPop";
import Grid from "@material-ui/core/Grid";
import Multiselect from "multiselect-react-dropdown";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { AiFillDelete } from "react-icons/ai";
import FormLabel from "@mui/material/FormLabel";

import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import RegInput from "../../../../components/Reg_components/RegInput";
import imageCompression from "browser-image-compression";
import TopBar from "../../../../components/TopBar/TopBar";

import { useTheme } from "@mui/material/styles";

import GlobalizationLanguage from "../../../../components/GlobalizationLanguage";
function AccountSetting() {
  var login = GlobalizationLanguage("/login");
  var wallet = GlobalizationLanguage("/wallet");
  var accountusers = GlobalizationLanguage("/account/users");
  var accountsettlement = GlobalizationLanguage("/account/settlement");
  var accounttransaction = GlobalizationLanguage("/account/transaction");
  var deliveries_history = GlobalizationLanguage("/deliveries_history");
  var messages = GlobalizationLanguage("/messages");
  const forgetpass = GlobalizationLanguage("/forgetpass");
  const Dashboard = GlobalizationLanguage("/Dashboard");

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const {
    dashboards,
    updateDashboard,
    update_mean_of_delivery,
    update_cop_logo,
  } = useContext(DashboardContext);
  const [showlist, setshowlist] = useState(false);
  const [lastitem, setlastitem] = useState(false);
  const [selectedProof, setselectedProof] = useState("nil");
  const [updatingLogo, setupdatingLogo] = useState(false);
  const [prefId, setprefId] = useState("");

  const options2 = [
    { label: "Bike", id: 1 },
    { label: "Car", id: 2 },
    // { label: "Tricycle", id: 3 },
    { label: "Truck", id: 4 },
    { label: "Van", id: 5 },
  ];
  const [data, setData] = useState(options2);

  const toggle = (index, item) => {
    if (validdata.indexOf(item.label.toLowerCase()) === -1) {
      validdata.push(item.label.toLowerCase());
      setupdateMode(!updateMode);
    } else {
      if (validdata.length === 1) {
        setlastitem(true);
      } else {
        validdata.splice(validdata.indexOf(item.label.toLowerCase()), 1);
        setupdateMode(!updateMode);
      }
    }
  };

  const [mssgState, setMssgState] = useState("");

  const [editFormID, seteditFormID] = useState(false);

  const auth = useContext(AuthContext);
  const { visitingCountryCode } = useContext(ConnContext);
  const [mode_of_transport, setMode_of_transport] = useState([]);

  const [modeValid, setmodeValid] = useState(false);

  const history = useHistory();
  const [showLogout, setShowLogout] = useState(false);
  const [showDeteleAcct, setShowDeteleAcct] = useState(false);
  const [loading, setloading] = useState(false);
  const [deleteInput, setdeleteInput] = useState("");

  const [account, setaccount] = useState([]);
  const [userProfile, setuserProfile] = useState([]);

  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [form_of_id, setForm_of_id] = useState({});
  const [form_of_id_dup, setForm_of_id_dup] = useState({});
  const [form_of_id_dup_to_send, setForm_of_id_dup_to_send] = useState("");

  const [validity, setvalidity] = useState("");
  const [bankDetails, setbankDetails] = useState({});

  const [profileMessage, setprofileMessage] = useState("");
  const [means_of_delivery, setmeans_of_delivery] = useState([]);
  const [validdata, setvaliddata] = useState([]);

  const [preferrence, setpreferrence] = useState([])
  const [registered_country, setregistered_country] = useState([])
  const [editpref, seteditpref] = useState(false)
  const [prefupdate, setprefupdate] = useState(false)

  const useStyles = makeStyles((theme) => ({
    btn: {
      backgroundColor: mssgState !== "reviewing" ? "var(--main)" : "#b2b2b2",
      borderRadius: "6px",
      padding: "4px 12px",
      color: "white",
      textTransform: "none",
      "&:hover": {
        background: "var(--lighty)",
      },
    },
  }));

  const classes = useStyles();

  const onRemove = (selectedList, selectedItem) => {
    if (selectedList.length === 0) {
      setmodeValid(false);
    } else {
      setmodeValid(true);
    }
    setMode_of_transport(selectedList);
  };

  const onSelect = (selectedList, selectedItem) => {
    if (selectedList.length === 0) {
      setmodeValid(false);
    } else {
      setmodeValid(true);
    }
    setMode_of_transport(selectedList);
  };

  const CustomStyle = {
    chips: {
      background: "grey",
      borderWidth: 0,
    },
    option: {
      background: "none",
      color: "black",
      margin: "10px",
    },
    borderWidth: 0,
  };

  const options = ["Bike", "Car", "Van", "Truck"];

  const [updateMode, setupdateMode] = useState(false);
  const [updating, setupdating] = useState(false);

  async function HandleLogoUpdate(e) {
    setupdatingLogo(true);
    const file = e.target.files[0];
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 512,
    };
    const output = await imageCompression(file, options);

    const dataa = await imageCompression.getDataUrlFromFile(output);

    const img = dataa.split(",");

    authAxios
      .post("/m-fulfiller-document-upload", {
        action_type: "cop_logo",
        cop_logo: img[1],
      })
      .then((response) => {
        if (process.env.REACT_APP_ENVIRONMENT !== "PROD") {
          console.log(response);
        }
        setupdatingLogo(false);

        setuserProfile({ ...userProfile, cop_logo: dataa });

        update_cop_logo(dataa);
      })
      .catch((err) => {
        console.log(err, "error");
        setupdatingLogo(false);
      });
  }

  async function HandleImageUpload(e) {
    const file = e.target.files[0];
    var options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 512,
    };
    const output = await imageCompression(file, options);

    const dataa = await imageCompression.getDataUrlFromFile(output);

    setForm_of_id_dup({ ...form_of_id_dup, form_of_id: dataa });

    const img = dataa.split(",");

    setForm_of_id_dup_to_send(img[1]);
  }

  const UpdateData = () => {
    setloading(false);
    setupdating(true);
    validdata?.length !== 0 &&
      authAxios
        .post("/m-fulfiller-document-upload", {
          action_type: "means_of_transport",
          fulfiller_id: userProfile.account_name,
          mode_of_delivery: validdata,
        })
        .then((response) => {
          if (process.env.REACT_APP_ENVIRONMENT !== "PROD") {
            // console.log(response);
          }

          update_mean_of_delivery(validdata);
          setupdating(true);
          if (response?.status === 200) {
            setupdating(false);
            Swal.fire({
              icon: "success",
              text: ` Updated successfully`,
              //   timer: 2500,
              showConfirmButton: "true",
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              // if (result.value) {
              //   setloading(true)
              window.location.reload();
              // }
              setloading(false);
              setupdating(false);
              setshowlist(!showlist);
            });
          }
        })
        .catch((err) => {
          console.log(err, "error");
        });
  };


  const savecom = ()=>{
    setprefupdate(true)
    if( pref === "single"){
      authAxios
      .post(`m-dillivry-communication-preference?vcc=${registered_country[0].toLowerCase()}&attribute=set_preference`, {
            src_state: storestate, 
            dst_state: storestate
      })
      .then((response)=>{
        setprefupdate(false)

        if (response?.status === 200) {
          // setupdating(false);
          Swal.fire({
            icon: "success",
            text: ` Updated successfully`,
            //   timer: 2500,
            showConfirmButton: "true",
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            // if (result.value) {
            //   setloading(true)
               window.location.reload();
            // }
            // setloading(false);
            // setupdating(false);
            // setshowlist(!showlist);
          });
        }
    
        

      })
      // console.log( storestate,'hello1');

    }
    else if( pref === "multiple"){

      authAxios
      .post(`m-dillivry-communication-preference?vcc=${registered_country[0].toLowerCase()}&attribute=set_preference`, {
            src_state: storestate, 
            dst_state: storestate2
      })
      .then((response)=>{
        setprefupdate(false)
        if (response?.status === 200) {
          Swal.fire({
            icon: "success",
            text: ` Updated successfully`,
            showConfirmButton: "true",
            confirmButtonColor: "var(--main)",
          }).then((result) => {
               window.location.reload();
          });
        }
      })
    }
    else{
      nationWide()
    }
   

  
   
    // console.log('hello1');
    // console.log('hello1');
  }




  const nationWide = async ()=>{
    
    for(let i = 0; i < preferrence.length; i++){
      authAxios
      .delete(`m-dillivry-communication-preference?vcc=${registered_country[0].toLowerCase()}&id=${preferrence[i]._id}`)
      .then((response)=>{
        if (response?.status === 200) {
        }
      })
        }  
        setInterval(() => {
          window.location.reload();
          setprefupdate(false)
        }, 10000);
       
      }


  const deletepref = (id)=>{
    authAxios
    .delete(`m-dillivry-communication-preference?vcc=${registered_country[0].toLowerCase()}&id=${id}`)
    .then((response)=>{
      if (response?.status === 200) {
       
        Swal.fire({
          icon: "success",
          text: ` Updated successfully`,
          showConfirmButton: "true",
          confirmButtonColor: "var(--main)",
        }).then((result) => {
            window.location.reload();
        });
      }
    })
  }


  const updateDriveLicence = (data) => {
      authAxios
        .post("/m-fulfiller-document-upload", {
          action_type: "proof_of_identity",
          form_of_id_sn: data?.driver_license,
          proof_of_id: form_of_id_dup_to_send,
          start_date: data?.valid_from,
          end_date: data?.valid_to,
        })
        .then((response) => {
          if (process.env.REACT_APP_ENVIRONMENT !== "PROD") {
            // console.log(response);
          }
          if (response?.status === 200) {
            setupdating(false);
            Swal.fire({
              icon: "success",
              text: ` Updated successfully`,
              //   timer: 2500,
              showConfirmButton: "true",
              confirmButtonColor: "var(--main)",
            }).then((result) => {
                 window.location.reload();
            });
          }
        })
        .catch((err) => {
          console.log(err, "error");
        });
  };

  const updateNIN = (data) => {
    authAxios
      .post("/m-fulfiller-document-upload", {
        action_type: "proof_of_identity",
        form_of_id_sn: data?.national_id_card,
        proof_of_id: form_of_id_dup_to_send,
        start_date: "",
        end_date: "",
      })
      .then((response) => {
        if (process.env.REACT_APP_ENVIRONMENT !== "PROD") {
          // console.log(response);
        }
        if (response?.status === 200) {
          setupdating(false);
          Swal.fire({
            icon: "success",
            text: ` Updated successfully`,
            //   timer: 2500,
            showConfirmButton: "true",
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const updateInterPassport = (data) => {
    authAxios
      .post("/m-fulfiller-document-upload", {
        action_type: "proof_of_identity",
        form_of_id_sn: data?.international_passport,
        proof_of_id: form_of_id_dup_to_send,
        start_date: data?.valid_from,
        end_date: data?.valid_to,
      })
      .then((response) => {
        if (process.env.REACT_APP_ENVIRONMENT !== "PROD") {
          // console.log(response);
        }
        if (response?.status === 200) {
          setupdating(false);
          Swal.fire({
            icon: "success",
            text: ` Updated successfully`,
            //   timer: 2500,
            showConfirmButton: "true",
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const UpdateData2 = (data) => {
    data?.length !== 0 &&
      authAxios
        .post("/m-fulfiller-document-upload", {
          action_type: "means_of_transport",
          fulfiller_id: userProfile.account_name,
          mode_of_delivery: data,
        })
        .then((response) => {
          if (process.env.REACT_APP_ENVIRONMENT !== "PROD") {
            // console.log(response);
          }
          update_mean_of_delivery(data);
        })
        .catch((err) => {
          console.log(err, "error");
        });
  };

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: ls.get("_nodi"),
    },
  });

  const UpdateMSSG = () => {
    if (profileMessage !== "") {
      authAxios
        .post("/m-fulfiller-document-upload", {
          action_type: "msg_profile",
          msg_profile: profileMessage,
        })
        .then((response) => {
          Swal.fire({
            icon: "success",
            text: "Profile message submitted for review",
            timer: 2500,
            showConfirmButton: false,
          });
        })
        .catch((err) => {
          console.log(err, "error");
        });
      setMssgState("reviewing");
    }
  };

  const updatePage = (dashData) => {
    ls.set(
      "means_of_delivery",
      JSON.stringify(
        dashData?.data?.account_profile?.account_profile[0]?.mode_of_delivery
      )
    );
    // console.log(dashData?.data.account_profile?.account_profile[0]?.preference)
    setpreferrence(dashData?.data.account_profile?.account_profile[0]?.preference)
    setregistered_country(dashData?.data.account_profile?.account_profile[0]?.registered_countries)
    setaccount(dashData.data.my_account_info.my_account_info);
    setuserProfile(dashData.data.account_profile.account_profile[0]);
    setFullname(
      dashData.data.my_account_info.my_account_info[0].first_name +
        " " +
        dashData.data.my_account_info.my_account_info[0].last_name
    );
    setEmail(dashData.data.my_account_info.my_account_info[0].user_id);
    setPhone(dashData.data.my_account_info.my_account_info[0]?.phone_number);
    setmeans_of_delivery(
      dashData?.data?.account_profile?.account_profile[0]?.mode_of_delivery
    );

    setbankDetails(
      dashData.data.account_profile.account_profile[0]?.bank_detail
    );

    setForm_of_id(
      dashData.data.account_profile.account_profile[0]?.proof_of_identity
    );

    setForm_of_id_dup(
      dashData.data.account_profile.account_profile[0]?.proof_of_identity
    );

    setvaliddata(
      dashData?.data?.account_profile?.account_profile[0]?.mode_of_delivery
    );

    const profileMessage =
      dashData.data.account_profile.account_profile[0]?.profile_message
        ?.msg_profile;
    setprofileMessage(profileMessage === "not uploaded" ? "" : profileMessage);
    const profileStatus =
      dashData.data.account_profile.account_profile[0]?.profile_message?.state;
    setMssgState(
      profileMessage === "not uploaded" ||
        (profileMessage === "" && profileStatus === "not uploaded")
        ? "empty"
        : profileStatus === "Pending admin review"
        ? "reviewing"
        : profileStatus === "not_valid"
        ? "not_valid"
        : "noEmpty"
    );
  };
  const [storeCountry, setstoreCountry] = useState("Nigeria");
  const [storestate, setstorestate] = useState("Lagos");
  const [storestate2, setstorestate2] = useState("Lagos");
  const [allstate, setallstate] = useState([]);
  const [allCountry, setallCountry] = useState([]);
  const [triggereffect, settriggereffect] = useState(false);
  const [tokenn, settokenn] = useState("false");
  const [selection, setselection] = useState("");
  const [pref, setpref] = useState("all");
  const [comm, setcomm] = useState(false);

  useEffect(() => {
    countryState();
    setloading(false);
    if (dashboards !== undefined) {
      updatePage(dashboards);
    } else {
      if (
        ls.get("_iRl") === "" ||
        ls.get("_iRl") === "dillivry-sole-trader-ops"
      ) {
        history.push(login);
      } else {
        setloading(true);
        axios
          .get(`${endpoint}m-dillivry-dashboard-manager`, {
            headers: {
              Authorization: ls.get("_nodi"),
            },
          })
          .then((response) => {
            // console.log(response,'ss')
            setloading(false);
            // history.push(Dashboard)
            updateDashboard(response);
            updatePage(response);
          })
          .catch((err) => {
            setloading(false);
            console.log(err, "error");
          });
      }
    }
  }, [triggereffect]);

  const countryState = () => {
    axios
      .get("https://www.universal-tutorial.com/api/getaccesstoken", {
        headers: {
          Accept: `application/json`,
          "api-token":
            "3G4kiq7Eo3QOGPoJaqmN_id4E1jTQ5q-lNbvk3JhKXtXV3NX10eg9nm22xyNjXczD88",
          "user-email": "adeolarhowolabi@gmail.com",
        },
      })
      .then((res) => {
        const token = res.data.auth_token;
        settokenn(res.data.auth_token);

        if (res.status === 200) {
          axios
            .get("https://www.universal-tutorial.com/api/countries/", {
              headers: {
                Authorization: `Bearer ${token} `,
                Accept: "application/json",
              },
            })
            .then((res) => {
              // console.log(res, "country-dara")
              callState(token, storeCountry);
              setallCountry(res.data);
            })
            .catch((err) => {
              console.log(err, "err");
            });
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };
  const handleSelection = (e) => {
    setselection(e.target.value);
    if (e.target.value === "single") {
      setpref("single");
      setcomm(true);
    } else if (e.target.value === "multiple") {
      setpref("multiple");
      setcomm(true);
    } else {
      setpref("all");
      setcomm(true);
    }
    // console.log(e.target.value,"llll")
  };

  const handleCountry = (e) => {
    setstoreCountry(e.target.value);
    callState(tokenn, e.target.value);
    console.log(e.target.value, "ee");
  };

  const callState = (token, cnt) => {
    // console.log(storeCountry,"cffff")

    axios
      .get(`https://www.universal-tutorial.com/api/states/${cnt}`, {
        headers: {
          Authorization: `Bearer ${token} `,
          Accept: "application/json",
        },
      })
      .then((res) => {
        // console.log(res,"state")
        setallstate(res.data);
      });
  };

  const logMeOut = () => {
    auth.logout();
    history.push("/");
  };

  const endpoint = process.env.REACT_APP_API;

  const disableAccount = () => {
    axios
      .delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
        headers: {
          Authorization: ls.get("_nodi"),
        },
        data: {
          action_type: "disable_account",
          vcc: registered_country[0],
        },
      })
      .then((res) => {
        setShowDeteleAcct(false);
        Swal.fire({
          title: "Account disabled",
          text: "contact the dillivry team to enable you account back",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            auth.logout();
            history.push("/");
          }
        });
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              auth.logout();
              history.push(login);
            }
          });
        } else {
          Swal.fire({
            icon: "info",
            text: "An error occur try again",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      });
  };

  const deleteMyAccount = () => {
    setloading(true);
    axios
      .delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
        headers: {
          Authorization: ls.get("_nodi"),
        },
        data: {
          action_type: "delete_account",
          vcc: registered_country[0],
        },
      })
      .then((res) => {
        setShowDeteleAcct(false);

        Swal.fire({
          title: "Account deleted",
          text: "We are very sorry to see you go",
          allowOutsideClick: false,
          confirmButtonColor: "var(--main)",
        }).then((result) => {
          if (result.value) {
            auth.logout();
            history.push("/");
          }
        });
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              auth.logout();
              history.push(login);
            }
          });
        } else {
          Swal.fire({
            icon: "info",
            text: "An error occur try again",
            timer: 3000,
            showConfirmButton: false,
          });
        }
      });
  };

  const schema1 = yup.object().shape({
    international_passport: yup
      .string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/,
        "Must contain alphabet and numbers"
      )
      .min(9, "Must be exactly 9 digits")
      .max(9, "Must be exactly 9 digits"),
  });

  const schemaDrive = yup.object().shape({
    driver_license: yup
      .string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]/,
        "Must contain alphabet and numbers"
      )
      .max(24, "Can't be more than 24 digit"),
  });

  const schemaNIN = yup.object().shape({
    national_id_card: yup
      .string()
      .min(11, "Can't be lesser than 11 digits")
      .max(11, "Can't be more than 11 digits")
      .matches(/^$|^[0-9]*$/, "Can't contain letter or special character"),
  });

  const {
    control: control1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema1),
  });

  const {
    control: controlDrive,
    handleSubmit: handleSubmitDrive,
    formState: { errors: errorsDrive },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schemaDrive),
  });

  const {
    control: controlNIN,
    handleSubmit: handleSubmitNIN,
    formState: { errors: errorsNIN },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schemaNIN),
  });

  return (
    <>
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100vh",
            backgroundColor: "none",
            zIndex: "300",
            position: "fixed",
            top: "0",
            paddingTop: "5px",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <LinearProgress color="success" />
          </Box>
        </div>
      ) : null}

      <div className="dashboardDesktop">
        <div className="topmenu">
          <TopBar />
        </div>
        <div className="content-view_menu">
          <div className="tab" onClick={() => history.push(Dashboard)}>
            <AiTwotoneShop /> &nbsp; Dillivry Activities
          </div>

          <div className="tab" onClick={() => history.push(wallet)}>
            <BiWallet /> &nbsp; Wallet
          </div>

          <div className="tab" onClick={() => history.push(messages)}>
            <TiMessages /> &nbsp; Messages
          </div>
          <div className="tab" onClick={() => history.push(deliveries_history)}>
            <BiTimer /> &nbsp; Quote history
          </div>
          <div className="activeTabs">
            <RiAccountPinCircleLine /> &nbsp; Account settings
          </div>
        </div>
      </div>

      <div className="Fulfiller_account">
        <div className="_account">
          {account.map((acct, index) => {
            return (
              <div
                style={{
                  display: "flex",
                  paddingTop: "20px",
                  flexDirection: "column",
                  gap: "20px",
                }}
                key={index}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div
                    className="tapps"
                    onClick={() => history.push(accounttransaction)}
                  >
                    <div>
                      <BiTransfer /> Transactions
                    </div>
                    <GoChevronRight />
                  </div>

                  {account[0]?.role === "dillivry-corp-sys-admin" ? (
                    <>
                      <div
                        className="tapps"
                        onClick={() => history.push(accountsettlement)}
                      >
                        <div>
                          <MdPayment /> Settlements
                        </div>
                        <GoChevronRight />
                      </div>

                      <div
                        className="tapps"
                        onClick={() => history.push(accountusers)}
                      >
                        <div>
                          <FaUsersCog /> User Manager
                        </div>
                        <GoChevronRight />
                      </div>
                    </>
                  ) : null}

                  <div className="activeTapps">
                    <div>
                      <FiSettings />
                      Settings
                    </div>
                    <GoChevronRight />
                  </div>

                  {account[0]?.role === "dillivry-corp-sys-admin" ? (
                    <div
                      className="tapps"
                      onClick={() => {
                        setShowDeteleAcct(true);
                      }}
                    >
                      <div>
                        <MdDelete />
                        Delete my account
                      </div>
                      <GoChevronRight />
                    </div>
                  ) : null}

                  <div
                    className="tapps"
                    onClick={() => {
                      setShowLogout(true);
                    }}
                  >
                    <div>
                      <BiLogOut />
                      Logout
                    </div>
                    <GoChevronRight />
                  </div>
                </div>

                <div className="profileInfo">
                  <div className="profile">
                    <div
                      className="profileimg"
                      style={{
                        backgroundImage: `url(${userProfile?.cop_logo})`,
                      }}
                    ></div>
                    <div
                      className="profilename"
                      style={{ textAlign: "center" }}
                    >
                      {acct.account_name.charAt(0)?.toUpperCase() +
                        acct.account_name?.slice(1)}
                    </div>
                    <div className="profilelocation">{acct.account_id}</div>
                    <div className="profilenum">{acct.user_id}</div>
                    <div className="profilenum">
                      {account[0]?.role === "dillivry-corp-sys-admin"
                        ? "Admin(Corperate)"
                        : account[0]?.role === "dillivry-corp-bid-ops"
                        ? "Bidder(Business)"
                        : account[0]?.role === "dillivry-sole-trader-ops"
                        ? ""
                        : account[0]?.role === "dillivry-corp-last-mile-ops"
                        ? "Fulfilling Agent(Business)"
                        : null}
                    </div>

                    {acct?.account_status?.state === "active" ||
                    acct?.account_status?.state === "CONFIRMED" ? null : (
                      <div className="profilenum">
                        {acct?.account_status?.state}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
          <div className="context-past">
            <div className="context-past-right">
              <div className="column">
                <div className="columnHead">
                  <div className="headiing">
                    <div
                      className="right"
                      style={{ justifyContent: "flex-start" }}
                    >
                      Account
                    </div>
                    <div className="leftend">
                      <button onClick={() => setShowLogout(true)}>
                        <FiPower /> Logout
                      </button>
                    </div>
                  </div>
                </div>

                <div className="columnBody1">
                  <div className="informtion">
                    <div className="informed">
                      <div className="person_infos">
                        <div className="pers">Personal Info</div>
                        <div className="pers_img">
                          {updatingLogo ? (
                            <div style={{ width: "50px", height: "50px" }}>
                              <PuffLoader
                                color="var(--main)"
                                loading={updatingLogo}
                                speedMultiplier={1}
                                size={50}
                              />
                            </div>
                          ) : (
                            <>
                              <img
                                src={userProfile?.cop_logo}
                                alt=""
                                width="50px"
                                height="50px"
                              />
                              <label htmlFor="update_logo">
                                <FaPencilAlt />
                              </label>
                              <input
                                type="file"
                                name="update_logo"
                                id="update_logo"
                                style={{ display: "none" }}
                                accept="image/x-png,image/gif,image/jpeg"
                                onChange={HandleLogoUpdate}
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="formation">
                        <div className="infoTitle">NAME</div>
                        <div className="infoDetails">
                          {fullname?.charAt(0)?.toUpperCase() +
                            fullname?.slice(1)?.toLowerCase()}
                        </div>
                        <div className="divida"></div>
                      </div>

                      <div className="formation">
                        <div className="infoTitle">EMAIL</div>
                        <div className="infoDetails">
                          {email?.charAt(0)?.toUpperCase() +
                            email?.slice(1)?.toLowerCase()}
                        </div>
                        <div className="divida"></div>
                      </div>

                      <div className="formation">
                        <div className="infoTitle">PHONE</div>
                        <div className="infoDetails">{phone}</div>
                        <div className="divida"></div>
                      </div>

                      <div className="formation">
                        <div className="infoTitle">MEANS OF TRANSPORTATION</div>
                        <div className="infoDetails-">
                          <div className="infodetailsflex">
                            <div className="category_container">
                              <div className="category_selector-">
                                <span>
                                  <div className="spanandclose">
                                    <div className="span-text-">
                                      {validdata.map((x, index) => (
                                        <ul className="span-text-ul">
                                          <li>
                                            {x}{" "}
                                            <AiOutlineClose
                                              size="0.7em"
                                              onClick={
                                                validdata.length === 1
                                                  ? () => setlastitem(true)
                                                  : () => {
                                                      return (
                                                        setmeans_of_delivery(
                                                          means_of_delivery.filter(
                                                            (item) => item !== x
                                                          )
                                                        ),
                                                        setvaliddata(
                                                          means_of_delivery.filter(
                                                            (item) => item !== x
                                                          )
                                                        ),
                                                        UpdateData2(
                                                          means_of_delivery.filter(
                                                            (item) => item !== x
                                                          )
                                                        ),
                                                        setshowlist(!showlist)
                                                      );
                                                    }
                                              }
                                            />{" "}
                                          </li>
                                        </ul>
                                      ))}
                                    </div>

                                    <div className="close-icon-"></div>
                                  </div>
                                </span>

                                <span
                                  className="select_icon"
                                  onClick={() => setshowlist(!showlist)}
                                >
                                  {showlist ? (
                                    <BsChevronUp />
                                  ) : (
                                    <BsChevronDown />
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="divida"></div>
                      </div>

                      {showlist && (
                        <div className="formation">
                          <div className="infoTitle">
                            <form>
                              {data.map((item, index) => (
                                <ul>
                                  <label
                                    key={item.label}
                                    className="check_container"
                                    style={{
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      fontSize: "16px",
                                      color: "#484848",
                                    }}
                                  >
                                    <input
                                      readOnly
                                      checked={
                                        validdata.includes(
                                          item.label.toLowerCase()
                                        )
                                          ? true
                                          : false
                                      }
                                      onClick={() => toggle(index, item)}
                                      type="checkbox"
                                    />
                                    {item.label}
                                    <span
                                      style={{ height: "18px", width: "18px" }}
                                      className="checkmark  check "
                                    ></span>
                                  </label>
                                </ul>
                              ))}
                            </form>
                          </div>
                          <div className="infoDetails"></div>
                          <div className="divida"></div>
                        </div>
                      )}

                      <div className="formation1">
                        <div className="infoTitle">PROFILE MESSAGE</div>
                        <div className="infoDetails">
                          <div className="profileMssg">
                            {mssgState === "not_valid" ? (
                              <div
                                style={{
                                  color: "maroon",
                                  marginBottom: "12px",
                                  fontSize: "12px",
                                  textTransform: "none",
                                }}
                              >
                                Your profile message content was rejected, edit
                                and upload again for review
                              </div>
                            ) : null}
                            <textarea
                              rows="4"
                              style={{
                                width: "100%",
                                border:
                                  mssgState === "editing"
                                    ? "0.5px solid #b2b2b2"
                                    : mssgState === "not_valid"
                                    ? "0.5px solid red"
                                    : "none",
                                lineHeight: "20px",
                                fontWeight: "500",
                                color: "#616161",
                                backgroundColor: "white",
                              }}
                              value={profileMessage}
                              onChange={(e) =>
                                setprofileMessage(e.target.value)
                              }
                              disabled={mssgState !== "editing"}
                              placeholder={
                                loading
                                  ? ""
                                  : "Click add profile message button and enter your profile message here, click on update button to submit message for review"
                              }
                            />
                          </div>
                          <div className="mssgeBtn">
                            <Button
                              className={classes.btn}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                mssgState === "reviewing" ? (
                                  <></>
                                ) : mssgState === "empty" ||
                                  mssgState === "noEmpty" ||
                                  mssgState === "not_valid" ? (
                                  setMssgState("editing")
                                ) : (
                                  UpdateMSSG()
                                );
                              }}
                            >
                              {mssgState === "empty"
                                ? "Add profile message"
                                : mssgState === "noEmpty" ||
                                  mssgState === "not_valid"
                                ? "Edit profile message"
                                : mssgState === "editing"
                                ? "Update profile message"
                                : mssgState === "reviewing"
                                ? "In review..."
                                : "Update profile message"}
                            </Button>
                          </div>
                        </div>
                        <div className="divida"></div>
                      </div>

                      <div className="formation1">
                        <div className="preference">
                            <div className="infoTitle">PREFERRED COMMUNICATION</div>
                              <div className="infoDetailsX"> 
                              <div>

                                 <h4 style={{marginBottom:"10px"}}>Curent preferrence:</h4> 
                                 <div>
                                 {
                                   preferrence.length === 0 ?
                                   
                                   <div>Nationwide</div>
                                   :
                                   <>
                                  
                                   {
                                        preferrence.map((x,i) => (
                                          <div key={i}>{x.selections[0]} - {x.selections[1]} <AiFillDelete onClick={()=> {deletepref(x._id);  setprefId(x._id)}}/></div>
                                        )) 

                                   }
                                    </>
                                             
                                }

                                 </div>
                              

                          <button onClick={()=> seteditpref(!editpref)}
                            style={{
                            background: "#50B14A",
                            borderRadius: "5px",
                            width: "70px",
                            height: "28px",
                            color: "#ffffff",
                            border: "none",
                            cursor:"pointer",
                            marginTop:"10px"
                            
                          }}
                        // > {editpref ? "loading..": "Edit"}</button>
                        > Edit</button>

                              </div>

                              {
                                    editpref ?
                                    <>
                                  <FormControl >
                                    <FormLabel  style={{marginBottom:"10px"}} id="demo-radio-buttons-group-label">Specify the states you want to receive new delivery alert for: </FormLabel>
                                    <RadioGroup
                                      aria-labelledby="demo-radio-buttons-group-label"
                                      defaultValue={selection}
                                      name="radio-buttons-group"
                                      style={{paddingLeft:"10px"}}
                                      onChange={(e)=> handleSelection(e)}
                                      
                                    
                                    >
                                      <FormControlLabel style={{color:"gray"}} value="single" control={<Radio  style={{color:"var(--main)", marginRight:"10px"}} />} label="Receive alert for delivery in my state" />
                                      <FormControlLabel style={{color:"gray"}}  value="multiple" control={<Radio  style={{color:"var(--main)" , marginRight:"10px"}} />} label="Receive alert for delivery in and outside my state" />
                                      <FormControlLabel style={{color:"gray"}}  value="all" control={<Radio  style={{color:"var(--main)" , marginRight:"10px"}} />} label="Receive alert for delivery nationwide" />
                                    </RadioGroup>
                                  </FormControl>
                                  {
                                          pref === "single" ?
                                          <div className="infoDetails22">
                                              <div className="settingsCountry">
                                                    <FormControl size="small" sx={{ m: 1, minWidth: 200 }} variant="standard"  style={{width:"200px", color:"var(--main)"}}>
                                                      <InputLabel id="demo-simple-select-autowidth-label"> Select your Country</InputLabel>
                                                      <Select
                                                        labelId="demo-simple-select-autowidth-label"
                                                        id="demo-simple-select-autowidth"
                                                        value={storeCountry}
                                                        onChange={(e)=> handleCountry(e)}
                                                        // onChange={(e)=> console.log(e.target.value,"valewww")}
                                                        autoWidth
                                                        label="Country"
                                                      >
                                                        {
                                                          allCountry.map((x,i)=>(
                                                            <MenuItem key={i}  value={x.country_name}><span ><img src={`https://countryflagsapi.com/svg/${x.country_short_name}`} width='20px' style={{marginRight:"10px"}} />{x.country_name}</span></MenuItem>
                                                          ))
                                                        }
                                                      
                                                      </Select>
                                                    </FormControl>
                                              </div>

                                              <div className="settingState">
                                                  <FormControl sx={{ m: 1, minWidth: 200 }} variant="standard" >
                                                      <InputLabel id="demo-simple-select-autowidth-label"> Select your State</InputLabel>
                                                      <Select
                                                        labelId="demo-simple-select-autowidth-label"
                                                        id="demo-simple-select-autowidth"
                                                        value={storestate}
                                                        onChange={(e)=> setstorestate(e.target.value)}
                                                        autoWidth
                                                        label="State"
                                                      >
                                                        
                                                        {
                                                          allstate.map((x,i)=>(
                                                        

                                                          
                                                              <MenuItem key={i} value={x.state_name}>{x.state_name}</MenuItem>
                                                            

                                                        

                                                          ))
                                                        }
                                                      
                                                      </Select>
                                                    </FormControl>

                                              </div>
                                              {/* <button className="prefBtn">submit</button> */}
                                          </div>
                                      :
                                      pref === "multiple" ?
                                      <>
                                        <div className="infoDetails22">
                                          <div className="settingsCountry">
                                                <FormControl size="small" sx={{ m: 1, minWidth: 200 }} variant="standard"  style={{width:"200px"}}>
                                                  <InputLabel id="demo-simple-select-autowidth-label">Select your Country</InputLabel>
                                                  <Select
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={storeCountry}
                                                    onChange={(e)=> handleCountry(e)}
                                                    // onChange={(e)=> console.log(e.target.value,"valewww")}
                                                    autoWidth
                                                    label="Country"
                                                  >
                                                    {/* <MenuItem value={10}>hello</MenuItem>
                                                    <MenuItem value={20}>he2llo</MenuItem> */}
                                                    {
                                                      allCountry.map((x,i)=>(
                                                      
                                                          <MenuItem key={i}  value={x.country_name}><span ><img src={`https://countryflagsapi.com/svg/${x.country_short_name}`} width='20px' style={{marginRight:"10px"}} />{x.country_name}</span></MenuItem>

                                                      
                                                      
                                                          
                                                          
                                                        

                                                      

                                                      ))
                                                    }
                                                  
                                                  </Select>
                                                </FormControl>
                                          </div>

                                          <div className="settingState">
                                              <FormControl sx={{ m: 1, minWidth: 200 }} variant="standard" >
                                                  <InputLabel id="demo-simple-select-autowidth-label"> Select your source State</InputLabel>
                                                  <Select
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={storestate}
                                                    onChange={(e)=> setstorestate(e.target.value)}
                                                    autoWidth
                                                    label=" Select your State"
                                                  >
                                                    
                                                    {
                                                      allstate.map((x,i)=>(
                                                    

                                                      
                                                          <MenuItem key={i} value={x.state_name}>{x.state_name}</MenuItem>
                                                        

                                                    

                                                      ))
                                                    }
                                                  
                                                  </Select>
                                                </FormControl>

                                          </div>
                                          
                                          {/* <div className="settingState">
                                          <FormControl sx={{ m: 1, width: 300 }}>
                                              <InputLabel id="demo-multiple-chip-label">Select other states</InputLabel>
                                              <Select
                                                labelId="demo-multiple-chip-label"
                                                id="demo-multiple-chip"
                                                multiple
                                                value={personName}
                                                onChange={handleChange}
                                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                renderValue={(selected) => (
                                                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected.map((value) => (
                                                      <Chip key={value} label={value} />
                                                    ))}
                                                  </Box>
                                                )}
                                                MenuProps={MenuProps}
                                              >
                                                {allstate.map((name) => (
                                                  <MenuItem
                                                    key={name}
                                                    value={name.state_name}
                                                    style={getStyles(name, personName, theme)}
                                                  >
                                                    {name.state_name}
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>

                                          </div> */}

                                            <div className="settingState">
                                              <FormControl sx={{ m: 1, minWidth: 200 }} variant="standard" >
                                                  <InputLabel id="demo-simple-select-autowidth-label"> Select your destination State</InputLabel>
                                                  <Select
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={storestate2}
                                                    onChange={(e)=> setstorestate2(e.target.value)}
                                                    autoWidth
                                                    label=" Select your State"
                                                  >
                                                    
                                                    {
                                                      allstate.map((x,i)=>(
                                                    

                                                      
                                                          <MenuItem key={i} value={x.state_name}>{x.state_name}</MenuItem>
                                                        

                                                    

                                                      ))
                                                    }
                                                  
                                                  </Select>
                                                </FormControl>

                                          </div>

                                        
                                      </div>

                               
                                      </>
                                      :
                                      <div className="infoDetails22">
                                        <div className="settingsCountry">
                                              <FormControl sx={{ m: 1, minWidth: 200 }} variant="standard"  style={{width:"200px"}}>
                                                <InputLabel id="demo-simple-select-autowidth-label">Select your Country</InputLabel>
                                                <Select
                                                  labelId="demo-simple-select-autowidth-label"
                                                  id="demo-simple-select-autowidth"
                                                  value={storeCountry}
                                                  onChange={(e)=> handleCountry(e)}
                                                  // onChange={(e)=> console.log(e.target.value,"valewww")}
                                                  autoWidth
                                                  label="Country"
                                                >
                                                  {/* <MenuItem value={10}>hello</MenuItem>
                                                  <MenuItem value={20}>he2llo</MenuItem> */}
                                                  {
                                                    allCountry.map((x,i)=>(
                                                    
                                                      <MenuItem key={i}  value={x.country_name}><span ><img src={`https://countryflagsapi.com/svg/${x.country_short_name}`} width='20px' style={{marginRight:"10px"}} />{x.country_name}</span></MenuItem>

                                                    
                                                    
                                                        
                                                        
                                                      

                                                    

                                                    ))
                                                  }
                                                
                                                </Select>
                                              </FormControl>
                                        </div>
                                      </div>
                                  }

                                  <div style={{display:"flex", justifyContent:"flex-end"}}>

                                  {comm  ? (
                        <button
                        onClick={()=> savecom()}
                         
                          style={{
                            background: "#50B14A",
                            borderRadius: "5px",
                            width: "70px",
                            height: "28px",
                            color: "#ffffff",
                            border: "none",
                            cursor:"pointer",
                            marginTop:"10px"
                          }}
                        >
                          {" "}
                          {prefupdate ? "loading..." : "Save"}{" "}
                        </button>
                      ) : (
                        <button
                          disabled
                          style={{
                            background: "#B2B2B2",
                            borderRadius: "5px",
                            width: "70px",
                            height: "28px",
                            color: "#ffffff",
                            border: "none",
                            marginTop:"10px"
                          }}
                        >
                          Save
                        </button>
                      )}
                                    
                                    
                                         
                                    
                                  </div>
                                  
                                
                                    
                            
                              
                                  </> 
                                  :
                                  null

}
                              
                              </div>


                        </div>

                        <div className="divida"></div>
                      </div>

                      <div className="formation">
                        <div className="infoTitle">FORM OF ID</div>
                        <div
                          className="infoDetails"
                          style={{
                            flexDirection: "row",
                            alignItems: "flex-end",
                            gap: "10px",
                          }}
                        >
                          <div
                            className="form_id"
                            style={{
                              backgroundImage: `url(${form_of_id_dup?.form_of_id})`,
                            }}
                          ></div>
                          {/* <span onClick={()=>seteditFormID(true)}><FaPencilAlt/></span> */}
                        </div>
                        <div className="divida"></div>
                      </div>

                      {/* <div className="formation">
                        <div className="infoTitle">VALIDITY PERIOD:</div>
                        <div className="infoDetails">{} </div>
                        <div className="divida"></div>
                      </div> */}

                      <div className="formation">
                        <div className="infoTitle">BANK DETAILS</div>
                        <div
                          className="infoDetails"
                          style={{ flexDirection: "column", gap: "7px" }}
                        >
                          <div>{bankDetails?.account_number}</div>
                          <div>{bankDetails?.bank_name}</div>
                          <div>{bankDetails?.account_name}</div>
                        </div>
                        {/* <div className="divida"></div> */}
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className="need"
                    style={{ color: "var(--main)", cursor: "pointer" }}
                  >
                    <span onClick={() => history.push(forgetpass)}>
                      Need to reset your password?{" "}
                    </span>
                  </div> */}

                  <div
                    className="need-"
                    style={{
                      padding: "10px 145px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ color: "var(--main)", cursor: "pointer" }}>
                      <span onClick={() => history.push(forgetpass)}>
                        Need to reset your password?{" "}
                      </span>
                    </div>

                    <div>
                      {/* {showlist  || comm ? ( */}
                      {showlist ? (
                        <button
                          onClick={
                            [...validdata].length !== 0 ? UpdateData : null
                          }
                          style={{
                            background: "#50B14A",
                            borderRadius: "5px",
                            width: "70px",
                            height: "28px",
                            color: "#ffffff",
                            border: "none",
                          }}
                        >
                          {" "}
                          {updating ? "loading..." : "Save"}{" "}
                        </button>
                      ) : (
                        <button
                          disabled
                          style={{
                            background: "#B2B2B2",
                            borderRadius: "5px",
                            width: "70px",
                            height: "28px",
                            color: "#ffffff",
                            border: "none",
                          }}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* 
                 <div
                    className="need-"
                   style={{ padding: "20px 165px", width: '100%', display: "flex", justifyContent: "space-between" }}
                  >
                    <div   style={{ color: "var(--main)", cursor: "pointer" }}><span onClick={() => history.push(forgetpass)}>
                      Need to reset your password?{" "}
                    </span></div>

                    <div> <button>Save</button>  </div>
                  </div> */}
            </div>
          </div>
        </div>
        {/* web view ends here */}

        {/* mobile view start here */}
        <div className="_accountMobile">
          <>
            <div style={{ display: "flex", marginBottom: "20px", gap: "20px" }}>
              <IoMdArrowRoundBack
                onClick={() => history.goBack()}
                size="24px"
              />
              <h2>Account</h2>
            </div>
            <div className="context-past-right">
              <div className="column">
                <div className="columnHead">
                  <div className="headiing">
                    <div className="right"></div>
                    <div className="leftend">
                      <button onClick={() => setShowLogout(true)}>
                        <FiPower /> Logout
                      </button>
                    </div>
                  </div>
                </div>

                <div className="columnBody1">
                  <div className="informtion">
                    <div className="informed">
                      <div className="person_infos">
                        <div className="pers">Personal Info</div>
                        <div className="pers_img">
                          {updatingLogo ? (
                            <div style={{ width: "40px", height: "40px" }}>
                              <PuffLoader
                                color="var(--main)"
                                loading={updatingLogo}
                                speedMultiplier={1}
                                size={40}
                              />
                            </div>
                          ) : (
                            <>
                              <img
                                src={userProfile?.cop_logo}
                                alt=""
                                width="40px"
                                height="40px"
                              />
                              <label htmlFor="update_logo">
                                <FaPencilAlt size="12px" />
                              </label>
                              <input
                                type="file"
                                name="update_logo"
                                id="update_logo"
                                style={{ display: "none" }}
                                accept="image/x-png,image/gif,image/jpeg"
                                onChange={HandleLogoUpdate}
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className="formation">
                        <div className="infoTitle">NAME</div>
                        <div className="infoDetails">
                          {fullname?.charAt(0)?.toUpperCase() +
                            fullname?.slice(1)?.toLowerCase()}
                        </div>
                        <div className="divida"></div>
                      </div>
                      <div className="formation">
                        <div className="infoTitle">EMAIL </div>
                        <div className="infoDetails">
                          {email?.charAt(0)?.toUpperCase() +
                            email?.slice(1)?.toLowerCase()}
                        </div>
                        <div className="divida"></div>
                      </div>
                      <div className="formation">
                        <div className="infoTitle">PHONE</div>
                        <div className="infoDetails">{phone}</div>
                      </div>

                      <div
                        className="formation-"
                        style={{ padding: " 0 20px" }}
                      >
                        <div className="infoTitle">MEANS OF TRANSPORTATION</div>
                        <div className="infoDetails--">
                          <div className="infodetailsflex-">
                            <div className="category_container-">
                              <div className="flex-tutor">
                                {/* style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "5px" }}  */}
                                <div>
                                  <span>
                                    <div className="spanandclose">
                                      <div className="span-text--">
                                        {validdata.map((x, index) => (
                                          <div className="span-text-ul">
                                            <div>
                                              {x?.charAt(0)?.toUpperCase() +
                                                x?.slice(1)?.toLowerCase()}{" "}
                                              <AiOutlineClose
                                                size="0.7em"
                                                onClick={
                                                  validdata.length === 1
                                                    ? () => setlastitem(true)
                                                    : () => {
                                                        return (
                                                          setmeans_of_delivery(
                                                            means_of_delivery.filter(
                                                              (item) =>
                                                                item !== x
                                                            )
                                                          ),
                                                          setvaliddata(
                                                            means_of_delivery.filter(
                                                              (item) =>
                                                                item !== x
                                                            )
                                                          ),
                                                          UpdateData2(
                                                            means_of_delivery.filter(
                                                              (item) =>
                                                                item !== x
                                                            )
                                                          ),
                                                          setshowlist(!showlist)
                                                        );
                                                      }
                                                }
                                              />
                                            </div>
                                          </div>
                                        ))}
                                      </div>

                                      <div className="close-icon-"></div>
                                    </div>
                                  </span>
                                </div>
                                <div>
                                  {" "}
                                  <span
                                    className="select_icon"
                                    onClick={() => setshowlist(!showlist)}
                                  >
                                    {showlist ? (
                                      <BsChevronUp
                                      // onClick={
                                      //   [...validdata].length !== 0
                                      //     ? UpdateData
                                      //     : null
                                      // }
                                      />
                                    ) : (
                                      <BsChevronDown />
                                    )}
                                  </span>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="divida">jj</div> */}
                      </div>

                      {showlist && (
                        <div className="formation">
                          <div className="infoTitle">
                            <form>
                              {data.map((item, index) => (
                                <ul>
                                  <label
                                    key={item.label}
                                    className="check_container"
                                    style={{
                                      fontFamily: "Roboto",
                                      fontStyle: "normal",
                                      fontWeight: "400",
                                      fontSize: "16px",
                                      color: "#484848",
                                    }}
                                  >
                                    <input
                                      readOnly
                                      checked={
                                        validdata.includes(
                                          item?.label?.toLowerCase()
                                        )
                                          ? true
                                          : false
                                      }
                                      onClick={() => toggle(index, item)}
                                      type="checkbox"
                                    />
                                    {item.label}
                                    <span
                                      style={{
                                        height: "18px",
                                        width: "18px",
                                      }}
                                      className="checkmark  check "
                                    ></span>
                                  </label>
                                </ul>
                              ))}
                            </form>
                          </div>
                          <div className="infoDetails"></div>
                          <div className="divida"></div>
                        </div>
                      )}

                      <div className="formation1">
                        <div className="infoTitle">PROFILE MESSAGE</div>
                        <div className="infoDetails">
                          <div className="profileMssg">
                            {mssgState === "not_valid" ? (
                              <div
                                style={{
                                  color: "maroon",
                                  marginBottom: "12px",
                                  fontSize: "12px",
                                  textTransform: "none",
                                }}
                              >
                                Your profile message content was rejected, edit
                                and upload again for review
                              </div>
                            ) : null}
                            <textarea
                              rows="4"
                              style={{
                                width: "100%",
                                border:
                                  mssgState === "editing"
                                    ? "0.5px solid #b2b2b2"
                                    : mssgState === "not_valid"
                                    ? "0.5px solid red"
                                    : "none",
                                lineHeight: "20px",
                                fontWeight: "500",
                                color: "#616161",
                                backgroundColor: "white",
                              }}
                              value={profileMessage}
                              onChange={(e) =>
                                setprofileMessage(e.target.value)
                              }
                              disabled={mssgState !== "editing"}
                              placeholder={
                                loading
                                  ? ""
                                  : "Click add profile message button and enter your profile message here, click on update button to submit message for review"
                              }
                            />
                          </div>
                          <div className="mssgeBtn">
                            <Button
                              className={classes.btn}
                              size="small"
                              variant="contained"
                              onClick={() => {
                                mssgState === "reviewing" ? (
                                  <></>
                                ) : mssgState === "empty" ||
                                  mssgState === "noEmpty" ||
                                  mssgState === "not_valid" ? (
                                  setMssgState("editing")
                                ) : (
                                  UpdateMSSG()
                                );
                              }}
                            >
                              {mssgState === "empty"
                                ? "Add profile message"
                                : mssgState === "noEmpty" ||
                                  mssgState === "not_valid"
                                ? "Edit profile message"
                                : mssgState === "editing"
                                ? "Update profile message"
                                : mssgState === "reviewing"
                                ? "In review..."
                                : "Update profile message"}
                            </Button>
                          </div>
                        </div>
                        <div className="divida"></div>
                      </div>

                      <div className="formation">
                        <div className="infoTitle">PREFERRED COMMUNICATION</div>

                        <div className="divida"></div>
                      </div>

                      <div className="formation">
                        <div className="infoTitle">FORM OF ID</div>
                        <div
                          className="infoDetails"
                          style={{
                            flexDirection: "row",
                            alignItems: "flex-end",
                            gap: "10px",
                          }}
                        >
                          <div
                            className="form_id"
                            style={{
                              backgroundImage: `url(${form_of_id?.form_of_id})`,
                            }}
                          ></div>
                          {/* <span onClick={()=>seteditFormID(true)}><FaPencilAlt/></span> */}
                        </div>
                        <div className="divida"></div>
                      </div>

                      <div className="formation">
                        <div className="infoTitle">BANK DETAILS</div>
                        <div
                          className="infoDetails"
                          style={{ flexDirection: "column", gap: "7px" }}
                        >
                          <div>{bankDetails?.account_number}</div>
                          <div>{bankDetails?.bank_name}</div>
                          <div>
                            {bankDetails?.account_name
                              ?.charAt(0)
                              ?.toUpperCase() +
                              bankDetails?.account_name
                                ?.slice(1)
                                ?.toLowerCase()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    padding: "5px 50px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {" "}
                    <div
                      style={{
                        color: "var(--main)",
                        cursor: "pointer",
                        marginBottom: "10px",
                        fontSize: "11px",
                      }}
                    >
                      <span onClick={() => history.push(forgetpass)}>
                        Need to reset your password?{" "}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div>
                      {showlist ? (
                        <button
                          onClick={
                            [...validdata].length !== 0 ? UpdateData : null
                          }
                          style={{
                            background: "#50B14A",
                            borderRadius: "5px",
                            width: "70px",
                            height: "28px",
                            color: "#ffffff",
                            border: "none",
                          }}
                        >
                          {" "}
                          {updating ? "loading..." : "Save"}{" "}
                        </button>
                      ) : (
                        <button
                          disabled
                          style={{
                            background: "#B2B2B2",
                            borderRadius: "5px",
                            width: "70px",
                            height: "28px",
                            color: "#ffffff",
                            border: "none",
                          }}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
        {/* mobile view ends here */}

        <ReactTooltip
          id="meanofdelivery"
          aria-haspopup="true"
          type="light"
          role="example"
        >
          <p>Update mean of delivery</p>
          {/* <p>Mode of movement</p> */}
        </ReactTooltip>

        <DialogOutClick open={lastitem} handleClose={() => setlastitem(false)}>
          <div style={{ width: "100%", padding: "30px 30px 20px 30px" }}>
            <div
              style={{
                width: "100%",
                marginBottom: "30px",
                fontSize: "13px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>You can't remove all means of transportation</span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div
                className="disBtn"
                style={{
                  width: "50px",
                  fontSize: "13px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => setlastitem(false)}
              >
                OK
              </div>
              {/* <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'white', backgroundColor:'#808080', border:'1px solid #808080' }}
                            onClick={logMeOut}>
                            Yes
                        </div> */}
            </div>
          </div>
        </DialogOutClick>

        <DialogOutClick
          open={showLogout}
          handleClose={() => setShowLogout(false)}
        >
          <div style={{ width: "100%", padding: "30px 30px 20px 30px" }}>
            <div
              style={{
                width: "100%",
                marginBottom: "30px",
                fontSize: "13px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>Are you sure you want to logout?</span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div
                className="disBtn"
                style={{
                  width: "50px",
                  fontSize: "13px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => setShowLogout(false)}
              >
                No
              </div>
              <div
                className="ediBtn"
                style={{
                  width: "50px",
                  fontSize: "13px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "white",
                  backgroundColor: "#808080",
                  border: "1px solid #808080",
                }}
                onClick={logMeOut}
              >
                Yes
              </div>
            </div>
          </div>
        </DialogOutClick>
        <DialogOutClick
          open={showDeteleAcct}
          handleClose={() => setShowDeteleAcct(false)}
        >
          <div style={{ width: "100%", padding: "30px 30px 20px 30px" }}>
            <div
              style={{
                width: "100%",
                marginBottom: "30px",
                fontSize: "13px",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>
                Are you sure you want to delete this account? Type in{" "}
                <b>permanently delete</b> in the input below to confirm deletion
              </span>
            </div>
            <div style={{ marginBottom: "30px" }}>
              <input
                type="text"
                placeholder="permanently delete"
                value={deleteInput}
                onChange={(e) => setdeleteInput(e.target.value)}
                style={{
                  border: "1px solid var(--main)",
                  padding: "7px 10px",
                  outline: "none",
                  borderRadius: "5px",
                  fontSize: "13px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <div
                className="disBtn"
                style={{
                  fontSize: "13px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  border: "#0080FF solid 1px",
                  backgroundColor: "#0080FF",
                  color: "white",
                }}
                onClick={disableAccount}
              >
                Disable instead
              </div>
              <div
                className="disBtn"
                style={{
                  width: "50px",
                  fontSize: "13px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => setShowDeteleAcct(false)}
              >
                Cancel
              </div>
              {deleteInput === "permanently delete" ? (
                <div
                  className="ediBtn"
                  style={{
                    width: "50px",
                    fontSize: "13px",
                    fontWeight: "bold",
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "red",
                    border: "1px solid red",
                  }}
                  onClick={deleteMyAccount}
                >
                  {loading ? (
                    <PuffLoader
                      color="var(--main)"
                      loading={loading}
                      speedMultiplier={1}
                      size={15}
                    />
                  ) : (
                    "Yes"
                  )}
                </div>
              ) : (
                <div
                  className="ediBtn"
                  style={{
                    width: "50px",
                    fontSize: "13px",
                    fontWeight: "bold",
                    cursor: "not-allowed",
                    color: "white",
                    backgroundColor: "#B86566",
                    border: "1px solid #B86566",
                  }}
                >
                  Yes
                </div>
              )}
            </div>
          </div>
        </DialogOutClick>

        <DialogPop
          // openPopup={showupdate}
          // setopenPopup={setShowupdate}
          head="Update Mean of Delivery"
        >
          <Grid className={classes.itemGrid} item xs={12} sm={12}>
            <div
              style={{
                fontWeight: "600",
                fontSize: "14px",
                marginBottom: "5px",
              }}
            >
              Means of delivery
            </div>
            <Multiselect
              options={options} // Options to display in the dropdown
              onSelect={onSelect}
              onRemove={onRemove} // Function will trigger on remove event
              showCheckbox={true}
              isObject={false}
              placeholder={
                mode_of_transport.length === 0 ? "E.g car, bike, van" : ""
              }
              style={CustomStyle}
            />

            <div className="err">{modeValid ? "" : "Required"}</div>
          </Grid>
        </DialogPop>
        <DialogOutClick
          open={editFormID}
          handleClose={() => seteditFormID(false)}
        >
          <div style={{ width: "100%", padding: "20px" }}>
            <div
              style={{
                fontSize: "15px",
                fontWeight: "600",
                marginBottom: "15px",
              }}
            >
              {" "}
              Update form of ID
            </div>
            <FormControl
              variant="outlined"
              className="formInput"
              style={{ marginBottom: "10px" }}
            >
              <Select
                value={selectedProof}
                onChange={(e) => setselectedProof(e.target.value)}
              >
                <MenuItem value="nil">Select your Identification</MenuItem>
                <MenuItem value="driver_license">Driver License</MenuItem>
                <MenuItem value="international_passport">
                  International Passport
                </MenuItem>
                <MenuItem value="national_id_card">National ID Card</MenuItem>
              </Select>
            </FormControl>
            {selectedProof === "driver_license" ? (
              <>
                <form
                  style={{ width: "100%" }}
                  onSubmit={handleSubmitDrive(updateDriveLicence)}
                  noValidate
                >
                  <Grid container spacing={1}>
                    <Grid item style={{ marginBottom: "10px" }} xs={12}>
                      <Controller
                        name="driver_license"
                        control={controlDrive}
                        defaultValue=""
                        render={({ field }) => (
                          <RegInput
                            {...field}
                            htmlFor="driver_license"
                            name="driver_license"
                            id="driver_license"
                            txt="Drivers license number"
                            placeholder="Drivers license number"
                            type="text"
                            size="small"
                            error={errorsDrive.driver_license}
                          />
                        )}
                      />
                      <div className="err">
                        {errorsDrive.driver_license &&
                          errorsDrive.driver_license?.message}
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ marginBottom: "10px" }}>
                      <Controller
                        name="valid_from"
                        control={controlDrive}
                        defaultValue=""
                        render={({ field }) => (
                          <RegInput
                            {...field}
                            htmlFor="valid_from"
                            name="valid_from"
                            id="valid_from"
                            txt="Valid from"
                            placeholder="Valid from"
                            type="date"
                            size="small"
                            error={errorsDrive.start}
                          />
                        )}
                      />
                      {/* <div className="err">
                          {errors2.valid_from && errors2.valid_from?.message}
                        </div> */}
                    </Grid>

                    <Grid item xs={6} sm={6} style={{ marginBottom: "10px" }}>
                      <Controller
                        name="valid_to"
                        control={controlDrive}
                        defaultValue=""
                        render={({ field }) => (
                          <RegInput
                            {...field}
                            htmlFor="valid_to"
                            name="valid_to"
                            id="valid_to"
                            txt="Valid to"
                            placeholder="Valid to"
                            type="date"
                            size="small"
                            error={errorsDrive.to}
                          />
                        )}
                      />
                    </Grid>
                    {/* <div className="err">{errorsDrive.driver_license && errorsDrive.driver_license?.message}</div>  */}

                    <Grid item xs={12}>
                      <div
                        className="infoDetails"
                        style={{
                          flexDirection: "row",
                          alignItems: "flex-end",
                          gap: "10px",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="form_id"
                          style={{
                            backgroundImage: `url(${form_of_id_dup?.form_of_id})`,
                            position: "relative",
                          }}
                        >
                          <label
                            className="form_id_label"
                            htmlFor="update_image"
                          >
                            Click to change <FaPencilAlt />
                          </label>
                        </div>
                        <input
                          type="file"
                          name="update_image"
                          id="update_image"
                          style={{ display: "none" }}
                          accept="image/x-png,image/gif,image/jpeg"
                          onChange={HandleImageUpload}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      {form_of_id.form_of_id === form_of_id_dup.form_of_id ? (
                        <input
                          className="submit_btn_custom_disable"
                          type="button"
                          value="Update"
                        />
                      ) : (
                        <input
                          className="submit_btn_custom"
                          type="submit"
                          value="Update"
                        />
                      )}
                    </Grid>
                  </Grid>
                </form>
              </>
            ) : selectedProof === "international_passport" ? (
              <>
                <form
                  style={{ width: "100%" }}
                  onSubmit={handleSubmit1(updateInterPassport)}
                  noValidate
                >
                  <Grid container spacing={1}>
                    <Grid item style={{ marginBottom: "10px" }} xs={12}>
                      <Controller
                        name="international_passport"
                        control={control1}
                        defaultValue=""
                        render={({ field }) => (
                          <RegInput
                            {...field}
                            htmlFor="international_passport"
                            name="international_passport"
                            id="international_passport"
                            txt="Internation passport number"
                            placeholder="Internation passport number"
                            type="text"
                            size="small"
                            error={errors1.international_passport}
                          />
                        )}
                      />
                      <div className="err">
                        {errors1.international_passport &&
                          errors1.international_passport?.message}
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ marginBottom: "10px" }}>
                      <Controller
                        name="valid_from"
                        control={control1}
                        defaultValue=""
                        render={({ field }) => (
                          <RegInput
                            {...field}
                            htmlFor="valid_from"
                            name="valid_from"
                            id="valid_from"
                            txt="Valid from"
                            placeholder="Valid from"
                            type="date"
                            size="small"
                            error={errors1.start}
                          />
                        )}
                      />
                      <div className="err">
                        {errors1.valid_from && errors1.valid_from?.message}
                      </div>
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ marginBottom: "10px" }}>
                      <Controller
                        name="valid_to"
                        control={control1}
                        defaultValue=""
                        render={({ field }) => (
                          <RegInput
                            {...field}
                            htmlFor="valid_to"
                            name="valid_to"
                            id="valid_to"
                            txt="Valid to"
                            placeholder="Valid to"
                            type="date"
                            size="small"
                            error={errors1.to}
                          />
                        )}
                      />
                      <div className="err">
                        {errors1.valid_to && errors1.valid_to?.message}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        className="infoDetails"
                        style={{
                          flexDirection: "row",
                          alignItems: "flex-end",
                          gap: "10px",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="form_id"
                          style={{
                            backgroundImage: `url(${form_of_id_dup?.form_of_id})`,
                            position: "relative",
                          }}
                        >
                          <label
                            className="form_id_label"
                            htmlFor="update_image"
                          >
                            Click to change <FaPencilAlt />
                          </label>
                        </div>
                        <input
                          type="file"
                          name="update_image"
                          id="update_image"
                          style={{ display: "none" }}
                          accept="image/x-png,image/gif,image/jpeg"
                          onChange={HandleImageUpload}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      {form_of_id.form_of_id === form_of_id_dup.form_of_id ? (
                        <input
                          className="submit_btn_custom_disable"
                          type="button"
                          value="Update"
                        />
                      ) : (
                        <input
                          className="submit_btn_custom"
                          type="submit"
                          value="Update"
                        />
                      )}
                    </Grid>
                  </Grid>
                </form>
              </>
            ) : selectedProof === "national_id_card" ? (
              <>
                <form
                  style={{ width: "100%" }}
                  onSubmit={handleSubmitNIN(updateNIN)}
                  noValidate
                >
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Controller
                        name="national_id_card"
                        control={controlNIN}
                        defaultValue=""
                        render={({ field }) => (
                          <RegInput
                            {...field}
                            htmlFor="national_id_card"
                            name="national_id_card"
                            id="national_id_card"
                            txt="National ID card number"
                            placeholder="National ID card number"
                            type="text"
                            size="small"
                            error={errorsNIN.national_id_card}
                          />
                        )}
                      />
                      <div className="err">
                        {errorsNIN.national_id_card &&
                          errorsNIN.national_id_card?.message}
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div
                        className="infoDetails"
                        style={{
                          flexDirection: "row",
                          alignItems: "flex-end",
                          gap: "10px",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="form_id"
                          style={{
                            backgroundImage: `url(${form_of_id_dup?.form_of_id})`,
                            position: "relative",
                          }}
                        >
                          <label
                            className="form_id_label"
                            htmlFor="update_image"
                          >
                            Click to change <FaPencilAlt />
                          </label>
                        </div>
                        <input
                          type="file"
                          name="update_image"
                          id="update_image"
                          style={{ display: "none" }}
                          accept="image/x-png,image/gif,image/jpeg"
                          onChange={HandleImageUpload}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      {form_of_id.form_of_id === form_of_id_dup.form_of_id ? (
                        <input
                          className="submit_btn_custom_disable"
                          type="button"
                          value="Update"
                        />
                      ) : (
                        <input
                          className="submit_btn_custom"
                          type="submit"
                          value="Update"
                        />
                      )}
                    </Grid>
                  </Grid>
                </form>
              </>
            ) : (
              <></>
            )}
          </div>
        </DialogOutClick>
      </div>
    </>
  );
}

export default AccountSetting;
