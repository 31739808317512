import React, { useState, useContext, useEffect } from "react";
import { FormControl, Button, InputLabel } from "@material-ui/core";
import InputField from "../../components/InputField";

import "./Login.css";
import { Link } from "react-router-dom";
import axios from "axios";

import { HiOutlineMail } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import PuffLoader from "react-spinners/PuffLoader";
import { AuthContext } from "../../context/AuthContext";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DialogSimplePop from "../../components/DialogSimplePop";
import Playstore from "../../assets/Playstore.png";
import Appstore from "../../assets/Appstore.png";
import IndividualSidebar from "../../components/Sidebar/IndividualSidebar";
import imagelogo from "../../assets/Dillivry.png";
import Nav from "../../components/Nav/Nav";
import Footer from "../../components/Footer/Footer";
import Accounthand2 from "../../assets/Accounthand2.svg";
import marinal from "../../assets/marinal.png";
import erros from "../../assets/erros.png";
import firstimage from "../../assets/iOS-getApp.svg";
import secondimage from "../../assets/Google-GetApp.svg";
import SecureLS from "secure-ls";
import { ConnContext } from "../../context/ConnContext";
import GlobalizationLanguage from "../../components/GlobalizationLanguage";
function LoginCorporate() {
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  var reset = GlobalizationLanguage("/reset")
  var deliver_with_us = GlobalizationLanguage("/create-account")
  const forgetpass=GlobalizationLanguage("/forgetpass")

  const authContext = useContext(AuthContext);
  const history = useHistory();
  const endPoint = process.env.REACT_APP_API;

  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [submitting, setsubmitting] = useState(false);
  const [showpassword, setshowpassword] = useState(false);
  const [userType, setuserType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [personell, setPersonell] = useState("corporate");
  const [show, setShow] = useState(true);

  const endpoint = process.env.REACT_APP_API;
  const {visitingCountryCode} = useContext(ConnContext)
  const handleLogin = async (e) => {
    e.preventDefault();
    var encodedPassword = btoa(password);
    setsubmitting(true);

    await Axios.post(`${endPoint}m-dillivry-auth-manager`, {
      auth_type: "login",
      username: username.toLowerCase(),
      password: encodedPassword,
      // vcc: visitingCountryCode
    }, )
      .then((response) => {
    ls.set("user_location", response?.data?.vcc)

        ls.set("aCs_", response.data?.account_status);
        if (response) {
          if (response.data.auth_status === "new_password_required") {
            history.push({
              pathname: reset,
              state: username,
            });
          } else if (response.data.auth_status === "success") {
            // ls.set("iLR_", response.data.geo_tracking_role);
            const { IdToken } = response.data.token;
            const token = IdToken.split(".")[1];
            const encodedToken = atob(token);
            const tokenJSON = JSON.parse(encodedToken);


            if (tokenJSON["custom:role"] !== undefined) {
              authContext.setAuthState(
                Object.assign(response.data, { role: tokenJSON["custom:role"] })
              );
              if (
                response.data.role === "dillivry-corp-sys-admin" &&
                personell === "corporate"
              ) {
                authContext.setAuthState(
                  Object.assign(response.data, {
                    role: tokenJSON["custom:role"],
                  })
                );
                ls.set("_nodi", response.data.token.IdToken);
                ls.set("eid", response.data.user_name);
                ls.set("_iRl", response.data.role);
                ls.set("nful", tokenJSON["given_name"]);
                ls.set("_tyf_", tokenJSON["custom:account_name"]);
                ls.set("btwl", response?.data?.data?.user_info[0]?.wallet_balance);// walllet balance
                ls.remove("_utk");
                window.location.reload()
              }
              else if (
                response.data.role === "dillivry-corp-bid-ops" &&
                personell === "bidder"
              ) {
                authContext.setAuthState(
                  Object.assign(response.data, {
                    role: tokenJSON["custom:role"],
                  })
                );
                ls.set("_nodi", response.data.token.IdToken);
                ls.set("eid", response.data.user_name);
                ls.set("_iRl", response.data.role);
                ls.set("nful", tokenJSON["given_name"]);
                ls.set("_tyf_", tokenJSON["custom:account_name"]);
                ls.remove("_utk");

                window.location.reload()

              } 
              else if (response.data.role === "dillivry-sole-trader-ops") {
              } 
              else if (response.data.role === "dillivry-last-mile-ops") {
              } else {
                setsubmitting(false);
                setShowAlert(true);
              }
            } 
            else {
              if (personell === "corporate") {
                setuserType("corporate");
                setShowAlert(true);
                setsubmitting(false);
              } 
              else if (personell === "bidder") {
                setuserType("bidder");
                setsubmitting(false);
                setShowAlert(true);
              }
            }
          }
        }
      })
      .catch((error) => {
        if (error.response?.status === 403) {
          setuserType("error");
          setShowAlert(true);
          setsubmitting(false);
        } else {
          Swal.fire({
            iconHtml: `<img src=${erros} style="width:100px;">`, // imageUrl: "dm-dillivry-web/web/src/assets/erros.png",
            title: "Oops!",
            imageAlt: "A tall image",
            text: "Invalid username or password",
            confirmButtonColor: "var(--main)",
          });
          setsubmitting(false);
        }
      });
  };
  return (
    <div className="login">
      <div className="menu">
        <div>
          <Nav />
        </div>
        <div className="ind-nav">
          <IndividualSidebar />
          <div className="logocont">
            <img
              src={imagelogo}
              alt="dillivry"
              onClick={() => history.push("/")}
            />
          </div>
        </div>
      </div>
      <div className="menulisss">
        <div
          className="loginSection"
          style={{
            backgroundImage: `url(${marinal})`,
            borderRadius: "20px",
            justifyContent: "center",
          }}
        >
        
          <div className="loginContent">
            <div className="loginForm">
              <form className="mainLgForm" onSubmit={handleLogin}>
                <div className="lgHead">Account Sign in</div>

                <div className="fff">
                  <div className="headingg">
                    <span className="acctTyp">Account type</span>
                    <select
                      className="copOrInd"
                      onChange={(e) => {
                        const selectedPersonell = e.target.value;

                        setPersonell(selectedPersonell);

                        if (selectedPersonell === "agent") {
                          setShow(false);
                          setuserType("agent");
                          setShowAlert(true);
                          setsubmitting(false);
                        } else setShow(true);
                      }}
                    >
                      {/* <option value="sender">Sender</option> */}
                      <option value="corporate">Corporate</option>
                      {/* <option value="agent">Agent</option> */}
                      {/* <option value="individual">Individual</option> */}
                      <option value="bidder">Bidder</option>
                    </select>
                  </div>
                  {show ? (
                    <div className="formCont">
                      <FormControl className="formInput" variant="outlined">

                        <InputField
                        label="Email"
                          required
                          name="username"
                          id="username"
                          type="email"
                          value={username}
                          labelWidth={60}
                          onChange={(e) => {
                            setusername(e.target.value);
                          }}
                          icon={
                            <HiOutlineMail
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                          }
                        />
                      </FormControl>

                      <FormControl className="formInput" variant="outlined">
                        
                        <InputField
                        label="Password"
                          required
                          name="password"
                          id="password"
                          type={showpassword ? "text" : "password"}
                          endIcon={
                            showpassword ? (
                              <Tooltip title="Hide password" interactive>
                                <VisibilityOffIcon
                                  style={{
                                    color: "grey",
                                    fontSize: "25px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setshowpassword(!showpassword)}
                                />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Show password" interactive>
                                <VisibilityIcon
                                  style={{
                                    color: "grey",
                                    fontSize: "25px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setshowpassword(!showpassword)}
                                />
                              </Tooltip>
                            )
                          }
                          value={password}
                          labelWidth={90}
                          onChange={(e) => {
                            setpassword(e.target.value);
                          }}
                          icon={
                            <RiLockPasswordLine
                              style={{
                                marginRight: "5px",
                                color: "var(--main)",
                              }}
                            />
                          }
                        />
                      </FormControl>
                      <div className="subtitle" style={{ textAlign: "left" }}>
                        <span className="lgCheckBoxCon">
                          Remember?
                          <input type="checkbox" className="lgcheckBox"></input>
                        </span>
                        <Link to={forgetpass} style={{ color: "var(--main)" }}>
                          Forgot password?
                        </Link>
                      </div>
                      <div className="lgBtnn">
                        {submitting ? (
                          <Button
                            size="large"
                            variant="contained"
                            type="submit"
                            disabled
                            style={{
                              backgroundColor: "var(--main)",
                              width: "25%",
                              color: "white",
                              borderRadius: "10px",
                              padding: "10px",
                              height: "30px",
                              textTransform: "none",
                            }}
                          >
                            <span className="btnlabe">
                              <PuffLoader
                                color="white"
                                loading={submitting}
                                speedMultiplier={1}
                                size={24}
                              />
                            </span>
                          </Button>
                        ) : (
                          <Button
                            size="large"
                            variant="contained"
                            type="submit"
                            style={{
                              backgroundColor: "var(--main)",
                              width: "25%",
                              color: "white",
                              borderRadius: "10px",
                              padding: "10px",
                              height: "30px",
                              textTransform: "none",
                            }}
                          >
                            <span className="btnlabe">Login</span>
                          </Button>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <hr style={{ border: "0.5px solid #e3e6e4" }}></hr>

                  <div className="pTag">
                    <p>New to Dillivry?</p>
                  </div>
                  <div className="LgReg">
                    <button
                      type="button"
                      variant="contained"
                      onClick={() => {
                        history.push(deliver_with_us);
                      }}
                      style={{
                        backgroundColor: "var(--main)",
                        width: "25%",
                        color: "white",
                        borderRadius: "10px",
                        border: "0px",
                        textTransform: "none",
                        fontWeight: "600",
                      }}
                      className="supbtn"
                    >
                      Register
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="loginImage">
              
            </div>
          </div>
        </div>
      </div>

      <DialogSimplePop
        openPopup={showAlert}
        btnText="Ok"
        onClick={() => setShowAlert(false)}
      >
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <img src={erros} alt="dillivry" style={{ width: "60px" }} />
          <div
            style={{
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "500",
              width: "100%",
            }}
          >
            {userType === "agent" ? (
              <>
                <div>Oops! All agent operations is within the app.</div>
                <div>Please download the app and login to your account.</div>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "30px",
                  }}
                >
                  <img
                    src={secondimage}
                    alt="playstore"
                    className="storeLogo"
                  />
                  <img src={firstimage} alt="appstore" className="storeLogo" />
                </div>
              </>
            ) : userType === "individual" ? (
              <>
                <div>Oops! You have selected a wrong account type.</div>
                <div>Check and try again.</div>
                <br></br>
                <div style={{ width: "100%" }}>
                  <img
                    src={Accounthand2}
                    alt="accounthand2"
                    className="handLogo"
                  />
                </div>
              </>
            ) : userType === "error" ? (
              <>
                <div>Forbidden access denied!</div>
                <div>
                  {" "}
                  Agent/Individual Provider? Please download the app and sign
                  in.
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "30px",
                  }}
                >
                  <img src={Playstore} alt="playstore" className="storeLogo" />
                  <img src={Appstore} alt="appstore" className="storeLogo" />
                </div>
              </>
            ) : userType === "bidder" ? (
              <>
                <div>Oops! You have selected a wrong account type.</div>
                <div>Check and try again.</div>
                <br></br>
                <div style={{ width: "100%" }}>
                  <img
                    src={Accounthand2}
                    alt="accounthand2"
                    className="handLogo"
                  />
                </div>
              </>
            ) : userType === "sender" ? (
              <>
                <div>Oops! You have selected a wrong account type.</div>
                <div>Check and try again.</div>
                <br></br>
                <div style={{ width: "100%" }}>
                  <img
                    src={Accounthand2}
                    alt="accounthand2"
                    className="handLogo"
                  />
                </div>
              </>
            ) : userType === "corporate" ? (
              <>
                <div>Oops! You have selected a wrong account type.</div>
                <div>Check and try again.</div>
                <br></br>
                <div style={{ width: "100%" }}>
                  <img
                    src={Accounthand2}
                    alt="accounthand"
                    className="handLogo"
                  />
                </div>
              </>
            ) : (
              <>
                <div>Oops! You have selected a wrong account type.</div>
                <div>Check and try again.</div>
                <br></br>
                <div style={{ width: "100%" }}>
                  <img
                    src={Accounthand2}
                    alt="accounthand"
                    className="handLogo"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </DialogSimplePop>
      <Footer />
    </div>
  );
}

export default LoginCorporate;
