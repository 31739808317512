import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Label,
  Tooltip
} from "recharts";

export default function LineChart({data}) {
  return (
    <>
      <div className="chartweb">
        <AreaChart
          width={650}
          height={250}
          data={data}
          margin={{top: 10, right: 30, left: 20,bottom: 0}}
        >
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="var(--main)" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="var(--main)" stopOpacity={0}/>
                </linearGradient>
            </defs>
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="day"/>
          <YAxis
            label={{ value: 'Amount', angle: -90, position: 'left' }} 
          />
          <Tooltip/>
          <Area type="monotone" dataKey="amount" strokeWidth={3} stroke="var(--main)" fillOpacity={1} fill="url(#colorUv)" />
        </AreaChart>
      </div>
      <div className="chartMob">
        <AreaChart
          width={300}
          height={250}
          data={data}
          margin={{top: 10, right: 30, left: 0,bottom: 0}}
        >
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="var(--main)" stopOpacity={1}/>
                    <stop offset="95%" stopColor="var(--main)" stopOpacity={0.1}/>
                </linearGradient>
            </defs>
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="day" />
          <YAxis/>
          <Tooltip/>
          <Area type="monotone" dataKey="amount" strokeWidth={3} stroke="var(--main)" fillOpacity={1} fill="url(#colorUv)" />
        </AreaChart>
      </div>
    </>
  );
}
