import getType from './dashboard.types';

export const INITIAL_STATE =  []

const dashboardReducer = (state, action) => {
    switch(action.type) {

      case getType.GET_DEFAULT_SEARCH_DATA:
        return {
          dashboards: 
            action.payload
        };

      case getType.ASSIGN_AGENT:
        const item_to_assign = state.dashboards.data.accepted_quotes.unassigned.filter((item)=>(item.delivery_id === action.payload.delivery_id))
        const item_assign_left = state.dashboards.data.accepted_quotes.unassigned.filter((item)=>(item.delivery_id !== action.payload.delivery_id))
        const assigned_agent_details = {"assigned_agent_details" : {
            'agent_name': action.payload.agentName,
            'pickup_agent_id': action.payload.agentid,
            'pickup_verification_status': "awaiting_verification"
            }
          }
          const AssignDelivery = Object.assign(item_to_assign[0], assigned_agent_details);
          AssignDelivery.delivery_status = "awaiting_pickup";
          //update assigned by adding new data to assigned 
          state.dashboards.data.accepted_quotes.assigned.unshift(AssignDelivery);
          // update unassigned by removing newly assigned data to assigned 
          state.dashboards.data.accepted_quotes.unassigned = item_assign_left
        return {
          dashboards: state.dashboards
        };

      case getType.REASSIGN_AGENT:
        const item_to_reassign = state.dashboards.data.accepted_quotes.assigned.filter((item)=>(item.delivery_id === action.payload.delivery_id))
        const item_reassign_left = state.dashboards.data.accepted_quotes.assigned.filter((item)=>(item.delivery_id !== action.payload.delivery_id))
          item_to_reassign[0].assigned_agent_details = {
            'agent_name': action.payload.agentName,
            'pickup_agent_id': action.payload.agentid,
            'pickup_verification_status': "awaiting_verification"
            }
            const new_arr = [ ...item_to_reassign, ...item_reassign_left]
          //update assigned by adding new assigned data 
            state.dashboards.data.accepted_quotes.assigned = new_arr
          return {
            dashboards: state.dashboards
          };

        case getType.REASSIGN_PICKEDUP:
          const item_to_reassign_pick = state.dashboards.data.picked_up.filter((item)=>(item.delivery_id === action.payload.delivery_id))
          const item_reassign_pick_left = state.dashboards.data.picked_up.filter((item)=>(item.delivery_id !== action.payload.delivery_id))
            item_to_reassign_pick[0].assigned_agent_details = {
              'agent_name': action.payload.agentName,
              'pickup_agent_id': action.payload.agentid,
              'pickup_verification_status': "verified"
              }
              const new_arr_pick = [ ...item_to_reassign_pick, ...item_reassign_pick_left]
            //update assigned by adding new assigned data 
              state.dashboards.data.picked_up = new_arr_pick
            return {
              dashboards: state.dashboards
            };

          case getType.REASSIGN_OUT:
            const item_to_reassign_out = state.dashboards.data.out_for_delivery.filter((item)=>(item.delivery_id === action.payload.delivery_id))
            const item_reassign_out_left = state.dashboards.data.out_for_delivery.filter((item)=>(item.delivery_id !== action.payload.delivery_id))
              item_to_reassign_out[0].assigned_agent_details = {
                'agent_name': action.payload.agentName,
                'pickup_agent_id': action.payload.agentid,
                'pickup_verification_status': "verified"
                }
                const new_arr_out = [ ...item_to_reassign_out, ...item_reassign_out_left]
              //update assigned by adding new assigned data 
                state.dashboards.data.out_for_delivery = new_arr_out
              return {
                dashboards: state.dashboards
              };

          case getType.REASSIGN_MISSED:
            const item_to_reassign_missed = state.dashboards.data.missed_delivery.filter((item)=>(item.delivery_id === action.payload.delivery_id))
            const item_reassign_missed_left = state.dashboards.data.missed_delivery.filter((item)=>(item.delivery_id !== action.payload.delivery_id))
              item_to_reassign_missed[0].assigned_agent_details = {
                'agent_name': action.payload.agentName,
                'pickup_agent_id': action.payload.agentid,
                'pickup_verification_status': "verified"
                }
                const new_arr_missed = [ ...item_to_reassign_missed, ...item_reassign_missed_left]
              //update assigned by adding new assigned data 
                state.dashboards.data.missed_delivery = new_arr_missed
              return {
                dashboards: state.dashboards
            };

          case getType.UPDATE_DM_MSG:
            let delvry = []
            let delivery_left =[]
            let new_update =[]
            if (action.payload.delivery_type === "current"){
                delvry = state.dashboards.data.current_quotes.filter((item)=>(item.delivery_id === action.payload.delivery))
                delvry[0].quote_detail[0].message = action.payload.updated_msg;
                delivery_left = state.dashboards.data.current_quotes.filter((item)=>(item.delivery_id !== action.payload.delivery))
                new_update = [ ...delvry, ...delivery_left]
                state.dashboards.data.current_quotes = new_update
            }
            else if (action.payload.delivery_type === "unassign"){
                delvry = state.dashboards.data.accepted_quotes.unassigned.filter((item)=>(item.delivery_id === action.payload.delivery))
                delvry[0].quote_detail[0].message = action.payload.updated_msg;
                delivery_left = state.dashboards.data.accepted_quotes.unassigned.filter((item)=>(item.delivery_id !== action.payload.delivery))
                new_update = [ ...delvry, ...delivery_left]
                state.dashboards.data.accepted_quotes.unassigned = new_update
            }
            else if (action.payload.delivery_type === "assign"){
                delvry = state.dashboards.data.accepted_quotes.assigned.filter((item)=>(item.delivery_id === action.payload.delivery))
                delvry[0].quote_detail[0].message = action.payload.updated_msg;
                delivery_left = state.dashboards.data.accepted_quotes.assigned.filter((item)=>(item.delivery_id !== action.payload.delivery))
                new_update = [ ...delvry, ...delivery_left]
                state.dashboards.data.accepted_quotes.assigned = new_update
            }
            else if (action.payload.delivery_type === "picked_up"){
                delvry = state.dashboards.data.picked_up.filter((item)=>(item.delivery_id === action.payload.delivery))
                delvry[0].quote_detail[0].message = action.payload.updated_msg;
                delivery_left = state.dashboards.data.picked_up.filter((item)=>(item.delivery_id !== action.payload.delivery))
                new_update = [ ...delvry, ...delivery_left]
                state.dashboards.data.picked_up = new_update
            }
            else if (action.payload.delivery_type === "out_for_delivery"){
                delvry = state.dashboards.data.out_for_delivery.filter((item)=>(item.delivery_id === action.payload.delivery))
                delvry[0].quote_detail[0].message = action.payload.updated_msg;
                delivery_left = state.dashboards.data.out_for_delivery.filter((item)=>(item.delivery_id !== action.payload.delivery))
                new_update = [ ...delvry, ...delivery_left]
                state.dashboards.data.out_for_delivery = new_update
            }
            else if (action.payload.delivery_type === "missed"){
                delvry = state.dashboards.data.missed_delivery.filter((item)=>(item.delivery_id === action.payload.delivery))
                delvry[0].quote_detail[0].message = action.payload.updated_msg;
                delivery_left = state.dashboards.data.missed_delivery.filter((item)=>(item.delivery_id !== action.payload.delivery))
                new_update = [ ...delvry, ...delivery_left]
                state.dashboards.data.missed_delivery = new_update
            }
            else if (action.payload.delivery_type === "decline"){
                delvry = state.dashboards.data.unsuccessful_quotes.filter((item)=>(item.delivery_id === action.payload.delivery))
                delvry[0].quote_detail[0].message = action.payload.updated_msg;
                delivery_left = state.dashboards.data.unsuccessful_quotes.filter((item)=>(item.delivery_id !== action.payload.delivery))
                new_update = [ ...delvry, ...delivery_left]
                state.dashboards.data.unsuccessful_quotes = new_update
            }
            return {
                  dashboards: state.dashboards
              };
        
          case getType.UPDATE_MSG:
                const delvery = state.dashboards.data.quote_messages.filter((item)=>(item.delivery_id === action.payload.delivery))
                delvery[0].conversation = action.payload.updated_msg;
                const delivery_ = state.dashboards.data.quote_messages.filter((item)=>(item.delivery_id !== action.payload.delivery))
                const new_updated = [ ...delvery, ...delivery_]
                state.dashboards.data.quote_messages = new_updated
            return {
              dashboards: state.dashboards
            };

            case getType.UPDATE_MEANS:
              state.dashboards.data.account_profile.account_profile[0].mode_of_delivery  = action.payload
            return {
              dashboards: state.dashboards
            };

            case getType.UPDATE_LOGO:
              state.dashboards.data.account_profile.account_profile[0].cop_logo  = action.payload
            return {
              dashboards: state.dashboards
            };

            case getType.UPDATE_BALC_WITHDRAW:
              state.dashboards.data.account_profile.account_profile[0].wallet_balance  = action.payload
            return {
              dashboards: state.dashboards
            };
        default:
        return state;
    }
  };

export default dashboardReducer;