import React from 'react'
import {Grid} from '@material-ui/core';
import RequestGeneral from './RequestGeneral';
import '../../../Registration/SenderRegistartion.css'
import RequestHaulage from './RequestHaulage';
import RequestHome from './RequestHome';
import SenderSideBar from '../../../components/SenderSideBar';
import SenderBottomNav from '../../../components/SenderBottomNav';
import CategorySelector from '../../../../../components/CategorySelector';
import { useLocation } from "react-router-dom";
import GlobalizationLanguage from '../../../../../components/GlobalizationLanguage';
import GooglePlaceAutocomplete from '../../../../../components/GooglePlaceAutocomplete';
export default function RequestDeliveryMobile({
        page,
        catIsValid,
        item_category,
        main_category,
        handleChange,
        handleChangeMainCategory,
        userInfo,
        setPage,
        noshowLayout, 

        setPickup_address,
        setpickupIsError,
        setdeli_locatn_frm_pickup_add,
        pickupIsError,
        pickup_address,
        setDelivery_address,
        deliveryIsError,
        delivery_address,
        setdeliveryIsError,
        deli_locatn_frm_pickup_add
    }) {
        const sendersettings=GlobalizationLanguage("/sendersettings")
        const sendersettlement=GlobalizationLanguage("/sendersettlement")
        const senderapi=GlobalizationLanguage("/senderapi")
        const team=GlobalizationLanguage("/team")
        var sendermessage = GlobalizationLanguage("/sendermessage")
       var RefPage = GlobalizationLanguage("/referrals_sender")
       
    const links =  [
        
        // {
        //     path: team,
        //     name: "Team",
        //     state:'inactive'
        // },
        // {
        //     path: senderapi,
        //     name: "API",
        //     state:'inactive'
        // },
        // {
        //     path: sendersettlement,
        //     name: "Settlement",
        //     state:'inactive'
        // },
        {
            path: sendersettings,
            name: "Settings",
            state:'inactive'
        },
        {
            path: RefPage,
            name: "Referral",
            icon: "devices",
            state:'inactive'
          },
        ]
    
    const location = useLocation();

    return (
        <div className="mobile-boby">
            {
                noshowLayout ? 
                <></>
                :
                <SenderSideBar pageTitle="Request delivery" links={links}/>
            }
            <div className="mobile-dash-bodyv2">
                <div className="updateCon" style={{height:'auto'}}>
                    <div className="formContain_request">
                        <form style={{ width: '100%' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} >
                                    <h3 style={{ color: 'var(--main)' }}>Request a delivery</h3>
                                </Grid>
                                {
                                    page === 1 ?
                                    <>
                                     <Grid item xs={12}>
                                        <GooglePlaceAutocomplete
                                            handleChange={(pickup_address) => {
                                            setPickup_address(pickup_address);
                                            setpickupIsError(true);
                                            }}
                                            handleSelect={(pickup_address) => {
                                            setPickup_address(pickup_address);
                                            setpickupIsError(false);
                                            }}
                                            setdeli_locatn_frm_pickup_add = {setdeli_locatn_frm_pickup_add}
                                            value={pickup_address}
                                            text= {item_category === 'Moving home' ? "Address moving from" :"Enter pick up address"}
                                            color="var(--main)"
                                            error={pickupIsError}
                                            pickup
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <GooglePlaceAutocomplete
                                            handleChange={(delivery_address) => {
                                            setDelivery_address(delivery_address);
                                            setdeliveryIsError(true);
                                            }}
                                            handleSelect={(delivery_address) => {
                                            setDelivery_address(delivery_address);
                                            setdeliveryIsError(false);
                                            }}
                                            value={delivery_address}
                                            text= {item_category === 'Moving home' ? "Address moving to" : "Enter delivery address"}
                                            color="red"
                                            error={deliveryIsError}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CategorySelector country={deli_locatn_frm_pickup_add} setitem_category={handleChange} setmainCategory={handleChangeMainCategory} categorySelected={item_category}/>
                                    </Grid>
                                    </>
                                :
                                null
                                }
                                <Grid item xs={12}>
                                { 
                                    item_category === 'Haulage'   || location?.state?.data?.item_category === "haulage"  ? 
                                        <RequestHaulage  
                                            page={page}  
                                            onClick={()=>{ setPage(page+1);}}
                                            previous={()=>{ setPage(1); }}
                                            itemCategory={item_category}  
                                            sender_name = {userInfo.sender_name}
                                            sender_email = {userInfo.sender_email}
                                            sender_phone_no = {userInfo.sender_phone_no}
                                            details_from_location={location?.state}

                                            delivery_location = {deli_locatn_frm_pickup_add}
                                            pickup_address = {pickup_address}
                                            delivery_address = {delivery_address}
                                            pickupIsError = {pickupIsError}
                                            deliveryIsError = {deliveryIsError}
                                            setpickupIsError = {setpickupIsError}
                                            setdeliveryIsError = {setdeliveryIsError}
                                        />    

                                        :
                                        item_category === 'Moving home' || location?.state?.data?.item_category?.charAt(0)?.toUpperCase() +  location?.state?.data?.item_category?.replaceAll("_", " ")?.slice(1) === 'Moving home' ?
                                            <RequestHome  
                                                page={page} 
                                                onClick={()=>{setPage(page+1); }}
                                                previous={()=>{ setPage(1); }}
                                                itemCategory={item_category}  
                                                sender_name = {userInfo.sender_name}
                                                sender_email = {userInfo.sender_email}
                                                sender_phone_no = {userInfo.sender_phone_no}
                                                details_from_location={location?.state}

                                                delivery_location = {deli_locatn_frm_pickup_add}
                                                pickup_address = {pickup_address}
                                                delivery_address = {delivery_address}
                                                pickupIsError = {pickupIsError}
                                                deliveryIsError = {deliveryIsError}
                                                setpickupIsError = {setpickupIsError}
                                                setdeliveryIsError = {setdeliveryIsError}
                                            />    
                                            :
                                            <RequestGeneral 
                                                sender_name = {userInfo.sender_name}
                                                sender_email = {userInfo.sender_email}
                                                sender_phone_no = {userInfo.sender_phone_no}
                                                itemCategory={item_category}  
                                                main_category={main_category}
                                                onClick={()=>{setPage(page+1);}}
                                                previous={()=>{ setPage(1); }}
                                                page={page}
                                                details_from_location={location?.state}

                                                delivery_location = {deli_locatn_frm_pickup_add}
                                                pickup_address = {pickup_address}
                                                delivery_address = {delivery_address}
                                                pickupIsError = {pickupIsError}
                                                deliveryIsError = {deliveryIsError}
                                                setpickupIsError = {setpickupIsError}
                                                setdeliveryIsError = {setdeliveryIsError}
                                                
                                            />
                                }
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </div>
            
            {
                noshowLayout ? 
                <></>
                :
                <div className="nav_bottom">
                    <SenderBottomNav state="request-delivery" />
                </div>
                
            }
            
        </div>
    )
}
