import React, { useState, useEffect, useContext, useRef } from "react";
import userAvatar from "../../../../assets/userAvatar.jpeg"
import carticon from '../../../../assets/carticon.svg'
import { useHistory } from 'react-router-dom';
import SecureLS from "secure-ls";
import DialogOutClick from "../../../../components/DialogOutClick";
import { AuthContext } from "../../../../context/AuthContext";
import DialogPop from "../../../../components/DialogPop";
import Instant from '../../../../assets/Instant.svg'
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import Dropdowncountries from "../../../../components/DropdownCountries";


export default function CorpNavbar({ accountInfo, cartCount }) {

  const history = useHistory()
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const auth = useContext(AuthContext);
  const [ShowLogout, setShowLogout] = useState(false)
  const [showname, setshowname] = useState(false)

  const [showRequestType, setshowRequestType] = useState(false)

  const logMeOut = () => {
    auth.logout();
    history.push("/");
  };

  const [search, setsearch] = useState("");

  return (
    <>
      <div className="navbar-main">
        <div className="nav-business-details">

          <div
            className="nav-img"
            style={{
              backgroundImage:
                accountInfo?.cop_logo !== null
                  ? `url(${accountInfo?.cop_logo})`
                  : accountInfo?.profile_photo !== ""
                    ? `url(${accountInfo?.profile_photo})`
                    : `url(${userAvatar})`,
            }}
          >
          </div>

          <div className="nav-business-name">
            {accountInfo?.account_name ? (
              <div >
                {
                  accountInfo?.role === "dillivry-corp-sys-admin" ? (
                    <div style={{ display: "flex", cursor: "pointer" }} onClick={() => setshowname(!showname)}>
                      <p className="nav-title">
                        {accountInfo?.account_name?.charAt(0).toUpperCase() +
                          accountInfo?.account_name?.slice(1)}
                      </p>

                      <span style={{ marginLeft: "5px" }}>
                        {
                          showname ?
                            <MdOutlineKeyboardArrowUp fontSize={20} />
                            :
                            <MdOutlineKeyboardArrowDown fontSize={20} />
                        }
                      </span>
                    </div>
                  )
                    : (
                      <></>
                    )}
              </div>
            ) : (
              <></>
            )}
            {
              accountInfo?.account_holder ?
                <>
                  {
                    showname ?
                      <span className="nav-title">
                        {
                          accountInfo.account_holder.first_name.charAt(0).toUpperCase() +
                          accountInfo.account_holder.first_name.slice(1) + " " + accountInfo?.account_holder?.last_name}
                      </span>
                      :
                      null
                  }
                </>
                :
                null
            }

            <span
              style={{ userSelect: "none", color: "red", cursor: "pointer" }}
              onClick={() => setShowLogout(true)}
            >
              {" "}
              Logout
            </span>
          </div>
        </div>

        <div className="cart-request-question">
          <div
            className="messageiconquestion"
            onClick={() => {
              history.push("/Messages");
            }}
            >
            <img
              src={carticon}
              className="icon-question"
              alt="MessageIcon"
            />
          </div>
            <Dropdowncountries/>
        </div>
      </div>

      <DialogPop
        openPopup={showRequestType}
        setopenPopup={setshowRequestType}
        spanhead={"Hello " + accountInfo?.given_name?.charAt(0).toUpperCase() + accountInfo?.given_name?.slice(1)}
        centerhead="Choose your preferred service"
      >
        <div style={{ dispaly: "flex", justifyContent: "center", gap:'10px' }} className="instant-schedule">
          <div className="instant"
            onClick={() => {
              history.push({
                pathname: "/viewQuote/request-a-delivery",
                state: {
                  state: `instant`
                },
              });
              setshowRequestType(!showRequestType)
            }}

          >
            <div className="icon">
              <img src={Instant} alt="Instant" className="instant-icon" />
            </div>
            <div className="instant-text-subtext">
              <p className="Instant-main">Instant</p>
              <span className="instant-sub">Pickup and delivery today</span>
            </div>
          </div>

          <div className="Schedule" onClick={() => {
            history.push({
              pathname: "/viewQuote/request-a-delivery",
              state: {
                state: `schedule`
                //  from:  'registration'
              },
            });
            setshowRequestType(!showRequestType)
          }}  >
            <div className="icon">
              <img src={Instant} alt="Schedule" className="Schedule-icon" />
            </div>
            <div className="Schedule-text-subtext">
              <p className="Schedule-main">Schedule</p>
              <span className="Schedule-sub">
                Choose pickup and delivery date
              </span>
            </div>
          </div>
        </div>
      </DialogPop>

      <DialogOutClick
        open={ShowLogout}
        handleClose={() => setShowLogout(false)}
      >
        <div style={{ width: "100%", padding: "30px 30px 20px 30px" }}>
          <div
            style={{
              width: "100%",
              marginBottom: "30px",
              fontSize: "13px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>Are you sure you want to logout?</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="disBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => setShowLogout(false)}
            >
              No
            </div>
            <div
              className="ediBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "white",
                backgroundColor: "#808080",
                border: "1px solid #808080",
              }}
              onClick={logMeOut}
            >
              Yes
            </div>
          </div>
        </div>
      </DialogOutClick>
    </>
  );
}
