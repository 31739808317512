import React, {useEffect,useContext, useState} from 'react'
import {parsePhoneNumber, isValidPhoneNumber, getCountries, getCountryCallingCode } from 'react-phone-number-input'
import "./CustomPhoneNumber.css"
import { FaChevronDown } from 'react-icons/fa'
import en from 'react-phone-number-input/locale/en.json'
import Axios from 'axios'
import Cookies from 'js-cookie';


function CustomPhoneNumber({setisError, isError, setPhone_number , bg, borderRadius }) {
    
    const [search, setsearch] = useState('');
    useEffect(() => {
        setcountry(Cookies.get('i18next').toUpperCase());
        setdiallerCode(`+${getCountryCallingCode(Cookies.get('i18next').toUpperCase())}`)
      }, [Cookies.get('i18next').toUpperCase()]);

    const [country, setcountry] = useState(Cookies.get('i18next').toUpperCase())

    const [diallerCode, setdiallerCode] = useState(getCountryCallingCode(Cookies.get('i18next').toUpperCase()))

    const labels = en

    const [phone_no_input, setphone_no_input] = useState('');

    const [country_list, setcountry_list] = useState(getCountries());

    const [fresh_country_list, setfresh_country_list] = useState(getCountries());

    const [showList, setshowList] = useState(false)

    
    const [phoneIsvalid, setPhoneIsvalid] = useState(true);

    const handleOnChange = (e) => {
        setphone_no_input(e.target.value)
        setdiallerCode(`+${getCountryCallingCode(country)}`)
        setPhoneIsvalid(isValidPhoneNumber(`+${getCountryCallingCode(country)}${e.target.value}`)) 
        setPhone_number(`+${getCountryCallingCode(country)}${e.target.value}`)
        setisError(false)
    }

    const handleFlagSelect = (count)=>{
        // onChange(country || undefined); 
        setdiallerCode(`+${getCountryCallingCode(count)}`)
        setcountry(count); 
        setshowList(false)
        setsearch('')
        setPhoneIsvalid(isValidPhoneNumber(`+${getCountryCallingCode(count)}${phone_no_input}`)) 
        setPhone_number(`+${getCountryCallingCode(count)}${phone_no_input}`)
        setcountry_list(fresh_country_list)
    }

    const filterData = e => {
        // console.log(labels[country_list[0]], "cooooool")
        if(e.target.value.length === 0 ){

        }
        setsearch(e.target.value.toLowerCase())
        const filteredData = fresh_country_list?.filter(list => labels[list].toLowerCase().includes(e.target.value.toLowerCase())) 
        setcountry_list(filteredData)
        }

  return (
    <>
    {/*  */}
        <div className="select_country_container" 
            style={{border: phoneIsvalid && !isError ? "1px solid #C4C4C4" : isError ? "1px solid red": "1px solid red" , backgroundColor: bg, borderRadius:borderRadius}}
            >
            <div className="select_country" onClick={()=>setshowList(!showList)} 
                style={{borderRight: phoneIsvalid ? "1px solid #C4C4C4" : "1px solid red" }}
                >
                <div className='flag_house'>
                <span className={`flag-icon flag-icon-${country.toLowerCase()}`}></span>
                    {/* <img src={flag_url} alt="" /> */}
                </div>
                <FaChevronDown size={10} className='chevron_icon' />
            </div>
            
            <div className='country_code'>
                ({diallerCode})
            </div>
            <input 
                className='phone_num_input' 
                type="number" 
                placeholder='0000000000' 
                value = {phone_no_input}
                onChange={handleOnChange}
            />

        </div>
        {
            showList ?
                <div className="select_options_cont">
                        
                    <div className='search_country' >
                        <input type="text"
                            value={search} 
                            autoFocus
                            placeholder="Search..."  
                            onChange={filterData}
                        />
                    </div>
                    {country_list.map((country) => (
                        <span className='select_options' key={country} onClick={()=>{handleFlagSelect(country)}}>
                            {labels[country]}
                        </span>
                    ))}
                </div>
                :
                <></>
        }
   
    </>
  )
}

export default CustomPhoneNumber

