import React, { useContext, useState, useEffect } from 'react'
import backtodashboard from "../SenderDashboard/Assets/backtodashboard.svg";
import messagequestion from "../SenderDashboard/Assets/messagequestion.svg";
import dillivrylogo from '../SenderDashboard/Assets/dillivrylogo.svg'
import "./SenderSideBar.css"
import { useHistory } from 'react-router-dom'
import { SenderContext } from "../../../context/SenderDashboard/SenderDashboardContext";
import DialogOutClick from '../../../components/DialogOutClick';
import { AuthContext } from '../../../context/AuthContext';
import userAvatar from "../../../assets/userAvatar.jpeg";
import shopping from '../../../assets/shopping.svg'
import SecureLS from "secure-ls";
import GlobalizationLanguage, { GlobalizationLanguagewithCOntryCode } from '../../../components/GlobalizationLanguage';
import Cookies from 'js-cookie';
import { ConnContext } from '../../../context/ConnContext';
import axios from 'axios';
import { Avatar, Drawer } from '@mui/material';
import {  makeStyles } from '@mui/styles';
import { IoClose } from 'react-icons/io5';
import { HiMenuAlt2 } from "react-icons/hi";
import clsx from 'clsx';

export default function SenderSideBar({ links, pageTitle, state }) {
  const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
  });
  const classes = useStyles();
  const [drawerstate, setdrawerState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setdrawerState({ ...drawerstate, [anchor]: open });
  };


  const auth = useContext(AuthContext);
  const [ShowLogout, setShowLogout] = useState(false)

  const { alldata, UpdateAlldata } = useContext(SenderContext)
  const [accountInfo, setAccountInfo] = useState()
  const [Cart, SetCart] = useState()

  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()
  const { visitingCountryCode } = useContext(ConnContext);

  useEffect(() => {
      setCountry(Cookies.get('i18next'));
    },[Cookies.get('i18next')])

  const [country,setCountry]=useState(countryvalue);

  const ls = new SecureLS({ isCompression: true, encodingType: "rabbit", encryptionSecret: "dm_dillivry", });

  const endpoint = process.env.REACT_APP_API;

  useEffect(() => {
    // console.log(alldata)
    if (alldata !== undefined) {
      if(alldata?.status === 200){
      const CheckLength =  alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length
      const InfoData = alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info) : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)
      CheckLength === 1 ? setAccountInfo(InfoData[0]) : setAccountInfo(InfoData[1]);
      SetCart(alldata?.data?.data?.deliveries ?   alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map((x) => Object.values(x?.account))?.flat()?.map(x => x?.cart_history?.deliveries)?.flat() : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map((x) => Object.values(x?.account))?.flat()?.map(x => x?.cart_history?.deliveries)?.flat())
      }
      // console.log('You are misbehaving')
    }
    else{
      axios
        .get(`${endpoint}m-dillivry-sender-api`, {
          headers: {
            Authorization: ls.get("_utk"),
          },
        })
        .then((res) => {

          // console.log(res.data.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info))
          // setAccountInfo(res.data.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)[0])

          const CheckLength =  res?.data?.data?.deliveries ?  res?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length : res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length
          const InfoData = res?.data?.data?.deliveries ?  res?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info) : res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)
          CheckLength === 1 ? setAccountInfo(InfoData[0]) : setAccountInfo(InfoData[1])
          SetCart(res?.data?.data?.deliveries ?   res?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map((x) => Object.values(x?.account))?.flat()?.map(x => x?.cart_history?.deliveries)?.flat() : res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map((x) => Object.values(x?.account))?.flat()?.map(x => x?.cart_history?.deliveries)?.flat())
          UpdateAlldata(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
}, [])

  const logMeOut = () => {
    auth.logout();
    history.push("/");
  };
  var sendermessage = GlobalizationLanguage("/sendermessage")
  var viewQuote = GlobalizationLanguage("/viewQuote")
  var viewQuote_myCart = GlobalizationLanguage("/viewQuote/myCart")

  const history = useHistory();

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
<div className="navigation">
          {/* <input type="checkbox" className="toggle-menu" /> */}
          
          <div className="side_menu">
          <div style={{ padding: "10px 20px 0px 20px" }}>
                  <IoClose size= '35px' color="var(--main)"  onClick={toggleDrawer(anchor, false)}/>
                </div>
            <div className="menu-inner-div" style={{marginTop:'0'}}>
              <div className="icondiv" onClick={() => history.push(viewQuote)}>
                <img
                  src={backtodashboard}
                  alt="backtodashboard"
                  className="backtodashboard"
                />
              </div>

              <div className="backtext" onClick={() => history.push(viewQuote)}>
                <span className="text-title">Back to dashboard</span>
              </div>
            </div>

            <div className="logo-description">
              {/* <div className="logo-body" style={{ 
                backgroundImage: accountInfo?.cop_logo !== null ? `url(${accountInfo?.cop_logo})`: accountInfo?.profile_photo !== "" ? `url(${accountInfo?.profile_photo})`:`url(${userAvatar})`
              }}></div> */}
              {
                accountInfo?.cop_logo !== undefined || null ? (
                  <img
                    className="nav-img"
                    alt="juk"
                    src={`${accountInfo?.profile_photo}`}
                  />
                ) :

                  accountInfo?.profile_photo !== undefined || null ? (
                    <img
                      className="nav-img"
                      alt="juk"
                      src={`${accountInfo?.profile_photo}`}
                    />
                  ) : (
                    <img className="nav-img" alt="juk" src={`${userAvatar}`} />
                  )}

              <div className="business-details">
                {
                  accountInfo?.given_name ?
                    <>
                      <p className="businessname" style={{ fontWeight: '500' }}>
                        {accountInfo?.role === "business_admin" ?
                          accountInfo?.business_name?.charAt(0).toUpperCase() + accountInfo?.business_name?.slice(1)
                          :
                          <></>

                        }

                      </p>
                      <p className="realname">{accountInfo?.given_name}</p>
                      {/* <p className="businessid">Business ID : D343848423904</p> */}
                    </>
                    :
                    <></>
                }


                <p onClick={() => {
                  setShowLogout(true);
                }}
                  className="logout" style={{ userSelect: 'none', color: 'red' }}>Logout</p>
              </div>
            </div>

            <div className="selected-menu ">
              {links.map((x, i) => (
                <div key={i} onClick={() => history.push(x.path)} className={x.state === "active" ? "menu-items-active" : "menu-items"} >{x.name}</div>
              ))}
            </div>


            <div onClick={() => {
              history.push(sendermessage)
            }} className="help-logo-title">
              <img src={messagequestion} alt="messagequestion" className="help-logo-icon" />
            </div>

            {/* <div className="bottom-logo" onClick={() => history.push('/')}>
              <img src={dillivrylogo} alt="dillivrylogo" className="bottom-logo-img" />
            </div> */}

            <div className="bottom-logo" onClick={()=>history.push(GlobalizationLanguagewithCOntryCode("/", country, visitingCountryCode ))}>
              <img src={dillivrylogo} alt="dillivrylogo" className="bottom-logo-img" />
              
            </div>


          </div>
        </div>
    </div>
  )

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
      <header className="header" >
          <HiMenuAlt2 size= '35px' color="var(--main)"  onClick={toggleDrawer(anchor, true)}>
            {anchor}
          </HiMenuAlt2>
        
        <div className="titl">
          <p className="tltletag">{pageTitle}</p>
        </div>

        <div className="search-cart">
          <div onClick={() => console.log(state)}  >
            {" "}
            {/* <img src={searchnormal} alt="searchIcon" className="searchicon" /> */}
          </div>
          <div className="cart-icon" onClick={() => history.push(viewQuote_myCart)}>
            {
              Cart?.length > 0 ?
                <div className="cart-icon-badge">
                  {Cart?.length}
                </div>
                :
                null
            }
            <img src={shopping} alt="shooping" className="carticon" />
          </div>

          <div>
            {" "}
          </div>
        </div>
      </header>
      <Drawer anchor={anchor} open={drawerstate[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
      </React.Fragment>))}

      <DialogOutClick
        open={ShowLogout}
        handleClose={() => setShowLogout(false)}
      >
        <div style={{ width: "100%", padding: "30px 30px 20px 30px" }}>
          <div
            style={{
              width: "100%",
              marginBottom: "30px",
              fontSize: "13px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>Are you sure you want to logout?</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="disBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => setShowLogout(false)}
            >
              No
            </div>
            <div
              className="ediBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "white",
                backgroundColor: "#808080",
                border: "1px solid #808080",
              }}
              onClick={logMeOut}
            >
              Yes
            </div>
          </div>
        </div>
      </DialogOutClick>

    </div>
  )
}
