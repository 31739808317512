import React from 'react';
import CorporateLayout from "./CorporateLayout";
import MobileCorpLayout from "./CorporateComponents/MobileCorpLayout";



export default function CreateDillivry() {

    return(
        <>
        <div className='deskstopcorp_con'>
            <CorporateLayout>
                <h2 style={{color:"var(--main)"}}>Coming soon</h2>
            


            </CorporateLayout>

        </div>
        <div className='mobilecorp_con'>
            <MobileCorpLayout pageTitle="Create dillivry"/>
                <div className='mobilecorp_gen' style={{ textAlign:"center"}} >
                <h2 style={{color:"var(--main)"}}>Coming soon</h2>
                

                </div>
            

           

            

        </div>
        </>
        
       
    )
}