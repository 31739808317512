import getType from './search.types';

export const INITIAL_STATE =  []

const searchReducer = (state, action) => {
    switch(action.type) {
      case getType.GET_DEFAULT_SEARCH_DATA:
        return {
          searches: 
            action.payload
        };
      
      case getType.ADD_To_WATCH:
        const item  = []
          state.searches.forEach(function (element) {
            if(action.payload.includes(element.delivery_id)){
              element.watched = "yes";
              item.push(element)
            }
            else{
              item.push(element)
            }
          })
        return {
          searches: item
        };
        
      case getType.REMOVE_FROM_WATCH:
        const removeItem  = []
          state.searches.forEach(function (element) {
            if(action.payload.includes(element.delivery_id)){
              delete element.watched
              removeItem.push(element)
            }
            else{
              removeItem.push(element)
            }
          })
        return {
          searches: removeItem
        };

      default:
        return state;
    }
  };

export default searchReducer;
