import React, {useState, useEffect, useContext} from 'react'
import './GetStartedHero.css'
import Cookies from 'js-cookie';
import { ConnContext } from '../../../context/ConnContext';


export default function GetStartedTextSwitch() {
    const {visitingCountryCode} = useContext(ConnContext)

    const [index, setindex] = useState(0);
    const country = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

    const switches = [
        {
            text1: "Got",
            text2: "Anything",
            text3: "to send or move?",
        },
        {
            text1: "Got",
            text2: "Food items",
            text3: "to send or move?",
        },
        {
            text1: "Got",
            text2: "Electronics",
            text3: "to send or move?",
        },
        {
            text1: "Got",
            text2: "Groceries",
            text3: "to send or move?",
        },
        {
            text1: "Got",
            text2: "Furniture",
            text3: "to send or move?",
        },
        {
            text1: "Got",
            text2: "Pharmaceuticals",
            text3: "to send or move?",
        },
        {
            text1: "Got",
            text2: "Beauty & Skincare",
            text3: "to send or move?",
        },
        {
            text1: "Got",
            text2: "Fashion items",
            text3: "to send or move?",
        },
        {
            text1: "Got",
            text2: "Documents & Books",
            text3: "to send or move?",
        },
        {
            text1: "Got",
            text2: "Heavy items",
            text3: "to send or move?",
        },
        {
            text1: "Got",
            text2: "Computer accessories",
            text3: "to send or move?",
        },
        {
            text1: "Moving",
            text2: "Home or relocating",
        }
    ]

    const switchText = (i)=> {
        setTimeout(() => {
            if(i === (switches.length - 1)){
                setindex(0)
            }
            else{
                setindex(i + 1)   
            }
        }, 2000)
    }
    useEffect(() => {
        switchText(index)
        // if(country==="ng"||country==="gh"||country==="ke"||country==="za"||country==="rw")
        // {switchText(index)}
      }, [index])
      
  return (
    <div className="textSwitchCont">
        {country==="ng"||country==="gh"||country==="ke"||country==="za"||country==="rw"?
        <><h1>{switches[index]?.text1} <span>{switches[index]?.text2}</span></h1>
        <h1>{switches[index]?.text3}</h1></>
        :
        null
        }
        {country==="gb"||country==="us"||country==="ca"?<><h1>Got Something</h1>
        <h1>to send or move?</h1></>:null}
        

        <h2>List your item and let delivery companies compete for your job.</h2>
    </div>
  )
}
