import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import dillivry from "../../../../assets/Dillivry.png";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { IconContext } from "react-icons";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

import Carousel from "react-material-ui-carousel";
import GoogleMapWidget from "../../../../components/Map/GoogleMapWidget";
import { AiFillFileText } from "react-icons/ai";
import { MdDashboard, MdBlock, MdLocationOn, MdPayment } from "react-icons/md";
import { FaClipboardCheck } from "react-icons/fa";
import { RiMiniProgramFill, RiPinDistanceFill } from "react-icons/ri";
import { BsCreditCard } from "react-icons/bs";
import { BiPhoneCall } from "react-icons/bi";
import { IoMdMailOpen } from "react-icons/io";

import { Button, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DialogPop from "../../../../components/DialogPop";
import { PuffLoader } from "react-spinners";
import MessageFulfiller from "../../../SendQuote/ViewQuote/MessageFulfiller";
import SecureLS from "secure-ls";
import { DashboardContext } from "../../../../context/Dashboard/DashboardProvider/dashboard.provider";
import { AuthContext } from "../../../../context/AuthContext";
import { useHistory } from "react-router-dom";
import DashDrillDownCurrent from "../DashDrillDownCurrent";
import HistoryDrillDownMobile from "./HistoryDrillDownMobile";
import { ConnContext } from "../../../../context/ConnContext";
import moment from 'moment';
import GlobalizationLanguage from "../../../../components/GlobalizationLanguage";
function HistoryDrillDown({ dp }) {
  var timeout = GlobalizationLanguage("/timeout")

  const auth = useContext(AuthContext);
  const history = useHistory();

  const { dashboards, updateDashboard } = useContext(DashboardContext);

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const { type, id } = useParams();

  const [viewData, setviewData] = useState([]);

  const [origin, setorigin] = useState([]);
  const [destination, setdestination] = useState([]);
  const [assignedAgent, setAssignedAgent] = useState([]);

  const [itemToReject, setItemToReject] = useState([]);
  const [rejectPopup, setrejectPopup] = useState(false);
  const [rejecting, setrejecting] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loadingData, setloadingData] = useState(false);
  const Authorization = ls.get("_nodi");

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: Authorization,
    },
  });

  const schema = yup.object().shape({
    comment: yup
      .string()
      .required("State your reason")
      .min(10, "Can't be lesser than 10 digits")
      .max(80, "Can't be lesser than 80 digits"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const { visitingCountryCode } = useContext(ConnContext);

  const rejectQuote = async (data) => {
    setrejecting(true);
    authAxios
      .post(
        `m-dillivry-bid-manager`,
        {
          action_type: "reject_bid",
          delivery_id: itemToReject,
          comment: data.comment,
          vcc: visitingCountryCode,
        },
        { timeout: 240000 }
      )
      .then((response) => {
        setrejectPopup(false);
        setrejecting(false);
        if (response.status === 200) {
          Swal.fire({
            icon: "info",
            text: "Quote rejected!",
            timer: 2500,
            showConfirmButton: false,
          });
        }
      })
      .catch((err) => {
        if (err.code === "ECONNABORTED") {
          console.log("internal-server-error-timeout");
          history.push(timeout);
          // window.location.reload()
        }
        setrejectPopup(false);
        setrejecting(false);
        Swal.fire({
          icon: "error",
          text: "error!",
          timer: 2000,
          showConfirmButton: false,
        });
        console.log(err);
      });
  };

  useEffect(() => {
    if (dashboards !== undefined) {
      let data = [];
      if (type === "current") {
        data = dashboards.data.current_quotes.filter(
          (item) => item.delivery_id === id
        );
      }
      if (type === "assign") {
        data = dashboards.data.accepted_quotes.assigned.filter(
          (item) => item.delivery_id === id
        );
        const agents = dashboards.data.all_users_list.all_users_list.filter(
          function (lastmile) {
            return lastmile.role === "dillivry-corp-last-mile-ops";
          }
        );
        setAssignedAgent(
          agents.filter(
            (agent) =>
              agent.user_id === data[0].assigned_agent_details.pickup_agent_id
          )
        );
      }
      if (type === "unassign") {
        data = dashboards.data.accepted_quotes.unassigned.filter(
          (item) => item.delivery_id === id
        );
      }
      if (type === "out_for_delivery") {
        data = dashboards.data.picked_up.filter(
          (item) => item.delivery_id === id
        );
        const agents = dashboards.data.all_users_list.all_users_list.filter(
          function (lastmile) {
            return lastmile.role === "dillivry-corp-last-mile-ops";
          }
        );
        setAssignedAgent(
          agents.filter(
            (agent) =>
              agent.user_id === data[0].assigned_agent_details.pickup_agent_id
          )
        );
      }
      if (type === "in_transit") {
        data = dashboards.data.out_for_delivery.filter(
          (item) => item.delivery_id === id
        );
        const agents = dashboards.data.all_users_list.all_users_list.filter(
          function (lastmile) {
            return lastmile.role === "dillivry-corp-last-mile-ops";
          }
        );
        setAssignedAgent(
          agents.filter(
            (agent) =>
              agent.user_id === data[0].assigned_agent_details.pickup_agent_id
          )
        );
      }
      if (type === "missed") {
        data = dashboards.data.missed_delivery.filter(
          (item) => item.delivery_id === id
        );
        const agents = dashboards.data.all_users_list.all_users_list.filter(
          function (lastmile) {
            return lastmile.role === "dillivry-corp-last-mile-ops";
          }
        );
        setAssignedAgent(
          agents.filter(
            (agent) =>
              agent.user_id === data[0].assigned_agent_details.pickup_agent_id
          )
        );
      }
      if (type === "decline") {
        data = dashboards.data.unsuccessful_quotes.filter(
          (item) => item.delivery_id === id
        );
      }
      if (type === "complete") {
        data = dashboards.data.completed_quotes.filter(
          (item) => item.delivery_id === id
        );
        const agents = dashboards.data.all_users_list.all_users_list.filter(
          function (lastmile) {
            return lastmile.role === "dillivry-corp-last-mile-ops";
          }
        );
        setAssignedAgent(
          agents.filter(
            (agent) =>
              agent.user_id === data[0].delivery_agent_details.pickup_agent_id
          )
        );
      }

      setviewData(data);
      const startLat = parseFloat(data[0].start_location.lat);
      const startlng = parseFloat(data[0].start_location.lng);
      const endLat = parseFloat(data[0].end_location.lat);
      const endLng = parseFloat(data[0].end_location.lng);

      setorigin([
        {
          id: "pickup",
          title: "Pickup",
          coordinates: {
            lat: startLat,
            lon: startlng,
          },
        },
      ]);
      setdestination([
        {
          id: "destination",
          title: "Destination",
          coordinates: {
            lat: endLat,
            lon: endLng,
          },
        },
      ]);
    } else {
      setloadingData(true);

      axios
        .get(`${process.env.REACT_APP_API}m-dillivry-dashboard-manager`, {
          headers: {
            Authorization: ls.get("_nodi"),
          },
        })
        .then((response) => {
          setloadingData(false);

          ls.set(
            "means_of_delivery",
            JSON.stringify(
              response?.data?.account_profile?.account_profile[0]
                ?.mode_of_delivery
            )
          );

          if (response.status === 200) {
            updateDashboard(response);
            let data = [];
            if (type === "current") {
              data = response.data.current_quotes.filter(
                (item) => item.delivery_id === id
              );
            }
            if (type === "assign") {
              data = response.data.accepted_quotes.assigned.filter(
                (item) => item.delivery_id === id
              );
              const agents = response.data.all_users_list.all_users_list.filter(
                function (lastmile) {
                  return lastmile.role === "dillivry-corp-last-mile-ops";
                }
              );
              setAssignedAgent(
                agents.filter(
                  (agent) =>
                    agent.user_id ===
                    data[0].assigned_agent_details.pickup_agent_id
                )
              );
            }
            if (type === "unassign") {
              data = response.data.accepted_quotes.unassigned.filter(
                (item) => item.delivery_id === id
              );
            }
            if (type === "out_for_delivery") {
              data = response.data.picked_up.filter(
                (item) => item.delivery_id === id
              );
              const agents = response.data.all_users_list.all_users_list.filter(
                function (lastmile) {
                  return lastmile.role === "dillivry-corp-last-mile-ops";
                }
              );
              setAssignedAgent(
                agents.filter(
                  (agent) =>
                    agent.user_id ===
                    data[0].assigned_agent_details.pickup_agent_id
                )
              );
            }
            if (type === "decline") {
              data = response.data.unsuccessful_quotes.filter(
                (item) => item.delivery_id === id
              );
            }
            if (type === "missed") {
              data = response.data.missed_delivery.filter(
                (item) => item.delivery_id === id
              );
            }
            if (type === "complete") {
              data = response.data.completed_quotes.filter(
                (item) => item.delivery_id === id
              );
              const agents = response.data.all_users_list.all_users_list.filter(
                function (lastmile) {
                  return lastmile.role === "dillivry-corp-last-mile-ops";
                }
              );
              setAssignedAgent(
                agents.filter(
                  (agent) =>
                    agent.user_id ===
                    data[0].delivery_agent_details.pickup_agent_id
                )
              );
            }

            setviewData(data);
            const startLat = parseFloat(data[0].start_location.lat);
            const startlng = parseFloat(data[0].start_location.lng);
            const endLat = parseFloat(data[0].end_location.lat);
            const endLng = parseFloat(data[0].end_location.lng);

            setorigin([
              {
                id: "pickup",
                title: "Pickup",
                coordinates: {
                  lat: startLat,
                  lon: startlng,
                },
              },
            ]);
            setdestination([
              {
                id: "destination",
                title: "Destination",
                coordinates: {
                  lat: endLat,
                  lon: endLng,
                },
              },
            ]);
          }
        })
        .catch((err) => {
          setloadingData(false);
          if (err?.response?.status === 403) {
            Swal.fire({
              icon: "warning",
              title: "Session!",
              text: "Session expired login again!",
              allowOutsideClick: false,
              confirmButtonColor: "var(--main)",
            }).then((result) => {
              if (result.value) {
                auth.logout();
                history.push(`/login`);
              }
            });
          }
        });
    }
  }, []);

  const numberWithCommas = new Intl.NumberFormat("en-GB");

  return (
    <>
      <div className="userDesktop">
        <div className="viewSearch">
          {loadingData ? (
            <div
              style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "none",
                zIndex: "300",
                position: "fixed",
                top: "0",
                paddingTop: "5px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <LinearProgress color="success" />
              </Box>
              <header className="item-header-content-header">
                <div className="item-header-content">
                  <div className="item-logo-content">
                    <Link to="/">
                      {" "}
                      <img
                        src={dillivry}
                        alt=""
                        className="item-content-logo"
                      />
                    </Link>
                  </div>
                </div>
              </header>
            </div>
          ) : null}
          {showMessage ? (
            <div style={{ padding: "30px 100px" }}>
              <div
                style={{
                  padding: "7px 15px",
                  backgroundColor: "var(--main)",
                  color: "white",
                  cursor: "pointer",
                  userSelect: "none",
                  display: "inline-block",
                  borderRadius: "5px",
                }}
                onClick={() => setShowMessage(false)}
              >
                Go back
              </div>
              <MessageFulfiller
                hide="yes"
                msg={messages}
                from="items"
                myDP={dp}
              />
            </div>
          ) : (
            viewData.map((view) => {
              var link = GlobalizationLanguage(`/message/${type}/${view.delivery_id}`)
              return (
                <div className="Desktop-screen-div">
                  <header className="item-header-content-header">
                    <div className="item-header-content">
                      <div className="item-logo-content">
                        <Link to="/">
                          {" "}
                          <img
                            src={dillivry}
                            alt=""
                            className="item-content-logo"
                          />
                        </Link>
                      </div>
                    </div>
                  </header>

                  <div key={view.delivery_id} className="view_Container">
                    <div className="view_Top_mobile">
                      <div className="topItem">
                        <div className="topItem-first-div">
                          <button
                            className="topItem-first-div-btn"
                            onClick={() => history.goBack()}
                          >
                            <span>Back</span>
                          </button>
                          <span
                            className="topItem-first-div-category"
                            style={{ fontWeight: "600" }}
                          >
                            {" "}
                            Category:{" "}
                          </span>
                          <span className="topItem-first-div-item-category">
                            {view.item_category.charAt(0)?.toUpperCase() +
                              view.item_category.slice(1).replace(/_/g, " ")}
                          </span>
                        </div>
                        <div className="topItem-second-div">
                          <span
                            className="topItem-second-div-Id"
                            style={{ fontWeight: "600" }}
                          >
                            ID:{" "}
                          </span>
                          <span className="topItem-second-div-item-category">
                            {view.delivery_id}
                          </span>
                          <button className="topItem-second-div-report">
                            <span>Report</span>
                          </button>
                        </div>
                      </div>
                      <div></div>
                    </div>

                    <div className="view_Map">
                      <GoogleMapWidget
                        origins={destination}
                        destinations={origin}
                      />
                    </div>
                    <div className="bodyItem" style={{ marginTop: "20px" }}>
                      <Grid container spacing={4}>
                        <Grid item md={3} xs={12}>
                          <div className="imgage-content">
                            <Carousel
                              navButtonsAlwaysInvisible={true}
                              indicators={false}
                              animation={"slide"}
                              timeout={100}
                              autoPlay={true}
                            >
                              {view.image_urls?.map((img_url, i) => (
                                <img
                                  key={i}
                                  src={img_url}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  alt="Item_image"
                                  className="carousel_image-"
                                />
                              ))}
                            </Carousel>
                          </div>
                        </Grid>
                        <Grid item md={5} xs={12}>
                          <>
                            <div className="content-iconn">
                              <div className="topTitle">
                                Delivery information
                              </div>
                              <div className="itemDescs">
                                <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <span data-tip data-for="globa1">
                                      <IconContext.Provider
                                        value={
                                          {
                                            // color: "#2e2e2e",
                                            // className: "global-class-name-icon",
                                          }
                                        }
                                      >
                                        {" "}
                                        <AiFillFileText />
                                      </IconContext.Provider>

                                          <ReactTooltip
                                            id="globa1"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>
                                              Item description:{" "}
                                            </span>
                                          </ReactTooltip>
                                        </span>
                                      </span>
                                      {/* {
                                        console.log(view, 'viewww')
                                      } */}
                                      <span className="icon-desc">
                                        <span>
                                          Item description:{" "}
                                        </span>
                                        {view.item_desc
                                          .charAt(0)
                                          ?.toUpperCase() +
                                          view.item_desc
                                            .toLowerCase()
                                            .slice(1)}
                                      </span>
                                    </div>

                                <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <span data-tip data-for="globalmode2">
                                      <IconContext.Provider
                                        value={
                                          {
                                            // color: "#2e2e2e",
                                            // className: "global-class-name-icon",
                                          }
                                        }
                                      >
                                        {" "}
                                        <MdDashboard />
                                      </IconContext.Provider>

                                      <ReactTooltip
                                        id="globalmode2"
                                        aria-haspopup="true"
                                        role="example"
                                        type="light"
                                      >
                                        <span>Item category </span>
                                      </ReactTooltip>
                                    </span>
                                  </span>
                                  <span className="icon-desc">
                                    <span>Item category: </span>
                                    {view.item_category
                                      .charAt(0)
                                      ?.toUpperCase() +
                                      view.item_category
                                        .slice(1)
                                        .replace(/_/g, " ")}
                                  </span>
                                </div>

                                <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <span data-tip data-for="globalmode">
                                      <IconContext.Provider
                                        value={
                                          {
                                            // color: "#2e2e2e",
                                            // className: "global-class-name-icon",
                                          }
                                        }
                                      >
                                        {" "}
                                        <AiFillFileText />
                                      </IconContext.Provider>

                                      <ReactTooltip
                                        id="globalmode"
                                        aria-haspopup="true"
                                        role="example"
                                        type="light"
                                      >
                                        <span>Delivery ID </span>
                                      </ReactTooltip>
                                    </span>
                                  </span>
                                  <span className="icon-desc">
                                    <span>Delivery ID: </span>
                                    {view.delivery_id}
                                  </span>
                                </div>

                                <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <span data-tip data-for="globalmdel">
                                      <IconContext.Provider
                                        value={
                                          {
                                            // color: "#2e2e2e",
                                            // className: "global-class-name-icon",
                                          }
                                        }
                                      >
                                        {" "}
                                        <AiFillFileText />
                                      </IconContext.Provider>

                                      <ReactTooltip
                                        id="globalmdel"
                                        aria-haspopup="true"
                                        role="example"
                                        type="light"
                                      >
                                        <span>Delivery type </span>
                                      </ReactTooltip>
                                    </span>
                                  </span>
                                  <span className="icon-desc">
                                    <span>Delivery type: </span>
                                    {view.delivery_type === "bid"
                                      ? "Quoted"
                                      : "Booked"}
                                  </span>
                                </div>

                                <div className="itemDesc">
                                  <span>
                                    <span data-tip data-for="globalsta">
                                      <IconContext.Provider
                                        value={
                                          {
                                            // color: "#2e2e2e",
                                            // className: "global-class-name-icon",
                                          }
                                        }
                                      >
                                        {" "}
                                        <RiMiniProgramFill />
                                      </IconContext.Provider>

                                      <ReactTooltip
                                        id="globalsta"
                                        aria-haspopup="true"
                                        role="example"
                                        type="light"
                                      >
                                        <span>Delivery status </span>
                                      </ReactTooltip>
                                    </span>
                                  </span>
                                  <span className="icon-desc">
                                    <span>Delivery status: </span>
                                    {type === "assign"
                                      ? "Awaiting Pickup"
                                      : type === "unassign"
                                      ? "Awaiting agent assignment"
                                      : type.charAt(0)?.toUpperCase() +
                                        type.slice(1).replace(/_/g, " ")}
                                  </span>
                                </div>
                                <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <span data-tip data-for="globaPick">
                                      <IconContext.Provider
                                        value={
                                          {
                                            // color: "#2e2e2e",
                                            // // className: "global-class-name-icon",
                                          }
                                        }
                                      >
                                        {" "}
                                        <MdLocationOn color="red" />
                                      </IconContext.Provider>

                                      <ReactTooltip
                                        id="globaPick"
                                        aria-haspopup="true"
                                        role="example"
                                        type="light"
                                      >
                                        <span>Pickup address </span>
                                      </ReactTooltip>
                                    </span>
                                  </span>
                                  <span className="icon-desc">
                                    <span>Pickup address: </span>

                                    {view.from}
                                  </span>
                                </div>
                                <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <span data-tip data-for="globaPickup">
                                      <IconContext.Provider
                                        value={
                                          {
                                            // color: "#2e2e2e",
                                            // className: "global-class-name-icon",
                                          }
                                        }
                                      >
                                        {" "}
                                        <BiPhoneCall />
                                      </IconContext.Provider>

                                      <ReactTooltip
                                        id="globaPickup"
                                        aria-haspopup="true"
                                        role="example"
                                        type="light"
                                      >
                                        <span>Pickup contact</span>
                                      </ReactTooltip>
                                    </span>
                                  </span>
                                  <span className="icon-desc">
                                    <span>Pickup contact: </span>

                                    {view.pickup_phone_no}
                                  </span>
                                </div>
                                <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <span data-tip data-for="globaPaddr">
                                      <IconContext.Provider
                                        value={
                                          {
                                            // color: "#2e2e2e",
                                            // className: "global-class-name-icon",
                                          }
                                        }
                                      >
                                        {" "}
                                        <MdLocationOn color="var(--main)" />
                                      </IconContext.Provider>

                                      <ReactTooltip
                                        id="globaPaddr"
                                        aria-haspopup="true"
                                        role="example"
                                        type="light"
                                      >
                                        <span>Delivery address</span>
                                      </ReactTooltip>
                                    </span>
                                  </span>
                                  <span className="icon-desc">
                                    <span>Delivery address: </span>

                                    {view.to}
                                  </span>
                                </div>

                                <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <span data-tip data-for="globaPickup2">
                                      <IconContext.Provider
                                        value={
                                          {
                                            // color: "#2e2e2e",
                                            // className: "global-class-name-icon",
                                          }
                                        }
                                      >
                                        {" "}
                                        <BiPhoneCall />
                                      </IconContext.Provider>

                                      <ReactTooltip
                                        id="globaPickup2"
                                        aria-haspopup="true"
                                        role="example"
                                        type="light"
                                      >
                                        <span>Delivery contact</span>
                                      </ReactTooltip>



                                      <ReactTooltip
                                            id="globaPickup3"
                                            aria-haspopup="true"
                                            role="example"
                                            type="light"
                                          >
                                            <span>Payer:</span>
                                          </ReactTooltip>
                                    </span>
                                  </span>
                                  <span className="icon-desc">
                                    <span>Delivery contact:</span>

                                    {view.delivery_phone_no}
                                  </span>
                                </div>


                                <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <span data-tip data-for="globaPickup3">
                                      <IconContext.Provider
                                        value={
                                          {
                                            // color: "#2e2e2e",
                                            // className: "global-class-name-icon",
                                          }
                                        }
                                      >
                                        {" "}
                                        <MdPayment />
                                      </IconContext.Provider>

                                      <ReactTooltip
                                        id="global30"
                                        aria-haspopup="true"
                                        role="example"
                                        type="light"
                                      >
                                        <span>Payer</span>
                                      </ReactTooltip>
                                    </span>
                                  </span>
                                  <span className="icon-desc">
                                    <span>Payer:</span>

                                    {view?.payer.charAt(0)?.toUpperCase() +
                                          view?.payer?.slice(1)}
                                  </span>
                                </div>

                                        



                              </div>
                            </div>

                            <div
                              className="content-iconn"
                              style={{ marginTop: "20px" }}
                            >
                              <div className="topTitle">
                                Payment information
                              </div>
                              <div className="itemDescs">
                                <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <span data-tip data-for="globaPickuppay">
                                      <IconContext.Provider
                                        value={
                                          {
                                            // color: "#2e2e2e",
                                            // className: "global-class-name-icon",
                                          }
                                        }
                                      >
                                        {" "}
                                        <BsCreditCard />
                                      </IconContext.Provider>

                                      <ReactTooltip
                                        id="globaPickuppay"
                                        aria-haspopup="true"
                                        role="example"
                                        type="light"
                                      >
                                        <span>Payment status</span>
                                      </ReactTooltip>
                                    </span>
                                  </span>
                                  <span className="icon-desc">
                                    <span>Payment status: </span>
                                    {view.payment_status === "pay_on_delivery"
                                      ? "Pay on delivery"
                                      : view.payment_status === "success"
                                      ? "Paid"
                                      : view.payment_status === "NA"
                                      ? "NA"
                                      : "Not paid"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <>
                            <div
                              className="itemDescs"
                              style={{ border: "none" }}
                            >
                              <div className="topTitle">Quote information</div>
                              <div className="itemDesc">
                                {" "}
                                <span>
                                  {" "}
                                  <span data-tip data-for="globaPQuote">
                                    <IconContext.Provider
                                      value={
                                        {
                                          // color: "#2e2e2e",
                                          // className: "global-class-name-icon",
                                        }
                                      }
                                    >
                                      {" "}
                                      <MdDashboard />
                                    </IconContext.Provider>

                                    <ReactTooltip
                                      id="globaPQuote"
                                      aria-haspopup="true"
                                      role="example"
                                      type="light"
                                    >
                                      <span> Quote status</span>
                                    </ReactTooltip>
                                  </span>
                                </span>
                                <span className="icon-desc">
                                  <span> Quote status: </span>
                                  {view.quote_status === "completed"
                                    ? "Delivered"
                                    : view.quote_status
                                        .charAt(0)
                                        ?.toUpperCase() +
                                      view.quote_status
                                        .slice(1)
                                        .replace(/_/g, " ")}
                                </span>
                              </div>

                              <div className="itemDesc">
                                {" "}
                                <span>
                                  {" "}
                                  <span data-tip data-for="globaPQuote2">
                                    <IconContext.Provider
                                      value={
                                        {
                                          // color: "#2e2e2e",
                                          // className: "global-class-name-icon",
                                        }
                                      }
                                    >
                                      {" "}
                                      <MdDashboard />
                                    </IconContext.Provider>

                                    <ReactTooltip
                                      id="globaPQuote2"
                                      aria-haspopup="true"
                                      role="example"
                                      type="light"
                                    >
                                      <span>Pickup date</span>
                                    </ReactTooltip>
                                  </span>
                                </span>
                                <span className="icon-desc">
                                  <span> Pickup date: </span>
                                  {view.pickup_date}
                                </span>
                              </div>
                            
                              <div className="itemDesc">
                                {" "}
                                <span>
                                  {" "}
                                  <span data-tip data-for="globaPQuote2">
                                    <IconContext.Provider
                                      value={
                                        {
                                          // color: "#2e2e2e",
                                          // className: "global-class-name-icon",
                                        }
                                      }
                                    >
                                      {" "}
                                      <MdDashboard />
                                    </IconContext.Provider>

                                    <ReactTooltip
                                      id="globaPQuote2"
                                      aria-haspopup="true"
                                      role="example"
                                      type="light"
                                    >
                                      <span>Pick up time</span>
                                    </ReactTooltip>
                                  </span>
                                </span>
                                <span className="icon-desc">
                                  <span> Pick up time: </span>
                                  {moment(view?.assigned_agent_details.pickup_timestamp).format("DD-MM-YYYY HH:MM:SS")}
                                </span>
                              </div>
                            
                              { view.quote_status === "completed" ?
                              <div className="itemDesc">
                                {" "}
                                <span>
                                  {" "}
                                  <span data-tip data-for="globaPQuote2">
                                    <IconContext.Provider
                                      value={
                                        {
                                          // color: "#2e2e2e",
                                          // className: "global-class-name-icon",
                                        }
                                      }
                                    >
                                      {" "}
                                      <MdDashboard />
                                    </IconContext.Provider>

                                    <ReactTooltip
                                      id="globaPQuote2"
                                      aria-haspopup="true"
                                      role="example"
                                      type="light"
                                    >
                                      <span>Delivery time</span>
                                    </ReactTooltip>
                                  </span>
                                </span>
                                <span className="icon-desc">
                                  <span> Delivery time: </span>
                                  {
                                    moment( view?.delivery_agent_details?.delivery_timestamp).format('DD-MM-YYYY hh:mm:ss ')
                                                }
                                </span>
                              </div>
                              :
                              null}

                              <div className="itemDesc">
                                {" "}
                                <span>
                                  {" "}
                                  <span data-tip data-for="globaPQuote21">
                                    <IconContext.Provider
                                      value={
                                        {
                                          // color: "#2e2e2e",
                                          // className: "global-class-name-icon",
                                        }
                                      }
                                    >
                                      {" "}
                                      <MdDashboard />
                                    </IconContext.Provider>

                                    <ReactTooltip
                                      id="globaPQuote21"
                                      aria-haspopup="true"
                                      role="example"
                                      type="light"
                                    >
                                      <span>Delivery time frame:</span>
                                    </ReactTooltip>
                                  </span>
                                </span>
                                <span className="icon-desc">
                                  <span>Delivery time frame: </span>
                                  {view.delivery_time_frame}
                                </span>
                              </div>

                              <div className="itemDesc">
                                <span>
                                  {" "}
                                  <span data-tip data-for="globaPQuote22">
                                    <IconContext.Provider
                                      value={
                                        {
                                          // color: "#2e2e2e",
                                          // className: "global-class-name-icon",
                                        }
                                      }
                                    >
                                      {" "}
                                      <FaClipboardCheck />
                                    </IconContext.Provider>

                                    <ReactTooltip
                                      id="globaPQuote22"
                                      aria-haspopup="true"
                                      role="example"
                                      type="light"
                                    >
                                      <span>Accepted quote</span>
                                    </ReactTooltip>
                                  </span>
                                  {/* <FaClipboardCheck/> */}
                                </span>
                                <span className="icon-desc">
                                  <span>Accepted quote: </span>
                                  &#8358;{" "}
                                  {numberWithCommas.format(view.quote_amount)}
                                </span>
                              </div>
                              <div className="itemDesc">
                                {type === "out_for_delivery" ? (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "space-between",
                                        margin: "5px 0",
                                      }}
                                    >
                                      <Button
                                        size="small"
                                        variant="contained"
                                        style={{
                                          fontSize: "12px",
                                          border: "1px solid var(--main)",
                                          backgroundColor: "white",
                                          color: "var(--main)",
                                          textTransform: "none",
                                        }}
                                        onClick={() =>
                                          history.push(
                                            link
                                          )
                                        }
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            gap: "5px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <span>
                                            <IoMdMailOpen size="12px" />
                                          </span>
                                          <span>Message</span>
                                        </div>
                                      </Button>
                                    </div>
                                  </>
                                ) : type === "assign" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-between",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      variant="contained"
                                      style={{
                                        fontSize: "12px",
                                        border: "1px solid red",
                                        backgroundColor: "#e2a5a5",
                                        color: "red",
                                        textTransform: "none",
                                      }}
                                      onClick={() => {
                                        setrejectPopup(true);
                                        setItemToReject(view.delivery_id);
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <span>
                                          <MdBlock size="12px" />
                                        </span>
                                        <span>Reject</span>
                                      </div>
                                    </Button>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      style={{
                                        fontSize: "12px",
                                        border: "1px solid var(--main)",
                                        backgroundColor: "white",
                                        color: "var(--main)",
                                        textTransform: "none",
                                      }}
                                      onClick={() =>
                                        history.push(
                                          link
                                        )
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <span>
                                          <IoMdMailOpen size="12px" />
                                        </span>
                                        <span>Message</span>
                                      </div>
                                    </Button>
                                  </div>
                                ) : type === "unassign" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-between",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      variant="contained"
                                      style={{
                                        fontSize: "12px",
                                        border: "1px solid red",
                                        backgroundColor: "#e2a5a5",
                                        color: "red",
                                        textTransform: "none",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        onClick={() => {
                                          setrejectPopup(true);
                                          setItemToReject(view.delivery_id);
                                        }}
                                      >
                                        <span>
                                          <MdBlock size="12px" />
                                        </span>
                                        <span>Reject</span>
                                      </div>
                                    </Button>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      style={{
                                        fontSize: "12px",
                                        border: "1px solid var(--main)",
                                        backgroundColor: "var(--main)",
                                        color: "white",
                                        textTransform: "none",
                                      }}
                                      onClick={() =>
                                        history.push(
                                          link
                                        )
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <span>
                                          <IoMdMailOpen size="12px" />
                                        </span>
                                        <span>Message</span>
                                      </div>
                                    </Button>
                                  </div>
                                ) : type === "complete" ? null : type ===
                                  "missed" ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-between",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      variant="contained"
                                      style={{
                                        fontSize: "12px",
                                        border: "1px solid var(--main)",
                                        backgroundColor: "var(--main)",
                                        color: "white",
                                        textTransform: "none",
                                      }}
                                      onClick={() =>
                                        history.push(
                                          link
                                        )
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <span>
                                          <IoMdMailOpen size="12px" />
                                        </span>
                                        <span>Message</span>
                                      </div>
                                    </Button>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-between",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <Button
                                      size="small"
                                      variant="contained"
                                      style={{
                                        fontSize: "12px",
                                        border: "1px solid red",
                                        backgroundColor: "#e2a5a5",
                                        color: "red",
                                        textTransform: "none",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <span>
                                          <MdBlock size="12px" />
                                        </span>
                                        <span>Reject</span>
                                      </div>
                                    </Button>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      style={{
                                        fontSize: "12px",
                                        border: "1px solid var(--main)",
                                        backgroundColor: "var(--main)",
                                        color: "white",
                                        textTransform: "none",
                                      }}
                                      onClick={() =>
                                        history.push(
                                          link
                                        )
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "5px",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <span>
                                          <IoMdMailOpen size="12px" />
                                        </span>
                                        <span>Message</span>
                                      </div>
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div
                              className="itemDescs"
                              style={{ marginTop: "20px" }}
                            >
                              {/* <div className="itemDesc"> */}
                              <span
                                className="icon-desc"
                                style={{
                                  flexDirection: "column",
                                }}
                              >
                                {
                                  type === "out_for_delivery" ? (
                                    <>
                                      <div className="topTitle">
                                        Assigned Agent
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          border: "1px solid #E5E5E5",
                                          borderTop: "none",
                                          borderRadius: "7px",
                                          borderTopRightRadius: "0",
                                          borderTopLeftRadius: "0",
                                          padding: "15px",
                                        }}
                                      >
                                        <Grid container spacing={1}>
                                          <Grid item xs={4}>
                                            <div
                                              className="quoteBodyDetailsRight"
                                              style={{
                                                backgroundImage: `url(${assignedAgent[0]?.profile_photo?.profile_photo})`,
                                              }}
                                            ></div>
                                          </Grid>
                                          <Grid item xs={8}>
                                            <Grid container spacing={1}>
                                              <Grid
                                                item
                                                xs={8}
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {" "}
                                                {
                                                  assignedAgent[0]?.first_name
                                                }{" "}
                                              </Grid>{" "}
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {" "}
                                              </Grid>
                                              <Grid
                                                item
                                                xs={8}
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {" "}
                                                {
                                                  assignedAgent[0]
                                                    ?.fulfilling_agent_id
                                                }{" "}
                                              </Grid>{" "}
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {" "}
                                              </Grid>
                                              <Grid
                                                item
                                                xs={8}
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {" "}
                                                Out for deliveries
                                              </Grid>{" "}
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                (
                                                {
                                                  assignedAgent[0]
                                                    ?.num_intransit_deliveries
                                                }
                                                ){" "}
                                              </Grid>
                                              <Grid
                                                item
                                                xs={8}
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {" "}
                                                Assign deliveries{" "}
                                              </Grid>{" "}
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                (
                                                {
                                                  assignedAgent[0]
                                                    ?.num_assgn_deliveries
                                                }
                                                ){" "}
                                              </Grid>
                                              <Grid
                                                item
                                                xs={8}
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {" "}
                                                Completed deliveries{" "}
                                              </Grid>{" "}
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                (
                                                {
                                                  assignedAgent[0]
                                                    ?.num_completed_deliveries
                                                }
                                                ){" "}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </div>
                                      {/* <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent:
                                                "space-between",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <Button
                                              size="small"
                                              variant="contained"
                                              style={{
                                                fontSize: "12px",
                                                border:
                                                  "1px solid var(--main)",
                                                backgroundColor:
                                                  "var(--main)",
                                                color: "white",
                                                textTransform: "none",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "5px",
                                                  alignItems:
                                                    "center",
                                                  justifyContent:
                                                    "center",
                                                }}
                                              >
                                                <span>
                                                  <RiPinDistanceFill size="12px" />
                                                </span>
                                                <span>
                                                  Track agent
                                                </span>
                                              </div>
                                            </Button>
                                          </div> */}
                                    </>
                                  ) : type === "assign" ? (
                                    <>
                                      <div className="topTitle">
                                        Assigned Agent
                                      </div>
                                      <div
                                        style={{
                                          width: "100%",
                                          border: "1px solid #E5E5E5",
                                          borderTop: "none",
                                          borderRadius: "7px",
                                          borderTopRightRadius: "0",
                                          borderTopLeftRadius: "0",
                                          padding: "15px",
                                        }}
                                      >
                                        <Grid container spacing={1}>
                                          <Grid item xs={4}>
                                            <div
                                              className="quoteBodyDetailsRight"
                                              style={{
                                                backgroundImage: `url(${assignedAgent[0]?.profile_photo?.profile_photo})`,
                                              }}
                                            ></div>
                                          </Grid>
                                          <Grid item xs={8}>
                                            <Grid container spacing={1}>
                                              <Grid
                                                item
                                                xs={8}
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {" "}
                                                {
                                                  assignedAgent[0]?.first_name
                                                }{" "}
                                              </Grid>{" "}
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {" "}
                                              </Grid>
                                              <Grid
                                                item
                                                xs={8}
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {" "}
                                                {
                                                  assignedAgent[0]
                                                    ?.fulfilling_agent_id
                                                }{" "}
                                              </Grid>{" "}
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {" "}
                                              </Grid>
                                              <Grid
                                                item
                                                xs={8}
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {" "}
                                                Out for deliveries
                                              </Grid>{" "}
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                (
                                                {
                                                  assignedAgent[0]
                                                    ?.num_intransit_deliveries
                                                }
                                                ){" "}
                                              </Grid>
                                              <Grid
                                                item
                                                xs={8}
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {" "}
                                                Assign deliveries{" "}
                                              </Grid>{" "}
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                (
                                                {
                                                  assignedAgent[0]
                                                    ?.num_assgn_deliveries
                                                }
                                                ){" "}
                                              </Grid>
                                              <Grid
                                                item
                                                xs={8}
                                                style={{
                                                  fontWeight: "500",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {" "}
                                                Completed deliveries{" "}
                                              </Grid>{" "}
                                              <Grid
                                                item
                                                xs={4}
                                                style={{
                                                  fontSize: "13px",
                                                }}
                                              >
                                                (
                                                {
                                                  assignedAgent[0]
                                                    ?.num_completed_deliveries
                                                }
                                                ){" "}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </div>
                                      {/* <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent:
                                                "space-between",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <Button
                                              size="small"
                                              variant="contained"
                                              style={{
                                                fontSize: "12px",
                                                border:
                                                  "1px solid var(--main)",
                                                backgroundColor:
                                                  "var(--main)",
                                                color: "white",
                                                textTransform: "none",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  gap: "5px",
                                                  alignItems:
                                                    "center",
                                                  justifyContent:
                                                    "center",
                                                }}
                                              >
                                                <span>
                                                  <RiPinDistanceFill size="12px" />
                                                </span>
                                                <span>
                                                  Track agent
                                                </span>
                                              </div>
                                            </Button>
                                          </div> */}
                                    </>
                                  ) : type === "complete" ? (
                                    // noShowAgent ?
                                    // null
                                    // :
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        marginTop: "10px",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <>
                                        <div className="topTitle">
                                          Delivery Agent
                                        </div>
                                        <div
                                          style={{
                                            width: "100%",
                                            border: "1px solid #E5E5E5",
                                            borderTop: "none",
                                            borderRadius: "7px",
                                            borderTopRightRadius: "0",
                                            borderTopLeftRadius: "0",
                                            padding: "15px",
                                          }}
                                        >
                                          <Grid container spacing={1}>
                                            <Grid item xs={4}>
                                              <div
                                                className="quoteBodyDetailsRight"
                                                style={{
                                                  backgroundImage: `url(${assignedAgent[0]?.profile_photo?.profile_photo})`,
                                                }}
                                              ></div>
                                            </Grid>
                                            <Grid item xs={8}>
                                              <Grid container spacing={1}>
                                                <Grid
                                                  item
                                                  xs={8}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  {
                                                    assignedAgent[0]?.first_name
                                                  }{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={4}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={8}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  {
                                                    assignedAgent[0]
                                                      ?.fulfilling_agent_id
                                                  }{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={4}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={8}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  Out for deliveries
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={4}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  (
                                                  {
                                                    assignedAgent[0]
                                                      ?.num_intransit_deliveries
                                                  }
                                                  ){" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={8}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  Assign deliveries{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={4}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  (
                                                  {
                                                    assignedAgent[0]
                                                      ?.num_assgn_deliveries
                                                  }
                                                  ){" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={8}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  Completed deliveries{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={4}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  (
                                                  {
                                                    assignedAgent[0]
                                                      ?.num_completed_deliveries
                                                  }
                                                  ){" "}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </div>
                                      </>
                                    </div>
                                  ) : type === "missed" ? (
                                    // noShowAgent ?
                                    //   null
                                    //   :
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        marginTop: "10px",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <>
                                        <div className="topTitle">
                                          Assigned Agent
                                        </div>
                                        <div
                                          style={{
                                            width: "100%",
                                            border: "1px solid #E5E5E5",
                                            borderTop: "none",
                                            borderRadius: "7px",
                                            borderTopRightRadius: "0",
                                            borderTopLeftRadius: "0",
                                            padding: "15px",
                                          }}
                                        >
                                          <Grid container spacing={1}>
                                            <Grid item xs={4}>
                                              <div
                                                className="quoteBodyDetailsRight"
                                                style={{
                                                  backgroundImage: `url(${assignedAgent[0]?.profile_photo?.profile_photo})`,
                                                }}
                                              ></div>
                                            </Grid>
                                            <Grid item xs={8}>
                                              <Grid container spacing={1}>
                                                <Grid
                                                  item
                                                  xs={8}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  {
                                                    assignedAgent[0]?.first_name
                                                  }{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={4}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={8}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  {
                                                    assignedAgent[0]
                                                      ?.fulfilling_agent_id
                                                  }{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={4}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={8}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  Out for deliveries
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={4}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  (
                                                  {
                                                    assignedAgent[0]
                                                      ?.num_intransit_deliveries
                                                  }
                                                  ){" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={8}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  Assign deliveries{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={4}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  (
                                                  {
                                                    assignedAgent[0]
                                                      ?.num_assgn_deliveries
                                                  }
                                                  ){" "}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={8}
                                                  style={{
                                                    fontWeight: "500",
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  {" "}
                                                  Completed deliveries{" "}
                                                </Grid>{" "}
                                                <Grid
                                                  item
                                                  xs={4}
                                                  style={{
                                                    fontSize: "13px",
                                                  }}
                                                >
                                                  (
                                                  {
                                                    assignedAgent[0]
                                                      ?.num_completed_deliveries
                                                  }
                                                  ){" "}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </div>
                                        {/* <div
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent:
                                                  "space-between",
                                                marginTop: "10px",
                                              }}
                                            >
                                              <Button
                                                size="small"
                                                variant="contained"
                                                style={{
                                                  fontSize: "12px",
                                                  border:
                                                    "1px solid var(--main)",
                                                  backgroundColor:
                                                    "var(--main)",
                                                  color: "white",
                                                  textTransform:
                                                    "none",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    gap: "5px",
                                                    alignItems:
                                                      "center",
                                                    justifyContent:
                                                      "center",
                                                  }}
                                                >
                                                  <span>
                                                    <RiPinDistanceFill size="12px" />
                                                  </span>
                                                  <span>
                                                    Track agent
                                                  </span>
                                                </div>
                                              </Button>
                                            </div> */}
                                      </>
                                    </div>
                                  ) : null
                                  // <div style={{display:'flex', width:'100%', justifyContent:'space-between', marginTop:'10px'}}>
                                  //     <Button
                                  //         size="small"
                                  //         variant="contained"
                                  //         style={{fontSize:'12px', border:'1px solid red', backgroundColor:'#e2a5a5', color:'red', textTransform:'none'}}
                                  //     >
                                  //         <div style={{display:'flex', gap:'5px', alignItems:'center', justifyContent:'center'}}>
                                  //             <span><MdBlock size='12px'/></span>
                                  //             <span>Reject</span>
                                  //         </div>
                                  //     </Button>
                                  //     <Button
                                  //         size="small"
                                  //         variant="contained"
                                  //         style={{fontSize:'12px', border:'1px solid var(--main)', backgroundColor:'var(--main)', color:'white', textTransform:'none'}}
                                  //         onClick={()=>{
                                  //             setShowMessage(true)
                                  //             setMessages([{sender_name : view.quote_detail[0].sender_name, conversation : view.quote_detail[0].message , delivery_id: view.delivery_id, fulfiller_id: view.quote_detail[0].fulfiller_id, quote_subject: view.item_desc, delivery_address: view.to, pickup_address: view.from, image_urls: view.image_urls} ]);
                                  //         }}
                                  //         >
                                  //         <div style={{display:'flex', gap:'5px', alignItems:'center', justifyContent:'center'}}>
                                  //             <span><IoMdMailOpen size='12px'/></span>
                                  //             <span>Message</span>
                                  //         </div>
                                  //     </Button>
                                  // </div>
                                }
                              </span>
                            </div>
                          </>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              );
            })
          )}
          <DialogPop
            openPopup={rejectPopup}
            setopenPopup={setrejectPopup}
            head="Reason for rejection"
          >
            <div className="quote_div">
              {
                <form
                  style={{ display: "flex", width: "100%" }}
                  onSubmit={handleSubmit(rejectQuote)}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <input
                        {...register("comment")}
                        type="text"
                        style={{
                          outline: "none",
                          padding: "5px 10px",
                          borderRadius: "7px",
                          width: "100%",
                          border: "1px solid #EAEAEA",
                          height: "30px",
                        }}
                      />
                      <div className="err">{errors.comment?.message}</div>
                    </Grid>
                  </Grid>
                  <Button
                    size="smaill"
                    variant="contained"
                    type="submit"
                    style={{
                      backgroundColor: "var(--main)",
                      width: "150px",
                      color: "white",
                      borderRadius: "10px",
                      textTransform: "none",
                      marginBottom: "20px",
                    }}
                  >
                    {rejecting ? (
                      <PuffLoader
                        color="white"
                        loading={rejecting}
                        speedMultiplier={1}
                        size={20}
                      />
                    ) : (
                      <span className="btnlabel"> Reject </span>
                    )}
                  </Button>
                </form>
              }
            </div>
          </DialogPop>
        </div>
      </div>

      <div className="userMobile">
        <HistoryDrillDownMobile />
      </div>
    </>
  );
}

export default HistoryDrillDown;