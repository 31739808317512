import React from 'react'
import './WhyDillivry.css'
import Growth from '../../assets/Growth.svg'
import Job from '../../assets/Job.svg'
import Earn from '../../assets/Earn.svg'
import { Grid } from '@material-ui/core'
import StepsCard from '../Home/HomeComponents/StepsCard'

export default function WhyDillivry() {
  return (
    <div className='WhyDillivry'>
        <div className="home_max_width">
            <h2 className='heroTitles'>Why Dillivry</h2>
            <div className="WhyDillivryCont">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4} >
                        <StepsCard img_size="70px" gap="15px" image={Earn} title="Earn money" text="Deliver and earn money."/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <StepsCard img_size="70px" gap="15px" image={Job} title="Get more jobs" text="Get more delivery jobs on Dillivry."/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <StepsCard img_size="70px" gap="15px" image={Growth} title="Grow your business" text="Your logistic business grows better."/>
                    </Grid>
                </Grid>
            </div>
        </div>
        
    </div>
  )
}
