import React,{useState, useEffect,useContext } from "react";
import image from "../../assets/new logo.001.png";
import { Link } from "react-router-dom";
import "../../components/Nav/nav.css";

import HomeHelp from '../HomeHelp';
import { useHistory } from 'react-router-dom';
import { Button, makeStyles } from "@material-ui/core";
import { AuthContext } from '../../context/AuthContext'
import SecureLS from 'secure-ls';
import DropdownComponent from "../DropdownComponent";
import Dropdowncountries from "../DropdownCountries";
import $ from 'jquery'
import { ConnContext } from "../../context/ConnContext"

import GlobalizationLanguage from "../GlobalizationLanguage";
export default function Nav({ clickHow, clickApi }) {
  const login = GlobalizationLanguage("/login")
  const Dashboard = GlobalizationLanguage("/Dashboard")
  var viewQuote = GlobalizationLanguage("/viewQuote")

  const auth = useContext(AuthContext);

  const logMeOut = () => {
    auth.logout();
    history.push('/')
    window.location.reload()
}

  const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

  const authContext = useContext(AuthContext)

  const [loggedIn, setLogggedIn] = useState(false);
  const [type, setType] = useState('')
  const [flexNavbar, setFlexNavbar]= useState(false)

  const addToHeader = () =>{
    if(process.env.REACT_APP_ENVIRONMENT === 'PROD'){    
      $('body').append(`
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10820955880"></script>
        <script>
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-10820955880');
        </script>
      `)
    }
  }

  useEffect(() => {

    addToHeader();
    if(ls.get('eid') === ""){
      setLogggedIn(false)
    }
    else{
      const acc_type = ls.get('_iRl');
        if(acc_type === 'individual'){
          setType('individual')
        }
        else if(acc_type === 'dillivry-sole-trader-ops'){
          setType('individual_provider')
          setLogggedIn(true)
        }
        else{setType('business')}
          setLogggedIn(true)
    }
}, [])

  const history = useHistory();

  const [showHelp, setshowHelp] = useState(false);

const changeBackground = () =>{
    if (window.scrollY >= 70 ){
      setFlexNavbar(true)
    }else{
      setFlexNavbar(false);
    }

  };
  window.addEventListener('scroll',changeBackground);

  const useStyles = makeStyles( theme => ({
    root: {
        width: "100%",
    },
    btn: {
        // width: widthh,
        padding:'7px 10px',
        borderRadius:'5px',
        background: 'var(--main)',
        color:  'white',
        '&:hover': {
           background: 'white',
           color:  'var(--main)',
           border: `1px solid var(--main)`,
        },
      },
      btn1: {
        // width: widthh,
        padding:'7px 10px',
        borderRadius:'5px',
        background: 'white',
        color:  'var(--main)',
        border: '1px solid var(--main)',
        '&:hover': {
           background: 'var(--main)',
           color:  'white',
           border: `1px solid white`,
        },
      },
}));

const classes = useStyles();

  return (
  <div style={{width:'100%',  position:'fixed', backgroundColor:'white'}}>
      <div className="max-screenTop" >
        <div className= {flexNavbar ? "flexnavbar ctive":"flexnavbar"}>
          
          <div className="logocontainer-web">
            <img src={image} alt="dillivry" onClick={()=>history.push('/')} />
          </div>

          <div className="linkscontainer">
            <ul>
                <Link><li onClick={() => setshowHelp(!showHelp)}>Help</li></Link>
                <Link ><li onClick={clickHow}>How it works</li></Link>
                <Link ><li onClick={clickApi}>API</li></Link>
                <Link to={GlobalizationLanguage(`/open-deliveries`)}><li>Open Deliveries</li></Link>
                <Link to={GlobalizationLanguage("/sender-signup")}><li><div className={classes.btn1} style={{textTransform:'none', fontSize:'14px'}}>Senders registration</div></li></Link>
                {/* <Link to="/open-deliveries"><li>Open Deliveries</li></Link>
                <Link to="/request-a-delivery"><li><div className={classes.btn1} style={{textTransform:'none', fontSize:'14px'}}>Request a delivery</div></li></Link> */}

                <Link><li><DropdownComponent  text="Provider sign up" color="white" bgColor="var(--main)" Size="small" style={{textTransform:'none', fontSize:'14px'}}/></li></Link>
                {
                  loggedIn ?
                  <>
                  {
                    type !== 'individual_provider' ?
                      <Link to={ type === 'individual' ?  viewQuote : authContext.isAuth ? type ==='individual_provider' ?  GlobalizationLanguage(`/open-deliveries`) : Dashboard : login}>
                          <li><div className={classes.btn}>My Dillivry</div></li>
                      </Link>
                      :
                      <>
                        <Link onClick={logMeOut} >
                            <li className="myDillivryBtn"><Button variant="contained" style={{textTransform:'none', fontSize:'14px'}}>Logout</Button></li>
                        </Link>
                      </>
                  } 
                  </>
                  :
                  <Link to={GlobalizationLanguage("/login")}>
                    <li>
                      <div 
                      className={classes.btn}>
                        Login
                      </div>
                    </li>
                  </Link>
                }
                
                <Dropdowncountries/>
                  
            </ul>
          </div>
          {
          showHelp ?
            <HomeHelp showDialog={showHelp} onClose={() => setshowHelp(false)} />
            :
            null
          }   
        </div> 
    </div>
  </div>
  );
}
