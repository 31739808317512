import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useHistory } from "react-router-dom";
import SecureLS from "secure-ls";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import wallet3 from "../../../assets/wallet3.svg";
import setting2 from "../../../assets/setting2.svg";
import walletminus from "../../../assets/walletminus.svg";
import emptywalletadd from "../../../assets/emptywalletadd.svg";
import DialogPop from "../../../components/DialogPop";
import LoginCorporate from "../../Login/LoginCorporate";
import Layout from "../../Sender/SenderDashboard/Layout";
import { SenderContext } from "../../../context/SenderDashboard/SenderDashboardContext";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Paystack from "../../../assets/Paystack.svg";
import Bankhome from "../../../assets/Bankhome.svg";
import { RiArrowDownSLine } from "react-icons/ri";

import {
  FormControl,
  Grid,
} from "@material-ui/core";
import { FaTextHeight } from "react-icons/fa";
import InputField from "../../../components/InputField";
import * as yup from "yup";
import Paystack_sender_fund_wallet from "../../../components/Paystack_sender_fund_wallet";
import SenderBottomNav from "../../Sender/components/SenderBottomNav";
import SenderSideBar from "../../Sender/components/SenderSideBar";
import DashBottomBtn from "../../Fulfiller/Dashboard/DashBottomBtn/DashBottomBtn";
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import FundWalletBankTransfer from "../../../components/MakePayment/FundWalletBankTransfer";

const WalletSender = () => {
  const [allbanks, setallbanks] = useState([]);

  const [accnum, setaccnum] = useState("");
  const [accname, setaccname] = useState("");
  const [loading, setloading] = useState(false);
  const [businessUpdate, setBusinessUpdate] = useState(false);
  const [otpRequired, setOtpRequired] = useState(false);

  const sendersettings = GlobalizationLanguage("/sendersettings")
  var RefPage = GlobalizationLanguage("/referrals_sender")

  const numberWithCommas = new Intl.NumberFormat("en-GB")
  const [showTranferFromWallet, setShowTranferFromWallet] = useState(false)
  const [payment_method, setpayment_method] = useState('pay_by_transfer')

  const links = [

    // {
    //   path: team,
    //   name: "Team",
    //   state: 'inactive'
    // },

    // {
    //   path: senderapi,
    //   name: "API",
    //   state: 'inactive'
    // },
    // {
    //   path: sendersettlement,
    //   name: "Settlement",
    //   state: 'inactive'
    // },
    {
      path: RefPage,
      name: "Referral",
      icon: "devices",
      state: 'inactive'
    },
    {
      path: sendersettings,
      name: "Settings",
      icon: "settings",
      state: 'inactive'
    },
  ]

  const [showpayment_method, setshowPayment_method] = useState(false);



  const [ShowLogin, setShowLogin] = useState(false)

  const handleChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setaddamt(e.target.value)
    }
  }

  const handleChangewithdrawamt = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setwithdrawamt(e.target.value)
    }
  }



  const [bankname, setbankname] = useState("");
  const [sortcode, setsortcode] = useState("")

  const schema = yup.object().shape({
    acc_number: yup
      .number()
      .typeError("You must specify a number")
      .min(0, "Min value 0."),
    acc_name: yup
      .string()
      .required("Account name is required")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  });

  const {
    control,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const [showwithdraw, setshowwithdraw] = useState(false);
  const [showsettings, setshowsettings] = useState(false);
  const [showform, setshowform] = useState(false)
  const [updateacct, setUpdateacct] = useState(false)
  const [showaddfund, setshowaddfund] = useState(false)
  const [proceedwithdraw, setproceedwithdraw] = useState(false);

  const [loadingData, setloadingData] = useState(false);
  const [withdrawamt, setwithdrawamt] = useState("");
  const [addamt, setaddamt] = useState('');
  const [isloading, setisloading] = useState(false);
  const [creditstate, setcreditstate] = useState(false);
  const [allstatet, setallstatet] = useState("all");

  const [walletHistory, setwalletHistory] = useState([]);
  const [walletBalc, setwalletBalc] = useState(0);
  const [refreshBalance, setrefreshBalance] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [checkfocus, setcheckfocus] = useState(false);
  const {
    UpdateAlldata,
    alldata,
    UpdateCartData,
    UpdateData,
  } = useContext(SenderContext);
  const [accountInfo, setAccountInfo] = useState();

  const endpoint = process.env.REACT_APP_API;


  const withdrawamount = (e) => {
    e.preventDefault();
    setproceedwithdraw(true);
    setshowwithdraw(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setshowwithdraw(false);
  };

  const buttonstyle = () => {
    // console.log("btn-clicked");
    if (withdrawamt === "") {
      setcheckfocus(!checkfocus);
    }
  };

  const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });

  const Withdrawalfunction = (e) => {
    e.preventDefault();
    setisloading(true);
    authAxios
      .post(`m-dillivry_instant-payment`, {
        transaction_type: "debit",
        amount: withdrawamt,
      })
      .then((res) => {
        setrefreshBalance(!refreshBalance)
        setisloading(false);
        setproceedwithdraw(false);
        Swal.fire({
          icon: "success",
          text: `Your withdrawal of ${withdrawamt} was successful`,
          showConfirmButton: true,
          confirmButtonColor: "#50B14A",
          confirmButtonText: "Continue",
          timer: 2000
        })
      })
      .catch((err) => {
        console.log(err);
        setisloading(false);
        setproceedwithdraw(false);
        Swal.fire({
          icon: "error",
          text: `${err?.response?.data?.message
            ? err?.response?.data?.message
            : "Withdrawal failed. Try again"
            }`,
          confirmButtonColor: "#50B14A",
          confirmButtonText: "Continue",
        });
        setisloading(false);
      });
  };

  const options = allbanks?.map((str, index) => ({ value: str, label: str }));

useEffect(() => {
  authAxios
  .get(`m-dillivry-sender-api?attribute=bank_list`)

  .then((res) => {
    setallbanks(res?.data?.data?.map(x => x)?.map((x) => x?.ng ?? x?.gb)?.flat()[0]?.data)
  })
  .catch((err) => {
    console.log(err);
    console.log(err.data);
  });
}, [])


  useEffect(() => {
    setrefreshingBalance(true)
    setloading(true);
   

    authAxios
      .get(`m-dillivry-sender-api?attribute=wallet_history`)
      .then((res) => {
        //  console.log(res, "response");
        const CheckLength = res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat()
        setwalletHistory(CheckLength)
      })
      .catch((err) => {
        console.log(err);
        console.log(err.data);
      });

    axios
        .get(`${endpoint}m-dillivry-sender-api`, {
          headers: {
            Authorization: ls.get("_utk"),
          },
        })
        .then((response) => {
          UpdateAlldata(response)
          if (response.status === 200) {
            UpdateData(response?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());
  
            UpdateCartData(
              response?.data?.data
                ?.map((x) => x?.ng ?? x?.gb)
                ?.map((x) => x?.account)
                ?.flat()
                ?.filter((x) => x.account_info)
                ?.map((x) => x?.account_info)[1]
            );
  
            const CheckLength = response?.data?.data
              ?.map((x) => x?.ng ?? x?.gb)
              ?.map((x) => x?.account)
              ?.flat()
              ?.filter((x) => x.account_info)
              ?.map((x) => x?.account_info).length;
  
            CheckLength === 1
              ? setAccountInfo(
                response?.data?.data
                  ?.map((x) => x?.ng ?? x?.gb)
                  ?.map((x) => x?.account)
                  ?.flat()
                  ?.filter((x) => x.account_info)
                  ?.map((x) => x?.account_info)[0]
              )
              : setAccountInfo(
                response?.data?.data
                  ?.map((x) => x?.ng ?? x?.gb)
                  ?.map((x) => x?.account)
                  ?.flat()
                  ?.filter((x) => x.account_info)
                  ?.map((x) => x?.account_info)[1]
              );
  
            CheckLength === 1
              ? setwalletBalc(
                response?.data?.data
                  ?.map((x) => x?.ng ?? x?.gb)
                  ?.map((x) => x?.account)
                  ?.flat()
                  ?.filter((x) => x.account_info)
                  ?.map((x) => x?.account_info)[0]?.wallet_balance
              )
              : setwalletBalc(
                response?.data?.data
                  ?.map((x) => x?.ng ?? x?.gb)
                  ?.map((x) => x?.account)
                  ?.flat()
                  ?.filter((x) => x.account_info)
                  ?.map((x) => x?.account_info)[1]?.wallet_balance
              );
  
          }
          if (response.status === 202) {
            setOtpRequired(true);
          }
        })
        .catch((err) => {
          setloading(false);
          if (err.response?.status === 401) {
            setBusinessUpdate(true);

          }
        })
        .finally(()=>{
          setrefreshingBalance(false)
          setloading(false);
        })
    
  }, [refreshBalance]);

  const [refreshingBalance, setrefreshingBalance] = useState(false)

  const AddBank = (e) => {
    e.preventDefault();
    setisloading(true)
    let dataToSend = {}
    if (accountInfo?.vcc?.toLowerCase() === "gb") {
      dataToSend = {
        bank_name: bankname,
        acct_name: accname,
        acct_number: accnum,
        sort_code: sortcode
      }
    }
    else {
      dataToSend = {
        acct_name: accname,
        acct_number: accnum,
        bank_name: bankname,
      }
    }

    authAxios
      .post(`m-dillivry-sender-api?attribute=bank_detail&registered_countries=${accountInfo?.vcc?.toLowerCase()}`,
        dataToSend
      )
      .then((res) => {
         Swal.fire({
          icon: "success",
          text: `Bank details added`,
          showConfirmButton: true,
          confirmButtonColor: "#50B14A",
          confirmButtonText: "Continue",
          timer: 2000
        }).then((result) => {
          setrefreshBalance(!refreshBalance)
          setshowsettings(false)
        })
        setisloading(false)
      })
      .catch((err) => {
        console.log(err);
        setisloading(false)
      });
  };

  return (
    <div>
      <div className="dsktopscreen">
        <Layout>
          {loading ? (
            <div
              style={{
                width: "100%",
                height: "100vh",
                backgroundColor: "none",
                zIndex: "300",
                position: "fixed",
                top: "70px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <LinearProgress color="success" />
              </Box>
            </div>
          ) : (
            <>
              {accountInfo?.bank_detail ? (
                <div>
                  {ShowLogin ? (
                    <LoginCorporate />
                  ) : (
                    <div className="dashboard">
                      {loadingData ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100vh",
                            backgroundColor: "none",
                            zIndex: "300",
                            position: "fixed",
                            top: "0",
                            paddingTop: "80px",
                          }}
                        >
                          <Box sx={{ width: "100%" }}>
                            <LinearProgress color="success" />
                          </Box>
                        </div>
                      ) : null}

                      <div className="wallet_cont">
                        <div className="wallet_home">
                          <div className="wallet_ballance">
                            <div className="wallet_balance_and_amount">
                              <div>
                                {" "}
                                <p className="waleet_title">Wallet balance</p>
                              </div>
                              <div>
                                {refreshingBalance ? (
                                  <ClipLoader
                                    color="white"
                                    loading={refreshingBalance}
                                    speedMultiplier={1}
                                    size={32}
                                  />
                                ) : walletBalc === 0 ||
                                  walletBalc === undefined ||
                                  walletBalc === NaN ? (
                                  <h1 className="wallet_amt">&#8358;0.00</h1>
                                ) : (
                                  <h1 className="wallet_amt">
                                    {/* {
                                 console.log(walletBalc)
                               } */}
                                    &#8358;
                                    {numberWithCommas.format(
                                      parseFloat(walletBalc)?.toFixed(2)
                                    )}
                                  </h1>
                                )}
                              </div>
                            </div>

                            <div className="wallet_icon">
                              <img
                                src={wallet3}
                                alt="walleticon"
                                className="wallet_icn_img"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="fwsettings">
                          <div className="fund-withdraw-settings">
                            <div
                              onClick={() => {
                                setshowaddfund(true);
                              }}
                              className="addfund"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <img src={emptywalletadd} alt="" />
                              </div>
                              <div>
                                {" "}
                                <span>Add fund</span>
                              </div>
                            </div>

                            <div
                              className="withdraw"
                              onClick={() => {
                                setshowwithdraw(true);
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                              >
                                {" "}
                                <img src={walletminus} alt="" />
                              </div>
                              <div>
                                {" "}
                                <span>Withdraw</span>
                              </div>
                            </div>

                            <div
                              className="settings"
                              onClick={() => setshowsettings(true)}
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  cursor: "pointer",
                                }}
                              >
                                {" "}
                                <img src={setting2} alt="" />
                              </div>
                              <div>
                                {" "}
                                <span>Settings</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="wallet-history">
                          <div>
                            {" "}
                            <h2>Wallet History</h2>
                          </div>
                        </div>

                        <div className="allcreditdebit">
                          <div className="alldiv">
                            <div
                              style={{
                                backgroundColor:
                                  allstatet === "all" ? "#E0FCE7" : "white",
                              }}
                              className="all"
                              onClick={() => setallstatet("all")}
                            >
                              <span>All</span>
                            </div>

                            <div
                              style={{
                                backgroundColor:
                                  allstatet === "credit" ? "#E0FCE7" : "white",
                              }}
                              className="credits"
                              onClick={() => {
                                // setcreditstate(true);
                                setallstatet("credit");
                              }}
                            >
                              <span>Credits</span>
                            </div>

                            <div
                              style={{
                                backgroundColor:
                                  allstatet === "debit" ? "#E0FCE7" : "white",
                              }}
                              onClick={() => setallstatet("debit")}
                              className="debits"
                            >
                              <span>Debits</span>
                            </div>
                          </div>
                        </div>

                        {allstatet === "all" ? (
                          <div>
                            <div
                              className={`${allstatet === "all"
                                ? "transaction-history"
                                : "transaction-history"
                                }`}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                             
                              {walletHistory.sort((x, y) => new Date(y.timestamp) - new Date(x.timestamp))
                                ?.slice(0,10)
                                ?.map((x, i) => {
                                return (
                                  <div key={i} className="transdetails">
                                    <div className="transtitleanddate">
                                      {x.transaction_type === "wallet_credit" || x.transaction_type === "credit" ? (
                                        <h1 className="transtitle">
                                        Payment made.
                                      </h1>
                                      ) : (
                                        <h1 className="transtitle">
                                        Withdrawal made
                                      </h1>
                                      )}
                                      <span className="transdate">
                                        {moment(
                                          x.timestamp.slice(0, 10)
                                        ).format("DD-MM-YY")}{" "}
                                        &nbsp; {x.timestamp.slice(12, 19)}
                                      </span>
                                    </div>

                                    <div className="transamount transamtdebit  ">
                                      <h1
                                        className={`${x.transaction_type === "wallet_credit" || x.transaction_type === "credit"
                                          ? "transamt credit   "
                                          : "transamtno  debit"
                                          }`}
                                      >
                                        &#8358;
                                        {`${x.transaction_type === "debit"
                                          ? numberWithCommas.format(x.debit_amount)
                                          : numberWithCommas.format(x.credit_amount)
                                          }`}
                                      </h1>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : allstatet === "credit" ? (
                          <div>
                            {walletHistory?.filter((x)=>(x.transaction_type === "credit" || x.transaction_type === "wallet_credit"))
                               ?.sort((x, y) => new Date(y.timestamp) - new Date(x.timestamp)
                               ).slice(0, 10)
                                ?.map((x, i) => {
                              return (
                                <div key={i} className="transaction-history-  credittrans  ">
                                  <div className="transdetails  credittransinner  ">
                                    <div>
                                        <h2 className="user_id">
                                          {" "}
                                          Payment made.
                                        </h2>

                                        <span className="transdate">
                                          {moment(
                                            x.timestamp.slice(0, 10)
                                          ).format("DD-MM-YY")}{" "}
                                          &nbsp; {x.timestamp.slice(12, 19)}
                                        </span>
                                    </div>
{/* {console.log(x)} */}
                                    <div>
                                        <span className="transamt">
                                          &#8358;{numberWithCommas.format(x.credit_amount)}
                                        </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div>
                            {walletHistory?.filter((x)=>x.transaction_type === "debit")
                               ?.sort((x, y) => new Date(y.timestamp) - new Date(x.timestamp)
                               ).slice(0, 10)?.map((x, i) => {
                              return (
                                <div key={i} className="transaction-history- debittrans">
                                  <div className="transdetails debittransinner  ">
                                    <div>
                                      {x.transaction_type === "debit" &&
                                        x.debit_amount ? (
                                        <h2 className="user_id">
                                          {" "}
                                          Withdrawal made
                                        </h2>
                                      ) : null}

                                      {x.transaction_type === "debit" &&
                                        x.debit_amount ? (
                                        <span className="transdate">
                                          {moment(
                                            x.timestamp.slice(0, 10)
                                          ).format("DD-MM-YY")}{" "}
                                          &nbsp; {x.timestamp.slice(12, 19)}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div>
                                      {x.transaction_type === "debit" &&
                                        x.debit_amount ? (
                                        <span className="transamtno">
                                          &#8358;{numberWithCommas.format(x.debit_amount)}
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        
                      </div>
                    </div>
                  )}



                  <DashBottomBtn activeTab="wallet" />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <form
                    onSubmit={AddBank}
                    action=""
                    style={{
                      width: "100%",
                      maxWidth: "450px"
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <h4 style={{ paddingTop: "20px", margin: 0, color: "var(--main)" }}>
                          Add deposit bank
                        </h4>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Controller
                          name="acc_number"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                            >
                              <InputField
                                label="Account number"
                                {...field}
                                id="acc_number"
                                type="number"
                                labelWidth={170}
                                required
                                value={accnum}
                                onChange={(e) => {
                                  setaccnum(e.target.value);
                                }}
                                icon={
                                  <FaTextHeight
                                    style={{
                                      marginRight: "5px",
                                      color: "var(--main)",
                                    }}
                                  />
                                }
                              />
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Controller
                          name="acc_name"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                            >
                              <InputField
                                label="Account name"
                                {...field}
                                //  error={errors.acc_number}
                                id="acc_name"
                                type="text"
                                labelWidth={170}
                                value={accname}
                                required
                                onChange={(e) => {
                                  setaccname(e?.target?.value);
                                }}
                                icon={
                                  <FaTextHeight
                                    style={{
                                      marginRight: "5px",
                                      color: "var(--main)",
                                    }}
                                  />
                                }
                              />
                            </FormControl>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <div style={{ width: "100%" }}>
                          <Autocomplete
                            options={options}
                            size="small"
                            renderInput={(params) => (
                              <TextField {...params} label="Select Bank" size="small" />
                            )}
                            onChange={(e, val) => {
                              if (val !== null) {
                                setbankname(val.value);
                              } else {
                                setbankname("");
                              }
                            }}
                            value={bankname}
                          />
                        </div>
                      </Grid>

                      {accountInfo?.vcc?.toLowerCase() === "gb" && (
                        <Grid item xs={12} sm={12}>
                          <Controller
                            name="sort_code"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                              >
                                <InputField
                                  label="Sort Code"
                                  {...field}
                                  //   error={errors.acc_number}
                                  id="sort_code"
                                  type="number"
                                  value={sortcode}
                                  onChange={(e) => {
                                    setsortcode(e.target.value);
                                  }}
                                  labelWidth={170}
                                  icon={
                                    <FaTextHeight
                                      style={{
                                        marginRight: "5px",
                                        color: "var(--main)",
                                      }}
                                    />
                                  }
                                />
                              </FormControl>
                            )}
                          />
                        </Grid>
                      )}

                      <Button
                        size="medium"
                        variant="contained"
                        type="submit"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "var(--main)",
                          width: "100%",
                          margin: "20px 0px",
                          color: "white",
                          borderRadius: "10px",
                          textTransform: "none",
                        }}
                      >
                        {isloading ? "loading..." : "Add bank"}
                      </Button>

                      <Grid></Grid>
                    </Grid>
                  </form>
                </div>
              )}
            </>
          )}
        </Layout>
      </div>

      <div className="mobilescreen">
        <SenderSideBar pageTitle="Wallet" links={links} />

        {loading ? (
          <div
            style={{
              width: "100%",
              height: "100vh",
              backgroundColor: "none",
              zIndex: "300",
              position: "fixed",
              top: "70px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <LinearProgress color="success" />
            </Box>
          </div>
        ) : (
          <>
            {accountInfo?.bank_detail ? (
              <div>
                {ShowLogin ? (
                  <LoginCorporate />
                ) : (
                  <div className="dashboard">
                    {loadingData ? (
                      <div
                        style={{
                          width: "100%",
                          height: "100vh",
                          backgroundColor: "none",
                          zIndex: "300",
                          position: "fixed",
                          top: "0",
                          paddingTop: "80px",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <LinearProgress color="success" />
                        </Box>
                      </div>
                    ) : null}
                    <div className="wallet_cont">
                      <div className="wallet_home">
                        <div className="wallet_ballance">
                          <div className="wallet_balance_and_amount">
                            <div>
                              {" "}
                              <p className="waleet_title">Wallet balance</p>
                            </div>
                            <div>
                              {refreshingBalance ? (
                                <ClipLoader
                                  color="white"
                                  loading={refreshingBalance}
                                  speedMultiplier={1}
                                  size={32}
                                />
                              ) : walletBalc === 0 ||
                                walletBalc === undefined ||
                                walletBalc === NaN ? (
                                <h1 className="wallet_amt">&#8358;0.00</h1>
                              ) : (
                                <h1 className="wallet_amt">
                                  {/* {
                                 console.log(walletBalc)
                               } */}
                                  &#8358;
                                  {numberWithCommas.format(
                                    parseFloat(walletBalc)?.toFixed(2)
                                  )}
                                </h1>
                              )}
                            </div>
                          </div>

                          <div className="wallet_icon">
                            <img
                              src={wallet3}
                              alt="walleticon"
                              className="wallet_icn_img"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="fwsettings">
                        <div className="fund-withdraw-settings">
                          <div
                            onClick={() => {
                              setshowaddfund(true);
                            }}
                            className="addfund"
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <img src={emptywalletadd} alt="" />
                            </div>
                            <div>
                              {" "}
                              <span>Add fund</span>
                            </div>
                          </div>

                          <div
                            className="withdraw"
                            onClick={() => {
                              setshowwithdraw(true);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              <img src={walletminus} alt="" />
                            </div>
                            <div>
                              {" "}
                              <span>Withdraw</span>
                            </div>
                          </div>

                          <div
                            className="settings"
                            onClick={() => setshowsettings(true)}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {" "}
                              <img src={setting2} alt="" />
                            </div>
                            <div>
                              {" "}
                              <span>Settings</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="wallet-history">
                        <div>
                          {" "}
                          <h2>Wallet History</h2>
                        </div>
                      </div>

                      <div className="allcreditdebit">
                        <div className="alldiv">
                          <div
                            style={{
                              backgroundColor:
                                allstatet === "all" ? "#E0FCE7" : "white",
                            }}
                            className="all"
                            onClick={() => setallstatet("all")}
                          >
                            <span>All</span>
                          </div>

                          <div
                            style={{
                              backgroundColor:
                                allstatet === "credit" ? "#E0FCE7" : "white",
                            }}
                            className="credits"
                            onClick={() => {
                              setcreditstate(true);
                              setallstatet("credit");
                            }}
                          >
                            <span>Credits</span>
                          </div>

                          <div
                            style={{
                              backgroundColor:
                                allstatet === "debit" ? "#E0FCE7" : "white",
                            }}
                            onClick={() => setallstatet("debit")}
                            className="debits"
                          >
                            <span>Debits</span>
                          </div>
                        </div>
                      </div>

                      {allstatet === "all" ? (
                        <div>
                          <div
                            className={`${allstatet === "all"
                              ? "transaction-history"
                              : "transaction-history"
                              }`}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {walletHistory?.map((x, i) => {
                              return (
                                <div key={i} className="transdetails">
                                  <div className="transtitleanddate">
                                    {x.transaction_type === "debit" ? (
                                      <h1 className="transtitle">
                                        Withdrawal made
                                      </h1>
                                    ) : (
                                      <h1 className="transtitle">
                                        Payment made.
                                      </h1>
                                    )}
                                    <span className="transdate">
                                      {moment(x.timestamp.slice(0, 10)).format(
                                        "DD-MM-YY"
                                      )}{" "}
                                      &nbsp; {x.timestamp.slice(12, 19)}
                                    </span>
                                  </div>

                                  <div className="transamount transamtdebit  ">
                                    <h1
                                      className={`${x.transaction_type === "credit"
                                        ? "transamt credit   "
                                        : "transamtno  debit"
                                        }`}
                                    >
                                      &#8358;
                                      {`${x.transaction_type === "debit"
                                        ? numberWithCommas.format(x.debit_amount)
                                        : numberWithCommas.format(x.credit_amount)
                                        }`}
                                    </h1>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : allstatet === "credit" ? (
                        <div>
                          {walletHistory?.map((x, i) => {
                            return (
                              <div className="transaction-history-  credittrans  ">
                                <div className="transdetails  credittransinner  ">
                                  <div>
                                    {x.transaction_type === "credit" ? (
                                      <h2 className="user_id">
                                        {" "}
                                        Payment made.
                                      </h2>
                                    ) : null}

                                    {x.transaction_type === "credit" ? (
                                      <span className="transdate">
                                        {moment(
                                          x.timestamp.slice(0, 10)
                                        ).format("DD-MM-YY")}{" "}
                                        &nbsp; {x.timestamp.slice(12, 19)}
                                      </span>
                                    ) : null}
                                  </div>

                                  <div>
                                    {x.transaction_type === "credit" ? (
                                      <span className="transamt">
                                        &#8358;{numberWithCommas.format(x.credit_amount)}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div>
                          {walletHistory?.map((x, i) => {
                            return (
                              <div className="transaction-history- debittrans">
                                <div className="transdetails debittransinner  ">
                                  <div>
                                    {x.transaction_type === "debit" &&
                                      x.debit_amount ? (
                                      <h2 className="user_id">
                                        {" "}
                                        Withdrawal made
                                      </h2>
                                    ) : null}

                                    {x.transaction_type === "debit" &&
                                      x.debit_amount ? (
                                      <span className="transdate">
                                        {moment(
                                          x.timestamp.slice(0, 10)
                                        ).format("DD-MM-YY")}{" "}
                                        &nbsp; {x.timestamp.slice(12, 19)}
                                      </span>
                                    ) : null}
                                  </div>
                                  <div>
                                    {x.transaction_type === "debit" &&
                                      x.debit_amount ? (
                                      <span className="transamtno">
                                        &#8358;{numberWithCommas.format(x.debit_amount)}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                )}


              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  maxWidth: "450px",
                  padding: "20px"
                }}
              >
                <form
                  onSubmit={AddBank}
                  action=""
                  style={{
                    width: "100%",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <h4 style={{ paddingTop: "20px", margin: 0, color: "var(--main)" }}>Add deposit bank</h4>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="acc_number"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            {/* <InputLabel htmlFor="acc_number">
                                {" "}
                                <FaEdit
                                  style={{
                                    marginRight: "5px",
                                    color: "var(--main)",
                                  }}
                                />{" "}
                                Account number{" "}
                              </InputLabel> */}
                            <InputField
                              label="Account number"
                              {...field}
                              //  error={errors.acc_number}
                              id="acc_number"
                              type="number"
                              labelWidth={170}
                              required
                              value={accnum}
                              onChange={(e) => {
                                setaccnum(e.target.value);
                              }}
                              icon={
                                <FaTextHeight
                                  style={{
                                    marginRight: "5px",
                                    color: "var(--main)",
                                  }}
                                />
                              }
                            />

                            {/* <div className="err">{errors.acc_number?.message}</div> */}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="acc_name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >
                            {/* <InputLabel htmlFor="acc_name">
                                {" "}
                                <FaEdit
                                  style={{
                                    marginRight: "5px",
                                    color: "var(--main)",
                                  }}
                                />{" "}
                                Account name{" "}
                              </InputLabel> */}
                            <InputField
                              label="Account name"
                              {...field}
                              //  error={errors.acc_number}
                              id="acc_name"
                              type="text"
                              labelWidth={170}
                              value={accname}
                              required
                              onChange={(e) => {
                                setaccname(e?.target?.value);
                              }}
                              icon={
                                <FaTextHeight
                                  style={{
                                    marginRight: "5px",
                                    color: "var(--main)",
                                  }}
                                />
                              }
                            />

                            {/* <div className="err">{errors.acc_name?.message}</div>  */}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <div style={{ width: "100%" }}>
                        <Autocomplete
                          options={options}
                          size="small"

                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Bank"
                            />
                          )}
                          onChange={(e, val) => {
                            if (val !== null) {
                              setbankname(val.value);
                            } else {
                              setbankname("");
                            }
                          }}
                          // onChange={(e, val) => console.log("val", val)}
                          value={bankname}
                        />
                      </div>
                    </Grid>

                    {accountInfo?.vcc?.toLowerCase() === "gb" && (
                      <Grid item xs={12} sm={12}>
                        <Controller
                          name="sort_code"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                            >
                              {/* <InputLabel htmlFor="sort_code">
                                  {" "}
                                  <FaEdit
                                    style={{
                                      marginRight: "5px",
                                      color: "var(--main)",
                                    }}
                                  />{" "}
                                  Sort Code{" "}
                                </InputLabel> */}
                              <InputField
                                label="Sort Code"
                                {...field}
                                //   error={errors.acc_number}
                                id="sort_code"
                                type="number"
                                value={sortcode}
                                onChange={(e) => {
                                  setsortcode(e.target.value);
                                }}
                                labelWidth={170}
                                icon={
                                  <FaTextHeight
                                    style={{
                                      marginRight: "5px",
                                      color: "var(--main)",
                                    }}
                                  />
                                }
                              />
                            </FormControl>
                          )}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                      <Button
                        size="medium"
                        variant="contained"
                        type="submit"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "var(--main)",
                          width: "100%",
                          color: "white",
                          borderRadius: "10px",
                          textTransform: "none",
                        }}
                      >
                        {isloading ? "loading..." : "Add bank"}
                      </Button>
                    </Grid>

                    <Grid>

                    </Grid>
                  </Grid>
                </form>
              </div>
            )}
          </>
        )}
        <SenderBottomNav state="wallet" />

        <DialogPop
          openPopup={proceedwithdraw}
          setopenPopup={setproceedwithdraw}
          centerhead="Withdrawal"
        >
          <div>
            <h1 className="withdrawinner">
              Kindly confirm you are about to withdraw the sum of  &#8358;{withdrawamt} from your wallet
            </h1>

            <div className="yesandnobtn">
              <button
                onClick={() => setproceedwithdraw(false)}
                className="nobtn"
              >
                No
              </button>

              <button onClick={Withdrawalfunction} className="yesbtn">
                {isloading ? (
                  <ClipLoader
                    color="white"
                    loading={isloading}
                    speedMultiplier={1}
                    size={32}
                  />
                ) : (
                  "Yes"
                )}
              </button>
            </div>
          </div>
        </DialogPop>

        <DialogPop
          openPopup={showaddfund}
          setopenPopup={setshowaddfund}
          head="Add fund"
        >
          <div className="withdrawform">
            <form>
              <div className="form-div">

                <input className='withdrawinput' autoFocus style={{ outline: "none" }} value={addamt} type="text" placeholder='Enter amount to add' onChange={(e) => handleChange(e)} />
                <div
                  style={{
                    color: "red",
                    marginTop: "0px",
                    marginBottom: "10px",
                    fontSize: "13px",
                    textDecoration: "italic",
                  }}
                >
                  {parseInt(addamt) < 100 ? (
                    <>You can only add from &#8358;100 and above</>
                  ) : null}
                </div>

                <Grid container spacing={4}>
                <Grid item xs={12}>
                  <select className='select_mode' value={payment_method} onChange={(e) => setpayment_method(e.target.value)} id="cars">
                    <option value="pay_by_transfer">Pay by transfer</option>
                    <option value="pay_by_card">Pay with paystack</option>
                    {/* <option value="pay_by_ussd">USSD</option> */}
                  </select>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "4px",
                    }}
                  >
                    {
                      payment_method === "pay_by_transfer" ?
                        addamt === '' ||  parseInt(addamt) < 100 ? 
                        <div className="paystack_btn_disable" >
                          Proceed
                        </div>
                        :
                        <div className="paystack_btn"
                          onClick={() => { setShowTranferFromWallet(true) }}
                        >
                          Proceed
                        </div>

                        :
                        <Paystack_sender_fund_wallet
                          sender
                          amount={addamt + "00"} //for paystack
                          amount_paid={addamt}
                          email={ls.get("eid")}
                          closePopup={() =>{
                            setshowPayment_method(false);
                            setshowaddfund(false)
                          }}
                          refresh={()=>setrefreshBalance(!refreshBalance)}
                        />
                    }
                  </div>
                </Grid>
                  
                </Grid>


              </div>
            </form>
          </div>
        </DialogPop>

        <DialogPop
          openPopup={showsettings}
          setopenPopup={setshowsettings}
          head="Bank details"
        >
          <div className="bank_icon">
            <div className="bankdetails">
              <p className="bank_acct">
                {accountInfo?.bank_detail?.account_number}
              </p>
              <p className="bank_acct">
                {accountInfo?.bank_detail?.account_name}
              </p>
              <p className="bank_acct">
                {accountInfo?.bank_detail?.bank_name}
              </p>
            </div>

            <div
              className="changebank"
              onClick={() => {
                setshowform(!showform);
              }}
            >
              <div className="bank_img_text_icon">
                {
                  <>
                    <div className="homeandtext">
                      <div className="imghome">
                        <img src={Bankhome} alt="bankhome" />
                      </div>

                      <div className="texthome">
                        <p className="texttag">
                          Change your bank details which your funds
                          will be sent to when you withdraw
                        </p>
                      </div>
                    </div>

                    <div className="icontext">
                      <RiArrowDownSLine />
                    </div>
                  </>
                }
                {updateacct ? <p>Update acct</p> : null}
              </div>
            </div>
          </div>
          {showform ? (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "0 20px",

                }}
              >
                <form
                  onSubmit={AddBank}
                  action=""
                  style={{
                    width: "100%",
                    maxWidth: "450px"
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <h4 style={{ paddingTop: "20px", margin: 0, color: "var(--main)" }}>
                        Add deposit bank
                      </h4>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="acc_number"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >

                            <InputField
                              label="Account number"
                              {...field}
                              //  error={errors.acc_number}
                              id="acc_number"
                              type="number"
                              labelWidth={170}
                              required
                              value={accnum}
                              onChange={(e) => {
                                setaccnum(e.target.value);
                              }}
                              icon={
                                <FaTextHeight
                                  style={{
                                    marginRight: "5px",
                                    color: "var(--main)",
                                  }}
                                />
                              }
                            />

                            {/* <div className="err">{errors.acc_number?.message}</div> */}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="acc_name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                          >

                            <InputField
                              label="Account name"
                              {...field}
                              //  error={errors.acc_number}
                              id="acc_name"
                              type="text"
                              labelWidth={170}
                              value={accname}
                              required
                              onChange={(e) => {
                                setaccname(e?.target?.value);
                              }}
                              icon={
                                <FaTextHeight
                                  style={{
                                    marginRight: "5px",
                                    color: "var(--main)",
                                  }}
                                />
                              }
                            />

                            {/* <div className="err">{errors.acc_name?.message}</div>  */}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <div style={{ width: "100%" }}>
                        <Autocomplete
                          size="small"
                          options={options}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Bank"
                            />
                          )}
                          onChange={(e, val) => {
                            if (val !== null) {
                              setbankname(val.value);
                            } else {
                              setbankname("");
                            }
                          }}
                          value={bankname}
                        />
                      </div>
                    </Grid>

                    {accountInfo?.vcc?.toLowerCase() === "gb" && (
                      <Grid item xs={12} sm={12}>
                        <Controller
                          name="sort_code"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                            >

                              <InputField
                                label="Sort Code"
                                {...field}
                                //   error={errors.acc_number}
                                id="sort_code"
                                type="number"
                                value={sortcode}
                                onChange={(e) => {
                                  setsortcode(e.target.value);
                                }}
                                labelWidth={170}
                                icon={
                                  <FaTextHeight
                                    style={{
                                      marginRight: "5px",
                                      color: "var(--main)",
                                    }}
                                  />
                                }
                              />

                              {/* <div className="err">{errors.acc_number?.message}</div> */}
                            </FormControl>
                          )}
                        />
                      </Grid>
                    )}

                    <Button
                      size="medium"
                      variant="contained"
                      type="submit"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "var(--main)",
                        width: "100%",
                        margin: "20px 0px",
                        color: "white",
                        borderRadius: "10px",
                        textTransform: "none",
                      }}

                    //   onClick={handleSubmit(AddBank)}
                    >
                      {isloading ? "loading..." : "Add bank"}
                    </Button>

                    <Grid></Grid>
                  </Grid>
                </form>
              </div>
            </div>
          ) : null}
        </DialogPop>

        <DialogPop
          openPopup={showwithdraw}
          setopenPopup={setshowwithdraw}
          head="Withdrawal"
        >
          <div>
            <div className="withdrwalbody">
              <div>
                <h1 className="withdrawtag">
                  Withdrawable: &nbsp; &nbsp; &nbsp;{" "}
                </h1>
              </div>

              <div>
                <span className="withdawspan">
                  &#8358;
                  {walletBalc === 0 ||
                    walletBalc === undefined ||
                    walletBalc === isNaN
                    ? "0.00"
                    : numberWithCommas.format(parseFloat(walletBalc))}
                </span>
              </div>
            </div>

            <div className="withdrawform">
              <form onSubmit={withdrawamount}>
                <div className="form-div">

                  <input className='withdrawinput' autoFocus style={{ outline: "none" }} value={withdrawamt} type="text" placeholder='Enter amount to add' onChange={(e) => handleChangewithdrawamt(e)} />

                  <div
                    style={{
                      color: "red",
                      marginTop: "5px",
                      fontSize: "13px",
                      textDecoration: "italic",
                    }}
                  >
                    {parseInt(withdrawamt) < 100 ? (
                      <>
                        You can only withdraw from &#8358;100 and
                        above
                      </>
                    ) : parseFloat(withdrawamt) >
                      parseFloat(walletBalc) ? (
                      <>
                        Amount cannot be more than your withdrawable
                      </>
                    ) : null}
                  </div>
                  <div className="cancelandproceed">
                    <div onClick={handleCancel} className="cancel">
                      Cancel
                    </div>

                    {withdrawamt === "" ? (
                      <button className="proceed" disabled>
                        Proceed
                      </button>
                    ) : parseInt(withdrawamt) < 100 ? (
                      <button className="proceed" disabled>
                        Proceed
                      </button>
                    ) : parseFloat(withdrawamt) >
                      parseFloat(walletBalc) ||
                      walletBalc === undefined ||
                      walletBalc === isNaN ? (
                      <button className="proceed" disabled>
                        Proceed
                      </button>
                    ) : (
                      <button className="proceedplus" type="submit">
                        <span>Proceed</span>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </DialogPop>
        <DialogPop
          openPopup={showTranferFromWallet}
          setopenPopup={setShowTranferFromWallet}
          head="Fund your wallet"
        >
          <div className="popContainer_payment">
            <FundWalletBankTransfer 
              amount={addamt} 
              onSuccess={()=>{
                setrefreshBalance(!refreshBalance); 
                setShowTranferFromWallet(false);
                setshowaddfund(false)
              }}
              onError={()=>{
                setShowTranferFromWallet(false)
                setshowaddfund(false)
              }}
              />
          </div>
      </DialogPop>

      </div>
    </div>
  );
};

export default WalletSender;
