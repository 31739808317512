import moment from "moment";
import React from "react";
import "./Tracking2.css";

export default function Pickedup({data}) {
  return (
    <div className="tracking-body">

      <div className="status-body">
        <div className="section-one">
          <div className="left-side-status">
            <div className="Status-h1">
              <h1 className="staus-main">Status</h1>
            </div>
            <div className="Listed-h1">
              <h1 className="Listed-main">Listed</h1>
            </div>
            <div className="Date-listed-p">
              <p className="Date-listed-main">
                {/* Your item was listed on Tue 9th Nov at 12:30pm */}
                Your item was listed on { moment( data.date_listed?.slice(0, 10)).format('DD-MM-YY')} at {data.date_listed?.slice(12, 19)}
              </p>
            </div>
          </div>

          <div className="rightfirst">
          <div className="right-side-status">
            <div className="to-be-styled-seperately"  >

            <div className="Tobe-delivered">
              <p className="tobe-delivered">To be delivered to:</p>
            </div>
            <div className="Contact">
              <h1 className="contact-text">Contact: {data.delivery_phone_no}</h1>
              <h1 className="Address-Main">
                {data.delivery_address}
              </h1>
            </div>
            </div>
            
          </div>
          </div>
        </div>


            <div className="sectionB-">

        <div className="section-two">
          <div className="section-two-follow-my-item">
            <h1 className="followmyitem">Follow my item</h1>
            <p className="findout">
              Find out where your item has been on its journey to the
              destination
            </p>

                <div className="container">

                    <ul className="progressBar">
                        <li className="active-progress"  >Listed</li>
                        <li>Quote Accepted</li>
                        <li>Awaiting Pickup</li>
                        <li>Picked up</li>
                        <li>Out for delivery</li>
                        <li>Delivered</li>

                    </ul>


                </div>

          </div>

          <div className="img-display">
           <div className="inner-img-div">
           <img src={data.image_urls[0]} alt="Itemdescription" />
           <p>{data.item_desc}</p>
           </div>
          </div>
        </div>





            </div>


      </div>
    </div>
  );
}
