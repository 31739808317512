import React from 'react'
import './appdownload.css'
import Appstore from '../../assets/IOS_App.svg'
import Playstore from '../../assets/Google_App.svg'
import TopBarHome from '../../components/TopBar/TopBarHome'
import ProviderHero from '../Home/HomeComponents/ProviderHero'
import WhyDillivry from './WhyDillivry'
import Footer from '../../components/Footer/Footer'
import ReactPlayer from 'react-player/youtube'
import phonelogo from "../../assets/phone.png"
export default function Appdownload() {
  return (
    <div>
      <TopBarHome/>
      <div className='AppDownloadHero'>
          <div className="container">
              <div className="row py-5">
                  <div className="col-sm-6 d-flex flex-column justify-content-around">
                      <h1>Get the app!</h1>
                      <div>
                      <h6>You need to download and complete your registration on the app.</h6>
                      <h6>Play the video to discover how Dillivry work best for you as a rider or driver.</h6>
                      </div>
                      <div className='playerConte'>
                        <ReactPlayer 
                          url='https://youtu.be/D1kmfF4PSVE'
                          width='100%'
                          height='100%' 
                          className='react-player'
                        />
                      </div>

                      <div className='appDownloadImg'>
                        <a href="https://apps.apple.com/us/app/dillivry/id1604161862"><img src={Appstore} alt="Dillivry Appstore"/></a>
                        <a href="https://play.google.com/store/apps/details?id=datamellon.app_mobile"><img src={Playstore} alt="Dillivry Playstore" /></a>
                      </div>
                  </div>
                  <div className="col-sm-6 p-5">
                      <img src={phonelogo} style={{maxWidth:"100%"}} alt="Dillivry" />
                  </div>
              </div>
          </div>
      </div>
      <WhyDillivry/>
      <ProviderHero bg="#F7F7F7" title="How Dillivry works"/>
      <Footer/>
    </div>
  )
}
