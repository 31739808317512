import React from 'react'
import "./How_it_works.css"
import ArrowRight from '../../assets/ArrowRight.svg'

export default function How_it_works_card1({title,description, image, showImage}) {
  return (
    <div className='work_cards'>
      <div className="work_card">
        <div className="work_card_other_side">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
        <div className="work_card_side1">
            <img src={image} alt="Request delivery" />
        </div>
      </div>
      {
        showImage ?
          <div className="arrow_image">
              <img src={ArrowRight} alt="dillivry" />
          </div>
          :
          <></>
      }
  </div>
  )
}
