/* eslint-disable no-unused-vars */
import React, { createContext, useState, useReducer, useEffect } from "react";
// import reducer from "./SenderReducer";
// import getType from './SenderTypes';
//import { INITIAL_STATE } from "../Dashboard/DashboardDrillDownProvider/current.reducer";

 const  INITIAL_STATE  = []

export const SenderContext = createContext();



export default function SenderDashboardContext(props) {

  var today = new Date();
  var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

  const [initialstate, setinitialstate] = useState()
  const [initialstatemain, setinitialstatemain] = useState()
  const [msgdrildown, setmsgdrildown] = useState()
  const [alldata, setalldata] = useState()
  const [Cartdata, setCartdata] = useState()
  const [accinfogeneral, setaccinfogeneral] = useState()
  const [PromoInfo, setPromoInfo] = useState()
  const [GBdata, setGBdata] = useState()
  const [NGdata, setNGdata] = useState()
  const [statusmemory, setStatusmemory] = useState("all")
  const [receivedQuotedata, setreceivedQuoteData] = useState([])
  const [quote, setquote] = useState([])

  
  const UpdateAcctInfoGeneral = (data)=>{
    setaccinfogeneral(data)
  }


    const UpdateData = (dash) =>{
      setinitialstate(dash)
    }

    const UpdateDatamain = (dash) =>{
      setinitialstatemain(dash)
    }

    const UpdateMssgDrill = (dash)=>{
      setmsgdrildown(dash)
    }

    const UpdateCartData = (data) =>{
      setCartdata(data)
    }

    const UpdatePromoInfo = (data) =>{
      setPromoInfo(data)
    }

    const UpdateAlldata = (data) =>{
      setalldata(data)
    }

    const GBStatistics = (data) =>{
      setGBdata(data)
    }

    const NGStatistics = (data) =>{
      setNGdata(data)
    }


    const UpdateStatusMemory = (status)=>{
      setStatusmemory(status)
    }




    const AddToCart = (delivery_id, fulfiller, amount) =>{
      const item_to_add_cart = initialstate.data.awaiting_quote.filter((item)=>(item.delivery_id === delivery_id));
      const item_left = initialstate.data.awaiting_quote.filter((item)=>(item.delivery_id !== delivery_id));
      initialstate.data.awaiting_quote = item_left;

      item_to_add_cart[0].delivery_status = "awaiting_agent_assignment";

      initialstate.data.awaiting_agent_assignment.unshift(item_to_add_cart[0]);


      const newCart ={
        "date_listed": item_to_add_cart[0]?.date_listed,
        "delivery_address":  item_to_add_cart[0]?.to,
        "delivery_id": item_to_add_cart[0]?.delivery_id,
        "distance": item_to_add_cart[0]?.distance,
        "duration": item_to_add_cart[0]?.duration,
        "duration_in_traffic": item_to_add_cart[0].duration_in_traffic,
        "image_urls": [`${item_to_add_cart[0].image_urls[0]}`],
        "item_category": item_to_add_cart[0].item_category,
        "item_desc": item_to_add_cart[0].item_desc,
        "pickup_address": item_to_add_cart[0].from,
        "provider_name": fulfiller,
        "quote_amount": amount,
        "time_stamp": date
      }

      initialstate.data.account.account_info.cart_history.deliveries.unshift(newCart)

    }

    const UpdateSenderMsg  = (new_message) =>{
      const deliveryData = msgdrildown.filter(
        (item) => item.delivery_id === new_message.id
      );

      const deliveryDataNot = msgdrildown.filter(
        (item) => item.delivery_id !== new_message.id
      );

      deliveryData[0].fulfiller_conversation = new_message.new_message

      const updated = [...deliveryData, ...deliveryDataNot]
    

      msgdrildown = updated
       
    }
    const createSenderMsg=(message) =>{
      // console.log (message,"msggg")
    
      let deliveryData = [];
      let quoteData = [];
      let quoteDataNot = [];
      let newData =[];
      deliveryData = msgdrildown.filter(
        (item) => item.delivery_id === message.id
      );
      
      
     
      quoteData = deliveryData[0]?.quote_detail.filter(
        (item) => item?.fulfiller_id === message.fulfillerID);

      quoteDataNot = deliveryData[0]?.quote_detail.filter(
          (item) => item?.fulfiller_id !== message.fulfillerID);
          // console.log(quoteData[0].message,"ww")
         
          newData=quoteData[0].message
          const addData = newData.unshift( message.new_message)
          quoteData[0].message =newData
      

      const updated = [...quoteData, ...quoteDataNot]
      // console.log([...quoteData, ...quoteDataNot],"be nice")
      initialstate.data.awaiting_quote.quote_detail = updated


    }

    const updatereceivedQuotedata = (data) =>{
      setreceivedQuoteData(data)
    }

    const Updatequote = (initialstate) =>{
      setquote( initialstate?.data?.awaiting_quote?.filter(function (item) {
        return item?.total_quote_received > 0;
      }))
    }
    return <SenderContext.Provider value={{initialstate, AddToCart, UpdateData, UpdateDatamain, setinitialstatemain, initialstatemain, UpdateAlldata, alldata, setalldata, UpdateMssgDrill,  UpdateCartData, Cartdata, setCartdata,   UpdateStatusMemory, statusmemory, GBdata,  NGdata,   createSenderMsg, UpdateSenderMsg, msgdrildown, setmsgdrildown, updatereceivedQuotedata, receivedQuotedata, Updatequote, quote, UpdatePromoInfo, UpdateAlldata,  PromoInfo, setPromoInfo, GBStatistics, NGStatistics, UpdateAcctInfoGeneral, accinfogeneral, setaccinfogeneral }}   >{props.children}</SenderContext.Provider>;
}
