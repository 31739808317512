import React, { useContext, useState, useEffect } from 'react'
import dillivrylogo from '../../../Sender/SenderDashboard/Assets/dillivrylogo.svg'
import "./MobileCorpLayout.css"
import { useHistory, useLocation } from 'react-router-dom'
import DialogOutClick from '../../../../components/DialogOutClick';
import { AuthContext } from '../../../../context/AuthContext';
import userAvatar from "../../../../assets/userAvatar.jpeg";
import carticon from '../../../../assets/carticon.svg'
import { DashboardContext } from "../../../../context/Dashboard/DashboardProvider/dashboard.provider";
import axios from "axios";
import SecureLS from "secure-ls";
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import dash1 from '../../../../assets/dash1.svg'
import dash2 from '../../../../assets/dash2.svg'
import mydill1 from '../../../../assets/mydill1.svg'
import mydill2 from '../../../../assets/mydill2.svg'
import watch from '../../../../assets/Watch.svg'
import Watch_active from '../../../../assets/Watch_active.svg'
import open1 from '../../../../assets/open1.svg'
import create1 from '../../../../assets/create1.svg'
import create2 from '../../../../assets/create2.svg'
import help1 from '../../../../assets/help1.svg'
import messag1 from '../../../../assets/messag1.svg'
import messag2 from '../../../../assets/messag2.svg'
import acct1 from '../../../../assets/acct1.svg'
import settings2 from '../../../../assets/setting2.svg'
import setting1 from '../../../../assets/setting1.svg'
import tran1 from '../../../../assets/tran1.svg'
import tran2 from '../../../../assets/tran2.svg'
import sett1 from '../../../../assets/sett1.svg'
import sett2 from '../../../../assets/sett2.svg'
import user1 from '../../../../assets/user1.svg'
import user2 from '../../../../assets/user2.svg'
import feed1 from '../../../../assets/feed1.svg'
import wallet1 from '../../../../assets/wallet1.svg'
import wallet2 from '../../../../assets/wallet2.svg'
import referralclicked from '../../../../assets/referralclicked.svg'
import referarl from '../../../../assets/referarl.svg'
import { HiArrowNarrowLeft, HiMenuAlt2 } from "react-icons/hi";
import { BsArrowLeft } from "react-icons/bs";
import Swal from 'sweetalert2';
import { ConnContext } from "../../../../context/ConnContext"
import { PuffLoader } from 'react-spinners';
import delete1 from '../../../../assets/delete1.svg'
import GlobalizationLanguage, { GlobalizationLanguagewithCOntryCode } from '../../../../components/GlobalizationLanguage';
import Cookies from 'js-cookie';
import Dropdowncountries from '../../../../components/DropdownCountries';
import clsx from 'clsx';
import { Avatar, Drawer } from '@mui/material';
import {  makeStyles } from '@mui/styles';
import { IoMdMenu } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';

export default function MobileCorpLayout({ pageTitle, state }) {
  const useStyles = makeStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
  });
  const classes = useStyles();
  const [drawerstate, setdrawerState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setdrawerState({ ...drawerstate, [anchor]: open });
  };

  const auth = useContext(AuthContext);
  const [ShowLogout, setShowLogout] = useState(false)

  const { dashboards, updateDashboard } = useContext(DashboardContext);
  const [accountInfo, setAccountInfo] = useState()
  const [loading, setloading] = useState(false)
  const endpoint = process.env.REACT_APP_API;
  const [showname, setshowname] = useState(false)
  const location = useLocation();
  const { visitingCountryCode } = useContext(ConnContext)
  const [showDeteleAcct, setShowDeteleAcct] = useState(false);
  const [deleteInput, setdeleteInput] = useState('');

  const ls = new SecureLS({ isCompression: true, encodingType: "rabbit", encryptionSecret: "dm_dillivry", });

  const logMeOut = () => {
    auth.logout();
    history.push("/");
  };

  const history = useHistory();

  const QuoteData = [
    // {
    //   path: "/Dashboard",
    //   name: "Dashboard",
    //   icon: dash1,
    //   iconclicked: dash2,
    // },
    // {
    //   path: "/MyDillivry",
    //   name: "My dillivry",
    //   icon: mydill1,
    //   iconclicked: mydill2
    // },
    {

      path: "/open-deliveries",
      name: "Open deliveries",
      icon: open1,
      iconclicked: open1
    },
    {
      path: "/CreateDillivry",
      name: "Create delivery",
      icon: create1,
      iconclicked: create2
    },

    // {
    //   path: "/corpwallet",
    //   name: "Wallet",
    //   icon: wallet1,
    //   iconclicked: wallet2

    // },
    // {
    //   path: "/Messages",
    //   name: "Messages",
    //   icon: messag1,
    //   iconclicked: messag2
    // },
    {
      path: GlobalizationLanguage("/watch-list"),
      name: "Watch list",
      icon: watch,
      iconclicked: Watch_active
    },
    // {
    //   path: "/corpreferrals",
    //   name: "Referrals",
    //   icon: referarl,
    //   iconclicked: referralclicked

    // },
    // {
    //   path: "/account",
    //   name: "Account",
    //   icon: acct1,

    //   iconclicked: acct1
    // },
    {
      path: "/How-it-works",
      name: "How it works",
      icon: setting1,
      iconclicked: settings2
    },
    {
      path: "/Settings",
      name: "Settings",
      icon: setting1,
      iconclicked: settings2
    },
    {
      path: "/contact-us",
      name: "Help",
      icon: help1,
      iconclicked: help1
    },
  ]

  const accountData = [
    {
      path: "/account",
      name: "Transaction",
      icon: tran1,
      iconclicked: tran2,
      click: false,

    },
    {
      // path: "/activedilivry/awaitingquote",
      path: "/Settlements",
      name: "Settlements",
      icon: sett1,
      iconclicked: sett2,
      click: false,
    },
    {

      path: "/UserManager",
      name: "User Manager",
      icon: user1,
      iconclicked: user2,
      click: false,
    },
    {
      path: "/6994622167893963",
      name: "Feedback",
      icon: feed1,
      iconclicked: feed1,
      click: false,
    },

    //   {
    //     path: "",
    //     name: "Delete  account",
    //     icon: delete1,
    //     iconclicked: delete1,
    //     click: true,

    //   },


  ]

  const [registered_country, setregistered_country] = useState([])



  useEffect(() => {
    if (dashboards !== undefined) {
  setregistered_country(dashboards?.data.account_profile?.account_profile[0]?.registered_countries)
      
      setAccountInfo(dashboards?.data?.account_profile?.account_profile[0])
    }
    // else {
    //   setloading(true);
    //   axios
    //     .get(`${endpoint}m-dillivry-dashboard-manager`, {
    //       headers: {
    //         Authorization: ls.get("_nodi"),
    //       },
    //     })
    //     .then((response) => {
    //       setAccountInfo(response?.data?.account_profile?.account_profile[0])
    //     })
    //     .catch((err) => {
    //       setloading(false);
    //     })
    // }
  }, [dashboards])


  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

  useEffect(() => {
    setCountry(Cookies.get('i18next'));
  }, [Cookies.get('i18next')])

  const [country, setCountry] = useState(countryvalue);


  const disableAccount = () => {
    axios.delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
      headers: {
        Authorization: ls.get('_nodi')
      },
      data: {
        "action_type": "disable_account",
        "vcc": registered_country[0]
      }
    })
      .then((res) => {
        setShowDeteleAcct(false)
        Swal.fire({
          title: 'Account disabled',
          text: 'contact the dillivry team to enable you account back',
          allowOutsideClick: false,
          confirmButtonColor: 'var(--main)',
        })
          .then((result) => {
            if (result.value) {
              auth.logout();
              history.push('/')
            }
          });
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          Swal.fire({
            icon: 'warning',
            title: 'Session!',
            text: 'Session expired login again!',
            allowOutsideClick: false,
            confirmButtonColor: 'var(--main)'
          })
            .then((result) => {
              if (result.value) {
                auth.logout();
                history.push('/login')
              }
            });
        }
        else {
          Swal.fire({
            icon: 'info',
            text: 'An error occur try again',
            timer: 3000,
            showConfirmButton: false,
          })
        }
      })
  }

  const deleteMyAccount = () => {
    setloading(true);
    axios.delete(`${endpoint}m-dillivry-user-manager-delete_ops`, {
      headers: {
        Authorization: ls.get('_nodi')
      },
      data: {
        "action_type": "delete_account",
        "vcc": registered_country[0]
      }
    })
      .then((res) => {
        setShowDeteleAcct(false)

        Swal.fire({
          title: 'Account deleted',
          text: 'We are very sorry to see you go',
          allowOutsideClick: false,
          confirmButtonColor: 'var(--main)',
        })
          .then((result) => {
            if (result.value) {
              auth.logout();
              history.push('/')
            }
          });
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          Swal.fire({
            icon: 'warning',
            title: 'Session!',
            text: 'Session expired login again!',
            allowOutsideClick: false,
            confirmButtonColor: 'var(--main)'
          })
            .then((result) => {
              if (result.value) {
                auth.logout();
                history.push('/login')
              }
            });
        }
        else {
          Swal.fire({
            icon: 'info',
            text: 'An error occur try again',
            timer: 3000,
            showConfirmButton: false,
          })
        }
      })
  }

  
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
<div className="navigation">
          {
            state === "message" ?
              <HiArrowNarrowLeft fontSize={25} style={{ cursor: "pointer", color: "gray" }} onClick={() => GlobalizationLanguage(history.push("/Messages"))} />
              :
              <>
                {/* <input type="checkbox" className="toggle-menu" style={{ zIndex: "25" }} /> */}
                <div className="hamburger" style={{ zIndex: "25" }}></div>
              </>
          }

          {
            state === "account" ?
              <div className="side_menu" style={{ overflow: "scroll", padding: "20px", zIndex: "20" }} >
                <div style={{ padding: "0px" }}>
                  <IoClose size= '35px' color="var(--main)"  onClick={toggleDrawer(anchor, false)}/>
                </div>
                <p
                  className="backaccount"
                  style={{ margin:"10px 0", cursor: "pointer" }}
                  onClick={() => history.push(GlobalizationLanguagewithCOntryCode(`/Dashboard`, country, visitingCountryCode))}> <span style={{ marginRight: "10px" }}
                  >
                    <BsArrowLeft fontSize={15} /> </span><span style={{ fontSize: "18px" }}>Back to dashboard</span>
                </p>
                
                <Avatar alt="dp" src={accountInfo?.cop_logo !== null ? 
                  accountInfo?.cop_logo : 
                  accountInfo?.profile_photo !== "" ?
                  accountInfo?.profile_photo : userAvatar
                } />

                <div className="nav-business-name">
                  <div >
                    <p className="nav-title" style={{ marginTop: "20px" }}>

                      {accountInfo?.account_name ? (
                        <div >
                          {
                            accountInfo?.role === "dillivry-corp-sys-admin" ? (
                              <div style={{ display: "flex", cursor: "pointer" }} >
                                <p className="nav-title">
                                  {accountInfo?.account_name?.charAt(0).toUpperCase() +
                                    accountInfo?.account_name?.slice(1)}
                                </p>
                              </div>
                            )
                              : (
                                <></>
                              )}
                        </div>

                      ) : (
                        <></>
                      )}

                    </p>

                  </div>
                  <span className="nav-title">
                    {
                      accountInfo?.account_holder ?
                        <>

                          <span className="nav-title">
                            {
                              accountInfo.account_holder.first_name.charAt(0).toUpperCase() +
                              accountInfo.account_holder.first_name.slice(1) + " " + accountInfo?.account_holder?.last_name}
                          </span>
                        </>
                        :
                        null
                    }

                  </span>


                  {
                    accountData.map((x, i) => {
                      return (
                        <div style={{ marginTop: '0px', cursor: 'pointer', userSelect: "none" }} key={i}>
                          <div
                            onClick={() => history.push(GlobalizationLanguagewithCOntryCode(`${x.path}`, country, visitingCountryCode))}
                            className="image-contentx">
                            {
                              location.pathname === x.path ? <img src={x.iconclicked} alt="iconimage" width='25px' className="side-img" /> : <img src={x.icon} width='25px' alt="iconimage" className="side-img" />
                            }
                            <p className={location.pathname === x.path ? 'active4 ' : 'inactive3'}>{x.name}</p>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                <div style={{ marginTop: '0px', cursor: 'pointer', userSelect: "none" }} >
                  <div
                    onClick={() => setShowDeteleAcct(true)}
                    className="image-contentx"
                  >
                    {
                      showDeteleAcct ? <img src={delete1} alt="iconimage" width='25px' className="side-img" /> : <img src={delete1} width='25px' alt="iconimage" className="side-img" />
                    }
                    <p >Delete account</p>
                  </div>
                </div>
              </div>
              :
              <div className="side_menu" style={{ overflow: "scroll", width: "250px", zIndex: "20" }}>
                <div style={{ padding: "10px 20px 0px 20px" }}>
                  <IoClose size= '35px' color="var(--main)"  onClick={toggleDrawer(anchor, false)}/>
                </div>
                <div className="logo-description" style={{ padding: "10px 20px 0px 20px" }}>
                  <div className="nav-business-details" style={{ padding: "0px" }}>
                    <Avatar alt="dp" src={accountInfo?.cop_logo !== null ? 
                        accountInfo?.cop_logo : 
                        accountInfo?.profile_photo !== "" ?
                        accountInfo?.profile_photo : userAvatar
                      } />
                    <div className="nav-business-name">
                      {accountInfo?.account_name ? (
                        <div >
                          {
                            accountInfo?.role === "dillivry-corp-sys-admin" ? (
                              <div style={{ display: "flex", cursor: "pointer" }} onClick={() => setshowname(!showname)}>
                                <p className="nav-title">
                                  {accountInfo?.account_name?.charAt(0).toUpperCase() +
                                    accountInfo?.account_name?.slice(1)}
                                </p>

                                <span style={{ marginLeft: "5px" }}>
                                  {
                                    showname ?
                                      <MdOutlineKeyboardArrowUp fontSize={20} />
                                      :
                                      <MdOutlineKeyboardArrowDown fontSize={20} />

                                  }
                                </span>
                              </div>
                            )
                              : (
                                <></>
                              )}
                        </div>

                      ) : (
                        <></>
                      )}
                      {
                        accountInfo?.account_holder ?
                          <>
                            {
                              showname ?
                                <span className="nav-title">
                                  {
                                    accountInfo?.account_holder?.first_name.charAt(0).toUpperCase() +
                                    accountInfo?.account_holder?.first_name.slice(1) + " " + accountInfo?.account_holder?.last_name}
                                </span>
                                :
                                null
                            }
                          </>
                          :
                          null
                      }

                      <span
                        style={{ userSelect: "none", color: "red", cursor: "pointer" }}
                        onClick={() => setShowLogout(true)}
                      >
                        {" "}
                        Logout
                      </span>
                    </div>
                  </div>
                </div>

                <div className="selected-menu " style={{ marginTop: "0px" }}>
                  <div style={{ display: "flex", flexDirection: "column", padding: "10px" }}  >

                    <>
                      {
                        QuoteData.map((x, i) => {
                          return (
                            <div style={{ marginTop: '0px', cursor: 'pointer', userSelect: "none" }} key={i}>
                              <div
                                onClick={() => history.push(GlobalizationLanguagewithCOntryCode(`${x.path}`, country, visitingCountryCode))}
                                className="image-contentx"
                                style={{ padding: "10px 10px 0px 10px" }}
                              >
                                {
                                  location.pathname === x.path ? <img src={x.iconclicked} alt="iconimage" width='25px' className="side-img" /> : <img src={x.icon} width='25px' alt="iconimage" className="side-img" />
                                }
                                <p className={location.pathname === x.path ? 'active4 ' : 'inactive3'}>{x.name}</p>
                              </div>
                            </div>
                          )
                        })
                      }
                    </>
                  </div>
                </div>
                <div className="bottom-logo" style={{ bottom: "0px" }} onClick={() => history.push(GlobalizationLanguagewithCOntryCode('/', country, visitingCountryCode))}>
                  <img src={dillivrylogo} alt="dillivrylogo" className="bottom-logo-img" />
                </div>
              </div>
          }
        </div>
    </div>
  )

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
      <header className="header" >
          <HiMenuAlt2 size= '35px' color="var(--main)"  onClick={toggleDrawer(anchor, true)}>
            {anchor}
          </HiMenuAlt2>
        <div className="titl">
          <p className="tltletag">{pageTitle}</p>
        </div>

        <div className="search-cart">
          <div className="cart-icon" >
            <img src={carticon} alt="shooping" className="carticon" />
          </div>
          <Dropdowncountries/>
        </div>
      </header>
        <Drawer anchor={anchor} open={drawerstate[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
      </React.Fragment>
      ))}

      <DialogOutClick
        open={ShowLogout}
        handleClose={() => setShowLogout(false)}
      >
        <div style={{ width: "100%", padding: "30px 30px 20px 30px" }}>
          <div
            style={{
              width: "100%",
              marginBottom: "30px",
              fontSize: "13px",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>Are you sure you want to logout?</span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <div
              className="disBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => setShowLogout(false)}
            >
              No
            </div>
            <div
              className="ediBtn"
              style={{
                width: "50px",
                fontSize: "13px",
                fontWeight: "bold",
                cursor: "pointer",
                color: "white",
                backgroundColor: "#808080",
                border: "1px solid #808080",
              }}
              onClick={logMeOut}
            >
              Yes
            </div>
          </div>
        </div>
      </DialogOutClick>
      <DialogOutClick
        open={showDeteleAcct}
        handleClose={() => setShowDeteleAcct(false)}
      >
        <div style={{ width: '100%', padding: "30px 30px 20px 30px" }}>
          <div style={{ width: '100%', marginBottom: "30px", fontSize: '14px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span>Are you sure you want to delete this account? Type in <b>permanently delete</b> in the input below to confirm deletion</span>
          </div>
          <div style={{ marginBottom: '30px' }}>
            <input
              type="text"
              placeholder="permanently delete"
              value={deleteInput}
              onChange={(e) => setdeleteInput(e.target.value)}
              style={{ border: '1px solid var(--main)', padding: '7px 10px', outline: 'none', borderRadius: '5px', fontSize: '13px' }}
            />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px' }}>
            <div className="disBtn" style={{ fontSize: '13px', fontWeight: 'bold', cursor: 'pointer', border: '#0080FF solid 1px', backgroundColor: '#0080FF', color: 'white' }}
              onClick={disableAccount} >
              Disable instead
            </div>
            <div className="disBtn" style={{ width: '50px', fontSize: '13px', fontWeight: 'bold', cursor: 'pointer' }}
              onClick={() => setShowDeteleAcct(false)} >
              Cancel
            </div>
            {
              deleteInput === "permanently delete" ?
                <div className="ediBtn" style={{ width: '50px', fontSize: '13px', fontWeight: 'bold', cursor: 'pointer', color: 'white', backgroundColor: 'red', border: '1px solid red' }}
                  onClick={deleteMyAccount}>
                  {
                    loading ?
                      <PuffLoader color='var(--main)' loading={loading} speedMultiplier={1} size={15} />
                      :
                      'Yes'
                  }
                </div>
                :
                <div className="ediBtn" style={{ width: '50px', fontSize: '13px', fontWeight: 'bold', cursor: 'not-allowed', color: 'white', backgroundColor: '#B86566', border: '1px solid #B86566' }}>
                  Yes
                </div>

            }

          </div>
        </div>
      </DialogOutClick>

    </div>
  )
}
