import { useEffect, useState, useRef, useContext } from "react";
import { IoCheckbox } from "react-icons/io5";
import feedback_logo from "../../../../assets/feedback_logo.svg";
import Footer from "../../../../components/Footer/Footer";
import Reviews from "../../../../assets/Reviews.svg";
import ReactPaginate from "react-paginate";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { useHistory, useLocation} from "react-router-dom";
import Nav from "../../../../components/Nav/Nav";
import IndividualSidebar from "../../../../components/Sidebar/IndividualSidebar";
import imagelogo from "../../../../assets/Dillivry.png";
import { SearchContext } from '../../../../context/SearchProvider/search.provider';
import {useParams}  from 'react-router-dom'
import {Grid} from "@material-ui/core"
import { BiArrowBack } from "react-icons/bi";
import SecureLS from "secure-ls";
import axios from "axios";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import {FcCancel} from 'react-icons/fc'
import { ConnContext } from "../../../../context/ConnContext";
import Layout from "../Layout";
import SenderSideBar from "../../components/SenderSideBar";
import GlobalizationLanguage, { GlobalizationLanguagewithCOntryCode } from "../../../../components/GlobalizationLanguage";
import Cookies from "js-cookie";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function ViewFulfilerSender() {
  const sendersettings=GlobalizationLanguage("/sendersettings")
  const sendersettlement=GlobalizationLanguage("/sendersettlement")
  const senderapi=GlobalizationLanguage("/senderapi")
  const team=GlobalizationLanguage("/team")
  var sendermessage = GlobalizationLanguage("/sendermessage")
  var viewQuote_request_delivery = GlobalizationLanguage("/viewQuote/request-a-delivery")
  var timeout = GlobalizationLanguage("/timeout")

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const {delivery_id, fulfiller_id} = useParams();
  const [loading, setloading] = useState(false)
  const Authorization = ls.get("_nodi") === "" ? "" : ls.get("_nodi");

  const {visitingCountryCode} = useContext(ConnContext)
  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: Authorization,
    },
  });


  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

  const [country,setCountry]=useState(countryvalue);

  useEffect(() => {
      setCountry(Cookies.get('i18next'));
    },[Cookies.get('i18next')])

  function reverseArr(input) {
    var ret = new Array();
    for (var i = input?.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }
  

  useEffect(() => {
    window.scrollTo(0, 0);
      setloading(true)
      authAxios
      .get(`${process.env.REACT_APP_API}m-dillivry-search?vcc=${country.toLowerCase()}&attribute=feedback_page&provider_id=${fulfiller_id}`, 
      
       {timeout: 240000}).then((response) => {
        setloading(false)
      setdetails(response?.data);
      // console.log(response?.data, "res.data")
      }).catch((err)=>{
        if (err.code === "ECONNABORTED") {
          console.log('internal-server-error-timeout')
          history.push(timeout)
          // window.location.reload()
        }
      })
  }, []); 



  const {searches, updateSearch} = useContext(SearchContext)

  const history = useHistory();

  const [details, setdetails] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [lastshowvalue, setLastShowValue] = useState(4);
  const [PER_PAGE, setPERPAGE] = useState(lastshowvalue);
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(details?.length / PER_PAGE);
  const handleLastShowValue = (e) => {
    let { name, value } = e.target;
   
  };
  const location = useLocation();



  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    
  };

  // const links =  [
  //   {
  //       path: "/quote",
  //       name: "Quotes",
  //   },
  //   {
  //       path: "/pastdelivery",
  //       name: "Past deliveries",
  //   },
  //   {
  //       path: viewQuote_request_delivery,
  //       name: "Request delivery",
  //   },
  //   {
  //       path: sendermessage,
  //       name: "Message",
  //   },
    
  //   {
  //       path: senderapi,
  //       name: "API",
  //   },
  //   {
  //       path: team,
  //       name: "Team",
  //   },
  //   {
  //       path: sendersettlement,
  //       name: "Settlement",
  //   },
  //   {
  //     path: sendersettings,
  //     name: "Settings",
  //   }
  //   ]


   
    const links =  [
      {
        path: viewQuote_request_delivery,
        name: "Request delivery",
    },
   
      {
        path: team,
        name: "Team",
        state:'inactive'
      },
  
      {
        path: senderapi,
        name: "API",
        state:'inactive'
    },  
      {
        path: sendersettlement,
        name: "Settlement",
        state:'inactive'
      },
      {
        path: sendermessage,
        name: "Help",
        state:'inactive'
      }
    ]
  



  return (
    <>
    {
      loading ?
        // <div style={{ width: '100%', height: '100vh', backgroundColor: 'none', zIndex: '300', position: 'fixed', top: '0', paddingTop: '5px' }}>
        //     <Box sx={{ width: '100%' }}>
        //         <LinearProgress color="success" />
        //     </Box>
        // </div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        : null
    }
    <div className="dsktopscreen">
      <Layout> 
        <div className="deskShow" >
          
          <div className="button-back-to-search" style={{width:'100%'}}>
            {" "}
            <button className="back-to-search" 
             onClick={()=>history?.goBack()}
            >
              {" "}
              <span className="back-tosearch-content"  style={{cursor: "pointer", userSelect:'none'}}>Back to delivery</span>
            </button>
          </div>

          <div className="htnlmk" style={{width:'100%'}}>
            <div className="fulfilername-body">
              <p>
                {details?.account_name}({details?.completed_jobs})
              </p>
            </div>
          </div>

          <div className="fulfiler-main" style={{width:'100%'}}>
            <div className="top-flex">
              <div className="fulfiler-inner-content">
                <div className="image-feedback-details">
                  <div className="image-provider">
                    <img
                      src={details?.cop_logo}
                      alt="Provider-Profile"
                    />
                  </div>
                  <div className="checkbox-under-image">
                    <div className="verified">
                      <IoCheckbox color="var(--main)" />{" "}
                      <span style={{ fontSize: "13px", fontWeight: "600" }}>
                        Verified
                      </span>
                    </div>
                    <div>
                    {
                      details?.good_in_transit_insurance?.insurance_coverage?.length > 4  ?
                      <div className="verified">
                          <IoCheckbox color='var(--main)'/> <span style={{fontSize:'13px', fontWeight:'600'}}>Insurance</span>
                      </div>
                      :
                      <div className="verified">
                          <FcCancel/> <span style={{fontSize:'13px', fontWeight:'600'}}>Insurance</span>
                      </div>
                    }
                    </div>
                  </div>
                </div>

                <div className="feedback-percentage">
                  <div className="fxflex">
                    <div className="iner-flex">
                      <div>
                        <p className="paragraph-feedback">Positive feedback: </p>
                      </div>
                      <div>
                        <span className="span-content">
                          {details?.Positive_feedback}
                        </span>
                      </div>
                    </div>

                    <div className="iner-flex">
                      <div>
                        <p className="paragraph-feedback">Completed Jobs: </p>
                      </div>
                      <div>
                        <span className="span-content">
                          ({details?.completed_jobs})
                        </span>
                      </div>
                    </div>

                    <div className="iner-flex">
                      <div>
                        <p className="paragraph-feedback">Rejected Jobs: </p>
                      </div>
                      <div>
                        <span className="span-content">
                          ({details?.rejected_jobs})
                        </span>
                      </div>
                    </div>

                    {/* Reason for comment is that data is not coming from backend */}

                    {/* <div className="iner-flex">
                      <div>
                        <p className="paragraph-feedback">Registered Since:</p>
                      </div>
                      <div>
                        <span className="span-content">
                          {details?.account_status?.joined_date?.substr(8, 2)}/
                          {details?.account_status?.joined_date?.substr(5, 2)}/
                          {details?.account_status?.joined_date?.substr(0, 4)}
                        </span>
                      </div>
                    </div> */}

                    <div className="iner-flex">
                      <div>
                        <p className="paragraph-feedback">Provider type:</p>
                      </div>
                      <div>
                        <span className="span-content">
                          {details?.role === "dillivry-corp-sys-admin"
                            ? "Corporate"
                            : details?.role === "dillivry-sole-trader-ops"  ? "Individual" : "Corporate"  }
                        </span>
                       
                      </div>
                    </div>

                    <div className="iner-flex">
                      <div>
                        <p className="paragraph-feedback">Insurance coverage:</p>
                      </div>
                      <div>
                        <span className="span-content">
                          {details?.good_in_transit_insurance?.insurance_coverage ?   details?.good_in_transit_insurance?.insurance_coverage : 'None' }
                        </span>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>

              <div className="feedback-table">
                <div
                  className="logo-feedback"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <img src={feedback_logo} alt="feedback_logo" />
                  <p className="feedback-logo">Feedback</p>
                </div>

                <div className="feedbacktable-div">
                  <table className="feedbacks-table">
                    <tr className="feedback-trow">
                      <th></th>
                      <th>1 month</th>
                      <th>6 month</th>
                      <th>12 month</th>
                    </tr>

                    <tr className="positive-feedback">
                      <td>Positive</td>
                      <td>{details?.Feedback?.positive.one_month}</td>
                      <td>{details?.Feedback?.positive.six_month}</td>
                      <td>{details?.Feedback?.positive.telv_month}</td>
                    </tr>
                    <tr>
                      <td>Negative</td>
                      <td>{details?.Feedback?.negative.one_month}</td>
                      <td>{details?.Feedback?.negative.six_month}</td>
                      <td>{details?.Feedback?.negative.telv_month}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div className="provider-history-div" style={{marginTop :'0px'}}>
              <h2 className="provider-history">
                {" "}
                <img src={Reviews} alt="Reviews-icons" /> Reviews 
              </h2>

              <table className="table_  table-tr-th   table-fulfiler">
                <thead>
                <tr>
                  <th>Rating</th>
                  <th>Item</th>
                  <th>Left by</th>
                  <th>Comments</th>
                  <th>Date</th>
                </tr>
                </thead>

              {details?.reviews?.map((i, d) => {
                  return (
                    <tr>
                     
                      <td>
                      {/* {
                        console.log(i, "iiiii", details)
                      } */}
                        {i.rating === "NotGreat" ? (
                          <FaThumbsDown color="red" />
                        ) : (
                          <FaThumbsUp color="var(--main)" />
                        )}
                      </td>

                      <td>{i?.item_desc ? i?.item_desc?.charAt(0)?.toUpperCase() + i.item_desc?.slice(1)  : null}</td>
                      <td>
                        {i?.sender_name} ({i?.count}){" "}
                      </td>
                      <td>{i?.additional_comments}</td>
                    
                      <td>
                        
                      {i?.time_stamp?.substr(8, 2)}/{i?.time_stamp.substr(5, 2)}/{i.time_stamp.substr(0, 4)} 
                        {/* {details?.account_status?.joined_date.substr(8, 2)}/
                        {details?.account_status?.joined_date.substr(5, 2)}/
                        {details?.account_status?.joined_date.substr(0, 4)} */}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </Layout> 
    </div>

    <div className="mobileShow">
      <SenderSideBar pageTitle="Fulfiller profile" links={links}/>

      <div className="fulfillerView">
          <div  className="fulfillerViewTop">
              {/* <div onClick={()=>
                history.push(`/activedeliveries/viewDelivery/${delivery_id}`)
              }> */}
              <div
                onClick={
                  ()=> history.goBack()
                }
                // onClick={()=> history.push(GlobalizationLanguagewithCOntryCode(`/viewQuote`, country, visitingCountryCode))}
              >
                  <BiArrowBack size='25px'/>
              </div>
              <div className='topCircle'></div>
          </div>

          <div className="fulfillertable">
              <div className="tableTitle">
                  {details?.account_name}
              </div>
              <div className="tableBody">
                {/* {
                  console.log(details, "details")
                } */}
                  <Grid container spacing={2}>
                      <Grid item xs={3}>
                        
                          <div className='img'
                              style={{ backgroundImage:`url(${details?.cop_logo})`}}
                              >
                          </div>
                          <div className="verified">
                              <IoCheckbox color='var(--main)' /> <span style={{fontSize:'13px', fontWeight:'600'}}>Verified</span>
                          </div>
                          {
                              details?.good_in_transit_insurance?.insurance_coverage?.length > 4  ?
                              <div className="verified">
                                  <IoCheckbox color='var(--main)'/> <span style={{fontSize:'13px', fontWeight:'600'}}>Insurance</span>
                              </div>
                              :
                              <div className="verified">
                                  <FcCancel/> <span style={{fontSize:'13px', fontWeight:'600'}}>Insurance</span>
                              </div>
                            }
                          
                      </Grid>
                      <Grid item xs={9}>
                          <Grid container spacing={1} style={{fontSize:'12px'}}>
                              <Grid item xs={7} style={{fontWeight:'500'}}>Positive feedback:</Grid><Grid item xs={5}>({details?.Positive_feedback})</Grid>
                              <Grid item xs={7} style={{fontWeight:'500'}}>Completed Jobs:</Grid><Grid item xs={5}>({details?.completed_jobs})</Grid>
                              <Grid item xs={7} style={{fontWeight:'500'}}>Rejected Jobs:</Grid><Grid item xs={5}>({details?.rejected_jobs})</Grid>
                              {/* <Grid item xs={7} style={{fontWeight:'500'}}>Registered since:</Grid><Grid item xs={5}>{details?.account_status?.joined_date?.substr(8, 2)}/{details?.account_status?.joined_date?.substr(5, 2)}/{details?.account_status?.joined_date?.substr(0, 4)}</Grid> */}
                              <Grid item xs={7} style={{fontWeight:'500'}}>Provider type:</Grid><Grid item xs={5}>{details?.role === "dillivry-corp-sys-admin" ? "Corporate": details?.role === "dillivry-sole-trader-ops"  ? "Individual" : "Corporate" }</Grid>
                              <Grid item xs={12} style={{fontWeight:'500'}}>Insurance coverage:</Grid>
                              <Grid item xs={12}>
                                {details?.good_in_transit_insurance?.insurance_coverage ? details?.good_in_transit_insurance?.insurance_coverage : 'None'  }
                              </Grid>
                          </Grid>
                      </Grid>
                  </Grid>
              </div>
              <div style={{padding:'20px 0'}}>
                  <p style={{padding:'10px', fontSize:'13px', fontWeight:'600'}}>Feedback</p>
                  <table style={{border: '1px solid #c4c4c4'}}>
                      <tr>
                          <th  style={{padding:'10px', fontSize:'12px'}}></th>
                          <th style={{padding:'10px', fontSize:'12px'}}>1 month</th>
                          <th style={{padding:'10px', fontSize:'12px'}}>6 months</th>
                          <th style={{padding:'10px', fontSize:'12px'}}>12 months</th>
                      </tr>
                      <tr style={{color:'var(--main)'}}>
                          <td style={{padding:'10px', fontSize:'12px'}}>Positive</td>        
                          <td style={{padding:'10px', fontSize:'12px'}}>{details?.Feedback?.positive.one_month}</td>        
                          <td style={{padding:'10px', fontSize:'12px'}}>{details?.Feedback?.positive.six_month}</td>        
                          <td style={{padding:'10px', fontSize:'12px'}}>{details?.Feedback?.positive.telv_month}</td>        
                      </tr> 
                      <tr style={{color:'red'}}>
                          <td style={{padding:'10px', fontSize:'12px'}}>Negative</td>   
                          <td style={{padding:'10px', fontSize:'12px'}}>{details?.Feedback?.negative.one_month}</td>        
                          <td style={{padding:'10px', fontSize:'12px'}}>{details?.Feedback?.negative.six_month}</td>        
                          <td style={{padding:'10px', fontSize:'12px'}}>{details?.Feedback?.negative.telv_month}</td>        
                      </tr> 
                  </table>
              </div>

              <div className='reviewTable'>
                  <div className="reviewHeable">
                      Reviews 
                  </div>
                         

                      {
                          details?.reviews?.map((review, index)=>{ return <div key={index} className="reviewBody">
                              <div> 
                                  <Grid container spacing={1}>
                                      <Grid item xs={3} style={{fontWeight:'500'}}>Rating</Grid> <Grid item xs={9}>{review.rating === "NotGreat" ? <FaThumbsDown color='red'/> : <FaThumbsUp color='var(--main)'/>}</Grid>
                                      <Grid item xs={3} style={{fontWeight:'500'}}>Item</Grid> <Grid item xs={9}>{review.item_desc?.charAt(0)
                                        ?.toUpperCase() + review.item_desc?.slice(1) }</Grid>
                                      <Grid item xs={3} style={{fontWeight:'500'}}>Left by</Grid> <Grid item xs={9}>{review.sender_name}</Grid>
                                      <Grid item xs={3} style={{fontWeight:'500'}}>Comment</Grid> <Grid item xs={9}>{review.additional_comments}</Grid>
                                      {/* <Grid item xs={3} style={{fontWeight:'500'}}>Date</Grid> <Grid item xs={9}> {review.time_stamp.substr(8, 2)}/{review.time_stamp.substr(5, 2)}/{review.time_stamp.substr(0, 4)}</Grid> */}
                                        <Grid item xs={3} style={{fontWeight:'500'}}>Date</Grid> <Grid item xs={9}> 
                                        {review.time_stamp.substr(8, 2)}/{review.time_stamp.substr(5, 2)}/{review.time_stamp.substr(0, 4)}
                                        </Grid> 
                                  </Grid>
                              </div>
                          </div>
                          })
                      }
              </div>
          </div> 
      </div>
    </div>
    </>
  );
}
