import React, {useState, useContext, useEffect }from 'react'
import OtpInput from "react-otp-input";
import RegBtn from './RegBtn';
import {ImArrowRight2} from 'react-icons/im';

import axios from 'axios';
import Swal from 'sweetalert2';
import {useHistory} from "react-router-dom"
import OTPCountDown from './OTPCountDown';
import SecureLS from 'secure-ls';
import { ConnContext } from '../../context/ConnContext';


function OTP({num, confirmed}) {
    const {visitingCountryCode} = useContext(ConnContext)
    
    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

    const history = useHistory();
    const [otp, setotp] = React.useState('')
    const [isloading, setisloading] = useState(false)

    const Confirm = ()=>{
        setisloading(true)

        axios.post(`${process.env.REACT_APP_API}fulfillers-reg`,{
            action_type: "validate_phone",
            account_name: ls.get('_tyf_'),
            security_code: otp,
            country_code: ls.get('_tyfRC'), 
        })
        .then((response)=>{
            setisloading(false)
            confirmed();
        })
        .catch((error)=>{
            console.log(error)
            Swal.fire({
                icon: 'error', 
                text: 'Invalid Security Code',
                allowOutsideClick: false,
                confirmButtonColor: 'var(--main)'
            })
            setisloading(false)
        })
    }

   

    return (
        <>
            <OtpInput
                value={otp}
                onChange={otp => setotp(otp)}
                numInputs={num}
                isInputNum={true}
                shouldAutoFocus={true}
                separator={
                    <span>
                    <strong></strong>
                    </span>
                }
                inputStyle={{
                    width: "3rem",
                    height: "3rem",
                    margin: "0 0.5rem",
                    fontSize:"24px",
                    borderRadius: 2,
                    border: "2px solid var(--main)",
                    outline: "none"
                }}
            />
            <div style={{width:'100%', textAlign:'center', marginTop:'20px'}}><OTPCountDown initialSeconds={45}/></div>
            <div className="btnDeskto">
                <RegBtn 
                    loading={isloading}
                    txt="Continue" 
                    icon={<ImArrowRight2 size='30px'/>}
                    type='button'
                    onClick={Confirm}
                /> 
            </div>
            <div className="btnMobil">
                <RegBtn
                    loading={isloading}
                    txt="Continue" 
                    icon={<ImArrowRight2 size='30px'/>}
                    type='button'
                    onClick={Confirm}
                /> 
            </div>
        </>
        )
    }

export default OTP
