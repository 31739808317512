import React from 'react'
import './SmallBtn.css'

function SmallBtn({icon, label, onClick}) {
    return (
           <span className="small" onClick={onClick}>
            {icon}&nbsp;{label}
            </span> 
        
    )
}

export default SmallBtn
