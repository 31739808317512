/* eslint-disable react/jsx-pascal-case */
import {useState, useContext} from 'react';
import './Registration.css'
import Corp_Ful_Reg from './Corp_Ful_Reg/Corp_Ful_Reg';
import { Grid } from '@material-ui/core';
import {useHistory, useLocation} from 'react-router-dom'
import { ConnContext } from "../../context/ConnContext";
import {Helmet} from 'react-helmet'
import TopBarHome from '../../components/TopBar/TopBarHome';
import WhyDillivry from '../Appdownload/WhyDillivry';
import ProviderHero from '../Home/HomeComponents/ProviderHero';
import Footer from '../../components/Footer/Footer';
import DownloadHero from '../Home/HomeComponents/DownloadHero';
import Dillivry from "../../assets/Dillivry.png";
import Small from "../../assets/pageEclipseSmall.svg";
import Big from "../../assets/pageEclipse.svg";
import ReactPlayer from 'react-player/youtube'
import GlobalizationLanguage from '../../components/GlobalizationLanguage';
function Registration() {

  const { visitingCountryCode } = useContext(ConnContext);
    const homeLink = GlobalizationLanguage("/");
    window.onbeforeunload = function () {
        window.history.replaceState(null, "");
    };

    const history = useHistory()
    const location = useLocation()

    const [page, setPage] = useState(location?.state?.from === "create_account" ? false : true)

    const [compName, setcompName] = useState("")
    const [comRegNum, setcomRegNum] = useState("")
    const [compAddr, setcompAddr] = useState("")
    
    const GoToRegistration = () =>{
        setPage(!page)
    }
    return (
        <div>
        <Helmet>
            <title>Dillivry - Partner with us as a logistics company</title>
            <meta charSet="utf-8" />
            <meta
                name="description"
                content=""  
            />
        </Helmet>
        {
            page ? 
            <div>
                <TopBarHome/>
                    <div className='AppDownloadHero'>
                        <div className="home_max_width">
                            <div className="AppDownloadHeroCont1">
                                <div className="leftAppDownload">
                                    <h1>Deliver with us</h1>
                                    <h6>Earn money with your vehicle.</h6>
                                </div>
                                <div className="rightAppDownload addPadding">
                                    <form style={{width:'100%'}}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <input 
                                                    autoFocus
                                                    type = "text" 
                                                    className = 'comp_input' 
                                                    placeholder = "Company name"
                                                    value = {compName}
                                                    onChange={(e)=>setcompName(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <input 
                                                    type = "text" 
                                                    className = 'comp_input' 
                                                    placeholder = "Company registration number"
                                                    value = {comRegNum}
                                                    onChange={(e)=>setcomRegNum(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <input 
                                                    type = "text" 
                                                    className = 'comp_input' 
                                                    placeholder = "Company address"
                                                    value = {compAddr}
                                                    onChange={(e)=>setcompAddr(e.target.value)}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <button 
                                                    type = "button" 
                                                    onClick={()=>setPage(false)}
                                                    className = 'comp_btn' 
                                                    value = "Get Started"
                                                >Get Started
                                                </button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                <WhyDillivry/>
                <ProviderHero bg="#F7F7F7" title="How Dillivry works"/>
                <DownloadHero bg="white" color="black"/>
                <Footer/>
            </div>
            :
            <div className='registrationV2'>
                <h6 className='position-absolute d-none d-md-block' onClick={()=>setPage(true)} style={{fontWeight:"unset"}}>Back</h6>
                <div className='regHead'>
                <a href={homeLink} className="homeLogo">
                <img src={Dillivry} alt="dillivry"  />
              </a>
              <h3>Sign up as Logistics Company</h3>
                </div>
                <div className='regPages'>
                    <div className='leftPage'>
                        <div className="pageTagv2">
                            Logistics company’s guide
                        </div>
                        <div className="pageSubtagev2">
                            Play the video to discover how dillivry work best for you as a logistics company
                        </div>
                        <div className='playerCont'>
                            <ReactPlayer 
                                url='https://youtu.be/D1kmfF4PSVE'
                                width='100%'
                                height='100%' 
                                className='react-player'
                            />
                        </div>
                    </div>
                    <div className='rightPage'>
                        <Corp_Ful_Reg setPage={setPage} compDetails={location?.state?.from === "create_account" ? undefined : {compName: compName , comRegNum: comRegNum, compAddr: compAddr}} handleGoBack={()=>history.goBack()}
                            countryCode = {visitingCountryCode}
                            /> 
                    </div>
                </div>

                <div className='topLeft'>
                    <img src={Small} alt="dillivry"/>
                </div>
                <div className='topRight'>
                    <img src={Big} alt="dillivry"/>
                </div>
                <div className='bottomLeft'>
                    <img src={Big} alt="dillivry"/>
                </div>
                <div className='bottomRight'>
                    <img src={Small} alt="dillivry"/>
                </div>
            </div>
            }
        </div>
        
    )
}
export default Registration;
