import React, {useState, useContext} from 'react'
import CategorySelector from '../../../components/CategorySelector'
import './GetStartedHero.css'
import { Button, Grid} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from 'axios';
import { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import SecureLS from 'secure-ls';
import CustomPhoneNumber from '../../../components/CustomPhoneNumber';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom'
import DialogOutClick from '../../../components/DialogOutClick';
import { ConnContext } from "../../../context/ConnContext";
import Swal from 'sweetalert2';
import GlobalizationLanguage from '../../../components/GlobalizationLanguage';
import { PuffLoader } from 'react-spinners';
import Cookies from 'js-cookie';
export default function GetStartedForm() {
    const [showErrorGet, setShowErrorGet] = useState(false);
    const [submitting, setsubmitting] = useState(false)
    const history = useHistory()
    var sender_signup = GlobalizationLanguage("/sender-signup")
    const login = GlobalizationLanguage("/login")

    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

  const { visitingCountryCode, countryName } = useContext(ConnContext);
  const [isError, setisError] = useState(false)

    const handleFormSubmit = (data)=>{
            if (phone_no === undefined || !isValidPhoneNumber(phone_no) || item_category === ""){
                console.log('number invalid')
                // console.log(phone_no)
                setitemCatError(true)
                setisError(true)
            }
            else{
                setsubmitting(true);
                
                axios.post(`${process.env.REACT_APP_API}m-dillivry-prospect-funnel`,{
                    email: data.email.toLowerCase(),
                    phone_number: parsePhoneNumber(phone_no).number,
                    item_category: item_category?.toLowerCase()?.split(" ")?.join("_")
                })
                .then((response) => {
                    setsubmitting(false);
                    if(visitingCountryCode === "NG" || visitingCountryCode === "GB"){
                       if(response?.data?.msg === "proceed to registration"){
                            history.push({
                                pathname: sender_signup,
                                state: {
                                    from: 'Home_page',
                                    email: data?.email,
                                    phone_no: phone_no,
                                    }
                                })
                        }
                        else if( (response?.data?.user_status === "active" || response?.data?.user_status === "CONFIRMED") && response?.data?.phone_status === "verified"){
                            history.push({
                                pathname: '/login',
                                state: {
                                    from: 'Home_page',
                                    email: data?.email
                                    }
                                })
                        }
                        else if( (response?.data?.user_status === "active" || response?.data?.user_status === "CONFIRMED") && response?.data?.phone_status === "not_verified"){
                            ls.set('_bisn',data?.email)
                            history.push({
                                pathname: sender_signup,
                                state: {
                                    from: 'Home_page_OTP_required',
                                    email: data?.email,
                                    phone_no: phone_no,
                                    }
                                })
                        }
                        else if (response?.data?.role === "dillivry-corp-sys-admin" ){
                            history.push({
                                pathname: '/login',
                                state: {
                                    from: 'Home_page',
                                    email: data?.email
                                    }
                                })
                        }
                        else if (response?.data?.role === "dillivry-corp-last-mile-ops"){
                            setShowErrorGet(true);
                        }
                        else if (response?.data?.role === "dillivry-sole-trader-ops"){
                            setShowErrorGet(true);
                        }
                        else{
                            history.push(login)
                        }
                    }
                    else{
                        Swal.fire({
                            icon: 'warning',
                            text: `We are currently not live in ${countryName}. Since we have your details, we shall keep you in the loop on our journey to go live in ${countryName}. Thank you.`,
                            confirmButtonColor: "var(--main)"
                        })
                    }
                })
                .catch((err)=>{
                    console.log(err,"err")
                    setsubmitting(false);
                })
            }
    }

    const schema = yup.object().shape({
        // email: yup.string().email("Valid email is required").required("Email is required"),
        email: yup
        .string()
        .matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, "Valid email is required")
        .required("Email is required"),
    });

    const { register, handleSubmit, formState: { errors }} = useForm({
        mode: 'all',
    resolver: yupResolver(schema),
    });

    const [email, setemail] = useState("")

    const [phone_no, setphone_no] = useState("")

    const [item_category, setitem_category] = useState('');
    
    const [main_item_category, setmain_item_category] = useState('');
    
    
    const [itemCatError, setitemCatError] = useState(false);

    const useStyles = makeStyles((theme) => ({
        formControl: {
          minWidth: `100%`,
        },                                     
        btn: {
            backgroundColor:"var(--main)",
            marginTop:'30px',
            color: "white",
            textTransform: "none",
            fontSize:'18px',
            width:'100%',
            borderRadius:'20px',
            padding:'7px 0',
            '&:hover': {
               background: "white",
               color:  "var(--main)",
               border: "1px solid var(--main)",
            },
          }
      }));

    const classes = useStyles();


  return (
      <div className='heroFormCont'>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                      <CategorySelector country={Cookies.get('i18next')} borderRadius='20px' borderColor={itemCatError && item_category === "" ? "error" : item_category !== "" ? "active" : ''} setitem_category={setitem_category} setmainCategory={setmain_item_category}/>
                  </Grid>
                  <Grid item xs={12}>
                      <CustomInput
                          register={register}
                          title="email"
                          type='email'
                          placeholder="Enter email address"
                          borderColor={email !== "" ? "active" : ''}
                          setvalue={setemail}
                          error={errors.email}
                          value={email} />
                  </Grid>
                  <Grid item xs={12}>
                      <CustomPhoneNumber
                          setPhone_number={setphone_no}
                          isError = {isError}
                          setisError={setisError}
                      />
                  </Grid>
                    <Grid item xs={12}>
                        <Button
                            disabled = {submitting}
                            type='submit'
                            size="small" variant="contained" disableElevation
                            className={classes.btn}>
                            <span> 
                                {
                                submitting ? 
                                    <span className="btnlabe">
                                        <PuffLoader color='white' loading={submitting} speedMultiplier={1} size={24} />
                                    </span>
                                    : 
                                    "Get Started"
                                } 
                            </span>
                        </Button>
                    </Grid>

              </Grid>
          </form>
            <DialogOutClick
                open={showErrorGet}
                handleClose={() => setShowErrorGet(false)}
                >
                <div style={{ width: "100%", padding: "20px" }}>
                    Access denied. Please login on the mobile app.
                </div>
            </DialogOutClick>
      </div>
  )
}
const CustomInput = ({error, type,register,title, borderColor, placeholder, setvalue, value}) =>(
    <div className="CustomInput_container">
      <div className="CustomInput_input" 
            style={{border: error ? '1px solid red' : 
            borderColor === 'active' ? 
            '1px solid var(--main)' : 
            '1px solid #C4C4C4' ,
        }}>
        <input 
            {...register(title)}
            type = {type} 
            className = 'Input_input' 
            placeholder = {placeholder}
            value = {value}
            onChange={(e)=>setvalue(e.target.value)}
            required
        />
      </div>
      </div>
)