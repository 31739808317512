import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Tracking3.css";
import Redsmiley2removebgpreview from "../../assets/Redsmiley2removebgpreview.png";
import greensmiley1removebgpreview from "../../assets/greensmiley1removebgpreview.png"
import SecureLS from 'secure-ls';
import GlobalizationLanguage, { GlobalizationLanguagewithCOntryCode } from "../../components/GlobalizationLanguage";
import { ConnContext } from "../../context/ConnContext";
import Cookies from "js-cookie";

export default function Delivered({data}) {
  const history = useHistory();
  const login=GlobalizationLanguage("/login")
  const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
  const { visitingCountryCode } = useContext(ConnContext);
  var countryvalue =
  Cookies.get("i18next") || visitingCountryCode.toLowerCase();
const [country, setCountry] = useState(countryvalue);

useEffect(() => {
  setCountry(Cookies.get("i18next"));
}, [Cookies.get("i18next")]);

    useEffect(() => {
     console.log(data, "data")
    }, []);

  return (
    <div className="tracking-body">
      <div className="status-body">
        <div className="section-one">
          <div className="left-side-status">
            <div className="Status-h1">
              <h1 className="staus-main">Status</h1>
            </div>
            <div className="Listed-h1">
              <h1 className="Listed-main">Delivered</h1>
            </div>
            <div className="Date-listed-p">
              <p className="Date-listed-main">
                {/* Your item was delivered on Tue 9th Nov at 12:30pm */}
                Your item was delivered on {data.delivered_timestamp?.slice(0, 10)} at {data.delivered_timestamp?.slice(12, 19)}
              </p>
            </div>
          </div>

          {/* {
  console.log(data.delivered_timestamp)
}
{
  console.log(data.delivered_timestamp.slice(0, 10))
}

{
  console.log(data.delivered_timestamp.slice(12, 19))
}

{
  console.log(new Date(), 'newdate')
}

{
  console.log(data.delivered_timestamp.slice(12, 19).toLocaleString('en-US', { hour: 'numeric', hour12: true }))
} */}
          <div className="rightfirst">
            <div className="right-side-status">
              <div className="to-be-styled-seperately">
                <div className="Tobe-delivered">
                  <p className="tobe-delivered">To be delivered to:</p>
                </div>
                <div className="Contact">
                  <h1 className="contact-text">Contact: {data.delivery_phone_no}</h1>
                  <h1 className="Address-Main">
                  {data.delivery_address}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sectionB-">
          <div className="section-two">
            <div className="section-two-follow-my-item">
              <h1 className="followmyitem">Follow my item</h1>
              <p className="findout">
                Find out where your item has been on its journey to the
                destination
              </p>

              <div className="container">
                <ul className="progressBar3">
                  <li className="active-progress">Listed</li>
                  <li>Quote Accepted</li>
                  <li>Awaiting Pickup</li>
                  <li>Picked up</li>
                  <li>Out for delivery</li>
                  <li>Delivered</li>
                </ul>
              </div>
            </div>

            <div className="img-display">
              <div className="inner-img-div">
                <img src={data.image_urls[0]}  alt="Itemdescription" />
                <p>{data.item_desc}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="third-section">
          <div className="empty1"></div>
          <div className="main-twp-rate">
            <div className="rate-your">
              <h1 className="rate-your-provider-main">Rate your provider</h1>
            </div>

            <div className="smiley-green-and-red">
              <img 
              
              
              // onClick={()=> history.push(`/9658393812345985/${data.sender_email}/${data.delivery_id}/Great`)}
              onClick={() =>{
                if (ls.get("_utk") === "") {
                    history.push(login)
                }
                else{
                  const url=GlobalizationLanguagewithCOntryCode(`/9658393812345985/userID=${data?.sender_email}/ID=${data?.delivery_id}/Great`,  country,
                  visitingCountryCode)
                  history.push(url)
                // history.push(`/TrackingPages/${detail.delivery_id}`)
              }
              // console.log(data, "data")
            }}
              
              src={greensmiley1removebgpreview} alt="Greensmiley" />
              <img  
 onClick={() =>{
  if (ls.get("_utk") === "") {
      history.push(login)
  }
  else{
    const url=GlobalizationLanguagewithCOntryCode(`/9658393812345985/userID=${data?.sender_email}/ID=${data?.delivery_id}/NotGreat`,  country,
    visitingCountryCode)

    history.push(url)
  // history.push(`/TrackingPages/${detail.delivery_id}`)
}}}

              
              src={Redsmiley2removebgpreview} alt="RedSmiley" />
            </div>
          </div>
          <div className="empty-three"></div>
        </div>
      </div>
    </div>
  );
}
