import React, { useEffect } from 'react'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; 
import { Calendar } from 'react-date-range';
import { useState } from 'react';

function Calender({onChange, minDate}) {
    
    const [Today, setToday] = useState('')
    
    useEffect(() => {
        if(minDate !== undefined){
            setToday(new Date(minDate)) 
        }
        else{
            setToday(new Date()) 
        }
    }, [])
    return (
        <div>
            <Calendar  
            onChange={onChange}
            minDate = {Today}/>
        </div>
    )
}
export default Calender
