import {useEffect, useState, useContext} from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { ConnContext } from '../../context/ConnContext';

function GlobalizationLanguage(link) {

    const {visitingCountryCode} = useContext(ConnContext)

    var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

    const [country,setCountry]=useState(countryvalue);

    useEffect(() => {
        setCountry(Cookies.get('i18next'));
      },[Cookies.get('i18next')])
    if(country=== visitingCountryCode.toLowerCase()){
        return link
    }
    return `/${country+link}`;
}
GlobalizationLanguage.propTypes = {
    
};
export function GlobalizationLanguagewithCOntryCode(link,country, vcc){
    if(country  === vcc.toLowerCase() ){
        return link
    }
    return `/${country+link}`;
}
export default GlobalizationLanguage;