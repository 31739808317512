import React, { useState, useEffect, useContext } from 'react'
import { usePaystackPayment} from 'react-paystack';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import Swal from 'sweetalert2'
import SecureLS from "secure-ls";
import DialogOutClick from './DialogOutClick';
import LoginCompV2 from './LoginCompV2';
// import Paystack from "../assets/Paystack.svg";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import { ConnContext } from '../context/ConnContext';

const PaystackWidgetSender = ({amount, email, transaction, amount_paid, reload, promoCode, discount, quote_amount }) => {
  const history = useHistory();
  const [TransDate, setTransDate] = useState('')
  const [ShowLogin, setShowLogin] = useState(false)


  const { visitingCountryCode } = useContext(ConnContext);
  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

    const [country,setCountry]=useState(countryvalue);
  

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

    useEffect(() => {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      setTransDate(date);
    }, [])
    const endpoint = `${process.env.REACT_APP_API}`
    
    const config = {
      reference: (new Date()).getTime(),
      email: email,
      amount: amount,
      channels: ['card', 'ussd'],
      // currency: 
      publicKey: process.env.REACT_APP_ENVIRONMENT === "PROD" || process.env.REACT_APP_ENVIRONMENT === "TEST" ? process.env.REACT_APP_PAYSTACK_KEY : process.env.REACT_APP_PAYSTACK_KEY_DEMO, 

  };

  const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });

  

  // you can call this function anything
  const onSuccess = (reference) => {
    const data = promoCode !== "" ? 
    {
      action_type: "make_payment",
      transactions: transaction,
      amount_paid: `${amount_paid}`,
      payment_date: TransDate,
      quoted_amount: `${quote_amount}`,
      payment_status: 'success',
      StatusCode: 200,
      reference_id: reference.reference,
      message: reference.message,
      transaction: reference.transaction,
      payment_gateway: "paystack",
      promocode: promoCode,
      promo_discount: discount,
    }
    :
    {
      action_type: "make_payment",
      transactions: transaction,
      amount_paid: `${amount_paid}`,
      payment_date: TransDate,
      quoted_amount: `${quote_amount}`,
      payment_status: 'success',
      StatusCode: 200,
      reference_id: reference.reference,
      message: reference.message,
      transaction: reference.transaction,
      payment_gateway: "paystack",
    }
    // if(reference.status === 'success'){
      authAxios.post(`m-dillivry-quote-manager?delivery_location=${transaction[0].delivery_location}`, data, 
      {timeout: 240000})
      .then((response)=>{
          Swal.fire({
            icon: 'success',
            text: "Payment made successfully" ,
            confirmButtonColor:'var(--main)'
          })
          .then((result)=>{
            if(result.value){
              reload()
              }
            
          })
      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              ls.removeAll();
              history.push(`/login`);
            }
          });
        }
      });
    // }
    // Implementation for whatever you want to do with reference and after success call.
    // console.log(reference, 'error')
    
  };

  // you can call this function anything
  const onClose = () => {

  }

    const initializePayment = usePaystackPayment(config);

    const tokenCheck = () =>{
      const decoded = jwt_decode(
        ls.get("_utk")
      );
      const expired = decoded.exp;
      if(expired <= Math.floor(Date.now() / 1000)){
          // console.log("session expired")
          setShowLogin(true)
        }
      else{
        initializePayment(onSuccess, onClose);
        }
    }

    return (
      <>
      <div 
        onClick={tokenCheck}
        className="paystack_btn">
          Proceed
      </div>

      <DialogOutClick
        open={ShowLogin}
        handleClose ={()=>{setShowLogin(false);  }}
        >
        <LoginCompV2 onFinish={() => {setShowLogin(false); initializePayment(onSuccess, onClose);}} />
      </DialogOutClick>

      </>
    );
};

export default PaystackWidgetSender