import React, { useState, useEffect, useContext, useRef } from "react";
import './SenderBottomNav.css'
import {useHistory, useLocation} from 'react-router-dom'
import DialogPop from "../../../components/DialogPop";
import Instant from "../../../assets/Instant.svg"
import { SenderContext } from "../../../context/SenderDashboard/SenderDashboardContext";
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
import axios from "axios";
import SecureLS from "secure-ls";

export default function SenderBottomNav({state}) {
  var viewQuote_request_delivery = GlobalizationLanguage("/viewQuote/request-a-delivery")
  var sendermessage = GlobalizationLanguage("/sendermessage");
  var viewQuote = GlobalizationLanguage("/viewQuote");
  var walletsender = GlobalizationLanguage("/wallet_sender");
  var viewQuote_quote = GlobalizationLanguage("/viewQuote/quotes");
 
  const history = useHistory()
  const location = useLocation();

  const [showRequestType, setshowRequestType] = useState(false)
  const { alldata, UpdateAlldata } = useContext(SenderContext);
  const [accountInfo, setAccountInfo] = useState();
  const endpoint = process.env.REACT_APP_API;

  useEffect(() => {

    const ls = new SecureLS({
      isCompression: true,
      encodingType: "rabbit",
      encryptionSecret: "dm_dillivry",
    });

    if (alldata !== undefined) {
      if(alldata.status === 200){
      const CheckLength =  alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length
      const InfoData = alldata?.data?.data?.deliveries ?  alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info) : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)
      CheckLength === 1 ? setAccountInfo(InfoData[0]) : setAccountInfo(InfoData[1])
      }
    }
    else{
      axios
        .get(`${endpoint}m-dillivry-sender-api`, {
          headers: {
            Authorization: ls.get("_utk"),
          },
        })
        .then((res) => {
          UpdateAlldata(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
}, [alldata])
  
  const CheckClickedUrl = (datum)=>{
      if (datum.path ===  "request_delivery" ) {
        if (location.pathname === "/viewQuote/request-a-delivery"){
          console.log('heloooo')
        }
        else{
          setshowRequestType(!showRequestType)
        }
      }else{
        history.push(datum.path)
      }
  }

  const bottomNav = [
    {
      path: viewQuote,
      name: "Dashboard",
      icon: "dashboard",
      state:'status'
    },
    {
      path: viewQuote_quote,
      name: "Quotes",
      icon: "assistant",
      state: "quotes",
    },
    {
      path: 'request_delivery',
      name: "Request Delivery",
      icon: "add_to_photos",
      state: "request-delivery",
    },
    {
      path: sendermessage,
      name: "Message",
      icon: "chat",
      state: "message",
    },
    {
      path: walletsender,
      name: "Wallet",
      icon: "account_balance_wallet",
      state: "wallet",
    },
  ];


  return (
    <div className="navbar">
        <nav className="nav_bottom">
          {
            bottomNav.map((datum, i)=>(
               <div key={i} onClick={()=>  CheckClickedUrl(datum)} className={datum.state === state ? "nav__link nav__link--active" : "nav__link"}>
                  <i className="material-icons nav__icon">{datum.icon}</i>
                  <span className="nav__text">{datum.name}</span>
              </div>
            ))
          }
        </nav>

        <DialogPop
        openPopup={showRequestType}
        setopenPopup={setshowRequestType}
         spanhead= { "Hello " + accountInfo?.given_name?.charAt(0)?.toUpperCase() + accountInfo?.given_name?.slice(1)}
        centerhead="Choose your preferred service"
      >
        <div style={{dispaly: "flex", justifyContent: "center", gap:'10px'   }}   className="instant-schedule">
          <div className="instant"    
          onClick={()=> {
            history.push({
             pathname: viewQuote_request_delivery,
            state: {
              state: `instant`
            },
          });
          setshowRequestType(!showRequestType)
        }} 
            
          >
            <div className="icon">
              <img src={Instant} alt="Instant" className="instant-icon" />
            </div>
            <div className="instant-text-subtext">
              <p className="Instant-main">Instant</p>
              <span className="instant-sub">Pickup and delivery today</span>
            </div>
          </div>

          <div className="Schedule" onClick={()=> {
             history.push({
              pathname: viewQuote_request_delivery,
             state: {
               state: `schedule`
              //  from:  'registration'
             },
           });
           setshowRequestType(!showRequestType)
          }   }  >
            <div className="icon">
              <img src={Instant} alt="Schedule" className="Schedule-icon" />
            </div>
            <div className="Schedule-text-subtext">
              <p className="Schedule-main">Schedule</p>
              <span className="Schedule-sub">
                Choose pickup and delivery date
              </span>
            </div>
          </div>
        </div>
      </DialogPop>
      


      </div>
  )
}
