import React, { useEffect, useState } from 'react'

const Currency = ({ location }) => {

  const [response, setResponse] = useState('')

  useEffect(() => {
    // console.log(location)
    if (location) {
      if (location.toLowerCase() === 'ng') {
        setResponse('&#8358;')
      }
      else if (location.toLowerCase() === 'gb') {
        setResponse('&#163;')
      }
      else {
        setResponse('&#163;')
      }
    }
  }, [location])

  return (
    <span dangerouslySetInnerHTML={{ __html: response }}>
    </span>
  )
}

export default Currency

// usage
{/* <Currency location='ng'/> */ }

// ${Cookies.get('i18next')}

