import { Box, Dialog, Grid, LinearProgress } from '@mui/material';
import axios from 'axios';
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PuffLoader } from 'react-spinners';
import SecureLS from 'secure-ls';
import Swal from 'sweetalert2';
import { SenderContext } from '../../context/SenderDashboard/SenderDashboardContext';
import PaymentDialogPop from '../PaymentDialogPop';
import Paystack_sender_fund_wallet from '../Paystack_sender_fund_wallet';
import FundWalletBankTransfer from './FundWalletBankTransfer';

const WalletFlow = ({ deliveries, total, wallet_balance, setwalletbalance,  closePopup, openPopup }) => {
  const numberWithCommas = new Intl.NumberFormat("en-GB")
  const history = useHistory();
  const [paying, setpaying] = useState(false)

  const [showTranferFromWallet, setShowTranferFromWallet] = useState(false)

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const endpoint = process.env.REACT_APP_API

  const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });

const [refreshBalance, setrefreshBalance] = useState(false)

const { UpdateData } = useContext(SenderContext);

  const getWalletBalance = () =>{
   axios
    .get(`${endpoint}m-dillivry-sender-api`, {
      headers: {
        Authorization: ls.get("_utk"),
      },
    })
    .then((res) => {
      UpdateData(res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());

      if (res.status === 200) {
        setwalletbalance(
          res?.data?.data
            ?.map((x) => x?.ng ?? x?.gb)
            ?.map((x) => x?.account)
            ?.flat()
            ?.filter((x) => x.account_info)
            ?.map((x) => x?.account_info)[0]?.wallet_balance
        );

        openPopup();
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(()=>{
      setrefreshBalance(false)
    })
  }


  const onFundSuccess = () =>{
    getWalletBalance()
    setrefreshBalance(true)
    closePopup()
  }

  const pay_from_wallet = () => {
    setpaying(true)

    // closePopup();

    authAxios
      .post(
        `/m-dillivry-quote-manager?delivery_location=${deliveries?.delivery_location ? deliveries?.delivery_location : deliveries?.vcc}`, {
        "action_type": "make_payment",
        "delivery_id": deliveries.delivery_id,
        "fulfiller_id": deliveries.fulfiller_id,
        "amount_paid": deliveries.quote_amount,
        "payment_date": new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
        "quoted_amount": deliveries.quote_amount,
        "payment_gateway": "dillivry_wallet"
      }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Payment made successfully",
          confirmButtonColor: "var(--main)",
        })
          .then(() => {
            window.location.reload()
          })
        ls.remove("item");
        ls.remove("delivery_id");

      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              ls.removeAll();
              history.push(`/login`);
            }
          });
        }
      })
      .finally(() => {
        setpaying(false)
      })
  }

  const [addamt, setaddamt] = useState('');
  const [showfundwallet, setshowfundwallet] = useState(false)

  const handleChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setaddamt(e.target.value)
    }
  }
  const [payment_method, setpayment_method] = useState('pay_by_transfer')



  return (
    <div>
      {
        refreshBalance ? 
        <div
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "none",
          zIndex: "700",
          position: "fixed",
          top: "40",
          padding: "0",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <LinearProgress color="success" />
        </Box>
      </div>
    : null
      }
      <div className='to_ceter'>Wallet balance</div>
      <div className="wallet_balncenc">
        <span className='wallet_balnc'>
          &#8358;{numberWithCommas.format(wallet_balance)}
        </span>
      </div>
      {
        Number(wallet_balance) < Number(total) ?
          <>
            <div className='insuffiv'>Insufficient fund in your wallet, fund your wallet</div>
            <div className="wallet_balncenc">
              <div className="paystack_btn" style={{ maxWidth: '150px' }}
                onClick={() => { setshowfundwallet(true);  }}
              >
                Fund wallet
              </div>
            </div>
          </>
          :
          <>
            <div className="wallet_balncenc" style={{ padding: '20px 0 0 0' }}>
              {
                paying ?
                  <div className="paystack_btn" style={{ maxWidth: '100px', height: '45px' }}>
                    <PuffLoader
                      color="white"
                      loading={paying}
                      speedMultiplier={1}
                      size={24}
                    />
                  </div>
                  :
                  <div onClick={pay_from_wallet} className="paystack_btn" style={{ maxWidth: '100px' }}>
                    Pay now
                  </div>
              }

            </div>
          </>
      }

      <PaymentDialogPop
        openPopup={showfundwallet}
        setopenPopup={setshowfundwallet}
        head="Fund your wallet"
      >
        <div className="withdrawform">
          <form>
            <div className="form-div">
              <div>
              <input className='withawinput' autoFocus style={{ outline: "none" }} value={addamt} type="text" placeholder='Enter amount to add' onChange={(e) => handleChange(e)} />

              </div>
              <div
                style={{
                  color: "red",
                  marginTop: "0px",
                  marginBottom: "10px",
                  fontSize: "13px",
                  textDecoration: "italic",
                }}
              >
                {parseInt(addamt) < 100 ? (
                  <>You can only add from &#8358;100 and above</>
                ) : null}
              </div>

              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <select className='select_mode' value={payment_method} onChange={(e) => setpayment_method(e.target.value)} id="cars">
                    <option value="pay_by_transfer">Pay by transfer</option>
                    <option value="pay_by_card">Pay with paystack</option>
                    {/* <option value="pay_by_ussd">USSD</option> */}
                  </select>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "4px",
                    }}
                  >
                    {
                      payment_method === "pay_by_transfer" ?
                        addamt === '' ||  parseInt(addamt) < 100 ? 
                        <div className="paystack_btn_disable" >
                          Proceed
                        </div>
                        :
                        <div className="paystack_btn"
                          onClick={() => { setShowTranferFromWallet(true) }}
                        >
                          Proceed
                        </div>

                        :
                        <Paystack_sender_fund_wallet
                          sender
                          amount={addamt + "00"} //for paystack
                          amount_paid={addamt}
                          email={ls.get("eid")}
                          closePopup={() => closePopup()}
                          refresh={onFundSuccess}
                        />
                    }
                  </div>
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </PaymentDialogPop>

      <PaymentDialogPop
        openPopup={showTranferFromWallet}
        setopenPopup={setShowTranferFromWallet}
        head="Fund your wallet"
      >
        <div className="popContainer_payment">
          <FundWalletBankTransfer amount={addamt} onSuccess={onFundSuccess}/>
        </div>
      </PaymentDialogPop>


    </div>
  )
}

export default WalletFlow