import React from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    export default function SnackbarUser({ setHandleOpen, open, message,severity  }) {
        return (
            <div style={{ marginTop:'32px'}}>
                <Snackbar open={open} autoHideDuration={4000} onClose={() => setHandleOpen(false)}>
                    <Alert onClose={() => setHandleOpen(false)} severity={severity}>
                        {message}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
