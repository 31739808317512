import React from "react";
import "./StepsCard.css";

export default function StepsCard({ image, title, text, img_size, gap }) {
  return (
    // <div className="stepCard" style={{ background: "lime" , display: "flex",  }}>
    //   <div
    //     className="stepCard_img"
    //     style={{
    //       backgroundImage: `url(${image})`,
    //       width: img_size,
    //       height: img_size,
    //       maxWidth: '120px'
    //     }}
    //   ></div>
    //   <div style={{

    //   }}   className="stepCardText">
    //     <h3>{title}</h3>
    //     <h4>{text}</h4>
    //   </div>
    // </div>

    <div className="stepHomeCard">
      <div className="StepImg">
        <div
          className="stepCard_img"
          style={{
            backgroundImage: `url(${image})`,
            width: img_size,
            height: img_size,
            maxWidth: "120px",
          }}
        ></div>
      </div>

      <div className="StepDetails">
        <h3>{title}</h3>
        <h4>{text}</h4>
      </div>
    </div>
  );
}
