import React, {useState, useEffect, useContext} from 'react'
import './CategorySelector.css'
import { Grid } from '@material-ui/core';
import Medicine from './Assets/Medicine.svg';
import MedicineActive from './Assets/MedicineActive.svg';
import Pharmaceuticals from './Assets/Pharmaceuticals.svg';
import PharmaceuticalsActive from './Assets/PharmaceuticalsActive.svg';

import PrintingActive from './Assets/PrintingActive.svg';
import Printing from './Assets/Printing.svg';
import VehicleParts from './Assets/VehicleParts.svg';
import VehiclePartsActive from './Assets/VehiclePartsActive.svg';
import Snacks from './Assets/Snacks.svg';
import SnacksActive from './Assets/SnacksActive.svg';

import BeautySkincare from './Assets/BeautySkincare.svg';
import BeautySkincareActive from './Assets/BeautySkincareActive.svg';
import Beverages from './Assets/Beverages.svg';
import BeveragesActive from './Assets/BeveragesActive.svg';
import CakeAndconfectionaries from './Assets/CakeAndconfectionaries.svg';
import CakeAndConfectionariesActive from './Assets/CakeAndConfectionariesActive.svg';
import Clothing from './Assets/Clothing.svg';
import ClothingActive from './Assets/ClothingActive.svg';
import ComputerAndAccessories from './Assets/ComputerAndAccessories.svg';
import ComputerAndAccessoriesActive from './Assets/ComputerAndAccessoriesActive.svg';
import DocumentsAndBooks from './Assets/DocumentsAndBooks.svg';
import DocumentsAndBooksActive from './Assets/DocumentsAndBooksActive.svg';
import ElecronicsAndAppliances from './Assets/ElecronicsAndAppliances.svg';
import ElectronicsAndAppliancesActive from './Assets/ElectronicsAndAppliancesActive.svg';
import Fashion from './Assets/Fashion.svg';
import FashionActive from './Assets/FashionActive.svg';
import Fastfood from './Assets/Fastfood.svg';

import FastfoodActive from './Assets/FastfoodActive.svg';
import Food from './Assets/Food.svg';
import FoodActive from './Assets/FoodActive.svg';
import Foodstuffs from './Assets/Foodstuffs.svg';
import FoodstuffsActive from './Assets/FoodstuffsActive.svg';
import FrozenFood from './Assets/FrozenFood.svg';
import FrozenFoodActive from './Assets/FrozenFoodActive.svg';
import Fruits from './Assets/Fruits.svg';
import FruitsActive from './Assets/FruitsActive.svg';
import Furniture from './Assets/Furniture.svg';
import FurnitureActive from './Assets/FurnitureActive.svg';
import Groceries from './Assets/Groceries.svg';
import GroceriesActive from './Assets/GroceriesActive.svg';
import Haulage from './Assets/Haulage.svg';
import HaulageActive from './Assets/HaulageActive.svg';
import HerbalProducts from './Assets/HerbalProducts.svg';

import HerbalProductsActive from './Assets/HerbalProductsActive.svg';
import HomeAppliances from './Assets/HomeAppliances.svg';
import HomeAppliancesActive from './Assets/HomeAppliancesActive.svg';
import JewellryAccessories from './Assets/JewellryAccessories.svg';
import JewellryAccessoriesActive from './Assets/JewellryAccessoriesActive.svg';
import Livestock from './Assets/Livestock.svg';
import LivestockActive from './Assets/LivestockActive.svg';
// import MedecineActive from './Assets/MedecineActive.svg';

import MovingHome from './Assets/MovingHome.svg';
import MovingHomeActive from './Assets/MovingHomeActive.svg';
import Others from './Assets/Others.svg';
import OthersActive from './Assets/OthersActive.svg';
import Perishables from './Assets/Perishables.svg';
import PerishablesActive from './Assets/PerishablesActive.svg';
import PhoneAccessories from './Assets/PhoneAccessories.svg';
import PhoneAndAccessoriesActive from './Assets/PhoneAndAccessoriesActive.svg';
import Provision from './Assets/Provision.svg';
import ProvisionActive from './Assets/ProvisionActive.svg';
import Shoes from './Assets/Shoes.svg';
import ShoesActive from './Assets/ShoesActive.svg';
import WineAndDrinks from './Assets/WineAndDrinks.svg';
import WineAndDrinksActive from './Assets/WineAndDrinksActive.svg';
import SecureLS from "secure-ls";


import {BsChevronDown, BsChevronUp} from "react-icons/bs"
import {RiCloseFill} from "react-icons/ri"
import { useLocation } from "react-router-dom";

import {ConnContext} from '../../context/ConnContext'
import Cookies from 'js-cookie';

function CategorySelector({country, borderRadius, borderColor, setitem_category,setmainCategory, categorySelected}) {

  const {visitingCountryCode} = useContext(ConnContext);

  const location = useLocation();

  const ls = new SecureLS({isCompression: true,encodingType: "rabbit",encryptionSecret: "dm_dillivry"});
  const [showlist, setshowlist] = useState(false);

  const [mouseHover, setmouseHover] = useState("")

  const [category, setcategory] = useState(categorySelected)

  const [subcategory, setsubcategory] = useState("")

  const [showSubcaterogy, setShowSubcaterogy] = useState(false)

  const [subcaterogyTitle, setSubcaterogyTitle] = useState('')


  useEffect(() => {
    if(location.state?.data !== undefined){
        setcategory( location?.state?.data?.item_category?.charAt(0)?.toUpperCase() +  location?.state?.data?.item_category?.replaceAll("_", " ")?.slice(1))
    }else{
      setcategory(category)
    }
    }, [location])

  const handleCategoryClick  = (category, sub_category) =>{
    if (sub_category === ""){
      setcategory(category)
      setShowSubcaterogy(false)
      setmouseHover(category)
      setshowlist(false)
      setitem_category(category)
      setmainCategory(category)
    }
    else{
      // setcategory("")
      // setcategory(category)
      setSubcaterogyTitle(category)
      setmouseHover(category)
      setsubcategory(sub_category);
      setShowSubcaterogy(true)
      setmainCategory(category)

    }
  }

  const handleSubCategoryClick  = (category) =>{
      setcategory(category)
      setmouseHover(category)
      setShowSubcaterogy(false)
      setshowlist(false)
      setitem_category(category)
  }

  const GB_main_category = [
    
    {
      text : "Electronics & Appliances",
      activeImage : ElectronicsAndAppliancesActive,
      inactiveImage : ElecronicsAndAppliances,
      subcategory : "electronics"
    },
    {
      text : "Food",
      activeImage : FoodActive,
      inactiveImage : Food,
      subcategory : "food"
    },
    {
      text : "Furniture",
      activeImage : FurnitureActive,
      inactiveImage : Furniture,
      subcategory : ""
    },
    {
      text : "Groceries",
      activeImage : GroceriesActive,
      inactiveImage : Groceries,
      subcategory : "groceries"
    },
    {
      text : "Haulage",
      activeImage : HaulageActive,
      inactiveImage : Haulage,
      subcategory : ""
    },
    {
      text : "Moving home",
      activeImage : MovingHomeActive,
      inactiveImage : MovingHome,
      subcategory : ""
    },
    {
      text : "Pharmaceuticals",
      activeImage : PharmaceuticalsActive,
      inactiveImage : Pharmaceuticals,
      subcategory : "pharmaceutical"
    },
    {
      text : "Boxes",
      activeImage : PharmaceuticalsActive,
      inactiveImage : Pharmaceuticals,
      subcategory : ""
    },
    {
      text : "Vehicle parts",
      activeImage : VehiclePartsActive,
      inactiveImage : VehicleParts,
      subcategory : ""
    },
    {
      text : "General items",
      activeImage : OthersActive,
      inactiveImage : Others,
      subcategory : ""
    },
    {
      text : "Livestock",
      activeImage : OthersActive,
      inactiveImage : Others,
      subcategory : ""
    },
    {
      text : "Pets",
      activeImage : OthersActive,
      inactiveImage : Others,
      subcategory : ""
    },
    {
      text : "Removal",
      activeImage : OthersActive,
      inactiveImage : Others,
      subcategory : ""
    },
    {
      text : "Pallets",
      activeImage : OthersActive,
      inactiveImage : Others,
      subcategory : ""
    }

  ]

  const GB_sub_category = 
    subcategory === "electronics" ?
      [{
          text : "Computer & Accessories",
          activeImage : ComputerAndAccessoriesActive,
          inactiveImage : ComputerAndAccessories,
        },
        {
          text : "Home appliances",
          activeImage : HomeAppliancesActive,
          inactiveImage : HomeAppliances,
        },
        {
          text : "Phones & Accessories",
          activeImage : PhoneAndAccessoriesActive,
          inactiveImage : PhoneAccessories,
        }]
        :
        subcategory === "food" ?
          [
            {
              text : "Dry food",
              activeImage : FastfoodActive,
              inactiveImage : Fastfood,
            },
            {
              text : "Fast food",
              activeImage : FastfoodActive,
              inactiveImage : Fastfood,
            },
            {
              text : "Food stuffs",
              activeImage : FoodstuffsActive,
              inactiveImage : Foodstuffs,
            },
            {
              text : "Frozen food",
              activeImage : FrozenFoodActive,
              inactiveImage : FrozenFood,
            }
            ]
            :
            subcategory === "groceries" ?
              [{
                  text : "Beverages",
                  activeImage : BeveragesActive,
                  inactiveImage : Beverages,
                },
                {
                  text : "Provisions",
                  activeImage : ProvisionActive,
                  inactiveImage : Provision,
                },
                {
                  text : "Wine & Drinks",
                  activeImage : WineAndDrinksActive,
                  inactiveImage : WineAndDrinks,
                }]
                :
                subcategory === "pharmaceutical" ?
                  [{
                      text : "Medicine",
                      activeImage : MedicineActive,
                      inactiveImage : Medicine,
                    }]
                    :
                    []

  const main_category = [
    {
      text : "Beauty & Skincare",
      activeImage : BeautySkincareActive,
      inactiveImage : BeautySkincare,
      subcategory : ""
    },
    {
      text : "Cake & Confectionery",
      activeImage : CakeAndConfectionariesActive,
      inactiveImage : CakeAndconfectionaries,
      subcategory : ""
    },
    {
      text : "Document & Books",
      activeImage : DocumentsAndBooksActive,
      inactiveImage : DocumentsAndBooks,
      subcategory : ""
    },
    {
      text : "Electronics & Appliances",
      activeImage : ElectronicsAndAppliancesActive,
      inactiveImage : ElecronicsAndAppliances,
      subcategory : "electronics"
    },
    {
      text : "Fashion",
      activeImage : FashionActive,
      inactiveImage : Fashion,
      subcategory : "fashion"
    },
    {
      text : "Food",
      activeImage : FoodActive,
      inactiveImage : Food,
      subcategory : "food"
    },
    {
      text : "Furniture",
      activeImage : FurnitureActive,
      inactiveImage : Furniture,
      subcategory : ""
    },
    {
      text : "Groceries",
      activeImage : GroceriesActive,
      inactiveImage : Groceries,
      subcategory : "groceries"
    },
    {
      text : "Haulage",
      activeImage : HaulageActive,
      inactiveImage : Haulage,
      subcategory : ""
    },
    {
      text : "Moving home",
      activeImage : MovingHomeActive,
      inactiveImage : MovingHome,
      subcategory : ""
    },
    {
      text : "Pharmaceutical",
      activeImage : PharmaceuticalsActive,
      inactiveImage : Pharmaceuticals,
      subcategory : "pharmaceutical"
    },
    {
      text : "Printing press",
      activeImage : PrintingActive,
      inactiveImage : Printing,
      subcategory : ""
    },
    {
      text : "Vehicle parts",
      activeImage : VehiclePartsActive,
      inactiveImage : VehicleParts,
      subcategory : ""
    },
    {
      text : "General items",
      activeImage : OthersActive,
      inactiveImage : Others,
      subcategory : ""
    },
  ]

  const sub_category = 
    subcategory === "electronics" ?
      [{
          text : "Computer & Accessories",
          activeImage : ComputerAndAccessoriesActive,
          inactiveImage : ComputerAndAccessories,
        },
        {
          text : "Home appliances",
          activeImage : HomeAppliancesActive,
          inactiveImage : HomeAppliances,
        },
        {
          text : "Phones & Accessories",
          activeImage : PhoneAndAccessoriesActive,
          inactiveImage : PhoneAccessories,
        }]
        :
        subcategory === "fashion" ?
          [{
              text : "Clothing",
              activeImage : ClothingActive,
              inactiveImage : Clothing,
            },
            {
              text : "Jewellery & Accessories",
              activeImage : JewellryAccessoriesActive,
              inactiveImage : JewellryAccessories,
            },
            {
              text : "Shoe",
              activeImage : ShoesActive,
              inactiveImage : Shoes,
            }]
            :
            subcategory === "food" ?
              [{
                  text : "Fast food",
                  activeImage : FastfoodActive,
                  inactiveImage : Fastfood,
                },
                {
                  text : "Food stuffs",
                  activeImage : FoodstuffsActive,
                  inactiveImage : Foodstuffs,
                },
                {
                  text : "Frozen food",
                  activeImage : FrozenFoodActive,
                  inactiveImage : FrozenFood,
                },
                {
                  text : "Livestock",
                  activeImage : LivestockActive,
                  inactiveImage : Livestock,
                },
                {
                  text : "Perishables",
                  activeImage : PerishablesActive,
                  inactiveImage : Perishables,
                },
                {
                  text : "Snacks",
                  activeImage : SnacksActive,
                  inactiveImage : Snacks,
                }]
                :
                subcategory === "groceries" ?
                  [{
                      text : "Beverages",
                      activeImage : BeveragesActive,
                      inactiveImage : Beverages,
                    },
                    {
                      text : "Fruits",
                      activeImage : FruitsActive,
                      inactiveImage : Fruits,
                    },
                    {
                      text : "Provisions",
                      activeImage : ProvisionActive,
                      inactiveImage : Provision,
                    },
                    {
                      text : "Wine & Drinks",
                      activeImage : WineAndDrinksActive,
                      inactiveImage : WineAndDrinks,
                    }]
                    :
                    subcategory === "pharmaceutical" ?
                      [{
                          text : "Medicine",
                          activeImage : MedicineActive,
                          inactiveImage : Medicine,
                        },
                        {
                          text : "Herbal products",
                          activeImage : HerbalProductsActive,
                          inactiveImage : HerbalProducts,
                        }
                       ]
                        :
                        []


  return (
    <div className="category_container">
      <div 
        className="category_selector"
        style={{
          border: borderColor === 'active' ? 
          '1px solid var(--main)' : 
          borderColor === 'error' ? 
          '1px solid red' : 
          '1px solid #C4C4C4',
          borderRadius: borderRadius ? borderRadius : "4px"
        }}
        onClick={()=>setshowlist(!showlist)}>
        
        <span>
        {category === ""|| category === undefined ? "Select category" : category}
        </span>
        <span className='select_icon'>
          {showlist ?
            <BsChevronUp/>
            :
            <BsChevronDown/>
          }
        </span>
      </div>
      {
        showlist ? 
        <div className="category_value">
          {
          showSubcaterogy ?
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className='subcaterogyTitle'>
                  <span>{subcaterogyTitle}</span>
                  <span className='subcat_close'
                    onClick={()=>setShowSubcaterogy(false)}>
                    <RiCloseFill size={20}/>
                  </span>
                </div>
              </Grid>
              {
                country === "ng" ?
                  sub_category.map((cat, index)=>(
                    <Grid item xs={4} key={index}>
                      <SubCategories
                        onMouseOver={()=>setmouseHover(cat.text)} 
                        onMouseLeave={()=>setmouseHover("")}
                        text={cat.text}
                        activeImage={cat.activeImage}
                        inactiveImage={cat.inactiveImage}
                        mouseHover={mouseHover}
                        category={category}
                        onClick = {()=>handleSubCategoryClick(cat.text)}
                      />
                  </Grid>
                  ))
                  :
                  GB_sub_category.map((cat, index)=>(
                    <Grid item xs={4} key={index}>
                      <SubCategories
                        onMouseOver={()=>setmouseHover(cat.text)} 
                        onMouseLeave={()=>setmouseHover("")}
                        text={cat.text}
                        activeImage={cat.activeImage}
                        inactiveImage={cat.inactiveImage}
                        mouseHover={mouseHover}
                        category={category}
                        onClick = {()=>handleSubCategoryClick(cat.text)}
                      />
                  </Grid>
                  ))
              }
            </Grid>
            :
            <Grid container spacing={2}>
              {
                country === "ng" ?
                
                main_category.map((cat, index)=>(
                  <Grid item xs={4} key={index}>
                    <Categories
                      onMouseOver={()=>setmouseHover(cat.text)} 
                      onMouseLeave={()=>setmouseHover("")}
                      text={cat.text}
                      activeImage={cat.activeImage}
                      inactiveImage={cat.inactiveImage}
                      mouseHover={mouseHover}
                      category={category}
                      onClick = {()=>handleCategoryClick(cat.text, cat.subcategory)}
                    />
                </Grid>
                ))
                :
                GB_main_category.map((cat, index)=>(
                  <Grid item xs={4} key={index}>
                    <Categories
                      onMouseOver={()=>setmouseHover(cat.text)} 
                      onMouseLeave={()=>setmouseHover("")}
                      text={cat.text}
                      activeImage={cat.activeImage}
                      inactiveImage={cat.inactiveImage}
                      mouseHover={mouseHover}
                      category={category}
                      onClick = {()=>handleCategoryClick(cat.text, cat.subcategory)}
                    />
                </Grid>
                ))
              }
            </Grid>
          }
        </div>
        :
        null
      }
      
    </div>
  )
}



export default CategorySelector

const Categories = ({onClick, onMouseOver, onMouseLeave,mouseHover,category, activeImage, inactiveImage, text }) =>(

  <div className="value_list" 
    onClick={onClick}
    onMouseOver={onMouseOver} 
    onMouseLeave={onMouseLeave}>
    <img src={
        mouseHover === text ? activeImage 
        :
        category === text ? activeImage 
        :
        inactiveImage

      } alt="medicine"
    />

   

    <span style={{color: 
      mouseHover === text ? "var(--main)" 
      :
      category === text ? "var(--main)" 
      : 
      "black"}}>
      {text}
    </span>

  </div>
)

const SubCategories = ({onClick, onMouseOver, onMouseLeave,mouseHover,category, activeImage, inactiveImage, text }) =>(
  <div className="value_list" 
    onClick={onClick}
    onMouseOver={onMouseOver} 
    onMouseLeave={onMouseLeave}>
    <img src={
        mouseHover === text ? activeImage 
        :
        category === text ? activeImage 
        :
        inactiveImage

      } alt="medicine"
    />

    <span style={{color: 
      mouseHover === text ? "var(--main)" 
      :
      category === text ? "var(--main)" 
      : 
      "black"}}>
      {text}
    </span>
  </div>
)