import React from 'react'
import RegBtnDouble from './RegBtnDouble'
import {BiCheckCircle} from 'react-icons/bi'

function ThanksNext({toNext, toStatus, txt, loading}) {
    return (
        <div style={{display:'flex',  height:'600px', justifyContent:'center', alignItems:'center', flexDirection:'column', gap:'20px'}}>
            <BiCheckCircle size='80' color='var(--main)'/>
            <div style={{width:'100%', fontSize:'15px', fontWeight:'600', textAlign:'center'}}>
                {txt}
            </div>
            <div className="btnDeskto">
                <RegBtnDouble 
                    type='button'
                    leftText="Go to account status" 
                    rightText="Go to next step"
                    toStatus={toNext}
                    toNext ={toStatus}
                    loading={loading}
                />
            </div>
            <div className="btnMobil">
                <RegBtnDouble 
                    type='button'
                    leftText="Go to account status" 
                    rightText="Go to next step"
                    toStatus={toNext}
                    toNext ={toStatus}
                    loading={loading}
                />
            </div>
        </div>
    )
}

export default ThanksNext
