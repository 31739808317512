import * as React from 'react';
import './styles.css'
import InactiveArrow from '../../assets/InactiveArrow.png'
import ActiveArrow from '../../assets/ActiveArrow.png'

const steps = [
  '1. Awaiting Quotes', 
  '2. Received quotes', 
  '3. Awaiting acknowledgment',
  '4. Awaiting agent assignment',
  '5. Awaiting pickup',
  '6. In-transit',
  '7. Out for delivery',
  '8. Completed'
];

export default function LinearStepper({activeStep}) {

  return (
    <div className='stepper_container'>
      {
        steps.map((x, i) =>(
        <div key={x} className="stepper_cont">
          <div className={activeStep === i ? 'stepper_text_active' : activeStep > i ? 'stepper_text' : "stepper_text_pending"}>{x}</div>
          <span className='stepper_text' style={{display: i === steps.length - 1 ? "none" : ""}}>
            <img src={activeStep > i ? ActiveArrow : InactiveArrow} alt="" />
          </span>
        </div>
        ))
      }
      
    </div>
  );
}
