import React from 'react'
import './ProviderHero.css'
import Register from '../../../assets/Register.svg'
import Quote from '../../../assets/Quote.svg'
import Deliver from '../../../assets/Deliver.svg'
import StepsCard from './StepsCard';
import { Grid } from '@material-ui/core'
import StepsCardProvider from './StepsCardProvider'

export default function ProviderHero({bg, title}) {
  return (
    <div className='ProviderHero' style={{backgroundColor: bg}}>
        <div className='container'>
        <div className="home_max_width">
            <h2 className='heroTitles'>{title}</h2>
            <div className="ProviderHeroCont">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}  >
                        {/* <div style={{backgroundColor: "Pink", height: "40px"}} > */}

                            <StepsCardProvider img_size="140px" gap="20px" image={Register} title="Register" text="Sign up for free on the web or download Dillivry app in App store or Google play."/>
                        {/* </div> */}
                        {/* <StepsCard img_size="140px" gap="20px" image={Register} title="Register" text="Sign up for free on the web or download Dillivry app in App store or Google play."/> */}
                    </Grid>
                    <Grid item xs={12} sm={4} >
                    <StepsCardProvider img_size="120px" gap="20px" image={Quote} title="Quote" text="Submit your reasonable quote for delivery requests."/>
                    {/* <div style={{backgroundColor: "green", height: "40px"}} ></div> */}
                        {/* <StepsCard img_size="120px" gap="20px" image={Quote} title="Quote" text="Submit your reasonable quote for delivery requests."/> */}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                    <StepsCardProvider img_size="105px" gap="20px" image={Deliver} title="Deliver" text="Pickup and deliver with care."/>
                    {/* <div style={{backgroundColor: "red", height: "40px"}} ></div> */}
                        {/* <StepsCard img_size="105px" gap="20px" image={Deliver} title="Deliver" text="Pickup and deliver with care."/> */}
                    </Grid>
                </Grid>
                </div>
            </div>
        </div>
    </div>
  )
}