import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom';
import {FiPower} from 'react-icons/fi'
import DialogOutClick from '../../../components/DialogOutClick';
import SecureLS from 'secure-ls';
import GlobalizationLanguage from '../../../components/GlobalizationLanguage';
function AccountSetting({ userProfile, goBack}) {
    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
    const forgetpass=GlobalizationLanguage("/forgetpass")

    useEffect(() => {
        setFullname(userProfile[0].first_name + " " + userProfile[0].last_name)
        setEmail(userProfile[0].user_id)
        setPhone(userProfile[0]?.phone_number?.phone_number)
        
    }, [])
    const [passwordResetted, setpasswordResetted] = useState(false)
    const [fullname, setFullname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [showLogout, setShowLogout] = useState(false)
    
    const history = useHistory();

    const logMeOut = () => {
        ls.removeAll();
        history.push('/');
    }
    return (
        <div className="context-past"> 
            <div className="context-past-right">
                <div className="column">
                    <div className="columnHead">
                        <div className="headiing">
                        <div className="right">Account</div>
                            <div className="left">
                                <button onClick={()=>setShowLogout(true)}><FiPower/> Logout</button>
                            </div> 
                        </div>
                    </div>
                    
                    <div className="columnBody1">
                        {/* <button onClick={()=>{console.log(information)}}>show</button> */}
                        <div className="informtion">
                            <div className="informed">
                                <div className="pers">
                                    Personal Info
                                </div>
                                <div className="formation">
                                    <div className="infoTitle">NAME</div>
                                    <div className="infoDetails">{fullname}</div>
                                    <div className="divida"></div>
                                </div> 
                                <div className="formation">
                                    <div className="infoTitle">EMAIL</div>
                                    <div className="infoDetails">{email}</div>
                                    <div className="divida"></div>
                                </div> 
                                <div className="formation">
                                    <div className="infoTitle">PHONE</div>
                                    <div className="infoDetails">{phone}</div>
                                </div> 
                            </div>
                        </div>
                        <div className="need" style={{color:'var(--main)', cursor:"pointer"}} ><span onClick={()=>history.push(forgetpass)}>Need to reset your password? </span></div>
                     </div>
                </div>
            </div>
            <DialogOutClick
                open={showLogout}
                handleClose ={()=>setShowLogout(false)}
                > 
                <div style={{width:'100%',padding:"30px 30px 20px 30px"}}>
                    <div style={{width:'100%',marginBottom:"30px", fontSize:'13px', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <span>Are you sure you want to logout?</span>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
                        <div className="disBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer'}}
                            onClick={()=>setShowLogout(false)} >
                            No
                        </div>
                        <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'white', backgroundColor:'#808080', border:'1px solid #808080' }}
                            onClick={logMeOut}>
                            Yes
                        </div>
                    </div>
                </div>
            </DialogOutClick>
        </div>
    )
}

export default AccountSetting
