import {useContext} from 'react';
import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react'
import { BiChevronDownCircle, BiChevronUpCircle, BiTimer, BiWallet } from 'react-icons/bi';
import ViewDeliveryUnassigned from '../ViewDeliveryUnAssigned';
import{DashboardContext}  from '../../../../context/Dashboard/DashboardProvider/dashboard.provider'
import { AiTwotoneShop } from 'react-icons/ai';
import { TiMessages } from 'react-icons/ti';
import { RiAccountPinCircleLine } from 'react-icons/ri';
import {useHistory} from 'react-router-dom'
import DashBottomBtn from '../DashBottomBtn/DashBottomBtn';
import GlobalizationLanguage from '../../../../components/GlobalizationLanguage';

function MobileQuoteHistory({value}) {
  var wallet = GlobalizationLanguage("/wallet")
  var account = GlobalizationLanguage("/account")
  const Dashboard = GlobalizationLanguage("/Dashboard")

const {dashboards, updateDashboard} = useContext(DashboardContext)
const history = useHistory() 
const [missed, setMissed] = useState([]);
const [completed, setCompleted] = useState([]);
const [showMissed, setshowMissed] = useState(true);
const [showComplete, setshowComplete] = useState(true);
const [view, setview] = useState(false)
const [dataToShow, setdataToShow] = useState([]);
const [clicked, setclicked] = useState('');
useEffect(() => {
    setMissed(dashboards.data.missed_delivery);
    setCompleted(dashboards.data.completed_quotes);
}, [])

    const numberWithCommas = new Intl.NumberFormat("en-GB")
    
    return (
      <>
        <div
          className="context"
          style={{ paddingTop: "20px", paddingBottom: "80px" }}
        >
          <div style={{ display: "flex", marginBottom: "20px", gap: "20px" }}>
            {/* <IoMdArrowRoundBack onClick={goBack} size='24px'/> */}
            <h2>Quote history</h2>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ padding: "8px 0", fontWeight: "500" }}>
              Missed Delivery
            </div>
            {showMissed ? (
              <div
                style={{ padding: "8px 0", fontWeight: "500" }}
                onClick={() => setshowMissed(false)}
              >
                <BiChevronDownCircle size="25px" />
              </div>
            ) : (
              <div
                style={{ padding: "8px 0", fontWeight: "500" }}
                onClick={() => setshowMissed(true)}
              >
                <BiChevronUpCircle size="25px" />
              </div>
            )}
          </div>
          {showMissed ? (
            <>
              <Grid
                container
                style={{ backgroundColor: "var(--main)", color: "white" }}
              >
                <Grid
                  item
                  xs={5}
                  style={{
                    padding: "7px 10px",
                    borderRight: "1px solid white",
                  }}
                >
                  Delivery ID
                </Grid>
                <Grid item xs={7} style={{ padding: "7px 10px" }}>
                  Description
                </Grid>
              </Grid>
              {missed.map((item, index) => {
                  const url = GlobalizationLanguage(`/delivery_history/missed/${item.delivery_id}`)
                return (
                  <div key={index} style={{ userSelect: "none" }}>
                    <Grid
                      container
                      style={{
                        border: "1px solid whitesmoke",
                        backgroundColor: "white",
                      }}
                      onClick={() => {
                        history.push(url);
                      }}
                    >
                      <Grid
                        item
                        xs={5}
                        style={{
                          padding: "7px 10px",
                          borderRight: "1px solid whitesmoke",
                        }}
                      >
                        {item.delivery_id}
                      </Grid>
                      <Grid item xs={7} style={{ padding: "7px 10px" }}>
                        {item.item_desc.charAt(0)?.toUpperCase() +
                          item.item_desc.toLowerCase().slice(1)}
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </>
          ) : null}

                <div style={{display:'flex',alignItems:'center', justifyContent:'space-between', width:'100%', marginTop:"30px"}}>
                    <div style={{padding:'8px 0', fontWeight:'500'}}>Completed Delivery</div>
                    {
                        showComplete ?
                        <div style={{padding:'8px 0', fontWeight:'500'}} onClick={()=>setshowComplete(false)}><BiChevronDownCircle size='25px'/></div>
                        :
                        <div style={{padding:'8px 0', fontWeight:'500'}} onClick={()=>setshowComplete(true)}><BiChevronUpCircle size='25px'/></div>
                    }
                </div>
                {
                    showComplete ?
                    <>
                        <Grid container style={{ backgroundColor:'var(--main)', color:'white'}}>
                            <Grid item xs={5} style={{padding:'7px 10px',borderRight:'1px solid white'}}>
                                Delivery ID
                            </Grid>
                            <Grid item xs={7} style={{padding:'7px 10px'}}>
                                Description
                            </Grid>
                        </Grid>
                        {
                            completed.map((item, index)=>{ 
                              var url= GlobalizationLanguage(`/delivery_history/complete/${item.delivery_id}`)
                              return <div key={index} style={{userSelect:"none"}}>
                                <Grid container style={{border:'1px solid whitesmoke', backgroundColor:'white'}}
                                    onClick={()=>{
                                        history.push(`/delivery_history/complete/${item.delivery_id}`)
                                    }}>
                                    <Grid item xs={5} style={{padding:'7px 10px',borderRight:'1px solid whitesmoke'}}>
                                        {item.delivery_id}
                                    </Grid>
                                    <Grid item xs={7} style={{padding:'7px 10px'}}>
                                        {item.item_desc.charAt(0)?.toUpperCase() + item.item_desc.toLowerCase().slice(1)}
                                    </Grid>
                                </Grid>
                                </div>
                            })
                        } 
                    </>
                    :
                    null
                }
                  
                <DashBottomBtn activeTab='history'/>
            </div>

            <div className="activeBtn">
              <BiTimer />
              <span>Quote history</span>
            </div>

            <div onClick={() => history.push(account)} className="footerBtn">
              <RiAccountPinCircleLine />
              <span>Account</span>
            </div>
      </>
    );
}
export default MobileQuoteHistory

