import './TitleText.css'
function TitleText({head, subHead, subHead2,subHead3,subHead4,onClick, margin_base}) {
    return (
        <div className='titleText' onClick={onClick} style={{ marginBottom: margin_base}}>
            <div style={{fontSize:'17px', fontWeight:'700'}}>{head}</div>
            <div style={{fontSize:'14px', fontWeight:'500', lineHeight:'15px'}}>
                    {subHead} <br/>
                <div style={{fontSize:'14px',marginTop:'10px'}}>
                    {subHead2}
                </div>
                <div style={{fontSize:'12px',marginTop:'0'}}>
                    {subHead3}
                </div>
            </div>
        </div>
    )
}
export default TitleText
