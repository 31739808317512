
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CssBaseline from "@material-ui/core/CssBaseline";
import RegBtn from '../../components/Reg_components/RegBtn';
import RegInput from '../../components/Reg_components/RegInput';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneNumber from '../../components/PhoneNumber';
import { useState } from 'react';
import axios from 'axios';
import TitleText from '../../components/Reg_components/TitleText';
import TopNavigation from '../../components/Reg_components/TopNavigation';
import DialogSimplePop from '../../components/DialogSimplePop';
import { IoWarningOutline } from 'react-icons/io5';
import PrivacyDialog from '../../components/PrivacyDialog';
import LoginTopMenu from '../../components/topNav/LoginTopMenu';
import {parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'
import Swal from 'sweetalert2'
import {useHistory} from 'react-router-dom';
import { useContext} from 'react'
import { AuthContext } from '../../context/AuthContext';
import SecureLS from 'secure-ls';
import { ConnContext } from '../../context/ConnContext';
import GlobalizationLanguage from '../../components/GlobalizationLanguage';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
 
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(0, 0, 4),
    backgroundColor:'var(--main)',
    color:'white',
    fontWeight:'600'
  },
  formLabel: {
    fontWeight:'600',
    fontSize:'14px',
  },
  itemGrid:{
    marginBottom:'5px'
  },
  input:{
    borderColor: "var(--main)",
    marginTop:'7px',
  }
}));

export default function RegFormAgent() {
  var timeout = GlobalizationLanguage("/timeout")

  const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

  const history = useHistory();
  const auth = useContext(AuthContext);

  const {visitingCountryCode} = useContext(ConnContext)
const [phone_num, setPhone_num] = useState('');
const [isloading, setisloading] = useState(false);
const [showAlertAgent, setshowAlertAgent] = useState(false)
const [policyShow, setPolicyShow] = useState(false);
const [pageToShow, setpageToShow] = useState('')

  const schema = yup.object().shape({
    first_name: yup.string().required("Firstname is required").min(3, "Can't be lesser than 3 digits").max(20, "Can't exceed 20 digits").matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
    last_name: yup.string().required("Lastname is required").min(3, "Can't be lesser than 3 digits").max(20, "Can't exceed 20 digits").matches(/^[aA-zZ\s]+$/, "Can't contain number or special character"),
    // email: yup.string().email("Valid email is required").required("Email is required"),
    email: yup
    .string()
    .matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/, "Valid email is required")
    .required("Email is required"),
  })

  const { control, handleSubmit,formState: { errors, isValid },getValues } = useForm({
      mode: 'all',
    resolver: yupResolver(schema),
  });

  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers:{
       Authorization:  ls.get('_nodi')
    }
})

  const submitForm = async (data) => {
    if(phone_num === undefined){
      Swal.fire({
        icon:'info',
        text:'Phone number is required',
        timer:2500,
        showConfirmButton: false,
        iconColor:'var(--main)'
      })
    }
    else if(!isValidPhoneNumber(phone_num)){
      Swal.fire({
        icon:'info',
        text:'Invalid phone number',
        timer:2500,
        showConfirmButton: false,
        iconColor:'var(--main)'
      })
    }
    else{
      setisloading(true)
        data.action_type = "create";
        data.role = "corp_last_mile_ops"
        data.phone_number = parsePhoneNumber(phone_num).number;
        data.email = data.email.toLowerCase()
        data.vcc = visitingCountryCode
       
        authAxios.post(`m-dillivry-user-manager`,
          data, {timeout: 240000}
        )
        .then((res)=>{
          setisloading(false)
          setshowAlertAgent(true)
        })
          .catch((error)=>{
            if (error.code === "ECONNABORTED") {
              console.log('internal-server-error-timeout')
              history.push(timeout)
              // window.location.reload()
            }
            if(error?.response?.status === 400){
              Swal.fire({
                icon:'question',
                text: 'Username/email already exist',
                timer: 3000,
                showConfirmButton: false
              })
            }
            else if(error?.response?.status === 403){
              Swal.fire({
                  icon: 'warning',
                  title: 'Session!',
                  text: 'Session expired login again!',
                  allowOutsideClick: false,
                  confirmButtonColor: 'var(--main)'
              })
              .then((result) => {
                  if (result.value) {
                      auth.logout();
                      history.push(`/login`);
                  }
              });
            }
            else{
              Swal.fire({
                icon:'error',
                text: 'An error occured, be sure you are connected to internet',
                timer: 3000,
                showConfirmButton: false
              })
            }
            console.log(error)
              setisloading(false) 
          })
        }
  };

  const classes = useStyles();

  return (
    <div className="registration">
      < div className="topDesktop">
          <LoginTopMenu/>  
        </div>
        <div className="afterReg">
      <div className="pageTitle">
        <div className="pageTag">
            Agent Registration
        </div>
        <div className="pageSubtage">
            Required steps.
        </div>
        <div className="pageSubtage">
        Here's what you need to create an agent account.
        </div>
      </div>
      <div className="formSide">

        <div className="regCont">
        <TopNavigation help='.' onBackClick={()=> window.location.reload()}/>
        <TitleText head='Agent Registration' 
            subHead='Required steps.' 
            subHead2="Here's what you need to create an agent account"
            margin_base="40px" />
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <form
                className={classes.form}
                onSubmit={handleSubmit(submitForm)}
                noValidate
              >
                <Grid container spacing={2}>
                  <Grid className={classes.itemGrid} item xs={6} sm={6}>
                    <Controller
                      name="first_name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <RegInput
                          inputProps={{
                            autocomplete: 'new-password',
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                          {...field}
                          htmlFor="first_name"
                          name="first_name"
                          txt="First name"
                          id="first_name"
                          placeholder="First name"
                          autoFocus
                          type="text"
                          size="small"
                          error={errors.first_name}
                        />
                      )}
                    />
                    <div className="err">
                      {errors.first_name && errors.first_name?.message}
                    </div>
                  </Grid>
                  <Grid className={classes.itemGrid} item xs={6} sm={6}>
                    <Controller
                      name="last_name"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <RegInput
                          inputProps={{
                            autocomplete: 'new-password',
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                          {...field}
                          htmlFor="last_name"
                          name="last_name"
                          id="last_name"
                          txt="Last name"
                          placeholder="Last name"
                          type="text"
                          size="small"
                          error={errors.last_name}
                        />
                      )}
                    />
                    <div className="err">
                      {errors.last_name && errors.last_name?.message}
                    </div>
                  </Grid>

                  <Grid className={classes.itemGrid} item xs={12}>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <RegInput
                        inputProps={{
                          autocomplete: 'new-password',
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                          {...field}
                          htmlFor="email"
                          name="email"
                          id="email"
                          txt="Email address"
                          placeholder="Email address"
                          type="email"
                          size="small"
                        />
                      )}
                    />
                    <div className="err">
                      {errors.email && errors.email?.message}
                    </div>
                  </Grid>

                  <Grid className={classes.itemGrid} item xs={12}>
                    <div style={{ fontWeight: "600", fontSize: "14px" }}>
                      Phone Number
                    </div>
                    <PhoneNumber value={phone_num} setValue={setPhone_num} />
                  </Grid>

                  <Grid item xs={12}>
                    <div style={{ fontSize: "10px", margin: "10px 0" }}>
                      By proceeding i agree to Dillivry
                      <span style={{ color: "#004DC1", cursor: "pointer"}} onClick={()=>{setPolicyShow(true); setpageToShow('terms')}}>
                        {" "}
                        Terms of Use{" "}
                      </span>
                      and acknowledge that i have read the <span onClick={()=>{setPolicyShow(true); setpageToShow('privacy')}} style={{color:'#004DC1', cursor:'pointer'}}> Privacy Policy. </span>  
                    </div>
                   
                  </Grid>
                </Grid>

                <RegBtn loading={isloading} txt="Invite Agent" type="submit" />
                <div style={{ marginBottom: "10px" }}></div>
              </form>
            </div>
          </Container>
        </div>
      </div>
      <DialogSimplePop
          openPopup={showAlertAgent} 
          btnText='Ok'
          onClick={()=> window.location.reload()}
      >
          <div style={{width:'100%',justifyContent:'center',alignItems:'center', display:'flex', flexDirection:'column', gap:'20px'}}>
              <IoWarningOutline size='50' color='#ffbf00'/>
              <div style={{textAlign:'center', fontSize:'16px', fontWeight:'500'}}>Invitation message to register and activate mobile phone has been sent to your agent. You might want to inform the agent to speed things up.</div>
          </div>
      </DialogSimplePop>

      <PrivacyDialog
        open={policyShow}
        onClick={()=>setPolicyShow(false)}
        pageToShow={pageToShow}
        title={pageToShow === 'terms' ? "Terms and condition" : "Privacy policy"}
      >
    </PrivacyDialog>
    </div>
    </div>
  );
}


