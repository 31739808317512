import React, {useContext, useState,forwardRef, useImperativeHandle, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {BiHomeCircle, BiCodeCurly, BiHelpCircle } from 'react-icons/bi';
import { GoGear } from 'react-icons/go';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { IoMdMenu } from "react-icons/io";
import { useHistory } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";
import HomeHelp from '../HomeHelp';
import {Link} from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'
import SecureLS from 'secure-ls';
import Dropdowncountries from '../DropdownCountries';
import DropdownComponent from '../DropdownComponent';
import { ConnContext } from "../../context/ConnContext"

import GlobalizationLanguage from '../GlobalizationLanguage';
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  home: {
    "&:hover":{
      color: "#73af46"
    }
  },
  search: {
    "&:hover":{
      color: "#73af46"
    }
  },
  how: {
      "&:hover":{
        color: "#73af46"
      }
  },
  api: {
    "&:hover":{
      color: "#73af46"
    }
  },
  help: {
    "&:hover":{
      color: "#73af46"
    }
  },
  get: {
    background: "#50b14a",
    width: "160px",
    height: "35px",
    padding: "0 10px",
    borderRadius: "3px",
    cursor: "pointer",
    border: "hidden",
    outline: "none",
    color: "white",
    marginLeft: "50px",
    "&:hover":{
     background: "#73af46",
    }
  },
  signup: {
    background: "#50b14a",
    height: "35px",
    width:'180px',
    borderRadius: "3px",
    cursor: "pointer",
    border: "hidden",
    outline: "none",
    color: "white",
    marginLeft: "50px",
    "&:hover":{
      background: "#73af46"
    }
  },
  login: {
    background: "#50b14a",
    width: "160px",
    height: "35px",
    padding: "0 10px",
    borderRadius: "3px",
    cursor: "pointer",
    border: "hidden",
    outline: "none",
    color: "white",
    marginLeft: "50px",
    "&:hover":{
      background: "#73af46"
    }
  },
});

export default function TemporaryDrawer({ ref, clickApi }) {
  const login = GlobalizationLanguage("/login")
  const Dashboard = GlobalizationLanguage("/Dashboard")
  const open_deliveries=GlobalizationLanguage(`/open-deliveries`)
  var sender_signup = GlobalizationLanguage("/sender-signup")
  var viewQuote = GlobalizationLanguage("/viewQuote")

  useImperativeHandle(ref, () => ({
    refreshMe() {
      setrefresh(!refresh)
    }
  }));

  const auth = useContext(AuthContext);

  const logMeOut = () => {
    auth.logout();
    history.push('/')
}

  const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

  const history = useHistory();
  const [showHelp, setShowHelp] = useState(false);
  const [help, setHelp] = useState(false);
  const [searchdeliveries, setSearchDeliveries] = useState(false)
  const [getquote, setGetquote] = useState(false)
  const authContext = useContext(AuthContext)

  const [loggedIn, setLogggedIn] = useState(false);
  const [type, setType] = useState('')
  const [refresh, setrefresh] = useState(false)

  useEffect(() => {
    if(ls.get('eid') === ""){
      setLogggedIn(false)
    }
    else{
      const acc_type = ls.get('_iRl');
        if(acc_type === 'individual'){
          setType('individual')
        }
        else if(acc_type === 'dillivry-sole-trader-ops'){
          setType('individual_provider')
          setLogggedIn(true)
        }
        else{setType('business')}
          setLogggedIn(true)
    }
  }, [refresh])
  const showHelped = () => {
    setShowHelp(!showHelp)
  }

  const showSearchDeliveries = () => {
    setSearchDeliveries(true);
    history.push(open_deliveries)
  }

  const showLogin = () => {
    // setLogin(true);
    history.push(login)
  }

  const getQuote = () => {
    setGetquote(true);
    history.push(sender_signup)
  }

  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      
    >
      <List style={{ marginTop: "30px"}}>
        <ListItem 
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}>
          <ListItemIcon><BiHomeCircle /></ListItemIcon>
          <ListItemText className={classes.home} onClick={()=>history.push("/")} style={{cursor:"pointer"}}>Home</ListItemText>
        </ListItem>

        <ListItem
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}>
          <ListItemIcon><BiHelpCircle /></ListItemIcon>
          <ListItemText className={classes.help} onClick={showHelped} style={{cursor:"pointer"}}>Help</ListItemText>
        </ListItem>

        <ListItem 
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}>
          <ListItemIcon><GoGear /></ListItemIcon>
          {/* <Link to="/" style={{color:"black", textDecoration:"none"}} > */}
              <ListItemText className={classes.how} onClick={()=>{
                history.push({
                  pathname: "/",
                  state: 'how'})
                }} 
              style={{cursor:"pointer"}}>
                How it works
              </ListItemText>
          {/* </Link> */}
        </ListItem>

        <ListItem 
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}>
          <ListItemIcon><BiCodeCurly /></ListItemIcon>
          {/* <Link to="/" style={{textDecoration:"none",color:"black"}} > */}
            <ListItemText className={classes.api} onClick={()=>{
              history.push({
                pathname: "/",
                state: 'api'})
            }} style={{cursor:"pointer"}}>
              API
           </ListItemText>
          {/* </Link> */}
        </ListItem>

        <ListItem
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}>
          <ListItemIcon><AiOutlineFileSearch /></ListItemIcon>
          <ListItemText className={classes.search} onClick={showSearchDeliveries} style={{cursor:"pointer"}}>Open deliveries</ListItemText>
        </ListItem>
      </List>
      <Divider />

      <List>
        <ListItem className={classes.get}>
          <ListItemText onClick={getQuote} style={{ display:'flex', alignItems:'center', justifyContent:'center' }}>
          Senders registration
          </ListItemText>
        </ListItem>
      </List>

      {/* <List>
        <ListItem className={classes.signup}>
          <ListItemText onClick={()=>history.push('/logistics-companies')} style={{ display:'flex', alignItems:'center' }}>
            Signup as corporate
            </ListItemText>
        </ListItem>
      </List> */}
      <List>
          <ListItem>
            <ListItemText className={classes.signup} style={{marginLeft:'34px',padding:'0', background:'var(--main)',borderRadius:'none'}} >
            <DropdownComponent text="Provider sign up" color="white" bgColor="var(--main)" Size="small" style={{textTransform:'none', fontSize:'14px', padding:'0'}}/>
            </ListItemText>
          </ListItem>
        </List>

      {/* <List>
        <ListItem className={classes.signup}>
          <ListItemText onClick={()=>history.push('/riders-and-drivers')} style={{ display:'flex', alignItems:'center' }}>
            Signup as individual
            </ListItemText>
        </ListItem>
      </List> */}

      {
          loggedIn ?
          
        <>
        {
          type !== 'individual_provider' ?
            <Link to={type === 'individual' ?  viewQuote : authContext.isAuth ? type ==='individual_provider' ?  GlobalizationLanguage(`/open-deliveries`) : Dashboard : login}>
              <List>
                <ListItem className={classes.login}>
                  <ListItemText style={{ textAlign:"center"}}>
                    My Dillvry
                  </ListItemText>
                </ListItem>
              </List>
            </Link>
            :
            <>
                <List>
                <ListItem className={classes.login}>
                  <ListItemText onClick={logMeOut} style={{ textAlign:"center"}}>
                    Logout
                  </ListItemText>
                </ListItem>
              </List>
            </>
        } 
        </>
          :
          <List>
            <ListItem className={classes.login}>
              <ListItemText onClick={showLogin} style={{ textAlign:"center"}}>
                Login
              </ListItemText>
            </ListItem>
          </List>
        }
        <List>
          <ListItem>
            <ListItemText >
            <Dropdowncountries/>
            </ListItemText>
          </ListItem>
        </List>
        

    </div>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <IoMdMenu onClick={toggleDrawer(anchor, true)}
            style={{ fontSize: "30px", marginLeft: "20px", marginTop: "20px" }}>
            {anchor}
          </IoMdMenu>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      {
        showHelp ?
          <HomeHelp showDialog={showHelp} onClose={() => setShowHelp(false)} />
          :
          null
      }
    </div>
  );
}
