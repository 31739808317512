import React, { useContext,useEffect, useState } from "react";
import { Link, withRouter, Route  } from "react-router-dom";
import { useHistory, useLocation } from 'react-router-dom';
import People from '../../../../assets/people.svg'
import settings2 from '../../../../assets/setting2.svg'
import setting1 from '../../../../assets/setting1.svg'
import dillivrylogo from '../../../Sender/SenderDashboard/Assets/dillivrylogo.svg'
import watch from '../../../../assets/Watch.svg'
import Watch_active from '../../../../assets/Watch_active.svg'
import dash1 from '../../../../assets/dash1.svg'
import dash2 from '../../../../assets/dash2.svg'
import mydill1 from '../../../../assets/mydill1.svg'
import mydill2 from '../../../../assets/mydill2.svg'
import open1 from '../../../../assets/open1.svg'
import create1 from '../../../../assets/create1.svg'
import create2 from '../../../../assets/create2.svg'
import help1 from '../../../../assets/help1.svg'
import messag1 from '../../../../assets/messag1.svg'
import messag2 from '../../../../assets/messag2.svg'
import acct1 from '../../../../assets/acct1.svg'
import wallet1 from '../../../../assets/wallet1.svg'
import wallet2 from '../../../../assets/wallet2.svg'
import referralclicked from '../../../../assets/referralclicked.svg'
import referarl from '../../../../assets/referarl.svg'
import GlobalizationLanguage, {GlobalizationLanguagewithCOntryCode} from '../../../../components/GlobalizationLanguage'
import Cookies from "js-cookie";
import { ConnContext } from "../../../../context/ConnContext";


function CorpSidebar() {
  const { visitingCountryCode } = useContext(ConnContext);

  var countryvalue = Cookies.get('i18next') || visitingCountryCode.toLowerCase()

  useEffect(() => {
      setCountry(Cookies.get('i18next'));
    },[Cookies.get('i18next')])

  const [country,setCountry]=useState(countryvalue);


  const location = useLocation();
  const history = useHistory();

  const QuoteData =  [
      {
        path: GlobalizationLanguage("/Dashboard"),
        name: "Dashboard",
        icon: dash1,
        iconclicked: dash2,
      },
      {
        // path: "/activedilivry/awaitingquote",
        path: GlobalizationLanguage("/MyDillivry"),
        name: "My dillivry",
        icon: mydill1,
        iconclicked: mydill2
      },
      {
       
        path: GlobalizationLanguage("/open-deliveries"),
        name: "Open deliveries",
        icon:   open1,
        iconclicked: open1
      },
      {
        path: GlobalizationLanguage("/CreateDillivry") ,
        name: "Create delivery",
        icon: create1,
        iconclicked: create2
      },
      
      {
        path: GlobalizationLanguage("/corpwallet"),
        name: "Wallet",
        icon: wallet1,
        iconclicked: wallet2

      },
      {
        path: GlobalizationLanguage("/Messages"),
        name: "Messages",
        icon: messag1,
        iconclicked: messag2
      },
      {
        path: GlobalizationLanguage("/watch-list"),
        name: "Watch list",
        icon: watch,
        iconclicked: Watch_active
      },
      {
        path: GlobalizationLanguage("/corpreferrals"),
        name: "Referrals",
        icon: referarl,
        iconclicked: referralclicked

      },
      
      
      {
        path: GlobalizationLanguage("/account"),
        name: "Account",
        icon: acct1,
        
        iconclicked: acct1
      },
      {
        path: GlobalizationLanguage("/How-it-works"),
        name: "How it works",
        icon: setting1,
        iconclicked: settings2
      },
      {
        path: GlobalizationLanguage("/Settings"),
        name: "Settings",
        icon: setting1,
        iconclicked: settings2
      },
        {
        path: GlobalizationLanguage("/contact-us"),
        name: "Help",
        icon: help1,
        iconclicked: help1
      },
    ]
  
  return (
    <>
      <div className="sidemenudetails" style={{paddingTop:"15px"}}>
       <div style={{display: "flex", flexDirection: "column",gap:'7px' }}  >
        {
          QuoteData.map((x, i)=>{
            return ( 
              <div style={{marginTop:'0px', cursor: 'pointer',  userSelect: "none"}} key={i}>
              <div 
                onClick={()=> history.push(`${x.path}`)}
                className="image-contentx" 
                >
                    {
                      location.pathname === x.path ? <img src={x.iconclicked} alt="iconimage" width='25px' className="side-img" /> :  <img src={x.icon} width='25px' alt="iconimage" className="side-img" />
                    }
                  <p className={location.pathname === x.path  ? 'active4 ': 'inactive3' }>{x.name}</p>
                </div>
              </div>
            )
          })
        }
       </div>
        <div className="bottom-logo" onClick={()=>history.push(GlobalizationLanguagewithCOntryCode("/", country, visitingCountryCode ))}>
          <img src={dillivrylogo} alt="dillivrylogo" className="bottom-logo-img" />
          
        </div>

      </div>
      
    </>
  );
}
export default CorpSidebar;
