import React, { useEffect, useState } from 'react'
import axios from 'axios';
import SecureLS from 'secure-ls';

const OTPCountDown = (props) => {
    const {type}=props
    const {initialMinute = 0,initialSeconds = 0} = props;
    const [ minutes, setMinutes ] = useState(initialMinute);
    const [seconds, setSeconds ] =  useState(initialSeconds);
    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
    console.log("type",type)
    useEffect(()=>{
    let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            } 
        }, 1000)
        return ()=> {
            clearInterval(myInterval);
          };
    });

    const Resend = ()=>{

        if (type === "sender"){
            axios.post(`${process.env.REACT_APP_API}m-generate-otp?user_type=user$user_id=${props.user_id}`)
            .then((response)=>{
                setSeconds("45")
            })
            .catch((error)=>{
                console.log(error)
            })
        }
        else{
            axios.post(`${process.env.REACT_APP_API}m-generate-otp?user_type=fulfiller&fulfiller_id=${ls.get('_tyf_')}`)
            .then((response)=>{
                setSeconds("45")
            })
            .catch((error)=>{
                console.log(error)
            })
        }
    }

    return (
        minutes === 0 && seconds === 0 
            ? <>Didn’t get the code yet?<br/><br/>
            <span style={{color:"#50B14A",cursor:"default"}} onClick={()=>Resend()}>Resend OTP</span></>
            :  `Resend code in ${minutes}:${seconds < 10 ?  `0${seconds}` : seconds}` 
    )
}

export default OTPCountDown;