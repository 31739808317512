import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { IoMdMenu } from "react-icons/io";
import { useHistory, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'
import SecureLS from 'secure-ls';
import DropdownComponent from '../DropdownComponent';
import Dropdowncountries from '../DropdownCountries';
import { AiFillCar, AiFillHome, AiFillInteraction } from 'react-icons/ai';
import Dillivry from "../../assets/Dillivry.png";
import { RiEBike2Fill } from 'react-icons/ri';
import { MdBusAlert } from 'react-icons/md';
import { FaQuestionCircle } from 'react-icons/fa';
import { ConnContext } from "../../context/ConnContext"

import GlobalizationLanguage from '../GlobalizationLanguage';
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  links: {
    "&:hover": {
      color: "#73af46"
    }
  },

  login: {
    background: "white",
    width: "182px",
    height: "35px",
    padding: "0 10px",
    borderRadius: "20px",
    cursor: "pointer",
    border: "1px solid var(--main)",
    outline: "none",
    color: "var(--main)",
    marginLeft: "50px",
    "&:hover": {
      background: "var(--main)",
      color: "white",
    }
  },
});

export default function SideBar() {
  const Dashboard = GlobalizationLanguage("/Dashboard")
  const login = GlobalizationLanguage("/login")
  var viewQuote = GlobalizationLanguage("/viewQuote")

  const auth = useContext(AuthContext);

  const logMeOut = () => {
    auth.logout();
    history.push('/')
    window.location.reload()

  }

  const history = useHistory();
  const location = useLocation();

  const authContext = useContext(AuthContext)
  const [loggedIn, setLogggedIn] = useState(false);
  const [type, setType] = useState('')

  const ls = new SecureLS({ isCompression: true, encodingType: 'rabbit', encryptionSecret: 'dm_dillivry' });

  useEffect(() => {
    if (ls.get('eid') === "") {
      setLogggedIn(false)
    }
    else {
      const acc_type = ls.get('_iRl');
      if (acc_type === 'individual') {
        setType('individual')
      }
      else if (acc_type === 'dillivry-sole-trader-ops') {
        setType('individual_provider')
        setLogggedIn(true)
      }
      else { setType('business') }
      setLogggedIn(true)
    }
  }, [])



  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <List style={{ marginTop: "30px" }}>
        <Link to={GlobalizationLanguage(`/`)}>
          <ListItem
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}>
            <ListItemIcon><AiFillHome /></ListItemIcon>
            <ListItemText className={classes.links} style={{ cursor: "pointer", color: location.pathname === GlobalizationLanguage("/") ? "#50B14A" : "#484848" }}>Home</ListItemText>
          </ListItem>
        </Link>
        <Link to={GlobalizationLanguage(`/riders-and-drivers`)}>
          <ListItem
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}>
            <ListItemIcon><RiEBike2Fill /></ListItemIcon>
            <ListItemText className={classes.links} style={{ cursor: "pointer", color: location.pathname === GlobalizationLanguage(`/riders-and-drivers`) ? "#50B14A" : "#484848" }}>Riders</ListItemText>
          </ListItem>
        </Link>

        <Link to={GlobalizationLanguage(`/riders-and-drivers`)}>
          <ListItem
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}>
            <ListItemIcon><AiFillCar /></ListItemIcon>
            <ListItemText className={classes.links} style={{ cursor: "pointer", color: location.pathname === GlobalizationLanguage(`/riders-and-drivers`) ? "#50B14A" : "#484848" }}> Drivers </ListItemText>
          </ListItem>
        </Link>

        <Link to={GlobalizationLanguage(`/logistics-companies`)}>
          <ListItem
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}>
            <ListItemIcon><MdBusAlert /></ListItemIcon>
            <ListItemText className={classes.links} style={{ cursor: "pointer", color: location.pathname === GlobalizationLanguage(`/logistics-companies`) ? "#50B14A" : "#484848" }}> Logistic companies </ListItemText>
          </ListItem>
        </Link>

        <Link to={GlobalizationLanguage(`/how-it-works`)}>
          <ListItem>
            <ListItemIcon><FaQuestionCircle /></ListItemIcon>
            <ListItemText className={classes.links} style={{ cursor: "pointer", color: location.pathname === GlobalizationLanguage(`/how-it-works`) ? "#50B14A" : "#484848" }}>How it works</ListItemText>
          </ListItem>
        </Link>

        <Link to={GlobalizationLanguage(`/open-deliveries`)}>
          <ListItem>
            <ListItemIcon><AiFillInteraction /></ListItemIcon>
            <ListItemText className={classes.links} style={{ cursor: "pointer", color: location.pathname === GlobalizationLanguage(`/open-deliveries`) ? "#50B14A" : "#484848" }}>Open Deliveries</ListItemText>
          </ListItem>
        </Link>
      </List>

      <Divider />
      {
        loggedIn ?
          <></>
          :
          <List>
            <ListItem>
              <ListItemText style={{ marginLeft: '34px', padding: '0', borderRadius: 'none' }} >
                <DropdownComponent text="Create account" outline="white" color="white" bgColor="var(--main)" Size="small" style={{ textTransform: 'none', fontSize: '14px', padding: '0' }} />
              </ListItemText>
            </ListItem>
          </List>
      }

      {
        loggedIn ?
          <>
            {
              type !== 'individual_provider' ?
                <Link to={type === 'individual' ? viewQuote : authContext.isAuth ? type === 'individual_provider' ? GlobalizationLanguage(`/open-deliveries`) : Dashboard : login}>
                  <List>
                    <ListItem className={classes.login}>
                      <ListItemText style={{ textAlign: "center" }}>
                        My Dillvry
                      </ListItemText>
                    </ListItem>
                  </List>
                </Link>
                :
                <>
                  <ListItem className={classes.login}>
                    <ListItemText onClick={logMeOut} style={{ textAlign: "center" }}>
                      Logout
                    </ListItemText>
                  </ListItem>
                </>
            }
          </>
          :
          <List>
            <ListItem className={classes.login}>
              <ListItemText
                onClick={() => history.push(login)}
                style={{ textAlign: "center" }}>
                Login
              </ListItemText>
            </ListItem>
          </List>
      }
      <List>
        <ListItem>
          <ListItemIcon></ListItemIcon>
          <ListItemText >
            <Dropdowncountries />
          </ListItemText>
        </ListItem>
      </List>

      {/* <Dropdowncountries/> */}
    </div>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className='sideBarDrawer'>

            <div className='dilliLogo' onClick={() => history.push('/')}>
              <img src={Dillivry} alt="Dillivry logo" />
            </div>
            <IoMdMenu onClick={toggleDrawer(anchor, true)}
              style={{ fontSize: "30px" }}>
              {anchor}
            </IoMdMenu>
          </div>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
