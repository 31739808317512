import React, {useContext,useEffect, useState} from 'react'
import './Settlement.css'
import {useHistory} from 'react-router-dom';
import { AuthContext } from '../../../context/AuthContext';
import DialogOutClick from '../../../components/DialogOutClick';
import { BsArrowRight, BsArrowUp, BsBarChartFill, BsFillQuestionCircleFill, BsGraphUp } from 'react-icons/bs';
import { AiFillPieChart } from 'react-icons/ai';
import { HiArrowDown, HiArrowUp } from 'react-icons/hi';
import LineChart from '../../../components/SettlementCharts/LineChart';
import BarGraph from '../../../components/SettlementCharts/BarChart';
import PieGraph from '../../../components/SettlementCharts/PieChart';
import { Grid } from '@material-ui/core';
import moment from 'moment'
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { ImEqualizer2 } from 'react-icons/im';
import{DashboardContext}  from '../../../context/Dashboard/DashboardProvider/dashboard.provider'
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Swal from 'sweetalert2';
import axios from 'axios';
import SecureLS from 'secure-ls';
import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
function Settlement() { 
    var login=GlobalizationLanguage("/login")

    const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});

    const history = useHistory()

    const {dashboards, updateDashboard} = useContext(DashboardContext)
    
    const [quoted, setQuoted] = useState([])
    const [bookedDelivery, setBookedDelivery] = useState([])
 
    const [loadingData, setloadingData] = useState(false)

    const authAxios = axios.create({
        baseURL: `${process.env.REACT_APP_API}`,
        headers:{
           Authorization:  ls.get('_nodi')
        }
    })

    useEffect(() => {
        if (dashboards !== undefined) {
            setQuoted(dashboards.data.transaction_details.transaction_details);
            setBookedDelivery(dashboards.data.booked_transaction_details.booked_transaction_details);
            setall([...dashboards.data.transaction_details.transaction_details, ...dashboards.data.booked_transaction_details.booked_transaction_details])
            updatePage(deliveryType, dashboards.data.transaction_details.transaction_details)
        }
        else{
            setloadingData(true)
            authAxios.get(`m-dillivry-dashboard-manager`)
            .then((response) => {
                ls.set(
                    "means_of_delivery",
                    JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                  );
                  
                setloadingData(false)
                updateDashboard(response);
                
                setQuoted(response.data.transaction_details.transaction_details);
                setBookedDelivery(response.data.booked_transaction_details.booked_transaction_details);
                setall([...response.data.transaction_details.transaction_details, ...dashboards.data.booked_transaction_details.booked_transaction_details])
                updatePage(deliveryType, response.data.transaction_details.transaction_details)

            })
            .catch((err)=>{
                setloadingData(false)
                if (err?.response?.status === 403) {
                    Swal.fire({
                      icon: "warning",
                      title: "Session!",
                      text: "Session expired login again!",
                      allowOutsideClick: false,
                      confirmButtonColor: "var(--main)",
                    }).then((result) => {
                      if (result.value) {
                        auth.logout();
                        history.push(login);
                      }
                    });
                } 
            })
        }
    }, [])
    
 
    const [percent, setpercent] = useState(0)
    const [percentBooked, setpercentBooked] = useState(0)
    const [highest, sethighest] = useState('')
    const [highestBooked, sethighestBooked] = useState('')

    const [all, setall] = useState([])
    const [startRange, setstartRange] = useState()
    const [endRange, setendRange] = useState()

    const [LastMonthQuote, setLastMonthQuote] = useState(0)
    const [LastMonthBooked, setLastMonthBooked] = useState(0)


    const [LastQuarterQuote, setLastQuarterQuote] = useState(0)
    const [LastQuarterBooked, setLastQuarterBooked] = useState(0)

    const [lastQuarterStartDate, setlastQuarterStartDate] = useState('')
    const [lastQuarterEndDate, setlastQuarterEndDate] = useState('')
    
    const [LastYearQuote, setLastYearQuote] = useState(0)
    const [LastYearBooked, setLastYearBooked] = useState(0)
 
    const firstDayOfTheYear = new Date(new Date().getFullYear(), 0, 1);
    const lastDayOfTheYear = new Date(new Date().getFullYear(), 11, 31)
    
    const firstDayOfLastYear = new Date(new Date().getFullYear() - 1, 0, 1);
    const lastDayOfLastYear = new Date(new Date().getFullYear() - 1, 11, 31)
 
    var now = new Date();
    var dis_quarter = Math.floor((now.getMonth() / 3));
    var thisQuarterStartDate = new Date(now.getFullYear(), dis_quarter * 3, 1);
    var thisQuarterEndDate = new Date(thisQuarterStartDate.getFullYear(), thisQuarterStartDate.getMonth() + 3, 0);

    const updatePage = (deliveryT, quoted) =>{

        var current = moment();// get current date
        var first = current.clone().weekday(1) // First day is the day of the month - the day of the week : to get the first day of the week
        var second =  current.clone().weekday(2) //second day of the week
        var third = current.clone().weekday(3) //third day of the week
        var fourth = current.clone().weekday(4) //fourth day of the week
        var fifth = current.clone().weekday(5) //fifth day of the week
        var sixth = current.clone().weekday(6) //sixth day of the week
        var last = current.clone().weekday(7) // last day is the first day + 6
        
        var first_last_week = current.clone().weekday(-6);
        var second_last_week =  current.clone().weekday(-5)
        var third_last_week = current.clone().weekday(-4)
        var fourth_last_week = current.clone().weekday(-3) 
        var fifth_last_week = current.clone().weekday(-2)
        var sixth_last_week = current.clone().weekday(-1)
        var last_last_week = current.clone().weekday(0)

        setfirstday_lastweek(first_last_week);
        setlastday_lastweek(last_last_week);

        //set first day of the week and the last day of the week in a state
        setfirstday(first);
        setlastday(last);

        setstartRange(first)
        setendRange(last)
       
        // ***** quoted delivery start here *****//

        // filter the data coming to get item equal to each day of the week
        const mondayPay = quoted.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(first)})
        const tuesdayPay = quoted.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(second)})
        const wednesdayPay = quoted.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(third)})
        const thursdayPay = quoted.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(fourth)})
        const fridayPay = quoted.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(fifth)})
        const saturdayPay = quoted.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(sixth)})
        const sundayPay = quoted.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(last)})

        // get amount due to the provider for each day in an array
        const arrMondayPay = mondayPay.map(a => a.amount_due_to_fulfiller);
        const arrTuesdayPay = tuesdayPay.map(a => a.amount_due_to_fulfiller);
        const arrWednesdayPay = wednesdayPay.map(a => a.amount_due_to_fulfiller);
        const arrThurdayPay = thursdayPay.map(a => a.amount_due_to_fulfiller);
        const arrFridayPay = fridayPay.map(a => a.amount_due_to_fulfiller);
        const arrSaturdayPay = saturdayPay.map(a => a.amount_due_to_fulfiller);
        const arrSundayPay = sundayPay.map(a => a.amount_due_to_fulfiller);
        
        const arrMondayPayToP = mondayPay.map(a => a.amount_due_to_platform);
        const arrTuesdayPayToP = tuesdayPay.map(a => a.amount_due_to_platform);
        const arrWednesdayPayToP = wednesdayPay.map(a => a.amount_due_to_platform);
        const arrThurdayPayToP = thursdayPay.map(a => a.amount_due_to_platform);
        const arrFridayPayToP = fridayPay.map(a => a.amount_due_to_platform);
        const arrSaturdayPayToP = saturdayPay.map(a => a.amount_due_to_platform);
        const arrSundayPayToP = sundayPay.map(a => a.amount_due_to_platform);
        
        const arrMondayPayGro = mondayPay.map(a => a.gross_amount);
        const arrTuesdayPayGro = tuesdayPay.map(a => a.gross_amount);
        const arrWednesdayPayGro = wednesdayPay.map(a => a.gross_amount);
        const arrThurdayPayGro = thursdayPay.map(a => a.gross_amount);
        const arrFridayPayGro = fridayPay.map(a => a.gross_amount);
        const arrSaturdayPayGro = saturdayPay.map(a => a.gross_amount);
        const arrSundayPayGro = sundayPay.map(a => a.gross_amount);
       
        // a function to convert array of string to array of number
        const toNumbers = arr => arr.map(Number);
            
        // a function to sum array of number together
        // [1,2,3,4].reduce((a, b) => a + b, 0)

        //sum up all amount for each day of the week and save it in a state
        setMonday(toNumbers(arrMondayPay).reduce((a, b) => a + b, 0))
        setTuesday(toNumbers(arrTuesdayPay).reduce((a, b) => a + b, 0))
        setWednesday(toNumbers(arrWednesdayPay).reduce((a, b) => a + b, 0))
        setThurday(toNumbers(arrThurdayPay).reduce((a, b) => a + b, 0))
        setFriday(toNumbers(arrFridayPay).reduce((a, b) => a + b, 0))
        setSaturday(toNumbers(arrSaturdayPay).reduce((a, b) => a + b, 0))
        setSunday(toNumbers(arrSundayPay).reduce((a, b) => a + b, 0))
       
        // filter the data coming to get item equal to each day of the week
        const mondayPayLast = quoted.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(first_last_week)})
        const tuesdayPayLast = quoted.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(second_last_week)})
        const wednesdayPayLast = quoted.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(third_last_week)})
        const thursdayPayLast = quoted.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(fourth_last_week)})
        const fridayPayLast = quoted.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(fifth_last_week)})
        const saturdayPayLast = quoted.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(sixth_last_week)})
        const sundayPayLast = quoted.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(last_last_week)})

        // get amount due to the provider for each day in an array
        const arrMondayPayLast = mondayPayLast.map(a => a.amount_due_to_fulfiller);
        const arrTuesdayPayLast = tuesdayPayLast.map(a => a.amount_due_to_fulfiller);
        const arrWednesdayPayLast = wednesdayPayLast.map(a => a.amount_due_to_fulfiller);
        const arrThurdayPayLast = thursdayPayLast.map(a => a.amount_due_to_fulfiller);
        const arrFridayPayLast = fridayPayLast.map(a => a.amount_due_to_fulfiller);
        const arrSaturdayPayLast = saturdayPayLast.map(a => a.amount_due_to_fulfiller);
        const arrSundayPayLast = sundayPayLast.map(a => a.amount_due_to_fulfiller);
        
        //sum up all amount for each day of the week and save it in a state
        setMondayLast(toNumbers(arrMondayPayLast).reduce((a, b) => a + b, 0))
        setTuesdayLast(toNumbers(arrTuesdayPayLast).reduce((a, b) => a + b, 0))
        setWednesdayLast(toNumbers(arrWednesdayPayLast).reduce((a, b) => a + b, 0))
        setThurdayLast(toNumbers(arrThurdayPayLast).reduce((a, b) => a + b, 0))
        setFridayLast(toNumbers(arrFridayPayLast).reduce((a, b) => a + b, 0))
        setSaturdayLast(toNumbers(arrSaturdayPayLast).reduce((a, b) => a + b, 0))
        setSundayLast(toNumbers(arrSundayPayLast).reduce((a, b) => a + b, 0))
        // ***** quoted delivery start here *****//


        // ***** booked delivery start here *****//
        const mondayPayBooked = bookedDelivery.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(first)})
        const tuesdayPayBooked = bookedDelivery.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(second)})
        const wednesdayPayBooked = bookedDelivery.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(third)})
        const thursdayPayBooked = bookedDelivery.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(fourth)})
        const fridayPayBooked = bookedDelivery.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(fifth)})
        const saturdayPayBooked = bookedDelivery.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(sixth)})
        const sundayPayBooked = bookedDelivery.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(last)})
        
        const arrMondayPayBooked = mondayPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrTuesdayPayBooked = tuesdayPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrWednesdayPayBooked = wednesdayPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrThurdayPayBooked = thursdayPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrFridayPayBooked = fridayPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrSaturdayPayBooked = saturdayPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrSundayPayBooked = sundayPayBooked.map(a => a.amount_due_to_fulfiller);
           
       setMondayBooked(toNumbers(arrMondayPayBooked).reduce((a, b) => a + b, 0))
       setTuesdayBooked(toNumbers(arrTuesdayPayBooked).reduce((a, b) => a + b, 0))
       setWednesdayBooked(toNumbers(arrWednesdayPayBooked).reduce((a, b) => a + b, 0))
       setThurdayBooked(toNumbers(arrThurdayPayBooked).reduce((a, b) => a + b, 0))
       setFridayBooked(toNumbers(arrFridayPayBooked).reduce((a, b) => a + b, 0))
       setSaturdayBooked(toNumbers(arrSaturdayPayBooked).reduce((a, b) => a + b, 0))
       setSundayBooked(toNumbers(arrSundayPayBooked).reduce((a, b) => a + b, 0))
     
        const mondayPayBookedLast = bookedDelivery.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(first_last_week)})
        const tuesdayPayBookedLast = bookedDelivery.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(second_last_week)})
        const wednesdayPayBookedLast = bookedDelivery.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(third_last_week)})
        const thursdayPayBookedLast = bookedDelivery.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(fourth_last_week)})
        const fridayPayBookedLast = bookedDelivery.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(fifth_last_week)})
        const saturdayPayBookedLast = bookedDelivery.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(sixth_last_week)})
        const sundayPayBookedLast = bookedDelivery.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(last_last_week)})

        const arrMondayPayBookedLast = mondayPayBookedLast.map(a => a.amount_due_to_fulfiller);
        const arrTuesdayPayBookedLast = tuesdayPayBookedLast.map(a => a.amount_due_to_fulfiller);
        const arrWednesdayPayBookedLast = wednesdayPayBookedLast.map(a => a.amount_due_to_fulfiller);
        const arrThurdayPayBookedLast = thursdayPayBookedLast.map(a => a.amount_due_to_fulfiller);
        const arrFridayPayBookedLast = fridayPayBookedLast.map(a => a.amount_due_to_fulfiller);
        const arrSaturdayPayBookedLast = saturdayPayBookedLast.map(a => a.amount_due_to_fulfiller);
        const arrSundayPayBookedLast = sundayPayBookedLast.map(a => a.amount_due_to_fulfiller);
     
        setMondayBookedLast(toNumbers(arrMondayPayBookedLast).reduce((a, b) => a + b, 0))
        setTuesdayBookedLast(toNumbers(arrTuesdayPayBookedLast).reduce((a, b) => a + b, 0))
        setWednesdayBookedLast(toNumbers(arrWednesdayPayBookedLast).reduce((a, b) => a + b, 0))
        setThurdayBookedLast(toNumbers(arrThurdayPayBookedLast).reduce((a, b) => a + b, 0))
        setFridayBookedLast(toNumbers(arrFridayPayBookedLast).reduce((a, b) => a + b, 0))
        setSaturdayBookedLast(toNumbers(arrSaturdayPayBookedLast).reduce((a, b) => a + b, 0))
        setSundayBookedLast(toNumbers(arrSundayPayBookedLast).reduce((a, b) => a + b, 0))
      
        // ***** booked delivery ends here *****//

        // ***** all delivery start here *****//
        const mondayPayAll = all.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(first)})
        const tuesdayPayAll = all.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(second)})
        const wednesdayPayAll = all.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(third)})
        const thursdayPayAll = all.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(fourth)})
        const fridayPayAll = all.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(fifth)})
        const saturdayPayAll = all.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(sixth)})
        const sundayPayAll = all.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(last)})

       const arrMondayPayAll = mondayPayAll.map(a => a.amount_due_to_fulfiller);
       const arrTuesdayPayAll = tuesdayPayAll.map(a => a.amount_due_to_fulfiller);
       const arrWednesdayPayAll = wednesdayPayAll.map(a => a.amount_due_to_fulfiller);
       const arrThurdayPayAll = thursdayPayAll.map(a => a.amount_due_to_fulfiller);
       const arrFridayPayAll = fridayPayAll.map(a => a.amount_due_to_fulfiller);
       const arrSaturdayPayAll = saturdayPayAll.map(a => a.amount_due_to_fulfiller);
       const arrSundayPayAll = sundayPayAll.map(a => a.amount_due_to_fulfiller);
     
       setMondayAll(toNumbers(arrMondayPayAll).reduce((a, b) => a + b, 0))
       setTuesdayAll(toNumbers(arrTuesdayPayAll).reduce((a, b) => a + b, 0))
       setWednesdayAll(toNumbers(arrWednesdayPayAll).reduce((a, b) => a + b, 0))
       setThurdayAll(toNumbers(arrThurdayPayAll).reduce((a, b) => a + b, 0))
       setFridayAll(toNumbers(arrFridayPayAll).reduce((a, b) => a + b, 0))
       setSaturdayAll(toNumbers(arrSaturdayPayAll).reduce((a, b) => a + b, 0))
       setSundayAll(toNumbers(arrSundayPayAll).reduce((a, b) => a + b, 0))
     
        const mondayPayAllLast = all.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(first_last_week)})
        const tuesdayPayAllLast = all.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(second_last_week)})
        const wednesdayPayAllLast = all.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(third_last_week)})
        const thursdayPayAllLast = all.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(fourth_last_week)})
        const fridayPayAllLast = all.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(fifth_last_week)})
        const saturdayPayAllLast = all.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(sixth_last_week)})
        const sundayPayAllLast = all.filter(function (quote) { return formatDate(quote.time_stamp) === formatDate(last_last_week)})

        const arrMondayPayAllLast = mondayPayAllLast.map(a => a.amount_due_to_fulfiller);
        const arrTuesdayPayAllLast = tuesdayPayAllLast.map(a => a.amount_due_to_fulfiller);
        const arrWednesdayPayAllLast = wednesdayPayAllLast.map(a => a.amount_due_to_fulfiller);
        const arrThurdayPayAllLast = thursdayPayAllLast.map(a => a.amount_due_to_fulfiller);
        const arrFridayPayAllLast = fridayPayAllLast.map(a => a.amount_due_to_fulfiller);
        const arrSaturdayPayAllLast = saturdayPayAllLast.map(a => a.amount_due_to_fulfiller);
        const arrSundayPayAllLast = sundayPayAllLast.map(a => a.amount_due_to_fulfiller);
        
        setMondayAllLast(toNumbers(arrMondayPayAllLast).reduce((a, b) => a + b, 0))
        setTuesdayAllLast(toNumbers(arrTuesdayPayAllLast).reduce((a, b) => a + b, 0))
        setWednesdayAllLast(toNumbers(arrWednesdayPayAllLast).reduce((a, b) => a + b, 0))
        setThurdayAllLast(toNumbers(arrThurdayPayAllLast).reduce((a, b) => a + b, 0))
        setFridayAllLast(toNumbers(arrFridayPayAllLast).reduce((a, b) => a + b, 0))
        setSaturdayAllLast(toNumbers(arrSaturdayPayAllLast).reduce((a, b) => a + b, 0))
        setSundayAllLast(toNumbers(arrSundayPayAllLast).reduce((a, b) => a + b, 0))

        const sumForWeek = toNumbers(arrMondayPay).reduce((a, b) => a + b, 0) + 
            toNumbers(arrTuesdayPay).reduce((a, b) => a + b, 0) +
            toNumbers(arrWednesdayPay).reduce((a, b) => a + b, 0) +
            toNumbers(arrThurdayPay).reduce((a, b) => a + b, 0) +
            toNumbers(arrFridayPay).reduce((a, b) => a + b, 0) +
            toNumbers(arrSaturdayPay).reduce((a, b) => a + b, 0) +
            toNumbers(arrSundayPay).reduce((a, b) => a + b, 0)
        
        const sumForLastWeek = toNumbers(arrMondayPayLast).reduce((a, b) => a + b, 0) + 
            toNumbers(arrTuesdayPayLast).reduce((a, b) => a + b, 0) +
            toNumbers(arrWednesdayPayLast).reduce((a, b) => a + b, 0) +
            toNumbers(arrThurdayPayLast).reduce((a, b) => a + b, 0) +
            toNumbers(arrFridayPayLast).reduce((a, b) => a + b, 0) +
            toNumbers(arrSaturdayPayLast).reduce((a, b) => a + b, 0) +
            toNumbers(arrSundayPayLast).reduce((a, b) => a + b, 0)

        if(sumForWeek > sumForLastWeek){
                sethighest('now')
                const percentage = (sumForWeek - sumForLastWeek) / (sumForWeek + sumForLastWeek) * 100
                setpercent(percentage)
            }
        else if (sumForWeek < sumForLastWeek){
                sethighest('last')
                const percentage = (sumForLastWeek - sumForWeek) / (sumForWeek + sumForLastWeek) * 100
                setpercent(percentage)
             }
        else{
                sethighest('equal')
                setpercent(0)
            }
        
        const sumForWeekBooked = toNumbers(arrMondayPayBooked).reduce((a, b) => a + b, 0) + 
            toNumbers(arrTuesdayPayBooked).reduce((a, b) => a + b, 0) +
            toNumbers(arrWednesdayPayBooked).reduce((a, b) => a + b, 0) +
            toNumbers(arrThurdayPayBooked).reduce((a, b) => a + b, 0) +
            toNumbers(arrFridayPayBooked).reduce((a, b) => a + b, 0) +
            toNumbers(arrSaturdayPayBooked).reduce((a, b) => a + b, 0) +
            toNumbers(arrSundayPayBooked).reduce((a, b) => a + b, 0)
        
        const sumForLastWeekBooked = toNumbers(arrMondayPayBookedLast).reduce((a, b) => a + b, 0) + 
            toNumbers(arrTuesdayPayBookedLast).reduce((a, b) => a + b, 0) +
            toNumbers(arrWednesdayPayBookedLast).reduce((a, b) => a + b, 0) +
            toNumbers(arrThurdayPayBookedLast).reduce((a, b) => a + b, 0) +
            toNumbers(arrFridayPayBookedLast).reduce((a, b) => a + b, 0) +
            toNumbers(arrSaturdayPayBookedLast).reduce((a, b) => a + b, 0) +
            toNumbers(arrSundayPayBookedLast).reduce((a, b) => a + b, 0)

        if(sumForWeekBooked > sumForLastWeekBooked){
                sethighestBooked('now')
                const percentage = (sumForWeekBooked - sumForLastWeekBooked) / (sumForWeekBooked + sumForLastWeekBooked) * 100
                setpercentBooked(percentage)
            }
        else if (sumForWeekBooked < sumForLastWeekBooked){
                sethighestBooked('last')
                const percentage = (sumForLastWeekBooked - sumForWeekBooked) / (sumForWeekBooked + sumForLastWeekBooked) * 100
                setpercentBooked(percentage)
             }
        else{
                sethighestBooked('equal')
                setpercentBooked(0)
            }
 
        if (deliveryT === 'quoted_deliveries'){
            setBarTableWeekData([
                {
                    "day" : "Mon",
                    "number_of_deliveries" : arrMondayPay.length,
                    "net" : toNumbers(arrMondayPay).reduce((a, b) => a + b, 0),
                    "platform" : toNumbers(arrMondayPayToP).reduce((a, b) => a + b, 0),
                    'gross' : toNumbers(arrMondayPayGro).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Tues",
                    "number_of_deliveries" : arrTuesdayPay.length,
                    "net" : toNumbers(arrTuesdayPay).reduce((a, b) => a + b, 0),
                    "platform" : toNumbers(arrTuesdayPayToP).reduce((a, b) => a + b, 0),
                    'gross' : toNumbers(arrTuesdayPayGro).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Wed",
                    "number_of_deliveries" : arrWednesdayPay.length,
                    "net" : toNumbers(arrWednesdayPay).reduce((a, b) => a + b, 0),
                    "platform" : toNumbers(arrWednesdayPayToP).reduce((a, b) => a + b, 0),
                    'gross' : toNumbers(arrWednesdayPayGro).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Thur",
                    "number_of_deliveries" : arrThurdayPay.length,
                    "net" : toNumbers(arrThurdayPay).reduce((a, b) => a + b, 0),
                    "platform" : toNumbers(arrThurdayPayToP).reduce((a, b) => a + b, 0),
                    'gross' : toNumbers(arrThurdayPayGro).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Fri",
                    "number_of_deliveries" : arrFridayPay.length,
                    "net" : toNumbers(arrFridayPay).reduce((a, b) => a + b, 0),
                    "platform" : toNumbers(arrFridayPayToP).reduce((a, b) => a + b, 0),
                    'gross' : toNumbers(arrFridayPayGro).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Sat",
                    "number_of_deliveries" : arrSaturdayPay.length,
                    "net" : toNumbers(arrSaturdayPay).reduce((a, b) => a + b, 0),
                    "platform" : toNumbers(arrSaturdayPayToP).reduce((a, b) => a + b, 0),
                    'gross' : toNumbers(arrSaturdayPayGro).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Sun",
                    "number_of_deliveries" : arrSundayPay.length,
                    "net" : toNumbers(arrSundayPay).reduce((a, b) => a + b, 0),
                    "platform" : toNumbers(arrSundayPayToP).reduce((a, b) => a + b, 0),
                    'gross' : toNumbers(arrSundayPayGro).reduce((a, b) => a + b, 0),
                },
            ]);
            setBarChartWeekData([
                {
                    "day" : "Mon",
                    "amount" : toNumbers(arrMondayPay).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "Tues",
                    "amount" : toNumbers(arrTuesdayPay).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Wed",
                    "amount" : toNumbers(arrWednesdayPay).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Thur",
                    "amount" : toNumbers(arrThurdayPay).reduce((a, b) => a + b, 0),
                    'color' : 'purple'
                },
                {
                    "day" : "Fri",
                    "amount" : toNumbers(arrFridayPay).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Sat",
                    "amount" : toNumbers(arrSaturdayPay).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Sun",
                    "amount" : toNumbers(arrSundayPay).reduce((a, b) => a + b, 0),
                    'color' : 'blue'
                },
            ]);
        }
        else if (deliveryT === 'booked_deliveries'){
            setBarTableWeekData([
                {
                    "day" : "Mon",
                    "number_of_deliveries" : arrMondayPayBooked.length,
                    "net" : toNumbers(arrMondayPayBooked).reduce((a, b) => a + b, 0),
                   
                },
                {
                    "day" : "Tues",
                    "number_of_deliveries" : arrTuesdayPayBooked.length,
                    "net" : toNumbers(arrTuesdayPayBooked).reduce((a, b) => a + b, 0),
                    
                },
                {
                    "day" : "Wed",
                    "number_of_deliveries" : arrWednesdayPayBooked.length,
                    "net" : toNumbers(arrWednesdayPayBooked).reduce((a, b) => a + b, 0),
                    
                },
                {
                    "day" : "Thur",
                    "number_of_deliveries" : arrThurdayPayBooked.length,
                    "net" : toNumbers(arrThurdayPayBooked).reduce((a, b) => a + b, 0),
                    
                },
                {
                    "day" : "Fri",
                    "number_of_deliveries" : arrFridayPayBooked.length,
                    "net" : toNumbers(arrFridayPayBooked).reduce((a, b) => a + b, 0),
                   
                },
                {
                    "day" : "Sat",
                    "number_of_deliveries" : arrSaturdayPayBooked.length,
                    "net" : toNumbers(arrSaturdayPayBooked).reduce((a, b) => a + b, 0),
                    
                },
                {
                    "day" : "Sun",
                    "number_of_deliveries" : arrSundayPayBooked.length,
                    "net" : toNumbers(arrSundayPayBooked).reduce((a, b) => a + b, 0),
                   
                },
            ]);
            setBarChartWeekData([
                {
                    "day" : "Mon",
                    "amount" : toNumbers(arrMondayPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "Tues",
                    "amount" : toNumbers(arrTuesdayPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Wed",
                    "amount" : toNumbers(arrWednesdayPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Thur",
                    "amount" : toNumbers(arrThurdayPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'purple'
                },
                {
                    "day" : "Fri",
                    "amount" : toNumbers(arrFridayPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Sat",
                    "amount" : toNumbers(arrSaturdayPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Sun",
                    "amount" : toNumbers(arrSundayPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'blue'
                },
            ])
        }
        else if(deliveryT === 'deliveries') {
            setBarChartWeekData([
                {
                    "day" : "Mon",
                    "amount" : toNumbers(arrMondayPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "Tues",
                    "amount" : toNumbers(arrTuesdayPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Wed",
                    "amount" : toNumbers(arrWednesdayPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Thur",
                    "amount" : toNumbers(arrThurdayPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'purple'
                },
                {
                    "day" : "Fri",
                    "amount" : toNumbers(arrFridayPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Sat",
                    "amount" : toNumbers(arrSaturdayPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Sun",
                    "amount" : toNumbers(arrSundayPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'blue'
                },
            ]);
            setBarTableWeekData([
                {
                    "day" : "Mon",
                    "number_of_deliveries" : arrMondayPayAll.length,
                    "net" : toNumbers(arrMondayPayAll).reduce((a, b) => a + b, 0),
                   
                },
                {
                    "day" : "Tues",
                    "number_of_deliveries" : arrTuesdayPayAll.length,
                    "net" : toNumbers(arrTuesdayPayAll).reduce((a, b) => a + b, 0),
                    
                },
                {
                    "day" : "Wed",
                    "number_of_deliveries" : arrWednesdayPayAll.length,
                    "net" : toNumbers(arrWednesdayPayAll).reduce((a, b) => a + b, 0),
                    
                },
                {
                    "day" : "Thur",
                    "number_of_deliveries" : arrThurdayPayAll.length,
                    "net" : toNumbers(arrThurdayPayAll).reduce((a, b) => a + b, 0),
                    
                },
                {
                    "day" : "Fri",
                    "number_of_deliveries" : arrFridayPayAll.length,
                    "net" : toNumbers(arrFridayPayAll).reduce((a, b) => a + b, 0),
                   
                },
                {
                    "day" : "Sat",
                    "number_of_deliveries" : arrSaturdayPayAll.length,
                    "net" : toNumbers(arrSaturdayPayAll).reduce((a, b) => a + b, 0),
                    
                },
                {
                    "day" : "Sun",
                    "number_of_deliveries" : arrSundayPayAll.length,
                    "net" : toNumbers(arrSundayPayAll).reduce((a, b) => a + b, 0),
                   
                },
            ]);
        }
    }

    const updatePageCustom = (periodT, deliveryT) =>{
        if(deliveryT === 'quoted_deliveries'){
            const dataInRange = (quoted.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(periodT[0]), moment(periodT[1]), 'days', '[]')}))
            const InRangeDate = dataInRange.map(a => a.time_stamp);
            const remove_duplicate_in_dateRange = [...new Set(InRangeDate)]
    
            const filter_for_each_range = [];
                for(let i = 0; i < remove_duplicate_in_dateRange.length; i++){
                    const dataInRange = (quoted.filter(function (quote) { return quote.time_stamp === remove_duplicate_in_dateRange[i]}))
                    filter_for_each_range.push(dataInRange);
                }
            const amount_due_fulfiller = []
                for(let i = 0; i < filter_for_each_range.length; i++){
                    const amount_dues = filter_for_each_range[i].map(a => a.amount_due_to_fulfiller);
                    amount_due_fulfiller.push(amount_dues);
                }
            const toNumbers = arr => arr.map(Number);    
            //sum up all amount for each day of the week and save it in a state
            const amount_sums = []
            for(let i = 0; i < amount_due_fulfiller.length; i++){
                const sum_amount_dues = toNumbers(amount_due_fulfiller[i]).reduce((a, b) => a + b, 0)
                amount_sums.push(sum_amount_dues);
            }
    
            const chart_data = [];
            for(let i = 0; i < amount_sums.length; i++){
                chart_data.push({
                    "day" : moment(remove_duplicate_in_dateRange[i]).format("DD/MM/YY"),
                    "amount" : amount_sums[i],
                    'color' : `#${i}08080`
                });
            }
        setBarChartWeekData(chart_data)
            
        }
        else if(deliveryT === 'booked_deliveries'){
            const dataInRange = (bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(periodT[0]), moment(periodT[1]), 'days', '[]')}))
            const InRangeDate = dataInRange.map(a => a.time_stamp);
            const remove_duplicate_in_dateRange = [...new Set(InRangeDate)]
    
            const filter_for_each_range = [];
                for(let i = 0; i < remove_duplicate_in_dateRange.length; i++){
                    const dataInRange = (bookedDelivery.filter(function (quote) { return quote.time_stamp === remove_duplicate_in_dateRange[i]}))
                    filter_for_each_range.push(dataInRange);
                }
            const amount_due_fulfiller = []
                for(let i = 0; i < filter_for_each_range.length; i++){
                    const amount_dues = filter_for_each_range[i].map(a => a.amount_due_to_fulfiller);
                    amount_due_fulfiller.push(amount_dues);
                }
            const toNumbers = arr => arr.map(Number);    
            //sum up all amount for each day of the week and save it in a state
            const amount_sums = []
            for(let i = 0; i < amount_due_fulfiller.length; i++){
                const sum_amount_dues = toNumbers(amount_due_fulfiller[i]).reduce((a, b) => a + b, 0)
                amount_sums.push(sum_amount_dues);
            }
    
            const chart_data = [];
            for(let i = 0; i < amount_sums.length; i++){
                chart_data.push({
                    "day" : moment(remove_duplicate_in_dateRange[i]).format("DD/MM/YY"),
                    "amount" : amount_sums[i],
                    'color' : `#${i}08080`
                });
            }
        
        setBarChartWeekData(chart_data)


        }

        else if(deliveryT === 'deliveries'){
            const dataInRange = (all.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(periodT[0]), moment(periodT[1]), 'days', '[]')}))
            const InRangeDate = dataInRange.map(a => a.time_stamp);
            const remove_duplicate_in_dateRange = [...new Set(InRangeDate)]
    
            const filter_for_each_range = [];
                for(let i = 0; i < remove_duplicate_in_dateRange.length; i++){
                    const dataInRange = (all.filter(function (quote) { return quote.time_stamp === remove_duplicate_in_dateRange[i]}))
                    filter_for_each_range.push(dataInRange);
                }
            const amount_due_fulfiller = []
                for(let i = 0; i < filter_for_each_range.length; i++){
                    const amount_dues = filter_for_each_range[i].map(a => a.amount_due_to_fulfiller);
                    amount_due_fulfiller.push(amount_dues);
                }
            const toNumbers = arr => arr.map(Number);    
            //sum up all amount for each day of the week and save it in a state
            const amount_sums = []
            for(let i = 0; i < amount_due_fulfiller.length; i++){
                const sum_amount_dues = toNumbers(amount_due_fulfiller[i]).reduce((a, b) => a + b, 0)
                amount_sums.push(sum_amount_dues);
            }
            
            const chart_data = [];
            for(let i = 0; i < amount_sums.length; i++){
                chart_data.push({
                    "day" : moment(remove_duplicate_in_dateRange[i]).format("DD/MM/YY"),
                    "amount" : amount_sums[i],
                    'color' : `#${i}08080`
                });
            }
        setBarChartWeekData(chart_data)

        }
        
    }


    const updatePageMonth = (deliveryT) =>{

        const startOfLastMonth = moment().subtract(1,'months').startOf('month')
        const endOfLastMonth = moment().subtract(1,'months').endOf('month')

        setstartRange(moment().startOf('month'))
        setendRange(moment().endOf('month'))
        
        var week1 = weeks[0];
        var week2 = weeks[1];
        var week3 = weeks[2];
        var week4 = weeks[3];
        var week5 = weeks[4];
        var week6 = weeks[5];

        var startOfweek1 = week1?.start
        var endOfweek1 = week1?.end
        var startOfweek2 = week2?.start
        var endOfweek2 = week2?.end
        var startOfweek3 = week3?.start
        var endOfweek3 = week3?.end
        var startOfweek4 = week4?.start
        var endOfweek4 = week4?.end
        var startOfweek5 = week5?.start
        var endOfweek5 = week5?.end
        var startOfweek6 = week6?.start
        var endOfweek6 = week6?.end

        // check if a date is between two dates
        const start = moment().date(startOfweek1);
        const end = moment().date(endOfweek1);
        const actual = moment().date(3);
        const test = moment(actual).isBetween(start, end, 'days', '[]');


        const firstWeek =  quoted.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek1), moment().date(endOfweek1), 'days', '[]')})
        const secondWeek =  quoted.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek2), moment().date(endOfweek2), 'days', '[]')})
        const thirdWeek =  quoted.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek3), moment().date(endOfweek3), 'days', '[]')})
        const fourthWeek =  quoted.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek4), moment().date(endOfweek4), 'days', '[]')})
        const fifthWeek =  quoted.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek5), moment().date(endOfweek5), 'days', '[]')})  
        // const sixthWeek = quoted.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek6), moment().date(endOfweek6), 'days', '[]')})
        

        const arrweek1Pay = firstWeek.map(a => a.amount_due_to_fulfiller);
        const arrweek2Pay = secondWeek.map(a => a.amount_due_to_fulfiller);
        const arrweek3Pay = thirdWeek.map(a => a.amount_due_to_fulfiller);
        const arrweek4Pay = fourthWeek.map(a => a.amount_due_to_fulfiller);
        const arrweek5Pay = fifthWeek.map(a => a.amount_due_to_fulfiller);

        const arrweek1PayTop = firstWeek.map(a => a.amount_due_to_platform);
        const arrweek2PayTop = secondWeek.map(a => a.amount_due_to_platform);
        const arrweek3PayTop = thirdWeek.map(a => a.amount_due_to_platform);
        const arrweek4PayTop = fourthWeek.map(a => a.amount_due_to_platform);
        const arrweek5PayTop = fifthWeek.map(a => a.amount_due_to_platform);
        
        const arrweek1PayGro = firstWeek.map(a => a.gross_amount);
        const arrweek2PayGro = secondWeek.map(a => a.gross_amount);
        const arrweek3PayGro = thirdWeek.map(a => a.gross_amount);
        const arrweek4PayGro = fourthWeek.map(a => a.gross_amount);
        const arrweek5PayGro = fifthWeek.map(a => a.gross_amount);

        const toNumbers = arr => arr.map(Number);
        
        setWeek1Sum(toNumbers(arrweek1Pay).reduce((a, b) => a + b, 0))
        setWeek2Sum(toNumbers(arrweek2Pay).reduce((a, b) => a + b, 0))
        setWeek3Sum(toNumbers(arrweek3Pay).reduce((a, b) => a + b, 0))
        setWeek4Sum(toNumbers(arrweek4Pay).reduce((a, b) => a + b, 0))
        setWeek5Sum(toNumbers(arrweek5Pay).reduce((a, b) => a + b, 0))

        //Booked start here
        const firstWeekBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek1), moment().date(endOfweek1), 'days', '[]')})
        const secondWeekBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek2), moment().date(endOfweek2), 'days', '[]')})
        const thirdWeekBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek3), moment().date(endOfweek3), 'days', '[]')})
        const fourthWeekBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek4), moment().date(endOfweek4), 'days', '[]')})
        const fifthWeekBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek5), moment().date(endOfweek5), 'days', '[]')})  
        // const sixthWeek = quoted.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek6), moment().date(endOfweek6), 'days', '[]')})
        
    // for last month
        const quotedForLastMonth = quoted.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(startOfLastMonth), moment(endOfLastMonth), 'days', '[]')})
        const bookedForLastMonth = bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(startOfLastMonth), moment(endOfLastMonth), 'days', '[]')})
        // const allForLastMonth = all.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(startOfLastMonth), moment(endOfLastMonth), 'days', '[]')})

       const payQuotedForLastMonth = quotedForLastMonth.map(a => a.amount_due_to_fulfiller);
       const payBookedForLastMonth = bookedForLastMonth.map(a => a.amount_due_to_fulfiller);

        setLastMonthQuote(toNumbers(payQuotedForLastMonth).reduce((a, b) => a + b, 0))
        setLastMonthBooked(toNumbers(payBookedForLastMonth).reduce((a, b) => a + b, 0))

       
        const arrweek1PayBooked = firstWeekBooked.map(a => a.amount_due_to_fulfiller);
        const arrweek2PayBooked = secondWeekBooked.map(a => a.amount_due_to_fulfiller);
        const arrweek3PayBooked = thirdWeekBooked.map(a => a.amount_due_to_fulfiller);
        const arrweek4PayBooked = fourthWeekBooked.map(a => a.amount_due_to_fulfiller);
        const arrweek5PayBooked = fifthWeekBooked.map(a => a.amount_due_to_fulfiller);
        
        setWeek1SumBooked(toNumbers(arrweek1PayBooked).reduce((a, b) => a + b, 0))
        setWeek2SumBooked(toNumbers(arrweek2PayBooked).reduce((a, b) => a + b, 0))
        setWeek3SumBooked(toNumbers(arrweek3PayBooked).reduce((a, b) => a + b, 0))
        setWeek4SumBooked(toNumbers(arrweek4PayBooked).reduce((a, b) => a + b, 0))
        setWeek5SumBooked(toNumbers(arrweek5PayBooked).reduce((a, b) => a + b, 0))
        

        // all deliveries start here 
        const firstWeekAll =  all.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek1), moment().date(endOfweek1), 'days', '[]')})
        const secondWeekAll =  all.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek2), moment().date(endOfweek2), 'days', '[]')})
        const thirdWeekAll =  all.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek3), moment().date(endOfweek3), 'days', '[]')})
        const fourthWeekAll =  all.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek4), moment().date(endOfweek4), 'days', '[]')})
        const fifthWeekAll =  all.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek5), moment().date(endOfweek5), 'days', '[]')})  
        // const sixthWeek = quoted.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment().date(startOfweek6), moment().date(endOfweek6), 'days', '[]')})
        
        const arrweek1PayAll = firstWeekAll.map(a => a.amount_due_to_fulfiller);
        const arrweek2PayAll = secondWeekAll.map(a => a.amount_due_to_fulfiller);
        const arrweek3PayAll = thirdWeekAll.map(a => a.amount_due_to_fulfiller);
        const arrweek4PayAll = fourthWeekAll.map(a => a.amount_due_to_fulfiller);
        const arrweek5PayAll = fifthWeekAll.map(a => a.amount_due_to_fulfiller);
        
        setWeek1SumAll(toNumbers(arrweek1PayAll).reduce((a, b) => a + b, 0))
        setWeek2SumAll(toNumbers(arrweek2PayAll).reduce((a, b) => a + b, 0))
        setWeek3SumAll(toNumbers(arrweek3PayAll).reduce((a, b) => a + b, 0))
        setWeek4SumAll(toNumbers(arrweek4PayAll).reduce((a, b) => a + b, 0))
        setWeek5SumAll(toNumbers(arrweek5PayAll).reduce((a, b) => a + b, 0))

        
        const sumForMonth = toNumbers(arrweek1Pay).reduce((a, b) => a + b, 0) + 
            toNumbers(arrweek2Pay).reduce((a, b) => a + b, 0) +
            toNumbers(arrweek3Pay).reduce((a, b) => a + b, 0) +
            toNumbers(arrweek4Pay).reduce((a, b) => a + b, 0) +
            toNumbers(arrweek5Pay).reduce((a, b) => a + b, 0) 
   
        const sumForLastMonth = toNumbers(payQuotedForLastMonth).reduce((a, b) => a + b, 0)

        if(sumForMonth > sumForLastMonth){
                sethighest('now')
                const percentage = (sumForMonth - sumForLastMonth) / (sumForMonth + sumForLastMonth) * 100
                setpercent(percentage)
            }
        else if (sumForMonth < sumForLastMonth){
                sethighest('last')
                const percentage = (sumForLastMonth - sumForMonth) / (sumForMonth + sumForLastMonth) * 100
                setpercent(percentage)
             }
        else{
                sethighest('equal')
                setpercent(0)
            }
        
        const sumForMonthBooked = toNumbers(arrweek1PayBooked).reduce((a, b) => a + b, 0) + 
            toNumbers(arrweek2PayBooked).reduce((a, b) => a + b, 0) +
            toNumbers(arrweek3PayBooked).reduce((a, b) => a + b, 0) +
            toNumbers(arrweek4PayBooked).reduce((a, b) => a + b, 0) +
            toNumbers(arrweek5PayBooked).reduce((a, b) => a + b, 0) 
   
        const sumForLastMonthBooked = toNumbers(payBookedForLastMonth).reduce((a, b) => a + b, 0)

        if(sumForMonthBooked > sumForLastMonthBooked){
                sethighestBooked('now')
                const percentage = (sumForMonthBooked - sumForLastMonthBooked) / (sumForMonthBooked + sumForLastMonthBooked) * 100
                setpercentBooked(percentage)
            }
        else if (sumForMonthBooked < sumForLastMonth){
                sethighestBooked('last')
                const percentage = (sumForLastMonthBooked - sumForMonthBooked) / (sumForMonthBooked + sumForLastMonthBooked) * 100
                setpercentBooked(percentage)
             }
        else{
                sethighestBooked('equal')
                setpercentBooked(0)
            }


        if (deliveryT === 'quoted_deliveries'){
            setBarTableWeekData([
                {
                    "day" : "Week1",
                    "number_of_deliveries" : arrweek1Pay.length,
                    "net" : toNumbers(arrweek1Pay).reduce((a, b) => a + b, 0),
                    "platform" : toNumbers(arrweek1PayTop).reduce((a, b) => a + b, 0),
                    'gross' : toNumbers(arrweek1PayGro).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Week2",
                    "number_of_deliveries" : arrweek2Pay.length,
                    "net" : toNumbers(arrweek2Pay).reduce((a, b) => a + b, 0),
                    "platform" : toNumbers(arrweek2PayTop).reduce((a, b) => a + b, 0),
                    'gross' : toNumbers(arrweek2PayGro).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Week3",
                    "number_of_deliveries" : arrweek3Pay.length,
                    "net" : toNumbers(arrweek3Pay).reduce((a, b) => a + b, 0),
                    "platform" : toNumbers(arrweek3PayTop).reduce((a, b) => a + b, 0),
                    'gross' : toNumbers(arrweek3PayGro).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Week4",
                    "number_of_deliveries" : arrweek4Pay.length,
                    "net" : toNumbers(arrweek4Pay).reduce((a, b) => a + b, 0),
                    "platform" : toNumbers(arrweek4PayTop).reduce((a, b) => a + b, 0),
                    'gross' : toNumbers(arrweek4PayGro).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Week5",
                    "number_of_deliveries" : arrweek5Pay.length,
                    "net" : toNumbers(arrweek5Pay).reduce((a, b) => a + b, 0),
                    "platform" : toNumbers(arrweek5PayTop).reduce((a, b) => a + b, 0),
                    'gross' : toNumbers(arrweek5PayGro).reduce((a, b) => a + b, 0),
                }
            ]);
            setBarChartWeekData([
                {
                    "day" : `${moment(moment().date(startOfweek1)).format("MMM D") + "-" + moment(moment().date(endOfweek1)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek1Pay).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : `${moment(moment().date(startOfweek2)).format("MMM D") + "-" + moment(moment().date(endOfweek2)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek2Pay).reduce((a, b) => a + b, 0),
                    'color' : 'blue'
                },
                {
                    "day" : `${moment(moment().date(startOfweek3)).format("MMM D") + "-" + moment(moment().date(endOfweek3)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek3Pay).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : `${moment(moment().date(startOfweek4)).format("MMM D") + "-" + moment(moment().date(endOfweek4)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek4Pay).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : `${moment(moment().date(startOfweek5)).format("MMM D") + "-" + moment(moment().date(endOfweek5)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek5Pay).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                }
            ])
        }

        else if (deliveryT === 'booked_deliveries'){
            setBarChartWeekData([
                {
                    "day" : `${moment(moment().date(startOfweek1)).format("MMM D") + "-" + moment(moment().date(endOfweek1)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek1PayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : `${moment(moment().date(startOfweek2)).format("MMM D") + "-" + moment(moment().date(endOfweek2)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek2PayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'blue'
                },
                {
                    "day" : `${moment(moment().date(startOfweek3)).format("MMM D") + "-" + moment(moment().date(endOfweek3)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek3PayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : `${moment(moment().date(startOfweek4)).format("MMM D") + "-" + moment(moment().date(endOfweek4)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek4PayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : `${moment(moment().date(startOfweek5)).format("MMM D") + "-" + moment(moment().date(endOfweek5)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek5PayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                }
            ]);
            setBarTableWeekData([
                {
                    "day" : "Week1",
                    "number_of_deliveries" : arrweek1PayBooked.length,
                    "net" : toNumbers(arrweek1PayBooked).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Week2",
                    "number_of_deliveries" : arrweek2PayBooked.length,
                    "net" : toNumbers(arrweek2PayBooked).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Week3",
                    "number_of_deliveries" : arrweek3PayBooked.length,
                    "net" : toNumbers(arrweek3PayBooked).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Week4",
                    "number_of_deliveries" : arrweek4PayBooked.length,
                    "net" : toNumbers(arrweek4PayBooked).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Week5",
                    "number_of_deliveries" : arrweek5PayBooked.length,
                    "net" : toNumbers(arrweek5PayBooked).reduce((a, b) => a + b, 0),
                }
            ]);
        }
        else if (deliveryT === 'deliveries'){
            setBarTableWeekData([
                {
                    "day" : "Week1",
                    "number_of_deliveries" : arrweek1PayAll.length,
                    "net" : toNumbers(arrweek1PayAll).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Week2",
                    "number_of_deliveries" : arrweek2PayAll.length,
                    "net" : toNumbers(arrweek2PayAll).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Week3",
                    "number_of_deliveries" : arrweek3PayAll.length,
                    "net" : toNumbers(arrweek3PayAll).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Week4",
                    "number_of_deliveries" : arrweek4PayAll.length,
                    "net" : toNumbers(arrweek4PayAll).reduce((a, b) => a + b, 0),
                },
                {
                    "day" : "Week5",
                    "number_of_deliveries" : arrweek5PayAll.length,
                    "net" : toNumbers(arrweek5PayAll).reduce((a, b) => a + b, 0),
                }
            ]);
            setBarChartWeekData([
                {
                    "day" : `${moment(moment().date(startOfweek1)).format("MMM D") + "-" + moment(moment().date(endOfweek1)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek1PayAll).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : `${moment(moment().date(startOfweek2)).format("MMM D") + "-" + moment(moment().date(endOfweek2)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek2PayAll).reduce((a, b) => a + b, 0),
                    'color' : 'blue'
                },
                {
                    "day" : `${moment(moment().date(startOfweek3)).format("MMM D") + "-" + moment(moment().date(endOfweek3)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek3PayAll).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : `${moment(moment().date(startOfweek4)).format("MMM D") + "-" + moment(moment().date(endOfweek4)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek4PayAll).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : `${moment(moment().date(startOfweek5)).format("MMM D") + "-" + moment(moment().date(endOfweek5)).format("MMM D")}`,
                    "amount" : toNumbers(arrweek5PayAll).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                }
            ])
        }
    }

    const updatePageQuarterly = (deliveryT) =>{ 
        
        // for last quarter
        const toNumbers = arr => arr.map(Number);

        const lastQuarterStart = moment().subtract(1,'quarters').startOf('quarter')
        const endOfLastQuarter = moment().subtract(1,'quarters').endOf('quarter')

        setlastQuarterStartDate(lastQuarterStart)
        setlastQuarterEndDate(endOfLastQuarter)

        const quotedForLastQuarter = quoted.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(lastQuarterStart), moment(endOfLastQuarter), 'days', '[]')})
        const bookedForLastQuarter = bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(lastQuarterStart), moment(endOfLastQuarter), 'days', '[]')})
        // const allForLastMonth = all.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(startOfLastMonth), moment(endOfLastMonth), 'days', '[]')})
       const payQuotedForLastQuarter = quotedForLastQuarter.map(a => a.amount_due_to_fulfiller);
       const payBookedForLastQuarter = bookedForLastQuarter.map(a => a.amount_due_to_fulfiller);

       const QuarterStart = moment().startOf('quarter')
       const QuarterEnd = moment().endOf('quarter')
       
       const quotedForQuarter = quoted.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(QuarterStart), moment(QuarterEnd), 'days', '[]')})
       const bookedForQuarter = bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(QuarterStart), moment(QuarterEnd), 'days', '[]')})
      
       const payQuotedForQuarter = quotedForQuarter.map(a => a.amount_due_to_fulfiller);
       const payBookedForQuarter = bookedForQuarter.map(a => a.amount_due_to_fulfiller);


        setLastQuarterQuote(toNumbers(payQuotedForLastQuarter).reduce((a, b) => a + b, 0))
        setLastQuarterBooked(toNumbers(payBookedForLastQuarter).reduce((a, b) => a + b, 0))

        setstartRange(moment().startOf('quarter'))
        setendRange(moment().endOf('quarter'))
      
        const janPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 0 && moment(quote.time_stamp).year() === moment().year()})
        const febPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 1 && moment(quote.time_stamp).year() === moment().year()})
        const marPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 2 && moment(quote.time_stamp).year() === moment().year()})
        const aprPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 3 && moment(quote.time_stamp).year() === moment().year()})
        const mayPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 4 && moment(quote.time_stamp).year() === moment().year()})
        const junPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 5 && moment(quote.time_stamp).year() === moment().year()})
        const julPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 6 && moment(quote.time_stamp).year() === moment().year()})
        const augPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 7 && moment(quote.time_stamp).year() === moment().year()})
        const sepPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 8 && moment(quote.time_stamp).year() === moment().year()})
        const octPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 9 && moment(quote.time_stamp).year() === moment().year()})
        const novPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 10 && moment(quote.time_stamp).year() === moment().year()})
        const decPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 11 && moment(quote.time_stamp).year() === moment().year()})
        
        const arrJanPay = janPay.map(a => a.amount_due_to_fulfiller);
        const arrfebPay = febPay.map(a => a.amount_due_to_fulfiller);
        const arrmarPay = marPay.map(a => a.amount_due_to_fulfiller);
        const arraprPay = aprPay.map(a => a.amount_due_to_fulfiller);
        const arrmayPay = mayPay.map(a => a.amount_due_to_fulfiller);
        const arrjunPay = junPay.map(a => a.amount_due_to_fulfiller);
        const arrjulPay = julPay.map(a => a.amount_due_to_fulfiller);
        const arraugPay = augPay.map(a => a.amount_due_to_fulfiller);
        const arrsepPay = sepPay.map(a => a.amount_due_to_fulfiller);
        const arroctPay = octPay.map(a => a.amount_due_to_fulfiller);
        const arrnovPay = novPay.map(a => a.amount_due_to_fulfiller);
        const arrdecPay = decPay.map(a => a.amount_due_to_fulfiller);
        
        const arrJanPayTop = janPay.map(a => a.amount_due_to_platform);
        const arrfebPayTop = febPay.map(a => a.amount_due_to_platform);
        const arrmarPayTop = marPay.map(a => a.amount_due_to_platform);
        const arraprPayTop = aprPay.map(a => a.amount_due_to_platform);
        const arrmayPayTop = mayPay.map(a => a.amount_due_to_platform);
        const arrjunPayTop = junPay.map(a => a.amount_due_to_platform);
        const arrjulPayTop = julPay.map(a => a.amount_due_to_platform);
        const arraugPayTop = augPay.map(a => a.amount_due_to_platform);
        const arrsepPayTop = sepPay.map(a => a.amount_due_to_platform);
        const arroctPayTop = octPay.map(a => a.amount_due_to_platform);
        const arrnovPayTop = novPay.map(a => a.amount_due_to_platform);
        const arrdecPayTop = decPay.map(a => a.amount_due_to_platform);
        
        const arrJanPayGro = janPay.map(a => a.gross_amount);
        const arrfebPayGro = febPay.map(a => a.gross_amount);
        const arrmarPayGro = marPay.map(a => a.gross_amount);
        const arraprPayGro = aprPay.map(a => a.gross_amount);
        const arrmayPayGro = mayPay.map(a => a.gross_amount);
        const arrjunPayGro = junPay.map(a => a.gross_amount);
        const arrjulPayGro = julPay.map(a => a.gross_amount);
        const arraugPayGro = augPay.map(a => a.gross_amount);
        const arrsepPayGro = sepPay.map(a => a.gross_amount);
        const arroctPayGro = octPay.map(a => a.gross_amount);
        const arrnovPayGro = novPay.map(a => a.gross_amount);
        const arrdecPayGro = decPay.map(a => a.gross_amount);
        

        setjanSum(toNumbers(arrJanPay).reduce((a, b) => a + b, 0))
        setfebSum(toNumbers(arrfebPay).reduce((a, b) => a + b, 0))
        setmarSum(toNumbers(arrmarPay).reduce((a, b) => a + b, 0))
        setaprSum(toNumbers(arraprPay).reduce((a, b) => a + b, 0))
        setmaySum(toNumbers(arrmayPay).reduce((a, b) => a + b, 0))
        setjunSum(toNumbers(arrjunPay).reduce((a, b) => a + b, 0))
        setjulSum(toNumbers(arrjulPay).reduce((a, b) => a + b, 0))
        setaugSum(toNumbers(arraugPay).reduce((a, b) => a + b, 0))
        setsepSum(toNumbers(arrsepPay).reduce((a, b) => a + b, 0))
        setoctSum(toNumbers(arroctPay).reduce((a, b) => a + b, 0))
        setnovSum(toNumbers(arrnovPay).reduce((a, b) => a + b, 0))
        setdecSum(toNumbers(arrdecPay).reduce((a, b) => a + b, 0))

        // for booked deliveries

        const janPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 0 && moment(quote.time_stamp).year() === moment().year()})
        const febPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 1 && moment(quote.time_stamp).year() === moment().year()})
        const marPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 2 && moment(quote.time_stamp).year() === moment().year()})
        const aprPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 3 && moment(quote.time_stamp).year() === moment().year()})
        const mayPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 4 && moment(quote.time_stamp).year() === moment().year()})
        const junPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 5 && moment(quote.time_stamp).year() === moment().year()})
        const julPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 6 && moment(quote.time_stamp).year() === moment().year()})
        const augPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 7 && moment(quote.time_stamp).year() === moment().year()})
        const sepPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 8 && moment(quote.time_stamp).year() === moment().year()})
        const octPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 9 && moment(quote.time_stamp).year() === moment().year()})
        const novPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 10 && moment(quote.time_stamp).year() === moment().year()})
        const decPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 11 && moment(quote.time_stamp).year() === moment().year()})
        
        const arrJanPayBooked = janPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrfebPayBooked = febPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrmarPayBooked = marPayBooked.map(a => a.amount_due_to_fulfiller);
        const arraprPayBooked = aprPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrmayPayBooked = mayPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrjunPayBooked = junPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrjulPayBooked = julPayBooked.map(a => a.amount_due_to_fulfiller);
        const arraugPayBooked = augPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrsepPayBooked = sepPayBooked.map(a => a.amount_due_to_fulfiller);
        const arroctPayBooked = octPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrnovPayBooked = novPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrdecPayBooked = decPayBooked.map(a => a.amount_due_to_fulfiller);

        setjanSumBooked(toNumbers(arrJanPayBooked).reduce((a, b) => a + b, 0))
        setfebSumBooked(toNumbers(arrfebPayBooked).reduce((a, b) => a + b, 0))
        setmarSumBooked(toNumbers(arrmarPayBooked).reduce((a, b) => a + b, 0))
        setaprSumBooked(toNumbers(arraprPayBooked).reduce((a, b) => a + b, 0))
        setmaySumBooked(toNumbers(arrmayPayBooked).reduce((a, b) => a + b, 0))
        setjunSumBooked(toNumbers(arrjunPayBooked).reduce((a, b) => a + b, 0))
        setjulSumBooked(toNumbers(arrjulPayBooked).reduce((a, b) => a + b, 0))
        setaugSumBooked(toNumbers(arraugPayBooked).reduce((a, b) => a + b, 0))
        setsepSumBooked(toNumbers(arrsepPayBooked).reduce((a, b) => a + b, 0))
        setoctSumBooked(toNumbers(arroctPayBooked).reduce((a, b) => a + b, 0))
        setnovSumBooked(toNumbers(arrnovPayBooked).reduce((a, b) => a + b, 0))
        setdecSumBooked(toNumbers(arrdecPayBooked).reduce((a, b) => a + b, 0))

        // for all deliveries
        const janPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 0 && moment(quote.time_stamp).year() === moment().year()})
        const febPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 1 && moment(quote.time_stamp).year() === moment().year()})
        const marPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 2 && moment(quote.time_stamp).year() === moment().year()})
        const aprPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 3 && moment(quote.time_stamp).year() === moment().year()})
        const mayPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 4 && moment(quote.time_stamp).year() === moment().year()})
        const junPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 5 && moment(quote.time_stamp).year() === moment().year()})
        const julPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 6 && moment(quote.time_stamp).year() === moment().year()})
        const augPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 7 && moment(quote.time_stamp).year() === moment().year()})
        const sepPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 8 && moment(quote.time_stamp).year() === moment().year()})
        const octPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 9 && moment(quote.time_stamp).year() === moment().year()})
        const novPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 10 && moment(quote.time_stamp).year() === moment().year()})
        const decPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 11 && moment(quote.time_stamp).year() === moment().year()})
        
        const arrJanPayAll = janPayAll.map(a => a.amount_due_to_fulfiller);
        const arrfebPayAll = febPayAll.map(a => a.amount_due_to_fulfiller);
        const arrmarPayAll = marPayAll.map(a => a.amount_due_to_fulfiller);
        const arraprPayAll = aprPayAll.map(a => a.amount_due_to_fulfiller);
        const arrmayPayAll = mayPayAll.map(a => a.amount_due_to_fulfiller);
        const arrjunPayAll = junPayAll.map(a => a.amount_due_to_fulfiller);
        const arrjulPayAll = julPayAll.map(a => a.amount_due_to_fulfiller);
        const arraugPayAll = augPayAll.map(a => a.amount_due_to_fulfiller);
        const arrsepPayAll = sepPayAll.map(a => a.amount_due_to_fulfiller);
        const arroctPayAll = octPayAll.map(a => a.amount_due_to_fulfiller);
        const arrnovPayAll = novPayAll.map(a => a.amount_due_to_fulfiller);
        const arrdecPayAll = decPayAll.map(a => a.amount_due_to_fulfiller);

        setjanSumAll(toNumbers(arrJanPayAll).reduce((a, b) => a + b, 0))
        setfebSumAll(toNumbers(arrfebPayAll).reduce((a, b) => a + b, 0))
        setmarSumAll(toNumbers(arrmarPayAll).reduce((a, b) => a + b, 0))
        setaprSumAll(toNumbers(arraprPayAll).reduce((a, b) => a + b, 0))
        setmaySumAll(toNumbers(arrmayPayAll).reduce((a, b) => a + b, 0))
        setjunSumAll(toNumbers(arrjunPayAll).reduce((a, b) => a + b, 0))
        setjulSumAll(toNumbers(arrjulPayAll).reduce((a, b) => a + b, 0))
        setaugSumAll(toNumbers(arraugPayAll).reduce((a, b) => a + b, 0))
        setsepSumAll(toNumbers(arrsepPayAll).reduce((a, b) => a + b, 0))
        setoctSumAll(toNumbers(arroctPayAll).reduce((a, b) => a + b, 0))
        setnovSumAll(toNumbers(arrnovPayAll).reduce((a, b) => a + b, 0))
        setdecSumAll(toNumbers(arrdecPayAll).reduce((a, b) => a + b, 0))

            const sumForQuarter = toNumbers(payQuotedForQuarter).reduce((a, b) => a + b, 0) 
            setCurrentQuarterSum(sumForQuarter)
            const sumForLastQuarter = toNumbers(payQuotedForLastQuarter).reduce((a, b) => a + b, 0)
            setLastQuarterSum(sumForLastQuarter)

                if(sumForQuarter > sumForLastQuarter){
                        sethighest('now')
                        const percentage = (sumForQuarter - sumForLastQuarter) / (sumForQuarter + sumForLastQuarter) * 100
                        setpercent(percentage)
                    }
                else if (sumForQuarter < sumForLastQuarter){
                        sethighest('last')
                        const percentage = (sumForLastQuarter - sumForQuarter) / (sumForQuarter + sumForLastQuarter) * 100
                        setpercent(percentage)
                    }
                else{
                        sethighest('equal')
                        setpercent(0)
                    }
                
                const sumForQuarterBooked = toNumbers(payBookedForQuarter).reduce((a, b) => a + b, 0) 
                setCurrentQuarterBookedSum(sumForQuarterBooked)
                const sumForLastQuarterBooked = toNumbers(payBookedForLastQuarter).reduce((a, b) => a + b, 0)
                setLastQuarterBookedSum(sumForLastQuarterBooked)

                if(sumForQuarterBooked > sumForLastQuarterBooked){
                        sethighestBooked('now')
                        const percentage = (sumForQuarterBooked - sumForLastQuarterBooked) / (sumForQuarterBooked + sumForLastQuarterBooked) * 100
                        setpercentBooked(percentage)
                    }
                else if (sumForQuarterBooked < sumForLastQuarter){
                        sethighestBooked('last')
                        const percentage = (sumForLastQuarterBooked - sumForQuarterBooked) / (sumForQuarterBooked + sumForLastQuarterBooked) * 100
                        setpercentBooked(percentage)
                    }
                else{
                        sethighestBooked('equal')
                        setpercentBooked(0)
                    }

        if (deliveryT === 'quoted_deliveries'){
            if(moment().quarter() === 1){
                
                    // setCurrentQuarterSum(toNumbers(arrJanPay).reduce((a, b) => a + b, 0) + toNumbers(arrfebPay).reduce((a, b) => a + b, 0) + toNumbers(arrmarPay).reduce((a, b) => a + b, 0))
                    // setLastQuarterSum(toNumbers(arrJanPay).reduce((a, b) => a + b, 0) + toNumbers(arrfebPay).reduce((a, b) => a + b, 0) + toNumbers(arrmarPay).reduce((a, b) => a + b, 0))
                    
                    // setCurrentQuarterBookedSum(toNumbers(arrJanPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrfebPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrmarPayBooked).reduce((a, b) => a + b, 0))
                    
                    setBarChartWeekData([
                        {
                            "day" : "January",
                            "amount" : toNumbers(arrJanPay).reduce((a, b) => a + b, 0),
                            'color' : 'grey'
                        },
                        {
                            "day" : "February",
                            "amount" : toNumbers(arrfebPay).reduce((a, b) => a + b, 0),
                            'color' : 'green'
                        },
                        {
                            "day" : "March",
                            "amount" : toNumbers(arrmarPay).reduce((a, b) => a + b, 0),
                            'color' : 'red'
                        }
                    ])
                    setBarTableWeekData([
                        {
                            "day" : "January",
                            "number_of_deliveries" : arrJanPay.length,
                            "net" : toNumbers(arrJanPay).reduce((a, b) => a + b, 0),
                            "platform" : toNumbers(arrJanPayTop).reduce((a, b) => a + b, 0),
                            'gross' : toNumbers(arrJanPayGro).reduce((a, b) => a + b, 0),
                        },
                        {
                            "day" : "February",
                            "number_of_deliveries" : arrfebPay.length,
                            "net" : toNumbers(arrfebPay).reduce((a, b) => a + b, 0),
                            "platform" : toNumbers(arrfebPayTop).reduce((a, b) => a + b, 0),
                            'gross' : toNumbers(arrfebPayGro).reduce((a, b) => a + b, 0),
                        },
                        {
                            "day" : "March",
                            "number_of_deliveries" : arrmarPay.length,
                            "net" : toNumbers(arrmarPay).reduce((a, b) => a + b, 0),
                            "platform" : toNumbers(arrmarPayTop).reduce((a, b) => a + b, 0),
                            'gross' : toNumbers(arrmarPayGro).reduce((a, b) => a + b, 0),
                        },
                    ])
            }
            else if(moment().quarter() === 2){
                // setCurrentQuarterSum(toNumbers(arraprPay).reduce((a, b) => a + b, 0) + toNumbers(arrmayPay).reduce((a, b) => a + b, 0) + toNumbers(arrjulPay).reduce((a, b) => a + b, 0))
                // setLastQuarterSum(toNumbers(arrJanPay).reduce((a, b) => a + b, 0) + toNumbers(arrfebPay).reduce((a, b) => a + b, 0) + toNumbers(arrmarPay).reduce((a, b) => a + b, 0))

                // setCurrentQuarterSum(toNumbers(arraprPay).reduce((a, b) => a + b, 0) + toNumbers(arrmayPay).reduce((a, b) => a + b, 0) + toNumbers(arrjulPay).reduce((a, b) => a + b, 0))
                // setLastQuarterSum(toNumbers(arrJanPay).reduce((a, b) => a + b, 0) + toNumbers(arrfebPay).reduce((a, b) => a + b, 0) + toNumbers(arrmarPay).reduce((a, b) => a + b, 0))

                setBarChartWeekData([
                    {
                        "day" : "April",
                        "amount" : toNumbers(arraprPay).reduce((a, b) => a + b, 0),
                        'color' : 'grey'
                    },
                    {
                        "day" : "May",
                        "amount" : toNumbers(arrmayPay).reduce((a, b) => a + b, 0),
                        'color' : 'green'
                    },
                    {
                        "day" : "June",
                        "amount" : toNumbers(arrjunPay).reduce((a, b) => a + b, 0),
                        'color' : 'red'
                    },
                
                ])
                setBarTableWeekData([
                    {
                        "day" : "April",
                        "number_of_deliveries" : arraprPay.length,
                        "net" : toNumbers(arraprPay).reduce((a, b) => a + b, 0),
                        "platform" : toNumbers(arraprPayTop).reduce((a, b) => a + b, 0),
                        'gross' : toNumbers(arraprPayGro).reduce((a, b) => a + b, 0),
                    },
                    {
                        "day" : "May",
                        "number_of_deliveries" : arrmayPay.length,
                        "net" : toNumbers(arrmayPay).reduce((a, b) => a + b, 0),
                        "platform" : toNumbers(arrmayPayTop).reduce((a, b) => a + b, 0),
                        'gross' : toNumbers(arrmayPayGro).reduce((a, b) => a + b, 0),
                    },
                    {
                        "day" : "June",
                        "number_of_deliveries" : arrjunPay.length,
                        "net" : toNumbers(arrjunPay).reduce((a, b) => a + b, 0),
                        "platform" : toNumbers(arrjunPayTop).reduce((a, b) => a + b, 0),
                        'gross' : toNumbers(arrjunPayGro).reduce((a, b) => a + b, 0),
                    },
                ])
            }
            else if(moment().quarter() === 3){
                // setCurrentQuarterSum(toNumbers(arrjulPay).reduce((a, b) => a + b, 0) + toNumbers(arraugPay).reduce((a, b) => a + b, 0) + toNumbers(arrsepPay).reduce((a, b) => a + b, 0))
                // setLastQuarterSum(toNumbers(arraprPay).reduce((a, b) => a + b, 0) + toNumbers(arrmayPay).reduce((a, b) => a + b, 0) + toNumbers(arrjulPay).reduce((a, b) => a + b, 0))

                // setCurrentQuarterSum(toNumbers(arrjulPay).reduce((a, b) => a + b, 0) + toNumbers(arraugPay).reduce((a, b) => a + b, 0) + toNumbers(arrsepPay).reduce((a, b) => a + b, 0))
                // setLastQuarterSum(toNumbers(arraprPay).reduce((a, b) => a + b, 0) + toNumbers(arrmayPay).reduce((a, b) => a + b, 0) + toNumbers(arrjulPay).reduce((a, b) => a + b, 0))

                setBarChartWeekData([
                    {
                        "day" : "July",
                        "amount" : toNumbers(arrjulPay).reduce((a, b) => a + b, 0),
                        'color' : 'grey'
                    },
                    {
                        "day" : "August",
                        "amount" : toNumbers(arraugPay).reduce((a, b) => a + b, 0),
                        'color' : 'green'
                    },
                    {
                        "day" : "September",
                        "amount" : toNumbers(arrsepPay).reduce((a, b) => a + b, 0),
                        'color' : 'red'
                    },
                
                ])
                setBarTableWeekData([
                    {
                        "day" : "July",
                        "number_of_deliveries" : arrjulPay.length,
                        "net" : toNumbers(arrjulPay).reduce((a, b) => a + b, 0),
                        "platform" : toNumbers(arrjulPayTop).reduce((a, b) => a + b, 0),
                        'gross' : toNumbers(arrjulPayGro).reduce((a, b) => a + b, 0),
                    },
                    {
                        "day" : "August",
                        "number_of_deliveries" : arraugPay.length,
                        "net" : toNumbers(arraugPay).reduce((a, b) => a + b, 0),
                        "platform" : toNumbers(arraugPayTop).reduce((a, b) => a + b, 0),
                        'gross' : toNumbers(arraugPayGro).reduce((a, b) => a + b, 0),
                    },
                    {
                        "day" : "September",
                        "number_of_deliveries" : arrsepPay.length,
                        "net" : toNumbers(arrsepPay).reduce((a, b) => a + b, 0),
                        "platform" : toNumbers(arrsepPayTop).reduce((a, b) => a + b, 0),
                        'gross' : toNumbers(arrsepPayGro).reduce((a, b) => a + b, 0),
                    },
                ])
            }
            else if(moment().quarter() === 4){
                // setCurrentQuarterSum(toNumbers(arroctPay).reduce((a, b) => a + b, 0) + toNumbers(arrnovPay).reduce((a, b) => a + b, 0) + toNumbers(arrdecPay).reduce((a, b) => a + b, 0))
                // setLastQuarterSum(toNumbers(arrjulPay).reduce((a, b) => a + b, 0) + toNumbers(arraugPay).reduce((a, b) => a + b, 0) + toNumbers(arrsepPay).reduce((a, b) => a + b, 0))

                // setLastQuarterBookedSum(toNumbers(arrjulPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arraugPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrsepPayBooked).reduce((a, b) => a + b, 0))
                // setCurrentQuarterBookedSum(toNumbers(arroctPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrnovPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrdecPayBooked).reduce((a, b) => a + b, 0))
                
                setBarChartWeekData([
                    {
                        "day" : "October",
                        "amount" : toNumbers(arroctPay).reduce((a, b) => a + b, 0),
                        'color' : 'grey'
                    },
                    {
                        "day" : "November",
                        "amount" : toNumbers(arrnovPay).reduce((a, b) => a + b, 0),
                        'color' : 'green'
                    },
                    {
                        "day" : "December",
                        "amount" : toNumbers(arrdecPay).reduce((a, b) => a + b, 0),
                        'color' : 'red'
                    },
                
                ])
                setBarTableWeekData([
                    {
                        "day" : "July",
                        "number_of_deliveries" : arrjulPay.length,
                        "net" : toNumbers(arrjulPay).reduce((a, b) => a + b, 0),
                        "platform" : toNumbers(arrjulPayTop).reduce((a, b) => a + b, 0),
                        'gross' : toNumbers(arrjulPayGro).reduce((a, b) => a + b, 0),
                    },
                    {
                        "day" : "August",
                        "number_of_deliveries" : arraugPay.length,
                        "net" : toNumbers(arraugPay).reduce((a, b) => a + b, 0),
                        "platform" : toNumbers(arraugPayTop).reduce((a, b) => a + b, 0),
                        'gross' : toNumbers(arraugPayGro).reduce((a, b) => a + b, 0),
                    },
                    {
                        "day" : "September",
                        "number_of_deliveries" : arrsepPay.length,
                        "net" : toNumbers(arrsepPay).reduce((a, b) => a + b, 0),
                        "platform" : toNumbers(arrsepPayTop).reduce((a, b) => a + b, 0),
                        'gross' : toNumbers(arrsepPayGro).reduce((a, b) => a + b, 0),
                    },
                ])
                setBarTableWeekData([
                    {
                        "day" : "October",
                        "number_of_deliveries" : arroctPay.length,
                        "net" : toNumbers(arroctPay).reduce((a, b) => a + b, 0),
                        "platform" : toNumbers(arroctPayTop).reduce((a, b) => a + b, 0),
                        'gross' : toNumbers(arroctPayGro).reduce((a, b) => a + b, 0),
                    },
                    {
                        "day" : "November",
                        "number_of_deliveries" : arrnovPay.length,
                        "net" : toNumbers(arrnovPay).reduce((a, b) => a + b, 0),
                        "platform" : toNumbers(arrnovPayTop).reduce((a, b) => a + b, 0),
                        'gross' : toNumbers(arrnovPayGro).reduce((a, b) => a + b, 0),
                    },
                    {
                        "day" : "December",
                        "number_of_deliveries" : arrdecPay.length,
                        "net" : toNumbers(arrdecPay).reduce((a, b) => a + b, 0),
                        "platform" : toNumbers(arrdecPayTop).reduce((a, b) => a + b, 0),
                        'gross' : toNumbers(arrdecPayGro).reduce((a, b) => a + b, 0),
                    },
                ])
            }
        }
        else if (deliveryT === 'booked_deliveries'){
            if(moment().quarter() === 1){
                    setCurrentQuarterBookedSum(toNumbers(arrJanPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrfebPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrmarPayBooked).reduce((a, b) => a + b, 0))
                    // setCurrentQuarterBookedSum(toNumbers(arrJanPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrfebPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrmarPayBooked).reduce((a, b) => a + b, 0))
                    
                    setCurrentQuarterSum(toNumbers(arrJanPay).reduce((a, b) => a + b, 0) + toNumbers(arrfebPay).reduce((a, b) => a + b, 0) + toNumbers(arrmarPay).reduce((a, b) => a + b, 0))
                    
                    setBarChartWeekData([
                    {
                        "day" : "January",
                        "amount" : toNumbers(arrJanPayBooked).reduce((a, b) => a + b, 0),
                        'color' : 'grey'
                    },
                    {
                        "day" : "February",
                        "amount" : toNumbers(arrfebPayBooked).reduce((a, b) => a + b, 0),
                        'color' : 'green'
                    },
                    {
                        "day" : "March",
                        "amount" : toNumbers(arrmarPayBooked).reduce((a, b) => a + b, 0),
                        'color' : 'red'
                    },
                
                ])
                
            }
            else if(moment().quarter() === 2){
                // setCurrentQuarterBookedSum(toNumbers(arraprPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrmayPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrjulPayBooked).reduce((a, b) => a + b, 0))
                // setLastQuarterBookedSum(toNumbers(arrJanPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrfebPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrmarPayBooked).reduce((a, b) => a + b, 0))

                // setCurrentQuarterSum(toNumbers(arraprPay).reduce((a, b) => a + b, 0) + toNumbers(arrmayPay).reduce((a, b) => a + b, 0) + toNumbers(arrjulPay).reduce((a, b) => a + b, 0))
                // setLastQuarterSum(toNumbers(arrJanPay).reduce((a, b) => a + b, 0) + toNumbers(arrfebPay).reduce((a, b) => a + b, 0) + toNumbers(arrmarPay).reduce((a, b) => a + b, 0))

                setBarChartWeekData([
                    {
                        "day" : "April",
                        "amount" : toNumbers(arraprPayBooked).reduce((a, b) => a + b, 0),
                        'color' : 'grey'
                    },
                    {
                        "day" : "May",
                        "amount" : toNumbers(arrmayPayBooked).reduce((a, b) => a + b, 0),
                        'color' : 'green'
                    },
                    {
                        "day" : "June",
                        "amount" : toNumbers(arrjunPayBooked).reduce((a, b) => a + b, 0),
                        'color' : 'red'
                    },
                
                ])
            }
            else if(moment().quarter() === 3){
                // setCurrentQuarterBookedSum(toNumbers(arrjulPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arraugPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrsepPayBooked).reduce((a, b) => a + b, 0))
                // setLastQuarterBookedSum(toNumbers(arraprPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrmayPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrjulPayBooked).reduce((a, b) => a + b, 0))

                // setCurrentQuarterSum(toNumbers(arrjulPay).reduce((a, b) => a + b, 0) + toNumbers(arraugPay).reduce((a, b) => a + b, 0) + toNumbers(arrsepPay).reduce((a, b) => a + b, 0))
                // setLastQuarterSum(toNumbers(arraprPay).reduce((a, b) => a + b, 0) + toNumbers(arrmayPay).reduce((a, b) => a + b, 0) + toNumbers(arrjulPay).reduce((a, b) => a + b, 0))

                setBarChartWeekData([
                    {
                        "day" : "July",
                        "amount" : toNumbers(arrjulPayBooked).reduce((a, b) => a + b, 0),
                        'color' : 'grey'
                    },
                    {
                        "day" : "August",
                        "amount" : toNumbers(arraugPayBooked).reduce((a, b) => a + b, 0),
                        'color' : 'green'
                    },
                    {
                        "day" : "September",
                        "amount" : toNumbers(arrsepPayBooked).reduce((a, b) => a + b, 0),
                        'color' : 'red'
                    },
                
                ])
            }
            else if(moment().quarter() === 4){
                // setLastQuarterBookedSum(toNumbers(arrjulPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arraugPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrsepPayBooked).reduce((a, b) => a + b, 0))
                // setCurrentQuarterBookedSum(toNumbers(arroctPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrnovPayBooked).reduce((a, b) => a + b, 0) + toNumbers(arrdecPayBooked).reduce((a, b) => a + b, 0))
                
                // setCurrentQuarterSum(toNumbers(arroctPay).reduce((a, b) => a + b, 0) + toNumbers(arrnovPay).reduce((a, b) => a + b, 0) + toNumbers(arrdecPay).reduce((a, b) => a + b, 0))
                // setLastQuarterSum(toNumbers(arrjulPay).reduce((a, b) => a + b, 0) + toNumbers(arraugPay).reduce((a, b) => a + b, 0) + toNumbers(arrsepPay).reduce((a, b) => a + b, 0))

                setBarChartWeekData([
                    {
                        "day" : "October",
                        "amount" : toNumbers(arroctPayBooked).reduce((a, b) => a + b, 0),
                        'color' : 'grey'
                    },
                    {
                        "day" : "November",
                        "amount" : toNumbers(arrnovPayBooked).reduce((a, b) => a + b, 0),
                        'color' : 'green'
                    },
                    {
                        "day" : "December",
                        "amount" : toNumbers(arrdecPayBooked).reduce((a, b) => a + b, 0),
                        'color' : 'red'
                    },
                
                ])
            }
        }
        else if (deliveryT === 'deliveries'){
            if(moment().quarter() === 1){
                setCurrentQuarterAllSum(toNumbers(arrJanPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrfebPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrmarPayAll).reduce((a, b) => a + b, 0))
                // setLastQuarterAllSum(toNumbers(arrJanPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrfebPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrmarPayAll).reduce((a, b) => a + b, 0))
                
                setBarChartWeekData([
                {
                    "day" : "January",
                    "amount" : toNumbers(arrJanPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "February",
                    "amount" : toNumbers(arrfebPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "March",
                    "amount" : toNumbers(arrmarPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
            
            ])
        }
        else if(moment().quarter() === 2){
            setCurrentQuarterAllSum(toNumbers(arraprPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrmayPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrjulPayAll).reduce((a, b) => a + b, 0))
            setLastQuarterAllSum(toNumbers(arrJanPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrfebPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrmarPayAll).reduce((a, b) => a + b, 0))

            setBarChartWeekData([
                {
                    "day" : "April",
                    "amount" : toNumbers(arraprPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "May",
                    "amount" : toNumbers(arrmayPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "June",
                    "amount" : toNumbers(arrjunPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
            
            ])
        }
        else if(moment().quarter() === 3){
            setCurrentQuarterAllSum(toNumbers(arrjulPayAll).reduce((a, b) => a + b, 0) + toNumbers(arraugPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrsepPayAll).reduce((a, b) => a + b, 0))
            setLastQuarterAllSum(toNumbers(arrJanPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrfebPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrmarPayAll).reduce((a, b) => a + b, 0))

            setBarChartWeekData([
                {
                    "day" : "July",
                    "amount" : toNumbers(arrjulPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "August",
                    "amount" : toNumbers(arraugPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "September",
                    "amount" : toNumbers(arrsepPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
            
            ])
        }
        else if(moment().quarter() === 4){
            setCurrentQuarterAllSum(toNumbers(arroctPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrnovPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrdecPayAll).reduce((a, b) => a + b, 0))
            setLastQuarterAllSum(toNumbers(arrJanPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrfebPayAll).reduce((a, b) => a + b, 0) + toNumbers(arrmarPayAll).reduce((a, b) => a + b, 0))
            
            setBarChartWeekData([
                {
                    "day" : "October",
                    "amount" : toNumbers(arroctPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "November",
                    "amount" : toNumbers(arrnovPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "December",
                    "amount" : toNumbers(arrdecPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
            
            ])
        }
        }
    }
       

    const updatePageYearly = (deliveryT) =>{ 
        // for last year
        const toNumbers = arr => arr.map(Number);

        const yearStart = moment().startOf('year')
        const yearEnd = moment().endOf('year')

        const quotedForYear = quoted.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(yearStart), moment(yearEnd), 'days', '[]')})
        const bookedForYear = bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(yearStart), moment(yearEnd), 'days', '[]')})
        
        const lastYearStart = moment().subtract(1,'years').startOf('year')
        const lastYearEnd = moment().subtract(1,'years').endOf('year')
       
        const quotedForLastYear = quoted.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(lastYearStart), moment(lastYearEnd), 'days', '[]')})
        const bookedForLastYear = bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(lastYearStart), moment(lastYearEnd), 'days', '[]')})
        // const allForLastMonth = all.filter(function (quote) { return moment(quote.time_stamp).isBetween(moment(startOfLastMonth), moment(endOfLastMonth), 'days', '[]')})

        const payQuotedForYear = quotedForYear.map(a => a.amount_due_to_fulfiller);
        const payBookedForYear = bookedForYear.map(a => a.amount_due_to_fulfiller);


       const payQuotedForLastYear = quotedForLastYear.map(a => a.amount_due_to_fulfiller);
       const payBookedForLastYear = bookedForLastYear.map(a => a.amount_due_to_fulfiller);

        setLastYearQuote(toNumbers(payQuotedForLastYear).reduce((a, b) => a + b, 0))
        setLastYearBooked(toNumbers(payBookedForLastYear).reduce((a, b) => a + b, 0))
        
        // for ends year here


        setstartRange(moment().startOf('year'))
        setendRange(moment().startOf('year'))

        const janPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 0 && moment(quote.time_stamp).year() === moment().year()})
        const febPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 1 && moment(quote.time_stamp).year() === moment().year()})
        const marPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 2 && moment(quote.time_stamp).year() === moment().year()})
        const aprPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 3 && moment(quote.time_stamp).year() === moment().year()})
        const mayPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 4 && moment(quote.time_stamp).year() === moment().year()})
        const junPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 5 && moment(quote.time_stamp).year() === moment().year()})
        const julPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 6 && moment(quote.time_stamp).year() === moment().year()})
        const augPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 7 && moment(quote.time_stamp).year() === moment().year()})
        const sepPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 8 && moment(quote.time_stamp).year() === moment().year()})
        const octPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 9 && moment(quote.time_stamp).year() === moment().year()})
        const novPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 10 && moment(quote.time_stamp).year() === moment().year()})
        const decPay =  quoted.filter(function (quote) { return moment(quote.time_stamp).month() === 11 && moment(quote.time_stamp).year() === moment().year()})
        
        const arrJanPay = janPay.map(a => a.amount_due_to_fulfiller);
        const arrfebPay = febPay.map(a => a.amount_due_to_fulfiller);
        const arrmarPay = marPay.map(a => a.amount_due_to_fulfiller);
        const arraprPay = aprPay.map(a => a.amount_due_to_fulfiller);
        const arrmayPay = mayPay.map(a => a.amount_due_to_fulfiller);
        const arrjunPay = junPay.map(a => a.amount_due_to_fulfiller);
        const arrjulPay = julPay.map(a => a.amount_due_to_fulfiller);
        const arraugPay = augPay.map(a => a.amount_due_to_fulfiller);
        const arrsepPay = sepPay.map(a => a.amount_due_to_fulfiller);
        const arroctPay = octPay.map(a => a.amount_due_to_fulfiller);
        const arrnovPay = novPay.map(a => a.amount_due_to_fulfiller);
        const arrdecPay = decPay.map(a => a.amount_due_to_fulfiller);
        

        setjanSum(toNumbers(arrJanPay).reduce((a, b) => a + b, 0))
        setfebSum(toNumbers(arrfebPay).reduce((a, b) => a + b, 0))
        setmarSum(toNumbers(arrmarPay).reduce((a, b) => a + b, 0))
        setaprSum(toNumbers(arraprPay).reduce((a, b) => a + b, 0))
        setmaySum(toNumbers(arrmayPay).reduce((a, b) => a + b, 0))
        setjunSum(toNumbers(arrjunPay).reduce((a, b) => a + b, 0))
        setjulSum(toNumbers(arrjulPay).reduce((a, b) => a + b, 0))
        setaugSum(toNumbers(arraugPay).reduce((a, b) => a + b, 0))
        setsepSum(toNumbers(arrsepPay).reduce((a, b) => a + b, 0))
        setoctSum(toNumbers(arroctPay).reduce((a, b) => a + b, 0))
        setnovSum(toNumbers(arrnovPay).reduce((a, b) => a + b, 0))
        setdecSum(toNumbers(arrdecPay).reduce((a, b) => a + b, 0))

        //booked deliveries
        const janPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 0 && moment(quote.time_stamp).year() === moment().year()})
        const febPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 1 && moment(quote.time_stamp).year() === moment().year()})
        const marPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 2 && moment(quote.time_stamp).year() === moment().year()})
        const aprPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 3 && moment(quote.time_stamp).year() === moment().year()})
        const mayPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 4 && moment(quote.time_stamp).year() === moment().year()})
        const junPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 5 && moment(quote.time_stamp).year() === moment().year()})
        const julPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 6 && moment(quote.time_stamp).year() === moment().year()})
        const augPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 7 && moment(quote.time_stamp).year() === moment().year()})
        const sepPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 8 && moment(quote.time_stamp).year() === moment().year()})
        const octPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 9 && moment(quote.time_stamp).year() === moment().year()})
        const novPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 10 && moment(quote.time_stamp).year() === moment().year()})
        const decPayBooked =  bookedDelivery.filter(function (quote) { return moment(quote.time_stamp).month() === 11 && moment(quote.time_stamp).year() === moment().year()})
        
        const arrJanPayBooked = janPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrfebPayBooked = febPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrmarPayBooked = marPayBooked.map(a => a.amount_due_to_fulfiller);
        const arraprPayBooked = aprPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrmayPayBooked = mayPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrjunPayBooked = junPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrjulPayBooked = julPayBooked.map(a => a.amount_due_to_fulfiller);
        const arraugPayBooked = augPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrsepPayBooked = sepPayBooked.map(a => a.amount_due_to_fulfiller);
        const arroctPayBooked = octPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrnovPayBooked = novPayBooked.map(a => a.amount_due_to_fulfiller);
        const arrdecPayBooked = decPayBooked.map(a => a.amount_due_to_fulfiller);

        setjanSumBooked(toNumbers(arrJanPayBooked).reduce((a, b) => a + b, 0))
        setfebSumBooked(toNumbers(arrfebPayBooked).reduce((a, b) => a + b, 0))
        setmarSumBooked(toNumbers(arrmarPayBooked).reduce((a, b) => a + b, 0))
        setaprSumBooked(toNumbers(arraprPayBooked).reduce((a, b) => a + b, 0))
        setmaySumBooked(toNumbers(arrmayPayBooked).reduce((a, b) => a + b, 0))
        setjunSumBooked(toNumbers(arrjunPayBooked).reduce((a, b) => a + b, 0))
        setjulSumBooked(toNumbers(arrjulPayBooked).reduce((a, b) => a + b, 0))
        setaugSumBooked(toNumbers(arraugPayBooked).reduce((a, b) => a + b, 0))
        setsepSumBooked(toNumbers(arrsepPayBooked).reduce((a, b) => a + b, 0))
        setoctSumBooked(toNumbers(arroctPayBooked).reduce((a, b) => a + b, 0))
        setnovSumBooked(toNumbers(arrnovPayBooked).reduce((a, b) => a + b, 0))
        setdecSumBooked(toNumbers(arrdecPayBooked).reduce((a, b) => a + b, 0))

         // for all deliveries
         const janPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 0 && moment(quote.time_stamp).year() === moment().year()})
         const febPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 1 && moment(quote.time_stamp).year() === moment().year()})
         const marPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 2 && moment(quote.time_stamp).year() === moment().year()})
         const aprPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 3 && moment(quote.time_stamp).year() === moment().year()})
         const mayPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 4 && moment(quote.time_stamp).year() === moment().year()})
         const junPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 5 && moment(quote.time_stamp).year() === moment().year()})
         const julPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 6 && moment(quote.time_stamp).year() === moment().year()})
         const augPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 7 && moment(quote.time_stamp).year() === moment().year()})
         const sepPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 8 && moment(quote.time_stamp).year() === moment().year()})
         const octPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 9 && moment(quote.time_stamp).year() === moment().year()})
         const novPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 10 && moment(quote.time_stamp).year() === moment().year()})
         const decPayAll =  all.filter(function (quote) { return moment(quote.time_stamp).month() === 11 && moment(quote.time_stamp).year() === moment().year()})
         
         const arrJanPayAll = janPayAll.map(a => a.amount_due_to_fulfiller);
         const arrfebPayAll = febPayAll.map(a => a.amount_due_to_fulfiller);
         const arrmarPayAll = marPayAll.map(a => a.amount_due_to_fulfiller);
         const arraprPayAll = aprPayAll.map(a => a.amount_due_to_fulfiller);
         const arrmayPayAll = mayPayAll.map(a => a.amount_due_to_fulfiller);
         const arrjunPayAll = junPayAll.map(a => a.amount_due_to_fulfiller);
         const arrjulPayAll = julPayAll.map(a => a.amount_due_to_fulfiller);
         const arraugPayAll = augPayAll.map(a => a.amount_due_to_fulfiller);
         const arrsepPayAll = sepPayAll.map(a => a.amount_due_to_fulfiller);
         const arroctPayAll = octPayAll.map(a => a.amount_due_to_fulfiller);
         const arrnovPayAll = novPayAll.map(a => a.amount_due_to_fulfiller);
         const arrdecPayAll = decPayAll.map(a => a.amount_due_to_fulfiller);
 
         setjanSumAll(toNumbers(arrJanPayAll).reduce((a, b) => a + b, 0))
         setfebSumAll(toNumbers(arrfebPayAll).reduce((a, b) => a + b, 0))
         setmarSumAll(toNumbers(arrmarPayAll).reduce((a, b) => a + b, 0))
         setaprSumAll(toNumbers(arraprPayAll).reduce((a, b) => a + b, 0))
         setmaySumAll(toNumbers(arrmayPayAll).reduce((a, b) => a + b, 0))
         setjunSumAll(toNumbers(arrjunPayAll).reduce((a, b) => a + b, 0))
         setjulSumAll(toNumbers(arrjulPayAll).reduce((a, b) => a + b, 0))
         setaugSumAll(toNumbers(arraugPayAll).reduce((a, b) => a + b, 0))
         setsepSumAll(toNumbers(arrsepPayAll).reduce((a, b) => a + b, 0))
         setoctSumAll(toNumbers(arroctPayAll).reduce((a, b) => a + b, 0))
         setnovSumAll(toNumbers(arrnovPayAll).reduce((a, b) => a + b, 0))
         setdecSumAll(toNumbers(arrdecPayAll).reduce((a, b) => a + b, 0))
        
        //  setCurrentQuarterSum(toNumbers(arrJanPay).reduce((a, b) => a + b, 0) + toNumbers(arrfebPay).reduce((a, b) => a + b, 0) + toNumbers(arrmarPay).reduce((a, b) => a + b, 0))
        const sumForYear = toNumbers(payQuotedForYear).reduce((a, b) => a + b, 0) 
        // setCurrentQuarterSum(sumForYear)
        const sumForLastYear = toNumbers(payQuotedForLastYear).reduce((a, b) => a + b, 0)
        // setLastQuarterSum(sumForLastYear)
        if(sumForYear > sumForLastYear){
            sethighest('now')
            const percentage = (sumForYear - sumForLastYear) / (sumForYear + sumForLastYear) * 100
            setpercent(percentage)
        }
        else if (sumForYear < sumForLastYear){
                sethighest('last')
                const percentage = (sumForLastYear - sumForYear) / (sumForLastYear + sumForYear) * 100
                setpercent(percentage)
            }
        else{
                sethighest('equal')
                setpercent(0)
            }
        
        const sumForYearBooked = toNumbers(payBookedForYear).reduce((a, b) => a + b, 0) 
        // setCurrentQuarterBookedSum(sumForQuarterBooked)
        const sumForLastYearBooked = toNumbers(payBookedForLastYear).reduce((a, b) => a + b, 0)
        // setLastQuarterBookedSum(sumForLastQuarterBooked)

        if(sumForYearBooked > sumForLastYearBooked){
                sethighestBooked('now')
                const percentage = (sumForYearBooked - sumForLastYearBooked) / (sumForYearBooked + sumForLastYearBooked) * 100
                setpercentBooked(percentage)
            }
        else if (sumForYearBooked < sumForLastYearBooked){
                sethighestBooked('last')
                const percentage = (sumForLastYearBooked - sumForYearBooked) / (sumForYearBooked + sumForLastYearBooked) * 100
                setpercentBooked(percentage)
            }
        else{
                sethighestBooked('equal')
                setpercentBooked(0)
            }


         if (deliveryT === 'quoted_deliveries'){
            setBarChartWeekData([
                {
                    "day" : "Jan",
                    "amount" : toNumbers(arrJanPay).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "Feb",
                    "amount" : toNumbers(arrfebPay).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Mar",
                    "amount" : toNumbers(arrmarPay).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Apr",
                    "amount" : toNumbers(arraprPay).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "May",
                    "amount" : toNumbers(arrmayPay).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Jun",
                    "amount" : toNumbers(arrjunPay).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Jul",
                    "amount" : toNumbers(arrjulPay).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "Aug",
                    "amount" : toNumbers(arraugPay).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Sept",
                    "amount" : toNumbers(arrsepPay).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Oct",
                    "amount" : toNumbers(arroctPay).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "Nov",
                    "amount" : toNumbers(arrnovPay).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Dec",
                    "amount" : toNumbers(arrdecPay).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
            
            ])
        }
        else if (deliveryT === 'booked_deliveries'){
            setBarChartWeekData([
                {
                    "day" : "Jan",
                    "amount" : toNumbers(arrJanPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "Feb",
                    "amount" : toNumbers(arrfebPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Mar",
                    "amount" : toNumbers(arrmarPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Apr",
                    "amount" : toNumbers(arraprPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "May",
                    "amount" : toNumbers(arrmayPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Jun",
                    "amount" : toNumbers(arrjunPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Jul",
                    "amount" : toNumbers(arrjulPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "Aug",
                    "amount" : toNumbers(arraugPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Sept",
                    "amount" : toNumbers(arrsepPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Oct",
                    "amount" : toNumbers(arroctPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "Nov",
                    "amount" : toNumbers(arrnovPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Dec",
                    "amount" : toNumbers(arrdecPayBooked).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
            
            ])
        }
        else if (deliveryT === 'deliveries'){
            setBarChartWeekData([
                {
                    "day" : "Jan",
                    "amount" : toNumbers(arrJanPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "Feb",
                    "amount" : toNumbers(arrfebPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Mar",
                    "amount" : toNumbers(arrmarPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Apr",
                    "amount" : toNumbers(arraprPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "May",
                    "amount" : toNumbers(arrmayPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Jun",
                    "amount" : toNumbers(arrjunPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Jul",
                    "amount" : toNumbers(arrjulPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "Aug",
                    "amount" : toNumbers(arraugPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Sept",
                    "amount" : toNumbers(arrsepPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
                {
                    "day" : "Oct",
                    "amount" : toNumbers(arroctPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'grey'
                },
                {
                    "day" : "Nov",
                    "amount" : toNumbers(arrnovPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'green'
                },
                {
                    "day" : "Dec",
                    "amount" : toNumbers(arrdecPayAll).reduce((a, b) => a + b, 0),
                    'color' : 'red'
                },
            
            ])
        }
        
    }

function getWeeksStartAndEndInMonth(month, year) {
        let weeks = [],
            firstDate = new Date(year, month, 1),
            lastDate = new Date(year, month + 1, 0),
            numDays = lastDate.getDate();
    
        let start = 1;
        let end = 7 - firstDate.getDay();
        if (start === 'monday') {
            if (firstDate.getDay() === 0) {
                end = 1;
            } else {
                end = 7 - firstDate.getDay() + 1;
            }
        }
        while (start <= numDays) {
            weeks.push({start: start, end: end});
            start = end + 1;
            end = end + 7;
            end = start === 1 && end === 8 ? 1 : end;
            if (end > numDays) {
                end = numDays;
            }
        }
        return weeks;
    }

    const weeksLastMonth = getWeeksStartAndEndInMonth((moment().month() - 1), moment().year())


    const weeks = getWeeksStartAndEndInMonth(moment().month(), moment().year())

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        
        return [day, month, year].join('-');
    }

    function formatDateReverse(date) {
		var d = date.substr(0, 2);
		var m = date.substr(3, 2)
		var y = date.substr(6, 4);
        return [y, m, d].join('-');
    }

    const [ShowCustom, setShowCustom] = useState(false)

    const [firstday, setfirstday] = useState('')
    const [lastday, setlastday] = useState('')

    const [firstday_lastweek, setfirstday_lastweek] = useState("")
    const [lastday_lastweek, setlastday_lastweek] = useState('')

    const [monday, setMonday] = useState('') 
    const [tuesday, setTuesday] = useState('')
    const [wednesday, setWednesday] = useState('')
    const [thurday, setThurday] = useState('')
    const [friday, setFriday] = useState('')
    const [saturday, setSaturday] = useState('')
    const [sunday, setSunday] = useState('')

    const [mondayBooked, setMondayBooked] = useState('') 
    const [tuesdayBooked, setTuesdayBooked] = useState('')
    const [wednesdayBooked, setWednesdayBooked] = useState('')
    const [thurdayBooked, setThurdayBooked] = useState('')
    const [fridayBooked, setFridayBooked] = useState('')
    const [saturdayBooked, setSaturdayBooked] = useState('')
    const [sundayBooked, setSundayBooked] = useState('')

    const [mondayAll, setMondayAll] = useState('') 
    const [tuesdayAll, setTuesdayAll] = useState('')
    const [wednesdayAll, setWednesdayAll] = useState('')
    const [thurdayAll, setThurdayAll] = useState('')
    const [fridayAll, setFridayAll] = useState('')
    const [saturdayAll, setSaturdayAll] = useState('')
    const [sundayAll, setSundayAll] = useState('')

    const [mondayLast, setMondayLast] = useState('') 
    const [tuesdayLast, setTuesdayLast] = useState('')
    const [wednesdayLast, setWednesdayLast] = useState('')
    const [thurdayLast, setThurdayLast] = useState('')
    const [fridayLast, setFridayLast] = useState('')
    const [saturdayLast, setSaturdayLast] = useState('')
    const [sundayLast, setSundayLast] = useState('')

    const [mondayBookedLast, setMondayBookedLast] = useState('') 
    const [tuesdayBookedLast, setTuesdayBookedLast] = useState('')
    const [wednesdayBookedLast, setWednesdayBookedLast] = useState('')
    const [thurdayBookedLast, setThurdayBookedLast] = useState('')
    const [fridayBookedLast, setFridayBookedLast] = useState('')
    const [saturdayBookedLast, setSaturdayBookedLast] = useState('')
    const [sundayBookedLast, setSundayBookedLast] = useState('')

    const [mondayAllLast, setMondayAllLast] = useState('') 
    const [tuesdayAllLast, setTuesdayAllLast] = useState('')
    const [wednesdayAllLast, setWednesdayAllLast] = useState('')
    const [thurdayAllLast, setThurdayAllLast] = useState('')
    const [fridayAllLast, setFridayAllLast] = useState('')
    const [saturdayAllLast, setSaturdayAllLast] = useState('')
    const [sundayAllLast, setSundayAllLast] = useState('')


    const [currentQuarterSum, setCurrentQuarterSum] = useState('')
    const [lastQuarterSum, setLastQuarterSum] = useState('')
    const [currentQuarterBookedSum, setCurrentQuarterBookedSum] = useState('')
    const [lastQuarterBookedSum, setLastQuarterBookedSum] = useState('')

    const [currentQuarterAllSum, setCurrentQuarterAllSum] = useState('')
    const [lastQuarterAllSum, setLastQuarterAllSum] = useState('')

    const [week1Sum, setWeek1Sum] = useState('')
    const [week2Sum, setWeek2Sum] = useState('')
    const [week3Sum, setWeek3Sum] = useState('')
    const [week4Sum, setWeek4Sum] = useState('')
    const [week5Sum, setWeek5Sum] = useState('')

    const [week1SumBooked, setWeek1SumBooked] = useState('')
    const [week2SumBooked, setWeek2SumBooked] = useState('')
    const [week3SumBooked, setWeek3SumBooked] = useState('')
    const [week4SumBooked, setWeek4SumBooked] = useState('')
    const [week5SumBooked, setWeek5SumBooked] = useState('')

    const [week1SumAll, setWeek1SumAll] = useState('')
    const [week2SumAll, setWeek2SumAll] = useState('')
    const [week3SumAll, setWeek3SumAll] = useState('')
    const [week4SumAll, setWeek4SumAll] = useState('')
    const [week5SumAll, setWeek5SumAll] = useState('')


    const [janSum, setjanSum] = useState('')
    const [febSum, setfebSum] = useState('')
    const [marSum, setmarSum] = useState('')
    const [aprSum, setaprSum] = useState('')
    const [maySum, setmaySum] = useState('')
    const [junSum, setjunSum] = useState('')
    const [julSum, setjulSum] = useState('')
    const [augSum, setaugSum] = useState('')
    const [sepSum, setsepSum] = useState('')
    const [octSum, setoctSum] = useState('')
    const [novSum, setnovSum] = useState('')
    const [decSum, setdecSum] = useState('')

    const [janSumBooked, setjanSumBooked] = useState('')
    const [febSumBooked, setfebSumBooked] = useState('')
    const [marSumBooked, setmarSumBooked] = useState('')
    const [aprSumBooked, setaprSumBooked] = useState('')
    const [maySumBooked, setmaySumBooked] = useState('')
    const [junSumBooked, setjunSumBooked] = useState('')
    const [julSumBooked, setjulSumBooked] = useState('')
    const [augSumBooked, setaugSumBooked] = useState('')
    const [sepSumBooked, setsepSumBooked] = useState('')
    const [octSumBooked, setoctSumBooked] = useState('')
    const [novSumBooked, setnovSumBooked] = useState('')
    const [decSumBooked, setdecSumBooked] = useState('')

    const [janSumAll, setjanSumAll] = useState('')
    const [febSumAll, setfebSumAll] = useState('')
    const [marSumAll, setmarSumAll] = useState('')
    const [aprSumAll, setaprSumAll] = useState('')
    const [maySumAll, setmaySumAll] = useState('')
    const [junSumAll, setjunSumAll] = useState('')
    const [julSumAll, setjulSumAll] = useState('')
    const [augSumAll, setaugSumAll] = useState('')
    const [sepSumAll, setsepSumAll] = useState('')
    const [octSumAll, setoctSumAll] = useState('')
    const [novSumAll, setnovSumAll] = useState('')
    const [decSumAll, setdecSumAll] = useState('')

    const [barChartWeekData, setBarChartWeekData] = useState([])
    const [barTableWeekData, setBarTableWeekData] = useState([])
    
const [deliveryType, setDeliveryType] = useState('quoted_deliveries')
    
    const auth = useContext(AuthContext);

    const numberWithCommas = new Intl.NumberFormat("en-GB")
    const [showLogout, setShowLogout] = useState(false)
    
    const logMeOut = ()=>{
        auth.logout();
         history.push('/') 
     }
    const [activeState, setactiveState] = useState('overview')
    const [chartType, setchartType] = useState('barchart');
    
    const [period, setperiod] = useState("week")

    const refreshenUp = (periodType, deliverieTyp) =>{
        if(periodType === 'week'){
            updatePage(deliverieTyp, quoted);
        }
        else if(periodType === 'month'){
            updatePageMonth(deliverieTyp)  
        }
        else if(periodType === 'quarter'){
            updatePageQuarterly(deliverieTyp);
        }
        else if(periodType === 'year'){
            updatePageYearly(deliverieTyp);
        }
        else if(periodType === 'customShow'){
        }
        else{
            setstartRange(periodType[0]);
            setendRange(periodType[1]);
            updatePageCustom(periodType, deliverieTyp)
        }
    }
    var dated = new Date();
    var firstDayMonth = new Date(dated.getFullYear(), dated.getMonth(), 1);
    var lastDayMonth = new Date(dated.getFullYear(), dated.getMonth() + 1, 0);

    var firstDayLastMonth = new Date(dated.getFullYear(), dated.getMonth() - 1, 1);
    var lastDayLastMonth = new Date(dated.getFullYear(), dated.getMonth(), 0);
    return (
        <div className="fulfillers">
            {loadingData ? (
                <div
                    style={{
                        width: "100%",
                        height: "100vh",
                        backgroundColor: "none",
                        zIndex: "300",
                        position: "fixed",
                        top: "0",
                        paddingTop: "5px",
                    }}>
                <Box sx={{ width: "100%" }}>
                    <LinearProgress color="success" />
                </Box>
                </div>
            ) : null}
            <div className="chartMob">
                {/* <div className='backBtn' style={{display:'flex', marginBottom:'10px', gap:'15px'}}>
                    <IoMdArrowRoundBack onClick={()=>history.goBack()} size='18px'/>
                    <h3>Settlement</h3>
                </div>   */}
            </div>
            
            <div className="topSettle">
                    <div className={activeState === 'overview' ? "topActive" : "topInactive" } 
                        onClick={()=>setactiveState('overview')}
                    >Overview</div>

                    <div className={activeState === 'report' ? "topActive" : "topInactive" }
                        onClick={()=>setactiveState('report')}
                    >Report</div>
                </div>

            {/* <div className='btnAssigns'>
                <div>
                </div>
                <div className="btnAssign" 
                    onClick={() => {
                        setShowLogout(true)
                    }}>
                    <BiLogOut size='18px'/> Logout
                </div>
            </div>  */}
            <>
            <div className="settlements">
                {/* <div className="rightSettle">
                    <div className="settleTabHead">My settlement</div>
                    <div className={activeState === 'overview' ? "settleTabActive" : "settleTab" } 
                        onClick={()=>setactiveState('overview')}
                    >Overview</div>

                    <div className={activeState === 'report' ? "settleTabActive" : "settleTab" }
                        onClick={()=>setactiveState('report')}
                    >Report</div>
                </div> */}

                <div className="mainSettle">
                    <Grid container>
                    <div className="leftSettleHead">
                        <span>{ activeState === 'overview' ? 'Overview': 'Report'}</span>
                        <span>
                            <select className="typeTime" onChange={(e)=>{refreshenUp(e.target.value, deliveryType); setperiod(e.target.value)}}>
                                <option value="week">Week</option>
                                <option value="month">Month</option>
                                <option value="quarter">Quarter</option>
                                <option value="year">Year</option>
                                <option value="customShow">Custom</option>
                            </select>
                        </span>
                    </div>
                    {/* <span onClick={()=>getSelectedRange}> show</span> */}

                    {
                        period === 'customShow' ?
                            <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                                <div style={{width:'200px'}}>
                                    <DateRangePickerComponent 
                                        placeholder="Enter Date Range"
                                        format="dd-MMM-yyyy"
                                        id="custom_daterangepicker" 
                                        change={(e)=>{
                                            refreshenUp(e.value, deliveryType);
                                            setperiod(e.value)
                                        }}
                                    />
                                </div>
                            </div>
                            :
                            null
                    }
                    
                    <div className="leftSettleBody">
                        {/* card start here */}
                        <div className="leftSettleTopCard">
                            <div className="leftSettleCardTop">
                                
                                {
                                    period === 'week' ?
                                    <div className="carded">
                                    <div className="currentWeek">
                                        <div className="weekk"> Current week</div>
                                        <div className="weekSpan">
                                            {moment(firstday).format("ddd, Do MMM YYYY") + " - " + moment(lastday).format("ddd, Do MMM YYYY")}
                                        </div>
                                    </div>
                                    <div className="quotedRevenue">
                                        <div className="quotedRevenueInner">
                                            <div className="revText">
                                                <span>
                                                    Quoted deliveries net revenue
                                                </span>
                                                <span className='toolTips' data-tip="Quoted deliveries net revenue as Gross revenue (Total revenue from the delivery you quoted for)minus the platform commission" tabindex="1" style={{marginLeft:'10px'}}>
                                                    <BsFillQuestionCircleFill/>
                                                </span>
                                                {/* <span class="toolTips" data-tip="By adding this class you can provide almost any element with a tool tip." tabindex="1">tool</span>  */}
                                            </div>
                                            <div className="revAmount">
                                                <span>
                                                    &#8358;
                                                    {numberWithCommas.format(monday + tuesday + wednesday + thurday + friday + saturday + sunday)}
                                                </span>
                                                <span style={{color: highest === 'now' ? 'var(--main)' : highest === 'last' ? 'red' : 'blue', fontSize:"16px", marginLeft:'20px'}}>
                                                   {
                                                        highest === 'now' ? 
                                                            <HiArrowUp size='20px' style={{marginRight:'5px'}}/> : 
                                                        highest === 'last' ? 
                                                            <HiArrowDown size='20px' style={{marginRight:'5px'}}/>
                                                            :
                                                            <ImEqualizer2 size='20px' style={{marginRight:'5px'}}/>

                                                            // <span data-tip data-for="globaldes">
                                                            // <ImEqualizer2 className = "global-class-name-icon" size='20px' style={{marginRight:'5px'}}/>
                                                            //     <ReactTooltip id="globaldes" aria-haspopup="true" role="example" type="light">
                                                            //     <p>Booked deliveries revenue is same as last week</p>
                                                            //     </ReactTooltip>
                                                            // </span>
                                                   }
                                                    <span>{percent.toFixed(2)}%</span>
                                                </span>
                                                
                                            </div>
                                        </div>

                                        <div className="quotedRevenueInner1">
                                            <div className="revText">
                                                <span>
                                                    Booked deliveries net revenue
                                                </span>
                                                <span className='toolTips' data-tip="Booked deliveries net revenue as Gross revenue (Total revenue from the deliveries booked against you, which payment has been paid to you directly" tabindex="1" style={{marginLeft:'10px'}}>
                                                    <BsFillQuestionCircleFill/>
                                                </span>
                                            </div>
                                            <div className="revAmount">
                                                <span>
                                                    &#8358;
                                                    {/* {numberWithCommas.format(currentQuarterSum)} */}
                                                    {numberWithCommas.format(mondayBooked + tuesdayBooked + wednesdayBooked + thurdayBooked + fridayBooked + saturdayBooked + sundayBooked)}
                                                </span>
                                                <span style={{color: highestBooked === 'now' ? 'var(--main)' : highestBooked === 'last' ? 'red' : 'blue', fontSize:"16px", marginLeft:'20px'}}>
                                                   {
                                                        highestBooked === 'now' ? 
                                                            <HiArrowUp size='20px' style={{marginRight:'5px'}}/> : 
                                                        highestBooked === 'last' ? 
                                                            <HiArrowDown size='20px' style={{marginRight:'5px'}}/>
                                                            :
                                                            <ImEqualizer2 size='20px' style={{marginRight:'5px'}}/>
                                                   }
                                                    <span>{percentBooked.toFixed(2)}%</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                period === 'month' ?
                                    <div className="carded">
                                    <div className="currentWeek">
                                        <div className="weekk"> Current month</div>
                                        <div className="weekSpan">
                                            { moment(firstDayMonth).format("ddd, Do MMM YYYY") +  " - " + moment(lastDayMonth).format("ddd, Do MMM YYYY")}
                                        </div>
                                    </div>
                                    <div className="quotedRevenue">
                                        <div className="quotedRevenueInner">
                                            <div className="revText">
                                                <span>
                                                    Quoted deliveries net revenue
                                                </span>
                                                <span className='toolTips' data-tip="Quoted deliveries net revenue as Gross revenue (Total revenue from the delivery you quoted for)minus the platform commission" tabindex="1" style={{marginLeft:'10px'}}>
                                                    <BsFillQuestionCircleFill/>
                                                </span>
                                            </div>
                                            <div className="revAmount">
                                                <span>
                                                    &#8358;
                                                    {numberWithCommas.format(week1Sum + week2Sum + week3Sum + week4Sum + week5Sum)}
                                                </span>
                                                <span style={{color: highest === 'now' ? 'var(--main)' : highest === 'last' ? 'red' : 'blue', fontSize:"16px", marginLeft:'20px'}}>
                                                   {
                                                        highest === 'now' ? 
                                                            <HiArrowUp size='20px' style={{marginRight:'5px'}}/> : 
                                                        highest === 'last' ? 
                                                            <HiArrowDown size='20px' style={{marginRight:'5px'}}/>
                                                            :
                                                            <ImEqualizer2 size='20px' style={{marginRight:'5px'}}/>
                                                   }
                                                    <span>{percent.toFixed(2)}%</span>
                                                </span>
                                                
                                            </div>
                                        </div>

                                        <div className="quotedRevenueInner1">
                                            <div className="revText">
                                                <span>
                                                    Booked deliveries net revenue
                                                </span>
                                                <span className='toolTips' data-tip="Booked deliveries net revenue as Gross revenue (Total revenue from the deliveries booked against you, which payment has been paid to you directly" tabindex="1" style={{marginLeft:'10px'}}>
                                                    <BsFillQuestionCircleFill/>
                                                </span>
                                            </div>
                                            <div className="revAmount">
                                                <span>
                                                    &#8358;
                                                    {numberWithCommas.format(week1SumBooked + week2SumBooked + week3SumBooked + week4SumBooked + week5SumBooked)}
                                                </span>
                                                <span style={{color: highestBooked === 'now' ? 'var(--main)' : highestBooked === 'last' ? 'red' : 'blue', fontSize:"16px", marginLeft:'20px'}}>
                                                   {
                                                        highestBooked === 'now' ? 
                                                            <HiArrowUp size='20px' style={{marginRight:'5px'}}/> : 
                                                        highestBooked === 'last' ? 
                                                            <HiArrowDown size='20px' style={{marginRight:'5px'}}/>
                                                            :
                                                            <ImEqualizer2 size='20px' style={{marginRight:'5px'}}/>
                                                   }
                                                    <span>{percentBooked.toFixed(2)}%</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                period === 'quarter' ?
                                    <div className="carded">
                                    <div className="currentWeek">
                                        <div className="weekk"> Current Quarter</div>
                                        <div className="weekSpan">
                                            {moment(thisQuarterStartDate).format("ddd, Do MMM YYYY") + " - " + moment(thisQuarterEndDate).format("ddd, Do MMM YYYY")}
                                        </div>
                                    </div>
                                    <div className="quotedRevenue">
                                        <div className="quotedRevenueInner">
                                            <div className="revText">
                                                <span>
                                                    Quoted deliveries net revenue
                                                </span>
                                                <span className='toolTips' data-tip="Quoted deliveries net revenue as Gross revenue (Total revenue from the delivery you quoted for)minus the platform commission" tabindex="1" style={{marginLeft:'10px'}}>
                                                    <BsFillQuestionCircleFill/>
                                                </span>
                                            </div>
                                            <div className="revAmount">
                                                <span>
                                                    &#8358;
                                                    {numberWithCommas.format(currentQuarterSum)}
                                                </span>
                                                <span style={{color: highest === 'now' ? 'var(--main)' : highest === 'last' ? 'red' : 'blue', fontSize:"16px", marginLeft:'20px'}}>
                                                   {
                                                        highest === 'now' ? 
                                                            <HiArrowUp size='20px' style={{marginRight:'5px'}}/> : 
                                                        highest === 'last' ? 
                                                            <HiArrowDown size='20px' style={{marginRight:'5px'}}/>
                                                            :
                                                            <ImEqualizer2 size='20px' style={{marginRight:'5px'}}/>
                                                   }
                                                    <span>{percent.toFixed(2)}%</span>
                                                </span>
                                                
                                            </div>
                                        </div>

                                        <div className="quotedRevenueInner1">
                                            <div className="revText">
                                                <span>
                                                    Booked deliveries net revenue
                                                </span>
                                                <span className='toolTips' data-tip="Booked deliveries net revenue as Gross revenue (Total revenue from the deliveries booked against you, which payment has been paid to you directly" tabindex="1" style={{marginLeft:'10px'}}>
                                                    <BsFillQuestionCircleFill/>
                                                </span>
                                            </div>
                                            <div className="revAmount">
                                                <span>
                                                    &#8358;
                                                    {/* {numberWithCommas.format(currentQuarterSum)} */}
                                                    {numberWithCommas.format(currentQuarterBookedSum)}
                                                </span>
                                                <span style={{color: highestBooked === 'now' ? 'var(--main)' : highestBooked === 'last' ? 'red' : 'blue', fontSize:"16px", marginLeft:'20px'}}>
                                                   {
                                                        highestBooked === 'now' ? 
                                                            <HiArrowUp size='20px' style={{marginRight:'5px'}}/> : 
                                                        highestBooked === 'last' ? 
                                                            <HiArrowDown size='20px' style={{marginRight:'5px'}}/>
                                                            :
                                                            <ImEqualizer2 size='20px' style={{marginRight:'5px'}}/>
                                                   }
                                                    <span>{percentBooked.toFixed(2)}%</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                period === 'year' ?
                                    <div className="carded">
                                    <div className="currentWeek">
                                        <div className="weekk"> Current year</div>
                                        <div className="weekSpan">
                                            {moment(firstDayOfTheYear).format("ddd, Do MMM YYYY") + " - " + moment(lastDayOfTheYear).format("ddd, Do MMM YYYY")}
                                        </div>
                                    </div>
                                    <div className="quotedRevenue">
                                        <div className="quotedRevenueInner">
                                            <div className="revText">
                                                <span>
                                                    Quoted deliveries net revenue
                                                </span>
                                                <span className='toolTips' data-tip="Quoted deliveries net revenue as Gross revenue (Total revenue from the delivery you quoted for)minus the platform commission" tabindex="1" style={{marginLeft:'10px'}}>
                                                    <BsFillQuestionCircleFill/>
                                                </span>
                                            </div>
                                            <div className="revAmount">
                                                <span>
                                                    &#8358;
                                                    {numberWithCommas.format(janSum + febSum + marSum + aprSum + maySum + junSum + julSum + augSum + sepSum + octSum + novSum + decSum)}
                                                </span> 
                                                <span style={{color: highest === 'now' ? 'var(--main)' : highest === 'last' ? 'red' : 'blue', fontSize:"16px", marginLeft:'20px'}}>
                                                   {
                                                        highest === 'now' ? 
                                                            <HiArrowUp size='20px' style={{marginRight:'5px'}}/> : 
                                                        highest === 'last' ? 
                                                            <HiArrowDown size='20px' style={{marginRight:'5px'}}/>
                                                            :
                                                            <ImEqualizer2 size='20px' style={{marginRight:'5px'}}/>
                                                   }
                                                    <span>{percent.toFixed(2)}%</span>
                                                </span>
                                                
                                            </div>
                                        </div>

                                        <div className="quotedRevenueInner1">
                                            <div className="revText">
                                                <span>
                                                    Booked deliveries net revenue
                                                </span>
                                                <span className='toolTips' data-tip="Booked deliveries net revenue as Gross revenue (Total revenue from the deliveries booked against you, which payment has been paid to you directly" tabindex="1" style={{marginLeft:'10px'}}>
                                                    <BsFillQuestionCircleFill/>
                                                </span>
                                            </div>
                                            <div className="revAmount">
                                                <span>
                                                    &#8358;
                                                    {/* {numberWithCommas.format(currentQuarterSum)} */}
                                                    {numberWithCommas.format(janSumBooked + febSumBooked + marSumBooked + aprSumBooked + maySumBooked + junSumBooked + julSumBooked + augSumBooked + sepSumBooked + octSumBooked + novSumBooked + decSumBooked)}
                                                </span>
                                                <span style={{color: highestBooked === 'now' ? 'var(--main)' : highestBooked === 'last' ? 'red' : 'blue', fontSize:"16px", marginLeft:'20px'}}>
                                                   {
                                                        highestBooked === 'now' ? 
                                                            <HiArrowUp size='20px' style={{marginRight:'5px'}}/> : 
                                                        highestBooked === 'last' ? 
                                                            <HiArrowDown size='20px' style={{marginRight:'5px'}}/>
                                                            :
                                                            <ImEqualizer2 size='20px' style={{marginRight:'5px'}}/>
                                                   }
                                                    <span>{percentBooked.toFixed(2)}%</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                                }
                                
                                {
                                    activeState === 'report' ?
                                        <div className="carded">
                                        <div className="currentWeek">
                                            {
                                                period === 'week' ?
                                                    <>
                                                        <div className="weekk"> Last week</div>
                                                        <div className="weekSpan">
                                                            {moment(firstday_lastweek).format("ddd, Do MMM YYYY") + " - " + moment(lastday_lastweek).format("ddd, Do MMM YYYY")}
                                                        </div>
                                                    </>
                                                :
                                                period === 'month' ?
                                                    <>
                                                        <div className="weekk"> Last month</div>
                                                        <div className="weekSpan">
                                                            { moment(firstDayLastMonth).format("ddd, Do MMM YYYY") +  " - " + moment(lastDayLastMonth).format("ddd, Do MMM YYYY")}
                                                        </div>
                                                    </>
                                                :
                                                period === 'quarter' ?
                                                    <>
                                                        <div className="weekk"> Last quarter</div>
                                                        <div className="weekSpan">
                                                            {moment(lastQuarterStartDate).format("ddd, Do MMM YYYY") + " - " + moment(lastQuarterEndDate).format("ddd, Do MMM YYYY")}
                                                        </div>
                                                    </>
                                                :
                                                period === 'year' ?
                                                    <>
                                                        <div className="weekk"> Last year</div>
                                                        <div className="weekSpan">
                                                            {moment(firstDayOfLastYear).format("ddd, Do MMM YYYY") + " - " + moment(lastDayOfLastYear).format("ddd, Do MMM YYYY")}
                                                        </div>
                                                    </>
                                                :
                                                period === 'custom' ?
                                                    <>
                                                        <div className="weekk"> customer</div>
                                                        <div className="weekSpan">
                                                            {moment(firstday_lastweek).format("ddd, Do MMM YYYY") + " - " + moment(lastday_lastweek).format("ddd, Do MMM YYYY")}
                                                        </div>
                                                    </>
                                                :
                                                null
                                            }
                                        </div>
                                        <div className="quotedRevenue">
                                            <div className="quotedRevenueInner">
                                                <div className="revText">
                                                    <span>
                                                        Quoted deliveries net revenue
                                                    </span>
                                                    <span className='toolTips' data-tip="Quoted deliveries net revenue as Gross revenue (Total revenue from the delivery you quoted for)minus the platform commission" tabindex="1" style={{marginLeft:'10px'}}>
                                                    <BsFillQuestionCircleFill/>
                                                </span>
                                                </div>
                                                {
                                                    period === 'week' ?
                                                    <div className="revAmount">
                                                        <span>
                                                            &#8358;
                                                            {/* {numberWithCommas.format(currentQuarterSum)} */}
                                                            {numberWithCommas.format(mondayLast + tuesdayLast + wednesdayLast + thurdayLast + fridayLast + saturdayLast + sundayLast)}
                                                        </span>
                                                        <span style={{color:'var(--main)', fontSize:"16px", marginLeft:'20px'}}>
                                                        
                                                        </span>
                                                    </div>
                                                    :
                                                    period === 'month' ?
                                                    <div className="revAmount">
                                                        <span>
                                                            &#8358;
                                                            {numberWithCommas.format(LastMonthQuote)}
                                                        </span>
                                                        <span style={{color:'var(--main)', fontSize:"16px", marginLeft:'20px'}}>
                                                        
                                                        </span>
                                                    </div>
                                                    :
                                                    period === 'quarter' ?
                                                    <div className="revAmount">
                                                        <span>
                                                            &#8358;
                                                            {numberWithCommas.format(lastQuarterSum)}
                                                        </span>
                                                        <span style={{color:'var(--main)', fontSize:"16px", marginLeft:'20px'}}>
                                                        
                                                        </span>
                                                    </div>
                                                    :
                                                    period === 'year' ?
                                                    <div className="revAmount">
                                                        <span>
                                                            &#8358;
                                                            {numberWithCommas.format(LastYearQuote)}
                                                        </span>
                                                        <span style={{color:'var(--main)', fontSize:"16px", marginLeft:'20px'}}>
                                                        
                                                        </span>
                                                    </div>
                                                    :
                                                    period === 'custom' ?
                                                    <div className="revAmount">
                                                        <span>
                                                            &#8358;
                                                            {/* {numberWithCommas.format(currentQuarterSum)} */}
                                                        </span>
                                                        <span style={{color:'var(--main)', fontSize:"16px", marginLeft:'20px'}}>
                                                        
                                                        </span>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                
                                            </div>

                                            <div className="quotedRevenueInner1">
                                                <div className="revText">
                                                    <span>
                                                        Booked deliveries net revenue
                                                    </span>
                                                    <span className='toolTips' data-tip="Booked deliveries net revenue as Gross revenue (Total revenue from the deliveries booked against you, which payment has been paid to you directly" tabindex="1" style={{marginLeft:'10px'}}>
                                                    <BsFillQuestionCircleFill/>
                                                </span>
                                                </div>

                                                {
                                                    period === 'week' ?
                                                    <div className="revAmount">
                                                        <span>
                                                            &#8358;
                                                            {/* {numberWithCommas.format(currentQuarterSum)} */}
                                                            {numberWithCommas.format(mondayBookedLast + tuesdayBookedLast + wednesdayBookedLast + thurdayBookedLast + fridayBookedLast + saturdayBookedLast + sundayBookedLast)}
                                                        </span>
                                                        <span style={{color:'var(--main)', fontSize:"16px", marginLeft:'20px'}}>
                                                        </span>
                                                    </div>
                                                    :
                                                    period === 'month' ?
                                                    <div className="revAmount">
                                                        <span>
                                                            &#8358;
                                                            {numberWithCommas.format(LastMonthBooked)}
                                                        </span>
                                                        <span style={{color:'var(--main)', fontSize:"16px", marginLeft:'20px'}}>
                                                        
                                                        </span>
                                                    </div>
                                                    :
                                                    period === 'quarter' ?
                                                    <div className="revAmount">
                                                        <span>
                                                            &#8358;
                                                            {numberWithCommas.format(lastQuarterBookedSum)}
                                                        </span>
                                                        <span style={{color:'var(--main)', fontSize:"16px", marginLeft:'20px'}}>
                                                        
                                                        </span>
                                                    </div>
                                                    :
                                                    period === 'year' ?
                                                    <div className="revAmount">
                                                        <span>
                                                            &#8358;
                                                            {numberWithCommas.format(LastYearBooked)}
                                                        </span>
                                                        <span style={{color:'var(--main)', fontSize:"16px", marginLeft:'20px'}}>
                                                        
                                                        </span>
                                                    </div>
                                                    :
                                                    period === 'custom' ?
                                                    <div className="revAmount">
                                                        <span>
                                                            &#8358;
                                                            {/* {numberWithCommas.format(currentQuarterSum)} */}
                                                        </span>
                                                        <span style={{color:'var(--main)', fontSize:"16px", marginLeft:'20px'}}>
                                                        
                                                        </span>
                                                    </div>
                                                    :
                                                    null
                                                    
                                                }

                                            </div>
                                        </div>
                                    </div> 
                                    :
                                    null
                                }
                            </div>
                            <div className="leftSettleCardBase">
                                <span className="viewReport" onClick={()=>setactiveState('report')}>
                                    <div><BsArrowRight/></div> <span style={{fontSize:'12px', marginLeft:'10px'}}> View report</span>
                                </span>
                            </div>
                        </div>
                        {/* trend start here */}
                        <div className="trend">
                            <div className="trendTop">
                                <span style={{fontWeight:'600', color:'var(--main)', fontSize:'16px'}}>Trend</span>
                                <span>
                                    <select className="typeDeliveries" onChange={(e)=>{setDeliveryType(e.target.value); refreshenUp(period, e.target.value);}}>
                                        <option value="quoted_deliveries">Quoted deliveries</option>
                                        <option value="booked_deliveries">Booked deliveries</option>
                                        <option value="deliveries">All Deliveries</option>
                                    </select>
                                </span>
                            </div>
                            <div className="trendTopTitle">
                                <div className="dateMonth"> 
                                {
                                    period === "year" ? 
                                    moment(firstDayOfTheYear).format("ddd, Do MMM YYYY") + " - " + moment(lastDayOfTheYear).format("ddd, Do MMM YYYY")
                                    :
                                    moment(startRange).format("ddd, Do MMM YYYY") + ' - ' +  moment(endRange).format("ddd, Do MMM YYYY")
                                }

                                </div>
                                <div className="chartts">
                                    <span className= {chartType === 'barchart' ? "activeChartt" : "chartt"}
                                        onClick={()=>setchartType('barchart')}>
                                        <BsBarChartFill/>
                                    </span>
                                    <span className={chartType === 'linechart' ? "activeChartt" : "chartt"}
                                        onClick={()=>setchartType('linechart')}>
                                        <BsGraphUp/>
                                    </span>
                                    <span className={chartType === 'piechart' ? "activeChartt" : "chartt"}
                                        onClick={()=>setchartType('piechart')}>
                                        <AiFillPieChart/>
                                    </span>
                                </div>
                            </div>
                            <div className="trendGraph">
                                {
                                    chartType === 'barchart' ? 
                                    <>
                                        <BarGraph data ={barChartWeekData}/>  
                                    </>
                                    :
                                    chartType === 'linechart' ? 
                                        <LineChart data ={barChartWeekData}/> 
                                    :
                                    chartType === 'piechart' ? 
                                        <PieGraph data ={barChartWeekData}/>
                                    :
                                    null
                                }
                            </div>
                        </div>

                        {/* table here */}
                        {
                            activeState === 'report' ? 
                                deliveryType === 'quoted_deliveries' ?
                                <div className="reportTableWrapper">
                                    <div className="reportHead">
                                        <Grid container spacing={1}>
                                            <Grid item xs={2}>
                                                {
                                                    period === 'week' ? "Days" :
                                                    period === 'month' ? "Weeks" :
                                                    period === 'quarter' ? "Months" :
                                                    period === 'year' ? "Months" :
                                                    "Dates"
                                                }
                                                </Grid>
                                            <Grid item xs={10}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={3}>
                                                          Number of deliveries
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                          Gross revenue (&#8358;)
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                          Platform comission (&#8358;)
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                          Net revenue (&#8358;)
                                                    </Grid>
                                                    {/* <Grid item xs={2}>
                                                          Settlement date
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                          Status
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    
                                        {barTableWeekData.map((table, index)=>{ return <div className="reportBdy">
                                             <Grid key={index} container spacing={1}>
                                            <Grid item xs={2}>{table.day}</Grid>
                                            <Grid item xs={10}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={3} style={{ borderLeft:'1px solid #EAEAEA'}}>
                                                          {table.number_of_deliveries}
                                                    </Grid>
                                                    <Grid item xs={3} style={{ borderLeft:'1px solid #EAEAEA'}}>
                                                        {numberWithCommas.format(table?.gross)}
                                                    </Grid>
                                                    <Grid item xs={3} style={{borderLeft:'1px solid #EAEAEA'}}>
                                                        {numberWithCommas.format(table?.platform)}
                                                    </Grid>
                                                    <Grid item xs={3} style={{borderLeft:'1px solid #EAEAEA'}}>
                                                        {numberWithCommas.format(table?.net)}
                                                    </Grid>
                                                    {/* <Grid item xs={2} style={{ borderLeft:'1px solid #EAEAEA'}}>
                                                          17/10/2021
                                                    </Grid> 
                                                    <Grid item xs={2} style={{borderLeft:'1px solid #EAEAEA'}}>
                                                          Pending
                                                    </Grid>  */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    })}
                                </div>
                                :
                                <div className="reportTableWrapper">
                                    <div className="reportHead">
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>{
                                                    period === 'week' ? "Days" :
                                                    period === 'month' ? "Weeks" :
                                                    period === 'quarter' ? "Months" :
                                                    period === 'year' ? "Months" :
                                                    "Dates"
                                                }</Grid>
                                            <Grid item xs={9}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                          Number of deliveries
                                                    </Grid>
                                                    {/* <Grid item xs={3}>
                                                          Gross revenue
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                          Platform comission
                                                    </Grid> */}
                                                    <Grid item xs={6}>
                                                          Net revenue
                                                    </Grid>
                                                    {/* <Grid item xs={2}>
                                                          Settlement date
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                          Status
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    
                                        {barTableWeekData.map((table, index)=>{ return <div className="reportBdy">
                                             <Grid key={index} container spacing={1}>
                                            <Grid item xs={3}>{table.day}</Grid>
                                            <Grid item xs={9}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6} style={{ borderLeft:'1px solid #EAEAEA'}}>
                                                          {table.number_of_deliveries}
                                                    </Grid>
                                                    {/* <Grid item xs={3} style={{ borderLeft:'1px solid #EAEAEA'}}>
                                                        &#8358;{numberWithCommas.format(table?.gross)}
                                                    </Grid>
                                                    <Grid item xs={3} style={{borderLeft:'1px solid #EAEAEA'}}>
                                                        &#8358;{numberWithCommas.format(table?.platform)}
                                                    </Grid> */}
                                                    <Grid item xs={6} style={{borderLeft:'1px solid #EAEAEA'}}>
                                                        &#8358;{numberWithCommas.format(table?.net)}
                                                    </Grid>
                                                    {/* <Grid item xs={2} style={{ borderLeft:'1px solid #EAEAEA'}}>
                                                          17/10/2021
                                                    </Grid> 
                                                    <Grid item xs={2} style={{borderLeft:'1px solid #EAEAEA'}}>
                                                          Pending
                                                    </Grid>  */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    })}
                                        
                                    
                                </div>
                                :
                                null
                        }
                    </div>
                    </Grid>
                </div>
            </div>
            <DialogOutClick
                open={showLogout}
                handleClose ={()=>setShowLogout(false)}
                > 
                <div style={{width:'100%',padding:"30px 30px 20px 30px"}}>
                    <div style={{width:'100%',marginBottom:"30px", fontSize:'13px', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <span>Are you sure you want to logout?</span>
                    </div>
                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
                        <div className="disBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer'}}
                            onClick={()=>setShowLogout(false)} >
                            No
                        </div>
                        <div className="ediBtn" style={{width:'50px',fontSize:'13px', fontWeight:'bold', cursor:'pointer', color:'white', backgroundColor:'#808080', border:'1px solid #808080' }}
                            onClick={logMeOut}>
                            Yes
                        </div>
                    </div>
                </div>
            </DialogOutClick>

            {/* <DialogOutClick
                open={ShowCustom}
                handleClose ={()=>setShowCustom(false)}
                >   */}
                    {/* <DateRange
                        onChange={item => setCustomRange([item.selection])}
                        ranges={customRange}
                        // minDate={getValues('pickup_date')} 
                    />
                    <ButtonCard
                        onClick={()=>{ 
                            if(`${customRange[0].startDate.getFullYear()}-${customRange[0].startDate.getMonth()}-${customRange[0].startDate.getDate()}`  ===  `${customRange[0].endDate.getFullYear()}-${customRange[0].endDate.getMonth()}-${customRange[0].endDate.getDate()}`){
                                
                                // setDelivery_time_frame(`${range[0].startDate.getFullYear()}-${range[0].startDate.getMonth()+1}-${range[0].startDate.getDate()}`); 
                                // setRange() 
                                setShowCustom(false)
                                
                            }
                            else{
                                if(customRange[0].endDate === null){
                                    // setDelivery_time_frame('')
                                    setShowCustom(false)
                                }
                                else{
                                    // setDelivery_time_frame(`${range[0].startDate.getFullYear()}-${range[0].startDate.getMonth()+1}-${range[0].startDate.getDate()} - ${range[0].endDate.getFullYear()}-${range[0].endDate.getMonth()+1}-${range[0].endDate.getDate()}`); 
                                    // setRange() 
                                    setShowCustom(false)
                                }
                            }
                        }}
                    text="Ok"
                    />
         */}
            {/* </DialogOutClick> */}
            </>
        </div>
    )
}

export default Settlement
