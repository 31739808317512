 import React from 'react';
 import Logo from  '../assets/Logo.png';
 import './Lazyloader.css'

 function LazyLoader() {
     return (
         
         <div className="loaderWrapper">
            <div className="spinners">
               <div className='insideSpinner'>
                <img src={Logo} alt="dillivry"/>
                </div> 
            </div>
         </div>
     )
 }
 
 export default LazyLoader
 