import React from 'react'
import { AiTwotoneShop } from 'react-icons/ai'
import { BiTimer, BiWallet } from 'react-icons/bi'
import { RiAccountPinCircleLine } from 'react-icons/ri'
import { TiMessages } from 'react-icons/ti'
import "./DashBottomBtn.css"
import { useHistory } from 'react-router-dom'
import GlobalizationLanguage from '../../../../components/GlobalizationLanguage'
import { FaPeopleArrows } from 'react-icons/fa'

const DashBottomBtn = ({activeTab}) => {
    const history = useHistory()

    var Dashboard = GlobalizationLanguage("/Dashboard")
    var my_dillivry = GlobalizationLanguage('/MyDillivry')
    var wallet = GlobalizationLanguage("/corpwallet")
    var messages = GlobalizationLanguage("/messages")
    var account = GlobalizationLanguage("/account")
    var Referral = GlobalizationLanguage("/corpreferrals")
    
    return (
        <div className="footerBtns">
            <div onClick={()=>history.push(Dashboard)} className={activeTab === 'dashboard' ? "activeBtn" : "footerBtn"} >
                <AiTwotoneShop />
                <span>Dashboard</span>
            </div>

            <div onClick={()=>history.push(my_dillivry)} className={activeTab === 'mydillivry' ? "activeBtn" : "footerBtn"} >
                <AiTwotoneShop />
                <span>Dillivry</span>
            </div>

            <div onClick={() => history.push(wallet)} className={activeTab === 'wallet' ? "activeBtn" : "footerBtn"} >
                <BiWallet /> <span>Wallet</span>
            </div>

            <div
                onClick={() => history.push(messages)}
                className={activeTab === 'message' ? "activeBtn" : "footerBtn"}
            >
                <TiMessages />
                <span>Message</span>
            </div>

            <div onClick={() => history.push(Referral)} className={activeTab === 'Referral' ? "activeBtn" : "footerBtn"} >
                <FaPeopleArrows /> <span>Referral</span>
            </div>

            <div
                onClick={() => history.push(account)}
                className={activeTab === 'account' ? "activeBtn" : "footerBtn"}
            >
                <RiAccountPinCircleLine />
                <span>Account</span>
            </div>
        </div>
    )
}

export default DashBottomBtn