import { useEffect, useContext, useState } from "react";
// import './Message.css';
import { MdNavigateNext } from "react-icons/md";
import SmallBtn from "../../../../components/SmallBtn";
import { HiArrowNarrowLeft } from "react-icons/hi";
import axios from "axios";
import Swal from "sweetalert2";
import PuffLoader from "react-spinners/PuffLoader";
import warngreen from "../../../../assets/warngreen.svg";
import warnrred from "../../../../assets/warnrred.svg";
import moment from "moment";
import { BsCheckAll } from "react-icons/bs";
import { useHistory, useParams } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";
import { DashboardContext } from "../../../../context/Dashboard/DashboardProvider/dashboard.provider";
import SecureLS from "secure-ls";
import userAvatar from "../../../../assets/userAvatar.jpeg";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import LoginCorporate from "../../../Login/LoginCorporate";
import jwt_decode from "jwt-decode";
import { ConnContext } from "../../../../context/ConnContext";
import TopBar from "../../../../components/TopBar/TopBar";
import CorporateLayout from "../CorporateLayout";
import MobileCorpLayout from "../CorporateComponents/MobileCorpLayout";
import send from "../../../../assets/Stroke.svg";
import horiz from "../../../../assets/horiz.svg";


function MessageDrillCorp() {
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });

  const { id } = useParams();
  const {visitingCountryCode} = useContext(ConnContext)

  const [dmReply, setDmReply] = useState("");
  const [DMconversation, setDMconversation] = useState([]);

  const [sendingMsg, setsendingMsg] = useState(false);
  const [error, seterror] = useState(false);
  const [DMsender, setDMsender] = useState("");
  const history = useHistory();
  const [msg, setmsg] = useState([]);

  const [myDP, setmyDP] = useState("");

  const auth = useContext(AuthContext);

  const { dashboards, updateDashboard, update_conversation } = useContext(DashboardContext);

  const [loadingData, setloadingData] = useState(false)
const [ShowLogin, setShowLogin] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (dashboards !== undefined) {
      setmyDP(dashboards.data.account_profile.account_profile[0].cop_logo);

      let data = [];

      const deliveryData = dashboards.data.quote_messages.filter(
        (item) => item.delivery_id === id
      );

      data = [
        {
          sender_name: deliveryData[0].sender_name,
          conversation: deliveryData[0].conversation,
          delivery_id: deliveryData[0].delivery_id,
          fulfiller_id: deliveryData[0].fulfiller_id,
          quote_subject: deliveryData[0].quote_subject,
          delivery_address: deliveryData[0].delivery_address,
          pickup_address: deliveryData[0].pickup_address,
          image_urls: deliveryData[0].image_urls[0],
        },
      ];

      setmsg(data);
      setDMconversation(data[0]?.conversation);
      setDMsender(data[0]?.sender_name);
    }
    else {

      if (ls.get('_iRl') === "" || ls.get('_iRl') === ('dillivry-sole-trader-ops') || ls.get('_nodi') === "") {
          setShowLogin(true)
      }
      else {
        const decoded = jwt_decode(ls.get("_nodi"));
        const expired = decoded.exp;
        if (expired < Date.now() / 1000) {
            Swal.fire({
                icon: "warning",
                title: "Session!",
                text: "Session expired login again!",
                allowOutsideClick: false,
                confirmButtonColor: "var(--main)",
            }).then((result) => {
                if (result.value) {
                auth.logout();
                setShowLogin(true)
                }
            });
        }
        else{
          setloadingData(true);
          axios.get(`${endpoint}m-dillivry-dashboard-manager`, {
              headers: {
                  Authorization: ls.get('_nodi')
              }
          })
              .then((response) => {

                ls.set(
                  "means_of_delivery",
                  JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                );
                  updateDashboard(response)
                  setloadingData(false)
                  setmyDP(response.data.account_profile.account_profile[0].cop_logo);

                let data = [];

                const deliveryData = response.data.quote_messages.filter(
                  (item) => item.delivery_id === id
                );

                data = [
                  {
                    sender_name: deliveryData[0].sender_name,
                    conversation: deliveryData[0].conversation,
                    delivery_id: deliveryData[0].delivery_id,
                    fulfiller_id: deliveryData[0].fulfiller_id,
                    quote_subject: deliveryData[0].quote_subject,
                    delivery_address: deliveryData[0].delivery_address,
                    pickup_address: deliveryData[0].pickup_address,
                    image_urls: deliveryData[0].image_urls[0],
                  },
                ];

                setmsg(data);
                setDMconversation(data[0]?.conversation);
                setDMsender(data[0]?.sender_name);
              })
              .catch((error) => {
                   if (error?.response?.status === 403) {
                      Swal.fire({
                          icon: 'warning',
                          title: 'Session!',
                          text: 'Session expired login again!',
                          allowOutsideClick: false,
                          confirmButtonColor: 'var(--main)'
                      })
                          .then((result) => {
                              if (result.value) {
                                  auth.logout();
                                  history.push(`/login`);
                              }
                          });
                  }
                  else {
                    auth.logout();
                    history.push(`/login`);
                 }
                 setloadingData(false)
              })
        }
      }
  }

  }, []);

  const handleenter=(e)=>{
    // replyMessage();
    if (e.keyCode === 13) {
      replyMessage();
  }
    // console.log("hello")
   
   
   
  
  }

//   const something=(event)=> {
//     if (event.keyCode === 13) {
//         console.log('enter')
//     }
// }
  function reverseArr(input) {
    var ret = new Array();
    for (var i = input?.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }

  const endpoint = process.env.REACT_APP_API;
  const authAxios = axios.create({
    baseURL: `${endpoint}`,
    headers: {
      Authorization: ls.get("_nodi"),
    },
  });

  const insert = (arr, index, ...newItems) => [
    ...arr.slice(0, index),
    ...newItems,
    ...arr.slice(index),
  ];

  const replyMessage = () => {
    if (dmReply === "") {
      seterror(true);
    } else {
      seterror(false);
      setsendingMsg(true);
      authAxios
        .post(`m-dillivry-bid-manager`, {
          action_type: "quote",
          delivery_id: msg[0]?.delivery_id,
          msg: dmReply,
          vcc: visitingCountryCode
        }, {timeout: 240000})
        .then((res) => {
          setsendingMsg(false);
          setDmReply("");

          let new_msg = {
            fulfiller_id: msg[0]?.fulfiller_id,
            msg: dmReply,
            time_stamp: new Date(),
          };
          let new_conversation = insert(DMconversation, 0, new_msg);
          setDMconversation(new_conversation);

          const send_to_context = {
            "delivery" : msg[0]?.delivery_id,
            "updated_msg" : new_conversation
         }
         update_conversation(send_to_context)

        })
        .catch((err) => {
          if (err.code === "ECONNABORTED") {
            console.log('internal-server-error-timeout')
            history.push('/timeout')
          }
          setsendingMsg(false);

          Swal.fire({
            icon: "info",
            text: "item has been delivered, Message not sent",
            timer: 3000,
            showConfirmButton: false,
          });
        });
    }
  };


  const capitalize = (x) => {
    return x;
    //  x?.charAt(0).toUpperCase() + x.slice(1)
  };
  return (
    <>

    <div className="deskstopcorp_con">
    <CorporateLayout>
          <>
    { 
      ShowLogin ?  
      history.push('/login')
      :
      <div className="dashboard">
        <div className="dashboardDesktop" style={{ marginTop: "10px" }}>
          <div className="topmenu">
            {/* <TopBar /> */}
          </div>
        </div>
        <div className="dashboardMobile">
          <div className="ind-nav" style={{ marginBottom: "10px" }}>
          {/* <TopBar /> */}
          </div>
        </div>
        <div></div>
        <div className="contMessage" style={{ backgroundColor: "white", marginTop: "10px" }}>
          <div className="padding4Mobile">
            <div style={{ width: "100px", marginBottom: "15px" }} className="backmsg" >
              <HiArrowNarrowLeft fontSize={25} style={{cursor:"pointer", color:"gray"}} onClick={() => history.push("/Messages")} />

              {/* <SmallBtn
                label="Back"
                icon={<TiArrowBack />}
                onClick={() => history.push("/Messages")}
              /> */}
            </div>

            <div className="newcorpmsg" style={{ marginTop: "15px" }}>
              <div className="msgViewContainer">
                <div className="msgViewContainerTop">
                  <div
                    className="msgViewContainerTopImage"
                    style={{ backgroundImage: `url(${msg[0]?.image_urls})` }}
                  ></div>
                  <div className="msgViewTopText">
                    <p>
                      {" "}
                      {msg[0]?.quote_subject.charAt(0)?.toUpperCase() +
                        msg[0]?.quote_subject.slice(1) +
                        " (" +
                        msg[0]?.delivery_id +
                        ")"}
                    </p>
                    <p>
                      {" "}
                      <img src={warngreen} alt="msgpi_icon" />
                      &ensp;{msg[0]?.pickup_address}
                    </p>
                    <p>
                      {" "}
                      <img src={warnrred} alt="msgpi_icon" />
                      &ensp;{msg[0]?.delivery_address}
                    </p>
                  </div>
                </div>
                {reverseArr(DMconversation).map((converse, index) => {
                  return (
                    <div key={index}>
                      {converse.fulfiller_id === undefined ? (
                        <>

                        <div className="left-wrapper">
                          <span class="imageView-cropper">
                            <img
                              src={userAvatar}
                              class="rounded"
                            />
                          </span>
                          <div class="message-left">
                            <div>
                              {/* <strong>{mssg.fulfiller_id}</strong> */}
                              <strong>
                                {capitalize(DMsender)}
                              </strong>
                            </div>
                            <div>
                              <p className="text-sty">
                                {capitalize(converse.msg)}{" "}
                              </p>
                            </div>
                            <div className="message-timeStamp">
                              <div className="all-time">
                                <div
                                  className="timediplaydesk"
                                  style={{ fontSize: "10px" }}
                                >
                                  {moment(converse.time_stamp).format(
                                    "MMM Do YYYY, h:mm a "
                                  )}
                                  (
                                  {moment(
                                    converse.time_stamp
                                  ).fromNow()}
                                  )
                                </div>
                                <div
                                  className="timediplaymobile"
                                  style={{ fontSize: "7px" }}
                                >
                                  {moment(converse.time_stamp).format(
                                    "MMM Do YYYY, h:mm a "
                                  )}
                                  (
                                  {moment(
                                    converse.time_stamp
                                  ).fromNow()}
                                  )
                                </div>
                              </div>
                            </div>
                            <div className="message-timeStamp">
                              <div className="all-time">
                                <BsCheckAll
                                  color="var(--main)"
                                  size="18px"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        </>
                     
                      ) : (
                        <>
                         <div className="right-wrapper">
                        <div class="message-right">
                          <div className="main-msg">
                            <p>
                              {" "}
                              {capitalize(converse.msg)}
                            </p>{" "}
                          </div>
                          <div className="message-timeStamp">
                            <div className="all-time">
                              <div
                                className="timediplaydesk"
                                style={{
                                  fontSize: "10px",
                                }}
                              >
                                {moment(
                                  converse.time_stamp
                                ).format(
                                  "MMM Do YYYY, h:mm a "
                                )}
                                (
                                {moment(
                                  converse.time_stamp
                                ).fromNow()}
                                )
                              </div>
                              <div
                                className="timediplaymobile"
                                style={{
                                  fontSize: "7px",
                                }}
                              >
                                {moment(
                                  converse.time_stamp
                                ).format(
                                  "MMM Do YYYY, h:mm a "
                                )}
                                (
                                {moment(
                                  converse.time_stamp
                                ).fromNow()}
                                )
                              </div>
                            </div>
                          </div>
                          <div className="message-timeStamp">
                            <div className="all-time">
                              <BsCheckAll
                                color="white"
                                size="18px"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                        
                        </>
                       
                      )}
                    </div>
                  );
                })}
              </div>
            </div>


            <div className="reply-message-wrap">
                                    <div className="reply-message-con">
                                      <div className="send-con">
                                        <div className="send-icons">
                                          <span>
                                            <input
                                              autoFocus
                                              requiredtype="text"
                                              className="reply-input"
                                              value={
                                                dmReply
                                              }
                                              onChange={(e) => {
                                                setDmReply(e.target.value);
                                                seterror(false);
                                              }}
                                              onKeyDown={(e) => handleenter(e) }
                                            
                                              type="text"
                                              placeholder="Type your message..."
                                            />
                                            {/* <p>{errors.message?.message}</p> */}
                                          </span>
                                        </div>
                                        <div className="send-icons">
                                          <span
                                            type="submit"
                                            value="submit"
                                            className="send-icon"
                                          >
                                            {sendingMsg ? (
                                              <>
                                                <span className="sending">
                                                  <PuffLoader
                                                    color="var(--main)"
                                                    loading={sendingMsg}
                                                    speedMultiplier={1}
                                                    size={25}
                                                  />
                                                </span>
                                              </>
                                            ) : (
                                              <span>
                                                <img
                                                  src={send}
                                                  alt=""
                                                  onClick={replyMessage}
                                                  style={{ width: "20px" }}
                                                />
                                              </span>
                                            )}
                                          </span>
                                          <span>
                                            {" "}
                                            <img
                                              src={horiz}
                                              alt=""
                                              style={{ marginLeft: "20px" }}
                                            />{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <br></br>
                                    </div>
              </div>

            {/* <div
              style={{
                padding: "20px",
                border: "1px solid #c4c4c4",
                borderTop: "none",
              }}
            >
              <textarea
                className="textarea"
                value={dmReply}
                onChange={(e) => {
                  setDmReply(e.target.value);
                  seterror(false);
                }}
                placeholder="Type your message..."
                style={{
                  width: "100%",
                  backgroundColor: "white",
                  border: error ? "1px solid red" : "1px solid #C4C4C4",
                  color: "black",
                }}
                rows="4"
              />
  

              <div
                className="acceptBTN"
                style={{ width: "150px" }}
                onClick={replyMessage}
              >
                {sendingMsg ? (
                  <PuffLoader
                    color="white"
                    loading={sendingMsg}
                    speedMultiplier={1}
                    size={16}
                  />
                ) : (
                  <>
                    <span>Send message </span>{" "}
                    <div>
                      <MdNavigateNext />
                    </div>
                  </>
                )}
              </div>
            </div> */}

          </div>
        </div>
        {
          loadingData ?
              <div style={{ width: '100%', height: '100vh', backgroundColor: 'none', zIndex: '300', position: 'fixed', top: '0', paddingTop: '5px' }}>
                  <Box sx={{ width: '100%' }}>
                      <LinearProgress color="success" />
                  </Box>
              </div>

              : null
        }
      </div>
    }
    </>

    </CorporateLayout>

    </div>
   

      <div className="mobilecorp_con">
      <MobileCorpLayout pageTitle="Message"  state={"message"}/>
        <div className="mobilecorp_gen">
         
        <>
    { 
      ShowLogin ?
      
      history.push('/login')
      :
      <div className="dashboard">
      
       
        <div className="contMessage" style={{ backgroundColor: "white", marginTop: "10px" }}>
          <div className="padding4Mobile">
            <div style={{ width: "100px", marginBottom: "15px" }}>
              {/* <SmallBtn
                label="Back"
                icon={<TiArrowBack />}
                onClick={() => history.push("/Messages")}
              /> */}
            </div>

            <div className="msgView" style={{ marginTop: "15px" }}>
              <div className="msgViewContainer">
                <div className="msgViewContainerTop">
                  <div
                    className="msgViewContainerTopImage"
                    style={{ backgroundImage: `url(${msg[0]?.image_urls})` }}
                  ></div>
                  <div className="msgViewTopText">
                    <p>
                      {" "}
                      {msg[0]?.quote_subject.charAt(0)?.toUpperCase() +
                        msg[0]?.quote_subject.slice(1) +
                        " (" +
                        msg[0]?.delivery_id +
                        ")"}
                    </p>
                    <p>
                      {" "}
                      <img src={warngreen} alt="msgpi_icon" />
                      &ensp;{msg[0]?.pickup_address}
                    </p>
                    <p>
                      {" "}
                      <img src={warnrred} alt="msgpi_icon" />
                      &ensp;{msg[0]?.delivery_address}
                    </p>
                  </div>
                </div>
                {reverseArr(DMconversation).map((converse, index) => {
                  return (
                    <div key={index}>
                      {converse.fulfiller_id === undefined ? (
                        <>

                        <div className="left-wrapper">
                          <span class="imageView-cropper">
                            <img
                              src={userAvatar}
                              class="rounded"
                            />
                          </span>
                          <div class="message-left">
                            <div>
                              {/* <strong>{mssg.fulfiller_id}</strong> */}
                              <strong>
                                {capitalize(DMsender)}
                              </strong>
                            </div>
                            <div>
                              <p className="text-sty">
                                {capitalize(converse.msg)}{" "}
                              </p>
                            </div>
                            <div className="message-timeStamp">
                              <div className="all-time">
                                <div
                                  className="timediplaydesk"
                                  style={{ fontSize: "10px" }}
                                >
                                  {moment(converse.time_stamp).format(
                                    "MMM Do YYYY, h:mm a "
                                  )}
                                  (
                                  {moment(
                                    converse.time_stamp
                                  ).fromNow()}
                                  )
                                </div>
                                <div
                                  className="timediplaymobile"
                                  style={{ fontSize: "7px" }}
                                >
                                  {moment(converse.time_stamp).format(
                                    "MMM Do YYYY, h:mm a "
                                  )}
                                  (
                                  {moment(
                                    converse.time_stamp
                                  ).fromNow()}
                                  )
                                </div>
                              </div>
                            </div>
                            <div className="message-timeStamp">
                              <div className="all-time">
                                <BsCheckAll
                                  color="var(--main)"
                                  size="18px"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        </>
                      ) : (
                        <>
                        <div className="right-wrapper">
                       <div class="message-right">
                         <div className="main-msg">
                           <p>
                             {" "}
                             {capitalize(converse.msg)}
                           </p>{" "}
                         </div>
                         <div className="message-timeStamp">
                           <div className="all-time">
                             <div
                               className="timediplaydesk"
                               style={{
                                 fontSize: "10px",
                               }}
                             >
                               {moment(
                                 converse.time_stamp
                               ).format(
                                 "MMM Do YYYY, h:mm a "
                               )}
                               (
                               {moment(
                                 converse.time_stamp
                               ).fromNow()}
                               )
                             </div>
                             <div
                               className="timediplaymobile"
                               style={{
                                 fontSize: "7px",
                               }}
                             >
                               {moment(
                                 converse.time_stamp
                               ).format(
                                 "MMM Do YYYY, h:mm a "
                               )}
                               (
                               {moment(
                                 converse.time_stamp
                               ).fromNow()}
                               )
                             </div>
                           </div>
                         </div>
                         <div className="message-timeStamp">
                           <div className="all-time">
                             <BsCheckAll
                               color="white"
                               size="18px"
                             />
                           </div>
                         </div>
                       </div>
                     </div>




                       {/* <div className="msgCard">
                         <div
                           className="msgCardPics"
                           style={{ backgroundImage: `url(${myDP})` }}
                         ></div>
                         <div className="msgCardChat">
                           <h4>{converse.fulfiller_id}</h4>
                           <span>{converse.msg}</span>
                         </div>
                         <span
                           style={{
                             position: "absolute",
                             top: "10px",
                             right: "10px",
                           }}
                         >
                           <div
                             className="timediplaydesk"
                             style={{ fontSize: "10px" }}
                           >
                             {moment(converse.time_stamp).format(
                               "MMM Do YYYY, h:mm a "
                             )}
                             ({moment(converse.time_stamp).fromNow()})
                           </div>
                           <div
                             className="timediplaymobile"
                             style={{ fontSize: "7px" }}
                           >
                             {moment(converse.time_stamp).format(
                               "MMM Do YYYY, h:mm a "
                             )}
                             ({moment(converse.time_stamp).fromNow()})
                           </div>
                         </span>
                         <span
                           style={{
                             position: "absolute",
                             bottom: "10px",
                             right: "10px",
                           }}
                         >
                           <BsCheckAll color="var(--main)" size="18px" />
                         </span>
                       </div> */}
                       
                       </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="reply-message-wrap">
                                    <div className="reply-message-con">
                                      <div className="send-con">
                                        <div className="send-icons">
                                          <span>
                                            <textarea
                                              autoFocus
                                              requiredtype="text"
                                              className="reply-input"
                                              value={
                                                dmReply
                                              }
                                              onChange={(e) => {
                                                setDmReply(e.target.value);
                                                seterror(false);
                                              }}
                                              // onKeyDown={(e) => handleenter(e) }
                                              type="text"
                                              placeholder="Type your message..."
                                            />
                                            {/* <p>{errors.message?.message}</p> */}
                                          </span>
                                        </div>
                                        <div className="send-icons">
                                          <span
                                            type="submit"
                                            value="submit"
                                            className="send-icon"
                                          >
                                            {sendingMsg ? (
                                              <>
                                                <span className="sending">
                                                  <PuffLoader
                                                    color="var(--main)"
                                                    loading={sendingMsg}
                                                    speedMultiplier={1}
                                                    size={25}
                                                  />
                                                </span>
                                              </>
                                            ) : (
                                              <span>
                                                <img
                                                  src={send}
                                                  alt=""
                                                  onClick={replyMessage}
                                                  style={{ width: "20px" }}
                                                />
                                              </span>
                                            )}
                                          </span>
                                          <span>
                                            {" "}
                                            <img
                                              src={horiz}
                                              alt=""
                                              style={{ marginLeft: "20px" }}
                                            />{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <br></br>
                                    </div>
              </div>
          </div>
        </div>
        {
          loadingData ?
              <div style={{ width: '100%', height: '100vh', backgroundColor: 'none', zIndex: '300', position: 'fixed', top: '0', paddingTop: '5px' }}>
                  <Box sx={{ width: '100%' }}>
                      <LinearProgress color="success" />
                  </Box>
              </div>

              : null
        }
      </div>
    }
        </>

        </div>

      </div>
    </>
     
      

   
  );
}
export default MessageDrillCorp;
