import React, { useContext,useState,useEffect, lazy, Suspense } from "react";
import ProtectedRoute from "./ProtectedRoute";
import HomeV2 from "./pages/Home/HomeV2";
import { Route, Switch, Redirect } from "react-router-dom";
import MoonLoader from 'react-spinners/MoonLoader';
import Dashboad2 from "./pages/LogisticDashboard/Dashboard"
import Login from "./pages/Login/Login";
import CreateAccount from "./pages/CreateAccount/CreateAccount";
import SendQuote from "./pages/SendQuote/SendQuote";
import FulfillerAccount from "./pages/Fulfiller/Dashboard/Account/FulfillerAccount";
import Registration from "./pages/Registration/Registration";
import RegistrationBlade from "./pages/Registration/RegistrationBlade";
import Appdownload from "./pages/Appdownload/Appdownload";
import ViewDeliverNew from "./pages/Fulfiller/Dashboard/ViewDeliverNew";
import ViewFulfilerNew from "./pages/Fulfiller/Dashboard/ViewFulfilerNew";
import TrackingPages from "./pages/SendQuote/TrackingPages";
import Pagetest from "./pages/SendQuote/Pagetest";
import DashDrillDown from "./pages/Fulfiller/Dashboard/DashDrillDown";
import CorpDashdrilldown from "./pages/Fulfiller/Dashboard/CorpDashdrilldown";
import MessageFulfiller from "./pages/Fulfiller/Dashboard/MessageDrillDown/MessageFulfiller";
import MessageFulfillerDM from "./pages/Fulfiller/Dashboard/MessageDrillDown/MessageFulfillerDM";
import MessageFulfillerDrillDown from "./pages/Fulfiller/Dashboard/MessageDrillDown/MessageFulfillerDrillDown";
import QuoteHistory from "./pages/Fulfiller/Dashboard/History/QuoteHistory";
import HistoryDrillDown from "./pages/Fulfiller/Dashboard/History/HistoryDrillDown";
import ActiveDilivry from "./pages/SendQuote/ViewQuote/ActiveDilivry";
import SenderAcct from "./pages/SendQuote/ViewQuote/SenderAcct";
import AccountSettlement from "./pages/Fulfiller/Dashboard/Account/AccountSettlement";
import AccountUser from "./pages/Fulfiller/Dashboard/Account/AccountUser";
import AccountSetting from "./pages/Fulfiller/Dashboard/Account/AccountSetting";
import MobileAccount from "./pages/Fulfiller/Dashboard/Account/MobileAccount";
import Timeout from "./pages/404/Timeout";
import ViewFulfilerNewSenderDashboard from "./pages/Fulfiller/Dashboard/ViewFulfilerNewSenderDashboard";
import ReceiverPayment from "./components/ReceiverPayment";
import Quotes from "./pages/Sender/SenderDashboard/Pages/Quote/Quotes";
import SenderRegistartion from "./pages/Sender/Registration/SenderRegistartion";
import Messages from "./pages/Sender/SenderDashboard/Pages/Messages/Messages";
import Settings from "./pages/Sender/SenderDashboard/Pages/Settings";
import QuoteDrillDown from "./pages/Sender/SenderDashboard/Pages/Quote/QuoteDrillDown";
import BusinessSettings from "./pages/Sender/SenderDashboard/Pages/BusinessSettings";
import MessageDrilldown from "./pages/Sender/SenderDashboard/Pages/Messages/MessageDrilldown";
import RequestDelivery from "./pages/Sender/SenderDashboard/Pages/RequestDelivery/RequestDelivery";
import ViewFulfilerSender from "./pages/Sender/SenderDashboard/Pages/ViewFulfilerSender";
import EditnewQuote from "./pages/Sender/SenderDashboard/Pages/Quote/EditnewQuote";
import Cart from "./pages/Sender/SenderDashboard/Cart";
import Checkout_Payment from "./pages/Sender/SenderDashboard/Checkout_Payment";
import ItemUpdatePage from "./pages/Sender/SenderDashboard/Pages/Quote/ItemUpdatePage";
import How_it_works from "./pages/How_it_works/How_it_works";
import Dashboard from "./pages/Fulfiller/Dashboard/Dashboard";
import MyDillivryCorp from "./pages/Fulfiller/Dashboard/MyDillivryCorp";
import CreateDillivry from "./pages/Fulfiller/Dashboard/CreateDillivry";
import CorpSettings from "./pages/Fulfiller/Dashboard/CorpSettings";
import CorpHelp from "./pages/Fulfiller/Dashboard/CorpHelp";

import ViewDelivery from "./pages/Fulfiller/Dashboard/ViewDelivery";
import Wallet from "./pages/SendQuote/ViewQuote/Wallet";
import CorpWallet from "./pages/Fulfiller/Dashboard/CorpWallet";
import MessageDrillCorp from "./pages/Fulfiller/Dashboard/MessageDrillDown/MessageDrillCorp";
import SettingsCorp from "./pages/Fulfiller/Dashboard/Account/SettingsCorp";
import CorpAccount from "./pages/Fulfiller/Dashboard/Account/CorpAccount";
import CorpSettlement from "./pages/Fulfiller/Dashboard/Account/CorpSettlement";
import CorpUsers from "./pages/Fulfiller/Dashboard/Account/CorpUsers";
import WalletSender from "./pages/SendQuote/ViewQuote/WalletSender";
import i18n from "i18next";
import { useHistory } from "react-router-dom";
import LogisticRegistartion from "./pages/Sender/Registration/LogisticRegistartion";
import { ConnContext } from './context/ConnContext';
import Messagefromreceivedquote from "./pages/Sender/SenderDashboard/Pages/Messages/Messagefromreceivedquote";
import CorpReferrals from "./pages/Fulfiller/Dashboard/CorpReferrals";

import ReferralPage from "./pages/SendQuote/ViewQuote/ReferralPage";

import QuotesStatus from "./pages/Sender/SenderDashboard/Pages/Quote/QuotesStatus/QuotesStatus";
import QuotesItemStatus from "./pages/Sender/SenderDashboard/Pages/Quote/QuotesStatus/QuotesItemStatus";
import WatchList from "./pages/Fulfiller/Dashboard/WatchList";
import App from "./components/Stripe/App";
import ThankYou from "./pages/ThankYou";

const Terms = lazy(() => import("./pages/Terms/Terms"));
const Unsubscribe = lazy(() => import("./pages/Unsubscribe/Unsubscribe"));
const API = lazy(() => import("./pages/Sender/SenderDashboard/Pages/API"));
const UserSettlement = lazy(() => import("./pages/Sender/SenderDashboard/Pages/UserSettlement"));
const Team = lazy(() => import("./pages/Sender/SenderDashboard/Pages/Team/Team"));
const CookiePolicy = lazy(() => import("./pages/CookiePolicy/CookiePolicy"));
const ResetPassword = lazy(() => import("./pages/Login/ResetPassword"));
const ResetForgetPassword = lazy(() => import("./pages/Login/ResetForgetPassword"));
const ForgetPassword = lazy(() => import("./pages/Login/ForgetPassword"));
const SearchDilivry2 = lazy(() => import("./pages/SearchDilivry2/NewSearch"));
const SearchDilivryvcc = lazy(() => import("./pages/SearchDilivry2/NewSearch2"));
const Privacy = lazy(() => import("./pages/Privacy/Privacy"));
const Feed = lazy(() => import("./pages/Contact/Feed"));
const Enquiry = lazy(() => import("./pages/Contact/Enquiry"));
const Complaints = lazy(() => import("./pages/Contact/Complaints"));
const Apipage = lazy(() => import("./pages/API_Page/Apipage"));
const ProviderRating = lazy(() => import("./components/ProviderRating"));
const FulfillerPlatformRating = lazy(() => import("./components/FulfillerPlatformRating"));
const SenderPlatformRating = lazy(() => import("./components/SenderPlatformRating"));
const Faqs = lazy(() => import("./pages/Contact/Faqs"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const flags = [
  {
    name: "Canada",
    countryCode: "ca"
  }, {
    name: "Ghana",
    countryCode: "gh"
  }, {
    name: "Kenya",
    countryCode: "ke"
  }, {
    name: "Nigeria",
    countryCode: "ng"
  }, {
    name: "Rwanda",
    countryCode: "rw"
  }, {
    name: "South Africa",
    countryCode: "za"
  }, {
    name: "United Kingdom",
    countryCode: "gb"
  }, {
    name: "USA",
    countryCode: "us"
  },
]

function Routing({ match, location }) {

  const { visitingCountryCode } = useContext(ConnContext)

  const history = useHistory();

  if (match.params.locale === undefined) {
    i18n.changeLanguage(`${visitingCountryCode.toLowerCase()}`)
  }

  if (match.params.locale === `${visitingCountryCode.toLowerCase()}`) {
    i18n.changeLanguage(`${visitingCountryCode.toLowerCase()}`)
    var ret = location.pathname.replace(`/${visitingCountryCode.toLowerCase()}`, '');
    history.push(ret)
  }

  const checkroute = flags.findIndex(item => item.countryCode === match.params.locale)
  if (checkroute !== -1) {
    i18n.changeLanguage(match.params.locale)
  }

  const checkLanguageroute = match.params.locale !== undefined && checkroute !== -1 ? `${match.url}/` : "/"

  if (checkLanguageroute === "/") {
    i18n.changeLanguage(`${visitingCountryCode.toLowerCase()}`)
  }


  return (
    <div>
      {/* <BrowserRouter
        getUserConfirmation={(message, callback) => {
          const allowTransition = window.confirm(message);
          callback(allowTransition);
        }}
      > */}
      <Suspense fallback={<div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <MoonLoader color='var(--main)' loading={true} speedMultiplier={1} size={60} /> </div>}>
        <Switch>
          <Route path={`${checkLanguageroute}Dashboard2`} component={Dashboad2} />

          <Route path={`${checkLanguageroute}create-account`} component={CreateAccount} />
          <Route path={`${checkLanguageroute}Login`}> <Login /> </Route>
          <Route exact path={`${checkLanguageroute}`} > <HomeV2 /></Route>

          <Route path={`${checkLanguageroute}open-deliveries`}><SearchDilivry2 /></Route>

          <Route path={`${checkLanguageroute}Dashboard`}><Dashboard /></Route>


          {/* <Route exact path={`${checkLanguageroute}open-delivery/:delivery_id`}><ViewDeliverNew /></Route> */}

          <Route exact path={`${checkLanguageroute}open-delivery/:delivery_id/:fulfiller_id`}><ViewFulfilerNew /></Route>

          <Route exact path={`${checkLanguageroute}delivery/:delivery_id/:fulfiller_id`}><ViewFulfilerNewSenderDashboard /></Route>
          
          <Route exact path={`${checkLanguageroute}open-delivery/:delivery_id`}><ViewDeliverNew /></Route>

          <Route exact path={`${checkLanguageroute}deliveries_/:delivery_id/:fulfiller_id`}><ViewFulfilerSender /></Route>

          <Route exact path={`${checkLanguageroute}faqs`}> <Redirect to="/faq" /></Route>

          <Route exact path={`${checkLanguageroute}registration`}><Redirect to="/deliver-with-us" /></Route>

          <Route exact path={`${checkLanguageroute}requestDelivery`}> <Redirect to="/request-a-delivery" /></Route>

          <Route exact path={`${checkLanguageroute}openDeliveries`}><Redirect to="/open-deliveries" /></Route>

          <Route exact path={`${checkLanguageroute}contact`}><Redirect to={`${checkLanguageroute}contact-us`} /> </Route>

          <Route path={`${checkLanguageroute}faq`} component={Faqs} />

          <Route exact path={`${checkLanguageroute}contact`}><Redirect to="/contact-us" /> </Route>

          <Route path={`${checkLanguageroute}contact-us`} component={Contact} />

          <Route path={`${checkLanguageroute}Feed`} component={Feed} />

          <Route path={`${checkLanguageroute}Enquiry`} component={Enquiry} />

          <Route path={`${checkLanguageroute}Complaints`} component={Complaints} />

          <Route path={`${checkLanguageroute}Api`} component={Apipage} />

          <Route path={`${checkLanguageroute}9658393812345985/:userID/:id/:comment`} component={ProviderRating} />

          <Route path={`${checkLanguageroute}6994622167893963`} component={FulfillerPlatformRating} />

          <Route path={`${checkLanguageroute}5156886826018256`} component={SenderPlatformRating} />

          <Route path={`${checkLanguageroute}Unsubscribe`} component={Unsubscribe} />

          <Route path={`${checkLanguageroute}request-a-delivery`} component={SendQuote} />

          <Route path={`${checkLanguageroute}ViewDelivery`} component={ViewDelivery} />

          <Route path={`${checkLanguageroute}MyDillivry`} component={MyDillivryCorp} />

          <Route path={`${checkLanguageroute}CreateDillivry`} component={CreateDillivry} />

          <Route path={`${checkLanguageroute}watch-list`} component={WatchList} />
          {/* <Route path="/Settings"><CorpSettings /></Route> */}
          <Route path={`${checkLanguageroute}CorpHelp`} component={CorpHelp} />

          <Route path={`${checkLanguageroute}login`} component={Login} />

          <Route path={`${checkLanguageroute}reset`} component={ResetPassword} />

          <Route path={`${checkLanguageroute}logistics-companies`} component={Registration} />


          <Route path={`${checkLanguageroute}Privacy`} component={Privacy} />

          <Route path={`${checkLanguageroute}resetpass`} component={ResetForgetPassword} />

          <Route path={`${checkLanguageroute}forgetpass`} component={ForgetPassword} />

          <Route path={`${checkLanguageroute}deliver-with-us`} component={RegistrationBlade} />

          <Route path={`${checkLanguageroute}riders-and-drivers`} component={Appdownload} />

          <Route path={`${checkLanguageroute}how-it-works`} component={How_it_works} />

          <Route path={`${checkLanguageroute}Terms`} component={Terms} />

          <Route path={`${checkLanguageroute}CookiePolicy`} component={CookiePolicy} />

          <Route path={`${checkLanguageroute}trackingPages/:userID/:id/`} component={TrackingPages} />

          <Route path={`${checkLanguageroute}trackingPages/:id`} exact component={TrackingPages} />

          <Route path={`${checkLanguageroute}tracking/:id`} exact component={Pagetest} />

          <Route path={`${checkLanguageroute}corpdeliveries/:type/:id`} component={CorpDashdrilldown} />

          <Route path={`${checkLanguageroute}deliveries/:type/:id`} component={DashDrillDown} />

          <Route path={`${checkLanguageroute}message/:type/:id`} component={MessageFulfillerDM} />

          <Route path={`${checkLanguageroute}messages/`} component={MessageFulfiller} />

          <Route path={`${checkLanguageroute}message/:id`} component={MessageFulfillerDrillDown} />

          <Route path={`${checkLanguageroute}deliveries_history`} component={QuoteHistory} />

          <Route path={`${checkLanguageroute}delivery_history/:type/:id`} component={HistoryDrillDown} />

          <Route path={`${checkLanguageroute}activedillivry`} component={ActiveDilivry} />

          <Route path={`${checkLanguageroute}acct`} component={SenderAcct} />

          <Route path={`${checkLanguageroute}account/transaction`} component={FulfillerAccount} />

          <Route path={`${checkLanguageroute}Settlements`} component={CorpSettlement} />

          <Route path = {`${checkLanguageroute}UserManager`} component={CorpUsers} />

          <Route path={`${checkLanguageroute}Settings`} component={SettingsCorp} />

          <Route path={`${checkLanguageroute}account`} component={CorpAccount} />

          <Route path={`${checkLanguageroute}account/settlement`} component={AccountSettlement} />

          <Route path={`${checkLanguageroute}account/users`} component={AccountUser} />

          <Route path={`${checkLanguageroute}account/settings`} component={AccountSetting} />

          <Route path={`${checkLanguageroute}account`} component={MobileAccount} />

          <Route path={`${checkLanguageroute}timeout`} component={Timeout} />

          <Route path={`${checkLanguageroute}receiver/:country_code/:delivery_id`} component={ReceiverPayment} />

          <Route path={`${checkLanguageroute}corpwallet`} component={CorpWallet} />

          <Route path={`${checkLanguageroute}corpreferrals`} component={CorpReferrals} />

          
          <Route exact path={`${checkLanguageroute}:vcc/open-deliveries`}> <SearchDilivry2 /></Route>
          
          <Route path={`${checkLanguageroute}wallet`} component={Wallet} />
          
          <Route path={`${checkLanguageroute}viewQuote/thank-you`} component={ThankYou} />

          {/* protected routes for senders */} 

          {/* <ProtectedRoute exact path="/viewQuote" component={SenderDashboard}/> */}
          <ProtectedRoute exact path={`${checkLanguageroute}viewQuote`} component={QuotesStatus} />

          <ProtectedRoute exact path={`${checkLanguageroute}wallet_sender`} component={WalletSender} />

          <ProtectedRoute exact path={`${checkLanguageroute}referrals_sender`} component={ReferralPage} />


          <ProtectedRoute exact path={`${checkLanguageroute}view-status/:delivery_id`} component={QuotesItemStatus} />

          <ProtectedRoute exact path={`${checkLanguageroute}viewQuote/quotes`} component={Quotes} />

          <ProtectedRoute exact path={`${checkLanguageroute}updatedescription`} component={ItemUpdatePage} />

          <ProtectedRoute exact path={`${checkLanguageroute}viewQuote/request-a-delivery`} component={RequestDelivery} />

          <ProtectedRoute exact path={`${checkLanguageroute}viewQuote/myCart`} component={Cart} />

          <ProtectedRoute exact path={`${checkLanguageroute}viewQuote/checkout`} component={Checkout_Payment} />

          <ProtectedRoute exact path={`${checkLanguageroute}activedeliveries/viewDelivery/:vcc/:id`} component={QuoteDrillDown} />

          <ProtectedRoute exact path={`${checkLanguageroute}sendermessage`} component={Messages} />


          <ProtectedRoute exact path={`${checkLanguageroute}stripecheckout`} component={App} />

          <ProtectedRoute exact path={`${checkLanguageroute}mssg/:id`} component={MessageDrilldown} />
          
          <ProtectedRoute exact path={`${checkLanguageroute}mssgg/:id`} component={Messagefromreceivedquote} />

          <ProtectedRoute exact path={`${checkLanguageroute}team`} component={Team} />

          <ProtectedRoute exact path={`${checkLanguageroute}senderapi`} component={API} />

          <ProtectedRoute exact path={`${checkLanguageroute}sendersettlement`} component={UserSettlement} />

          <ProtectedRoute exact path={`${checkLanguageroute}sendersettings`} component={Settings} />

          <ProtectedRoute exact path={`${checkLanguageroute}sendersettings/business`} component={BusinessSettings} />

          <ProtectedRoute excat path={`${checkLanguageroute}editquote`} component={EditnewQuote} />

          <Route exact path={`${checkLanguageroute}sender-signup`} component={SenderRegistartion} />

        </Switch>
      </Suspense>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default Routing;
