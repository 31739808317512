import * as React from 'react';
import { Line, Circle } from 'rc-progress';
import {  ThemeProvider, createTheme } from '@mui/system';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';


const theme = createTheme({
    palette: {
      background: {
        paper: '#fff',
      },
      text: {
        primary: '#50B14A',
        secondary: '#46505A',
      },
      action: {
        active: '#001E3C',
      },
      success: {
        dark: '#009688',
      },
    },
  });


export default function LinearIndeterminate() {
  return (
    // <ThemeProvider  theme={theme} >
        <Box sx={{ width: '100%', height: '1%' }}>
      <LinearProgress color="success" />
    </Box>
    // </ThemeProvider>
  );
}
