import { useEffect } from 'react';
import elipse11 from "../assets/Ellipse 11.png";
import './DownloadApp.css';
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import iphone from "../assets/iphon.png"
import playstore from "../assets/andriod.svg"
import appstore from "../assets/ios.svg"




function DownloadApp() {
   
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const navContainer = document.getElementById("navScroll")
    return (
        <>
            <div className="googlecontainer" id="navScroll">
                <div className="circle"></div>
                <img className="elipse11" src={elipse11} alt="dillivry" />

                {/* <img className="elipse12" src={elipse12} alt="dillivry" /> */}

                <div className="googlegrid">
                    <div className="googletextcontainer">
                        <div className="textcont">
                            <p>Ready to request delivery</p>
                            <p>or sign up as a provider?</p>
                        </div>

                        <div className="googleimagecontainer">
                            <div className="gimagecontainer">
                                <a href="https://play.google.com/store/apps/details?id=datamellon.app_mobile"
                                    title="Dillivry on playStore" target="_blank" ><img src={playstore} alt="Dillivry" />
                                </a>
                            </div>
                            <div className="gimagecontainer">
                                <a href="https://apps.apple.com/us/app/dillivry/id1604161862"
                                    title="Dillivry on appStore" target="_blank" >
                                    <img src={appstore} alt="Dillivry" /></a>
                            </div>
                        </div>

                        <div>
                            <h4>Get the app!</h4>
                        </div>

                    </div>

                    <div className="pimagecontainer">
                        <img src={iphone} alt="Dillivry" />
                    </div>
                </div>

            </div>
        </>
    )
}

export default DownloadApp
