import { useEffect, useState, useContext } from 'react'
import {useParams} from 'react-router-dom'
import dillivry from '../../../../assets/Dillivry.png'
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Carousel from 'react-material-ui-carousel';
import {AiFillFileText} from 'react-icons/ai'
import {MdDashboard,MdLocationOn, MdPayment} from 'react-icons/md'
import {FaClipboardCheck} from 'react-icons/fa'
import {RiMiniProgramFill} from 'react-icons/ri'
import {BsCreditCard} from 'react-icons/bs'
import {BiArrowBack, BiPhoneCall} from 'react-icons/bi'
import {IoMdMailOpen} from 'react-icons/io'

import { Button, Grid } from '@material-ui/core'
import axios from 'axios'
import Swal from 'sweetalert2';
import { PuffLoader } from 'react-spinners';
import SecureLS from 'secure-ls';
import{DashboardContext}  from '../../../../context/Dashboard/DashboardProvider/dashboard.provider'
import { AuthContext } from '../../../../context/AuthContext';
import {useHistory} from "react-router-dom"
import ReactTooltip from 'react-tooltip';
import { IconContext } from 'react-icons';
import moment from 'moment';
import GlobalizationLanguage from '../../../../components/GlobalizationLanguage';
function HistoryDrillDownMobile({ dp, noShowAgent}) {

  const auth = useContext(AuthContext);
  const history = useHistory();
  
  const {dashboards, updateDashboard} = useContext(DashboardContext)
  
  const ls = new SecureLS({ isCompression: true, encodingType:  'rabbit' , encryptionSecret: 'dm_dillivry'});
  
  const {type, id} = useParams()
  
  
  const [viewData, setviewData] = useState([]);
  
  const [assignedAgent, setAssignedAgent] = useState([])
  
 
  const [loadingData, setloadingData] = useState(false)
 
     
      useEffect(() => {
          if (dashboards !== undefined){
              let data = []
              if(type === 'current'){
                  data = dashboards.data.current_quotes.filter((item)=> item.delivery_id === id)    
              }
              if(type === 'assign'){
                  data = dashboards.data.accepted_quotes.assigned.filter((item)=> item.delivery_id === id)    
                  const agents= dashboards.data.all_users_list.all_users_list.filter(function (lastmile) { return lastmile.role === "dillivry-corp-last-mile-ops" })
                  setAssignedAgent(agents.filter((agent)=>agent.user_id === data[0].assigned_agent_details.pickup_agent_id))
              }
              if(type === 'unassign'){
                  data = dashboards.data.accepted_quotes.unassigned.filter((item)=> item.delivery_id === id)    
              }
              if(type === 'out_for_delivery'){
                  data = dashboards.data.picked_up.filter((item)=> item.delivery_id === id)
                  const agents= dashboards.data.all_users_list.all_users_list.filter(function (lastmile) { return lastmile.role === "dillivry-corp-last-mile-ops" })
                  setAssignedAgent(agents.filter((agent)=>agent.user_id === data[0].assigned_agent_details.pickup_agent_id))    
              }
              if(type === 'in_transit'){
                data = dashboards.data.out_for_delivery.filter((item)=> item.delivery_id === id)
                const agents= dashboards.data.all_users_list.all_users_list.filter(function (lastmile) { return lastmile.role === "dillivry-corp-last-mile-ops" })
                setAssignedAgent(agents.filter((agent)=>agent.user_id === data[0].assigned_agent_details.pickup_agent_id))    
            }
              if(type === 'missed'){
                  data = dashboards.data.missed_delivery.filter((item)=> item.delivery_id === id)    
                  const agents= dashboards.data.all_users_list.all_users_list.filter(function (lastmile) { return lastmile.role === "dillivry-corp-last-mile-ops" })
                  setAssignedAgent(agents.filter((agent)=>agent.user_id === data[0].assigned_agent_details.pickup_agent_id))
              }
              if(type === 'decline'){
                  data = dashboards.data.unsuccessful_quotes.filter((item)=> item.delivery_id === id)    
              }
              if(type === 'complete'){
                  data = dashboards.data.completed_quotes.filter((item)=> item.delivery_id === id)    
                  const agents= dashboards.data.all_users_list.all_users_list.filter(function (lastmile) { return lastmile.role === "dillivry-corp-last-mile-ops" })
                  setAssignedAgent(agents.filter((agent)=>agent.user_id === data[0].delivery_agent_details.pickup_agent_id))
              }
              setviewData(data)
                 
          }
          else{
  
              setloadingData(true);
  
              axios.get(`${process.env.REACT_APP_API}m-dillivry-dashboard-manager`, {
                  headers: {
                      Authorization: ls.get('_nodi')
                  }
              })
              .then((response) => {

                ls.set(
                  "means_of_delivery",
                  JSON.stringify(response?.data?.account_profile?.account_profile[0]?.mode_of_delivery)
                );
                  setloadingData(false);
                  if (response.status === 200) {
                    updateDashboard(response);
                    let data = [];
                    if (type === "current") {
                      data = response.data.current_quotes.filter(
                        (item) => item.delivery_id === id
                      );
                    }
                    
                    if (type === "assign") {
                      data = response.data.accepted_quotes.assigned.filter(
                        (item) => item.delivery_id === id
                      );
                      const agents= response.data.all_users_list.all_users_list.filter(function (lastmile) { return lastmile.role === "dillivry-corp-last-mile-ops" })
                      setAssignedAgent(agents.filter((agent)=>agent.user_id === data[0].assigned_agent_details.pickup_agent_id))
                    }
                    if (type === "unassign") {
                      data = response.data.accepted_quotes.unassigned.filter(
                        (item) => item.delivery_id === id
                      );
                    }
                    if (type === "out_for_delivery") {
                      data = response.data.picked_up.filter(
                        (item) => item.delivery_id === id
                      );
                      const agents= response.data.all_users_list.all_users_list.filter(function (lastmile) { return lastmile.role === "dillivry-corp-last-mile-ops" })
                      setAssignedAgent(agents.filter((agent)=>agent.user_id === data[0].assigned_agent_details.pickup_agent_id))
                    }
                    if(type === 'decline'){
                      data = response.data.unsuccessful_quotes.filter((item)=> item.delivery_id === id)    
                      }
                    if (type === "missed") {
                      data = response.data.missed_delivery.filter(
                        (item) => item.delivery_id === id
                      );
                    }
                    if (type === "complete") {
                      data = response.data.completed_quotes.filter(
                        (item) => item.delivery_id === id
                      );
                      const agents= response.data.all_users_list.all_users_list.filter(function (lastmile) { return lastmile.role === "dillivry-corp-last-mile-ops" })
                      setAssignedAgent(agents.filter((agent)=>agent.user_id === data[0].delivery_agent_details.pickup_agent_id))
                    }
                    setviewData(data);
                  }
              })
              .catch((err)=>{
                  setloadingData(false);
                  if(err?.response?.status === 403){
                      Swal.fire({
                          icon: 'warning',
                          title: 'Session!',
                          text: 'Session expired login again!',
                          allowOutsideClick: false,
                          confirmButtonColor: 'var(--main)'
                      })
                      .then((result) => {
                          if (result.value) {
                              auth.logout();
                              history.push(`/login`);
                          }
                      });
                  }
              })
          }
          
      }, [])

      function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [day, month,year].join('-');
    }
  
      const numberWithCommas = new Intl.NumberFormat("en-GB")
  
    return (
        <div className="viewSearch">
          {
            loadingData ?
                <div style={{ width: '100%', height: '100vh', backgroundColor: 'none', zIndex: '300', position: 'fixed', top: '0', paddingTop: '5px' }}>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress color="success" />
                    </Box>
                    <div style={{ display:'flex', alignItems:'center', justifyContent:'center', paddingTop:'200px'}}>
                      <PuffLoader
                          color="#50b14a"
                          loading={loadingData}
                          speedMultiplier={1}
                          size={100}
                        />
                    </div>
                </div>
                : 
                null
            }
            
            <>
                <div  className="fulfillerViewTop">
                    <div onClick={()=>
                      history.goBack()
                    }>
                        <BiArrowBack size='25px'/>
                    </div> 
                    <div className='viewLogo'>
                        <img src={dillivry} alt="logo"/>
                    </div>
                    <div className='topCircle'></div>
                </div>
                {
                    viewData.map((view)=>{ 
              var link = GlobalizationLanguage(`/message/${type}/${view.delivery_id}`)
                      
                      return <div key={view.delivery_id} className="view_Container">
                     <div className="view_Top_mobile">
                        <div className="topItem" style={{backgroundColor:'var(--main)', justifyContent: 'center', padding:'7px', fontSize:'13px', color:'white'}}>
                          {
                              type === 'complete' ?
                              'Completed : ' + formatDate(view.delivery_agent_details.delivery_timestamp)
                              :
                              'Due date : ' + view.delivery_time_frame
                          }  
                        </div>
                      </div>
                      
                      

                      <div className='submitQuot'>
                        <div className="bodyItem" style={{ marginTop: "20px" }}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <div className="imgage-content">
                              <Carousel
                                navButtonsAlwaysInvisible={true}
                                indicators={false}
                                animation={"slide"}
                                timeout={100}
                                autoPlay={true}
                              >
                                {view.image_urls?.map((img_url, i) => (
                                  <img
                                    key={i}
                                    src={img_url}
                                    style={{ width: "100%", height: "100%" }}
                                    alt="Item_image"
                                    className="carousel_image-"
                                  />
                                ))}
                              </Carousel>
                              </div>
                          </Grid>
                          <Grid item xs={12}>
                            <>
                            <div className="content-iconn">
                              <div className="topTitle">
                                Delivery information
                              </div>
                              <div className="itemDescs">
                                <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <AiFillFileText/>
                                  </span>
                                  <span className="icon-desc">
                                  <span >Item description: </span>
                                    {view.item_desc.charAt(0)?.toUpperCase() + view.item_desc.toLowerCase().slice(1)}
                                  </span>
                                </div>

                                <div className="itemDesc">
                                    <span>
                                      {" "}
                                      <MdDashboard/>
                                    </span>
                                    <span className="icon-desc">
                                    <span>Item category: </span>
                                      {view.item_category.charAt(0)?.toUpperCase() + view.item_category.slice(1).replace(/_/g , " ")}
                                    </span>
                                </div>

                                <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <AiFillFileText/>
                                  </span>
                                  <span className="icon-desc">
                                  <span>Delivery ID: </span>
                                    {view.delivery_id}
                                  </span>
                                </div>

                                <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <AiFillFileText/>
                                  </span>
                                  <span className="icon-desc">
                                  <span>Delivery type: </span>
                                    {view.delivery_type === 'bid' ? 'Quoted' : 'Booked'}
                                  </span>
                                </div>
                                
                                <div className="itemDesc">
                                  <span>
                                    <RiMiniProgramFill/>
                                  </span>
                                  <span className="icon-desc">
                                  <span>Delivery status: </span>
                                      {type === 'assign' ? 'Awaiting Pickup' 
                                          : 
                                          type === 'unassign' ? "Awaiting agent assignment" 
                                          : 
                                          type === 'out_for_delivery' ? 'Out for delivery' 
                                          : 
                                          type === 'outbid' ? 'Outbidded' 
                                          :
                                          type === 'complete' ? 'Completed' 
                                          :
                                          type === 'missed' ? 'Missed' 
                                          :
                                          null
                                      }
                                    </span>
                                </div>
                                <div className="itemDesc">
                              <span>
                                {" "}
                                <MdLocationOn color='red'/>
                              </span>
                              <span className="icon-desc">
                              <span>Pickup address: </span>

                                {view.from}
                              </span>
                            </div>
                            <div className="itemDesc">
                              <span>
                                {" "}
                                <BiPhoneCall />
                              </span>
                              <span className="icon-desc">
                              <span>Pickup contact: </span>

                                {view.pickup_phone_no}
                              </span>
                            </div>
                            <div className="itemDesc">
                              <span>
                                {" "}
                                <MdLocationOn color="var(--main)"/>
                              </span>
                              <span className="icon-desc">
                              <span>Delivery address: </span>

                                {view.to}
                              </span>
                            </div>
                            <div className="itemDesc">
                              <span>
                                {" "}
                                <BiPhoneCall />
                              </span>
                              <span className="icon-desc">
                              <span>Delivery contact: </span>

                                {view.delivery_phone_no}
                              </span>
                            </div>




                            <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <span data-tip data-for="globaPickup2">
                                      <IconContext.Provider
                                        value={
                                          {
                                            // color: "#2e2e2e",
                                            // className: "global-class-name-icon",
                                          }
                                        }
                                      >
                                        {" "}
                                        <MdPayment />
                                      </IconContext.Provider>

                                      <ReactTooltip
                                        id="global30"
                                        aria-haspopup="true"
                                        role="example"
                                        type="light"
                                      >
                                        <span>Payer</span>
                                      </ReactTooltip>
                                    </span>
                                  </span>
                                  <span className="icon-desc">
                                    <span>Payer:</span>

                                    {view?.payer.charAt(0)?.toUpperCase() +
                                          view?.payer?.slice(1)}
                                  </span>
                                </div>




                              </div>
                            </div>           
                            <div className="content-iconn" style={{marginTop:'20px'}}>
                              <div className="topTitle">
                                Payment information
                              </div>
                              <div className="itemDescs">
                            <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <BsCreditCard/>
                                  </span>
                                  <span className="icon-desc">
                                  <span>Payment status: </span>
                                    {view.payment_status === 'pay_on_delivery' ? 'Pay on delivery' :  view.payment_status === 'success' ? 'Paid' :  view.payment_status === 'NA' ? 'NA' : 'Not paid' }
                                  </span>
                                </div>
                                </div>
                                </div>
                            </>

                          </Grid>
                      <Grid item xs={12}>
                        <>
                          <div className="itemDescs" style={{border:'none'}}>
                              <div className="topTitle">
                                  Quote information
                                </div>
                                <div className="itemDesc">
                                    {" "}
                                    <span>
                                      {" "}
                                      <MdDashboard/>
                                    </span>
                                    <span className="icon-desc"><span> Quote status: </span>
                                    {view.quote_status === 'completed' ? "Delivered" :
                                        view.quote_status.charAt(0)?.toUpperCase() + view.quote_status.slice(1).replace(/_/g , " ")
                                    }

                                    </span>
                                  </div>

                                <div className="itemDesc">
                                    {" "}
                                    <span>
                                      {" "}
                                      <MdDashboard/>
                                    </span>
                                    <span className="icon-desc"><span> Pickup date: </span>{view.pickup_date}</span>
                                  </div>

                                  <div className="itemDesc">
                                    {" "}
                                    <span>
                                      {" "}
                                      <MdDashboard/>
                                    </span>
                                    <span className="icon-desc"><span> Pickup time: </span>{moment(view?.assigned_agent_details.pickup_timestamp).format("YYYY-MM-DD HH:MM:SS")}</span>
                                  </div>
                                  {
                                    view.quote_status === "completed" ?
                                  <div className="itemDesc">
                                    {" "}
                                    <span>
                                      {" "}
                                      <MdDashboard/>
                                    </span>
                                    <span className="icon-desc"><span> Delivery time: </span>{moment( view?.delivery_agent_details?.delivery_timestamp).format('DD-MM-YYYY hh:mm:ss ')}</span>
                                  </div>
                                  : null
                                   }

                                  <div className="itemDesc">
                                  {" "}
                                  <span>
                                    {" "}
                                    <MdDashboard/>
                                  </span>
                                  <span className="icon-desc"><span>Delivery time frame: </span>{view.delivery_time_frame}</span>
                                </div>

                                <div className="itemDesc">
                                  <span>
                                    {" "}
                                    <FaClipboardCheck/>
                                  </span>
                                  <span className="icon-desc">
                                    <span>Accepted quote: </span>
                                  &#8358; {numberWithCommas.format(view.quote_amount)}
                                  </span>
                                </div>
                                <div className="itemDesc">
                                  {
                                    type === 'missed' ?
                                    <div style={{display:'flex', width:'100%', justifyContent:'space-between', marginTop:'10px'}}> 
                                        <Button 
                                            size="small" 
                                            variant="contained" 
                                            style={{fontSize:'12px', border:'1px solid var(--main)', backgroundColor:'var(--main)', color:'white', textTransform:'none'}}
                                            onClick={() => history.push(link)}
                                        >
                                            <div style={{display:'flex', gap:'5px', alignItems:'center', justifyContent:'center'}}>
                                                <span><IoMdMailOpen size='12px'/></span>
                                                <span>Message</span>
                                            </div>
                                        </Button>
                                    </div>
                                    :
                                    null
                                  }
                              </div>
                            </div>

                            <div className="itemDescs" style={{marginTop:'20px'}}>
                                <span className="icon-desc" style={{flexDirection:'column'}}>
                                  {
                                    type === 'complete' ?
                                      <>
                                        <div className="topTitle">Delivery Agent</div>
                                          <div style={{width:'100%', border:'1px solid #E5E5E5', borderTop:'none', borderRadius:'7px', borderTopRightRadius:'0', borderTopLeftRadius:'0', padding:'15px'}}>
                                              <Grid container spacing={1}>
                                                  <Grid item xs={4}>
                                                      <div className="quoteBodyDetailsRight" 
                                                          style={{ backgroundImage:`url(${assignedAgent[0]?.profile_photo?.profile_photo})`}}
                                                      ></div>
                                                  </Grid>
                                                  <Grid item xs={8}>
                                                      <Grid container spacing={1}>
                                                          <Grid item xs={8} style={{fontWeight:'500', fontSize:'13px'}}> {assignedAgent[0]?.first_name} </Grid> <Grid item xs={4} style={{ fontSize:'13px'}}> </Grid>
                                                          <Grid item xs={8} style={{fontWeight:'500', fontSize:'13px'}}> {assignedAgent[0]?.fulfilling_agent_id}  </Grid> <Grid item xs={4} style={{ fontSize:'13px'}}> </Grid>
                                                          <Grid item xs={8} style={{fontWeight:'500', fontSize:'13px'}}> Out for deliveries</Grid> <Grid item xs={4} style={{ fontSize:'13px'}}>({assignedAgent[0]?.num_intransit_deliveries}) </Grid>
                                                          <Grid item xs={8} style={{fontWeight:'500', fontSize:'13px'}}> Assign deliveries </Grid> <Grid item xs={4} style={{ fontSize:'13px'}}>({assignedAgent[0]?.num_assgn_deliveries}) </Grid>
                                                          <Grid item xs={8} style={{fontWeight:'500', fontSize:'13px'}}> Completed deliveries </Grid> <Grid item xs={4} style={{ fontSize:'13px'}}>({assignedAgent[0]?.num_completed_deliveries}) </Grid>
                                                      </Grid>
                                                  </Grid>
                                              </Grid>
                                          </div>
                                      </>
                                      :
                                      type === 'missed' ?
                                        <span className="icon-desc" style={{flexDirection:'column'}}>
                                              <div className="topTitle">Assigned Agent</div>
                                                <div style={{width:'100%', border:'1px solid #E5E5E5', borderTop:'none', borderRadius:'7px',borderTopRightRadius:'0', borderTopLeftRadius:'0', padding:'15px'}}>
                                                  <Grid container spacing={1}>
                                                      <Grid item xs={4}>
                                                          <div className="quoteBodyDetailsRight" 
                                                              style={{ backgroundImage:`url(${assignedAgent[0]?.profile_photo.profile_photo})`}}
                                                          ></div>
                                                      </Grid>
                                                      <Grid item xs={8}>
                                                          <Grid container spacing={1}>
                                                              <Grid item xs={8} style={{fontWeight:'500', fontSize:'13px'}}> {assignedAgent[0]?.first_name} </Grid> <Grid item xs={4} style={{ fontSize:'13px'}}> </Grid>
                                                              <Grid item xs={8} style={{fontWeight:'500', fontSize:'13px'}}> {assignedAgent[0]?.fulfilling_agent_id}  </Grid> <Grid item xs={4} style={{ fontSize:'13px'}}> </Grid>
                                                              <Grid item xs={8} style={{fontWeight:'500', fontSize:'13px'}}> Out for deliveries</Grid> <Grid item xs={4} style={{ fontSize:'13px'}}>({assignedAgent[0]?.num_intransit_deliveries}) </Grid>
                                                              <Grid item xs={8} style={{fontWeight:'500', fontSize:'13px'}}> Assign deliveries </Grid> <Grid item xs={4} style={{ fontSize:'13px'}}>({assignedAgent[0]?.num_assgn_deliveries}) </Grid>
                                                              <Grid item xs={8} style={{fontWeight:'500', fontSize:'13px'}}> Completed deliveries </Grid> <Grid item xs={4} style={{ fontSize:'13px'}}>({assignedAgent[0]?.num_completed_deliveries}) </Grid>
                                                          </Grid>
                                                      </Grid>
                                                  </Grid>
                                              </div>
                                              {/* <div style={{display:'flex', width:'100%', justifyContent:'space-between', marginTop:'10px'}}> 
                                                  <Button 
                                                      size="small" 
                                                      variant="contained" 
                                                      style={{fontSize:'12px', border:'1px solid var(--main)', backgroundColor:'var(--main)', color:'white', textTransform:'none'}}
                                                   >
                                                      <div style={{display:'flex', gap:'5px', alignItems:'center', justifyContent:'center'}}>
                                                          <span><RiPinDistanceFill size='12px'/></span>
                                                          <span>Track agent</span>
                                                      </div>
                                                  </Button>
                                              </div> */}
                                          </span>
                                        :
                                        null
                                    }
                                </span>
                          </div>
                          </>
                        </Grid>
                      </Grid>
                    </div>
                    </div>
                    </div>
                    })
                }
            </>
        </div>
    )
}

export default HistoryDrillDownMobile