import React, { useState, useEffect, useContext, useCallback } from "react";
import Checkout from "./Checkout";
import NavbarCart from "./NavbarCart";
import "./Checkout.css";
import { Box, FormControl, FormControlLabel, Grid, LinearProgress, Radio, RadioGroup } from "@mui/material";
import PaystackWidgetSender from "../../../components/PaystackWidgetSender";
import { useLocation } from "react-router-dom";
import SecureLS from "secure-ls";
import SenderSideBar from "../components/SenderSideBar";
import { SenderContext } from "../../../context/SenderDashboard/SenderDashboardContext";
import { PuffLoader } from "react-spinners";
import dillivrylogo from "../../../assets/dillivrylogo.svg";

import GlobalizationLanguage from "../../../components/GlobalizationLanguage";
import Paystack_sender_fund_wallet from "../../../components/Paystack_sender_fund_wallet";
import PaymentDialogPop from "../../../components/PaymentDialogPop";
import axios from "axios";
import check from "../../../assets/check.svg";
import progress from "../../../assets/progress.svg";
import App from "../../../components/Stripe/App";
import FundWalletBankTransfer from "../../../components/MakePayment/FundWalletBankTransfer";
import Swal from "sweetalert2";
import Copy from "../../../components/Copy";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { IoArrowBack } from "react-icons/io5";


const Checkout_Payment = ({ history }) => {
  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const sendersettings = GlobalizationLanguage("/sendersettings");
  var sendermessage = GlobalizationLanguage("/sendermessage");
  var viewQuote_myCart = GlobalizationLanguage("/viewQuote/myCart");
  var viewQuote_request_delivery = GlobalizationLanguage(
    "/viewQuote/request-a-delivery"
  );
  var viewQuote = GlobalizationLanguage("/viewQuote");

  const {
    initialstate, UpdateData
  } = useContext(SenderContext);


  const numberWithCommas = new Intl.NumberFormat("en-GB");
  const [stripepayment, setStripePayment] = useState(false);
  const [payment_method, setpayment_method] = useState('pay_by_wallet')
  const [payment_method_fund_wallet, setpayment_method_fund_wallet] = useState('pay_by_transfer')
  const location = useLocation();

  const [checkoutList, setcheckoutList] = useState([]);
  const [transaction, settransaction] = useState([]);
  const [selecttransaction, setselecttransaction] = useState([]);
  const [walletavailable, setwalletavailable] = useState(false);
  const [transferavailable, settransferavailable] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [gbdata, setgbdata] = useState(false);

  const [subTotal, setsubTotal] = useState(0);
  const [promoCode, setpromoCode] = useState("");
  const [promoValue, setpromoValue] = useState(0);
  const [promoAmount, setPromoAmount] = useState(0);
  const [total, settotal] = useState(0);
  const [quoteAmount, setquoteAmount] = useState(0);
  const [showlist, setshowlist] = useState(false);
  const [applying, setApplying] = useState(false);
  // const [payment_method, setpayment_method] = useState("paystack");
  const [errorMsg, seterrorMsg] = useState("");
  const [applied, setapplied] = useState(false);

  const [isStart, setIsStart] = useState(false);
  const [showpayment_method, setshowPayment_method] = useState(false);
  const [showtransferdetails, setshowtransferdetails] = useState(false);
  const [showwalletdetails, setshowwalletdetails] = useState(false);
  const [showinsufficient, setshowinsufficient] = useState(false);
  const [showfundwallet, setshowfundwallet] = useState(false);
  const [showsent, setshowsent] = useState(false);
  const [walletblc, setwalletblc] = useState();
  const [withdrawamt, setwithdrawamt] = useState("");
  const [addamt, setaddamt] = useState('');
  const [paying, setpaying] = useState(false)

  const [showing, setshowing] = useState('bank_selection')

  const [showWalletPay, setshowWalletPay] = useState(false)

  const [checkfocus, setcheckfocus] = useState(false);
  const toNumbers = (arr) => arr.map(Number);

  const [validrandom, setValidrandom] = useState();
  const [lastmsg, setlastmsg] = useState(false);
  const [paymentloading, setpaymentloading] = useState(false);
  const [paymentresponse, setpaymentresponse] = useState("");

  const [walletbalance, setwalletbalance] = useState();

  const [showTranferFromWallet, setShowTranferFromWallet] = useState(false)

  const handleChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setaddamt(e.target.value)
    }
  }
  const [refreshBalance, setrefreshBalance] = useState(false)
  const [loadingBalance, setloadingBalance] = useState(false)

  useEffect(() => {
    setloadingBalance(true)
    axios
      .get(`${endpoint}m-dillivry-sender-api`, {
        headers: {
          Authorization: ls.get("_utk"),
        },
      })
      .then((res) => {
        UpdateData(res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());

        if (res.status === 200) {
          setwalletbalance(
            res?.data?.data
              ?.map((x) => x?.ng ?? x?.gb)
              ?.map((x) => x?.account)
              ?.flat()
              ?.filter((x) => x.account_info)
              ?.map((x) => x?.account_info)[0]?.wallet_balance
          );
        }
      })
      .catch((err) => {

        console.log(err);
      })
      .finally(() => {
        setloadingBalance(false)
      })

  }, []);

  const getWalletBalance = () => {

    setrefreshBalance(true)

    axios
      .get(`${endpoint}m-dillivry-sender-api`, {
        headers: {
          Authorization: ls.get("_utk"),
        },
      })
      .then((res) => {
        UpdateData(res?.data?.data?.map((x) => x?.ng ?? x?.gb)?.flat());

        if (res.status === 200) {
          setwalletbalance(
            res?.data?.data
              ?.map((x) => x?.ng ?? x?.gb)
              ?.map((x) => x?.account)
              ?.flat()
              ?.filter((x) => x.account_info)
              ?.map((x) => x?.account_info)[0]?.wallet_balance
          );

          CheckPayment(res?.data?.data
            ?.map((x) => x?.ng ?? x?.gb)
            ?.map((x) => x?.account)
            ?.flat()
            ?.filter((x) => x.account_info)
            ?.map((x) => x?.account_info)[0]?.wallet_balance)
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setrefreshBalance(false)
      })
  }


  const authAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      Authorization: ls.get("_utk"),
    },
  });


  const onFundSuccess = () => {
    getWalletBalance()
    setrefreshBalance(true)
    setShowTranferFromWallet(false)
    setshowfundwallet(false)
  }

  const [gettingNarration, setgettingNarration] = useState(false);

  const getNarration = () => {

    setgettingNarration(true)

    authAxios.put(`${endpoint}m-dillivry-payment-and-verification-ops?type=payment_intent&delivery_location=ng&amount_paid=${total}&intent_type=pay_for_delivery`, {
      transactions: transaction
    })
      .then((res) => {
        setValidrandom(res?.data?.data?.narration_id);
        setshowing('transfer_page')
      })
      .catch((err) => {
        console.log(err, "error resposen")
      })
      .finally(() => {
        setgettingNarration(false)
      })
  }

  const [selectedValue, setSelectedValue] = useState('providus_bank');
  const [accountDetails, setAccountDetails] = useState({ 'name': 'Providus Bank', 'acct_num': '5401083438' });

  const handleSelect = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === 'providus_bank') {
      setAccountDetails({
        'name': 'Providus Bank', 'acct_num': '5401083438'
      })
    }
    else if (event.target.value === 'gtbank') {
      setAccountDetails({
        'name': 'GTBank', 'acct_num': '0715260356'
      })
    }
    else if (event.target.value === 'sterling') {
      setAccountDetails({
        'name': 'Sterling Bank', 'acct_num': '0090748438'
      })
    }
    else {
      setAccountDetails({
        'name': 'Wema Bank', 'acct_num': '0124231027'
      })
    }
  };

  var myHeaders = new Headers();
  myHeaders.append("Authorization", ls.get("_utk"));
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "transactions": transaction
  });

  var requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    // mode: 'no-cors'
  };


  var callback = (data, error) => {
    if (error) {
      Swal.fire({
        icon : "info",
        iconColor : '#70c06c',
        html : `<div class="line_high"> ${error?.response?.data?.message ?  error?.response?.data?.message : 'Operation not successful'}</div>`,
        confirmButtonColor:'#50B14A'
    })
      setpaymentloading(false)
      setshowtransferdetails(false);
      setshowsent(false);
      return;

    }

    setpaymentloading(false)
    setshowtransferdetails(false);
    setshowsent(false);

    Swal.fire({
      icon: "success",
      text: "Payment made successfully",
      timer: 3000,
      showConfirmButton: false,
    }).then(() => {
      window.location.reload()
    });
  };

  const ConfirmPayment = (retries, callback) => {

    setpaymentloading(true);

    authAxios.put(`${endpoint}/m-dillivry-payment-and-verification-ops?delivery_location=ng&narration=${validrandom}&type=confirm_payment&amount_paid=${total}`, 
      {
        "transactions": transaction
      })
      .then(result => {
        callback(result);
        console.log(result, "resonpesee")
      })
      .catch((error) => {
        if (retries > 0) {
          setTimeout(() => {
            ConfirmPayment(--retries, callback);
          }, 25000);
        }
        else {
          callback([], error);
        }
      })

  }


  const MakeWalletPayment = () => {

    setpaying(true)

    authAxios
      .post(
        `/m-dillivry-quote-manager?delivery_location=${transaction[0].delivery_location}`, {
        "action_type": "make_payment",
        "transactions": transaction,
        "amount_paid": total,
        "payment_date": new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
        "quoted_amount": total,
        "payment_gateway": "dillivry_wallet"
      }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Payment made successfully",
          confirmButtonColor: "var(--main)",
        })
          .then(() => {
            setshowWalletPay(false)
            window.location.reload()
          })
        ls.remove("item");
        ls.remove("delivery_id");

      })
      .catch((err) => {
        if (err?.response?.status === 403) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              ls.removeAll();
              history.push(`/login`);
            }
          });
        }
        else {
          Swal.fire({
            icon: "error",
            text: `${err?.response?.data?.message ? err?.response?.data?.message : "Operation not successful"}`,
            confirmButtonColor: '#50B14A'
          })
        }
      })
      .finally(() => {
        setpaying(false)
      })
  }

  const ShowWalletMessage = () => {
    setshowinsufficient(!showinsufficient)
  }

  const CheckPayment = (walletbalance) => {
    setshowPayment_method(false)
    Number(walletbalance) < Number(total)
      ? ShowWalletMessage()
      : setshowWalletPay(true)
  }

  const FundWallet = () => {
    setshowfundwallet(!showfundwallet)
    setshowinsufficient(!showinsufficient)
  }

  const withdrawamount = (e) => {
    e.preventDefault();
    // setproceedwithdraw(true);
    // setshowwithdraw(false);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    // setshowwithdraw(false);
  };

  const buttonstyle = () => {
    // console.log("btn-clicked");
    if (withdrawamt === "") {
      // setcheckfocus(!checkfocus);
    }
  };

  const filter = (arr, item) => {
    const filtered = arr.filter((check) => check.delivery_id !== item);

    let resss = filtered.map((location) => {
      return {
        delivery_id: location?.delivery_id,
        fulfiller_id: location?.provider_name,
        quoted_amount: location?.quote_amount,
        delivery_location: location?.delivery_location,
      };
    }
    )

    settransaction(resss)
    // console.log(resss,"filtered")

    setcheckoutList(filtered);
    setsubTotal(
      toNumbers(filtered.map((a) => a.quote_amount)).reduce((a, b) => a + b, 0)
    );
    settotal(
      (1 - promoValue) *
      toNumbers(filtered.map((a) => a.quote_amount)).reduce(
        (a, b) => a + b,
        0
      )
    );
    setquoteAmount(
      toNumbers(filtered.map((a) => a.quote_amount)).reduce((a, b) => a + b, 0)
    );
    setPromoAmount(
      promoValue *
      toNumbers(filtered.map((a) => a.quote_amount)).reduce(
        (a, b) => a + b,
        0
      )
    );
  };

  const endpoint = process.env.REACT_APP_API;


  const promoCodeDetails =
    initialstate?.data?.account?.account_info?.general_promo_code;

  useEffect(() => {

    location?.state?.find((x) => x["mode_of_payment"] === "pay_by_wallet") &&
      setwalletavailable(!walletavailable);

    location?.state?.find((x) => x["mode_of_payment"] === "pay_by_transfer") &&
      settransferavailable(!transferavailable);

    if (initialstate && location?.state) {
      setwalletblc(
        initialstate.map((x) => x?.account?.account_info?.wallet_balance)[0]
      );
      setcheckoutList(location?.state);
      const array_of_quote_amt = location?.state?.map((a) => a.quote_amount); //get all amount
      // const toNumbers = arr => arr.map(Number) // convert array of string to array of number
      setsubTotal(toNumbers(array_of_quote_amt)?.reduce((a, b) => a + b, 0)); //sum up arrays of numbers
      settotal(
        (1 - promoValue) *
        toNumbers(array_of_quote_amt)?.reduce((a, b) => a + b, 0)
      ); //sum up arrays of numbers
      setquoteAmount(toNumbers(array_of_quote_amt)?.reduce((a, b) => a + b, 0));
      setPromoAmount(promoValue * subTotal);
    } else {
      history.push({
        pathname: viewQuote_myCart,
        state: "from_cart",
      });
    }
  }, [promoValue, initialstate]);

  useEffect(() => {

    setgbdata(
      location?.state?.reduce(
        (accumulator, vendor) =>
          accumulator || vendor.delivery_location === "gb",
        false
      )
    );

    location?.state?.reduce(
      (accumulator, vendor) => accumulator || vendor.delivery_location === "gb",
      false
    ) === true
      ? setgbdata(true)
      : setgbdata(false);

    if (initialstate && location.state) {
      location?.state?.map((location) => {
        transaction.push({
          delivery_id: location?.delivery_id,
          fulfiller_id: location?.provider_name,
          quoted_amount: location?.quote_amount,
          delivery_location: location?.delivery_location,
        });
      });
    }

    selecttransaction.push({
      delivery_id: location?.state?.map((x) => x?.delivery_id)[0],
      fulfiller_id: location?.state?.map((x) => x?.provider_name)[0],
      quoted_amount: location?.state?.map((x) => x?.quote_amount)[0],
      delivery_location: location?.state?.map((x) => x?.delivery_location)[0],
    });

    setselecttransaction(
      location?.state?.map((location) => {
        return {
          delivery_id: location?.delivery_id,
          fulfiller_id: location?.provider_name,
          quoted_amount: location?.quote_amount,
          delivery_location: location?.delivery_location,
        };
      })
    );



    setSelectedId(
      location?.state?.map((location) => {
        return location?.delivery_id;
      })
    );

  }, []);

  const styles = {
    popContainer: {},
    iconCont: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
      borderBottom: "1px solid #E7E7E7",
      paddingBottom: '10px',
      gap: "10px",
    },
  };

  const Date_diff = (firstDate, secondDate) => {
    const d = firstDate;
    const today = secondDate;
    const diffTime = d - today;
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return diffDays;
  };

  const applyPromo = () => {
    setApplying(true);
    setTimeout(() => {
      if (promoCodeDetails === undefined) {
        seterrorMsg("No ongoing promo at the moment");
        setapplied(false);
      } else {
        if (promoCode === promoCodeDetails?.promo_code) {
          if (
            Date_diff(new Date(), new Date(promoCodeDetails?.start_date)) < 0
          ) {
            seterrorMsg("Promo have not started");
            setapplied(false);
          } else if (
            Date_diff(new Date(promoCodeDetails?.end_date), new Date()) > 0
          ) {
            if (promoCodeDetails?.usage_left > 0) {
              if (
                parseInt(promoCodeDetails?.usage_left) < checkoutList.length
              ) {
                seterrorMsg(
                  `${parseInt(
                    promoCodeDetails?.usage_left
                  )} Promo usage left, hence your checkout list has ${checkoutList.length
                  } item(s) `
                );
                setapplied(false);
              } else {
                seterrorMsg("");
                setapplied(true);
                setpromoValue(parseFloat(promoCodeDetails.discount));
              }
            } else {
              seterrorMsg("Promo usage reached");
              setapplied(false);
            }
          } else {
            seterrorMsg("promo have ended");
            setapplied(false);
          }
        } else {
          setpromoValue(0);
          seterrorMsg("invalid promo code");
          setapplied(false);
        }
      }
      setApplying(false);
    }, 1000);
  };

  const links = [
    {
      path: viewQuote,
      name: "Quotes",
      state: "inactive",
    },

    {
      path: viewQuote_request_delivery,
      name: "Request delivery",
      state: "inactive",
    },
    {
      path: sendermessage,
      name: "Message",
      state: "inactive",
    },

    // {
    //   path: senderapi,
    //   name: "API",
    //   state: "inactive",
    // },
    // {
    //   path: team,
    //   name: "Team",
    //   state: "inactive",
    // },
    // {
    //   path: sendersettlement,
    //   name: "Settlement",
    //   state: "inactive",
    // },
    {
      path: sendersettings,
      name: "Settings",
      state: "inactive",
    },
  ];

  return (
    <div>
      <div className="dsktopscreen">
        <NavbarCart
          backToText="Back to cart"
          backTo={() => history.push(viewQuote_myCart)}
        />
      </div>
      {
      }
      {
        refreshBalance ?
          <div
            style={{
              width: "100%",
              height: "100vh",
              backgroundColor: "none",
              zIndex: "700",
              position: "fixed",
              top: "40",
              padding: "0",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <LinearProgress color="success" />
            </Box>
          </div>
          : null
      }
      <div className="mobilescreen">
        <SenderSideBar pageTitle="Checkout" links={links} />
      </div>
      <div className="cart-cont">
        <div className="cart-cards">
          <div className="ckeockout-text">Check out </div>
          {checkoutList.map((x, i) => {
            return (
              <div key={i}>
                <Checkout
                  image={x?.image_urls[0]}
                  amount="Quote amount:"
                  actamount={x?.quote_amount}
                  paymentStatus={x?.payment_type}
                  itemDesc={x?.item_desc}
                  itemLocation={x?.pickup_address}
                  itemDestination={x?.delivery_address}
                  distance={x?.delivery_id}
                  deliveryStatus={x?.delivery_status}
                  acknowledgeStatus={x?.ack_status}
                  timeframe={x?.time_stamp}
                  timelisted={x?.date_listed}
                  deliverymode={x?.mode_of_delivery}
                  payer={x?.payer}
                  onRemoveClick={() => filter(checkoutList, x.delivery_id)}
                  removecheck
                  modeofpayment={x?.mode_of_payment}
                  location={x?.delivery_location}

                />
              </div>
            );
          })}

          <div className="paymentCont">
            <div></div>

            <div className="paymentRight">
              {/* <span style={{fontSize:'12px', color:'red',width:'100%', fontWeight:'400', width:'100%', display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}> <AiFillCloseCircle/>  Cash payment is not allowed</span> */}
              <div className="paymentRight_inner">
                <div style={{ width: "90%" }}>
                  <div>
                    <div className="sub_">
                      <span className="sub_titl">Subtotal</span>
                      <span className="sub_a">
                        &#8358;{numberWithCommas.format(subTotal)}
                      </span>
                    </div>
                    <div className="sub_">
                      <span className="sub_titl">Promo value</span>
                      <span className="sub_a">
                        &#8358;{numberWithCommas.format(promoAmount)}
                      </span>
                    </div>
                  </div>
                  <div className="total_">
                    <span className="total_titl">Total</span>
                    <span className="total_a">
                      &#8358;{numberWithCommas.format(total)}
                    </span>
                  </div>

                  <div className="paymentLeft">
                    <div
                      style={{
                        color: "var(--main)",
                        fontWeight: "500",
                        fontSize: "15px",
                        marginBottom: "18px",
                      }}
                    >
                      Apply promo code{" "}
                    </div>
                    <div>
                      <Grid container spacing={1}>
                        <Grid item xs={8}>
                          <input
                            value={promoCode}
                            onChange={(e) => {
                              setpromoCode(e.target.value);
                              seterrorMsg("");
                            }}
                            className="promo_input"
                            type="number"
                            placeholder="Enter promo code here"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <button
                            disabled={applying}
                            className="promo_btn"
                            onClick={applyPromo}
                          >
                            {applying ? (
                              <PuffLoader
                                color="white"
                                loading={applying}
                                speedMultiplier={1}
                                size={18}
                              />
                            ) : (
                              "Apply"
                            )}
                          </button>
                        </Grid>
                        <Grid item xs={12}>
                          <div
                            style={{
                              fontSize: "13px",
                              color: "maroon",
                              fontStyle: "italic",
                            }}
                          >
                            {errorMsg}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>

                {gbdata === true ? (
                  <>
                    <button
                      style={{
                        background: "#50B14A",
                        color: "#FFFFFF",
                        padding: "4px",
                        border: "1px solid #50B14A",
                      }}
                      onClick={() => {
                        setStripePayment(!stripepayment);
                        setshowPayment_method(!showpayment_method);
                      }}
                    >
                      Pay with stripe
                    </button>
                  </>
                ) :

                  (
                    <>
                      <select className='select_mode' value={payment_method} onChange={(e) => setpayment_method(e.target.value)} id="cars">
                        <option value="pay_by_wallet">Pay from wallet</option>
                        <option value="pay_by_transfer">Pay by transfer</option>
                        <option value="pay_by_card">Pay with paystack</option>
                        {/* <option value="pay_by_ussd">USSD</option> */}
                        {/* <option value="pay_by_cash">Cash</option> */}
                      </select>
                      {
                        payment_method === "pay_by_wallet" ?
                          <div className="paystack_btn"
                            onClick={loadingBalance ? null : () => CheckPayment(walletbalance)}
                          >
                            {
                              loadingBalance ? "Loading..." : "Proceed"
                            }
                          </div>
                          :
                          payment_method === "pay_by_transfer" ?
                            <div className="paystack_btn"
                              onClick={() => {
                                setshowPayment_method(false);
                                setshowtransferdetails(!showtransferdetails)

                              }}
                            >
                              Proceed
                            </div>
                            :
                            payment_method === "pay_by_card" || payment_method === "pay_by_ussd" ?
                              <PaystackWidgetSender
                                amount={total + "00"} //for paystack
                                amount_paid={total} //for backend
                                promoCode={applied ? promoCode : ""}
                                discount={promoCodeDetails?.discount}
                                quote_amount={quoteAmount}
                                transaction={transaction}
                                email={ls.get("eid")}
                                reload={() => {
                                  history.push({
                                    pathname: viewQuote_myCart,
                                    state: "from_cart",
                                  });
                                }}
                              />
                              :
                              <>{payment_method}</>
                      }
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <PaymentDialogPop
        openPopup={showtransferdetails}
        setopenPopup={setshowtransferdetails}
        head="Make payment by transfer"
      >
        <div>
          <div className="iconConTextStyle" style={styles.iconCont}>
            <div className="head_logo">
              <img width="100px" src={dillivrylogo} alt="dillivrylogo" className="dililogo" />
            </div>

            <div className="name_and_pay">
              <div className="sender__email">
                <h1
                  style={{
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    margin: 0,
                  }}
                  className="main_sender_email"
                >
                  {/* {item?.user_id} */}
                </h1>
              </div>
              <div className="amouttopay">
                <p style={{ color: "#50B14A" }} className="amountpay">
                  Pay &#8358;{numberWithCommas.format(total)}
                </p>
              </div>
            </div>
          </div>
          {
            showing === 'bank_selection' ?
              <div className='popContainer_payment'>
                <h1 style={{ fontSize: "14px", color: "#6F6F6F", marginTop: '20px', textAlign: 'left', marginBottom: '15px' }}>
                  Kindly select your preferred bank
                </h1>
                <div className="radio_cont">
                  <FormControl>
                    <RadioGroup
                      value={selectedValue}
                      onChange={handleSelect}
                    >
                      <FormControlLabel className='radio_lbl' value="providus_bank" control={<Radio color="success" />} label="Providus Bank" />
                      <FormControlLabel disabled className='radio_lbl' value="gtbank" control={<Radio color="success" />} label="GTBank" />
                      <FormControlLabel disabled className='radio_lbl' value="sterling" control={<Radio color="success" />} label="Sterling Bank" />
                      <FormControlLabel disabled className='radio_lbl' value="wema" control={<Radio color="success" />} label="Wema Bank" />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="sent__btn">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "20px 30px"
                    }}
                  >
                    <div
                      className="paystack_btn"
                      style={{ padding: '6px' }}
                      onClick={gettingNarration ? () => console.log("hello") : getNarration}
                    >
                      {
                        gettingNarration ? "Loading..." : "Proceed"
                      }
                    </div>
                  </div>
                </div>

              </div>
              :

              <>
                <div className="transfer_amt" >
                  <div onClick={() => setshowing('bank_selection')}>
                    <IoArrowBack size={20} />
                  </div>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontStyle: "normal",
                      fontSize: "14px",
                      color: "#6F6F6F",
                      margin: "0"
                    }}
                  >
                    Transfer &#8358;{numberWithCommas.format(total)} to the account{" "}
                  </h1>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "20px 0",
                    background: "#F4F4F4",
                    borderRadius: "5px",
                    padding: "20px 0",
                  }}
                  className="bank__details"
                >
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "24px",
                      color: "#1D1C1D",
                    }}
                    className="bank__name"
                  >
                    {accountDetails.name}
                  </h1>
                  <h1
                    style={{
                      fontFamily: "Roboto",
                      fontSize: "32px",
                      color: "#1D1C1D",
                    }}
                    className="bank__acct"
                  >
                    {accountDetails.acct_num}
                    <Copy tocopy={accountDetails.acct_num}>
                      <ContentCopyOutlinedIcon sx={{ fontSize: '20px', marginLeft: '10px', color: 'grey' }} />
                    </Copy>

                  </h1>
                </div>

                <div>
                  <div
                    className="text"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                      }}
                      className="id-txt"
                    >
                      Use this unique ID <br />
                      <span
                        style={{
                          color: "#004DC1",
                          display: "flex",
                          justifyContent: "center",
                          fontFamily: "Roboto",
                          gap: "2px",
                          fontSize: "32px",
                        }}
                      >
                        {`${validrandom}`}
                        <Copy tocopy={validrandom}>
                          <ContentCopyOutlinedIcon sx={{ fontSize: '20px', marginLeft: '10px', color: 'grey' }} />
                        </Copy>
                      </span>
                    </p>
                  </div>

                  <div className="textid">
                    <p
                      style={{
                        textAlign: "center",
                      }}
                      className="maintxt"
                    >
                      in your narration as payment reference to get value for the
                      payment
                    </p>
                  </div>
                  <div className="textid" style={{ color: 'grey', fontSize: '12px', marginTop: '10px' }}>
                    <p style={{ textAlign: "center" }} >
                      Click the button below once transfer is made successfully
                    </p>
                  </div>
                </div>

                <div className="sent__btn">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    <button
                      onClick={
                        () => {
                          return (
                            ConfirmPayment(10, callback),
                            setshowtransferdetails(false),
                            setshowsent(!showsent)
                          );
                        }
                      }
                      style={{
                        background: "#50B14A",
                        borderRadius: "20px",
                        padding: " 0 30px",
                        border: "1px solid #50B14A",
                        color: "#ffffff",
                        height: "auto",
                        fontSize: "16px",
                        fontFamily: "Roboto",
                        fontWeight: "700",
                      }}
                    >
                      {isStart ? "I have sent the money" : " I have sent the money"}
                    </button>
                  </div>
                </div>
              </>
          }
        </div>
      </PaymentDialogPop>

      <PaymentDialogPop
        openPopup={showsent}
        setopenPopup={setshowsent}
      >
        <div>
          <div style={styles.iconCont}>
            <div className="head_logo">
              <img src={dillivrylogo} alt="dillivrylogo" className="dililogo" />
            </div>

            <div className="name_and_pay">
              <div className="sender__email">
                <h1
                  style={{
                    fontSize: "12px",
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    margin: 0,
                  }}
                  className="main_sender_email"
                >
                </h1>
              </div>
              <div className="amouttopay">
                <p style={{ color: "#50B14A" }} className="amountpay">
                  Pay &#8358;{numberWithCommas.format(total)}
                </p>
              </div>
            </div>
          </div>

          {lastmsg ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
              className="transfer_amt"
            >
              <h1
                style={{
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontSize: "14px",
                  color: "#6F6F6F",
                }}
                className="transfer_text"
              >
                We are still unable to confirm your payment. You can proceed
                with your delivery process. You will get a feedback from us when
                your payment is confirmed.{" "}
              </h1>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
              className="transfer_amt"
            >
              {lastmsg ? (
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontSize: "14px",
                    color: "#6F6F6F",
                  }}
                  className="transfer_text"
                >
                  We are still unable to confirm your payment. You can proceed
                  with your delivery process. You will get a feedback from us
                  when your payment is confirmed.
                </h1>
              ) : (
                <h1
                  style={{
                    fontFamily: "Roboto",
                    fontStyle: "normal",
                    fontSize: "14px",
                    color: "#6F6F6F",
                  }}
                  className="transfer_text"
                >
                  {paymentloading
                    ? "We are waiting to receive your transfer. This can take up to a minute"
                    : paymentresponse}
                </h1>
              )}
            </div>
          )}

          {paymentloading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
              className="progressapprovedimg"
            >
              <div className="received">
                <img src={check} alt="checked" className="receivedimg" />
                <p
                  className="sent__text"
                  style={{
                    color: "#50B14A",
                  }}
                >
                  Sent
                </p>
              </div>

              <Box mt={3} mb={2} sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>

              {!paymentloading ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  className="sent"
                >
                  <img src={check} alt="progress" className="receivedimg" />

                  <p
                    style={{
                      color: "#50B14A",
                    }}
                    className="received"
                  >
                    Received
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  className="sent"
                >
                  <img src={progress} alt="progress" className="receivedimg" />

                  <p className="received">Received</p>
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <button
                onClick={() => {
                  return setshowtransferdetails(false), setshowsent(!showsent);
                }}
                style={{
                  background: "#50B14A",
                  borderRadius: "4px",
                  padding: " 0 30px",
                  border: "1px solid #50B14A",
                  color: "#ffffff",
                  height: "40px",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                }}
              >
                Proceed
              </button>
            </div>
          )}

        </div>
      </PaymentDialogPop>

      {Number(walletblc) > Number(total) ? (
        <PaymentDialogPop
          openPopup={showwalletdetails}
          setopenPopup={setshowwalletdetails}
          head="Withdrawal"
        >
          <div>
            <div className="withdrwalbody">
              <div>
                <h1 className="withdrawtag">Withdrawable: &nbsp; &nbsp;</h1>
              </div>

              <div>
                <span className="withdawspan">
                  &#8358;
                  {
                    numberWithCommas?.format(total) === 0 ||
                      numberWithCommas?.format(total) === undefined ||
                      numberWithCommas?.format(total) === isNaN
                      ? "0.00"
                      : numberWithCommas.format(total)
                  }
                </span>
              </div>
            </div>

            <div className="withdrawform">
              <form onSubmit={withdrawamount} action="">
                <div className="form-div">
                  <input
                    type="number"
                    onKeyUp={buttonstyle}
                    name="withdrawinput"
                    value={withdrawamt}
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    onChange={(e) => {
                      setwithdrawamt(e.target.value);
                    }}
                    required
                    placeholder="Enter amount to withdraw"
                    className="withdrawinput"
                  />
                  <div
                    style={{
                      color: "red",
                      marginTop: "5px",
                      fontSize: "13px",
                      textDecoration: "italic",
                    }}
                  >
                    {parseInt(withdrawamt) < 100 ? (
                      <>You can only withdraw from &#8358;100 and above</>
                    ) : parseFloat(withdrawamt) > parseFloat(walletblc) ? (
                      <>Amount cannot be more than your withdrawable</>
                    ) : // <></>
                      checkfocus ? (
                        <>You can only enter a digit</>
                      ) : null}
                  </div>
                  <div className="cancelandproceed">
                    <div onClick={handleCancel} className="cancel">
                      Cancel
                    </div>

                    {withdrawamt === "" ? (
                      <button className="proceed" disabled>
                        Proceed
                      </button>
                    ) : parseInt(withdrawamt) < 100 ? (
                      <button className="proceed" disabled>
                        Proceed
                      </button>
                    ) : parseFloat(withdrawamt) > parseFloat(walletblc) ||
                      walletblc === undefined ||
                      walletblc === isNaN ? (
                      <button className="proceed" disabled>
                        Proceed
                      </button>
                    ) : (
                      <button className="proceedplus" type="submit">
                        <span>Proceed</span>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </PaymentDialogPop>
      ) : (
        <PaymentDialogPop
          openPopup={showfundwallet}
          setopenPopup={setshowfundwallet}
          head="Add fund"
        >
          <div className="withdrawform">
            <form onSubmit={withdrawamount} action="">
              <div className="form-div">
                <input className='withdrawinput' autoFocus style={{ outline: "none" }} value={addamt} type="text" placeholder='Enter amount to add' onChange={(e) => handleChange(e)} />

                <div
                  style={{
                    color: "red",
                    marginTop: "0px",
                    marginBottom: "10px",
                    fontSize: "13px",
                    textDecoration: "italic",
                  }}
                >
                  {parseInt(addamt) < 100 ? (
                    <>You can only add from &#8358;100 and above</>
                  ) : null}
                </div>

                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <select className='select_mode' value={payment_method_fund_wallet} onChange={(e) => setpayment_method_fund_wallet(e.target.value)} >
                      <option value="pay_by_transfer">Pay by transfer</option>
                      <option value="pay_by_card">Pay with paystack</option>
                      {/* <option value="pay_by_ussd">USSD</option> */}
                    </select>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: "4px",
                      }}
                    >
                      {
                        payment_method_fund_wallet === "pay_by_transfer" ?
                          addamt === '' || parseInt(addamt) < 100 ?
                            <div className="paystack_btn_disable" >
                              Proceed
                            </div>
                            :
                            <div className="paystack_btn"
                              onClick={() => { setShowTranferFromWallet(true) }}
                            >
                              Proceed
                            </div>
                          :
                          <Paystack_sender_fund_wallet
                            sender
                            amount={addamt + "00"} //for paystack
                            amount_paid={addamt}
                            email={ls.get("eid")}
                            closePopup={() => setshowfundwallet(false)}
                            refresh={onFundSuccess}

                          />
                      }
                    </div>
                  </Grid>
                </Grid>
              </div>
            </form>
          </div>
        </PaymentDialogPop>
      )}

      <PaymentDialogPop
        openPopup={showTranferFromWallet}
        setopenPopup={setShowTranferFromWallet}
        head="Fund your wallet"
      >
        <div className="popContainer_payment">
          <FundWalletBankTransfer amount={addamt} onSuccess={onFundSuccess} />
        </div>
      </PaymentDialogPop>

      <PaymentDialogPop
        openPopup={showinsufficient}
        setopenPopup={setshowinsufficient}
      >
        <div className="div.messageandbutton" >
          <p>Insufficient fund in your wallet, fund your wallet</p>

          <div className='to_ceter'>Wallet balance</div>
          <div className="wallet_balncenc">
            <span className='wallet_balnc'>
              &#8358;{numberWithCommas.format(walletbalance)}
            </span>
          </div>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  background: "#50B14A",
                  borderRadius: "4px",
                  padding: " 0 30px",
                  border: "1px solid #50B14A",
                  color: "#ffffff",
                  height: "40px",
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  fontWeight: "700",
                }}
                onClick={FundWallet}
              >
                Fund wallet
              </button>
            </div>
          </Grid>

        </div>


      </PaymentDialogPop>

      <PaymentDialogPop
        openPopup={showWalletPay}
        setopenPopup={setshowWalletPay}
      >
        <div className="div.messageandbutton" >

          <div className='to_ceter'>Wallet balance</div>
          <div className="wallet_balncenc">
            <span className='wallet_balnc'>
              &#8358;{numberWithCommas.format(walletbalance)}
            </span>
          </div>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              {
                paying ?
                  <div className="paystack_btn" style={{ width: '200px', height: '45px' }}>
                    <PuffLoader
                      color="white"
                      loading={paying}
                      speedMultiplier={1}
                      size={24}
                    />
                  </div>
                  :
                  <button
                    style={{
                      marginTop: '30px',
                      background: "#50B14A",
                      borderRadius: "4px",
                      padding: " 0 30px",
                      border: "1px solid #50B14A",
                      color: "#ffffff",
                      height: "40px",
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      fontWeight: "700",
                      width: "200px"
                    }}
                    onClick={MakeWalletPayment}
                  >
                    Pay now
                  </button>

              }


            </div>
          </Grid>

        </div>


      </PaymentDialogPop>


      <PaymentDialogPop
        openPopup={stripepayment}
        setopenPopup={setStripePayment}
        head="Payment"
      >
        <App
          promoCode={applied ? promoCode : ""}
          discount={promoCodeDetails?.discount}
          state="checkout"
          transaction={transaction}
          total={total}
          amount_paid={total}
          quote_amount={quoteAmount}
        />
      </PaymentDialogPop>
    </div>
  );
};

export default Checkout_Payment;
