import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { useHistory } from "react-router-dom";
import SecureLS from "secure-ls";
import moment from "moment";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { AuthContext } from "../../../context/AuthContext";
import { DashboardContext } from "../../../context/Dashboard/DashboardProvider/dashboard.provider";
import Layout from "../../Sender/SenderDashboard/Layout";
import { SenderContext } from "../../../context/SenderDashboard/SenderDashboardContext";
import jwt_decode from "jwt-decode";
import SenderBottomNav from "../../Sender/components/SenderBottomNav";
import SenderSideBar from "../../Sender/components/SenderSideBar";
import GlobalizationLanguage, { GlobalizationLanguagewithCOntryCode } from "../../../components/GlobalizationLanguage";
import Cookies from "js-cookie";
import { ConnContext } from "../../../context/ConnContext";
import { useClipboard } from "use-clipboard-copy";
import copyart from "../../../assets/copyart.svg";
import ReferralPageMobile from "./ReferralPageMobile";
import GenerateReferalBtn from "./GenerateReferalBtn";

function ReferralPage() {

  const { visitingCountryCode } =
    useContext(ConnContext);

  const {
    alldata,
    UpdateAlldata,
  } = useContext(SenderContext);

  var countryvalue =
    Cookies.get("i18next") || visitingCountryCode.toLowerCase();

  useEffect(() => {
    setCountry(Cookies.get("i18next"));
  }, [Cookies.get("i18next")]);

  const [country, setCountry] = useState(countryvalue);

  const ls = new SecureLS({
    isCompression: true,
    encodingType: "rabbit",
    encryptionSecret: "dm_dillivry",
  });
  const history = useHistory();
  const auth = useContext(AuthContext);

  const sendersettings = GlobalizationLanguage("/sendersettings")
  const sendersettlement = GlobalizationLanguage("/sendersettlement")
  const senderapi = GlobalizationLanguage("/senderapi")
  const team = GlobalizationLanguage("/team")
  var RefPage = GlobalizationLanguage("/referrals_sender");

  const [generatedReferral, setGeneratedReferral] = useState("")
  
  const links = [
    // {
    //   path: team,
    //   name: "Team",
    //   state: 'inactive'
    // },

    // {
    //   path: senderapi,
    //   name: "API",
    //   state: 'inactive'
    // },
    // {
    //   path: sendersettlement,
    //   name: "Settlement",
    //   state: 'inactive'
    // },
    {
      path: RefPage,
      name: "Referral",
      icon: "devices",
      state: "active",
    },
    // {
    //   path: sendermessage,
    //   name: "Help",
    //   state: 'inactive'
    // },
    {
      path: sendersettings,
      name: "Settings",
      icon: "settings",
      state: 'inactive'
    },
  ]

  const { dashboards, updateDashboard, update_balc_withdraw } =
    useContext(DashboardContext);
  const [ShowLogin, setShowLogin] = useState(false);
  const [loadingData, setloadingData] = useState(false);
  const [refdata, setrefdata] = useState([]);
  const [refcode, setrefcode] = useState("")
  const [refbycode, setrefbycode] = useState("")


  const endpoint = process.env.REACT_APP_API;

  useEffect(() => {
    setloadingData(true);
    if (alldata !== undefined) {
      const CheckLength = alldata?.data?.data?.deliveries ? alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length
      const InfoData = alldata?.data?.data?.deliveries ? alldata?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info) : alldata?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)
      console.log(InfoData, "infodata")
      CheckLength === 1 ? setrefcode(InfoData[0]?.referral_code) : setrefcode(InfoData[1]?.referral_code)
      CheckLength === 1 ? setrefbycode(InfoData[0]?.referred_by_code) : setrefbycode(InfoData[1]?.referred_by_code)
      window.scrollTo(0, 0);
      if (ls.get("_utk") === "") {

        history.push(
          GlobalizationLanguagewithCOntryCode(
            "/login",
            country,
            visitingCountryCode
          )
        );
        auth.logout();
      }
      else {
        const decoded = jwt_decode(ls.get("_utk"));
        const expired = decoded.exp;
        if (expired < Date.now() / 1000) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              auth.logout();
              history.push(
                GlobalizationLanguagewithCOntryCode(
                  "/login",
                  country,
                  visitingCountryCode
                )
              );
            }
          });
        } else {
          setloadingData(true);
          axios
            .get(`${endpoint}m-dillivry-promo-code?&type=referrals_earning`, {
              headers: {
                Authorization: ls.get("_utk"),
              },
            })
            .then((response) => {
              setloadingData(false);
              setrefdata(response?.data?.data);
            })
            .catch((error) => {
              setloadingData(false);
            });
        }
      }


      if (alldata === undefined) {
        setloadingData(true);
        axios
          .get(`${endpoint}m-dillivry-sender-api`, {
            headers: {
              Authorization: ls.get("_utk"),
            },
          })
          .then((response) => {
            setrefcode(response?.data?.my_account_info?.my_account_info?.map(x => x?.referral_code))
            setrefbycode(dashboards?.data?.my_account_info?.my_account_info?.map(x => x?.referred_by_code))


            const CheckLength = response?.data?.data?.deliveries ? response?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length : response?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length
            const InfoData = response?.data?.data?.deliveries ? response?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info) : response?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)
            console.log(InfoData, "infodataaa 2222")
            CheckLength === 1 ? setrefcode(InfoData[0]?.referral_code) : setrefcode(InfoData[1]?.referral_code)
            CheckLength === 1 ? setrefbycode(InfoData[0]?.referred_by_code) : setrefbycode(InfoData[1]?.referred_by_code)
            UpdateAlldata(response)


            setloadingData(false);

          })
          .catch((error) => {
            if (error?.response?.status === 403) {
              Swal.fire({
                icon: "warning",
                title: "Session!",
                text: "Session expired login again!",
                allowOutsideClick: false,
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  auth.logout();
                  history.push('/login')
                }
              })
            }
          })
      }
    } else {
      setloadingData(true);
      window.scrollTo(0, 0);
      if (ls.get("_utk") === "") {

        history.push(
          GlobalizationLanguagewithCOntryCode(
            "/login",
            country,
            visitingCountryCode
          )
        );
        auth.logout();
      }
      else {
        const decoded = jwt_decode(ls.get("_utk"));
        const expired = decoded.exp;
        if (expired < Date.now() / 1000) {
          Swal.fire({
            icon: "warning",
            title: "Session!",
            text: "Session expired login again!",
            allowOutsideClick: false,
            confirmButtonColor: "var(--main)",
          }).then((result) => {
            if (result.value) {
              auth.logout();
              history.push(
                GlobalizationLanguagewithCOntryCode(
                  "/login",
                  country,
                  visitingCountryCode
                )
              );
            }
          });
        } else {
          setloadingData(true);
          axios
            .get(`${endpoint}m-dillivry-promo-code?&type=referrals_earning`, {
              headers: {
                Authorization: ls.get("_utk"),
              },
            })
            .then((response) => {
              setloadingData(false);
              setrefdata(response?.data?.data);

            })
            .catch((error) => {
              setloadingData(false);
              setloadingData(false);
            });
        }
      }
      if (alldata === undefined) {
        setloadingData(true);
        axios
          .get(`${endpoint}m-dillivry-sender-api`, {
            headers: {
              Authorization: ls.get("_utk"),
            },
          })
          .then((response) => {
            setrefcode(response?.data?.my_account_info?.my_account_info?.map(x => x?.referral_code))
            setrefbycode(dashboards?.data?.my_account_info?.my_account_info?.map(x => x?.referred_by_code))
            const CheckLength = response?.data?.data?.deliveries ? response?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length : response?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)?.length
            const InfoData = response?.data?.data?.deliveries ? response?.data?.data?.deliveries?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info) : response?.data?.data?.map((x) => x?.ng ?? x?.gb)?.map(x => x?.account)?.map(x => x?.account_info)
            CheckLength === 1 ? setrefcode(InfoData[0]?.referral_code) : setrefcode(InfoData[1]?.referral_code)
            CheckLength === 1 ? setrefbycode(InfoData[0]?.referred_by_code) : setrefbycode(InfoData[1]?.referred_by_code)
            UpdateAlldata(response)


            setloadingData(false);

          })
          .catch((error) => {
            if (error?.response?.status === 403) {
              Swal.fire({
                icon: "warning",
                title: "Session!",
                text: "Session expired login again!",
                allowOutsideClick: false,
                confirmButtonColor: "var(--main)",
              }).then((result) => {
                if (result.value) {
                  auth.logout();
                  // setShowLogin(true);
                  history.push('/login')
                }
              })
            }
          })
      }



    }
  }, [])






  const dataHead = [
    {
      thead: "Date registered",
    },
    {
      thead: "Name",
    },
    {
      thead: "No of delivery",
    },

    {
      thead: "Avg earning per Delivery",
    },

    {
      thead: "Total earnings",
    }
  ];



  const clipboard = useClipboard({
    copiedTimeout: 600, // timeout duration in milliseconds
  });



  return (
    <div>
      <div className="dsktopscreen">
        <Layout>
          <div>
            {/* <SearchBar/> */}
            {ShowLogin ? (
              history.push("/login")
            ) : (
              <div className="dashboard">
                {loadingData ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100vh",
                      backgroundColor: "none",
                      zIndex: "300",
                      position: "fixed",
                      top: "0",
                      paddingTop: "80px",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress color="success" />
                    </Box>
                  </div>
                ) : (
                  <div
                    style={{
                      padding: "0px 55px",
                    }}
                    className="dashboardDesktop"
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "30px",
                      }}
                      className="referandref"
                    >
                      <div
                        className="leftref"
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "700",
                        }}
                      >
                        Referral code

                      </div>

                      <div
                        style={{
                          fontFamily: "Roboto",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "700",
                        }}
                        className="leftref"
                      >
                        Referred by code
                      </div>
                    </div>
                    {
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "20px",
                        }}
                        className="referandref"
                      >
                        {
                          refcode === " " || refcode === undefined || refcode === null || refcode?.length === 0 ? 
                            <span><GenerateReferalBtn setValue={setGeneratedReferral}/> </span> :
                            <div
                              className="leftref"
                              style={{
                                border: "1px solid rgba(0, 0, 0, 0.12)",
                                borderRadius: "8px",
                                padding: "6px",
                                color: "#484848",
                                fontSize: "16px",
                                fontFamily: "Roboto",

                                fontStyle: "normal",
                              }}
                            >
                              <input
                                style={{
                                  width: "75px",
                                  border: "none",

                                }}
                                ref={clipboard.target}
                                value={refcode}
                                readOnly
                              />

                              <img
                                onClick={clipboard.copy}
                                src={copyart}
                                alt="copytag"
                                className="tagcopy"
                                data-ho
                              />
                              {clipboard.copied ? "Copied" : ""}
                            </div>
                        }
                        {
                          refbycode === " " || refbycode === undefined || refbycode === null || refbycode?.length === 0 || refbycode?.length === 0 ? <span>NA</span> :
                            <div className="leftref">
                              <p
                                style={{
                                  border: "1px solid rgba(0, 0, 0, 0.12)",
                                  borderRadius: "8px",
                                  padding: "6px",
                                  color: "#484848",
                                  fontSize: "16px",
                                  fontFamily: "Roboto",

                                  fontStyle: "normal",
                                }}
                              >
                                {refbycode}
                              </p>
                            </div>
                        }
                      </div>
                    } 
                    <h6
                      style={{
                        marginBottom: "20px",
                      }}
                    >
                      Referral history
                    </h6>

                    <table className="msg-table">
                      <thead>
                        {dataHead.map((msg, i) => (
                          <th
                            style={{ background: "#ffffff" }}
                            key={i}
                            className="data-thead--ff"
                          >
                            {msg.thead}
                          </th>
                        ))}
                      </thead>
                      {
                        refdata.map((x, i) => {
                          return (
                            <tbody>
                              <tr className="msg-trow-" key={i}>
                                <td className="msg-tdata-f">
                                  <span
                                    style={{
                                      color: "#004DC1",
                                    }}
                                    className="itemdesc-span"
                                  >
                                    {/* {x?.date_registered} */}
                                    {moment(x?.date_registered)?.format("DD-MM-YYYY")}  &nbsp;
                                    {moment(x?.date_registered)?.format('LTS')}
                                  </span>
                                </td>
                                <td className="msg-tdata-f">
                                  <span className="itemdesc-span">
                                    {x?.referral_name}
                                  </span>
                                </td>
                                <td className="msg-tdata-f">
                                  <span className="itemdesc-span">
                                    {x?.completed_jobs}
                                  </span>
                                </td>
                                <td className="msg-tdata-f">
                                  <span className="itemdesc-span">

                                    &#8358;
                                    {x?.average_earn.toLocaleString()}

                                  </span>
                                </td>
                                <td className="msg-tdata-f">
                                  <span className="itemdesc-span">

                                    &#8358;
                                    {x?.credit_amount_total.toLocaleString()}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })
                      }
                    </table>

                    {
                      refdata?.length === 0 ?

                        <div style={{
                          textAlign: "center",
                          marginTop: "80px"
                        }} >
                         <div>You do not have referral history</div> 
                         {
                          refcode === " " || refcode === undefined || refcode === null || refcode?.length === 0 ?
                          <div>kindly generate referral code to get your code</div> 
                          :
                          null
                         }
                        
                         </div> : null
                    }
                  </div>
                )}

                {/* <>
     <h1>Referrals</h1>
  </> */}
              </div>
            )}
          </div>

        </Layout>
      </div>

      <div className="mobilescreen">
        <SenderSideBar pageTitle="Referral" links={links} />
        <ReferralPageMobile data={refdata} refcode={refcode} refbycode={refbycode} />
        <SenderBottomNav />
      </div>
    </div>
  )
}

export default ReferralPage