import React from 'react'
import './CaptureBtn.css'
function CaptureBtn({onClick}) {
    return (
        <div className ="e251_2274" onClick={onClick}>
            <div className ="e251_2275">
                <div className ="e251_2276"></div>
            </div>
        </div>
    )
}

export default CaptureBtn
